<div class="block-container" [ngClass]="typeTravel === 'multidesti' ? 'return' : typeTravel">
  <span class="travel" *ngIf="typeTravel === 'multiseg' && !resa"
    >{{ "GLOBAL.TRIP" | translate }} {{ itemIndex + 1 }}</span
  >
  <div class="vertical-separator" *ngIf="typeTravel !== 'return' && typeTravel !== 'multidesti'">
    <span class="round"></span>
    <span class="line"></span>
    <span class="round"></span>
  </div>
  <div class="reservations-container">
    <div
      class="reservation"
      *ngFor="let trip of this.item.detail.trips; let isFirst = first; let isLast = last; let i = index"
      [ngClass]="typeTravel === 'multidesti' ? 'return' : typeTravel"
      [class.afkl]="item.provider"
    >
      <ng-container *ngIf="item.provider === 'afkl'; else sabreItem">
        <div class="legs" [ngClass]="typeTravel === 'multidesti' ? 'return' : typeTravel">
          <span
            class="separator-segment"
            *ngIf="(typeTravel === 'return' || typeTravel === 'multidesti') && !isFirst"
          ></span>
          <div class="infos" [ngClass]="typeTravel === 'multidesti' ? 'return' : typeTravel">
            <ng-container *ngIf="typeTravel === 'return' || typeTravel === 'multidesti'; else other">
              <p class="place">
                <ng-container *ngIf="(commonService.isTabletObservable | async) === false; else isTablet">
                  <span class="type" *ngIf="typeTravel === 'return' && isFirst" translate>GLOBAL.DEPARTURE</span>
                  <span class="type" *ngIf="typeTravel === 'return' && isLast" translate>GLOBAL.RETURN</span>
                  <span class="type" *ngIf="typeTravel === 'multidesti' && isFirst" translate
                    >GLOBAL.MULTIDESTINATIONS</span
                  >
                </ng-container>
                <ng-template #isTablet>
                  <span class="type" *ngIf="typeTravel === 'return' && isFirst"
                    >{{ "GLOBAL.DEPARTURE" | translate }} - {{ "GLOBAL.RETURN" | translate }}</span
                  >
                  <span class="type" *ngIf="typeTravel === 'multidesti' && isFirst">{{
                    "GLOBAL.MULTIDESTINATIONS" | translate
                  }}</span>
                </ng-template>
                <span class="od"
                  ><span class="origin"
                    >{{ this.item.detail.airports[trip.Dep.IATALocationCode]?.city }},
                    <span class="airport">&nbsp;{{ trip.Dep.IATALocationCode }}</span></span
                  >
                  <i class="spt-icon-arrow-circle-right arrow-right"></i>
                  <span class="destination" *ngIf="typeTravel === 'return' || typeTravel === 'multidesti'"
                    >{{ this.item.detail.airports[trip.Arrival.IATALocationCode]?.city }},
                    <span class="airport">&nbsp;{{ trip.Arrival.IATALocationCode }}</span></span
                  ></span
                >
              </p>
            </ng-container>
            <ng-template #other>
              <div class="origin">
                <p class="place">
                  {{ this.item.detail.airports[trip.Dep.IATALocationCode]?.city }},
                  <span class="airport">&nbsp;{{ trip.Dep.IATALocationCode }}</span>
                </p>
                <div class="date-hours-container">
                  <div class="date-hours" *ngIf="isFirst">
                    <div class="date">
                      <div class="schedules">
                        <div class="schedule">
                          <img src="./assets/svg/calendar-icon.svg" class="calendar-icon" />
                          <span class="date-day">{{ trip.Dep.AircraftScheduledDateTime | localizedDate: "dd" }}</span>
                          <span class="date-month">{{
                            trip.Dep.AircraftScheduledDateTime | localizedDate: "MMM" | titlecase
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="chip-timing">
                      <div class="schedules">
                        <div class="schedule">
                          <img
                            src="./assets/svg/clock2.svg"
                            class="clock-icon"
                            *ngIf="(commonService.isTabletObservable | async) === false"
                          />
                          <span>{{ trip.Dep.AircraftScheduledDateTime | slice: 11 : 16 }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="destination">
                <p class="place">
                  {{ this.item.detail.airports[trip.Arrival.IATALocationCode]?.city }},
                  <span class="airport">&nbsp;{{ trip.Arrival.IATALocationCode }}</span>
                </p>
                <div class="date-hours-container">
                  <div class="date-hours" *ngIf="isLast">
                    <div class="date">
                      <div class="schedules">
                        <div class="schedule">
                          <img src="./assets/svg/calendar-icon.svg" class="calendar-icon" />
                          <span class="date-day">{{
                            trip.Arrival.AircraftScheduledDateTime | localizedDate: "dd"
                          }}</span>
                          <span class="date-month">{{
                            trip.Arrival.AircraftScheduledDateTime | localizedDate: "MMM" | titlecase
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="chip-timing">
                      <div class="schedules">
                        <div class="schedule">
                          <img
                            src="./assets/svg/clock2.svg"
                            class="clock-icon"
                            *ngIf="(commonService.isTabletObservable | async) === false"
                          />
                          <!-- TODO revoir le formatage de l'heure -->
                          <span>{{ trip.Arrival.AircraftScheduledDateTime | slice: 11 : 16 }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="date-hours-container" *ngIf="typeTravel === 'return'">
            <div class="date-hours">
              <div class="date">
                <div class="schedules">
                  <p class="schedule">
                    <img src="./assets/svg/calendar-icon.svg" class="calendar-icon" />
                    <span class="date-day">{{ trip.Dep.AircraftScheduledDateTime | localizedDate: "dd" }}</span>
                    <span class="date-month">{{
                      trip.Dep.AircraftScheduledDateTime | localizedDate: "MMM" | titlecase
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="chip-timing">
                <div class="schedules">
                  <p class="schedule">
                    <img src="./assets/svg/clock2.svg" class="clock-icon" />
                    <!-- TODO revoir le formatage de l'heure -->
                    <span
                      >{{ trip.Dep.AircraftScheduledDateTime | slice: 11 : 16 }} -
                      {{ trip.Arrival.AircraftScheduledDateTime | slice: 11 : 16 }}</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-template #sabreItem>
        <div
          class="legs"
          *ngFor="let leg of trip.legs; let isFirst = first; let isLast = last; let i = index"
          [ngClass]="typeTravel === 'multidesti' ? 'return' : typeTravel"
        >
          <span class="separator-segment" *ngIf="typeTravel === 'multidesti' && !isFirst"></span>

          <div class="infos" [ngClass]="typeTravel === 'multidesti' ? 'return' : typeTravel">
            <ng-container *ngIf="typeTravel === 'return' || typeTravel === 'multidesti'; else other">
              <p class="place" [ngClass]="typeTravel === 'multidesti' ? 'return' : typeTravel">
                <span class="type" *ngIf="typeTravel === 'return' && isFirst" translate>GLOBAL.DEPARTURE</span>
                <span class="type" *ngIf="typeTravel === 'return' && isLast" translate>GLOBAL.RETURN</span>
                <span class="type" *ngIf="typeTravel === 'multidesti' && isFirst" translate
                  >GLOBAL.MULTIDESTINATIONS</span
                >

                <span class="od"
                  ><span class="origin"
                    >{{ this.item.detail.airports[leg.departure.airport]?.city }},
                    <span class="airport">&nbsp;{{ leg.departure.airport }}</span></span
                  >
                  <i class="spt-icon-arrow-circle-right arrow-right"></i>
                  <span class="destination" *ngIf="typeTravel === 'return' || typeTravel === 'multidesti'"
                    >{{ this.item.detail.airports[leg.arrival.airport]?.city }},
                    <span class="airport">&nbsp;{{ leg.arrival.airport }}</span></span
                  ></span
                >
              </p>
            </ng-container>
            <ng-template #other>
              <div class="origin">
                <p class="place">
                  {{ this.item.detail.airports[leg.departure.airport]?.city }},
                  <span class="airport">&nbsp;{{ leg.departure.airport }}</span>
                </p>
                <div class="date-hours-container">
                  <div class="date-hours" *ngIf="isFirst">
                    <div class="date">
                      <div class="schedules">
                        <div class="schedule">
                          <img src="./assets/svg/calendar-icon.svg" class="calendar-icon" />
                          <span class="date-day">{{ leg.departure.date | localizedDate: "dd" }}</span>
                          <span class="date-month">{{ leg.departure.date | localizedDate: "MMM" | titlecase }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="chip-timing">
                      <div class="schedules">
                        <p class="schedule">
                          <img src="./assets/svg/clock2.svg" class="clock-icon" />
                          <span>{{ leg.departure.time | slice: 0 : 5 }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="destination">
                <p class="place">
                  {{ this.item.detail.airports[leg.arrival.airport]?.city }},
                  <span class="airport">&nbsp;{{ leg.arrival.airport }}</span>
                </p>
                <div class="date-hours-container">
                  <div class="date-hours" *ngIf="isLast">
                    <div class="date">
                      <div class="schedules">
                        <div class="schedule">
                          <img src="./assets/svg/calendar-icon.svg" class="calendar-icon" />
                          <span class="date-day">{{ leg.arrival.date | localizedDate: "dd" }}</span>
                          <span class="date-month">{{ leg.arrival.date | localizedDate: "MMM" | titlecase }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="chip-timing">
                      <div class="schedules">
                        <p class="schedule">
                          <img src="./assets/svg/clock2.svg" class="clock-icon" />
                          <span>{{ leg.arrival.time | slice: 0 : 5 }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="date-hours-container" *ngIf="typeTravel === 'return' || typeTravel === 'multidesti'">
            <div class="date-hours">
              <div class="date">
                <div class="schedules">
                  <p class="schedule">
                    <img src="./assets/svg/calendar-icon.svg" class="calendar-icon" />
                    <span class="date-day">{{ leg.departure.date | localizedDate: "dd" }}</span>
                    <span class="date-month">{{ leg.departure.date | localizedDate: "MMM" | titlecase }}</span>
                  </p>
                </div>
              </div>
              <div class="chip-timing">
                <div class="schedules">
                  <p class="schedule">
                    <img src="./assets/svg/clock2.svg" class="clock-icon" />
                    <span>{{ leg.departure.time | slice: 0 : 5 }} - {{ leg.arrival.time | slice: 0 : 5 }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
