<div class="conditions-container" *ngIf="item.formData.rooms?.length > 0">
  <p class="title">{{ "GLOBAL.CONDITIONS" | translate }}</p>
  <ng-container *ngFor="let room of item.formData.rooms; let idx = index">
    <p class="traveler">{{ room.RoomDescription }}</p>
    <div class="conditions-content" *ngIf="dataHotel">
      <ng-container *ngIf="dataHotel.cancellationPolicy[0]; else norefund">
        <ul>
          <li *ngIf="dataHotel.cancellationPolicy[0].cfps[0]">
            {{ "SEARCH.RESULT.HOTEL.FILTERS.CANCELLABLE" | translate }}
          </li>
          <li *ngIf="dataHotel.cancellationPolicy[0].cfps[0]">
            {{ "GLOBAL.CATEGORY.REFUNDABLE" | translate }}
          </li>
          <li *ngIf="!dataHotel.cancellationPolicy[0].cfps[0]">
            {{ "SEARCH.RESULT.HOTEL.REFUNDS.NO_REFUND" | translate }}
          </li>
        </ul>
        <div class="conditions-content-detail">
          <strong>{{ dataHotel.cancellationPolicy[0].cfps[0] | refundText: dataHotel.datein : amount }}</strong>
          <ng-container *ngIf="dataHotel.contractRemarks">
            <ng-container *ngIf="!this.more; else seeMore">
              <p *ngFor="let contractRemark of dataHotel.contractRemarks" [innerHTML]="contractRemark"></p>
            </ng-container>
            <ng-template #seeMore>
              <p>
                <span [innerHTML]="this.contractRemarks"></span>
                <span (mousedown)="openModalContractRemarks()" class="see-more">
                  {{ "GLOBAL.SEE_MORE" | translate }}</span
                >
              </p>
            </ng-template>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #norefund>
        <ul>
          <li>
            {{ "SEARCH.RESULT.HOTEL.REFUNDS.NO_REFUND" | translate }}
          </li>
        </ul>
      </ng-template>
    </div>
    <p-dialog
      *ngIf="this.see"
      appendTo="body"
      [(visible)]="this.see"
      [modal]="true"
      [responsive]="true"
      [header]="'GLOBAL.CONDITIONS' | translate"
    >
      <div class="description">
        <ng-container *ngFor="let contractRemark of dataHotel.contractRemarks">
          <p [innerHTML]="contractRemark"></p>
        </ng-container>
      </div>
    </p-dialog>
  </ng-container>
</div>
