import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "spt-travel-hotel-page-commentaries",
  styleUrls: ["./commentaries.component.scss"],
  templateUrl: "./commentaries.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class CommentariesComponent implements OnInit {
  @Output() outdatedInformations: EventEmitter<any> = new EventEmitter();
  private _reviews;
  private _accomcode;
  private reviewsCanBeUpdated: boolean = false;
  @Input() set accomcode(value: string) {
    this._accomcode = value;
    this.reviewsCanBeUpdated = true;
  }
  get accomcode() {
    return this._accomcode;
  }
  @Input() set reviews(values: any[]) {
    this._reviews = values;
    if (this.reviewsCanBeUpdated === true) {
      this.updateReviews(values);
      this.reviewsCanBeUpdated = false;
    }
  }

  get reviews() {
    return this._reviews;
  }
  ngOnInit() {}

  updateReviews(values: any[]) {
    this.reviewsCanBeUpdated = false;

    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 1);
    if (values.find((value) => value.time * 1000 > sixMonthsAgo.getTime()) === undefined) {
      this.outdatedInformations.emit(true);
    }
  }
}
