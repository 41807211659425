<ng-container *ngFor="let itinerary of this.itineraries">
  <div class="legs">
    <div *ngFor="let leg of itinerary.legs; let tripIndex = index" class="leg">
      <spt-travel-flight-itinerary-leg
        [departureDate]="leg.schedules[0].departure.date"
        [baggageIncluded]="itinerary.baggageIncluded"
        [carryOnIncluded]="itinerary.carryOnIncluded"
        [carbonOffset]="itinerary?.carbonOffset"
        [leg]="leg"
        class="hideInformations"
      ></spt-travel-flight-itinerary-leg>
      <div class="company">
        <span>{{ "SEARCH.RESULT.AIR.SOLD_BY" | translate }} {{ companies[leg.governingCarrier]?.name }}</span>
        <img class="icon" src="https://static.misterfly.com:443/images/airlines/{{ leg.governingCarrier }}.png" />
      </div>
    </div>
  </div>
  <div class="warning" *ngIf="airportsDifferents" translate>SEARCH.AIR.AIRPORTS_WARNING</div>
  <div class="charges" *ngIf="itineraries?.length > 1">
    <div class="title">{{ "SEARCH.RESULT.TOTAL" | translate }}:</div>

    <div class="price">
      {{
        {
          amount: itinerary.price ? itinerary.price : itinerary.totalPrice,
          currency: "EUR"
        }
          | SPTPrice
          | async
      }}
      <span class="out-policy" *ngIf="itinerary.isOutPolicy" translate>GLOBAL.OUT_OF_POLICY</span>
    </div>
  </div>
</ng-container>
