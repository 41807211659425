<div class="conditions-container" *ngIf="item.detail.trips[0].pricingInformations && item.detail.users.length > 0">
  <div class="title">
    <span>{{ "SEARCH.RESULT.AIR.FILTERS.CONDITIONS" | translate }}</span>
    <span>{{
      "GLOBAL.CATEGORY." +
        (item.detail.trips[0].pricingInformations?.[0].fare.passengerInfos[0].fareComponents[0].cabinCode | uppercase)
        | translate
    }}</span>
  </div>
  <ng-container *ngFor="let user of item.detail.users; let idx = index">
  <ng-container *ngIf="item.provider === 'afkl'; else sabreTrip">
    <spt-conditions-flight-information
      [afklTrip]="item"
      [dateEnd]="dateEnd"
      [index]="idx"
      [user]="user"
    ></spt-conditions-flight-information>
  </ng-container>
  <ng-template #sabreTrip>
    <ng-container *ngFor="let trip of item.detail.trips">
      <ng-container *ngFor="let pricingInformation of trip.pricingInformations">
        <spt-conditions-flight-information
          [pricingInformation]="pricingInformation"
          [dateEnd]="dateEnd"
          [index]="idx"
          [user]="user"
        ></spt-conditions-flight-information>
      </ng-container>
    </ng-container>
  </ng-template>
  </ng-container>
</div>
