import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MailService {
  constructor(private httpClient: HttpClient) {}

  sendInvitation(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      ignoreLoading: "true",
      ignoreLoadingBar: "true",
    });

    return this.httpClient.post(`${environment.api}/mails/${data.type}`, data, {
      headers,
    });
  }

  resetPassword(email: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      ignoreLoading: "true",
      ignoreLoadingBar: "true",
      ignoreErrorMessage: "true",
    });

    return this.httpClient.post(`${environment.api}/mails/forgot-password`, { email }, { headers });
  }
}
