import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import L from "leaflet";
import { CommonService } from "src/app/@shared/services/common.service";

@Component({
  selector: "spt-itinerary-car",
  templateUrl: "./car.component.html",
  styleUrls: ["./car.component.scss"],
})
export class ItineraryCarComponent {
  @Input() item: any;

  @ViewChild("leafletMap", { static: false }) mapElement: ElementRef;

  private map: L.Map = null;
  public mapOtions: L.MapOptions;
  public coordinates: any;

  constructor(
    private sanitizer: DomSanitizer,
    public commonService: CommonService,
  ) {}

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  setExternalLink(coordinates: L.LatLngExpression) {
    const userAgent: string = navigator?.userAgent;

    if (this.commonService.isCordova() === true) {
      if (userAgent && /ipad|iphone|ipod/i.test(userAgent)) {
        this.coordinates = "maps://maps.apple.com/?q=";
      } else {
        this.coordinates = "geo:";
      }
    } else {
      this.coordinates = "https://maps.google.com/?q=";
    }
    if (this.coordinates) {
      // sanitaze for safe url
      this.coordinates += coordinates.toString();
      this.coordinates = this.sanitize(this.coordinates);
    }
  }

  setMapMarker(coordinates: L.LatLngExpression) {
    this.mapOtions = {
      center: L.latLng(coordinates),
      layers: [
        L.tileLayer("http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
          maxZoom: 18,
          attribution: "...",
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }),
      ],
      zoom: 15,
    };

    this.map = L.map(this.mapElement.nativeElement, this.mapOtions);
    const marker: L.Icon = L.icon({
      iconUrl: "./assets/svg/map-purple-icon.svg",
      iconSize: [48, 48],
    });
    L.marker(coordinates, { icon: marker }).addTo(this.map);
    this.setExternalLink(coordinates);
  }
}
