import { Injectable } from "@angular/core";
import { Address } from "src/app/@shared/@types/models";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { UntypedFormGroup } from "@angular/forms";
import { RequestService } from "src/app/@shared/services/request.service";
import { MessageService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "src/app/@shared/services/common.service";
import { HttpClient } from "@angular/common/http";
import {
  DateTimeSearch,
  SearchCriteria,
  SearchOptions,
  SearchResult,
  TimeRange,
  Travel,
  WhenSearch,
} from "../search-engine-service";
import { AbstractPlaceSearchService } from "../abstract-place-search.service";
import { MemberSociety } from "src/app/@shared/@types/society";

@Injectable({
  providedIn: "root",
})
export class SearchTransferService extends AbstractPlaceSearchService<TransferSearchOptions> {
  getType(): "transfer" {
    return "transfer";
  }
  transferForm: UntypedFormGroup;
  constructor(
    private requestService: RequestService,
    private messageService: MessageService,
    private translateService: TranslateService,
    public commonService: CommonService,
    httpClient: HttpClient,
  ) {
    super(httpClient);
  }

  getMaxPassengers(searchCriteria: SearchCriteria<Address, TransferSearchOptions>): number {
    return -1;
  }

  launchSearch(searchCriteria: SearchCriteria<Address, TransferSearchOptions>): Observable<SearchResult> {
    // Les passagers indiqués dans SearchCriteria#mainTravel.people ne sont mis qu'à titre arbitraire pour valider le SearchCriteria
    // cf. SearchSeminarComponent#constructor

    let data = {
      travels: searchCriteria.travels.map((t) => {
        t.people = [];
        return t;
      }),
      personsNumber: searchCriteria.options.personsNumber,
      reference: searchCriteria.options.reference,
      name: searchCriteria.options.name,
      phone: searchCriteria.options.phone,
      type: searchCriteria.options.kind,
      comment: searchCriteria.options.comment,
    };
    return this.requestService.create(data, "transfer").pipe(
      tap((result) => {
        if (result) {
          this.messageService.add({
            severity: "success",
            summary: this.translateService.instant("SEARCH.TRANSFER.SENT"),
          });
        }
      }),
    );
  }

  createBlankCriteria(): SearchCriteria<Address, TransferSearchOptions> {
    return new SearchCriteria<Address, TransferSearchOptions>(
      new TransferSearchOptions(),
      new Travel<Address>(
        [],
        new WhenSearch(new DateTimeSearch("", new TimeRange())),
        this.createLocalityFromJson({}),
        this.createLocalityFromJson({}),
      ),
    );
  }

  createBlankOptions(): TransferSearchOptions {
    throw new TransferSearchOptions();
  }

  createCriteriaFromPreviousSearch(previousSearch?: any): SearchCriteria<Address, TransferSearchOptions> {
    if (!previousSearch) {
      return undefined;
    }
    throw new Error("Method not implemented.");
  }
  createCriteriaFromBasketItem(
    lastFolderItemsInBasket: any[],
    members: MemberSociety[],
  ): SearchCriteria<Address, TransferSearchOptions> {
    const basketItem = lastFolderItemsInBasket?.[0];
    if (!basketItem) {
      return undefined;
    }
    throw new Error("Method not implemented.");
  }
}

export class TransferSearchOptions implements SearchOptions {
  kind: string = "";
  personsNumber: number;
  reference?: string;
  name: string;
  phone: string;
  comment?: string;

  isValid(): boolean {
    return (
      this.kind.trim().length > 0 &&
      this.personsNumber > 0 &&
      this.name.trim().length > 0 &&
      this.phone.trim().length > 0
    );
  }
}
