import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TrainTypes } from "../../../../../travel/train/train";
import { CommonService } from "../../../../services/common.service";

@Component({
  selector: "spt-conditions-train-dialog",
  templateUrl: "./conditions-train.component.html",
  styleUrls: ["./conditions-train.component.scss"],
})
export class ConditionsTrainComponent {
  @Input() name: string;
  @Input() conditions: Array<TrainTypes.Condition>;
  @Input() conditionsSummary: string;
  @Input() routeRestriction: string;
  @Input() visible: boolean;
  @Output() closeConditions: EventEmitter<void> = new EventEmitter();

  constructor(public commonService: CommonService) {}

  onHide(): void {
    this.closeConditions.emit();
  }
}
