import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SelectItem } from "primeng/api";
import { DateTimeSearch, SearchCriteria, SearchResult, Travel, WhenSearch } from "../search-engine-service";
import { Address } from "src/app/@shared/@types/models";
import moment from "moment";
import { Subscription } from "rxjs";
import { SearchTransferService, TransferSearchOptions } from "./search-transfer.service";
import { TranslateService } from "@ngx-translate/core";
import { SocietyService } from "src/app/@shared/services/society.service";
import { SuggestPanelService } from "../common/suggest-panel.service";
import { CommonService } from "src/app/@shared/services/common.service";

@Component({
  selector: "spt-search-transfer",
  templateUrl: "./search-transfer.component.html",
  styleUrls: ["./search-transfer.component.scss"],
})
export class SearchTransferComponent {
  searchEngineType: string;
  transferKindOptions: Array<SelectItem> = [];
  gridTemplateAreas!: string;
  gridTemplateRows!: string;

  @Input() searchCriteria: SearchCriteria<Address, TransferSearchOptions>;
  isValid: boolean = false;
  now: Date = moment().startOf("day").toDate();
  yearRange: string = `${this.now.getFullYear()}:${this.now.getFullYear() + 10}`;
  travels: Travel<Address>[];
  mainTravel: Travel<Address>;

  private observablesToClearOnDestroy: Subscription[] = [];
  suggestOpened: boolean = false;

  constructor(
    private searchService: SearchTransferService,
    private translateService: TranslateService,
    private societyService: SocietyService,
    protected suggestPanelService: SuggestPanelService,
    // utilisé dans le template
    protected commonService: CommonService,
  ) {
    this.searchEngineType = searchService.getType();
  }

  ngOnInit(): void {
    if (!this.searchCriteria) {
      this.searchCriteria = this.searchService.createBlankCriteria();
    }

    // On indique un membre, juste pour rendre valide le SearchCriteria.
    // Les passagers ne sont pas utilisés dans SearchTransferComponent#launchSearch
    this.searchCriteria.mainTravel.people.push(this.societyService.society.value.members[0]);

    // Pas de favoris sur "Transfert", mais un "écran d'accueil"
    this.suggestPanelService.init("transfer-welcome");
    this.observablesToClearOnDestroy.push(this.suggestPanelService.onChange.subscribe((panel) => {}));
    this.searchCriteria.mainTravel.when.inward = undefined;
    this.transferKindOptions.push(
      {
        label: this.translateService.instant("SEARCH.TRANSFER.TYPE.ECONOMICAL"),
        value: "economical",
      },
      {
        label: this.translateService.instant("SEARCH.TRANSFER.TYPE.SEDAN"),
        value: "sedan",
      },
      {
        label: this.translateService.instant("SEARCH.TRANSFER.TYPE.VAN"),
        value: "van",
      },
    );
  }

  ngOnDestroy(): void {
    this.observablesToClearOnDestroy.forEach((o) => o.unsubscribe());
    this.observablesToClearOnDestroy.length = 0;
  }
  private refreshGridTemplateAreas(): void {
    const gridTemplateAreasResult: string[] = ["title  suggest", "trip-0   suggest"];
    const gridTemplateAreasRows: string[] = ["auto", "auto"];
    this.searchCriteria.travels.forEach((item, index) => {
      gridTemplateAreasResult.push(
        "trip-" + index + " suggest",
        "origin-" + index + "  suggest",
        "destination-" + index + "  suggest",
        "datetime-" + index + "  suggest",
      );
      gridTemplateAreasRows.push("auto", "auto", "auto", "auto");
    });
    gridTemplateAreasResult.push("addTrip  suggest");
    gridTemplateAreasRows.push("auto");
    gridTemplateAreasResult.push("options   suggest", "triggers  suggest");
    gridTemplateAreasRows.push("auto", "auto", "1fr");
    this.gridTemplateAreas = gridTemplateAreasResult.map((l) => "'" + l + "'").join("\n");
    this.gridTemplateRows = gridTemplateAreasRows.join(" ");
  }
  activateSuggest(suggestToActivate: string) {
    this.suggestPanelService.activate(suggestToActivate);
    this.suggestOpened = true;
  }

  @Output()
  onSearchResult: EventEmitter<SearchResult> = new EventEmitter<SearchResult>();

  addTravel() {
    this.searchCriteria.travels.push(
      new Travel<Address>(
        this.searchCriteria.mainTravel.people,
        new WhenSearch(new DateTimeSearch()),
        this.searchService.createDummyLocalityFromName(""),
        this.searchService.createDummyLocalityFromName(""),
      ),
    );
    this.refreshGridTemplateAreas();
    this.checkValidity();
  }
  search(): void {
    this.searchService
      .launchSearch(this.searchCriteria)
      .subscribe((searchResult: SearchResult) => this.onSearchResult.emit(searchResult));
  }
  removeTravel(idx: number) {
    if (!this.travels[idx]) {
      throw new Error("Travel index not found: " + idx);
    }
    if (this.travels.length === 1 && idx === 0) {
      throw new Error("mainTravel can not be removed");
    }
    this.travels.splice(idx, 1);
    if (idx === 0) {
      this.mainTravel = this.travels[0];
    }
  }
  removeTrip(index: number): void {
    this.searchCriteria.removeTravel(index);
    this.refreshGridTemplateAreas();
    this.checkValidity();
  }
  resetSuggestPanel(): void {
    this.suggestPanelService.reset();
    this.suggestOpened = false;
  }

  checkValidity() {
    this.isValid = this.searchService.searchCriteriaIsValid(this.searchCriteria);
  }
}
