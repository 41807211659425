import { animate, state, style, transition, trigger } from "@angular/animations";
import { AfterViewInit, Component, ElementRef, HostBinding, Input } from "@angular/core";
import { fromEvent, Observable } from "rxjs";
import { distinctUntilChanged, filter, map, pairwise, share, throttleTime } from "rxjs/operators";

enum Direction {
  Up = "Up",
  Down = "Down",
}

enum VisibilityState {
  Visible = "visible",
  Hidden = "hidden",
}

@Component({
  selector: "spt-sticky-header",
  template: `<ng-content></ng-content>`,
  styles: [
    `
      :host {
        position: fixed;
        width: 100%;
      }
    `,
  ],
  animations: [
    trigger("toggle", [
      state(VisibilityState.Hidden, style({ opacity: 0, transform: "translateY(-100%)" })),
      state(VisibilityState.Visible, style({ opacity: 1, transform: "translateY(0)" })),
      transition("* => *", animate("200ms ease-in")),
    ]),
  ],
})
export class StickyHeaderComponent implements AfterViewInit {
  private isVisible: boolean = true;
  private _childElement: string;
  @Input() scrollRef: ElementRef;
  @Input() set childElement(value: string) {
    this._childElement = value;
    this.run();
  }
  get childElement(): string {
    return this._childElement;
  }
  private element: any;

  @HostBinding("@toggle")
  get toggle(): VisibilityState {
    return this.isVisible ? VisibilityState.Visible : VisibilityState.Hidden;
  }

  ngAfterViewInit(): void {
    this.run();
  }

  run(): void {
    if (this.childElement) {
      this.element = this.scrollRef?.nativeElement?.querySelector(this.childElement);
    } else {
      this.element = this.scrollRef?.nativeElement;
    }
    if (this.element) {
      const scroll$: Observable<Direction> = fromEvent(this.element, "scroll").pipe(
        throttleTime(10),
        map(() => {
          return this.element.scrollTop;
        }),
        pairwise(),
        filter(([y1, y2]) => y2 > y1 || y1 - y2 > 30 || y1 < 80 || y2 < 80),
        map(([y1, y2]): Direction => {
          if (y1 < 80 || y2 < 80) {
            return Direction.Up;
          } else {
            return y2 < y1 ? Direction.Up : Direction.Down;
          }
        }),
        distinctUntilChanged(),
        share(),
      );

      const scrollUp$: Observable<Direction> = scroll$.pipe(filter((direction) => direction === Direction.Up));

      const scrollDown$: Observable<Direction> = scroll$.pipe(filter((direction) => direction === Direction.Down));

      scrollUp$.subscribe(() => (this.isVisible = true));
      scrollDown$.subscribe(() => (this.isVisible = false));
    }
  }
}
