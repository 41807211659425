<spt-loading-v2 [auto]="true"></spt-loading-v2>

<div
  class="header grid grid-nogutter align-items-end flex-column"
  *ngIf="(commonService.isTabletObservable | async) === false && (commonService.isTabletObservable | async) === false"
>
  <div class="soft-grey txt-15 f-sm" translate>LOGIN.NO_ACCOUNT</div>
  <div class="soft-grey txt-15 f-sm" translate>
    LOGIN.CONTACT.US
    <a href="mailto:account@supertripper.com">account@supertripper.com</a>
  </div>
  <!-- <p-button styleClass='p-button-secondary p-button-rounded' type='button' label="{{ 'LOGIN.SIGNUP' | translate }}" (click)="subscribePage()"></p-button> -->
</div>

<div class="container col flex-column">
  <div class="stepper">
    <div class="stepper-container">
      <div class="step-bar"></div>
      <div
        class="step-bar progress"
        [class.progress-s1]="needPassword || !needPassword"
        [class.progress-s2]="needPassword"
        [class.progress-s3]="pendingConnection"
      ></div>
      <div class="step">
        <div class="circle" [class.active]="needPassword || !needPassword">
          <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 19C1 15.134 4.13401 12 8 12C11.866 12 15 15.134 15 19"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 9C10.2091 9 12 7.20914 12 5C12 2.79086 10.2091 1 8 1C5.79086 1 4 2.79086 4 5C4 7.20914 5.79086 9 8 9Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="step-title" translate>LOGIN.STEPPER.FIRST_STEP</div>
      </div>
      <div class="step">
        <div class="circle" [class.active]="needPassword">
          <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 9C1 8.05719 1 7.58579 1.29289 7.29289C1.58579 7 2.05719 7 3 7H15C15.9428 7 16.4142 7 16.7071 7.29289C17 7.58579 17 8.05719 17 9V17C17 17.9428 17 18.4142 16.7071 18.7071C16.4142 19 15.9428 19 15 19H3C2.05719 19 1.58579 19 1.29289 18.7071C1 18.4142 1 17.9428 1 17V9Z"
              stroke="white"
              stroke-width="2"
            />
            <path
              d="M13 7V5C13 2.79086 11.2091 1 9 1V1C6.79086 1 5 2.79086 5 5V7"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </div>
        <div class="step-title" translate>LOGIN.STEPPER.SECOND_STEP</div>
      </div>
      <div class="step">
        <div class="circle" [class.active]="pendingConnection">
          <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.3386 22.3796L11.6937 23.3145H11.6937L11.3386 22.3796ZM10.6615 22.3796L10.3065 23.3145H10.3065L10.6615 22.3796ZM2.83341 2.5H19.1667V0.5H2.83341V2.5ZM19.3334 2.66667V9.859H21.3334V2.66667H19.3334ZM2.66675 9.859V2.66667H0.666748V9.859H2.66675ZM19.3334 9.859C19.3334 17.783 12.6105 20.8269 10.9836 21.4448L11.6937 23.3145C13.4166 22.6601 21.3334 19.1585 21.3334 9.859H19.3334ZM0.666748 9.859C0.666748 19.1585 8.58352 22.6601 10.3065 23.3145L11.0166 21.4448C9.38967 20.8269 2.66675 17.783 2.66675 9.859H0.666748ZM10.9836 21.4448C10.9826 21.4452 10.9839 21.4446 10.9872 21.444C10.9906 21.4433 10.9951 21.4429 11.0001 21.4429C11.0051 21.4429 11.0095 21.4433 11.0129 21.444C11.0163 21.4446 11.0176 21.4452 11.0166 21.4448L10.3065 23.3145C10.7571 23.4856 11.243 23.4856 11.6937 23.3145L10.9836 21.4448ZM19.1667 2.5C19.2588 2.5 19.3334 2.57462 19.3334 2.66667H21.3334C21.3334 1.47005 20.3634 0.5 19.1667 0.5V2.5ZM2.83341 0.5C1.6368 0.5 0.666748 1.47005 0.666748 2.66667H2.66675C2.66675 2.57462 2.74137 2.5 2.83341 2.5V0.5Z"
              fill="white"
            />
            <path
              d="M15.2071 9.20711C15.5976 8.81658 15.5976 8.18342 15.2071 7.79289C14.8166 7.40237 14.1834 7.40237 13.7929 7.79289L15.2071 9.20711ZM9.83333 13.1667L9.12623 13.8738C9.31376 14.0613 9.56812 14.1667 9.83333 14.1667C10.0985 14.1667 10.3529 14.0613 10.5404 13.8738L9.83333 13.1667ZM8.20711 10.1262C7.81658 9.7357 7.18342 9.7357 6.79289 10.1262C6.40237 10.5168 6.40237 11.1499 6.79289 11.5404L8.20711 10.1262ZM13.7929 7.79289L9.12623 12.4596L10.5404 13.8738L15.2071 9.20711L13.7929 7.79289ZM10.5404 12.4596L8.20711 10.1262L6.79289 11.5404L9.12623 13.8738L10.5404 12.4596Z"
              fill="white"
            />
          </svg>
        </div>
        <div class="step-title" translate>LOGIN.STEPPER.THIRD_STEP</div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="commonService.browserSupported; else unsupportedBrowser">
    <div class="form-title">
      <h1 class="txt-25 m-0" translate>LOGIN.LOGIN_MESSAGE</h1>
      <span class="soft-grey txt-18" translate>LOGIN.LOGIN_SUB_MESSAGE</span>
    </div>
    <form
      [formGroup]="loginForm"
      (ngSubmit)="needPassword ? login() : checkEmail()"
      class="form-group"
      [hidden]="loading"
    >
      <!-- <div class="p-inputgroup"> -->
      <div class="m-t-20" [hidden]="needPassword">
        <div class="grid grid-nogutter justify-content-between align-items-center">
          <label class="labelinput" translate>ACCOUNT.GENERAL.EMAIL</label>
          <a (click)="goToResetPass()" class="txt-14" translate>LOGIN.FORGOT_PASSWORD</a>
        </div>
        <div class="flex w-full items-center" style="gap: 20px">
          <input
            class="iosInputFix"
            name="email"
            required
            pInputText
            formControlName="user"
            type="email"
            autocomplete="true"
          />
          <img
            *ngIf="biometricAvailableAndSet"
            (click)="checkBiometricSecret()"
            [src]="'./assets/svg/' + biometricType + '.svg'"
            alt="biometric login"
            class="biometric-icon-container"
          />
        </div>
      </div>
      <!-- </div> -->
      <!-- <div class="p-inputgroup"> -->
      <div class="m-t-20" [hidden]="!needPassword">
        <div class="grid grid-nogutter justify-content-between align-items-center">
          <label class="labelinput" translate>LOGIN.PASSWORD</label>
          <a (click)="goToResetPass()" class="txt-14" translate>LOGIN.FORGOT_PASSWORD</a>
        </div>
        <div class="flex w-full items-center" style="gap: 20px">
          <div class="p-inputgroup align-items-center">
            <input
              class="iosInputFix"
              pInputText
              formControlName="password"
              [type]="this.displayPassword ? 'text' : 'password'"
              #passwordInput
            />
            <span class="p-inputgroup-addon" (click)="changeStatutPassword()"><i class="fas fa-eye"></i></span>
          </div>
          <img
            *ngIf="biometricAvailableAndSet"
            (click)="checkBiometricSecret()"
            [src]="'./assets/svg/' + biometricType + '.svg'"
            alt="biometric login"
            class="biometric-icon-container"
          />
        </div>
      </div>
      <div class="biometric-container" *ngIf="biometricAvailable" >
        <div class="biometric-toggle-container">
          <p-inputSwitch aria-labelledby="biometric" formControlName="biometric"> </p-inputSwitch>
          <label for="biometric">{{ "LOGIN.BIOMETRIC_LOGIN_TOGGLE" | translate }}</label>
        </div>
      </div>
      <!-- </div> -->
      <div class="grid grid-nogutter align-items-end justify-content-end">
        <p-button
          type="submit"
          *ngIf="!needPassword"
          label="{{ 'LOGIN.NEXT' | translate }}"
          class="m-t-30"
          [disabled]="loginForm.controls['user'].invalid"
        ></p-button>
      </div>
      <div class="grid grid-nogutter align-items-end justify-content-between">
        <ng-container *ngIf="needPassword">
          <p (mousedown)="editEmail()" translate>GLOBAL.RETURN</p>
          <p-button
            type="submit"
            *ngIf="needPassword"
            label="{{ 'LOGIN.SIGNIN' | translate }}"
            class="m-t-30"
            [disabled]="loginForm.invalid"
          ></p-button>
        </ng-container>
      </div>
    </form>

    <div *ngIf="loading">
      <spt-loading-v2></spt-loading-v2>
    </div>
  </ng-container>

  <ng-template #unsupportedBrowser>
    <a class="unsupported-browser" href="https://www.google.com/chrome/" target="_blank">
      {{ "LOGIN.UNSUPPORTED_BROWSER" | translate }}
    </a>
  </ng-template>
</div>
