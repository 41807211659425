<div class="container">
  <h1>Ajoutez des options à votre voyage</h1>
  <div class="options-container">
    <div class="option-item">
      <div class="icon">
        <img src="./assets/svg/add-seat-icon.svg" class="transport-icon" > 
      </div>
      <div class="details">
        <div class="selectedOption" *ngIf="seatMapUsers?.length > 0 && seatSelected; else chooseSeat">
          <div
            class="user"
            *ngFor="let seatMapdUser of seatMapUsers"
            [class.selected]="selectedUser?.PaxID === seatMapdUser.PaxID"
            (click)="selectUser(seatMapdUser)"
          >
            <div class="number">
              <ng-container *ngIf="seatMapdUser.seat; else noSeatSelected">
                {{ seatMapdUser.seat?.rowNumber }} {{ seatMapdUser.seat?.ColumnID }}
              </ng-container>
              <ng-template #noSeatSelected>--</ng-template>
            </div>
            {{ seatMapdUser?.seat?.label }}:
            {{ seatMapdUser?.seat?.price | SPTPrice | async }}
          </div>
        </div>
        <ng-template #chooseSeat>
          <p>Choisir son siège</p>
        <div
          class="choose-ancillaries"
        >
          <img src="./assets/svg/add-ancillaries-icon.svg" class="transport-icon" (click)="selectSeat(this.ancillariesData.item)" >
        </div>
        </ng-template>
      </div>
    </div>
    <div class="option-item">
      <div class="icon">
        <img src="./assets/svg/add-luggage-icon.svg" class="transport-icon">
      </div>
      <div class="details">
        <div class="selectedOption" *ngIf="bagUsers?.length > 0 && bagSelected; else chooseBag">
          <div
            class="user"
            *ngFor="let bagdUser of bagUsers"
            [class.selected]="selectedUser?.PaxID === bagdUser?.PaxID"
            (click)="selectUser(bagdUser)"
          >
            {{ bagdUser?.bag?.label }}:
            {{ bagdUser?.bag?.price | SPTPrice | async }}
          </div>
        </div>
        <ng-template #chooseBag>
          <p>Ajouter un bagage</p>
          <div
            class="choose-ancillaries"
            >
            <img src="./assets/svg/add-ancillaries-icon.svg" class="transport-icon" (click)="addLuggage(this.ancillariesData.item)">
          </div>
        </ng-template>
      </div>
    </div>
    
  </div>
  <div class="actions">
    <div class="cancel">
      <p-button
        (mousedown)="close()"
        label="{{ 'Passer cette étape'  }}"
        styleClass="ui-button-secondary"
        type="button"
      >
      </p-button>
    </div>

    <div class="validation">
      <p-button
        [disabled]="(seatMapUsers === undefined) && (bagUsers === undefined)"
        (mousedown)="validateAncillaries()"
        label="{{ 'Valider les options'}}"
        type="button"
      >
      </p-button>
    </div>
  </div>
</div>