import { Component, OnInit, Input, ViewEncapsulation, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { CommonService } from "../../services/common.service";
import { LoadingBarService } from "@ngx-loading-bar/core";

@Component({
  selector: "spt-loading-v2",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LoadingV2Component implements OnInit, OnDestroy {
  @ViewChild("loadingBar") loadingBar: ElementRef;
  @Input() public message: string;
  @Input() auto: boolean = false;

  constructor(
    public commonService: CommonService,
    public loader: LoadingBarService,
  ) {
    this.loader.useRef("default").set(0);
    this.loader.useRef("http").set(0);
  }

  ngOnInit(): void {
    if (this.auto === false) {
      this.loader.useRef().start();
    }
  }

  ngOnDestroy(): void {
    if (this.auto === false) {
      this.loader.useRef().set(100);
      this.loader.useRef().complete();
    }
  }
}
