<div class="search-content" [ngClass]="type">
  <!--
    'tabindex="-1"' : pour éviter l'autofocus sur le premier champs de formulaire à l'ouverture de la popup, on rend focussable un élément
    avant le spt-locality-autocomplete pour éviter l'ouverture automatique de la 2ème popup
  -->
  <button type="button" class="closeModalButton fas fa-times fa-2x m-l-auto" (mousedown)="closeModal()"></button>
  <ng-container [ngSwitch]="type">
    <spt-search-car
      *ngSwitchCase="'car'"
      [searchCriteria]="searchCriteria"
      (onSearchResult)="showSearchResult($event)"
    ></spt-search-car>
    <spt-search-transfer
      *ngSwitchCase="'transfer'"
      [searchCriteria]="searchCriteria"
      (onSearchResult)="showSearchResult($event)"
    ></spt-search-transfer>
    <spt-search-flight
      *ngSwitchCase="'flight'"
      [searchCriteria]="searchCriteria"
      (onSearchResult)="showSearchResult($event)"
    ></spt-search-flight>
    <spt-search-hotel
      *ngSwitchCase="'hotel'"
      [searchCriteria]="searchCriteria"
      (onSearchResult)="showSearchResult($event)"
    ></spt-search-hotel>
    <spt-search-seminar
      *ngSwitchCase="'seminar'"
      [searchCriteria]="searchCriteria"
      (onSearchResult)="showSearchResult($event)"
    ></spt-search-seminar>
    <spt-search-train
      *ngSwitchCase="'train'"
      [searchCriteria]="searchCriteria"
      (onSearchResult)="showSearchResult($event)"
    ></spt-search-train>
  </ng-container>
</div>
