import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

export enum FolderStatus {
  CREATED = "created",
  CONFIRMED = "confirmed",
  CANCELLED = "cancelled",
  ERROR = "error",
}

@Injectable({
  providedIn: "root",
})
export class FolderService {
  constructor(private httpClient: HttpClient) {}

  list(folderStatus: Array<FolderStatus>, startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get(`${environment.api}/folders/list`, {
      params: {
        status: folderStatus.join(","),
        startDate,
        endDate,
      },
    });
  }

  /**
   * Renvoi le timestamp de la date de départ d'un item du folder
   * @param _item
   * @returns
   */
  public static getItemOriginTimestamp(_item: any): number {
    switch (_item.type) {
      case "flight":
        if (_item.provider === "afkl") {
          return Date.parse(_item.detail.trips[0].Dep.AircraftScheduledDateTime);
        } else {
          return Date.parse(
            _item.detail.trips[0].legs[0].departureDatetime ||
              _item.detail.trips[0].legs[0].departureDate + "T00:00:00Z" ||
              _item.detail.trips[0].legs[0].departure.date + "T00:00:00Z",
          );
        }
      case "train":
        return Date.parse(
          _item.detail.journeys[0].departure.date.utc || _item.detail.journeys[0].departure.date + "T00:00:00Z",
        );
      case "hotel":
        return Date.parse(_item.detail.datein.utc || _item.detail.datein.date + "T00:00:00Z");
      case "car":
        return Date.parse(_item.detail.pickupDate.utc || _item.detail.pickupDate.date + "T00:00:00Z");
    }
    return NaN;
  }

  static sortFolderItems(_itemA: any, _itemB: any): number {
    return FolderService.getItemOriginTimestamp(_itemA) - FolderService.getItemOriginTimestamp(_itemB);
  }
}
