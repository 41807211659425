import { RouterModule, Routes } from "@angular/router";
import { ModuleWithProviders } from "@angular/core";
import { SignInComponent } from "./sign-in/sign-in.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { PasswordResetComponent } from "./password-reset/password-reset.component";
import { AuthComponent } from "./auth.component";
import { ActiveAccountComponent } from "./active-account/active-account.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";

const routes: Routes = [
  {
    path: "auth",
    component: AuthComponent,
    children: [
      { path: "", component: SignInComponent },
      { path: "sign-up", component: SignUpComponent },
      { path: "password-reset/:email", component: PasswordResetComponent },
      { path: "activate/:token/:user-id", component: ActiveAccountComponent },
      { path: "resetpassword/:jwt/:id", component: ResetPasswordComponent },
    ],
  },
  {
    path: "activate/:token/:user-id",
    redirectTo: "auth/activate/:token/:user-id",
  },
  { path: "resetpassword/:jwt/:id", redirectTo: "auth/resetpassword/:jwt/:id" },
];

export const AuthRoutingModule: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
