import { ScrollSectionDirective } from "./scroll-section.directive";
import { Directive } from "@angular/core";

@Directive({
  selector: "[appScrollManager]",
})
export class ScrollManagerDirective {
  private sections: Map<string | number, ScrollSectionDirective> = new Map();

  scroll(id: string | number): void {
    this.sections.get(id).scroll();
  }

  register(section: ScrollSectionDirective): void {
    this.sections.set(section.id, section);
  }

  remove(section: ScrollSectionDirective): void {
    this.sections.delete(section.id);
  }
}
