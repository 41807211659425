<div class="container col p-0">
  <ng-container *ngIf="commonService.browserSupported; else unsupportedBrowser">
    <div class="form-title">
      <h1 class="txt-25 m-0" translate>LOGIN.FORGOT_PASSWORD</h1>
      <span class="soft-grey txt-18" translate>LOGIN.FORGOT_PASSWORD_MESSAGE</span>
    </div>
    <form [formGroup]="resetForm" (ngSubmit)="reset()" class="form-group">
      <div class="m-t-20">
        <label class="labelinput" translate>ACCOUNT.GENERAL.EMAIL</label>
        <input class="iosInputFix" pInputText formControlName="email" type="text" />
      </div>
      <div class="btns-container">
        <p-button
          [disabled]="resetForm.invalid || resetting"
          label="{{ 'GLOBAL.CONTINUE' | translate }}"
          class="m-t-30 large"
          type="submit"
        ></p-button>
        <br />
        <a translate (click)="loginPage()">LOGIN.BACK_TO_LOGIN</a>
      </div>
    </form>
  </ng-container>
  <ng-template #unsupportedBrowser>
    <a class="unsupported-browser" href="https://www.google.com/chrome/" target="_blank">
      {{ "LOGIN.UNSUPPORTED_BROWSER" | translate }}
    </a>
  </ng-template>
</div>
