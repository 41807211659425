import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { CommonService } from "src/app/@shared/services/common.service";
import { CarTypes } from "../car";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { removeDuplicates } from "src/app/@shared/utils";

@Component({
  selector: "spt-travel-car-block",
  templateUrl: "./car-block.component.html",
  styleUrls: ["./car-block.component.scss"],
})
export class CarBlockComponent implements OnInit, OnDestroy {
  @Input() car: CarTypes.Car;
  @Input() locale: string;
  @Output() carSelected: EventEmitter<any> = new EventEmitter();

  public expanded: boolean = false;
  public hasCorporateCode: boolean;
  public corporateCodeIndex: number = -1;
  public promote: boolean = false;

  public advantageKeyList: Set<string> = new Set();

  // Open and close hours for agencies
  public pickupDayHourStart: string;
  public pickupDayHourEnd: string;
  public returnDayHourStart: string;
  public returnDayHourEnd: string;
  public Math: Math = Math;

  public avantages: Array<string> = [
    "Annulation gratuite 48h",
    "Carburant équitable",
    "Assurance collision",
    "Protection contre le vol",
    "Kilométrage",
  ];
  private currentLang;
  private subscriptions: Subscription[] = [];
  constructor(
    public commonService: CommonService,
    private translateService: TranslateService,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.filter((s) => !s.closed).forEach((s) => s.unsubscribe());
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang || "fr";
    this.subscriptions.push(this.translateService.onLangChange.subscribe((lang) => (this.currentLang = lang || "fr")));

    this.promote = this.car.offers?.[0]?.hasRsePolicy && this.car.isElectric;
    this.hasCorporateCode = this.car.offers.find((o: any) => o.packageCategory === "ENTERPRISE") !== undefined;
    this.corporateCodeIndex = this.car.offers.findIndex((o: any) => o.packageCategory === "ENTERPRISE");

    this.pickupDayHourStart = this.car.pickup.agency.PickUp.schedule.OperationTimes.OperationTime[0].Start;
    this.pickupDayHourEnd = this.car.pickup.agency.PickUp.schedule.OperationTimes.OperationTime[0].End;

    if (this.car.return) {
      this.returnDayHourStart = this.car.return.agency.DropOff.schedule.OperationTimes.OperationTime[0].Start;
      this.returnDayHourEnd = this.car.return.agency.DropOff.schedule.OperationTimes.OperationTime[0].End;
    } else {
      this.returnDayHourStart = this.car.pickup.agency.DropOff.schedule.OperationTimes.OperationTime[0].Start;
      this.returnDayHourEnd = this.car.pickup.agency.DropOff.schedule.OperationTimes.OperationTime[0].End;
    }
    this.advantageKeyList = new Set(
      this.car.offers
        .flatMap((offer) => offer.advantages)
        .filter((advantage) => advantage.value)
        .map((advantage) => advantage.key)
        .sort((a, b) => a.localeCompare(b)),
    );

    this.car.offers.forEach((offer) =>
      offer.advantages.forEach(
        (advantage) =>
          (advantage.description =
            advantage.description?.[this.currentLang] ||
            advantage.description?.[this.currentLang.toUpperCase()] ||
            advantage.description),
      ),
    );
  }
  selectOffer(car: any, offer: any): void {
    this.carSelected.emit({ car, offer });
  }

  isANumber(val: number): boolean {
    return !isNaN(val);
  }

  transformPrice(price: number): string {
    return price.toString().replace(".", ",");
  }

  get computedOffers(): Array<any> {
    let computedOffers: Array<any> = [];
    computedOffers = [
      this.car.offers.find((o: any) => o.packageCategory === "ENTERPRISE"),
      ...this.car.offers.filter((o: any) => o.packageCategory !== "ENTERPRISE"),
    ].filter((z: any) => z);
    computedOffers.forEach((offer) => {
      offer.advantages.sort((a, b) => a.key.localeCompare(b.key));
    });
    return computedOffers;
  }

  get displayingAddress(): string {
    const addr1: string = this.car.pickup.agency.PickUp.location.Address.AddressLine1;
    const addr2: string = this.car.pickup.agency.PickUp.location.Address.AddressLine2;

    if (addr1 && !addr2) {
      return addr1.toLowerCase();
    }

    if (addr2 && !addr1) {
      return addr2.toLowerCase();
    }

    return addr2.toLowerCase();
  }
}

export interface Advantage {
  key: string;
  description: string;
}
