import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { TrainService } from "../train.service";
import { Subject, zip } from "rxjs";
import { TrainTypes } from "../train";
import { takeUntil } from "rxjs/operators";
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "../../../@shared/services/common.service";
import { round } from "lodash";

@Component({
  selector: "spt-travel-train-seatmap",
  templateUrl: "./seatmap.component.html",
  styleUrls: ["./seatmap.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelTrainSeatMapComponent implements OnInit, OnDestroy {
  seatMaps: TrainTypes.SeatMap[] = [];
  isTablet: boolean;
  noSeatMap: boolean;
  selectedCompartment: number;
  selectedDeck: TrainTypes.DeckType;
  selectedSeats: Array<string> = [];
  compartmentChoices: Array<SelectItem & { availability: number }>;
  deckChoices: Record<number, Array<SelectItem & { availability: number }>>;
  segments: Array<TrainTypes.Segment>;
  allSeatMapSelections: Array<Array<TrainTypes.SeatLocation>>;
  seatMapsLoaded: boolean;
  travelClass: TrainTypes.TravelClass;
  passengerNumber: number;
  selectedSegment: number = 0;
  selectedSeatNumber: number = 0;
  maxSeatsReached: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject();

  constructor(
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private trainService: TrainService,
    private translateService: TranslateService,
    private commonService: CommonService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.commonService.isTabletObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isTablet: boolean): void => {
      this.isTablet = isTablet;
    });
    this.travelClass = this.dynamicDialogConfig.data.travelClass;
    this.segments = this.dynamicDialogConfig.data.segments;
    this.passengerNumber = this.dynamicDialogConfig.data.passengerNumber;

    if (this.dynamicDialogConfig.data.provider === "trainline") {
      this.seatMaps = this.dynamicDialogConfig.data.seatMap;
    } else {
      this.getSeatMap();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  onSeatMapSelected(index: number): void {
    this.selectedSeats = [];
    this.selectedSegment = index;
    this.mapChoicesAndSelectDefaults(index);
    this.changeDetector.markForCheck();
  }

  onSeatSelected(value: Array<string>): void {
    this.selectedSeats = value;
    this.selectedSeatNumber = this.selectedSeats.length;
    this.changeDetector.markForCheck();
  }

  selectCoach(index: number): void {
    this.passengerNumber === 1 ? (this.selectedSeats.length = 0) : null;
    if (
      this.deckChoices[this.selectedCompartment].find(
        (selectItem: SelectItem): boolean => selectItem.value === this.selectedDeck,
      )?.disabled
    ) {
      this.selectedDeck = this.deckChoices[this.selectedCompartment].find(
        (selectItem: SelectItem): boolean => !selectItem.disabled,
      ).value;
    }
    this.travelClass = this.seatMaps[index]?.carriages
      .flatMap((carriage) => carriage.compartments)
      .find((compartment: TrainTypes.Compartment): boolean => compartment.number === this.selectedCompartment)
      ?.travelClass as TrainTypes.TravelClass;
  }

  confirmSelection(): void {
    let mySeatLocations = this.selectedSeats.map(
      (selectedSeat: string): TrainTypes.SeatLocation => ({
        coachNumber: this.selectedCompartment.toString(),
        seatNumber: selectedSeat,
      }),
    );
    this.allSeatMapSelections
      ? this.allSeatMapSelections.push(...[mySeatLocations])
      : (this.allSeatMapSelections = new Array(mySeatLocations));
    if (this.seatMaps.length === this.allSeatMapSelections.length) {
      this.dynamicDialogRef.close(this.allSeatMapSelections.filter((selection) => selection.length > 0));
    } else {
      let index = this.seatMaps.findIndex(
        (seatMap: TrainTypes.SeatMap): boolean => seatMap === this.seatMaps[this.selectedSegment],
      );
      if (index < this.seatMaps.length - 1) {
        this.onSeatMapSelected(index + 1);
      }
    }
  }

  private mapChoicesAndSelectDefaults(num: number): void {
    if (!this.seatMaps[num]) {
      return;
    }

    const compartments = this.seatMaps[num].carriages
      .flatMap((carriage) => carriage.compartments)
      .filter((compartment) => compartment.type === "PASSENGER");

    this.compartmentChoices = compartments.map((compartment) => ({
      availability: compartment.totalAvailableSeats,
      label: `${this.translateService.instant("SEARCH.RESULT.RAIL.SEAT_PREFERENCES.COACH")} ${compartment.number}`,
      disabled: this.travelClass !== compartment.travelClass || compartment.totalAvailableSeats === 0,
      value: compartment.number,
    }));

    this.deckChoices = Object.fromEntries(
      compartments.map((compartment) => [
        compartment.number,
        compartment.decks.map((deck) => ({
          availability: deck.totalAvailableSeats,
          label: `${this.translateService.instant(`SEARCH.RESULT.RAIL.SEAT_PREFERENCES.DECK_${deck.deckType}`)}`,
          disabled: deck.full,
          value: deck.deckType,
        })),
      ]),
    );

    const indexCarriageWithAvailableSeats = this.seatMaps[num].carriages.findIndex((carriage) =>
      carriage.compartments.some((compartment) => compartment.totalAvailableSeats !== 0),
    );

    if (indexCarriageWithAvailableSeats !== -1) {
      const selectedCarriage = this.seatMaps[num].carriages[indexCarriageWithAvailableSeats];
      this.travelClass = selectedCarriage.compartments[0].travelClass as TrainTypes.TravelClass;
      this.selectedCompartment = selectedCarriage.compartments.find(
        (compartment) => compartment.totalAvailableSeats !== 0,
      ).number;

      const availableDecks = selectedCarriage.compartments
        .find((compartment) => compartment.number === this.selectedCompartment)
        ?.decks?.filter((deck) => deck.totalAvailableSeats > 0);

      if (availableDecks && availableDecks.length > 0) {
        this.selectedDeck = availableDecks.find((deck) => !deck.full)?.deckType ?? "BOTTOM";
      }
    }

    this.changeDetector.markForCheck();
    this.stretchAndShrinkElement(num);
  }

  private getSeatMap(): void {
    if (this.dynamicDialogConfig.data.seatMap) {
      const observables = this.dynamicDialogConfig.data.seatMap.map((seatmap: string) => {
        return this.trainService.getSeatMap(seatmap);
      });

      zip(...observables).subscribe({
        next: (seatMaps: TrainTypes.SeatMap[]): void => {
          this.seatMapsLoaded = true;
          seatMaps.forEach((seatMap: TrainTypes.SeatMap) => {
            let newIndex = this.seatMaps.push(seatMap) - 1;
            if (seatMap) {
              this.mapChoicesAndSelectDefaults(newIndex);
            }
          });
        },
        error: (): void => {
          this.noSeatMap = true;
          this.dynamicDialogRef.close();
        },
        complete: (): void => {
          this.noSeatMap = false;
        },
      });
    }
  }
  private stretchAndShrinkElement(index: number): void {
    this.seatMaps[index].carriages[0].compartments.forEach((compartiment) => {
      compartiment.decks.forEach((myDeck) => {
        if ((myDeck.deckGrid.rowSize === 22 || myDeck.deckGrid.rowSize === 24) && myDeck.deckGrid.columnSize === 20) {
          myDeck.deckGrid.deckCells.forEach((deckcell) => {
            deckcell.columnStretch = round(deckcell.columnStretch / 4);
            deckcell.columnRank = round(deckcell.columnRank / 3);
            if (deckcell.columnRank >= 4) {
              deckcell.columnRank -= 1;
            }
            if (deckcell.type === "WC" || (deckcell.type === "TECH_ROOM" && deckcell.columnStretch === 3)) {
              deckcell.columnStretch = 2;
            }
            this.StretchTableUnderTable(myDeck);
          });
        } else if (myDeck.deckGrid.rowSize === 40 && myDeck.deckGrid.columnSize === 8) {
          //Première voiture
          myDeck.deckGrid.deckCells.forEach((deckCell) => {
            if (this.commonService.isTablet) {
              if (deckCell.columnRank === 0 && deckCell.type === "TABLE") {
                deckCell.columnStretch = 3;
              }
              if (deckCell.type === "WC" || deckCell.type === "STAIRS") {
                deckCell.rowStretch += 3;
              }
            } else {
              if (deckCell.columnRank === 0 && deckCell.type === "TABLE") {
                deckCell.columnStretch = 3;
              }
            }
          });
        } else {
          this.StretchTableUnderTable(myDeck);
        }
      });
    });
  }
  closeModal(): void {
    this.dynamicDialogRef.close();
  }
  StretchTableUnderTable(myDeck: TrainTypes.Deck): void {
    const Tables = myDeck.deckGrid.deckCells.filter((deckcell) => deckcell.type === "TABLE");
    Tables.forEach((currentTable) => {
      const sameRowTableUnder = Tables.filter(
        (table) =>
          table.rowRank === currentTable.rowRank &&
          table !== currentTable &&
          table.columnRank === currentTable.columnRank + 1,
      );

      if (sameRowTableUnder.length === 1) {
        currentTable.columnStretch = 2;
      }
    });
  }
}
