import { TravelFlightDetailsComponent } from "./details/travel-flight-details.component";
import { InputSwitchModule } from "primeng/inputswitch";
import { TravelFlightFiltersComponent } from "./filters/travel-flight-filters.component";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { FlightComponent } from "./flight.component";
import { SharedModule } from "src/app/@shared/shared.module";
import { FlightService } from "./flight.service";
import { CheckboxModule } from "primeng/checkbox";
import { SliderModule } from "primeng/slider";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { TravelFlightItineraryComponent } from "./itinerary/travel-flight-itinerary.component";
import { TravelFlightPreviewComponent } from "./preview/travel-flight-preview.component";
import { EndModule } from "./flight-validate-option/flight-validate-option.module";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { TravelFlightSelectionComponent } from "./selection/selection.component";
import { AFKLSeatmapComponent } from "./afkl-seatmap/afkl-seatmap.component";
import { AFKLBagagesComponent } from "./afkl-bagages/afkl-bagages.component";
import { AFKLAncillariesComponent } from "./afkl-ancillaries/afkl-ancillaries.component";

const FLIGHT_COMPONENTS: Array<any> = [
  AFKLSeatmapComponent,
  AFKLBagagesComponent,
  AFKLAncillariesComponent,
  FlightComponent,
  TravelFlightPreviewComponent,
  TravelFlightItineraryComponent,
  TravelFlightFiltersComponent,
  TravelFlightDetailsComponent,
  TravelFlightSelectionComponent,
];

const FLIGHT_PIPES: Array<any> = [];

const FLIGHT_MODULES: Array<any> = [
  ScrollingModule,
  EndModule,

  SharedModule,
  CheckboxModule,
  SliderModule,
  InfiniteScrollModule,
  InputSwitchModule,
  TranslateModule.forChild(),
];

const FLIGHT_PROVIDERS: Array<any> = [FlightService];

@NgModule({
  imports: [...FLIGHT_MODULES],
  declarations: [...FLIGHT_COMPONENTS, ...FLIGHT_PIPES],
  exports: [FlightComponent],
  providers: [...FLIGHT_PROVIDERS],
})
export class TravelFlightModule {}
