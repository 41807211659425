import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "spt-reservations-detail-transfer",
  templateUrl: "./transfer.component.html",
  styleUrls: ["./transfer.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ReservationsDetailTransferComponent implements OnInit {
  @Input() item: any;
  @Input() isSmallDevice: boolean;
  @Input() locale: string;
  @Output() openTicket: EventEmitter<{
    documents: Array<any>;
    type: "train";
    reference: string;
  }> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
