<div class="header">
  <div class="details">
    <p class="address">
      <span>
        <img class="transport-icon" src="./assets/svg/transfer-full.svg" />
      </span>
      <span class="title f-sb">
        {{ this.item.metadata.form.fromcode.description || this.item.metadata.form.fromcode.label }}
        <br />
        {{ this.item.metadata.form.tocode.description || this.item.metadata.form.tocode.label }}
      </span>
    </p>
    <p class="type" translate>
      <ng-container *ngIf="this.item.metadata.form.returning"> SEARCH.SHORT.ROUND </ng-container>
    </p>
  </div>
  <div class="datetimes">
    <p class="dates">
      <span
        class="departure"
        [class.p-r-0]="!this.item.metadata.form.returning"
        [class.m-r-10]="!this.item.metadata.form.returning"
      >
        {{ this.item.metadata.form.travelling | localizedDate: "d" }}<br />
        <small class="month">
          {{ (this.item.metadata.form.travelling | localizedDate: "MMM").replace(".", "") }}
        </small>
      </span>
      <span class="separator"></span>
      <span class="return" *ngIf="this.item.metadata.form.returning">
        {{ this.item.metadata.form.returning | localizedDate: "d" }}<br />
        <small class="month">
          {{ (this.item.metadata.form.returning | localizedDate: "MMM").replace(".", "") }}
        </small>
      </span>
    </p>
    <p class="times">
      <span class="schedule departure">
        {{
          this.item.metadata.form.travelling
            | localizedDate: ((this.sessionService.getSession() | async)?.timeFormat === 12 ? "h:mm a" : "H:mm")
        }}
      </span>
      <span class="schedule return" *ngIf="this.item.metadata.form.returning">
        {{
          this.item.metadata.form.returning
            | localizedDate: ((this.sessionService.getSession() | async)?.timeFormat === 12 ? "h:mm a" : "H:mm")
        }}
      </span>
    </p>
  </div>
</div>
<p class="travelers" translate>GLOBAL.TRAVELERS</p>
<div class="infos">
  <p class="user">
    <spt-avatar [user]="request.board[0][0]"></spt-avatar>
    <sup class="board" *ngIf="request.board[0].length - 1 > 1"> +{{ request.board[0].length - 1 }} </sup>
  </p>

  <span class="out-politic" *ngIf="this.request?.isOutOfPolicy" translate>GLOBAL.OUT_OF_POLICY</span>
  <p class="price f-sb">
    {{ request.price | currency: "EUR" : "symbol" : "1.2-2" }}
  </p>
</div>
