<div class="date">
  <span class="calendar spt-icon-date"></span>
  <div class="day">
    {{ this.departureDateTime | localizedDate: "dd" }}
  </div>
  <div class="month">
    {{ this.departureDateTime | localizedDate: "MMM" }}
  </div>
</div>
<div class="schedule-container">
  <div class="dots-container">
    <div class="dots">
      <div class="round"></div>
      <div class="line"></div>
      <div class="round stops" *ngIf="leg.airportStops?.length > 2"></div>
      <div class="line"></div>
      <div class="round"></div>
    </div>
  </div>
  <ng-container *ngIf="leg.schedules?.length; else afklLeg">
    <div class="schedule">
      <div
        class="point"
        *ngFor="let schedule of leg.schedules; let isLast = last; let isFirst = first; let index = index"
      >
        <spt-travel-flight-itinerary-leg-point
          class="departure"
          [name]="flightService.airports[schedule.departure.airport]?.name"
          [iata]="schedule.departure.airport"
          [country]="flightService.airports[schedule.departure.airport]?.country"
          [datetime]="schedule.departure.date"
          [time]="schedule.departure.time"
          [terminal]="schedule.departure.terminal"
          [flightNumber]="schedule.carrier.marketingFlightNumber"
          [travelClass]="schedule.cabinCodes"
        ></spt-travel-flight-itinerary-leg-point>
        <div class="company-duration" *ngIf="schedule?.carrier?.operating">
          <div class="name">
            {{ companies[schedule?.carrier?.operating]?.name }}
          </div>
          <img
            class="icon"
            src="https://static.misterfly.com:443/images/airlines/{{ schedule?.carrier?.operating }}.png"
          />

          <div class="tag">
            <span *ngIf="itinerary?.provider === 'afkl'"> NDC Direct Connect</span>
          </div>

          <div class="flight-duration">
            <spt-travel-carbon-offset
              [isDetailedCarbonLabel]="false"
              [carbonOffset]="carbonOffset"
            ></spt-travel-carbon-offset>
            <span class="flight-duration-data">
              {{ schedule.duration | flightTimeToDuration }}
            </span>
          </div>
        </div>
        <spt-travel-flight-itinerary-leg-point
          class="arrival"
          [departureDate]="schedule.departure.date + 'T' + schedule.departure.time"
          [name]="flightService.airports[schedule.arrival.airport]?.name"
          [iata]="schedule.arrival.airport"
          [country]="flightService.airports[schedule.arrival.airport]?.country"
          [datetime]="schedule.arrival.date"
          [time]="schedule.arrival.time"
          [terminal]="schedule.arrival.terminal"
          [flightNumber]="schedule.carrier.marketingFlightNumber"
        ></spt-travel-flight-itinerary-leg-point>
      </div>
    </div>
    <div *ngIf="leg.schedules.length > 1" class="duration" translate [translateParams]="{ time: duration }">
      SEARCH.AIR.DURATION
    </div>
  </ng-container>
  <ng-template #afklLeg>
    <div class="schedule">
      <div class="point">
        <spt-travel-flight-itinerary-leg-point
          class="departure"
          [name]="flightService.airports[leg.Dep.IATALocationCode]?.name"
          [iata]="leg.Dep.IATALocationCode"
          [country]="flightService.airports[leg.Dep.IATALocationCode]?.country"
          [datetime]="leg.Dep.AircraftScheduledDateTime"
          [time]="leg.Dep.AircraftScheduledDateTime | slice: 11 : 16"
          [terminal]="leg.Dep.IATALocationCode"
          [flightNumber]="leg.MarketingCarrierInfo.MarketingCarrierFlightNumberText"
          [travelClass]="leg.CabinType.CabinTypeCode"
        ></spt-travel-flight-itinerary-leg-point>
        <div class="company-duration" *ngIf="leg?.MarketingCarrierInfo?.CarrierDesigCode">
          <div class="name">
            {{ companies[leg?.MarketingCarrierInfo?.CarrierDesigCode]?.name }}
          </div>
          <img
            class="icon"
            src="https://static.misterfly.com:443/images/airlines/{{ leg?.MarketingCarrierInfo?.CarrierDesigCode }}.png"
          />

          <div *ngIf="itinerary?.provider === 'afkl'" class="tag">NDC Direct Connect</div>

          <div class="flight-duration">
            <i class="spt-icon-clock"></i>
            {{ duration | flightTimeToDuration }}
          </div>
        </div>
        <spt-travel-flight-itinerary-leg-point
          class="arrival"
          [departureDate]="leg.Dep.AircraftScheduledDateTime"
          [name]="flightService.airports[leg.Arrival.IATALocationCode]?.name"
          [iata]="leg.Arrival.IATALocationCode"
          [country]="flightService.airports[leg.Arrival.IATALocationCode]?.country"
          [datetime]="leg.Arrival.AircraftScheduledDateTime"
          [time]="leg.Arrival.AircraftScheduledDateTime | slice: 11 : 16"
          [terminal]="leg.Arrival.IATALocationCode"
          [flightNumber]="leg.MarketingCarrierInfo.MarketingCarrierFlightNumberText"
        ></spt-travel-flight-itinerary-leg-point>
      </div>
    </div>
    <div *ngIf="leg.length > 1" class="duration" translate [translateParams]="{ time: duration }">
      SEARCH.AIR.DURATION
    </div>
  </ng-template>
</div>
