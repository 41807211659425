import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DateTime, Interval } from "luxon";

@Pipe({
  name: "dateAdjustment",
})
export class DateAdjustmentPipe implements PipeTransform {
  private prefix: string;

  constructor(translateService: TranslateService) {
    // TODO: Mieux gérer la traduction de l'abbréviation de "jour" en fonction de la langue
    this.prefix = translateService.defaultLang === "fr" ? "j" : "d";
  }

  transform(_date: string, _initialDate: string): string {
    if (!_date || !_initialDate) {
      return "";
    }
    const initialDate = toDate(_initialDate);
    const date = toDate(_date);
    const interval = Interval.fromDateTimes(DateTime.min(initialDate, date), DateTime.max(initialDate, date));
    const diff: number = interval.length("days");

    if (diff > 0) {
      return `${this.prefix}+${diff}`;
    }
    return "";
  }
}

// Ignore les heures, minutes, secondes, millisecondes
function toDate(str): DateTime {
  return DateTime.fromISO(DateTime.fromISO(str, { setZone: true }).toISODate());
}
