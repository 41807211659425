<div class="journeys">
  <ng-container
    *ngFor="
      let journey of orderedJourneys;
      let i = index;
      let isOdd = odd;
      let isEven = even;
      let isFirst = first;
      let isLast = last
    "
  >
    <ng-container *ngIf="isFirst || (isLast && journey.direction === 'inward')">
      <div class="horizontal-separator" *ngIf="(commonService.isTabletObservable | async) === false && i > 0"></div>
      <div class="journey">
        <div class="link">
          <span class="round" [class.return]="isOdd"></span>
          <span class="line"></span>
          <span class="round" [class.return]="isEven"></span>
        </div>
        <div class="locations" [class.round]="hasReturn && hasOutward">
          <p class="direction" *ngIf="(commonService.isTabletObservable | async) === false && hasReturn && hasOutward">
            {{ "GLOBAL." + journey.direction | uppercase | translate }}
          </p>
          <div class="origin">
            <span class="station">{{ journey.departure.name | titlecase }}</span>
          </div>
          <i
            class="spt-icon-arrow-circle-right arrow-right"
            *ngIf="(commonService.isTabletObservable | async) === false && hasReturn && hasOutward"
          ></i>
          <div class="destination">
            <span class="station">{{ journey.arrival.name | titlecase }}</span>
          </div>
        </div>
        <div class="datetime" *ngIf="journey.segments.length > 0">
          <i class="spt-icon-date date-icon"></i>
          <span class="date">{{ journey.departure.date.date | localizedDate: "d MMM" | titlecase }}</span>
          <p class="schedule" *ngFor="let schedule of schedules[journey.direction]">
            <small class="time">
              {{ schedule.departure.time }}
            </small>
          </p>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
