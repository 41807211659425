import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SuggestPanelService {
  active: SuggestPanel = { name: undefined };
  private defaultPanel = undefined;
  public readonly onChange: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public readonly onReset: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private resetTimer?: any;

  constructor() {}

  init(defaultPanelName: string): void {
    this.defaultPanel = defaultPanelName;
    this.activate(this.defaultPanel);
  }

  reset(): void {
    // On annule l'éventuelle demande précédente de reset
    this.cancelReset();

    // On diffère le reset pour annuler cette action si une activation sur un panel est demandé juste après
    this.resetTimer = setTimeout(() => {
      this.onReset.next(this.defaultPanel);
      this.activate(this.defaultPanel);
    }, 200);
  }

  cancelReset() {
    clearTimeout(this.resetTimer);
  }

  activate(suggestToActivate: string): void {
    this.cancelReset();
    if (this.active.name !== suggestToActivate) {
      this.onChange.next(suggestToActivate);
      this.active.name = suggestToActivate;
    }
  }

  setDefault(panelName: string) {
    this.defaultPanel = panelName;
  }
}

export interface SuggestPanel {
  /**
   * Nom du panneau des suggestions.
   * Peut avoir au moins une des valeurs suivantes :
   * - departure
   * - arrival
   * - inward
   * - outward
   * - favorites
   */
  name: string;
}
