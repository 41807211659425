import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { Locality, SearchEngineType, TimeRange, WhenSearch } from "../../search-engine-service";
import { SessionService } from "src/app/@shared/services/session.service";
import { DEFAULT_DATE_FORMAT, UserService } from "src/app/@shared/services/user.service";
import { TranslateService } from "@ngx-translate/core";
import { SelectItem } from "primeng/api";
import { times12, times24 } from "src/app/dashboard/utils";
import { CommonService } from "src/app/@shared/services/common.service";
import { SuggestPanelService } from "../suggest-panel.service";
import { Subscription } from "rxjs";
import { DateTime, Interval } from "luxon";
import { TimeOption } from "src/app/dashboard/@dashboard-shared/components/time-selector/time-selector.component";

@Component({
  selector: "spt-search-datetime",
  templateUrl: "./search-datetime.component.html",
  styleUrls: ["./search-datetime.component.scss"],
})
export class SearchDatetimeComponent<LOCALITY_TYPE extends Locality> implements OnInit, OnChanges, OnDestroy {
  // Nécessaire pour pouvoir définir les liens entre <label for> et les <input>
  id = "SearchDatetimeComponent-" + Math.random().toString(36).substring(2);

  @ViewChild("template", { static: true }) template!: TemplateRef<any>;

  @Input() gridArea: string = "datetime";
  @Input() gridAreaError?: string = "error";
  @Input() suggestPanelName = "datetime";
  @Input() time: boolean = true;
  @Input() styleClass: string = "";
  @Input() styleClassDateMandatory: string = "";
  
  @Input() sourceWhenCriteria!: WhenSearch;

  @Output() onActivateSuggest: EventEmitter<string> = new EventEmitter<string>();
  @Output() onCloseSuggest: EventEmitter<void> = new EventEmitter<void>();
  @Output() onDatetimeChange: EventEmitter<WhenSearch> = new EventEmitter<WhenSearch>();

  @Input() i18n!: {
    oneWaySuggestPanelTitle?: string;
    oneWaySuggestPanelSubTitle?: string;
    roundTripSuggestPanelTitle?: string;
    outwardDatePlaceholder?: string;
    outwardLabelDate?: string;
    outwardLabelTime?: string;

    inwardDatePlaceholder?: string;
    inwardLabelDate?: string;
    inwardLabelTime?: string;

    DepartsAfter?: string;
    ArrivalBefore?: string;
    allDay?: string;
  };

  label: { outward: { type: string; time: string }; inward: { type: string; time: string } } = {
    outward: {
      type: "",
      time: "",
    },
    inward: {
      type: "",
      time: "",
    },
  };

  @Input() maxDateCount: number = 2;

  @Input() timeOptions!: SelectItem[];
  @Input() timeOptionsArrivalBefore!: SelectItem[];
  protected timeOptionsByHourWindow: { DepartsAfter?: SelectItem[]; ArrivalBefore?: SelectItem[] } = {};

  hourWindowOptions: SelectItem[] = [];
  initCalendar: boolean = false;
  calendarDates: Date[] = [new Date()];
  nightNumber: number = 0;

  @Input() calendarSelectionMode: "multiple" | "range" = "multiple";
  @Input() minStartDate: Date = new Date();
  @Input() yearRange: string;
  @Input() hourType: boolean = true;

  @ViewChild("time") searchElement: ElementRef;

  @Input() searchEngineType: SearchEngineType;

  calendarDateFormat;
  calendarFirstDayOfWeek: number = 1;
  dateFormat: string = DEFAULT_DATE_FORMAT;
  searchType: string = "";

  private isActive: boolean = false;
  private observablesToClearOnDestroy: Subscription[] = [];

  constructor(
    private viewContainerRef: ViewContainerRef,
    private sessionService: SessionService<any>,
    private userService: UserService,
    private translateService: TranslateService,
    protected suggestPanelService: SuggestPanelService,
    // Utilisé dans le template
    protected commonService: CommonService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maxDateCount && changes.maxDateCount.currentValue < this.calendarDates.length) {
      this.calendarDates.length = changes.maxDateCount.currentValue;
    }
  }

  private getTypeOption(value: any): SelectItem<any> {
    return this.hourWindowOptions.find((e) => e.value === value);
  }
  private getTimeOption(type: "DepartsAfter" | "ArrivalBefore", timeRange: TimeRange): SelectItem<any> {
    return this.timeOptionsByHourWindow[type]?.find(
      (e) => e.value.begin === timeRange.begin && e.value.end === timeRange.end,
    );
  }

  updateSourceWhenCriteriaFromEdit(event: any): void {
    let dates = [];

    if (this.calendarDates && Array.isArray(this.calendarDates)) {
      this.calendarDates.sort((a, b) => a?.getTime() - b?.getTime());
      dates.push(...this.calendarDates);
    } else {
      dates = [this.calendarDates];
    }
    this.sourceWhenCriteria.outward.setDateOnly(dates[0]);
    if (this.sourceWhenCriteria.inward) {
      this.sourceWhenCriteria.inward.setDateOnly(dates[1]);
    }
    if (this.sourceWhenCriteria.inward?.date && this.sourceWhenCriteria.outward?.date) {
      const outwardDate = DateTime.fromISO(this.sourceWhenCriteria.outward.date);
      const inwardDate = DateTime.fromISO(this.sourceWhenCriteria.inward.date);
      const interval = Interval.fromDateTimes(
        DateTime.min(outwardDate, inwardDate),
        DateTime.max(outwardDate, inwardDate),
      );
      const night: number = interval.length("days");
      this.nightNumber = night;
    }
    this.onDatetimeChange.emit(this.sourceWhenCriteria);
  }
  ngOnInit(): void {
    // Permet de ne pas avoir le tag <stp-locality-autocomplete/> présent dans le DOM
    // (cf.https://stackoverflow.com/questions/38716105/angular2-render-a-component-without-its-wrapping-tag#answer-56887630)
    // Indispensable pour pouvoir utiliser les `grid-template-areas`
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
    if (this.searchEngineType) {
      this.searchType = this.translateService
        .instant(`BASKET.TYPES.${[this.searchEngineType.toUpperCase()]}`)
        .toLowerCase();
    }
    if (!this.timeOptions) {
      const session = this.sessionService.get();
      this.timeOptions = (session.timeFormat === "12" ? times12 : times24).map((o) => createTimeOption(o));
    }

    this.timeOptionsByHourWindow.DepartsAfter = this.timeOptions;

    if (this.timeOptionsArrivalBefore) {
      this.timeOptionsByHourWindow.ArrivalBefore = this.timeOptionsArrivalBefore;
    } else {
      this.timeOptionsByHourWindow.ArrivalBefore = this.timeOptions;
    }
    this.observablesToClearOnDestroy.push(
      this.suggestPanelService.onChange.subscribe((panelName) => (this.isActive = this.suggestPanelName === panelName)),
    );
    this.observablesToClearOnDestroy.push(
      this.userService.user.subscribe((user) => {
        this.dateFormat = user?.settings?.nativeDateFormat || DEFAULT_DATE_FORMAT;
      }),
    );

    this.sessionService.getSession().subscribe((s) => (s.dateFormat = this.calendarDateFormat));
    this.calendarFirstDayOfWeek = this.translateService.currentLang === "en" ? 0 : 1;

    this.calendarDates = [];
    const outwardDate: Date = stringToDate(this.sourceWhenCriteria.outward.date);
    if (outwardDate) {
      this.calendarDates.push(outwardDate);
    }
    if (this.sourceWhenCriteria.inward) {
      const inwardDate: Date = stringToDate(this.sourceWhenCriteria.inward.date);
      if (inwardDate) {
        this.calendarDates.push(inwardDate);
      }
    }

    this.hourWindowOptions.push(
      {
        label: this.translateService.instant("SEARCH.DEPARTURE_AT"),
        value: "DepartsAfter",
      },
      {
        label: this.translateService.instant("SEARCH.ARRIVAL_BEFORE"),
        value: "ArrivalBefore",
      },
    );

    this.refreshLabels();
  }

  ngAfterViewInit(): void {
    // Afin de ne pas monopoliser le CPU pour l'affichage du calendrier (qui peux nuire à d'autres tâches comme le chargement des favoris/lastSearch),
    // on diffère l'initialisation du DOM effectué par PrimeNG/Calendar
    setTimeout(() => (this.initCalendar = true), 800);
  }

  ngOnDestroy(): void {
    this.observablesToClearOnDestroy.forEach((o) => o.unsubscribe());
    this.observablesToClearOnDestroy.length = 0;
  }

  refreshLabels() {
    this.label.outward.type = this.getTypeOption(this.sourceWhenCriteria.outward.type)?.label;
    this.label.outward.time = this.getTimeOption(
      this.sourceWhenCriteria.outward.type,
      this.sourceWhenCriteria.outward.time,
    )?.label;
    this.label.inward.type =
      this.sourceWhenCriteria.inward && this.getTypeOption(this.sourceWhenCriteria.inward.type)?.label;
    this.label.inward.time =
      this.sourceWhenCriteria.inward &&
      this.getTimeOption(this.sourceWhenCriteria.inward.type, this.sourceWhenCriteria.inward.time)?.label;
  }

  activateSuggest(): void {
    this.onActivateSuggest.emit(this.suggestPanelName);
  }

  hideSuggest(): void {
    this.onCloseSuggest.emit();
  }

  focusTime(): void {
    this.searchElement.nativeElement.focus();
  }

  focus() {
    this.suggestPanelService.cancelReset();
    this.activateSuggest();
  }

  changeOutwardTime(timeOption: TimeOption) {
    this.sourceWhenCriteria.outward.time.begin = timeOption.value;
    this.sourceWhenCriteria.outward.time.end = timeOption.value;
    this.label.outward.time = timeOption.hours.label + ":" + timeOption.minutes.label;
    this.onDatetimeChange.emit(this.sourceWhenCriteria);
  }

  changeTime() {
    this.refreshLabels();
    this.onDatetimeChange.emit(this.sourceWhenCriteria);
  }
}

function stringToDate(str: string) {
  const result = str ? new Date(Date.parse(str)) : undefined;
  return result && !isNaN(result.getTime()) ? result : undefined;
}

function createTimeOption(option: SelectItem<number>): SelectItem<{ begin: string; end: string }> {
  const h = String(option.value).padStart(2, "0");
  return {
    label: option.label,
    value: {
      begin: h + ":00",
      end: h + ":59",
    },
  };
}
