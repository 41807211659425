<span class="way" *ngIf="displayWayType">Aller</span>
<div class="legs">
  <div class="leg" *ngFor="let leg of itinerary.legs; let i = index">
    <ng-container *ngIf="i < step">
      <div class="departure">
        <div class="time">{{ leg.departure.time | dateParser }}</div>
        <div class="place">
          {{ flightService.airports[leg.departure.airport]?.name }}
        </div>
      </div>
      <div class="arrow spt-icon-arrow-circle-right"></div>
      <div class="arrival">
        <div class="time">{{ leg.arrival.time | dateParser }}</div>
        <div class="place">
          {{ flightService.airports[leg.arrival.airport]?.name }}
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="modify">
  <p-button
    type="submit"
    styleClass="p-button-fourth"
    type="text"
    [label]="'GLOBAL.MODIFY' | translate"
    (mousedown)="change()"
  ></p-button>
</div>
