import { Component, HostListener, Input, OnChanges, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "spt-tooltip",
  styleUrls: ["./tooltip.component.scss"],
  templateUrl: "./tooltip.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class TooltipComponent implements OnInit {
  @Input() triggerLabel: string;
  @Input() description: string;
  @Input() items: string[];
  @Input() triggerImg: string;
  @Input() position: "top" | "bottom" | "left" | "right";
  @Input() isSmallDevice: boolean;

  constructor() {}

  @HostListener("mousedown", ["$event"])
  @HostListener("click", ["$event"])
  public onClick(event: any): void {
    // Nécessaire pour le mobile
    event.stopPropagation();
    event.preventDefault();
  }

  ngOnInit(): void {
    if (!this.position) {
      if (this.isSmallDevice) {
        this.position = "top";
      } else {
        this.position = "right";
      }
    }
  }
}
