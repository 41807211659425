import { Component, OnInit, OnDestroy, Input, ViewEncapsulation } from "@angular/core";
import { BehaviorSubject, Subscription, combineLatest } from "rxjs";
import { filter } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { SocietyService } from "../../services/society.service";
import { MemberSociety } from "../../@types/society";
import { User } from "../../@types/user";
import { AuthorizationsByPolicy } from "../../services/authorization.service";

interface AppliedPolicy {
  priceMax?: number;
  classMax?: string;
  refundable?: boolean;
  changeable?: boolean;
  ratingMin?: number;
  ratingMax?: number;
  categories?: string[];
  insurranceIsMandatory?: boolean;
  scoreMin?: number;
}

interface GroupedAuthorization {
  userIds: string[];
  appliedPolicy: AppliedPolicy;
  hasInfo: boolean;
  isPriceOutOfPolicy: boolean;
  isClassOutOfPolicy: boolean;
  isConditionsOutOfPolicy: boolean;
  isScoreOutOfPolicy: boolean;
  isDurationOutOfPolicy: boolean;
  mandatoryInsurranceOutOfPolicy: boolean;
  resolvedUsers?: { lastname: string; firstname: string }[];
}

@Component({
  selector: "spt-out-of-policy",
  templateUrl: "./out-of-policy.component.html",
  styleUrls: ["./out-of-policy.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class OutOfPolicyComponent implements OnInit, OnDestroy {
  protected groupedAuthorizationsByPolicy: GroupedAuthorization[] = [];
  private members: MemberSociety[] = [];
  private subscriptions: Subscription[] = [];
  protected concernedUserIds: string[] = [];

  @Input() isOutOfPolicy: boolean;
  @Input() displayInfo: boolean = true;
  @Input() isPriceOutOfPolicy: boolean;
  @Input() type: string;
  @Input() position: string = "bottom";

  @Input() authorizationsByPoliciesSubject: BehaviorSubject<AuthorizationsByPolicy[]> = new BehaviorSubject([]);
  @Input() set authorizationsByPolicies(authorizationsByPolicies: AuthorizationsByPolicy[]) {
    this.authorizationsByPoliciesSubject.next(authorizationsByPolicies);
  }

  public isClassOutOfPolicyTexts: string[] = [];

  constructor(
    private translateService: TranslateService,
    private societyService: SocietyService,
  ) {}

  private resolveUser(userId: string): { lastname: string; firstname: string } {
    const user = this.members.map((m) => m.user).find((u) => u._id === userId);
    return user ? { lastname: user.lastname, firstname: user.firstname } : { lastname: "", firstname: "" };
  }

  private resolveUsers(userIds: string[]): { lastname: string; firstname: string }[] {
    return userIds.map((userId) => this.resolveUser(userId));
  }

  ngOnInit(): void {
    this.subscriptions.push(
      combineLatest([this.authorizationsByPoliciesSubject, this.societyService.society.asObservable()])
        .pipe(
          filter(([authorizationsByPolicies, society]) => {
            return !!authorizationsByPolicies && !!society;
          }),
        )
        .subscribe(([authorizationsByPolicies, society]) => {
          this.members = society?.members || [];
          this.groupedAuthorizationsByPolicy = this.groupUsersByPolicy(authorizationsByPolicies);
          this.concernedUserIds = this.groupedAuthorizationsByPolicy.flatMap((policyData) => policyData.userIds);
          this.setClassOutOfPolicyTexts();
        }),
    );
  }

  groupUsersByPolicy(authorizationsByPolicies: AuthorizationsByPolicy[]): GroupedAuthorization[] {
    const policyMap = new Map<string, GroupedAuthorization>();
    authorizationsByPolicies.forEach((policyData) => {
      const policyKey = JSON.stringify(policyData.appliedPolicy);
      if (policyMap.has(policyKey)) {
        const existingPolicy = policyMap.get(policyKey);
        existingPolicy.userIds = Array.from(new Set([...existingPolicy.userIds, ...policyData.userIds]));
        existingPolicy.resolvedUsers = this.resolveUsers(existingPolicy.userIds);
      } else {
        const hasInfo = this.checkHasInfo(policyData);
        policyMap.set(policyKey, {
          userIds: [...policyData.userIds],
          appliedPolicy: policyData.appliedPolicy,
          hasInfo: hasInfo,
          isPriceOutOfPolicy: policyData.isPriceOutOfPolicy,
          isClassOutOfPolicy: policyData.isClassOutOfPolicy,
          isConditionsOutOfPolicy: policyData.isConditionsOutOfPolicy,
          isScoreOutOfPolicy: policyData.isScoreOutOfPolicy,
          isDurationOutOfPolicy: policyData.isDurationOutOfPolicy,
          mandatoryInsurranceOutOfPolicy: policyData.mandatoryInsurranceOutOfPolicy,
          resolvedUsers: this.resolveUsers(policyData.userIds),
        });
      }
    });

    return Array.from(policyMap.values());
  }

  checkHasInfo(policyData: AuthorizationsByPolicy): boolean {
    return (
      policyData.isPriceOutOfPolicy ||
      policyData.mandatoryInsurranceOutOfPolicy ||
      policyData.isDurationOutOfPolicy ||
      policyData.isScoreOutOfPolicy ||
      policyData.isClassOutOfPolicy ||
      policyData.isConditionsOutOfPolicy
    );
  }

  setClassOutOfPolicyTexts(): void {
    this.isClassOutOfPolicyTexts = this.groupedAuthorizationsByPolicy.map((authorization) => {
      if (this.type === "hotel") {
        if (authorization.appliedPolicy.ratingMin && authorization.appliedPolicy.ratingMax) {
          return this.translateService.instant("POLICY.TOOLTIP.TRAVEL_CLASS.HOTEL.MIN_MAX", {
            min: authorization.appliedPolicy.ratingMin,
            max: authorization.appliedPolicy.ratingMax,
          });
        }
        if (authorization.appliedPolicy.ratingMin && !authorization.appliedPolicy.ratingMax) {
          return this.translateService.instant("POLICY.TOOLTIP.TRAVEL_CLASS.HOTEL.MIN_ONLY", {
            min: authorization.appliedPolicy.ratingMin,
          });
        }
        if (!authorization.appliedPolicy.ratingMin && authorization.appliedPolicy.ratingMax) {
          return this.translateService.instant("POLICY.TOOLTIP.TRAVEL_CLASS.HOTEL.MAX_ONLY", {
            max: authorization.appliedPolicy.ratingMax,
          });
        }
      } else if (this.type === "flight") {
        return this.translateService.instant(`POLICY.TOOLTIP.TRAVEL_CLASS.FLIGHT`, {
          max: this.translateService.instant(`GLOBAL.CATEGORY.${authorization.appliedPolicy.classMax?.toUpperCase()}`),
        });
      } else if (this.type === "train") {
        return authorization.appliedPolicy.classMax === "class_b"
          ? this.translateService.instant(`POLICY.TOOLTIP.TRAVEL_CLASS.TRAIN.SECOND`)
          : this.translateService.instant(`POLICY.TOOLTIP.TRAVEL_CLASS.TRAIN.FIRST`);
      } else if (this.type === "car") {
        return authorization.isClassOutOfPolicy
          ? this.translateService.instant(`POLICY.TOOLTIP.TRAVEL_CLASS.CAR`)
          : this.translateService.instant(`POLICY.TOOLTIP.TRAVEL_CLASS.CAR_IN`);
      }
      return "";
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
