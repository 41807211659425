<spt-sticky-header
  *ngIf="elementRef && displayFilters === false && (hotels | async)?.length > 0"
  [scrollRef]="elementRef"
  childElement=".main"
>
  <div class="resume-filters" *ngIf="(commonService.isTabletObservable | async) === true">
    <img src="./assets/svg/filters.svg" alt="" class="open" (mousedown)="switchFilters()" />
    <div class="running-filters">
      <div *ngFor="let filter of runningFilters" (mousedown)="deleteFilter(filter)">
        <div class="filter">
          {{ filter.label | translate }}
          <div class="remove">
            <img src="./assets/svg/close-white.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</spt-sticky-header>
<div class="main">
  <div
    class="filters"
    *ngIf="(commonService.isTabletObservable | async) === false || displayFilters"
    [formGroup]="filterForm"
    [class.reduced]="reducedFilterPanel"
  >
    <div class="filters-container">
      <div class="filters-header">
        <div class="type">
          <img src="./assets/svg/hotel.svg" alt="" />
          <span translate>GLOBAL.HOTEL</span>
        </div>
        <div class="close" (click)="switchFilters()">
          <img src="./assets/svg/close-panel.svg" alt="" />
        </div>
      </div>
      <div class="search-description">
        <img src="./assets/svg/moon.svg" alt="" />
        <span translate [translateParams]="{ nbNights: nbNights, nbRooms: nbRooms }"
          >SEARCH.RESULT.HOTEL.DESCRIPTION</span
        >
      </div>
      <div class="search">
        <i class="spt-icon-search p-button-icon p-button-icon-left ng-star-inserted"></i>
        <input pInputText type="text" formControlName="search" placeholder="{{ 'SEARCH.HOTEL.SEARCH' | translate }}" />
      </div>
      <div class="stars-rating">
        <div class="stars">
          <div class="title" translate>SEARCH.RESULT.HOTEL.STARS</div>
          <div class="checkbox-container" *ngFor="let value of starsOptions">
            <div class="checkbox-input">
              <p-checkbox [formControl]="filterForm.controls['stars']" class="checkbox" [label]="value" [value]="value">
              </p-checkbox>
            </div>
            <div
              class="stat"
              [class.active]="filterForm.controls['stars'].value | sptIndexOf: value : lastFiltersUpdate"
            >
              {{ filterStats.stars[value] }}
            </div>
          </div>
        </div>
        <div class="rating">
          <div class="title" translate>SEARCH.RESULT.HOTEL.NOTE<span translate>SEARCH.RESULT.HOTEL.MIN</span></div>
          <input pInputText type="text" formControlName="rating" />
        </div>
      </div>
      <div class="price">
        <div class="title" translate>SEARCH.RESULT.HOTEL.MAX-PRICE</div>
        <div class="input-group">
          <p-slider class="price-range" [max]="priceRange.max" formControlName="price"> </p-slider>
          <div class="value">{{ { amount: filterForm.get("price").value, currency: "EUR" } | SPTPrice | async }}</div>
        </div>
      </div>
      <div class="checkIn">
        <div class="title" translate>SEARCH.TRANSFER.CHECKIN</div>
        <div class="slider">
          <p-slider
            [(ngModel)]="rangeValuesCheckIn"
            [step]="1"
            [range]="true"
            formControlName="checkIn"
            [min]="0"
            [max]="48"
          ></p-slider>
          <div>{{ CheckInFrom }} - {{ CheckInTo }}</div>
        </div>
      </div>

      <div class="checkOut">
        <div class="title" translate>SEARCH.TRANSFER.CHECKOUT</div>
        <div class="slider">
          <p-slider
            [(ngModel)]="rangeValuesCheckOut"
            [step]="1"
            [range]="true"
            formControlName="checkOut"
            [min]="0"
            [max]="48"
          ></p-slider>
          <div>{{ CheckOutFrom }} - {{ CheckOutTo }}</div>
        </div>
      </div>
      <div class="cancellation" *ngIf="refundableExist">
        <div class="title" translate>BOOKING.BOOKING_CANCELATION</div>
        <div class="checkbox-container">
          <div class="checkbox-input">
            <p-checkbox
              [formControl]="filterForm.controls['refundable']"
              class="checkbox"
              [label]="'SEARCH.RESULT.HOTEL.FILTERS.CANCELLABLE' | translate"
              binary="true"
            >
            </p-checkbox>
          </div>
          <div class="stat" [class.active]="filterForm.get('refundable').value">
            {{ filterStats.cancellable }}
          </div>
        </div>
      </div>

      <div class="inPolicy" *ngIf="outOfPolicyExist">
        <div class="title" translate>ACCOUNT.GENERAL.POLITIC</div>
        <div class="checkbox-container">
          <div class="checkbox-input">
            <p-checkbox
              [formControl]="filterForm.controls['inPolicy']"
              class="checkbox"
              [label]="'SEARCH.HOTEL.IN_POLICY' | translate"
              binary="true"
            >
            </p-checkbox>
          </div>
          <div class="stat" [class.active]="filterForm.get('inPolicy').value">
            {{ filterStats.inPolicy }}
          </div>
        </div>
      </div>

      <div class="stuffs">
        <div class="title" translate>SEARCH.RESULT.HOTEL.FILTERS.STUFF</div>
        <div class="checkbox-container" *ngFor="let option of stuffOptions">
          <div class="checkbox-input">
            <p-checkbox
              [formControl]="filterForm.controls['stuff']"
              class="checkbox"
              [label]="'SEARCH.HOTEL.FACILITIES.' + option.label | translate"
              [value]="option.value"
            ></p-checkbox>
          </div>
          <div
            class="stat"
            [class.active]="filterForm.get('stuff').value | sptIndexOf: option.value : lastFiltersUpdate"
          >
            {{ filterStats.options[option.label] }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="map-hotels">
    <div class="overmap-container">
      <div class="overmap" [class.filter-reduced]="reducedFilterPanel">
        <div class="filters-sort" *ngIf="(commonService.isTabletObservable | async) === false">
          <div class="running-filters">
            <div
              class="filter"
              *ngFor="let filter of runningFilters?.slice(0, 2); let i = index"
              (mousedown)="deleteFilter(filter)"
            >
              <div>
                <div class="remove">
                  <img src="./assets/svg/close-violet.svg" alt="" (mousedown)="deleteFilter(filter)" />
                </div>
                {{ filter.label | translate }}
              </div>
            </div>

            <div class="filter-reduced" *ngIf="runningFilters.length > 2">
              <div class="remove">
                <img
                  src="./assets/svg/close-violet.svg"
                  alt=""
                  (mousedown)="deleteFilter(runningFilters[runningFilters.length - 1])"
                />
              </div>
              <div class="filter-count">+{{ runningFilters.length - 2 }}</div>
            </div>
          </div>
          <p-dropdown [options]="sortOptions" [(ngModel)]="sortOption" (onChange)="applyFilters()"> </p-dropdown>
        </div>
        <div class="not-in-policy" *ngIf="atleastOneInPolicy === false && seeOutOfPolicy == false" translate>
          SEARCH.RESULT.HOTEL.NO_RESULT_IN_POLICY
        </div>
        <div
          class="hotels"
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50"
          [scrollWindow]="false"
        >
          <ng-container *ngFor="let hotel of hotels | async">
            <spt-travel-hotel-thumbnail
              (select)="selectHotel(hotel)"
              (mouseenter)="hoverHotel(hotel)"
              (mouseleave)="unHoverHotel(hotel)"
              class="step"
              [class.promote-carbon-offset]="
                hotelAvailabilities[hotel.accomcode].hasRsePolicy && isECF[hotel.accomcode]
              "
              [promoteCarbonOffset]="hotelAvailabilities[hotel.accomcode].hasRsePolicy && isECF[hotel.accomcode]"
              [class.hide]="!hotelAvailabilities[hotel.accomcode]"
              (updateHotel)="updateHotel($event)"
              [hotel]="hotel"
              [availabilities]="hotelAvailabilities[hotel.accomcode]"
            ></spt-travel-hotel-thumbnail>
          </ng-container>
        </div>
        <div class="hotels-loading" *ngIf="hotelsLoading; else loadMore">
          <img src="./assets/svg/loading-bar.gif" alt="" />
          <p translate>SEARCH.RESULT.HOTEL.SEARCH_LOADING</p>
        </div>
        <ng-template #loadMore>
          <div
            class="load-more"
            [class.pulse]="searching"
            [class.disabled]="searching"
            [class.hide]="hideButton"
            (mousedown)="searchMore()"
            #searchHereBtn
            translate
          >
            SEARCH.RESULT.HOTEL.SEARCH_MORE
          </div>
        </ng-template>
      </div>
      <div
        *ngIf="(commonService.isTabletObservable | async) === false"
        class="search-in-this-zone hide"
        [class.pulse]="searching"
        [class.disabled]="searching"
        [class.hide]="hideButton"
        (mousedown)="searchHere()"
        #searchHereBtn
        translate
      >
        SEARCH.RESULT.HOTEL.SEARCH_IN_THIS_ZONE
      </div>
    </div>
    <div *ngIf="(commonService.isTabletObservable | async) === false" class="map" #map></div>
  </div>
</div>

<p-sidebar
  *ngIf="selectedHotel"
  [visible]="!!selectedHotel"
  position="right"
  [modal]="false"
  [styleClass]="panelClass"
  [showCloseIcon]="false"
>
  <spt-travel-hotel-page
    [search]="search"
    [hotel]="selectedHotel"
    [availabilities]="hotelAvailabilities[selectedHotel.accomcode]"
    [seeOutOfPolicy]="seeOutOfPolicy"
    (selectFare)="switchPanelAndSelectFare($event)"
    (unselectHotel)="unselectedHotel()"
    (outdatedInformations)="refresh(selectedHotel.accomcode)"
    (updateHotel)="updateHotel($event)"
    (availabilitiesUpdateRequired)="refresh(selectedHotel.accomcode, true)"
  ></spt-travel-hotel-page>
</p-sidebar>
