import { NgModule } from "@angular/core";

import { SharedModule } from "src/app/@shared/shared.module";
import { SelectMembersComponent } from "./select-members.component";
// import { AvatarModule } from '../../components/avatar/avatar.module';
import { TranslateModule } from "@ngx-translate/core";

const SELECT_MEMBERS_MODULES: Array<any> = [
  SharedModule,
  // AvatarModule,
  TranslateModule.forChild(),
];

@NgModule({
  declarations: [SelectMembersComponent],
  imports: [...SELECT_MEMBERS_MODULES],
  exports: [SelectMembersComponent],
})
export class SelectMembersModule {}
