<div class="journey-detail">
  <div class="reservation-detail">
    <div class="reservation-detail-container">
      <div class="destination">
        <div class="date-wrapper">
          <div class="date datein">
            <p class="day-number">
              {{ pickupInfo.date | localizedDate: "dd" }}
            </p>
            <p class="month">{{ pickupInfo.date | localizedDate: "MMM" }}</p>
          </div>
          <div class="date dateout">
            <p class="day-number">
              {{ returnInfo.date | localizedDate: "dd" }}
            </p>
            <p class="month">{{ returnInfo.date | localizedDate: "MMM" }}</p>
          </div>
        </div>
        <div class="ellipse-line">
          <img src="./assets/svg/oneway-icon.svg" class="ellipse-empty" />
        </div>
        <div class="destination-time-society-container">
          <p class="departure-place-name">
            {{ pickupInfo.place | titlecase }}, <br /><span>{{ pickupInfo.city | titlecase }}</span>
          </p>
          <p class="departure-time">{{ item.detail.pickupDate.time }}</p>
          <p class="arrival-place-name">
            {{ returnInfo.place | titlecase }}, <br /><span>{{ returnInfo.city | titlecase }}</span>
          </p>
          <p class="arrival-time">{{ item.detail.returnDate.time }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
