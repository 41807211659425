import { Component, Input } from "@angular/core";
import { DataHotel } from "../../sidebar-travel.component";
import { CommonService } from "src/app/@shared/services/common.service";

@Component({
  selector: "spt-conditions-hotel",
  templateUrl: "./hotel.component.html",
  styleUrls: ["./hotel.component.scss"],
})
export class ConditionsHotelComponent {
  @Input() item: any;
  @Input() dataHotel: DataHotel;
  public more: boolean = false;
  public see: boolean = false;
  public contractRemarks: string;
  public nbMaxChar: number = 300;

  public constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    if (this.dataHotel?.contractRemarks) {
      this.contractRemarks = this.dataHotel.contractRemarks.map((contractRemark) => contractRemark).join("<br> ");
      if (this.commonService.isTablet) {
        this.nbMaxChar = 150;
      }
      if (this.contractRemarks.length > this.nbMaxChar) {
        this.more = true;
        this.contractRemarks = this.contractRemarks.slice(0, this.nbMaxChar) + "... ";
      }
    }
  }
  openModalContractRemarks(): void {
    this.see = true;
  }
}
