<div *ngIf="subtitle" class="title">{{ subtitle }}</div>
<div class="maxPeopleReached" *ngIf="count === max" translate>MEMBERS.MAX_REACHED</div>

<div class="container w-full" [ngClass]="modalMembers ? 'flex' : ''">
  <div class="main" [ngClass]="{ deactivated: count === max }">
    <div class="search">
      <div class="p-inputgroup align-items-center search-input">
        <span class="p-inputgroup-addon">
          <span class="spt-icon-search"></span>
        </span>
        <input
          class="f-sb txt-17"
          pInputText
          type="text"
          [(ngModel)]="searchInput"
          placeholder="{{ 'SEARCH.SEARCH_TRAVELLER' | translate }}"
        />
      </div>
      <div class="filter" *ngIf="!searchEngine">
        <label for="selected-all" translate>GLOBAL.SELECT_ALL</label>
        <p-checkbox
          inputId="selected-all"
          [(ngModel)]="selectAll"
          binary="true"
          (ngModelChange)="allSelect($event)"
        ></p-checkbox>
      </div>
    </div>

    <div class="users">
      <p-virtualScroller
        [value]="newMembersList | sptFilter: searchInput : filterFields"
        class="infinite-scroll"
        [itemSize]="itemSize"
      >
        <ng-template pTemplate="item" let-member>
          <!-- Le bouton d'ajout d'invité n'est pas inclu via le template header du virualscrooll afin de pouvoir se comporter comme un item (inclu dans le scroll) -->
          <!-- La valeur "null" correspond à l'option d'ajout d'un invité -->
          <div *ngIf="member.invite" class="invite">
            <p-button
              *ngIf="(commonService.isTabletObservable | async) === false"
              type="button"
              label="{{ 'GLOBAL.ADD_INVITE' | translate }}"
              icon="spt-icon-add-traveler"
              (mousedown)="invite()"
              styleClass="p-button-fourth"
            >
            </p-button>
          </div>
          <div
            *ngIf="!member.invite"
            class="avatar"
            [class.selected]="member.isSelected"
            (mousedown)="toggleMemberStatus(member)"
          >
            <spt-avatar [user]="member.user"></spt-avatar>
            <div class="p-checkbox">
              <div class="p-checkbox-box">
                <i class="fas fa-check"></i>
              </div>
            </div>
          </div>
        </ng-template>
      </p-virtualScroller>
    </div>
  </div>

  <div class="panel">
    <div class="selected-users">
      <div class="title">
        <span>{{ "MEMBERS.SELECTED" | translate }}</span>
        <div class="counter">
          {{ (newMembersList | sptFilter: "true" : "isSelected")?.length }}
          {{ max ? "/ " + max : "" }}
        </div>
      </div>
      <div class="users">
        <div class="user" *ngFor="let member of membersSelectedDisplayedList" (mousedown)="toggleMemberStatus(member)">
          <div class="username">
            {{ member.user.username }}
          </div>
          <span class="spt-icon-close1"></span>
        </div>
        <ng-container *ngIf="membersSelectedAdditionnalList.length > 0">
          <button type="button" #morePeople class="user">
            {{ membersSelectedAdditionnalList.length }} personnes...
          </button>
          <p-contextMenu
            [target]="morePeople"
            [appendTo]="'body'"
            [model]="membersSelectedAdditionnalList"
            [triggerEvent]="morePeopleTriggerEvent"
          ></p-contextMenu>
        </ng-container>
      </div>
    </div>

    <div class="select-actions" *ngIf="displayActionButtons">
      <div class="invite" *ngIf="canCreateUser">
        <p-button
          type="submit"
          label="{{ 'GLOBAL.INVITE' | translate }}"
          icon="spt-icon-add-traveler"
          (mousedown)="invite()"
          styleClass="p-button-fourth"
        >
        </p-button>
      </div>
      <div class="valid">
        <p-button
          type="submit"
          label="{{ 'GLOBAL.VALIDATE' | translate }}"
          (mousedown)="submit()"
          styleClass="medium"
        ></p-button>
      </div>
    </div>
  </div>
</div>
