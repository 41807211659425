import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ItemFlight, RequestFlight } from "../../../../../../@shared/@types/models";
import { CommonService } from "../../../../../../@shared/services/common.service";

@Component({
  selector: "spt-request-flight",
  templateUrl: "./flight.component.html",
  styleUrls: ["../request.component.scss", "./flight.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RequestFlightComponent implements OnInit {
  @Input() request: RequestFlight;
  legsLength: number;
  type: string;
  itemFlight: ItemFlight;
  public reg: RegExp = new RegExp(/:\d{2}(\+|\-|Z).*/);
  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.itemFlight = this.request.item as ItemFlight;
    this.legsLength = this.itemFlight.metadata.itineraries[0].legs.length;
    this.type = this.itemFlight.form.type;
  }
}
