import { ActivatedRoute } from "@angular/router";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { DynamicDialogRef, DynamicDialogConfig } from "primeng/dynamicdialog";
import { MemberSociety, Society } from "src/app/@shared/@types/society";
import { CommonService } from "src/app/@shared/services/common.service";
import { SocietyService } from "src/app/@shared/services/society.service";
import { RoleService } from "src/app/@shared/services/roles.service";
import moment from "moment";
import { ModalService } from "../../../../@shared/services/modal.service";
import { MenuItem } from "primeng/api";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "spt-select-members",
  templateUrl: "./select-members.component.html",
  styleUrls: ["./select-members.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SelectMembersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() membersList: Array<MemberSociety>;
  @Input() membersSelected: Array<MemberSociety>;
  @Input() title: string;
  @Input() max: number;
  @Input() modalMembers: boolean = true;
  @Input() displaySelectedMembers?: number;
  @Input() itemSize: number = 70;
  @Input() subtitle: string;
  @Input() displayActionButtons: boolean = true;
  @Output() onChangeSelectedMembers: EventEmitter<MemberSociety[]> = new EventEmitter();

  public count: number = 0;

  public filterFields: string[] = ["user.username", "user.email"];

  public searchInput: string;
  public selectAll: boolean = false;
  public onlySelected: boolean;
  // TODO other array instead of property to reduce loop
  public newMembersList: Array<(MemberSociety | { invite: boolean }) & { isSelected: boolean }>;
  public isMemberSelected: boolean;
  public isSearch: boolean;
  private dialogBaseConfig: DynamicDialogConfig;
  private currentRole: string;
  private society: Society;
  private members: MemberSociety[];
  public membersSelectedDisplayedList: MemberSociety[] = [];
  public membersSelectedAdditionnalList: MenuItem[] = [];
  public canCreateUser: boolean = false;
  morePeopleTriggerEvent: string = "click";
  private isTabletSubscription: Subscription;

  @Input() searchEngine: boolean = false;

  public constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    public commonService: CommonService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private societyService: SocietyService,
    private roleService: RoleService,
    private modalService: ModalService,
  ) {}

  ngOnDestroy(): void {
    this.isTabletSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.membersSelected?.firstChange === false || changes.membersList?.firstChange === false) {
      // Réinitialisation du composant si :
      // - la liste des membres sélectionnés passée en paramètre est modifiée.
      // - ou la liste des membres sélectionnable passée en paramètre est modifiée.
      this.ngOnInit();
    }
  }

  ngOnInit(): void {
    this.canCreateUser = this.roleService.canCreateUser();
    this.dialogBaseConfig = new DynamicDialogConfig();

    this.isTabletSubscription = this.commonService.isTabletObservable.subscribe((isTablet) => {
      if (isTablet) {
        this.dialogBaseConfig.width = "100vw";
        this.dialogBaseConfig.height = window.innerHeight + "px";
        this.morePeopleTriggerEvent = "click";
      } else {
        this.dialogBaseConfig.width = "1030px";
        this.dialogBaseConfig.height = "auto";
        this.morePeopleTriggerEvent = "mouseover";
      }
    });

    this.dialogBaseConfig.showHeader = false;
    this.dialogBaseConfig.dismissableMask = true;

    this.currentRole = this.roleService.roles.value;

    this.society = this.societyService.society.value;
    this.members = this.society.members;

    this.onlySelected = false;
    this.membersList = this.membersList || this.config.data.membersList;
    this.membersSelected = this.membersSelected || this.config.data.membersSelected;
    this.count = this.membersSelected.length;
    this.title = this.title || this.config.data.title;
    this.max = this.max || this.config.data.max;
    this.isSearch = this.isSearch || this.config.data.isSearch;
    const newMembersList: Array<(MemberSociety | { invite: boolean }) & { isSelected: boolean }> = [];
    if (this.canCreateUser && this.searchEngine && this.commonService.isTablet === false) {
      // La valeur "null" correspond à l'option d'ajout d'un invité
      newMembersList.push({ invite: true, isSelected: false });
    }
    this.membersList.forEach((member: MemberSociety, idx: number) => {
      if (this.membersSelected) {
        const isSelected: number = this.membersSelected.findIndex(
          (memberSelected: MemberSociety) => member._id === memberSelected._id,
        );
        this.isMemberSelected = isSelected > -1;
        let isActive: boolean = true;
        if (member.user.status === -1) {
          isActive = false;
        } else if (member.invited) {
          if (member.activationDate && moment(member.activationDate).isAfter(moment())) {
            isActive = false;
          }
          if (member.expirationDate && moment(member.expirationDate).isBefore(moment())) {
            isActive = false;
          }
        }
        if (isActive) {
          newMembersList.push({
            ...JSON.parse(JSON.stringify(member)),
            isSelected: isSelected > -1,
          });
        }
      }
    });
    this.newMembersList = newMembersList;
    this.refreshSelectedMemberList();
  }

  toggleMemberStatus(newMember: MemberSociety & { isSelected: boolean }, newStatus?: boolean): void {
    this.changeMemberStatus(newMember, !newMember.isSelected);
  }

  changeMemberStatus(newMember: MemberSociety & { isSelected: boolean }, newStatus: boolean): void {
    if (newStatus === true) {
      if (this.max && this.max <= this.count) {
        return;
      }

      this.count++;
    } else {
      this.count--;
    }

    newMember.isSelected = newStatus;
    this.searchInput = "";
    this.newMembersList = this.newMembersList.slice();
    this.isMemberSelected = this.newMembersList.some(
      (member: (MemberSociety & { isSelected: boolean }) | null) => member?.isSelected,
    );
    this.onChangeSelectedMembers.emit(this.newMembersList.filter((member) => member?.isSelected) as MemberSociety[]);
    this.refreshSelectedMemberList();
  }

  private refreshSelectedMemberList() {
    if (!this.displaySelectedMembers) {
      this.displaySelectedMembers = Number.MAX_VALUE;
    }
    const selectedMembers: (MemberSociety & { isSelected: boolean })[] = this.newMembersList.filter(
      (member) => member?.isSelected,
    ) as (MemberSociety & { isSelected: boolean })[];
    this.membersSelectedDisplayedList = selectedMembers.filter((_, idx) => idx < this.displaySelectedMembers);
    this.membersSelectedAdditionnalList = selectedMembers
      .filter((_, idx) => idx >= this.displaySelectedMembers)
      .map((member) => ({
        id: member._id,
        label: member.user.username,
        icon: "pi pi-times",
        title: this.translateService.instant("MEMBERS.DELETE"),
        command: () => {
          this.changeMemberStatus(member, false);
        },
      }));
  }

  public returnInitial(text: string): string {
    return text
      .split(" ")
      .map((n: any) => n[0])
      .join(".")
      .toUpperCase();
  }
  allSelect(isSelected: boolean): void {
    this.newMembersList.forEach((member) => this.changeMemberStatus(member as any, isSelected));
  }
  submit(): void {
    const newSelected: Array<MemberSociety> = [];
    this.newMembersList.forEach((member: (MemberSociety & { isSelected: boolean }) | null) => {
      if (member?.isSelected) {
        delete member.isSelected;
        newSelected.push(member);
      }
    });
    this.ref.close(newSelected);
  }

  invite() {
    this.societyService.getBillings().subscribe((billings) => {
      this.dialogBaseConfig.data = {
        role: this.currentRole,
        society: this.society,
        members: this.members.slice(0),
        billings,
        invited: true,
        expirationDate: moment().add(1, "day").startOf("day").toDate(),
        activationDate: moment().startOf("day").toDate(),
      };

      // Dirty trick to avoid circular dependency
      import("./../../../management/members/modalMember/modalMember.component").then((res) => {
        this.modalService.openModal(res.ModalMemberComponent, this.dialogBaseConfig).onClose.subscribe((data) => {
          if (data && data.member) {
            let member = data.member;
            const society = this.societyService.society.value;
            society.members.push(member);
            this.membersList.push(member);
            this.newMembersList.push(member);
            this.changeMemberStatus(member, true);
          }
        });
      });
    });
  }

  closeModal(): void {
    const newSelected: Array<MemberSociety> = [];

    this.newMembersList.forEach((member: MemberSociety & { isSelected: boolean }) => {
      if (member?.isSelected) {
        delete member.isSelected;
        newSelected.push(member);
      }
    });

    this.ref.close(newSelected);
  }
}
