import {
  Component,
  Input,
  ViewEncapsulation,
  OnInit,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { CommonService } from "src/app/@shared/services/common.service";
import { TranslateService } from "@ngx-translate/core";
import { MemberSociety, Society } from "src/app/@shared/@types/society";
import { FolderService, FolderStatus } from "../../../../@shared/services/folder.service";
import moment from "moment";
import { filter, mergeMap } from "rxjs/operators";
import { UtilsTypes } from "../../../../@shared/@types/utils";

@Component({
  selector: "spt-booking-list",
  templateUrl: "./booking-list.component.html",
  styleUrls: ["./booking-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BookingListComponent implements OnInit, OnChanges {
  @Input() items: any[];
  @Input() society: Society;
  @Input() user: any;
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  public displayedItems: any[];
  public displayedItem: any;
  public members: MemberSociety[];
  public member: MemberSociety;
  public displayPanel: boolean;
  public locale: string;
  public choosenObject: any;

  constructor(
    public commonService: CommonService,
    private translateService: TranslateService,
    private folderService: FolderService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.members = this.society.members;
    this.locale = this.translateService.currentLang;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.members = this.society.members;
    if (changes.items && changes.items.currentValue && changes.items.currentValue.length > 0) {
      this.filter();
    }
  }

  openEticket(item: any): void {
    if (item.documentIds && item.documentIds.length > 0) {
      item.documentIds.forEach((documentId: string) => {
        this.commonService.openLink(documentId);
      });
    }
  }

  selectMember(event: any): void {
    this.member = event.value;
    this.filter();
  }

  filter(): void {
    if (!this.member) {
      if (this.commonService.isTablet) {
        this.displayedItems = this.items.filter((item: any) => {
          return item.travelers.find((_traveler: any) => _traveler.userId === this.user._id);
        });
      } else {
        this.displayedItems = [...this.items];
      }
    } else {
      this.displayedItems = this.items.filter((item: any) => {
        return item.travelers.find((_traveler: any) => _traveler.userId === this.member.user._id);
      });
    }
  }

  onSidebarClose(): void {
    this.commonService.statusBarTextWhite();
    this.choosenObject = undefined;
    // this.folderService
    //   .list(
    //     [FolderStatus.CONFIRMED],
    //     moment().format("YYYY-MM-DD"),
    //     moment().add(1, "month").format("YYYY-MM-DD")
    //   )
    //   .pipe(
    //     mergeMap((result: { folders: Array<any>; items: UtilsTypes.ObjectKey<Array<any>> }) => {
    //       return Object.values(result.items);
    //     }),
    //     filter((_items: Array<any>) => _items.length > 0),
    //   )
    //   .subscribe((_items: Array<any>) => {
    //     if (_items) {
    //       this.items = _items;
    //       this.filter();
    //     } else {
    //       this.displayedItems = [];
    //     }
    //     this.changeDetector.markForCheck();
    //   });
  }
}
