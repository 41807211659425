import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Message, SelectItem } from "primeng/api";
import { TrainTypes } from "../../../../../travel/train/train";
import { TrainService } from "../../../../../travel/train/train.service";
import { LoadingService } from "../../../../../@shared/services/loading.service";
import { CommonService } from "../../../../../@shared/services/common.service";
import { UtilsTypes } from "src/app/@shared/@types/utils";
import { forkJoin, mergeMap, Observable, of, Subscription } from "rxjs";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DateTime } from "luxon";
import { removeDuplicates } from "src/app/@shared/utils";

@Component({
  selector: "spt-reservations-cancel-train",
  templateUrl: "./train.component.html",
  styleUrls: ["./train.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservationsCancelTrainComponent implements OnInit, OnChanges {
  @Input() orderId: string;
  @Input() orderItems: Array<TrainTypes.OrderItem>;
  @Input() relatedOrderItems: Array<TrainTypes.RelatedOrderItem>;
  @Input() travelers: any[];
  @Input() provider: TrainTypes.Provider;
  @Input() cancellation: any;
  @Output() selectCancel: EventEmitter<{ orderId: string; options: Array<string>; reason: string }> =
    new EventEmitter();

  protected userItems: SelectItem<TrainTypes.NamedPassenger>[] = [];
  protected refundOptions: TrainTypes.Option[][] = [];
  protected journeys: TrainTypes.RefundJourney[] = [];
  protected selectedRefundOptions: TrainTypes.Option[] = [];
  protected noRefundOptions: boolean = false;
  protected messages: Message[] = [];
  protected partial: boolean = true;

  displayedReservationFees: UtilsTypes.Price = {
    amount: 0,
    currency: "EUR",
  };

  protected voucherRefund: boolean = false;

  private reservationFees: UtilsTypes.Price = {
    amount: 0,
    currency: "EUR",
  };

  private subscriptions: Subscription[] = [];
  protected cancelJourneyForm: FormGroup<CancelJourneyForm>;

  constructor(
    private formBuilder: FormBuilder,
    private trainService: TrainService,
    private changeDetector: ChangeDetectorRef,
    private loadingService: LoadingService,
    public commonService: CommonService,
  ) {}

  private checkVoucherRefund(): void {
    this.voucherRefund = this.orderItems.some((_orderItem: TrainTypes.OrderItem) => {
      return (
        _orderItem.carrier === "DB" &&
        _orderItem.item.sections.some((_section: TrainTypes.ItinerarySection) =>
          _section.fares.some((_fare: TrainTypes.Fare) => _fare.flexibility === "semiflexi"),
        )
      );
    });
  }

  private setupJourneyAndUserItems(): void {
    const refundOptions = this.refundOptions.flatMap((compatibleRefundOption) => compatibleRefundOption);

    this.messages = refundOptions
      .filter((refundOption) => !refundOption.isValid)
      .flatMap((refundOption) => refundOption.reasons)
      .map((reason) => ({
        detail: reason,
        severity: "warn",
      }));

    const validRefundOptions = refundOptions.filter((refundOption) => refundOption.isValid);
    this.partial = validRefundOptions.every((refundOption) => refundOption.partial);

    this.userItems = removeDuplicates(
      validRefundOptions.flatMap((refundOption) =>
        refundOption.passengers.map((passenger) => ({
          label: `${passenger.firstname} ${passenger.lastname}`,
          value: Object.assign(
            // Détermination du username si non définit dans passenger
            { username: `${passenger.firstname} ${passenger.lastname}` },
            passenger,
            { option: refundOption },
          ) as TrainTypes.NamedPassenger,
        })),
      ),
      // Clé de recherche des doublons
      (item1, item2) => item1.value.id === item2.value.id,
    );

    const now = DateTime.now().toMillis();

    this.journeys = removeDuplicates(
      validRefundOptions.flatMap((option) => option.journeys),
      // Clé de recherche des doublons
      (item1, item2) => item1.departure.name === item2.departure.name && item1.arrival.name === item2.arrival.name,
    )
      .filter(
        (_journey: TrainTypes.Journey) =>
          DateTime.fromMillis(getTime(_journey.departure.date.utc)).plus({ minutes: 20 }).toMillis() > now,
      )
      .sort((journeyA, journeyB): number => {
        if (journeyA.direction === journeyB.direction) {
          return getDepartureTime(journeyA) - getDepartureTime(journeyB);
        }
        return journeyA.direction === "outward" ? -1 : 1;
      });
    if (this.userItems.length === 0 && this.journeys.length === 0) {
      this.noRefundOptions = true;
    }
  }

  private setupFees(): void {
    this.orderItems.forEach((_orderItem: TrainTypes.OrderItem): void => {
      if (_orderItem.item.pricing.lineItems) {
        this.reservationFees = {
          amount: _orderItem.item.pricing.lineItems.reduce((amount: number, _lineItem: TrainTypes.LineItem): number => {
            return _lineItem.type === "reservation-fee" ? amount + _lineItem.price.amount : amount;
          }, 0),
          currency: _orderItem.item.pricing.totalAmount.currency as UtilsTypes.Currency,
        };
      }
    });

    if (this.reservationFees.amount > 0) {
      this.displayedReservationFees = {
        // TODO: Ce calcul parait étrange ... à revoir ? (cf. la division par le nombre d'options du lot d'index 0)
        amount: (this.reservationFees.amount / this.refundOptions[0].length) * this.selectedRefundOptions.length,
        currency: this.reservationFees.currency,
      };
    }
  }

  private setupRefundOptions(refundOptions: TrainTypes.Option[][]): void {
    this.refundOptions = refundOptions;

    if (refundOptions) {
      this.setupJourneyAndUserItems();
      this.setupFees();
      this.checkVoucherRefund();
    } else {
      this.noRefundOptions = true;
      this.trainService.synchronizePNR(this.orderId).subscribe();
    }
    this.loadingService.remove();
    this.changeDetector.markForCheck();
  }

  private initForm() {
    this.cancelJourneyForm = this.formBuilder.group<CancelJourneyForm>({
      passengersToRemove: this.formBuilder.control<TrainTypes.NamedPassenger[]>([]),
      journeysToRemove: this.formBuilder.control<TrainTypes.Journey[]>([]),
    });
    this.subscriptions.push(
      this.cancelJourneyForm.controls.passengersToRemove.valueChanges.subscribe((selectedTravelerIds) =>
        this.onSelectTravelers(selectedTravelerIds),
      ),
      this.cancelJourneyForm.controls.journeysToRemove.valueChanges.subscribe((selectedJourneyIds) =>
        this.onSelectJourneys(selectedJourneyIds),
      ),
    );
  }

  ngOnInit(): void {
    this.loadingService.add();
    this.initForm();
    if (this.relatedOrderItems?.some((item) => item.orderLinkType === "target")) {
      forkJoin(
        this.relatedOrderItems.map((_relatedOrderItem: TrainTypes.RelatedOrderItem) =>
          this.trainService.getRefundables(_relatedOrderItem.orderId, this.provider),
        ),
      )
        .pipe(
          mergeMap((refundables: TrainTypes.Option[][][]): Observable<TrainTypes.Option[][]> => of(refundables.flat())),
        )
        .subscribe({
          next: (refundOptions: TrainTypes.Option[][]): void => {
            this.setupRefundOptions(refundOptions);
          },
          error: (): void => this.loadingService.remove(),
        });
    } else {
      this.trainService.getRefundables(this.orderId, this.provider).subscribe({
        next: (refundOptions: TrainTypes.Option[][]): void => this.setupRefundOptions(refundOptions),
        error: (): void => this.loadingService.remove(),
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cancellation && !changes.cancellation.firstChange && changes.cancellation.currentValue === undefined) {
      this.trainService.synchronizePNR(this.orderId).subscribe();
    }
  }

  private onSelectTravelers(selectedTravelerIds: TrainTypes.NamedPassenger[]): void {
    // Lors de la (dé)sélection d'un passager, on reset la sélection des trajets
    this.cancelJourneyForm.controls.journeysToRemove.setValue([], { emitEvent: false });

    let selectedRefundOptions = [];

    if (selectedTravelerIds.length > 0) {
      if (this.provider === "sabre") {
        if (this.refundOptions[0].length === selectedTravelerIds.length) {
          // Annulation de la totalité
          selectedRefundOptions = this.refundOptions[0];
        } else {
          // Annulation que d'une partie des passagers. On ne doit donc prendre que les offres compatible avec une anulation partielle
          selectedRefundOptions = this.refundOptions[0].filter((option) => {
            return (
              option.isValid &&
              option.partial &&
              option.passengers.length > 0 &&
              hasPassenger(option, selectedTravelerIds)
            );
          });
        }
      } else {
        // TODO : comment gérer les annulations totales dans ce cas ?
        this.refundOptions.forEach((compatibleOptions: Array<TrainTypes.Option>) => {
          const options = compatibleOptions.filter(
            (option: TrainTypes.Option) =>
              option.isValid &&
              option.partial &&
              option.passengers.length > 0 &&
              hasPassenger(option, selectedTravelerIds),
          );

          // TODO: étrange -> pourquoi écraser les passager du précédent lot si le suivant contient au moins un des passagers sélectionnés ?
          if (options.length) {
            selectedRefundOptions = options;
          }
        });
      }
    }
    this.selectedRefundOptions = selectedRefundOptions;

    this.updateDisplayedReservationFeesIfNeeded();
    this.emitEventForSelectedRefundOptions();
  }

  private onSelectJourneys(selectedJourneys: TrainTypes.Journey[]): void {
    // Lors de la (dé)sélection d'un trajet, on reset la sélection des passagers
    this.cancelJourneyForm.controls.passengersToRemove.setValue([], { emitEvent: false });

    let selectedRefundOptions = [];
    if (selectedJourneys.length > 0) {
      let compatibleIndex: number = 0;

      if (this.provider === "sabre") {
        if (this.journeys.length === selectedJourneys.length) {
          // Annulation de la totalité
          selectedRefundOptions =
            this.refundOptions.find((options) =>
              // Pour Sabre, l'attribut "partial" n'est pas fiable, donc pour l'option de remboursement total,
              // on cherche l'option de remboursement où n'est pas spécifié de segment ni de passager
              options.some(
                (option) => option.isValid && option.journeys.length === 0 && option.passengers.length === 0,
              ),
            ) || [];
        } else {
          // Annulation que d'une partie des trajets. On ne doit donc prendre que les offres compatibles avec une anulation partielle
          const criteria = (option) =>
            option.isValid && option.partial && option.journeys.length > 0 && hasJourney(option, selectedJourneys);
          selectedRefundOptions =
            this.refundOptions.map((options) => options.filter(criteria)).find((options) => options.length > 0) || [];
        }
      } else {
        this.refundOptions.forEach((_compatibleOptions: TrainTypes.Option[], refundOptionIndex: number): void => {
          if (refundOptionIndex !== compatibleIndex) {
            compatibleIndex++;
          }

          _compatibleOptions
            .filter(
              (option: TrainTypes.Option): boolean =>
                option.isValid && option.journeys.length > 0 && hasJourney(option, selectedJourneys),
            )
            .forEach((_compatibleOption: TrainTypes.Option): void => {
              if (
                !this.hasSelectedRefundOption(_compatibleOption) ||
                (compatibleIndex === refundOptionIndex && !_compatibleOption.partial)
              ) {
                selectedRefundOptions.push(_compatibleOption);
              }
            });
        });
      }
    }
    this.selectedRefundOptions = selectedRefundOptions;
    this.updateDisplayedReservationFeesIfNeeded();
    this.emitEventForSelectedRefundOptions();
  }

  /**
   * Vérifie si l'option indiquée en paramètre fait partie des options de remboursement sélectionnée.
   */
  private hasSelectedRefundOption(option: TrainTypes.Option) {
    return this.selectedRefundOptions.some((_selectedOption: TrainTypes.Option): boolean => {
      return _selectedOption.id === option.id;
    });
  }

  private updateDisplayedReservationFeesIfNeeded() {
    if (this.reservationFees.amount === 0) {
      return;
    }
    if (this.selectedRefundOptions.length === 0) {
      this.displayedReservationFees.amount = this.reservationFees.amount;
    } else {
      this.displayedReservationFees.amount =
        (this.reservationFees.amount / this.refundOptions[0].length) * this.selectedRefundOptions.length;
    }
    this.changeDetector.markForCheck();
  }

  private emitEventForSelectedRefundOptions() {
    if (this.selectedRefundOptions.length) {
      this.selectCancel.emit({
        orderId: this.orderId,
        options: this.selectedRefundOptions.map((_option: TrainTypes.Option) => _option.id),
        reason: "CUSTOMER_REQUESTED",
      });
    } else {
      this.selectCancel.emit(undefined);
    }
  }
}

/**
 * Permet de déterminer si l'option concerne au moins un des passagers contenu dans la liste passée en paramètre
 */
function hasPassenger(option: TrainTypes.Option, passengers: TrainTypes.NamedPassenger[]): boolean {
  return option.passengers.some((optionPassenger) =>
    passengers.some((passenger) => optionPassenger.id === passenger.id),
  );
}

/**
 * Permet de déterminer si l'option concerne au moins un des trajets contenu dans la liste passée en paramètre
 */
function hasJourney(option: TrainTypes.Option, journeys: TrainTypes.Journey[]): boolean {
  return option.journeys.some((optionJourney: TrainTypes.Journey): boolean =>
    journeys.some((journey: TrainTypes.Journey) => isSameJourney(optionJourney, journey)),
  );
}

function isSameJourney(j1: TrainTypes.Journey, j2: TrainTypes.Journey) {
  return (
    j1.id === j2.id ||
    (j1.departure.locationId === j2?.departure.locationId && j1.arrival.locationId === j2?.arrival.locationId)
  );
}

function getDepartureTime(journey): number {
  const utc = journey.departure.date.utc;
  return typeof utc.getTime === "function" ? utc.getTime() : Date.parse(utc.toString());
}
function getTime(date: any): number {
  if (!date) {
    return NaN;
  }
  if (typeof date.getTime === "function") {
    return date.getTime();
  }
  return Date.parse(date.toString());
}
export interface CancelJourneyForm {
  passengersToRemove: FormControl<TrainTypes.NamedPassenger[]>;
  journeysToRemove: FormControl<TrainTypes.Journey[]>;
}
