import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { datadogLogs } from "@datadog/browser-logs";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

declare var google: any;

console.debug = function () {
  console.log(`%c${arguments[0]}`, "background: #222; color: #55D1D0");
};

if (environment.production) {
  enableProdMode();

  // console.log = () => {};
  // console.trace = () => {};
  // console.debug = () => {};
  // console.info = () => {};
  // console.warn = () => {};
  // console.error = () => {};

  datadogLogs.init({
    clientToken: "pub1563707fc94a0f5372037de4077a0fa0",
    site: "datadoghq.eu",
    service: "spt-front",
    env: "production",
    version: "1",
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err: any) => console.error(err));
