import { DOCUMENT } from "@angular/common";
import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivationEnd, Event, NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { MenuItem } from "primeng/api";
import { Dialog } from "primeng/dialog";
import { Subscription } from "rxjs";

import { map, filter } from "rxjs/operators";
import { Requests } from "src/app/@shared/@types/models";
import { Roles } from "src/app/@shared/@types/society";
import { User } from "src/app/@shared/@types/user";
import { MenuService } from "src/app/@shared/components/menu/menu.service";
import { CommonService } from "src/app/@shared/services/common.service";
import { RoleService } from "src/app/@shared/services/roles.service";
import { SessionService, SessionType } from "src/app/@shared/services/session.service";

import { UserService } from "src/app/@shared/services/user.service";
import { AuthService } from "../../../../auth/auth.service";
import { BasketService, FILTER_HISTORY } from "src/app/@shared/services/basket.service";
import { ResetService } from "src/app/service_initializer/reset.service";

declare const window: any;

@Component({
  selector: "spt-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() negative: boolean;
  public user: User;
  public displayed: boolean;
  public background: boolean = false;
  public items: Array<MenuItem>;
  public headersLink: Array<any>;
  public bookRequests: Array<Requests>;
  public bookRequestsLoaded: boolean;
  public managersLoaded: boolean;
  public pendingBookRequests: Array<Requests>;
  public usersAndManagers: Map<string, Array<User>> = new Map();
  public isDialogVisible: boolean;
  public isCordova: boolean;
  public bookrequestManagers: any = {};
  private subscriptions: Subscription[] = [];
  public routeHome: boolean = false;
  protected currentOpenedBasketId: string = undefined;
  protected countingBaskets: number = undefined;

  constructor(
    public roleService: RoleService,
    private userService: UserService,
    public router: Router,
    public commonService: CommonService,
    private sessionService: SessionService<SessionType>,
    private translateService: TranslateService,
    private resetService: ResetService,
    private menuService: MenuService,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
    public basketService: BasketService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => (this.routeHome = event.url === "/"));

    this.items = [];
    this.headersLink = [];

    this.displayed = false;

    // don't fire the first time if not in constructor
    this.subscriptions.push(
      this.router.events
        .pipe(
          filter(
            (event: Event) => event instanceof ActivationEnd && event.snapshot && event.snapshot.children.length === 0,
          ),
        )
        .subscribe((event: any) => {
          if (event.snapshot.data.backgroundHeader !== undefined) {
            this.background = event.snapshot.data.backgroundHeader;
          } else {
            this.background = false;
          }
          if (event.snapshot.data.displayHeaderMobile !== undefined && this.commonService.isTablet) {
            this.displayed = event.snapshot.data.displayHeaderMobile;
          } else {
            this.displayed = true;
          }
        }),
    );
  }

  ngOnInit(): void {
    this.bookRequestsLoaded = false;
    this.managersLoaded = false;
    this.document.addEventListener(
      "deviceready",
      () => {
        this.isCordova = this.commonService.isCordova();
      },
      false,
    );

    this.subscriptions.push(
      this.roleService.roles.pipe(filter((role: Roles) => !!role)).subscribe((role: Roles) => {
        this.setMenuItems(role);
        this.subscriptions.push(
          this.translateService.onLangChange.subscribe(() => {
            this.setMenuItems(role);
          }),
        );
      }),
      this.userService.user.pipe(filter((user: User) => !!user)).subscribe((user: User) => {
        this.user = user;
        this.currentOpenedBasketId = this.user.activeBasket ?? "";
      }),

      this.basketService.counting$.subscribe((counting) => {
        this.countingBaskets = counting.validating + counting.inProgress;
      }),
    );
  }

  displayMenu(): void {
    this.menuService.display();
  }

  removeMenu(): void {
    this.menuService.remove();
  }

  showDialogMaximized(dialog: Dialog): void {
    dialog.maximize();
  }

  customMoment(date?: string): moment.Moment {
    if (date) {
      return moment(date.replace(/Z/, ""));
    }
    return moment();
  }

  ngOnDestroy(): void {
    this.subscriptions.filter((s) => !s.closed).forEach((s) => s.unsubscribe());
  }

  setMenuItems(role: Roles): void {
    const items: Array<MenuItem> = [
      {
        routerLink: "/settings/account/general",
        label: this.translateService.instant("HEADER.MY_ACCOUNT"),
        icon: "account-circle",
      },
    ];
    const headersLink: Array<any> = [
      {
        routerLink: "/",
        label: this.translateService.instant("ACCOUNT.GENERAL.TRAVEL"),
      },
      {
        routerLink: "/management/bookings",
        label: this.translateService.instant("BOOKING.RESERVATIONS"),
      },
      {
        routerLink: "/reporting",
        label: this.translateService.instant("Reporting"),
      },
    ];

    if (role === "executive") {
      items.push(
        {
          routerLink: "/management/members/list",
          label: this.translateService.instant("HEADER.COLLABORATORS"),
          icon: "seminary",
        },
        {
          routerLink: "/management/billings",
          label: this.translateService.instant("HEADER.BILLS"),
          icon: "billings",
        },
        {
          routerLink: "/management/policies",
          label: this.translateService.instant("HEADER.POLICY"),
          icon: "policy",
        },
        {
          routerLink: "/settings/society/train",
          label: this.translateService.instant("HEADER.MY_SOCIETY"),
          icon: "society",
        },
      );
    } else if (role === "booker") {
      items.push({
        routerLink: "/management/members",
        label: this.translateService.instant("HEADER.COLLABORATORS"),
        icon: "seminary",
      });
    } else if (role === "accountant") {
      items.push(
        {
          routerLink: "/management/members",
          label: this.translateService.instant("HEADER.COLLABORATORS"),
          icon: "seminary",
        },
        {
          routerLink: "/management/billings",
          label: this.translateService.instant("HEADER.BILLS"),
          icon: "billings",
        },
      );
      headersLink.push({
        routerLink: "/management/billings",
        label: this.translateService.instant("HEADER.BILLS"),
        icon: "billings",
      });
    }
    items.push(
      {
        routerLink: "/management/csr",
        label: this.translateService.instant("HEADER.CSR"),
        icon: "rse",
      },
      {
        command: (): void => this.logOut(),
        label: this.translateService.instant("HEADER.LOGOUT"),
        icon: "logout",
      },
    );
    const currentLang =
      this.translateService.currentLang == "undefined" || null
        ? "en"
        : this.translateService.currentLang === "es"
          ? "en"
          : this.translateService.currentLang;
    headersLink.push({
      link: `https://intercom.help/supertripper/${currentLang}/`,
      label: this.translateService.instant("HEADER.HELP"),
      target: "_blank",
    });

    this.items.length = 0;
    this.items.push(...items);
    this.headersLink.length = 0;
    this.headersLink.push(...headersLink);
    this.cd.detectChanges();
  }

  logOut(): void {
    const userProfile: any = this.authService.getUserProfile();
    console.log("logout to OneSignal");
    // window.OneSignalDeferred.push(function (OneSignal) {
    //   OneSignal.logout();
    // });
    if (this.user.settings.auth && this.user.settings.auth.sso && userProfile) {
      this.authService
        .logout(userProfile, this.commonService.isCordova())
        .pipe(
          map((data: { logoutUri: string }) => {
            const browser: Window = window.open(
              data.logoutUri,
              this.commonService.isCordova() === true ? "_system" : "_self",
              "location=no",
            );
            browser.onunload = (): void => {
              browser.close();
            };
            this.authService.clearUserProfile();
            this.resetService.resetAllServices();
            return this.sessionService.close();
          }),
        )
        .subscribe();
    } else {
      this.resetService.resetAllServices();
      this.sessionService.close().subscribe();
    }
  }
}
