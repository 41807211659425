import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "isFuture",
})
export class IsFuturePipe implements PipeTransform {
  constructor() {}

  transform(reservation: any): boolean {
    try {
      switch (reservation.type) {
        case "train":
          if (reservation.item && reservation.item.metadata.optBooking) {
            return reservation.item.metadata.oads.some((oad: any) => {
              return new Date(oad.departAt) > new Date();
            });
          } else if (reservation.item && reservation.item.metadata.ptps) {
            return reservation.item.metadata.ptps.some((ptp: any) => {
              return new Date(ptp.departure.time) > new Date();
            });
          } else if (reservation.limitDate) {
            return new Date(reservation.limitDate) > new Date();
          }
          break;
        default:
          return false;
      }
    } catch (error) {
      return false;
    }
  }
}
