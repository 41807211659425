import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { RoleService } from "./roles.service";
import { map, filter, take } from "rxjs/operators";
import { Roles } from "../@types/society";

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  constructor(
    private rolesService: RoleService,
    private router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.rolesService.roles.pipe(
      filter((role: Roles) => !!role),
      map((role: Roles) => {
        switch (state.url) {
          case `/management/billings`:
            return this.is(role, ["executive", "accountant"]);
          case `/management/members`:
            return this.is(role, ["executive", "booker", "accountant"]);
          case `/management/policies`:
            return this.is(role, ["executive"]);
          case `management/bookings`:
            return this.is(role, ["executive", "booker", "traveler"]);
          default:
            return true;
        }
      }),
      take(1),
    );
  }

  is(currentRole: Roles, requiredRoles: Array<Roles>): boolean {
    if (requiredRoles.indexOf(currentRole) > -1) {
      return true;
    }
    this.router.navigate([""]);
    return false;
  }
}
