<div *ngIf="item.labels && item.labels.length > 0 && item.status !== 'cancelled'" class="labels">
  <div>
    <p class="label-title" translate>SEARCH.LABELS</p>
    <div class="edit">
      <span (mousedown)="editLabels()" translate>LABELS.UPDATE_VALUES</span>
    </div>
  </div>
  <div class="labels-list">
    <p *ngFor="let label of item.labels">
      <span>{{ label.label }} :</span>
      {{ (label.chosen.label ? label.chosen.label : label.chosen.value) || "LABELS.NO_INFORMATION" | translate }}
    </p>
  </div>
</div>
<div
  class="footer-buttons"
  [ngClass]="
    item.type === 'train' && !(item.status === 'cancelled' || item.status === 'modified')
      ? 'train'
      : item.provider === 'afkl' && item.type === 'flight'
        ? 'flight'
        : ''
  "
>
  <div class="bills" *ngIf="bills?.length > 0" (click)="openBills(bills)">
    <img src="./../../../../assets/svg/pdf.svg" />
    <span *ngIf="bills?.length > 1" class="ng-star-inserted" translate>BOOKING.BILLS.MULTIPLE_RECEIPTS</span>
    <span *ngIf="bills?.length < 2" class="ng-star-inserted" translate>BOOKING.BILLS.SINGLE_RECEIPT</span>
  </div>
  <ng-container *ngIf="item.type !== 'train' && item.provider !== 'afkl' && item.status !== 'cancelled'">
    <button *ngIf="showCancelButton" class="cancel" (click)="cancelReservation()">
      <span *ngIf="!isSmallDevice">{{ "BOOKING.MY_CANCELLATION_SIDEBAR" | translate }}</span>
      <span *ngIf="isSmallDevice">{{ "GLOBAL.CANCEL" | translate }}</span>
    </button>
    <button
      class="e-ticket"
      *ngIf="item?.documentIds?.length > 0 && item?.status !== 'error' && item.status !== 'cancelled'"
      (click)="openEtickets(item)"
    >
      <img src="./assets/svg/download.svg" />
      <span *ngIf="!isSmallDevice">{{ "BOOKING.SEE_ETICKET" | translate }}</span>
      <span *ngIf="isSmallDevice">E-ticket</span>
    </button>
  </ng-container>

  <ng-container *ngIf="item.type === 'train' && !(item.status === 'cancelled' || item.status === 'modified')">
    <p class="text-center offline" *ngIf="item.offline; else onlineItemActions" translate>ITEM.DETAIL.OFFLINE</p>
    <ng-template #onlineItemActions>
      <div class="online-item-actions">
        <button
          class="cancel"
          [class.w-full]="showCancelButton && !showModifyButton"
          *ngIf="showCancelButton"
          (click)="cancelReservation()"
        >
          <span *ngIf="!isSmallDevice">{{ "BOOKING.MY_CANCELLATION_SIDEBAR" | translate }}</span>
          <span *ngIf="isSmallDevice">{{ "GLOBAL.CANCEL" | translate }}</span>
        </button>
        <button
          class="modif"
          [class.w-full]="!showCancelButton && showModifyButton"
          *ngIf="showModifyButton"
          (click)="modifyReservation()"
        >
          <span>
            {{
              (item.detail.afterSales &&
              !item.detail.afterSales.refundable &&
              item.detail.afterSales.changeable &&
              isUKTravel
                ? "RESERVATIONS.TRAIN.CHANGE_OF_JOURNEY"
                : "GLOBAL.MODIFY"
              ) | translate
            }}
          </span>
        </button>
      </div>
    </ng-template>
    <button
      class="e-ticket"
      *ngIf="item?.documentIds?.length > 0 && item?.status === 'confirmed'"
      (click)="openEtickets(item)"
    >
      <img src="./assets/svg/download.svg" />
      <span *ngIf="!isSmallDevice">{{ "BOOKING.SEE_ETICKET" | translate }}</span>
      <span *ngIf="isSmallDevice">E-ticket</span>
    </button>
  </ng-container>
  <ng-container
    *ngIf="
      item.provider === 'afkl' && item.type === 'flight' && !(item.status === 'cancelled' || item.status === 'modified')
    "
  >
    <p class="text-center offline" *ngIf="item.offline; else onlineItemActions" translate>ITEM.DETAIL.OFFLINE</p>
    <ng-template #onlineItemActions>
      <div class="online-item-actions">
        <button
          class="cancel"
          [class.w-full]="showCancelButton && !showModifyButton"
          *ngIf="showCancelButton"
          (click)="cancelReservation()"
        >
          <span *ngIf="!isSmallDevice">{{ "BOOKING.MY_CANCELLATION_SIDEBAR" | translate }}</span>
          <span *ngIf="isSmallDevice">{{ "GLOBAL.CANCEL" | translate }}</span>
        </button>
        <button
          class="modif"
          [class.w-full]="!showCancelButton && showModifyButton"
          *ngIf="showModifyButton"
          (click)="modifyReservation()"
        >
          <span>
            {{
            (item.detail.afterSales &&
              !item.detail.afterSales.refundable &&
              item.detail.afterSales.changeable &&
              isUKTravel
                ? "RESERVATIONS.TRAIN.CHANGE_OF_JOURNEY"
                : "GLOBAL.MODIFY"
            ) | translate
            }}
          </span>
        </button>
      </div>
    </ng-template>
    <button
      class="e-ticket"
      *ngIf="item?.documentIds?.length > 0 && item?.status === 'confirmed'"
      (click)="openEtickets(item)"
    >
      <img src="./assets/svg/download.svg" />
      <span *ngIf="!isSmallDevice">{{ "BOOKING.SEE_ETICKET" | translate }}</span>
      <span *ngIf="isSmallDevice">E-ticket</span>
    </button>
  </ng-container>
</div>
<p class="canceled-text" *ngIf="item.status === 'cancelled'">
  {{ "BOOKING.CANCEL_REFUND" | translate }}
  {{ item.cancellation ? (item.cancellation.price | SPTPrice: item.cancellationDate | async) : "0 €" }}
</p>
