<div style="margin: 11px" id="strength" #strength>
  <small
    >{{ "PASSWORD.STRENGTH.TITLE" | translate }}: <b>{{ barLabel | translate }}</b></small
  >
  <ul id="strengthBar" class="strength-bar">
    <li class="point" [style.background-color]="bar0"></li>
    <li class="point" [style.background-color]="bar1"></li>
    <li class="point" [style.background-color]="bar2"></li>
    <li class="point" [style.background-color]="bar3"></li>
    <li class="point" [style.background-color]="bar4"></li>
  </ul>
  <div class="password-helper" *ngIf="showIndications">
    <span *ngIf="!passwordValid; else validMessage">
      {{ "PASSWORD.VALIDATORS.INVALID_MESSAGE" | translate }}
    </span>
    <ng-template #validMessage>
      <span class="valid">{{ "PASSWORD.VALIDATORS.VALID" | translate }}</span>
    </ng-template>
    <ul>
      <li *ngIf="!requirements['min8digits']">
        {{ "PASSWORD.VALIDATORS.MINCHAR" | translate }}
      </li>
      <li *ngIf="!requirements['upperCase']">
        {{ "PASSWORD.VALIDATORS.UPPERCASE" | translate }}
      </li>
      <li *ngIf="!requirements['lowerCase']">
        {{ "PASSWORD.VALIDATORS.LOWERCASE" | translate }}
      </li>
      <li *ngIf="!requirements['digit']">
        {{ "PASSWORD.VALIDATORS.DIGIT" | translate }}
      </li>
      <li *ngIf="!requirements['specialCharacter']">
        {{ "PASSWORD.VALIDATORS.SPECIALCHARACTER" | translate }}
      </li>
    </ul>
  </div>
</div>
