import { Injectable } from "@angular/core";
import { AbstractPlaceSearchService } from "../abstract-place-search.service";
import {
  DateTimeSearch,
  SearchCriteria,
  SearchOptions,
  SearchResult,
  Travel,
  WhenSearch,
  checkLocalityIsValid,
} from "../search-engine-service";
import { SearchService } from "src/app/@shared/services/search.service";
import { TranslateService } from "@ngx-translate/core";
import { HotelTypes } from "src/app/@shared/@types/hotel";
import { Observable } from "rxjs";
import { DateTime } from "luxon";
import { Address } from "src/app/@shared/@types/models";
import { HttpClient } from "@angular/common/http";
import { MemberSociety } from "src/app/@shared/@types/society";

@Injectable({
  providedIn: "root",
})
export class HotelSearchService extends AbstractPlaceSearchService<HotelSearchOptions> {
  constructor(
    private searchService: SearchService,
    private translateService: TranslateService,
    httpClient: HttpClient,
  ) {
    super(httpClient);
  }

  override searchCriteriaIsValid(searchCriteria: SearchCriteria<Address, HotelSearchOptions>): boolean {
    // Dans le cadre de l'hotel, la destination est forcément la même pour tout les travels
    searchCriteria.travels.forEach((travel) => (travel.destination = searchCriteria.mainTravel.destination));
    let isValid = searchCriteria.isValid();

    // Pour l'hotel, la date de départ est indispensable
    isValid = isValid && this.checkOutwardIsValid(searchCriteria);

    return isValid;
  }

  private checkOutwardIsValid(searchCriteria: SearchCriteria<Address, HotelSearchOptions>): boolean {
    return !searchCriteria.travels.some(
      (travel) => !(travel.when.outward && travel.when.outward.date !== travel.when.inward.date),
    );
  }

  getType(): "hotel" {
    return "hotel";
  }

  getMaxPassengers(searchCriteria: SearchCriteria<Address, HotelSearchOptions>): number {
    return 4;
  }

  launchSearch(searchCriteria: SearchCriteria<Address, HotelSearchOptions>): Observable<SearchResult> {
    let data: HotelTypes.Search = {
      completeAddress: searchCriteria.mainTravel.destination,
      datein: searchCriteria.mainTravel.when.outward.date,
      dateout: searchCriteria.mainTravel.when.inward.date,
      rooms: searchCriteria.travels.map((c) => ({ members: c.people })),
      breakfast: searchCriteria.options.breakfast,
      cancellable: searchCriteria.options.cancellable,
      stars: searchCriteria.options.stars,
      userIds: searchCriteria.travels.flatMap((searchCriteria) => searchCriteria.people).map((p) => p.user._id),
    };
    return this.searchService.create(this.getType(), data, this.translateService.currentLang);
  }

  createCriteriaFromBasketItem(
    lastFolderItemsInBasket: any[],
    members: MemberSociety[],
  ): SearchCriteria<Address, HotelSearchOptions> {
    const basketItem = lastFolderItemsInBasket?.[0];
    if (!basketItem) {
      return undefined;
    }
    return new SearchCriteria<Address, HotelSearchOptions>(
      new HotelSearchOptions(),
      new Travel<Address>(
        basketItem?.travelers
          .map((traveler) => members.find((member) => member.user?._id === traveler.userId))
          .filter((member) => !!member),
        new WhenSearch(
          new DateTimeSearch(basketItem.detail.datein.date),
          new DateTimeSearch(basketItem.detail.dateout.date),
        ),
        this.createLocalityFromJson(basketItem.detail),
      ),
    );
  }

  addRoomTo(searchCriteria: SearchCriteria<Address, HotelSearchOptions>): void {
    const firstRoom = searchCriteria.mainTravel;
    searchCriteria.travels.push(new Travel<Address>([], firstRoom.when, firstRoom.destination));
  }
  private createDateTimeSearchFromPreviousSearch(isodate: string): DateTimeSearch {
    if (!isodate) {
      return undefined;
    }
    return Object.assign(new DateTimeSearch(), {
      date: DateTime.fromISO(isodate).toISODate(),
    });
  }

  createCriteriaFromPreviousSearch(previousSearch?: any): SearchCriteria<Address, HotelSearchOptions> {
    if (!previousSearch) {
      return undefined;
    }
    const whenSearch = new WhenSearch(
      this.createDateTimeSearchFromPreviousSearch(previousSearch.datein),
      this.createDateTimeSearchFromPreviousSearch(previousSearch.dateout),
    );
    const destination = this.createLocalityFromJson(previousSearch.completeAddress);
    const hotelSearchOptions = Object.assign(new HotelSearchOptions(), {
      stars: previousSearch.stars,
      cancellable: previousSearch.cancellable,
      breakfast: previousSearch.breakfast,
    });

    const travels: Travel<Address>[] = previousSearch.rooms.map(
      (room) => new Travel<Address>(room.members, whenSearch, destination),
    );

    return new SearchCriteria<Address, HotelSearchOptions>(hotelSearchOptions, ...travels);
  }

  createBlankCriteria(): SearchCriteria<Address, HotelSearchOptions> {
    return new SearchCriteria<Address, HotelSearchOptions>(
      new HotelSearchOptions(),
      new Travel<Address>(
        [],
        new WhenSearch(new DateTimeSearch(), new DateTimeSearch()),
        this.createDummyLocalityFromName("titi"),
      ),
    );
  }
}

export class HotelSearchOptions implements SearchOptions {
  stars: { [key: string]: boolean };

  cancellable: boolean;

  breakfast: boolean;

  constructor() {
    this.stars = {
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
    };
    this.cancellable = false;
    this.breakfast = false;
  }

  isValid(): boolean {
    return true;
  }
}
