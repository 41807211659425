<form
  (submit)="search()"
  class="form hasOrigin"
  [class.hasDestination]="!searchCriteria.options.destinationSameAsOrigin"
  [ngClass]="'suggest-' + suggestPanelService.active.name"
  [class.suggestOpened]="suggestOpened"
>
  <div class="title">
    <div class="flex justify-content-content items-center">
      <img class="icon-travel" src="./assets/svg/{{ searchEngineType }}.svg" />
      <h1 class="label" [innerHTML]="'SEARCH.CAR.TITLE' | translate"></h1>
    </div>
  </div>

  <spt-travel-search-autocomplete
    [label]="{
      origin: 'SEARCH.CAR.WHERE_LABEL' | translate,
      destination: 'SEARCH.RETURN' | translate
    }"
    [placeholder]="{
      origin: 'SEARCH.CAR.PICKUP_ADDRESS_LABEL' | translate,
      destination: 'SEARCH.CAR.RETURN_ADDRESS_LABEL' | translate
    }"
    [optionDestinationSameAsOriginLabel]="'SEARCH.CAR.SAME_ADDRESS_LABEL' | translate"
    [destinationSameAsOrigin]="searchCriteria.options.destinationSameAsOrigin"
    [searchService]="searchService"
    [travel]="searchCriteria.mainTravel"
    (onCloseSuggest)="resetSuggestPanel()"
    (onTravelChange)="checkValidity()"
    (onDestinationSameAsOriginChange)="changeDestinationSameAsOrigin($event)"
    [nextFocus]="searchDatetimeComponent"
  ></spt-travel-search-autocomplete>
  <spt-search-datetime
    #searchDatetimeComponent
    [i18n]="{
      outwardLabelDate: 'SEARCH.CHECKIN' | translate,
      outwardLabelTime: 'GLOBAL.HOUR' | translate,
      inwardLabelDate: 'SEARCH.CHECKOUT' | translate,
      inwardLabelTime: 'GLOBAL.HOUR' | translate,
      outwardDatePlaceholder: 'GLOBAL.DATE' | translate,
      inwardDatePlaceholder: 'GLOBAL.DATE' | translate,
      oneWaySuggestPanelTitle: 'SEARCH.CAR.DATETIME_TITLE' | translate
    }"
    [hourType]="false"
    [yearRange]="yearRange"
    [sourceWhenCriteria]="searchCriteria.mainTravel.when"
    (onActivateSuggest)="activateSuggest($event)"
    (onCloseSuggest)="resetSuggestPanel()"
    calendarSelectionMode="range"
    (onDatetimeChange)="checkValidity()"
  ></spt-search-datetime>
  <spt-search-people
    (onActivateSuggest)="activateSuggest($event)"
    [people]="searchCriteria.mainTravel.people"
    (onPeopleListChange)="checkValidity()"
    (onCloseSuggest)="resetSuggestPanel()"
    [searchEngineType]="searchEngineType"
    [membersMax]="1"
  ></spt-search-people>
  <div class="options" style="grid-area: options"></div>
  <div class="triggers submit">
    <p-button type="submit" [disabled]="!isValid" [label]="'SEARCH.CTA' | translate"></p-button>
  </div>
</form>
