<div class="detail">
  {{ hotel?.description?.description }}
</div>
<div class="stuffs">
  <div class="stuff" *ngFor="let category of hotel?.description?.shortFacilities">
    <div class="imageTitle">
      <img src="./assets/svg/facilities/{{ category }}.svg" alt="" />
      <span>{{ "SEARCH.HOTEL.FACILITIES." + category | translate }}</span>
    </div>
    <ul class="facilities">
      <li class="facility" *ngFor="let facility of facilitiesByCategory[category]">
        {{ facility.label }}
      </li>
    </ul>
  </div>
</div>
