<div class="etickets-modal">
  <div class="etickets-header">
    <h2 class="etickets-title">
      {{ "BOOKING.ETICKETS" | translate }}
      {{ "BILLINGS." + type | uppercase | translate }}
      N° {{ reference }}
    </h2>

    <i *ngIf="this.commonService.isTablet" class="fas fa-times fa-2x" (mousedown)="closeModal()"></i>
  </div>

  <div class="etickets-container">
    <button
      class="eticket-button p-button-secondary"
      pButton
      *ngFor="let eticket of etickets; let i = index"
      (mousedown)="openEticket(i)"
      [label]="'BOOKING.ETICKET' | translate: { number: i + 1 }"
    ></button>
  </div>
</div>
