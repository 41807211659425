import { Pipe, PipeTransform } from "@angular/core";
import Fuse from "fuse.js";

@Pipe({
  name: "fusePipe",
})
export class FusePipe implements PipeTransform {
  transform(items: any[], fuseOptions: any): Array<any> {
    if (fuseOptions && fuseOptions.value) {
      const options: Fuse.IFuseOptions<any> = {
        shouldSort: false,
        threshold: 0.2,
        keys: fuseOptions.keys,
      };
      const fuse: Fuse<any> = new Fuse(items, options);
      const result: Array<any> = fuse.search(fuseOptions.value);
      return result.map((res: Fuse.FuseResult<any>) => {
        return res.item;
      });
    } else {
      return items;
    }
  }
}
