import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { SessionService, SessionType } from "src/app/@shared/services/session.service";
import { StorageService } from "../../@shared/services/storage.service";
import { AuthService } from "../auth.service";
import { MessageService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { LoadingService } from "src/app/@shared/services/loading.service";
import { catchError, concatMap, tap } from "rxjs/operators";
import { throwError, forkJoin, of, Observable, from } from "rxjs";
import { CommonService } from "src/app/@shared/services/common.service";
import { SocietyService } from "src/app/@shared/services/society.service";
import { UserService } from "src/app/@shared/services/user.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Society } from "../../@shared/@types/society";
import { AccountService } from "src/app/@shared/services/account.service";
// import OneSignal from "onesignal-cordova-plugin";
declare const window: any;

@Component({
  selector: "spt-sign-in",
  styleUrls: ["./sign-in.component.scss"],
  templateUrl: "./sign-in.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class SignInComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  public displayPassword: boolean = false;
  public needPassword: boolean = false;
  public pendingConnection: boolean = false;
  public biometricAvailable: boolean = false;
  public biometricType: string = "";
  public biometricAvailableAndSet: boolean = false;
  private biometricAvailableToggle: boolean = false;
  @ViewChild("passwordInput") passwordInput: ElementRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private sessionService: SessionService<SessionType>,
    private messageService: MessageService,
    private translateService: TranslateService,
    private loadingService: LoadingService,
    private storageService: StorageService,
    private changeDetector: ChangeDetectorRef,
    public commonService: CommonService,
    public societyService: SocietyService,
    public userService: UserService,
    public httpClient: HttpClient,
    public accountService: AccountService,
  ) {}

  public subscribePage(): void {
    this.router.navigate(["auth", "sign-up"]);
  }

  get form(): any {
    return this.loginForm.value;
  }

  ngOnInit(): void {
    document.addEventListener("deviceready", this.onDeviceReady.bind(this), false);

    this.storageService.getItem("biometricRegistered").subscribe((value) => {
      if (typeof value === "boolean") {
        this.biometricAvailableToggle = value;
      }
    });

    this.loginForm = this.formBuilder.group({
      user: ["", [Validators.email]],
      password: ["", Validators.required],
      biometric: [this.biometricAvailableToggle],
    });

    this.loginForm.controls.biometric.valueChanges.subscribe((value) => {
      this.storageService.setItem("biometricRegistered", { biometric: value }).subscribe();
    });

    this.setupQueryParamsSubscription();
    this.checkBiometricAvailability(); // Ajout de la vérification de la biométrie
  }

  private checkBiometricAvailability(): void {
    if (window.Fingerprint) {
      window.Fingerprint.isAvailable(
        (type) => {
          this.biometricType = type + window.device.platform.toLowerCase();
          this.biometricAvailable = true;
          this.checkBiometricSecret(); // Appel de la méthode pour vérifier le secret biométrique
        },
        (error: any) => {
          this.biometricAvailable = false;
        },
      );
    }
  }

  private onDeviceReady() {
    console.log("Device is ready");
  }

  private setupQueryParamsSubscription(): void {
    this.route.queryParams
      .pipe(
        concatMap((params: Params) => {
          if (window.Fingerprint && window.device) {
            window.Fingerprint.isAvailable((type) => {
              this.biometricType = type + window.device.platform.toLowerCase();
              this.biometricAvailable = true;
            });
          }
          return forkJoin([this.storageService.getItem("biometricRegistered"), of(params)]);
        }),
        concatMap((result: [{ biometric: boolean }, Params]) => {
          const [biometric, queryParams] = result;

          // Redirection si le second élément est un objet vide
          if (Object.keys(queryParams).length === 0) {
            this.router.navigate(["/auth"]);
            return of(null);
          }

          if (biometric && biometric.biometric && window.Fingerprint && !queryParams?.token && !queryParams?.profile) {
            window.Fingerprint.isAvailable(() => {
              this.biometricAvailableAndSet = true;
              this.loginForm.controls["biometric"].setValue(true);
              setTimeout(() => {
                this.checkBiometricSecret();
              }, 800);
              this.changeDetector.detectChanges();
            });
            return of(null); // Ensure we return an observable
          } else if (queryParams && queryParams.profile && queryParams.token) {
            this.loadingService.add();
            this.authService.setUserProfile(JSON.parse(atob(queryParams.profile)));
            const { token, loggedUser } = JSON.parse(atob(queryParams.token));
            return this.createSession(token, loggedUser.userId, loggedUser.roles);
          } else if (queryParams && queryParams.society && queryParams.sso === "true") {
            return of({ societyId: queryParams.society });
          } else {
            return of(null);
          }
        }),
        concatMap((data: any) => {
          if (data) {
            this.setSession(data);
            return this.getSociety(data.societyId, data.user ? data.user.settings.language : undefined);
          } else {
            return of(null);
          }
        }),
        concatMap((data) => {
          const session: any = this.sessionService.get();
          let user = this.userService.user.value;
          if (user) {
            return from(
              this.router.navigate([""]).then(() => {
                if (this.biometricAvailable) {
                  if (user) {
                    let userNeedsUpdate: boolean = true;

                    if (user.settings.auth?.biometric != undefined) {
                      userNeedsUpdate = user.settings.auth.biometric !== this.loginForm.get("biometric").value;
                    }

                    user.settings.auth = { ...user.settings.auth, biometric: this.loginForm.get("biometric").value };

                    this.storageService
                      .setItem("biometricRegistered", { biometric: this.loginForm.get("biometric").value })
                      .subscribe();

                    if (userNeedsUpdate) {
                      this.accountService.updateAccountGeneral(user._id, user).subscribe();

                      if (this.loginForm.get("biometric").value === true) {
                        window.Fingerprint.registerBiometricSecret(
                          {
                            secret: btoa(JSON.stringify(this.sessionService.get())),
                            invalidOnEnrollment: true,
                          },
                          (): void => {},
                          (error: any): void => console.log("Error register", error),
                        );
                      }
                    }
                  }
                }

                this.loadingService.init();
                return this.sessionService.open(session, { rememberMe: true });
              }),
            );
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(
        () => {
          console.log("Session opened");
          this.loadingService.remove();
        },
        (error) => {
          console.log("Error", error);
          this.loadingService.remove();
        },
      );
  }

  public checkEmail(): void {
    this.authService
      .checkSSO(this.loginForm.get("user").value)
      .pipe(
        catchError(() => {
          this.needPassword = true;
          setTimeout(() => {
            (this.passwordInput.nativeElement as HTMLInputElement).focus();
          }, 300);
          return of();
        }),
      )
      .subscribe((result: any) => {
        if (result && !result.needPassword) {
          window.open(
            `${environment.api}/oauth2/${btoa(result.email)}?isCordova=${this.commonService.isCordova()}`,
            this.commonService.isCordova() ? "_system" : "_self",
            "location=no",
          );
        } else if (result && result.needPassword) {
          this.needPassword = result.needPassword;
          setTimeout(() => {
            (this.passwordInput.nativeElement as HTMLInputElement).focus();
          }, 300);
        }
      });
  }

  public editEmail(): void {
    this.needPassword = undefined;
  }

  public login(): void {
    this.pendingConnection = true;
    this.loadingService.add();
    this.authService
      .login(this.form)
      .pipe(
        catchError((error: any) => {
          this.pendingConnection = false;

          this.loadingService.remove();
          this.messageService.add({
            severity: "error",
            detail: this.translateService.instant("NOTIFICATIONS.WRONG_ACCOUNT"),
          });
          return throwError(error);
        }),
        concatMap((resLogin: any) => {
          return this.createSession(resLogin.data, resLogin.loggedUser._id, resLogin.loggedUser.roles);
        }),
        concatMap((data: any) => {
          this.setSession(data);
          if (this.biometricAvailable) {
            if (data.user) {
              let userNeedsUpdate: boolean = true;

              if (data.user.settings.auth?.biometric != undefined) {
                userNeedsUpdate = data.user.settings.auth.biometric !== this.loginForm.get("biometric").value;
              }

              data.user.settings.auth = { biometric: this.loginForm.get("biometric").value };

              this.storageService
                .setItem("biometricRegistered", { biometric: this.loginForm.get("biometric").value })
                .subscribe();

              if (userNeedsUpdate) {
                this.accountService.updateAccountGeneral(data.user._id, data.user).subscribe();
              }

              if (this.loginForm.get("biometric").value === true) {
                window.Fingerprint.registerBiometricSecret(
                  {
                    secret: btoa(JSON.stringify(this.sessionService.get())),
                    invalidOnEnrollment: true,
                  },
                  (): void => {},
                  (error: any): void => console.log("Error register", error),
                );
              }
            }
          }

          // window.OneSignalDeferred = window.OneSignalDeferred || [];
          // console.log("OneSignal Login with userId", data.user._id);
          // window.OneSignalDeferred.push(function (OneSignal) {
          //   OneSignal.login(data?.user?._id ?? "");
          // });

          return this.getSociety(data.societyId, data.user.settings.language);
        }),
        concatMap((society: any) => {
          const session: any = this.sessionService.get();
          this.router
            .navigate([""])
            .then(() => {
              this.loadingService.init();
              // window.OneSignalDeferred.push(function (OneSignal) {
              //   OneSignal.User.addTag("society_id", society._id);
              // });
            })
            .catch((err: any) => console.error(err));

          return this.sessionService.open(session, { rememberMe: true });
        }),
      )
      .subscribe();
  }

  public goToResetPass(): void {
    this.router.navigate(["auth", "password-reset", this.loginForm.get("user").value]);
  }

  changeStatutPassword(): void {
    this.displayPassword = !this.displayPassword;
  }

  private checkBiometricSecret(): void {
    window.Fingerprint.loadBiometricSecret(
      {},
      (secret: string) => {
        if (secret) {
          this.loadingService.add();
          this.changeDetector.detectChanges();
          const { token, userId, roles } = JSON.parse(atob(secret));

          this.createSession(token, userId, roles)
            .pipe(
              concatMap((data: any) => {
                if (data) {
                  this.setSession(data);
                  return this.getSociety(data.societyId, data.user ? data.user.settings.language : undefined);
                } else {
                  return of(null);
                }
              }),
              concatMap(() => {
                const session: any = this.sessionService.get();
                return from(
                  this.router.navigate([""]).then(() => {
                    this.loadingService.init();
                    return this.sessionService.open(session, { rememberMe: true });
                  }),
                );
              }),
            )
            .subscribe({
              next: () => {
                this.loadingService.remove();
              },
              error: (e) => {
                this.messageService.add({
                  severity: "error",
                  summary: this.translateService.instant("BIOMETRIC_JWT_EXPIRED"),
                  life: 30000,
                });
                this.loadingService.remove();
              },
            });
        }
      },
      (error: any) => {
        let errorCode;

        if (error.code && [-101, -102].includes(error.code)) {
          errorCode = "BIOMETRIC." + String(error.code);
        } else {
          errorCode = "BIOMETRIC.GENERIC_ERROR";
        }

        if (error.code && ![-108, -109].includes(error.code)) {
          this.messageService.add({
            severity: "error",
            detail: this.translateService.instant(errorCode),
          });
          console.log(error);
          this.changeDetector.detectChanges();
        }
      },
    );
  }

  private setSession(data) {
    const session: any = this.sessionService.get();
    if (session) {
      session.societyId = data.societyId;
      session.defaultLanguage = data.user.settings.language || "fr";
      session.dateFormat = data.user.settings.dateFormat || (session.defaultLanguage === "en" ? "mm-dd-y" : "dd/mm/y");
      session.timeFormat = data.user.settings.timeFormat || (session.defaultLanguage === "en" ? "12" : "24");
      this.sessionService.set(session, { rememberMe: true });
    }
  }

  private createSession(
    token: string,
    userId: string,
    roles: Array<string>,
  ): Observable<{ societyId: string | Society; user: any }> {
    const session: SessionType = {
      token: token.replace(/JWT /, ""),
      userId,
      roles,
    };
    this.sessionService.set(session, { rememberMe: true });
    return forkJoin({
      societyId: this.societyService.retrieveSociety(userId, false, true),
      user: this.userService.getUser(userId, true),
    });
  }

  private getSociety(societyId: string, language: string): Observable<any> {
    const session: any = this.sessionService.get();
    if (session) {
      session.societyId = societyId;
      session.defaultLanguage = language || "fr";
      this.translateService.setDefaultLang(session.defaultLanguage ?? "fr");
      this.translateService.use(session.defaultLanguage ?? "fr");
      this.sessionService.set(session, { rememberMe: true });
      return this.societyService.getSociety(societyId, { popMember: true }, true);
    } else if (societyId) {
      window.open(
        `${environment.api}/oauth2/${btoa(societyId)}?isCordova=${this.commonService.isCordova()}`,
        this.commonService.isCordova() ? "_system" : "_self",
        "location=no",
      );
      return of(null);
    }
  }
}
