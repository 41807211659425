<button type="button" class="closeModalButton fas fa-times fa-2x m-l-auto" (mousedown)="closeModal()"></button>
<h1 translate>SEARCH.RESULT.RAIL.SEAT_DESIGNATED</h1>
<section class="seat-map" *ngIf="seatMapsLoaded; else loading">
  <div class="container" *ngIf="seatMaps.length > 1">
    <ng-container class="correspondance" *ngFor="let seatMap of seatMaps; index as num">
      <i (click)="onSeatMapSelected(num)" class="step" [class.selectedSegment]="selectedSegment === num">
        <div class="border-icon">
          <img class="icon-train" src="/assets/svg/train-full-white.svg" />
        </div>
        <span class="stepText">
          {{ "SEARCH.RESULT.RAIL.SEAT_PREFERENCES.MULTI_CONNECTION" | translate: { index: num + 1 } }}
        </span>
        <p *ngIf="num !== segments.length - 1">- - - -</p>
      </i>
    </ng-container>
  </div>
  <div class="data" *ngIf="seatMaps[selectedSegment]; else noSeatMapAvailable">
    <div class="carriage" *ngFor="let carriage of seatMaps[selectedSegment].carriages">
      <ng-container *ngFor="let compartment of carriage.compartments">
        <div class="coach" *ngIf="selectedCompartment && compartment.number === selectedCompartment">
          <div class="selection-container">
            <p-dropdown
              class="coach-dropdown"
              [options]="compartmentChoices"
              [(ngModel)]="selectedCompartment"
              (onChange)="selectCoach(selectedSegment)"
              [disabled]="selectedSeats.length === passengerNumber && passengerNumber > 1"
            >
              <ng-template pTemplate="item" let-item>
                <div class="label-container">
                  <span class="label">{{ item.label }}</span>
                  <span class="availability" *ngIf="item.availability; else full">
                    ({{ item.availability }} {{ "SEARCH.RESULT.RAIL.SEAT_PREFERENCES.FREE" | translate
                    }}{{ item.availability > 1 ? "s" : "" }})
                  </span>
                </div>
              </ng-template>
            </p-dropdown>
            <ng-template #full>
              <span>({{ "SEARCH.RESULT.RAIL.SEAT_PREFERENCES.FULL" | translate }})</span>
            </ng-template>
            <p class="travel-class">
              {{ "SEARCH.TRAIN." + compartment.travelClass | translate }}
            </p>
            <p-selectButton
              [allowEmpty]="false"
              class="deck-select"
              [options]="deckChoices[compartment.number]"
              [(ngModel)]="selectedDeck"
              [class.has-multiple]="deckChoices[compartment.number].length > 1"
              [disabled]="selectedSeats.length === passengerNumber && passengerNumber > 1"
            >
              <ng-template let-item>
                <div class="label-container">
                  <span class="label">{{ item.label }}</span>
                  <span class="availability" *ngIf="item.availability; else full">
                    ({{ item.availability }} {{ "SEARCH.RESULT.RAIL.SEAT_PREFERENCES.FREE" | translate
                    }}{{ item.availability > 1 ? "s" : "" }})
                  </span>
                </div>
              </ng-template>
            </p-selectButton>
          </div>
          <div class="scrollBarPos">
            <ng-container *ngFor="let deck of compartment.decks">
              <spt-travel-seatmap-compartment
                *ngIf="deck.deckType && selectedDeck === deck.deckType && deck.deckGrid"
                [deck]="deck"
                [passengerNumber]="passengerNumber"
                (seatSelected)="onSeatSelected($event)"
                (maxSeatsReached)="maxSeatsReached = $event"
              >
              </spt-travel-seatmap-compartment>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="validation-container" *ngIf="seatMaps[selectedSegment]">
    <div class="reached-error-container" *ngIf="maxSeatsReached && passengerNumber > 1">
      <p-message severity="error" [text]="'SEARCH.RESULT.RAIL.SEAT_PREFERENCES.MAX_REACHED' | translate"></p-message>
    </div>
    <div class="selected-container" *ngIf="selectedSeats.length > 0; else selectedContainer">
      <span class="seat-number">{{ selectedSeats }}</span>
      <span class="coach"
        >{{ "SEARCH.RESULT.RAIL.SEAT_PREFERENCES.COACH" | translate }} {{ selectedCompartment }} -
      </span>
      <span class="travel-class">{{ "SEARCH.TRAIN." + travelClass + "_LETTRE" | translate }} </span>
      <span class="deck">- {{ "SEARCH.RESULT.RAIL.SEAT_PREFERENCES.DECK_" + selectedDeck | translate }}</span>
    </div>
    <ng-template #selectedContainer>
      <span class="chooseMsg">{{ "SEARCH.RESULT.RAIL.SEAT_PREFERENCES.CHOOSE_MESSAGE" | translate }}</span>
    </ng-template>
  </div>
  <div class="button-container" *ngIf="(selectedSeats.length === passengerNumber && isTablet) || !isTablet">
    <p-button
      class="confirm-button"
      styleClass="w-full"
      [label]="'GLOBAL.VALIDATE' | translate"
      (mousedown)="confirmSelection()"
      [disabled]="seatMaps[selectedSegment] && selectedSeats.length !== passengerNumber"
    ></p-button>
  </div>
</section>
<ng-template #loading>
  <section class="loading-container">
    <spt-loading-v2></spt-loading-v2>
  </section>
</ng-template>

<ng-template #noSeatMapAvailable>
  <div class="no-availability" translate>SEARCH.RESULT.RAIL.NO_SEAT_MAP</div>
</ng-template>
