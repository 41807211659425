import { animate, state, style, transition, trigger } from "@angular/animations";
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Data } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { SelectItem } from "primeng/api";
import { Calendar } from "primeng/calendar";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Billing } from "src/app/@shared/@types/models";
import { MemberSociety, Society } from "src/app/@shared/@types/society";
import { User } from "src/app/@shared/@types/user";
import { CommonService } from "src/app/@shared/services/common.service";
import { TagService } from "src/app/@shared/services/tag.service";
import { UtilsTypes } from "../../@shared/@types/utils";
import { FolderStatus, FolderService } from "../../@shared/services/folder.service";
import { ItemStatus } from "../../@shared/services/item.service";
import { Tag } from "../../@shared/@types/tag";
import { SocietyService } from "../../@shared/services/society.service";
import { Label } from "src/app/@shared/@types/label";
import { LabelService } from "src/app/@shared/services/label.service";

@Component({
  selector: "spt-reservations",
  templateUrl: "./reservations.component.html",
  styleUrls: ["./reservations.component.scss"],
  animations: [
    trigger("filtersFadeInOut", [
      state(
        "visible",
        style({
          opacity: "1",
        }),
      ),
      state(
        "hidden",
        style({
          opacity: "0",
        }),
      ),
      transition("hidden <=> visible", [animate("700ms linear")]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ReservationsComponent implements OnInit, OnDestroy {
  @ViewChild("periodCalendar", { static: false }) periodCalendar: Calendar;
  @ViewChild("periodCalendarMobile", { static: false }) periodCalendarMobile: Calendar;

  public isDisplayedModification: boolean = false;
  public isDisplayedCancellation: boolean = false;
  public societyLabels: Array<Label> = [];
  public showLabelSide: boolean = false;
  public showSidebar: boolean = false;
  public showFilters: boolean = false;
  public filterState: string = "hidden";
  public itemsTypes: Array<SelectItem> = [];
  public itemsEntities: Array<SelectItem> = [];
  public itemsServices: Array<SelectItem> = [];
  public itemsUsers: Array<SelectItem> = [];
  public itemsTags: Array<SelectItem> = [];
  public inputSearch: string = "";
  public paramFilters: UtilsTypes.ObjectKey<any> = {
    types: [],
    entities: [],
    services: [],
    tags: [],
    users: [],
  };
  public dictionnary: { [key: string]: string } = {};
  public rangeDates: Array<Date>;
  public yearRange: string;
  public members: MemberSociety[];
  public currentUsers: UtilsTypes.ObjectKey<User>;
  public reservationBillings: any[] = [];
  public societyTags: Array<any> = [];
  public searchPipeArray: any[];
  public fuseOptions: any;
  public folders: Observable<Array<Array<any>>>;
  public items: BehaviorSubject<any> = new BehaviorSubject(null);
  public filteredItems: BehaviorSubject<Array<any>> = new BehaviorSubject(null);
  public locale: string;
  public averageTimes: object;
  public choosenObject: any;
  public displayCancelled: boolean = false;
  public passengerEmails: UtilsTypes.ObjectKey<string> = {};

  private billings: any = {};
  private rangeDateOptns: any = {};
  private society: Society;
  private ngUnsubscribe: Subject<void> = new Subject();
  protected travelersList: string = "";

  constructor(
    public commonService: CommonService,
    private route: ActivatedRoute,
    private tagService: TagService,
    public translateService: TranslateService,
    private folderService: FolderService,
    private societyService: SocietyService,
    private labelService: LabelService,
    private cd: ChangeDetectorRef,
  ) {}

  static getDateIn(item: any): Date {
    let dateIn: Date;
    switch (item.type) {
      case "flight":
        if (item.provider === "afkl") {
          dateIn = item.detail.trips[0].Dep.AircraftScheduledDateTime;
        } else {
          dateIn = item.detail.trips[0].legs[0]?.departure.utc;
        }

        break;
      case "hotel":
        dateIn = item.detail.datein.utc;
        break;
      case "car":
        dateIn = item.detail.pickupDate.utc;
        break;
      case "train":
        dateIn = item.detail.journeys[0].departure.date.utc;
        break;
      case "transfer":
        switch (item.detail.booking.transfers[0].fromdetails.codetype) {
          case "AIR":
          case "IATA":
            dateIn = item.detail.booking.transfers[0].fromdetails.flight.arrivaldatetime.utc;
            break;
          case "STN":
            dateIn = item.detail.booking.transfers[0].fromdetails.train.arrivaldatetime.utc;
            break;
          case "RST":
          case "GEO":
            dateIn = item.detail.booking.transfers[0].fromdetails.accommodation.pickupdatetime.utc;
            break;
        }
        break;
    }
    return dateIn;
  }

  static sortItems(itemA: any, itemB: any): number {
    const itemADateIn: Date = ReservationsComponent.getDateIn(itemA);
    const itemBDateIn: Date = ReservationsComponent.getDateIn(itemB);

    return moment(itemADateIn).diff(moment(itemBDateIn));
  }

  private setEntitiesAndServicesFilters(): void {
    const itemsEntities: Array<{ label: string; value: string }> = [];
    const itemsServices: Array<{ label: string; value: string }> = [];
    this.reservationBillings.forEach((billing: any) => {
      this.dictionnary[billing.id] = billing.service ? `${billing.raison} (${billing.service})` : billing.raison;
      if (billing.raison) {
        itemsEntities.push({
          label: billing.raison,
          value: billing.id,
        });
      }

      if (billing.service) {
        itemsServices.push({
          label: billing.service,
          value: billing.id,
        });
      }
    });
    this.itemsEntities = Array.from(new Set(itemsEntities));
    this.itemsServices = itemsServices;
  }

  private setFilters(): void {
    const billingsToGet = [];
    this.items.subscribe((items) => {
      if (items) {
        items.forEach((_item: any) => {
          _item.travelers.forEach((traveler: any) => {
            this.currentUsers[traveler.userId] = {};
          });

          this.passengerEmails[_item.id] = _item.travelers
            .slice(1)
            .map((_traveler: any) => `${_traveler.email}\n\r`)
            .join("");
          if (_item && _item.type) {
            if (!this.itemsTypes.some((elem: SelectItem) => elem.label === _item.type)) {
              this.dictionnary[_item.type] = this.translateService.instant(`GLOBAL.${_item.type.toUpperCase()}`);
              this.itemsTypes.push({
                label: _item.type,
                value: _item.type,
              });
            }

            if (_item._tags && _item._tags.length > 0) {
              _item._tags.forEach((tag: Tag) => {
                if (this.dictionnary[tag.label] === undefined) {
                  this.dictionnary[tag.label] = tag.label;
                  this.itemsTags.push({
                    label: tag.label,
                    value: tag.label,
                  });
                }
              });
            }

            if (_item.billingId && !this.billings[_item.billingId]) {
              billingsToGet.push(_item.billingId);
            }

            let itemBillingIds;
            if (_item.billingId && this.billings[_item.billingId]) {
              itemBillingIds = [_item.billingId];
            } else {
              itemBillingIds = _item.travelers.map((traveler) => traveler.billingId.toString());
            }
            itemBillingIds.forEach((itemBillingId) => {
              if (
                !this.reservationBillings.find((billing: Billing) => itemBillingId === billing.id.toString()) &&
                this.billings[itemBillingId]
              ) {
                let label: string = `${this.billings[itemBillingId].raison}`;
                if (this.billings[itemBillingId].service) {
                  label += `(${this.billings[itemBillingId].service})`;
                }
                this.reservationBillings.push({
                  ...this.billings[itemBillingId],
                  label,
                });
              }
            });
          }
          _item.travelersList = _item.travelers.map((v) => `${v.firstname} ${v.lastname}`).join(", ");
        });

        if (billingsToGet.length > 0) {
          this.societyService.getBillings().subscribe((billings: Array<Billing>) => {
            billings.forEach((billing) => {
              this.billings[billing.id] = billing;
              let label: string = `${billing.raison}`;
              if (billing.service) {
                label += `(${billing.service})`;
              }
              this.reservationBillings.push({
                ...billing,
                label,
              });
              this.dynamicFilters();
              this.setEntitiesAndServicesFilters();
            });
          });
        } else {
          this.dynamicFilters();
          this.setEntitiesAndServicesFilters();
        }
      }
      this.updateFilteredItems();
    });
  }

  private getItems(): void {
    this.locale = this.translateService.currentLang;
    // this.items.next([]);

    this.folderService
      .list(
        [FolderStatus.CONFIRMED, FolderStatus.CANCELLED],

        this.rangeDateOptns.dateinMin,
        this.rangeDateOptns.dateinMax,
      )
      .subscribe((result: { baskets: Array<any>; folders: Array<any>; items: UtilsTypes.ObjectKey<Array<any>> }) => {
        const itemsByFolder = Object.values(result.items);

        itemsByFolder.forEach((items) => {
          items.forEach((item) => {
            // Récupération du Basket associé pour déterminer le titre du basket
            const basket = result.baskets.find((basket) =>
              // Recherche le basket contenant l'ID du folder
              basket.folderIds?.includes(
                // Détermine l'ID du folder contenant l'Item
                result.folders.find((folder) => folder.itemIds.includes(item.id))?.id,
              ),
            );
            if (!item.title) {
              item.title = basket?.title || "Nom panier inconnu";
            }
            item.confirmationDate = item.statusHistory.find((status) => status.to === "confirmed")?.date;
            if (item.tagIds) {
              item._tags = item.tagIds
                .map((tagId) => {
                  return this.societyTags.find((societyTag) => societyTag.id === tagId);
                })
                .filter((tag) => !!tag);
            }
          });
        });
        const items = Object.values(result.items)
          .map((itemsOfFolder) => {
            const travelType = this.getTravelType(itemsOfFolder);
            itemsOfFolder.map((o) => (o.travelType = travelType));
            return itemsOfFolder;
          })
          .flat();
        this.items.next(items);
      });
  }

  trackItem(index: number, item: any): void {
    return item.id;
  }

  updateFilteredItems(): void {
    if (this.items.value) {
      const statusToDisplay = [ItemStatus.CONFIRMED];
      if (this.displayCancelled) {
        statusToDisplay.push(ItemStatus.CANCELLED, ItemStatus.MODIFIED);
      }
      const filteredItems = this.items.value
        .filter((item) => statusToDisplay.includes(item.status))
        // Uniformisation de la définition de la référence
        .map((item) => {
          if (!item.reference) {
            let reference: string;
            switch (item.type) {
              case "train":
                reference = item.detail?.orderItems
                  ?.filter((o) => o.carrierReference != null)
                  .map((o) => o.carrierReference)
                  .join(", ");
                break;
              case "car":
                reference = item.detail.confirmationNumber;
                break;
              case "hotel":
                reference = item.detail.supplierbookingref;
                break;
              case "flight":
                reference = item.detail.supplierRef;
                if (item.formData?.orderCreateRS?.initialItemAfklPrice) {
                  item.price.amount += item.formData.orderCreateRS.initialItemAfklPrice.amount;
                }
                break;
              case "transfer":
                reference = item.detail.booking.general.bookingreference;
                break;
              default:
                reference = "référence non disponible";
            }
            item.reference = reference;
          }
          return item;
        })
        .sort(ReservationsComponent.sortItems);
      this.filteredItems.next(filteredItems);
    }
  }

  private initializeFilters(): void {
    this.itemsTags = [];
    this.currentUsers = {};
    this.itemsUsers = [];
    this.itemsEntities = [];
    this.itemsServices = [];
    this.reservationBillings = [];
    this.itemsTypes = [];
  }

  ngOnInit(): void {
    this.commonService.statusBarTextBlack();
    this.route.data.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: Data): void => {
      if (data.labels) {
        this.societyLabels = data.labels.filter((label: Label): boolean => label.isActive);
      }
    });
    const start: moment.Moment = moment().startOf("day");
    const end: moment.Moment = moment().add(1, "year").endOf("day");

    // const start: moment.Moment = moment("2022-01-01").startOf("day");
    // const end: moment.Moment = moment("2022-02-01").endOf("month");

    this.rangeDates = [start.toDate(), end.toDate()];
    this.yearRange = `1980:${this.rangeDates[1].getFullYear()}`;

    this.rangeDateOptns = {
      dateinMin: start.format("YYYY-MM-DD"),
      dateinMax: end.format("YYYY-MM-DD"),
    };

    this.initializeFilters();
    this.billings = this.route.snapshot.data.billings;
    if (this.billings) {
      this.billings.forEach((billing: Billing) => {
        this.billings[billing.id.toString()] = billing;
      });
    }
    this.getSocietyTags();
    this.getItems();
    this.setFilters();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getSocietyTags(): void {
    this.society = this.route.snapshot.data.society_and_members.society;
    this.tagService.getTags().subscribe((tags: any) => {
      this.societyTags = tags;
    });
  }
  getTravelType(itemsOfFolder: any[]): string {
    if (itemsOfFolder.length === 0) {
      return;
    }
    if (itemsOfFolder.length > 1) {
      return "multiseg";
    }
    const detail = itemsOfFolder[0].detail;
    if (!detail.trips || detail.trips.length === 0) {
      return;
    }
    if (detail.trips[0].legs?.length > 1 && detail.trips[0].legs?.length <= 2) {
      return "return";
    }
    if (detail.trips[0].legs?.length > 2) {
      return "multidesti";
    }
    return "single";
  }
  dynamicFilters(): void {
    this.route.data.pipe(takeUntil(this.ngUnsubscribe)).subscribe((_data: Data) => {
      Object.keys(this.currentUsers).forEach((userId: string) => {
        const member: MemberSociety = _data.society_and_members.members.find(
          (_member: MemberSociety) => _member.user._id === userId,
        );
        if (member) {
          if (!this.dictionnary[userId]) {
            this.dictionnary[userId] = member.user.username
              ? member.user.username
              : `${member.user.firstname} ${member.user.lastname}`;
          }
          this.itemsUsers.push({
            label: member.user.username ? member.user.username : `${member.user.firstname} ${member.user.lastname}`,
            value: member.user._id,
          });
        }
      });
    });
  }

  openCalendar(event: Event): void {
    (this.periodCalendar || this.periodCalendarMobile).onInputFocus(event);
  }

  calendarShown(calendar: any): void {
    this.commonService.setBackFunction((): void => {
      calendar.toggle();
    }, this);
  }

  calendarClosed(): void {
    this.commonService.unsetBackFunction();
  }

  toggleFilters(): void {
    this.showFilters = !this.showFilters;
    if (this.showFilters) {
      this.filterState = "visible";
    } else {
      this.filterState = "hidden";
    }
  }

  setFuseOptions(): any {
    return {
      findAllMatches: true,
      keys: [
        "_tags.label",
        "travelers.userId",
        "travelers.lastname",
        "travelers.firstname",
        "detail.journeys.departure.name",
        "detail.journeys.arrival.name",
        "detail.hotel.description",
        "detail.transfers.fromdetails.name",
        "detail.transfers.todetails.name",
      ],
      value: this.inputSearch,
    };
  }

  mappedType(types: any[]): any {
    if (types && types.length > 0) {
      return {
        key: "type",
        value: types,
      };
    }
    return;
  }

  filterDate(date: Array<Date>): void {
    if (date[0] && date[1]) {
      this.initializeFilters();
      this.rangeDates = date;
      this.rangeDateOptns = {
        dateinMin: moment(this.rangeDates[0]).format("YYYY-MM-DD"),
        dateinMax: moment(this.rangeDates[1]).format("YYYY-MM-DD"),
        dateoutMin: moment(this.rangeDates[0]).format("YYYY-MM-DD"),
        dateoutMax: moment(this.rangeDates[1]).format("YYYY-MM-DD"),
      };
      this.getItems();
      this.periodCalendar.toggle();
    }
  }

  mappedTags(tags: any[]): any {
    if (tags && tags.length > 0) {
      return { key: "_tags.label", value: tags };
    }
    return;
  }

  mappedTravelers(users: string[]): any {
    if (users && users.length > 0) {
      return { key: "travelers.userId", value: users };
    }
    return;
  }

  mappedEntities(entities: any[]): any {
    if (entities && entities.length > 0) {
      return { key: "travelers.billingId", value: entities };
    }
    return;
  }

  mappedServices(services: any[]): any {
    if (services && services.length > 0) {
      return { key: "travelers.billingId", value: services };
    }
    return;
  }

  applyFilter(): void {
    const mappedFilterTypes: any = this.mappedType(this.paramFilters.types);
    const mappedFilterEntities: any = this.mappedEntities(this.paramFilters.entities);
    const mappedFilterServices: any = this.mappedServices(this.paramFilters.services);
    const mappedFilterTags: any = this.mappedTags(this.paramFilters.tags);
    const mappedFilterTravelers: any = this.mappedTravelers(this.paramFilters.users);
    this.searchPipeArray = [
      mappedFilterTypes,
      mappedFilterTravelers,
      mappedFilterTags,
      mappedFilterEntities,
      mappedFilterServices,
    ].filter((val: any) => !!val);
    this.fuseOptions = this.setFuseOptions();
  }

  deleteTagFilter(key: string, value: any): void {
    const index: number = this.paramFilters[key].indexOf(value);
    this.paramFilters[key].splice(index, 1);
    this.applyFilter();
  }

  resetAllFilters(): void {
    this.displayCancelled = false;
    this.inputSearch = "";
    this.paramFilters = {
      searchInput: [],
      tags: [],
      users: [],
      types: [],
      canceled: [],
    };
    this.updateFilteredItems();
    this.applyFilter();
  }

  closeSidebar(reload: boolean): void {
    this.commonService.statusBarTextWhite();
    this.choosenObject = undefined;
    this.isDisplayedModification = undefined;
    this.isDisplayedCancellation = undefined;
    if (reload) {
      this.getItems();
      this.setFilters();
    }
  }
  openSidebar(item: any) {
    this.choosenObject = item;
    this.isDisplayedModification = false;
    this.isDisplayedCancellation = false;
    this.showLabelSide = false;
    this.showSidebar = true;
  }
  modify(item: any) {
    this.isDisplayedModification = true;
    this.isDisplayedCancellation = false;
    this.showLabelSide = false;
  }

  cancel(item: any) {
    this.isDisplayedCancellation = true;
    this.isDisplayedModification = false;
    this.showLabelSide = false;
  }
  editLabel(item: any) {
    this.isDisplayedCancellation = false;
    this.isDisplayedModification = false;
    this.showLabelSide = true;
  }
  addUserLabel(event: { labels: any; comment: string }): void {
    if (event) {
      if (event.labels) {
        this.labelService.update(this.choosenObject, event.labels).subscribe((): void => {
          this.choosenObject.labels = event.labels;
          this.cd.markForCheck();
        });
      }

      if (event.comment) {
        this.labelService.updateComment(this.choosenObject, event.comment).subscribe((): void => {
          this.choosenObject.comment = event.comment;
          this.cd.markForCheck();
        });
      }
    }
    this.showLabelSide = false;
  }
}
