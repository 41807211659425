import {
  Component,
  ViewEncapsulation,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { Observable, Subject, takeUntil } from "rxjs";
import { SPTPricePipe } from "src/app/@shared/pipes/currency.pipe";
import { CommonService } from "src/app/@shared/services/common.service";
import { FlightService } from "../flight.service";
import { SelectItem } from "primeng/api";
import fidelities from "../../../../assets/fidelities";
import { Filter } from "../flight.component";
@Component({
  selector: "spt-travel-flight-itinerary",
  styleUrls: ["./travel-flight-itinerary.component.scss"],
  templateUrl: "./travel-flight-itinerary.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TravelFlightItineraryComponent implements OnInit {
  private _itinerary: any;
  public carriers: string[] = [];
  @Input() departureDates: any;
  @Input() step: number;
  @Input() tripType: string;
  @Input() dataForFilters: any;
  @Output() preview: EventEmitter<void> = new EventEmitter();
  @Output() extend: EventEmitter<void> = new EventEmitter();
  @Output() filteredPrice: EventEmitter<any> = new EventEmitter();
  @Input() pricedOffer?: any;
  otherOffers?: any = [];
  filterForCurrentStep: Filter;
  displayedOtherOffers: any[] = [];
  hideAdvantages: boolean = false;
  totalPrice: number = 0;
  @Input() selectedItinerary?: any;
  public extended: boolean = false;
  @Input() set itinerary(value: any) {
    this._itinerary = value;
    this.itineraryUpdated();
  }
  get itinerary() {
    return this._itinerary;
  }

  public isRefundable: boolean;
  public isExchangeable: boolean;
  public refundAmount: number;
  public exchangeAmount: number;
  public refundText: Observable<string>;
  public exchangeText: Observable<string>;
  public computedOffer: Array<any> = [];
  public advantages: SelectItem[];

  constructor(
    private SPTPricePipe: SPTPricePipe,
    public commonService: CommonService,
    private flightService: FlightService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.flightService.closeExtendedPanel.subscribe((data) => {
      if (this.extended && data === true) {
        this.extended = false;
      }
    });
    this.flightService.offerPriced.subscribe((data) => {
      if (this.itinerary.provider && data.pricedOffer.offerId === this.itinerary.offerId) {
        this.extended = true;
        this.itinerary = data.pricedOffer;
        this.searchAfklMissingCabinClassesForFilters(data.pricedOffer.otherOffers);
        if (this.filterForCurrentStep) {
          const filteredOtherOffers = data.pricedOffer.otherOffers.filter((offer: any) =>
            this.filterItineraryOtherOffersClass(offer, this.filterForCurrentStep),
          );
          this.otherOffers.push(...filteredOtherOffers);

          this.updateDisplayedOtherOffers();
        } else {
          this.itinerary.filteredOffers = data.pricedOffer.otherOffers;
        }
      }
    });

    this.flightService.getFilterForCurrentStepData().subscribe((data) => {
      this.filterForCurrentStep = data;
      if (this.itinerary.provider && this.itinerary.otherOffers.length) {
        this.updateDisplayedOtherOffers();
      }
    });
  }

  searchAfklMissingCabinClassesForFilters(pricedOffers: any[]) {
    const filterCabinCodesData: any[] = Array.isArray(this.dataForFilters)
      ? this.dataForFilters.filter((entry) => entry.type === "cabinCodes").flatMap((entry) => entry.data)
      : this.dataForFilters.data;

    if (filterCabinCodesData) {
      const filterCabinCodesLabelArray = filterCabinCodesData.map((cabinCodesData: any) => cabinCodesData.value);
      const offerPriceCabinClasses: string[] = pricedOffers.map((pricedOffer: any) => {
        return pricedOffer.cabinCodes;
      });

      const missing_price_class = offerPriceCabinClasses.find(
        (priceClass: any) => !filterCabinCodesLabelArray.includes(priceClass),
      );
      if (missing_price_class) {
        filterCabinCodesData.push({
          label: this.getClassName(missing_price_class),
          key: missing_price_class,
          value: missing_price_class,
        });
      }
    }
  }

  getClassName(classCode) {
    const classMap = {
      Y: "ECONOMY",
      J: "PREMIUM",
      C: "BUSINESS",
      F: "FIRST",
    };

    return classMap[classCode] || "ECONOMY";
  }

  updateDisplayedOtherOffers() {
    const filteredClassOtherOffers = this.itinerary.otherOffers.filter((offer: any) =>
      this.filterItineraryOtherOffersClass(offer, this.filterForCurrentStep),
    );
    this.itinerary.filteredOffers = filteredClassOtherOffers.filter((itinerary: any) =>
      this.flightService.filterItineraryOtherOffers(itinerary, this.filterForCurrentStep),
    );
    this.hideAdvantages = this.itinerary.filteredOffers?.length === 0;
  }

  filterItineraryOtherOffersClass(itinerary: any, filterForCurrentStep: Filter) {
    return itinerary.cabinCodes === filterForCurrentStep.cabinCodes;
  }

  comparerEtAjouterValeursManquantes(objet1: any, objet2: any): any {
    for (let prop in objet2) {
      if (objet2.hasOwnProperty(prop) && !objet1.hasOwnProperty(prop)) {
        objet1[prop] = objet2[prop];
      }
      if (objet2.hasOwnProperty(prop) && objet1.hasOwnProperty(prop)) {
        this.comparerEtAjouterValeursManquantes(objet1, objet2);
      }
    }
    return objet1;
  }

  itineraryUpdated(): void {
    this.isExchangeable = false;
    this.isRefundable = false;
    let refoundAmount = 0;
    let exchangeAmount = 0;
    if (this.itinerary.provider === "afkl") {
      this.isExchangeable = this.itinerary.changeable;
      this.isRefundable = this.itinerary.refundable;

      this.itinerary.otherOffers.forEach((offer) => {
        if (offer.refundable) {
          offer.refundablePenalities = offer.refundablePenalities?.filter((penality) => {
            return penality.PenaltyAmount?.$t;
          });
        }
        if (offer.changeable) {
          offer.changeablePenalities = offer.changeablePenalities?.find((penality) => {
            return penality.PenaltyAmount?.$t;
          });
        }
      });
    } else {
      this.itinerary.pricingInformations.forEach((pi) => {
        if (Object.keys(pi.fare).length) {
          pi.fare.passengerInfos.forEach((passenger) => {
            if (Array.isArray(passenger.penaltiesInfo && passenger.penaltiesInfo.penalties)) {
              passenger.penaltiesInfo.penalties.forEach((penalty) => {
                if (penalty.type === "Refund" && penalty.applicability === "Before" && penalty.refundable === true) {
                  this.isRefundable = true;
                  refoundAmount += penalty.amount;
                }
                if (penalty.type === "Exchange" && penalty.applicability === "Before" && penalty.changeable === true) {
                  this.isExchangeable = true;
                  exchangeAmount += penalty.amount;
                }
              });
            }
          });
        }
      });
    }
    this.refundAmount = refoundAmount;
    this.refundText = this.SPTPricePipe.transform({ amount: this.refundAmount, currency: "EUR" }, new Date());
    this.exchangeAmount = exchangeAmount;
    this.exchangeText = this.SPTPricePipe.transform({ amount: this.exchangeAmount, currency: "EUR" }, new Date());

    this.advantages = [
      {
        label: "SEARCH.RESULT.AIR.FILTERS.BAGGAGES_CARRY",
        value: this.itinerary.baggageIncluded === 0 ? false : true,
      },
      {
        label: "SEARCH.RESULT.AIR.FILTERS.BAGGAGES_BAGGAGES",
        value: this.itinerary.carryOnIncluded === 0 ? false : true,
      },
      { label: "SEARCH.RESULT.AIR.FILTERS.CANCELLABLE", value: this.isRefundable },
      { label: "SEARCH.RESULT.AIR.FILTERS.CHANGEABLE", value: this.isExchangeable },
    ];
    // TODO: il faut absolument refactorer le code pour ne pas nécessiter ça !
    this.cd.markForCheck();
  }

  previewItinerary(itinerary, pricingInformations?) {
    if (pricingInformations) {
      const selectedItinerary = { ...itinerary };
      selectedItinerary.pricingInformations = [pricingInformations];
      selectedItinerary.totalPrice = pricingInformations.price.total;
      this.preview.emit(selectedItinerary);
    } else {
      this.preview.emit(itinerary);
    }
  }

  getOfferPriceWithselectedItinerary() {
    return this.itinerary?.totalPrice + this.selectedItinerary?.totalPrice;
  }

  extendItinerary(itinerary) {
    if (itinerary.provider === "afkl" && this.extended === false) {
      if (!itinerary.filteredOffers || !itinerary.filteredOffers.length) {
        this.extend.emit(itinerary);
      }
    } else {
      if (this.selectedItinerary && this.itinerary.type === "packaged") {
        this.displayedOtherOffers = this.displayedOtherOffers.length
          ? this.flightService.filterSabreClass(this.selectedItinerary, this.displayedOtherOffers)
          : this.flightService.filterSabreClass(this.selectedItinerary, this.itinerary.otherOffers);
      } else if (this.displayedOtherOffers.length === 0) {
        this.displayedOtherOffers = this.itinerary.otherOffers;
      }
    }
    this.extended = !this.extended;
  }

  protected readonly fidelities = fidelities;
}
