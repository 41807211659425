import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "searchPipe",
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], params: any[]): Array<any> {
    if (!params || params.length === 0) {
      return items;
    }
    const values: Array<{ key: string; value: any }> = Object.values(params);
    const filteredItems: Array<any> = items.filter((item: any) => {
      const result: boolean = values.every((value: { key: string; value: any }) => {
        const isValid: boolean = this.test(item, value.key, value.value);
        return isValid;
      });
      return result;
    });
    return filteredItems;
  }

  test(item: any, _key: string, value: string | any[]): boolean {
    if (!item) {
      return false;
    }
    if (!value) {
      return true;
    }
    const keys: Array<string> = _key.split(".");

    const key: string = keys[0];
    if (typeof item === "string") {
      return typeof value === "object"
        ? (value as Array<any>).some((data: any) => data === item)
        : item === value || item.toLowerCase().includes(value.toLowerCase());
    } else if (typeof item === "number") {
      return typeof value === "object"
        ? (value as Array<any>).some((data: string) => Number(data) === item)
        : item === Number(value);
    } else if (typeof item === "object") {
      if (item.length) {
        for (const item1 of item) {
          if (this.test(item1, _key, value)) {
            return true;
          }
        }
        return false;
      } else {
        return this.test(item[key], keys.slice(1).join("."), value);
      }
    } else {
      return false;
    }
  }
}
