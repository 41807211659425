import { ViewEncapsulation, Component, Input, OnInit } from "@angular/core";
@Component({
  selector: "spt-markup-hotel",
  templateUrl: "./markup-hotel.component.html",
  styleUrls: ["./markup-hotel.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MarkupHotelComponent implements OnInit {
  @Input() hotel: any;
  @Input() availabilities: any;
  // tslint:disable-next-line:no-input-rename
  @Input() select: Function;
  isSelected: boolean;
  constructor() {}

  ngOnInit(): void {}

  onImgError($event): void {
    $event.target.src = "./assets/svg/default-hotel.svg";
  }
}
