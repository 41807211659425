export const times24 = [
  { label: "0h00", value: 0 },
  { label: "1h00", value: 1 },
  { label: "2h00", value: 2 },
  { label: "3h00", value: 3 },
  { label: "4h00", value: 4 },
  { label: "5h00", value: 5 },
  { label: "6h00", value: 6 },
  { label: "7h00", value: 7 },
  { label: "8h00", value: 8 },
  { label: "9h00", value: 9 },
  { label: "10h00", value: 10 },
  { label: "11h00", value: 11 },
  { label: "12h00", value: 12 },
  { label: "13h00", value: 13 },
  { label: "14h00", value: 14 },
  { label: "15h00", value: 15 },
  { label: "16h00", value: 16 },
  { label: "17h00", value: 17 },
  { label: "18h00", value: 18 },
  { label: "19h00", value: 19 },
  { label: "20h00", value: 20 },
  { label: "21h00", value: 21 },
  { label: "22h00", value: 22 },
  { label: "23h00", value: 23 },
  { label: "23h59", value: 24 },
];

export const times12 = [
  { label: "12:00PM", value: 0 },
  { label: "1:00AM", value: 1 },
  { label: "2:00AM", value: 2 },
  { label: "3:00AM", value: 3 },
  { label: "4:00AM", value: 4 },
  { label: "5:00AM", value: 5 },
  { label: "6:00AM", value: 6 },
  { label: "7:00AM", value: 7 },
  { label: "8:00AM", value: 8 },
  { label: "9:00AM", value: 9 },
  { label: "10:00AM", value: 10 },
  { label: "11:00AM", value: 11 },
  { label: "12:00AM", value: 12 },
  { label: "1:00PM", value: 13 },
  { label: "2:00PM", value: 14 },
  { label: "3:00PM", value: 15 },
  { label: "4:00PM", value: 16 },
  { label: "5:00PM", value: 17 },
  { label: "6:00PM", value: 18 },
  { label: "7:00PM", value: 19 },
  { label: "8:00PM", value: 20 },
  { label: "9:00PM", value: 21 },
  { label: "10:00PM", value: 22 },
  { label: "11:00PM", value: 23 },
  { label: "11:59PM", value: 24 },
];
