import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { MessageService } from "primeng/api";
import { Observable, of, switchMap, throwError } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";
import { CommonService } from "../../@shared/services/common.service";
import { UserService } from "../../@shared/services/user.service";
import { TrainTypes } from "./train";
import { Reason, TrainService } from "./train.service";
import { UtilsTypes } from "../../@shared/@types/utils";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";

@Component({
  selector: "spt-travel-train",
  templateUrl: "./train.component.html",
  styleUrls: ["./train.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelTrainComponent implements OnInit, OnChanges {
  @Input() search: {
    id: string;
    data: TrainTypes.SearchBody | TrainTypes.ExchangeBody;
  };
  @Input() itemId: string;
  @Input() seeOutOfPolicy: boolean;
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Output() selectItinerary: EventEmitter<{
    itinerary: TrainTypes.Itinerary | TrainTypes.ItineraryChange;
    fees: UtilsTypes.Price;
    provider: TrainTypes.Provider;
    price: UtilsTypes.Price;
    passengers: Array<TrainTypes.Passenger>;
    origin: string;
    destination: string;
    type: TrainTypes.SearchType;
  }> = new EventEmitter();
  @Output() sidebarOpen: EventEmitter<boolean> = new EventEmitter();
  @Output() modifySearch: EventEmitter<void> = new EventEmitter();
  searchResult: Observable<TrainTypes.SearchResult>;
  outwardResults: TrainTypes.SearchResult;
  inwardResults: TrainTypes.SearchResult;
  passengers: Array<TrainTypes.Passenger>;
  searchId: string;
  firstOfferDateTime: string;
  lastOfferDateTime: string;
  activeTabIndex: number;
  searchType: TrainTypes.SearchType;
  journeySearchType: TrainTypes.JourneySearchType;
  searchJourneyDirection: TrainTypes.SearchJourneyDirection;
  isUKSearch: boolean;
  showPackages: boolean;
  origin: TrainTypes.Station;
  destination: TrainTypes.Station;
  searchDate: string;
  returnDate: string;
  isLoading: boolean;
  toggleOfferPackages: boolean;
  selectedOffers: TrainTypes.OffersSelection;
  selectedOutward: TrainTypes.OffersSelection;
  selectedInward: TrainTypes.OffersSelection;
  selectedReturnFare: boolean;
  locale: string;
  provider: TrainTypes.Provider;
  fees: UtilsTypes.Price = {
    amount: 0,
    currency: "EUR",
  };
  showSidebar: boolean = false;
  errorOccurred: string;
  noMorePreviousResult: boolean = false;
  noMoreNextResult: boolean = false;
  sectionId: string;
  orderId: string;
  allowedAlternativeIds: Array<string>;
  noETicket: boolean;

  constructor(
    public commonService: CommonService,
    private trainService: TrainService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private userService: UserService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
  ) {}

  getSearchResults(update?: boolean): void {
    if (update) {
      this.setDateAndDirection(this.search.data);
      this.selectedOutward = undefined;
    }

    this.loading.emit(true);
    this.errorOccurred = undefined;
    if (update && this.outwardResults) {
      this.searchResult = of(this.outwardResults).pipe(
        switchMap((result: TrainTypes.SearchResult): Observable<TrainTypes.SearchResult> => this.handleResult(result)),
        catchError((err: TrainTypes.Warning | HttpErrorResponse): Observable<undefined> => {
          this.errorOccurred = err instanceof HttpErrorResponse ? err.error.code : err.code;
          this.changeDetector.markForCheck();
          return of(undefined);
        }),
        finalize((): void => this.loading.emit(false)),
      );
    } else {
      let outwardAlternativeIds: Array<string>;
      if (this.selectedOutward && this.provider === "trainline") {
        outwardAlternativeIds = this.selectedOutward.offers.map((_offer: TrainTypes.Offer): string => _offer.id);
      }

      if (
        ((this.searchJourneyDirection === "Outward" || this.searchType === "Exchange") && !this.selectedOutward) ||
        update
      ) {
        this.searchResult = this.trainService.getSearchResults(this.search.id).pipe(
          switchMap(
            (result: TrainTypes.SearchResult): Observable<TrainTypes.SearchResult> => this.handleResult(result),
          ),
          catchError((err: TrainTypes.Warning | HttpErrorResponse): Observable<undefined> => {
            this.errorOccurred = err instanceof HttpErrorResponse ? err.error.code : err.code;
            this.changeDetector.markForCheck();
            return of(undefined);
          }),
          finalize((): void => {
            this.loading.emit(false);
          }),
        );
      } else if (this.searchJourneyDirection === "Inward" && this.selectedOutward && !this.isUKSearch) {
        if (this.searchType === "Exchange") {
          const exchange: TrainTypes.ExchangeBody = this.search.data as TrainTypes.ExchangeBody;
          this.searchDate = `${moment(
            (exchange.exchangeCriteria[1].outwardJourney || exchange.exchangeCriteria[1].inwardJourney).date,
          ).format("YYYY-MM-DD")}T${
            (exchange.exchangeCriteria[1].outwardJourney || exchange.exchangeCriteria[1].inwardJourney).hourWindowTime
          }`;
        } else {
          const search: TrainTypes.SearchBody = this.search.data as TrainTypes.SearchBody;
          this.searchDate = search.inwardJourney.date;
        }
        this.searchResult = this.trainService
          .getInwardSearchResults(
            this.search.id,
            this.provider === "trainline" ? this.selectedOutward.journey.id : this.selectedOutward.offers[0].id,
            outwardAlternativeIds,
            this.provider,
          )
          .pipe(
            switchMap((result: TrainTypes.SearchResult): Observable<TrainTypes.SearchResult> => {
              return this.handleResult(result);
            }),
            catchError((err: TrainTypes.Warning | HttpErrorResponse): Observable<undefined> => {
              this.errorOccurred = err instanceof HttpErrorResponse ? err.error.code : err.code;
              this.changeDetector.markForCheck();
              return of(undefined);
            }),
            finalize((): void => {
              this.loading.emit(false);
            }),
          );
      } else if (this.searchType === "Exchange") {
        this.journeySearchType = (
          this.search.data as TrainTypes.ExchangeBody
        ).exchangeCriteria[0].inwardJourney.hourWindowType;
        this.searchResult = of(this.inwardResults).pipe(
          switchMap((result: TrainTypes.SearchResult): Observable<TrainTypes.SearchResult> => {
            return this.handleResult(result);
          }),
          catchError((err: TrainTypes.Warning | HttpErrorResponse): Observable<undefined> => {
            this.errorOccurred = err instanceof HttpErrorResponse ? err.error.code : err.code;
            this.changeDetector.markForCheck();
            return of(undefined);
          }),
          finalize((): void => {
            this.loading.emit(false);
          }),
        );
      } else {
        this.journeySearchType = (this.search.data as TrainTypes.SearchBody).inwardJourney.hourWindowType;
        this.searchResult = of(this.inwardResults).pipe(
          switchMap((result: TrainTypes.SearchResult): Observable<TrainTypes.SearchResult> => {
            return this.handleResult(result);
          }),
          catchError((err: TrainTypes.Warning | HttpErrorResponse): Observable<undefined> => {
            this.errorOccurred = err instanceof HttpErrorResponse ? err.error.code : err.code;
            this.changeDetector.markForCheck();
            return of(undefined);
          }),
          finalize((): void => {
            this.loading.emit(false);
          }),
        );
      }
    }
  }

  ngOnInit(): void {
    this.searchType = this.search.data.type;
    this.locale = this.translateService.currentLang;
    this.setDateAndDirection(this.search.data);
    this.getSearchResults();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.search && !changes.search.firstChange) {
      this.outwardResults = undefined;
      this.inwardResults = undefined;
      this.getSearchResults(true);
    }
  }

  closeDetailPanel(): void {
    this.showSidebar = false;
    this.sidebarOpen.emit(false);

    if (this.selectedOffers) {
      this.selectedOffers = undefined;
    } else if (this.selectedInward) {
      this.selectedInward = undefined;
    } else if (this.selectedOutward) {
      this.selectedOutward = undefined;
    }
    this.commonService.enableAppScroll();
  }

  loadMore(searchPageDirection: "Previous" | "Next"): boolean {
    this.loading.emit(true);
    this.closeSidebar();
    let dateTime: string;
    if (
      (searchPageDirection === "Previous" && this.noMorePreviousResult) ||
      (searchPageDirection === "Next" && this.noMoreNextResult)
    ) {
      this.loading.emit(true);
      return false;
    }

    if (
      searchPageDirection === "Previous" &&
      (this.journeySearchType === "DepartsAfter" || this.journeySearchType === "ArrivesBefore")
    ) {
      dateTime = moment(this.firstOfferDateTime).subtract(2, "hours").format("YYYY-MM-DDTHH:mm");
    } else {
      dateTime = moment(this.lastOfferDateTime).format("YYYY-MM-DDTHH:mm");
    }

    this.searchResult = this.trainService
      .getMoreResults(
        this.search.id,
        searchPageDirection,
        this.searchJourneyDirection,
        this.journeySearchType,
        this.searchId,
        this.searchType,
        this.provider,
        dateTime,
        this.selectedOutward ? this.selectedOutward.offers[0].id : undefined,
      )
      .pipe(
        map((result: TrainTypes.SearchResult): TrainTypes.SearchResult => {
          this.handleResult(result);
          return result;
        }),
        finalize((): void => {
          this.loading.emit(false);
        }),
        catchError((err: { code: string; description: string }): Observable<undefined> => {
          this.errorOccurred = err.code;
          this.isLoading = false;
          this.changeDetector.markForCheck();
          return of(undefined);
        }),
      );
    return true;
  }

  onOffersSelected(selection: TrainTypes.OffersSelection): void {
    this.selectedOffers = selection;
    this.openSidebar();
  }

  onSelectTrip(offersSelection: TrainTypes.OffersSelection): void {
    if (this.searchJourneyDirection === "Outward") {
      this.selectedOutward = {
        ...offersSelection,
        offers: [offersSelection.offers[offersSelection.selectedOffer]],
      };

      if (this.isUKSearch) {
        this.selectedReturnFare =
          this.selectedOutward.offers[0].allowedAlternatives?.length > 0 &&
          this.selectedOutward.offers[0].fares[0].fareName.includes("Return");
      }

      if (
        this.searchType === "Single" ||
        this.searchType === "OpenReturn" ||
        (this.searchType === "Exchange" &&
          (this.search.data as TrainTypes.ExchangeBody).exchangeCriteria.length === 1 &&
          (((this.search.data as TrainTypes.ExchangeBody).exchangeCriteria[0].outwardJourney &&
            !(this.search.data as TrainTypes.ExchangeBody).exchangeCriteria[0].inwardJourney) ||
            (!(this.search.data as TrainTypes.ExchangeBody).exchangeCriteria[0].outwardJourney &&
              (this.search.data as TrainTypes.ExchangeBody).exchangeCriteria[0].inwardJourney)))
      ) {
        const recapItinerary: TrainTypes.RecapItinerary = {
          booker: {
            firstname: this.userService.user.value.firstname,
            lastname: this.userService.user.value.lastname,
            email: this.userService.user.value.email,
          },
          outward: this.selectedOutward,
          price: this.selectedOutward.offers[0].price,
          fullPrice: this.selectedOutward.offers[0].fullPrice,
          passengers: this.passengers,
          isOutOfPolicy: this.selectedOutward.offers[0].isOutOfPolicy,
          carbonOffset: this.selectedOutward.journey.carbonOffset,
          provider: this.provider,
        };

        this.createItinerary(recapItinerary);
      } else {
        this.searchJourneyDirection = "Inward";
        this.getSearchResults();
      }
      this.selectedOffers = undefined;
    } else {
      this.selectedInward = {
        ...offersSelection,
        offers: [offersSelection.offers[offersSelection.selectedOffer]],
      };

      if (this.searchType === "Exchange" && this.selectedOutward) {
        this.selectedOutward.exchangeOptionId = (
          this.search.data as TrainTypes.ExchangeBody
        ).exchangeCriteria[0].sectionId;
      }

      let recapItinerary: TrainTypes.RecapItinerary;
      if (this.selectedOutward) {
        recapItinerary = {
          booker: {
            firstname: this.userService.user.value.firstname,
            lastname: this.userService.user.value.lastname,
            email: this.userService.user.value.email,
          },
          outward: this.selectedOutward,
          inward: this.selectedInward,
          passengers: this.passengers,
          price: {
            amount: this.selectedOutward.offers[0].price.amount + this.selectedInward.offers[0].price.amount,
            currency: this.selectedOutward.offers[0].price.currency,
          },
          fullPrice: {
            amount: this.selectedOutward.offers[0].fullPrice.amount + this.selectedInward.offers[0].fullPrice.amount,
            currency: this.selectedOutward.offers[0].fullPrice.currency,
          },
          isOutOfPolicy: this.selectedOutward.isOutOfPolicy || this.selectedInward.isOutOfPolicy,
          carbonOffset: {
            amount: this.selectedOutward.journey.carbonOffset.amount + this.selectedInward.journey.carbonOffset.amount,
            price: this.selectedOutward.journey.carbonOffset.price + this.selectedInward.journey.carbonOffset.price,
          },
          provider: this.provider,
        };
      } else {
        recapItinerary = {
          booker: {
            firstname: this.userService.user.value.firstname,
            lastname: this.userService.user.value.lastname,
            email: this.userService.user.value.email,
          },
          inward: this.selectedInward,
          passengers: this.passengers,
          price: {
            amount: this.selectedInward.offers[0].price.amount,
            currency: this.selectedInward.offers[0].price.currency,
          },
          fullPrice: {
            amount: this.selectedInward.offers[0].fullPrice.amount,
            currency: this.selectedInward.offers[0].fullPrice.currency,
          },
          isOutOfPolicy: this.selectedInward.isOutOfPolicy || this.selectedInward.isOutOfPolicy,
          carbonOffset: {
            amount: this.selectedInward.journey.carbonOffset.amount + this.selectedInward.journey.carbonOffset.amount,
            price: this.selectedInward.journey.carbonOffset.price + this.selectedInward.journey.carbonOffset.price,
          },
          provider: this.provider,
        };
      }

      if ((this.search.data as TrainTypes.ExchangeBody).orderId) {
        recapItinerary.orderId = (this.search.data as TrainTypes.ExchangeBody).orderId;
      }

      this.createItinerary(recapItinerary);
      this.selectedOffers = undefined;
    }
    this.showSidebar = false;
    this.sidebarOpen.emit(false);
  }

  changeSearch(): void {
    this.modifySearch.emit();
  }

  redirect(): void {
    this.router.navigate(["/"]);
  }

  private setDateAndDirection(searchBody: TrainTypes.SearchBody | TrainTypes.ExchangeBody): void {
    if (this.searchType === "Exchange") {
      const exchange: TrainTypes.ExchangeBody = searchBody as TrainTypes.ExchangeBody;
      this.origin = (
        exchange.exchangeCriteria[0].outwardJourney || exchange.exchangeCriteria[0].inwardJourney
      ).departureStation;
      this.destination = (
        exchange.exchangeCriteria[0].outwardJourney || exchange.exchangeCriteria[0].inwardJourney
      ).arrivalStation;
      this.searchDate = (
        exchange.exchangeCriteria[0].outwardJourney || exchange.exchangeCriteria[0].inwardJourney
      ).date;
      this.searchJourneyDirection =
        !exchange.exchangeCriteria[0].outwardJourney && exchange.exchangeCriteria[0].inwardJourney
          ? "Inward"
          : "Outward";
    } else {
      const search: TrainTypes.SearchBody = searchBody as TrainTypes.SearchBody;
      this.origin = search.outwardJourney.departureStation;
      this.destination = search.outwardJourney.arrivalStation;
      this.searchDate = search.outwardJourney.date;
      this.searchJourneyDirection = "Outward";

      if (search.inwardJourney) {
        this.returnDate = search.inwardJourney.date;
      }
    }
  }

  private filterJourneysByDate(
    journeyWithOffers: Array<TrainTypes.JourneyWithOffers>,
    searchDate: string,
  ): Array<TrainTypes.JourneyWithOffers> {
    return journeyWithOffers.filter((_journey: TrainTypes.JourneyWithOffers): boolean => {
      return (
        moment(searchDate).startOf("day").isBefore(moment(_journey.departure.date.utc)) &&
        moment(searchDate).endOf("day").isAfter(moment(_journey.departure.date.utc))
      );
    });
  }

  private handleResult(result: TrainTypes.SearchResult): Observable<TrainTypes.SearchResult> {
    if (!result.journeys || result.journeys.length === 0) {
      const warning: TrainTypes.Warning = {
        detail: "No results",
        code: "SEARCH.RESULT.NO_TRAINS",
      };

      this.loading.emit(false);

      if (result.warnings?.length) {
        this.noETicket = result.warnings[0].code === "NO_ELECTRONIC_TICKET";
      }

      return throwError((): TrainTypes.Warning => warning);
    }
    this.provider = result.provider;
    this.searchId = result.id;
    this.passengers = result.passengers;

    if (this.commonService.isTablet) {
      this.activeTabIndex = result.itemsCount?.flexi > 0 ? 2 : result.itemsCount?.semiflexi > 0 ? 1 : 0;
      this.changeDetector.detectChanges();
    }

    if (this.searchType === "Exchange") {
      const searchBody: TrainTypes.ExchangeBody = this.search.data as TrainTypes.ExchangeBody;
      this.sectionId = searchBody.exchangeCriteria[0].sectionId;
      this.orderId = searchBody.orderId;

      if (this.searchJourneyDirection === "Outward") {
        this.isUKSearch =
          searchBody.exchangeCriteria[0].outwardJourney.departureStation.country === "GB" &&
          searchBody.exchangeCriteria[0].outwardJourney.arrivalStation.country === "GB";

        if (this.isUKSearch) {
          if (searchBody.exchangeCriteria[0].inwardJourney) {
            const inwardOffers: Array<TrainTypes.JourneyWithOffers> = [...result.journeys].filter(
              (_journey: TrainTypes.JourneyWithOffers): boolean => _journey.direction === "inward",
            );
            this.inwardResults = {
              ...result,
              journeys: inwardOffers,
            };
            this.inwardResults.journeys = this.filterJourneysByDate(
              inwardOffers,
              `${searchBody.exchangeCriteria[0].inwardJourney.date}T${searchBody.exchangeCriteria[0].inwardJourney.hourWindowTime}`,
            );
          }
        }

        result.journeys = this.filterJourneysByDate(
          result.journeys,
          `${searchBody.exchangeCriteria[0].outwardJourney.date}T${searchBody.exchangeCriteria[0].outwardJourney.hourWindowTime}`,
        );
        this.outwardResults = result;
        this.journeySearchType = searchBody.exchangeCriteria[0].outwardJourney.hourWindowType;
      } else if (this.searchJourneyDirection === "Inward") {
        const searchJourney: TrainTypes.SearchJourney =
          searchBody.exchangeCriteria[0].inwardJourney ?? searchBody.exchangeCriteria[0].outwardJourney;
        result.journeys = this.filterJourneysByDate(
          result.journeys,
          `${searchJourney.date}T${searchJourney.hourWindowTime}`,
        );
        this.isUKSearch =
          searchJourney.departureStation.country === "GB" && searchJourney.arrivalStation.country === "GB";
        this.journeySearchType = (
          searchBody.exchangeCriteria[1] || searchBody.exchangeCriteria[0]
        ).inwardJourney.hourWindowType;
      }
    } else {
      const searchBody: TrainTypes.SearchBody = this.search.data as TrainTypes.SearchBody;
      this.isUKSearch =
        searchBody.outwardJourney.departureStation.country === "GB" &&
        searchBody.outwardJourney.arrivalStation.country === "GB";
      if (this.searchJourneyDirection === "Outward" && !this.selectedOutward && !this.isUKSearch) {
        this.journeySearchType = searchBody.outwardJourney.hourWindowType;
        result.journeys = this.filterJourneysByDate(
          result.journeys,
          `${searchBody.outwardJourney.date}T${searchBody.outwardJourney.hourWindowTime}`,
        );
      } else if (this.searchJourneyDirection === "Inward" && !this.isUKSearch) {
        this.journeySearchType = searchBody.inwardJourney.hourWindowType;
        result.journeys = this.filterJourneysByDate(
          result.journeys,
          `${searchBody.inwardJourney.date}T${searchBody.inwardJourney.hourWindowTime}`,
        );
      } else if (this.isUKSearch) {
        this.journeySearchType = searchBody.outwardJourney.hourWindowType;
        if (
          this.searchJourneyDirection === "Outward" ||
          (this.selectedOutward && this.searchJourneyDirection === "Inward")
        ) {
          const outwardJourneys: Array<TrainTypes.JourneyWithOffers> = [...result.journeys].filter(
            (_offer: TrainTypes.JourneyWithOffers): boolean => _offer.direction === "outward",
          );
          this.outwardResults = {
            ...result,
            journeys: outwardJourneys,
          };
          this.outwardResults.journeys = this.filterJourneysByDate(
            outwardJourneys,
            `${searchBody.outwardJourney.date}T${searchBody.outwardJourney.hourWindowTime}`,
          );
        }

        if (searchBody.inwardJourney) {
          const inwardJourneys: Array<TrainTypes.JourneyWithOffers> = [...result.journeys].filter(
            (_offer: TrainTypes.JourneyWithOffers): boolean => _offer.direction === "inward",
          );
          this.inwardResults = {
            ...result,
            journeys: inwardJourneys,
          };
          this.inwardResults.journeys = this.filterJourneysByDate(
            inwardJourneys,
            `${searchBody.inwardJourney.date}T${searchBody.inwardJourney.hourWindowTime}`,
          );
        }
        result.journeys =
          this.searchJourneyDirection === "Outward" ? this.outwardResults.journeys : this.inwardResults.journeys;

        if (this.selectedOutward && this.searchJourneyDirection === "Inward") {
          const outwardJourney: TrainTypes.JourneyWithOffers = this.outwardResults.journeys.find(
            (_offer: TrainTypes.JourneyWithOffers): boolean => {
              return (
                this.selectedOutward.journey.departure.locationId === _offer.departure.locationId &&
                this.selectedOutward.journey.arrival.locationId === _offer.arrival.locationId &&
                this.selectedOutward.journey.departure.date.utc === _offer.departure.date.utc &&
                this.selectedOutward.journey.arrival.date.utc === _offer.arrival.date.utc
              );
            },
          );

          if (outwardJourney) {
            if (this.searchType === "Return" && !this.selectedOffers) {
              this.selectedOutward = outwardJourney.sections.reduce(
                (
                  _offersSelection: TrainTypes.OffersSelection,
                  _section: TrainTypes.Section,
                ): TrainTypes.OffersSelection => {
                  if (!_offersSelection.sectionIds.includes(_section.id)) {
                    _offersSelection.offers.push(
                      ..._section.offers[_offersSelection.flexibility][_offersSelection.travelClass],
                    );
                    _offersSelection.sectionIds.push(_section.id);
                    _offersSelection.deliveryData.push(
                      ..._section.offers[_offersSelection.flexibility][_offersSelection.travelClass][
                        _offersSelection.selectedOffer
                      ].deliveryData,
                    );
                    _offersSelection.seatPreferenceOptions.push(
                      ..._section.offers[_offersSelection.flexibility][_offersSelection.travelClass][
                        _offersSelection.selectedOffer
                      ].seatReservationOptions,
                    );
                    _offersSelection.discounts.push(
                      ..._section.offers[_offersSelection.flexibility][_offersSelection.travelClass][
                        _offersSelection.selectedOffer
                      ].discounts,
                    );
                    _offersSelection.price.amount +=
                      _section.offers[_offersSelection.flexibility][_offersSelection.travelClass][
                        _offersSelection.selectedOffer
                      ].price.amount;
                    _offersSelection.fullPrice.amount +=
                      _section.offers[_offersSelection.flexibility][_offersSelection.travelClass][
                        _offersSelection.selectedOffer
                      ].fullPrice.amount;
                    _offersSelection.isOutOfPolicy &&=
                      _section.offers[_offersSelection.flexibility][_offersSelection.travelClass][
                        _offersSelection.selectedOffer
                      ].isOutOfPolicy;
                    _offersSelection.changeable &&=
                      _section.offers[_offersSelection.flexibility][_offersSelection.travelClass][
                        _offersSelection.selectedOffer
                      ].changeable;
                    _offersSelection.refundable &&=
                      _section.offers[_offersSelection.flexibility][_offersSelection.travelClass][
                        _offersSelection.selectedOffer
                      ].refundable;
                    return _offersSelection;
                  }
                  return {
                    journey: outwardJourney,
                    flexibility: _section.offers[_offersSelection.selectedOffer].flexibility,
                    travelClass: _section.offers[_offersSelection.selectedOffer].travelClass,
                    offers: _section.offers[_offersSelection.selectedOffer],
                    isOutOfPolicy: _section.offers[_offersSelection.selectedOffer].isOutOfPolicy,
                    price: _section.offers[_offersSelection.selectedOffer].price,
                    fullPrice: _section.offers[_offersSelection.selectedOffer].fullPrice,
                    deliveryData: _section.offers[_offersSelection.selectedOffer].deliveryData,
                    seatPreferenceOptions: _section.offers[_offersSelection.selectedOffer].seatReservationOptions,
                    sectionIds: [_section.id],
                    refundable: _section.offers[_offersSelection.selectedOffer].refundable,
                    changeable: _section.offers[_offersSelection.selectedOffer].changeable,
                    selectedOffer: _offersSelection.selectedOffer,
                    discounts: _offersSelection.discounts,
                  };
                },
                {} as TrainTypes.OffersSelection,
              );
            }
          }
        }
      }
      this.showPackages = this.isUKSearch && this.searchJourneyDirection === "Outward";
    }

    if (this.journeySearchType === "DepartsAfter") {
      this.firstOfferDateTime = moment(result.journeys[0].departure.date.utc).format("YYYY-MM-DDTHH:mm");
      this.lastOfferDateTime = moment(result.journeys[result.journeys.length - 1].departure.date.utc).format(
        "YYYY-MM-DDTHH:mm",
      );
    } else {
      this.firstOfferDateTime = moment(result.journeys[0].arrival.date.utc).format("YYYY-MM-DDTHH:mm");
      this.lastOfferDateTime = moment(result.journeys[result.journeys.length - 1].arrival.date.utc).format(
        "YYYY-MM-DDTHH:mm",
      );
    }

    this.noMorePreviousResult =
      (result.type === "Outward" && !result.pagination.outward.previous) ||
      (result.type === "Inward" && !result.pagination.inward.previous);
    this.noMoreNextResult =
      (result.type === "Outward" && !result.pagination.outward.next) ||
      (result.type === "Inward" && !result.pagination.inward.next);

    this.loading.emit(false);
    return of(result);
  }

  private validateItinerary(updatedItinerary: TrainTypes.Itinerary): void {
    this.closeSidebar();
    if (this.searchType === "Exchange") {
      this.loading.emit(true);
      this.trainService
        .estimateChange(this.orderId, updatedItinerary as TrainTypes.ItineraryChange, this.provider)
        .subscribe((itineraryChange: TrainTypes.ItineraryChange): void => {
          this.loading.emit(false);
          this.selectItinerary.emit({
            itinerary: itineraryChange,
            fees: this.fees,
            provider: this.provider,
            price: itineraryChange.pricing.lineItems.length
              ? itineraryChange.pricing.lineItems
                  .filter((_lineItem: TrainTypes.LineItem): boolean => _lineItem.type === "section")
                  .reduce(
                    (price: UtilsTypes.Price, _lineItem: TrainTypes.LineItem): UtilsTypes.Price => {
                      return {
                        amount: _lineItem.price.amount + price.amount,
                        currency: _lineItem.price.currency,
                      };
                    },
                    { amount: 0, currency: "EUR" },
                  )
              : itineraryChange.pricing.totalAmount,
            origin: this.origin.localizedName || this.origin.name,
            destination: this.destination.localizedName || this.destination.name,
            passengers: this.passengers,
            type: this.searchType,
          });
        });
    } else {
      this.selectItinerary.emit({
        itinerary: updatedItinerary,
        fees: this.fees,
        provider: this.provider,
        price: updatedItinerary.pricing.lineItems.length
          ? updatedItinerary.pricing.lineItems
              .filter((_lineItem: TrainTypes.LineItem): boolean => _lineItem.type === "section")
              .reduce(
                (price: UtilsTypes.Price, _lineItem: TrainTypes.LineItem): UtilsTypes.Price => {
                  return {
                    amount: _lineItem.price.amount + price.amount,
                    currency: _lineItem.price.currency,
                  };
                },
                { amount: 0, currency: "EUR" },
              )
          : updatedItinerary.pricing.totalAmount,
        origin: this.origin.localizedName || this.origin.name,
        destination: this.destination.localizedName || this.destination.name,
        passengers: this.passengers,
        type: this.searchType,
      });
    }
  }

  private mapItinerarySection(sections: Array<TrainTypes.OffersSelection>): Array<TrainTypes.ItinerarySection> {
    return sections.map((_section: TrainTypes.OffersSelection): TrainTypes.ItinerarySection => {
      return {
        fares: _section.offers.flatMap((_offer: TrainTypes.Offer): Array<TrainTypes.Fare> => _offer.fares),
        segments: _section.journey.segments,
        id: _section.sectionIds[0],
        refundable: _section.refundable,
        changeable: _section.changeable,
        departure: _section.journey.departure,
        arrival: _section.journey.arrival,
      };
    });
  }

  private createItinerary(recapItinerary: TrainTypes.RecapItinerary): void {
    if (this.provider === "trainline") {
      this.fees.currency = (recapItinerary.outward || recapItinerary.inward).price.currency;
      if (this.searchType === "Exchange") {
        this.fees.amount = 12;
        const offerSelection: TrainTypes.OffersSelection = recapItinerary.outward || recapItinerary.inward;
        const itineraryRequest: TrainTypes.ItineraryChangeRequest = {
          originalSectionId: this.sectionId,
          outward: {
            offerId: offerSelection.journey.id,
            alternativeIds: [offerSelection.offers[0].id],
          },
          booker: {
            firstname: this.userService.user.value.firstname,
            lastname: this.userService.user.value.lastname,
            email: this.userService.user.value.email,
          },
          friendlyId: (this.search.data as TrainTypes.ExchangeBody).carrierReference,
        };

        if (
          recapItinerary.inward &&
          recapItinerary.inward.offers.some(
            (_offer: TrainTypes.Offer): boolean => itineraryRequest.outward.offerId === _offer.id,
          )
        ) {
          itineraryRequest.inward = {
            offerId: recapItinerary.inward.journey.id,
            alternativeIds: [recapItinerary.inward.offers[0].id],
          };
        }
        this.loading.emit(true);
        this.closeSidebar();
        this.trainService.createItineraryChange(this.orderId, itineraryRequest).subscribe({
          next: (_itinerary: TrainTypes.ItineraryChange): void => {
            this.loading.emit(false);
            this.validateItinerary(_itinerary);
          },
          error: (err: Error): Observable<void> => {
            this.loading.emit(false);
            this.messageService.add({
              detail: err.message,
              severity: "error",
            });
            return of(undefined);
          },
        });
      } else {
        this.fees.amount = 6;
        const itineraryRequest: TrainTypes.ItineraryRequest = {
          outward: {
            offerId: recapItinerary.outward?.journey.id,
            alternativeIds: [recapItinerary.outward?.offers[0].id],
          },
          provider: this.provider,
          booker: {
            firstname: this.userService.user.value.firstname,
            lastname: this.userService.user.value.lastname,
            email: this.userService.user.value.email,
          },
        };

        if (recapItinerary.inward) {
          itineraryRequest.inward = {
            offerId: recapItinerary.inward.journey.id,
            alternativeIds: [recapItinerary.inward.offers[0].id],
          };
        }
        this.loading.emit(true);
        this.closeSidebar();
        this.trainService.createItinerary(itineraryRequest).subscribe({
          next: (_itinerary: TrainTypes.Itinerary): void => {
            this.loading.emit(false);
            this.validateItinerary(_itinerary);
          },
          error: (err: Error): Observable<void> => {
            this.loading.emit(false);
            this.messageService.add({
              detail: err.message,
              severity: "error",
            });
            return of(undefined);
          },
        });
      }
    } else {
      const passengers: Array<TrainTypes.NamedPassenger> = recapItinerary.passengers.map(
        (_passenger: TrainTypes.Passenger): TrainTypes.NamedPassenger => ({
          id: _passenger.id,
          email: _passenger.email,
          firstname: _passenger.firstname,
          lastname: _passenger.lastname,
          dateOfBirth: _passenger.dateOfBirth,
          phone: _passenger.phone,
          passport: _passenger.passport ? { number: _passenger.passport.number } : undefined,
          title: _passenger.title,
          discounts: _passenger.discounts,
        }),
      );
      const seatPreferencesGroupsByDirection: UtilsTypes.ObjectKey<Array<TrainTypes.SeatPreferenceOption>> = {};
      const pricing: TrainTypes.ItemisedPricing = {
        totalAmount: {
          amount: 0,
          currency: recapItinerary.price.currency,
        },
        lineItems: [],
      };
      let parentItinerary: string;
      if (recapItinerary.outward) {
        seatPreferencesGroupsByDirection["outward"] = this.mapSeatPreferenceGroups(
          recapItinerary.outward.seatPreferenceOptions,
          recapItinerary.outward.journey.segments,
        );
        pricing.totalAmount.amount += recapItinerary.outward.offers[0].price.amount;
        pricing.totalAmount.currency = recapItinerary.outward.offers[0].price.currency;
        pricing.lineItems.push({
          price: recapItinerary.outward.offers[0].price,
          type: "section",
        });

        if (this.search.data.type === "Exchange") {
          parentItinerary = (this.search.data as TrainTypes.ExchangeBody).exchangeCriteria[0].sectionId;
        }
      }

      if (recapItinerary.inward) {
        pricing.totalAmount.amount += recapItinerary.inward.offers[0].price.amount;
        seatPreferencesGroupsByDirection["inward"] = this.mapSeatPreferenceGroups(
          recapItinerary.inward.seatPreferenceOptions,
          recapItinerary.inward.journey.segments,
        );
        pricing.lineItems.push({
          price: recapItinerary.inward.offers[0].price,
          type: "section",
        });

        if (this.search.data.type === "Exchange") {
          parentItinerary = (
            (this.search.data as TrainTypes.ExchangeBody).exchangeCriteria[0] ||
            (this.search.data as TrainTypes.ExchangeBody).exchangeCriteria[1]
          ).sectionId;
        }
      }

      const itinerary: TrainTypes.Itinerary = {
        passengers,
        journeys: recapItinerary.inward
          ? recapItinerary.outward
            ? [
                {
                  ...recapItinerary.outward?.journey,
                  direction: "outward",
                },
                {
                  ...recapItinerary.inward.journey,
                  direction: "inward",
                },
              ]
            : [recapItinerary.inward.journey]
          : [
              this.selectedInward
                ? { ...recapItinerary.inward.journey, direction: "inward" }
                : recapItinerary.outward.journey,
            ],
        booker: {
          email: this.userService.user.value.email,
          firstname: this.userService.user.value.firstname,
          lastname: this.userService.user.value.lastname,
        },
        carbonOffset: recapItinerary.carbonOffset,
        status: "Open",
        reservability: "none",
        pricing,
        seatPreferenceOptions: seatPreferencesGroupsByDirection,
        discounts: recapItinerary.passengers.reduce(
          (cards: Array<TrainTypes.Discount>, _passenger: TrainTypes.Passenger): Array<TrainTypes.Discount> => {
            _passenger.discounts?.forEach((_appliedDiscount: TrainTypes.Discount): void => {
              if (!cards.some((_card: TrainTypes.Discount): boolean => _card.type === _appliedDiscount.type)) {
                cards.push({
                  identifier: _appliedDiscount.identifier,
                  provider: _appliedDiscount.provider,
                  program: _appliedDiscount.program,
                  type: _appliedDiscount.type,
                });
              }
            });
            return cards;
          },
          [],
        ),
        deliveryOptions: recapItinerary.inward
          ? recapItinerary.outward
            ? [...recapItinerary.outward.deliveryData, ...recapItinerary.inward.deliveryData]
            : [...recapItinerary.inward.deliveryData]
          : recapItinerary.outward.deliveryData,
        refundable: recapItinerary.inward
          ? recapItinerary.outward
            ? recapItinerary.inward.refundable && recapItinerary.outward.refundable
            : recapItinerary.inward.refundable
          : recapItinerary.outward.refundable,
        changeable: recapItinerary.inward
          ? recapItinerary.outward
            ? recapItinerary.inward.changeable && recapItinerary.outward.changeable
            : recapItinerary.inward.changeable
          : recapItinerary.outward.changeable,
        sections: this.mapItinerarySection(
          recapItinerary.inward
            ? recapItinerary.outward
              ? [recapItinerary.outward, recapItinerary.inward]
              : [recapItinerary.inward]
            : [recapItinerary.outward],
        ),
        offerIds: recapItinerary.inward
          ? recapItinerary.outward
            ? [
                ...recapItinerary.inward.offers.map((_offer) => _offer.id),
                ...recapItinerary.outward.offers.map((_offer) => _offer.id),
              ]
            : recapItinerary.inward.offers.map((_offer) => _offer.id)
          : recapItinerary.outward.offers.map((_offer) => _offer.id),
      };
      if (this.searchType === "Exchange") {
        const exchangeBody: TrainTypes.ExchangeBody = this.search.data as TrainTypes.ExchangeBody;
        const itineraryChange: TrainTypes.ItineraryChange = {
          ...itinerary,
          orderId: exchangeBody.orderId,
          changes: exchangeBody.exchangeCriteria.map(
            (_criteria: TrainTypes.ExchangeCriteria, index: number): TrainTypes.Change => {
              return {
                sectionId: _criteria.sectionId,
                offerIds: (index === 0
                  ? recapItinerary.outward || recapItinerary.inward
                  : recapItinerary.inward
                ).offers.map((_offer: TrainTypes.Offer): string => _offer.id),
                reason: Reason.CUSTOMER_REQUESTED,
                userIds: _criteria.userIds,
              };
            },
            parentItinerary,
          ),
        };
        itineraryChange.deliverySelection = itineraryChange.deliveryOptions[0];
        this.validateItinerary(itineraryChange);
      } else {
        this.validateItinerary(itinerary);
      }
    }
  }

  private mapSeatPreferenceGroups(
    seatPreferenceOptions: Array<TrainTypes.SeatPreferenceOption>,
    segments: Array<TrainTypes.Segment>,
  ): Array<TrainTypes.SeatPreferenceOption> {
    const seatPreferenceOptionsBySegment: Array<TrainTypes.SeatPreferenceOption> = [];
    seatPreferenceOptions.forEach((seatPreferenceOption: TrainTypes.SeatPreferenceOption): void => {
      const foundSegment: TrainTypes.Segment = segments.find((_segment: TrainTypes.Segment): boolean => {
        return seatPreferenceOption.segmentIds.includes(_segment.id);
      });
      if (foundSegment) {
        const foundSeatPreferenceOption: TrainTypes.SeatPreferenceOption = seatPreferenceOptionsBySegment.find(
          (_seatPreferenceOption: TrainTypes.SeatPreferenceOption): boolean =>
            _seatPreferenceOption.segmentIds.includes(foundSegment.id),
        );
        if (foundSeatPreferenceOption) {
          foundSeatPreferenceOption.passengerIds.push(...seatPreferenceOption.passengerIds);
          foundSeatPreferenceOption.passengerIds = Array.from(new Set(foundSeatPreferenceOption.passengerIds));
        } else {
          seatPreferenceOptionsBySegment.push(seatPreferenceOption);
        }
      }
    });
    return seatPreferenceOptionsBySegment;
  }

  private openSidebar(): void {
    this.commonService.statusBarTextBlack();
    this.showSidebar = true;
    this.sidebarOpen.emit(true);
  }

  private closeSidebar(): void {
    this.commonService.statusBarTextWhite();
    this.showSidebar = false;
    this.sidebarOpen.emit(false);
  }
}
