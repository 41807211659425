import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, OnDestroy } from "@angular/core";

import { CommonService } from "../../services/common.service";
import { MenuService, Item } from "./menu.service";
import { SessionService, SessionType } from "../../services/session.service";
import { Router } from "@angular/router";
import { UserService } from "../../services/user.service";
import { User } from "../../@types/user";
import { filter, switchMap } from "rxjs/operators";
import { AuthService } from "../../../auth/auth.service";
import { Subscription } from "rxjs";
import { BasketService } from "../../services/basket.service";
declare const cordova: any;
@Component({
  selector: "spt-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MenuComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public displayMenu: boolean = false;
  public items: Array<Item>;
  public user: User;
  public basketOpened: any;
  public basketValidation: any;
  public basketCount: number;
  protected currentOpenedBasketId: string = undefined;

  constructor(
    public commonService: CommonService,
    public menuService: MenuService,
    private sessionService: SessionService<SessionType>,
    private router: Router,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
    private basketService: BasketService,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.filter((s) => !s.closed).forEach((s) => s.unsubscribe());
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.menuService.displayMenu.subscribe((display: boolean) => {
        this.displayMenu = display;
        this.cd.detectChanges();
        if (display === true) {
          this.commonService.disableAppScroll();
        } else {
          this.commonService.enableAppScroll();
        }
        this.items = this.menuService.menu;
      }),

      this.userService.user.pipe(filter((user: User) => !!user)).subscribe((user: User) => {
        this.user = user;
        this.currentOpenedBasketId = this.user.activeBasket ?? "";
        if (this.currentOpenedBasketId) {
          const basketItems = this.items.find((item: any) => item.icon === "basket")
          if (basketItems) {
            basketItems.routerLink += this.currentOpenedBasketId
          }
        }
      }),

      this.basketService.counting$.subscribe((counting) => {
        this.basketCount = counting.validating + counting.inProgress
      }),
    );
  }

  goTo(item: Item): void {
    if (item.label === "HEADER.HELP") {
      if (this.commonService.isCordova()) {
        cordova.InAppBrowser.open(`https://intercom.help/supertripper/${item.lang}/`, item.target);
      } else {
        window.open(`https://intercom.help/supertripper/${item.lang}/`, item.target);
      }
    }
    if (item.routerLink) {
      this.router.navigate(item.routerLink?.endsWith("/") ? [item.routerLink, ""] : [item.routerLink]).then(() => {
        this.close();
      });
    } else {
      item.command();
      this.close();
    }
  }

  logout(): void {
    const userProfile: any = this.authService.getUserProfile();
    if (this.userService.user.value.settings.auth && this.userService.user.value.settings.auth.sso && userProfile) {
      this.authService
        .logout(userProfile, this.commonService.isCordova())
        .pipe(
          switchMap((data: { logoutUri: string }) => {
            const browser: Window = window.open(data.logoutUri, "_system", "location=no");
            browser.onunload = (): void => {
              browser.close();
            };
            this.authService.clearUserProfile();
            return this.sessionService.close();
          }),
        )
        .subscribe(() => {
          this.close();
          this.router.navigate(["/auth"]);
        });
    } else {
      this.sessionService.close().subscribe(() => {
        this.close();
      });
    }
  }
  close(): void {
    this.menuService.remove();
  }
}
