import { MessageModule } from "primeng/message";
import { SharedModule } from "../@shared/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { NgModule } from "@angular/core";
import { TimeSelectorModule } from "../dashboard/@dashboard-shared/components/time-selector/time-selector.module";
import { SearchDatetimeComponent } from "./search-engine/common/search-datetime/search-datetime.component";
import { SearchPeopleComponent } from "./search-engine/common/search-people/search-people.component";
import { LocalityAutocompleteComponent } from "./search-engine/common/travel-search-autocomplete/locality-autocomplete/locality-autocomplete.component";
import { TravelSearchAutocompleteComponent } from "./search-engine/common/travel-search-autocomplete/travel-search-autocomplete.component";
import { SearchCarComponent } from "./search-engine/search-car/search-car.component";
import { SearchFlightComponent } from "./search-engine/search-flight/search-flight.component";
import { SearchHotelComponent } from "./search-engine/search-hotel/search-hotel.component";
import { SearchSeminarComponent } from "./search-engine/search-seminar/search-seminar.component";
import { SearchTrainComponent } from "./search-engine/search-train/search-train.component";
import { SelectMembersModule } from "../dashboard/@dashboard-shared/modals/select-members/select-members.module";
import { SearchEngineComponent } from "./search-engine/search-engine.component";
import { FavoritesComponent } from "./search-engine/common/favorites/favorites.component";
import { SearchTransferComponent } from "./search-engine/search-transfer/search-transfer.component";

const SEARCH_MODULES: Array<any> = [
  SharedModule,
  SelectMembersModule,
  TimeSelectorModule,
  MessageModule,
  TranslateModule.forChild(),
];

const SEARCH_COMPONENTS: any[] = [
  LocalityAutocompleteComponent,
  TravelSearchAutocompleteComponent,
  SearchDatetimeComponent,
  SearchPeopleComponent,
  FavoritesComponent,

  SearchTrainComponent,
  SearchFlightComponent,
  SearchCarComponent,
  SearchSeminarComponent,
  SearchHotelComponent,
  SearchEngineComponent,
  SearchTransferComponent,
];

@NgModule({
  imports: [...SEARCH_MODULES],
  declarations: [...SEARCH_COMPONENTS],
  providers: [],
})
export class SearchModule {}
