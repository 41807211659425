import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, of, merge } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import { UserLabel } from "../@types/label";
import moment from "moment";
import { UserService } from "./user.service";
export interface SearchOptions {
  board: any;
  boardtype: string;
  type: string;
  popHotels: string;
  popRooms: string;
  // distance: string;
  groupHotel?: boolean;
  upgrades?: boolean;
  cancellable?: boolean;
}

export interface DetailsOptions {
  _id: string;
  provider: string;
}

export interface PositionSearch {
  position: [number, number];
}

export interface BookOptions {
  accom: string;
  board: string;
  bookrequest?: string;
  billing?: string;
  userLabel?: UserLabel;
  email?: string;
}

export interface FavoriteData {
  oads?: any[];
  coordinates?: [number, number];
  metadata?: any;
}

@Injectable({
  providedIn: "root",
})
export class HotelService {
  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
  ) {}

  searchEngine(input: string) {
    const endpoint = `${environment.api}/hotels/`;
    const params: HttpParams = new HttpParams({ fromObject: { input } });
    return this.httpClient.get(endpoint, { params });
  }

  searchHotels(
    longitude: number,
    latitude: number,
    maxDistance: number,
    accomcodeToIgnore: string[],
    options?: { stars: number[] },
    silenced: boolean = false,
  ) {
    let headers: HttpHeaders;
    if (silenced) {
      headers = new HttpHeaders({
        ignoreLoading: "true",
        ignoreLoadingBar: "true",
        ignoreErrorMessage: "true",
        "accept-language": this.userService.user.value.settings?.language ?? "fr",
      });
    } else {
      headers = new HttpHeaders({
        "accept-language": this.userService.user.value.settings?.language ?? "fr",
      });
    }
    const endpoint: string = `${environment.api}/hotels/${latitude}/${longitude}`;
    return this.httpClient
      .post(
        endpoint,
        {
          accomcodeToIgnore: accomcodeToIgnore.join(","),
          maxDistance: maxDistance.toString(),
        },
        { headers },
      )
      .pipe(map((hotels: any) => this.setScoreHotels(hotels, longitude, latitude, options)));
  }

  getAvailabilities(
    datein: string | Date,
    dateout: string | Date,
    accomcodes: string[],
    board: [string[]],
    options?: {
      boardType: "BB" | "BD" | "FB";
      cancellable: boolean;
      maxAmount?: number;
      cityCode?: string;
      onlyNegociatedFares?: boolean;
    },
  ) {
    let _datein, _dateout;
    if (typeof datein === "string") {
      _datein = datein;
    } else if (datein instanceof Date) {
      _datein = moment(datein).format("YYYY-MM-DD");
    } else {
      throw new Error("Invalid datein format");
    }

    if (typeof dateout === "string") {
      _dateout = dateout;
    } else if (dateout instanceof Date) {
      _dateout = moment(dateout).format("YYYY-MM-DD");
    } else {
      throw new Error("Invalid dateout format");
    }

    const headers: HttpHeaders = new HttpHeaders({
      ignoreLoading: "true",
      ignoreLoadingBar: "true",
      ignoreErrorMessage: "true",
    });
    const params = new HttpParams({
      fromObject: Object.entries(options).reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = `${value}`;
        }
        return acc;
      }, {}),
    });
    return this.httpClient
      .post(
        `${environment.api}/hotels/availabilities/${_datein}/${_dateout}/${board.join(";")}`,
        {
          accomcodes: accomcodes.join(","),
        },
        {
          params,
          headers,
        },
      )
      .pipe(
        map((data: { sessionId: string; availabilities: any[] }) => {
          data.availabilities.forEach((availability) => {
            availability.possibilities.forEach((possibility) => {
              possibility.sessionId = data.sessionId;
            });
          });
          return data;
        }),
        catchError((error) => {
          return of({ availabilities: [] });
        }),
      );
  }

  getRatesDetails(accomtoken: string, sessionId: string) {
    const endpoint = `${environment.api}/hotels/rates/${sessionId}/${accomtoken}`;
    return this.httpClient.get(endpoint, {
      headers: { ignoreErrorMessage: "true" },
    });
  }

  getRatesDetailsByBookingRef(bookingref: string) {
    const endpoint = `${environment.api}/hotels/rates/${bookingref}`;
    return this.httpClient.get(endpoint, {
      headers: { ignoreErrorMessage: "true" },
    });
  }

  findHotel(query: string): Observable<FindHotelResult[]> {
    const endpoint = `${environment.api}/hotels/${encodeURIComponent(query)}`;
    return this.httpClient.get(endpoint) as Observable<FindHotelResult[]>;
  }

  addFavorite(type: "user" | "company", label: string, engine: "hotel", data: FavoriteData) {
    const endpoint = `${environment.api}/favorites`;
    return this.httpClient.post(endpoint, {
      type,
      label,
      engine,
      ...data,
    });
  }

  removeFavorite(id: string) {
    const endpoint = `${environment.api}/favorites/${id}`;
    return this.httpClient.delete(endpoint);
  }

  setScoreHotels(hotels: any[], latitude = null, longitude = null, options: { stars: number[] }) {
    if (hotels && hotels.length > 0) {
      hotels.forEach((hotel) => {
        if (latitude && longitude) {
          hotel.distance = this.distance(hotel.coordinates[1], hotel.coordinates[0], latitude, longitude);
        }
      });
      return hotels.filter(
        (hotel) =>
          !hotel.description ||
          (options.stars && options.stars.length > 0 ? options.stars.includes(hotel.description.rank) : true),
      );
    } else {
      return hotels;
    }
  }

  getDescription(
    accomcode: string,
    provider: string,
    ignoreLoading: boolean = false,
    forceUpdate: boolean = false,
  ): Observable<any> {
    const endPoint: string = `${environment.api}/hotels/${accomcode}:${provider}/description`;
    return this.httpClient.get(endPoint, {
      params: {
        forceUpdate: forceUpdate ? "true" : "false",
      },
      headers: {
        ignoreErrorMessage: "true",
        ignoreLoadingBar: ignoreLoading ? "true" : "false",
        "accept-language": this.userService.user.value.settings.language,
      },
    });
  }

  distance(lat1, lon1, lat2, lon2) {
    const p = 0.017453292519943295; // Math.PI / 180
    const c = Math.cos;
    const a = 0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

    return 12742000 * Math.asin(Math.sqrt(a)); // 2 * R * 1000; R = 6371 km
  }

  getHotels(accomcodes: string[]) {
    const endPoint: string = `${environment.api}/hotels/list/${accomcodes}`;
    return this.httpClient.get(endPoint, {
      headers: {
        ignoreErrorMessage: "true",
        "accept-language": this.userService.user.value.settings.language,
      },
    });
  }
}

export interface FindHotelResult {
  accomcode: string;
  description: {
    title: string;
  };
}
