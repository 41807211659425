import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { SelectItem } from "primeng/api";
import { CommonService } from "../../../../../@shared/services/common.service";
import { TrainTypes } from "../../../../../travel/train/train";
import { Reason, TrainService } from "../../../../../travel/train/train.service";
import { LoadingService } from "../../../../../@shared/services/loading.service";
import { forkJoin, mergeMap, Observable, of, Subscription } from "rxjs";
import { UtilsTypes } from "../../../../../@shared/@types/utils";
import { SearchService } from "src/app/@shared/services/search.service";
import { Router } from "@angular/router";

@Component({
  selector: "spt-reservations-modify-train",
  templateUrl: "./train.component.html",
  styleUrls: ["./train.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservationsModifyTrainComponent implements OnInit, OnDestroy {
  @Input() itemId: string;
  @Input() orderId: string;
  @Input() orderItems: Array<TrainTypes.OrderItem>;
  @Input() relatedOrderItems: Array<TrainTypes.RelatedOrderItem>;
  @Input() isSmallDevice: boolean;
  @Input() locale: string;
  @Input() travelers: Array<any>;
  @Input() provider: TrainTypes.Provider;
  @Input() hasTickets: boolean;

  private exchangeOptions: TrainTypes.Option[] = [];
  protected journeys: TrainTypes.RefundJourney[] = [];
  protected noChangeableOptions: boolean = false;
  protected yearRange: string = "1930:" + new Date().getFullYear();
  protected hourWindowTypes: Array<SelectItem> = [
    {
      label: this.translateService.instant("SEARCH.DEPARTURE_AFTER"),
      value: "DepartsAfter",
    },
    {
      label: this.translateService.instant("SEARCH.ARRIVAL_BEFORE"),
      value: "ArrivesBefore",
    },
  ];
  protected minDate: Date;
  protected times: Array<any> = [
    { label: "--:--", value: "00:00" },
    { label: "06:00", value: "06:00" },
    { label: "08:00", value: "08:00" },
    { label: "10:00", value: "10:00" },
    { label: "12:00", value: "12:00" },
    { label: "14:00", value: "14:00" },
    { label: "16:00", value: "16:00" },
    { label: "18:00", value: "18:00" },
    { label: "20:00", value: "20:00" },
    { label: "22:00", value: "22:00" },
  ];

  private subscriptions: Subscription[] = [];
  protected modifyForm: FormGroup<ExchangeForm>;

  protected COJFee: UtilsTypes.Price;

  constructor(
    private trainService: TrainService,
    private translateService: TranslateService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private loadingService: LoadingService,
    private searchService: SearchService,
    private router: Router,
  ) {}

  private buildForm(): FormGroup<ExchangeForm> {
    const form = this.fb.group<ExchangeForm>({
      journeyIndex: this.fb.control(undefined),
      date: this.fb.control(undefined, Validators.required),
      hourWindowTime: this.fb.control("00:00", Validators.required),
      hourWindowType: this.fb.control("DepartsAfter", Validators.required),
      direction: this.fb.control(undefined, Validators.required),
    });
    this.subscriptions.push(form.controls.journeyIndex.valueChanges.subscribe((index) => this.onSelectJourney(index)));
    return form;
  }

  private static mapExchangeOptionsToJourneys(exchangeOptions: TrainTypes.Option[]): TrainTypes.RefundJourney[] {
    const journeys: TrainTypes.RefundJourney[] = [];
    exchangeOptions.forEach((_exchangeOption: TrainTypes.Option): void => {
      if (_exchangeOption.partial) {
        _exchangeOption.journeys.forEach((_refundJourney: TrainTypes.RefundJourney): void => {
          const foundJourney: TrainTypes.RefundJourney = journeys.find(
            (_journey: TrainTypes.RefundJourney): boolean => {
              return (
                _journey.departure.name === _refundJourney.departure.name &&
                _journey.arrival.name === _refundJourney.arrival.name
              );
            },
          );

          if (!foundJourney && _refundJourney) {
            journeys.push(Object.assign({ exchangeOptionId: _exchangeOption.id }, _refundJourney));
          }
        });
      } else {
        const newJourney: TrainTypes.RefundJourney = {
          ..._exchangeOption.journeys[0],
          arrival: _exchangeOption.journeys[_exchangeOption.journeys.length - 1].arrival,
        } as TrainTypes.RefundJourney;
        const foundJourney: TrainTypes.RefundJourney = journeys.find((_journey: TrainTypes.RefundJourney): boolean => {
          return (
            _journey.departure.name === newJourney.departure.name && _journey.arrival.name === newJourney.arrival.name
          );
        });

        if (!foundJourney) {
          journeys.push({
            ..._exchangeOption.journeys[0],
            exchangeOptionId: _exchangeOption.id,
            arrival: _exchangeOption.journeys[_exchangeOption.journeys.length - 1].arrival,
          });
        }
      }
    });

    journeys.sort((_journeyA: TrainTypes.RefundJourney, _journeyB: TrainTypes.RefundJourney) =>
      _journeyA.direction === "outward" ? -1 : 1,
    );
    return journeys;
  }

  private onSelectJourney(index?: number): void {
    this.modifyForm.controls.date.setValue(DateTime.fromISO(this.journeys[index].departure.date.date).toJSDate());
    this.modifyForm.controls.direction.setValue(this.journeys[index].direction);
  }

  private createExchangeBody(): TrainTypes.ExchangeBody {
    const formValue: ExchangeFormValue = this.modifyForm.value as ExchangeFormValue;

    let exchangeCriteria: Array<TrainTypes.ExchangeCriteria> = [];

    let criteria: TrainTypes.ExchangeCriteria;

    const exchangeOptionId = this.journeys[formValue.journeyIndex].exchangeOptionId;
    const selectedExchangeOption = this.exchangeOptions.find(
      (exchangeOption) => exchangeOption.id === exchangeOptionId,
    );
    if (this.provider === "trainline") {
      const selectedChangeableIds: Array<string> = [exchangeOptionId];

      criteria = {
        sectionId: selectedExchangeOption.sectionId,
        selectedChangeableIds,
        reason: Reason.CUSTOMER_REQUESTED,
      };
    } else {
      criteria = {
        sectionId: selectedExchangeOption.id,
        reason: Reason.CUSTOMER_REQUESTED,
      };
    }
    exchangeCriteria.push(criteria);

    const userIds: Array<string> = [];
    selectedExchangeOption.passengers.forEach((_passenger: TrainTypes.Passenger): void => {
      const traveler: any = this.travelers.find((_traveler: any): boolean => {
        return (
          (_passenger.discounts &&
            _passenger.discounts.length &&
            ((_traveler.subscriptions &&
              _traveler.subscriptions.length &&
              _passenger.discounts.some((_appliedDiscount: TrainTypes.Discount): boolean => {
                return _traveler.subscriptions.some(
                  (_subscription: any): boolean => _subscription.number === _appliedDiscount.identifier,
                );
              })) ||
              (_traveler.fidelities &&
                _traveler.fidelities.length &&
                _passenger.discounts.some((_appliedDiscount: TrainTypes.Discount): boolean => {
                  return _traveler.fidelities.some(
                    (_fidelity: any): boolean => _fidelity.number === _appliedDiscount.identifier,
                  );
                })))) ||
          ((`${_traveler.firstname} ${_traveler.lastname}`
            .replace("s/n", "")
            .replace(/[-\/']/g, " ")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .trim()
            .toUpperCase() ===
            `${_passenger.firstname} ${_passenger.lastname}`
              .replace("s/n", "")
              .replace(/[-\/']/g, " ")
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .trim()
              .toUpperCase() ||
            `${_traveler.firstname} A ${_traveler.lastname}`
              .replace("s/n", "")
              .replace(/[-\/']/g, " ")
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .trim()
              .toUpperCase() ===
              `${_passenger.firstname} ${_passenger.lastname}`
                .replace("s/n", "")
                .replace(/[-\/']/g, " ")
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .trim()
                .toUpperCase()) &&
            DateTime.fromISO(_traveler.birthdate).toISODate() === _passenger.dateOfBirth)
        );
      });

      if (traveler && !userIds.includes(traveler.userId)) {
        userIds.push(traveler.userId);
      }
    });

    let carrierReference: string;
    const orderItem: TrainTypes.OrderItem = this.orderItems.find((_orderItem: TrainTypes.OrderItem): boolean => {
      return selectedExchangeOption.orderItemIds.includes(
        _orderItem.type === "itinerary"
          ? _orderItem.item.id
          : (_orderItem.item as TrainTypes.ItineraryChange).parentItinerary,
      );
    });
    if (orderItem) {
      carrierReference = orderItem.carrierReference;
    }

    if (formValue.direction === "outward") {
      criteria.outwardJourney = this.buildSearchJourney(formValue);
    } else {
      criteria.inwardJourney = this.buildSearchJourney(formValue);
    }

    return {
      orderId: selectedExchangeOption.orderId,
      exchangeCriteria,
      type: "Exchange",
      provider: this.provider,
      userIds,
      carrierReference,
    };
  }

  private buildSearchJourney(formValue: ExchangeFormValue): TrainTypes.SearchJourney {
    const selectedJourney = this.journeys[formValue.journeyIndex];
    return {
      departureStation: {
        city: selectedJourney.departure.city,
        name: selectedJourney.departure.name,
        locationId: selectedJourney.departure.locationId,
        country: selectedJourney.departure.country,
      },
      arrivalStation: {
        city: selectedJourney.arrival.city,
        name: selectedJourney.arrival.name,
        locationId: selectedJourney.arrival.locationId,
        country: selectedJourney.arrival.country,
      },
      date: DateTime.fromJSDate(formValue.date).toISODate(),
      hourWindowTime: formValue.hourWindowTime,
      hourWindowType: formValue.hourWindowType,
    };
  }

  private mapChangeables(exchangeOptions: Array<Array<TrainTypes.Option>>): void {
    const now = DateTime.now().toMillis();
    this.exchangeOptions = (exchangeOptions || [])
      .flatMap((a) => a)
      .filter((e) => e.isValid)
      .map((exchangeOption) => this.keepValidJourneysForDate(exchangeOption, now))
      .filter((e) => e.journeys?.length > 0);

    this.journeys = ReservationsModifyTrainComponent.mapExchangeOptionsToJourneys(this.exchangeOptions);
    if (exchangeOptions.length > 0) {
      this.modifyForm = this.buildForm();
    } else {
      this.noChangeableOptions = true;
      this.trainService.synchronizePNR(this.orderId).subscribe();
      this.modifyForm = undefined;
    }
    this.loadingService.remove();
    this.changeDetector.markForCheck();
  }

  keepValidJourneysForDate(exchangeOption: TrainTypes.Option, dateInMillis: number): any {
    const result = Object.assign(exchangeOption, {
      journeys: exchangeOption.journeys.filter(
        (journey) =>
          DateTime.fromMillis(getTime(journey.departure.date.utc)).plus({ minutes: 20 }).toMillis() > dateInMillis,
      ),
    });
    if (exchangeOption.partial !== true && result.journeys.length !== exchangeOption.journeys.length) {
      // N'autorise pas la prise en compte partiel. Donc on invalide toutes les journeys
      result.journeys = [];
    }
    return result;
  }

  ngOnInit(): void {
    this.loadingService.add();

    if (
      this.provider === "trainline" &&
      this.orderItems.some(
        (_orderItem: TrainTypes.OrderItem) => _orderItem.carrier === "ATOC" && _orderItem.item.refundable === false,
      )
    ) {
      this.COJFee = {
        amount: 10,
        currency: "GBP",
      };
    }
    let observable: Observable<TrainTypes.Option[][]>;
    if (this.relatedOrderItems?.length) {
      observable = forkJoin(
        this.relatedOrderItems.map((_relatedOrderItem: TrainTypes.RelatedOrderItem) =>
          this.trainService.getChangeables(_relatedOrderItem.orderId, this.provider),
        ),
      ).pipe(
        mergeMap((changeables: Array<Array<Array<TrainTypes.Option>>>): Observable<Array<Array<TrainTypes.Option>>> => {
          return of(changeables.flat());
        }),
      );
    } else {
      observable = this.trainService.getChangeables(this.orderId, this.provider);
    }
    this.subscriptions.push(
      observable.subscribe({
        next: (_exchangeOptions: Array<Array<TrainTypes.Option>>): void => this.mapChangeables(_exchangeOptions),
        error: (): void => this.loadingService.remove(),
      }),
    );
  }

  calendarShown(calendar?: any): void {
    this.commonService.setBackFunction((): void => {
      calendar.toggle();
    }, this);
  }

  calendarClosed(): void {
    this.commonService.unsetBackFunction();
  }

  ngOnDestroy(): void {
    this.subscriptions.filter((s) => !s.closed).forEach((s) => s.unsubscribe());
  }

  searchExchange(): void {
    this.searchService.create("train", this.createExchangeBody(), this.locale, this.itemId).subscribe(
      (search: { id: string }): Promise<boolean> =>
        this.router.navigate(["travel", search.id], {
          queryParams: {
            itemId: this.itemId,
          },
        }),
    );
  }
}
function getTime(date: any): number {
  if (!date) {
    return NaN;
  }
  if (typeof date.getTime === "function") {
    return date.getTime();
  }
  return Date.parse(date.toString());
}
export interface ExchangeFormValue {
  journeyIndex: number;
  date: Date;
  hourWindowTime: string;
  hourWindowType: TrainTypes.JourneySearchType;
  direction: TrainTypes.OfferDirection;
}
export interface ExchangeForm {
  journeyIndex: FormControl<number>;
  date: FormControl<Date>;
  hourWindowTime: FormControl<string>;
  hourWindowType: FormControl<TrainTypes.JourneySearchType>;
  direction: FormControl<TrainTypes.OfferDirection>;
}
