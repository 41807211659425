<ng-template #template>
  <div
    class="dates-container"
    [style.grid-area]="gridArea"
    [class.active]="isActive"
    [ngClass]="styleClass || styleClassDateMandatory"
  >
    <div class="date" (click)="activateSuggest()">
      <div class="search-datetime">
        <div class="date-button">
          <div
            class="outward datein"
            [class.valid]="sourceWhenCriteria.outward.date && sourceWhenCriteria.outward.time?.begin"
          >
            <div class="calendar p-inputgroup">
              <span class="label">{{ i18n.outwardLabelDate }}</span>
              <img src="./assets/svg/calendar-violet.svg" class="p-inputgroup-addon" />
              <span class="input-date" [ngClass]="sourceWhenCriteria.outward.date ? '' : 'empty'">{{
                (sourceWhenCriteria.outward.date | date: dateFormat) || i18n.outwardDatePlaceholder
              }}</span>
              <div class="hourWindow" *ngIf="time">
                <span class="hourWindowType" *ngIf="!i18n.outwardLabelTime">{{ label.outward.type }}</span>
                <span class="label-time" [ngClass]="label.outward.time ? '' : 'empty'">{{
                  i18n.outwardLabelTime
                }}</span>
                <span class="hour">{{ label.outward.time }}</span>
              </div>
            </div>
          </div>
          <div
            class="inward dateout"
            *ngIf="sourceWhenCriteria.inward"
            [class.valid]="
              sourceWhenCriteria.inward.date &&
              (searchEngineType !== 'hotel' ||
                (searchEngineType === 'hotel' && sourceWhenCriteria.outward.date !== sourceWhenCriteria.inward.date))
            "
          >
            <div class="calendar p-inputgroup">
              <span class="label">{{ i18n.inwardLabelDate }}</span>
              <img src="./assets/svg/calendar-violet.svg" class="p-inputgroup-addon" />
              <span class="input-date" [ngClass]="sourceWhenCriteria.inward.date ? '' : 'empty'">{{
                (sourceWhenCriteria.inward.date | date: dateFormat) || i18n.inwardDatePlaceholder
              }}</span>
              <!--(this.sessionService.getSession() | async)?.dateFormat-->
              <div class="hourWindow" *ngIf="time">
                <span class="hourWindowType" *ngIf="!i18n.inwardLabelTime">{{ label.inward.type }}</span>
                <span class="label-time" [ngClass]="sourceWhenCriteria.inward.time ? '' : 'empty'">{{
                  i18n.inwardLabelTime
                }}</span>
                <span class="hour">{{ label.inward.time }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p
    *ngIf="searchEngineType === 'train' || searchEngineType === 'flight'"
    class="error"
    [ngClass]="styleClass"
    [style.grid-area]="gridAreaError"
  >
    {{ "SEARCH.TRAIN.ERROR_TIME_MANDATORY" | translate: { searchEngineType: searchType } }}
  </p>
  <p
    *ngIf="searchEngineType === 'flight'"
    class="error"
    [ngClass]="styleClassDateMandatory"
    [style.grid-area]="gridAreaError"
  >
    {{ "SEARCH.TRAIN.ERROR_DATE_MANDATORY" | translate }}
  </p>
  <div
    *ngIf="initCalendar"
    class="suggest-panel datetime"
    [ngClass]="suggestPanelService.active.name === suggestPanelName ? 'active' : ''"
  >
    <button
      type="button"
      class="closeModalButton fas fa-times fa-2x m-l-auto"
      (mousedown)="hideSuggest()"
      *ngIf="commonService.isTablet"
    ></button>
    <div class="selectDates" [ngClass]="time ? ' time-true' : ''">
      <p-calendar
        [(ngModel)]="calendarDates"
        [inline]="true"
        [showWeek]="false"
        [selectionMode]="calendarSelectionMode"
        [numberOfMonths]="12"
        [maxDateCount]="maxDateCount"
        [minDate]="minStartDate"
        [dateFormat]="calendarDateFormat"
        [firstDayOfWeek]="calendarFirstDayOfWeek"
        [yearRange]="yearRange"
        (onSelect)="updateSourceWhenCriteriaFromEdit($event)"
      >
        <p-header>
          <p class="text-center f-sb txt-20">
            {{ i18n.oneWaySuggestPanelTitle }}
          </p>
          <p *ngIf="i18n.oneWaySuggestPanelSubTitle" class="text-center txt-14 subtitle">
            {{ i18n.oneWaySuggestPanelSubTitle }}
          </p>
        </p-header>
      </p-calendar>
    </div>
    <div>
      <div class="nights" *ngIf="searchEngineType === 'hotel'">
        <span translate>SEARCH.HOTEL.NIGHTS</span><span class="count f-sb">{{ nightNumber }}</span>
      </div>
      <div class="time-selector selectHours" *ngIf="searchEngineType === 'transfer'">
        <fieldset class="outward">
          <legend translate>SEARCH.TRANSFER.HOUR_LABEL</legend>
          <div class="hours">
            <spt-time-selector
              [value]="sourceWhenCriteria.outward.time.begin"
              (onChange)="changeOutwardTime($event)"
            ></spt-time-selector>
          </div>
        </fieldset>
      </div>
      <div class="selectHours" *ngIf="time && searchEngineType !== 'transfer'">
        <fieldset class="outward">
          <legend>{{ i18n.outwardLabelDate }}</legend>
          <ng-container
            [ngTemplateOutlet]="editFields"
            [ngTemplateOutletContext]="{
              direction: 'outward',
              context: sourceWhenCriteria.outward
            }"
          >
          </ng-container>
        </fieldset>
        <fieldset class="inward" *ngIf="sourceWhenCriteria.inward">
          <legend>{{ i18n.inwardLabelDate }}</legend>
          <ng-container
            [ngTemplateOutlet]="editFields"
            [ngTemplateOutletContext]="{
              direction: 'inward',
              context: sourceWhenCriteria.inward
            }"
          >
          </ng-container>
        </fieldset>
      </div>
      <div
        class="triggers submit"
        *ngIf="commonService.isTablet"
        [ngClass]="time || searchEngineType === 'hotel' ? 'time' : ''"
      >
        <p-button type="button" label="{{ 'GLOBAL.VALIDATE' | translate }}" (click)="hideSuggest()"></p-button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #editFields let-direction="direction" let-context="context">
  <div class="hours">
    <p-dropdown
      [options]="hourWindowOptions"
      [(ngModel)]="context.type"
      (onChange)="refreshLabels()"
      appendTo="body"
      *ngIf="hourType"
    >
      <ng-template let-option pTemplate="selectedItem">
        <p class="d-flex align-items-center hour-item">
          <span class="m-l-10">{{ option?.label }}</span>
        </p>
      </ng-template>
      <ng-template let-option pTemplate="item">
        <p class="d-flex align-items-center">
          <span class="m-l-10">{{ option?.label }}</span>
        </p>
      </ng-template>
    </p-dropdown>
    <p-dropdown
      [options]="timeOptionsByHourWindow[context.type]"
      [(ngModel)]="context.time"
      (onChange)="changeTime()"
      appendTo="body"
    >
      <ng-template let-option pTemplate="selectedItem">
        <p class="d-flex align-items-center hour-item">
          <span class="m-l-10">{{ option?.label }}</span>
        </p>
      </ng-template>
      <ng-template let-option pTemplate="item">
        <p class="d-flex align-items-center">
          <span class="m-l-10">{{ option?.label }}</span>
        </p>
      </ng-template>
    </p-dropdown>
  </div>
</ng-template>
