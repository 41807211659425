import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "spt-travel-hotel-page-pictures",
  styleUrls: ["./pictures.component.scss"],
  templateUrl: "./pictures.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class PicturesComponent implements OnInit {
  @Input() hotel;
  ngOnInit() {}
}
