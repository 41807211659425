import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "stars",
})
export class StarsPipe implements PipeTransform {
  transform(data: any): string {
    if (!data) {
      return;
    }

    let starsText: string = "";
    for (let i: number = 0; i < 5; i++) {
      if (i < (data as number)) {
        starsText += '<i class="spt-icon-star"></i>';
      }
    }
    return starsText;
  }
}
