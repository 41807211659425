<div class="header">
  <div class="details">
    <p class="address">
      <span>
        <img class="transport-icon" src="./assets/svg/hotel-full.svg" />
      </span>
      <span class="title f-sb">{{ this.item?.address }}</span>
    </p>
  </div>
  <div class="datetimes">
    <p class="dates">
      <span class="departure">
        {{ request.datein | localizedDate: "d" }}<br />
        <small class="month">
          {{ (request.datein | localizedDate: "MMM").replace(".", "") }}
        </small>
      </span>
      <span class="separator"></span>
      <span class="return">
        {{ request.dateout | localizedDate: "d" }}<br />
        <small class="month">
          {{ (request.dateout | localizedDate: "MMM").replace(".", "") }}
        </small>
      </span>
    </p>
  </div>
</div>
<p class="travelers" translate>GLOBAL.TRAVELERS</p>
<div class="infos">
  <p class="user">
    <spt-avatar [user]="request.board[0][0]"></spt-avatar>
    <sup class="board" *ngIf="request.board[0].length > 1"> +{{ request.board[0].length - 1 }} </sup>
  </p>
  <p class="quantities">
    <span class="quantities-container">
      <i class="quantities-icon spt-icon-chamber"></i><span class="m-l-10">{{ this.item?.rooms?.length }}</span>
    </span>
  </p>
  <span class="out-politic" *ngIf="this.request?.isOutOfPolicy" translate>GLOBAL.OUT_OF_POLICY</span>
  <p class="price f-sb">
    {{ request.price | currency: "EUR" : "symbol" : "1.2-2" }}
  </p>
</div>
