import { CommonService } from "src/app/@shared/services/common.service";
import { Component, ViewEncapsulation, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import { duration } from "moment";
import { TranslateService } from "@ngx-translate/core";
import { FlightService } from "../../flight.service";
import _companies from "../../../../@shared/companies";
import { DateTime, Interval } from "luxon";

@Component({
  selector: "spt-travel-flight-itinerary-leg",
  styleUrls: ["./travel-flight-itinerary-leg.component.scss"],
  templateUrl: "./travel-flight-itinerary-leg.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TravelFlightItineraryLegComponent implements OnInit {
  private _leg: any;
  @Input() set leg(value: any) {
    this._leg = value;
    const _duration = duration(this._leg.duration, "minutes");
    this.duration = `${_duration.hours() + _duration.days() * 24}h${String(_duration.minutes()).padStart(2, "0")}`;

    this.departure = this._leg.schedules?.[0].departure;
    this.arrival = this._leg.schedules?.[this._leg.schedules.length - 1].arrival;

    let departure, arrival;
    if (this.leg.Dep) {
      departure = this.leg.Dep.date;
      arrival = this.leg.Arrival.date;
    } else {
      departure = this.leg.departure.date;
      arrival = this.leg.arrival.date;
    }
    this.departureDateTime = DateTime.fromJSDate(new Date(departure));
    this.arrivalDateTime = DateTime.fromJSDate(new Date(arrival));
    if (!this.leg.schedules?.length) {
      const afklDepartureDateTime = DateTime.fromISO(this.leg.Dep.AircraftScheduledDateTime);
      const afklArrivalDateTime = DateTime.fromISO(this.leg.Arrival.AircraftScheduledDateTime);
      this.duration = Interval.fromDateTimes(afklDepartureDateTime, afklArrivalDateTime).length("minutes");
    }
    this.cd.markForCheck();
  }
  get leg(): any {
    return this._leg;
  }
  @Input() baggageIncluded;
  @Input() carryOnIncluded;
  @Input() passengersCount: number;
  @Input() carbonOffset: number;
  @Input() pricedOffer?: any;
  @Input() otherOffer?: any;
  @Input() departureDate: any;
  @Input() itinerary: any;

  public companies: any = _companies;
  public carryBagageDesc: string;

  public departureDateTime: DateTime;
  public arrivalDateTime: DateTime;

  duration: any;
  departure: any;
  arrival: any;
  aiports;

  constructor(
    private translateService: TranslateService,
    public commonService: CommonService,
    private flightService: FlightService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.getLuggageDesc();
  }

  getLuggageDesc(): void {
    let { governingCarrier, cabinCodes } = this.leg;
    if (governingCarrier) {
      let carryDescription = "GLOBAL.FLIGHT_BAGAGES.INFORMATION.CARRY." + governingCarrier;
      if (["AF", "TU"].includes(governingCarrier) && cabinCodes) {
        carryDescription += `.${cabinCodes[0]}`;
      }
      let trad = this.translateService.instant(carryDescription);
      if (trad !== carryDescription) {
        this.carryBagageDesc = trad;
        return;
      }
    }
    this.carryBagageDesc = this.translateService.instant("GLOBAL.FLIGHT_BAGAGES.INFORMATION.CARRY_NO_INFO");
  }
}
