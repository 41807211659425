<form
  (submit)="search()"
  class="form"
  [ngClass]="'suggest-' + suggestPanelService.active.name"
  [class.suggestOpened]="suggestOpened"
>
  <div class="title">
    <div class="flex justify-content-content items-center">
      <img class="icon-travel" src="./assets/svg/{{ searchEngineType }}.svg" />
      <h1 class="label" [innerHTML]="'SEARCH.TRAIN.TITLE' | translate"></h1>
    </div>
  </div>
  <div class="header">
    <div class="types">
      <div
        class="type"
        *ngFor="let travelTrainTypeItem of travelTrainTypes"
        [class.active]="travelTrainType === travelTrainTypeItem"
      >
        <label translate>
          <input
            type="radio"
            name="type"
            [(ngModel)]="travelTrainType"
            [value]="travelTrainTypeItem"
            (ngModelChange)="changeType($event)"
          />
          SEARCH.TRAIN.{{ travelTrainTypeItem | uppercase }}
        </label>
      </div>
    </div>
  </div>
  <spt-travel-search-autocomplete
    [placeholder]="{
      origin: 'SEARCH.TRAIN.STATION' | translate,
      destination: 'SEARCH.TRAIN.STATION' | translate
    }"
    [reverse]="true"
    [searchService]="searchService"
    [travel]="searchCriteria.mainTravel"
    (onCloseSuggest)="resetSuggestPanel()"
    (onTravelChange)="checkValidity()"
    [nextFocus]="searchDatetimeComponent"
  ></spt-travel-search-autocomplete>
  <spt-search-datetime
    #searchDatetimeComponent
    [i18n]="{
      outwardLabelDate: 'GLOBAL.DEPARTURE' | translate,
      inwardLabelDate: 'GLOBAL.INWARD' | translate,
      outwardDatePlaceholder: 'GLOBAL.DATE' | translate,
      inwardDatePlaceholder: 'GLOBAL.DATE' | translate,
      oneWaySuggestPanelTitle: searchCriteria.mainTravel.when.inward
        ? ('SEARCH.TRAIN.DATETIME_TITLE' | translate)
        : ('SEARCH.TRAIN.DATETIME_TITLE_SINGLE' | translate)
    }"
    [yearRange]="yearRange"
    [timeOptions]="timeOptions"
    [maxDateCount]="maxDateCount"
    [sourceWhenCriteria]="searchCriteria.mainTravel.when"
    [calendarSelectionMode]="searchCriteria.mainTravel.when.inward ? 'range' : 'single'"
    (onActivateSuggest)="activateSuggest($event)"
    (onCloseSuggest)="resetSuggestPanel()"
    (onDatetimeChange)="checkValidity()"
    [styleClass]="hourMandatory ? 'hourMandatory' : ''"
    [searchEngineType]="searchEngineType"
  ></spt-search-datetime>
  <spt-search-people
    (onActivateSuggest)="activateSuggest($event)"
    [people]="searchCriteria.mainTravel.people"
    (onPeopleListChange)="checkValidity()"
    (onCloseSuggest)="resetSuggestPanel()"
    [searchEngineType]="searchEngineType"
    [membersMax]="membersMax"
  ></spt-search-people>
  <div class="warning-container" *ngIf="showSearchWarning">
    <p-message severity="warn" [text]="'SEARCH.TRAIN.UK_SEARCH' | translate"></p-message>
  </div>
  <div class="triggers submit">
    <p-button
      type="submit"
      [disabled]="!isValid || showSearchWarning"
      label="{{ 'SEARCH.CTA' | translate }}"
    ></p-button>
  </div>
</form>
