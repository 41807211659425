import { Component, Input } from "@angular/core";
import { TrainTypes } from "src/app/travel/train/train";

@Component({
  selector: "spt-conditions-train",
  templateUrl: "./train.component.html",
  styleUrls: ["./train.component.scss"],
})
export class ConditionsTrainComponent {
  @Input() item: any;
  public passengerConditionArray: PassengerCondition[] = [];
  public selectedFare: TrainTypes.Fare;

  ngOnInit(): void {
    const result: { [key: string]: PassengerCondition } = {};
    const itinerariesOrOrderItems =
      this.item.itineraries ||
      this.item.detail.orderItems
        .filter((orderItem) => orderItem.type === "itinerary")
        .map((orderItem) => orderItem.item);
    const sections = itinerariesOrOrderItems
      .flatMap((itineraryOrOrderItem) => itineraryOrOrderItem.sections)
      .filter((section) => !!section);
    const fares = sections.flatMap((section) => section.fares);
    const rawConditions = fares.flatMap((fare) =>
      fare.passengers.map(
        (passenger) =>
          ({
            fareName: fare.fareName,
            passengerId: passenger,
            fare: fare,
            refundable: fare.refundable ? "GLOBAL.CATEGORY.REFUNDABLE" : "GLOBAL.CATEGORY.NON_REFUNDABLE",
            changeable: fare.changeable ? "GLOBAL.CATEGORY.EXCHANGEABLE" : "GLOBAL.CATEGORY.NON_EXCHANGEABLE",
            passenger: this.getPassengerDetail(passenger),
          }) as Condition,
      ),
    );
    const conditions = rawConditions.filter((condition) => !!condition.passenger?.id).filter(uniqueCondition);
    conditions.forEach((condition) => {
      if (!result[condition.passenger.id]) {
        result[condition.passenger.id] = new PassengerCondition(condition.passenger);
      }
      result[condition.passenger.id].conditions.push(condition);
    });
    this.passengerConditionArray = Object.values(result);
  }

  toggleFare(fare: TrainTypes.Fare): void {
    this.selectedFare = fare;
  }

  private getPassengerDetail(passenger: any) {
    const passengerId = typeof passenger === "string" ? passenger : passenger.id;
    const result = (this.item.detail?.passengers || this.item.travelers).find((p) => p.id === passengerId);
    if (!result) {
      //console.warn("Impossible de trouver le détail du passager:", passengerId, "dans:", this.item.detail.passengers);
    }
    return result;
  }
}

class PassengerCondition {
  passengerId: string;
  constructor(public passenger: any) {
    this.passengerId = passenger.id;
  }
  conditions: Condition[] = [];
}

interface Condition {
  passenger: any;
  fare: TrainTypes.Fare;
  refundable: string;
  changeable: string;
}

/**
 * Permet de déterminer si une condition est identique à une autre
 */
function uniqueCondition(condition: Condition, index: number, array: Condition[]) {
  return array.findIndex((conditionItem) => isDeepEqual(conditionItem, condition)) === index;
}

function isObject(object): boolean {
  return object != null && typeof object === "object";
}

function isDeepEqual(object1, object2): boolean {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (var key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
      return false;
    }
  }
  return true;
}
