<div class="actions">
  <span *ngIf="request.comment" class="comment">
    {{ request.comment }}
  </span>
  <span class="buttons">
    <ng-container *ngIf="userId != request?.user; else cancelRequest">
      <p-button (click)="refuse()" [label]="'NOTIFICATIONS.DECLINE' | translate" styleClass="p-button-secondary">
      </p-button>
    </ng-container>
    <ng-template #cancelRequest>
      <p-button
        (click)="refuse()"
        [label]="'GLOBAL.CANCEL' | translate"
        styleClass="p-button-secondary"
        type="text"
      ></p-button>
    </ng-template>
    <p-button (click)="goToBookRequest()" [label]="'BOOKING.SEE' | translate" type="text"></p-button>
  </span>
</div>
