<div class="airport-information">
  <div class="label">
    <div class="airport">
      <span class="name">{{ name }}</span>
      <span class="iata">{{ iata }},</span>
    </div>
    <div class="country">
      {{ country }}
    </div>
  </div>
  <div class="terminal">
    <span *ngIf="terminal">{{ "GLOBAL.TERMINAL" | translate }} {{ terminal }}</span>
    <span *ngIf="terminal && flightNumber"> - </span>
    <span *ngIf="flightNumber">{{ "GLOBAL.FLIGHT" | translate }} {{ flightNumber }}</span>
  </div>
</div>
<div class="time">
  {{ this._time }}
  <sup class="adjustment">{{ datetime | dateAdjustment: departureDate }}</sup>
</div>
