import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpClient } from "@angular/common/http";

import { SessionService, SessionType } from "./session.service";
import { environment } from "src/environments/environment";

import { Router } from "@angular/router";
import { defer, Observable, throwError } from "rxjs";
import { catchError, mergeMap, share } from "rxjs/operators";

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  private refresher: Observable<any>;

  constructor(
    private sessionService: SessionService<SessionType>,
    private http: HttpClient,
    private router: Router,
  ) {
    this.init();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf(environment.api) < 0) {
      return next.handle(request);
    }
    let newRequest: HttpRequest<any>;
    let headers: HttpHeaders = this.defaultHeaders(request.headers);
    newRequest = request.clone({ headers });

    const session: SessionType = this.sessionService.get();
    if (session && session.token) {
      if (!headers.get("Authorization")) {
        headers = headers.set("Authorization", `JWT ${session.token}`);
      }
      if (session.societyId) {
        headers = headers.set("activesociety", session.societyId);
      }

      newRequest = request.clone({ headers });
      return next.handle(newRequest).pipe(
        catchError((error: any) => {
          if (error.status === 401) {
            // problem with refresh token
            this.sessionService.close().subscribe();
            //       // return this.loginPopUp().pipe(
            //       //   switchMap(newSession => this.resendReq(next, req, newSession))
            //       // );
            //     }
            //     else if (error.status === 401 && error.url.indexOf('login') === -1) { // problem with jwt {
            //       return this.refresher.pipe(
            //         switchMap(newSession => this.resendReq(next, req, newSession))
            //       );
            //     }
            //     else if (error.status === 403) {
            //       this.location.back();
          } else {
            return throwError(error);
          }
          return throwError(error);
        }),
      );
    }

    return next.handle(newRequest);
  }

  defaultHeaders(headers?: HttpHeaders): HttpHeaders {
    const jsonHeaders: { [name: string]: string } = {
      "Content-Type": "application/json",
    };
    if (headers) {
      headers.keys().forEach((header: string) => (jsonHeaders[header] = headers.get(header)));
    }

    return new HttpHeaders(jsonHeaders);
  }

  init(): void {
    this.refresher = defer(() => {
      const session: SessionType = this.sessionService.get();
      const endPoint: string = `${environment.api}/api/auth/refresh`;
      let headers: HttpHeaders = this.defaultHeaders();

      headers = headers.set("RT-Token", session.refreshToken);

      return this.http.get(endPoint, { headers, observe: "response" });
    }).pipe(
      mergeMap((res: any) => {
        const session: SessionType = this.sessionService.get();
        session.token = res.headers.get("JWT-Token");
        return this.sessionService.set(session, { rememberMe: true });
      }),
      share(),
    );
  }
}
