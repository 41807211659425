import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MessageService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "src/app/@shared/services/common.service";
import { MailService } from "../../@shared/services/mail.service";

@Component({
  selector: "spt-password-reset",
  styleUrls: ["./password-reset.component.scss"],
  templateUrl: "./password-reset.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class PasswordResetComponent implements OnInit {
  resetting: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private mailService: MailService,
    private messageService: MessageService,
    private translateService: TranslateService,
    public commonService: CommonService,
  ) {}

  public resetForm: UntypedFormGroup;

  get form(): any {
    return this.resetForm.value;
  }

  public loginPage(): void {
    this.router.navigate(["auth"]);
  }

  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    });
    this.activatedRoute.params.subscribe((params) => {
      if (params.email) {
        this.resetForm.patchValue({ email: params.email });
      }
    });
  }

  public reset(): void {
    this.resetting = true;
    this.mailService.resetPassword(this.form.email).subscribe(
      () => {
        this.resetting = false;
        this.messageService.add({
          severity: "success",
          detail: this.translateService.instant("NOTIFICATIONS.RESET_PASS_SUCCEFULY_SENT"),
          life: 10000,
        });
        this.router.navigate(["auth"]);
      },
      () => {
        this.messageService.add({
          severity: "success",
          detail: this.translateService.instant("NOTIFICATIONS.RESET_PASS_SUCCEFULY_SENT"),
          life: 10000,
        });
        this.router.navigate(["auth"]);
      },
    );
  }
}
