import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { DynamicDialogRef, DynamicDialogConfig } from "primeng/dynamicdialog";
import { SelectItem } from "primeng/api";
import { TrainTypes } from "../train";
import { Subscription } from "rxjs";

@Component({
  selector: "spt-travel-train-seat-reservations",
  templateUrl: "./seat-reservations.component.html",
  styleUrls: ["./seat-reservations.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelTrainSeatReservationsComponent implements OnInit, OnDestroy {
  protected seatReservationFormArray: FormArray<FormGroup<SeatReservationItemForm>>;
  protected selectItems: SeatReservationSelectItems[] = [];
  private subscriptions: Subscription[] = [];
  private seatPreferenceOptions: Array<TrainTypes.SeatPreferenceOption>;
  private travelClasses: TrainTypes.TravelClass[];

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
  ) {}

  private buildForm(): void {
    this.seatReservationFormArray = this.fb.array(
      this.seatPreferenceOptions.map((seatPreferenceOption) => {
        return this.fb.group<SeatReservationItemForm>({
          seatOption: this.fb.control<SeatOption>(undefined),
          carriageType: this.fb.control(undefined),
          deck: this.fb.control(undefined),
          // Le choix des places n'est possible que pour les trajets avec un seul voyageur
          nextTo: this.fb.array(
            seatPreferenceOption.hasSeatPreferences && seatPreferenceOption.passengerIds.length === 1
              ? seatPreferenceOption.passengerIds.map((id) => this.buildNextToForm())
              : [],
          ),
        });
      }),
    );
  }

  private buildNextToForm() {
    const formGroup = this.fb.group<NextToForm>({
      coachNumber: this.fb.control<string>(""),
      seatNumber: this.fb.control<string>(""),
    });
    this.subscriptions.push(
      formGroup.valueChanges.subscribe((value: TrainTypes.SeatLocation) => {
        const coachNumber = formGroup.controls.coachNumber;
        const seatNumber = formGroup.controls.seatNumber;
        if (!coachNumber.value && !seatNumber.value) {
          coachNumber.clearValidators();
          seatNumber.clearValidators();
        } else {
          coachNumber.setValidators(Validators.required);
          seatNumber.setValidators(Validators.required);
        }
        coachNumber.updateValueAndValidity({ onlySelf: true });
        seatNumber.updateValueAndValidity({ onlySelf: true });
      }),
    );
    return formGroup;
  }

  protected onSubmit(): void {
    const seatPreferenceSelections = this.getSeatPreferenceSelections();
    this.dialogRef.close(seatPreferenceSelections);
  }
  private seatTypeConditions = {
    SEAT_CLUB_4: (passengerNb) => passengerNb > 2 && passengerNb <= 4,
    SEAT_SOLO: (passengerNb) => passengerNb <= 2,
    SEAT_DUO: (passengerNb) => passengerNb <= 2,
    SEAT_CLUB_2: (passengerNb) => passengerNb <= 2,
  };
  private filterSeatType(type: string, passengerNb: number): boolean {
    return !this.seatTypeConditions[type] || this.seatTypeConditions[type](passengerNb);
  }
  ngOnInit(): void {
    this.travelClasses = this.dialogConfig.data.travelClasses;
    this.seatPreferenceOptions = this.dialogConfig.data.seatPreferenceGroups.filter(
      (option) => option.reservationMethods[0] !== "SEAT_RESERVATION_NOT_APPLICABLE",
    );
    this.selectItems = this.seatPreferenceOptions.map((seatPreferenceOption) => ({
      // Construction de la liste des options possible pour "Siège"
      // Cette liste doit "mixer" les "seatType" et "position"
      seatOption: [
        {
          label: this.translateService.instant(`SEARCH.RESULT.RAIL.SEAT_PREFERENCES.NONE`),
          value: {
            seatType: { description: null },
            position: { description: null },
          },
        },
        ...seatPreferenceOption.seatType
          .map((item) => item.description)
          .filter((item) => this.filterSeatType(item, seatPreferenceOption.passengerIds.length))
          .map((value) => ({
            label: this.translateService.instant(`SEARCH.RESULT.RAIL.SEAT_PREFERENCES.${value}`),
            value: {
              position: { description: null },
              seatType: { description: value },
            },
          })),
        ...seatPreferenceOption.position
          .map((item) => item.description)
          .filter((item) => this.filterSeatType(item, seatPreferenceOption.passengerIds.length))
          .map((value) => ({
            label: this.translateService.instant(`SEARCH.RESULT.RAIL.SEAT_PREFERENCES.${value}`),
            value: {
              position: { description: value },
              seatType: { description: null },
            },
          })),
      ],
      carriageType: seatPreferenceOption.carriageType
        .map((item) => item.description)
        .map((value) => ({
          label: this.translateService.instant(`SEARCH.RESULT.RAIL.SEAT_PREFERENCES.${value}`),
          value: { description: value },
        })),
      deck: [
        {
          label: this.translateService.instant(`SEARCH.RESULT.RAIL.SEAT_PREFERENCES.NONE`),
          value: { description: null },
        },
        ...seatPreferenceOption.deck
          .map((item) => item.description)
          .map((value) => ({
            label: this.translateService.instant(`SEARCH.RESULT.RAIL.SEAT_PREFERENCES.${value}`),
            value: { description: value },
          })),
      ],
    }));

    this.buildForm();
  }

  private getSeatPreferenceSelections(): TrainTypes.SeatPreferenceSelection[] {
    return (this.seatReservationFormArray.value as SeatReservationItemFormValue[]).map((formValue, idx) => {
      const source = this.seatPreferenceOptions[idx];
      return {
        id: source.id,
        segmentIds: source.segmentIds,
        passengerIds: source.passengerIds,
        position: formValue.seatOption?.position ?? null,
        seatType: formValue.seatOption?.seatType ?? null,
        seatSelection: {
          description:
            formValue.nextTo.filter((n) => !!n.coachNumber && !!n.coachNumber).length > 0
              ? "SEAT_NEXT_TO"
              : "SEAT_RESERVATION_DEFAULT",
        },
        // direction: ,
        //carriageType: !!formValue.carriageType ? { description: formValue.carriageType } : null,
        carriageType: { description: null },
        deck: formValue.deck,
        facilities: source.facilities,
        placeType: source.placeType,
        // description: ,
        seatLocations: formValue.nextTo.filter((p) => !!p.coachNumber && !!p.seatNumber),
        reservationMethods: source.reservationMethods,
      } as TrainTypes.SeatPreferenceSelection;
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.filter((s) => !s.closed).forEach((s) => s.unsubscribe());
  }
}

/**
 * Ensemble des listes d'options possible pour les drop-down
 */
export interface SeatReservationSelectItems {
  seatOption: SelectItem<SeatOption>[];
  carriageType: SelectItem<TrainTypes.SeatPreference>[];
  deck: SelectItem<TrainTypes.SeatPreference>[];
}

interface SeatReservationItemForm {
  /**
   * Siège
   */
  seatOption: FormControl<SeatOption>;
  /**
   * Type de voiture
   */
  carriageType: FormControl<TrainTypes.SeatPreference>;
  /**
   * Pont
   */
  deck: FormControl<TrainTypes.SeatPreference>;
  /**
   * Siège à côté de ...
   */
  nextTo?: FormArray<FormGroup<NextToForm>>;
}
interface NextToForm {
  coachNumber?: FormControl<string>;
  seatNumber?: FormControl<string>;
}
interface SeatReservationItemFormValue {
  seatOption: SeatOption;
  carriageType: TrainTypes.SeatPreference;
  deck: TrainTypes.SeatPreference;
  nextTo: TrainTypes.SeatLocation[];
}
export interface SeatOption {
  seatType?: TrainTypes.SeatPreference;
  position?: TrainTypes.SeatPreference;
}
