import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "address",
})
export class AddressPipe implements PipeTransform {
  transform(address: any, properties: string[] = ["city"]): any {
    if (!address) {
      return;
    }
    if (address.label) {
      return address.label;
    }
    let strAddress: string = "";
    properties.forEach((prop: string) => {
      if (address[prop]) {
        strAddress += address[prop] + ", ";
      }
    });
    return strAddress.slice(0, -2);
  }
}
