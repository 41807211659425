import { CommonService } from "src/app/@shared/services/common.service";
import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({
  name: "flightTimeToDuration",
})
export class FlightTimeToDurationPipe implements PipeTransform {
  constructor(private commonService: CommonService) {}

  transform(minutes: number): string {
    const total: moment.Duration = moment.duration(minutes, "minutes");
    return this.commonService.isTablet
      ? `${total.hours() + total.days() * 24}h${String(total.minutes()).padStart(2, "0")}`
      : `${total.hours() + total.days() * 24}h${String(total.minutes()).padStart(2, "0")}`;
  }
}
