import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./dashboard/dashboard.module").then((x) => x.DashboardModule),
  },
  {
    path: "travel",
    loadChildren: () => import("./travel/travel.module").then((x) => x.TravelModule),
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((x) => x.AuthModule),
  },
  { path: "dashboard/bills", redirectTo: "management/billings" },
  { path: "dashboard/bookings", redirectTo: "management/bookings" },
  { path: "login", redirectTo: "" },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      scrollPositionRestoration: "top",
      urlUpdateStrategy: "eager",
      paramsInheritanceStrategy: "always",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

// export const AppRoutingModule: ModuleWithProviders = RouterModule.forRoot(routes, {
//     onSameUrlNavigation: 'reload',
//     scrollPositionRestoration: 'top',
//     urlUpdateStrategy: 'eager'
// });
