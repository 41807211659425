import { Injectable } from "@angular/core";

import { filter, first } from "rxjs/operators";
import { UserService } from "src/app/@shared/services/user.service";
import { User } from "src/app/@shared/@types/user";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserResolver {
  constructor(private userService: UserService) {}

  resolve(): Observable<User> {
    return this.userService.user.pipe(
      filter((user: User) => !!user),
      first(),
    );
  }
}
