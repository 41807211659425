import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService, SelectItem } from "primeng/api";
import { asyncScheduler, forkJoin, merge, Observable, scheduled, Subject } from "rxjs";
import { concatAll, takeUntil } from "rxjs/operators";
import { UtilsTypes } from "../../../@shared/@types/utils";
import { TravelTrainSeatReservationsComponent } from "../seat-reservations/seat-reservations.component";
import { TrainTypes } from "../train";
import { TrainService } from "../train.service";
import { ModalService } from "../../../@shared/services/modal.service";
import { User } from "../../../@shared/@types/user";
import { UserService } from "../../../@shared/services/user.service";
import { Preferences } from "../../../@shared/@types/models";
import UKStations from "../collect-uk-stations";
import { forEach } from "lodash";
import { TravelTrainSeatMapComponent } from "../seatmap/seatmap.component";
import { CommonService } from "src/app/@shared/services/common.service";
import { DialogService } from "primeng/dynamicdialog";

@Component({
  selector: "spt-travel-train-itinerary",
  templateUrl: "./itinerary.component.html",
  styleUrls: ["./itinerary.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelTrainItineraryComponent implements OnInit, OnDestroy {
  @Input() itinerary: TrainTypes.Itinerary | TrainTypes.ItineraryChange;
  @Input() provider: TrainTypes.Provider;
  @Input() passengers: Array<TrainTypes.Passenger>;
  @Input() type: TrainTypes.SearchType;
  @Input() locale: string;
  @Input() isSmallDevice: boolean;
  @Input() isUkSearch: boolean;
  @Input() preferences: Preferences;
  @Input() users: Array<User>;
  @Output() termsOfUse: EventEmitter<TrainTypes.TermsOfUse[]> = new EventEmitter();
  @Output() updating: EventEmitter<boolean> = new EventEmitter();
  @Output() selectDeliveryOption: EventEmitter<boolean> = new EventEmitter();
  @Output() mandatoryReservationSelected: EventEmitter<boolean> = new EventEmitter();

  flexibility: TrainTypes.Flexibility | TrainTypes.ATOCFlexibility;
  selectedFare: TrainTypes.Fare;
  selectSeat: UtilsTypes.ObjectKey<number> = {
    outward: undefined,
    inward: undefined,
  };
  fareTypes: {
    outward: Array<TrainTypes.Fare>;
    inward: Array<TrainTypes.Fare>;
  } = {
    outward: undefined,
    inward: undefined,
  };
  hasOuiGo: boolean;
  isRequiredDeliveryOptions: boolean = false;
  deliveryOptions: Array<SelectItem> = [];
  selectedDeliveryOption: TrainTypes.DeliveryData;
  showTravelCard: boolean;
  validatingDeliveryOption: boolean = false;
  showPriceBreakdown: boolean = false;
  total: UtilsTypes.Price;
  childrenPassengers: Array<TrainTypes.Passenger>;
  user: User;
  inCollectStations: boolean;
  stations: Array<SelectItem>;
  isAllTer: { [key: number]: boolean } = {};
  private _stations: Array<SelectItem>;
  private vendorsWithLead: RegExp = /(DB|Renfe)/;
  private ngUnsubscribe: Subject<void> = new Subject();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private trainService: TrainService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private messageService: MessageService,
    private userService: UserService,
    private commonService: CommonService,
    private dialogService: DialogService,
  ) {}

  private checkTermsOfUse(): void {
    const termsOfUseOptions: TrainTypes.TermsOfUse[] = [];
    if (this.itinerary.vendors?.includes("DB")) {
      termsOfUseOptions.push({
        href: "https://www.bahn.com/en/view/home/info/gtc.shtml",
        label: "SEARCH.RESULT.RAIL.TERMS.TITLE",
        link: "SEARCH.RESULT.RAIL.TERMS.DB",
        value: false,
        vendor: "DB",
      });
    }

    if (this.itinerary.vendors?.includes("ATOC")) {
      termsOfUseOptions.push({
        href: "https://www.nationalrail.co.uk/National Rail Conditions of Travel.pdf",
        label: "SEARCH.RESULT.RAIL.TERMS.TITLE",
        link: "SEARCH.RESULT.RAIL.TERMS.ATOC",
        value: false,
        vendor: "ATOC",
      });
    }

    if (termsOfUseOptions.length > 0) {
      this.termsOfUse.emit(termsOfUseOptions);
    }
  }

  private updatePassengersDetails(): Observable<any> {
    return scheduled(
      this.itinerary.passengers.map((passenger: TrainTypes.NamedPassenger): Observable<any> => {
        const foundPassenger: TrainTypes.Passenger = this.passengers.find(
          (_passenger: TrainTypes.Passenger): boolean => _passenger.id === passenger.id,
        );
        return this.trainService.updatePassengerDetails(this.itinerary.id, passenger.id, {
          firstname: foundPassenger.firstname,
          lastname: foundPassenger.lastname,
          email: foundPassenger.email,
          title: foundPassenger.title,
          phone: foundPassenger.phone,
          passport: foundPassenger.passport
            ? {
                number: foundPassenger.passport.number,
              }
            : undefined,
        });
      }),
      asyncScheduler,
    ).pipe(concatAll());
  }

  private mapFares(): void {
    const farePrice: UtilsTypes.Price = {
      amount: 0,
      currency: "EUR",
    };
    this.itinerary.journeys.forEach((_journey: TrainTypes.Journey): void => {
      this.itinerary.sections.forEach((_section: TrainTypes.ItinerarySection): void => {
        _section.fares.forEach((_fare: TrainTypes.Fare): void => {
          const segmentId: number = _journey.segments.findIndex((_segment: TrainTypes.Segment): boolean => {
            return _fare.segmentIds.includes(_segment.id);
          });
          if (segmentId !== -1 && !this.fareTypes[_journey.direction]) {
            this.fareTypes[_journey.direction] = [_fare];
          } else if (segmentId !== -1 && this.fareTypes[_journey.direction]) {
            const foundFare: TrainTypes.Fare = this.fareTypes[_journey.direction].find(
              (_fareType: TrainTypes.Fare): boolean => {
                return _fareType.fareName === _fare.fareName && _fareType.price?.amount === _fare.price?.amount;
              },
            );
            if (foundFare) {
              foundFare.passengers = Array.from(new Set([...foundFare.passengers, ..._fare.passengers]));
            } else {
              this.fareTypes[_journey.direction].push(_fare);
            }
          } else if (_fare.price) {
            farePrice.amount += _fare.price.amount;
            farePrice.currency = _fare.price.currency;
          }
        });
      });
    });
  }

  private mapDeliveryOptions(): void {
    if (this.itinerary.deliveryOptions && this.itinerary.deliveryOptions.length && this.type !== "Exchange") {
      this.itinerary.deliveryOptions.forEach((_deliveryData: TrainTypes.DeliveryData): void => {
        if (
          !this.deliveryOptions.some((_option: SelectItem): boolean => _option.label === _deliveryData.deliveryCode)
        ) {
          this.deliveryOptions.push({
            label: _deliveryData.deliveryCode,
            value: _deliveryData,
          });
        }
      });

      if (this.deliveryOptions.length === 1) {
        this.selectedDeliveryOption = this.deliveryOptions[0].value;
        this.setDeliveryOption(this.deliveryOptions[0].value);
      }

      this.itinerary.deliverySelection = this.itinerary.deliveryOptions[0];
    } else if (this.type !== "Exchange") {
      this.deliveryOptions.push({
        label: "ELECTRONIC_TICKET",
        value: {
          deliveryCode: "ELECTRONIC_TICKET",
        },
      });
      this.selectedDeliveryOption = this.deliveryOptions[0].value;
    } else {
      this.isRequiredDeliveryOptions = false;
    }
  }

  private getFlexibility(): void {
    let bestFlexibility: TrainTypes.Flexibility | TrainTypes.ATOCFlexibility = "nonflexi";
    for (const section of this.itinerary.sections) {
      for (const fare of section.fares) {
        if (fare.flexibility && fare.flexibility !== "nonflexi") {
          bestFlexibility = fare.flexibility;
        }
      }
    }
    this.flexibility = bestFlexibility;
    this.changeDetector.markForCheck();
  }

  private mapUserSeatPreferences(
    groups: Array<TrainTypes.SeatPreferenceOption>,
    user: User,
    index: number,
  ): Array<TrainTypes.SeatPreferenceSelection> {
    const seatSelection: Array<TrainTypes.SeatPreferenceSelection> = [];
    groups.forEach((_seatPreferenceOption: TrainTypes.SeatPreferenceOption): void => {
      if (_seatPreferenceOption.passengerIds.includes(`${index + 1}`)) {
        const preferredDeck: TrainTypes.SeatPreference = _seatPreferenceOption.deck?.find(
          (_deck: TrainTypes.SeatPreference): boolean => {
            return _deck.description === user.preferences.train.deck;
          },
        );
        const preferredPosition: TrainTypes.SeatPreference = _seatPreferenceOption.position?.find(
          (_position: TrainTypes.SeatPreference): boolean => {
            return _position.description === user.preferences.train.position;
          },
        );
        const preferredSeatType: TrainTypes.SeatPreference = _seatPreferenceOption.seatType?.find(
          (_seatType: TrainTypes.SeatPreference): boolean => {
            return _seatType.description === user.preferences.train.seatType;
          },
        );
        if (preferredDeck || preferredPosition || preferredSeatType) {
          seatSelection.push({
            id: _seatPreferenceOption.id,
            segmentIds: _seatPreferenceOption.segmentIds,
            passengerIds: _seatPreferenceOption.passengerIds,
            reservationMethods: _seatPreferenceOption.reservationMethods,
            deck: preferredDeck,
            position: preferredPosition,
            seatType: preferredSeatType,
            placeType: _seatPreferenceOption.placeType,
            description: `${
              preferredDeck
                ? this.translateService.instant(`SEARCH.RESULT.RAIL.SEAT_PREFERENCES.${preferredDeck.description}`)
                : ""
            }, ${
              preferredPosition
                ? this.translateService.instant(`SEARCH.RESULT.RAIL.SEAT_PREFERENCES.${preferredPosition.description}`)
                : ""
            }, ${
              preferredSeatType
                ? this.translateService.instant(`SEARCH.RESULT.RAIL.SEAT_PREFERENCES.${preferredSeatType.description}`)
                : ""
            }`,
            seatSelection: {
              description: "SEAT_RESERVATION_DEFAULT",
            },
          });
        }
      }
    });
    return seatSelection;
  }

  ngOnInit(): void {
    this.total = (this.itinerary as TrainTypes.ItineraryChange).exchangeEstimation
      ? (this.itinerary as TrainTypes.ItineraryChange).exchangeEstimation.exchangeContractOptions[0].paymentBalances[0]
          .price
      : {
          amount: this.itinerary.pricing.totalAmount.amount,
          currency: this.itinerary.pricing.totalAmount.currency as UtilsTypes.Currency,
        };
    this.user = this.userService.user.value;
    this.childrenPassengers = [...this.passengers].filter((_passenger: TrainTypes.Passenger): boolean => {
      return ["INFANT", "CHILD"].includes(_passenger.passengerCode);
    });
    this.showTravelCard =
      !!this.itinerary.journeys[0].departure.name.match(/london/gi) ||
      !!this.itinerary.journeys[0].arrival.name.match(/london/gi);
    this.mapDeliveryOptions();
    this.getFlexibility();
    this.mapFares();

    this.checkTermsOfUse();

    if (this.itinerary.journeys[0].departure.country === "GB") {
      this._stations = UKStations.map((_station: string): SelectItem => {
        return {
          label: _station,
          value: _station,
        };
      });
      this.stations = [...this._stations];
      this.inCollectStations = this.stations.some(
        (_station: SelectItem): boolean => _station.value === this.itinerary.journeys[0].departure.name,
      );
    }

    if (this.provider === "trainline" && this.type !== "Exchange") {
      this.updating.emit(true);
      const passengerObservables: Observable<any> = this.updatePassengersDetails();

      if (this.itinerary && this.vendorsWithLead.test(this.itinerary.vendors.join(" "))) {
        this.updating.emit(true);
        scheduled(
          merge([passengerObservables, this.trainService.setLeadPassenger(this.itinerary.id, this.passengers[0].id)]),
          asyncScheduler,
        )
          .pipe(concatAll())
          .subscribe((): void => {
            this.updating.emit(false);
          });
      } else {
        passengerObservables.subscribe((): void => {
          this.updating.emit(false);
        });
      }
    }

    if (
      this.itinerary.journeys.some((_journey: TrainTypes.Journey) =>
        _journey.segments.some((_segment: TrainTypes.Segment) => _segment.transport.trainCode.indexOf("OUIGO") >= 0),
      )
    ) {
      this.hasOuiGo = true;
    } else {
      this.hasOuiGo = false;
    }

    if (this.hasOuiGo === false) {
      if (this.itinerary.seatPreferenceOptions && Object.values(this.itinerary.seatPreferenceOptions).length > 0) {
        this.itinerary.seatPreferenceSelection = {};

        if (this.itinerary.seatPreferenceOptions.outward) {
          this.itinerary.seatPreferenceSelection.outward = [];
        }

        if (this.itinerary.seatPreferenceOptions.inward) {
          this.itinerary.seatPreferenceSelection.inward = [];
        }

        this.users.forEach((_user: User, index: number): void => {
          if (_user.preferences && _user.preferences.train && this.passengers.length === 1) {
            if (this.itinerary.seatPreferenceSelection.outward) {
              this.itinerary.seatPreferenceSelection.outward.push(
                ...this.mapUserSeatPreferences(this.itinerary.seatPreferenceOptions.outward, _user, index),
              );
            }

            if (this.itinerary.seatPreferenceSelection.inward) {
              this.itinerary.seatPreferenceSelection.inward.push(
                ...this.mapUserSeatPreferences(this.itinerary.seatPreferenceOptions.inward, _user, index),
              );
            }
          }
        });
        this.allMandatoryAreSelected();
      }
    }
    this.itinerary.journeys.forEach((_journey: TrainTypes.Journey, index: number): void => {
      this.isAllTer[index] = _journey.segments.every((_segment: TrainTypes.Segment): boolean => {
        return _segment.transport.trainCode === "TER" || _segment.transport.trainCode.indexOf("OUIGO") >= 0;
      });
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  modifySeats(segments: Array<TrainTypes.Segment>, direction: TrainTypes.OfferDirection): void {
    this.itinerary.seatPreferenceSelection[direction] = [];
    const travelClasses: Array<TrainTypes.TravelClass> = [];
    this.itinerary.seatPreferenceOptions[direction].forEach(
      (_seatPreferenceGroup: TrainTypes.SeatPreferenceOption): void => {
        this.itinerary.sections.forEach((_section: TrainTypes.ItinerarySection): void => {
          _section.fares.forEach((_fare: TrainTypes.Fare): void => {
            if (
              _fare.segmentIds.some((_segmentId: string): boolean =>
                _seatPreferenceGroup.segmentIds.includes(_segmentId),
              )
            ) {
              travelClasses.push(_fare.fareSegments[0].travelClass);
            }
          });
        });
      },
    );
    if (travelClasses.find((_travelClass) => _travelClass === "FIRST_CLASS")) {
      this.openSeatMaps(segments, direction);
    } else {
      this.modalService
        .openModal(TravelTrainSeatReservationsComponent, {
          dismissableMask: true,
          data: {
            seatPreferenceGroups: this.itinerary.seatPreferenceOptions[direction],
            provider: this.provider,
            segments,
            travelClasses: Array.from(new Set(travelClasses)),
          },
          height: this.isSmallDevice ? `100vh` : "auto",
          showHeader: false,
          styleClass: "seat-preferences-modal",
          width: this.isSmallDevice ? "100vw" : "650px",
        })
        .onClose.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((seatPreferenceSelection: Array<TrainTypes.SeatPreferenceSelection>): void => {
          if (seatPreferenceSelection) {
            const requests: Array<Observable<any>> = [];
            seatPreferenceSelection.forEach((_seatPreference: TrainTypes.SeatPreferenceSelection): void => {
              if (_seatPreference.seatLocations?.length > 0) {
                _seatPreference.description = this.translateService.instant(
                  `SEARCH.RESULT.RAIL.SEAT_PREFERENCES.${_seatPreference.seatSelection.description}`,
                  {
                    coach: _seatPreference.seatLocations[0].coachNumber,
                    seat: _seatPreference.seatLocations.reduce(
                      (seats: string, seatLocation: TrainTypes.SeatLocation): string => {
                        return `${seats === "" ? "" : `${seats}, `}${seatLocation.seatNumber}`;
                      },
                      "",
                    ),
                  },
                );
              } else {
                _seatPreference.description = Object.values(_seatPreference)
                  .filter(
                    (value: string | TrainTypes.SeatPreference): boolean =>
                      !!value &&
                      typeof value !== "string" &&
                      value.description &&
                      !["SEAT_RESERVATION_DEFAULT", "SEAT_NEXT_TO"].includes(value.description),
                  )
                  .map((value: TrainTypes.SeatPreference): string => {
                    return this.provider === "trainline"
                      ? value.description
                      : this.translateService.instant(`SEARCH.RESULT.RAIL.SEAT_PREFERENCES.${value.description}`);
                  })
                  .join(", ");
              }

              if (this.provider === "trainline") {
                const setSeatPreferencesRequest: TrainTypes.SetSeatPreferencesRequest = {
                  seatPreferenceGroup: _seatPreference.id,
                  deck: _seatPreference.deck ? _seatPreference.deck.id : undefined,
                  direction: _seatPreference.direction ? _seatPreference.direction.id : undefined,
                  carriageType: _seatPreference.carriageType ? _seatPreference.carriageType.id : undefined,
                  seatType: _seatPreference.seatType ? _seatPreference.seatType.id : undefined,
                  position: _seatPreference.position ? _seatPreference.position.id : undefined,
                  facilities:
                    _seatPreference.facilities && _seatPreference.facilities.length > 0
                      ? _seatPreference.facilities.map((_facility: TrainTypes.SeatPreference): string => _facility.id)
                      : undefined,
                  seatLocations: _seatPreference.seatLocations?.length > 0 ? _seatPreference.seatLocations : undefined,
                };

                requests.push(
                  this.trainService.setSeatPreferences(this.itinerary.id, setSeatPreferencesRequest, this.type),
                );
              }
            });
            this.itinerary.seatPreferenceSelection[direction] = seatPreferenceSelection;
            this.allMandatoryAreSelected();
            this.changeDetector.markForCheck();

            if (requests.length > 0) {
              this.updating.emit(true);
              forkJoin(requests).subscribe((): void => {
                this.messageService.add({
                  severity: "success",
                  summary: this.translateService.instant("NOTIFICATIONS.TRAIN.SEAT_PREFERENCES_SET"),
                });
                this.updating.emit(false);
              });
            }
          }
        });
    }
  }

  setDeliveryOption(deliveryOptionValue: TrainTypes.DeliveryData): void {
    this.validatingDeliveryOption = true;

    if (this.provider === "trainline" && this.itinerary.journeys[0].departure.country === "GB") {
      this._stations = UKStations.map((_station: string): SelectItem => {
        return {
          label: _station,
          value: _station,
        };
      });
      this.stations = this._stations;
      this.inCollectStations = this.stations.some(
        (_station: SelectItem): boolean => _station.value === this.itinerary.journeys[0].departure.name,
      );
    }

    this.itinerary.deliverySelection = deliveryOptionValue;
    this.isRequiredDeliveryOptions = false;
    this.validatingDeliveryOption = false;
    this.updating.emit(false);
    this.changeDetector.detectChanges();
  }

  toggleFare(fare: TrainTypes.Fare): void {
    this.selectedFare = fare;
    this.changeDetector.detectChanges();
  }
  mandatorySeatIsSelected(index: number): boolean {
    let SeatIsSelected = false;
    let direction: TrainTypes.OfferDirection;
    if (index === 0) {
      direction = "outward";
    } else if (index === 1) {
      direction = "inward";
    }
    if (this.itinerary.seatPreferenceOptions[direction]) {
      this.itinerary.seatPreferenceOptions[direction].forEach((location, index) => {
        if (location.reservationType === "optional") {
          SeatIsSelected = true;
        } else if (
          location.reservationType &&
          this.itinerary.seatPreferenceSelection[direction][index]?.seatSelection.description
        ) {
          if (
            location.reservationType === "mandatory" &&
            this.itinerary.seatPreferenceSelection[direction][index].seatSelection.description ===
              "SEAT_RESERVATION_DEFAULT"
          ) {
            return SeatIsSelected;
          } else {
            SeatIsSelected = true;
          }
        }
      });
    }

    return SeatIsSelected;
  }
  allMandatoryAreSelected() {
    let allSeatSelected: boolean;
    if (this.itinerary.journeys.length === 1) {
      if (this.mandatorySeatIsSelected(0)) {
        this.mandatoryReservationSelected.emit(true);
        allSeatSelected = true;
      } else {
        this.mandatoryReservationSelected.emit(false);
        allSeatSelected = false;
      }
    } else {
      if (this.mandatorySeatIsSelected(0) && this.mandatorySeatIsSelected(1)) {
        this.mandatoryReservationSelected.emit(true);
        allSeatSelected = true;
      } else {
        this.mandatoryReservationSelected.emit(false);
        allSeatSelected = false;
      }
    }
    return allSeatSelected;
  }
  isSecondClass(direction: TrainTypes.OfferDirection): boolean {
    let allSecondClass: boolean;
    if (this.itinerary.sections.length > 1) {
      let index = direction == "outward" ? 1 : 0;
      allSecondClass = this.itinerary.sections[index].fares.every((fare: TrainTypes.Fare) => {
        return fare.fareSegments.every((segment) => {
          return segment.travelClass === "SECOND_CLASS";
        });
      });
    } else {
      allSecondClass = this.itinerary.sections[0].fares.every((fare: TrainTypes.Fare) => {
        // if inward + outward, section[0] is inward, if only outward, section[0] is outward
        return fare.fareSegments.every((segment) => {
          return segment.travelClass === "SECOND_CLASS";
        });
      });
    }

    return allSecondClass;
  }
  openSeatMaps(segments: Array<TrainTypes.Segment>, direction: TrainTypes.OfferDirection): void {
    // Détermination de la section correspondant aux segments passé en paramètre
    const currentSection =
      this.itinerary.sections.find((section) => section.segments === segments) || this.itinerary.sections[0];
    this.dialogService
      .open(TravelTrainSeatMapComponent, {
        closeOnEscape: false,
        data: {
          seatMap: this.itinerary.seatPreferenceOptions[direction].map((option) => option.seatMap?.id),
          travelClass: currentSection.fares[0].fareSegments[0].travelClass,
          segments: segments,
          provider: this.provider,
          passengerNumber: this.itinerary.seatPreferenceOptions[direction][0].passengerIds.length,
          selectedSegment: 0,
        },
        showHeader: false,
        dismissableMask: false,
        header: this.translateService.instant("SEARCH.RESULT.RAIL.SEAT_DESIGNATED"),
        height: this.commonService.isTablet ? `calc(${window.innerHeight}+30)px` : "auto",
        width: this.commonService.isTablet ? "100vw" : "auto",
        style: { "overflow-x": "unset", "max-width": "none" },
        styleClass: "seatmap",
      })
      .onClose.subscribe((seatMapSeatLocations: Array<Array<TrainTypes.SeatLocation>>): void => {
        if (seatMapSeatLocations) {
          seatMapSeatLocations.forEach((seatLocations: Array<TrainTypes.SeatLocation>, index: number) => {
            this.itinerary.seatPreferenceSelection[direction].push({
              id: this.itinerary.seatPreferenceOptions[direction][index].passengerIds[0],
              segmentIds: this.itinerary.seatPreferenceOptions[direction][index].segmentIds,
              passengerIds: this.itinerary.seatPreferenceOptions[direction][index].passengerIds,
              seatLocations,
              seatSelection: {
                description: "SEAT_DESIGNATED",
              },
              placeType: "SEAT",
            });
          });
        }
        this.changeDetector.markForCheck();
        this.itinerary.seatPreferenceSelection[direction].forEach(
          (_seatPreference: TrainTypes.SeatPreferenceSelection): void => {
            if (_seatPreference.seatLocations?.length > 0) {
              _seatPreference.description = this.translateService.instant(
                `SEARCH.RESULT.RAIL.SEAT_PREFERENCES.${_seatPreference.seatSelection.description}`,
                {
                  coach: _seatPreference.seatLocations[0].coachNumber,
                  seat: _seatPreference.seatLocations.reduce(
                    (seats: string, seatLocation: TrainTypes.SeatLocation): string => {
                      return `${seats === "" ? "" : `${seats}, `}${seatLocation.seatNumber}`;
                    },
                    "",
                  ),
                },
              );
            } else {
              _seatPreference.description = Object.values(_seatPreference)
                .filter(
                  (value: string | TrainTypes.SeatPreference): boolean =>
                    !!value &&
                    typeof value !== "string" &&
                    value.description &&
                    !["SEAT_RESERVATION_DEFAULT", "SEAT_NEXT_TO"].includes(value.description),
                )
                .map((value: TrainTypes.SeatPreference): string => {
                  return this.provider === "trainline"
                    ? value.description
                    : this.translateService.instant(`SEARCH.RESULT.RAIL.SEAT_PREFERENCES.${value.description}`);
                })
                .join(", ");
            }
          },
        );
      });
  }
}
