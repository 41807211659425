<div class="block-container" [class.return]="hasReturn">
  <div class="vertical-separator" *ngIf="!hasReturn">
    <span class="round"></span>
    <span class="line"></span>
    <span class="round"></span>
  </div>
  <div class="reservations-container">
    <div
      class="reservation"
      [class.return]="hasReturn"
      [class.line-through]="
        (exchangeType === 'Single' && journey.direction === exchangeDirection && isFirst) ||
        (exchangeType !== 'Single' && journey.direction === exchangeDirection && isOdd)
      "
      *ngFor="let journey of mappedJourneys; let isFirst = first; let isLast = last; let isOdd = odd"
    >
      <div class="infos">
        <ng-container *ngIf="hasReturn; else other">
          <p class="place">
            <span class="type" *ngIf="hasReturn && isFirst" translate>GLOBAL.DEPARTURE</span>
            <span class="type" *ngIf="hasReturn && isLast" translate>GLOBAL.RETURN</span>
            <span class="od"
              ><span class="origin"> {{ journey.departure.name | titlecase }}</span>
              <i class="spt-icon-arrow-circle-right arrow-right"></i>
              <span class="destination">{{ journey.arrival.name | titlecase }}</span>
            </span>
          </p>
        </ng-container>
        <ng-template #other>
          <div class="origin">
            <p class="place" *ngIf="isFirst || (isLast && journey.direction === 'inward')">
              {{ journey.departure.name | titlecase }}
            </p>
            <div class="date-hours-container" *ngIf="isFirst">
              <div class="date-hours">
                <div class="date">
                  <div class="schedules">
                    <div class="schedule">
                      <img src="./assets/svg/calendar-icon.svg" class="calendar-icon" />
                      <span class="date-day">{{ journey.departure.date.date | localizedDate: "dd" }}</span>
                      <span class="date-month">{{
                        journey.departure.date.date | localizedDate: "MMM" | titlecase
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="chip-timing">
                  <div class="schedules">
                    <div class="schedule">
                      <img src="./assets/svg/clock2.svg" class="clock-icon" />
                      <span> {{ journey.departure.date.time }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="destination">
            <p class="place" *ngIf="isLast">
              {{ journey.arrival.name | titlecase }}
            </p>
            <div class="date-hours-container">
              <div class="date-hours" *ngIf="isLast && journey.segments.length > 0">
                <div class="date">
                  <div class="schedules">
                    <div class="schedule">
                      <img src="./assets/svg/calendar-icon.svg" class="calendar-icon" />
                      <span class="date-day">{{ journey.arrival.date.date | localizedDate: "dd" }}</span>
                      <span class="date-month">{{ journey.arrival.date.date | localizedDate: "MMM" | titlecase }}</span>
                    </div>
                  </div>
                </div>
                <div class="chip-timing">
                  <div class="schedules">
                    <ng-container *ngFor="let schedule of schedules[journey.direction]; let isScheduleLast = last">
                      <div class="schedule" *ngIf="isScheduleLast">
                        <img src="./assets/svg/clock2.svg" class="clock-icon" />
                        <span>{{ schedule.arrival.date.time }}</span>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="date-hours-container" *ngIf="hasReturn && isFirst">
        <div class="date-hours">
          <div class="date">
            <div class="schedules">
              <div class="schedule">
                <img src="./assets/svg/calendar-icon.svg" class="calendar-icon" />
                <span class="date-day">{{ journey.departure.date.date | localizedDate: "dd" }}</span>
                <span class="date-month">{{ journey.departure.date.date | localizedDate: "MMM" | titlecase }}</span>
              </div>
            </div>
          </div>
          <div class="chip-timing">
            <div class="schedules">
              <div class="schedule">
                <img src="./assets/svg/clock2.svg" class="clock-icon" />
                <span> {{ journey.departure.date.time }} - {{ journey.arrival.date.time }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="date-hours-container" *ngIf="hasReturn && isLast && journey.segments.length > 0">
        <div class="date-hours">
          <div class="date">
            <div class="schedules">
              <div class="schedule">
                <img src="./assets/svg/calendar-icon.svg" class="calendar-icon" />
                <span class="date-day">{{ journey.arrival.date.date | localizedDate: "dd" }}</span>
                <span class="date-month">{{ journey.arrival.date.date | localizedDate: "MMM" | titlecase }}</span>
              </div>
            </div>
          </div>
          <div class="chip-timing">
            <div class="schedules">
              <div class="schedule">
                <img src="./assets/svg/clock2.svg" class="clock-icon" />
                <span> {{ journey.departure.date.time }} - {{ journey.arrival.date.time }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
