<div class="grid grid-nogutter">
  <span class="txt-23 f-sb m-b-10" translate>SEARCH.RESULT.ADD_TAGS</span>
  <p-autoComplete
    class="col-12"
    (onSelect)="emitTag()"
    (onUnselect)="emitTag()"
    [(ngModel)]="texts"
    [suggestions]="suggestions"
    (keydown.enter)="addTag($event)"
    (onBlur)="addTag($event)"
    [delay]="'10'"
    [autoHighlight]="true"
    (completeMethod)="search($event)"
    field="label"
    [multiple]="true"
  ></p-autoComplete>
  <span *ngIf="(commonService.isTabletObservable | async) === false" translate>TAGS.INFORMATIONS</span>
</div>
