import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { CommonService } from "../../../../../@shared/services/common.service";
import { TrainTypes } from "../../../../../travel/train/train";
import { UtilsTypes } from "../../../../../@shared/@types/utils";

@Component({
  selector: "spt-booking-train",
  templateUrl: "./train.component.html",
  styleUrls: ["../booking-list.component.scss", "./train.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TrainComponent implements OnInit {
  hasOutward: boolean;
  hasReturn: boolean;
  orderedJourneys: Array<Partial<TrainTypes.Journey>>;
  schedules: UtilsTypes.ObjectKey<Array<{ departure: UtilsTypes.TravelDate; arrival: UtilsTypes.TravelDate }>> = {
    outward: [],
    inward: [],
  };
  @Input() item: any;
  @Output() openedEticket: EventEmitter<any> = new EventEmitter();

  constructor(public commonService: CommonService) {}

  getSchedules(): void {
    this.item.detail.journeys.forEach((_journey: TrainTypes.Journey) => {
      const schedule: {
        departure: UtilsTypes.TravelDate;
        arrival: UtilsTypes.TravelDate;
      } = this.schedules[_journey.direction].find(
        (_schedule: { departure: UtilsTypes.TravelDate; arrival: UtilsTypes.TravelDate }) => {
          return (
            _schedule.departure.date === _journey.departure.date.date &&
            _schedule.arrival.date === _journey.arrival.date.date
          );
        },
      );
      if (schedule && schedule.arrival.time < _journey.arrival.date.time) {
        schedule.arrival = _journey.arrival.date;
      } else if (!schedule) {
        this.schedules[_journey.direction].push({
          departure: _journey.departure.date,
          arrival: _journey.arrival.date,
        });
      }
    });
  }

  ngOnInit(): void {
    const orderedJourneys: Array<Partial<TrainTypes.Journey>> = [];
    this.schedules.outward = [];
    this.schedules.inward = [];
    this.getSchedules();
    this.hasOutward = this.item.detail.journeys.some(
      (_journey: TrainTypes.Journey) => _journey.direction === "outward",
    );
    this.hasReturn = this.item.detail.journeys.some((_journey: TrainTypes.Journey) => _journey.direction === "inward");
    const tempJourneys: Array<TrainTypes.Journey> = [...this.item.detail.journeys];

    if (this.hasOutward) {
      const firstOutward: TrainTypes.Journey = this.item.detail.journeys.find(
        (_journey: TrainTypes.Journey) => _journey.direction === "outward",
      );
      const lastOutward: TrainTypes.Journey = tempJourneys
        .reverse()
        .find((_journey: TrainTypes.Journey) => _journey.direction === "outward");

      orderedJourneys.push({
        departure: firstOutward.departure,
        arrival: lastOutward.arrival,
        direction: "outward",
        segments: [...(firstOutward.segments ?? []), ...(lastOutward.segments ?? [])],
      });
    }

    if (this.hasReturn) {
      const firstInward: TrainTypes.Journey = this.item.detail.journeys.find(
        (_journey: TrainTypes.Journey) => _journey.direction === "inward",
      );
      const lastInward: TrainTypes.Journey = tempJourneys.find(
        (_journey: TrainTypes.Journey) => _journey.direction === "inward",
      );

      orderedJourneys.push({
        departure: firstInward.departure,
        arrival: lastInward.arrival,
        direction: "inward",
        segments: [...(firstInward.segments ?? []), ...(lastInward.segments ?? [])],
      });
    }

    this.orderedJourneys = orderedJourneys;
  }
}
