<div class="component-container">
  <section>
    <ng-container *ngIf="(commonService.isTabletObservable | async) === false; else mobile">
      <h1 class="bookings">{{ "GLOBAL.MY_BOOKING" | translate }}</h1>
    </ng-container>
    <ng-template #mobile>
      <div class="header-mobile">
        <h1 class="bookings">{{ "GLOBAL.MY_BOOKING" | translate }}</h1>
        <spt-menu-mobile></spt-menu-mobile>
      </div>
    </ng-template>
    <div>
      <div class="container">
        <div class="filters grid align-items-start">
          <div class="search">
            <div class="filter-content">
              <div class="filter-input">
                <div class="p-inputgroup align-items-center search">
                  <span class="p-inputgroup-addon"><i class="fas fa-search"></i></span>
                  <input
                    type="text"
                    [placeholder]="'BOOKING.SEARCH_LABEL' | translate"
                    pInputText
                    [(ngModel)]="inputSearch"
                    (ngModelChange)="applyFilter()"
                  />
                </div>
                <div
                  class="filter-more"
                  *ngIf="(commonService.isTabletObservable | async) === true"
                  (click)="toggleFilters()"
                >
                  <i class="fa-solid fa-circle-plus" *ngIf="!showFilters"></i>
                  <i class="fa-solid fa-circle-minus" *ngIf="showFilters"></i>
                </div>
              </div>
              <div [hidden]="!showFilters && (commonService.isTabletObservable | async) === true" class="type">
                <p-multiSelect
                  [(ngModel)]="paramFilters.types"
                  [options]="itemsTypes"
                  styleClass="multiselect-filter"
                  defaultLabel="{{ 'GLOBAL.TYPE' | translate }}"
                  [showHeader]="false"
                  [displaySelectedLabel]="false"
                  [maxSelectedLabels]="null"
                  [resetFilterOnHide]="true"
                  (onChange)="applyFilter()"
                >
                  <ng-template let-itemType let-i="index" pTemplate="item">
                    <p translate>GLOBAL.{{ itemType.label | uppercase }}</p>
                  </ng-template>
                  <ng-template let-value pTemplate="selectedItems">
                    <div>
                      {{ "GLOBAL.TYPE" | translate }}
                      <span class="count"
                        ><span *ngIf="paramFilters.types?.length > 0">{{ paramFilters.types.length }}</span></span
                      >
                    </div>
                  </ng-template>
                </p-multiSelect>
              </div>
              <ng-container *ngIf="(commonService.isTabletObservable | async) === false; else calendarMobile">
                <div class="text-icon" (click)="openCalendar($event)">
                  <img src="./assets/svg/calendar-reservation.svg" class="action-icon" />
                  <p-calendar
                    [firstDayOfWeek]="this.translateService.currentLang === 'en' ? 0 : 1"
                    [dateFormat]="dateFormat"
                    [(ngModel)]="rangeDates"
                    (ngModelChange)="filterDate($event)"
                    [showTime]="false"
                    [yearNavigator]="true"
                    [monthNavigator]="true"
                    [yearRange]="yearRange"
                    selectionMode="range"
                    required
                    [selectOtherMonths]="true"
                    [panelStyle]="{ 'z-index': '2' }"
                    #periodCalendar
                    (onFocus)="calendarShown(periodCalendar)"
                    (onClose)="calendarClosed()"
                  >
                    <p-header>
                      <p class="text-center f-sb txt-20">
                        {{ "BOOKING.PERIOD" | translate }}
                      </p>
                    </p-header>
                  </p-calendar>
                </div>
              </ng-container>
              <ng-template #calendarMobile>
                <div class="text-icon calendar" (click)="openCalendar($event)" [hidden]="!showFilters">
                  <p>
                    <img src="./assets/svg/calendar-reservation.svg" class="action-icon" />
                    {{ "BOOKING.PERIOD" | translate }}
                  </p>
                  <i class="pi pi-chevron-down"></i>

                  <p-calendar
                    [firstDayOfWeek]="this.translateService.currentLang === 'en' ? 0 : 1"
                    [(ngModel)]="rangeDates"
                    (ngModelChange)="filterDate($event)"
                    [showTime]="false"
                    [yearNavigator]="true"
                    [monthNavigator]="true"
                    [yearRange]="yearRange"
                    selectionMode="range"
                    required
                    [selectOtherMonths]="true"
                    [panelStyle]="{ 'z-index': '2' }"
                    panelStyleClass="p-calendar-body"
                    appendTo="body"
                    #periodCalendarMobile
                    (onClose)="calendarClosed()"
                    (onFocus)="calendarShown(periodCalendar)"
                  >
                    <p-header>
                      <p class="text-center f-sb txt-20">
                        {{ "BOOKING.PERIOD" | translate }}
                      </p>
                    </p-header>
                  </p-calendar>
                </div>
              </ng-template>
              <div
                class="filter-more"
                *ngIf="(commonService.isTabletObservable | async) === false"
                (click)="toggleFilters()"
              >
                <i class="fa-solid fa-circle-plus" *ngIf="!showFilters"></i>
                <i class="fa-solid fa-circle-minus" *ngIf="showFilters"></i>
                {{ "SEARCH.RESULT.FILTERS" | translate }}
              </div>
            </div>
          </div>
          <div class="filters-other" *ngIf="showFilters" [@filtersFadeInOut]="filterState">
            <p-multiSelect
              [(ngModel)]="paramFilters.entities"
              [options]="itemsEntities"
              styleClass="multiselect-filter"
              defaultLabel="{{ 'BOOKING.FILTER_ENTITY' | translate }}"
              [resetFilterOnHide]="true"
              [filter]="true"
              [disabled]="itemsEntities?.length === 0"
              filterBy="label"
              [filterFields]="['label']"
              [displaySelectedLabel]="false"
              (ngModelChange)="applyFilter()"
              [maxSelectedLabels]="null"
              ><ng-template let-value pTemplate="selectedItems">
                <div>
                  {{ "BOOKING.FILTER_ENTITY" | translate }}
                  <span class="count"
                    ><span *ngIf="paramFilters.entities?.length > 0">{{ paramFilters.entities.length }}</span></span
                  >
                </div>
              </ng-template>
            </p-multiSelect>
            <p-multiSelect
              [(ngModel)]="paramFilters.services"
              [options]="itemsServices"
              styleClass="multiselect-filter"
              defaultLabel="{{ 'GLOBAL.SERVICE' | translate }}"
              [resetFilterOnHide]="true"
              [filter]="true"
              filterBy="label"
              [filterFields]="['label']"
              [disabled]="itemsServices?.length === 0"
              [displaySelectedLabel]="false"
              [maxSelectedLabels]="null"
              (ngModelChange)="applyFilter()"
            >
              <ng-template let-value pTemplate="selectedItems">
                <div>
                  {{ "GLOBAL.SERVICE" | translate }}
                  <span class="count"
                    ><span *ngIf="paramFilters.services?.length > 0">{{ paramFilters.services.length }}</span></span
                  >
                </div>
              </ng-template>
            </p-multiSelect>
            <p-multiSelect
              [(ngModel)]="paramFilters.tags"
              [options]="itemsTags"
              styleClass="multiselect-filter"
              defaultLabel="{{ 'GLOBAL.TAGS' | translate }}"
              [resetFilterOnHide]="true"
              [filter]="true"
              filterBy="label"
              [filterFields]="['label']"
              [disabled]="itemsTags?.length === 0"
              [displaySelectedLabel]="false"
              [maxSelectedLabels]="null"
              (ngModelChange)="applyFilter()"
            >
              <ng-template let-value pTemplate="selectedItems">
                <div>
                  {{ "GLOBAL.TAGS" | translate }}
                  <span class="count"
                    ><span *ngIf="paramFilters.tags?.length > 0">{{ paramFilters.tags.length }}</span></span
                  >
                </div>
              </ng-template>
            </p-multiSelect>
            <p-multiSelect
              [(ngModel)]="paramFilters.users"
              [options]="itemsUsers"
              styleClass="multiselect-filter"
              defaultLabel="{{ 'HEADER.COLLABORATORS' | translate }}"
              [resetFilterOnHide]="true"
              [filter]="true"
              filterBy="label"
              [filterFields]="['label']"
              [disabled]="itemsUsers?.length === 0"
              [displaySelectedLabel]="false"
              (ngModelChange)="applyFilter()"
              [maxSelectedLabels]="null"
            >
              <ng-template let-value pTemplate="selectedItems">
                <div>
                  {{ "HEADER.COLLABORATORS" | translate }}
                  <span class="count"
                    ><span *ngIf="paramFilters.users?.length > 0">{{ paramFilters.users.length }}</span></span
                  >
                </div>
              </ng-template>
            </p-multiSelect>
            <ng-container *ngIf="(commonService.isTabletObservable | async) === false">
              <div class="cancel-checkbox">
                <p-checkbox
                  [binary]="true"
                  [(ngModel)]="displayCancelled"
                  (onChange)="updateFilteredItems()"
                  [label]="'BOOKING.DISPLAY_CANCEL' | translate"
                ></p-checkbox>
              </div>
              <p class="reset-filters" (click)="resetAllFilters()">
                {{ "BOOKING.RESET_FILTERS" | translate }}
              </p>
            </ng-container>
          </div>
          <div
            class="cancel-reset"
            *ngIf="showFilters && (commonService.isTabletObservable | async) === true"
            [@filtersFadeInOut]="filterState"
          >
            <div class="cancel-checkbox">
              <p-checkbox
                [binary]="true"
                [(ngModel)]="displayCancelled"
                (onChange)="updateFilteredItems()"
                [label]="'BOOKING.DISPLAY_CANCEL' | translate"
              ></p-checkbox>
            </div>

            <p class="reset-filters" (click)="resetAllFilters()">
              {{ "BOOKING.RESET_FILTERS" | translate }}
            </p>
          </div>
        </div>
        <div class="content">
          <div *ngIf="filteredItems | async | searchPipe: searchPipeArray | fusePipe: fuseOptions as $filteredItems">
            <div [ngClass]="showFilters ? 'large-margin-top' : 'small-margin-top'" *ngIf="!!filteredItems">
              <cdk-virtual-scroll-viewport
                class="virtual-scroll"
                [class.shorter]="!!showFilters"
                [itemSize]="(commonService.isTabletObservable | async) === true ? 480 : 280"
                (scrolledIndexChange)="showFilters = false"
                appendOnly
              >
                <div
                  *cdkVirtualFor="
                    let item of filteredItems | async | searchPipe: searchPipeArray | fusePipe: fuseOptions;
                    let i = index;
                    trackBy: trackItem;
                    templateCacheSize: 0
                  "
                >
                  <p-card
                    [styleClass]="['cancelled', 'modified'].includes(item.status) ? item.status + '' : 'confirmed'"
                  >
                    <div
                      class="item"
                      [ngClass]="['cancelled', 'modified'].includes(item.status) ? item.status + '' : 'confirmed'"
                    >
                      <div class="informations">
                        <div class="title-status">
                          <div *ngIf="item.status === 'cancelled'" class="item-cancelled">
                            <span translate>GLOBAL.CANCELLED</span>
                          </div>
                          <div *ngIf="item.status === 'modified'" class="item-cancelled">
                            <span translate>GLOBAL.MODIFIED</span>
                          </div>
                          <div class="item-icon" *ngIf="(commonService.isTabletObservable | async) === true">
                            <i [class]="'spt-icon-' + item.type"></i>
                          </div>
                          <div class="title-basket" [attr.data-title]="item.title">
                            <span>{{ item.title }}</span>
                          </div>
                        </div>

                        <div class="item-icon" *ngIf="(commonService.isTabletObservable | async) === false">
                          <img src="./assets/svg/cards/{{ item.type }}.svg" alt="" />
                        </div>
                        <div class="item-passengers" [class.one]="item.travelers.length < 2">
                          <div class="passengers-list" [attr.data-title]="item.travelersList">
                            {{ item.travelersList }}
                          </div>
                          <div class="avatars-list">
                            <div class="avatars">
                              <spt-avatar
                                class="simple-avatar"
                                *ngFor="let traveler of item.travelers.slice(0, 2)"
                                [user]="traveler"
                                [displayUsername]="false"
                                [displayTooltip]="true"
                                title="{{ traveler.firstname }} {{ traveler.lastname }}"
                              ></spt-avatar>
                            </div>
                            <div
                              class="users-number"
                              *ngIf="item.travelers.length > 2"
                              [pTooltip]="passengerEmails[item.id].toString()"
                              tooltipPosition="top"
                            >
                              <p>+</p>
                              <span>{{ item.travelers.length - 2 }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="separator-top" *ngIf="(commonService.isTabletObservable | async) === false"></div>
                        <div class="informations-details">
                          <ng-container [ngSwitch]="item.type">
                            <spt-basket-train-block *ngSwitchCase="'train'" class="item-body" [item]="item">
                            </spt-basket-train-block>
                            <spt-basket-car-block *ngSwitchCase="'car'" class="item-body" [item]="item">
                            </spt-basket-car-block>
                            <spt-basket-hotel-block *ngSwitchCase="'hotel'" class="item-body" [item]="item">
                            </spt-basket-hotel-block>
                            <spt-basket-flight-block
                              *ngSwitchCase="'flight'"
                              class="item-body"
                              [item]="item"
                              [typeTravel]="item.travelType"
                              [firstItem]="avatar"
                              resa="true"
                            >
                            </spt-basket-flight-block>
                            <spt-basket-transfer-block *ngSwitchCase="'transfer'" class="item-body" [item]="item">
                            </spt-basket-transfer-block>
                          </ng-container>
                          <div
                            class="separator-bottom"
                            *ngIf="(commonService.isTabletObservable | async) === true"
                          ></div>
                          <div class="item-price">
                            <span>{{ item.price | SPTPrice: item.confirmationDate | async }}</span>
                            <p-button
                              [styleClass]="
                                (commonService.isTabletObservable | async) === true
                                  ? 'p-button-quaternary large-mobile'
                                  : 'p-button-quaternary medium'
                              "
                              label="{{ 'BOOKING.MANAGE' | translate }}"
                              class="see-button"
                              type="button"
                              (click)="openSidebar(item)"
                            ></p-button>
                          </div>
                        </div>
                      </div>
                      <spt-reservation-block-footer class="item-footer" [item]="item"></spt-reservation-block-footer>
                    </div>
                  </p-card>
                </div>
              </cdk-virtual-scroll-viewport>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<spt-sidebar
  [opened]="choosenObject !== undefined"
  (close)="choosenObject = undefined"
  [showCloseIcon]="false"
  styleClass="sidebar-travel"
>
  <ng-container *ngIf="choosenObject">
    <spt-sidebar-travel
      *ngIf="!isDisplayedModification && !isDisplayedCancellation"
      [item]="choosenObject"
      (toggleSidebarEmitter)="closeSidebar(false)"
      [visible]="showSidebar"
    >
      <footer>
        <spt-reservation-sidebar-footer
          [item]="choosenObject"
          (onModify)="modify($event)"
          (onCancel)="cancel($event)"
          (onShowLabelSide)="editLabel($event)"
        ></spt-reservation-sidebar-footer>
      </footer>
    </spt-sidebar-travel>
    <spt-reservations-modify
      *ngIf="isDisplayedModification"
      [item]="choosenObject"
      [isSmallDevice]="isSmallDevice"
      [locale]="locale"
      (returnToDetails)="isDisplayedModification = undefined"
    ></spt-reservations-modify>
    <spt-reservations-cancel
      *ngIf="isDisplayedCancellation"
      [item]="choosenObject"
      [isSmallDevice]="isSmallDevice"
      [locale]="locale"
      (closeSidebar)="closeSidebar(true)"
      (returnToDetails)="isDisplayedCancellation = undefined"
    ></spt-reservations-cancel>
    <spt-label-side
      [labels]="societyLabels"
      [userLabel]="choosenObject.labels"
      [type]="choosenObject.type"
      [visible]="showLabelSide"
      [comment]="choosenObject.comment"
      (validateUserLabels)="addUserLabel($event)"
    ></spt-label-side>
  </ng-container>
</spt-sidebar>
