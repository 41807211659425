import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SliderModule } from "primeng/slider";
import { SharedModule } from "src/app/@shared/shared.module";
import { TagsModule } from "src/app/dashboard/@dashboard-shared/modals/tags-modal/tags.module";
import { TravelTransferComponent } from "src/app/travel/transfer/transfer.component";

@NgModule({
  imports: [SharedModule, SliderModule, TagsModule, TranslateModule.forChild()],
  exports: [TravelTransferComponent],
  declarations: [TravelTransferComponent],
})
export class TravelTransferModule {}
