import { Config } from "./environment.d";

export const environment: Config = {
  production: true,
  api: "https://spt-api-management.prd.supertripper.com",
  baseApi: "https://spt-api-management.prd.supertripper.com",
  stripe: "pk_live_yxEIY45X17HZ3B1Vyd1IEZFQ",
  stripeUrl: "https://stripe.supertripper.com",
  application: "supertripper-vL6jvWU@`!`#S7FX",
  googleKey: "AIzaSyCBGYM_YF032FOBCW5MQTqaFsRBaKp1KbI",
  graphHopperKey: "28920890-ce41-4368-827b-55c1048e91f5",
  activateGeocoder: true,
  bucketAvatarsEndpoint: "https://prd-spt-api-management-avatars.s3.eu-west-3.amazonaws.com/",
};

//markerIOproject: "64872a87e1bae6f09e2de8a5";
