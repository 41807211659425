<img
  *ngIf="(commonService.isTabletObservable | async) === true"
  src="./../../../../assets/svg/tag.svg"
  class="tag-icon"
  (click)="toggleTicketTags()"
  #tagsTicketBtn
/>
<img
  *ngIf="(commonService.isTabletObservable | async) === false"
  src="./../../../../assets/svg/tag.svg"
  class="tag-icon"
/>
<div class="tags-wrapper">
  <div *ngIf="tags" class="tags">
    <ng-container *ngIf="!this.commonService.isTablet">
      <ng-container *ngFor="let tag of tags; let idx = index">
        <div
          class="tag"
          *ngIf="idx < nbMax"
          [attr.data-title]="tag.label"
          [ngClass]="tagsAdditionnalList.length > 0 ? 'more' : ''"
        >
          <button type="button"># {{ tag.label }}</button>

          <div class="delete-tag" (click)="deleteTag(tag)">
            <img src="./assets/svg/garbage.svg" class="cross-icon" />
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="tagsAdditionnalList.length > 0">
        <button type="button" #moreTag class="tag">...</button>
        <p-contextMenu
          [target]="moreTag"
          [appendTo]="'body'"
          [model]="tagsAdditionnalList"
          [triggerEvent]="moreTagTriggerEvent"
        ></p-contextMenu>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="this.commonService.isTablet">
      <ng-container *ngFor="let tag of tags; let idx = index">
        <div class="tag" *ngIf="idx < nbMax" [ngClass]="tagsAdditionnalList.length > 2 ? 'more' : ''">
          <button type="button" #moreTag># {{ tag.label }}</button>
          <p-contextMenu
            [target]="moreTag"
            [appendTo]="'body'"
            [model]="tagsAdditionnalList"
            [triggerEvent]="moreTagTriggerEvent"
          ></p-contextMenu>
        </div>
      </ng-container>
      <ng-container *ngIf="tagsAdditionnalList.length > 2">
        <button type="button" #moreTag class="tag">...</button>
        <p-contextMenu
          [target]="moreTag"
          [appendTo]="'body'"
          [model]="tagsAdditionnalList"
          [triggerEvent]="moreTagTriggerEvent"
        ></p-contextMenu>
      </ng-container>
    </ng-container>
  </div>
</div>

<div class="tag-button-dropwdown-container">
  <p-button styleClass="p-button-secondary small" (click)="tagOverlay.toggle($event)" (keyup.esc)="toggleSocietyTags()">
    <span class="p-icon fa-solid fa-plus"></span>
    <span>{{ "BOOKING.ADD_TAG" | translate }}</span>
    <spt-tooltip
      [description]="'TAGS.TOOLTIP' | translate"
      [isSmallDevice]="commonService.isTablet"
      [position]="'top'"
      [triggerImg]="'info-violet.svg'"
    ></spt-tooltip>
  </p-button>

  <p-overlayPanel [appendTo]="'body'" [dismissable]="true" #tagOverlay>
    <div class="dropdown-society-tag">
      <div class="search">
        <input
          pInputText
          type="text"
          styleClass="tag-input"
          field="label"
          [(ngModel)]="tagInput"
          [placeholder]="'BOOKING.ADD_TAG' | translate"
          (keyup.escape)="toggleSocietyTags()"
          (keyup.enter)="addTag()"
        />
        <img src="./assets/svg/checked.svg" class="add-tag-icon" (click)="addTag()" />
      </div>
      <div class="results">
        <p
          class="tag-text"
          (click)="selectTag(societyTag)"
          *ngFor="let societyTag of societyTags | async | sptFilter: tagInput : tagFilterFields"
        >
          # {{ societyTag.label }}
        </p>
      </div>
    </div>
  </p-overlayPanel>
</div>
