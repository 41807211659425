import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { SharedModule } from "../../../../@shared/shared.module";
import { BillsModalComponent } from "./bills-modal.component";

@NgModule({
  declarations: [BillsModalComponent],
  imports: [SharedModule, ButtonModule, TranslateModule],
  exports: [BillsModalComponent],
})
export class BillsModalModule {}
