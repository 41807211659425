import { Component, Input, ViewEncapsulation } from "@angular/core";
import moment from "moment";

@Component({
  selector: "spt-travel-confirmation-car",
  templateUrl: "./confirmation-sidebar-car.component.html",
  styleUrls: ["./confirmation-sidebar-car.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationSidebarCarComponent {
  @Input() car: any;
  @Input() search: any;
  @Input() travelers: any = [];
  @Input() locale: string;

  constructor() {}

  get locationDuration(): number {
    const diff: number = moment(this.search.data.returnDate).diff(moment(this.search.data.pickupDate), "days");

    return diff + 1;
  }
}
