import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { DateTimeSearch, SearchCriteria, SearchResult, Travel, WhenSearch } from "../search-engine-service";
import { FlightLocality, FlightSearchOptions, FlightSearchService } from "./flight-search.service";
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { MemberSociety } from "src/app/@shared/@types/society";
import { FlightTypes } from "src/app/@shared/@types/flight";
import { UtilsTypes } from "src/app/@shared/@types/utils";
import { SessionService } from "src/app/@shared/services/session.service";
import { times12, times24 } from "../../../dashboard/utils";
import { SuggestPanelService } from "../common/suggest-panel.service";
import { groupByMap } from "src/app/@shared/utils";

@Component({
  selector: "spt-search-flight",
  templateUrl: "./search-flight.component.html",
  styleUrls: ["./search-flight.component.scss"],
})
export class SearchFlightComponent implements OnInit, OnChanges {
  searchEngineType: string;
  @Input() pickableMembers: MemberSociety[];
  public memberPassengers: MemberSociety[];
  public societyMembers: MemberSociety[];
  public maxDateCount: number = 2;
  protected hourMandatory: { [date: string]: boolean } = {};
  protected dateMandatory: { [date: string]: boolean } = {};

  public travelTypes: UtilsTypes.FixedLengthArray<["round", "simple", "multi"]> = ["round", "simple", "multi"];

  public yearRange: string = `1930:2023`;

  public travelType: FlightTypes.TravelType = "round";

  cabinClassOptions: Array<SelectItem> = [
    {
      label: this.translateService.instant("GLOBAL.CATEGORY.Y"),
      value: "Y",
    },
    {
      label: this.translateService.instant("GLOBAL.CATEGORY.C"),
      value: "C",
    },
  ];

  withLuggageOptions: Array<SelectItem> = [
    {
      label: this.translateService.instant("SEARCH.AIR.OPTIONS.NO.BAGAGES"),
      value: -1,
    },
    {
      label: this.translateService.instant("SEARCH.AIR.OPTIONS.WITH.BAGAGES"),
      value: 1,
    },
  ];

  @Input() searchCriteria!: SearchCriteria<FlightLocality, FlightSearchOptions>;
  isValid: boolean = false;
  gridTemplateAreas!: string;
  gridTemplateRows!: string;

  protected timeOptionsDepartsAfter: SelectItem[] = [];
  protected timeOptionsArrivalBefore: SelectItem[] = [];

  suggestOpened: boolean = false;

  constructor(
    protected searchService: FlightSearchService,
    private sessionService: SessionService<any>,
    private translateService: TranslateService,
    protected suggestPanelService: SuggestPanelService,
  ) {
    this.searchEngineType = searchService.getType();
  }

  private refreshGridTemplateAreas(): void {
    const gridTemplateAreasResult: string[] = ["title  suggest", "type   suggest"];
    const gridTemplateAreasRows: string[] = ["auto", "auto"];
    this.searchCriteria.travels.forEach((item, index) => {
      if (this.travelType === "multi") {
        gridTemplateAreasResult.push("separate-" + index + "  suggest", "trip-" + index + "  suggest");
        gridTemplateAreasRows.push("auto", "auto");
      }
      gridTemplateAreasResult.push(
        "origin-" + index + "  suggest",
        "destination-" + index + "  suggest",
        "datetime-" + index + "  suggest",
        "error-" + index + "  suggest",
      );
      gridTemplateAreasRows.push("auto", "auto", "auto", "auto");
    });
    if (this.travelType === "multi") {
      gridTemplateAreasResult.push("addFlight  suggest");
      gridTemplateAreasRows.push("auto");
    }
    gridTemplateAreasResult.push("people    suggest", "options   suggest", "triggers  suggest");
    gridTemplateAreasRows.push("auto", "auto", "1fr");
    this.gridTemplateAreas = gridTemplateAreasResult.map((l) => "'" + l + "'").join("\n");
    this.gridTemplateRows = gridTemplateAreasRows.join(" ");
  }

  ngOnInit(): void {
    if (!this.searchCriteria) {
      this.searchCriteria = this.searchService.createBlankCriteria();
    }
    this.suggestPanelService.init(DEFAULT_SUGGEST_PANEL);
    const session = this.sessionService.get();
    this.timeOptionsDepartsAfter.push(TIME_OPTION_ALLDAY);
    this.timeOptionsArrivalBefore.push(TIME_OPTION_ALLDAY);

    const times = session.timeFormat === "12" ? times12 : times24;

    this.timeOptionsDepartsAfter.push(
      ...times.map((timeTmp: SelectItem) => {
        return {
          label: timeTmp.label,
          value: {
            begin: timeTmp.value - 1 < 0 ? "00:00" : `${String(timeTmp.value - 1).padStart(2, "0")}:00`,
            end: "23:59",
            value: timeTmp.value,
          },
        };
      }),
    );

    this.timeOptionsArrivalBefore.push(
      ...times.map((timeTmp: SelectItem) => {
        return {
          label: timeTmp.label,
          value: {
            begin: "00:00",
            end: timeTmp.value + 1 >= 24 ? "23:59" : `${String(timeTmp.value + 1).padStart(2, "0")}:00`,
            value: timeTmp.value,
          },
        };
      }),
    );

    if (this.searchCriteria.travels.length > 1) {
      this.travelType = "multi";
      // Pas de favoris en mode "multi"
      this.suggestPanelService.setDefault(undefined);
    } else {
      this.travelType = !!this.searchCriteria.mainTravel.when.inward ? "round" : "simple";
      this.suggestPanelService.setDefault(DEFAULT_SUGGEST_PANEL);
    }
    this.refreshGridTemplateAreas();
  }

  activateSuggest(suggestToActivate: string) {
    this.suggestPanelService.activate(suggestToActivate);
    this.suggestOpened = true;
  }

  @Output()
  onSearchResult: EventEmitter<SearchResult> = new EventEmitter<SearchResult>();

  search(): void {
    this.searchService
      .launchSearch(this.searchCriteria)
      .subscribe((searchResult: SearchResult) => this.onSearchResult.emit(searchResult));
  }

  resetSuggestPanel(): void {
    this.suggestPanelService.reset();
    this.suggestOpened = false;
  }

  checkValidity() {
    if (!!this.searchCriteria.mainTravel.when.inward) {
      this.updateHourMandatoryForTravel([
        this.searchCriteria.mainTravel.when.outward,
        this.searchCriteria.mainTravel.when.inward,
      ]);
    } else if (this.searchCriteria.travels.length > 1) {
      this.dateMandatory = this.searchService.checkIfTravelsDatesAreInGoodOrder(this.searchCriteria.travels.map((travel) => travel.when.outward))
      this.updateHourMandatoryForTravel(this.searchCriteria.travels.map((travel) => travel.when.outward));
    } else {
      this.updateHourMandatoryForTravel();
    }
    this.isValid = this.searchService.searchCriteriaIsValid(this.searchCriteria, this.dateMandatory);
  }

  changeType(type: FlightTypes.TravelType) {
    if (type === "round") {
      this.maxDateCount = 2;
      this.searchCriteria.mainTravel.when.inward = new DateTimeSearch();
    } else {
      this.maxDateCount = 1;
      this.searchCriteria.travels.forEach((t) => (t.when.inward = undefined));
    }
    if (type !== "multi") {
      this.searchCriteria.travels.length = 1;
    }
    this.refreshGridTemplateAreas();
    this.checkValidity();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const newTravelType: FlightTypes.TravelType = changes["travelType"] as any as FlightTypes.TravelType;
    if (newTravelType) {
      this.changeType(newTravelType);
    }
  }

  addTravel() {
    this.searchCriteria.travels.push(
      new Travel<FlightLocality>(
        this.searchCriteria.mainTravel.people,
        new WhenSearch(new DateTimeSearch()),
        this.searchService.createDummyLocalityFromName(""),
        this.searchService.createDummyLocalityFromName(""),
      ),
    );
    this.refreshGridTemplateAreas();
  }

  private updateHourMandatoryForTravel(dateTimeSearch?: DateTimeSearch[]): void {
    const items =
      (dateTimeSearch || []).filter(
        (item) =>
          // On ne tient compte que des items où une date a été indiquée
          item.date?.length > 0,
      ) || [];
    this.hourMandatory = Object.fromEntries(
      Object.entries(groupByMap(items, (item) => item.date)).map(([datetime, items]) => [
        datetime,
        items.length > 1 && items.some((item) => item.time.begin === "00:00" && item.time.end === "23:59"),
      ]),
    );
  }

 

  removeTrip(index: number): void {
    this.searchCriteria.removeTravel(index);
    this.refreshGridTemplateAreas();
  }
}

const TIME_OPTION_ALLDAY = {
  label: "--:--",
  value: {
    begin: "00:00",
    end: "23:59",
  },
};

const DEFAULT_SUGGEST_PANEL = "favorites-0";
