import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RequestService {
  endpoint: string = `${environment.api}/api/request`;

  constructor(private httpClient: HttpClient) {}

  get(id: string): Observable<any> {
    return this.httpClient.get(`${this.endpoint}/${id}`).pipe(map((result: any) => result.data));
  }

  list(type?: string): Observable<Array<any>> {
    const params: HttpParams = new HttpParams({ fromObject: { type } });

    return this.httpClient
      .get(this.endpoint, {
        params,
      })
      .pipe(map((result: any) => result.data));
  }

  create(body: any, type: "seminar" | "seminarPackaged" | "transfer"): Observable<any> {
    return this.httpClient
      .post(this.endpoint, {
        type,
        item: body,
      })
      .pipe(map((result: any) => result.data));
  }

  update(id: string, body: any): Observable<any> {
    return this.httpClient.put(`${this.endpoint}/${id}`, body).pipe(map((result: any) => result.data));
  }

  delete(id: string): Observable<any> {
    return this.httpClient.delete(`${this.endpoint}/${id}`);
  }
}
