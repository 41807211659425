import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { TrainTypes } from "../../train";
import { UtilsTypes } from "../../../../@shared/@types/utils";
import { CommonService } from "src/app/@shared/services/common.service";

const CODES_TARIF_ABONNE = ["PL11", "CW11", "SE11", "JE11", "PL13", "LB11"];
const CODES_TARIF_NEGOCIE = ["RI05", "FA12"];

@Component({
  selector: "spt-travel-train-journey",
  templateUrl: "./journey.component.html",
  styleUrls: ["./journey.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelTrainJourneyComponent implements OnInit, OnChanges {
  @Input() journey: TrainTypes.JourneyWithOffers;
  @Input() locale: string;
  @Input() flexibilities: Array<TrainTypes.Flexibility | TrainTypes.ATOCFlexibility>;
  @Input() selectedFlexibility: TrainTypes.Flexibility | TrainTypes.ATOCFlexibility;
  @Input() isSmallDevice: boolean;
  @Input() seeOutOfPolicy: boolean;
  @Input() allowedAlternativeIds: Array<string>;
  @Input() selectedReturnFare: boolean;
  @Output() selectOffers: EventEmitter<TrainTypes.OffersSelection> = new EventEmitter();
  travelClasses: Array<TrainTypes.TravelClass> = ["SECOND_CLASS", "FIRST_CLASS"];
  bestPriceOffer: TrainTypes.OffersSelection;
  filteredOffers: Record<string, TrainTypes.OffersSelection> = {};
  totalSections: UtilsTypes.ObjectKey<UtilsTypes.Price> = {};

  constructor(public commonService: CommonService) {}

  private static findBestPriceProduct(
    offerSelection: Record<string, TrainTypes.OffersSelection>,
  ): TrainTypes.OffersSelection {
    const offers: Array<TrainTypes.OffersSelection> = Object.values(offerSelection);
    offers.sort((_offerA: TrainTypes.OffersSelection, _offerB: TrainTypes.OffersSelection) => {
      if (_offerA.price.amount < _offerB.price.amount) {
        return -1;
      } else if (_offerA.price.amount > _offerB.price.amount) {
        return 1;
      }
    });
    return offers[0];
  }

  ngOnInit(): void {
    this.filterOffers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.selectedReturnFare &&
      changes.selectedReturnFare.currentValue !== changes.selectedReturnFare.previousValue
    ) {
      this.filterOffers();
    }
  }

  onSelectOffer(offersSelection: Partial<TrainTypes.OffersSelection>): void {
    this.selectOffers.emit(offersSelection as TrainTypes.OffersSelection);
  }

  /**
   * Permet de savoir si une offre doit être prise en compte ou pas
   */
  private isValidOffer(_offer: TrainTypes.Offer): boolean {
    return this.seeOutOfPolicy || (!this.seeOutOfPolicy && !_offer.isOutOfPolicy);
  }

  /**
   * Permet de savoir si le produit contient au moins un code tarif d'une liste définit
   */
  private hasFareCode(offer: TrainTypes.Offer, fareCodes: string[]): boolean {
    // renvoi `true` si le produit contient au moins une alternative contenant au moins un fareCode correspondant à un des codes tarif indiqués
    return offer.fares.some((fare: TrainTypes.Fare) => fareCodes.includes(fare.fareCode));
  }

  private filterOffers(): void {
    this.journey.sections.forEach((section: TrainTypes.Section): void => {
      Object.entries(section.offers).forEach(
        ([_flexibility, _travelClasses]: [
          TrainTypes.Flexibility,
          Record<TrainTypes.TravelClass, Array<TrainTypes.Offer>>,
        ]) => {
          Object.entries(_travelClasses).forEach(
            ([_travelClass, _offers]: [TrainTypes.TravelClass, Array<TrainTypes.Offer>]) => {
              _offers.forEach((_offer: TrainTypes.Offer) => {
                if (this.isValidOffer(_offer)) {
                  _offer.hasNegotiatedRate = this.hasFareCode(_offer, CODES_TARIF_NEGOCIE);
                  _offer.subscriber = this.hasFareCode(_offer, CODES_TARIF_ABONNE);

                  if (
                    this.filteredOffers &&
                    this.filteredOffers[`${_flexibility}-${_travelClass}`] &&
                    !this.filteredOffers[`${_flexibility}-${_travelClass}`].offers.some(
                      (_filteredOffer: TrainTypes.Offer) => _offer.id === _filteredOffer.id,
                    ) &&
                    ((this.selectedReturnFare && _offer.allowedAlternatives?.length > 0) ||
                      (!this.selectedReturnFare &&
                        (!_offer.allowedAlternatives || _offer.allowedAlternatives?.length === 0)) ||
                      this.journey.direction === "outward")
                  ) {
                    this.filteredOffers[`${_flexibility}-${_travelClass}`].sectionIds.push(section.id);
                    this.filteredOffers[`${_flexibility}-${_travelClass}`].offers.push(_offer);
                    this.filteredOffers[`${_flexibility}-${_travelClass}`].isOutOfPolicy &&= _offer.isOutOfPolicy;
                    this.filteredOffers[`${_flexibility}-${_travelClass}`].refundable &&= _offer.refundable;
                    this.filteredOffers[`${_flexibility}-${_travelClass}`].changeable &&= _offer.changeable;

                    if (this.filteredOffers[`${_flexibility}-${_travelClass}`].seatPreferenceOptions) {
                      this.filteredOffers[`${_flexibility}-${_travelClass}`].seatPreferenceOptions.push(
                        ..._offer.seatReservationOptions,
                      );
                    } else {
                      this.filteredOffers[`${_flexibility}-${_travelClass}`].seatPreferenceOptions =
                        _offer.seatReservationOptions;
                    }

                    if (this.filteredOffers[`${_flexibility}-${_travelClass}`].deliveryData) {
                      this.filteredOffers[`${_flexibility}-${_travelClass}`].deliveryData.push(..._offer.deliveryData);
                    } else {
                      this.filteredOffers[`${_flexibility}-${_travelClass}`].deliveryData = _offer.deliveryData;
                    }

                    if (_offer.discounts) {
                      if (this.filteredOffers[`${_flexibility}-${_travelClass}`].discounts) {
                        this.filteredOffers[`${_flexibility}-${_travelClass}`].discounts.push(..._offer.discounts);
                      } else {
                        this.filteredOffers[`${_flexibility}-${_travelClass}`].discounts = _offer.discounts;
                      }
                    }

                    this.filteredOffers[`${_flexibility}-${_travelClass}`].hasNegotiatedRate ||=
                      _offer.hasNegotiatedRate;
                    this.filteredOffers[`${_flexibility}-${_travelClass}`].subscriber ||= _offer.subscriber;
                  } else if (
                    (!this.filteredOffers[`${_flexibility}-${_travelClass}`] &&
                      this.selectedReturnFare &&
                      _offer.allowedAlternatives?.length > 0) ||
                    ((!_offer.allowedAlternatives || _offer.allowedAlternatives?.length === 0) &&
                      !this.selectedReturnFare) ||
                    this.journey.direction === "outward"
                  ) {
                    this.filteredOffers[`${_flexibility}-${_travelClass}`] = {
                      journey: this.journey,
                      sectionIds: [section.id],
                      offers: [_offer],
                      deliveryData: _offer.deliveryData,
                      travelClass: _travelClass,
                      flexibility: _flexibility,
                      seatPreferenceOptions: _offer.seatReservationOptions,
                      price: _offer.price,
                      fullPrice: _offer.fullPrice,
                      isOutOfPolicy: _offer.isOutOfPolicy,
                      changeable: _offer.changeable,
                      refundable: _offer.refundable,
                      allowedAlternatives: _offer.allowedAlternatives,
                      selectedOffer: 0,
                      hasNegotiatedRate: _offer.hasNegotiatedRate,
                      subscriber: _offer.subscriber,
                      discounts: _offer.discounts,
                    };
                    this.totalSections[`${_flexibility}-${_travelClass}`] =
                      this.filteredOffers[`${_flexibility}-${_travelClass}`].offers[0].price;
                  }
                }
              });
            },
          );
        },
      );
    });

    this.bestPriceOffer = TravelTrainJourneyComponent.findBestPriceProduct(this.filteredOffers);
  }
}
