import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "spt-basket-other-block",
  templateUrl: "./other-block.component.html",
  styleUrls: ["./other-block.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class OtherBlockComponent {
  private _item: any;
  @Input() set item(value: any) {
    this._item = value;
  }
  get item(): any {
    return this._item;
  }

  constructor() {}
}
