import { ApplicationRef, Injectable, Injector, NgZone } from "@angular/core";
import { Driver, NgForageConfig } from "ngforage";
import { SessionService, SessionType } from "../@shared/services/session.service";
import { forkJoin, of } from "rxjs";

import { CommonService } from "../@shared/services/common.service";
import { LoadingService } from "../@shared/services/loading.service";
import { PoliciesService } from "../@shared/services/policies.service";
import { RoleService } from "../@shared/services/roles.service";
import { Router } from "@angular/router";
import { SocietyService } from "../@shared/services/society.service";
import { StorageService } from "../@shared/services/storage.service";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "../@shared/services/user.service";
import { concatMap } from "rxjs/operators";

// import { WebsocketService } from '../@shared/services/websocket.service';

type AcceptedLanguage = "fr" | "en" | "es";
declare const Singleton: any;
declare const navigator: any;
declare const cordova: any;

@Injectable({
  providedIn: "root",
})
export class AppInitializerService {
  /**
   * https://stackoverflow.com/questions/39767019/app-initializer-raises-cannot-instantiate-cyclic-dependency-applicationref-w
   * Déclaration de fonctions injectant un module pour éviter une injection cyclique
   * Le lien ci-dessus indique des potentiels réponses à utiliser pour résoudre ce pb
   **/

  private get _router(): any {
    return this._injector.get(Router);
  }
  private get _zone(): any {
    return this._injector.get(NgZone);
  }
  private get _applicationRef(): any {
    return this._injector.get(ApplicationRef);
  }
  private get _commonService(): any {
    return this._injector.get(CommonService);
  }
  private get _ngfConfig(): any {
    return this._injector.get(NgForageConfig);
  }
  private get _storageService(): any {
    return this._injector.get(StorageService);
  }
  private get _translateService(): any {
    return this._injector.get(TranslateService);
  }

  private get _societyService(): any {
    return this._injector.get(SocietyService);
  }
  private get _userService(): any {
    return this._injector.get(UserService);
  }
  private get _roleService(): any {
    return this._injector.get(RoleService);
  }
  private get _sessionService(): any {
    return this._injector.get(SessionService);
  }
  private get _loadingService(): any {
    return this._injector.get(LoadingService);
  }
  private get _policiesService(): any {
    return this._injector.get(PoliciesService);
  }
  // // private get _websocketService(): any { return this._injector.get(WebsocketService); }

  private session: SessionType;

  constructor(private _injector: Injector) {}

  // split cette fonction pour la rendre propre (addEventListener ensemble, config ensemble etc)
  eventsListeners(): void {
    this._router.events.subscribe(() => {
      this._zone.run(() =>
        setTimeout(() => {
          this._applicationRef.tick();
        }, 0),
      );
    });
    document.addEventListener(
      "focusout",
      () => {
        this._commonService.enableIntercom();
        // document.getElementsByName('intercom-launcher-frame')[0].style.display = '';
      },
      false,
    );
    document.addEventListener(
      "focusin",
      () => {
        this._commonService.disableIntercom();
        // document.getElementsByName('intercom-launcher-frame')[0].style.display = 'none';
      },
      false,
    );

    document.addEventListener(
      "deviceready",
      () => {
        // document.querySelector('body').classList.add('cordova');
        console.log("deviceready");
        this._commonService.deviceReady = true;
        navigator.splashscreen.hide();
        window.open = cordova.InAppBrowser.open;
        this._commonService.appReady.next(true);

        (window as any).handleOpenURL = (url: string): void => {
          const match = url.match(/token=(?<token>.*)&profile=(?<profile>.*)/);
          if (match) {
            console.log("handleOpenURL.match");
            const params: { [key: string]: string } = match.groups;
            this._zone.run(() => {
              console.log("handleOpenURL.run");
              this._router.navigate(["/auth"], { queryParams: params });
            });
          } else {
            console.log("!handleOpenURL.match");
            const match = url.match(/baskets/);
            if (match) {
              console.log("yes");
              this._router.navigate(["/baskets"]);
            } else {
              this._zone.run(() => {
                console.log("!handleOpenURL.run", url);
                this._router.navigate(["/auth"]);
              });
            }
          }
        };
      },
      false,
    );
  }

  loadUtils(): Promise<any> {
    return new Promise<void>((resolve: any, reject: any): void => {
      this.eventsListeners();

      this._ngfConfig.configure({
        name: "Superfront",
        driver: [
          // defaults to indexedDB -> webSQL -> localStorage
          Driver.INDEXED_DB,
          Driver.WEB_SQL,
          Driver.LOCAL_STORAGE,
        ],
      });

      this._translateService.onLangChange.subscribe((change: any) => {
        if (change && change.lang) {
          Singleton.getInstance(change.lang);
          const opt: any = {
            language_override: change.lang,
          };

          if (window.location.origin !== "http://devfront.supertripper.com:4200") {
            (window as any).Intercom("update", opt);
          }
        }
      });

      this._storageService.getItem("session").subscribe((res: any) => {
        let language: string = res && res.defaultLanguage ? res.defaultLanguage : "";
        const availableLanguage: Array<string> = ["fr", "en", "es"];

        if (availableLanguage.indexOf(language) !== -1) {
          this.translateLanguage(res.defaultLanguage);
        } else {
          language = navigator.language.substring(0, 2).toLocaleLowerCase();
          if (availableLanguage.indexOf(language) !== -1) {
            this.translateLanguage(language as AcceptedLanguage);
          } else {
            this.translateLanguage("fr");
          }
        }
        resolve();
      });
    });
  }

  translateLanguage(language: "fr" | "en" | "es"): any {
    Singleton.getInstance(language);
    this._translateService.setDefaultLang(language);
  }

  // récupérer la session 'dans sessionService' , puis les infos  utilisateurs + société avec le localStorage
  loadSocietyAndUser(): any {
    return new Promise<void>((resolve: any, reject: any): any => {
      this._sessionService
        .load()
        .pipe(
          concatMap(() => {
            const session: any = this._sessionService.get();
            if (session) {
              const userId: string = session.userId;
              // this._websocketService.join(userId);
              const societyId: string = session.societyId;
              this._societyService.init();
              return forkJoin(
                this._userService.getUser(userId, true),
                this._societyService.getSociety(societyId, { popMember: true }, true),
              );
            } else {
              return of(null);
            }
          }),
        )
        .subscribe(
          () => resolve(),
          () => {
            this._sessionService.close();
            resolve();
          },
        );
    });
  }
}
