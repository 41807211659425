<div class="validate" *ngIf="localManagers && localManagers.length > 0">
  <p class="by">
    {{ "NOTIFICATIONS.TO_VALIDATE_BY" | translate }}
  </p>

  <p class="manager" *ngIf="localManagers && localManagers.length > 0">
    {{ localManagers[0].firstname }} {{ localManagers[0].lastname }}

    <sup class="board" *ngIf="localManagers.length > 1" [pTooltip]="managersUsernames">
      +{{ localManagers.length - 1 }}
    </sup>
  </p>
</div>
