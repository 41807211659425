import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Tag } from "../@types/tag";
import { BehaviorSubject } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class TagService {
  public societyTags: BehaviorSubject<Tag[]> = new BehaviorSubject<Tag[]>([]);
  constructor(private httpClient: HttpClient) {
    this.getTags().subscribe();
  }

  getTags(): any {
    return this.httpClient.get(`${environment.api}/tags`).pipe(tap((tags: Tag[]) => this.societyTags.next(tags)));
  }

  set(tags: string[], itemId: string) {
    const endpoint = `${environment.api}/items/${itemId}/tags`;
    return this.httpClient.patch(endpoint, { tags });
  }

  add(labelTag: string) {
    const endpoint = `${environment.api}/tags/`;
    return this.httpClient
      .post(endpoint, { tag: labelTag })
      .pipe(tap((tag: Tag) => this.societyTags.next([...this.societyTags.value, tag])));
  }

  updateTagBooking(tagsTextArray: Array<any>, id, society, type: string): Observable<any> {
    return this.httpClient.patch(`${environment.api}/tags/${id}`, {
      society,
      type,
    });
  }
}
