<ng-content select="header"></ng-content>
<button type="button" class="closeModalButton fas fa-times fa-2x m-r-auto" (mousedown)="closeSidebar()"></button>
<div class="ticket-detail">
  <div class="detail-header">
    <div class="title-container">
      <ng-container [ngSwitch]="item.type">
        <img *ngSwitchCase="'flight'" src="./assets/svg/flight-full-colored.svg" class="transport-icon" />
        <img *ngSwitchCase="'car'" src="./assets/svg/car-full-colored.svg" class="transport-icon" />
        <img *ngSwitchCase="'hotel'" src="./assets/svg/hotel-full-colored.svg" class="transport-icon" />
        <img *ngSwitchCase="'train'" src="./assets/svg/train-full-colored.svg" class="transport-icon" />
      </ng-container>
      <h2 class="title">
        {{ "GLOBAL.MYRESERVATION" | translate }}
        <span translate>{{ "BASKET.TYPES." + (item.type | uppercase) }}</span>
      </h2>
      <div>
        <div class="person-number">
          <img src="./assets/svg/profile.svg" />
          <p>x {{ item.travelers.length }}</p>
        </div>
        <p class="price">
          {{ item.price | SPTPrice: item.confirmationDate | async }}
        </p>
      </div>
    </div>
  </div>
  <div class="basic-info-container">
    <div class="item-number-resa">
      <ng-container *ngIf="reference"
        >{{ "GLOBAL.BOOKING_NUMBER" | translate }} <span>{{ reference }}</span></ng-container
      >
      <div *ngIf="item.type === 'car' && !reference">Acriss: {{ item.detail.acrissCode }}</div>
    </div>
    <div class="item-number-pnr">
      <ng-container *ngIf="pnr && user.isAdmin"
        >{{ "GLOBAL.AGENCY_NUMBER" | translate }} : <span>{{ pnr }}</span></ng-container
      >
    </div>
    <div *ngIf="item.type === 'car' && reference">Acriss: {{ item.detail.acrissCode }}</div>
    <div *ngIf="item.type === 'car' && item?.detail.vendorCode" class="logo-car">
      {{ "SEARCH.CAR.VENDOR" | translate }} {{ item?.formData?.agencyName }}
    </div>
  </div>

  <spt-itinerary
    [item]="item"
    [isSmallDevice]="isSmallDevice"
    [locale]="locale"
    (onDataHotelUpdated)="updateDataHotel($event)"
  ></spt-itinerary>
  <spt-conditions [item]="item" [dataHotel]="dataHotel"></spt-conditions>

  <div class="booker validator" *ngIf="this.item.statusHistory">
    <div>
      <p translate>BOOKING.BOOKER</p>
      <div class="profiles">
        <spt-avatar
          [user]="
            item.offline
              ? { username: item.metadata?.booker?.username }
              : booker?.user
                ? booker.user
                : { username: customerService }
          "
          [extraInfo]="booker?.date"
        ></spt-avatar>
      </div>
    </div>
    <div *ngIf="validator?.user">
      <p translate>BOOKING.VALIDATOR</p>
      <div class="profiles">
        <spt-avatar [user]="validator.user" [extraInfo]="validator?.date"> </spt-avatar>
      </div>
    </div>
  </div>
  <div class="booker travelers" [ngSwitch]="item.type">
    <div class="travelers" [ngClass]="showPriceBreakdown ? 'showPriceBreakdown' : ''">
      <div class="traveler-number">
        <p>
          {{ "SEARCH.TRAVELLERS" | translate }}
          <span>({{ item.travelers.length }})</span>
        </p>
        <i
          class="arrow"
          [class.spt-icon-chevron-down]="!showAllTravelers"
          [class.spt-icon-chevron-up]="showAllTravelers"
          (click)="toggleAllTravelers()"
          *ngIf="item.travelers.length > 1"
        ></i>
      </div>
      <p class="total">{{ "SEARCH.RESULT.TOTAL" | translate }}</p>
      <ng-container *ngIf="!showAllTravelers">
        <div class="profiles" *ngFor="let user of users | slice: 0 : 1">
          <spt-avatar [user]="user"></spt-avatar>
        </div>
      </ng-container>
      <ng-container *ngIf="showAllTravelers">
        <div class="profiles" *ngFor="let user of users">
          <spt-avatar [user]="user"></spt-avatar>
        </div>
      </ng-container>
      <div class="price">
        {{ item.price | SPTPrice: item.confirmationDate | async }}
      </div>
    </div>
  </div>
</div>
<ng-content select="footer"></ng-content>
