import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from "@angular/core";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { User } from "src/app/@shared/@types/user";
import { ActivatedRoute, Router } from "@angular/router";
import { Society } from "src/app/@shared/@types/society";
import { CommonService } from "src/app/@shared/services/common.service";
import { LoadingService } from "src/app/@shared/services/loading.service";
import { SearchEngineComponent } from "src/app/search/search-engine/search-engine.component";
import { Subject, takeUntil } from "rxjs";
import { FolderService, FolderStatus } from "../../@shared/services/folder.service";
import moment from "moment";
import { UtilsTypes } from "../../@shared/@types/utils";
import { ItemStatus } from "../../@shared/services/item.service";
import { ModalService } from "../../@shared/services/modal.service";
import { FlightService as SharedFlightService } from "src/app/@shared/services/flight.service";

interface MenuIcons {
  name: string;
  mobileName: string;
  iconName: string;
  type: "hotel" | "car" | "train" | "seminar" | "flight" | "transfer";
}
@Component({
  selector: "spt-home",
  styleUrls: ["./home.component.scss"],
  templateUrl: "./home.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild("bookTab", { static: false }) private bookTab: ElementRef;
  @ViewChild("bookingsTab", { static: false }) private bookingsTab: ElementRef;

  public user: User;
  public society: Society;
  public menuIcons: Array<MenuIcons> = [
    {
      iconName: "hotel",
      name: "DASHBOARD.HOTEL",
      mobileName: "GLOBAL.HOTEL",
      type: "hotel",
    },
    {
      iconName: "flight",
      name: "DASHBOARD.AIR",
      mobileName: "GLOBAL.FLIGHT",
      type: "flight",
    },
    {
      iconName: "train",
      name: "DASHBOARD.TRAIN",
      mobileName: "GLOBAL.TRAIN",
      type: "train",
    },
    {
      iconName: "car",
      mobileName: "GLOBAL.CAR",
      name: "DASHBOARD.CAR",
      type: "car",
    },
    {
      iconName: "transfer",
      mobileName: "GLOBAL.TRANSFER",
      name: "DASHBOARD.TRANSFER.BUTTON",
      type: "transfer",
    },
    {
      iconName: "seminar",
      mobileName: "GLOBAL.SEMINAR",
      name: "DASHBOARD.SEMINAR.TITLE",
      type: "seminar",
    },
  ];
  public type: "hotel" | "car" | "train" | "seminar" | "flight" | "transfer";
  public filteredItems: any[];
  public loading: boolean;
  public activeTab: number = 0;

  private dialogBaseConfig: DynamicDialogConfig = new DynamicDialogConfig();
  private ngUnsubscribe: Subject<void> = new Subject();

  constructor(
    private folderService: FolderService,
    private router: Router,
    private route: ActivatedRoute,
    public commonService: CommonService,
    private loadingService: LoadingService,
    private renderer2: Renderer2,
    private modalService: ModalService,
    private sharedFlightService: SharedFlightService,
  ) {}

  openModalService(type: "hotel" | "car" | "train" | "seminar" | "flight" | "transfer"): void {
    const config = {
      ...this.dialogBaseConfig,
      data: {
        ...this.dialogBaseConfig.data,
        type,
      },
    };
    this.modalService.openModal(SearchEngineComponent, config);
  }

  ngOnInit(): void {
    this.commonService.statusBarTextWhite();
    this.society = this.route.snapshot.data.society_and_members.society;
    this.user = this.route.snapshot.data.user;
    this.loadingService.loading.pipe(takeUntil(this.ngUnsubscribe)).subscribe((loading: boolean) => {
      this.loading = loading;
      if (loading === false) {
        this.folderService
          .list([FolderStatus.CONFIRMED], moment().format("YYYY-MM-DD"), moment().add(2, "month").format("YYYY-MM-DD"))
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((result: { folders: Array<any>; items: UtilsTypes.ObjectKey<Array<any>> }) => {
            this.filteredItems = Object.values(result.items)
              .flat()
              .filter((_item: any) => _item.status === ItemStatus.CONFIRMED)
              .sort(FolderService.sortFolderItems);
            this.filteredItems.forEach((item) => {
              let d;
              try {
                d = new Date(FolderService.getItemOriginTimestamp(item)).toISOString();
              } catch (e) {
                d = FolderService.getItemOriginTimestamp(item);
              }
            });

            // this.filteredItems
            //   .filter((item: any) => item.type === "flight" && item.provider === "afkl")
            //   .forEach((filteredItem: any) => {
            //     if (filteredItem) {
            //       const departureAirport = [];
            //       const arrivalAriports = [];
            //       filteredItem.detail.trips.forEach((trip: any) => {
            //         departureAirport.push(trip.Dep.IATALocationCode);
            //         arrivalAriports.push(trip.Arrival.IATALocationCode);
            //       });
            //       this.sharedFlightService
            //         .listAirports([...departureAirport, ...arrivalAriports])
            //         .subscribe((data) => (filteredItem.detail.airports = data));
            //     }
            //   });
          });
      }
    });

    this.dialogBaseConfig.height = this.commonService.isTablet ? `100vh` : "775px";
    this.dialogBaseConfig.width = this.commonService.isTablet ? "100vw" : "1100px";
    this.dialogBaseConfig.showHeader = false;
    this.dialogBaseConfig.dismissableMask = true;
    this.dialogBaseConfig.styleClass = "search-form-dialog";
    this.dialogBaseConfig.data = {
      user: this.route.snapshot.data.user,
      _members: this.route.snapshot.data.society_and_members
        ? this.route.snapshot.data.society_and_members.members
        : undefined,
      society: this.route.snapshot.data.society_and_members
        ? this.route.snapshot.data.society_and_members.society
        : undefined,
      role: this.route.snapshot.data.role,
    };
    
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  changeTab(index: number): void {
    this.activeTab = index;
    if (index === 0) {
      this.renderer2.addClass(this.bookTab.nativeElement, "active");
      this.renderer2.removeClass(this.bookingsTab.nativeElement, "active");
    } else {
      this.renderer2.addClass(this.bookingsTab.nativeElement, "active");
      this.renderer2.removeClass(this.bookTab.nativeElement, "active");
    }
  }
}
