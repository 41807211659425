import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CarbonOffsetTypes } from "../../../@shared/@types/carbon-offset";
import { TrainTypes } from "../train";
import { UserService } from "../../../@shared/services/user.service";
import { UtilsTypes } from "../../../@shared/@types/utils";

@Component({
  selector: "spt-travel-train-details",
  styleUrls: ["./details.component.scss"],
  templateUrl: "./details.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelTrainDetailsComponent implements OnInit, OnChanges {
  @Input() offersSelection: TrainTypes.OffersSelection;
  @Input() travelClass: TrainTypes.TravelClass;
  @Input() flexibility: TrainTypes.Flexibility | TrainTypes.ATOCFlexibility;
  @Input() direction: TrainTypes.SearchJourneyDirection;
  @Input() passengers: Array<TrainTypes.Passenger>;
  @Input() isSmallDevice: boolean;
  @Input() provider: TrainTypes.Provider;
  @Input() carbonOffset: CarbonOffsetTypes.CarbonOffsetEstimation;
  @Input() origin: string;
  @Input() destination: string;
  @Input() searchType: TrainTypes.SearchType;
  @Input() price: UtilsTypes.Price;
  @Input() fullPrice: UtilsTypes.Price;
  @Output() validated: EventEmitter<TrainTypes.OffersSelection> = new EventEmitter();
  @Output() closePanel: EventEmitter<boolean> = new EventEmitter();
  dateTime: string;
  selectedOfferIndex: number = 0;
  selectedFareIndex: number = 0;
  selectedPrice: UtilsTypes.Price;
  selectedFullPrice: UtilsTypes.Price;
  locale: string;
  details: Array<TrainTypes.Details> = [];
  user: any;
  childrenPassengers: Array<TrainTypes.Passenger>;
  selectedFare: TrainTypes.Fare;
  operators: Array<string>;
  hasCorporateDiscount: boolean;
  hasGroupSave: boolean;
  CORPORATE_FARES: string[] = ["RI05"];

  constructor(
    private translateService: TranslateService,
    private userService: UserService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  getPrices(): void {
    this.selectedPrice = {
      amount: 0,
      currency: this.offersSelection.offers[this.selectedOfferIndex].price.currency,
    };
    this.selectedFullPrice = {
      amount: 0,
      currency: this.offersSelection.offers[this.selectedOfferIndex].fullPrice.currency,
    };
    if (this.provider === "trainline") {
      const offer: TrainTypes.Offer = this.offersSelection.offers[this.selectedOfferIndex];
      this.selectedPrice.amount += offer.price.amount;
      this.selectedFullPrice.amount += offer.fullPrice.amount;
    } else {
      this.offersSelection.offers[this.selectedOfferIndex].fares.forEach((_fare: TrainTypes.Fare): void => {
        this.selectedPrice.amount += _fare.price.amount * _fare.passengers.length;
        if (_fare.fullPrice) {
          this.selectedFullPrice.amount += _fare.fullPrice.amount * _fare.passengers.length;
        }
      });
    }
    this.hasCorporateDiscount = this.offersSelection.offers[this.selectedOfferIndex].fares.some(
      (fare: TrainTypes.Fare) => {
        return this.CORPORATE_FARES.includes(fare.fareCode);
      },
    );
    this.hasGroupSave =
      this.offersSelection.offers[this.selectedOfferIndex].fares[this.selectedFareIndex].appliedDiscounts?.some(
        (appliedDiscount: TrainTypes.Discount): boolean => appliedDiscount.name.includes("Group"),
      ) ?? false;
  }

  selectPrices(offerIndex: number): void {
    this.selectedOfferIndex = offerIndex;
    this.getPrices();
  }

  ngOnInit(): void {
    this.locale = this.translateService.currentLang;
    this.dateTime = `${this.offersSelection.journey.segments[0].departure.date.date}T${this.offersSelection.journey.segments[0].departure.date.time}`;
    this.childrenPassengers = [...this.passengers].filter((_passenger: TrainTypes.Passenger): boolean => {
      return ["INFANT", "CHILD"].includes(_passenger.passengerCode);
    });
    this.user = this.userService.user.value;
    this.operators = Array.from(
      new Set(
        this.offersSelection.journey.segments
          .filter(
            (_segment: TrainTypes.Segment) =>
              _segment.transport.carrier && _segment.transport.carrier !== "Unknown Operator",
          )
          .map((_segment: TrainTypes.Segment) => _segment.transport.carrier),
      ),
    );
    this.getPrices();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.products && !changes.products.firstChange && changes.products.currentValue) {
      this.selectedOfferIndex = 0;
      this.selectedFareIndex = 0;
      this.getPrices();
    }
  }

  trackByName(index: number, item: TrainTypes.Fare): string {
    return item.fareCode;
  }

  selectOffer(): void {
    this.offersSelection.selectedOffer = this.selectedOfferIndex;
    this.validated.emit(this.offersSelection);
  }

  close(): void {
    this.closePanel.emit();
  }

  selectFare(fare: TrainTypes.Fare): void {
    this.selectedFare = fare;
    this.changeDetector.detectChanges();
  }
}
