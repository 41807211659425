import { Directive, Output, EventEmitter, Input, OnChanges, NgZone, SimpleChanges } from "@angular/core";
import { environment } from "../../../environments/environment";

@Directive({
  selector: "[googlePlace]",
})
export class GooglePlacesDirective implements OnChanges {
  @Input() placeId: string;
  @Input() address: string;
  @Input() isFavorite: boolean;
  @Output() selectPlace: EventEmitter<google.maps.GeocoderResult> = new EventEmitter();

  constructor(private ngZone: NgZone) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.placeId && !changes.placeId.firstChange) ||
      (environment.activateGeocoder &&
        changes.address &&
        !changes.address.firstChange &&
        (changes.address.previousValue === "" || changes.isFavorite?.currentValue))
    ) {
      this.getDetails(!!changes.placeId);
    }
  }

  private getDetails(force: boolean): void {
    const request: google.maps.GeocoderRequest = {};

    if (this.address && !force) {
      request.address = this.address;
    } else {
      request.placeId = this.placeId;
    }

    new google.maps.Geocoder().geocode(
      request,
      (res: Array<google.maps.GeocoderResult>, status: google.maps.GeocoderStatus) => {
        this.ngZone.run(() => {
          if (status === google.maps.GeocoderStatus.OK && res && res[0]) {
            this.selectPlace.emit(res[0]);
          } else {
            console.error(status);
          }
        });
      },
    );
  }
}
