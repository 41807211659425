import { HttpClient, HttpContext, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";
import { TrainTypes } from "./train";
import { map, retry } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { UtilsTypes } from "src/app/@shared/@types/utils";
import { DateTime } from "luxon";
import { NGX_LOADING_BAR_IGNORED } from "@ngx-loading-bar/http-client";

export enum Reason {
  CUSTOMER_REQUESTED = "CUSTOMER_REQUESTED",
  EQUIPMENT_FAILURE = "EQUIPMENT_FAILURE",
  STRIKE = "STRIKE",
}

export enum Flexibility {
  nonflexi,
  semiflexi,
  flexi,
}

export enum TravelClass {
  SECOND_CLASS,
  FIRST_CLASS,
}

@Injectable({
  providedIn: "root",
})
export class TrainService {
  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
  ) {}

  searchStations(input: string): Observable<Array<TrainTypes.Station>> {
    return this.httpClient.get<Array<TrainTypes.Station>>(`${environment.api}/trains/stations/?input=${input}`, {
      context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
      headers: {
        ignoreErrorMessage: "true",
        ignoreLoading: "true",
        "Accept-Language": this.translateService.currentLang,
      },
    });
  }

  getCardTypes(type: "fidelity" | "subscription"): Observable<Array<TrainTypes.CardType>> {
    return this.httpClient.get<Array<TrainTypes.CardType>>(`${environment.api}/trains/cardtypes`, {
      params: {
        type,
      },
      headers: {
        "Accept-Language": this.translateService.currentLang,
        "Content-Type": "application/json",
      },
    });
  }

  getSearchResults(id: string): Observable<TrainTypes.SearchResult> {
    return this.httpClient
      .get(`${environment.api}/search/${id}/results`, {
        headers: {
          ignoreErrorMessage: "true",
          "Accept-Language": this.translateService.currentLang,
          "Content-Type": "application/json",
        },
      })
      .pipe(map((data: { result: TrainTypes.SearchResult }) => data.result));
  }

  getInwardSearchResults(
    sptSearchId: string,
    selectedOfferId: string,
    selectedAlternativeIds: Array<string>,
    provider: TrainTypes.Provider,
  ): Observable<TrainTypes.SearchResult> {
    return this.httpClient.post<TrainTypes.SearchResult>(
      `${environment.api}/trains/search/${sptSearchId}/inward`,
      {
        selectedOfferId,
        selectedAlternativeIds,
        provider,
      },
      {
        headers: {
          ignoreErrorMessage: "true",
          "Accept-Language": this.translateService.currentLang,
          "Content-Type": "application/json",
        },
      },
    );
  }

  getMoreResults(
    sptSearchId: string,
    searchPageDirection: "Previous" | "Next",
    searchJourneyDirection: TrainTypes.SearchJourneyDirection,
    journeySearchType: TrainTypes.JourneySearchType,
    searchId: string,
    searchType: TrainTypes.SearchType,
    provider: TrainTypes.Provider,
    dateTime: string,
    offerId: string,
  ): Observable<TrainTypes.SearchResult> {
    let params: HttpParams = new HttpParams({
      fromObject: {
        searchPageDirection,
        searchJourneyDirection,
        journeySearchType,
        searchType,
        provider,
      },
    });

    if (searchId) {
      params = params.set("searchId", searchId);
    }

    if (dateTime) {
      params = params.set("dateTime", dateTime);
    }

    if (offerId) {
      params = params.set("offerId", offerId);
    }

    return this.httpClient
      .get(`${environment.api}/trains/search/${sptSearchId}`, {
        headers: {
          ignoreErrorMessage: "true",
          "Accept-Language": this.translateService.currentLang,
          "Content-Type": "application/json",
        },
        params,
      })
      .pipe(map((data: TrainTypes.SearchResult) => data));
  }

  createItinerary(itineraryRequest: TrainTypes.ItineraryRequest): Observable<TrainTypes.Itinerary> {
    return this.httpClient.post<TrainTypes.Itinerary>(`${environment.api}/trains/itineraries`, itineraryRequest, {
      headers: {
        "Accept-Language": this.translateService.currentLang,
        "Content-Type": "application/json",
      },
    });
  }

  updatePassengerDetails(
    itineraryId: string,
    passengerId: string,
    passengerDetails: Partial<TrainTypes.Passenger>,
  ): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      ignoreErrorMessage: "true",
    });
    return this.httpClient
      .put(`${environment.api}/trains/itineraries/${itineraryId}/passengers/${passengerId}`, passengerDetails, {
        headers,
      })
      .pipe(retry(1));
  }

  setLeadPassenger(itineraryId: string, passengerId: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      ignoreErrorMessage: "true",
    });

    return this.httpClient
      .put(
        `${environment.api}/trains/itineraries/${itineraryId}/passengers/${passengerId}/leadPassenger`,
        {},
        {
          headers,
        },
      )
      .pipe(retry(1));
  }

  setSeatPreferences(
    itineraryId: string,
    seatPreferencesRequest: TrainTypes.SetSeatPreferencesRequest,
    searchType: TrainTypes.SearchType,
  ): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.api}/trains/itineraries/${itineraryId}/seatpreferences`,
      {
        seatPreferencesRequest,
        searchType,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  }

  getChangeables(orderId: string, provider: TrainTypes.Provider): Observable<Array<Array<TrainTypes.Option>>> {
    return this.httpClient.get<Array<Array<TrainTypes.Option>>>(
      `${environment.api}/trains/orders/${orderId}/changeables`,
      {
        headers: {
          "Accept-Language": this.translateService.currentLang,
          "Content-Type": "application/json",
        },
        params: {
          provider,
        },
      },
    );
  }

  getRefundables(orderId: string, provider: TrainTypes.Provider): Observable<Array<Array<TrainTypes.Option>>> {
    return this.httpClient.get<Array<Array<TrainTypes.Option>>>(
      `${environment.api}/trains/orders/${orderId}/refundables`,
      {
        headers: {
          "Accept-Language": this.translateService.currentLang,
          "Content-Type": "application/json",
        },
        params: {
          provider,
        },
      },
    );
  }

  estimateChange(
    orderId: string,
    itineraryChange: TrainTypes.ItineraryChange,
    provider: TrainTypes.Provider,
  ): Observable<TrainTypes.ItineraryChange> {
    return this.httpClient.post<TrainTypes.ItineraryChange>(
      `${environment.api}/trains/orders/${orderId}/exchangeEstimate`,
      itineraryChange,
      {
        headers: {
          "Accept-Language": this.translateService.currentLang,
          "Content-Type": "application/json",
        },
        params: {
          provider,
        },
      },
    );
  }

  createItineraryChange(
    orderId: string,
    itineraryRequest: TrainTypes.ItineraryChangeRequest,
  ): Observable<TrainTypes.Itinerary> {
    return this.httpClient.post<TrainTypes.Itinerary>(
      `${environment.api}/trains/orders/${orderId}/itinerarychange`,
      itineraryRequest,
      {
        headers: {
          "Accept-Language": this.translateService.currentLang,
          "Content-Type": "application/json",
        },
      },
    );
  }

  synchronizePNR(orderId: string): Observable<void> {
    return this.httpClient.get<void>(`${environment.api}/trains/orders/${orderId}/synchronize`);
  }

  getFareType(id: string): Observable<TrainTypes.Fare> {
    return this.httpClient.get<TrainTypes.Fare>(`${environment.api}/trains/faretypes/${id}`, {
      headers: {
        "Accept-Language": this.translateService.currentLang,
      },
    });
  }

  readRailCardData(number: string, dateOfBirth: string): Observable<TrainTypes.RailCard> {
    return this.httpClient.get<TrainTypes.RailCard>(`${environment.api}/trains/railcards/${number}`, {
      params: {
        dateOfBirth,
      },
      headers: {
        "Accept-Language": this.translateService.currentLang,
      },
    });
  }

  getSeatMap(seatMapId: string): Observable<TrainTypes.SeatMap> {
    if (seatMapId === undefined) {
      return of<TrainTypes.SeatMap>(null);
    }
    return this.httpClient.get<TrainTypes.SeatMap>(`${environment.api}/trains/seatMaps/${seatMapId}`, {
      headers: {
        "Accept-Language": this.translateService.currentLang,
      },
    });
  }

  public mapJourneys(journeys: TrainTypes.Journey[]): any {
    const journeysByDirection = {
      outward: [],
      inward: [],
    };
    const schedules = {
      outward: [],
      inward: [],
    };
    let filteredJourneys;
    if (journeys.length > 2) {
      const journeysWithIds = journeys.map((j, index) => {
        // Générer un id si l'objet n'a pas d'id
        if (!j.id) {
          j.id = `journey_${index}`;
        }
        return j;
      });

      filteredJourneys = Array.from(new Map(journeysWithIds.map((j) => [j.id, j])).values());
    } else {
      filteredJourneys = journeys;
    }

    for (let journey of filteredJourneys) {
      if (journey.direction === "outward") {
        journeysByDirection.outward.push(journey);
        schedules.outward.push(...journey.segments);
      } else {
        journeysByDirection.inward.push(journey);
        schedules.inward.push(...journey.segments);
      }
    }

    const mappedJourneys = {};
    if (journeysByDirection.outward.length > 0) {
      mappedJourneys["outward"] = this.extractJourneyInfo(journeysByDirection.outward);
    }

    if (journeysByDirection.inward.length > 0) {
      mappedJourneys["inward"] = this.extractJourneyInfo(journeysByDirection.inward);
    }

    return { mappedJourneys: Object.values(mappedJourneys), schedules };
  }

  private extractJourneyInfo(journeys) {
    return journeys.reduce((resumeJourney, journey, index) => {
      if (index === 0) {
        return {
          ...journey,
        };
      } else {
        if (DateTime.fromISO(journey.departure.date.utc) < DateTime.fromISO(resumeJourney.departure.date.utc)) {
          resumeJourney.departure = journey.departure;
        }

        if (DateTime.fromISO(journey.arrival.date.utc) > DateTime.fromISO(resumeJourney.arrival.date.utc)) {
          resumeJourney.arrival = journey.arrival;
        }
        return {
          ...resumeJourney,
          segments: [...resumeJourney.segments, ...journey.segments],
        };
      }
    }, {});
  }
}
