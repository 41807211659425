import { FlightService } from "./../flight.service";
import { Component, Input, ViewEncapsulation, Output, EventEmitter } from "@angular/core";
@Component({
  selector: "spt-travel-flight-preview",
  styleUrls: ["./travel-flight-preview.component.scss"],
  templateUrl: "./travel-flight-preview.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class TravelFlightPreviewComponent {
  @Input() itinerary: any;
  @Input() step: number;
  @Input() displayWayType: boolean = true;
  @Output() private changeLastLeg: EventEmitter<null> = new EventEmitter();

  constructor(public flightService: FlightService) {}

  change(): void {
    this.changeLastLeg.emit();
  }
}
