<div class="cancel-section">
  <i *ngIf="isSmallDevice" class="mobile-close-modify spt-icon-back-arrow" (click)="returnToDetails.emit()"></i>
  <div *ngIf="!isSmallDevice" class="close-modify-container" (click)="returnToDetails.emit()">
    <i class="spt-icon-back-arrow"></i>
    <span class="return">{{ "GLOBAL.RETURN" | translate }}</span>
  </div>

  <h2 class="modify-title">{{ "BOOKING.CANCELLATION.TITLE" | translate }}</h2>

  <p-messages [(value)]="warningMessages" *ngIf="warningMessages.length > 0" [closable]="false"></p-messages>

  <div class="cancellation-content" [ngSwitch]="item.type">
    <ng-container *ngSwitchCase="'train'">
      <spt-reservations-cancel-train
        [orderId]="item.detail.id"
        [orderItems]="item.detail.orderItems"
        [relatedOrderItems]="item.detail.relatedOrderItems"
        [travelers]="item.travelers"
        [provider]="item.provider"
        [cancellation]="cancellation"
        (selectCancel)="onSelected($event)"
      ></spt-reservations-cancel-train>
    </ng-container>
    <ng-container *ngSwitchCase="'flight'">
      <spt-reservations-cancel-flight
        [orderId]="item.detail.id"
        [orderItems]="item.detail.orderItems"
        [item]="item"
        [relatedOrderItems]="item.detail.relatedOrderItems"
        [travelers]="item.travelers"
        [provider]="item.provider"
        [cancellation]="cancellation"
        (selectCancel)="onSelected($event)"
      ></spt-reservations-cancel-flight>
    </ng-container>
    <ng-container *ngSwitchCase="'transfer'">
      <spt-reservations-cancel-transfer
        [booking]="item.detail.booking"
        [travelers]="item.travelers"
        [provider]="item.provider"
        (selectCancel)="onSelected($event)"
      ></spt-reservations-cancel-transfer>
    </ng-container>
  </div>

  <div class="m-b-30 m-t-30" *ngIf="contactCustomerService">
    {{ "BOOKING.CANCELLATION.CONTACT_CUSTOMER_SERVICE" | translate }}
  </div>
  <ng-container *ngIf="item.type === 'flight'; else trainCancel">
    <p-button
      *ngIf="!contactCustomerService && !cancellation && optionsRequired"
      class="w-full"
      styleClass="change-button"
      (onClick)="getCancellation()"
      [label]="'BOOKING.CANCELLATION.TITLE' | translate"
    >
    </p-button>
  </ng-container>
  <ng-template #trainCancel>
    <p-button
      *ngIf="!contactCustomerService && !cancellation && optionsRequired"
      class="w-full"
      styleClass="change-button"
      (onClick)="getCancellation()"
      [disabled]="!cancelCriteria || cancelCriteria.options?.length === 0"
      [label]="'BOOKING.CANCELLATION.TITLE' | translate"
    >
    </p-button>
  </ng-template>

  <div class="confirm-refund-container m-t-30" *ngIf="cancellation">
    <p
      class="amount-refund-text text-center"
      *ngIf="cancellation && (!['train'].includes(item.type) || item.offline === true)"
    >
      {{
        "BOOKING.CANCELLATION.DEFAULT_DESCRIPTION"
          | translate
            : {
                amount: cancellation.price | SPTPrice: item.confirmationDate | async
              }
      }}
    </p>
    <div *ngIf="item.type === 'train'">
      <p class="amount-refund-text text-center">
        {{
          "BOOKING.CANCELLATION.DEFAULT_DESCRIPTION"
            | translate
              : {
                  amount: cancellation.data.refundPrice | SPTPrice: item.confirmationDate | async
                }
        }}
      </p>
      <ng-container *ngIf="cancellation.data.charge.amount !== 0">
        <p class="separator"></p>
        <p class="flex justify-between">
          <span translate>BOOKING.CANCELLATION.ORIGINAL_PRICE</span>
          <span>
            {{ cancellation.data.originalPrice | SPTPrice: item.confirmationDate | async }}
          </span>
        </p>
        <p class="flex justify-between">
          <span translate>BOOKING.CANCELLATION.CHARGE</span>
          <span>
            {{ cancellation.data.charge | SPTPrice: item.confirmationDate | async }}
          </span>
        </p>
      </ng-container>
    </div>
    <p *ngIf="item.type === 'flight'" translate>BOOKING.CANCELLATION.FLIGHT_DESCRIPTION</p>
    <div class="buttons-container">
      <p-button
        styleClass="medium p-button-secondary"
        (onClick)="cancel()"
        [label]="'GLOBAL.CANCEL' | translate"
      ></p-button>
      <p-button
        styleClass="medium"
        (onClick)="validateCancel(cancellation.data?.id)"
        [label]="'GLOBAL.VALIDATE' | translate"
      >
      </p-button>
    </div>
  </div>
</div>
