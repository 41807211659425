<div class="breadcrumb">
  <ul>
    <li class="search active">
      <span><span class="spt-icon-search"></span></span>
      <ng-container *ngIf="(commonService.isTabletObservable | async) === false">
        {{ "SEARCH.FLIGHT.BREADCRUMB.SEARCH" | translate }}
      </ng-container>
    </li>
    <li class="departure active" *ngIf="numberOfSteps <= 2 && searchType !== 'multi'">
      <span><span class="spt-icon-flight-departure"></span></span>
      <ng-container *ngIf="(commonService.isTabletObservable | async) === false">
        {{ "SEARCH.FLIGHT.BREADCRUMB.OUTWARD" | translate }}
      </ng-container>
    </li>
    <ng-container *ngIf="this.searchType === 'multi'">
      <li
        class="departure active"
        [class.active]="step <= activeStep || active === 'validation'"
        *ngFor="let step of getNumberArray(numberOfSteps)"
      >
        <span><span class="spt-icon-flight-departure"></span></span>
        <ng-container *ngIf="(commonService.isTabletObservable | async) === false">
          {{ "GLOBAL.FLIGHT" | uppercase | translate }} {{ step + 1 }}
        </ng-container>
      </li>
    </ng-container>

    <li
      class="return"
      [class.active]="active === 'return' || active === 'validation'"
      *ngIf="return && numberOfSteps <= 2"
    >
      <span><span class="spt-icon-flight-arrival"></span></span>{{ "SEARCH.FLIGHT.BREADCRUMB.INWARD" | translate }}
    </li>
    <li class="validation" [class.active]="active === 'validation'">
      <!-- TODO modifier image sur la page validation pour l'active -->
      <span>
        <ng-container *ngIf="active !== 'validation'; else validation"
          ><img src="./../../../assets/svg/check-violet.svg"
        /></ng-container>
        <ng-template #validation>
          <img src="./../../../assets/svg/check-white.svg" />
        </ng-template>
      </span>
      <ng-container *ngIf="(commonService.isTabletObservable | async) === false">
        {{ "SEARCH.FLIGHT.BREADCRUMB.VALIDATION" | translate }}
      </ng-container>
    </li>
  </ul>
</div>
