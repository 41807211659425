import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FlightService } from "src/app/travel/flight/flight.service";
import _companies from "../../../../@shared/companies";

@Component({
  selector: "app-flight-detail",
  templateUrl: "./flight-detail.component.html",
  styleUrls: ["./flight-detail.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FlightDetailComponent implements OnInit {
  @Input() item: any;
  public itineraries: any[];
  public viewOnly = true;
  public companies: any = _companies;

  constructor(private flightService: FlightService) {}

  ngOnInit(): void {
    this.itineraries = this.item.detail.trips;
    this.flightService.airports = this.item.detail.airports;
  }
}
