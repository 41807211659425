import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { Subscription } from "rxjs";
import { checkLocalityIsValid, Locality, SearchEngineService, SearchEngineType } from "../../../search-engine-service";
import { CommonService } from "src/app/@shared/services/common.service";
import { Address } from "src/app/@shared/@types/models";
import { TransferTypes } from "src/app/travel/transfer/transfer";
import { SuggestPanelService } from "../../suggest-panel.service";
import { PlaceFinderComponent } from "src/app/@shared/place-finder/place-finder.component";

const MAX_NB_RESULTS = 10;

@Component({
  selector: "stp-locality-autocomplete",
  templateUrl: "./locality-autocomplete.component.html",
  styleUrls: ["./locality-autocomplete.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LocalityAutocompleteComponent<LOCALITY_TYPE extends Locality | Address | TransferTypes.Place>
  implements OnInit, OnChanges, OnDestroy
{
  // Nécessaire pour pouvoir définir les liens entre <label for> et les <input>
  id = "LocalityAutocompleteComponent-" + Math.random().toString(36).substring(2);

  @ViewChild("template", { static: true })
  template!: TemplateRef<any>;

  @ViewChild("input", { static: false })
  input: ElementRef;

  @ViewChild("placeFinderTemplate", { static: true })
  placeFinderTemplate!: TemplateRef<any>;

  @ViewChild("placeFinderComponent", { static: false })
  placeFinderComponent: PlaceFinderComponent;

  @Input() restrictions?: LocalityRestrictions;
  @Input() suggestions?: any[];
  @Input() icon?: string;
  @Input() className!: string;
  @Input() gridArea!: string;
  @Input() suggestPanelName!: string;
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() suggestPanelTitle?: string;

  @Input() address?: Address;
  @Input() radius?: number;
  @Input() from?: string;

  @Input() disabled: boolean = false;
  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() onChange: EventEmitter<LocalityChangeEvent<LOCALITY_TYPE>> = new EventEmitter<
    LocalityChangeEvent<LOCALITY_TYPE>
  >();
  @Output() onFocus: EventEmitter<void> = new EventEmitter<void>();
  @Input() showSuggestInPanel: boolean = true;
  @Input() reverse: boolean = false;
  @Output() onReverse = new EventEmitter<void>();
  @Input() displayInFavorites: boolean = false;

  // Nombre minimum de caractères à renseigner avant de déclencher une recherche
  minimumCharactersToType!: number;
  @Input() initialLocality!: LOCALITY_TYPE;
  searchString: string = "";
  searchResults: LOCALITY_TYPE[] | null = null;
  valid: boolean = false;
  private searchSubscription?: Subscription;

  /**
   * Pour la recherche de gare/aéroport, on limite à MAX_NB_RESULTS résultats. En cas d'un nombre de résultat plus important, hasMoreResults est à true.
   */
  hasMoreResults: boolean = false;

  @Input() searchService!: SearchEngineService<LOCALITY_TYPE, any>;

  searchEngineType: SearchEngineType;

  constructor(
    private viewContainerRef: ViewContainerRef,
    protected commonService: CommonService,
    protected suggestPanelService: SuggestPanelService,
  ) {}

  private needPlaceFinder(): boolean {
    return (
      this.searchEngineType === "hotel" ||
      this.searchEngineType === "transfer" ||
      this.searchEngineType === "car" ||
      this.searchEngineType === "seminar"
    );
  }

  ngOnInit(): void {
    this.searchEngineType = this.searchService.getType();
    const template = this.needPlaceFinder() ? this.placeFinderTemplate : this.template;

    // Permet de ne pas avoir le tag <stp-locality-autocomplete/> présent dans le DOM
    // (cf.https://stackoverflow.com/questions/38716105/angular2-render-a-component-without-its-wrapping-tag#answer-56887630)
    // Indispensable pour pouvoir utiliser les 'grid-template-areas'
    this.viewContainerRef.createEmbeddedView(template);
    this.viewContainerRef.element.nativeElement.remove();

    this.minimumCharactersToType = this.searchService.getMinimumNumberOfCharactersForLocalitySearch();
    if (this.initialLocality) {
      this.setLocality(this.initialLocality, "ngOnInit");
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["initialLocality"]?.firstChange === false && changes["initialLocality"]?.currentValue) {
      this.setLocality(changes["initialLocality"].currentValue, "ngOnChanges");
      if (!changes["initialLocality"].currentValue.label) {
        this.address = {
          street: undefined,
          city: undefined,
          label: "",
          postal_code: undefined,
          country: undefined,
          countryCode: undefined,
        };
        if (this.placeFinderComponent) {
          this.placeFinderComponent.selectedAddress.description = "";
        }
      }
    }
  }

  focusOnInput() {
    this.suggestPanelService.cancelReset();
    if (this.input?.nativeElement) {
      this.input.nativeElement.focus();
    } else if (this.placeFinderComponent) {
      this.placeFinderComponent.focusInput();
    }
  }

  onTypingInSearchInput(event: Event): void {
    this.searchSubscription?.unsubscribe();
    const target = event.target as HTMLInputElement;
    const value = target.value;
    this.setLocality(this.searchService.createDummyLocalityFromName(value));
    this.searchResults = null;
    if (value && value.trim().length >= this.minimumCharactersToType) {
      this.searchSubscription = this.searchService.localitySearch(value).subscribe((result) => {
        this.searchResults = result.slice(0, MAX_NB_RESULTS);
        this.hasMoreResults = result.length > this.searchResults.length;
      });
    }
    if (!value || value.trim().length === 0) {
      this.hideSuggest();
    } else {
      this.showSuggest();
    }
  }

  selectLocalityProposal(locality: LOCALITY_TYPE): void {
    // On supprime les résultats à la sélection d'un item pour masquer la liste
    // (utile sur mobile ou sur desktop dans les favoris pour masquer la liste déroulante)
    this.searchResults = null;
    this.showSuggestInPanel && this.suggestPanelService.reset();
    this.setLocality(locality);
  }

  setLocality(locality: LOCALITY_TYPE, from?: string): void {
    if (this.needPlaceFinder()) {
      this.address = this.searchService.createLocalityFromJson(locality) as Address;
    } else {
      this.searchString = locality["label"] || locality["name"];
    }
    this.valid = checkLocalityIsValid(locality);
    this.onChange.emit({
      locality: locality,
      from: from || "setLocality",
    });
  }

  formatted(searchResult: Address): void {
    this.setLocality(searchResult as LOCALITY_TYPE, "formatted");
  }

  selectTransferType(searchResult: Address): void {
    this.setLocality(searchResult as LOCALITY_TYPE, "selectTransferType");
  }

  showSuggest(): void {
    this.onSearch.emit(this.suggestPanelName);
  }

  hideSuggest(): void {
    this.searchResults = null;
    this.showSuggestInPanel && this.suggestPanelService.reset();
  }

  onPlaceFinderInput(value: any) {
    const locality: any = {
      label: value,
      street: "",
      city: "",
      postal_code: "",
      country: "",
      countryCode: "",
    };
    this.setLocality(locality, "onInput");
  }

  ngOnDestroy(): void {
    this.searchSubscription?.unsubscribe();
  }

  reverseData(): void {
    this.onReverse.emit();
  }
}

export interface LocalityRestrictions {
  country: string;
}

export interface LocalityChangeEvent<LOCALITY_TYPE extends Locality | Address | TransferTypes.Place> {
  locality: LOCALITY_TYPE;
  from: string;
}
