import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { RequestCar, ItemCar } from "../../../../../../@shared/@types/models";

@Component({
  selector: "spt-request-rental-car",
  templateUrl: "./rental-car.component.html",
  styleUrls: ["../request.component.scss", "./rental-car.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RequestRentalCarComponent implements OnInit {
  @Input() request: RequestCar;
  item: ItemCar;

  ngOnInit(): void {
    this.item = this.request.item as ItemCar;
  }
}
