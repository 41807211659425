<div class="oad">
  <div class="origin">
    <p class="time">
      {{ departure.hour | dateParser }}
    </p>
    <p class="station">
      {{ departure.local | titlecase }}
    </p>
  </div>
  <i class="arrow spt-icon-arrow-circle-right txt-12"></i>
  <div class="destination">
    <p class="time">
      {{ arrival.hour | dateParser }}
    </p>
    <p class="station">
      {{ arrival.local | titlecase }}
    </p>
  </div>
</div>
<div class="options">
  <div class="type">
    <span class="underline-dashed">
      {{
        refundMod.refund
          | uppercase
          | translate
            : {
                price:
                  {
                    amount: selectedOutward.refundFees[0],
                    currency: selectedOutward.currency
                  }
                  | SPTPrice
                  | async
              }
      }}
    </span>
  </div>
  <div class="class">
    <span class="underline-dashed">
      {{
        refundMod.changeable
          | uppercase
          | translate
            : {
                price:
                  {
                    amount: selectedOutward.exchangeFees[0],
                    currency: selectedOutward.currency
                  }
                  | SPTPrice
                  | async
              }
      }}
    </span>
  </div>
  <p class="update">
    <p-button
      [label]="'SEARCH.RESULT.AIR.MODIFICATION.' + types | uppercase | translate: { index: step }"
      *ngIf="isSmallDevice"
      class="update-btn"
      (onClick)="onSearch()"
    ></p-button>
  </p>
</div>
