import { Component, Input, OnChanges, SimpleChange, ViewEncapsulation } from "@angular/core";
import { UtilsTypes } from "../../@types/utils";

@Component({
  selector: "app-password-strength-bar",
  templateUrl: "./password-strength-bar.component.html",
  styleUrls: ["./password-strength-bar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PasswordStrengthBarComponent implements OnChanges {
  @Input() passwordToCheck: string;
  barLabel: string;
  bar0: string;
  bar1: string;
  bar2: string;
  bar3: string;
  bar4: string;

  public requirements = {
    upperCase: false,
    lowerCase: false,
    digit: false,
    min8digits: false,
    specialCharacter: false,
  };
  public passwordValid: boolean = false;
  public showIndications: boolean = false;

  private colors: Array<string> = ["#F00", "#F90", "#FF0", "#9F0", "#0F0"];

  private static measureStrength(pass: string): number {
    let score: number = 0;
    // award every unique letter until 5 repetitions
    const letters: UtilsTypes.ObjectKey<number> = {};

    for (let i: number = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    const variations: UtilsTypes.ObjectKey<boolean> = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W_/.test(pass),
    };

    let variationCount: number = 0;
    for (const check in variations) {
      variationCount += variations[check] ? 1 : 0;
    }
    score += (variationCount - 1) * 10;
    return Math.trunc(score);
  }

  private getColor(score: number): { idx: number; col: string } {
    let idx: number = 0;
    this.barLabel = "PASSWORD.STRENGTH.INSUFFICIENT";

    if (score > 90) {
      idx = 4;
      this.barLabel = "PASSWORD.STRENGTH.STRONG";
    } else if (score > 70) {
      idx = 3;
      this.barLabel = "PASSWORD.STRENGTH.GOOD";
    } else if (score >= 40) {
      idx = 2;
      this.barLabel = "PASSWORD.STRENGTH.AVERAGE";
    } else if (score >= 20) {
      idx = 1;
      this.barLabel = "PASSWORD.STRENGTH.WEAK";
    }

    return {
      idx: idx + 1,
      col: this.colors[idx],
    };
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes.passwordToCheck.currentValue !== "") {
      this.showIndications = true;
    }
    const password: string = changes["passwordToCheck"].currentValue;

    this.setBarColors(5, "#DDD");

    if (password) {
      const c: { idx: number; col: string } = this.getColor(PasswordStrengthBarComponent.measureStrength(password));
      this.setBarColors(c.idx, c.col);
    }

    const validators: any = {
      digit: /^(?=.*\d)/,
      upperCase: /^(?=.*[A-Z])/,
      lowerCase: /^(?=.*[a-z])/,
      specialCharacter: /^(?=.*[!@#\$%\^&\*~`()_\-\+={[}\]|:;"'<,>\.\/?´ç¡¿€])/,
      min8digits: /^[A-Za-z\d\W_!@#\$%\^&\*~`()_\-\+={[}\]|:;"'<,>\.\/?´ç¡¿€]{7,}/,
    };
    Object.keys(validators).forEach((regex) => {
      const result = validators[regex].test(changes["passwordToCheck"].currentValue);
      this.requirements[regex] = result;
    });

    if (Object.values(this.requirements).every((v) => v === true)) {
      this.passwordValid = true;
    } else {
      this.passwordValid = false;
    }
  }

  private setBarColors(count: number, col: string): void {
    for (let _n: number = 0; _n < count; _n++) {
      this["bar" + _n] = col;
    }
  }
}
