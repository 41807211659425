<section
  class="offer"
  *ngFor="let journey of journeys; trackBy: trackById"
  [class.empty]="isEmpty(journey.sections) || !displayedOffers"
>
  <spt-travel-train-segments
    class="segments"
    [segments]="journey.segments"
    [hasSections]="!isEmpty(journey.sections)"
    [offerCarbonOffset]="journey.carbonOffset"
    [isSmallDevice]="isSmallDevice"
  ></spt-travel-train-segments>
  <section class="products" *ngIf="!isEmpty(journey.sections); else noTrains">
    <spt-travel-train-journey
      [journey]="journey"
      [locale]="locale"
      [flexibilities]="flexibilities"
      [selectedFlexibility]="selectedFlexibility"
      [isSmallDevice]="isSmallDevice"
      [seeOutOfPolicy]="seeOutOfPolicy"
      [allowedAlternativeIds]="allowedAlternativeIds"
      [selectedReturnFare]="selectedReturnFare"
      (selectOffers)="onSelectOffers($event)"
    ></spt-travel-train-journey>
  </section>
  <ng-template #noTrains>
    <section class="products empty">
      <i class="spt-icon-search-no-result"></i>
      <p translate>SEARCH.RESULT.RAIL.NO_ALTERNATIVES</p>
    </section>
  </ng-template>
</section>
