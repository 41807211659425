<ng-container *ngIf="this.search?.data">
  <spt-breadcrumb
    [active]="itineraryWay"
    [searchType]="this.search?.data.type"
    [activeStep]="step"
    [numberOfSteps]="itinerariesByStep?.length"
    [return]="this.search?.data?.type !== 'simple'"
  ></spt-breadcrumb>

  <div
    class="main"
    *ngIf="!errorOccurred; else noFlights"
    [ngClass]="this.search?.data?.type === 'simple' ? 'simple' : ''"
  >
    <div class="filters">
      <spt-travel-flight-filters
        type="general"
        [currentFilter]="filterByStep[step]"
        [filterDefinitions$]="filterDefinitionsSubject"
        (onFilterChange)="refreshItinerariesToDisplay()"
      >
      </spt-travel-flight-filters>
    </div>
    <ng-container *ngIf="displayedItineraries">
      <div class="itineraries">
        <div class="order-options">
          <ul>
            <li
              class="tab"
              [class.active]="option.value === order"
              *ngFor="let option of orderOptions"
              (click)="onTabChange($event, option.value)"
            >
              {{ option.label | translate }}
            </li>
          </ul>
        </div>
        <div class="list-itineraries" *ngIf="displayedItineraries.length; else noFlights" #scroll>
          <cdk-virtual-scroll-viewport class="infinite-scroll" itemSize="255" minBufferPx="4000" maxBufferPx="8000">
            <div>
              <spt-travel-flight-selection
                *ngIf="selectedItineraries[step - 1] && (commonService.isTabletObservable | async) === true"
                [selectedOutward]="selectedItineraries[step - 1]"
                [type]="itineraryWay"
                [isSmallDevice]="commonService.isTablet"
                [step]="step"
                (search)="changeLast()"
              ></spt-travel-flight-selection>
            </div>
            <div class="itinerary-way" *ngIf="(commonService.isTabletObservable | async) === true">
              <p class="title-separated">
                {{ "SEARCH.RESULT.AIR.MOBILE." + itineraryWay | uppercase | translate: { index: step + 1 } }}
              </p>
              <img src="./assets/svg/resume/calendar.svg" />
              <p class="departureDate">
                {{ departureDates[step] | localizedDate: "dd MMM" }}
              </p>
            </div>
            <div
              class="promote-carbon-offset"
              *ngIf="
                alternativeTrain?.alternativeSearchId &&
                alternativeTrain?.minimumCarbonOffset &&
                itineraryWay !== 'return'
              "
            >
              <div class="promote-background">
                <img src="./../../../assets/svg/policy-carbon-offset.svg" />
              </div>
              <div class="promote-content">
                <div class="promote-head-content">
                  <ng-container *ngIf="alternativeTrain?.hasRsePolicy; else notPolicy">{{
                    "POLICY.PROMOTE.FLIGHT.TITLE" | translate
                  }}</ng-container>
                  <ng-template #notPolicy>
                    {{ "SEARCH.FLIGHT.PROMOTE.TITLE" | translate }}
                  </ng-template>
                </div>
                <div class="promote-carbon-offset-value">
                  {{
                    "SEARCH.FLIGHT.PROMOTE.CARBON_OFFSET"
                      | translate
                        : {
                            minimumCarbonOffset:
                              (minimalCarbonOffsetByStep[step] - alternativeTrain.minimumCarbonOffset.amount
                              | number: "1.0-2"),
                            minimumRailDuration: minimumRailDurationFormatted
                          }
                  }}
                </div>
                <ng-container *ngIf="alternativeTrain?.hasRsePolicy; else notPolicyInfo"
                  ><p [innerHTML]="'POLICY.PROMOTE.FLIGHT.INFO' | translate"></p
                ></ng-container>
                <ng-template #notPolicyInfo>
                  <p [innerHTML]="'SEARCH.FLIGHT.PROMOTE.INFO' | translate"></p>
                </ng-template>
              </div>
              <p-button
                styleClass="p-button-primary medium"
                [label]="'GLOBAL.SEARCH' | translate"
                [routerLink]="'/travel/' + alternativeTrain.alternativeSearchId"
              >
              </p-button>
            </div>
            <ng-container *cdkVirtualFor="let itinerary of displayedItineraries">
              <spt-travel-flight-itinerary
                [pricedOffer]="pricedOffer"
                [itinerary]="itinerary"
                [selectedItinerary]="this.selectedItineraries[0]"
                [dataForFilters]="dataForFilters[step]"
                (extend)="expandItinerary(itinerary)"
                (filteredPrice)="itinerary.filteredPrice = $event"
                (preview)="preview($event)"
                [step]="step"
                [tripType]="this.search.data.type"
                [departureDates]="this.departureDates"
              ></spt-travel-flight-itinerary>
            </ng-container>
          </cdk-virtual-scroll-viewport>
        </div>

        <div class="preview" *ngIf="currentItinerary && (commonService.isTabletObservable | async) === false">
          <spt-travel-flight-preview
            [displayWayType]="this.search?.data?.type === 'round'"
            [itinerary]="currentItinerary"
            [step]="step"
            (changeLastLeg)="changeLast()"
          ></spt-travel-flight-preview>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-template #noFlights>
    <div class="error-container">
      <p class="text-center">
        <img class="error-img" src="./assets/svg/no-results.svg" alt="No results" />
      </p>
      <p class="error-message" translate>SEARCH.RESULT.NO_FLIGHTS</p>
      <p-button
        class="error-button"
        [label]="'SEARCH.RESULT.AIR.UPDATE_SEARCH' | translate"
        styleClass="p-button-secondary"
        (onClick)="changeSearch()"
      ></p-button>
    </div>
  </ng-template>

  <div
    class="mobile-button-filters"
    *ngIf="(commonService.isTabletObservable | async) === true"
    [class.displayed]="!filtersDisplayed"
    (mousedown)="displayFilters()"
  >
    <span class="spt-icon-filter"></span>
    <span translate>SEARCH.RESULT.FILTERS</span>
  </div>

  <div class="mobile-filters" [class.displayed]="filtersDisplayed">
    <div class="mobile-filters-header">
      <span class="spt-icon-back-arrow" (mousedown)="displayFilters(false)"></span>
      <div class="title" translate>SEARCH.RESULT.FILTERS</div>
    </div>
    <spt-travel-flight-filters
      type="general"
      [currentFilter]="filterByStep[step]"
      #generalFilters
      [filterDefinitions$]="filterDefinitionsSubject"
      (onFilterChange)="refreshItinerariesToDisplay()"
    >
    </spt-travel-flight-filters>
    <div class="actions" *ngIf="commonService.isTablet">
      <button class="filters-mobile-validate" (mousedown)="displayFilters(false)" type="button">
        {{ "GLOBAL.VALIDATE" | translate }}
      </button>
    </div>
  </div>
</ng-container>

<spt-sidebar [opened]="choosenObject !== undefined" (close)="hidePreview()">
  <div class="cta-container">
    <ng-container *ngIf="choosenObject">
      <confirmation-sidebar-flight
        [itineraryWay]="itineraryWay"
        [dataSearchType]="this.search.data.type"
        [itineraries]="[choosenObject]"
        [selectedItinerary]="selectedItineraries.length ? selectedItineraries[0] : undefined"
        [data]="dataPreview"
      ></confirmation-sidebar-flight>
    </ng-container>

    <div class="space"></div>

    <div class="cta-block">
      <div class="cta flex justify-content-center items-center">
        <p-button
          type="button"
          [label]="'SEARCH.AIR.VALIDATE.SELECT_TRAVEL' | translate"
          (click)="select(choosenObject)"
        >
        </p-button>
      </div>
    </div>
  </div>
</spt-sidebar>

<p-sidebar [(visible)]="displayValidatePanel" [fullScreen]="true" [showCloseIcon]="false" [modal]="false">
  <spt-flight-validate-option
    (close)="hideValidate()"
    *ngIf="displayValidatePanel"
    [itineraries]="oldSelected"
    [settings]="settings"
    [users]="users"
    [newOffers]="bestPackages"
    (validate)="validate($event)"
    [search]="search"
  ></spt-flight-validate-option>
</p-sidebar>
