<ng-container *ngFor="let itinerary of this.itineraries">
  <div class="header">
    <img class="flight-logo" src="./assets/svg/details/plane.svg" />
    <div class="informations">
      <div class="oad">
        <div *ngIf="form.trips[0]; let trip" class="origin">
          {{ trip.departureAirport.name }}
        </div>
        <img class="arrow" src="./assets/svg/details/large-arrow.svg" />
        <div *ngIf="form.trips[0]; let trip" class="destination">
          {{ trip.arrivalAirport.name }}
        </div>
      </div>
      <div class="secondary">
        <div class="type" translate>SEARCH.{{ form.type | uppercase }}</div>
        <div class="flexible">
          <i class="spt-icon-tag txt-14"></i>
          <ng-container [ngSwitch]="itinerary.flexible">
            <span *ngSwitchCase="true" translate> GLOBAL.CATEGORY.REFUNDABLE_OR_EXCHANGEABLE </span>
            <span *ngSwitchCase="false" translate> GLOBAL.CATEGORY.NON_REFUNDABLE </span>
          </ng-container>
          <span *ngIf="itinerary.refundable" translate> REMBOURSABLE / </span>
          <span *ngIf="itinerary.changeable" translate> ECHANGEABLE / </span>
          <span *ngIf="!itinerary.changeable && !itinerary.refundable && itinerary.partial" translate>
            VOIR CONDITION
          </span>
        </div>
      </div>
    </div>
    <span class="spt-icon-back-arrow close" (mousedown)="close()"></span>
  </div>

  <div class="legs">
    <div *ngFor="let leg of itinerary.legs; let tripIndex = index" class="leg">
      <div [ngSwitch]="leg.type" class="type">
        <span *ngSwitchCase="'simple'" translate>SEARCH.{{ leg.type | uppercase }}</span>
        <span *ngSwitchCase="'round'">
          <ng-container [ngSwitch]="tripIndex">
            <ng-container *ngSwitchCase="0" translate>SEARCH.{{ leg.type | uppercase }}</ng-container>
            <ng-container *ngSwitchCase="1" translate>SEARCH.{{ leg.type | uppercase }}</ng-container>
          </ng-container>
        </span>
        <span *ngSwitchCase="'multi'">{{ "GLOBAL.FLIGHT" | translate }} {{ tripIndex + 1 }}</span>
      </div>

      <spt-travel-flight-itinerary-leg
        [departureDate]="this.departureDates[tripIndex]"
        [baggageIncluded]="itinerary.baggageIncluded"
        [carbonOffset]="itinerary?.carbonOffset"
        [carryOnIncluded]="itinerary.carryOnIncluded"
        [leg]="leg"
      ></spt-travel-flight-itinerary-leg>
      <div class="company">
        <span>{{ "SEARCH.RESULT.AIR.SOLD_BY" | translate }} {{ companies[leg.governingCarrier]?.name }}</span>
        <img class="icon" src="https://static.misterfly.com:443/images/airlines/{{ leg.governingCarrier }}.png" />
      </div>
    </div>
  </div>
  <div class="warning" *ngIf="airportsDifferents" translate>SEARCH.AIR.AIRPORTS_WARNING</div>

  <div class="travelers">
    <div class="title" translate>GLOBAL.TRAVELERS</div>
    <div *ngFor="let user of this.users; let i = index" class="traveler">
      <spt-avatar [user]="user"></spt-avatar>
      <div class="informations">
        <div
          class="information"
          *ngFor="let pricingInformation of itinerary.pricingInformations; let indexPricing = index"
        >
          <div class="flight-number" *ngIf="itinerary.pricingInformations.length > 1">
            <ng-container [ngSwitch]="form.type">
              <span *ngSwitchCase="'round'">
                {{ (indexPricing === 0 ? "GLOBAL.DEPARTURE" : "GLOBAL.RETURN") | translate }}
              </span>
              <span *ngSwitchCase="multi"> {{ "GLOBAL.FLIGHT" | translate }} {{ indexPricing + 1 }} </span>
            </ng-container>
          </div>

          <div class="type-and-luggages">
            <div class="traveler-type">
              <span translate>GLOBAL.TYPE</span>:
              <span>
                {{
                  "SEARCH.RESULT.AIR.PASSENGER.TYPES." + pricingInformation.fare.passengerInfos[i].passengerType
                    | translate
                }}
              </span>
            </div>
            <div class="luggages">
              {{ pricingInformation.fare.passengerInfos[i].baggageIncluded }}
              <i class="spt-icon-baggage txt-14 m-l-10"></i>
            </div>
            <div class="luggages">
              {{ pricingInformation.fare.passengerInfos[i].carryOnIncluded }}
              <i class="spt-icon-baggage txt-14 m-l-10"></i>
            </div>
          </div>
          <div class="flexible">
            <ng-container
              *ngIf="pricingInformation.fare.passengerInfos[i]?.penaltiesInfo?.penalties?.length > 0; else noCondition"
            >
              <p
                *ngFor="
                  let penalty of pricingInformation.fare.passengerInfos[i]?.penaltiesInfo?.penalties;
                  let isLast = last
                "
              >
                <ng-container *ngIf="penalty.type === 'Exchange' && penalty.applicability === 'Before'">
                  <ng-container *ngIf="penalty.amount > -1; else noExchange">
                    {{ "GLOBAL.CATEGORY.MODIFICATION_BEFORE" | translate }}:
                    {{ penalty | SPTPrice | async }}
                    /
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="penalty.type === 'Refund' && penalty.applicability === 'Before'">
                  <ng-container *ngIf="penalty.amount > -1; else noRefund">
                    {{ "GLOBAL.FEES.CANCELLATION.BEFORE" | translate }}
                    {{
                      {
                        amount: penalty.amount,
                        currency: pricingInformation.price.currency
                      }
                        | SPTPrice
                        | async
                    }}
                  </ng-container>
                </ng-container>
              </p>
              <ng-template #noCondition>
                {{ "GLOBAL.CATEGORY.NON_EXCHANGEABLE" | translate }} /
                {{ "GLOBAL.CATEGORY.NON_REFUNDABLE" | translate }}
              </ng-template>
              <ng-template #noExchange>
                {{ "GLOBAL.CATEGORY.NON_EXCHANGEABLE" | translate }}
              </ng-template>
              <ng-template #noRefund>
                {{ "GLOBAL.CATEGORY.NON_REFUNDABLE" | translate }}
              </ng-template>
            </ng-container>
          </div>
          <div class="cabinAndFareCodes">
            <div *ngFor="let fareComponent of pricingInformation.fare.passengerInfos[index]?.fareComponents">
              <p>
                {{ "SEARCH.RESULT.AIR.FILTERS.FAREBASISCODE" | translate }}
                {{ fareComponent.fareBasisCode }}
              </p>
              <div>
                <div>{{ "SEARCH.RESULT.AIR.FILTERS.CABINCODES" | translate }}:</div>
                <p *ngFor="let segment of fareComponent.segments">
                  {{ "GLOBAL.CATEGORY." + (segment.cabinCode | uppercase) | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div *ngIf="labels?.length > 0 && userLabel" class="labels m-b-15">
  <p translate>SEARCH.LABELS</p>
  <div *ngFor="let societyLabel of labels">
    <ng-container *ngIf="userLabel?.value[societyLabel.label]">
      {{ societyLabel.label }} :
      {{
        userLabel?.value[societyLabel.label]?.value
          ? userLabel?.value[societyLabel.label]?.value
          : userLabel?.value[societyLabel.label]
      }}
    </ng-container>
  </div>
</div>

<div class="charges">
  <div class="title">{{ "SEARCH.RESULT.TOTAL" | translate }}:</div>

  <div class="price">
    {{ total }}
    {{
      {
        amount: total,
        currency: "EUR"
      }
        | SPTPrice
        | async
    }}

    <span class="out-policy" *ngIf="isOutOfPolicy" translate>GLOBAL.OUT_OF_POLICY</span>
  </div>
</div>

<div class="conditions">
  <div class="title" translate>SEARCH.RESULT.CONDITIONS.BOOKING</div>
  <p translate>SEARCH.RESULT.CONDITIONS.VERIFICATION</p>
  <p [innerHTML]="'SEARCH.RESULT.CONDITIONS.CARBON_OFFSET' | translate"></p>
</div>
