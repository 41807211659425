import { NgModule } from "@angular/core";
import { SidebarTravelComponent } from "./sidebar-travel.component";
import { SharedModule } from "src/app/@shared/shared.module";
import { ConditionsComponent } from "./conditions/conditions.component";
import { ItineraryComponent } from "./itinerary/itinerary.component";
import { ItineraryTrainComponent } from "./itinerary/train/train.component";
import { ItineraryFlightComponent } from "./itinerary/flight/flight.component";
import { ItineraryHotelComponent } from "./itinerary/hotel/hotel.component";
import { ItineraryCarComponent } from "./itinerary/car/car.component";
import { ConditionsTrainComponent } from "./conditions/train/train.component";
import { ConditionsFlightComponent } from "./conditions/flight/flight.component";
import { ConditionsCarComponent } from "./conditions/car/car.component";
import { ConditionsHotelComponent } from "./conditions/hotel/hotel.component";
import { ConditionsFlightInformationComponent } from "./conditions/flight/information/information.component";

const COMPONENTS: Array<any> = [SidebarTravelComponent];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ConditionsComponent,
    ItineraryComponent,
    ItineraryTrainComponent,
    ItineraryFlightComponent,
    ItineraryHotelComponent,
    ItineraryCarComponent,
    ConditionsTrainComponent,
    ConditionsFlightComponent,
    ConditionsCarComponent,
    ConditionsHotelComponent,
    ConditionsFlightInformationComponent,
  ],
  imports: [SharedModule],
  exports: [...COMPONENTS],
})
export class SidebarTravelModule {}
