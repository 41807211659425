import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AccountService } from "src/app/@shared/services/account.service";
import { MessageService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { UtilsTypes } from "../../@shared/@types/utils";
import { Subscription } from "rxjs";

@Component({
  selector: "spt-reset-password",
  styleUrls: ["./reset-password.component.scss"],
  templateUrl: "./reset-password.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  public displayPassword: Object = { password: false, repeatPassword: false };

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {}

  public resetForm: UntypedFormGroup;
  private dataPassword: { jwt: string; id: string };

  static checkPasswords(form: UntypedFormGroup): any {
    const pass: any = form.get("password");
    const confirmPass: any = form.get("repeatPassword");
    if (!pass.value || !confirmPass.value || pass.value.trim() === "" || confirmPass.value.trim() === "") {
      return { mismatchedPassword: false };
    }
    const exactMatch: boolean = pass.value === confirmPass.value;
    return exactMatch ? null : { mismatchedPassword: false };
  }

  get form(): any {
    return this.resetForm.value;
  }

  get repeatPassword(): AbstractControl {
    return this.resetForm.get("repeatPassword");
  }

  get password(): AbstractControl {
    return this.resetForm.get("password");
  }

  public loginPage(): void {
    this.router.navigate(["auth"]);
  }

  ngOnInit(): void {
    const passRequirement: UtilsTypes.ObjectKey<number> = {
      passwordMinLowerCase: 1,
      passwordMinNumber: 1,
      passwordMinSymbol: 1,
      passwordMinUpperCase: 1,
      passwordMinCharacters: 8,
    };
    this.subscription = new Subscription();

    this.resetForm = this.formBuilder.group(
      {
        password: [
          "",
          [
            Validators.required,
            Validators.pattern(
              /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d!@#\$%\^&\*~`()_\-\+={[}\]|:;"'<,>\.\/?´ç¡¿€]{7,}/,
            ),
          ],
        ],
        repeatPassword: [
          "",
          [
            Validators.required,
            Validators.pattern(
              /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d!@#\$%\^&\*~`()_\-\+={[}\]|:;"'<,>\.\/?´ç¡¿€]{7,}/,
            ),
          ],
        ],
      },
      { validator: ResetPasswordComponent.checkPasswords },
    );

    this.subscription.add(
      this.route.params.subscribe((params: { jwt: string; id: string }) => {
        this.dataPassword = params;
      }),
    );
  }

  public changePassword(): void {
    this.accountService.changeResetPassword(this.form.password, this.dataPassword.jwt, this.dataPassword.id).subscribe(
      () => {
        this.messageService.add({
          severity: "success",
          detail: this.translateService.instant("NOTIFICATIONS.PASSWORD_CHANGED_SUCCESSFULLY"),
          life: 10000,
        });
        this.router.navigate(["auth"]);
      },
      (): any => {
        this.messageService.add({
          severity: "error",
          detail: this.translateService.instant("NOTIFICATIONS.UNABLE_TO_CHANGE_PASSWORD"),
          life: 10000,
        });
      },
    );
  }

  changeStatutPassword(type): void {
    this.displayPassword[type] = !this.displayPassword[type];
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
