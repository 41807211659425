<div class="container col p-0">
  <ng-container *ngIf="commonService.browserSupported; else unsupportedBrowser">
    <div class="form-title">
      <h1 class="txt-25 m-0" translate>LOGIN.ACTIVATE</h1>
    </div>
    <form *ngIf="form" [formGroup]="form" (ngSubmit)="activeAccount()" class="form-group">
      <p translate>ACCOUNT.PASSWORD.RULES</p>
      <label translate>ACCOUNT.GENERAL.PASSWORD</label>
      <div class="p-inputgroup align-items-center">
        <input
          class="iosInputFix"
          pInputText
          formControlName="password"
          [type]="this.displayPassword['password'] ? 'text' : 'password'"
          #passwordInput
        />
        <span class="p-inputgroup-addon" (click)="changeStatutPassword('password')"><i class="fas fa-eye"></i></span>
      </div>
      <app-password-strength-bar [passwordToCheck]="form.value.password"></app-password-strength-bar>
      <label translate>LOGIN.SUBSCRIPTION.REPEAT_PASSWORD</label>
      <div class="p-inputgroup align-items-center">
        <input
          class="iosInputFix"
          pInputText
          formControlName="controlPassword"
          [type]="this.displayPassword['passwordControl'] ? 'text' : 'password'"
          #passwordInput
        />
        <span class="p-inputgroup-addon" (click)="changeStatutPassword('passwordControl')"
          ><i class="fas fa-eye"></i
        ></span>
      </div>
      <p-button
        label="{{ 'GLOBAL.VALIDATE' | translate }}"
        class="m-t-20"
        [disabled]="!form.valid"
        type="submit"
      ></p-button>
    </form>
  </ng-container>
  <ng-template #unsupportedBrowser>
    <a class="unsupported-browser" href="https://www.google.com/chrome/" target="_blank">
      {{ "LOGIN.UNSUPPORTED_BROWSER" | translate }}
    </a>
  </ng-template>
</div>
