import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { CommonService } from "../../../@shared/services/common.service";
import { FlightService } from "../flight.service";

@Component({
  selector: "spt-travel-flight-selection",
  templateUrl: "./selection.component.html",
  styleUrls: ["./selection.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelFlightSelectionComponent {
  @Input() selectedOutward;
  @Input() type: string;
  @Input() isSmallDevice: boolean;
  @Input() step: number;
  @Output() search: EventEmitter<void> = new EventEmitter();

  public departure: any = {};
  public arrival: any = {};
  public refundMod: any = {};
  public types: string;
  constructor(
    public commonService: CommonService,
    private flightService: FlightService,
  ) {}

  ngOnChanges(changes) {
    this.type !== "trip" ? (this.types = "departure") : (this.types = this.type);
    if (this.type === "trip" || this.types === "trip") {
      this.types = "trip";
    } else {
      this.types = "departure";
    }
    // this.selectedOutward = changes.selectedOutward.currentValue;
    // this.step = changes.step.currentValue;
    // this.type = changes.type.lastValue;
    this.departure.local = this.flightService.airports[this.selectedOutward.legs[0].departure.airport].name;
    this.departure.hour = this.selectedOutward.legs[0].departureTime;
    this.arrival.local = this.flightService.airports[this.selectedOutward.legs[0].arrival.airport].name;
    this.arrival.hour = this.selectedOutward.legs[0].arrivalTime;

    this.refundMod.refund = this.selectedOutward.pricingInformations[0].refundable
      ? this.selectedOutward.pricingInformations[0].refundFees[0] === 0
        ? "SEARCH.RESULT.AIR.OFFER.REFUNDABLE"
        : "SEARCH.RESULT.AIR.OFFER.PARTIAL_REFUNDABLE"
      : "SEARCH.RESULT.AIR.OFFER.NO_REFUNDABLE";

    this.refundMod.changeable = this.selectedOutward.pricingInformations[0].changeable
      ? this.selectedOutward.pricingInformations[0].exchangeFees[0] === 0
        ? "SEARCH.RESULT.AIR.FILTERS.FREE_CANCELLATION"
        : "SEARCH.RESULT.AIR.OFFER.PARTIAL_EXCHANGEABLE"
      : "SEARCH.RESULT.AIR.OFFER.NO_EXCHANGEABLE";
  }
  onSearch(): void {
    this.search.emit();
  }
}
