<div class="journey-detail">
  <div
    class="reservation-detail"
    *ngFor="let journey of mappedJourneys; let journeyIndex = index; let firstJourney = first; let lastJourney = last"
  >
    <ng-container *ngIf="!hasDifferentJourneys || firstJourney || (lastJourney && journey.direction === 'inward')">
      <div class="reservation-detail-container">
        <p class="direction">
          {{ "GLOBAL." + journey.direction | uppercase | translate }}
        </p>
        <ng-container *ngIf="journey.segments.length > 0; else openReturn">
          <div
            class="destination"
            *ngFor="let segment of journey.segments; let last = last; let first = first; let segmentIndex = index"
          >
            <div class="date-wrapper">
              <p class="day-number">
                {{ segment.departure.date.utc | localizedDate: "dd" }}
              </p>
              <p class="month">
                {{ segment.departure.date.utc | localizedDate: "MMM" }}
              </p>
            </div>

            <div class="dot-line">
              <div class="dot-empty"></div>
              <div class="line"></div>
              <div class="dot-empty"></div>
            </div>

            <div class="destination-time-society-container">
              <p class="departure-place-name">
                {{ segment.departure.name | titlecase }}
              </p>
              <p class="departure-time">{{ segment.departure.date.time }}</p>
              <p class="information">
                <span class="chip-time">
                  <i class="spt-icon-clock"></i>
                  <span class="duration">
                    {{
                      (segment.duration
                        ? segment.duration
                        : travelDuration(segment.departure.date.utc, segment.arrival.date.utc)
                      ) | duration
                    }}
                  </span>
                </span>
                <span class="offset">
                  <i class="spt-icon-carbon-offset"></i>
                  <span class="m-l-5">
                    {{
                      segment.carbonOffset
                        ? (segment.carbonOffset.amount | kiloFormatter)
                        : ("CARBON_OFFSET.UNAVAILABLE" | translate)
                    }}
                  </span>
                </span>
                <span class="carrier">
                  <span class="transport m-r-5">
                    <img
                      [alt]="segment.transport.carrier"
                      [src]="'assets/img/train-carriers/' + segment.transport.carrier + '.webp'"
                    />
                  </span>
                  <span class="m-r-5">
                    {{ "GLOBAL." + segment.transport.transportMode | uppercase | translate }}
                  </span>
                  <span class="m-r-5" *ngIf="segment.transport?.trainCode">
                    {{ segment.transport.trainCode }}
                  </span>
                  <span>{{ segment.transport.trainNumber }}</span>
                </span>
              </p>
              <p class="arrival-place-name">
                {{ segment.arrival.name | titlecase }}
              </p>
              <p class="arrival-time">{{ segment.arrival.date.time }}</p>
            </div>
            <div class="switching-time text-center" *ngIf="journey.segments.length > 0 && !last">
              <span>
                {{
                  travelDuration(segment.arrival.date.utc, journey.segments[segmentIndex + 1].departure.date.utc)
                    | duration
                }}
              </span>
              <span *ngIf="!isSmallDevice">{{ "BOOKING.TRAIN.CHANGING_TIME" | translate }}</span>
            </div>
            <div class="line-purple-dotted"></div>
            <div class="class-seats">
              <div class="class-luggage">
                <p class="class-luggage-title">
                  {{ "GLOBAL.CLASS.CLASS" | translate }}
                </p>
                <p class="class-luggage-data" *ngIf="travelClasses[segment.id]">
                  {{ "SEARCH.TRAIN." + travelClasses[segment.id] | translate }}
                </p>
              </div>
              <div class="space-allocations" *ngIf="spaceAllocations[journey.direction]">
                <p class="space-allocation-title">
                  {{ "BOOKING.TRAIN.SPACE_ALLOCATIONS.TITLE" | translate }}
                </p>
                <ng-container *ngIf="spaceAllocations[journey.direction][segment.id]; else free">
                  <p
                    class="space-allocation"
                    *ngFor="let spaceAllocation of spaceAllocations[journey.direction][segment.id]"
                  >
                    <span class="carriage" translate><i class="fas fa-train"></i></span>
                    <span>{{ spaceAllocation.coachNumber | number }}</span>
                    <span class="position" translate><i class="fas fa-chair"></i></span>
                    <span>{{ spaceAllocation.seatNumber | number }}</span>
                  </p>
                </ng-container>
                <ng-template #free>
                  <p class="space-allocation">
                    <span class="carriage">
                      {{ "BOOKING.TRAIN.SPACE_ALLOCATIONS.FREE" | translate }}
                    </span>
                  </p>
                </ng-template>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #openReturn>
          <section class="open-return-journey">
            {{ "GLOBAL.CLASS.RETURN" | translate }}
            {{ "SEARCH.RESULT.RAIL.OPEN_RETURN" | translate }}
          </section>
        </ng-template>
      </div>
      <div class="fares" *ngIf="fareInfosByDirection[journey.direction] && journey.segments.length > 0">
        <ng-container *ngFor="let fareInfo of fareInfosByDirection[journey.direction]">
          <div class="fare-title">
            <p class="type">{{ fareInfo.fare.fareName }} x {{ fareInfo.nbPassengers }}</p>
            <ng-container *ngIf="fareInfo.price?.amount > 0">
              <p
                class="price"
                *ngIf="
                  !fareInfo.fare.fareName.endsWith('Return') ||
                    (fareInfo.fare.fareName.endsWith('Return') && journey.direction === 'outward');
                  else returnFare
                "
              >
                {{ fareInfo.price | SPTPrice: item.confirmationDate | async }}
              </p>
              <ng-template #returnFare>
                <p class="price">
                  {{
                    {
                      amount: 0,
                      currency: fareInfo.price.currency
                    }
                      | SPTPrice: item.confirmationDate
                      | async
                  }}
                </p>
              </ng-template>
            </ng-container>
            <div class="see-conditions" (mousedown)="toggleFare(fareInfo.fare)">
              {{ "SEARCH.RESULT.RAIL.SEE_CONDITIONS" | translate }}
            </div>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="item.detail?.journeys.length > 1 && !lastJourney">
        <div class="separator"></div>
      </ng-container>
    </ng-container>
  </div>
</div>

<spt-conditions-train-dialog
  *ngIf="selectedFare"
  [visible]="!!selectedFare"
  [conditions]="selectedFare.conditions"
  [conditionsSummary]="selectedFare.conditionsSummary"
  [routeRestriction]="selectedFare.routeRestriction"
  [name]="selectedFare.fareName"
  (closeConditions)="selectedFare = undefined"
></spt-conditions-train-dialog>
