import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Message, MessageService } from "primeng/api";
import { ItemService, ItemStatus } from "../../../../@shared/services/item.service";
import { TrainTypes } from "../../../../travel/train/train";
import { finalize } from "rxjs/operators";
import { LoadingService } from "../../../../@shared/services/loading.service";

@Component({
  selector: "spt-reservations-cancel",
  templateUrl: "./cancel.component.html",
  styleUrls: ["./cancel.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservationsCancelComponent implements OnInit {
  @Input() item: any;
  @Input() isSmallDevice: boolean;
  @Input() locale: string;
  @Output() returnToDetails: EventEmitter<void> = new EventEmitter();
  @Output() closeSidebar: EventEmitter<any> = new EventEmitter();

  public warningMessages: Array<Message> = [];
  public contactCustomerService: boolean;
  public cancelCriteria: any;
  public cancellation: any;

  public cancellationReady: boolean = false;
  public optionsRequired: boolean = false;

  constructor(
    private itemService: ItemService,
    private cd: ChangeDetectorRef,
    private loadingService: LoadingService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.item.confirmationDate = this.item.statusHistory.find((status) => status.to === "confirmed")?.date;
    if (["car", "hotel"].includes(this.item.type)) {
      this.loadingService.add();
      this.itemService
        .getCancellation(this.item.id)
        .pipe(
          finalize((): void => {
            this.loadingService.remove();
          }),
        )
        .subscribe((cancellation: any): void => {
          this.cancellation = cancellation;
          this.cd.markForCheck();
        });
    } else if (["flight"].includes(this.item.type) && this.item.provider !== "afkl") {
      this.loadingService.add();
      this.itemService
        .getCancellation(this.item.id)
        .pipe(
          finalize((): void => {
            this.loadingService.remove();
          }),
        )
        .subscribe((cancellation: any): void => {
          this.cancellation = cancellation;
          this.cd.markForCheck();
        });
    } else {
      this.optionsRequired = true;
    }
  }

  onSelected(event: any): void {
    this.cancellation = undefined;
    this.cancelCriteria = event;
  }

  getCancellation(): void {
    this.loadingService.add();

    switch (this.item.type) {
      case "flight":
        if (this.item.provider === "afkl") {
          this.itemService
            .getCancellation(this.item.id, {
              orderId: this.item.formData.orderID,
              refundableIds: this.cancelCriteria ? this.cancelCriteria.options.join(",") : [],
              reason: "cancellation",
            })
            .pipe(finalize((): void => this.loadingService.remove()))
            .subscribe((cancellation: any): void => {
              this.cancellation = cancellation;
              this.cd.markForCheck();
            });
        }
        break;
      case "train":
        this.itemService
          .getCancellation(this.item.id, {
            orderId: this.cancelCriteria.orderId,
            refundableIds: this.cancelCriteria.options.join(","),
            reason: this.cancelCriteria.reason,
          })
          .pipe(finalize((): void => this.loadingService.remove()))
          .subscribe((cancellation: any): void => {
            this.cancellation = cancellation;
            this.cd.markForCheck();
          });
        break;
      case "transfer":
        this.cancellation = {
          price: {
            amount:
              this.item.detail.booking.transfers.length === 2 && this.cancelCriteria.options.length === 1
                ? this.item.price.amount / 2
                : this.item.price.amount,
            currency: this.item.price.currency,
          },
          data: {
            id: this.cancelCriteria.options.length === 1 ? this.cancelCriteria.options[0] : undefined,
          },
        };
        this.loadingService.remove();
        break;
      default:
        break;
    }
  }

  cancel(): void {
    this.returnToDetails.emit();
  }

  validateCancel(dataId: string): void {
    this.loadingService.add();
    this.itemService
      .cancel(
        this.item.id,
        {
          dataId,
          optionId: this.cancelCriteria ? this.cancelCriteria.options[0] : undefined,
        },
        this.locale,
      )
      .pipe(finalize((): void => this.loadingService.remove()))
      .subscribe({
        next: (item: any): void => {
          this.messageService.add({
            severity: "success",
            summary: this.translateService.instant("NOTIFICATIONS.ITEM_CANCELLED"),
          });
          this.item.status = item.status;
          this.closeSidebar.emit(item.status === ItemStatus.CANCELLED);
        },
        error: (): void => {
          this.messageService.add({
            severity: "error",
            summary: this.translateService.instant("NOTIFICATIONS.ITEM_CANCELLATION_FAILED"),
          });
          this.closeSidebar.emit();
        },
      });
  }
}
