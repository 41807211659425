import Raven from "raven-js";
import { Component, ElementRef, HostListener, NgZone, OnInit, Renderer2, ViewEncapsulation } from "@angular/core";
import { SessionService, SessionType } from "./@shared/services/session.service";
import { concatMap, filter, first } from "rxjs/operators";

import { CommonService } from "./@shared/services/common.service";
import { LoadingService } from "./@shared/services/loading.service";
import { Location } from "@angular/common";
import { PoliciesService } from "./@shared/services/policies.service";
import { RoleService } from "./@shared/services/roles.service";
import { Router } from "@angular/router";
import { Society } from "./@shared/@types/society";
import { SocietyService } from "./@shared/services/society.service";
import { StorageService } from "./@shared/services/storage.service";
import { User } from "./@shared/@types/user";
import { UserService } from "./@shared/services/user.service";
import moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { Meta } from "@angular/platform-browser";
import { PrimeNGConfig, Translation } from "primeng/api";
import { FeedbackService } from "./@shared/services/feedback.service";
// import OneSignal from "onesignal-cordova-plugin";

// import { WebsocketService } from './@shared/services/websocket.service';

// FOR INTERCOM no use const $intercom: any = (window as any).Intercom; because ref Intercom is maybe change
// const $intercom: any = (window as any).Intercom;

declare const navigator: any;
declare const StatusBar: any;
declare const device: any;
declare const ThreeDeeTouch: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  public constructor(
    elementRef: ElementRef<HTMLElement>,
    private feedbackService: FeedbackService,
    private meta: Meta,
    private sessionService: SessionService<SessionType>,
    private storageService: StorageService,
    private router: Router,
    private societyService: SocietyService,
    private userService: UserService,
    private loadingService: LoadingService,
    private roleService: RoleService,
    private commonService: CommonService,
    private location: Location,
    private zone: NgZone,
    private translateService: TranslateService,
    private primeNgConfig: PrimeNGConfig,
  ) {
    // if (this.commonService.isCordova()) {
    //   // Remove this method to stop OneSignal Debugging
    //   OneSignal.Debug.setLogLevel(6);

    //   // Replace YOUR_ONESIGNAL_APP_ID with your OneSignal App ID
    //   OneSignal.initialize("77a62abe-265b-4422-b022-59a350f95f8c");
    //   OneSignal.login("123");
    //   OneSignal.Notifications.addEventListener("click", async (e) => {
    //     let clickData = await e.notification;
    //     console.log("Notification Clicked  " + clickData);
    //     console.log(clickData);
    //   });

    //   OneSignal.Notifications.requestPermission(true).then((success: Boolean) => {
    //     console.log("Notification permission granted " + success);
    //   });
    // }

    this.meta.addTag({
      name: "robots",
      content: "none",
    });
    this.title = "superfront";
    this.loadingService.loading.subscribe((loading: boolean) => {
      this.loading = loading === null ? false : loading;
      if (this.loading === true) {
        this.commonService.disableAppScroll();
      } else {
        this.commonService.enableAppScroll();
      }
    });
    this.initializeApp();
    this.sessionService.getSession().subscribe((session) => {
      elementRef.nativeElement.dataset.dateFormat = session?.dateFormat || "dd/mm/yy";
    });
  }

  public title: string = "Places";
  private session: SessionType;
  public user: any;
  public loading: boolean = true;

  @HostListener("document:click", ["$event"])
  documentClick(event: any): void {
    this.commonService.documentClickedTarget.next(event.target);
  }

  // hideIntercom(): void {
  //   (window as any).Intercom('hide');
  // }
  // showIntercom(): void {
  //   (window as any).Intercom('show');
  // }

  ngOnInit(): void {
    if (this.commonService.isCordova() && device?.platform === "iOS") {
      document.addEventListener("deviceready", this.initThreeDeeTouch.bind(this), false);
    }
    window.addEventListener("native.keyboardshow", function (e) {
      if (document.activeElement.classList.contains("iosInputFix")) {
        setTimeout(function () {
          document.activeElement.scrollIntoView();
        }, 100);
      }
    });
    const userAgent: string = navigator?.userAgent;
    if (userAgent && /android|ipad|iphone|ipod/i.test(userAgent) && !this.commonService.isCordova()) {
      if (typeof device === undefined) {
        document.location.href = `spt:/${window.location.pathname}`;
      }
    } else if (window.location.origin !== "http://devfront.supertripper.com:4200") {
      (window as any).Intercom("boot", {
        app_id: "nv143j07",
        custom_launcher_selector: "#intercom-chat",
        hide_default_launcher: this.commonService.isTablet,
      });
      (window as any).Intercom("update");
    }
    this.feedbackService.load();
  }
  initThreeDeeTouch() {
    StatusBar.overlaysWebView(true);
    document.addEventListener(
      "threeDeeTouchClick",
      function (e) {
        let path: string = null;
        switch (e.detail.data.type) {
          case "reservations":
            path = "management/bookings";
            break;
          case "compte":
            path = "account";
            break;
          default:
            path = "/";
            break;
        }
        if (path) {
          this.zone.run(() => {
            this.router.navigate([path]);
          });
        }
      }.bind(this),
    );

    ThreeDeeTouch.onHomeIconPressed = function (payload) {
      document.dispatchEvent(new CustomEvent("threeDeeTouchClick", { detail: { data: payload } }));
    };
  }
  initializeApp(): void {
    this.sessionService
      .getLoggedOut()
      .pipe(
        filter((value: boolean) => !!value),
        concatMap(() => {
          this.commonService.unsetBackFunction(true);
          return this.storageService.clear();
        }),
      )
      .subscribe((res: any) => {
        Raven.setUserContext({});
        this.loadingService.init();
        this.societyService.init();

        (window as any).Intercom("boot", {
          app_id: "nv143j07",
          custom_launcher_selector: "#intercom-chat",
        });
        (window as any).Intercom("update");
        // $crisp.push(['do', 'session:reset']);
        this.router.navigate(["auth"]).then(() => {
          this.commonService.appReady
            .pipe(
              filter((ready: boolean) => ready !== null),
              first(),
            )
            .subscribe((ready: boolean) => {
              navigator.app.clearHistory();
            });
        });
        this.loadingService.remove();
      });

    this.sessionService.getLoggedIn().subscribe((res: { session: SessionType; options: any }) => {
      this.commonService.unsetBackFunction(true);
      this.session = res.session;
      const authPath: RegExp = /^\/(auth|resetpassword|sign-in|activate)/;
      const currentPath: string = this.location.path();
      if (this.session) {
        this.initUtils();
        if (authPath.test(currentPath)) {
          this.router
            .navigate([""])
            .then(() => {
              this.loadingService.remove();
            })
            .catch((err: any) => console.error(err));
        }
      } else {
        if (!authPath.test(currentPath)) {
          this.router
            .navigate(["auth"])
            .then(() => {
              this.loadingService.remove();
            })
            .catch((err: any) => console.error(err));
        }
      }
      this.zone.run(() => {
        this.commonService.appReady
          .pipe(
            filter((ready: boolean) => ready !== null),
            first(),
          )
          .subscribe((ready: boolean) => {
            if (ready === true) {
              navigator.app.clearHistory();
            }
          });
        this.loadingService.remove();
        this.commonService.setBackFunction(() => {
          navigator.app.backHistory();
        }, this);
      });
    });
  }

  initUtils(): void {
    console.log("initUtils");
    const user: User = this.userService.user.value;
    const society: Society = this.societyService.society.value;
    console.log({ user });
    moment.locale(user?.settings.language || "fr");
    this.translateService.setDefaultLang(user?.settings.language || "fr");
    this.translateService.use(user?.settings.language || "fr");
    console.log(this.translateService.currentLang);
    this.translateService.get("primeng").subscribe((res: Translation) => this.primeNgConfig.setTranslation(res));

    Raven.setUserContext({
      id: this.session.userId,
      email: user?.email,
      username: user?.username,
    });

    if (window.location.origin !== "http://devfront.supertripper.com:4200" && user) {
      (window as any).Intercom("update", {
        app_id: "nv143j07",
        name: user.username, // Full name
        email: user.email, // Email address,
        phone: user.phone,
        user_id: this.session.userId,
        language_override: user.settings.language || "fr",
        company: {
          name: society.name,
          id: society._id,
        },
        role_in_company: this.roleService.roles.value,
      });
    }
  }
}
