import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({
  name: "fromNow",
})
export class FromNowPipe implements PipeTransform {
  transform(timestamp: number): string {
    return moment(timestamp * 1000).fromNow();
  }
}
