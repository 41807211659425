import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Facility, FilterOption, Hotel, SearchBody } from "./hotel.d";
import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ComponentRef,
  createComponent,
  ElementRef,
  EnvironmentInjector,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { HotelService } from "src/app/@shared/services/hotel.service";
import * as L from "leaflet";
import { CommonService } from "src/app/@shared/services/common.service";
import { BehaviorSubject, Subject } from "rxjs";
import { MarkupHotelComponent } from "./markup-hotel/markup-hotel.component";
import { TranslateService } from "@ngx-translate/core";
import { MemberSociety } from "../../@shared/@types/society";
import { AuthorizationService } from "src/app/@shared/services/authorization.service";
import { takeUntil } from "rxjs/operators";
import { DateTime } from "luxon";

const BASE_SEARCH_DIST = 500;
// La valeur etait de 5000 avant.
const SEARCH_DIST_OFFSET = 4000;
const SEARCH_DIST_MAX = 20000;
const SEARCH_DIST_GAP = 1000;
@Component({
  selector: "spt-travel-hotel",
  styleUrls: ["./hotel.component.scss"],
  templateUrl: "./hotel.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class HotelComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() search: {
    data: SearchBody;
  };
  @Input() seeOutOfPolicy: boolean;
  @ViewChild("map") mapElement: ElementRef;
  @ViewChild("searchHereBtn") searchHereBtn: ElementRef;
  @Output() selectFare: EventEmitter<any> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Output() toggleHeader: EventEmitter<boolean> = new EventEmitter();
  @Output() sideBarOpen: EventEmitter<boolean> = new EventEmitter();
  public hotelsLoading: boolean = false;
  public options: L.MapOptions;
  public hotels: BehaviorSubject<Hotel[]> = new BehaviorSubject<Hotel[]>([]);
  public _hotels: Hotel[] = [];
  private accomsToLoad: string[] = [];
  public hotelAvailabilities: any;
  public selectedHotel: Hotel;
  public filterForm: UntypedFormGroup;
  public displayFilters: boolean = false;
  public lastFiltersUpdate: Date;
  public runningFilters: { label: string; value: any; type: string }[];
  public stuffOptions: FilterOption[];
  public facilityOptions: Facility[];
  public refundableExist: boolean = false;
  public outOfPolicyExist: boolean = false;
  public atleastOneInPolicy: boolean = false;
  public starsOptions: Array<string> = ["1", "2", "3", "4", "5"];
  public filterStats: any = {
    stars: {},
    options: {},
    cancellable: null,
  };
  public priceRange: { min: number; max: number } = {
    min: 0,
    max: 0,
  };
  rangeValuesCheckIn: number[] = [0, 48];
  rangeValuesCheckOut: number[] = [0, 48];
  CheckInFrom: string;
  CheckInTo: string;
  CheckOutFrom: string;
  CheckOutTo: string;
  public reducedFilterPanel: boolean = false;
  public panelClass: string;
  public nbNights: number;
  public nbRooms: number;
  public sortOptions: any[];
  public sortOption: string = "distance";
  public isECF?: { [accomcode: string]: boolean } = {};
  private map: L.Map;
  private centerMarker: L.Marker;
  private searchPosition: { longitude: number; latitude: number };
  private lastPosition: { longitude: number; latitude: number };
  private lastMaxDistance: number;
  private _markerComponents: Array<{
    popup: L.Popup;
    component: ComponentRef<MarkupHotelComponent>;
    id: string;
  }>;
  private adaptZoom: boolean = true;
  private maximumAmountPolicy: number;
  private searchDistOffset = 5000;
  private availabilitiesInPolicy: number = 0;
  // search in this area booleans
  public searching: Boolean = false;
  public hideButton: Boolean = true;
  //
  selectedTime: number = 0;

  constructor(
    public elementRef: ElementRef,
    private hotelService: HotelService,
    public commonService: CommonService,
    public authorizationService: AuthorizationService,
    private injector: Injector,
    private environmentInjector: EnvironmentInjector,
    private applicationRef: ApplicationRef,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
  ) {}

  // le moins cher, le plus proche, le mieux noté
  private toRadians(degrees) {
    return (Math.PI * degrees) / 180;
  }

  private toDegrees(radians) {
    return (radians * 180) / Math.PI;
  }

  private ngUnsubscribe: Subject<void> = new Subject();

  // private maxLatLongOnBearing(centerLat, centerLong, bearing, distance) {

  //   const lonRads = this.toRadians(centerLong);
  //   const latRads = this.toRadians(centerLat);
  //   const bearingRads = this.toRadians(bearing);
  //   const maxLatRads = Math.asin(Math.sin(latRads) * Math.cos(distance / 6371) + Math.cos(latRads)
  //     * Math.sin(distance / 6371) * Math.cos(bearingRads));
  //   const maxLonRads = lonRads + Math.atan2((Math.sin(bearingRads) * Math.sin(distance / 6371) * Math.cos(latRads)),
  //     (Math.cos(distance / 6371) - Math.sin(latRads) * Math.sin(maxLatRads)));

  //   const maxLat = this.toDegrees(maxLatRads);
  //   const maxLong = this.toDegrees(maxLonRads);

  //   return { lat: maxLat, lng: maxLong };
  // }

  // private async getBoundingCoords(centerLat, centerLong, distance) {
  //   const top = this.maxLatLongOnBearing(centerLat, centerLong,45,distance);
  //   const right = this.maxLatLongOnBearing(centerLat, centerLong, 135, distance);
  //   const bottom = this.maxLatLongOnBearing(centerLat, centerLong, 225, distance);
  //   const left = this.maxLatLongOnBearing(centerLat, centerLong, 315, distance);

  //   this.searchHotels2(centerLong, centerLat, 1000).pipe(
  //     concatMap(() => this.searchHotels2(top.lng, top.lat,1000)),
  //     concatMap(() => this.searchHotels2(right.lng, right.lat,1000)),
  //     concatMap(() => this.searchHotels2(bottom.lng, bottom.lat, 1000)),
  //     concatMap(() => this.searchHotels2(left.lng, left.lat, 1000))
  //   ).subscribe()
  // }

  _selectedFare: any;

  get selectedFare(): any {
    return this._selectedFare;
  }

  @Input() set selectedFare(value: any) {
    this._selectedFare = value;
    this.updatePanelClass();
  }

  ngOnInit(): void {
    this.CheckInFrom = this.formatTime(this.rangeValuesCheckIn[0]);
    this.CheckInTo = this.formatTime(this.rangeValuesCheckIn[1]);
    this.CheckOutFrom = this.formatTime(this.rangeValuesCheckOut[0]);
    this.CheckOutTo = this.formatTime(this.rangeValuesCheckOut[1]);
    this._markerComponents = [];
    this.loading.emit(true);
    this.filterForm = this.fb.group({
      stars: null,
      rating: null,
      stuff: null,
      refundable: null,
      inPolicy: null,
      search: null,
      checkIn: [[0, 48]],
      checkOut: [[0, 48]],
      price: 0,
    });
    this.sortOptions = [
      {
        label: this.translateService.instant("SEARCH.HOTEL.SORT.PRICE"),
        value: "price",
      },
      {
        label: this.translateService.instant("SEARCH.HOTEL.SORT.DISTANCE"),
        value: "distance",
      },
      {
        label: this.translateService.instant("SEARCH.HOTEL.SORT.RATING"),
        value: "rating",
      },
    ];

    const dateIn = DateTime.fromISO(this.search.data.datein);
    const dateOut = DateTime.fromISO(this.search.data.dateout);
    this.nbNights = dateOut.diff(dateIn, "days").days;
    this.nbRooms = this.search.data.rooms.length;

    this.hotels.next([]);
    this.stuffOptions = [];

    this.hotelAvailabilities = {};
    const [longitude, latitude] = this.search.data.completeAddress.coordinates;
    this.searchPosition = {
      longitude,
      latitude,
    };
    this.availabilitiesInPolicy = 0;
    this.searchHotels(longitude, latitude, BASE_SEARCH_DIST, 50);
    const stuff = new Set(this.filterForm.controls["stuff"].value);
    if (this.search.data.breakfast) {
      stuff.add("BREAKFAST");
    }
    this.filterForm.patchValue({
      stars: Object.entries(this.search.data.stars)
        .filter(([star, value]: [string, any]) => !!value)
        .map(([star, value]: [string, any]) => "" + star),
      refundable: this.search.data.cancellable,
      stuff: Array.from(stuff),
    });
    this.filterForm.valueChanges.subscribe(() => {
      this.applyFilters();
    });

    this.generateMarkers();
  }

  ngAfterViewInit(): void {
    const layer: L.TileLayer = L.tileLayer("http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
      // maxZoom: 18,
      attribution: "...",
      subdomains: ["mt0", "mt1", "mt2", "mt3"],
    });

    const [latitude, longitude] = this.search.data.completeAddress.coordinates;

    this.centerMarker = L.marker([latitude, longitude], {
      icon: L.icon({
        iconUrl: "./assets/marker-icon.png",
        iconSize: [50, 50],
        iconAnchor: [25, 100],
      }),
    });

    const mapOptions: L.MapOptions = {
      center: [longitude, latitude],
      layers: [layer, this.centerMarker],
      zoomControl: false,
    };
    if (this.commonService.isTablet) {
      mapOptions.zoomControl = false;
      mapOptions.dragging = false;
      mapOptions.scrollWheelZoom = false;
      mapOptions.doubleClickZoom = false;
      mapOptions.touchZoom = false;
      mapOptions.tap = false;
    }

    if (this.mapElement) {
      this.map = L.map(this.mapElement.nativeElement, mapOptions);
      L.control
        .zoom({
          position: "topright",
        })
        .addTo(this.map);

      this.map.whenReady(() => {
        setTimeout(() => {
          this.map.invalidateSize();
          this.loading.emit(false);
        }, 0);
      });

      this.map.on("dragend", () => {
        if (this.hideButton) {
          this.lastMaxDistance = 0;
          this.searchHereBtn.nativeElement.innerHTML = "SEARCH.RESULT.HOTEL.SEARCH_IN_THIS_ZONE";
          this.lastMaxDistance = 0;
          this.hideButton = false;
        }
      });
    } else {
      this.loading.emit(false);
    }
  }

  private searchHere = () => {
    if (!this.searching) {
      this.searchHereBtn.nativeElement.innerHTML = "SEARCH.RESULT.HOTEL.SEARCHING_IN_THIS_ZONE";

      const center: L.LatLng = this.map.getCenter();

      this.searchHotels(center.lat, center.lng, this.lastMaxDistance + SEARCH_DIST_OFFSET);
      this.searching = true;
    }
  };

  private searchMore = () => {
    if (!this.searching) {
      if (this.lastMaxDistance + this.searchDistOffset > SEARCH_DIST_MAX) {
        this.hideButton = true;
      } else {
        this.searchHotels(
          this.lastPosition.longitude,
          this.lastPosition.latitude,
          this.lastMaxDistance + SEARCH_DIST_OFFSET,
          50,
        );
      }
    }
  };

  private releaseSearchHereBtn = () => {
    if (this.searching) {
      this.searching = false;
      this.hideButton = true;
    }
  };

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    this._markerComponents.forEach((object: { popup: L.Popup; component: ComponentRef<MarkupHotelComponent> }) => {
      object.component.destroy();
    });
  }

  updateHotel(hotel: Hotel): void {
    const hotelInList: Hotel = this._hotels.find((_hotel: Hotel) => _hotel.accomcode === hotel.accomcode);

    hotelInList.thumbnail = hotel.thumbnail;
    hotelInList.description = hotel.description;
    hotelInList.metadata = hotel.metadata;

    this.applyFilters();
  }

  searchHotels(
    longitude: number,
    latitude: number,
    maxDistance: number = BASE_SEARCH_DIST,
    minResults?: number,
    silenced: boolean = false,
  ): void {
    this.hotelsLoading = true;
    this.lastPosition = {
      longitude,
      latitude,
    };
    const stars: Array<number> = Object.entries(this.search.data.stars)
      .filter(([star, value]: [string, any]) => !!value)
      .map(([star]: [string, any]) => Number(star));

    this.lastMaxDistance = maxDistance;
    this.hotelService
      .searchHotels(
        longitude,
        latitude,
        maxDistance,
        this._hotels.map((hotel: Hotel) => hotel.accomcode),
        { stars },
        silenced,
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (hotels: Array<Hotel>) => {
          hotels.forEach((hotel: Hotel) => {
            const distance: number = this.calculDistance(
              this.searchPosition.latitude,
              this.searchPosition.longitude,
              hotel.coordinates[0],
              hotel.coordinates[1],
            );
            hotel.distance = distance;
          });

          const hotelsAccomcodes = this._hotels.map((hotel: Hotel) => hotel.accomcode);
          this._hotels.push(...hotels.filter((hotel: Hotel) => !hotelsAccomcodes.includes(hotel.accomcode)));
          let accoms: string[] = [],
            cityCode: string;
          let accomWithDescription;
          hotels.forEach((hotel) => {
            accoms.push(hotel.accomcode);
            cityCode = hotel.citycode;
            if (BASE_SEARCH_DIST === maxDistance && hotel.description) {
              accomWithDescription = hotel.accomcode;
            }
          });

          if (accomWithDescription) {
            this.authorizationService
              .getPolicyHotel(accomWithDescription, this.search.data.userIds)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((data: any) => {
                if (data && data.price) {
                  this.maximumAmountPolicy = data.price.max;
                  this.loadAvailabilities(accoms, cityCode, maxDistance, minResults);
                }
              });
          }
          if (!accoms) {
            this.releaseSearchHereBtn();
          } else {
            this.loadAvailabilities(accoms, null, maxDistance, minResults);
          }
        },
        error: (err) => {
          this.hotelsLoading = false;
          this.hideButton = false;
        },
      });
  }

  loadAvailabilities(
    accoms: Array<any>,
    citycode: string,
    maxDistance?: number,
    minResult?: number,
    onlyNegociatedFares: boolean = false,
  ): void {
    if (accoms.length === 0) {
      this.hotelsLoading = false;
      this.hideButton = false;
      return;
    }
    const { datein, dateout, rooms, breakfast, cancellable } = this.search.data;
    let boardType: "BB" | "BD" | "FB";
    if (breakfast) {
      boardType = "BB";
    }
    const userIds: any = rooms.map((room: any) => {
      return room.members.map((member: MemberSociety) => {
        return member.user._id;
      });
    });
    const cityCode = BASE_SEARCH_DIST === maxDistance ? citycode : undefined;
    if (cityCode && accoms && accoms.length > 0) {
      this.accomsToLoad.push(...accoms);
    } else {
      accoms.push(...this.accomsToLoad);
      this.accomsToLoad = [];
    }
    this.hotelService
      .getAvailabilities(datein, dateout, accoms, userIds, {
        boardType,
        cancellable,
        maxAmount: cityCode ? (this.maximumAmountPolicy ? this.maximumAmountPolicy * this.nbNights : null) : null,
        cityCode,
        onlyNegociatedFares,
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (data: { availabilities: any[]; sessionId: string }) => {
          if (cityCode !== undefined) {
            const unknowAccomcodes = data.availabilities
              .filter((availability) => !this._hotels.find((hotel) => hotel.accomcode === availability.code))
              .map((availability) => availability.code);
            if (unknowAccomcodes.length > 0) {
              this.hotelService
                .getHotels(unknowAccomcodes)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((_hotels: Hotel[]) => {
                  _hotels.forEach((hotel: Hotel) => {
                    const distance: number = this.calculDistance(
                      this.searchPosition.latitude,
                      this.searchPosition.longitude,
                      hotel.coordinates[0],
                      hotel.coordinates[1],
                    );
                    hotel.distance = distance;
                  });
                  const hotelsAccomcodes = this._hotels.map((hotel: Hotel) => hotel.accomcode);
                  this._hotels.push(..._hotels.filter((hotel: Hotel) => !hotelsAccomcodes.includes(hotel.accomcode)));

                  this.applyFilters();
                });
            }
          }
          data.availabilities
            .filter((_availability: any) => {
              if (this.seeOutOfPolicy === false) {
                return _availability.inPolicy;
              }
              return true;
            })
            .forEach((availability: any) => {
              if (availability.inPolicy === false) {
                this.outOfPolicyExist = true;
              }
              if (availability.inPolicy === true) {
                this.atleastOneInPolicy = true;
                this.availabilitiesInPolicy++;
              }

              availability.possibilities.forEach((possibility: any) => {
                possibility.rooms.forEach((room: any) => {
                  if (room.Refundable === "true") {
                    availability.refundable = true;
                    this.refundableExist = true;
                  }
                });
              });
              this.hotelAvailabilities[availability.code] = availability;
              if (availability.bestPrice > this.priceRange.max) {
                if (this.priceRange.max === this.filterForm.get("price").value || this.priceRange.max === 0) {
                  this.filterForm.patchValue({ price: availability.bestPrice });
                }
                this.priceRange.max = availability.bestPrice;
              }
              if (availability.bestPrice < this.priceRange.min || this.priceRange.min === 0) {
                this.priceRange.min = availability.bestPrice;
              }
            });
          if (
            minResult &&
            minResult > Object.keys(this.hotelAvailabilities).length &&
            maxDistance + SEARCH_DIST_GAP <= SEARCH_DIST_MAX
          ) {
            this.searchHotels(
              this.lastPosition.longitude,
              this.lastPosition.latitude,
              maxDistance + SEARCH_DIST_GAP,
              minResult,
              true,
            );
            if (this.lastMaxDistance + SEARCH_DIST_GAP > SEARCH_DIST_MAX) {
              this.hideButton = true;
            }
          } else {
            this.hotelsLoading = false;
          }
          if (this.lastMaxDistance + SEARCH_DIST_GAP > SEARCH_DIST_MAX) {
            this.hideButton = true;
          }
          this.applyFilters();
        },
      });
  }

  generateMarkers(): void {
    this.hotels.pipe(takeUntil(this.ngUnsubscribe)).subscribe((hotels: Array<Hotel>) => {
      if (this.mapElement) {
        hotels.forEach((hotel: Hotel, index) => {
          const availabilities: Array<any> = this.hotelAvailabilities[hotel.accomcode];
          if (availabilities && hotel.description) {
            if (this._markerComponents.find((marker: any) => marker.id === hotel.accomcode)) {
              return;
            }
            // Pour la création dynamique des éléments, voir : https://github.com/bluehalo/ngx-leaflet/issues/178#issuecomment-1532051247
            const component = createComponent(MarkupHotelComponent, {
              elementInjector: this.injector,
              environmentInjector: this.environmentInjector,
            });
            this.applicationRef.attachView(component.hostView);
            component.instance.hotel = hotel;
            component.instance.availabilities = availabilities;
            component.instance.select = this.selectHotel.bind(this);
            component.changeDetectorRef.detectChanges();
            component.instance.isSelected = false;

            const popup: L.Popup = L.popup({
              autoPan: false,
              closeOnClick: false,
              autoClose: false,
              keepInView: false,
              closeButton: false,
            })
              .setContent(component.location.nativeElement)
              .setLatLng(new L.LatLng(hotel.coordinates[1], hotel.coordinates[0]));
            this.map.openPopup(popup);
            this._markerComponents.push({
              id: hotel.accomcode,
              popup,
              component,
            });
          }
        });
        this.releaseSearchHereBtn();
        this._markerComponents.forEach((marker: any) => {
          const hotelMarker: Hotel = hotels.find((hotel: Hotel) => hotel.accomcode === marker.id);
          if (hotelMarker) {
            this.map.openPopup(marker.popup);
          } else {
            this.map.closePopup(marker.popup);
          }
        });
        if (this.map && this.adaptZoom === true) {
          this.adaptZoom = false;
          this.updateZoom();
        }
      }
      this.isECF = Object.fromEntries(
        hotels.map((hotel) => [
          hotel.accomcode,
          hotel.description?.facilities?.some((facility) => facility.code === "ECF"),
        ]),
      );
      this.stuffOptions = hotels
        .filter((hotel: Hotel) => (hotel.description ? !!hotel.description.shortFacilities : false))
        .map((hotel: Hotel) => hotel.description.shortFacilities)
        .reduce((stuffOptions: Array<any>, shortFacilities: Array<any>) => {
          shortFacilities.forEach((facility: any) => {
            const stuffOption: any = stuffOptions.find((option: any) => option.value === facility);
            if (stuffOption) {
              return;
            }
            stuffOptions.push({
              value: facility,
              label: facility,
              count: 0,
            });
          });
          return stuffOptions;
        }, this.stuffOptions);
      const allFacilities = hotels.flatMap((hotel: Hotel) => hotel.description?.facilities || []);
      const facilitiesMap: { [key: string]: any } = {};

      allFacilities.forEach((facility: any) => {
        if (!facilitiesMap[facility.label]) {
          facilitiesMap[facility.label] = facility;
        }
      });
      const uniqueFacilities = Object.values(facilitiesMap);
      this.facilityOptions = uniqueFacilities;
    });
  }

  filterFacilitiesByType(value: string): any[] {
    return this.facilityOptions.filter((f) => f.type === value);
  }

  updateZoom(): void {
    const group: L.FeatureGroup = L.featureGroup([
      ...this._markerComponents.map((marker: any) => marker.popup),
      this.centerMarker,
    ]);
    this.map.fitBounds(group.getBounds());
    const maxZoom = this.map.getMaxZoom();
    this.map.setZoom(maxZoom - 2);
  }

  selectHotel(hotel: Hotel): void {
    this.commonService.statusBarTextBlack();
    this.selectedHotel = hotel;
    if (!this.reducedFilterPanel && this.commonService.isTablet === false) {
      this.switchFilters();
    }
    this.updatePanelClass();
    this.sideBarOpen.emit(true);
  }
  hoverHotel(hotel: Hotel): void {
    if (!this.mapElement) {
      return;
    }
    this.map.panTo(new L.LatLng(hotel.coordinates[1], hotel.coordinates[0]));
    const index = this._markerComponents.findIndex((marker: any) => marker.id === hotel.accomcode);
    const leafletStyle: string = document
      .getElementsByClassName("leaflet-popup leaflet-zoom-animated")
      [index]?.getAttribute("style");
    document
      .getElementsByClassName("leaflet-popup leaflet-zoom-animated")
      [index]?.setAttribute("style", leafletStyle + "z-index: 1;");
    let marker = this._markerComponents.find((marker: any) => marker.id === hotel.accomcode);
    if (marker) {
      marker.component.instance.isSelected = true;
      marker.component.changeDetectorRef.detectChanges();
    }
  }
  unHoverHotel(hotel: Hotel): void {
    if (!this.mapElement) {
      return;
    }
    const index = this._markerComponents.findIndex((marker: any) => marker.id === hotel.accomcode);
    const leafletStyle: string = document
      .getElementsByClassName("leaflet-popup leaflet-zoom-animated")
      [index]?.getAttribute("style");
    document
      .getElementsByClassName("leaflet-popup leaflet-zoom-animated")
      [index]?.setAttribute("style", leafletStyle.substring(0, leafletStyle.length - 11)); // z-index: 1; = 11 char
    let marker = this._markerComponents.find((marker: any) => marker.id === hotel.accomcode);
    if (marker) {
      marker.component.instance.isSelected = false;
      marker.component.changeDetectorRef.detectChanges();
    }
  }

  switchPanelAndSelectFare(fare: any): void {
    this.selectedFare = fare;
    this.updatePanelClass();
    this.selectFare.emit(fare);
  }

  refresh(accomcode: string, onlyNegociatedFares: boolean = false): void {
    this.loadAvailabilities([accomcode], null, null, null, onlyNegociatedFares);
  }

  unselectedHotel(): void {
    this.commonService.statusBarTextWhite();
    this.sideBarOpen.emit(false);
    this.selectedHotel = undefined;
    this.updatePanelClass();
  }

  updatePanelClass(): void {
    let panelClass: string;
    if (this.selectedFare) {
      panelClass = "medium";
    } else if (this.selectedHotel) {
      if (this.reducedFilterPanel) {
        panelClass = "large no-filter-panel";
      } else {
        panelClass = "large";
      }
    }
    this.panelClass = panelClass;
  }

  switchFilters(): void {
    if (this.commonService.isTablet) {
      this.displayFilters = !this.displayFilters;
      this.commonService.statusBarTextBlack();
      this.toggleHeader.emit();
    } else {
      this.reducedFilterPanel = !this.reducedFilterPanel;
      this.commonService.statusBarTextWhite();
      this.updatePanelClass();
      setTimeout(() => {
        this.map.invalidateSize();
      }, 300);
    }
  }

  deleteFilter(value: any): void {
    const actualValue: any = this.filterForm.get(value.type).value;
    if (Array.isArray(actualValue)) {
      const index: number = actualValue.indexOf(value.value);
      if (index !== -1) {
        actualValue.splice(index, 1);
        this.filterForm.get(value.type).setValue(actualValue);
      }
    } else {
      this.filterForm.get(value.type).setValue(false);
    }
    this.lastFiltersUpdate = new Date();

    this.applyFilters();
  }

  applyFilters(): void {
    const runningFilters: Array<any> = [];
    const value: any = this.filterForm.value;
    if (value.stars) {
      runningFilters.push(
        ...value.stars.map((star: number) => {
          return {
            type: "stars",
            label: `${star} *`,
            value: star,
          };
        }),
      );
    }
    if (value.stuff) {
      runningFilters.push(
        ...value.stuff.map((stuff: any) => {
          return {
            type: "stuff",
            label: `SEARCH.HOTEL.FACILITIES.${stuff}`,
            value: stuff,
          };
        }),
      );
    }
    if (value.refundable) {
      runningFilters.push({
        type: "refundable",
        label: "SEARCH.HOTEL.CANCELABLE",
        value: value.refundable,
      });
    }
    if (value.inPolicy) {
      runningFilters.push({
        type: "inPolicy",
        label: "SEARCH.HOTEL.IN_POLICY",
        value: value.inPolicy,
      });
    }
    this.runningFilters = runningFilters;
    const hotels: Array<Hotel> = this._hotels
      .filter(this.filterAvailabilities)
      .filter(this.filterInPolicy)
      .filter(this.filterPrice)
      .filter(this.filterRefundable)
      .filter(this.filterStars)
      .filter(this.filterStuff)
      .filter(this.filterRating)
      .filter(this.filterTitle)
      .filter(this.filterCheckIn)
      .filter(this.filterCheckOut);

    const filterStats: any = {
      stars: {},
      options: {},
      cancellable: 0,
      inPolicy: 0,
    };
    hotels.forEach((hotel: Hotel) => {
      if (hotel.description) {
        if (hotel.description.rank) {
          if (filterStats.stars[hotel.description.rank]) {
            filterStats.stars[hotel.description.rank]++;
          } else {
            filterStats.stars[hotel.description.rank] = 1;
          }
        }
        if (hotel.description.shortFacilities) {
          hotel.description.shortFacilities.forEach((facility: any) => {
            if (filterStats.options[facility]) {
              filterStats.options[facility]++;
            } else {
              filterStats.options[facility] = 1;
            }
          });
        }
      }
      if (this.hotelAvailabilities[hotel.accomcode].refundable) {
        filterStats.cancellable++;
      }
      if (this.hotelAvailabilities[hotel.accomcode].inPolicy) {
        filterStats.inPolicy++;
      }
    });
    this.filterStats = filterStats;
    this.hotels.next(this.applyOrder(hotels));
  }

  applyOrder(hotels: Hotel[]): Array<Hotel> {
    let sortFn: (hotelA: Hotel, hotelB: Hotel) => any;
    switch (this.sortOption) {
      case "distance":
        sortFn = this.sortDistance;
        break;
      case "price":
        sortFn = this.sortPrice;
        break;
      case "rating":
        sortFn = this.sortRating;
        break;
    }
    return hotels.sort(sortFn);
  }

  private calculDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R: number = 6371; // km
    const dLat: number = this.toRad(lat2 - lat1);
    const dLon: number = this.toRad(lon2 - lon1);

    const a: number =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(this.toRad(lat1)) * Math.cos(this.toRad(lat2));
    const c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d: number = R * c;
    return d * 1000;
  }

  // Converts numeric degrees to radians
  private toRad(value: number): number {
    return (value * Math.PI) / 180;
  }

  private sortDistance = (hotelA: Hotel, hotelB: Hotel): number => {
    return hotelA.distance - hotelB.distance;
  };

  private sortPrice = (hotelA: Hotel, hotelB: Hotel): number => {
    return this.hotelAvailabilities[hotelA.accomcode].bestPrice - this.hotelAvailabilities[hotelB.accomcode].bestPrice;
  };

  private sortRating = (hotelA: Hotel, hotelB: Hotel): number => {
    return hotelB.description.rating - hotelA.description.rating;
  };

  private filterRefundable = (hotel: Hotel): boolean => {
    const value: boolean = this.filterForm.get("refundable").value;
    if (!value) {
      return true;
    } else {
      return this.hotelAvailabilities[hotel.accomcode].refundable;
    }
  };

  private filterInPolicy = (hotel: Hotel): boolean => {
    const value: boolean = this.filterForm.get("inPolicy").value;
    if (!value) {
      return true;
    } else {
      return this.hotelAvailabilities[hotel.accomcode].inPolicy === value;
    }
  };

  private filterAvailabilities = (hotel: Hotel): boolean => {
    return !!this.hotelAvailabilities[hotel.accomcode];
  };

  private filterStars = (hotel: Hotel): boolean => {
    const value: Array<any> = this.filterForm.get("stars").value;
    if (!value || value.length === 0) {
      return true;
    }
    if (hotel.description && hotel.description.rank) {
      return value.includes(hotel.description.rank.toString());
    }
    return false;
  };

  private filterStuff = (hotel: Hotel): boolean => {
    const values: Array<any> = this.filterForm.get("stuff").value;
    if (!values || values.length === 0) {
      return true;
    }
    if (hotel.description && hotel.description.shortFacilities) {
      return values.every((value: any) => hotel.description.shortFacilities.includes(value));
    }
    return false;
  };

  private filterRating = (hotel: Hotel): boolean => {
    const value: Array<any> = this.filterForm.get("rating").value;
    if (!value || value.length === 0) {
      return true;
    }
    if (hotel.description && hotel.description.rating) {
      return Number(value) <= hotel.description.rating;
    }
  };

  private filterPrice = (hotel: Hotel): boolean => {
    const value: number = this.filterForm.get("price").value;
    return value >= this.hotelAvailabilities[hotel.accomcode].bestPrice;
  };

  private filterTitle = (hotel: Hotel): boolean => {
    const value: string = this.filterForm.get("search").value;
    if (!value) {
      return true;
    }
    if (hotel.description?.title.toLowerCase().includes(value.toLowerCase())) {
      return true;
    }
  };
  private filterCheckOut = (hotel: Hotel): boolean => {
    const value = this.filterForm.get("checkOut").value;
    this.CheckOutFrom = this.formatTime(this.rangeValuesCheckOut[0]);
    this.CheckOutTo = this.formatTime(this.rangeValuesCheckOut[1]);
    if (value[0] === 0 && value[1] === 48) {
      return true;
    } else {
      if (hotel.description?.checktimes) {
        const checkOutTime = hotel.description?.checktimes["CheckOutTime"];
        if (checkOutTime) {
          if (checkOutTime.from && checkOutTime.to) {
            return (
              (value[0] <= this.convertTimeToSliderValue(checkOutTime.from) &&
                value[1] >= this.convertTimeToSliderValue(checkOutTime.to)) ||
              (value[0] >= this.convertTimeToSliderValue(checkOutTime.from) &&
                value[1] <= this.convertTimeToSliderValue(checkOutTime.to))
            );
          }
          if (!checkOutTime.from && checkOutTime.to) {
            return (
              value[0] <= this.convertTimeToSliderValue(checkOutTime.to) &&
              value[1] >= this.convertTimeToSliderValue(checkOutTime.to)
            );
          }
        }
        return true;
      }
    }
  };
  private filterCheckIn = (hotel: Hotel): boolean => {
    const value = this.filterForm.get("checkIn").value;
    this.CheckInFrom = this.formatTime(this.rangeValuesCheckIn[0]);
    this.CheckInTo = this.formatTime(this.rangeValuesCheckIn[1]);
    if (value[0] === 0 && value[1] === 48) {
      return true;
    } else {
      if (hotel.description?.checktimes) {
        const checkInTime = hotel.description?.checktimes["CheckInTime"];
        if (checkInTime) {
          if (checkInTime.from && checkInTime.to) {
            return (
              (value[0] <= this.convertTimeToSliderValue(checkInTime.from) &&
                value[1] >= this.convertTimeToSliderValue(checkInTime.from)) ||
              (value[0] >= this.convertTimeToSliderValue(checkInTime.to) &&
                value[1] <= this.convertTimeToSliderValue(checkInTime.to))
            );
          }
          if (checkInTime.from && !checkInTime.to) {
            return (
              value[0] <= this.convertTimeToSliderValue(checkInTime.from) &&
              value[1] >= this.convertTimeToSliderValue(checkInTime.from)
            );
          }
          if (!checkInTime.from && checkInTime.to) {
            return value[1] <= this.convertTimeToSliderValue(checkInTime.to);
          }
        }
      }
      return true;
    }
  };

  formatTime(value: number): string {
    // slider 24h = 48 step de 30 minutes
    const hours = Math.floor(value / 2);
    const minutes = value % 2 === 0 ? "00" : "30";
    if (minutes === "00") {
      return `${hours}h`;
    } else {
      return `${hours}h${minutes}`;
    }
  }
  public convertTimeToSliderValue(timeString: string): number {
    if (timeString !== "anytime" && timeString !== "") {
      const [hourMinute, meridiem] = timeString.split(" ");
      const [hour, minute] = hourMinute.split(":");

      let hourValue = parseInt(hour, 10);

      if (meridiem === "PM" && hourValue !== 12) {
        hourValue += 12;
      }

      const totalMinutes = hourValue * 60 + parseInt(minute, 10);
      return totalMinutes / 30;
    }
  }
}
