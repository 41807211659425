import { NgModule } from "@angular/core";

import { SharedModule } from "src/app/@shared/shared.module";
import { TagsModalComponent } from "./tags-modal.component";
import { TagsComponent } from "./tags-component/tags.component";
import { TranslateModule } from "@ngx-translate/core";

const SELECT_MEMBERS_MODULES: Array<any> = [SharedModule, TranslateModule.forChild()];

@NgModule({
  declarations: [TagsModalComponent, TagsComponent],
  imports: [...SELECT_MEMBERS_MODULES],
  exports: [TagsModalComponent, TagsComponent],
})
export class TagsModule {}
