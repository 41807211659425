import { Component, ViewEncapsulation, OnInit, Input, ChangeDetectionStrategy } from "@angular/core";
import { DateTime } from "luxon";

@Component({
  selector: "spt-travel-flight-itinerary-leg-point",
  styleUrls: ["./travel-flight-itinerary-leg-point.component.scss"],
  templateUrl: "./travel-flight-itinerary-leg-point.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TravelFlightItineraryLegPointComponent {
  @Input() name: string;
  @Input() iata: string;
  @Input() country: string;
  @Input() terminal: string;
  @Input() datetime: string;
  @Input() set time(value: string) {
    // TODO: Revoir la gestion des dates. Les dates ne doivent pas être transmises en string, mais en type Date afin de gérer son formatage dans le HTML via un pipe
    //this._time = value.replace(/(:\d{2})([+\-Z]).*/, "$1");
    this._time = value.replace(/:\d{2}([+\-Z]).*/, "").replace(/(\d+:\d+):00/, "$1");
  }
  @Input() flightNumber: string;
  @Input() travelClass: string;
  @Input() pricedOffer?: any;
  @Input() otherOffer?: any;
  @Input() departureDate: string;
  @Input() _time: string;

  constructor() {}
}
