import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { filter, first, map, concatMap } from "rxjs/operators";
import { Roles } from "../@types/society";
import { RoleService } from "../services/roles.service";
import { UserService } from "../services/user.service";
import { User } from "../@types/user";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ExtraSettingsResolver {
  constructor(
    private roleService: RoleService,
    private userService: UserService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    let user: User;

    return this.roleService.roles.pipe(
      filter((role: Roles) => !!role),
      first(),
      concatMap(() =>
        this.userService.user.pipe(
          filter((_user: User) => !!_user),
          first(),
        ),
      ),
      map((_user: User) => {
        user = _user;
        return {
          hasSelectEntity: this.roleService.hasSelectEntity(),
          hasOutOfPolicy: this.roleService.hasBookOutOfPolicy(),
          seeOutOfPolicy: this.roleService.seeOutOfPolicy(),
          hasBook: this.roleService.hasBook(),
          hasBookToHim: this.roleService.hasBookToHim(),
        };
      }),
    );
  }
}
