<div class="product-header">
  <div class="icon">
    <img class="icon spt-icon-taxi" src="./assets/svg/transfer.svg" alt="transfer icon" />
  </div>
  <div class="itinerary">
    <div class="pickup">
      {{ data.origin ? data.origin : data.travelling.pickup.name }}
    </div>
    <i class="arrow spt-icon-arrow-circle-right"></i>
    <div class="dropoff">
      {{ data.destination ? data.destination : data.travelling.dropoff.name }}
    </div>
  </div>
</div>
<div class="product-container" *ngIf="product && product.travelling">
  <p class="direction" translate>SEARCH.RESULT.TRANSFER.TRAVELLING</p>
  <div class="general-container">
    <div class="picture">
      <img
        [alt]="product.travelling.general.producttype"
        class="logo-img"
        [src]="vehicleTypes[product.travelling.general.producttypeid].imageurl"
      />
    </div>
    <div class="general">
      <div class="product-name-container">
        <span class="product-name">
          {{ product.travelling.general.producttype }}
        </span>
        <span class="product-pax">
          <i class="fas fa-users"></i>
          {{
            product.travelling.general.minpax
              ? product.travelling.general.minpax + "-" + product.travelling.general.maxpax
              : travelers.length
          }}
        </span>
        <span class="product-luggage m-l-10">
          <i class="fas fa-luggage-cart"></i>
          {{ product.travelling.general.luggage ? product.travelling.general.luggage : travelers.length }}
        </span>
        <span class="product-transfertime m-l-10">
          <i class="fas fa-clock"></i>
          ~{{ product.travelling.general.transfertime }}min
        </span>
      </div>
      <div class="product-price-container">
        <span class="product-price f-sb">
          {{
            {
              amount: product.travelling.pricing.price,
              currency: product.travelling.pricing.currency
            }
              | SPTPrice
              | async
          }}
        </span>
        <small class="vehicle-number m-l-15" translate>SEARCH.RESULT.TRANSFER.BY_VEHICLE</small>
      </div>
      <div class="grid align-items-center" *ngIf="product.travelling.general.maxpax && search">
        <label for="vehicles" class="m-0" translate>SEARCH.RESULT.TRANSFER.VEHICLES</label>
        <input
          id="vehicles"
          class="vehicles-input"
          pInputText
          type="number"
          min="1"
          max="10"
          [(ngModel)]="vehicles"
          (input)="vehiclesChange()"
        />
      </div>
      <div class="out-politic" *ngIf="product.travelling.general.isOutOfPolicy" translate>GLOBAL.OUT_OF_POLICY</div>
    </div>
  </div>
  <div class="m-b-15">
    <p class="txt-23 f-sb m-b-25" translate>SEARCH.RESULT.TRANSFER.PICKUP</p>
    <div class="grid align-items-center">
      <i [class]="searchIcons.travelling.pickup"></i>
      <p class="col-10 grid grid-nogutter">
        <span class="f-sb" translate>GLOBAL.ADDRESS</span>
        <span>{{ search ? search.data.pickup.name : data.travelling.pickup.name }}</span>
      </p>
    </div>
    <div class="grid align-items-center">
      <i class="far fa-clock fa-2x col-2"></i>
      <p class="col-10 grid grid-nogutter" *ngIf="estimatedPickupDatetime; else pickupDatetimeTemplate">
        <span class="f-sb">{{ "SEARCH.RESULT.TRANSFER.ESTIMATED" | translate }}</span>
        <span>{{ estimatedPickupDatetime | date: "short" }}</span>
        <small class="calculation"><em translate>SEARCH.RESULT.TRANSFER.CALCULATION</em></small>
      </p>
      <ng-template #pickupDatetimeTemplate>
        <p class="col-10 grid grid-nogutter">
          <span class="f-sb">
            {{
              (isTransportation.pickup ? inboundPlaceholder[pickupSearchType] : outboundPlaceholder[pickupSearchType])
                | translate
            }}
          </span>
          <span>{{ (search ? search.data.travelling : data.travelling.pickup.date) | date: "short" }}</span>
        </p>
      </ng-template>
    </div>
    <div class="grid align-items-center" *ngIf="isTransportation?.pickup">
      <i class="fas fa-info-circle fa-2x col-2"></i>
      <p class="col-10 grid grid-nogutter">
        <label for="pickupNumber" class="f-sb txt-16">
          {{ complementaryPlaceholders.number | translate }}
        </label>
        <input
          id="pickupNumber"
          class="complementary-input"
          pInputText
          [(ngModel)]="travellingNumber"
          [class.input-error]="!travellingNumber"
          (ngModelChange)="numberSet($event, 'travelling')"
          placeholder="Ex: AF123"
        />
        <ng-container *ngIf="['AIR', 'IATA'].includes(pickupSearchType)">
          <label for="pickupTerminal" class="f-sb txt-16">
            {{ "SEARCH.TRANSFER.TERMINAL" | translate }}
          </label>
          <input
            id="pickupTerminal"
            class="complementary-input"
            pInputText
            [(ngModel)]="travellingTerminal"
            placeholder="Ex: 1, A, T1"
            (ngModelChange)="terminalSet($event, 'travelling')"
          />
        </ng-container>
      </p>
    </div>
    <div
      class="grid align-items-center"
      *ngIf="product.travelling.general.producttypeid === 13 || product.travelling.general.producttypeid == 508"
    >
      <i class="fas fa-wheelchair fa-2x col-2"></i>
      <p class="col-10 grid grid-nogutter">
        <span class="f-sb" translate>SEARCH.RESULT.TRANSFER.EXTRAS</span>
        <input
          class="complementary-input"
          pInputText
          type="number"
          [(ngModel)]="wheelchairNumber"
          min="0"
          [placeholder]="'SEARCH.TRANSFER.WHEELCHAIR' | translate"
        />
      </p>
    </div>
  </div>
  <div class="m-b-15">
    <p class="txt-23 f-sb m-b-25" translate>SEARCH.RESULT.TRANSFER.DROPOFF</p>
    <div class="grid align-items-center">
      <i [class]="searchIcons.travelling.dropoff"></i>
      <p class="col-10 grid grid-nogutter">
        <span class="f-sb" translate>GLOBAL.ADDRESS</span>
        <span>{{ search ? search.data.dropoff.name : data.travelling.dropoff.name }}</span>
      </p>
    </div>
    <div class="grid align-items-center">
      <i class="far fa-clock fa-2x col-2"></i>
      <p class="col-10 grid grid-nogutter" *ngIf="estimatedDropoffDatetime; else dropoffDatetimeTemplate">
        <span class="f-sb">{{
          (isTransportation.dropoff ? outboundPlaceholder[dropoffSearchType] : inboundPlaceholder[dropoffSearchType])
            | translate
        }}</span>
        <span>{{ estimatedDropoffDatetime | date: "short" }}</span>
      </p>
      <ng-template #dropoffDatetimeTemplate>
        <p class="col-10 grid grid-nogutter">
          <span class="f-sb"
            >{{
              (isTransportation.dropoff
                ? outboundPlaceholder[dropoffSearchType]
                : inboundPlaceholder[dropoffSearchType]
              ) | translate
            }}
          </span>
          <span>{{ (search ? search.data.travelling : data.travelling.dropoff.date) | date: "short" }}</span>
        </p>
      </ng-template>
    </div>
    <div class="grid align-items-center" *ngIf="isTransportation?.dropoff">
      <i class="fas fa-info-circle fa-2x col-2"></i>
      <p class="col-10 grid grid-nogutter">
        <span class="f-sb" translate>SEARCH.RESULT.TRANSFER.COMPLEMENTARY</span>
        <ng-container>
          <label for="dropoffNumber" class="f-sb txt-16">
            {{ complementaryPlaceholders.number | translate }}
          </label>
          <input
            id="dropoffNumber"
            class="complementary-input"
            pInputText
            [(ngModel)]="travellingNumber"
            [class.input-error]="!travellingNumber"
            (ngModelChange)="numberSet($event, 'travelling')"
            placeholder="Ex: AF123"
          />
          <ng-container *ngIf="['AIR', 'IATA'].includes(dropoffSearchType)">
            <label for="dropoffTerminal" class="f-sb txt-16">
              {{ "SEARCH.TRANSFER.TERMINAL" | translate }}
            </label>
            <input
              id="dropoffTerminal"
              class="complementary-input"
              pInputText
              [(ngModel)]="travellingTerminal"
              (ngModelChange)="terminalSet($event, 'travelling')"
              placeholder="Ex: 1, A, T1"
            />
          </ng-container>
        </ng-container>
      </p>
    </div>
  </div>
  <div class="product-information col-12 grid p-t-35 p-b-35">
    <p class="txt-23 f-sb m-b-25" translate>SEARCH.RESULT.TRANSFER.INFORMATION</p>
    <p class="description justify">
      {{ product.travelling.general.description }}
    </p>
  </div>
</div>

<div class="product-container" *ngIf="(search?.data.returning || data.returning) && product?.returning">
  <p class="direction" translate>SEARCH.RESULT.TRANSFER.RETURNING</p>
  <div class="general-container">
    <div class="picture">
      <img
        [alt]="product.returning.general.producttype"
        class="logo-img"
        [src]="vehicleTypes[product.returning.general.producttypeid].imageurl"
      />
    </div>
    <div class="general">
      <div class="product-name-container">
        <span class="product-name">
          {{ product.returning.general.producttype }}
        </span>
        <span class="product-pax" *ngIf="product.returning.general.minpax">
          <i class="fas fa-users"></i>
          {{
            product.returning.general.minpax
              ? product.returning.general.minpax + "-" + product.returning.general.maxpax
              : travelers.length
          }}
        </span>
        <span class="product-luggage m-l-10" *ngIf="product.returning.general.luggage">
          <i class="fas fa-luggage-cart"></i>
          {{ product.returning.general.luggage ? product.returning.general.luggage : travelers.length }}
        </span>
        <span class="product-transfertime m-l-10">
          <i class="fas fa-clock"></i>
          ~{{ product.returning.general.transfertime }}min
        </span>
      </div>
      <div class="product-price-container">
        <span class="product-price f-sb">
          {{
            {
              amount: product.returning.pricing.price,
              currency: product.returning.pricing.currency
            }
              | SPTPrice
              | async
          }}
        </span>
        <small class="vehicle-number m-l-15" translate>SEARCH.RESULT.TRANSFER.BY_VEHICLE</small>
      </div>
      <div class="grid align-items-center" *ngIf="product.returning.general.maxpax && search">
        <label for="vehicles" class="m-0" translate>SEARCH.RESULT.TRANSFER.VEHICLES</label>
        <input
          id="returningVehicles"
          class="vehicles-input"
          pInputText
          type="number"
          min="1"
          max="10"
          [(ngModel)]="returningVehicles"
          (change)="vehiclesChange()"
        />
      </div>
      <div class="out-politic" *ngIf="product.returning.general.isOutOfPolicy" translate>GLOBAL.OUT_OF_POLICY</div>
    </div>
  </div>
  <div class="m-b-15">
    <p class="txt-23 f-sb m-b-25" translate>SEARCH.RESULT.TRANSFER.PICKUP</p>
    <div class="col grid align-items-center">
      <i [class]="searchIcons.returning.pickup"></i>
      <p class="col-10 grid grid-nogutter">
        <span class="f-sb" translate>GLOBAL.ADDRESS</span>
        <span>{{ (search ? search.data.dropoff : data.returning.pickup).name }}</span>
      </p>
    </div>
    <div class="col grid align-items-center">
      <i class="far fa-clock fa-2x col-2"></i>
      <p
        class="col-10 grid grid-nogutter"
        *ngIf="estimatedReturningPickupDatetime; else returningPickupDatetimeTemplate"
      >
        <span class="f-sb">{{ "SEARCH.RESULT.TRANSFER.ESTIMATED" | translate }}</span>
        <span>{{ estimatedReturningPickupDatetime | date: "short" }}</span>
        <small class="calculation"><em translate>SEARCH.RESULT.TRANSFER.CALCULATION</em></small>
      </p>
      <ng-template #returningPickupDatetimeTemplate>
        <p class="col-10 grid grid-nogutter">
          <span class="f-sb">
            {{
              (isTransportation.pickup ? outboundPlaceholder[pickupSearchType] : inboundPlaceholder[dropoffSearchType])
                | translate
            }}
          </span>
          <span>{{ (search ? search.data.returning : data.returning.pickup.date) | date: "short" }}</span>
        </p>
      </ng-template>
    </div>
    <div class="grid align-items-center" *ngIf="isTransportation?.dropoff">
      <i class="fas fa-info-circle fa-2x col-2"></i>
      <p class="col-10 grid grid-nogutter">
        <label for="returningPickupNumber" class="f-sb txt-16">
          {{ complementaryPlaceholders.number | translate }}
        </label>
        <input
          id="returningPickupNumber"
          class="complementary-input"
          pInputText
          [(ngModel)]="returningNumber"
          [class.input-error]="!returningNumber"
          (ngModelChange)="numberSet($event, 'returning')"
          placeholder="Ex: AF123"
        />
        <ng-container *ngIf="dropoffSearchType === 'AIR' || dropoffSearchType === 'IATA'">
          <label for="returningPickupTerminal" class="f-sb txt-16">
            {{ "SEARCH.TRANSFER.TERMINAL" | translate }}
          </label>
          <input
            id="returningPickupTerminal"
            class="complementary-input"
            pInputText
            [(ngModel)]="returningTerminal"
            placeholder="Ex: 1, A, T1"
            (ngModelChange)="terminalSet($event, 'returning')"
          />
        </ng-container>
      </p>
    </div>
  </div>
  <div class="m-b-15">
    <p class="txt-23 f-sb m-b-25" translate>SEARCH.RESULT.TRANSFER.DROPOFF</p>
    <div class="grid align-items-center">
      <i [class]="searchIcons.returning.dropoff"></i>
      <p class="col-10 grid grid-nogutter">
        <span class="f-sb" translate>GLOBAL.ADDRESS</span>
        <span>{{ (search ? search.data.pickup : data.returning.dropoff).name }}</span>
      </p>
    </div>
    <div class="grid align-items-center">
      <i class="far fa-clock fa-2x col-2"></i>
      <p
        class="col-10 grid grid-nogutter"
        *ngIf="estimatedReturningDropoffDatetime; else returningDropoffDatetimeTemplate"
      >
        <span class="f-sb">{{
          (isTransportation.pickup ? outboundPlaceholder[pickupSearchType] : inboundPlaceholder[pickupSearchType])
            | translate
        }}</span>
        <span>{{ estimatedReturningDropoffDatetime | date: "short" }}</span>
      </p>
      <ng-template #returningDropoffDatetimeTemplate>
        <p class="col-10 grid grid-nogutter">
          <span class="f-sb"
            >{{
              (isTransportation.pickup ? outboundPlaceholder[pickupSearchType] : inboundPlaceholder[pickupSearchType])
                | translate
            }}
          </span>
          <span>{{ (search ? search.data.returning : data.returning.dropoff.date) | date: "short" }}</span>
        </p>
      </ng-template>
    </div>
    <div class="grid align-items-center" *ngIf="isTransportation?.pickup">
      <i class="fas fa-info-circle fa-2x col-2"></i>
      <p class="col-10 grid grid-nogutter">
        <span class="f-sb" translate>SEARCH.RESULT.TRANSFER.COMPLEMENTARY</span>
        <label for="returningDropoffNumber" class="f-sb txt-16">
          {{ complementaryPlaceholders.number | translate }}
        </label>
        <input
          id="returningDropoffNumber"
          class="complementary-input"
          pInputText
          [(ngModel)]="returningNumber"
          [class.input-error]="!returningNumber"
          (ngModelChange)="numberSet($event, 'returning')"
          placeholder="Ex: AF123"
        />
        <ng-container *ngIf="pickupSearchType === 'AIR' || pickupSearchType === 'IATA'">
          <label for="returningDropoffTerminal" class="f-sb txt-16">
            {{ "SEARCH.TRANSFER.TERMINAL" | translate }}
          </label>
          <input
            id="returningDropoffTerminal"
            class="complementary-input"
            pInputText
            [(ngModel)]="returningTerminal"
            placeholder="Ex: 1, A, T1"
            (ngModelChange)="terminalSet($event, 'returning')"
          />
        </ng-container>
      </p>
    </div>
  </div>

  <div class="product-information grid p-t-35 p-b-35">
    <p class="txt-23 f-sb m-b-25" translate>SEARCH.RESULT.TRANSFER.INFORMATION</p>
    <p class="description justify">
      {{ product.returning.general.description }}
    </p>
  </div>
</div>

<section class="passengers-price">
  <div *ngIf="travelers.length > 0" class="passengers">
    <p class="travelers" translate>GLOBAL.TRAVELERS</p>
    <ng-container *ngFor="let user of travelers">
      <spt-avatar [user]="user" class="avatar"></spt-avatar>
    </ng-container>
  </div>

  <div class="price">
    <p class="total" translate>SEARCH.RESULT.TOTAL</p>
    <p class="amount">
      {{ price | SPTPrice | async }}
    </p>
  </div>
</section>

<div class="separator"></div>

<section class="carbon-offset">
  <spt-travel-carbon-offset [carbonOffset]="carbonOffset" [locale]="locale"></spt-travel-carbon-offset>
</section>
