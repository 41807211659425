import { map, Observable, of } from "rxjs";
import {
  SearchCriteria,
  SearchFavorite,
  SearchEngineService,
  Travel,
  SearchResult,
  SearchOptions,
  LastSearchItem,
  lastSearches,
  Search,
  WhenSearch,
  DateTimeSearch,
} from "./search-engine-service";
import { Address } from "src/app/@shared/@types/models";
import { HttpClient } from "@angular/common/http";
import { MemberSociety } from "src/app/@shared/@types/society";

export abstract class AbstractPlaceSearchService<OPTIONS_TYPE extends SearchOptions>
  implements SearchEngineService<Address, OPTIONS_TYPE>
{
  constructor(private httpClient: HttpClient) {}

  abstract getType(): "hotel" | "car" | "seminar" | "transfer";

  abstract getMaxPassengers(searchCriteria: SearchCriteria<Address, OPTIONS_TYPE>): number;

  getMinimumNumberOfCharactersForLocalitySearch(): number {
    return 1;
  }

  localitySearch(searchString: string): Observable<Address[]> {
    throw new Error("Please use place-finder instead");
  }

  fetchDetailsForLocality(locality: Address): Observable<Address> {
    throw new Error("Not implemented");
  }

  abstract launchSearch(searchCriteria: SearchCriteria<Address, OPTIONS_TYPE>): Observable<SearchResult>;

  abstract createBlankCriteria(): SearchCriteria<Address, OPTIONS_TYPE>;

  searchCriteriaIsValid(searchCriteria: SearchCriteria<Address, OPTIONS_TYPE>): boolean {
    return searchCriteria.isValid();
  }

  createDummyLocalityFromName(name: string): Address {
    return this.createLocalityFromJson({ name: name });
  }

  createLocalityFromJson(json: any): Address {
    if (!json) {
      return undefined;
    }
    return {
      place_id: json.place_id,
      street: json.street,
      city: json.city,
      postal_code: json.postal_code,
      area: json.area,
      country: json.country,
      countryCode: json.countryCode,
      coordinates: json.coordinates ? [json.coordinates[0], json.coordinates[1]] : [],
      place_id_city: json.place_id_city,
      place_id_country: json.place_id_country,
      label: json.label,
      types: json.types,
      description: json.description,
    };
  }

  lastSearches(limit: number): Observable<LastSearchItem<Address>[]> {
    return lastSearches(this.httpClient, this.getType(), limit).pipe(
      map((array) =>
        array.map((search) => {
          return new LastSearchItem(computeLastSearchLabel(search), computeTravelFromSearch(search));
        }),
      ),
    );
  }

  abstract createCriteriaFromPreviousSearch(previousSearch: any): SearchCriteria<Address, OPTIONS_TYPE>;

  abstract createCriteriaFromBasketItem(
    lastFolderItemsInBasket: any[],
    members: MemberSociety[],
  ): SearchCriteria<Address, OPTIONS_TYPE>;
}

function computeLastSearchLabel(search: Search) {
  if (search.type === "hotel") {
    const completeAddress = search.data.completeAddress;
    return completeAddress?.label || completeAddress?.description;
  }
  return search.data.pickupAddress?.label + (search.data.returnAddress ? " - " + search.data.returnAddress.label : "");
}

function computeTravelFromSearch(search: Search): Travel<Address> {
  let origin = undefined;
  let destination = undefined;

  if (search.type === "hotel") {
    destination = toAddress(search.data.completeAddress);
  } else {
    origin = toAddress(search.data.pickupAddress);
    destination = toAddress(search.data.returnAddress);
  }

  return new Travel([], new WhenSearch(new DateTimeSearch(), new DateTimeSearch()), destination, origin);
}

function toAddress(data: any): Address {
  if (!data) {
    return undefined;
  }
  return data;
}
