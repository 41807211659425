import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";

import { Hotel } from "src/app/travel/hotel/hotel";
import { HotelService } from "src/app/@shared/services/hotel.service";
import { Observable } from "rxjs";
import moment from "moment";

@Component({
  selector: "spt-basket-hotel-block",
  templateUrl: "./hotel-block.component.html",
  styleUrls: ["./hotel-block.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HotelBlockComponent {
  private _item: any;
  @Input() set item(value: any) {
    this._item = value;
    this.itemUpdated();
  }
  get item() {
    return this._item;
  }
  public hotel: Observable<Hotel>;
  public hotelTitle: string;
  public hotelAddress: string;
  public duration: number;
  public datein: Date;
  public dateout: Date;

  constructor(private hotelService: HotelService) {}

  itemUpdated(): void {
    if (this.item.detail && this.item.detail.accomcode) {
      this.hotel = this.hotelService.getDescription(this.item.detail.accomcode, this.item.formData.suppliercode, true);
    } else {
      this.hotel = undefined;
    }
    this.hotelTitle = this.item.detail.hotelTitle;
    this.hotelAddress = this.item.detail.hotelAddress;
    this.datein = new Date(this.item.detail.datein ? this.item.detail.datein.date : this.item.formData.datein);
    this.dateout = new Date(this.item.detail.dateout ? this.item.detail.dateout.date : this.item.formData.dateout);
    this.duration = moment(this.dateout).diff(this.datein, "days");
  }
}
