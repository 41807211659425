<form
  (submit)="search()"
  [style.grid-template-areas]="gridTemplateAreas"
  [style.grid-template-rows]="gridTemplateRows"
  class="form"
  [ngClass]="'travelType-' + travelType + ' ' + 'suggest-' + suggestPanelService.active.name"
  [class.suggestOpened]="suggestOpened"
>
  <div class="title">
    <div class="flex justify-content-content items-center">
      <img class="icon-travel" src="./assets/svg/{{ searchEngineType }}.svg" />
      <!-- todo sur tous les form-->
      <h1 class="label" [innerHTML]="'SEARCH.FLIGHT.TITLE' | translate"></h1>
    </div>
  </div>
  <div class="header">
    <div class="types">
      <div class="type" *ngFor="let travelTypeItem of travelTypes" [class.active]="travelType === travelTypeItem">
        <label translate>
          <input
            type="radio"
            name="type"
            [(ngModel)]="travelType"
            [value]="travelTypeItem"
            (ngModelChange)="changeType($event)"
          />
          SEARCH.{{ travelTypeItem | uppercase }}
        </label>
      </div>
    </div>
  </div>
  <ng-container *ngFor="let travel of searchCriteria.travels; let idx = index">
    <span
      *ngIf="travelType === 'multi' && idx > 0"
      [ngClass]="'separate-' + idx"
      [style.grid-area]="'separate-' + idx"
    ></span>
    <div class="trip" [style.grid-area]="'trip-' + idx" *ngIf="travelType === 'multi'">
      <div class="trip-title">{{ "SEARCH.FLIGHT.TRAVEL" | translate }} {{ idx + 1 }}</div>
      <div class="remove" [style.grid-area]="'remove-' + idx" *ngIf="idx > 0">
        <a (click)="removeTrip(idx)" translate>SEARCH.AIR.DELETE_FLIGHT</a>
      </div>
    </div>
    <spt-travel-search-autocomplete
      [placeholder]="{
        origin: 'SEARCH.AIR.PLACEHOLDER_FINDER' | translate,
        destination: 'SEARCH.AIR.PLACEHOLDER_FINDER' | translate
      }"
      [reverse]="true"
      [favoritesSuggestPanelName]="'favorites-' + idx"
      [originGridArea]="'origin-' + idx"
      [originSuggestPanelName]="'origin-' + idx"
      [destinationGridArea]="'destination-' + idx"
      [destinationSuggestPanelName]="'destination-' + idx"
      [searchService]="searchService"
      [travel]="travel"
      (onCloseSuggest)="resetSuggestPanel()"
      (onTravelChange)="checkValidity()"
      [disableFavorites]="travelType === 'multi'"
      [nextFocus]="searchDatetimeComponent"
    ></spt-travel-search-autocomplete>

    <spt-search-datetime
      #searchDatetimeComponent
      [i18n]="{
        outwardLabelDate: 'GLOBAL.DEPARTURE' | translate,
        inwardLabelDate: 'GLOBAL.INWARD' | translate,
        outwardDatePlaceholder: 'GLOBAL.DATE' | translate,
        inwardDatePlaceholder: 'GLOBAL.DATE' | translate,
        oneWaySuggestPanelTitle: searchCriteria.mainTravel.when.inward
          ? ('SEARCH.AIR.DATETIME_TITLE' | translate)
          : ('SEARCH.AIR.DATETIME_TITLE_SIMPLE' | translate)
      }"
      [maxDateCount]="maxDateCount"
      [gridArea]="'datetime-' + idx"
      [gridAreaError]="'error-' + idx"
      [suggestPanelName]="'datetime-' + idx"
      [sourceWhenCriteria]="travel.when"
      [yearRange]="yearRange"
      [timeOptions]="timeOptionsDepartsAfter"
      [timeOptionsArrivalBefore]="timeOptionsArrivalBefore"
      (onActivateSuggest)="activateSuggest($event)"
      (onCloseSuggest)="resetSuggestPanel()"
      (onDatetimeChange)="checkValidity()"
      [searchEngineType]="searchEngineType"
      [calendarSelectionMode]="searchCriteria.mainTravel.when.inward ? 'range' : 'single'"
      [styleClass]="
        hourMandatory[travel.when.outward?.date] || hourMandatory[travel.when.inward?.date] ? 'hourMandatory' : ''
      "
      [styleClassDateMandatory]="
        dateMandatory[travel.when.outward?.date] || dateMandatory[travel.when.inward?.date]?'dateMandatory' :''
      "
    ></spt-search-datetime>
  </ng-container>
  <div *ngIf="travelType === 'multi'" class="addTrip">
    <span class="separate-add-trip"></span>
    <p-button
      (click)="addTravel()"
      [label]="'SEARCH.AIR.ADD_FLIGHT' | translate"
      type="button"
      styleClass="p-button-quaternary medium"
    ></p-button>
    <span class="separate-add-trip"></span>
  </div>
  <spt-search-people
    (onActivateSuggest)="activateSuggest($event)"
    [people]="searchCriteria.mainTravel.people"
    (onPeopleListChange)="checkValidity()"
    (onCloseSuggest)="resetSuggestPanel()"
    [searchEngineType]="searchEngineType"
    [membersMax]="9"
  ></spt-search-people>
  <div class="options">
    <div class="settings-block-one">
      <div class="classes">
        <span translate>GLOBAL.CLASS.CLASS</span>

        <div class="choice">
          <p-dropdown
            name="cabinClass"
            styleClass="p-inputgroup"
            [options]="cabinClassOptions"
            [(ngModel)]="searchCriteria.options.cabinClass"
          >
            <ng-template let-option pTemplate="selectedItem">
              <p class="d-flex align-items-center baggage-item">
                <i class="d-flex align-items-center justify-content-center txt-20"
                  ><img src="./assets/svg/seat.svg"
                /></i>
                <span class="opt m-l-10">{{ option?.label }}</span>
              </p>
            </ng-template>
          </p-dropdown>
        </div>
      </div>

      <div class="baggages">
        <span translate>GLOBAL.BAGAGES</span>

        <div class="choice">
          <p-dropdown
            name="withLuggage"
            styleClass="p-inputgroup"
            [options]="withLuggageOptions"
            [(ngModel)]="searchCriteria.options.luggages"
          >
            <!--p-dropdown [options]="baggagesCount" formControlName="baggages"-->
            <ng-template let-option pTemplate="selectedItem">
              <p class="d-flex align-items-center baggage-item">
                <i class="spt-icon-baggage d-flex align-items-center justify-content-center txt-20"></i>
                <span class="opt m-l-10">{{ option?.label }}</span>
              </p>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div class="triggers submit">
    <p-button type="submit" [disabled]="!isValid" label="{{ 'SEARCH.CTA' | translate }}"></p-button>
  </div>
</form>
