<div class="link">
  <span class="round"></span>
  <ng-container *ngIf="hasReturn">
    <span class="line"></span>
    <span class="round"></span>
  </ng-container>
</div>
<div class="origin">
  <div class="locations">
    <div class="address">
      <span class="street">{{ pickup.address | titlecase }}</span
      >,
      <span class="city">{{ pickup.city | titlecase }}</span>
    </div>
  </div>
  <div class="datetime" *ngIf="hasReturn; else sameReturn">
    <i class="m-r-5 date-icon spt-icon-date"></i>
    <span class="date">
      {{ this.item.detail.pickupDate.date | localizedDate: "d MMM" | titlecase }}
    </span>
    <small class="time">
      {{ this.item.detail.pickupDate.time }}
    </small>
  </div>
  <ng-template #sameReturn>
    <div class="dates">
      <div class="from">
        <i class="m-r-5 date-icon spt-icon-date"></i>
        <span class="date">
          {{ this.item.detail.pickupDate.date | localizedDate: "d MMM" | titlecase }}
        </span>
        <small class="time">
          {{ this.item.detail.pickupDate.time }}
        </small>
      </div>
      <i class="spt-icon-arrow-right m-l-10 m-r-10 separator"></i>
      <div class="to">
        <i class="m-r-5 date-icon spt-icon-date"></i>
        <span class="date">
          {{ this.item.detail.returnDate.date | localizedDate: "d MMM" | titlecase }}
        </span>
        <small class="time">
          {{ this.item.detail.returnDate.time }}
        </small>
      </div>
    </div>
  </ng-template>
</div>
<ng-container *ngIf="hasReturn">
  <div class="destination">
    <div class="locations">
      <div class="address">
        <span class="street">{{ dropoff.address | titlecase }}</span
        >,
        <span class="city">{{ dropoff.city | titlecase }}</span>
      </div>
    </div>
    <div class="datetime">
      <i class="m-r-5 date-icon spt-icon-date"></i>
      <span class="date">
        {{ this.item.detail.returnDate.date | localizedDate: "d MMM" | titlecase }}
      </span>
      <small class="time">
        {{ this.item.detail.returnDate.time }}
      </small>
    </div>
  </div>
</ng-container>
