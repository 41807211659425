import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Subject, merge, of } from "rxjs";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { catchError, map, takeUntil, tap } from "rxjs/operators";
import { CarService } from "src/app/@shared/services/car.service";
import { CarTypes } from "./car";
import { CommonService } from "src/app/@shared/services/common.service";
import { TranslateService } from "@ngx-translate/core";
import { groupBy } from "lodash-es";
import moment from "moment";
import { Observable } from "rxjs/internal/Observable";
import { UserService } from "../../@shared/services/user.service";
import { DateTime } from "luxon";

@Component({
  selector: "spt-travel-car",
  templateUrl: "./car.component.html",
  styleUrls: ["./car.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CarComponent implements OnInit, OnDestroy {
  @Input() search: any;
  @Input() seeOutOfPolicy: boolean;
  @Output() loading: EventEmitter<any> = new EventEmitter();
  @Output() choosen: EventEmitter<any> = new EventEmitter();
  @Output() modifySearch: EventEmitter<any> = new EventEmitter();
  @Output() sidebarOpen: EventEmitter<boolean> = new EventEmitter();

  public displayedCurrency: string;
  public radius: number = 5;
  public allCars: CarTypes.Car[] = [];
  public allVendors: { name: string; isSelected: boolean }[] = [];
  public allCategories: {
    name: string;
    firstPrice: number;
    currency: string;
  }[] = [];
  public allGearbox: { name: string; count: number }[] = [];
  public allEngineTypes: { name: string; count: number }[] = [];
  public allOptions: {};
  public noResult: boolean = false;
  public noResultFilter: boolean = false;
  public reallyNoResult: boolean = false;
  public fatalError: boolean = false;
  public outOfTime: boolean = false;
  public error: string = undefined;
  public searchTimes: { pickup: Date; return: Date };
  public agencies: any;
  public isOutPolicy: boolean;

  private searchByAgency = {
    done: false,
    running: false,
    agenciesToFetch: [],
    currentProvider: [],
  };

  /** Price slider */
  public lowestPrice: number = 0;
  public highestPrice: number = 0;
  public filteringPrice: number = 100;

  /** Used to be binded with categories's checkbox */
  public selectedCategories: string[];
  /** Used to be binded with gearbox's checkbox */
  public selectedGearbox: string[];
  /** Used to be binded with options checkbox */
  public selectedOptions: string[];
  /** Used to be binded with engine types checkbox */
  public selectedEngineTypes: string[];

  public runningFilters: Array<any> = [
    {
      type: "type",
      label: "label",
      value: "value",
    },
  ];
  public sortOptions: Array<any> = [];
  public sortOption: string = "price";

  /** Displayed cars */
  public filteredCars: BehaviorSubject<CarTypes.Car[]> = new BehaviorSubject([]);
  /** Categories updates event */
  public categories: BehaviorSubject<string[]> = new BehaviorSubject([]);
  /** Vendors updates event */
  public vendors: BehaviorSubject<any[]> = new BehaviorSubject([]);
  /** Price's limit event */
  public maxPrice: BehaviorSubject<any[]> = new BehaviorSubject([]);
  /** Gearbox type event */
  public gearboxs: BehaviorSubject<any[]> = new BehaviorSubject([]);
  /** Engine type event */
  public engineTypes: BehaviorSubject<any[]> = new BehaviorSubject([]);
  /** Price event */
  public priceMaxUpdated: BehaviorSubject<number> = new BehaviorSubject(0);
  /** List of available options */
  public options: BehaviorSubject<any> = new BehaviorSubject({});
  /** Trigger change on the method to sort cars */
  public sortMethod: BehaviorSubject<any> = new BehaviorSubject(Boolean);

  public filterPanelOpened: boolean = false;

  public locale: string;

  private ngUnsubscribe: Subject<void> = new Subject();

  constructor(
    private carService: CarService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    public commonService: CommonService,
    public translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.init();

    this.displayedCurrency = this.userService.user.value.settings?.currency;
    this.sortOptions = [
      {
        label: this.translateService.instant("SEARCH.HOTEL.SORT.PRICE"),
        value: "price",
      },
      {
        label: this.translateService.instant("SEARCH.HOTEL.SORT.DISTANCE"),
        value: "distance",
      },
    ];
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  filteringCars(): void {
    merge(
      this.vendors,
      this.categories,
      this.priceMaxUpdated,
      this.gearboxs,
      this.options,
      this.sortMethod,
      this.engineTypes,
    )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        Promise.resolve().then(() => {
          const cars: Array<any> = this.allCars
            .filter((z: any) => z)
            .map((car: CarTypes.Car) => {
              if (
                !this.vendors.value.every((v: any) => !v.isSelected) &&
                !this.vendors.value.find((v: any) => v.name === car.agencyName).isSelected
              ) {
                return undefined;
              }

              if (this.categories.value.length > 0 && !this.categories.value.includes(car.carModel)) {
                return undefined;
              }

              if (
                Math.min(...car.offers.map((o: CarTypes.Offer) => o.totalPrice.amount).flat()) >= this.filteringPrice
              ) {
                return undefined;
              }

              if (
                this.gearboxs.value.length > 0 &&
                !this.gearboxs.value.find((g: any) => g.name === car.gearboxType.toLowerCase())
              ) {
                return undefined;
              }

              if (this.engineTypes.value.length > 0) {
                if (this.engineTypes.value.length > 1) {
                  return car;
                } else {
                  if (this.engineTypes.value[0].name !== this.translateService.instant("SEARCH.RESULT.CAR.ELECTRIC")) {
                    if (!car.isElectric) {
                      return car;
                    }
                  } else {
                    if (car.isElectric) {
                      return car;
                    }
                  }
                }
                return undefined;
              }

              const options: Array<any> = Object.keys(this.options.value);
              if (options.length > 0 && options.length < Object.keys(this.allOptions).length) {
                const localOffers: any = JSON.parse(
                  JSON.stringify(
                    car.offers.filter((offer: CarTypes.Offer) => {
                      return options.every((option: any) => {
                        return offer.advantages
                          .filter(({ value }: { value: boolean }) => value === true)
                          .map(({ key }: { key: string }) => key)
                          .includes(option);
                      });
                    }),
                  ),
                );

                if (localOffers.length === 0) {
                  return undefined;
                }

                return {
                  ...car,
                  offers: localOffers,
                  startPrice: {
                    amount: Math.floor(Math.min(...localOffers.map((c: any) => c.totalPrice.amount))) + 1,
                    currency: localOffers[0].totalPrice.currency,
                  },
                };
              }

              return car;
            });

          setTimeout(() => {
            const orderedCars: Array<CarTypes.Car> = this.applyOrder(
              cars.filter((car: CarTypes.Car) => car !== undefined),
            );
            this.groupCarGearBox(orderedCars);
            this.filteredCars.next(orderedCars);
          }, 1);
        });
      });
  }

  /**
   * Funtion use to handle HTTP error while searching cars
   */
  errorHandler(data: any): Observable<any> {
    if (this.allCars.length) {
      this.handleCars();
    } else {
      if (data && data.error && data.error.code === "CAR_SEARCH_NO_AVAIBILITIES") {
        this.noResult = true;
      } else if (data && data.error && data.error.code === "PICKUP_TIME_BEFORE_CURRENT_LOCAL_TIME") {
        this.outOfTime = true;
      } else if (data && data.error && data.error.code === "CAR_SABRE_ALL_SEARCH_REJECTED") {
        if (this.radius < 20) {
          this.radius += 5;
          this.init();
        } else {
          this.noResult = false;
          this.reallyNoResult = true;
        }
      } else {
        this.fatalError = true;
      }
    }

    return of([]);
  }

  private handleCars(): void {
    if (!this.allCars || this.allCars.length === 0) {
      return;
    }

    this.filteringCars();

    const allPrices: Array<number> = [...this.allCars]
      .map((c: CarTypes.Car) => c.offers.map((o: CarTypes.Offer) => o.totalPrice.amount))
      .flat();

    this.lowestPrice = Math.floor(Math.min(...allPrices)) + 1;
    this.highestPrice = Math.floor(Math.max(...allPrices)) + 1;
    this.filteringPrice = this.highestPrice;

    this.filteredCars.next(this.allCars);
  }

  /** INITIALIZER */
  init(agencySearch?: CarTypes.SearchByAgency): void {
    const searchId: string = (this.activatedRoute.params as any).value["search-id"];
    this.loading.emit(true);
    this.locale = this.userService.user.value.settings.language;

    const {
      data: {
        pickupDate,
        returnDate,
        pickupTime,
        returnTime,
        pickupAddress,
        returnAddress,
        returnAddressSameAsPickupAddress,
      },
    } = this.search;

    const pickupCoordinates: any = pickupAddress.coordinates;
    const returnCoordinates: any = returnAddressSameAsPickupAddress
      ? pickupAddress.coordinates
      : returnAddress.coordinates;

    this.carService
      .search(
        searchId,
        pickupCoordinates,
        returnCoordinates,
        moment(pickupDate).format("YYYY-MM-DD"),
        `${pickupTime.toString().padStart(2, "0")}:00`,
        moment(returnDate).format("YYYY-MM-DD"),
        `${returnTime.toString().padStart(2, "0")}:00`,
        this.radius,
        agencySearch,
      )
      .pipe(
        map((data: any) => {
          // If user has the right to see out of policy offer, pass to the next step
          if (this.seeOutOfPolicy) {
            return data;
          }
          return {
            cars: data.cars.filter((car: any) => {
              car.offers = car.offers.filter((offer: any) => {
                return offer.inPolicy === true;
              });

              return car.offers.length > 0;
            }),
            agencies: data.agencies,
          };
        }),
        map((data: any) => {
          const _cars: Array<CarTypes.Car> = data.cars
            .filter((car: CarTypes.Car) => {
              if (!this.checkOperationTimes(car.pickup.agency.PickUp.schedule, "pickup")) {
                return false;
              }

              return this.checkOperationTimes(car.return.agency.DropOff.schedule, "return");
            })
            .filter((z: any) => !!z);

          if (data.cars.length > 0 && _cars.length === 0) {
            this.error = "SEARCH.RESULT.NO_RESULT_CARS_CRITERIA";
          }
          return {
            ...data,
            cars: _cars,
          };
        }),
        tap(({ cars, agencies }: { cars: Array<CarTypes.Car>; agencies: Array<CarTypes.Agency> }) => {
          // if it's SearchByAgency don't rewrite the agencies
          if (!agencySearch) {
            this.agencies = agencies;
          }

          if (cars.length === 0) {
            this.noResult = true;

            if (this.radius < 20) {
              this.radius += 5;
              this.init();
            } else {
              this.noResult = false;
              this.reallyNoResult = true;
              this.loading.emit(false);
            }

            return;
          }

          try {
            if (!this.searchByAgency.done && !this.searchByAgency.running && !this.reallyNoResult) {
              const carsPerSupplier = cars.reduce((acc, car) => {
                if (!acc[car.agencyName]) {
                  acc[car.agencyName] = 1;
                } else {
                  acc[car.agencyName] += 1;
                }
                return acc;
              }, []);
              this.searchByAgency.agenciesToFetch = Object.keys(carsPerSupplier)
                .filter((supplier) => {
                  return carsPerSupplier[supplier] < 3;
                })
                .map((supplierName) => {
                  const agenciesRef = Object.values(agencies[supplierName])
                    .map((agency: any) => {
                      if (typeof agency !== "string") {
                        return {
                          vendor: agencies[supplierName].vendorCode,
                          locationCode: agency.locationCode,
                          extendedLocationCode: agency.extendedLocationCode,
                          isDropOff: agency.DropOff ?? false,
                          isPickUp: agency.PickUp ?? false,
                          returnAddressSameAsPickupAddress: returnAddressSameAsPickupAddress,
                        };
                      }
                      // Returning undefined for string values
                      return undefined;
                    })
                    .filter(Boolean); // Filter out undefined values

                  // Using the supplierName as the key directly in the array
                  return agenciesRef;
                });
            }
            if (this.searchByAgency.agenciesToFetch.length && !this.searchByAgency.done) {
              this.searchByAgency.running = true;

              if (!this.searchByAgency.currentProvider.length && this.searchByAgency.agenciesToFetch.length) {
                this.searchByAgency.currentProvider = this.searchByAgency.agenciesToFetch.shift();
              }

              let nextSearch;
              if (returnAddressSameAsPickupAddress) {
                nextSearch = this.searchByAgency.currentProvider.shift();
              } else {
                nextSearch = this.searchByAgency.currentProvider;
              }
              if (nextSearch) {
                this.init(nextSearch);
                if (!returnAddressSameAsPickupAddress) {
                  this.searchByAgency.currentProvider.length = 0;
                }
              }
            }
            if (!this.searchByAgency.agenciesToFetch[0]) {
              this.searchByAgency.done = true;
              this.searchByAgency.running = false;
            }
          } catch (error) {
            console.log(error);
          }

          this.noResult = false;

          // Copy all the cars to keep the totality

          cars = [...this.allCars, ...cars];

          const uniqueCarsMap = new Map();

          cars.forEach((car) => {
            const { identityHash } = car;
            uniqueCarsMap.set(identityHash, car);
          });

          this.allCars = Array.from(uniqueCarsMap.values());

          // Compute all categories
          this.allCategories = Array.from(new Set(cars.map((car: CarTypes.Car) => car.carModel)))
            .filter((z: string) => z)
            .map((name: string): { name: string; firstPrice: number; currency: string } => {
              const firstPrice: number = Math.floor(
                Math.min(
                  ...cars
                    .filter((car: CarTypes.Car) => car.carModel === name)
                    .map((c: CarTypes.Car) => c.offers.map((o: CarTypes.Offer) => o.totalPrice.amount))
                    .flat(),
                ),
              );
              const currency: string = cars.find((car: CarTypes.Car) => car.carModel === name).offers[0].totalPrice
                .currency;

              return {
                name,
                firstPrice,
                currency,
              };
            })
            .filter((categorie: { firstPrice: number; name: string; currency: string }) => categorie.name !== "N/A");

          // Compute all engines
          this.allEngineTypes = [
            {
              name: this.translateService.instant("SEARCH.RESULT.CAR.ELECTRIC"),
              count: this.allCars.filter((c: CarTypes.Car) => c.isElectric).length,
            },
            {
              name: this.translateService.instant("SEARCH.RESULT.CAR.THERMAL"),
              count: this.allCars.filter((c: CarTypes.Car) => !c.isElectric).length,
            },
          ];

          // Compute the list of available vendors
          this.allVendors = Array.from(new Set(cars.map((car: CarTypes.Car) => car.agencyName)))
            .filter((z: string) => z)
            .map((name: string): { name: string; isSelected: boolean } => ({
              name,
              isSelected: false,
            }));

          // Define which gearbox are available
          this.groupCarGearBox(cars);

          // Define which options are available
          this.allOptions = {};

          cars
            .map((c: CarTypes.Car) => c.offers.map((o: CarTypes.Offer) => o.advantages))
            .flat()
            .forEach((options: Array<any>) => {
              options.forEach((option: any) => {
                if (!this.allOptions[option.key]) {
                  this.allOptions[option.key] = option.value ? 1 : 0;
                } else {
                  this.allOptions[option.key] = this.allOptions[option.key] + (option.value ? 1 : 0);
                }
              });
            });

          // Fill pipeline with computed data
          this.categories.next([]);
          this.vendors.next(this.allVendors);
          this.gearboxs.next(this.allGearbox);
          this.options.next(this.allOptions);

          if (this.error) {
            return;
          }
        }),
        catchError(this.errorHandler.bind(this)),
      )
      .subscribe(({ cars }: { cars: CarTypes.Car[] }) => {
        if (!cars || cars.length === 0) {
          return;
        }
        this.filteringCars();
        this.loading.emit(false);

        const allPrices: Array<number> = cars
          .map((c: CarTypes.Car) => c.offers.map((o: CarTypes.Offer) => o.totalPrice.amount))
          .flat();
        this.lowestPrice = Math.floor(Math.min(...allPrices)) + 1;
        this.highestPrice = Math.floor(Math.max(...allPrices)) + 1;
        this.filteringPrice = this.highestPrice;
        this.error = undefined;
      });

    this.searchTimes = {
      pickup: DateTime.now().set({ hour: this.search.data.pickupTime }).startOf("hour").toJSDate(),
      return: DateTime.now().set({ hour: this.search.data.returnTime }).startOf("hour").toJSDate(),
    };
  }

  groupCarGearBox(cars: CarTypes.Car[]) {
    const groupedValue: any = groupBy(cars, "gearboxType");
    this.allGearbox = Object.keys(groupedValue).map((key: string) => ({
      name: key.toLowerCase(),
      count: groupedValue[key].length,
    }));
  }

  checkOperationTimes(schedule, way: string): boolean {
    if (schedule.OperationTimes) {
      return schedule.OperationTimes.OperationTime.find((operationTime) => {
        return (
          this.search.data[`${way}Time`] >= parseInt(operationTime.Start.slice(0, 2), 0) &&
          this.search.data[`${way}Time`] <= parseInt(operationTime.End.slice(0, 2), 0)
        );
      });
    } else if (schedule.StartDate) {
      return moment(`${this.search.data[`${way}Date`]}T${this.search.data[`${way}Time`]}:00:00`).isAfter(
        moment(schedule.StartDate),
      );
    } else {
      throw new Error("Unsupported OperationTimes");
    }
  }

  /**
   * DOM METHODS
   */
  selectVendor(vendorName: string): void {
    const computedVendors: Array<{ isSelected: boolean; name: string }> = this.allVendors.map(
      (vendor: { isSelected: boolean; name: string }) => {
        if (vendor.name === vendorName) {
          vendor.isSelected = !vendor.isSelected;
        }

        return vendor;
      },
    );

    this.vendors.next(computedVendors);
  }

  selectCategory(): void {
    const currentCategories: Array<any> = this.allCategories.filter((category: any) =>
      this.selectedCategories.includes(category.name),
    );
    this.categories.next(currentCategories.map((c: any) => c.name));
  }

  selectGearboxType(): void {
    const currentGearbox: any = this.allGearbox.filter((g: any) => this.selectedGearbox.includes(g.name));
    this.gearboxs.next(currentGearbox);
  }

  selectOption(): void {
    const filtered: Array<any> = Object.keys(this.allOptions)
      .filter((key: string) => this.selectedOptions.includes(key))
      .reduce((obj: any, key: string) => {
        obj[key] = this.allOptions[key];
        return obj;
      }, {});

    this.options.next(filtered);
  }

  selectEngineType(): void {
    const currentEngine: any = this.allEngineTypes.filter((g: any) => this.selectedEngineTypes.includes(g.name));
    this.engineTypes.next(currentEngine);
  }

  selectMaxPrice(price: any): void {
    if (price.value === this.lowestPrice) {
      this.filteringPrice++;
    } else if (price.value === this.highestPrice) {
      this.filteringPrice--;
    }

    this.priceMaxUpdated.next(price.value);
  }

  carSelected({ car, offer }: { car: CarTypes.Car; offer: CarTypes.Offer }): void {
    const choosenCar: any = JSON.parse(JSON.stringify(car));
    delete choosenCar.offers;
    choosenCar.offer = JSON.parse(JSON.stringify(offer));

    this.isOutPolicy = !choosenCar.offer.inPolicy;
    this.choosen.emit(choosenCar);
  }

  changeSortMethod(): void {
    this.sortMethod.next(true);
  }

  applyOrder(cars: any[]): Array<CarTypes.Car> {
    let sortFn: (carA: CarTypes.Car, carB: CarTypes.Car) => number;

    switch (this.sortOption) {
      case "distance":
        sortFn = this.sortDistance;
        break;
      case "price":
        sortFn = this.sortPrice;
        break;
    }

    return cars.sort(sortFn);
  }

  switchFilterPanel(): void {
    this.filterPanelOpened = !this.filterPanelOpened;
    if (this.filterPanelOpened) {
      this.sidebarOpen.emit(true);
      this.commonService.statusBarTextBlack();
    } else {
      this.sidebarOpen.emit(false);
      this.commonService.statusBarTextWhite();
    }
  }

  private sortDistance = (carA: CarTypes.Car, carB: CarTypes.Car): number => {
    const dA: number = !Number.isNaN(parseFloat(carA.pickup.agencyDistance))
      ? parseFloat(carA.pickup.agencyDistance)
      : 999;
    const dB: number = !Number.isNaN(parseFloat(carB.pickup.agencyDistance))
      ? parseFloat(carB.pickup.agencyDistance)
      : 999;

    if (dA < dB) {
      return -1;
    } else {
      return 1;
    }
  };

  private sortPrice = (carA: CarTypes.Car, carB: CarTypes.Car): number => {
    if (carA.offers[0].totalPrice.amount > carB.offers[0].totalPrice.amount) {
      return 1;
    } else {
      return -1;
    }
  };
}
