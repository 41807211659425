import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { HomeComponent } from "./home/home.component";
import { DashboardComponent } from "./dashboard.component";
import { AuthGuard } from "../@shared/services/auth-guard.service";
import { UserResolver } from "../@shared/resolvers/user.resolver";
import { RoleResolver } from "../@shared/resolvers/roles.resolver";
import { SocietyAndMembersResolver } from "../@shared/resolvers/society-and-members.resolver";
import { BillingsResolver } from "../@shared/resolvers/billings.resolver";

const routes: Routes = [
  {
    path: "",
    component: DashboardComponent,
    children: [
      {
        path: "",
        component: HomeComponent,
        data: { backgroundHeader: true, widthContent: "1020px" },
        resolve: {
          user: UserResolver,
          society_and_members: SocietyAndMembersResolver,
          role: RoleResolver,
        },
      },
      {
        path: "management",
        data: { displayHeaderMobile: false, backgroundHeader: true },
        loadChildren: () => import("./management/management.module").then((x) => x.ManagementModule),
        canActivateChild: [AuthGuard],
      },
      {
        path: "settings",
        data: { backgroundHeader: true },
        loadChildren: () => import("./settings/settings.module").then((x) => x.SettingsModule),
        canActivateChild: [AuthGuard],
        resolve: { user: UserResolver },
      },
      {
        path: "baskets",
        data: { displayHeaderMobile: false, backgroundHeader: true },
        loadChildren: () => import("./baskets/baskets.module").then((x) => x.BasketsModule),
      },
      {
        path: "reporting",
        loadChildren: () => import("./reporting/reporting.module").then((x) => x.ReportingModule),
        canActivateChild: [AuthGuard],
        data: { widthContent: "1030px" },
        resolve: {
          user: UserResolver,
          society_and_members: SocietyAndMembersResolver,
          billings: BillingsResolver,
          role: RoleResolver,
        },
      },
      /*{
        path: "reporting",
        component: DisabledReportingComponent,
        data: { widthContent: "1030px" }
      }*/
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRouting {}
