<div class="location">
  <i class="spt-icon-hotel"></i>
  <span> Hotel : {{ hotel?.description?.city }}</span>
</div>

<div class="main">
  <div class="picture-info">
    <div class="title-map">
      <h2 class="title">{{ hotel?.description?.title }}</h2>
      <div class="mapHotel">
        <div class="address">
          <img src="./assets/svg/markup-black.svg" />
          <span>{{ hotel?.description?.address }}</span>
        </div>
        <div class="leaflet-map" #leafletMap></div>
        <a *ngIf="googleMapsUrl" class="googleMapLink" href="{{ googleMapsUrl }}" target="_blank" translate
          >RESERVATIONS.HOTEL.OPEN_MAPS_DESKTOP</a
        >
      </div>
    </div>
    <div class="hotel-picture">
      <img
        *ngIf="commonService.isTabletObservable | async"
        class="rounded-figure"
        src="./assets/svg/rounded-figure-mobile.svg"
        alt=""
      />
      <img
        *ngIf="(commonService.isTabletObservable | async) === false"
        class="rounded-figure"
        src="./assets/svg/rounded-figure.svg"
        alt=""
      />
      <ng-container *ngIf="hotel?.description?.images?.[0]?.url">
        <img class="picture" [src]="hotel.description.images[0].url" alt="" />
      </ng-container>
    </div>
  </div>

  <span class="description"> {{ hotel?.description?.description }}</span>
  <div class="separateur"></div>

  <div class="dates-descriptionRooms">
    <div class="date-picture">
      <img class="moon-icon" src="./assets/svg/moon.svg" alt="" />
      <div class="datein">
        <div class="day-month">{{ datein | localizedDate: "dd" }} {{ datein | localizedDate: "MMM" }}</div>
        <div class="hour">{{ hotel.description?.checktimes?.CheckInTime?.from }}</div>
      </div>
    </div>
    <i class="spt-icon-arrow-circle-right"></i>
    <div class="date-picture">
      <img class="moon-icon" src="./assets/svg/moon.svg" alt="" />
      <div class="dateout">
        <div class="day-month">{{ dateout | localizedDate: "dd" }} {{ dateout | localizedDate: "MMM" }}</div>
        <div class="hour">
          {{ hotel.description?.checktimes?.CheckOutTime.to }}
        </div>
      </div>
    </div>
    <div class="vertical-separateur"></div>
    <div class="rooms">
      <div class="room" *ngFor="let room of rooms">
        <span>{{ room.RoomDescription }}</span>
        <ul>
          <li class="type">
            {{ "SEARCH.RESULT.HOTEL.TYPE_BREAKFAST." + room.boardType | translate }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="carbon-offset">
    <ng-container *ngIf="carbonOffset; else carbonOffsetUnavailable">
      <spt-travel-carbon-offset [carbonOffset]="carbonOffset"></spt-travel-carbon-offset>
    </ng-container>

    <ng-template #carbonOffsetUnavailable>
      <i class="spt-icon-carbon-offset"></i>
      <span translate>CARBON_OFFSET.UNAVAILABLE</span>
    </ng-template>
  </div>
  <div class="cancellationPolicy">
    <h2 translate>SEARCH.RESULT.HOTEL.REFUNDS.REFUND_TEXT_POLICY</h2>
    <div *ngFor="let room of rooms" class="room-resume">
      <div class="description-price">
        <div class="dot"></div>
        <div class="descriptionRoom">
          {{ room.RoomDescription }}
        </div>
        <div class="priceRoom">
          {{ room.price | currency: "EUR" }}
        </div>
      </div>
      <div class="conditions-content">
        <ul>
          <li class="refundable">
            {{
              "SEARCH.RESULT.HOTEL.FILTERS." + (cancellationPolicy !== null ? "CANCELLABLE" : "NON_CANCELLABLE")
                | translate
            }}
          </li>
        </ul>
        <div class="conditions-content-detail">
          <ng-container *ngIf="cancellationPolicy">
            {{ cancellationPolicy?.cfps[0] | refundText: datein : price.amount }}
          </ng-container>
          <ng-container *ngIf="cancellationPolicy === null">
            {{ "SEARCH.RESULT.HOTEL.REFUNDS.NO_REFUND" | translate }}
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="travelers">
  <h2>
    {{ travelerLabel | translate }}
    :
  </h2>
  <div class="avatars">
    <spt-avatar
      *ngFor="let traveler of travelers"
      [user]="traveler"
      title="{{ traveler.firstname }} {{ traveler.lastname }}"
    ></spt-avatar>
  </div>
</div>
<div class="condition">
  <div class="title" translate>SEARCH.RESULT.AIR.BEFORE_RESA.OBLIGATION_BUYING</div>
  <span translate>SEARCH.HOTEL.CONDITION</span>
</div>
<div class="cta flex justify-content-center items-center add-basket">
  <div class="cta-wrapper">
    <div class="price">
      <div class="total-price">
        <span class="previous">{{ "SEARCH.RESULT.TOTAL" | translate }} :</span>
        <span class="total">
          {{ price | SPTPrice | async }}
        </span>
      </div>
      <spt-out-of-policy
        [isOutOfPolicy]="inPolicy === false"
        [authorizationsByPolicies]="authorizationsByPolicies"
        position="top"
        type="hotel"
      ></spt-out-of-policy>
    </div>

    <p-button type="button" [label]="'GLOBAL.ADD_TO_BASKET' | translate" (click)="prebook.emit()"> </p-button>
  </div>
</div>
