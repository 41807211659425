<div class="trip" *ngFor="let trip of item.detail.trips; let last = last">
  <ng-container *ngIf="item.type === 'flight' && item.provider === 'afkl'; else sabreItem">
    <div class="legs">
      <div class="horizontal-separator" *ngIf="(commonService.isTabletObservable | async) === false && i > 0"></div>
      <div class="leg" [class.round]="item.detail.trips.length >= 2">
        <div class="link">
          <div class="dot" [class.return]="isOdd"></div>
          <div class="line"></div>
          <div class="dot" [class.return]="isEven"></div>
        </div>
        <div class="locations" [class.round]="item.detail.trips.length >= 2">
          <div
            class="direction"
            *ngIf="(commonService.isTabletObservable | async) === false && item.detail.trips.length >= 2"
          >
            <ng-container>
              {{ last === false ? ("GLOBAL.DEPARTURE" | translate) : ("GLOBAL.RETURN" | translate) }}
            </ng-container>
            <ng-container *ngIf="item.detail.trips.length > 2">
              {{ "GLOBAL.FLIGHT" | translate }} {{ i + 1 }}
            </ng-container>
          </div>
          <div class="origin">
            <div class="adress">
              <span class="airport">{{ item.detail.airports[trip.Dep.IATALocationCode]?.name }}</span>
              <span class="city">, {{ item.detail.airports[trip.Dep.IATALocationCode]?.city }}</span>
            </div>
            <ng-container *ngIf="item.detail.trips.length < 2">
              <div class="datetime departure">
                <i class="spt-icon-date date-icon"></i>
                <span class="date">{{ trip.Dep.date | localizedDate: "d MMM" | titlecase }}</span>
                <span class="time">{{ trip.Dep.AircraftScheduledDateTime | slice: 11 : 16 }}</span>
              </div>
            </ng-container>
          </div>
          <i
            class="m-l-5 m-r-5 spt-icon-arrow-circle-right arrow-right ng-star-inserted"
            *ngIf="(commonService.isTabletObservable | async) === false && item.detail.trips.length >= 2"
          ></i>
          <div class="destination">
            <div class="adress">
              <span class="airport">{{
                item.detail.airports[trip.Arrival.IATALocationCode]?.name || trip.Arrival.IATALocationCode
              }}</span>
              <span class="city">, {{ item.detail.airports[trip.Arrival.IATALocationCode]?.city }}</span>
            </div>
            <ng-container *ngIf="item.detail.trips.length < 2">
              <ng-container *ngIf="last === false; else lastdateTime">
                <div class="datetime">
                  <i class="spt-icon-date date-icon"></i>
                  <span class="date">{{ trip.Arrival.date | localizedDate: "d MMM" | titlecase }}</span>
                  <span class="time">{{ trip.Arrival.AircraftScheduledDateTime | slice: 11 : 16 }}</span>
                </div>
              </ng-container>
              <ng-template #lastdateTime>
                <div class="datetime">
                  <i class="spt-icon-date date-icon"></i>
                  <span class="date">{{ trip.Arrival.date | localizedDate: "d MMM" | titlecase }}</span>
                  <span class="time">{{ trip.Arrival.AircraftScheduledDateTime | slice: 11 : 16 }}</span>
                </div>
              </ng-template>
            </ng-container>
          </div>
        </div>
        <div class="datetime" *ngIf="item.detail.trips.length >= 2">
          <i class="spt-icon-date date-icon"></i>
          <span class="date">{{ trip.Dep.date | localizedDate: "d MMM" | titlecase }}</span>
          <span class="time">{{ trip.Dep.AircraftScheduledDateTime | slice: 11 : 16 }}</span>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #sabreItem>
    <div class="legs">
      <ng-container *ngFor="let leg of trip.legs; let i = index; let isOdd = odd; let isEven = even; let isLast = last">
        <div class="horizontal-separator" *ngIf="(commonService.isTabletObservable | async) === false && i > 0"></div>
        <div class="leg" [class.round]="trip.legs.length >= 2">
          <div class="link">
            <div class="dot" [class.return]="isOdd"></div>
            <div class="line"></div>
            <div class="dot" [class.return]="isEven"></div>
          </div>
          <div class="locations" [class.round]="trip.legs.length >= 2">
            <div
              class="direction"
              *ngIf="(commonService.isTabletObservable | async) === false && trip.legs.length >= 2"
            >
              <ng-container *ngIf="trip.legs.length === 2">
                {{ i === 0 ? ("GLOBAL.DEPARTURE" | translate) : ("GLOBAL.RETURN" | translate) }}
              </ng-container>
              <ng-container *ngIf="trip.legs.length > 2"> {{ "GLOBAL.FLIGHT" | translate }} {{ i + 1 }} </ng-container>
            </div>
            <div class="origin">
              <div class="adress">
                <span class="airport">{{ item.detail.airports[leg.departure.airport].name }}</span>
                <span class="city">, {{ item.detail.airports[leg.departure.airport].city }}</span>
              </div>
              <ng-container *ngIf="trip.legs.length < 2">
                <div class="datetime departure">
                  <i class="spt-icon-date date-icon"></i>
                  <span class="date">{{ leg.departure.date | localizedDate: "d MMM" | titlecase }}</span>
                  <span class="time">{{ leg.departure.time | slice: 0 : 5 }}</span>
                </div>
              </ng-container>
            </div>
            <i
              class="m-l-5 m-r-5 spt-icon-arrow-circle-right arrow-right ng-star-inserted"
              *ngIf="(commonService.isTabletObservable | async) === false && trip.legs.length >= 2"
            ></i>
            <div class="destination">
              <div class="adress">
                <span class="airport">{{
                  item.detail.airports[leg.arrival.airport]?.name || leg.arrival.airport
                }}</span>
                <span class="city">, {{ item.detail.airports[leg.arrival.airport]?.city }}</span>
              </div>
              <ng-container *ngIf="trip.legs.length < 2">
                <div class="datetime">
                  <i class="spt-icon-date date-icon"></i>
                  <span class="date">{{ leg.arrival.date | localizedDate: "d MMM" | titlecase }}</span>
                  <span class="time">{{ leg.arrival.time | slice: 0 : 5 }}</span>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="datetime" *ngIf="trip.legs.length >= 2">
            <i class="spt-icon-date date-icon"></i>
            <span class="date">{{ leg.departure.date | localizedDate: "d MMM" | titlecase }}</span>
            <span class="time">{{ leg.departure.time | slice: 0 : 5 }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>
