import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import moment from "moment";
import { UtilsTypes } from "src/app/@shared/@types/utils";
import { TrainTypes } from "src/app/travel/train/train";
import { TrainService } from "src/app/travel/train/train.service";

@Component({
  selector: "spt-itinerary-train",
  templateUrl: "./train.component.html",
  styleUrls: ["./train.component.scss"],
})
export class ItineraryTrainComponent implements OnInit, OnDestroy {
  @Input() item: any;
  @Input() isSmallDevice: boolean;
  @Input() locale: string;
  @Output() openTicket: EventEmitter<{
    documents: Array<any>;
    type: "train";
    reference: string;
  }> = new EventEmitter();
  travelClasses: UtilsTypes.ObjectKey<TrainTypes.TravelClass> = {};

  /**
   * Les conditions regroupées par trajet (trajet "aller", trajet "retour")
   */
  fareInfosByDirection: {
    // Direction: "inward" ou "outward"
    [key: string]: FareInfo[];
  } = {};

  /**
   * Les conditions regroupées par passagers
   */
  faresByPassengers: {
    // Key : identifiant du passager
    [key: string]: TrainTypes.Fare[];
  } = {};

  spaceAllocations: UtilsTypes.ObjectKey<Array<Array<TrainTypes.SpaceAllocation>>> = {};
  selectedFare: TrainTypes.Fare;
  hasDifferentJourneys: boolean = false;
  journeys: TrainTypes.Journey[];
  orderItems: any;
  mappedJourneys: Array<TrainTypes.Journey> = [];

  constructor(private trainService: TrainService) {}

  ngOnDestroy(): void {
    this.fareInfosByDirection = {};
    this.faresByPassengers = {};
  }

  travelDuration(departureTime: Date, arrivalTime: Date): string {
    if (!departureTime || !arrivalTime) {
      return null;
    }
    const departureT: moment.Moment = moment(departureTime);
    const arrivalT: moment.Moment = moment(arrivalTime);
    const difference: moment.Duration = moment.duration(arrivalT.diff(departureT));
    const hours: number = difference.get("hours");
    const minutes: number = difference.get("minutes");
    return `PT${hours}H${minutes}M`;
  }

  ngOnInit(): void {
    if (this.item.detail?.journeys?.length > 0) {
      this.journeys = this.item.detail.journeys;
    } else if (this.item.journeys?.length > 0) {
      this.journeys = this.item.journeys;
    } else {
      this.journeys = this.item.formData?.itinerary?.journeys;
    }
    this.mapJourneys(this.journeys);
    this.orderItems = this.item.detail?.orderItems || this.item.itineraries;
    this.item.confirmationDate = this.item.statusHistory?.find(
      (status: any): boolean => status.to === "confirmed",
    )?.date;
    if (this.item && !this.item?.offline) {
      this.orderItems = this.item.detail?.orderItems || this.item.itineraries;
      if (this.item.detail) {
        this.item.detail.orderItems = this.orderItems.sort(
          (_orderItemA: TrainTypes.OrderItem, _orderItemB: TrainTypes.OrderItem): number => {
            if (_orderItemA.item.journeys[0].departure.date.utc < _orderItemB.item.journeys[0].departure.date.utc) {
              return -1;
            } else {
              return 1;
            }
          },
        );
      }
    }
    this.mapFares();
  }

  toggleFare(fare: TrainTypes.Fare): void {
    this.selectedFare = fare;
  }
  private mapJourneys(journeys): void {
    const { mappedJourneys } = this.trainService.mapJourneys(journeys);
    this.mappedJourneys = mappedJourneys;
  }

  private mapFares(): void {
    const journeys: TrainTypes.Journey[] = this.item.detail?.journeys || this.item.journeys;

    this.orderItems
      .flatMap((_orderItem) => _orderItem.item?.sections || _orderItem.sections)
      .filter((order) => !!order)
      .flatMap((_section: TrainTypes.ItinerarySection): TrainTypes.Fare[] => _section.fares)
      .forEach((_sectionFare: TrainTypes.Fare): void => {
        // Pour chacune des conditions ...
        // Récupération de la classe du segment
        _sectionFare.segmentIds.forEach((_segmentId: string): void => {
          this.travelClasses[_segmentId] = _sectionFare.fareSegments?.[0].travelClass;
        });
        // Récupération du "journey" contenant le segmentId correspondant au "segment du 'section fare'"
        // pour déterminer si cela correspond à l'aller ou au retour
        const direction = journeys.find((_journey) =>
          _journey.segments.some((_segment) => _sectionFare.segmentIds.includes(_segment.id)),
        )?.direction;

        if (!direction) {
          console.warn("Impossible de déterminer si la condition est rattachée à l'aller ou au retour");
          return;
        }

        FareInfo.addFareForDirection(this.fareInfosByDirection, direction, _sectionFare);

        // Constitution de la liste des conditions pour chaque passagers
        _sectionFare.passengers.forEach((_passenger) => {
          if (!this.faresByPassengers[_passenger]) {
            this.faresByPassengers[_passenger] = [];
          }
          this.faresByPassengers[_passenger].push(_sectionFare);
        });
      });

    this.orderItems.forEach((_orderItem) =>
      (_orderItem.item?.journeys || _orderItem.journeys).forEach(
        (_journey: TrainTypes.Journey, _journeyIndex: number): void => {
          _journey.segments.forEach((_segment: TrainTypes.Segment) => {
            const spaceAllocationForJourney = (_orderItem.item?.spaceAllocations || _orderItem.spaceAllocations)?.[
              _journeyIndex
            ];
            const direction = _journey.direction;
            if (spaceAllocationForJourney) {
              if (!this.spaceAllocations[direction]) {
                this.spaceAllocations[direction] = [];
              }

              this.spaceAllocations[direction][_segment.id] = spaceAllocationForJourney.filter(
                (spaceAllocation: TrainTypes.SpaceAllocation): boolean => spaceAllocation.fareSegmentId === _segment.id,
              );
            }
          });
        },
      ),
    );
  }
}

/**
 * Permet de regrouper les conditions (TrainTypes.Fare) en fonction de leur nom (fareName)
 */
class FareInfo {
  nbPassengers: number = 0;
  price: UtilsTypes.Price = {
    amount: 0,
    currency: undefined,
  };

  constructor(
    public readonly fare: TrainTypes.Fare,
    public readonly direction: string,
  ) {}

  private addFare(fare: TrainTypes.Fare) {
    if (fare.fareCode !== this.fare.fareCode) {
      throw new Error("fare (" + fare.fareCode + ") to add must be: " + this.fare.fareCode);
    }
    if (!this.price.currency) {
      this.price.currency = fare.price.currency;
    } else if (this.price.currency !== fare.price.currency) {
      // Cas qui ne devrait normalement pas arriver car les prix pour un même folder doivent avoir la même devise
      throw new Error("currencies are not same");
    }
    this.price.amount += fare.price.amount * fare.passengers.length;
    this.nbPassengers += fare.passengers.length;
  }

  static addFareForDirection(
    fareInfosByDirection: { [key: string]: FareInfo[] },
    direction: string,
    fare: TrainTypes.Fare,
  ) {
    let fareInfosForDirection = fareInfosByDirection[direction];
    if (!fareInfosForDirection) {
      fareInfosForDirection = fareInfosByDirection[direction] = [];
    }

    // Récupération du FareInfo éventuellement déjà existant pour la direction désirée
    let fareInfo = fareInfosForDirection.find((_fareInfo) => {
      _fareInfo.fare.fareCode === fare.fareCode;
    });
    if (!fareInfo) {
      // Si le FareInfo n'a pas encore été créé pour le fareName correspondant, on en initialise un
      fareInfo = new FareInfo(fare, direction);
      fareInfosForDirection.push(fareInfo);
    }

    // Ajout de la condition spécifique dans le FareInfo corrrespondant
    fareInfo.addFare(fare);
  }
}
