import { Component, OnInit, ViewChild, ElementRef, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService, UserSignUp } from "../auth.service";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { Address } from "src/app/@shared/@types/models";
import { MessageService } from "primeng/api";

@Component({
  selector: "spt-sign-up",
  styleUrls: ["./sign-up.component.scss"],
  templateUrl: "./sign-up.component.html",
})
export class SignUpComponent implements OnInit {
  public signUpForm: UntypedFormGroup;
  public societyName: any;
  public errorMessageResources: any;
  public displayPassword: Object = {
    password: false,
    controlPassword: false,
  };

  @ViewChild("addressCompanyRef", { static: false })
  addressCompanyRef: ElementRef;
  public types: Array<string> = ["address"];

  constructor(
    private router: Router,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private zone: NgZone,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.setErrorMessages();

    this.signUpForm = this.formBuilder.group(
      {
        email: ["", Validators.compose([Validators.email, Validators.required])],
        password: [
          "",
          [
            Validators.required,
            Validators.pattern(
              /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d!@#\$%\^&\*~`()_\-\+={[}\]|:;"'<,>\.\/?´ç¡¿€]{7,}/,
            ),
          ],
        ],
        controlPassword: [
          "",
          [
            Validators.required,
            Validators.pattern(
              /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d!@#\$%\^&\*~`()_\-\+={[}\]|:;"'<,>\.\/?´ç¡¿€]{7,}/,
            ),
          ],
        ],

        title: ["", Validators.required],
        firstname: ["", Validators.required],
        lastname: ["", Validators.required],
        phone: ["", Validators.required],
        companyName: ["", Validators.required],
        // companyAddress: ["", Validators.required],
      },
      {
        validator: this.duplicatePassword,
      },
    );
  }

  duplicatePassword(formGroup: UntypedFormGroup): any {
    const control: any = formGroup.get("password");
    const matchingControl: any = formGroup.get("controlPassword");

    if (!control.value || !matchingControl.value) {
      matchingControl.setErrors();
      return null;
    }
    const exactMatch: boolean = control.value === matchingControl.value;

    exactMatch === false ? matchingControl.setErrors({ mismatchedPassword: false }) : matchingControl.setErrors();

    return null;
  }

  public register(): void {
    const userSignUp: UserSignUp = {
      email: this.signUpForm.value.email.replace(new RegExp(/\s/, "gm"), ""),
      password: this.signUpForm.value.password,

      title: this.signUpForm.value.title,
      firstname: this.signUpForm.value.firstname,
      lastname: this.signUpForm.value.lastname,
      phone: this.signUpForm.value.phone,
    };

    this.authService.signUp(userSignUp, this.signUpForm.value.companyName).subscribe(() => {
      this.messageService.add({
        severity: "success",
        summary: "Votre inscription à bien été prise en compte.",
        sticky: true,
      });
      this.loginPage();
    });
  }

  setAddress(companyAddress: Address): void {
    this.zone.run(() => {
      this.signUpForm.controls.companyAddress.setValue(companyAddress);
    });
  }

  public loginPage(): void {
    this.router.navigate(["auth"]);
  }

  changeStatutPassword(type: string): void {
    this.displayPassword[type] = !this.displayPassword[type];
  }

  setErrorMessages(): void {
    this.errorMessageResources = {
      email: {
        required: "Email is required",
        email: "Not is email",
      },
      companyAddress: {
        required: "Address is required.",
      },
      password: {
        required: "Password is required",
      },
      controlPassword: {
        mismatchedPassword: "Rewrite password",
      },
      companyName: {
        required: "Company name is required.",
      },
      firstname: {
        required: "First name is required.",
      },
      lastname: {
        required: "Last name is required.",
      },
    };
  }
}
