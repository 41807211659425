import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import _companies from "../../../@shared/companies";
import { FlightService } from "../flight.service";
import { Filter } from "../flight.component";
import { CommonService } from "src/app/@shared/services/common.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "spt-travel-flight-filters",
  styleUrls: ["./travel-flight-filters.component.scss"],
  templateUrl: "./travel-flight-filters.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class TravelFlightFiltersComponent implements OnInit, OnChanges, OnDestroy {
  public companies: any = _companies;

  @Input() filterDefinitions$: Observable<any>;
  filterDefinitions: any;

  @Output() onFilterChange: any = new EventEmitter<void>();

  @Input() currentFilter: Filter;

  public indexFilterForTitle: number;
  public sortBy: string = "ascend";
  public alphabeticOrderIcon: string = "./assets/svg/alphaAscend.svg";

  private unsub: Subject<any> = new Subject();
  constructor(
    public flightService: FlightService,
    private commonService: CommonService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.commonService.statusBarTextBlack();
    this.filterDefinitions$.pipe(takeUntil(this.unsub)).subscribe((filterDefinitions) => {
      let indexFilterForTitle = undefined;
      filterDefinitions.forEach((availableFilter, i) => {
        if (["freeRefund", "changeable", "refundable"].includes(availableFilter.type) && !indexFilterForTitle) {
          indexFilterForTitle = i;
        }
        if (availableFilter.type === "cabinCodes") {
          availableFilter.data.forEach((item) => {
            item.value = item.key;
            item.label = this.translateService.instant("GLOBAL.CATEGORY." + item.key);
          });
        }
      });
      this.filterDefinitions = filterDefinitions.sort((a, b) => (a.type === "cabinCodes" ? -1 : 1));
      this.indexFilterForTitle = indexFilterForTitle;
      this.checkFilter();
    });
  }

  onchangeDropdown(event: any) {
    console.log(event);
  }

  private checkFilter() {
    if (this.filterDefinitions && this.currentFilter) {
      this.filterDefinitions.forEach((availableFilter, i) => {
        if (availableFilter.type === "price") {
          // On définit un prix à la valeur max si celui-ci n'est pas déjà définit
          const price = this.currentFilter.price === undefined ? availableFilter.max : this.currentFilter.price;
          // On s'assure que le prix (éventuellement déjà définit au préalable) est bien compris entre le min et le max
          this.currentFilter.price = Math.max(availableFilter.min, Math.min(availableFilter.max, price));
        } else if (availableFilter.type === "duration") {
          // On définit une durée à la valeur max si celle-ci n'est pas déjà définie
          const duration =
            this.currentFilter.duration === undefined ? availableFilter.max : this.currentFilter.duration;
          // On s'assure que le durée (éventuellement déjà définie au préalable) est bien comprise entre le min et le max
          this.currentFilter.duration = Math.max(availableFilter.min, Math.min(availableFilter.max, duration));
        }
      });
      if (this.currentFilter && this.currentFilter.baggageIncludedMinimumNumber === undefined) {
        this.currentFilter.baggageIncludedMinimumNumber = 1;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.currentFilter?.currentValue) {
      this.checkFilter();
    }
  }

  ngOnDestroy(): void {
    this.unsub.next(null);
    this.unsub.complete();
  }

  triggerFilterChange(): void {
    this.onFilterChange.emit();
  }

  closePanels(): void {
    this.flightService.closeExtendedPanel.next(true)
  }

  changeSortOrder(): void {
    this.sortBy === "ascend"
      ? ((this.sortBy = "descend"), (this.alphabeticOrderIcon = "./assets/svg/alphaDescend.svg"))
      : ((this.sortBy = "ascend"), (this.alphabeticOrderIcon = "./assets/svg/alphaAscend.svg"));
  }
}
