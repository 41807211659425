import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { CommonService } from "../../../@shared/services/common.service";
import { TrainTypes } from "../train";

@Component({
  selector: "spt-travel-train-selection",
  templateUrl: "./selection.component.html",
  styleUrls: ["./selection.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelTrainSelectionComponent {
  @Input() selectedOutward: TrainTypes.OffersSelection;
  @Input() locale: string;
  @Input() isSmallDevice: boolean;
  @Output() search: EventEmitter<void> = new EventEmitter();

  constructor(public commonService: CommonService) {}

  onSearch(): void {
    this.search.emit();
  }
}
