import { CommonService } from "src/app/@shared/services/common.service";
import { Injectable } from "@angular/core";
import { default as markerSDK, MarkerSdk } from "@marker.io/browser";
import { UserService } from "./user.service";
import { SessionService, SessionType } from "./session.service";
import { User } from "../@types/user";
import { environment } from "src/environments/environment";
import { combineLatestWith } from "rxjs/operators";

const ANONYMOUS_EMAIL = "anonymous@supertripper.com";
const ANONYMOUS_FULLNAME = "anonymous";

@Injectable({
  providedIn: "root",
})
export class FeedbackService {
  private widget?: MarkerSdk;
  private projectId?: string;
  private reporter = {
    email: ANONYMOUS_EMAIL,
    fullName: ANONYMOUS_FULLNAME,
  };
  private customData = {
    userId: undefined,
    societyId: undefined,
  };

  constructor(
    private userService: UserService,
    private commonService: CommonService,
    private sessionService: SessionService<SessionType>,
  ) {
    this.projectId = environment.markerIOproject;
  }

  load() {
    if (!this.projectId || this.commonService.isCordova() === true) {
      console.info("MarkerIO is disabled");
      return;
    }
    if (this.widget) {
      return;
    }

    this.userService.user.pipe(combineLatestWith(this.sessionService.getSession())).subscribe(([user, session]) => {
      this.setUser(user, session?.societyId);
    });

    this.sessionService.getSession().subscribe((session: SessionType) => {
      if (!session) {
        // déconnexion
        this.setUser(undefined);
      }
    });
  }

  setUser(user?: User, societyId?: string) {
    this.reporter = {
      email: user?.email || ANONYMOUS_EMAIL,
      fullName: user?.username || ANONYMOUS_FULLNAME,
    };

    this.customData.userId = user?._id;
    this.customData.societyId = societyId;
    this.loadMarkerIO();
  }

  private loadMarkerIO() {
    if (window.markerConfig) {
      return;
    }
    if (!window.markerConfig) {
      window.markerConfig = {
        project: this.projectId,
      };
    }
    window.markerConfig.customData = this.customData;
    if (this.widget) {
      this.setupWidget();
    } else {
      markerSDK
        .loadWidget({
          project: this.projectId,
        })
        .then((widget) => {
          this.widget = widget;
          this.setupWidget();
        });
    }
  }

  private setupWidget() {
    this.widget.setReporter(this.reporter);
    // En attente de la correction du bug: https://github.com/marker-io/browser-sdk/issues/21
    (this.widget as any).setCustomData(this.customData);
  }
}
