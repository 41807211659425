import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-car-detail",
  templateUrl: "./car-detail.component.html",
  styleUrls: ["./car-detail.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RentalDetailComponent implements OnInit {
  @Input() item: any;
  public pickupInfo: {
    place: string;
    date: string;
    time: string;
    city: string;
  };
  public returnInfo: {
    place: string;
    date: string;
    time: string;
    city: string;
  };

  constructor() {}

  ngOnInit(): void {
    this.pickupInfo = this.generateInfos(this.item.detail, "pickupAddress");
    this.returnInfo = this.generateInfos(this.item.detail, "returnAddress");
  }

  generateInfos(detail, addressType) {
    const { line1, line2, city, zipcode, country } = detail[addressType];
    return {
      place: [line1, line2].filter((line) => !!line).join(","),
      city: [city, zipcode, country].filter((s) => !!s).join(","),
      date: detail.date,
      time: detail.time,
    };
  }
}
