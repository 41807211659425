<div
  class="transfers"
  *ngFor="
    let transfer of item.detail.booking.transfers;
    let transferIndex = index;
    let isOdd = isOdd;
    let isEven = isEven
  "
>
  <div
    class="horizontal-separator"
    *ngIf="(commonService.isTabletObservable | async) === false && transferIndex > 0"
  ></div>
  <div class="transfer">
    <div class="link">
      <span class="round" [class.return]="isOdd"></span>
      <span class="line"></span>
      <span class="round" [class.return]="isEven"></span>
    </div>
    <div class="locations">
      <div class="origin">
        <span class="name">{{ transfer.fromdetails.name }}</span>
      </div>
      <i
        class="spt-icon-arrow-circle-right arrow-right"
        *ngIf="(commonService.isTabletObservable | async) === false && item.detail.booking.transfers.length > 1"
      ></i>
      <div class="destination">
        <span class="name">{{ transfer.todetails.name }}</span>
      </div>
    </div>
    <div class="datetime">
      <i class="spt-icon-date date-icon"></i>
      <ng-container *ngIf="transfer.fromdetails.flight">
        <span class="date">
          {{ transfer.fromdetails.flight.arrivaldatetime.date | date: "d MMM" | titlecase }}
        </span>
        <small class="time">{{ transfer.fromdetails.flight.arrivaldatetime.time }}</small>
      </ng-container>
      <ng-container *ngIf="transfer.fromdetails.train">
        <span class="date">{{ transfer.fromdetails.train.arrivaldatetime.date | date: "d MMM" | titlecase }}</span>
        <small class="time">{{ transfer.fromdetails.train.arrivaldatetime.time }}</small>
      </ng-container>
      <ng-container *ngIf="transfer.fromdetails.accommodation">
        <span class="date">
          {{ transfer.fromdetails.accommodation.pickupdatetime.date | date: "d MMM" | titlecase }}
        </span>
        <small class="time">{{ transfer.fromdetails.accommodation.pickupdatetime.time }}</small>
      </ng-container>
    </div>
  </div>
</div>
