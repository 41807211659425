import { Component, OnInit, Input, ViewChild, ElementRef, ViewEncapsulation, ChangeDetectorRef } from "@angular/core";
import moment from "moment";
import * as L from "leaflet";
import { DomSanitizer } from "@angular/platform-browser";
import { HotelService } from "src/app/@shared/services/hotel.service";
import { CommonService } from "src/app/@shared/services/common.service";

@Component({
  selector: "app-hotel-detail",
  templateUrl: "./hotel-detail.component.html",
  styleUrls: ["./hotel-detail.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HotelDetailComponent implements OnInit {
  @Input() item: any;

  @ViewChild("leafletMap", { static: false }) mapElement: ElementRef;

  private map: L.Map = null;
  public mapOtions: L.MapOptions;

  public datein: any;
  public dateout: any;
  public nightNumber: number;
  public hotelRank: number;
  public boardType: string;
  public coordinates: any;

  public rooms: Array<{ places: number; title: string }> = [];

  constructor(
    private hotelService: HotelService,
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    public commonService: CommonService,
  ) {}

  ngOnInit() {
    this.rooms = this.item.detail.rooms;
    if (this.item.offline === true || this.item.provider === "offline") {
      this.boardType = this.item.formData.boardType;
    } else {
      this.boardType = this.item.detail.boardType || this.item.detail.rooms[0].type;
    }
    this.nightsNumber();

    this.hotelService.getDescription(this.item.detail.accomcode, this.item.formData.suppliercode).subscribe((hotel) => {
      this.hotelRank = hotel.description.rank;
      const [longitude, latitude] = hotel.coordinates;
      this.setMapMarker([latitude, longitude]);
      this.cd.detectChanges();
    });
  }

  nightsNumber() {
    this.datein = moment(this.item.detail.datein.utc);
    this.dateout = moment(this.item.detail.dateout.utc);
    this.nightNumber = this.dateout.diff(this.datein, "days");
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  setExternalLink(coordinates: L.LatLngExpression) {
    const userAgent: string = navigator?.userAgent;

    if (this.commonService.isCordova() === true) {
      if (userAgent && /ipad|iphone|ipod/i.test(userAgent)) {
        this.coordinates = "maps://maps.apple.com/?q=";
      } else {
        this.coordinates = "geo:";
      }
    } else {
      this.coordinates = "https://maps.google.com/?q=";
    }
    if (this.coordinates) {
      // sanitaze for safe url
      this.coordinates += coordinates.toString();
      this.coordinates = this.sanitize(this.coordinates);
    }
  }

  setMapMarker(coordinates: L.LatLngExpression) {
    this.mapOtions = {
      center: L.latLng(coordinates),
      layers: [
        L.tileLayer("http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
          maxZoom: 18,
          attribution: "...",
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }),
      ],
      zoom: 15,
    };

    this.map = L.map(this.mapElement.nativeElement, this.mapOtions);
    const marker: L.Icon = L.icon({
      iconUrl: "./assets/svg/map-purple-icon.svg",
      iconSize: [48, 48],
    });
    L.marker(coordinates, { icon: marker }).addTo(this.map);
    this.setExternalLink(coordinates);
  }
}
