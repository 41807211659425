import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { CommonService } from "../../../../../@shared/services/common.service";

@Component({
  selector: "spt-booking-rental",
  templateUrl: "./rental.component.html",
  styleUrls: ["../booking-list.component.scss", "./rental.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RentalComponent implements OnInit {
  @Input() item: any;
  @Output() openedEticket: EventEmitter<any> = new EventEmitter();
  @HostBinding("class.has-return") hasReturn: boolean;

  public pickupDate: Date;
  public returnDate: Date;
  public pickup: { address: string; city: string };
  public dropoff: { address: string; city: string };

  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.hasReturn = !(this.item.detail.pickupAddress.line1 === this.item.detail.returnAddress.line1);
    this.pickup = this.getAddress(this.item.detail.pickupAddress);
    this.dropoff = this.getAddress(this.item.detail.returnAddress);
  }

  openEticket(item: any): void {
    this.openedEticket.emit(item);
  }

  getAddress(pickup: any): { address: string; city: string } {
    const result: { address: string; city: string } = {
      address: `${pickup.line1}`,
      city: pickup.city,
    };
    if (pickup.line2) {
      result.address += ` ${pickup.line2}`;
    }
    return result;
  }
}
