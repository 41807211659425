import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { DateTimeSearch, SearchCriteria, SearchResult } from "../search-engine-service";
import { CarSearchOptions, CarSearchService } from "./car-search.service";
import moment from "moment";
import { Address } from "src/app/@shared/@types/models";
import { SessionService } from "src/app/@shared/services/session.service";
import { SuggestPanelService } from "../common/suggest-panel.service";

@Component({
  selector: "spt-search-car",
  templateUrl: "./search-car.component.html",
  styleUrls: ["./search-car.component.scss"],
})
export class SearchCarComponent implements OnInit, OnChanges {
  searchEngineType: string;

  @Input()
  searchCriteria: SearchCriteria<Address, CarSearchOptions>;
  isValid: boolean = false;

  public yearRange: string = `${moment().format("YYYY")}:${moment().add(1, "year").format("YYYY")}`;
  suggestOpened: boolean = false;

  constructor(
    protected searchService: CarSearchService,
    private sessionService: SessionService<any>,
    protected suggestPanelService: SuggestPanelService,
  ) {
    this.searchEngineType = searchService.getType();
  }

  ngOnInit(): void {
    if (!this.searchCriteria) {
      this.searchCriteria = this.searchService.createBlankCriteria();
    }
    this.suggestPanelService.init(DEFAULT_SUGGEST_PANEL);
    const session = this.sessionService.get();
  }

  changeDestinationSameAsOrigin(state: boolean): void {
    this.searchCriteria.options.destinationSameAsOrigin = state;
    if (this.searchCriteria.options.destinationSameAsOrigin === true) {
      this.searchCriteria.mainTravel.destination = this.searchCriteria.mainTravel.origin;
    } else if (
      this.searchCriteria.mainTravel.destination === undefined ||
      this.searchCriteria.mainTravel.destination === this.searchCriteria.mainTravel.origin
    ) {
      this.searchCriteria.mainTravel.destination = this.searchService.createDummyLocalityFromName("");
    }
    this.checkValidity();
  }

  activateSuggest(suggestToActivate: string) {
    this.suggestPanelService.activate(suggestToActivate);
    this.suggestOpened = true;
  }

  @Output()
  onSearchResult: EventEmitter<SearchResult> = new EventEmitter<SearchResult>();

  search(): void {
    this.searchService
      .launchSearch(this.searchCriteria)
      .subscribe((searchResult: SearchResult) => this.onSearchResult.emit(searchResult));
  }

  resetSuggestPanel(): void {
    this.suggestPanelService.reset();
    this.suggestOpened = false;
  }

  checkValidity() {
    this.isValid = this.searchService.searchCriteriaIsValid(this.searchCriteria);
  }

  changeType(type: any) {
    this.searchCriteria.mainTravel.when.inward = type === "one-way" ? undefined : new DateTimeSearch();
    this.checkValidity();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["type"]) {
      this.changeType(changes["type"]);
    }
  }
}

const DEFAULT_SUGGEST_PANEL = "favorites";
