import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { TagService } from "src/app/@shared/services/tag.service";

@Component({
  selector: "spt-tags-modal",
  styleUrls: ["./tags-modal.component.scss"],
  templateUrl: "./tags-modal.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class TagsModalComponent implements OnInit {
  constructor(
    private config: DynamicDialogConfig,
    private tagService: TagService,
    private ref: DynamicDialogRef,
  ) {}

  public booking: any = this.config.data.booking;
  public tags: Array<any>;
  public enteredEnter: boolean = false;

  ngOnInit(): void {
    this.tags = [];
  }

  public getTags(event: any): void {
    this.tags = event;
    if (this.enteredEnter) {
      const type: string =
        this.booking.type === "Hotel" && this.booking.item.provider !== "Mano" ? "reservations" : "booking";
      const tagLabels: Array<any> = [];
      if (this.tags) {
        this.tags.forEach((tag: any) => tagLabels.push(tag.label));
      }
      // this.tagService.updateTagBooking(tagLabels, this.config.data.booking._id, this.config.data.booking.society, type).subscribe(
      //     (res: any) => {
      //         const tagsArray: Array<any> = [];
      //         res[0].data.tagIds.forEach((tag: string) => {
      //             tagsArray.push(Object.values(res[1]).filter(
      //                 (tagAvailable: any) => {
      //                     return tagAvailable._id === tag;
      //                 }
      //             )[0]);
      //         });
      //         this.config.data.booking.tagIds = tagsArray;
      //     });
    }
  }

  public addTag(): void {
    this.enteredEnter = true;
    this.ref.close(this.tags);
  }
}
