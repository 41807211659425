<ng-container [ngSwitch]="item.type">
  <spt-itinerary-hotel
    *ngSwitchCase="'hotel'"
    [item]="item"
    [price]="item.price"
    [detail]="item.detail"
    [formData]="item.formData"
    [travelers]="item.travelers"
    [itemId]="item.id"
    [status]="item.status"
    (onDataHotelUpdated)="onDataHotelUpdated.emit($event)"
    [referenced]="this.referenced"
  ></spt-itinerary-hotel>
  <spt-car-description
    *ngSwitchCase="'car'"
    [item]="item"
    [search]="{ data: item.formData.search }"
    [showLeaflet]="true"
  ></spt-car-description>
  <spt-itinerary-flight *ngSwitchCase="'flight'" [item]="item"></spt-itinerary-flight>
  <spt-itinerary-train
    *ngSwitchCase="'train'"
    [item]="item"
    [isSmallDevice]="isSmallDevice"
    [locale]="locale"
    (openTicket)="openEtickets($event)"
  >
  </spt-itinerary-train>
</ng-container>
