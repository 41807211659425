<div *ngIf="(commonService.isTabletObservable | async) === false" class="back-arrow" (mousedown)="back()">
  <i class="spt-icon-back-arrow"></i>
</div>
<div class="description">
  <div *ngIf="(commonService.isTabletObservable | async) === true" class="back-arrow" (mousedown)="back()">
    <i class="spt-icon-back-arrow"></i>
  </div>
  <div class="supplier" [class.negociated]="availabilities?.supplier === 'SAB'">
    <spt-specific-rate
      *ngIf="availabilities?.supplier === 'SAB'"
      [negociated]="true"
      type="hotel"
      [position]="'bottom'"
    ></spt-specific-rate>
    <span *ngIf="availabilities?.supplier !== 'SAB'">{{
      "SEARCH.PROVIDERS." + availabilities.supplier | translate
    }}</span>
  </div>
  <div class="title">
    <span>{{ hotel.description?.title }}</span>
  </div>
  <div class="infos">
    <div class="street">
      <img src="./assets/svg/pointer.svg" alt="" />
      {{ hotel.description?.address }}
    </div>
    <div class="stars-mobile-vendor">
      <div class="stars" [innerHtml]="hotel.description?.rank | stars"></div>
      <span class="mobile-vendor">
        <span translate>SEARCH.PROVIDERS.VIA</span>
        <span>{{ "SEARCH.PROVIDERS." + availabilities.supplier | translate }}</span>
      </span>
    </div>
    <div class="rate">
      <span class="score">{{ hotel.description?.rating | number: "1.0-1" }}</span>
      <span classe="max">/10</span>
    </div>
  </div>
  <img [src]="hotel.thumbnail" alt="" class="picture" (error)="onImgError($event)" />
  <div class="time">
    Checkin : {{ checktimes.checkInTime?.from | date: timeFormat }} - Checkout :
    {{ checktimes.checkOutTime?.to | date: timeFormat }}
  </div>
</div>

<div class="menu">
  <p-tabMenu [model]="menu" [activeItem]="activeMenu">
    <ng-template pTemplate="item" let-item let-i="index">
      <ng-container *ngIf="item.label === 'SEARCH.RESULT.HOTEL.AVIS'; else menu">
        <ng-container *ngIf="hotel?.metadata?.reviews">
          <span translate [translateParams]="{ number: hotel?.metadata?.reviews?.length }">{{ item.label }}</span>
        </ng-container>
      </ng-container>
      <ng-template #menu>
        <span translate>{{ item.label }}</span>
      </ng-template>
    </ng-template>
  </p-tabMenu>
</div>

<ng-container [ngSwitch]="activeMenu.label">
  <spt-travel-hotel-page-availabilities
    [search]="search"
    *ngSwitchCase="'SEARCH.RESULT.HOTEL.FARES'"
    [seeOutOfPolicy]="seeOutOfPolicy"
    [availabilities]="availabilities"
    (selectFare)="selectOffer($event)"
  ></spt-travel-hotel-page-availabilities>

  <spt-travel-hotel-page-pictures
    *ngSwitchCase="'SEARCH.RESULT.HOTEL.PICTURES'"
    [hotel]="hotel"
  ></spt-travel-hotel-page-pictures>

  <spt-travel-hotel-page-detail
    *ngSwitchCase="'SEARCH.RESULT.HOTEL.DETAILS'"
    [hotel]="hotel"
  ></spt-travel-hotel-page-detail>

  <spt-travel-hotel-page-commentaries
    *ngSwitchCase="'SEARCH.RESULT.HOTEL.AVIS'"
    [accomcode]="hotel?.accomcode"
    [reviews]="hotel?.metadata?.reviews"
    (outdatedInformations)="refresh()"
  ></spt-travel-hotel-page-commentaries>
</ng-container>
