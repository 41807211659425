<div class="ticket-detail" *ngIf="!isDisplayedModification && !isDisplayedCancellation">
  <div class="detail-header">
    <div class="title-container">
      <ng-container [ngSwitch]="item.type">
        <img *ngSwitchCase="'flight'" src="./assets/svg/flight-full-colored.svg" class="transport-icon" />
        <img *ngSwitchCase="'car'" src="./assets/svg/rentalcar-full-colored.svg" class="transport-icon" />
        <img *ngSwitchCase="'hotel'" src="./assets/svg/hotel-full-colored.svg" class="transport-icon" />
        <img *ngSwitchCase="'train'" src="./assets/svg/train-full-colored.svg" class="transport-icon" />
      </ng-container>
      <h2 class="title">
        {{ "GLOBAL.MYRESERVATION" | translate }}
        <span translate>{{ "BASKET.TYPES." + (item.type | uppercase) }}</span>
      </h2>
    </div>
    <div class="basic-info-container">
      <div class="item-number">
        N°
        <ng-container [ngSwitch]="item.type">
          <span *ngSwitchCase="'flight'">{{ item.detail.supplierRef }}</span>
          <span *ngSwitchCase="'car'">{{ item.detail.confirmationNumber }}</span>
          <span *ngSwitchCase="'hotel'">{{ item.detail.supplierbookingref }}</span>
          <span *ngSwitchCase="'train'">{{ item.detail.orderReference }}</span>
          <span *ngSwitchCase="'transfer'">{{ item.detail.booking.general.bookingreference }}</span>
        </ng-container>
      </div>
      <div class="person-number">
        <img src="./assets/svg/profile.svg" class="transport-icon" />
        <p>x {{ item.travelers.length }}</p>
      </div>
      <p class="price">
        {{ item.price | SPTPrice: item.confirmationDate | async }}
      </p>
    </div>
    <div *ngIf="item.type === 'car'">Acriss: {{ item.detail.acrissCode }}</div>
    <div *ngIf="item.type === 'car' && item?.detail.vendorCode" class="logo-car">
      {{ "SEARCH.CAR.VENDOR" | translate }} {{ item?.detail.vendorCode }}
    </div>
  </div>
  <ng-container [ngSwitch]="item.type">
    <app-hotel-detail *ngSwitchCase="'hotel'" [item]="item"></app-hotel-detail>
    <app-car-detail *ngSwitchCase="'car'" [item]="item"></app-car-detail>
    <app-flight-detail *ngSwitchCase="'flight'" [item]="item"></app-flight-detail>
    <spt-reservations-detail-train
      *ngSwitchCase="'train'"
      [item]="item"
      [isSmallDevice]="isSmallDevice"
      [locale]="locale"
      (openTicket)="openEtickets($event)"
    >
    </spt-reservations-detail-train>
  </ng-container>

  <div class="booker">
    <p translate>BOOKING.BOOKER</p>
    <div class="profiles">
      <spt-avatar
        [user]="
          item.offline ? { username: item.metadata?.booker?.username } : booker ? booker : { username: customerService }
        "
      ></spt-avatar>
    </div>
  </div>

  <div class="booker" *ngIf="item?.type === 'flight'; else standard_users">
    <p class="title" translate>GLOBAL.TRAVELERS</p>
    <div *ngFor="let traveler of item?.travelers; let i = index" class="traveler">
      <div class="profiles">
        <spt-avatar [user]="traveler" title="{{ traveler.userfirstname }} {{ traveler.userlastname }}"></spt-avatar>
      </div>
      <div class="informations" *ngFor="let itinerary of item?.detail.trips">
        <ng-container
          *ngFor="
            let pricingInformation of itinerary.pricingInformations
              ? itinerary.pricingInformations
              : [itinerary.pricingInformation]
          "
        >
          <div class="type-and-luggages">
            <div class="traveler-type">
              <span translate>GLOBAL.TYPE</span>:
              <span>
                {{
                  "SEARCH.RESULT.AIR.PASSENGER.TYPES." +
                    (pricingInformation?.fare?.passengerInfos &&
                    pricingInformation?.fare?.passengerInfos[i] &&
                    pricingInformation?.fare?.passengerInfos[i].passengerType
                      ? pricingInformation.fare.passengerInfos[i].passengerType
                      : "ADT") | translate
                }}
              </span>
            </div>
            <div
              class="luggages"
              *ngIf="
                pricingInformation?.fare?.passengerInfos &&
                pricingInformation?.fare?.passengerInfos[i] &&
                pricingInformation?.fare?.passengerInfos[i].baggageIncluded
              "
            >
              {{ pricingInformation.fare.passengerInfos[i].baggageIncluded }}
              <i class="spt-icon-baggage txt-14 m-l-10"></i>
            </div>
            <div
              class="luggages"
              *ngIf="
                pricingInformation?.fare?.passengerInfos &&
                pricingInformation?.fare?.passengerInfos[i] &&
                pricingInformation?.fare?.passengerInfos[i].carryOnIncluded
              "
            >
              {{ pricingInformation.fare.passengerInfos[i].carryOnIncluded }}
              <i class="spt-icon-baggage txt-14 m-l-10"></i>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-template #standard_users>
    <div class="travelers">
      <div class="traveler-number">
        <p>
          {{ "SEARCH.TRAVELLERS" | translate }}
          <span>({{ item.travelers.length }})</span>
        </p>
        <i
          class="arrow"
          [class.spt-icon-chevron-down]="!showAllTravelers"
          [class.spt-icon-chevron-up]="showAllTravelers"
          (click)="toggleAllTravelers()"
          *ngIf="item.travelers.length > 1"
        ></i>
      </div>
      <p class="total">{{ "SEARCH.RESULT.TOTAL" | translate }}</p>
      <ng-container *ngIf="!showAllTravelers">
        <div class="profiles" *ngFor="let user of users | slice: 0 : 1">
          <spt-avatar [user]="user"></spt-avatar>
        </div>
      </ng-container>
      <ng-container *ngIf="showAllTravelers">
        <div class="profiles" *ngFor="let user of users">
          <spt-avatar [user]="user"></spt-avatar>
        </div>
      </ng-container>
      <div class="price">
        {{ item.price | SPTPrice: item.confirmationDate | async }}
        <i
          *ngIf="hasReservationFee"
          class="fas pointer"
          [ngClass]="showPriceBreakdown ? 'fa-chevron-up' : 'fa-chevron-down'"
          (mousedown)="showPriceBreakdown = !showPriceBreakdown"
        ></i>
      </div>
      <div class="price-breakdown" [hidden]="!showPriceBreakdown">
        <ng-container *ngFor="let orderItem of item.detail.orderItems">
          <ng-container *ngFor="let lineItem of orderItem.item.pricing?.lineItems">
            <p class="line-item" *ngIf="lineItem.price.amount > 0">
              <span class="description">{{
                "SEARCH.RESULT.RAIL.LINE_ITEM.TYPE." + lineItem.type | uppercase | translate
              }}</span>
              <span class="amount">
                {{ lineItem.price | SPTPrice: item.confirmationDate | async }}
              </span>
            </p>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <div class="separator" *ngIf="item.labels && item.labels.length > 0 && item.status !== 'cancelled'"></div>

  <div *ngIf="item.labels && item.labels.length > 0 && item.status !== 'cancelled'" class="labels">
    <div>
      <p class="label-title" translate>SEARCH.LABELS</p>
      <div class="labels-list">
        <p *ngFor="let label of item.labels">
          {{ label.label }}:
          {{ (label.chosen.label ? label.chosen.label : label.chosen.value) || "LABELS.NO_INFORMATION" | translate }}
        </p>
      </div>
    </div>

    <div class="edit">
      <span (mousedown)="editLabels()" translate>LABELS.UPDATE_VALUES</span>
    </div>
  </div>

  <div class="separator"></div>

  <div class="bills" *ngIf="!this.commonService.isTablet && bills?.length > 0" (click)="openBills(bills)">
    <i class="fas fa-file-pdf"></i>
    <span *ngIf="bills?.length > 1" class="ng-star-inserted" translate>BOOKING.BILLS.MULTIPLE_RECEIPTS</span>
    <span *ngIf="bills?.length < 2" class="ng-star-inserted" translate>BOOKING.BILLS.SINGLE_RECEIPT</span>
  </div>

  <spt-travel-carbon-offset [carbonOffset]="item.carbonOffset" [locale]="locale"></spt-travel-carbon-offset>

  <div class="footer-buttons" *ngIf="item.type !== 'train' && item.status !== 'cancelled'">
    <button *ngIf="showCancelButton" class="cancel" (click)="cancelReservation()">
      <span *ngIf="!isSmallDevice">{{ "BOOKING.MY_CANCELLATION" | translate }}</span>
      <span *ngIf="isSmallDevice">{{ "GLOBAL.CANCEL" | translate }}</span>
    </button>
    <button
      class="e-ticket"
      *ngIf="item?.documentIds?.length > 0 && item?.status !== 'error' && item.status !== 'cancelled'"
      (click)="openEtickets(item)"
    >
      <img src="./assets/svg/flashcode.svg" class="flashcode" />
      <span *ngIf="!isSmallDevice">{{ "BOOKING.SEE_ETICKET" | translate }}</span>
      <span *ngIf="isSmallDevice">E-ticket</span>
    </button>
  </div>

  <div
    class="footer-buttons"
    *ngIf="item.type === 'train' && !(item.status === 'cancelled' || item.status === 'modified')"
  >
    <p class="text-center offline" *ngIf="item.offline; else onlineItemActions" translate>ITEM.DETAIL.OFFLINE</p>
    <ng-template #onlineItemActions>
      <button
        class="cancel"
        [class.w-full]="showCancelButton && !showModifyButton"
        *ngIf="showCancelButton"
        (click)="cancelReservation()"
      >
        <span *ngIf="!isSmallDevice">{{ "BOOKING.MY_CANCELLATION" | translate }}</span>
        <span *ngIf="isSmallDevice">{{ "GLOBAL.CANCEL" | translate }}</span>
      </button>
      <button
        class="modif"
        [class.w-full]="!showCancelButton && showModifyButton"
        *ngIf="showModifyButton"
        (click)="modifyReservation()"
      >
        <img src="./assets/svg/pencil-purple.svg" class="pencil" />
        <span>
          {{
            (item.detail.afterSales &&
            !item.detail.afterSales.refundable &&
            item.detail.afterSales.changeable &&
            isUKTravel
              ? "RESERVATIONS.TRAIN.CHANGE_OF_JOURNEY"
              : "GLOBAL.MODIFY"
            ) | translate
          }}
        </span>
      </button>
    </ng-template>
    <button
      class="e-ticket"
      *ngIf="item?.documentIds?.length > 0 && item?.status === 'confirmed'"
      (click)="openEtickets(item)"
    >
      <i class="flashcode spt-icon-flashcode"></i>
      <span *ngIf="!isSmallDevice">{{ "BOOKING.SEE_ETICKET" | translate }}</span>
      <span *ngIf="isSmallDevice">E-ticket</span>
    </button>
  </div>

  <p class="canceled-text" *ngIf="item.status === 'cancelled'">
    {{ "BOOKING.CANCEL_REFUND" | translate }}
    {{ item.cancellation ? (item.cancellation.price | SPTPrice: item.cancellationDate | async) : "0 €" }}
  </p>
</div>

<spt-reservations-modify
  *ngIf="isDisplayedModification"
  [item]="item"
  [isSmallDevice]="isSmallDevice"
  [locale]="locale"
  (returnToDetails)="isDisplayedModification = undefined"
></spt-reservations-modify>

<spt-reservations-cancel
  *ngIf="isDisplayedCancellation"
  [item]="item"
  [isSmallDevice]="isSmallDevice"
  [locale]="locale"
  (closeSidebar)="closeSidebarEmitter($event)"
  (returnToDetails)="isDisplayedCancellation = undefined"
></spt-reservations-cancel>

<spt-label-side
  [labels]="societyLabels"
  [userLabel]="item.labels"
  [type]="item.type"
  [visible]="showLabelSide"
  [comment]="item.comment"
  (validateUserLabels)="addUserLabel($event)"
></spt-label-side>
