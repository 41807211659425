export default [
  {
    label: "Ulfy Rewards",
    value: "00162",
    type: "Flight",
  },
  {
    label: "Panorama Club",
    value: "00113",
    type: "Flight",
  },
  {
    label: "Privilege",
    value: "00117",
    type: "Flight",
  },
  {
    label: "Fly More",
    value: "00052",
    type: "Flight",
  },
  {
    label: "Topbonus",
    value: "00158",
    type: "Flight",
  },
  {
    label: "SkyPoints",
    value: "00143",
    type: "Flight",
  },
  {
    label: "Indigo Card",
    value: "00072",
    type: "Flight",
  },
  {
    label: "Capricorn",
    value: "00023",
    type: "Flight",
  },
  {
    label: "Royal Skies",
    value: "00129",
    type: "Flight",
  },
  {
    label: "Rapid Rewards",
    value: "00124",
    type: "Flight",
  },
  {
    label: "Safar Flyer",
    value: "00131",
    type: "Flight",
  },
  {
    label: "Jal Mileage Bank",
    value: "00077",
    type: "Flight",
  },
  {
    label: "Infinito",
    value: "00073",
    type: "Flight",
  },
  {
    label: "Sata Imagine",
    value: "00133",
    type: "Flight",
  },
  {
    label: "Flyer Bonus",
    value: "00054",
    type: "Flight",
  },
  {
    label: "Iberia Plus",
    value: "00071",
    type: "Flight",
  },
  {
    label: "Bintermas",
    value: "00021",
    type: "Flight",
  },
  {
    label: "JAL Mileage Bank (JMB)",
    value: "00078",
    type: "Flight",
  },
  {
    label: "Biman Loyalty Club",
    value: "00020",
    type: "Flight",
  },
  {
    label: "Finnair Plus",
    value: "00050",
    type: "Flight",
  },
  {
    label: "Qantas Frequent Flyer",
    value: "00120",
    type: "Flight",
  },
  {
    label: "Club Premier",
    value: "00030",
    type: "Flight",
  },
  {
    label: "Fidelidade TAM",
    value: "00048",
    type: "Flight",
  },
  {
    label: "Royal Orchid Plus",
    value: "00127",
    type: "Flight",
  },
  {
    label: "WestJet Rewards",
    value: "00172",
    type: "Flight",
  },
  {
    label: "HawaiianMiles",
    value: "00069",
    type: "Flight",
  },
  {
    label: "Frequent Flyer Programme",
    value: "00062",
    type: "Flight",
  },
  {
    label: "Skypass",
    value: "00142",
    type: "Flight",
  },
  {
    label: "The Marco Polo Club",
    value: "00156",
    type: "Flight",
  },
  {
    label: "Asia Miles",
    value: "00013",
    type: "Flight",
  },
  {
    label: "PIA Awards Plus+",
    value: "00116",
    type: "Flight",
  },
  {
    label: "Insel Air Aruba",
    value: "00075",
    type: "Flight",
  },
  {
    label: "Pegasus Plus",
    value: "00114",
    type: "Flight",
  },
  {
    label: "Blue Sky Mongolia",
    value: "00022",
    type: "Flight",
  },
  {
    label: "Belavia Leader",
    value: "00018",
    type: "Flight",
  },
  {
    label: "Destinations",
    value: "00033",
    type: "Flight",
  },
  {
    label: "Leader Program",
    value: "00086",
    type: "Flight",
  },
  {
    label: "Nomad Club",
    value: "00106",
    type: "Flight",
  },
  {
    label: "Multiplus",
    value: "00102",
    type: "Flight",
  },
  {
    label: "Correndon (Bronze, Silver, Gold)",
    value: "00032",
    type: "Flight",
  },
  {
    label: "EuroBonus SAS",
    value: "00046",
    type: "Flight",
  },
  {
    label: "Sunmiles",
    value: "00148",
    type: "Flight",
  },
  {
    label: "EarlyReturns",
    value: "00038",
    type: "Flight",
  },
  {
    label: "Namako",
    value: "00104",
    type: "Flight",
  },
  {
    label: "Garuda Frequent Flyer",
    value: "00064",
    type: "Flight",
  },
  {
    label: "Eastern Miles",
    value: "00039",
    type: "Flight",
  },
  {
    label: "Elevate",
    value: "00042",
    type: "Flight",
  },
  {
    label: "Rainbow Club",
    value: "00123",
    type: "Flight",
  },
  {
    label: "Dynasty Flyer",
    value: "00037",
    type: "Flight",
  },
  {
    label: "Travelair Club",
    value: "00160",
    type: "Flight",
  },
  {
    label: "SkyGift",
    value: "00140",
    type: "Flight",
  },
  {
    label: "Aeroflot Bonus",
    value: "00004",
    type: "Flight",
  },
  {
    label: "Airpoints",
    value: "00009",
    type: "Flight",
  },
  {
    label: "Life Miles",
    value: "00087",
    type: "Flight",
  },
  {
    label: "Royal Plus",
    value: "00128",
    type: "Flight",
  },
  {
    label: "Aeroplan",
    value: "00006",
    type: "Flight",
  },
  {
    label: "Skywards",
    value: "00144",
    type: "Flight",
  },
  {
    label: "Transaero Privilege Program",
    value: "00159",
    type: "Flight",
  },
  {
    label: "AiRunion",
    value: "00010",
    type: "Flight",
  },
  {
    label: "Cloud 9",
    value: "00028",
    type: "Flight",
  },
  {
    label: "Dividend Miles",
    value: "00035",
    type: "Flight",
  },
  {
    label: "Status",
    value: "00147",
    type: "Flight",
  },
  {
    label: "Fortune Wings Club",
    value: "00060",
    type: "Flight",
  },
  {
    label: "Enrich/Grads",
    value: "00043",
    type: "Flight",
  },
  {
    label: "Kestrel Flyer",
    value: "00081",
    type: "Flight",
  },
  {
    label: "Infinity MileageLands",
    value: "00074",
    type: "Flight",
  },
  {
    label: "TBA",
    value: "00154",
    type: "Flight",
  },
  {
    label: "Hi-Fly",
    value: "00070",
    type: "Flight",
  },
  {
    label: "Matmid",
    value: "00093",
    type: "Flight",
  },
  {
    label: "Vol de Plus",
    value: "00170",
    type: "Flight",
  },
  {
    label: "VClub",
    value: "00165",
    type: "Flight",
  },
  {
    label: "Flypass",
    value: "00058",
    type: "Flight",
  },
  {
    label: "Kmiles",
    value: "00083",
    type: "Flight",
  },
  {
    label: "Voyager",
    value: "00171",
    type: "Flight",
  },
  {
    label: "Cedar Miles",
    value: "00026",
    type: "Flight",
  },
  {
    label: "Rewards4all",
    value: "00126",
    type: "Flight",
  },
  {
    label: "Uz Air Plus",
    value: "00163",
    type: "Flight",
  },
  {
    label: "Baltic Miles",
    value: "00017",
    type: "Flight",
  },
  {
    label: "My Blue",
    value: "00103",
    type: "Flight",
  },
  {
    label: "Fidelys",
    value: "00049",
    type: "Flight",
  },
  {
    label: "TACV Club",
    value: "00151",
    type: "Flight",
  },
  {
    label: "Air Algérie Plus",
    value: "00007",
    type: "Flight",
  },
  {
    label: "Golden Panda",
    value: "00068",
    type: "Flight",
  },
  {
    label: "Pacific Wings Plus",
    value: "00112",
    type: "Flight",
  },
  {
    label: "Air Moldova Club",
    value: "00008",
    type: "Flight",
  },
  {
    label: "Miles & Bonus",
    value: "00098",
    type: "Flight",
  },
  {
    label: "KrisFlyer",
    value: "00084",
    type: "Flight",
  },
  {
    label: "AAdvantage",
    value: "00003",
    type: "Flight",
  },
  {
    label: "Saga Club",
    value: "00132",
    type: "Flight",
  },
  {
    label: "FlySmiles",
    value: "00059",
    type: "Flight",
  },
  {
    label: "JetPrivilege",
    value: "00080",
    type: "Flight",
  },
  {
    label: "Frequence Plus",
    value: "00061",
    type: "Flight",
  },
  {
    label: "LifeMiles",
    value: "00088",
    type: "Flight",
  },
  {
    label: "Sindbad Frequent Flyer",
    value: "00135",
    type: "Flight",
  },
  {
    label: "Sheba Miles",
    value: "00134",
    type: "Flight",
  },
  {
    label: "Asiana Club",
    value: "00014",
    type: "Flight",
  },
  {
    label: "Sky Pearl Club",
    value: "00137",
    type: "Flight",
  },
  {
    label: "OK Plus",
    value: "00109",
    type: "Flight",
  },
  {
    label: "Vantage Club",
    value: "00164",
    type: "Flight",
  },
  {
    label: "Gold Circle Club",
    value: "00066",
    type: "Flight",
  },
  {
    label: "Azur Plus",
    value: "00016",
    type: "Flight",
  },
  {
    label: "Vision Team",
    value: "00169",
    type: "Flight",
  },
  {
    label: "FlyAway Rewards",
    value: "00053",
    type: "Flight",
  },
  {
    label: "Aerolineas Plus",
    value: "00005",
    type: "Flight",
  },
  {
    label: "Etihad Guest",
    value: "00044",
    type: "Flight",
  },
  {
    label: "Celestars",
    value: "00027",
    type: "Flight",
  },
  {
    label: "Phoenix Miles",
    value: "00115",
    type: "Flight",
  },
  {
    label: "Executive Club",
    value: "00047",
    type: "Flight",
  },
  {
    label: "Velocity Frequent Flyer",
    value: "00166",
    type: "Flight",
  },
  {
    label: "Caribbean Miles",
    value: "00024",
    type: "Flight",
  },
  {
    label: "SkyMiles",
    value: "00141",
    type: "Flight",
  },
  {
    label: "Sir Turtle Rewards",
    value: "00136",
    type: "Flight",
  },
  {
    label: "ANA Mileage Club",
    value: "00012",
    type: "Flight",
  },
  {
    label: "TAP Victoria",
    value: "00153",
    type: "Flight",
  },
  {
    label: "Flying Returns",
    value: "00057",
    type: "Flight",
  },
  {
    label: "Yemenia Sama Club",
    value: "00173",
    type: "Flight",
  },
  {
    label: "7th Heaven",
    value: "00001",
    type: "Flight",
  },
  {
    label: "Qatar Airways Privilege Club",
    value: "00121",
    type: "Flight",
  },
  {
    label: "Jet Privilège",
    value: "00079",
    type: "Flight",
  },
  {
    label: "EuroBonus",
    value: "00045",
    type: "Flight",
  },
  {
    label: "Alfursan®",
    value: "00011",
    type: "Flight",
  },
  {
    label: "AZAL Miles",
    value: "00015",
    type: "Flight",
  },
  {
    label: "Trueblue",
    value: "00161",
    type: "Flight",
  },
  {
    label: "Egret Card",
    value: "00040",
    type: "Flight",
  },
  {
    label: "Programa Fidelidade",
    value: "00118",
    type: "Flight",
  },
  {
    label: "S7 Priority",
    value: "00130",
    type: "Flight",
  },
  {
    label: "LANPASS",
    value: "00085",
    type: "Flight",
  },
  {
    label: "Carpatair Green Club",
    value: "00025",
    type: "Flight",
  },
  {
    label: "Nok Fan Club",
    value: "00105",
    type: "Flight",
  },
  {
    label: "Paa Royal",
    value: "00111",
    type: "Flight",
  },
  {
    label: "Punto",
    value: "00119",
    type: "Flight",
  },
  {
    label: "Malmö Aviation Bonusprogram",
    value: "00091",
    type: "Flight",
  },
  {
    label: "Club Tiare",
    value: "00031",
    type: "Flight",
  },
  {
    label: "Flying Blue",
    carrier: "AF",
    value: "00055",
    type: "Flight",
  },
  {
    label: "Flying Club",
    value: "00056",
    type: "Flight",
  },
  {
    label: "Reward$",
    value: "00125",
    type: "Flight",
  },
  {
    label: "Miles & More",
    value: "00099",
    type: "Flight",
  },
  {
    label: "Top Bonus",
    value: "00157",
    type: "Flight",
  },
  {
    label: "Sky Plus",
    value: "00138",
    type: "Flight",
  },
  {
    label: "EgyptAir Plus",
    value: "00041",
    type: "Flight",
  },
  {
    label: "MilleMiglia",
    value: "00101",
    type: "Flight",
  },
  {
    label: "Sky Smile",
    value: "00139",
    type: "Flight",
  },
  {
    label: "Mileage Plan",
    value: "00096",
    type: "Flight",
  },
  {
    label: "Teemane Club",
    value: "00155",
    type: "Flight",
  },
  {
    label: "Oasis Club",
    value: "00108",
    type: "Flight",
  },
  {
    label: "Golden Lotus Plus",
    value: "00067",
    type: "Flight",
  },
  {
    label: "Miles & Smiles",
    value: "00100",
    type: "Flight",
  },
  {
    label: "SNCF - Voyageur",
    value: "SNCF001",
    type: "Train",
  },
  {
    label: "SNCF - Grand Voyageur",
    value: "SNCF002",
    type: "Train",
  },
  {
    label: "SNCF - Grand Voyageur Plus",
    value: "SNCF003",
    type: "Train",
  },
  {
    label: "SNCF - Grand Voyageur Le Club",
    value: "SNCF004",
    type: "Train",
  },
  {
    label: "Sixt Car Express Card",
    value: "ID",
    type: "car",
  },
];
