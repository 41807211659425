import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "dateParser",
})
export class DateParserPipe implements PipeTransform {
  constructor() {}

  transform(_date: any, _initialDate: any): string {
    return _date.replace(/:\d{2}(\+|\-|Z).*/, "");
  }
}
