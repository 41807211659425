<div class="header"></div>
<div class="main">
  <div class="bagage-container-left-panel">
    <img class="transport-icon" src="./assets/svg/bagageAfkl.svg" alt="test" />
  </div>
  <div class="bagage-container-right-panel">
    <div class="bagage-content">
      <div class="bagage-content-header">
        <h1>
          {{"REPORTING.GENERAL.TOP.TRAVELERS.TOOLTIP.TRAVEL" | translate }} : {{ airports[0]?.city }}, {{ airports[0]?.iata }}- {{ airports[1]?.city }}, {{ airports[1]?.iata }}
        </h1>
        <p>{{"SEARCH.RESULT.AIR.LUGGAGE.LUGGAGE_PAGE_TOP_LABEL" | translate}}</p>
      </div>

      <div class="bagage-content-offers">
        <div class="bagage-content-offers-dropdown">
          <p-dropdown
            [options]="offers"
            [disabled]="pricedOffer"
            [placeholder]="'GLOBAL.ALL' | translate"
            (onChange)="selectLuggage($event)"
            optionLabel="Service.ServiceDefinitionRefID.Name"
            [showClear]="true"
          >
          </p-dropdown>
        </div>
        <div class="bagage-content-offers-resume">
          <p class="offer-item">
            {{ selectedOffer?.quantity }} x “{{ selectedOffer?.service?.Name }}” {{"SEARCH.RESULT.AIR.LUGGAGE.SUP_LUGGAGE" | translate}}
            {{
              item?.formData?.orderCreateRS?.DataLists?.PaxList?.Pax.length
            }}
            {{ item?.formData?.orderCreateRS?.DataLists?.PaxList?.Pax.length > 1 ? ("REPORTING.GENERAL.TOP.TRAVELERS.TOOLTIP.TRAVELS" | translate) : ("REPORTING.GENERAL.TOP.TRAVELERS.TOOLTIP.TRAVEL" | translate) }}.........................................{{ selectedOffer?.price }} €
          </p>
        </div>
        <div class="bagage-content-offers-price-resume" *ngIf="selectedOffer">
          <p class="total">Total :</p>
          <p class="price">
            {{ selectedOffer?.price * item?.formData?.orderCreateRS?.DataLists?.PaxList?.Pax.length }}€
          </p>
        </div>
      </div>

      <div class="bagage-content-info">
        <div class="bagage-infobulle-container">
          <div class="bagage-infobulle-container-left">
            <img class="trigger ng-star-inserted" alt="info" src="./assets/svg/info-fonce.svg" />
          </div>
          <div class="bagage-infobulle-container-right">
            <div class="bagage-infobulle-container-left-title">
              {{"SEARCH.RESULT.AIR.LUGGAGE.LUGGAGE_PAGE_ALERT_ONE" | translate}}
            </div>
            <div class="bagage-infobulle-container-left-desc">
              {{"SEARCH.RESULT.AIR.LUGGAGE.LUGGAGE_PAGE_ALERT_TWO" | translate}}
            </div>
          </div>
        </div>
        <div class="bagage-desc-container">
          <p>
            <b>*{{"GLOBAL.FLIGHT_BAGAGES.SIMPLE_BAGAGE" | translate}} :</b> {{"GLOBAL.FLIGHT_BAGAGES.INFORMATION.BAGAGE" | translate}} 
          </p>
          <p>
            <b>**{{"GLOBAL.FLIGHT_BAGAGES.SIMPLE_CARRY" | translate}} :</b> {{"GLOBAL.FLIGHT_BAGAGES.INFORMATION.BAGAGE" | translate}} 
          </p>
        </div>
        <div class="actions">
          <div class="cancel">
            <p-button
              (mousedown)="close()"
              label="{{ 'GLOBAL.CANCEL' | translate }}"
              styleClass="ui-button-sixth"
              type="button"
            >
            </p-button>
          </div>

          <div class="validation">
            <p-button
              [disabled]="!selectedOffer"
              (mousedown)="selectService()"
              label="{{ 'GLOBAL.VALIDATE' | translate }}"
              type="button"
            >
            </p-button>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</div>
