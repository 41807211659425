import { Component, ViewEncapsulation, Input } from "@angular/core";
@Component({
  selector: "spt-cloud-bg",
  templateUrl: "./cloud-background.component.html",
  styleUrls: ["./cloud-background.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CloudBackgroundComponent {
  @Input() loading: boolean;

  constructor() {}
}
