import { NgModule } from "@angular/core";

import { SharedModule } from "../@shared/shared.module";
import { RequestActionsComponent } from "./@dashboard-shared/components/header/request/actions/actions.component";
import { RequestFlightComponent } from "./@dashboard-shared/components/header/request/flight/flight.component";
import { RequestHotelComponent } from "./@dashboard-shared/components/header/request/hotel/hotel.component";
import { RequestRentalCarComponent } from "./@dashboard-shared/components/header/request/rental-car/rental-car.component";
import { RequestComponent } from "./@dashboard-shared/components/header/request/request.component";
import { RequestTrainComponent } from "./@dashboard-shared/components/header/request/train/train.component";
import { RequestTransferComponent } from "./@dashboard-shared/components/header/request/transfer/transfer.component";

import { SearchModule } from "../search/search.module";
import { SelectMembersModule } from "./@dashboard-shared/modals/select-members/select-members.module";

import { HomeComponent } from "./home/home.component";
import { HeaderComponent } from "./@dashboard-shared/components/header/header.component";

import { DashboardRouting } from "./dashboard.routing";
import { DashboardComponent } from "./dashboard.component";

import { MenuModule } from "primeng/menu";
import { MenubarModule } from "primeng/menubar";
import { TabViewModule } from "primeng/tabview";
import { ButtonModule } from "primeng/button";
import { DropdownModule } from "primeng/dropdown";
import { ToastModule } from "primeng/toast";
import { AutoCompleteModule } from "primeng/autocomplete";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ToolbarModule } from "primeng/toolbar";
import { BookingListModule } from "./@dashboard-shared/components/booking-list/booking-list.module";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ManagersComponent } from "./@dashboard-shared/components/header/request/managers/managers.component";
import { EticketsModalModule } from "./@dashboard-shared/modals/etickets-modal/etickets-modal.module";
import { BillsModalModule } from "./@dashboard-shared/modals/bills-modal/bills-modal.module";
import { NotificationsComponent } from "./@dashboard-shared/components/notifications/notifications.component";

const DASHBOARD_COMPONENTS: Array<any> = [
  DashboardComponent,
  HomeComponent,
  HeaderComponent,
  RequestComponent,
  RequestActionsComponent,
  RequestFlightComponent,
  RequestHotelComponent,
  RequestRentalCarComponent,
  RequestTrainComponent,
  RequestTransferComponent,
  ManagersComponent,
  NotificationsComponent,
];

const DASHBOARD_MODULES: Array<any> = [
  SharedModule,
  SelectMembersModule,
  SearchModule,
  BookingListModule,
  MenuModule,
  MenubarModule,
  ToolbarModule,
  LeafletModule,
  ToastModule,
  OverlayPanelModule,
  TabViewModule,
  ButtonModule,
  DropdownModule,
  AutoCompleteModule,
  ScrollingModule,
  EticketsModalModule,
  BillsModalModule,
  // ManagementModule
];

@NgModule({
  imports: [...DASHBOARD_MODULES, DashboardRouting],
  declarations: [...DASHBOARD_COMPONENTS],
  exports: [HeaderComponent],
  // exports: [HeaderComponent],
})
export class DashboardModule {}
