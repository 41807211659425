import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "duration",
})
export class DurationPipe implements PipeTransform {
  transform(value: string): string {
    const period: RegExpMatchArray = value.match(/PT(?:(\d?.?\d+?)H)?(?:(\d?.?\d+?)M)?(?:(\d?.?\d+?)S)?/);
    let hours: string = period[1];
    let mins: string = period[2];
    const secs: string = period[3];

    if (hours) {
      const dotIndex: number = hours.indexOf(".");
      if (dotIndex !== -1) {
        const fractionalHours: number = Number(hours.slice(hours.indexOf(".")));
        mins = `${Math.round(fractionalHours * 60)}`;
        hours = hours.slice(0, hours.indexOf("."));
      }
    }

    return `${hours ? `${hours}h` : ""}${mins ? `${mins}m` : ""}${secs ? `${secs}s` : ""}`;
  }
}
