<ng-container *ngIf="itineraries[0].legs?.length; else afklItineraryLeg">
  <ng-container *ngFor="let itinerary of this.itineraries; let itineraryIndex = index">
    <div class="legs">
      <div *ngFor="let leg of itinerary.legs; let tripIndex = index" class="leg">
        <div class="separator" *ngIf="tripIndex === 1 && itinerary.legs.length > 1"></div>
        <p class="direction" *ngIf="itinerary.legs.length > 1">
          {{ "GLOBAL." + (tripIndex === 0 ? "outward" : "inward") | uppercase | translate }}
        </p>
        <spt-travel-flight-itinerary-leg
          [departureDate]="leg.schedules[0].departure.date"
          [baggageIncluded]="itinerary.baggageIncluded"
          [carryOnIncluded]="itinerary.carryOnIncluded"
          [carbonOffset]="itinerary?.carbonOffset"
          [leg]="leg"
          [showClass]="false"
          class="hideInformations"
        ></spt-travel-flight-itinerary-leg>
        <div class="company" *ngIf="tripIndex === 1 && itinerary.legs.length > 1">
          <span>{{ "SEARCH.RESULT.AIR.SOLD_BY" | translate }} {{ companies[leg.governingCarrier]?.name }}</span>
          <img class="icon" src="https://static.misterfly.com:443/images/airlines/{{ leg.governingCarrier }}.png" />
        </div>
      </div>
    </div>
    <div class="warning" *ngIf="airportsDifferents" translate>SEARCH.AIR.AIRPORTS_WARNING</div>
    <div class="charges" *ngIf="itineraries?.length > 1">
      <div class="title">{{ "SEARCH.RESULT.TOTAL" | translate }}:</div>

      <div class="price">
        {{
          {
            amount: itinerary.price ? itinerary.price : itinerary.totalPrice,
            currency: "EUR"
          }
            | SPTPrice
            | async
        }}
        <span class="out-policy" *ngIf="itinerary.isOutPolicy" translate>GLOBAL.OUT_OF_POLICY</span>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #afklItineraryLeg>
  <div class="legs">
    <div *ngFor="let itinerary of this.itineraries; let tripIndex = index" class="leg">
      <div class="separator" *ngIf="tripIndex === 1 && itineraries.length > 1"></div>
      <spt-travel-flight-itinerary-leg
        [departureDate]="itinerary.Dep.AircraftScheduledDateTime"
        [baggageIncluded]="itinerary.baggageIncluded"
        [carryOnIncluded]="itinerary.carryOnIncluded"
        [carbonOffset]="itinerary?.carbonOffset"
        [leg]="itinerary"
        [showClass]="false"
        class="hideInformations"
      ></spt-travel-flight-itinerary-leg>
      <div class="company" *ngIf="tripIndex === 1 && itineraries.length > 1">
        <span
          >{{ "SEARCH.RESULT.AIR.SOLD_BY" | translate }}
          {{ companies[itinerary.MarketingCarrierInfo?.CarrierDesigCode]?.name }}</span
        >
        <img
          class="icon"
          src="https://static.misterfly.com:443/images/airlines/{{
            itinerary.MarketingCarrierInfo?.CarrierDesigCode
          }}.png"
        />
      </div>
    </div>
  </div>
  <div class="warning" *ngIf="airportsDifferents" translate>SEARCH.AIR.AIRPORTS_WARNING</div>
  <div class="charges" *ngIf="itineraries?.length > 1">
    <div class="title">{{ "SEARCH.RESULT.TOTAL" | translate }}:</div>

    <div class="price">
      {{
        {
          amount: item.price ? item.price.amount : itineraries[0].totalPrice,
          currency: "EUR"
        }
          | SPTPrice
          | async
      }}
      <span class="out-policy" *ngIf="itineraries[0].isOutPolicy" translate>GLOBAL.OUT_OF_POLICY</span>
    </div>
  </div>
</ng-template>
