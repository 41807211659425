import { Component, EventEmitter, ViewEncapsulation, OnInit, Output, Input } from "@angular/core";
import { SelectItem } from "primeng/api";
import { SessionService } from "src/app/@shared/services/session.service";
@Component({
  selector: "spt-time-selector",
  templateUrl: "./time-selector.component.html",
  styleUrls: ["./time-selector.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TimeSelectorComponent implements OnInit {
  @Input()
  value: string;

  minuteOptions: SelectItem[] = [];
  hourOptions: SelectItem[] = [];

  hours: string;
  minutes: string;

  @Output()
  onChange: EventEmitter<TimeOption> = new EventEmitter<TimeOption>();

  constructor(private sessionService: SessionService<any>) {}

  ngOnInit(): void {
    const session = this.sessionService.get();
    this.hourOptions = buildHourOptions(session.timeFormat);
    this.minuteOptions = buildMinuteOptions();
    const v = (this.value || "").split(":");
    if (v.length === 2) {
      this.hours = v[0];
      this.minutes = v[1];
    }
  }

  update(): void {
    if (!this.hours || !this.minutes) {
      return;
    }
    const value = this.hours + ":" + this.minutes;
    if (value !== this.value) {
      this.value = value;
      this.onChange.emit({
        value,
        hours: this.hourOptions.find((o) => o.value === this.hours),
        minutes: this.minuteOptions.find((o) => o.value === this.minutes),
      });
    }
  }
}

function buildMinuteOptions(): SelectItem[] {
  const result: SelectItem[] = [];
  for (let i: number = 0; i < 60; i++) {
    const m = i.toString().padStart(2, "0");
    result.push({
      label: m,
      value: m,
    });
  }
  return result;
}

function buildHourOptions(timeFormat: "12" | "24"): SelectItem[] {
  const result: SelectItem[] = [];
  if (timeFormat === "12") {
    result.push({ label: `12AM`, value: `00` });
    for (let i: number = 1; i < 12; i++) {
      const h = i.toString().padStart(2, "0");
      result.push({
        label: h + "AM",
        value: h,
      });
    }
    result.push({ label: `12PM`, value: `12` });

    for (let i: number = 1; i < 12; i++) {
      const h = i.toString().padStart(2, "0");
      result.push({
        label: h + "PM",
        value: h,
      });
    }
  } else {
    for (let i: number = 0; i < 24; i++) {
      const h = i.toString().padStart(2, "0");
      result.push({
        label: h,
        value: h,
      });
    }
  }
  return result;
}

export interface TimeOption {
  value: string;
  hours: SelectItem;
  minutes: SelectItem;
}
