import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";

import { CommonService } from "src/app/@shared/services/common.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "spt-basket-car-block",
  templateUrl: "./car-block.component.html",
  styleUrls: ["./car-block.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CarBlockComponent {
  private _item: any;
  @Input() set item(value: any) {
    this._item = value;
    this.itemUpdated();
  }
  get item() {
    return this._item;
  }
  public pickupInfo: {
    place: string;
    date: string;
    time: string;
    city: string;
  };
  public returnInfo: {
    place: string;
    date: string;
    time: string;
    city: string;
  };
  public locale: string;
  public hasDifferentAddress: boolean;

  constructor(
    public commonService: CommonService,
    private translateService: TranslateService,
  ) {}

  itemUpdated(): void {
    this.locale = this.translateService.currentLang;
    this.pickupInfo = this.generateInfos(this.item.detail, "pickup");
    this.returnInfo = this.generateInfos(this.item.detail, "return");
    this.hasDifferentAddress =
      this.returnInfo.place !== this.pickupInfo.place || this.returnInfo.city !== this.pickupInfo.city;
  }

  generateInfos(detail: any, type: string): any {
    const { line1, line2, city, street } = detail[`${type}Address`];
    const { date, time } = detail[`${type}Date`];
    return {
      place: [line1, line2].join(","),
      city: `${city}`,
      date,
      time,
    };
  }
}
