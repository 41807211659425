import { Billing, ItemFlight, RequestFlight } from "src/app/@shared/@types/models";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { MemberSociety, Society } from "src/app/@shared/@types/society";

import { ActivatedRoute } from "@angular/router";
import { Label } from "src/app/@shared/@types/label";
import { LabelService } from "src/app/@shared/services/label.service";
import _companies from "../../../@shared/companies";
import { SocietyService } from "src/app/@shared/services/society.service";

@Component({
  selector: "spt-travel-flight-details",
  styleUrls: ["./travel-flight-details.component.scss"],
  templateUrl: "./travel-flight-details.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class TravelFlightDetailsComponent implements OnInit, OnDestroy {
  public companies: any = _companies;
  @Input() searchId: string;
  @Input() form: any;
  // @Input() itinerary: any;
  @Input() itineraries: any;
  @Input() users: any;
  @Input() bookRequest?: RequestFlight;
  @Input() hasOutOfPolicy: boolean;
  @Input() hasBook: boolean;
  @Input() hasSelectEntity: boolean;
  @Output() closePanel: any = new EventEmitter();
  @Output() isBooking: any = new EventEmitter();

  @Input() departureDates: any;

  public superflexMandatory: boolean = false;

  public tagIds: any;
  public billing: any;
  public tags: Array<any>;
  public showLabelSide: boolean = false;

  public commentPolicy: string;

  public userLabel: any;
  public comment: any;
  @Input() society: Society;
  // carbonOffset: any;
  private bookingData: any;
  public labels: any;

  public superflex: boolean;
  protected readonly superflexRate: number = 0.3;
  public superflexAmount: number;
  public total: number;
  public airportsDifferents: boolean;
  public listBillings: Array<{ value: string; label: string }>;

  public carbonOffset = { amount: 0, price: 0 };
  public isOutOfPolicy = false;
  constructor(
    private route: ActivatedRoute,
    private labelService: LabelService,
    private societyService: SocietyService,
  ) {}

  ngOnInit(): void {
    this.total = 0;
    this.itineraries.forEach((itinerary) => {
      this.total += itinerary.price ? itinerary.price : parseFloat(itinerary.totalPrice);
      this.carbonOffset.amount += itinerary.amount;
      this.carbonOffset.price += itinerary.price;
      if (itinerary.isOutPolicy) {
        this.isOutOfPolicy = true;
      }
    });
    this.superflexAmount = this.total * this.superflexRate;
    if (this.bookRequest && this.bookRequest.item && (this.bookRequest.item as ItemFlight).metadata.superflex) {
      this.activeSuperflex((this.bookRequest.item as ItemFlight).metadata.superflex);
      // this.superflex = (this.bookRequest.item as ItemFlight).metadat.superflex || false;
    } else {
      this.superflex = false;
    }
    this.labels = this.route.snapshot.data["labels"].filter((label: Label) => label.isActive);

    if (this.bookRequest && this.bookRequest.userLabel) {
      this.userLabel = this.bookRequest.userLabel;
      this.comment = this.bookRequest.comment;
    }

    this.airportsDifferents = false;

    this.society.members.forEach((member: MemberSociety) => {
      if (member.policy) {
        this.commentPolicy = this.commentPolicy === "mandatory" ? "mandatory" : member.policy.commentMandatory;
        if (!this.superflexMandatory && member.policy.flight && member.policy.flight.superflexible) {
          this.superflexMandatory = member.policy.flight.superflexible;
        }
      }
    });
    this.initBillings();
  }

  ngOnDestroy(): void {}

  initBillings(): void {
    if (this.society) {
      this.billing = this.users[0].billing;
      this.listBillings = this.societyService.billings
        .getValue()
        ?.filter((billing: Billing) => !billing.status || billing.status !== "unactive")
        .map((billing: Billing) => {
          return {
            value: billing.id,
            label: billing.service ? `${billing.raison} (${billing.service})` : billing.raison,
          };
        });
    }
  }

  getTags(tags: any): void {
    this.tags = tags;
  }

  close(): void {
    this.closePanel.emit();
  }

  changeOffsetPrice(value: any): void {
    this.itineraries.forEach((itinerary) => {
      itinerary.carbonOffset.paid = value;
    });
    // this.itinerary.carbonOffset.paid = value;
  }

  addUserLabel(event: any): void {
    if (event.userLabel || event.comment) {
      if (event.comment) {
        this.comment = event.comment;
        this.bookingData.comment = this.comment;
      }

      if (event.userLabel) {
        this.userLabel = event.userLabel;
        this.labelService.updateUserLabel(this.userLabel);
        this.bookingData.userLabel = this.userLabel;
      }
    } else {
      this.showLabelSide = false;
    }
  }

  activeSuperflex(active: boolean = false): void {
    this.superflex = active;
    let price = 0;
    this.itineraries.forEach((itinerary) => {
      price += itinerary.price ? itinerary.price : itinerary.totalPrice;
    });
    this.total = this.superflex === true ? Number(price) + Number(price) * this.superflexRate : Number(price);
  }
}
