import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Address, Billing, CustomField } from "src/app/@shared/@types/models";
import { map, tap } from "rxjs/operators";
import { Society, OptionsSociety, MemberSociety } from "../@types/society";
import { User } from "../@types/user";
import { BehaviorSubject, Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SocietyService {
  public society: BehaviorSubject<Society> = new BehaviorSubject<Society>(null);
  public billingCurrency: string; // TODO: not used, must be completed to take billing currency as second default for display
  private _society: Society;
  public billings: BehaviorSubject<Billing[]> = new BehaviorSubject<Billing[]>(null);

  constructor(private readonly httpClient: HttpClient) {}

  public init(): void {
    this._society = undefined;
    this.society.next(null);
  }

  reset(): void {
    this.billings.next(null);
    this.init();
  }

  public getSociety(societyId: string, options: OptionsSociety, saveCurrent: boolean = false): Observable<Society> {
    const endPoint: string = `${environment.api}/api/society/${societyId}`;

    // recup la société
    // regarde flatmap , prendre resultat, si members existe
    // cherche société
    // si pas populate alors get society
    // get dans current
    let params: HttpParams = new HttpParams();
    const optionsKeys: Array<string> = Object.keys(options || {});
    optionsKeys.forEach((key: string) => {
      params = params.set(key, options[key]);
    });

    return this.httpClient.get(endPoint, { params }).pipe(
      map((res: any): Society => this.mapSocietyFront(res.data)),
      map((society: Society) => {
        if (options.popMember) {
          society.members.sort((a: MemberSociety, b: MemberSociety) => {
            const usernameA: string = a.user.username.toLocaleLowerCase();
            const usernameB: string = b.user.username.toLocaleLowerCase();
            if (usernameA > usernameB) {
              return 1;
            } else if (usernameA < usernameB) {
              return -1;
            }
            return 0;
          });
        }
        if (saveCurrent) {
          this.saveSociety(society, options);
        }
        return society;
      }),
    );
  }

  public getMember(memberId: string): Observable<MemberSociety> {
    const endPoint: string = `${environment.api}/societies/${this.society.value._id}/members/${memberId}`;
    return this.httpClient.get(endPoint) as Observable<MemberSociety>;
  }

  private saveSociety(data: Society, options?: OptionsSociety): void {
    const tmp: Society = { ...this._society, ...data };

    if (options && !options.popMember && this._society) {
      tmp.members = this._society.members;
    }
    this._society = tmp;
    this.society.next(tmp);
  }

  public setSociety(name: string, saveCurrent: boolean = false): Observable<Society | any> {
    const endPoint: string = `${environment.api}/api/society`;

    const body: any = {
      name,
    };

    return this.httpClient.post(endPoint, body).pipe(
      map((res: any): Society => this.mapSocietyFront(res.data)),
      map((society: Society) => {
        if (saveCurrent === true) {
          this.saveSociety(society);
        }

        return society;
      }),
    );
  }

  assignPolicyToMembers(societyId: string, membersSelected: Array<MemberSociety>, policyId: string): Observable<void> {
    const idsMember: Array<string> = membersSelected.map((member: MemberSociety) => member.user._id);

    const body: any = {
      idsMember,
      policy: policyId,
    };

    return this.putSociety(societyId, body, false).pipe(
      tap(() => {
        this._society.members.forEach((member: MemberSociety, indexSociety: number) => {
          const index: number = membersSelected.findIndex(
            (memberSelected: MemberSociety) => memberSelected._id === member._id,
          );
          if (index > -1) {
            this._society.members[indexSociety] = membersSelected[index];
          }
        });
        this.society.next(this._society);
      }),
    );
  }

  putSociety(societyId: string, body: any, saveCurrent: boolean): Observable<Society | any> {
    const endPoint: string = `${environment.api}/api/society/${societyId}`;

    return this.httpClient.put(endPoint, body).pipe(
      map((res: any): Society => this.mapSocietyFront(res.data)),
      map((society: Society) => {
        if (saveCurrent === true) {
          this.saveSociety(society);
        }

        return society;
      }),
    );
  }

  deleteCustomFields(society: string | Society, _id: string): Observable<Society | any> {
    let societyId: string;
    let saveSociety: boolean;
    let customFields: Array<CustomField>;

    if (typeof society === "string" && this._society._id === society) {
      societyId = this._society._id;
      customFields = this._society.customFields.filter((customFied: CustomField) => customFied._id !== _id);
      saveSociety = true;
    } else if (typeof society !== "string" && society._id) {
      societyId = society._id;
      customFields = society.customFields.filter((customFied: CustomField) => customFied._id !== _id);
      saveSociety = false;
    } else {
      return of();
    }

    const body: any = {
      customFields,
    };
    return this.putSociety(societyId, body, saveSociety);
  }

  addCustomFieds(society: string | Society, newCustomFields: string[]): Observable<Society | any> {
    let societyId: string;
    let saveSociety: boolean;
    let customFields: Array<CustomField | string>;

    if (typeof society === "string" && this._society._id === society) {
      societyId = this._society._id;
      customFields = [...this._society.customFields, ...newCustomFields];
      saveSociety = true;
    } else if (typeof society !== "string" && society._id) {
      societyId = society._id;
      customFields = [...society.customFields, ...newCustomFields];
      saveSociety = false;
    } else {
      return of();
    }

    const body: any = {
      customFields,
    };
    return this.putSociety(societyId, body, saveSociety);
  }

  addBilling(billing: Billing): Observable<any> {
    const endpoint = `${environment.api}/billings`;
    return this.httpClient.post(endpoint, billing);
  }

  attachPaymentMethod(
    billingId: string,
    paymentMethodId: string,
    frequency: "monthly" | "bimonthly" | "on_purchase",
    titleSettings: any,
    day_of_payment?: number,
    paymentDelay?: number,
  ) {
    const endpoint = `${environment.api}/billings/${billingId}/payment-method`;
    return this.httpClient.put(endpoint, {
      paymentMethodId,
      frequency,
      day_of_payment,
      titleSettings,
      paymentDelay,
    });
  }

  addPaymentMethod(societyId: string, token: string): Observable<Society> {
    const body: any = {
      addSource: token,
    };

    return this.putSociety(societyId, body, false).pipe(
      map((society: Society) => {
        let card: any;
        if (society.addedMoP.object === "card") {
          card = {
            brand: society.addedMoP.brand,
            customer: society.addedMoP.customer,
            exp_month:
              society.addedMoP.exp_month < 10
                ? "0" + society.addedMoP.exp_month.toString()
                : society.addedMoP.exp_month.toString(),
            exp_year: society.addedMoP.exp_year.toString(),
            id: society.addedMoP.id,
            last4: society.addedMoP.last4,
            name: society.addedMoP.name,
          };
        } else {
          card = society.addedMoP;
        }
        if (this._society.mop && this._society.mop.length) {
          this._society.mop.push(card);
        } else {
          this._society.mop = [card];
        }
        this.society.next(this._society);
        return JSON.parse(JSON.stringify(this._society));
      }),
    );
  }

  changeDefaultCard(societyId: string, cardId: string): Observable<Society> {
    const body: any = {
      changeDefaultMoP: cardId,
    };
    return this.putSociety(societyId, body, true);
  }

  retrieveSociety(currentUserId: string, full: boolean = false, saveCurrent: boolean): Observable<Society | string> {
    const endPoint: string = `${environment.api}/api/society`;
    return this.httpClient.get(endPoint).pipe(
      map((res: any) => this.mapSocietyFront(res, currentUserId)),
      map((society: Society) => {
        if (saveCurrent === true) {
          this.saveSociety(society);
        }
        if (full === false) {
          return society._id;
        } else {
          return society;
        }
      }),
    );
  }

  public updateUser(user: User): void {
    const _member: MemberSociety = this._society.members.find((member: MemberSociety) => member.user._id === user._id);
    if (_member) {
      _member.user = user;
    }
  }

  mapSocietyFront(res: any, currentUserId?: string): Society {
    let data: any;

    if (res && res.data && res.data.length > 0) {
      data = res.data.find((society: any) => {
        const hasCurrentUser: number = society.members.findIndex((member: any) => {
          if (member && member.user === currentUserId) {
            return true;
          } else {
            return false;
          }
        });
        if (hasCurrentUser > -1) {
          return true;
        } else {
          return false;
        }
      });
    } else if (res && res.data) {
      data = res.data;
    } else {
      data = res;
    }

    if (data.mop) {
      const mops: any = data.mop.data ? data.mop.data : data.mop;
      data.mop = mops.map((mop: any) => {
        if (mop.object === "card") {
          return {
            brand: mop.brand,
            customer: mop.customer,
            exp_month: mop.exp_month < 10 ? "0" + mop.exp_month.toString() : mop.exp_month.toString(),
            exp_year: mop.exp_year.toString(),
            id: mop.id,
            last4: mop.last4,
            name: mop.name,
          };
        } else if (mop.type === "sepa_debit") {
          return mop;
        } else if (mop.object === "payment_method") {
          return {
            brand: mop.card.brand,
            customer: mop.customer,
            exp_month: mop.card.exp_month < 10 ? "0" + mop.card.exp_month.toString() : mop.card.exp_month.toString(),
            exp_year: mop.card.exp_year.toString(),
            id: mop.id,
            last4: mop.card.last4,
            name: mop.billing_details.name,
          };
        } else {
          return mop;
        }
      });
    }
    return data;
  }

  mapUserBack(society: Society): any {
    return society;
  }

  public getManagers(userId: string, all: boolean = false, isOutOfPolicy: boolean = false): Observable<Array<User>> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        userId,
        all: all === true ? "true" : "false",
        isOutOfPolicy: isOutOfPolicy === true ? "true" : "false",
      },
    });
    const endpoint: string = `${environment.api}/api/managers`;
    return this.httpClient
      .get(endpoint, { params, headers: { ignoreErrorMessage: "true" } })
      .pipe(map((result: any) => result.data));
  }

  public getAdmins(userId: string): Observable<Array<User>> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        userId,
      },
    });
    const endpoint: string = `${environment.api}/authorizations/validationCircuit/getMembers/manager`;
    return this.httpClient
      .get(endpoint, { params, headers: { ignoreErrorMessage: "true" } })
      .pipe(map((result: any) => result.data));
  }

  getBillings(forceUpdate: boolean = false) {
    if (forceUpdate === false && this.billings.value !== null) {
      return of(this.billings.value);
    } else {
      return this.httpClient.get(`${environment.api}/billings`).pipe(
        map((billings: Billing[]) => {
          this.billings.next(billings);
          return billings;
        }),
      );
    }
  }
}
