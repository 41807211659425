import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { LocalizedDatePipe } from "src/app/@shared/pipes/localized-date.pipe";
import { CommonService } from "src/app/@shared/services/common.service";
import { CarbonOffsetTypes } from "../../../@shared/@types/carbon-offset";
import { ModalService } from "src/app/@shared/services/modal.service";
import { AFKLAncillariesComponent } from "../afkl-ancillaries/afkl-ancillaries.component";

@Component({
  selector: "spt-flight-validate-option",
  styleUrls: ["./flight-validate-option.component.scss"],
  templateUrl: "./flight-validate-option.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class FlightValidateOptionComponent implements OnInit {
  @Input() itineraries: Array<any>;
  @Input() settings: any;
  @Input() users: Array<any>;
  @Input() newOffers: Array<any>;
  @Input() search: any;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() validate: EventEmitter<any> = new EventEmitter();
  @ViewChild("option", { static: false }) optionElement: ElementRef;

  public activeTab: number = 0;
  public searchResumePlaces: string[];
  public searchDates: string;
  public airportsDifferent: boolean = false;

  public price: number = 0;

  public carbonOffset: CarbonOffsetTypes.CarbonOffsetEstimation = {
    amount: 0,
    price: 0,
  };
  public isOutOfPolicy: boolean = false;

  public newPrice: number = 0;
  public newCarbonOffset: CarbonOffsetTypes.CarbonOffsetEstimation = {
    amount: 0,
    price: 0,
  };
  public newIsOutOfPolicy: boolean = false;
  public activeTabNew: number = 0;
  public viewOnly: boolean = false;

  constructor(
    public commonService: CommonService,
    private localizedDatePipe: LocalizedDatePipe,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.commonService.isTabletObservable.subscribe((isTablet: boolean) => {
      this.setSearchResume(isTablet);
    });
    if (this.itineraries.length > 1) {
      this.airportsDifferent =
        this.itineraries[0].legs[0].departure.airport !==
          this.itineraries[this.itineraries.length - 1].legs[
            this.itineraries[this.itineraries.length - 1].legs.length - 1
          ].arrival.airport ||
        this.itineraries[0].legs[0].arrival.airport !==
          this.itineraries[this.itineraries.length - 1].legs[
            this.itineraries[this.itineraries.length - 1].legs.length - 1
          ].departure.airport;
    }
    this.itineraries.forEach((itinerary: any, index: number) => {
      // if (this.searchResumeCities && this.searchResumeCities[i] && this.searchResumeCities[i] !== itin) {}
      this.price += itinerary.price ? itinerary.price : parseFloat(itinerary.totalPrice);
      this.carbonOffset.amount += itinerary.carbonOffset.amount;
      this.carbonOffset.price += itinerary.carbonOffset.price;
      if (itinerary.isOutPolicy) {
        this.isOutOfPolicy = true;
      }
    });

    if (this.newOffers) {
      this.newOffers.forEach((itinerary: any) => {
        this.newPrice += itinerary.price ? itinerary.price : itinerary.totalPrice;
        this.newCarbonOffset.amount += itinerary.carbonOffset.amount;
        this.newCarbonOffset.price += itinerary.carbonOffset.price;
        if (itinerary.isOutPolicy) {
          this.newIsOutOfPolicy = true;
        }
      });
    }
  }

  setSearchResume(isTablet: boolean): void {
    this.searchResumePlaces = [];
    const placeType: string = isTablet ? "iata" : "name";
    const searchDates: Array<any> = [];
    this.search.data.trips.forEach((trip: any, i: number) => {
      if (i === 0) {
        searchDates.push(this.localizedDatePipe.transform(trip.departureDate));
      } else if (i === this.search.data.trips.length - 1) {
        searchDates.push(this.localizedDatePipe.transform(trip.departureDate));
      }
      if (this.searchResumePlaces[this.searchResumePlaces.length - 1] !== trip.departureAirport[placeType]) {
        this.searchResumePlaces.push(trip.departureAirport[placeType]);
      }
      this.searchResumePlaces.push(trip.arrivalAirport[placeType]);
    });
    this.searchDates = searchDates.join(" - ");
  }

  handleChange($event: any, type: string): void {
    if (type === "new") {
      this.activeTabNew = $event.index;
    } else {
      this.activeTab = $event.index;
    }
  }

  chooseOffer(data: any): void {
    this.validate.emit({ offers: data });
  }

  closeValidation(): void {
    this.close.emit(false);
  }

  displayOption(): void {
    this.optionElement.nativeElement.scrollIntoView({ behavior: "smooth" });
  }
}
