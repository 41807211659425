import { Injectable } from "@angular/core";

import { filter, first, map, mergeMap } from "rxjs/operators";
import { SocietyService } from "../services/society.service";
import { Society, MemberSociety } from "../@types/society";
import { cloneDeep } from "lodash-es";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MembersResolver {
  constructor(private societyService: SocietyService) {}

  resolve(): Observable<Array<MemberSociety>> {
    return this.societyService.society.pipe(
      filter((society: Society) => !!society),
      mergeMap((society: Society) => {
        if (!society.members[0].user._id) {
          return this.societyService.getSociety(society._id, { popMember: true }, true);
        } else {
          return of(society);
        }
      }),
      map((society: Society) => cloneDeep(society.members)),
      first(),
    );
  }
}
