import { Component, Input } from "@angular/core";
import { CommonService } from "src/app/@shared/services/common.service";

@Component({
  selector: "spt-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent {
  @Input() active: String = "";
  @Input() return: boolean = false;
  @Input() numberOfSteps: number = 0;
  @Input() activeStep: number = 0;
  @Input() searchType: string = "round";

  getNumberArray(num: number): number[] {
    return Array(num)
      .fill(0)
      .map((x, i) => i);
  }

  constructor(public commonService: CommonService) {}
}
