import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { DataHotel } from "../sidebar-travel.component";

@Component({
  selector: "spt-conditions",
  templateUrl: "./conditions.component.html",
  styleUrls: ["./conditions.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ConditionsComponent {
  @Input() item: any;
  @Input() dataHotel: DataHotel;
}
