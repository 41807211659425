import { Input, NgModule } from "@angular/core";

import { SharedModule } from "../@shared/shared.module";

import { AuthRoutingModule } from "./auth-routing.module";
import { SignInComponent } from "./sign-in/sign-in.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { PasswordResetComponent } from "./password-reset/password-reset.component";

import { RadioButtonModule } from "primeng/radiobutton";
import { AuthComponent } from "./auth.component";
import { AuthService } from "./auth.service";
import { TranslateModule } from "@ngx-translate/core";
import { ActiveAccountComponent } from "./active-account/active-account.component";
import { InputSwitchModule } from "primeng/inputswitch";

const AUTH_MODULES: Array<any> = [SharedModule, RadioButtonModule, TranslateModule.forChild(), InputSwitchModule];

const AUTH_COMPONENTS: Array<any> = [
  AuthComponent,
  SignInComponent,
  SignUpComponent,
  PasswordResetComponent,
  ActiveAccountComponent,
];

@NgModule({
  declarations: [...AUTH_COMPONENTS],
  imports: [...AUTH_MODULES, AuthRoutingModule],
  providers: [AuthService],
})
export class AuthModule {}
