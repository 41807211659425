import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Passport, SettingsUserNotification, TravelFidelity } from "../@types/models";
import { User } from "../@types/user";
import { UserService } from "./user.service";
import { concatMap, finalize, map } from "rxjs/operators";
import { MessageService } from "primeng/api";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  public user: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private messageService: MessageService,
  ) {}

  public updateAccountGeneral(userId: string, data: User | any): Observable<any> {
    // data.settings = data.settings;
    const endPoint: string = `${environment.api}/api/users/${userId}`;
    return this.httpClient.put(endPoint, data).pipe(concatMap(() => this.userService.getUser(userId, true)));
  }

  public updateAccountPassport(userId: string, data: Passport): Observable<any> {
    const endPoint: string = `${environment.api}/api/users/${userId}`;
    return this.httpClient
      .put(endPoint, { setPassport: data })
      .pipe(concatMap(() => this.userService.getUser(userId, true)));
  }

  public updateTravelFidelities(userId: string, data: Array<TravelFidelity>): Observable<any> {
    const endPoint: string = `${environment.api}/api/users/${userId}`;
    return this.httpClient
      .put(endPoint, { setFidelities: data })
      .pipe(concatMap(() => this.userService.getUser(userId, true)));
  }

  public updateTravelSubscriptions(userId: string, data: Array<any>): Observable<any> {
    const endPoint: string = `${environment.api}/api/users/${userId}`;
    return this.httpClient
      .put(endPoint, { travelSubscriptions: data })
      .pipe(concatMap(() => this.userService.getUser(userId, true)));
  }

  public updatePassword(userId: string, data: any): Observable<any> {
    const endPoint: string = `${environment.api}/api/users/${userId}`;
    return this.httpClient.put(endPoint, data);
  }

  public resetPassword(email: string): Observable<any> {
    const endPoint: string = `${environment.api}/api/forgot`;
    const headers: HttpHeaders = new HttpHeaders({
      ignoreLoadingBar: "true",
      ignoreErrorMessage: "true",
    });
    return this.httpClient.post(endPoint, { user: email }, { headers }).pipe(
      finalize(() => {
        this.messageService.add({
          severity: "success",
          detail: "Votre demande a été prise en compte",
        });
      }),
    );
  }

  public changeResetPassword(password: string, token: string, userId: string): Observable<any> {
    const endPoint: string = `${environment.api}/api/users/${userId}`;
    return this.httpClient.put(endPoint, { password, forgot: token }, { headers: { authorization: token } });
  }

  public updatePreferences(userId: string, preferences: any): Observable<any> {
    return this.httpClient.put(`${environment.api}/api/users/${userId}`, {
      preferences,
    });
  }
}
