<div class="noSeatMap">
  <button type="button" class="closeModalButton fas fa-times fa-2x" (click)="close()"></button>
  <h1 translate>SEARCH.RESULT.RAIL.SEAT_DESIGNATED</h1>
  <form class="seat-preferences-form" *ngIf="selectItems && seatReservationFormArray" (submit)="onSubmit()">
    <div
      class="seat-preference-group"
      *ngFor="let seatReservationFormGroupItem of seatReservationFormArray.controls; index as idx"
      [formGroup]="seatReservationFormGroupItem"
    >
      <div class="seat-preference" *ngIf="selectItems[idx]?.seatOption?.length > 1">
        <label translate>SEARCH.RESULT.RAIL.SEAT_PREFERENCES.SEAT_TYPE</label>
        <p-dropdown
          styleClass="w-full"
          formControlName="seatOption"
          [options]="selectItems[idx]?.seatOption"
          [placeholder]="'LABELS.CHOOSE' | translate"
          appendTo="body"
        ></p-dropdown>
      </div>
      <!--div class="seat-preference" *ngIf="selectItems[idx]?.carriageType?.length > 1">
        <label translate>SEARCH.RESULT.RAIL.SEAT_PREFERENCES.CARRIAGE_TYPE</label>
        <p-dropdown
          styleClass="w-full"
          formControlName="carriageType"
          [options]="selectItems[idx]?.carriageType"
          [placeholder]="'LABELS.CHOOSE' | translate"
          appendTo="body"
        ></p-dropdown>
      </div-->
      <div class="seat-preference" *ngIf="selectItems[idx]?.deck?.length > 1">
        <label translate>SEARCH.RESULT.RAIL.SEAT_PREFERENCES.DECK</label>
        <p-dropdown
          styleClass="w-full"
          formControlName="deck"
          [options]="selectItems[idx]?.deck"
          [placeholder]="'LABELS.CHOOSE' | translate"
          appendTo="body"
        ></p-dropdown>
      </div>
      <div class="next-to" *ngIf="seatReservationFormGroupItem.controls.nextTo" [ngClass]="seatPreferenceKey">
        <ng-container *ngFor="let nextToItem of seatReservationFormGroupItem.controls.nextTo.controls">
          <label>{{ "SEARCH.RESULT.RAIL.SEAT_NEXT_TO" | translate }} :</label>
          <p class="seat-next-to" [formGroup]="nextToItem">
            <span class="seat-inputs-container">
              <input
                class="seat-input"
                pInputText
                [placeholder]="'SEARCH.RESULT.RAIL.SEAT_PREFERENCES.COACH' | translate"
                formControlName="coachNumber"
              />
              <input
                class="seat-input"
                pInputText
                [placeholder]="'SEARCH.RESULT.RAIL.SEAT_PREFERENCES.SEAT' | translate"
                formControlName="seatNumber"
              />
            </span>
          </p>
        </ng-container>
      </div>
    </div>
    <p-button
      type="submit"
      class="validate-button"
      styleClass="p-button-primary"
      [label]="'GLOBAL.VALIDATE' | translate"
      [disabled]="seatReservationFormArray.pristine || seatReservationFormArray.invalid"
    ></p-button>
  </form>
</div>
