import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { CommonService } from "../../../../../@shared/services/common.service";

@Component({
  selector: "spt-booking-flight",
  templateUrl: "./flight.component.html",
  styleUrls: ["../booking-list.component.scss", "./flight.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FlightComponent {
  @Input() item: any;
  @Output() openedEticket: EventEmitter<any> = new EventEmitter();

  constructor(public commonService: CommonService) {}

  openEticket(booking: any): void {
    this.openedEticket.emit(booking);
  }
}
