import { NgModule, LOCALE_ID, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from "@angular/common/http";

import { LeafletModule } from "@asymmetrik/ngx-leaflet";

import { AppComponent } from "./app.component";
import { SharedModule } from "./@shared/shared.module";
import { CustomHttpInterceptor } from "./@shared/services/http.interceptor";

import { AppRoutingModule } from "./app.routing";
import { DashboardModule } from "./dashboard/dashboard.module";
import { AuthModule } from "./auth/auth.module";
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { HttpErrorInterceptor } from "./@shared/services/http-error.interceptor";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import localeEn from "@angular/common/locales/en";
import localeEs from "@angular/common/locales/es";
import { ResetPasswordComponent } from "./auth/reset-password/reset-password.component";
// import * as Raven from "raven-js";
// import { environment } from "src/environments/environment";

// for HttpClient import:
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";

// for Core import:
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { AppInitializerService } from "./service_initializer/app_initializer.service";
import { TravelModule } from "./travel/travel.module";
import { CommonService } from "./@shared/services/common.service";

registerLocaleData(localeEs, "es");
registerLocaleData(localeEn, "en");
registerLocaleData(localeFr, "fr");

// Raven.config("https://188921aa274d44029f9e9ddd1b00206f@sentry.io/1419677", {
//   maxMessageLength: 1000000,
//   maxUrlLength: 1000000,
// }).install();

// export class RavenErrorHandler extends ErrorHandler {
//   handleError(err: any): void {
//     if (environment.production) {
//       Raven.captureException(err.originalError || err, { maxMessageLength: 1000000,  maxUrlLength: 1000000 });
//     }
//     if (!environment.production) {
//       super.handleError(err);
//     }
//   }
// }

// export class GlobalErrorHandler extends ErrorHandler {
//   handleError(error: any): void {
//     const chunkFailedMessage: RegExp = /Loading chunk [\d]+ failed/;

//     if (chunkFailedMessage.test(error.message)) {
//       window.location.reload();
//     } else {
//       // if (environment.production) {
//       //   Raven.captureException(error.originalError || error, {
//       //     maxMessageLength: 1000000,
//       //     maxUrlLength: 1000000,
//       //   });
//       // }
//       if (!environment.production) {
//       }
//       super.handleError(error);
//     }
//   }
// }
import { ServiceWorkerModule } from "@angular/service-worker";
const silentApis: Array<string> = ["api/bookrequest?status=0"];
const APP_COMPONENTS: Array<any> = [AppComponent, ResetPasswordComponent];

const APP_MODULES: Array<any> = [
  SharedModule,
  AuthModule,
  DashboardModule,
  TravelModule,
  BrowserModule,
  BrowserAnimationsModule,
  LeafletModule,
  HttpClientModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
  }),
  ToastModule,
  LoadingBarModule,
  LoadingBarHttpClientModule,
];

export function utilsLoader(appInitializerService: AppInitializerService): any {
  return (): Promise<any> => appInitializerService.loadUtils();
}

export function societyLoader(appInitializerService: AppInitializerService): any {
  return (): Promise<any> => appInitializerService.loadSocietyAndUser();
}

@NgModule({
  declarations: [...APP_COMPONENTS],
  imports: [...APP_MODULES, AppRoutingModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "fr" },
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: utilsLoader,
      deps: [AppInitializerService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: societyLoader,
      deps: [AppInitializerService],
      multi: true,
    },
    MessageService,
    CommonService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
