import { TravelComponent } from "./travel.component";
import { LabelResolver } from "../@shared/resolvers/label.resolver";
import { RouterModule, Routes } from "@angular/router";
import { ModuleWithProviders } from "@angular/core";
import { AuthGuard } from "../@shared/services/auth-guard.service";
import { SocietyResolver } from "../@shared/resolvers/society.resolver";
import { ExtraSettingsResolver } from "../@shared/resolvers/extra-settings.resolver";
import { TravelConditionsComponent } from "./conditions/conditions.component";

const routes: Routes = [
  {
    path: "travel/conditions/:type/:conditionId",
    component: TravelConditionsComponent,
    canActivateChild: [AuthGuard],
  },
  {
    path: "travel/:search-id",
    component: TravelComponent,
    canActivateChild: [AuthGuard],
    resolve: {
      labels: LabelResolver,
      society: SocietyResolver,
      extraSettings: ExtraSettingsResolver,
    },
  },
];

export const TravelRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
