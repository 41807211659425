<ng-container [ngSwitch]="item.type">
  <ng-container *ngSwitchCase="'train'">
    <spt-conditions-train [item]="item"></spt-conditions-train>
  </ng-container>
  <ng-container *ngSwitchCase="'flight'">
    <spt-conditions-flight [item]="item"></spt-conditions-flight>
  </ng-container>
  <ng-container *ngSwitchCase="'car'">
    <spt-conditions-car [item]="item" [search]="{ data: item.formData.search }"></spt-conditions-car>
  </ng-container>
  <ng-container *ngSwitchCase="'hotel'">
    <spt-conditions-hotel [item]="item" [dataHotel]="dataHotel"></spt-conditions-hotel>
  </ng-container>
</ng-container>
