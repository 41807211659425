import { Directive, ElementRef, OnDestroy, OnInit } from "@angular/core";

// pseudo class clear button
const NATIVE_PSEUDO_CLASS = 'input[type="search"]::-webkit-search-cancel-button';
const CLASSNAME_NOT_EMPTY_VALUE = "not-empty-value";
const CLASSNAME_FOCUSED = "input-focused";
const CLASSNAME_HOVERED = "input-hovered";

@Directive({
  selector: 'input[type="search"]',
})
export class InputSearchPolyfillDirective implements OnInit, OnDestroy {
  private static NATIVE_SUPPORT: boolean = undefined;
  private el: HTMLInputElement;
  private button: HTMLElement;
  constructor(private elRef: ElementRef) {
    this.el = elRef.nativeElement as HTMLInputElement;
    if (this.nativeSupport()) {
      return;
    }
    this.button = document.createElement("span");
    this.button.className = "search-clear-button pi pi-times ";
    this.button.addEventListener("mousedown", () => this.clearValue());
    this.el.before(this.button);
  }

  ngOnInit() {
    if (this.nativeSupport()) {
      return;
    }
    this.el.addEventListener("input", this.onInputFct);
    this.el.addEventListener("focus", this.onFocusFct);
    this.el.addEventListener("blur", this.onBlurFct);
    this.el.addEventListener("mouseover", this.onMouseOverFct);
    this.el.addEventListener("mouseout", this.onMouseOutFct);
    this.onInput();
    this.button.style.marginLeft = this.el.clientWidth - 15 + "px";
    this.button.style.lineHeight = this.el.clientHeight + "px";
  }
  ngOnDestroy(): void {
    this.el.removeEventListener("input", this.onInputFct);
    this.el.removeEventListener("focus", this.onFocusFct);
    this.el.removeEventListener("blur", this.onBlurFct);
    this.el.removeEventListener("mouseover", this.onMouseOverFct);
    this.el.removeEventListener("mouseout", this.onMouseOutFct);
  }

  onInputFct = () => this.onInput();
  onInput() {
    if (this.el.value.length > 0) {
      this.button.classList.add(CLASSNAME_NOT_EMPTY_VALUE);
    } else {
      this.button.classList.remove(CLASSNAME_NOT_EMPTY_VALUE);
    }
  }

  onFocusFct = () => this.onFocus();
  onFocus() {
    this.button.classList.add(CLASSNAME_FOCUSED);
  }

  onBlurFct = () => this.onBlur();
  onBlur() {
    this.button.classList.remove(CLASSNAME_FOCUSED);
  }

  onMouseOverFct = () => this.onMouseOver();
  onMouseOver() {
    this.button.classList.add(CLASSNAME_HOVERED);
  }

  onMouseOutFct = () => this.onMouseOut();
  onMouseOut() {
    this.button.classList.remove(CLASSNAME_HOVERED);
  }

  clearValue() {
    this.el.value = this.el.defaultValue || "";
    this.el.dispatchEvent(new Event("input"));
  }

  nativeSupport(): boolean {
    if (InputSearchPolyfillDirective.NATIVE_SUPPORT === undefined) {
      InputSearchPolyfillDirective.NATIVE_SUPPORT =
        window.CSS?.supports && window.CSS?.supports("selector(" + NATIVE_PSEUDO_CLASS + ")");
      console.log(
        "[InputSearchPolyfillDirective] native support:",
        NATIVE_PSEUDO_CLASS,
        "->",
        InputSearchPolyfillDirective.NATIVE_SUPPORT,
      );
    }
    return InputSearchPolyfillDirective.NATIVE_SUPPORT;
  }
}
