import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Input,
  OnDestroy,
} from "@angular/core";
import { Booking } from "../../../@shared/@types/booking";
import { User } from "../../../@shared/@types/user";
import { Label } from "../../../@shared/@types/label";
import { CommonService } from "../../../@shared/services/common.service";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Data } from "@angular/router";
import { LabelService } from "../../../@shared/services/label.service";
import { takeUntil } from "rxjs/operators";
import { EticketsModalComponent } from "../../@dashboard-shared/modals/etickets-modal/etickets-modal.component";
import { ModalService } from "../../../@shared/services/modal.service";
import { SocietyService } from "src/app/@shared/services/society.service";
import { MemberSociety } from "../../../@shared/@types/society";
import { BillService } from "src/app/@shared/services/bill.service";
import { BillsModalComponent } from "../../@dashboard-shared/modals/bills-modal/bills-modal.component";
import { Subject } from "rxjs";
import { TrainTypes } from "../../../travel/train/train";

@Component({
  selector: "spt-reservations-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservationsDetailComponent implements OnInit, OnDestroy {
  @Input() item: any;
  @Input() isSmallDevice: boolean;
  @Output() toggleSidebarEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Output() updateReservations: EventEmitter<Booking> = new EventEmitter();

  public customerService: string;
  public users: User[] = [];
  public booker: any;
  public showAllTravelers: boolean = false;
  public isDisplayedModification: boolean = false;
  public isDisplayedCancellation: boolean = false;
  public societyLabels: Array<Label> = [];
  public showLabelSide: boolean = false;
  public locale: string;
  public bills: Array<any> = [];
  public showModifyButton: boolean = true;
  public showCancelButton: boolean = true;
  public showPriceBreakdown: boolean = false;
  public hasReservationFee: boolean = false;
  public isUKTravel: boolean = false;

  private ngUnsubscribe: Subject<void> = new Subject();

  constructor(
    private commonService: CommonService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private labelService: LabelService,
    private cd: ChangeDetectorRef,
    private modalService: ModalService,
    private societyService: SocietyService,
    private billService: BillService,
  ) {}

  ngOnInit(): void {
    this.commonService.statusBarTextBlack();
    this.customerService = this.translateService.instant("GLOBAL.CUSTOMER.SERVICE");
    console.log(this.item);
    const statusHistory: any = this.item.statusHistory.find((status: any): boolean => status.to === "confirmed");
    this.item.confirmationDate = statusHistory?.date;
    this.item.cancellationDate = this.item.statusHistory.find(
      (status: any): boolean => status.to === "cancelled",
    )?.date;
    if (statusHistory) {
      console.log(this.societyService.society.value.members);
      const member: MemberSociety = this.societyService.society.value.members.find(
        (_member: MemberSociety): boolean => _member.user._id === statusHistory.userId,
      );
      if (member) {
        this.booker = member.user;
      }
    }
    this.locale = this.translateService.currentLang;
    this.users = this.item.travelers;
    this.route.data.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: Data): void => {
      if (data.labels) {
        this.societyLabels = data.labels.filter((label: Label): boolean => label.isActive);
      }
    });

    // Bill modal stuff
    this.billService.getBillInfo(this.item.id).subscribe((bills: any): void => {
      for (const bill of bills) {
        bill.travelers = [];
        const travelerIds = Array.from(new Set(bill.lines.map((line: any): string[] => line.userIds).flat()));
        for (const userId of travelerIds) {
          bill.travelers.push(
            this.societyService.society.value.members.find(
              (member: MemberSociety): boolean => member.user._id.toString() === userId,
            ).user,
          );
        }
        this.bills.push(bill);
      }
      this.cd.detectChanges();
    });

    if (this.item.detail.journeys) {
      const isNotPastTravel: boolean = this.item.detail.journeys.some(
        (_journey: TrainTypes.Journey) => new Date(_journey.departure.date.utc) > new Date(),
      );
      if (this.item.detail.afterSales) {
        this.showCancelButton = this.item.detail.afterSales.refundable && isNotPastTravel;
        this.showModifyButton = this.item.detail.afterSales.changeable && isNotPastTravel;
      } else {
        this.showCancelButton = isNotPastTravel;
        this.showModifyButton = isNotPastTravel;
      }
    }

    if (this.item.type === "train") {
      this.hasReservationFee = this.item.detail.orderItems.some((_orderItem: TrainTypes.OrderItem) => {
        return (
          _orderItem.carrier === "DB" &&
          _orderItem.item.pricing?.lineItems?.some((_lineItem: TrainTypes.LineItem) => {
            return _lineItem.type === "reservation-fee" && _lineItem.price.amount > 0;
          })
        );
      });
      this.isUKTravel = this.item.detail.orderItems.some(
        (_orderItem: TrainTypes.OrderItem) => _orderItem.carrier === "ATOC",
      );
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  closeSidebar(): void {
    this.toggleSidebarEmitter.emit(false);
  }

  toggleAllTravelers(): void {
    this.showAllTravelers = !this.showAllTravelers;
  }

  cancelReservation(): void {
    this.isDisplayedCancellation = true;
  }

  modifyReservation(): void {
    this.isDisplayedModification = true;
  }

  closeModify(event: boolean): void {
    if (this.isDisplayedModification) {
      this.isDisplayedModification = event;
    }

    if (this.isDisplayedCancellation) {
      this.isDisplayedCancellation = event;
    }
  }

  openEtickets(event: any): void {
    let reference: string;
    switch (event.type) {
      case "train":
        reference = event.detail.orderReference;
        break;
    }
    if (event.documentIds.length > 1) {
      this.modalService.openModal(EticketsModalComponent, {
        data: {
          etickets: event.documentIds,
          type: event.type,
          reference,
          travelers: this.users,
        },
        dismissableMask: true,
        showHeader: false,
      });
    } else {
      this.commonService.openLink(event.documentIds[0]);
    }
  }

  openBills(bills: any): void {
    if (bills.length > 1) {
      this.modalService.openModal(BillsModalComponent, {
        data: {
          bills: this.bills,
        },
        dismissableMask: true,
        showHeader: false,
      });
    } else {
      this.commonService.openLink(bills[0].documentIds[0]);
    }
  }

  closeSidebarEmitter(event: boolean): void {
    this.commonService.statusBarTextWhite();
    this.toggleSidebarEmitter.emit(event);
  }

  editLabels(): void {
    this.showLabelSide = true;
  }

  addUserLabel(event: { labels: any; comment: string }): void {
    if (event.labels) {
      this.labelService.update(this.item, event.labels).subscribe((): void => {
        this.item.labels = event.labels;
        this.cd.markForCheck();
      });
    }

    if (event.comment) {
      this.labelService.updateComment(this.item, event.comment).subscribe((): void => {
        this.item.comment = event.comment;
        this.cd.markForCheck();
      });
    }
    this.showLabelSide = false;
  }
}
