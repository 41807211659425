import { Injectable, Inject, Injector } from "@angular/core";
import { SocietyService } from "../@shared/services/society.service";
import { UserService } from "../@shared/services/user.service";

@Injectable({
  providedIn: "root",
})
export class ResetService {
  constructor(private injector: Injector) {}

  resetAllServices(): void {
    const societyService = this.injector.get(SocietyService);
    societyService.reset();

    const userService = this.injector.get(UserService);
    userService.reset();

    // Réinitialisez d'autres services ici
    // const otherService = this.injector.get(OtherService);
    // otherService.reset();
  }
}
