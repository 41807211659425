<div class="bills-modal">
  <div class="bills-header">
    <h2 class="bills-title">
      <img src="./assets/svg/Receipt.svg" [alt]="'BILLINGS.RECEIPT' | translate" />
      {{ "BOOKING.BILLS.RECEIPTS" | translate }}
    </h2>

    <i *ngIf="this.commonService.isTablet" class="close fas fa-times fa-2x" (mousedown)="closeModal()"></i>
  </div>

  <div class="bills-container">
    <div class="bills-row" *ngFor="let bill of bills; let i = index">
      <div class="profiles">
        <spt-avatar
          *ngFor="let traveler of bill.travelers.slice(0, 2)"
          [user]="traveler"
          [displayUsername]="false"
        ></spt-avatar>
        <ng-container *ngIf="bill.travelers.length > 2">
          <div class="more-users">+{{ bill.travelers.length - 2 }}</div>
        </ng-container>
      </div>

      <p class="item bill-type">
        <strong>{{ "BILLINGS." + bill.displayedType | translate }}</strong>
      </p>

      <p class="item bill-title">{{ bill.title }}</p>

      <div class="bill-button" (mousedown)="openBill(i)">
        <img src="./assets/svg/download.svg" [alt]="'BILLINGS.DOWNLOAD' | translate" />
      </div>
    </div>
    <!-- /bills-row -->
  </div>
  <!-- /bills-container -->
</div>
<!-- /bills-modal -->
