<spt-sticky-header *ngIf="containerRef" [scrollRef]="containerRef" class="header">
  <div class="logo">
    <a [routerLink]="'/'" class="grid align-items-center header-link no-underline">
      <img class="header-logo" src="./assets/svg/supertripper-white.svg" />
      <span class="f-sb txt-20 m-l-15 txt-white">supertripper</span>
    </a>
  </div>
</spt-sticky-header>
<section class="conditions-container" #container>
  <h1 translate>TRAVEL.CONDITIONS.TITLE</h1>
  <ng-container *ngIf="$fareType | async as fareType">
    <div>
      <h2 class="conditions-title">{{ fareType.fareName }}</h2>
      <p *ngIf="fareType.conditionsSummary">{{ fareType.conditionsSummary }}</p>
    </div>
    <div class="conditions" *ngFor="let condition of fareType.conditions">
      <h3 class="name">{{ condition.name }}</h3>
      <p class="description">{{ condition.description }}</p>
    </div>
  </ng-container>
</section>
