import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import moment from "moment";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "src/app/@shared/@types/user";

import { environment } from "../../../environments/environment";
import { CarbonOffsetTypes } from "../@types/carbon-offset";
import { UtilsTypes } from "../@types/utils";

@Injectable({
  providedIn: "root",
})
export class CarbonOffsetService {
  public carbonOffsetResults: UtilsTypes.ObjectKey<CarbonOffsetTypes.CarbonOffsetEstimation> = {};
  private carbonOffsetEndpoint: string = `${environment.api}/carbon-offsets`;

  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
  ) {}

  calculateCarbonOffset(
    type: string,
    metadata: any,
    origin?: any,
    destination?: any,
  ): Observable<CarbonOffsetTypes.CarbonOffsetEstimation> {
    const key: string = JSON.stringify({ type, metadata, origin, destination });
    if (this.carbonOffsetResults[key] === undefined) {
      let params: HttpParams = new HttpParams({
        fromObject: {
          type,
          metadata: JSON.stringify(metadata),
        },
      });

      if (origin && destination) {
        params = params.set("origin", origin).set("destination", destination);
      }

      const headers: HttpHeaders = new HttpHeaders({
        ignoreLoadingBar: "true",
        ignoreErrorMessage: "true",
      });
      return this.httpClient
        .get(`${this.carbonOffsetEndpoint}/calculate`, {
          headers,
          params,
        })
        .pipe(
          map((data: CarbonOffsetTypes.CarbonOffsetEstimation) => {
            this.carbonOffsetResults[key] = data;
            return this.carbonOffsetResults[key];
          }),
        );
    } else {
      return of(this.carbonOffsetResults[key]);
    }
  }

  getSocietyCarbonOffset(startDate: string, endDate: string): Observable<Array<CarbonOffsetTypes.CarbonOffset>> {
    return this.httpClient.post<Array<CarbonOffsetTypes.CarbonOffset>>(`${this.carbonOffsetEndpoint}/list`, {
      startDate,
      endDate,
    });
  }

  getCarbonOffset(carbonOffsetId: string): Observable<CarbonOffsetTypes.CarbonOffset> {
    const headers: HttpHeaders = new HttpHeaders({
      ignoreLoadingBar: "true",
      ignoreErrorMessage: "true",
    });
    return this.httpClient.get<CarbonOffsetTypes.CarbonOffset>(`${this.carbonOffsetEndpoint}/${carbonOffsetId}`, {
      headers,
    });
  }

  saveCarbonOffset(
    type: string,
    name: string,
    bookingId: string,
    billingId: string,
    price: number,
    amount: number,
    startDate: Date,
    endDate: Date,
    usersIds: Array<string>,
  ): Observable<CarbonOffsetTypes.CarbonOffset> {
    const headers: HttpHeaders = new HttpHeaders({
      ignoreLoadingBar: "true",
      ignoreErrorMessage: "true",
    });

    return this.httpClient.post<CarbonOffsetTypes.CarbonOffset>(
      this.carbonOffsetEndpoint,
      {
        type,
        name,
        bookingId,
        billingId,
        price,
        amount,
        startDate,
        endDate,
        usersIds,
      },
      { headers },
    );
  }

  updateCarbonOffsetStatus(id: string, status: CarbonOffsetTypes.Status): Observable<CarbonOffsetTypes.CarbonOffset> {
    return this.httpClient.put<CarbonOffsetTypes.CarbonOffset>(`${this.carbonOffsetEndpoint}/${id}`, {
      status,
    });
  }

  compensateCarbonOffset(booking: any, carbonOffset: CarbonOffsetTypes.CarbonOffsetEstimation): Observable<any> {
    let name: string;

    switch (booking.type) {
      case "Train":
        name =
          this.translateService.instant(`GLOBAL.TRAIN`) +
          ` ${booking.item.metadata.ptps[0].departure.station} -> ` +
          `${booking.item.metadata.ptps[0].arrival.station} ${this.translateService.instant("GLOBAL.DATES.FROM")}` +
          `${moment(booking.datein).format("L")} ${this.translateService.instant("GLOBAL.DATES.TO")}` +
          `${moment(booking.dateout).format("L")}`;
        break;
      case "RentalCar":
        name =
          this.translateService.instant(`GLOBAL.RENTALCAR`) +
          ` ${booking.item.metadata.fromLocation.StreetNmbr} ` +
          `${booking.item.metadata.fromLocation.CityName} -> ${booking.item.metadata.toLocation.StreetNmbr} ` +
          `${booking.item.metadata.toLocation.CityName} ${this.translateService.instant("GLOBAL.DATES.FROM")}` +
          `${moment(booking.datein).format("L")} ${this.translateService.instant("GLOBAL.DATES.TO")}` +
          `${moment(booking.dateout).format("L")}`;
        break;
      case "Flight":
        name = this.translateService.instant(`GLOBAL.FLIGHT`);
        break;
      case "Transfer":
        name =
          this.translateService.instant(`GLOBAL.TRANSFER`) +
          ` ${booking.item.metadata.transfers[0].fromdetails.name} -> ${booking.item.metadata.transfers[0].todetails.name} ` +
          `${this.translateService.instant("GLOBAL.DATES.FROM")} ${moment(booking.datein).format("L")} ` +
          `${this.translateService.instant("GLOBAL.DATES.TO")} ${moment(booking.dateout).format("L")}`;
        break;
      default:
        name = name =
          this.translateService.instant(`GLOBAL.HOTEL`) +
          ` ${booking.metadata.hotel.title} ` +
          `${this.translateService.instant("GLOBAL.DATES.FROM")} ${moment(booking.datein).format("L")} ` +
          `${this.translateService.instant("GLOBAL.DATES.TO")} ${moment(booking.dateout).format("L")}`;
        break;
    }

    const usersIds: Array<string> = booking.item.metadata.users.map((user: User) => user._id);

    return this.saveCarbonOffset(
      booking.type,
      name,
      booking._id,
      booking.billing,
      carbonOffset.price,
      carbonOffset.amount,
      booking.datein,
      booking.dateout,
      usersIds,
    );
  }
}
