<div class="resume-header">
  <div class="description">
    <img alt="Icon Train" src="./assets/svg/train.svg" />
    <div class="trip">
      <b class="point">
        {{ searchJourneyDirection === "Outward" || type === "Exchange" ? origin.name : destination.name }}
      </b>
      <span class="all-stations" *ngIf="origin.locationType === 'City'" translate> SEARCH.TRAIN.ALL_STATIONS </span>
      <i class="arrow spt-icon-arrow-circle-right"></i>
      <b class="point">
        {{ searchJourneyDirection === "Outward" || type === "Exchange" ? destination.name : origin.name }}
      </b>
      <span class="all-stations" *ngIf="destination.locationType === 'City'" translate>
        SEARCH.TRAIN.ALL_STATIONS
      </span>
    </div>
    <div class="type" translate>SEARCH.TRAIN.{{ type || action | uppercase }}</div>
  </div>
  <div class="travel-type">
    <span class="type">
      {{ "GLOBAL." + searchJourneyDirection | uppercase | translate }}
    </span>
    <img src="./assets/svg/resume/calendar.svg" />
    <b class="date">
      {{
        (type === "Exchange" || searchJourneyDirection === "Outward" ? departureDate : returnDate)
          | localizedDate: "dd MMM"
      }}
    </b>
  </div>
</div>
