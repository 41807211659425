import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MessageService, SelectItem } from "primeng/api";
import { filter, first, takeUntil } from "rxjs/operators";
import { CarbonOffsetService } from "../../@shared/services/carbon-offset.service";
import { CommonService } from "src/app/@shared/services/common.service";
import { FlightService } from "./flight.service";
import { TranslateService } from "@ngx-translate/core";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { ModalService } from "src/app/@shared/services/modal.service";
import { DynamicDialogConfig } from "primeng/dynamicdialog";

@Component({
  selector: "spt-traveling-flight",
  styleUrls: ["./flight.component.scss"],
  templateUrl: "./flight.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class FlightComponent implements OnInit, OnDestroy {
  @Input() search: any;
  @Input() removeLast: Subject<any>;
  @Input() seeOutOfPolicy: boolean;

  private settings: any;
  private users: any;
  @Output() selectFares: EventEmitter<any> = new EventEmitter();
  @Output() modifySearch: EventEmitter<void> = new EventEmitter();

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  viewport: CdkVirtualScrollViewport;

  public display: boolean = false;

  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Output() sidebarOpen: EventEmitter<boolean> = new EventEmitter();
  @Output() confirmSidebarOpen: EventEmitter<boolean> = new EventEmitter();

  public choosenObject: any;
  public packages: any;
  public stateDescription: string;
  public alternativeTrain: any;
  protected minimumRailDurationFormatted: string;

  public displayValidatePanel: boolean;
  public lastSelectedItineraries: Array<any>;

  public filtersDisplayed: boolean = false;

  public selectedItineraries: any = [];
  public existingSabreInboundItineraries: any = [];
  public existingAfklInboundItineraries: any = [];
  public existingInboundItineraries: any = [];
  public currentItinerary: any;

  public displayedItineraries: any[];
  public activeIndex: number;

  // Liste des itinéraires aller (et pour les autres steps le cas échéant)
  public itinerariesByStep: any[][];
  public minimalCarbonOffsetByStep: number[] = [];

  public indexWay: number = 0;
  public filterDefinitionsSubject: Subject<any> = new Subject();
  public orderOptions: SelectItem[] = [
    {
      label: this.translateService.instant("SEARCH.RESULT.AIR.ORDER.PRICE"),
      value: "price",
    },
    {
      label: this.translateService.instant("SEARCH.RESULT.AIR.ORDER.DEPARTURE_TIME"),
      value: "departureTime",
    },
    {
      label: this.translateService.instant("SEARCH.RESULT.AIR.ORDER.OLD_DEPARTURE_TIME"),
      value: "longDepartureTime",
    },
    {
      label: this.translateService.instant("SEARCH.RESULT.AIR.ORDER.DURATION"),
      value: "duration",
    },
  ];
  public order: "price" | "departureTime" | "duration" = "duration";
  public selectSeparated: boolean = false;
  public itineraryWay: "departure" | "return" | "trip";
  private ngUnsubscribe: Subject<void> = new Subject();
  public step: number;

  public departureDates: Array<any> = [];

  public fareCodes: any = {};
  public bestPackages: Array<any>;
  public oldSelected: Array<any>;

  public dataPreview: any;

  filterByStep: Filter[] = [];

  public errorOccurred: boolean;
  pricedOffer?: any;
  otherOffer?: any;
  private dialogBaseConfig: DynamicDialogConfig = new DynamicDialogConfig();
  private dataForFilters: Array<any>;

  constructor(
    private route: ActivatedRoute,
    private flightService: FlightService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private carbonOffsetService: CarbonOffsetService,
    private router: Router,
    private messageService: MessageService,
    public commonService: CommonService,
  ) {}

  private resetViewport(): void {
    if (this.viewport) {
      this.viewport.checkViewportSize();
      this.viewport.scrollTo({ top: 0 });
    }
  }

  ngOnInit(): void {
    this.removeLast
      .pipe(
        filter((val: boolean) => val === true),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.changeLast(true);
      });

    this.loading.emit(true);
    this.stateDescription = "SEARCH.LOADING_DESCRIPTION.RESULTS";
    this.initExtraSettings();

    this.selectedItineraries.splice(-1);
    this.step = 0;
    this.lastSelectedItineraries = [];
    this.currentItinerary = undefined;
    this.setItineraryWay();
    this.departureDates = [];
    this.search.data.trips.forEach((trip: any) => {
      this.departureDates.push(trip.departureDate);
    });
    this.flightService.fetchResults(this.search.id, this.seeOutOfPolicy, this.search.data.type).subscribe({
      next: (res: any) => {
        if (res.error) {
          this.minimalCarbonOffsetByStep = [];
          this.itinerariesByStep = [];
          this.displayedItineraries = [];
          this.router.navigate(["/"]);
          if (res.error.alreadyShown) {
            return;
          }
          if (res.error === "no result") {
            // this.messageService.add({ severity: 'warn', detail: 'Aucune offre disponible' });
          } else if (res.error === "All Off Policy") {
            this.messageService.add({
              severity: "warn",
              detail: this.translateService.instant("GLOBAL.NO_OFFER_IN_POLICY"),
            });
          } else {
            this.messageService.add({
              severity: "warn",
              detail: this.translateService
                // tslint:disable-next-line:max-line-length
                .instant(
                  "Une erreur avec le fournisseur est survenue, n'hésitez pas à contacter notre service client. ",
                ),
            });
          }
        } else {
          this.minimalCarbonOffsetByStep = res.itineraries.map((itineraries, index) =>
            Math.min(...itineraries.map((itinerary) => itinerary.carbonOffset?.amount).filter((v) => !!v)),
          );
          this.users = res.users;
          this.settings = res.settings;

          this.alternativeTrain = res.alternativeTrain;
          const hours = Math.floor(res.alternativeTrain.minimumRailDuration / 60);
          const minutes = String(res.alternativeTrain.minimumRailDuration - hours * 60).padStart(2, "0");
          this.minimumRailDurationFormatted =
            hours + (this.translateService.currentLang === "fr" ? "h" : ":") + minutes;

          this.itinerariesByStep = !res.itineraries
            ? []
            : res.itineraries
                // Si un "step" est "undefined", on l'ignore
                .filter((item) => !!item)
                // On ignore les itinéraire "undefined"
                .map((itinerary) => {
                  if (Array.isArray(itinerary)) {
                    return itinerary.filter((i) => !!i);
                  }
                  return itinerary;
                });

          // Initialisation des filtres pour chacun des steps
          this.filterByStep = this.itinerariesByStep.map(
            (i) => ({ cabinCodes: this.search.data.cabinClassPreferred }) as unknown as Filter,
          );
          this.dataForFilters = res.dataForFilters;
          this.refreshItinerariesToDisplay();
        }
        this.loading.emit(false);
        this.commonService.statusBarTextWhite();
      },
      error: (err) => {
        console.log(err);
        this.errorOccurred = true;
        this.loading.emit(false);
      },
    });
  }

  fetchItinerariesForInbound(itinerary: any) {
    this.loading.emit(true);
    this.flightService.fetchFlightResults(this.search.id, this.seeOutOfPolicy, itinerary).subscribe({
      next: (res: any) => {
        if (res.error) {
          this.minimalCarbonOffsetByStep = [];
          this.itinerariesByStep = [];
          this.displayedItineraries = [];
          this.router.navigate(["/"]);
          if (res.error.alreadyShown) {
            return;
          }
          if (res.error === "no result") {
            // this.messageService.add({ severity: 'warn', detail: 'Aucune offre disponible' });
          } else if (res.error === "All Off Policy") {
            this.messageService.add({
              severity: "warn",
              detail: this.translateService.instant("GLOBAL.NO_OFFER_IN_POLICY"),
            });
          } else {
            this.messageService.add({
              severity: "warn",
              detail: this.translateService
                // tslint:disable-next-line:max-line-length
                .instant(
                  "Une erreur avec le fournisseur est survenue, n'hésitez pas à contacter notre service client. ",
                ),
            });
          }
        } else {
          this.step++;
          this.users = res.users;
          this.settings = res.settings;
          if (itinerary.provider) {
            if (res.itineraries.length) {
              this.itinerariesByStep[1] = [...res.itineraries]
                // Si un "step" est "undefined", on l'ignore
                .filter((item) => !!item)
                // On ignore les itinéraire "undefined"
                .map((itinerary) => {
                  if (Array.isArray(itinerary)) {
                    return itinerary.filter((i) => !!i);
                  }
                  return itinerary;
                });
              // Initialisation des filtres pour chacun des steps
              if (this.hasApplicableElements(this.filterByStep[0], res.dataForFilters)) {
                const newFilterByStep = { ...this.filterByStep[0] }
                delete newFilterByStep.duration
                delete newFilterByStep.price
                this.filterByStep[1] = newFilterByStep;
              } else {
                this.filterByStep[1] = {
                  cabinCodes: this.search.data.cabinClassPreferred,
                };
              }

              this.dataForFilters[1] = res.dataForFilters;
              this.refreshItinerariesForPackages(this.itinerariesByStep[1]);
            }
          } else {
            this.minimalCarbonOffsetByStep = res.itineraries.map((itinerary, index) =>
              Math.min(itinerary.carbonOffset?.amount),
            );

            if (res.itineraries?.length) {
              this.itinerariesByStep[1] = [...res.itineraries]
                // Si un "step" est "undefined", on l'ignore
                .filter((item) => !!item)
                // On ignore les itinéraire "undefined"
                .map((itinerary) => {
                  if (Array.isArray(itinerary)) {
                    return itinerary.filter((i) => !!i);
                  }
                  return itinerary;
                });
              // Initialisation des filtres pour chacun des steps
              if (this.hasApplicableElements(this.filterByStep[0], res.dataForFilters)) {
                const newFilterByStep = { ...this.filterByStep[0] }
                delete newFilterByStep.duration
                delete newFilterByStep.price
                this.filterByStep[1] = newFilterByStep;
              } else {
                this.filterByStep[1] = {
                  cabinCodes: this.search.data.cabinClassPreferred,
                };
              }
              this.dataForFilters[1] = res.dataForFilters;
              this.refreshItinerariesForPackages(res.itineraries);
            } else {
              this.refreshItinerariesForPackages(res.itineraries);
            }
          }

          this.setItineraryWay();
        }
        this.loading.emit(false);
        this.commonService.statusBarTextWhite();
      },
      error: (err) => {
        console.log(err);
        this.errorOccurred = true;
        this.loading.emit(false);
      },
    });
  }
  hasApplicableElements(obj, arr) {
    for (const key of Object.keys(obj)) {
      if (key === "baggageIncludedMinimumNumber") {
        continue;
      }
      if (key === "price") {
        continue;
      }
      
      const element = arr.find((item) => item.type === key);
      if (key === "companies" && element !== undefined) {
        const company = element.data.find((company) => company.label === obj[key]);
        if (company) {
          return true;
        }
      } else if (element !== undefined) {
        return true;
      }
    }
    return false;
  }
  fetchOfferPrice(offerId: string, offerItemId: string, packagedOfferPrice?: boolean, itinerary?: any) {
    this.loading.emit(true);
    return this.flightService
      .getOfferPrice(this.search.id, offerId, offerItemId, packagedOfferPrice, itinerary)
      .subscribe({
        next: (data) => {
          this.loading.emit(false);
          this.flightService.offerPriced.next(data);
          return data;
        },
        error: (err) => {
          console.log(err);
          this.loading.emit(false);
        },
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onTabChange(event: any, option?: any): void {
    this.order = option;
    this.refreshItinerariesToDisplay();
  }

  setItineraryWay(): void {
    if (this.search.data.type === "multi") {
      this.itineraryWay = "trip";
    } else if (this.search.data.type === "round" && this.step === 1) {
      this.itineraryWay = "return";
    } else {
      this.itineraryWay = "departure";
    }
  }

  initExtraSettings(): void {
    const extraSettings: any = this.route.snapshot.data.extraSettings;
    if (extraSettings) {
      this.seeOutOfPolicy = extraSettings.seeOutOfPolicy;
    }
  }

  displayFilters(value: boolean = true): void {
    this.filtersDisplayed = value;
    this.sidebarOpen.emit(value);
    if (value === true) {
      this.commonService.statusBarTextBlack();
      this.commonService.setBackFunction(() => {
        this.filtersDisplayed = false;
        this.sidebarOpen.emit(false);
        this.commonService.statusBarTextWhite();
        this.commonService.unsetBackFunction();
      }, this);
    } else {
      this.commonService.statusBarTextWhite();
      this.commonService.unsetBackFunction();
    }
  }

  updateFilters($event: any): void {
    this.filterDefinitionsSubject.next($event);
  }

  refreshItinerariesToDisplay(resetFilter?: boolean, additionnalFilter?: (_itinerary: any) => boolean): void {
    if (resetFilter) {
      this.filterByStep[this.step] = {};
    }
    let orderedItinariesForStep = this.flightService.orderResults(this.order, this.itinerariesByStep[this.step]);
    if (this.dataForFilters && this.dataForFilters[this.step]) {
      if (Array.isArray(this.dataForFilters[this.step])) {
        this.filterDefinitionsSubject.next(this.dataForFilters[this.step]);
      } else {
        this.filterDefinitionsSubject.next(this.dataForFilters);
      }
    }
    if (additionnalFilter) {
      orderedItinariesForStep = orderedItinariesForStep.filter(additionnalFilter);
    }
    this.displayedItineraries = orderedItinariesForStep.filter((itinerary: any) =>
      this.filterItinerary(itinerary, this.filterByStep[this.step], this.step),
    );
    this.flightService.filterForCurrentStep.next(this.filterByStep[this.step]);
    this.resetViewport();
  }

  refreshItinerariesForPackages(
    itinerariesByStep: any[],
    resetFilter?: boolean,
    additionnalFilter?: (_itinerary: any) => boolean,
  ): void {
    if (resetFilter) {
      this.filterByStep[this.step] = {};
    }
    let orderedItinariesForStep = this.flightService.orderResults(this.order, itinerariesByStep);
    if ((this.dataForFilters && this.dataForFilters[this.step], this.step)) {
      if (Array.isArray(this.dataForFilters[this.step])) {
        this.filterDefinitionsSubject.next(this.dataForFilters[this.step]);
      } else {
        this.filterDefinitionsSubject.next(this.dataForFilters);
      }
    }
    if (additionnalFilter) {
      orderedItinariesForStep = orderedItinariesForStep.filter(additionnalFilter);
    }

    this.displayedItineraries = orderedItinariesForStep.filter((itinerary: any) =>
      this.filterItinerary(itinerary, this.filterByStep[this.step], this.step),
    );

    this.resetViewport();
  }

  private filterItinerary(itinerary: any, filterForCurrentStep: Filter, step: any): boolean {
    let isValid = true;

    // Check if the itinerary contains the desired cabin codes
    if (filterForCurrentStep.cabinCodes && itinerary.provider !== "afkl" && itinerary.otherOffers.length >= 1) {
      const otherOffers = itinerary.otherOffers.filter((offer: any) =>
        this.filterItineraryOtherOffersClass(offer, filterForCurrentStep),
      );
      itinerary.filteredOffers = otherOffers;
      if (otherOffers.length === 0) {
        isValid = false;
      }
    }

    // If the itinerary is still considered valid
    if (isValid) {
      // Check legs for nbStops and companies conditions
      let legMeetsNbStopsCondition = true;
      let legMeetsCompaniesCondition = true;

      // Function to check nbStops and companies conditions for a given leg
      const checkLegConditions = (leg: any) => {
        for (const filterType in filterForCurrentStep) {
          if (Object.prototype.hasOwnProperty.call(filterForCurrentStep, filterType)) {
            const filterValue = filterForCurrentStep[filterType];

            // Skip iteration if the filter value is null or undefined
            if (filterValue == null) continue;

            // Handle specific filter conditions
            switch (filterType) {
              case "price":
                if (filterValue < itinerary.totalPrice) isValid = false;
                break;
              case "duration":
                if (filterValue < leg.duration) isValid = false;
                break;
              case "refundable":
              case "changeable":
              case "freeRefund":
              case "freeExchange":
                if (!itinerary[filterType] && itinerary.provider !== "afkl" && itinerary.otherOffers.length === 1) {
                  isValid = false;
                }
                break;
              case "haveNegotiatedRate":
              case "subscriber":
              case "baggageIncluded":
                if (itinerary.provider) {
                  continue;
                } else {
                  if (itinerary[filterType] < filterForCurrentStep.baggageIncludedMinimumNumber) isValid = false;
                }
               
                break;
              case "nbStops":
                const numberOfStops = itinerary.legs.length > 1 ? itinerary.legs[1].schedules.length - 1 : leg.schedules.length - 1;
                if (filterValue.length > 0 && !filterValue.includes(numberOfStops)) {
                  legMeetsNbStopsCondition = false;
                }
                break;
              case "companies":
                if (filterValue.length > 0) {
                  const legToCheck = itinerary.legs.length > 1 ? itinerary.legs[1] : leg;
                  const meetsCondition = filterValue.includes("AF") ? legToCheck.schedules.some((schedule: any) =>
                    filterValue.includes(schedule.carrier.operating) || filterValue.includes(legToCheck.governingCarrier)
                  ) :  legToCheck.schedules.some((schedule: any) =>
                    filterValue.includes(schedule.carrier.operating)
                  );
                  if (!meetsCondition) {
                    legMeetsCompaniesCondition = false;
                  }
                }
                
                break;
              case "departureAirports":
                const departureSchedules = itinerary.legs.length > 1 ? itinerary.legs[1].schedules : leg.schedules;
                if (filterValue.length > 0 && !filterValue.some((airport: string) =>
                  departureSchedules.some((schedule: any) =>  schedule.departure.airport === airport)
                )) {
                  isValid = false;
                }
                break;
              case "arrivalAirports":
                const arrivalSchedules = itinerary.legs.length > 1 ? itinerary.legs[1].schedules : leg.schedules;
                if (filterValue.length > 0 && !filterValue.some((airport: string) =>
                  arrivalSchedules.some((schedule: any) => schedule.arrival.airport === airport)
                )) {
                  isValid = false;
                }
                break;
              case "airportStops":
                const stopSchedules = itinerary.legs.length > 1 ? itinerary.legs[1].schedules : leg.schedules;
                if (filterValue.length > 0 && !filterValue.some((airport: string) =>
                  stopSchedules.some((schedule: any) => schedule.departure.airport === airport || schedule.arrival.airport === airport)
                )) {
                  isValid = false;
                }
                break;
              case "carryOnIncluded":
                if (itinerary.provider) {
                  continue;
                } else {
                  if (filterValue === true && itinerary.carryOnIncluded <= 0) isValid = false;
                }
                break;
              default:
                // Handle other filter types if needed
                break;
            }

            // Break out of the loop if the itinerary is no longer considered valid
            if (!isValid) break;
          }
        }
      };

      // Check legs of the main itinerary
      for (const leg of itinerary.legs) {
        checkLegConditions(leg);
      }

      // Check legs of otherOffers if there are multiple offers
      if (itinerary.otherOffers.length > 1) {
        for (const otherOffer of itinerary.otherOffers) {
          for (const leg of otherOffer.legs) {
            checkLegConditions(leg);
          }
        }
      }

      // If any leg fails to meet the condition for nbStops or companies, set isValid to false
      if (!legMeetsNbStopsCondition || !legMeetsCompaniesCondition) {
        isValid = false;
      }
    }

    // Filter other offers if the itinerary is still considered valid and has additional offers
    if (isValid && itinerary.otherOffers.length >= 1) {
      itinerary.filteredOffers = itinerary.filteredOffers && itinerary.filteredOffers.length > 0
        ? itinerary.filteredOffers.filter((offer: any) =>
          this.flightService.filterItineraryOtherOffers(offer, filterForCurrentStep),
        )
        : itinerary.otherOffers.filter((offer: any) =>
          this.flightService.filterItineraryOtherOffers(offer, filterForCurrentStep),
        );
    }

    return isValid;
  }



  filterItineraryOtherOffersClass(itinerary: any, filterForCurrentStep: Filter) {
    return (
      itinerary.cabinCodes.some((cabinCode) => cabinCode === filterForCurrentStep.cabinCodes) ||
      itinerary.cabinCodes === filterForCurrentStep.cabinCodes ||
      itinerary.legs[0].schedules.some((schedule) =>
        schedule.cabinCodes.some((cabinCode) => cabinCode === filterForCurrentStep.cabinCodes),
      )
    );
  }

  preview(itinerary: any): void {
    if (this.search.data.type === "simple") {
      this.select(itinerary);
    } else {
      this.commonService.statusBarTextBlack();
      this.sidebarOpen.emit(true);
      this.choosenObject = itinerary;
      this.dataPreview = {
        itineraries: [itinerary],
        settings: this.settings,
        users: this.users,
        searchType: this.search.data.type,
      };
    }
  }

  expandItinerary(itinerary: any) {
    const result: any = this.fetchOfferPrice(
      itinerary.offerId,
      itinerary.offerItem[0].OfferItemID,
      itinerary.type === "packaged",
      this.step === 1 ? itinerary : null,
    );
    this.pricedOffer = result.pricedOffer;
  }

  select(itinerary: any): void {
    if (this.search.data.type === "simple") {
      this.commonService.statusBarTextBlack();
      this.confirmSidebarOpen.emit(true);
      this.selectFares.emit({
        itineraries: [itinerary],
        settings: this.settings,
        users: this.users,
        searchType: this.search.data.type,
      });
    } else if (this.search.data.type === "multi" && this.step !== this.itinerariesByStep.length - 1) {
      this.selectedItineraries.push(itinerary);
      this.currentItinerary = itinerary;
      const previousFilter = this.filterByStep[this.step];
      this.step++;
      const departureMax: any = itinerary.legs[itinerary.legs.length - 1].arrivalDatetime.add(1, "hour");
      this.setItineraryWay();

      const currentFilter = this.filterByStep[this.step];
      Object.entries(previousFilter).forEach(([key, value]) => {
        if (key && currentFilter[key] === undefined && value !== undefined) {
          if (typeof value === "object") {
            // On fait un clone pour "casser" le lien avec le filtre précédent.
            // Sinon, tout changement d'une des propriété de l'objet "value" se répercuterait sur le filtre précédent également
            currentFilter[key] = JSON.parse(JSON.stringify(value));
          } else {
            currentFilter[key] = value;
          }
        }
      });

      this.refreshItinerariesToDisplay(
        false,
        (_itinerary: any) => _itinerary.legs[0].departureDatetime.diff(departureMax) > 0,
      );
      this.flightService.closeExtendedPanel.next(true);
      this.sidebarOpen.emit(false);
      this.commonService.statusBarTextWhite();
    } else if (itinerary.type === "packaged") {
      this.selectedItineraries.push(itinerary);
      this.displayValidatePanel = true;
      this.oldSelected = [itinerary];
      this.commonService.statusBarTextBlack();
      this.confirmSidebarOpen.emit(true);
    } else if (
      this.step === this.itinerariesByStep.length - 1 &&
      this.search.data.type !== "simple" &&
      this.step != 0
    ) {
      // this.loading.emit(true);
      this.selectedItineraries.push(itinerary);
      const previousFilter = this.filterByStep[this.step];
      this.displayValidatePanel = true;
      this.oldSelected = this.selectedItineraries;
      this.commonService.statusBarTextBlack();
      this.confirmSidebarOpen.emit(true);
    } else {
      this.selectedItineraries.push(itinerary);
      this.currentItinerary = itinerary;
      const previousFilter = this.filterByStep[this.step];
      const departureMax: any = itinerary.legs[itinerary.legs.length - 1].arrivalDatetime.add(1, "hour");
      this.flightService.closeExtendedPanel.next(true);
      if (itinerary.className === "LOWEST") {
        this.step++;
        this.setItineraryWay();
        this.refreshFilters(previousFilter);
      } else {
        this.fetchItinerariesForInbound(itinerary);
        this.refreshFilters(previousFilter);
      }

      // On ne doit conserver que les retours dont l'heure de départ est au moins une heure après la date d'arrivée de l'aller
      this.refreshItinerariesToDisplay(
        false,
        (_itinerary: any) => _itinerary.legs[0].departureDatetime.diff(departureMax) > 0,
      );

      this.sidebarOpen.emit(false);
      this.commonService.statusBarTextWhite();
    }

    this.choosenObject = undefined;
  }

  private refreshFilters(previousFilter: Filter) {
    const currentFilter = this.filterByStep[this.step];
    Object.entries(previousFilter).forEach(([key, value]) => {
      if (key && currentFilter[key] === undefined && value !== undefined) {
        if (typeof value === "object") {
          // On fait un clone pour "casser" le lien avec le filtre précédent.
          // Sinon, tout changement d'une des propriété de l'objet "value" se répercuterait sur le filtre précédent également
          currentFilter[key] = JSON.parse(JSON.stringify(value));
        } else {
          currentFilter[key] = value;
        }
      }
    });
  }

  hidePreview(): void {
    this.confirmSidebarOpen.emit(false);
    this.commonService.statusBarTextWhite();
    this.sidebarOpen.emit(false);
    this.choosenObject = undefined;
  }

  changeLast(displayLast: boolean = false): void {
    if (this.step > 0) {
      if (displayLast === false) {
        this.step--;

        if (this.search.data.type != "multi") {
          this.itineraryWay = "departure";
        }
      }
      this.currentItinerary = this.selectedItineraries[this.step - 1];
      this.selectedItineraries[this.step] = undefined;
      this.selectedItineraries.splice(this.step);
      if (this.search.data.type !== "simple") {
        this.displayedItineraries = this.itinerariesByStep[this.step];
      }
      this.refreshItinerariesToDisplay(displayLast);
    }
  }

  // use when you close the panel to unselect the itinerary, no need to filter
  unselectItinerary(): void {
    this.changeLast(true);
    // this.selectedItineraries.splice(-1);;
  }

  validate($event: any): void {
    if (!$event || !$event.offers) {
      this.displayValidatePanel = false;
      return this.changeLast(true);
    }
    let price: number = 0;
    this.confirmSidebarOpen.emit(false);
    $event.offers.forEach((itinerary: any) => {
      price += itinerary.price ? parseFloat(itinerary.price) : parseFloat(itinerary.totalPrice);
    });

    this.selectFares.emit({
      itineraries: $event.offers,
      settings: this.settings,
      users: this.users,
      newOffers: this.bestPackages,
      autoPrebook: true,
      price: {
        amount: price,
        currency: "EUR",
      },
    });
  }

  hideValidate(): void {
    this.displayValidatePanel = false;
    this.confirmSidebarOpen.emit(false);
    this.commonService.statusBarTextWhite();
    this.sidebarOpen.emit(false);
    this.changeLast(true);
  }

  changeSearch(): void {
    this.modifySearch.emit();
  }
}

export interface Filter {
  price?: number;
  duration?: number;

  freeRefund?: boolean;
  freeExchange?: boolean;
  subscriber?: boolean;
  changeable?: boolean;
  haveNegotiatedRate?: boolean;
  refundable?: boolean;
  carryOnIncluded?: boolean;
  baggageIncluded?: boolean;
  baggageIncludedMinimumNumber?: number;

  nbStops?: number[];
  departureAirports?: string[];
  airportStops?: string[];
  arrivalAirports?: string[];
  cabinCodes?: string;
  companies?: string[];
}
