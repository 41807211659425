import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/@shared/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { TravelCarModule } from "./car/car.module";
import { TravelTransferModule } from "./transfer/transfer.module";
import { TravelComponent } from "./travel.component";
import { TravelFlightModule } from "./flight/flight.module";
import { TravelHotelModule } from "./hotel/hotel.module";
import { TravelRouting } from "./travel.routing";
import { TravelTrainModule } from "./train/train.module";
import { TravelConditionsComponent } from "./conditions/conditions.component";

const TRAVELING_MODULES: Array<any> = [
  FormsModule,
  SharedModule,
  TravelFlightModule,
  TravelTrainModule,
  TravelHotelModule,
  TravelCarModule,
  TravelTrainModule,
  TravelTransferModule,
  TranslateModule.forChild(),
];

const TRAVEL_COMPONENTS: any[] = [TravelComponent, TravelConditionsComponent];

@NgModule({
  imports: [...TRAVELING_MODULES, TravelRouting],
  declarations: [...TRAVEL_COMPONENTS],
  providers: [],
})
export class TravelModule {}
