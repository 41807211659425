<ng-template #template>
  <div
    [class.inGridArea]="!!gridArea?.input"
    [class.valid]="valid"
    [ngClass]="className + ' input-' + gridArea?.input"
    class="p-inputgroup-container"
    [style.gridArea]="gridArea?.input"
    [class.disabled]="disabled"
    (click)="focusInput()"
  >
    <span class="reverse" (click)="reverseData()" *ngIf="reverse && gridArea?.input.includes('origin')">
      <img src="./assets/svg/switch.svg" />
    </span>
    <div [class.input-error]="hasError" [class.valid]="valid" class="p-inputgroup">
      <ng-container *ngIf="icon; else noIcon">
        <i class="p-inputgroup-addon txt-20 disabled" [ngClass]="icon"></i>
      </ng-container>

      <ng-template #noIcon>
        <img class="p-inputgroup-addon disabled" src="./assets/svg/markup-violet.svg" />
      </ng-template>
      <ng-container *ngIf="label">
        <span class="label">{{ label }}</span>
      </ng-container>

      <div class="input-select">
        <input
          #autoComplete
          type="search"
          [id]="inputId"
          [ngModel]="selectedAddress.description"
          (focus)="focusEmitter.emit(); open()"
          (blur)="close()"
          [placeholder]="placeholder"
          googleAutocomplete
          [types]="types"
          [radius]="radius"
          [from]="from"
          (searchPredictions)="showPredictions($event)"
          googlePlace
          [placeId]="selectedPredictionId"
          [address]="selectedAddress.description"
          (selectPlace)="setAddress($event)"
          (input)="onInput($event)"
          (keyboardControl)="keyboardControl($event)"
          required
          [disabled]="disabled"
          [componentRestrictions]="restrictions"
          [ngClass]="label ? 'label' : ''"
        />
      </div>
    </div>
  </div>
  <div
    *ngIf="opened"
    [style.gridArea]="gridArea?.results"
    class="results {{ alignement }}"
    [class.inGridArea]="!!gridArea?.input"
  >
    <div class="autocomplete">
      <ul class="autocompleted">
        <li
          class="place"
          [ngClass]="{
            selected: selectedItemIndex === i,
            'keyboard-active': selectedItemIndex > -1
          }"
          *ngFor="let prediction of predictions; let i = index"
          (mousedown)="selectPrediction(prediction)"
        >
          <!-- ci-dessus : le 'mousedown' est nécessaire (au lieu du click) pour que "selectPrediction" puisse se déclencher avant le masquage des résultats effectué lors du blur du champ input -->
          <ng-container *ngIf="prediction && prediction.value; else geocodingIcon">
            <ng-container [ngSwitch]="prediction.value.destinationtypecode">
              <i *ngSwitchCase="'STN'" class="fas fa-train"></i>
              <i *ngSwitchCase="'IATA'" class="fas fa-plane"></i>
              <i *ngSwitchCase="'AIR'" class="fas fa-plane"></i>
              <i *ngSwitchCase="'RST'" class="fas fa-hotel"></i>
              <i *ngSwitchCase="'HTL'" class="fas fa-hotel"></i>
              <i *ngSwitchCase="'GIATA'" class="fas fa-hotel"></i>
              <i *ngSwitchCase="'IFF'" class="fas fa-hotel"></i>
              <i *ngSwitchCase="'TTI'" class="fas fa-hotel"></i>
              <i *ngSwitchCase="'PRT'" class="fas fa-ship"></i>
              <i *ngSwitchDefault class="fas fa-map-marker-alt"></i>
            </ng-container>
          </ng-container>

          <ng-template #geocodingIcon>
            <i class="fas fa-map-marker-alt"></i>
          </ng-template>
          {{ prediction.description }}
        </li>
      </ul>
      <div class="no-result" *ngIf="predictions.length === 0" translate>PLACEFINDER.NO_SEARCH_RESULTS</div>
    </div>
  </div>
</ng-template>
<div *ngIf="!gridArea" [class.fullscreen]="(commonService.isTabletObservable | async) === true && opened">
  <ng-container *ngTemplateOutlet="template"></ng-container>
</div>
