import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { HotelService } from "src/app/@shared/services/hotel.service";
import { ItemService } from "src/app/@shared/services/item.service";
import { UtilsTypes } from "../../../../@types/utils";
import * as L from "leaflet";
import { DomSanitizer } from "@angular/platform-browser";
import { CommonService } from "src/app/@shared/services/common.service";

@Component({
  selector: "spt-travel-confirmation-hotel",
  templateUrl: "./confirmation-sidebar-hotel.component.html",
  styleUrls: ["./confirmation-sidebar-hotel.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationSidebarHotelComponent implements OnInit, AfterViewInit {
  @Input() itemId: string;
  @Input() status: string;
  @Input() hotel: any;
  @Input() price: UtilsTypes.Price;
  @Output() prebook: EventEmitter<void> = new EventEmitter();

  @ViewChild("leafletMap", { static: false }) mapElement: ElementRef;

  private map: L.Map = null;
  private mapOtions: L.MapOptions = null;

  pricePerDay: UtilsTypes.Price;
  public rooms: any[];
  public datein: Date;
  public dateout: Date;
  public cancellationPolicy: any;
  public contractRemarks: string[];
  public inPolicy: boolean;
  public authorizationsByPolicies: any[];
  public carbonOffset: any;
  public googleMapsUrl: string;

  constructor(
    private hotelService: HotelService,
    private itemService: ItemService,
    private elementRef: ElementRef,
    public commonService: CommonService,
  ) {}

  private _travelers: any[];
  public travelerLabel: string;
  @Input() set travelers(value: any[]) {
    this._travelers = value;
    if (value.length === 1) {
      this.travelerLabel = "GLOBAL.TRAVELER";
    } else if (value.length > 1) {
      this.travelerLabel = "GLOBAL.TRAVELERS";
    }
  }

  get travelers(): any[] {
    return this._travelers;
  }

  private _detail: any;

  get detail(): any {
    return this._detail;
  }

  @Input() set detail(value: any) {
    this._detail = value;
    const { rooms, datein, dateout } = value;
    if (datein) {
      this.datein = datein;
    }
    if (dateout) {
      this.dateout = dateout;
    }
    if (rooms) {
      this.rooms = rooms;
      this.calculPricePerDay();
    }
  }

  private _formData: any;

  get formData(): any {
    return this._formData;
  }

  @Input() set formData(value: any) {
    this._formData = value;
    const { rooms, datein, dateout } = value;
    if (datein) {
      this.datein = datein;
    }
    if (dateout) {
      this.dateout = dateout;
    }
    if (rooms) {
      this.rooms = rooms;
      this.calculPricePerDay();
    }
    this.getRatesDetails();
  }

  ngOnInit(): void {
    if (!this.hotel && this.detail && this.detail.accomcode) {
      this.hotelService.getDescription(this.detail.accomcode, this.formData.suppliercode).subscribe((hotel) => {
        this.hotel = hotel;
      });
    }
  }
  ngAfterViewInit() {
    const [longitude, latitude] = this.hotel.coordinates;
    this.setMapMarker([latitude, longitude]);
    this.googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
  }
  setMapMarker(coordinates: L.LatLngExpression) {
    this.mapOtions = {
      center: L.latLng(coordinates),
      layers: [
        L.tileLayer("http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
          maxZoom: 18,
          attribution: "...",
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }),
      ],
      zoom: 14,
    };

    this.map = L.map(this.mapElement.nativeElement, this.mapOtions);
    const marker: L.Icon = L.icon({
      iconUrl: "./assets/svg/map-purple-icon.svg",
      iconSize: [30, 30],
    });
    L.marker(coordinates, { icon: marker }).addTo(this.map);
  }

  getRatesDetails() {
    let getRatesDetails: any;
    if (this.status === "confirmed") {
      getRatesDetails = this.itemService.getCancellation(this.itemId);
    } else if (this.formData) {
      getRatesDetails = this.hotelService.getRatesDetails(this.formData.accomtoken, this.formData.sessionId);
    }
    if (getRatesDetails) {
      getRatesDetails.subscribe((cancellation: any) => {
        if (cancellation.data) {
          this.cancellationPolicy = cancellation.data.cancellationPolicy
            ? cancellation.data.cancellationPolicy[0]
            : null;
          this.contractRemarks = cancellation.data.contractRemarks;
          this.inPolicy = cancellation.data.inPolicy;

          this.carbonOffset = cancellation.data.carbonOffset;
        } else {
          this.authorizationsByPolicies = cancellation.authorizationsByPolicies;
          this.cancellationPolicy = cancellation.cancellationPolicy ? cancellation.cancellationPolicy[0] : null;
          this.contractRemarks = cancellation.contractRemarks;
          this.inPolicy = cancellation.inPolicy;
          this.carbonOffset = cancellation.carbonOffset;
        }
        this.elementRef.nativeElement.scrollIntoView({ behavior: "smooth" });
      });
    }
  }

  calculPricePerDay(): void {
    this.pricePerDay = {
      amount: this.rooms.reduce((acc, room) => {
        return acc + Number(room.pricePerDay);
      }, 0),
      currency: this.price.currency,
    };
  }
}
