<p-dropdown
  styleClass="w-full"
  panelStyleClass="fullscreen lg"
  appendTo="body"
  [options]="hourOptions"
  (onChange)="update()"
  [placeholder]="'GLOBAL.HOUR_MANDATORY' | translate"
  [(ngModel)]="hours"
></p-dropdown>
<span class="time-separator">:</span>
<p-dropdown
  styleClass="w-full"
  panelStyleClass="fullscreen lg"
  appendTo="body"
  [options]="minuteOptions"
  (onChange)="update()"
  [(ngModel)]="minutes"
  [placeholder]="'GLOBAL.MINUTES_MANDATORY' | translate"
></p-dropdown>
