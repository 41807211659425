<section class="itinerary-header">
  <div class="icon">
    <i class="spt-icon-train"></i>
  </div>
  <div class="journey">
    <div class="origin">
      <div class="name">
        {{ itinerary.journeys[0].departure.name }}
      </div>
    </div>
    <i class="arrow spt-icon-arrow-circle-right"></i>
    <div class="destination">
      <div class="name">
        {{ itinerary.journeys[0].arrival.name }}
      </div>
    </div>
  </div>
  <div class="step">
    <span class="way" translate> {{ isSmallDevice ? "SEARCH.SHORT." : "SEARCH.TRAIN." }}{{ type | uppercase }} </span>
    <span class="confirmation" translate>GLOBAL.CONFIRMATION</span>
  </div>
  <div class="travel-class">
    <i class="spt-icon-tag m-r-5"></i>
    {{ "SEARCH.RESULT.RAIL.FLEXIBILITY." + flexibility | uppercase | translate }}
  </div>
</section>

<section class="journeys">
  <ng-container *ngFor="let journey of itinerary.journeys; let i = index; let isFirst = first; let isLast = last">
    <section *ngIf="journey.segments.length > 0; else openReturn" class="journey" [class.isLast]="isLast && !isFirst">
      <div class="date">
        <span class="day">{{ journey.departure.date.date | date: "dd" }}</span>
        <span class="month">{{ journey.departure.date.date | localizedDate: "MMM" }}</span>
      </div>
      <div class="legs">
        <div class="segments">
          <spt-travel-train-segments
            class="detailed"
            [detailed]="true"
            [segments]="journey.segments"
            [isSmallDevice]="isSmallDevice"
            [offerCarbonOffset]="journey.carbonOffset"
          ></spt-travel-train-segments>
        </div>
        <div
          class="seat-preferences"
          *ngIf="
            hasOuiGo === false &&
            isAllTer[i] === false &&
            itinerary.seatPreferenceOptions &&
            itinerary.seatPreferenceOptions[journey.direction]?.length &&
            itinerary.seatPreferenceOptions[journey.direction][0]?.reservationType &&
            !['undefined', 'notrequired'].includes(
              itinerary.seatPreferenceOptions[journey.direction][0].reservationType
            )
          "
          [class.hasChoice]="
            itinerary.seatPreferenceOptions[journey.direction] &&
            itinerary.seatPreferenceOptions[journey.direction][0].reservationType === 'optional'
          "
        >
          <ng-container
            *ngIf="
              itinerary.seatPreferenceSelection && itinerary.seatPreferenceSelection[journey.direction];
              else chooseSeats
            "
          >
            <div class="seat-preference" *ngIf="itinerary.seatPreferenceSelection[journey.direction]">
              <p class="segments">
                {{ journey.departure.name }} -> {{ journey.arrival.name }}
                <span *ngIf="itinerary.seatPreferenceSelection[journey.direction].length === 1; else multiSegments">
                  <p
                    *ngIf="
                      itinerary.seatPreferenceSelection[journey.direction][0].seatSelection.description ===
                        'SEAT_RESERVATION_DEFAULT';
                      else seatDesignated
                    "
                  >
                    {{ "SEARCH.RESULT.RAIL.SEAT_PREFERENCES.PREFERENCE" | translate }} :
                    {{ itinerary.seatPreferenceSelection[journey.direction][0].description }}
                  </p>
                  <ng-template #seatDesignated>
                    <span
                      *ngIf="
                        itinerary.seatPreferenceSelection[journey.direction][0].description !== '';
                        else noDescription
                      "
                      >{{ itinerary.seatPreferenceSelection[journey.direction][0].description }}</span
                    >
                    <ng-template #noDescription>
                      {{
                        "SEARCH.RESULT.RAIL.SEAT_PREFERENCES.SEAT_NEXT_TO"
                          | translate
                            : {
                                coach: itinerary.seatPreferenceSelection[journey.direction][0].seatLocation.coachNumber,
                                seat: itinerary.seatPreferenceSelection[journey.direction][0].seatLocation.seatNumber
                              }
                      }}
                    </ng-template>
                  </ng-template>
                </span>
                <ng-template #multiSegments>
                  <div *ngFor="let segment of itinerary.seatPreferenceSelection[journey.direction]; let index = index">
                    <span
                      *ngIf="
                        segment.seatSelection.description === 'SEAT_RESERVATION_DEFAULT' &&
                          itinerary.seatPreferenceOptions[journey.direction][index]?.reservationMethods[0] !==
                            'SEAT_RESERVATION_NOT_APPLICABLE';
                        else multidesignated
                      "
                    >
                      {{ "SEARCH.RESULT.RAIL.SEAT_PREFERENCES.MULTI_CONNECTION" | translate: { index: index + 1 } }} :
                      {{ segment.description }}
                    </span>
                    <ng-template #multidesignated>
                      <div *ngIf="!isSecondClass(journey.direction); else secondeClass">
                        {{ "SEARCH.RESULT.RAIL.SEAT_PREFERENCES.MULTI_CONNECTION" | translate: { index: index + 1 } }}
                        :
                        <ng-container *ngIf="segment.seatLocations[0]?.coachNumber">
                          {{
                            "SEARCH.RESULT.RAIL.SEAT_PREFERENCES.SEAT_POSITION"
                              | translate
                                : {
                                    coach: segment.seatLocations[0]?.coachNumber,
                                    seat: segment.seatLocations[0]?.seatNumber
                                  }
                          }}
                        </ng-container>
                      </div>
                    </ng-template>
                    <ng-template #secondeClass>
                      <div>
                        {{ "SEARCH.RESULT.RAIL.SEAT_PREFERENCES.MULTI_CONNECTION" | translate: { index: index + 1 } }}
                        :
                        <ng-container *ngIf="segment.seatLocation?.coachNumber">
                          {{
                            "SEARCH.RESULT.RAIL.SEAT_PREFERENCES.SEAT_POSITION"
                              | translate
                                : {
                                    coach: segment.seatLocation?.coachNumber,
                                    seat: segment.seatLocation?.seatNumber
                                  }
                          }}
                        </ng-container>
                      </div>
                    </ng-template>
                  </div>
                </ng-template>
              </p>
              <div
                class="border"
                [class.mandatorySeatIsSelected]="mandatorySeatIsSelected(i)"
                *ngIf="itinerary.seatPreferenceOptions[journey.direction]"
              >
                <p class="modify-button" (mousedown)="modifySeats(journey.segments, journey.direction)">
                  <img src="../../../../assets/svg/itineraries/seat.svg" alt="Seat" />
                  {{ "SEARCH.RESULT.RAIL.SEAT_PREFERENCES.CHOOSE" | translate }}
                </p>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-template #chooseSeats>
          <div class="seat-preference">
            <p class="segments">
              {{
                "SEARCH.RESULT.RAIL.SEAT_PREFERENCES." +
                  itinerary.seatPreferenceOptions[journey.direction][0].reservationType
                  | uppercase
                  | translate
              }}
            </p>
            <div
              class="border"
              *ngIf="itinerary.seatPreferenceOptions[journey.direction]"
              [class.mandatory]="itinerary.seatPreferenceOptions[journey.direction][0].reservationType !== 'optional'"
            >
              <p class="modify-button" (mousedown)="modifySeats(journey.segments, journey.direction)">
                <img src="../../../../assets/svg/itineraries/seat.svg" alt="Seat" />
                {{ "SEARCH.RESULT.RAIL.SEAT_PREFERENCES.CHOOSE" | translate }}
              </p>
              <p class="modify-button" (mousedown)="modifySeatsLegacy(journey.segments, journey.direction)">
                <img src="../../../../assets/svg/itineraries/seat.svg" alt="Seat" />
                LEGACY
              </p>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="fares" *ngIf="journey.direction && fareTypes[journey.direction]">
        <ng-container *ngFor="let fareType of fareTypes[journey.direction]">
          <div class="fare-title">
            <p class="type">{{ fareType.fareName }} x {{ fareType.passengers.length }}</p>
            <ng-container *ngIf="fareType.price">
              <p
                class="price"
                *ngIf="
                  !fareType.fareName.endsWith('Return') ||
                    (fareType.fareName.endsWith('Return') && journey.direction === 'outward');
                  else returnFare
                "
              >
                {{
                  {
                    amount: fareType.price.amount * fareType.passengers.length,
                    currency: fareType.price.currency
                  }
                    | SPTPrice
                    | async
                }}
              </p>
              <ng-template #returnFare>
                <p class="price">
                  {{
                    {
                      amount: 0,
                      currency: fareType.price.currency
                    }
                      | SPTPrice
                      | async
                  }}
                </p>
              </ng-template>
            </ng-container>
            <div class="see-conditions" (mousedown)="toggleFare(fareType)">
              {{ "SEARCH.RESULT.RAIL.SEE_CONDITIONS" | translate }}
            </div>
          </div>
        </ng-container>
      </div>
    </section>
  </ng-container>
  <ng-template #openReturn>
    <section class="open-return-journey">
      {{ "GLOBAL.CLASS.RETURN" | translate }}
      {{ "SEARCH.RESULT.RAIL.OPEN_RETURN" | translate }}
      {{ fareTypes["outward"][fareTypes["outward"].length - 1].validUntil.inward | localizedDate: "short" }}
    </section>
  </ng-template>
</section>

<section class="passengers-price">
  <div *ngIf="passengers.length > 0" class="passengers">
    <p class="travelers" translate>GLOBAL.TRAVELERS</p>
    <spt-avatar
      *ngFor="let passenger of passengers"
      [user]="{
        username: passenger.firstname + ' ' + passenger.lastname,
        birthdate: passenger.dateOfBirth
      }"
      [displayTravelerType]="true"
      class="m-b-15"
      title="{{ passenger.firstname }} {{ passenger.lastname }}"
    ></spt-avatar>
  </div>

  <div class="price">
    <p class="total" translate>SEARCH.RESULT.TOTAL</p>
    <p class="amount">
      {{ total | SPTPrice | async }}
      <i
        *ngIf="(provider === 'trainline' && itinerary.pricing.lineItems.length) || type === 'Exchange'"
        class="fas pointer"
        [ngClass]="showPriceBreakdown ? 'fa-chevron-up' : 'fa-chevron-down'"
        (mousedown)="showPriceBreakdown = !showPriceBreakdown"
      ></i>
    </p>
  </div>

  <div class="price-breakdown" *ngIf="showPriceBreakdown">
    <ng-container *ngFor="let lineItem of itinerary.pricing.lineItems">
      <p class="line-item" *ngIf="lineItem.price.amount !== 0">
        <span class="description">{{
          "SEARCH.RESULT.RAIL.LINE_ITEM.TYPE." + lineItem.type | uppercase | translate
        }}</span>
        <span class="amount">
          {{ lineItem.price | SPTPrice | async }}
        </span>
      </p>
    </ng-container>
  </div>

  <div class="warning-container" *ngIf="childrenPassengers?.length && user">
    <p class="children-warning">
      {{ "SEARCH.RESULT.RAIL.PASSENGERS.WARNING" | translate }}
    </p>
    <ul>
      <li *ngFor="let childPassenger of childrenPassengers">
        <span class="passenger">{{ childPassenger.firstname }} {{ childPassenger.lastname }}</span>
        <a
          [routerLink]="user.email === childPassenger.email ? ['/account'] : ['/management', 'members']"
          [queryParams]="user.email === childPassenger.email ? undefined : { email: childPassenger.email }"
        >
          {{ "GLOBAL.MODIFY" | translate }}
        </a>
      </li>
    </ul>
  </div>
</section>

<section class="delivery-options" *ngIf="deliveryOptions?.length && type !== 'Exchange'">
  <p class="title" translate>SEARCH.RESULT.RAIL.DELIVERY_OPTIONS.TITLE</p>
  <p-dropdown
    [options]="deliveryOptions"
    appendTo="body"
    [placeholder]="'LABELS.CHOOSE' | translate"
    [disabled]="validatingDeliveryOption || deliveryOptions.length === 1"
    [(ngModel)]="selectedDeliveryOption"
    (ngModelChange)="setDeliveryOption($event)"
  >
    <ng-template let-item pTemplate="item">
      <span>
        {{ "SEARCH.RESULT.RAIL.DELIVERY_OPTIONS." + item.label | uppercase | translate }}
      </span>
      <span *ngIf="item.value.price?.amount > 0">
        {{ item.value.price | SPTPrice | async }}
      </span>
    </ng-template>
    <ng-template let-item pTemplate="selectedItem">
      <span>
        {{ "SEARCH.RESULT.RAIL.DELIVERY_OPTIONS." + item?.label | uppercase | translate }}
      </span>
      <span *ngIf="item?.value.price?.amount > 0">
        {{ item?.value.price | SPTPrice | async }}
      </span>
    </ng-template>
  </p-dropdown>
</section>
<section class="selected-delivery-option" *ngIf="selectedDeliveryOption">
  <ng-container *ngIf="selectedDeliveryOption.deliveryCode === 'ELECTRONIC_TICKET'">
    <p class="description" translate>SEARCH.RESULT.RAIL.DELIVERY_OPTIONS.MOBILE</p>
    <p class="description" translate>GLOBAL.OR</p>
    <p class="description" translate>SEARCH.RESULT.RAIL.DELIVERY_OPTIONS.PRINTED</p>
    <p class="description" translate>SEARCH.RESULT.RAIL.DELIVERY_OPTIONS.REQUIREMENTS</p>
    <small>
      <b translate>SEARCH.RESULT.RAIL.DELIVERY_OPTIONS.VALID</b>
    </small>
  </ng-container>
  <ng-container *ngIf="selectedDeliveryOption.deliveryCode === 'AtocETicket'">
    <p class="description" translate>SEARCH.RESULT.RAIL.DELIVERY_OPTIONS.MOBILE</p>
    <p class="description" translate>GLOBAL.OR</p>
    <p class="description" translate>SEARCH.RESULT.RAIL.DELIVERY_OPTIONS.PRINTED</p>
    <p class="description" translate>SEARCH.RESULT.RAIL.DELIVERY_OPTIONS.EMAIL</p>
  </ng-container>
  <ng-container *ngIf="selectedDeliveryOption.deliveryCode === 'AtocKiosk'">
    <p class="description" translate>SEARCH.RESULT.RAIL.DELIVERY_OPTIONS.STATION_PRINT_TICKET</p>
    <p
      class="description"
      *ngIf="inCollectStations; else notInCollectStations"
      [innerHTML]="
        'SEARCH.RESULT.RAIL.DELIVERY_OPTIONS.COLLECT'
          | translate
            : {
                station: itinerary.journeys[0].departure.name
              }
      "
    ></p>
    <ng-template #notInCollectStations>
      <p
        class="description"
        [innerHTML]="
          'SEARCH.RESULT.RAIL.DELIVERY_OPTIONS.NOT_COLLECT'
            | translate
              : {
                  station: itinerary.journeys[0].departure.name
                }
        "
      ></p>
    </ng-template>
    <p class="description" [innerHTML]="'SEARCH.RESULT.RAIL.DELIVERY_OPTIONS.COLLECT_STATIONS' | translate"></p>
    <p-dropdown
      [placeholder]="'SEARCH.RESULT.RAIL.STATIONS.LIST' | translate"
      [filter]="true"
      [filterFields]="['label']"
      [options]="stations"
      [virtualScroll]="true"
      [virtualScrollItemSize]="20"
    ></p-dropdown>
  </ng-container>
</section>

<section class="separator"></section>

<section class="carbon-offset">
  <spt-travel-carbon-offset
    [carbonOffset]="itinerary.carbonOffset"
    [currency]="total.currency"
    [locale]="locale"
  ></spt-travel-carbon-offset>
</section>

<spt-conditions-train-dialog
  *ngIf="selectedFare"
  [visible]="!!selectedFare"
  [conditions]="selectedFare.conditions"
  [conditionsSummary]="selectedFare.conditionsSummary"
  [routeRestriction]="selectedFare.routeRestriction"
  [name]="selectedFare.fareName"
  (closeConditions)="selectedFare = undefined"
></spt-conditions-train-dialog>
