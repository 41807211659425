<div class="favorites">
  <div class="favorites-content">
    <div class="title" [ngClass]="favorites.length > 4 ? 'scroll' : ''">
      <span>
        <i class="pi pi-heart-fill"></i>
        <span>{{ title }}</span>
      </span>
      <p-button
        type="button"
        [label]="'SEARCH.FAVORITE_ADD_BUTTON' | translate"
        (mousedown)="addFavorites = true"
        class="favorites--add-show"
        styleClass="p-button-quaternary medium"
      ></p-button>
    </div>
    <form class="favorites-add" *ngIf="addFavorites" [ngClass]="searchEngineType" (submit)="addFavorite()">
      <i class="fas fa-times fa-2x m-l-auto" (click)="addFavorites = false"></i>
      <div *ngIf="searchEngineType === 'hotel'" class="p-inputgroup-container origin favorites inGridArea">
        <label *ngIf="searchEngineType === 'hotel'" class="name p-inputgroup input-origin">
          <span class="label">{{ "LABELS.NAME" | translate }}</span>
          <div class="input-select">
            <input
              [(ngModel)]="newFavoriteLabel"
              type="text"
              [placeholder]="'PLACEFINDER.FAVORITES.PLACEHOLDER_NAME' | translate"
              name="label"
              class="label"
            />
          </div>
        </label>
      </div>
      <spt-travel-search-autocomplete
        [label]="label"
        [placeholder]="placeholder"
        [optionDestinationSameAsOriginLabel]="optionDestinationSameAsOriginLabel"
        [destinationSameAsOrigin]="newFavoritedestinationSameAsOrigin"
        [searchService]="searchService"
        [travel]="newFavorite"
        (onTravelChange)="checkNewFavoriteValidity()"
        [disableFavorites]="true"
        [className]="'favorites'"
        [showSuggestInPanel]="false"
        [openInModal]="false"
      ></spt-travel-search-autocomplete>

      <p-button
        type="submit"
        [label]="'GLOBAL.ADD' | translate"
        [disabled]="!newFavoriteValid"
        class="favorites--add-validate"
        styleClass="p-button-quaternary medium"
      ></p-button>
    </form>
    <ul class="favorites-list" [ngClass]="favorites.length > 4 ? 'scroll' : ''">
      <li *ngFor="let favorite of favorites" class="favorite">
        <button type="button" (mousedown)="selectFavorite(favorite)">
          <span *ngIf="this.searchEngineType === 'hotel'" class="favorite-hotel" [attr.data-title]="favorite.label"
            ><span>{{ favorite.label }}</span></span
          >
          <span *ngIf="this.searchEngineType !== 'hotel'"
            ><span
              class="favorite-origin"
              [attr.data-title]="favorite.oad?.departure?.name || favorite.oad?.departure?.label"
              ><span>{{ favorite.oad?.departure?.name || favorite.oad?.departure?.label }}</span></span
            >

            <span
              class="favorite-destination"
              [attr.data-title]="favorite.oad?.arrival?.name || favorite.oad?.arrival?.label"
              ><span> &nbsp;- {{ favorite.oad?.arrival?.name || favorite.oad?.arrival?.label }}</span></span
            ></span
          >
        </button>
        <button
          type="button"
          (mousedown)="removeFavorite(favorite)"
          [attr.data-title]="'SEARCH.FAVORITE_SUP_BUTTON_HEART' | translate"
        >
          <i class="pi pi-heart-fill"></i>
        </button>
      </li>
    </ul>
    <div class="no-result" *ngIf="favorites.length === 0" translate>PLACEFINDER.NO_FAVORITE</div>
  </div>
</div>
<div class="last-search">
  <div class="last-search-content">
    <h2>
      <span translate>SEARCH.LASTSEARCH_TITLE</span>
    </h2>

    <ul class="last-search-list">
      <li *ngFor="let lastSearch of lastSearchs" class="search">
        <button type="button" (mousedown)="selectLastSearch(lastSearch)">
          <span *ngIf="this.searchEngineType === 'hotel'"
            ><span class="favorite-hotel" [attr.data-title]="lastSearch.label"
              ><span>{{ lastSearch.label }}</span></span
            ></span
          >
          <span *ngIf="this.searchEngineType !== 'hotel'"
            ><span class="favorite-origin" [attr.data-title]="lastSearch.travel.origin?.label"
              ><span>{{ lastSearch.travel.origin?.label }}</span></span
            >
            <span
              *ngIf="lastSearch.travel.destination"
              class="favorite-destination"
              [attr.data-title]="lastSearch.travel.destination?.label"
            >
              <span>&nbsp;- {{ lastSearch.travel.destination?.label }}</span></span
            ></span
          >
        </button>
        <button
          type="button"
          (mousedown)="addLastSearchToFavorite(lastSearch)"
          [attr.data-title]="'SEARCH.FAVORITE_ADD_BUTTON_HEART' | translate"
        >
          <i class="pi pi-heart"></i>
        </button>
      </li>
    </ul>
    <div class="no-result" *ngIf="lastSearchs.length === 0" translate>PLACEFINDER.NO_FAVORITE</div>
  </div>
</div>
