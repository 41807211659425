import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { SharedModule } from "../../../../@shared/shared.module";
import { EticketsModalComponent } from "./etickets-modal.component";

@NgModule({
  declarations: [EticketsModalComponent],
  imports: [SharedModule, ButtonModule, TranslateModule],
  exports: [EticketsModalComponent],
})
export class EticketsModalModule {}
