import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { Address } from "src/app/@shared/@types/models";
import { CommonService } from "../services/common.service";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { SearchEngineType } from "src/app/search/search-engine/search-engine-service";
import { AutocompleteResult } from "../directives";

@Component({
  selector: "spt-place-finder",
  styleUrls: ["./place-finder.component.scss"],
  templateUrl: "./place-finder.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class PlaceFinderComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("template", { static: true })
  template!: TemplateRef<any>;

  @Input() placeholder: string = this.translateService.instant("GLOBAL.ADDRESS");
  @Input() required: boolean = false;
  @Input() inputId: string;
  @Output() formatted: EventEmitter<Address> = new EventEmitter();
  @Input() address: Address;
  @Input() types: Array<string>;
  @Input() hasError: boolean;
  @Input() searchAddress: number = -1;
  @Input() searchType: SearchEngineType;
  @Input() icon: string;
  @Input() disabled: boolean = false;
  @Input() openAutocomplete: boolean;
  @Input() className?: string = "";
  @Input() valid?: boolean;
  @Input() filterAutocompleteResult?: (result: AutocompleteResult) => AutocompleteResult;
  @Input() filterAutocompleteResultThis?: any;
  @Output() selectTransferType: EventEmitter<any> = new EventEmitter();
  @Output() selectEmitter: EventEmitter<any> = new EventEmitter();
  @Output() openEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() focusEmitter: EventEmitter<void> = new EventEmitter();
  @Output() inputEmitter: EventEmitter<string> = new EventEmitter();
  @ViewChild("autoComplete", { static: false })
  private autoComplete: ElementRef;
  @Input() radius: number;
  @Input() from: any;
  @Input() showIcon: boolean = false;
  @Input() label: string = "";
  @Input() alignement: "left" | "right" = "left";
  @Input() suggestions: Array<any>;
  @Input() restrictions: google.maps.places.ComponentRestrictions;
  @Input() gridArea?: { input: string; results: string };
  @Input() reverse?: boolean;
  @Output() onReverse = new EventEmitter<void>();

  public opened: boolean = false;
  public focus: boolean = true;

  public selectedAddress: { description: string } = {
    description: "",
  };
  public predictions: Array<google.maps.places.AutocompletePrediction & { value: any }>;
  public selectedPredictionId: string;
  private ngUnsubscribe: Subject<void> = new Subject();
  public selectedItemIndex: number = -1;

  constructor(
    private zone: NgZone,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef,
    public commonService: CommonService,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.predictions = [];
    this.placeholder = this.required ? `${this.placeholder}*` : this.placeholder;
    if (this.gridArea) {
      // Permet de ne pas avoir le tag <stp-locality-autocomplete/> présent dans le DOM
      // (cf.https://stackoverflow.com/questions/38716105/angular2-render-a-component-without-its-wrapping-tag#answer-56887630)
      // Indispensable pour pouvoir utiliser les 'grid-template-areas'
      this.viewContainerRef.createEmbeddedView(this.template);
      this.viewContainerRef.element.nativeElement.remove();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.address?.currentValue) {
      this.formatAddress(changes.address.currentValue);
    }

    if (changes.suggestions && !changes.suggestions.firstChange && changes.suggestions.currentValue) {
      this.predictions = changes.suggestions.currentValue;
    }

    if (changes.openAutocomplete && !changes.openAutocomplete.firstChange && changes.openAutocomplete.currentValue) {
      this.autoComplete.nativeElement.focus();
      this.changeDetector.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  focusInput() {
    this.autoComplete?.nativeElement?.focus();
  }

  showPredictions(autocompleteResult: AutocompleteResult): void {
    if (this.filterAutocompleteResult) {
      autocompleteResult = this.filterAutocompleteResult.call(this.filterAutocompleteResultThis, autocompleteResult);
    }
    if (autocompleteResult.predictions && autocompleteResult.predictions.length) {
      this.predictions = autocompleteResult.predictions;
    }
  }

  selectPrediction(prediction: any): void {
    if (prediction.place_id) {
      this.selectedPredictionId = prediction.place_id;
    } else if (prediction.value) {
      const transferSelected: any = {
        type: prediction.value.destinationtypecode,
        code: prediction.value.destinationcode,
        coordinates: prediction.coordinates,
        description: prediction.description,
      };
      this.selectedAddress = {
        description: prediction.description,
      };
      this.selectTransferType.emit(transferSelected);
    }
  }

  keyboardControl(key: string): void {
    switch (key) {
      case "ArrowDown":
        if (this.selectedItemIndex === this.predictions.length - 1) {
          return;
        }

        this.selectedItemIndex++;
        break;
      case "ArrowUp":
        if (this.selectedItemIndex === -1) {
          return;
        }

        this.selectedItemIndex--;
        break;
      case "Enter":
        this.selectPrediction(this.predictions[this.selectedItemIndex]);
        break;
    }
  }

  setAddress(addrObj: google.maps.GeocoderResult): void {
    this.zone.run(() => {
      this.formatAddressGoogle(addrObj);
    });
  }

  formatAddress(add: Address): void {
    if (add.label) {
      this.selectedAddress.description = add.label;
      return;
    }
    if (add.description) {
      this.selectedAddress.description = add.description;
      return;
    }
    if (add.street) {
      this.selectedAddress.description += `${add.street}, `;
    }
    if (add.city) {
      this.selectedAddress.description += `${add.city}, `;
    }
    if (add.country) {
      this.selectedAddress.description += `${add.country}`;
    }
  }

  formatAddressGoogle(addrObj: google.maps.GeocoderResult): void {
    if (!addrObj.place_id) {
      return;
    }
    const add: Address | any = {
      place_id: addrObj.place_id,
      coordinates: [addrObj.geometry.location.lat(), addrObj.geometry.location.lng()],
      label: "",
      types: addrObj.types,
    };

    if (addrObj.formatted_address) {
      add.label += `${addrObj.formatted_address}`;
    }
    if (addrObj.types.indexOf("street_address") > -1) {
      add.street = `${addrObj.address_components[0].long_name} ${addrObj.address_components[1].long_name}`;
    } else if (addrObj.types.indexOf("route") > -1) {
      add.street = `${addrObj.address_components[0].long_name}`;
    } else if (addrObj.types.indexOf("premise") > -1) {
      add.street = `${addrObj.address_components[0].long_name} ${addrObj.address_components[1].long_name}`;
    } else {
      add.street = " ";
    }
    addrObj.address_components.forEach((item: google.maps.GeocoderAddressComponent) => {
      if (!add.city && item.types.indexOf("locality") > -1) {
        add.city = item.long_name;
      }
      // administrative_area_level_1 === state === Californie === Ile de france
      if (item.types.indexOf("administrative_area_level_1") > -1) {
        add.area = item.short_name;
      }
      if (item.types.indexOf("country") > -1) {
        add.country = item.long_name;
        add.countryCode = item.short_name;
      }
      if (item.types.indexOf("postal_code") > -1) {
        add.postal_code = item.short_name;
      }
    });
    this.formatAddress(add);
    this.formatted.emit(add);
  }

  open(): void {
    this.openEmitter.emit(true);
    this.opened = true;
    this.commonService.disableAppScroll();
  }

  close(): void {
    this.openEmitter.emit(false);
    this.opened = false;
    this.commonService.enableAppScroll();
  }

  /**
   * Méthode appelé notamement lors du "clear" de la valeur (appuie sur la petite croix à droite du champ pour vider sa valeur).
   * Dans ce contexte,
   *
   * cf. https://stackoverflow.com/questions/2977023/how-do-you-detect-the-clearing-of-a-search-html5-input
   */
  onInput(event: any): void {
    if (event.target?.value?.length === 0) {
      // Dans le cas où la valeur est vidé
      this.predictions.length = 0;
    }
    this.inputEmitter.emit(event.target?.value);
    this.selectedPredictionId = undefined;
  }
  reverseData(): void {
    this.onReverse.emit();
  }
}
