import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { Router } from "@angular/router";
import { Message } from "primeng/api";
import { Observable } from "rxjs";
import { SearchService } from "../../../../@shared/services/search.service";
import { TrainTypes } from "../../../../travel/train/train";

@Component({
  selector: "spt-reservations-modify",
  templateUrl: "./modify.component.html",
  styleUrls: ["./modify.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservationsModifyComponent implements OnInit {
  @Input() item: any;
  @Input() isSmallDevice: boolean;
  @Input() locale: string;
  @Output() returnToDetails: EventEmitter<void> = new EventEmitter();

  warningMessages: Array<Message> = [];
  modificationBody: TrainTypes.ExchangeBody;
  modification: Observable<any>;

  constructor() {
    // TODO: composant à supprimer, et remplacer son appel directement par <spt-reservations-modify-train/> ?
  }

  ngOnInit(): void {}
}
