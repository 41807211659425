import { Component, Input, ViewEncapsulation } from "@angular/core";
import { CommonService } from "src/app/@shared/services/common.service";

@Component({
  selector: "spt-basket-flight-block",
  templateUrl: "./flight-block.component.html",
  styleUrls: ["./flight-block.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FlightBlockComponent {
  private _item: any;
  @Input() set item(value: any) {
    this._item = value;
  }
  @Input() typeTravel?: string;
  @Input() firstItem: boolean = true;
  @Input() itemIndex: number;
  @Input() resa?: boolean = false;
  get item(): any {
    return this._item;
  }

  constructor(public commonService: CommonService) {}

  ngOnInit(): void {}
}
