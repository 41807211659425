export default [
  "Abbey Wood",
  "Aber",
  "Abercynon",
  "Aberdare",
  "Aberdeen",
  "Aberdour",
  "Abergavenny",
  "Abergele & Pensarn",
  "Aberystwyth",
  "Accrington",
  "Acle",
  "Acocks Green",
  "Acton Bridge",
  "Acton Bridge (Cheshire)",
  "Acton Central",
  "Acton Main Line",
  "Adderley Park",
  "Addlestone",
  "Adisham",
  "Adlington (Cheshire)",
  "Adlington (Lancashire)",
  "Adlington (Lancs)",
  "Adwick",
  "Airdrie",
  "Albany Park",
  "Albrighton",
  "Alderley Edge",
  "Aldershot",
  "Aldrington",
  "Alexandra Palace",
  "Alexandra Parade",
  "Alexandria",
  "Alfreton",
  "Allens West",
  "Alloa",
  "Alnmouth",
  "Alresford (Essex)",
  "Althorne (Essex)",
  "Althorpe",
  "Althorpe (Humberside)",
  "Alton",
  "Altrincham",
  "Alvechurch",
  "Ambergate",
  "Amberley",
  "Anderston",
  "Andover",
  "Anerley",
  "Angmering",
  "Anniesland",
  "Ansdell & Fairhaven",
  "Apperley Bridge",
  "Appleby",
  "Appledore (Kent)",
  "Appley Bridge",
  "Apsley",
  "Arbroath",
  "Ardrossan South Beach",
  "Ardwick",
  "Argyle Street",
  "Arlesey",
  "Armadale",
  "Armadale (West Lothian)",
  "Arnside",
  "Arundel",
  "Ascot (Berks)",
  "Ash",
  "Ash Vale",
  "Ashford (Surrey)",
  "Ashford International",
  "Ashley",
  "Ashtead",
  "Ashton-under-Lyne",
  "Ashurst (Kent)",
  "Ashurst (New Forest)",
  "Ashurst New Forest",
  "Ashwell & Morden",
  "Askam",
  "Aspatria",
  "Aspley Guise",
  "Aston",
  "Atherstone",
  "Atherstone (Warks)",
  "Atherton",
  "Atherton (Manchester)",
  "Attenborough",
  "Attleborough",
  "Audley End",
  "Axminster",
  "Aylesbury",
  "Aylesbury Vale Parkway",
  "Aylesford",
  "Aylesham",
  "Ayr",
  "Ayr Depot",
  "Bagshot",
  "Baildon",
  "Baillieston",
  "Balcombe",
  "Baldock",
  "Balham",
  "Balloch",
  "Balloch Central",
  "Bamber Bridge",
  "Bamford",
  "Banbury",
  "Bangor (Gwynedd)",
  "Banstead",
  "Barassie",
  "Bardon Mill",
  "Bare Lane",
  "Bargoed",
  "Barking",
  "Barming",
  "Barmouth",
  "Barnehurst",
  "Barnes",
  "Barnes Bridge",
  "Barnetby",
  "Barnham",
  "Barnsley",
  "Barnstaple",
  "Barnt Green",
  "Barrhead",
  "Barrow in Furness",
  "Barrow upon Soar",
  "Barrow-in-Furness",
  "Barrow-upon-Soar",
  "Barry",
  "Barry Docks",
  "Barry Island",
  "Basildon",
  "Basingstoke",
  "Bat & Ball",
  "Bath Spa",
  "Bathgate",
  "Batley",
  "Battersea Park",
  "Battle",
  "Battlesbridge",
  "Bayford",
  "Beaconsfield",
  "Bearsden",
  "Bearsted",
  "Beccles",
  "Beckenham Hill",
  "Beckenham Junction",
  "Bedford",
  "Bedford St Johns",
  "Bedhampton",
  "Bedworth",
  "Bedwyn",
  "Beeston",
  "Bekesbourne",
  "Belle Vue",
  "Bellgrove",
  "Bellingham",
  "Bellingham (London)",
  "Bellshill",
  "Belmont",
  "Belper",
  "Beltring",
  "Belvedere",
  "Bempton",
  "Ben Rhydding",
  "Benfleet",
  "Bentley (Hampshire)",
  "Bentley (Hants)",
  "Bentley (South Yorks)",
  "Bentley (South Yorkshire)",
  "Berkhamsted",
  "Berkswell",
  "Bermuda Park",
  "Berrylands",
  "Berwick (Sussex)",
  "Berwick-upon-Tweed",
  "Bescot Stadium",
  "Bethnal Green",
  "Beverley",
  "Bexhill",
  "Bexley",
  "Bexleyheath",
  "Bicester North",
  "Bicester Village",
  "Bickley",
  "Biggleswade",
  "Bilbrook",
  "Billericay",
  "Billingham",
  "Billingham (Cleveland)",
  "Billingshurst",
  "Bingley",
  "Birchgrove",
  "Birchington",
  "Birchington-on-Sea",
  "Birchwood",
  "Birkbeck",
  "Birmingham International",
  "Birmingham Moor Street",
  "Birmingham New Street",
  "Birmingham Snow Hill",
  "Bishop Auckland",
  "Bishopbriggs",
  "Bishops Stortford",
  "Bishopstone (Sussex)",
  "Bishopton (Renfrewshire)",
  "Bitterne",
  "Blackburn",
  "Blackheath",
  "Blackpool North",
  "Blackpool Pleasure Beach",
  "Blackpool South",
  "Blackridge",
  "Blackrod",
  "Blackwater",
  "Blairhill",
  "Blake Street",
  "Blakedown",
  "Blantyre",
  "Blaydon",
  "Bletchley",
  "Bloxwich",
  "Bloxwich North",
  "Bodmin Parkway",
  "Bognor Regis",
  "Bolton",
  "Bolton-on-Dearne",
  "Bolton-upon-Dearne",
  "Bookham",
  "Bootle (Cumbria)",
  "Borough Green & Wrotham",
  "Bosham",
  "Boston",
  "Botley",
  "Bourne End",
  "Bournemouth",
  "Bournville",
  "Bow Brickhill",
  "Bow Street",
  "Bowes Park",
  "Box Hill & Westhumble",
  "Boxhill & Westhumble",
  "Bracknell",
  "Bradford Forster Square",
  "Bradford Interchange",
  "Bradford on Avon",
  "Bradford-on-Avon",
  "Braintree",
  "Braintree Freeport",
  "Bramhall",
  "Bramley (Hampshire)",
  "Bramley (Hants)",
  "Bramley (West Yorks)",
  "Bramley (West Yorkshire)",
  "Brampton (Cumbria)",
  "Brandon",
  "Branksome",
  "Bredbury",
  "Brentford",
  "Brentwood",
  "Bridge of Allan",
  "Bridgend",
  "Bridgeton",
  "Bridgwater",
  "Bridlington",
  "Brierfield",
  "Brighouse",
  "Brighton",
  "Brimsdown",
  "Brinnington",
  "Bristol Parkway",
  "Bristol Temple Meads",
  "Brithdir",
  "Brixton",
  "Broad Green",
  "Broadbottom",
  "Broadstairs",
  "Brockenhurst",
  "Brockholes",
  "Brockley",
  "Bromley Cross",
  "Bromley Cross (Lancs)",
  "Bromley North",
  "Bromley South",
  "Bromsgrove",
  "Brondesbury",
  "Brondesbury Park",
  "Brookmans Park",
  "Brookwood",
  "Brough",
  "Broxbourne",
  "Bruce Grove",
  "Brundall",
  "Brunstane",
  "Bryn",
  "Buckshaw Parkway",
  "Bulwell",
  "Bures",
  "Burgess Hill",
  "Burley Park",
  "Burley-in-Wharfedale",
  "Burnage",
  "Burneside (Cumbria)",
  "Burnham (Buckinghamshire)",
  "Burnham (Bucks)",
  "Burnham-on-Crouch",
  "Burnley Barracks",
  "Burnley Central",
  "Burnley Manchester Rd",
  "Burnley Manchester Road",
  "Burnside (South Lanarkshire)",
  "Burnside (Strathclyde)",
  "Burntisland",
  "Burscough Bridge",
  "Burscough Junction",
  "Bursledon",
  "Burton on Trent",
  "Burton-on-Trent",
  "Bury St Edmunds",
  "Busby",
  "Bush Hill Park",
  "Bushey",
  "Butlers Lane",
  "Buxted",
  "Buxton",
  "Byfleet & New Haw",
  "Cadoxton",
  "Caerphilly",
  "Caersws",
  "Caldercruix",
  "Caldicot",
  "Caledonian Road & Barnsbury",
  "Cam & Dursley",
  "Camberley",
  "Camborne",
  "Cambridge",
  "Cambridge Heath",
  "Cambridge North",
  "Cambuslang",
  "Camden Road",
  "Camelon",
  "Canley",
  "Cannock",
  "Canonbury",
  "Canterbury East",
  "Canterbury West",
  "Cantley",
  "Cardiff Bay",
  "Cardiff Central",
  "Cardiff Queen Street",
  "Cark & Cartmel",
  "Carlisle",
  "Carluke",
  "Carmarthen",
  "Carnforth",
  "Carnoustie",
  "Carntyne",
  "Carpenders Park",
  "Carshalton",
  "Carshalton Beech",
  "Carshalton Beeches",
  "Castle Cary",
  "Castleford",
  "Castleton (Manchester)",
  "Castleton Moor",
  "Caterham",
  "Catford",
  "Catford Bridge",
  "Cathays",
  "Cathcart",
  "Cattal",
  "Chadwell Heath",
  "Chafford Hundred",
  "Chafford Hundred Lakeside",
  "Chalkwell",
  "Chandlers Ford",
  "Chapel-en-le-Frith",
  "Chapeltown (South Yorkshire)",
  "Chapeltown (Yorks)",
  "Chappel & Wakes Colne",
  "Charing (Kent)",
  "Charing Cross (Glasgow)",
  "Charlbury",
  "Charlton",
  "Chartham",
  "Chassen Road",
  "Chatham",
  "Cheadle Hulme",
  "Cheam",
  "Cheddington",
  "Chelford (Cheshire)",
  "Chelmsford",
  "Chelmsford (Essex)",
  "Chelsfield",
  "Cheltenham Spa",
  "Chepstow",
  "Cherry Tree",
  "Chertsey",
  "Cheshunt",
  "Chessington North",
  "Chessington South",
  "Chester",
  "Chester Le Street",
  "Chester Road",
  "Chester-le-Street",
  "Chesterfield",
  "Chestfield & Swalecliffe",
  "Chichester",
  "Chilham",
  "Chingford",
  "Chinley",
  "Chippenham",
  "Chipstead",
  "Chirk",
  "Chislehurst",
  "Chiswick",
  "Cholsey",
  "Chorley",
  "Christchurch",
  "Christs Hospital",
  "Church & Oswaldtwistle",
  "Church Fenton",
  "Church Stretton",
  "City Thameslink",
  "Clacton-on-Sea",
  "Clandon",
  "Clapham High Street",
  "Clapham Junction",
  "Clapton",
  "Clarkston",
  "Claygate",
  "Cleethorpes",
  "Clifton Down",
  "Clitheroe",
  "Clock House",
  "Clydebank",
  "Coatbridge Sunnyside",
  "Coatdyke",
  "Cobham & Stoke D'Abernon",
  "Codsall",
  "Cogan",
  "Colchester",
  "Colchester Town",
  "Coleshill Parkway",
  "Collington",
  "Colne",
  "Colwall",
  "Colwyn Bay",
  "Congleton",
  "Conisbrough",
  "Cononley",
  "Cooden Beach",
  "Cookham",
  "Cooksbridge",
  "Corbridge",
  "Corby",
  "Corkickle",
  "Coryton",
  "Coseley",
  "Cosford",
  "Cosham",
  "Cottingham",
  "Cottingley",
  "Coulsdon South",
  "Coulsdon Town",
  "Coventry",
  "Coventry Arena",
  "Cowden (Kent)",
  "Cowdenbeath",
  "Cradley Heath",
  "Craigendoran",
  "Cramlington",
  "Cranbrook (Devon)",
  "Craven Arms",
  "Crawley",
  "Crayford",
  "Cressing (Essex)",
  "Creswell",
  "Crewe",
  "Crewkerne",
  "Crews Hill",
  "Cricklewood",
  "Croftfoot",
  "Crofton Park",
  "Cromer",
  "Cromford",
  "Crookston",
  "Cross Gates",
  "Crossflatts",
  "Crosshill",
  "Crosskeys",
  "Crossmyloof",
  "Croston",
  "Crouch Hill",
  "Crowborough",
  "Crowhurst",
  "Crowthorne",
  "Croy",
  "Crystal Palace",
  "Cuddington",
  "Cuffley",
  "Cumbernauld",
  "Cupar",
  "Cuxton",
  "Cwmbach",
  "Cwmbran",
  "Dagenham Dock",
  "Daisy Hill",
  "Dalgety Bay",
  "Dalmarnock",
  "Dalmeny",
  "Dalmuir",
  "Dalreoch",
  "Dalry",
  "Dalston (Cumbria)",
  "Dalston Junction",
  "Dalston Kingsland",
  "Dalton (Cumbria)",
  "Danby",
  "Danes Court",
  "Danescourt",
  "Darlington",
  "Darnall",
  "Darsham",
  "Dartford",
  "Darton",
  "Darwen",
  "Datchet",
  "Davenport",
  "Dawlish",
  "Deal",
  "Deansgate",
  "Deansgate G-Mex",
  "Deighton",
  "Delamere",
  "Denby Dale",
  "Denham",
  "Denmark Hill",
  "Deptford",
  "Derby",
  "Derby Road (Ipswich)",
  "Dewsbury",
  "Didcot Parkway",
  "Digby & Sowton",
  "Dinas (Rhondda)",
  "Dinas Powys",
  "Dingle Road",
  "Dinsdale",
  "Dinting",
  "Disley",
  "Diss",
  "Dodworth",
  "Doleham",
  "Doncaster",
  "Dorchester South",
  "Dore & Totley",
  "Dorking",
  "Dorking (Deepdene)",
  "Dorking (Main)",
  "Dorking Deepdene",
  "Dormans",
  "Dorridge",
  "Dove Holes",
  "Dover Priory",
  "Dovercourt",
  "Downham Market",
  "Drayton Park",
  "Drem",
  "Driffield",
  "Drigg",
  "Droitwich Spa",
  "Dronfield",
  "Drumchapel",
  "Drumgelloch",
  "Drumry",
  "Duddeston",
  "Dudley Port",
  "Duffield",
  "Duke Street",
  "Dullingham",
  "Dumbarton Central",
  "Dumbarton East",
  "Dumbreck",
  "Dumfries",
  "Dumpton Park",
  "Dunbar",
  "Dunblane",
  "Dundee",
  "Dunfermline Queen Margaret",
  "Dunfermline Town",
  "Dunlop",
  "Dunton Green",
  "Durham",
  "Durrington",
  "Durrington-on-Sea",
  "Dyce",
  "Ealing Broadway",
  "Earlestown",
  "Earley",
  "Earlsfield",
  "Earlswood (Surrey)",
  "Earlswood (West Midlands)",
  "East Croydon",
  "East Didsbury",
  "East Dulwich",
  "East Farleigh",
  "East Garforth",
  "East Grinstead",
  "East Kilbride",
  "East Malling",
  "East Midlands Parkway",
  "East Tilbury",
  "East Worthing",
  "Eastbourne",
  "Eastbrook",
  "Easterhouse",
  "Eastleigh",
  "Ebbsfleet International",
  "Ebbw Vale Parkway",
  "Ebbw Vale Town",
  "Eccles (Manchester)",
  "Eccleston Park",
  "Edale",
  "Edb Apt Bus/Tram",
  "Eden Park",
  "Edenbridge",
  "Edenbridge (Kent)",
  "Edenbridge Town",
  "Edge Hill",
  "Edinburgh",
  "Edinburgh (Waverley)",
  "Edinburgh Airport (By Bus or Tram)",
  "Edinburgh Airport Bus/Tram",
  "Edinburgh Gateway",
  "Edinburgh Park",
  "Edmonton Green",
  "Effingham Junction",
  "Egham",
  "Egton",
  "Elephant & Castle",
  "Elgin",
  "Elmers End",
  "Elmstead Woods",
  "Elmswell",
  "Elsecar",
  "Elsenham (Essex)",
  "Elsenham Essex",
  "Elstree & Borehamwood",
  "Eltham",
  "Ely",
  "Emerson Park",
  "Emsworth",
  "Energlyn & Churchill Park",
  "Enfield Chase",
  "Enfield Lock",
  "Enfield Town",
  "Entwistle",
  "Epsom",
  "Epsom (Surrey)",
  "Epsom Downs",
  "Erdington",
  "Eridge",
  "Erith",
  "Esher",
  "Eskbank",
  "Etchingham",
  "Euxton Balshaw Lane",
  "Evesham",
  "Ewell East",
  "Ewell West",
  "Exeter Central",
  "Exeter St David's",
  "Exeter St Davids",
  "Exeter St Thomas",
  "Exhibition Centre (Glasgow)",
  "Exmouth",
  "Eynsford",
  "Fairfield",
  "Fairwater",
  "Falconwood",
  "Falkirk Grahamston",
  "Falkirk High",
  "Falmer",
  "Fareham",
  "Farnborough (Main)",
  "Farnborough Main",
  "Farnborough North",
  "Farncombe",
  "Farnham",
  "Farningham Road",
  "Farnworth",
  "Farringdon",
  "Faversham",
  "Faygate",
  "Featherstone",
  "Felixstowe",
  "Feltham",
  "Feniton",
  "Fenny Stratford",
  "Fernhill",
  "Ferriby",
  "Filey",
  "Filton Abbey Wood",
  "Filton Abbeywood",
  "Finchley Road & Frognal",
  "Finsbury Park",
  "Fishbourne (Sussex)",
  "Fishersgate",
  "Fishguard & Goodwick",
  "Fitzwilliam",
  "Five Ways",
  "Fleet",
  "Flimby",
  "Flint",
  "Flitwick",
  "Flixton",
  "Folkestone Central",
  "Folkestone West",
  "Ford",
  "Forest Gate",
  "Forest Hill",
  "Forres",
  "Fort William",
  "Four Oaks",
  "Foxfield",
  "Foxton",
  "Frant",
  "Fratton",
  "Frimley",
  "Frinton-on-Sea",
  "Frizinghall",
  "Frodsham",
  "Frome",
  "Fulwell",
  "Furness Vale",
  "Gainsborough Central",
  "Galashiels",
  "Garforth",
  "Garrowhill",
  "Garston (Hertfordshire)",
  "Garston (Herts)",
  "Garswood",
  "Gartcosh",
  "Garth (Bridgend)",
  "Garth (Mid Glamorgan)",
  "Gathurst",
  "Gatley",
  "Gatwick Airport",
  "Gerrards Cross",
  "Gidea Park",
  "Giffnock",
  "Gilberdyke",
  "Gilfach Fargoed",
  "Gillingham (Dorset)",
  "Gillingham (Kent)",
  "Gipsy Hill",
  "Glaisdale",
  "Glasgow Airport",
  "Glasgow Central",
  "Glasgow Queen Street",
  "Glasshoughton",
  "Glazebrook",
  "Gleneagles",
  "Glengarnock",
  "Glossop",
  "Gloucester",
  "Glynde",
  "Gobowen",
  "Godalming",
  "Godley",
  "Godstone",
  "Goldthorpe",
  "Goodmayes",
  "Goole",
  "Goostrey",
  "Gordon Hill",
  "Gorebridge",
  "Goring & Streatley",
  "Goring by Sea",
  "Goring-by-Sea",
  "Gorton",
  "Gospel Oak",
  "Gourock",
  "Gowerton",
  "Grange Over Sands",
  "Grange Park",
  "Grange-over-Sands",
  "Grangetown (Cardiff)",
  "Grantham",
  "Grateley",
  "Gravelly Hill",
  "Gravesend",
  "Grays",
  "Great Ayton",
  "Great Bentley",
  "Great Chesterford",
  "Great Malvern",
  "Great Missenden",
  "Great Yarmouth",
  "Green Road",
  "Greenbank",
  "Greenfaulds",
  "Greenfield",
  "Greenhithe",
  "Greenhithe for Bluewater",
  "Greenock Central",
  "Greenwich",
  "Grimsby Town",
  "Grindleford",
  "Grosmont",
  "Grove Park",
  "Guide Bridge",
  "Guildford",
  "Guiseley",
  "Gunnersbury",
  "Gunton",
  "Gypsy Lane",
  "HARWICH TOWN",
  "Hackbridge",
  "Hackney Central",
  "Hackney Downs",
  "Hackney Wick",
  "Haddenham & T P'way",
  "Haddenham & Thame Parkway",
  "Haddiscoe",
  "Hadfield",
  "Hadley Wood",
  "Hag Fold",
  "Haggerston",
  "Hagley",
  "Hairmyres",
  "Hale",
  "Hale (Manchester)",
  "Halesworth",
  "Halewood",
  "Halifax",
  "Hall Green",
  "Halling",
  "Haltwhistle",
  "Ham Street",
  "Hamble",
  "Hamilton Central",
  "Hammerton",
  "Hampden Park (Sussex)",
  "Hampstead Heath",
  "Hampton (London)",
  "Hampton Court",
  "Hampton Wick",
  "Hampton-in-Arden",
  "Hamstead (Birmingham)",
  "Hamworthy",
  "Hanborough",
  "Handforth",
  "Hanwell",
  "Hapton",
  "Harlesden",
  "Harlington (Bedfordshire)",
  "Harlington (Beds)",
  "Harlow Mill",
  "Harlow Town",
  "Harold Wood",
  "Harpenden",
  "Harrietsham",
  "Harringay",
  "Harringay Green Lanes",
  "Harrington",
  "Harrogate",
  "Harrow & Wealdstone",
  "Hartford",
  "Hartford (Cheshire)",
  "Hartlebury",
  "Hartlepool",
  "Harwich International",
  "Harwich Town",
  "Haslemere",
  "Hassocks",
  "Hastings",
  "Hatch End",
  "Hatfield & Stainforth",
  "Hatfield (Herts)",
  "Hatfield Peverel",
  "Hathersage",
  "Hattersley",
  "Havant",
  "Haverfordwest",
  "Hawkhead",
  "Haydon Bridge",
  "Haydons Road",
  "Hayes & Harlington",
  "Hayes (Kent)",
  "Haymarket",
  "Haywards Heath",
  "Hazel Grove",
  "Headcorn",
  "Headingley",
  "Headstone Lane",
  "Heald Green",
  "Heath High Level",
  "Heath Low Level",
  "Heathrow Term 4",
  "Heathrow Term123",
  "Heathrow Terminal 1 Bus",
  "Heathrow Terminal 4 (Rail Station Only)",
  "Heathrow Terminal 5",
  "Heathrow Terminal 5 (Rail Station Only)",
  "Heathrow Terminals 2 & 3 (Rail Station only)",
  "Heaton Chapel",
  "Hebden Bridge",
  "Hedge End",
  "Hednesford",
  "Heighington",
  "Helensburgh Central",
  "Helsby",
  "Hemel Hempstead",
  "Hendon",
  "Hengoed",
  "Henley in Arden",
  "Henley on Thames",
  "Henley-in-Arden",
  "Henley-on-Thames",
  "Hereford",
  "Herne Bay",
  "Herne Hill",
  "Hersham",
  "Hertford East",
  "Hertford North",
  "Hessle",
  "Hever",
  "Heworth",
  "Hexham",
  "Heysham Port",
  "High Brooms",
  "High Wycombe",
  "Higham",
  "Higham (Kent)",
  "Highams Park",
  "Hildenborough",
  "Hillfoot",
  "Hillington West",
  "Hilsea",
  "Hinchley Wood",
  "Hindley",
  "Hinton Admiral",
  "Hitchin",
  "Hither Green",
  "Hockley",
  "Hollingbourne",
  "Holmes Chapel",
  "Holmwood",
  "Holmwood (Surrey)",
  "Holyhead",
  "Holytown",
  "Homerton",
  "Honeybourne",
  "Honiton",
  "Honley",
  "Honor Oak Park",
  "Hook",
  "Hope (Derbyshire)",
  "Horden",
  "Horley",
  "Hornbeam Park",
  "Hornsey",
  "Horsforth",
  "Horsham",
  "Horsley",
  "Horwich Parkway",
  "Hough Green",
  "Hounslow",
  "Hove",
  "Hoveton & Wroxham",
  "Howden",
  "Hoxton",
  "Hucknall",
  "Huddersfield",
  "Hull",
  "Huncoat",
  "Hungerford",
  "Hunmanby",
  "Huntingdon",
  "Huntly",
  "Hurst Green",
  "Huyton",
  "Hyde Central",
  "Hyde North",
  "Hyndland",
  "Hythe (Essex)",
  "Ifield",
  "Ilford",
  "Ilkeston",
  "Ilkley",
  "Imperial Wharf",
  "Ince (Manchester)",
  "Ingatestone",
  "Insch",
  "Inverkeithing",
  "Inverness",
  "Inverurie",
  "Ipswich",
  "Irlam",
  "Irvine",
  "Isleworth",
  "Islip",
  "Jewellery Quarter",
  "Johnstone (Strathclyde)",
  "Jordanhill",
  "Kearsley (Manchester)",
  "Kearsney (Kent)",
  "Keighley",
  "Keith",
  "Kelvedon",
  "Kemble",
  "Kempston Hardwick",
  "Kempton Park",
  "Kemsing",
  "Kemsley",
  "Kendal",
  "Kenilworth",
  "Kenley",
  "Kennett",
  "Kensal Green",
  "Kensal Rise",
  "Kensington Olympia",
  "Kent House",
  "Kentish Town West",
  "Kenton",
  "Kents Bank",
  "Kettering",
  "Kew Bridge",
  "Kew Gardens",
  "Keynsham",
  "Kidbrooke",
  "Kidderminster",
  "Kidsgrove",
  "Kilburn High Road",
  "Kilmarnock",
  "Kilmaurs",
  "Kilwinning",
  "Kingham",
  "Kings Langley",
  "Kings Lynn",
  "Kings Norton",
  "Kings Park",
  "Kings Sutton",
  "Kingston",
  "Kingswood",
  "Kintore",
  "Kirby Cross",
  "Kirk Sandall",
  "Kirkby Stephen",
  "Kirkby in Ashfield",
  "Kirkby in Furness",
  "Kirkby-in-Ashfield",
  "Kirkby-in-Furness",
  "Kirkcaldy",
  "Kirkham & Wesham",
  "Kirkstall Forge",
  "Kiveton Bridge",
  "Kiveton Park",
  "Knaresborough",
  "Knebworth",
  "Knockholt",
  "Knottingley",
  "Knutsford",
  "Ladywell",
  "Laindon",
  "Lanark",
  "Lancaster",
  "Lancing",
  "Landywood",
  "Langho",
  "Langley (Berks)",
  "Langley Green",
  "Langside",
  "Langwith-Whaley Thorns",
  "Larbert",
  "Largs",
  "Larkhall",
  "Laurencekirk",
  "Layton (Lancs)",
  "Lea Bridge",
  "Lea Green",
  "Lea Hall",
  "Leagrave",
  "Lealholm",
  "Leamington Spa",
  "Leatherhead",
  "Ledbury",
  "Lee (London)",
  "Leeds",
  "Leicester",
  "Leigh (Kent)",
  "Leigh-on-Sea",
  "Leighton Buzzard",
  "Lenham",
  "Lenzie",
  "Leominster",
  "Letchworth Garden City",
  "Leuchars",
  "Levenshulme",
  "Lewes",
  "Lewisham",
  "Leyland",
  "Leyton Midland Road",
  "Leytonstone High Road",
  "Lichfield City",
  "Lichfield Trent Valley",
  "Lidlington",
  "Limehouse",
  "Limehouse (c2c)",
  "Lincoln",
  "Lingfield",
  "Lingwood",
  "Linlithgow",
  "Liphook",
  "Liskeard",
  "Liss",
  "Lisvane & Thornhill",
  "Littleborough",
  "Littlehampton",
  "Littlehaven",
  "Littleport",
  "Liverpool Lime St",
  "Liverpool Lime Street",
  "Liverpool South Parkway",
  "Livingston North",
  "Livingston South",
  "Llanbradach",
  "Llandaf",
  "Llandudno",
  "Llandudno Junction",
  "Llanelli",
  "Llanharan",
  "Llanhilleth",
  "Llanishen",
  "Llantwit Major",
  "Llwynypia",
  "Lochwinnoch",
  "Lockwood",
  "London Blackfriars",
  "London Bridge",
  "London Cannon Street",
  "London Charing Cross",
  "London Euston",
  "London Fenchurch Street",
  "London Fields",
  "London Kings Cross",
  "London Liverpool Street",
  "London Marylebone",
  "London Paddington",
  "London Road (Brighton)",
  "London Road (Guildford)",
  "London St Pancras",
  "London St Pancras International",
  "London Victoria",
  "London Waterloo",
  "London Waterloo East",
  "Long Buckby",
  "Long Eaton",
  "Longbeck",
  "Longbridge",
  "Longfield",
  "Longniddry",
  "Lostock",
  "Lostock Hall",
  "Loughborough",
  "Loughborough Junction",
  "Low Moor",
  "Lower Sydenham",
  "Lowestoft",
  "Ludlow",
  "Luton",
  "Luton Airport",
  "Luton Airport Bus",
  "Luton Airport Parkway",
  "Lydney",
  "Lye (West Midlands)",
  "Lymington Pier",
  "Lymington Town",
  "Lytham",
  "Macclesfield",
  "Maesteg",
  "Maesteg (Ewenny Road)",
  "Maidenhead",
  "Maidstone Barracks",
  "Maidstone East",
  "Maidstone West",
  "Malden Manor",
  "Malton",
  "Malvern Link",
  "Manchester Airport",
  "Manchester Oxford Road",
  "Manchester Piccadilly",
  "Manchester Victoria",
  "Manea",
  "Manningtree",
  "Manor Park",
  "Manors",
  "Mansfield Woodhouse",
  "March",
  "Marden (Kent)",
  "Margate",
  "Market Harborough",
  "Markinch",
  "Marks Tey",
  "Marlow",
  "Marple",
  "Marsden (Yorks)",
  "Marsden (Yorkshire)",
  "Marske",
  "Marston Green",
  "Martin Mill",
  "Martins Heron",
  "Marton",
  "Maryland",
  "Maryport",
  "Matlock",
  "Matlock Bath",
  "Mauldeth Road",
  "Maxwell Park",
  "Maze Hill",
  "Meadowhall",
  "Meldreth",
  "Melksham",
  "Melton (Suffolk)",
  "Melton Mowbray",
  "Menston",
  "Meols Cop",
  "Meopham",
  "Meridian Water",
  "Merstham",
  "Merthyr Tydfil",
  "Metrocentre",
  "Mexborough",
  "Micheldever",
  "Micklefield",
  "Middlesbrough",
  "Milford (Surrey)",
  "Mill Hill (Lancs)",
  "Mill Hill Broadway",
  "Millbrook (Bedfordshire)",
  "Millbrook (Beds)",
  "Milliken Park",
  "Millom",
  "Mills Hill (Manchester)",
  "Milngavie",
  "Milton Keynes Central",
  "Minster",
  "Mirfield",
  "Mistley",
  "Mitcham Eastfields",
  "Mitcham Junction",
  "Montrose",
  "Moorside",
  "Moorthorpe",
  "Morden South",
  "Morecambe",
  "Moreton (Dorset)",
  "Moreton in Marsh",
  "Moreton-in-Marsh",
  "Morley",
  "Morpeth",
  "Mortlake",
  "Moses Gate",
  "Mossley Hill",
  "Mosspark",
  "Motherwell",
  "Motspur Park",
  "Mottingham",
  "Mouldsworth",
  "Moulsecoomb",
  "Mount Florida",
  "Mountain Ash",
  "Muirend",
  "Musselburgh",
  "Mytholmroyd",
  "Nafferton",
  "Nailsea & Backwell",
  "Nairn",
  "Nantwich",
  "Navigation Road",
  "Neath",
  "Needham Market",
  "Neilston",
  "Nelson",
  "Netley",
  "New Barnet",
  "New Beckenham",
  "New Cross",
  "New Cross Gate",
  "New Eltham",
  "New Hythe",
  "New Lane",
  "New Malden",
  "New Mills Central",
  "New Mills Newtown",
  "New Milton",
  "New Pudsey",
  "New Southgate",
  "Newark Castle",
  "Newark Northgate",
  "Newbridge",
  "Newbury",
  "Newcastle",
  "Newcourt",
  "Newcraighall",
  "Newhaven Harbour",
  "Newhaven Town",
  "Newington",
  "Newmarket",
  "Newport (Essex)",
  "Newport (South Wales)",
  "Newstead",
  "Newton (Lanark)",
  "Newton (Lanarkshire)",
  "Newton Abbot",
  "Newton Aycliffe",
  "Newton Le Willows",
  "Newton for Hyde",
  "Newton-le-Willows",
  "Newtongrange",
  "Newtown (Powys)",
  "Ninian Park",
  "Norbiton",
  "Norbury",
  "Normans Bay",
  "Normanton",
  "North Berwick",
  "North Camp",
  "North Dulwich",
  "North Fambridge",
  "North Queensferry",
  "North Road",
  "North Road (Darlington)",
  "North Sheen",
  "North Walsham",
  "North Wembley",
  "Northallerton",
  "Northampton",
  "Northfield",
  "Northfleet",
  "Northolt Park",
  "Northumberland Park",
  "Northumberland Park (London)",
  "Northwich",
  "Norwich",
  "Norwood Junction",
  "Nottingham",
  "Nuneaton",
  "Nunhead",
  "Nunthorpe",
  "Nutbourne",
  "Nutfield",
  "Oakengates",
  "Oakleigh Park",
  "Ockendon",
  "Ockley",
  "Old Hill",
  "Oldfield Park",
  "Olton",
  "Ore",
  "Orpington",
  "Orrell",
  "Otford",
  "Oulton Broad North",
  "Oulton Broad South",
  "Outwood",
  "Overton",
  "Oxenholme Lake District",
  "Oxford",
  "Oxford Parkway",
  "Oxshott",
  "Oxted",
  "Paddock Wood",
  "Padgate",
  "Paignton",
  "Paisley Canal",
  "Paisley Gilmour Street",
  "Palmers Green",
  "Pangbourne",
  "Pannal",
  "Par",
  "Parbold",
  "Park Street",
  "Parkstone (Dorset)",
  "Parton",
  "Patricroft",
  "Patterton",
  "Peckham Rye",
  "Pemberton",
  "Pembrey & Burry Port",
  "Pembroke Dock",
  "Penarth",
  "Pencoed",
  "Pengam",
  "Penge East",
  "Penge West",
  "Penistone",
  "Penkridge",
  "Penrhiwceiber",
  "Penrith",
  "Penrith (North Lakes)",
  "Penshurst",
  "Pentre Bach",
  "Pentre-Bach",
  "Penzance",
  "Perry Barr",
  "Pershore",
  "Perth",
  "Peterborough",
  "Petersfield",
  "Petts Wood",
  "Pevensey & Westham",
  "Pevensey Bay",
  "Pewsey",
  "Pinhoe",
  "Pitsea",
  "Pleasington",
  "Pluckley",
  "Plumley",
  "Plumpton",
  "Plumstead",
  "Plymouth",
  "Pokesdown",
  "Polegate",
  "Pollokshaws East",
  "Pollokshaws West",
  "Pollokshields East",
  "Pollokshields West",
  "Polmont",
  "Ponders End",
  "Pontefract Monkhill",
  "Pontefract Tanshelf",
  "Pontlottyn",
  "Pontyclun",
  "Pontypool & New Inn",
  "Pontypridd",
  "Poole",
  "Poppleton",
  "Port Glasgow",
  "Port Talbot Parkway",
  "Portchester",
  "Porth",
  "Portlethen",
  "Portslade",
  "Portsmouth & Southsea",
  "Portsmouth Harbour",
  "Possilpark",
  "Possilpark & Parkhouse",
  "Potters Bar",
  "Poulton Le Fylde",
  "Poulton-le-Fylde",
  "Poynton",
  "Prescot",
  "Prestatyn",
  "Prestbury",
  "Preston (Lancs)",
  "Preston Park",
  "Prestonpans",
  "Prestwick (Strathclyde)",
  "Prestwick Town",
  "Priesthill & Darnley",
  "Princes Risborough",
  "Prittlewell",
  "Prudhoe",
  "Pulborough",
  "Purfleet",
  "Purley",
  "Purley Oaks",
  "Putney",
  "Pye Corner",
  "Pyle",
  "Queenborough",
  "Queens Park (Glasgow)",
  "Queens Park (London)",
  "Queens Road (Peckham)",
  "Queens Road Peckham",
  "Queenstown Road",
  "Queenstown Road (Battersea)",
  "Radlett",
  "Radley",
  "Radyr",
  "Rainford",
  "Rainham (Essex)",
  "Rainham (Kent)",
  "Rainhill",
  "Ramsgate",
  "Ramsgreave & Wilpshire",
  "Ravenglass for Eskdale",
  "Ravensbourne",
  "Rayleigh",
  "Raynes Park",
  "Reading",
  "Reading West",
  "Rectory Road",
  "Red Funnel T2",
  "Redcar Central",
  "Redcar East",
  "Reddish North",
  "Redditch",
  "Redhill",
  "Redruth",
  "Reedham (London)",
  "Reedham (Norfolk)",
  "Reigate",
  "Retford",
  "Rhiwbina",
  "Rhoose (for Cardiff Airport)",
  "Rhoose Cardiff International Airport",
  "Rhyl",
  "Rhymney",
  "Richmond (London)",
  "Riddlesdown",
  "Ridgmont",
  "Riding Mill",
  "Risca & Pontymister",
  "Rishton",
  "Robertsbridge",
  "Robroyston",
  "Roby",
  "Rochdale",
  "Rochester",
  "Rochford",
  "Rogerstone",
  "Romford",
  "Romiley",
  "Romsey",
  "Roose",
  "Rose Grove",
  "Rose Hill",
  "Rose Hill Marple",
  "Rosyth",
  "Rotherham Central",
  "Rotherhithe",
  "Roughton Road",
  "Rowlands Castle",
  "Rowley Regis",
  "Roydon",
  "Roydon Essex",
  "Royston",
  "Royston (Herts)",
  "Ruabon",
  "Rufford",
  "Rugby",
  "Rugeley (Trent Valley)",
  "Rugeley Town",
  "Rugeley Trent Valley",
  "Runcorn",
  "Runcorn East",
  "Ruswarp",
  "Rutherglen",
  "Ryde Esplanade",
  "Ryde St Johns Road",
  "Ryder Brow",
  "Rye (Sussex)",
  "Rye House",
  "Salford Central",
  "Salford Crescent",
  "Salfords (Surrey)",
  "Salisbury",
  "Saltaire",
  "Saltburn",
  "Saltmarshe",
  "Sandal & Agbrigg",
  "Sandbach",
  "Sanderstead",
  "Sandling",
  "Sandown",
  "Sandwell & Dudley",
  "Sandwich",
  "Sandy",
  "Sankey",
  "Sankey For Penketh",
  "Sarn",
  "Saunderton",
  "Sawbridgeworth",
  "Saxmundham",
  "Scarborough",
  "Scotstounhill",
  "Scunthorpe",
  "Seaford (Sussex)",
  "Seaford Sussex",
  "Seaham",
  "Seamer",
  "Seascale",
  "Seaton Carew",
  "Seer Green & Jordans",
  "Selby",
  "Selhurst",
  "Sellafield",
  "Selling",
  "Selly Oak",
  "Settle",
  "Seven Kings",
  "Seven Sisters",
  "Sevenoaks",
  "Severn Tunnel Junction",
  "Shadwell",
  "Shalford (Surrey)",
  "Shanklin",
  "Shawfair",
  "Shawford",
  "Shawlands",
  "Sheerness on Sea",
  "Sheerness-on-Sea",
  "Sheffield",
  "Shelford (Cambs)",
  "Shenfield",
  "Shenstone",
  "Shepherd's Bush",
  "Shepherds Bush",
  "Shepherds Well",
  "Shepley",
  "Shepperton",
  "Shepreth",
  "Sherborne",
  "Sherburn in Elmet",
  "Sherburn-in-Elmet",
  "Sheringham",
  "Shettleston",
  "Shifnal",
  "Shildon",
  "Shipley (Yorks)",
  "Shipley (Yorkshire)",
  "Shirebrook",
  "Shireoaks",
  "Shirley",
  "Shirley (West Midlands)",
  "Shoeburyness",
  "Sholing",
  "Shoreditch High Street",
  "Shoreham (Kent)",
  "Shoreham by Sea",
  "Shoreham-by-Sea",
  "Shortlands",
  "Shotton",
  "Shotts",
  "Shrewsbury",
  "Sidcup",
  "Sileby",
  "Silecroft",
  "Silkstone Common",
  "Silver Street",
  "Silverdale",
  "Singer",
  "Sittingbourne",
  "Skegness",
  "Skipton",
  "Slade Green",
  "Slaithwaite",
  "Sleaford",
  "Sleights",
  "Slough",
  "Small Heath",
  "Smethwick Galton Bridge",
  "Smethwick Rolfe Street",
  "Smithy Bridge",
  "Snodland",
  "Snowdown",
  "Soham",
  "Sole Street",
  "Solihull",
  "South Acton",
  "South Bermondsey",
  "South Croydon",
  "South Elmsall",
  "South Gyle",
  "South Hampstead",
  "South Kenton",
  "South Merton",
  "South Milford",
  "South Ruislip",
  "South Tottenham",
  "South Woodham Ferrers",
  "Southall",
  "Southampton Airport Parkway",
  "Southampton Central",
  "Southbourne",
  "Southbury",
  "Southease",
  "Southend Airport",
  "Southend Central",
  "Southend East",
  "Southend Victoria",
  "Southminster",
  "Southwick",
  "Sowerby Bridge",
  "Spalding",
  "Spring Road",
  "Springburn",
  "Squires Gate",
  "St Albans Abbey",
  "St Albans City",
  "St Annes-on-the-Sea",
  "St Austell",
  "St Bees",
  "St Denys",
  "St Erth",
  "St Helens Central",
  "St Helens Junction",
  "St Helier",
  "St Helier (London)",
  "St Ives (Cornwall)",
  "St James Street (Walthamstow)",
  "St Johns",
  "St Johns (London)",
  "St Leonards Warrior Square",
  "St Margarets (Herts)",
  "St Margarets (London)",
  "St Mary Cray",
  "St Neots",
  "Stafford",
  "Staines",
  "Stalybridge",
  "Stamford (Lincs)",
  "Stamford Hill",
  "Stanford-le-Hope",
  "Stansted Airport",
  "Stansted Mountfitchet",
  "Staplehurst",
  "Starbeck",
  "Staveley",
  "Staveley (Cumbria)",
  "Stechford",
  "Steeton & Silsden",
  "Stepps",
  "Stevenage",
  "Stewartby",
  "Stewarton",
  "Stirling",
  "Stockport",
  "Stocksfield",
  "Stocksmoor",
  "Stockton",
  "Stoke Mandeville",
  "Stoke Newington",
  "Stoke on Trent",
  "Stoke-on-Trent",
  "Stone (Staffs)",
  "Stone Crossing",
  "Stonebridge Park",
  "Stonegate",
  "Stonehaven",
  "Stonehouse",
  "Stoneleigh",
  "Stourbridge Junction",
  "Stourbridge Town",
  "Stow",
  "Stowmarket",
  "Stratford (London)",
  "Stratford International",
  "Stratford upon Avon",
  "Stratford-upon-Avon",
  "Stratford-upon-Avon Parkway",
  "Strawberry Hill",
  "Streatham",
  "Streatham (Greater London)",
  "Streatham Common",
  "Streatham Hill",
  "Streethouse",
  "Strines",
  "Strood (Kent)",
  "Stroud (Glos)",
  "Stroud (Gloucs)",
  "Sturry",
  "Styal",
  "Sudbury (Suffolk)",
  "Summerston",
  "Sunbury",
  "Sunderland",
  "Sundridge Park",
  "Sunningdale",
  "Sunnymeads",
  "Surbiton",
  "Surrey Quays",
  "Sutton (London)",
  "Sutton Coldfield",
  "Sutton Common",
  "Sutton Parkway",
  "Swale",
  "Swanley",
  "Swanscombe",
  "Swansea",
  "Swanwick",
  "Sway",
  "Swaythling",
  "Swindon (Wilts)",
  "Swinton (Manchester)",
  "Swinton (South Yorks)",
  "Sydenham (London)",
  "Sydenham Hill",
  "Syon Lane",
  "Syston",
  "Tadworth",
  "Taffs Well",
  "Tame Bridge Parkway",
  "Tamworth",
  "Taplow",
  "Tattenham Corner",
  "Taunton",
  "Teddington",
  "Teignmouth",
  "Telford",
  "Telford Central",
  "Templecombe",
  "Tenby",
  "Teynham",
  "Thames Ditton",
  "Thatcham",
  "Thatto Heath",
  "The Hawthorns",
  "Theale",
  "Theobalds Grove",
  "Thetford",
  "Thirsk",
  "Thornaby",
  "Thorne North",
  "Thornliebank",
  "Thornton Heath",
  "Thorpe Bay",
  "Thorpe-le-Soken",
  "Three Bridges",
  "Three Oaks",
  "Thurnscoe",
  "Thurston",
  "Tilbury Town",
  "Tile Hill",
  "Tilehurst",
  "Tipton",
  "Tir Phil",
  "Tir-Phil",
  "Tisbury",
  "Tiverton Parkway",
  "Todmorden",
  "Tolworth",
  "Ton Pentre",
  "Tonbridge",
  "Tondu",
  "Tonypandy",
  "Tooting",
  "Topsham",
  "Torquay",
  "Totnes",
  "Tottenham Hale",
  "Totton",
  "Trefforest",
  "Trefforest Estate",
  "Trehafod",
  "Treherbert",
  "Treorchy",
  "Trimley",
  "Tring",
  "Troon",
  "Trowbridge",
  "Truro",
  "Tulse Hill",
  "Tunbridge Wells",
  "Turkey Street",
  "Tweedbank",
  "Twickenham",
  "Twyford",
  "Ty Glas",
  "Tyseley",
  "Uckfield",
  "Uddingston",
  "Ulleskelf",
  "Ulverston",
  "University (Birmingham)",
  "Uphall",
  "Upholland",
  "Upminster",
  "Upper Halliford",
  "Upper Holloway",
  "Upper Warlingham",
  "Upwey",
  "Urmston",
  "Vauxhall",
  "Virginia Water",
  "Waddon",
  "Wadhurst",
  "Wakefield Kirkgate",
  "Wakefield Westgate",
  "Walkden",
  "Wallington",
  "Wallyford",
  "Walmer",
  "Walsall",
  "Walsden",
  "Waltham Cross",
  "Walthamstow Central",
  "Walthamstow Queens Road",
  "Walton on Thames",
  "Walton-on-Thames",
  "Walton-on-the-Naze",
  "Wanborough",
  "Wandsworth Common",
  "Wandsworth Road",
  "Wandsworth Town",
  "Wanstead Park",
  "Wapping",
  "Warblington",
  "Ware (Herts)",
  "Wareham (Dorset)",
  "Warminster",
  "Warnham",
  "Warrington Bank Quay",
  "Warrington Central",
  "Warrington West",
  "Warwick",
  "Warwick Parkway",
  "Water Orton",
  "Waterbeach",
  "Wateringbury",
  "Watford High Street",
  "Watford Junction",
  "Watford North",
  "Watlington",
  "Watton-at-Stone",
  "Waun-Gron Park",
  "Wavertree Technology Park",
  "Weeley",
  "Weeton",
  "Welham Green",
  "Welling",
  "Wellingborough",
  "Wellington (Shropshire)",
  "Welshpool",
  "Welwyn Garden City",
  "Welwyn North",
  "Wem",
  "Wembley Central",
  "Wembley Stadium",
  "Wendover",
  "West Allerton",
  "West Byfleet",
  "West Calder",
  "West Croydon",
  "West Drayton",
  "West Dulwich",
  "West Ealing",
  "West Hampstead",
  "West Hampstead Thameslink",
  "West Horndon",
  "West Kilbride",
  "West Malling",
  "West Norwood",
  "West Ruislip",
  "West Runton",
  "West St Leonards",
  "West Sutton",
  "West Wickham",
  "West Worthing",
  "Westbury",
  "Westcliff",
  "Westcombe Park",
  "Westenhanger",
  "Westerfield",
  "Westerton",
  "Westgate on Sea",
  "Westgate-on-Sea",
  "Westhoughton",
  "Weston Super Mare",
  "Weston-super-Mare",
  "Wetheral",
  "Weybridge",
  "Weymouth",
  "Whaley Bridge",
  "Whatstandwell",
  "Whifflet",
  "Whimple",
  "Whiston",
  "Whitby",
  "Whitchurch (Hampshire)",
  "Whitchurch (Hants)",
  "Whitchurch (Shropshire)",
  "White Hart Lane",
  "White Notley",
  "Whitecraigs",
  "Whitehaven",
  "Whitlocks End",
  "Whitstable",
  "Whittlesea",
  "Whittlesford Parkway",
  "Whitton (London)",
  "Whitwell",
  "Whitwell (Derbyshire)",
  "Whyteleafe",
  "Whyteleafe South",
  "Wickford",
  "Wickham Market",
  "Widdrington",
  "Widnes",
  "Widney Manor",
  "Wigan North Western",
  "Wigan Wallgate",
  "Wigton",
  "Wildmill",
  "Willesden Junction",
  "Williamwood",
  "Wilmcote",
  "Wilmslow",
  "Wilnecote (Staffs)",
  "Wimbledon",
  "Wimbledon Chase",
  "Winchelsea",
  "Winchester",
  "Winchfield",
  "Winchmore Hill",
  "Windermere",
  "Windsor & Eton Central",
  "Windsor & Eton Riverside",
  "Winnersh",
  "Winnersh Triangle",
  "Winsford",
  "Wishaw",
  "Witham",
  "Witley",
  "Witton (West Midlands)",
  "Wivelsfield",
  "Wivenhoe",
  "Woburn Sands",
  "Woking",
  "Wokingham",
  "Woldingham",
  "Wolverhampton",
  "Wolverton",
  "Wombwell",
  "Wood Street",
  "Woodbridge",
  "Woodgrange Park",
  "Woodhouse",
  "Woodlesford",
  "Woodley",
  "Woodmansterne",
  "Woodsmoor",
  "Wool",
  "Woolston",
  "Woolwich Arsenal",
  "Woolwich Dockyard",
  "Worcester Foregate Street",
  "Worcester Park",
  "Worcester Shrub Hill",
  "Worcestershire Parkway",
  "Workington",
  "Worksop",
  "Worle",
  "Worplesdon",
  "Worstead",
  "Worthing",
  "Wrabness",
  "Wraysbury",
  "Wrexham Central",
  "Wrexham General",
  "Wye",
  "Wylam",
  "Wylde Green",
  "Wymondham",
  "Wythall",
  "Yalding",
  "Yardley Wood",
  "Yarm",
  "Yate",
  "Yatton",
  "Yeovil Junction",
  "Ynyswen",
  "Yoker",
  "York",
  "Ystrad (Rhondda)",
  "Ystrad Mynach",
  "Ystrad Rhondda",
];
