<ng-template #template>
  <div class="people" [style.gridArea]="gridArea" [class.active]="isActive">
    <span type="button" (click)="activateSuggest()">
      <ng-container *ngIf="people.length > 0; else noPeople">
        <ng-container>
          <div class="passenger-selected-list">
            <span class="passenger-user">
              <span class="avatar" style="position: relative"
                ><ng-container *ngFor="let member of people; let idx = index">
                  <spt-avatar *ngIf="idx < 1" [user]="member.user" [displayUsername]="false"></spt-avatar>
                </ng-container>
                <span class="count-avatar" *ngIf="people.length > 1"
                  ><span>+ {{ people.length - 1 }}</span></span
                >
              </span>
              <span class="passenger-name-list">
                <span *ngFor="let member of people; let idx = index" class="passenger-name"
                  >{{ member.user.username }}<span *ngIf="idx < people.length - 1">,</span>&nbsp;</span
                ></span
              ></span
            >
            <div class="count f-sb">{{ people.length }}</div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #noPeople>
        <p-button
          styleClass="p-button-fifth medium"
          icon="spt-icon-add-traveler"
          label="{{ 'GLOBAL.TRAVELERS' | translate }}"
          type="button"
        ></p-button>
      </ng-template>
    </span>
  </div>

  <div *ngIf="suggestPanelService.active.name === suggestPanelName" class="suggest-panel">
    <button
      type="button"
      class="closeModalButton fas fa-times fa-2x m-l-auto"
      (mousedown)="hideSuggest()"
      *ngIf="commonService.isTablet"
    ></button>
    <spt-select-members
      [membersSelected]="people"
      [membersList]="allMembers"
      [max]="membersMax"
      [modalMembers]="false"
      [itemSize]="75"
      [displayActionButtons]="false"
      [displaySelectedMembers]="4"
      (onChangeSelectedMembers)="onChangeSelectedMembers($event)"
      searchEngine="true"
    ></spt-select-members>
    <div class="triggers submit" *ngIf="commonService.isTablet" (click)="hideSuggest()">
      <p-button label="{{ 'GLOBAL.VALIDATE' | translate }}" type="button"></p-button>
    </div>
  </div>
</ng-template>
