import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { TrainTypes } from "../../../../../travel/train/train";
import { TransferTypes } from "../../../../../travel/transfer/transfer";

@Component({
  selector: "spt-reservations-cancel-transfer",
  templateUrl: "./transfer.component.html",
  styleUrls: ["./transfer.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ReservationsCancelTransferComponent implements OnInit {
  @Input() booking: TransferTypes.Booking;
  @Input() travelers: Array<any>;
  @Input() provider: TrainTypes.Provider;
  @Output() selectCancel: EventEmitter<{ options: Array<number> }> = new EventEmitter();
  selectedTransfers: Array<number>;
  activeTransfers: Array<boolean> = [];

  constructor() {}

  ngOnInit(): void {
    if (this.booking.transfers.length === 1) {
      this.selectedTransfers = [this.booking.transfers[0].transferid];
      this.onSelectTransfers(0);
    }
  }

  onSelectTransfers(index: number): void {
    this.activeTransfers[index] = !this.activeTransfers[index];
    this.selectCancel.emit({ options: this.selectedTransfers });
  }
}
