<div class="content">
  <h2 translate>ACCOUNT.GENERAL.NOTIFICATIONS.TITLE</h2>
  <form class="main form" (ngSubmit)="updateNotification()" [formGroup]="notificationForm">
    <div class="grid grid-nogutter align-items-start">
      <div class="col">
        <div *ngIf="role === 'booker'" class="switch">
          <div>
            <div class="flexCenter">
              <p-inputSwitch inputId="notifyBookingRequest" formControlName="bookrequest"></p-inputSwitch>
              <label for="notifyBookingRequest">{{
                "ACCOUNT.GENERAL.NOTIFICATIONS.MAIL_REQUEST_NOTIFICATION" | translate
              }}</label>
            </div>

            <div *ngIf="bookRequestWarning" class="confirmManagersWarning" translate>
              ACCOUNT.GENERAL.NOTIFICATIONS.MAIL_REQUEST_NOTIFICATION_WARNING
            </div>
          </div>
        </div>
        <div class="switch" *ngIf="role === 'executive' || role === 'booker'">
          <p-inputSwitch inputId="notifyBookingConfirm" formControlName="bookconfirm"></p-inputSwitch>
          <ng-container *ngIf="role !== 'booker'; else confirmManagers">
            <label for="notifyBookingConfirm">{{
              "ACCOUNT.GENERAL.NOTIFICATIONS.MAIL_CONFIRM_NOTIFICATION" | translate
            }}</label>
          </ng-container>

          <ng-template #confirmManagers>
            <label for="notifyBookingConfirm">{{
              "ACCOUNT.GENERAL.NOTIFICATIONS.MAIL_CONFIRM_NOTIFICATION_MANAGER" | translate
            }}</label>
          </ng-template>
        </div>
        <ng-container *ngIf="role === 'accountant' || role === 'executive'">
          <div class="switch">
            <p-inputSwitch inputId="notifyReceipt" formControlName="receipt"></p-inputSwitch>
            <label for="notifyReceipt">{{
              "ACCOUNT.GENERAL.NOTIFICATIONS.MAIL_RECEIPT_NOTIFICATION" | translate
            }}</label>
          </div>
          <div class="switch">
            <p-inputSwitch inputId="notifyMonthly" formControlName="monthly"></p-inputSwitch>
            <label for="notifyMonthly">{{
              "ACCOUNT.GENERAL.NOTIFICATIONS.MAIL_MONTHLY_NOTIFICATION" | translate
            }}</label>
          </div>
        </ng-container>
        <div *ngIf="role === 'executive'" class="switch">
          <div>
            <div class="flexCenter">
              <p-inputSwitch inputId="notifyAll" formControlName="all"></p-inputSwitch>
              <label for="notifyAll">{{ "ACCOUNT.GENERAL.NOTIFICATIONS.MAIL_ALL_NOTIFICATION" | translate }}</label>
            </div>
            <div class="notifyAllWarning" *ngIf="notifyAllWarning">
              <p translate>ACCOUNT.GENERAL.NOTIFICATIONS.MAIL_ALL_OFF_WARNING.TITLE</p>

              <ul>
                <li translate>ACCOUNT.GENERAL.NOTIFICATIONS.MAIL_ALL_OFF_WARNING.BULLET_A</li>
                <li translate>ACCOUNT.GENERAL.NOTIFICATIONS.MAIL_ALL_OFF_WARNING.BULLET_B</li>
                <li translate>ACCOUNT.GENERAL.NOTIFICATIONS.MAIL_ALL_OFF_WARNING.BULLET_C</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <p-button type="submit" label="{{ 'GLOBAL.SAVE' | translate }}" [disabled]="notificationForm.invalid"></p-button>
    </div>
  </form>
</div>
