<div class="review" *ngFor="let review of reviews">
  <div class="author">
    <div class="name">{{ review.author_name }}</div>
    <div class="date">{{ review.time | fromNow }}</div>
    <div class="rating">
      {{ review.rating * 2 }}
      <sup class="scale">/ 10</sup>
    </div>
  </div>
  <div class="commentary">{{ review.text }}</div>
</div>
