<spt-loading-v2 *ngIf="loading"></spt-loading-v2>
<div
  class="error-container app-error text-center"
  *ngIf="criticalAppDeadEnd && commonService.isTabletObservable | async"
>
  <p class="text-center">
    <img class="error-img" src="./assets/svg/search-error.svg" alt="Search error" />
  </p>
  <p class="error-message" translate>SEARCH.ERROR.APP</p>
  <p-button
    class="error-button"
    [label]="'SEARCH.ERROR.BTN' | translate"
    styleClass="ui-button-secondary"
    (onClick)="magicFormula()"
  >
  </p-button>
</div>
<spt-sticky-header
  *ngIf="!!engine && search && headerDisplayed"
  [scrollRef]="engine"
  [childElement]="search?.type === 'hotel' ? '.main' : null"
  class="header {{ search.type }}"
  [class.mobileHide]="selectionSidebarOpen || confirmationSideBarOpen"
>
  <div class="logo">
    <a routerLink="/">
      <!-- <ng-template #logo> -->
      <img src="./assets/svg/supertripper-white.svg" alt="" />
      <!-- </ng-template> -->
    </a>
  </div>

  <ng-container [ngSwitch]="search?.type">
    <ng-container *ngSwitchCase="'car'">
      <img class="location-icon" src="./assets/svg/markup-white.svg" alt="" *ngIf="!commonService.isTablet" />
      <div class="location">
        <div class="fromto">
          <div class="from">{{ search?.data?.pickupAddress?.city }}</div>
          <ng-container
            *ngIf="
              search?.data?.returnAddress && search?.data?.pickupAddress?.city !== search?.data?.returnAddress?.city
            "
          >
            <i class="spt-icon-arrow-circle-right"></i>
            <div class="to">{{ search?.data?.returnAddress?.city }}</div>
          </ng-container>
        </div>
      </div>

      <div class="dates spt-icon-date resume-dates">
        <div class="fromto">
          <div class="from">
            {{ search?.data.pickupDate | localizedDate: "dd MMM" }}
          </div>
          <i class="spt-icon-arrow-circle-right"></i>
          <div class="to">
            {{ search?.data.returnDate | localizedDate: "dd MMM" }}
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'hotel'">
      <div class="place">
        <img src="./assets/svg/markup-white.svg" alt="" *ngIf="!commonService.isTablet" />
        <span class="name">
          <ng-container *ngIf="search?.data.completeAddress?.description">
            {{ search.data.completeAddress.description }}
          </ng-container>
          <ng-container *ngIf="search?.data.completeAddress?.city">
            {{ search.data.completeAddress.city }}
            {{ search.data.completeAddress.street }}
          </ng-container>
          <ng-container *ngIf="search?.data.completeAddress.label">
            {{ search.data.completeAddress.label }}
          </ng-container>
        </span>
      </div>
      <div class="dates spt-icon-date">
        <div class="fromto">
          <div class="from">
            {{ search?.data.datein | localizedDate: "dd MMM" }}
          </div>
          <i class="spt-icon-arrow-circle-right"></i>
          <div class="to">
            {{ search?.data.dateout | localizedDate: "dd MMM" }}
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'flight'">
      <div class="oad">
        <div *ngIf="search.data.trips[0]; let trip" class="origin">
          <i class="spt-icon-flight-departure txt-18 txt-white" *ngIf="!commonService.isTablet"></i>
          <div class="airport">
            <div class="name">{{ trip.departureAirport.name }}</div>
            <div class="iata">{{ trip.departureAirport.iata }}</div>
          </div>
        </div>
        <i class="arrow spt-icon-arrow-circle-right txt-white txt-12"></i>
        <div *ngIf="search.data.trips[0]; let trip" class="destination">
          <i class="spt-icon-flight-arrival txt-18 txt-white" *ngIf="!commonService.isTablet"></i>
          <div class="airport">
            <div class="name">{{ trip.arrivalAirport.name }}</div>
            <div class="iata">{{ trip.arrivalAirport.iata }}</div>
          </div>
        </div>
        <div class="type" translate>
          {{ (commonService.isTabletObservable | async) === true ? "SEARCH.SHORT." : "SEARCH."
          }}{{ search.data.type | uppercase }}
        </div>
      </div>

      <div class="container-dates {{ search.data.type }}">
        <i class="calendar spt-icon-date txt-white"></i>
        <div class="dates">
          <ng-container *ngIf="search.data.trips[0]; let trip">
            <span class="departure">
              {{ trip.departureDate | localizedDate: "dd MMM" }}
            </span>
          </ng-container>
          <ng-container *ngIf="search.data.type !== 'simple'">
            <i class="arrow spt-icon-arrow-circle-right txt-11"></i>
            <ng-container *ngIf="search.data.trips[search.data.trips.length - 1]; let trip">
              <div class="return">
                {{ trip.departureDate | localizedDate: "dd MMM" }}
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'train'">
      <ng-container *ngIf="itemId; else trainSearch">
        <div class="oad">
          <div class="origin">
            <i class="spt-icon-train txt-18 txt-white" *ngIf="!commonService.isTablet"></i>
            <div class="airport">
              <div class="name">
                {{
                  (search.data.exchangeCriteria[0].outwardJourney || search.data.exchangeCriteria[0].inwardJourney)
                    .departureStation.name | titlecase
                }}
              </div>
            </div>
          </div>
          <i class="arrow spt-icon-arrow-circle-right txt-white txt-12"></i>
          <div class="destination">
            <i class="spt-icon-train txt-18 txt-white" *ngIf="!commonService.isTablet"></i>
            <div class="airport">
              <div class="name">
                {{
                  (search.data.exchangeCriteria[0].outwardJourney || search.data.exchangeCriteria[0].inwardJourney)
                    .arrivalStation.name | titlecase
                }}
              </div>
            </div>
          </div>
          <div class="type" translate>
            {{ (commonService.isTabletObservable | async) === true ? "SEARCH.SHORT." : "SEARCH."
            }}{{ search.data.type | uppercase }}
          </div>
        </div>

        <div class="container-dates {{ search.data.type }}">
          <i class="calendar spt-icon-date txt-white"></i>
          <div class="dates">
            <span class="departure">
              {{
                (search.data.exchangeCriteria[0].outwardJourney || search.data.exchangeCriteria[0].inwardJourney).date
                  | localizedDate: "dd MMM"
              }}
            </span>
            <ng-container
              *ngIf="search.data.exchangeCriteria[0]?.outwardJourney && search.data.exchangeCriteria[1]?.inwardJourney"
            >
              <i class="arrow spt-icon-arrow-circle-right txt-11"></i>
              <div class="return">
                {{ search.data.exchangeCriteria[1].inwardJourney.date | localizedDate: "dd MMM" }}
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-template #trainSearch>
        <div class="oad">
          <div class="origin">
            <i class="spt-icon-train txt-18 txt-white" *ngIf="!commonService.isTablet"></i>
            <div class="airport">
              <div class="name">
                {{ search.data.outwardJourney.departureStation.name }}
              </div>
            </div>
          </div>
          <i class="arrow spt-icon-arrow-circle-right txt-white txt-12"></i>
          <div class="destination">
            <i class="spt-icon-train txt-18 txt-white" *ngIf="!commonService.isTablet"></i>
            <div class="airport">
              <div class="name">
                {{ search.data.outwardJourney.arrivalStation.name }}
              </div>
            </div>
          </div>
          <div class="type" translate>
            {{ (commonService.isTabletObservable | async) === true ? "SEARCH.SHORT." : "SEARCH."
            }}{{ search.data.type | uppercase }}
          </div>
        </div>

        <div class="container-dates {{ search.data.type }}">
          <i class="calendar spt-icon-date txt-white"></i>
          <div class="dates">
            <span class="departure">
              {{ search.data.outwardJourney.date | localizedDate: "dd MMM" }}
            </span>
            <ng-container *ngIf="search.data.type === 'Return'">
              <i class="arrow spt-icon-arrow-circle-right txt-11"></i>
              <ng-container *ngIf="search.data.inwardJourney">
                <div class="return">
                  {{ search.data.inwardJourney.date | localizedDate: "dd MMM" }}
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="'transfer'">
      <div class="oad">
        <div class="origin">
          <i class="spt-icon-taxi txt-18 txt-white" *ngIf="!commonService.isTablet"></i>
          <div class="airport">
            <div class="name">
              {{ search.data.pickup.description || search.data.pickup.name }}
            </div>
          </div>
        </div>
        <i class="arrow spt-icon-arrow-circle-right txt-white txt-12"></i>
        <div class="destination">
          <i class="spt-icon-taxi txt-18 txt-white" *ngIf="!commonService.isTablet"></i>
          <div class="airport">
            <div class="name">
              {{ search.data.dropoff.description || search.data.dropoff.name }}
            </div>
          </div>
        </div>
        <div class="type" translate>
          {{ (commonService.isTabletObservable | async) === true ? "SEARCH.SHORT." : "SEARCH."
          }}{{ search.data.type | uppercase }}
        </div>
      </div>

      <div class="container-dates {{ search.data.type }}">
        <i class="calendar spt-icon-date txt-white"></i>
        <div class="dates">
          <span class="departure">
            {{ search.data.travelling | localizedDate: "dd MMM" }}
          </span>
          <ng-container *ngIf="search.data.returning">
            <i class="arrow spt-icon-arrow-circle-right txt-11"></i>
            <div class="return">
              {{ search.data.returning | localizedDate: "dd MMM" }}
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div class="travelers">
    <i class="profile spt-icon-profile-full"></i>
    <span class="travelers-count"><i class="spt-icon-close"></i>{{ search?.data.userIds.length }}</span>
  </div>

  <div class="modify" (mousedown)="modify()" *ngIf="search && search.oldItemId === null">
    <i class="spt-icon-search" *ngIf="commonService.isTabletObservable | async; else searchButton"></i>
    <ng-template #searchButton>
      <p-button
        icon="spt-icon-search"
        iconPos="left"
        styleClass="p-button-primary-white"
        label="{{ 'SEARCH.RESULT.AIR.UPDATE_SEARCH' | translate }}"
        type="button"
      ></p-button>
    </ng-template>
  </div>
</spt-sticky-header>

<section class="engine {{ search?.type }}" #engineBlock>
  <ng-container *ngIf="search" [ngSwitch]="search.type">
    <spt-travel-hotel
      class="hotel"
      *ngSwitchCase="'hotel'"
      [search]="search"
      [selectedFare]="choosenObject"
      [seeOutOfPolicy]="seeOutOfPolicy"
      (selectFare)="openConfirmationPanel($event)"
      (loading)="dataLoading($event)"
      (sideBarOpen)="toggleSelectionSidebar($event)"
      (toggleHeader)="toggleHeader()"
    ></spt-travel-hotel>
    <spt-travel-car
      *ngSwitchCase="'car'"
      [search]="search"
      [seeOutOfPolicy]="seeOutOfPolicy"
      (loading)="dataLoading($event)"
      (choosen)="openConfirmationPanel($event)"
      (sidebarOpen)="toggleSelectionSidebar($event)"
      (modifySearch)="modify()"
    ></spt-travel-car>
    <spt-travel-train
      *ngSwitchCase="'train'"
      [search]="search"
      [itemId]="itemId"
      [seeOutOfPolicy]="seeOutOfPolicy"
      (loading)="dataLoading($event)"
      (selectItinerary)="openConfirmationPanel($event)"
      (modifySearch)="modify()"
      (sidebarOpen)="toggleSelectionSidebar($event)"
    ></spt-travel-train>
    <spt-traveling-flight
      [removeLast]="removeLast"
      *ngSwitchCase="'flight'"
      [search]="search"
      [seeOutOfPolicy]="seeOutOfPolicy"
      (selectFares)="openConfirmationPanel($event)"
      (loading)="dataLoading($event)"
      (modifySearch)="modify()"
      (sidebarOpen)="toggleSelectionSidebar($event)"
      (confirmSidebarOpen)="confirmationSideBarOpen = $event"
    ></spt-traveling-flight>
  </ng-container>
</section>

<div *ngIf="afterBook" class="back"></div>

<spt-sidebar
  [styleClass]="search?.type"
  [opened]="choosenObject !== undefined && (search.type !== 'flight' || search.data.trips?.length === 1)"
  (close)="closeConfirmationPopup()"
>
  <div class="cta-container">
    <ng-container *ngIf="choosenObject" [ngSwitch]="search.type">
      <spt-travel-confirmation-car
        *ngSwitchCase="'car'"
        [car]="choosenObject"
        [search]="search"
        [travelers]="users"
        [locale]="locale"
      >
      </spt-travel-confirmation-car>
      <confirmation-sidebar-flight
        *ngSwitchCase="'flight'"
        [itineraries]="choosenObject?.itineraries"
        [data]="choosenObject"
      ></confirmation-sidebar-flight>
      <spt-travel-confirmation-hotel
        *ngSwitchCase="'hotel'"
        [price]="choosenObject.price"
        [formData]="choosenObject.fare"
        [hotel]="choosenObject.hotel"
        [travelers]="users"
        (prebook)="prebook()"
      ></spt-travel-confirmation-hotel>
      <spt-travel-train-itinerary
        *ngSwitchCase="'train'"
        [itinerary]="choosenObject.itinerary"
        [isSmallDevice]="commonService.isTablet"
        [locale]="locale"
        [provider]="choosenObject.provider"
        [type]="choosenObject.type"
        [passengers]="choosenObject.passengers"
        [users]="users"
        (termsOfUse)="termsOfUse($event)"
        (selectDeliveryOption)="deliveryOptionRequired = !$event"
        (updating)="bookingInProgress = $event"
        (mandatoryReservationSelected)="allMandatorySelected = $event"
      ></spt-travel-train-itinerary>
    </ng-container>

    <div class="space" *ngIf="search?.type !== 'hotel'"></div>
    <div
      class="cta-block {{ search?.type }}"
      [class.termsAgreementRequired]="termsAgreementRequired !== undefined"
      *ngIf="search?.type !== 'hotel'"
    >
      <section class="itinerary-actions" [class.exchange]="choosenObject?.type === 'Exchange'">
        <div class="terms" *ngIf="termsOfUseCheckboxes?.length > 0">
          <ng-container *ngFor="let termsOfUseCheckbox of termsOfUseCheckboxes">
            <label>
              <p-checkbox
                (onChange)="toggleTerms(termsOfUseCheckboxes)"
                [(ngModel)]="termsOfUseCheckbox.value"
                [binary]="true"
              ></p-checkbox>
              <span
                >{{ termsOfUseCheckbox.label | translate }}
                <a [href]="termsOfUseCheckbox.href" target="_blank" rel="noopener">
                  {{ termsOfUseCheckbox.link | translate }}
                </a></span
              >
            </label>
          </ng-container>
        </div>
      </section>

      <div class="cta flex justify-content-center items-center add-basket">
        <p-button
          type="button"
          [disabled]="
            bookingInProgress ||
            termsAgreementRequired ||
            deliveryOptionRequired ||
            informationRequired ||
            estimationLoading ||
            (choosenObject?.itinerary?.reservability !== 'none' && !allMandatorySelected && search?.type === 'train')
          "
          [label]="(modifyWithoutValidation ? 'GLOBAL.VALIDATE_UPDATE' : 'GLOBAL.ADD_TO_BASKET') | translate"
          (onClick)="prebook()"
        >
        </p-button>
      </div>
      <span
        class="messageWarningPlace"
        *ngIf="choosenObject?.itinerary?.reservability !== 'none' && !allMandatorySelected && search?.type === 'train'"
        >{{ "SEARCH.RESULT.RAIL.SEAT_PREFERENCES.WARNING_MESSAGE" | translate }}</span
      >
    </div>
  </div>
</spt-sidebar>
