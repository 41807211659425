import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { TrainTypes } from "../train";

@Component({
  selector: "spt-travel-train-resume",
  styleUrls: ["./resume.component.scss"],
  templateUrl: "./resume.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelTrainResumeComponent {
  @Input() origin: TrainTypes.Station;
  @Input() destination: TrainTypes.Station;
  @Input() type: TrainTypes.SearchType;
  @Input() departureDate: string;
  @Input() returnDate: string;
  @Input() searchJourneyDirection: TrainTypes.SearchJourneyDirection;
  @Input() action: "search" | "exchange";
}
