import { Directive, EventEmitter, HostListener, Input, NgZone, OnDestroy, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";

declare const Singleton: any;

@Directive({
  selector: "[googleAutocomplete]",
})
export class GoogleAutocompleteDirective implements OnInit, OnDestroy {
  @Input() types: Array<string>;
  @Input() radius: number;
  @Input() from: any;
  @Input() componentRestrictions?: google.maps.places.ComponentRestrictions;
  @Output() searchPredictions: EventEmitter<AutocompleteResult> = new EventEmitter();
  @Output() keyboardControl: EventEmitter<any> = new EventEmitter();
  private keyup: Subject<any> = new Subject();
  private ngUnsubscribe: Subject<void> = new Subject();

  constructor(private ngZone: NgZone) {}

  ngOnInit(): void {
    this.keyup.pipe(debounceTime(300), takeUntil(this.ngUnsubscribe)).subscribe((debouncedEvent: any) => {
      try {
        if (debouncedEvent.target.value && debouncedEvent.target.value.length) {
          this.getPredictions(debouncedEvent);
        }
      } catch {
        const singleton: any = Singleton.getInstance();

        singleton.script.onload = (): void => {
          this.getPredictions(debouncedEvent);
        };
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  @HostListener("keyup", ["$event"])
  onKeyup(event: any): void {
    if (["ArrowDown", "ArrowUp", "Enter"].includes(event.key)) {
      this.keyboardControl.emit(event.key);
    }

    event.preventDefault();
    event.stopPropagation();
    this.keyup.next(event);
  }

  private getPredictions(event: any): void {
    const opts: google.maps.places.AutocompletionRequest = {
      input: event.target.value,
      types: this.types,
      componentRestrictions: this.componentRestrictions,
    };
    new google.maps.places.AutocompleteService().getPlacePredictions(
      opts,
      (
        predictions: Array<google.maps.places.AutocompletePrediction & { value: any }>,
        status: google.maps.places.PlacesServiceStatus,
      ) => {
        this.ngZone.run(() => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            this.searchPredictions.emit({ event, predictions });
          }
        });
      },
    );
  }
}

export interface AutocompleteResult {
  event: Event;
  predictions: Array<google.maps.places.AutocompletePrediction & { value: any }>;
}
