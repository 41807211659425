<div class="header" *ngIf="request">
  <div class="details" *ngIf="this.item && item">
    <p class="address">
      <span>
        <img class="transport-icon" src="./assets/svg/rentalcar-full.svg" />
      </span>
      <span class="title f-sb">
        {{ this.item.address.street }}, {{ this.item.address.city }}
        <ng-container *ngIf="this.item.dropOffAddress">
          <br />
          {{ this.item.dropOffAddress.street }},
          {{ this.item.dropOffAddress.city }}
        </ng-container>
      </span>
    </p>
  </div>

  <div class="datetimes">
    <p class="dates">
      <span class="departure">
        {{ request.datein | localizedDate: "d" }}<br />
        <small class="month">
          {{ (request.datein | localizedDate: "MMM").replace(".", "") }}
        </small>
      </span>
      <span class="separator"></span>
      <span class="return">
        {{ request.dateout | localizedDate: "d" }}<br />
        <small class="month">
          {{ (request.dateout | localizedDate: "MMM").replace(".", "") }}
        </small>
      </span>
    </p>
    <p class="times">
      <span class="schedule departure">
        {{
          request.datein
            | localizedDate: ((this.sessionService.getSession() | async)?.timeFormat === 12 ? "h:mm a" : "H:mm")
        }}
      </span>
      <span class="schedule return">
        {{
          request.dateout
            | localizedDate: ((this.sessionService.getSession() | async)?.timeFormat === 12 ? "h:mm a" : "H:mm")
        }}
      </span>
    </p>
  </div>
</div>

<p class="travelers" translate>GLOBAL.TRAVELERS</p>

<div class="infos">
  <p class="user" *ngIf="request.board && request.board[0]">
    <spt-avatar [user]="request.board[0][0]"></spt-avatar>
    <sup class="board" *ngIf="request.board[0].length - 1 > 1"> +{{ request.board[0].length - 1 }} </sup>
  </p>

  <span class="out-politic" *ngIf="this.request?.isOutOfPolicy" translate>GLOBAL.OUT_OF_POLICY</span>

  <p class="price f-sb">
    {{ request.price | currency: "EUR" : "symbol" : "1.2-2" }}
  </p>
</div>
