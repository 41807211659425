import ColorHash from "color-hash";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";

import { OverlayPanel } from "primeng/overlaypanel";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { FlightService } from "../flight.service";
import { CommonService } from "../../../@shared/services/common.service";

@Component({
  selector: "spt-afkl-seatmap",
  styleUrls: ["./afkl-seatmap.component.scss"],
  templateUrl: "./afkl-seatmap.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class AFKLSeatmapComponent implements OnInit {
  seatmaps: any[] = [];
  displayedSeatmapIndex: number = 0;
  journey: string;
  flightNumber: string;
  cabinClass: string;
  seatMapUsers: any[] = [];
  item: any;
  offers: any[] = [];
  private colorHash: ColorHash;
  isTablet: boolean;
  public highlightedOffer: string = null;
  selectedUser: any;
  public selectedSeats: any = [];
  public paxLength: number = 0;

  constructor(
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private flightService: FlightService,
    private dialogRef: DynamicDialogRef,
    private commonService: CommonService,
  ) {
    const data = this.config.data.data;
    this.item = this.config.data.item;
    if (this.item) {
      this.seatMapUsers = this.item.formData.orderCreateRS?.DataLists.PaxList.Pax;
      if (this.seatMapUsers.length === 1) {
        this.selectedUser = this.seatMapUsers[0];
      }
    }

    this.colorHash = new ColorHash({
      hue: [
        { min: 30, max: 90 },
        { min: 180, max: 210 },
        { min: 270, max: 285 },
      ],
    });

    this.paxLength = data.DataLists.PaxList.Pax?.length ?? 1;
    this.journey = this.config.data.data;
    this.flightNumber = this.config.data.flightNumber;
    this.cabinClass = this.config.data.cabinClass;

    this.offers = data["ALaCarteOffer"].ALaCarteOfferItem.map((offer) => {
      const description = offer.Service.ServiceDefinitionRefID.Desc.DescText;

      return {
        id: offer.OfferItemID,
        description,
        color: this.colorHash.hex(description),
        price: {
          amount: offer.UnitPrice.TotalAmount["$t"],
          currency: offer.UnitPrice.TotalAmount["CurCode"],
        },
        segmentRef: offer.Eligibility.FlightAssociations.PaxSegmentID,
      };
    });

    if (Array.isArray(data["SeatMap"]) === false) {
      data["SeatMap"] = [data["SeatMap"]];
    }

    this.seatmaps = data["SeatMap"].map((seatmap) => {
      // const journeys = data.DataLists.
      const seatRows: any[] = [];
      if (Array.isArray(seatmap.CabinCompartment)) {
        seatmap.CabinCompartment.forEach((compartment: any) => {
          for (let i = 1; i <= Number(compartment.LastRowNumber); i++) {
            const hasAlreadySeatRow = compartment.SeatRow.find((row) => Number(row.RowNumber) === i);
            if (!hasAlreadySeatRow) {
              seatRows.push({
                RowNumber: i,
                Seat: [],
              });
            } else {
              hasAlreadySeatRow.Seat = hasAlreadySeatRow.Seat.map((seat: any) => {
                const seatOfferItem = this.offers.find((offer: any) => offer.id === seat.OfferItemRefID);
                const type = seatOfferItem?.description?.split(" ")[0].toLowerCase();
                const seatTypeArray = ["standard", "front", "extra", "free"];
                return {
                  ...seat,
                  offerItem: seatOfferItem,
                  price: seatOfferItem?.price,
                  rowNumber: i,
                  isSeat: seat.SeatCharacteristicCode.includes("SO") === false,
                  type,
                  cover:
                    type && seatTypeArray.includes(type)
                      ? `./assets/img/${type}-seat.png`
                      : `./assets/img/standard-seat.png`,
                };
              });
              seatRows.push(hasAlreadySeatRow);
            }
          }
        });
      } else {
        for (let i = 1; i <= Number(seatmap.CabinCompartment.LastRowNumber); i++) {
          const hasAlreadySeatRow = seatmap.CabinCompartment.SeatRow.find((row) => Number(row.RowNumber) === i);
          if (!hasAlreadySeatRow) {
            seatRows.push({
              RowNumber: i,
              Seat: [],
            });
          } else {
            hasAlreadySeatRow.Seat = hasAlreadySeatRow.Seat.map((seat: any) => {
              const seatOfferItem = this.offers.find((offer: any) => offer.id === seat.OfferItemRefID);
              const type = seatOfferItem?.description?.split(" ")[0].toLowerCase();
              const seatTypeArray = ["standard", "front", "extra", "free"];
              return {
                ...seat,
                offerItem: seatOfferItem,
                price: seatOfferItem?.price,
                rowNumber: i,
                isSeat: seat.SeatCharacteristicCode.includes("SO") === false,
                type,
                cover:
                  type && seatTypeArray.includes(type)
                    ? `./assets/img/${type}-seat.png`
                    : `./assets/img/standard-seat.png`,
              };
            });
            seatRows.push(hasAlreadySeatRow);
          }
        }
      }
      
      const offers = this.offers.filter((offer) => offer.segmentRef === seatmap.PaxSegmentRefID);
      return {
        seatRows,
        columnID: seatmap.CabinCompartment.ColumnID,
        offers,
        journey: "",
      };
    });
  }

  ngOnInit(): void {
    this.isTablet = this.commonService.isTablet;
  }

  display(index: number) {
    this.displayedSeatmapIndex = index;
  }

  highlight(offerId: string) {
    this.highlightedOffer = offerId;
  }

  selectSeat(seat: any) {
    if (seat.isSeat === false || seat.OccupationStatusCode === "OCCUPIED") {
      return;
    } else {
      if (this.selectedUser) {
        this.selectedUser.seat = seat;
      }
    }
  }

  selectUser(user) {
    this.selectedUser = user;
  }

  selectService(): void {
    this.flightService
      .setAncillaries(this.item.formData.orderID, this.item.formData.searchId, this.seatMapUsers, "seat")
      .subscribe((response: any) => {
        this.dialogRef.close(response.data);
      });
  }

  validate() {
    if (this.seatMapUsers.every((user) => "seat" in user)) {
      this.selectService();
    }
    // this.dynamicDialogRef.close(this.selectedSeats);
  }

  cancel() {
    this.dynamicDialogRef.close();
  }
}
