import { NgModule } from "@angular/core";
import { TabViewModule } from "primeng/tabview";
import { SharedModule } from "../../@shared/shared.module";
import { TravelTrainResumeComponent } from "./resume/resume.component";
import { TravelTrainSeatMapComponent } from "./seatmap/seatmap.component";
import { TravelTrainSelectionComponent } from "./selection/selection.component";
import { TravelTrainComponent } from "./train.component";
import { CurrencyPipe } from "@angular/common";
import { TravelTrainDetailsComponent } from "./details/details.component";
import { TravelTrainItineraryComponent } from "./itinerary/itinerary.component";
import { RadioButtonModule } from "primeng/radiobutton";
import { StepsModule } from "primeng/steps";
import { MessageModule } from "primeng/message";
import { TravelTrainJourneysComponent } from "./journeys/journeys.component";
import { TravelTrainJourneyComponent } from "./journeys/journey/journey.component";
import { SelectButtonModule } from "primeng/selectbutton";

const TRAIN_COMPONENTS: Array<any> = [
  TravelTrainComponent,
  TravelTrainJourneysComponent,
  TravelTrainJourneyComponent,
  TravelTrainResumeComponent,
  TravelTrainDetailsComponent,
  TravelTrainSelectionComponent,
  TravelTrainSeatMapComponent,
  TravelTrainItineraryComponent,
];

const TRAIN_MODULES: Array<any> = [SharedModule, RadioButtonModule, StepsModule, TabViewModule, MessageModule];

@NgModule({
  imports: [...TRAIN_MODULES, SelectButtonModule],
  exports: [TravelTrainComponent, TravelTrainItineraryComponent],
  declarations: [...TRAIN_COMPONENTS],
  providers: [CurrencyPipe],
})
export class TravelTrainModule {}
