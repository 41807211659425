import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { User } from "../../../../../@shared/@types/user";
import { RoleService } from "../../../../../@shared/services/roles.service";
import { SocietyService } from "../../../../../@shared/services/society.service";
import { MemberSociety } from "../../../../../@shared/@types/society";

@Component({
  selector: "spt-request",
  templateUrl: "./request.component.html",
  styleUrls: ["./request.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestComponent implements OnInit {
  @Input() request: any;
  @Input() user: User;
  @Input() managers: User[];
  @Output() refuseRequest: EventEmitter<string> = new EventEmitter();
  userManagers: Array<User>;

  constructor(
    public roleService: RoleService,
    private societyService: SocietyService,
  ) {}

  ngOnInit(): void {
    this.userManagers = this.managers;
    const board: Array<User> = this.request.board.map((board: Array<string>) => {
      return board.map((user) => {
        const member: MemberSociety = this.societyService.society.value.members.find((_member: MemberSociety) => {
          return _member.user._id === user;
        });
        return member ? member.user : undefined;
      });
    });
    this.request.board = board;
  }

  refuse(): void {
    this.refuseRequest.emit(this.request._id);
  }
}
