import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
} from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import L from "leaflet";
import moment from "moment";
import { CarService } from "src/app/@shared/services/car.service";
import { CommonService } from "src/app/@shared/services/common.service";

@Component({
  selector: "spt-car-description",
  templateUrl: "./car-description.component.html",
  styleUrls: ["./car-description.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DescriptionCarComponent implements OnInit, AfterViewInit {
  @Input() item: any;
  @Input() showLeaflet?: boolean;
  @Input() search: any;
  @Input() travelers: any = [];
  @Input() locale: string;

  public pickupInfo: {
    place: string;
    date: string;
    time: string;
    city: string;
  };
  public returnInfo: {
    place: string;
    date: string;
    time: string;
    city: string;
  };
  public isSameReturnAgency: boolean = false;

  @ViewChild("leafletMap", { static: false }) mapElement: ElementRef;
  @ViewChild("leafletMapReturn", { static: false }) mapReturnElement: ElementRef;

  private mapReturn: L.Map = null;
  public mapOtions: L.MapOptions;
  public pickUpCoordinates: SafeUrl;
  public dropOffCoordinates: SafeUrl;

  public carName: string;
  public carModel: string;
  public pickupAddressLine1: string;
  public pickupAddressLine2: string;
  public pickupCityName: string;
  public returnAddressLine1: string;
  public returnAddressLine2: string;
  public returnCityName: string;
  public pickupDate: string;
  public pickupTime: string;
  public returnDate: string;
  public returnTime: string;
  public gearboxType: string;
  public doorCount: number;
  public passengerPlaceCount: number;
  public pickupAgencyRef: string;
  public returnAgencyRef: string;

  constructor(
    private sanitizer: DomSanitizer,
    public commonService: CommonService,
  ) {}

  ngOnInit(): void {
    this.initializeData();
    this.isSameReturnAgency = this.checkIfSameReturnAgency();
  }

  ngAfterViewInit(): void {
    this.pickUpCoordinates = this.mapMarker(this.mapElement, this.getPickupCoordinates());
    const dropOffAddress = this.getReturnCoordinates();
    if (dropOffAddress) {
      this.dropOffCoordinates = this.mapMarker(this.mapReturnElement, dropOffAddress);
    }
  }

  get locationDuration(): number {
    const diff: number = moment(this.returnDate).diff(moment(this.pickupDate), "days");

    return diff + 1;
  }

  private initializeData(): void {
    this.carName =
      this.search?.data?.carName ??
      this.item?.formData?.carName ??
      this.item.carName ??
      this.item?.detail?.carName ??
      "Unknown";
    this.carModel =
      this.search?.data?.carModel ??
      this.item?.formData?.carModel ??
      this.item.carModel ??
      this.item?.detail?.carModel ??
      "Unknown";

    this.pickupAddressLine1 =
      this.search?.data?.pickupAddress?.label ??
      this.item?.formData?.pickupAddress?.label ??
      this.item.pickupAddress?.label ??
      this.item?.detail?.pickupAddress?.line1 ??
      "Unknown pickupAddressLine2";
    this.pickupAddressLine2 =
      this.search?.data?.pickupAddress?.line2 ??
      this.item?.formData?.pickupAddress?.line2 ??
      this.item.pickupAddress?.line2 ??
      this.item?.detail?.pickupAddress?.line2 ??
      "";
    this.pickupCityName =
      this.search?.data?.pickupAddress?.city ??
      this.item?.formData?.pickupAddress?.city ??
      this.item.pickupAddress?.city ??
      this.item?.detail?.pickupAddress?.city ??
      "Unknown";

    this.returnAddressLine1 =
      this.search?.data?.returnAddress?.label ??
      this.item?.formData?.returnAddress?.label ??
      this.item.returnAddress?.label ??
      this.item?.detail?.returnAddress?.line1 ??
      "Unknown";
    this.returnAddressLine2 =
      this.search?.data?.returnAddress?.line2 ??
      this.item?.formData?.returnAddress?.line2 ??
      this.item.returnAddress?.line2 ??
      this.item?.detail?.returnAddress?.line2 ??
      "";
    this.returnCityName =
      this.search?.data?.returnAddress?.city ??
      this.item?.formData?.returnAddress?.city ??
      this.item.returnAddress?.city ??
      this.item?.detail?.returnAddress?.city ??
      "Unknown";

    this.pickupDate =
      this.search?.data?.pickupDate ??
      this.item?.formData?.pickupDate ??
      this.item.pickupDate ??
      this.item?.detail?.pickupDate?.date ??
      "Unknown";
    this.pickupTime =
      this.search?.data?.pickupTime ??
      this.item?.formData?.pickupTime ??
      this.item.pickupTime ??
      this.item?.detail?.pickupDate?.time ??
      "Unknown";
    this.returnDate =
      this.search?.data?.returnDate ??
      this.item?.formData?.returnDate ??
      this.item.returnDate ??
      this.item?.detail?.returnDate?.date ??
      "Unknown";
    this.returnTime =
      this.search?.data?.returnTime ??
      this.item?.formData?.returnTime ??
      this.item.returnTime ??
      this.item?.detail?.returnDate?.time ??
      "Unknown";

    this.gearboxType =
      this.search?.data?.gearboxType ??
      this.item?.formData?.gearboxType ??
      this.item.gearboxType ??
      this.item?.detail?.gearboxType ??
      "Unknown";
    this.doorCount =
      this.search?.data?.doorCount ??
      this.item?.formData?.doorCount ??
      this.item.doorCount ??
      this.item?.detail?.doorCount ??
      0;
    this.passengerPlaceCount =
      this.search?.data?.passengerPlaceCount ??
      this.item?.formData?.passengerPlaceCount ??
      this.item.passengerPlaceCount ??
      this.item?.detail?.passengerPlaceCount ??
      0;
    this.pickupAgencyRef =
      this.search?.data?.pickupAgencyRef ??
      this.item?.formData?.pickup?.agencyRef ??
      this.item.pickup?.agencyRef ??
      this.item?.detail?.pickupAgencyRef ??
      "";
    this.returnAgencyRef =
      this.search?.data?.returnAgencyRef ??
      this.item?.formData?.return?.agencyRef ??
      this.item.return?.agencyRef ??
      this.item?.detail?.returnAgencyRef ??
      "";
  }

  private checkIfSameReturnAgency(): boolean {
    const pickupAddress = this.getPickupCoordinates();
    const returnAddress = this.getReturnCoordinates();

    return (
      this.search?.data?.returnAddressSameAsPickupAddress ||
      (pickupAddress && returnAddress && pickupAddress[0] === returnAddress[0] && pickupAddress[1] === returnAddress[1])
    );
  }

  private sanitize(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  private buildExternalLink(coordinates: L.LatLngExpression): SafeUrl {
    const userAgent: string = navigator?.userAgent;
    let result = "";
    if (this.commonService.isCordova() === true) {
      if (userAgent && /ipad|iphone|ipod/i.test(userAgent)) {
        result = "maps://maps.apple.com/?q=";
      } else {
        result = "geo:";
      }
    } else {
      result = "https://maps.google.com/?q=";
    }
    if (result) {
      // sanitaze for safe url
      result += coordinates.toString();
      return this.sanitize(result);
    }
    return undefined;
  }

  private mapMarker(mapElement: ElementRef, coordinates: L.LatLngExpression): SafeUrl {
    if (mapElement?.nativeElement && coordinates) {
      this.mapOtions = {
        center: L.latLng(coordinates),
        layers: [
          L.tileLayer("http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
            maxZoom: 18,
            attribution: "...",
            subdomains: ["mt0", "mt1", "mt2", "mt3"],
          }),
        ],
        zoom: 15,
      };

      const map: L.Map = L.map(mapElement.nativeElement, this.mapOtions);
      const marker: L.Icon = L.icon({
        iconUrl: "./assets/svg/map-purple-icon.svg",
        iconSize: [48, 48],
      });
      L.marker(coordinates, { icon: marker }).addTo(map);
      return this.buildExternalLink(coordinates);
    }
    return undefined;
  }

  // Utility methods to get data from multiple sources
  private getPickupCoordinates(): L.LatLngExpression {
    return (
      this.search?.data?.pickupAddress?.coordinates ??
      this.item?.formData?.pickupAddress?.coordinates ??
      this.item.pickupAddress?.coordinates ??
      this.item?.detail?.pickupAddress?.coordinates ??
      null
    );
  }

  private getReturnCoordinates(): L.LatLngExpression {
    if (this.search?.data?.returnAddressSameAsPickupAddress) {
      return this.getPickupCoordinates();
    }
    return (
      this.search?.data?.returnAddress?.coordinates ??
      this.item?.formData?.returnAddress?.coordinates ??
      this.item.returnAddress?.coordinates ??
      this.item?.detail?.returnAddress?.coordinates ??
      null
    );
  }
}
