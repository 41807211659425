import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Sidebar } from "primeng/sidebar";
import { finalize } from "rxjs/operators";
import { Label } from "../../@types/label";

import { CommonService } from "../../services/common.service";
import { LabelService } from "../../services/label.service";
import { SocietyService } from "../../services/society.service";
import { UserService } from "../../services/user.service";

@Component({
  selector: "spt-label-side",
  templateUrl: "./label-side.component.html",
  styleUrls: ["./label-side.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelSideComponent implements OnInit {
  public userLabelsForm: UntypedFormGroup;
  public defaultsValues: Array<any>;
  public _visible: boolean;
  @Input() comment: string;
  @Input() commentMandatory: string;
  @Input() isOutOfPolicy: boolean;
  private _labels: Label[];
  @Input() set labels(value: Array<Label>) {
    this._labels = value;
    this.setForm();
  }
  @ViewChild("labelSidebar") labelSidebar: Sidebar;
  get labels(): Array<Label> {
    return this._labels;
  }
  private _userLabel: any;
  public loading: boolean = false;
  private lastLabelSystemRequestValue: string;
  public lastLabelSystemRequestDescription: string;
  @Input() set userLabel(value: any) {
    if (value) {
      this._userLabel = value;
      this.toFormGroup();

      if (
        this.societyService.society.value.settings &&
        this.societyService.society.value.settings.config &&
        this.societyService.society.value.settings.config.systemRequest
      ) {
        const { systemRequest } = this.societyService.society.value.settings.config;

        this.userLabelsForm.valueChanges.subscribe((data: any) => {
          if (this.lastLabelSystemRequestValue !== data[systemRequest].value) {
            this.loading = true;
            this.labelService
              .getProjectManager(data[systemRequest].value)
              .pipe(
                finalize(() => {
                  this.loading = false;
                  this.lastLabelSystemRequestValue = data[systemRequest].value;
                }),
              )
              .subscribe(
                (managerUser: any) => {
                  // WARNING: for Arsilon, need to adapt when more use cases.
                  this.lastLabelSystemRequestDescription = managerUser.username;
                  this.systemRequestUpdate.emit(managerUser._id.toString() === this.userSerice.user.value._id);
                },
                () => {
                  this.systemRequestUpdate.emit(false);
                },
              );
          }
        });
      }
    }
  }
  get userLabel(): any {
    return this._userLabel;
  }
  @Input() canValidateBasket: {
    isAuthorized: boolean;
    commentMandatory: boolean;
    errors: string[];
  };
  @Input() type: string;
  @Input() set visible(value: boolean) {
    this.changeDisplayPanel(value);
  }
  get visible(): boolean {
    return this._visible;
  }
  @Output() validateUserLabels: EventEmitter<any> = new EventEmitter();
  @Output() systemRequestUpdate: EventEmitter<any> = new EventEmitter();

  constructor(
    private labelService: LabelService,
    private translateService: TranslateService,
    public commonService: CommonService,
    private fb: UntypedFormBuilder,
    private societyService: SocietyService,
    private userSerice: UserService,
  ) {}

  ngOnInit(): void {
    if (this.labels && this.labels.length > 0) {
      this.defaultsValues = [
        { label: this.translateService.instant("LABELS.YES"), value: "true" },
        { label: this.translateService.instant("LABELS.NO"), value: "false" },
      ];
      const none: string = this.translateService.instant("GLOBAL.NONE");
      this.labels.forEach((label: Label) => {
        if (label.required === false) {
          label.defaults.unshift({
            label: none,
            value: null,
          });
        }
      });
    }
  }

  changeDisplayPanel(newValue: boolean): void {
    if (newValue === this._visible) {
      return;
    }
    this._visible = newValue;
    if (newValue === true) {
      this.commonService.disableAppScroll();
      this.commonService.disableIntercom();
      this.commonService.setBackFunction(() => {
        this.changeDisplayPanel(false);
      }, this);
    } else {
      if (this.labelSidebar) {
        this.labelSidebar.destroyModal();
      }
      this.commonService.enableAppScroll();
      this.commonService.enableIntercom();
      this.commonService.unsetBackFunction();
    }
  }

  validate(): void {
    const labels: Array<any> = [];
    this.labelsArr.value.forEach((value: any, index: number) => {
      let chosen: {
        label?: string;
        value: any;
      };

      if (value && typeof value === "object") {
        chosen = {
          label: value.label,
          value: value.value,
        };
      } else {
        chosen = {
          value,
        };
      }

      labels.push({
        labelId: this.labels[index]._id,
        onBill: this.labels[index].onBill,
        label: this.labels[index].label,
        required: this.labels[index].required,
        chosen,
      });
    });
    this.validateUserLabels.emit({
      comment: this.comment,
      labels,
    });
    this.visible = false;
  }

  cancelLabel(): void {
    this.validateUserLabels.emit(undefined);
    this.visible = false;
  }

  get labelsArr(): UntypedFormArray {
    return this.userLabelsForm.get("labels") as UntypedFormArray;
  }

  private setForm(): void {
    this.userLabelsForm = this.fb.group({
      labels: this.fb.array(
        this.labels.map((label: Label) => {
          return this.fb.control({
            [label.label]: [null, label.required ? [Validators.required] : null],
          });
        }),
      ),
    });
  }

  private toFormGroup(): any {
    const dynamicFormFormat: Array<any> = [];
    this.labels.forEach((label: Label) => {
      let userLabel: any;
      if (this.userLabel) {
        userLabel = this.userLabel.find((_userLabel: any) => _userLabel.labelId === label._id);
      }
      if (userLabel) {
        if (label.type === "text") {
          dynamicFormFormat.push(userLabel.chosen.value);
        } else {
          dynamicFormFormat.push(userLabel.chosen);
        }
      } else {
        dynamicFormFormat.push(null);
      }
    });
    this.labelsArr.patchValue(dynamicFormFormat);
  }
}
