import { Hotel } from "../hotel.d";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { HotelService, FavoriteData } from "src/app/@shared/services/hotel.service";
import { Favorite } from "src/app/@shared/services/favorite.service";
import { CommonService } from "src/app/@shared/services/common.service";

@Component({
  selector: "spt-travel-hotel-thumbnail",
  styleUrls: ["./hotel-thumbnail.component.scss"],
  templateUrl: "./hotel-thumbnail.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class HotelThumbnailComponent implements OnInit, OnChanges {
  @Input() hotel: Hotel;
  @Input() availabilities: any;
  @Input() promoteCarbonOffset: boolean = false;
  @Output() updateHotel: EventEmitter<Hotel> = new EventEmitter();
  @Output() select: EventEmitter<void> = new EventEmitter();
  public promotions: string[];
  public facilities: string[] = [];
  public additionalFacilities: string[] = [];

  public inPolicy: boolean;

  public displayedFacilityIcons = {
    RESTAURANT: true,
    PARKING: true,
    INTERNET: true,
    RECEPTION2424: true,
  };

  constructor(
    private hotelService: HotelService,
    public commonService: CommonService,
  ) {}

  ngOnInit() {}

  ngOnChanges(value) {
    if (this.availabilities) {
      const promotions = new Set<string>(
        ...this.availabilities.possibilities.filter((a) => a.promotions).map((a) => a.promotions),
      );
      this.promotions = Array.from(promotions);
      let facilities = [];
      let additionalFacilities = [];
      if (this.promotions.length > 0) {
        facilities.push("gift");
      }
      this.inPolicy = this.availabilities.possibilities.some((a) => a.inPolicy);
      if (this.hotel.description === undefined || this.hotel.description.description === undefined) {
        this.hotelService.getDescription(this.hotel.accomcode, this.hotel.suppliers?.[0]).subscribe((hotel) => {
          this.hotel = {
            ...this.hotel,
            ...hotel,
          };
          this.updateHotel.emit(this.hotel);
        });
      }
      (this.hotel.description?.shortFacilities || []).forEach((facility) => {
        if (facility && this.displayedFacilityIcons[facility] === true) {
          if (facilities.length < 3) {
            facilities.push(facility);
          } else {
            additionalFacilities.push(facility);
          }
        }
      });
      if (additionalFacilities.length === 1) {
        facilities.push(additionalFacilities[0]);
        additionalFacilities = [];
      }
      // Pour assurer que "gift" est à la fin
      facilities.reverse();

      this.facilities = facilities;
      this.additionalFacilities = additionalFacilities;
    }
  }

  switchFavorite(e: Event) {
    e.stopPropagation();
    const [lng, lat] = this.hotel.coordinates;
    const favoriteData: FavoriteData = {
      coordinates: [lat, lng],
      metadata: {
        accomcode: this.hotel.accomcode,
      },
    };
    if (this.hotel.favorite && this.hotel.favorite.type === "user") {
      this.hotelService.removeFavorite(this.hotel.favorite.id).subscribe(() => {
        delete this.hotel.favorite;
      });
    } else {
      this.hotelService
        .addFavorite("user", this.hotel.description.title, "hotel", favoriteData)
        .subscribe((favorite: Favorite) => {
          this.hotel.favorite = favorite;
        });
    }
  }
  onImgError($event): void {
    $event.target.src = "./assets/svg/default-hotel.svg";
  }
}
