import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { TransferTypes } from "./transfer";

@Injectable({
  providedIn: "root",
})
export class TransferService {
  endpointProducts: string = `${environment.api}/transfers/products`;
  endpointBooking: string = `${environment.api}/transfers/booking`;

  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
  ) {}

  getTypes(): Observable<any> {
    return this.httpClient.get(`${this.endpointProducts}/types`);
  }

  getDestinations(input: string): Observable<any> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        input,
      },
    });
    const headers: HttpHeaders = new HttpHeaders({
      ignoreErrorMessage: "true",
      ignoreLoading: "true",
      "Accept-Language": this.translateService.currentLang,
    });

    return this.httpClient.get(`${this.endpointProducts}/destinations`, {
      params,
      headers,
    });
  }

  getAvailableRoutes(type: string, code: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      ignoreErrorMessage: "true",
      ignoreLoadingBar: "true",
      ignoreLoading: "true",
      "Accept-Language": this.translateService.currentLang,
    });

    return this.httpClient.get(`${this.endpointProducts}/routes/${type}/${code}`, {
      headers,
    });
  }

  getSearchResults(searchId: string): Observable<TransferTypes.SearchResult> {
    return this.httpClient.get<TransferTypes.SearchResult>(`${environment.api}/search/${searchId}/results`, {
      headers: {
        "Accept-Language": this.translateService.currentLang,
        "Content-Type": "application/json",
      },
    });
  }

  getBooking(bookingref: string): Observable<TransferTypes.Booking> {
    return this.httpClient.get<TransferTypes.Booking>(`${this.endpointBooking}/${bookingref}`, {
      headers: {
        "Accept-Language": this.translateService.currentLang,
        "Content-Type": "application/json",
      },
    });
  }

  amendBooking(bookingref: string, booking: any): Observable<TransferTypes.Booking> {
    return this.httpClient.put<TransferTypes.Booking>(`${this.endpointBooking}/${bookingref}`, booking, {
      headers: {
        "Accept-Language": this.translateService.currentLang,
        "Content-Type": "application/json",
      },
    });
  }

  cancelBooking(bookingref: string, transferId?: string): Observable<TransferTypes.Booking> {
    return this.httpClient.delete<TransferTypes.Booking>(
      `${this.endpointBooking}/${bookingref}/${transferId ? transferId : ""}`,
      {
        headers: {
          "Accept-Language": this.translateService.currentLang,
          "Content-Type": "application/json",
        },
      },
    );
  }
}
