<div class="journey-detail">
  <ng-container *ngIf="item.detail.booking.transfers">
    <div
      class="reservation-detail"
      *ngFor="let transfer of item.detail.booking.transfers; let i = index; let last = last"
    >
      <div class="reservation-detail-container">
        <div class="destination" [ngStyle]="{ 'margin-bottom': !last ? '20px' : '0px' }">
          <ng-container [ngSwitch]="transfer.fromdetails.codetype">
            <ng-container *ngSwitchCase="'AIR' || 'IATA'">
              <div class="date-wrapper">
                <div class="date">
                  <p class="day-number">
                    {{ transfer.fromdetails.flight.arrivaldatetime.date | date: "dd" }}
                  </p>
                  <p class="month">
                    {{ transfer.fromdetails.flight.arrivaldatetime.date | date: "MMM" | titlecase }}
                  </p>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'STN'">
              <div class="date-wrapper">
                <div class="date">
                  <p class="day-number">
                    {{ transfer.fromdetails.train.arrivaldatetime.date | date: "dd" }}
                  </p>
                  <p class="month">
                    {{ transfer.fromdetails.train.arrivaldatetime.date | date: "MMM" | titlecase }}
                  </p>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div class="date-wrapper">
                <div class="date">
                  <p class="day-number">
                    {{ transfer.fromdetails.accommodation.pickupdatetime.date | date: "dd" }}
                  </p>
                  <p class="month">
                    {{ transfer.fromdetails.accommodation.pickupdatetime.date | date: "MMM" | titlecase }}
                  </p>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <div class="dot-line">
            <div class="dot-empty"></div>
            <div class="line"></div>
            <div class="dot-empty"></div>
          </div>

          <ng-container [ngSwitch]="transfer.todetails.codetype">
            <ng-container *ngSwitchCase="'AIR' || 'IATA'">
              <div class="date-wrapper">
                <div class="date">
                  <p class="day-number">
                    {{ transfer.todetails.flight.departuredatetime.date | date: "dd" }}
                  </p>
                  <p class="month">
                    {{ transfer.todetails.flight.departuredatetime.date | date: "MMM" | titlecase }}
                  </p>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'STN'">
              <div class="date-wrapper">
                <div class="date">
                  <p class="day-number">
                    {{ transfer.todetails.train.departuredatetime.date | date: "dd" }}
                  </p>
                  <p class="month">
                    {{ transfer.todetails.train.departuredatetime.date | date: "MMM" | titlecase }}
                  </p>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div class="date-wrapper">
                <div class="date">
                  <p class="day-number">
                    {{ transfer.todetails.accommodation.dropoffdatetime.date | date: "dd" }}
                  </p>
                  <p class="month">
                    {{ transfer.todetails.accommodation.dropoffdatetime.date | date: "MMM" | titlecase }}
                  </p>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <div class="destination-time-society-container">
            <ng-container [ngSwitch]="transfer.fromdetails.codetype">
              <p class="departure-place-name">
                {{ transfer.fromdetails.name }}
              </p>
              <ng-container *ngSwitchCase="'AIR' || 'IATA'">
                <p class="departure-time">
                  {{ transfer.fromdetails.flight.arrivaldatetime.time }}
                </p>
              </ng-container>
              <ng-container *ngSwitchCase="'STN'">
                <p class="departure-time">
                  {{ transfer.fromdetails.train.arrivaldatetime.time }}
                </p>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <p class="departure-time">
                  {{ transfer.fromdetails.accommodation.pickupdatetime.time }}
                </p>
              </ng-container>
            </ng-container>
            <div class="chip-time">
              <i class="spt-icon-clock"></i>
              <p>{{ transfer.transfertime }}</p>
            </div>
            <ng-container [ngSwitch]="transfer.todetails.codetype">
              <p class="arrival-place-name">{{ transfer.todetails.name }}</p>
              <ng-container *ngSwitchCase="'AIR' || 'IATA'">
                <p class="arrival-time">
                  {{ transfer.todetails.flight.departuredatetime.time }}
                </p>
              </ng-container>
              <ng-container *ngSwitchCase="'STN'">
                <p class="arrival-time">
                  {{ transfer.todetails.train.departuredatetime.time }}
                </p>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <p class="arrival-time">
                  {{ transfer.todetails.accommodation.dropoffdatetime.time }}
                </p>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="separator" *ngIf="item.detail.booking.transfers.length > 1 && !last"></div>
    </div>
  </ng-container>
</div>
