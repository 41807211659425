import { Injectable } from "@angular/core";
import { BehaviorSubject, map, Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "../../services/common.service";
import { Basket } from "../../@types/basket";
import { BasketService } from "../../services/basket.service";

export interface Item {
  icon: string;
  label: string;
  routerLink?: any;
  link?: string;
  target?: string;
  command?: any;
  lang?: string;
}
@Injectable({
  providedIn: "root",
})
export class MenuService {
  public displayMenu: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _menu: Array<Item> = [];

  constructor(
    private translateService: TranslateService,
    private commonService: CommonService,
    private basketService: BasketService,
  ) {
    const currentLang =
      this.translateService.currentLang == (undefined || null)
        ? "en"
        : this.translateService.currentLang === "es"
          ? "en"
          : this.translateService.currentLang;
    this._menu = [
      {
        routerLink: "/",
        label: "HEADER.MENU_TRAVEL",
        icon: "travel-mobile",
      },
      {
        routerLink: "settings/account",
        label: "HEADER.MY_ACCOUNT",
        icon: "account-mobile",
      },
      {
        routerLink: "/baskets/",
        label: "BASKET.LIST.TITLE",
        icon: "basket",
      },
      {
        command: () => {},
        lang: currentLang,
        label: "HEADER.HELP",
        icon: "help-mobile",
        target: this.commonService.isCordova() === true ? "_system" : "_blank",
      },
    ];
  }

  set menu(_menu: Array<Item>) {
    this._menu = _menu;
  }

  get menu(): Array<Item> {
    return this._menu;
  }

  display(): void {
    this.commonService.setBackFunction(() => {
      this.remove();
    }, this);
    this.displayMenu.next(true);
  }
  remove(): void {
    this.commonService.unsetBackFunction();
    this.displayMenu.next(false);
  }
}
