<div *ngIf="background" class="fond"></div>
<ng-container *ngIf="displayed">
  <div [class.white]="!background" class="main-menu grid align-items-center justify-content-between">
    <div class="grid grid-nogutter m-0 align-items-center">
      <a [routerLink]="'/'" class="grid align-items-center header-link">
        <img class="header-logo" src="./assets/svg/supertripper-{{ background ? 'white' : 'violet' }}.svg" />
        <span [ngClass]="{ 'txt-white': background }" class="f-sb txt-20 m-l-15">supertripper</span>
      </a>
      <div *ngIf="(commonService.isTabletObservable | async) === false" class="grid header">
        <ng-container *ngFor="let headerLink of headersLink">
          <a *ngIf="headerLink.routerLink" [routerLink]="headerLink.routerLink" class="txt-18">
            {{ headerLink.label | translate }}</a
          >
          <a *ngIf="headerLink.link" [href]="headerLink.link" [target]="headerLink.target" class="txt-18">
            {{ headerLink.label | translate }}</a
          >
        </ng-container>
      </div>
    </div>

    <div
      [ngClass]="
        (commonService.isTabletObservable | async) === true ? 'justify-content-end' : 'justify-content-between'
      "
      class="grid align-items-center grid-nogutter m-0 right-container"
    >
      <ng-container *ngIf="(commonService.isTabletObservable | async) === false; else mobileIntercom">
        <div
          *ngIf="basketService.baskets$ | async as baskets"
          class="notifications p-5"
          [routerLink]="['/baskets', currentOpenedBasketId ?? '']"
        >
          <img *ngIf="background" class="basket" src="./assets/svg/basket-white.svg" alt="" />
          <img *ngIf="!background" class="basket" src="./assets/svg/basket.svg" alt="" />
          <div *ngIf="countingBaskets > 0" class="alert-count">&nbsp;</div>
        </div>
        <div (click)="menuHeader.toggle($event)" class="pointer grid align-items-center" *ngIf="user">
          <spt-avatar [displayTooltip]="false" [displayUsername]="false" [user]="user"></spt-avatar>
        </div>
      </ng-container>

      <ng-template #mobileIntercom>
        <div id="intercom-chat" class="intercom-chat-bubble">
          <img alt="Chat" src="./assets/svg/chat.svg" />
        </div>
      </ng-template>
      <spt-menu-mobile [basket]="basketCount" [background]="background"></spt-menu-mobile>
    </div>
  </div>
</ng-container>
<p-menu #menuHeader [model]="items" [popup]="true" appendTo="body" styleClass="submenu"></p-menu>
