import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { CommonService } from "../../../../../@shared/services/common.service";
import { Booking } from "../../../../../@shared/@types/booking";

@Component({
  selector: "spt-booking-hotel",
  templateUrl: "./hotel.component.html",
  styleUrls: ["../booking-list.component.scss", "./hotel.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HotelComponent {
  @Input() item: any;
  @Output() openedEticket: EventEmitter<any> = new EventEmitter();

  constructor(public commonService: CommonService) {}

  openEticket(event, booking: Booking): void {
    event.stopPropagation();

    this.openedEticket.emit(booking);
  }
}
