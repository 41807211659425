<div class="header">
  <a translate (click)="loginPage()">LOGIN.BACK_TO_LOGIN</a>
</div>

<div class="container grid col p-0">
  <div class="form-title">
    <h1 class="txt-25 m-0" translate>LOGIN.FORGOT_PASSWORD</h1>
    <span class="soft-grey txt-18" translate>LOGIN.ENTER_FORGOT_PASSWORD</span>
  </div>
  <p translate>ACCOUNT.PASSWORD.RULES</p>
  <form [formGroup]="resetForm" (ngSubmit)="changePassword()" class="form-group">
    <div class="m-t-20">
      <label class="labelinput" translate>ACCOUNT.GENERAL.PASSWORD</label>
      <div class="p-inputgroup align-items-center">
        <input
          required
          class="iosInputFix"
          formControlName="password"
          autocomplete="off"
          class="input-shadow"
          pInputText
          [type]="this.displayPassword['password'] ? 'text' : 'password'"
        />
        <span class="p-inputgroup-addon" (click)="changeStatutPassword('password')"><i class="fas fa-eye"></i></span>
      </div>
      <app-password-strength-bar [passwordToCheck]="password.value"></app-password-strength-bar>
    </div>
    <div class="m-t-20">
      <label class="labelinput" translate>LOGIN.SUBSCRIPTION.REPEAT_PASSWORD</label>
      <div class="p-inputgroup align-items-center">
        <input
          class="iosInputFix"
          id="repeatPassword"
          required
          formControlName="repeatPassword"
          autocomplete="off"
          class="input-shadow"
          pInputText
          [type]="this.displayPassword['repeatPassword'] ? 'text' : 'password'"
        />
        <span class="p-inputgroup-addon" (click)="changeStatutPassword('repeatPassword')"
          ><i class="fas fa-eye"></i
        ></span>
      </div>
    </div>
    <div
      *ngIf="resetForm.invalid && (resetForm.dirty || resetForm.touched)"
      class="cross-validation-error-message alert alert-danger"
    >
      <div *ngIf="resetForm.errors?.mismatchedPassword" translate>ACCOUNT.PASSWORD.MISMATCHED</div>
    </div>
    <p-button
      type="submit"
      [disabled]="resetForm.invalid"
      label="{{ 'GLOBAL.SAVE' | translate }}"
      class="m-t-30 flex flex-end"
    ></p-button>
    <br />
  </form>
</div>
