<ng-container *ngIf="noRefundOptions === false; else noOptions">
  <div class="select-passengers-container" *ngIf="userItems.length">
    <p-multiSelect
      [options]="userItems"
      [defaultLabel]="'GLOBAL.TRAVELERS' | translate"
      [(ngModel)]="selectedTravelers"
      (ngModelChange)="onSelectTravelers()"
    >
      <ng-template let-items pTemplate="selectedItems">
        <p *ngIf="items?.length === 0">{{ "GLOBAL.TRAVELERS" | translate }}</p>
        <spt-avatar
          *ngIf="selectedTravelers?.length === 1"
          [user]="{
            username: selectedTravelers[0].firstname + ' ' + selectedTravelers[0].lastname
          }"
          [displayTooltip]="true"
        ></spt-avatar>
        <p *ngIf="items?.length > 1">{{ items.length }} {{ "BOOKING.SELECTED_TRAVELERS" | translate }}</p>
      </ng-template>
      <ng-template let-item pTemplate="item">
        <div class="traveler-container">
          <spt-avatar [user]="item.value"></spt-avatar>
        </div>
      </ng-template>
    </p-multiSelect>
  </div>
  <div class="select-travel-container">
    <div class="travel-info-container" *ngFor="let journey of journeys; let index = index">
      <div class="checkbox-button" *ngIf="journeys.length > 1">
        <p-checkbox
          [id]="'checkbox-' + index"
          name="checkbox-{{ index }}"
          class="checkbox"
          [value]="journey"
          [(ngModel)]="selectedJourneys"
          (ngModelChange)="onSelectJourney(index)"
        ></p-checkbox>
      </div>
      <div class="travel-destination" [ngClass]="activeJourney[index] ? 'travel-interaction' : 'travel-no-interaction'">
        <div class="travel-from-to">
          <p class="travel-text">
            {{ airports[journey.Dep.IATALocationCode]?.name }}
          </p>
          <i class="spt-icon-arrow-circle-right"></i>
          <p class="travel-text">
            {{ airports[journey.Arrival.IATALocationCode]?.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="selectedRefundOptions.length === 0 && selectedTravelers.length === 0 && selectedJourneys.length === 0 && item.provider === 'afkl'"
    class="select-one"
  >
    <p translate>RESERVATIONS.TRAIN.CANCEL.SELECT_ONE_TRAVELER_OR_ROUTE</p>
  </div>
  <div *ngIf="displayedReservationFees.amount > 0">
    <p
      class="reservation-fees"
      translate
      [translateParams]="{
        fees: (displayedReservationFees | SPTPrice | async)
      }"
    >
      RESERVATIONS.TRAIN.CANCEL.RESERVATION_FEE
    </p>
  </div>
  <div *ngIf="voucherRefund">
    <p class="voucher-refund" translate>RESERVATIONS.TRAIN.CANCEL.VOUCHER_REFUND</p>
  </div>
  <div class="my-5" *ngIf="partial === false">
    {{ "RESERVATIONS.TRAIN.CANCEL.NO_PARTIAL" | translate }}
  </div>
</ng-container>

<ng-template #noOptions>
  <p class="text-center">
    <p-message severity="warn" [text]="'RESERVATIONS.TRAIN.NO_REFUNDABLES' | translate"></p-message>
  </p>
</ng-template>
