<div class="menu" *ngIf="this.displayMenu">
  <div class="menu-header">
    <spt-avatar [displayTooltip]="false" [displayUsername]="true" [user]="user"></spt-avatar>
    <div (mousedown)="close()">
      <i class="fas fa-times"></i>
    </div>
  </div>
  <div class="menu-content">
    <div class="menu-content-item">
      <ng-container *ngFor="let item of items" (mousedown)="goTo(item)" class="item">
        <div *ngIf="!item.link" (click)="goTo(item)" class="item">
          <img src="./../../../../assets/svg/menu/{{ item.icon }}.svg" />
          <span>{{ item.label | translate }}</span>
          <span *ngIf="item.routerLink?.includes('basket') && basketCount != 0" class="alert-count"></span>
        </div>
        <a *ngIf="item.link" [href]="item.link" [target]="item.target" class="item">
          <img src="./../../../../assets/svg/menu/{{ item.icon }}.svg" />
          <span>{{ item.label | translate }}</span>
        </a>
      </ng-container>
      <div class="item" (mousedown)="logout()">
        <img src="./../../../../assets/svg/menu/logout.svg" /><span translate>ACCOUNT.LOGOUT</span>
      </div>
    </div>
    <div class="logo"><img class="header-logo" src="./assets/svg/logo-supertripper-violet.svg" /></div>
  </div>
</div>
