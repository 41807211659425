import { Injectable } from "@angular/core";
import { NgForage } from "ngforage";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable, of } from "rxjs";

export class ClassPolicyFlightOrder {
  economic: number = 0;
  premium: number = 1;
  business: number = 2;
  first: number = 3;
  0: string = "economic";
  1: string = "premium";
  2: string = "business";
  3: string = "first";
}
export const policyFlightOrder: ClassPolicyFlightOrder = Object.freeze(new ClassPolicyFlightOrder());

@Injectable({
  providedIn: "root",
})
export class FlightService {
  private endpoint: string = `${environment.api}/api/flights`;
  private airports = {};

  constructor(
    public readonly storage: NgForage,
    private readonly httpClient: HttpClient,
  ) {}

  public locate(input: string, lang?: "fr" | "en" | "es"): Observable<LocateResult[]> {
    const headers: HttpHeaders = new HttpHeaders({
      ignoreErrorMessage: "true",
      ignoreLoading: "true",
      ignoreLoadingBar: "true",
    });
    let url: string;
    if (lang) {
      url = `${this.endpoint}/locate/${encodeURI(input)}/${lang}`;
    } else {
      url = `${this.endpoint}/locate/${encodeURI(input)}`;
    }
    return this.httpClient
      .get(url, { headers })
      .pipe(map((result: any) => result.filter((data) => data.travelType === "FLIGHT")));
  }

  decode(code) {
    return this.airports[code] ? this.airports[code].name : code;
  }
  listAirports(codes: Array<string>): Observable<{ [iata: string]: IATAResult }> {
    const params: HttpParams = new HttpParams({
      fromObject: { "iata-codes": codes },
    });
    if (codes.length) {
      return this.httpClient.get(`${environment.api}/flight/airports`, { params }).pipe(
        map((res: any) => {
          for (const key in res.data) {
            if (Object.prototype.hasOwnProperty.call(res.data, key)) {
              this.airports[key] = res.data[key];
            }
          }
          return res.data;
        }),
      );
    }
    return of({});
  }
}
export interface LocateResult {
  rank: number;
  calculatedFactor: number;
  calculatedRank: number;
  airportIATA: string;
  airportName: string;
  cityIATA: string;
  cityName: string;
  countryISO: string;
  countryName: string;
  continent: string;
  areaLevel1Code: string;
  areaLevel1Name: string;
  distanceToDowntown: number;
  name: string;
  isCity: boolean;
  ident: boolean;
}
export interface IATAResult {
  name: string;
  city: string;
  country: string;
  iata: string;
  icao: string;
  latitude: number;
  longitude: number;
  altitude: number;
  timezone: number;
  dst: string;
  tz: string;
  type: string;
  source: string;
}
