<ng-container *ngIf="filterDefinitions && currentFilter">
  <div
    class="list-filters"
    [class.noGap]="['changeable', 'freeRefund', 'refundable', 'freeExchange'].includes(filterDefinition?.type)"
    *ngFor="let filterDefinition of filterDefinitions; let indexFilter = index"
  >
    <ng-container [ngSwitch]="filterDefinition?.type">
      <div *ngSwitchCase="'cabinCodes'" class="cabinCodes filter">
        <div class="input-container">
          <p-dropdown
            [(ngModel)]="currentFilter.cabinCodes"
            (onChange)="triggerFilterChange()"
            [options]="filterDefinition.data"
          ></p-dropdown>
        </div>
      </div>
      <div *ngSwitchCase="'price'" class="price filter">
        <span class="title" translate>
          {{ "SEARCH.RESULT.AIR.FILTERS.MAX_PRICE" | uppercase }}
        </span>
        <div class="input-container">
          <p-slider
            [(ngModel)]="currentFilter.price"
            (onSlideEnd)="triggerFilterChange()"
            [min]="filterDefinition.min"
            [max]="filterDefinition.max"
          ></p-slider>
          <span class="label">{{
            {
              amount: currentFilter.price !== undefined ? currentFilter.price : filterDefinition.max,
              currency: "EUR"
            }
              | SPTPrice
              | async
          }}</span>
        </div>
      </div>
      <div *ngSwitchCase="'duration'" class="price filter">
        <span class="title" translate>
          {{ "SEARCH.RESULT.AIR.FILTERS.DURATION" | uppercase }}
        </span>
        <div class="input-container">
          <p-slider
            [(ngModel)]="currentFilter.duration"
            (onSlideEnd)="triggerFilterChange()"
            [min]="filterDefinition.min"
            [max]="filterDefinition.max"
          ></p-slider>
          <span class="label">{{
            (currentFilter.duration !== undefined ? currentFilter.duration : filterDefinition.max)
              | flightTimeToDuration
          }}</span>
        </div>
      </div>

      <div
        *ngIf="filterDefinition?.type === 'haveNegotiatedRate' || filterDefinition?.type === 'subscriber'"
        class="filter"
      >
        <span class="title" translate> SEARCH.RESULT.AIR.FILTERS.TARIFS </span>
        <div class="list-filters group">
          <div *ngSwitchCase="'haveNegotiatedRate'" class="changeable filter">
            <div class="input-container">
              <p-checkbox
                [(ngModel)]="currentFilter.haveNegotiatedRate"
                (onChange)="triggerFilterChange()"
                [label]="filterDefinition.data.value | translate"
                [value]="filterDefinition.data.key"
                [binary]="true"
              >
              </p-checkbox>
              <spt-tooltip
                [title]="'TOOLTIP.INFORMATION' | translate"
                [description]="'GLOBAL.FLIGHT_TARIFS.INFORMATION.NEGOCIATED_RATE' | translate"
                [position]="'top'"
                [triggerImg]="'info-fonce.svg'"
              ></spt-tooltip>
            </div>
          </div>

          <div *ngSwitchCase="'subscriber'" class="changeable filter">
            <div class="input-container">
              <p-checkbox
                [(ngModel)]="currentFilter.subscriber"
                (onChange)="triggerFilterChange()"
                [label]="filterDefinition.data.value | translate"
                [value]="filterDefinition.data.key"
                [binary]="true"
              >
              </p-checkbox>
              <spt-tooltip
                [title]="'TOOLTIP.INFORMATION' | translate"
                [description]="'GLOBAL.FLIGHT_TARIFS.INFORMATION.SUBSCRIBER_RATE' | translate"
                [position]="'top'"
                [triggerImg]="'info-fonce.svg'"
              ></spt-tooltip>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="['changeable', 'freeRefund', 'refundable', 'freeExchange'].includes(filterDefinition?.type)">
        <span *ngIf="indexFilter === indexFilterForTitle" class="title" id="optionsText" translate>
          SEARCH.RESULT.AIR.FILTERS.CONDITIONS
        </span>

        <div *ngIf="filterDefinition?.type === 'changeable'" class="input-container">
          <p-checkbox
            [(ngModel)]="currentFilter.changeable"
            (onChange)="triggerFilterChange()"
            [label]="filterDefinition.data.value | translate"
            [value]="filterDefinition.data.key"
            [binary]="true"
          >
          </p-checkbox>
        </div>
        <div *ngIf="filterDefinition?.type === 'freeRefund'" class="input-container">
          <p-checkbox
            [(ngModel)]="currentFilter.freeRefund"
            (onChange)="triggerFilterChange()"
            [label]="filterDefinition.data.value | translate"
            [value]="filterDefinition.data.key"
            [binary]="true"
          >
          </p-checkbox>
        </div>
        <div *ngIf="filterDefinition?.type === 'freeExchange'" class="input-container">
          <p-checkbox
            [(ngModel)]="currentFilter.freeExchange"
            (onChange)="triggerFilterChange()"
            [label]="filterDefinition.data.value | translate"
            [value]="filterDefinition.data.key"
            [binary]="true"
          >
          </p-checkbox>
        </div>

        <div *ngIf="filterDefinition?.type === 'refundable'" class="input-container">
          <p-checkbox
            [(ngModel)]="currentFilter.refundable"
            (onChange)="triggerFilterChange()"
            [label]="filterDefinition.data.value | translate"
            [value]="filterDefinition.data.key"
            [binary]="true"
          >
          </p-checkbox>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'baggages'">
        <div class="refundable filter">
          <span class="title" translate>
            {{ "GLOBAL.BAGAGES" | uppercase }}
          </span>
          <div class="input-container">
            <p-checkbox
              [(ngModel)]="currentFilter.carryOnIncluded"
              (onChange)="triggerFilterChange()"
              [label]="'SEARCH.RESULT.AIR.FILTERS.BAGGAGES_CARRY' | translate"
              name="carryOnIncluded"
              [binary]="true"
              (onChange)="triggerFilterChange()"
            >
            </p-checkbox>
          </div>
          <div class="input-container">
            <p-checkbox
              [(ngModel)]="currentFilter.baggageIncluded"
              (onChange)="triggerFilterChange()"
              [label]="'SEARCH.RESULT.AIR.FILTERS.BAGGAGES_BAGGAGES' | translate"
              name="baggageIncluded"
              [binary]="true"
              (onChange)="triggerFilterChange()"
            >
            </p-checkbox>
          </div>
        </div>
        <div *ngIf="currentFilter.baggageIncluded" class="baggageIncluded refundable filter">
          <span class="title" translate>
            {{ "SEARCH.RESULT.AIR.FILTERS." + filterDefinition.type | uppercase }}
          </span>
          <ng-container *ngFor="let list of filterDefinition.data">
            <div class="input-container" *ngIf="list.key !== 0">
              <p-radioButton
                (onClick)="triggerFilterChange()"
                [label]="'SEARCH.RESULT.AIR.LUGGAGE.INCLUDED_LUGGAGE' | translate: { number: list.key }"
                name="baggages"
                [value]="list.key"
                [(ngModel)]="currentFilter.baggageIncludedMinimumNumber"
              >
              </p-radioButton>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <div *ngSwitchCase="'nbStops'" class="refundable filter">
        <span class="title" translate>
          {{ "SEARCH.RESULT.AIR.FILTERS." + filterDefinition.type | uppercase }}
        </span>
        <div class="input-container" *ngFor="let list of filterDefinition.data">
          <p-checkbox
            [(ngModel)]="currentFilter.nbStops"
            (onChange)="triggerFilterChange()"
            [label]="'SEARCH.RESULT.AIR.STOP.' + list.label | translate"
            [value]="list.key"
          >
          </p-checkbox>
        </div>
      </div>

      <div *ngSwitchCase="'companies'" class="refundable filter">
        <div class="title companies" [ngClass]="this.sortBy" translate>
          {{ "SEARCH.RESULT.AIR.FILTERS." + filterDefinition.type | uppercase }}
          <span class="filter-alpha" (mousedown)="changeSortOrder()" [ngClass]="this.sortBy"
            ><span>A-Z</span><span class="fa-solid fa-right-left"></span
          ></span>
        </div>
        <div class="input-container" *ngFor="let list of filterDefinition.data | alphabeticOrder: sortBy : 'companies'">
          <p-checkbox
            [(ngModel)]="currentFilter.companies"
            (onChange)="triggerFilterChange()"
            [label]="companies[list.label]?.name || list.label"
            [value]="list.key"
          >
          </p-checkbox>
        </div>
      </div>

      <div *ngSwitchCase="'departureAirports'" class="refundable filter">
        <span class="title" translate>
          {{ "SEARCH.RESULT.AIR.FILTERS." + filterDefinition.type | uppercase }}
        </span>
        <div class="input-container" *ngFor="let list of filterDefinition.data">
          <p-checkbox
            [(ngModel)]="currentFilter.departureAirports"
            (onChange)="triggerFilterChange()"
            [label]="flightService.airports[list.label]?.name || list.label"
            [value]="list.key"
          >
          </p-checkbox>
        </div>
      </div>

      <div *ngSwitchCase="'arrivalAirports'" class="refundable filter">
        <span class="title" translate>
          {{ "SEARCH.RESULT.AIR.FILTERS." + filterDefinition.type | uppercase }}
        </span>
        <div class="input-container" *ngFor="let list of filterDefinition.data">
          <p-checkbox
            [(ngModel)]="currentFilter.arrivalAirports"
            (onChange)="triggerFilterChange()"
            [label]="flightService.airports[list.label]?.name || list.label"
            [value]="list.key"
          >
          </p-checkbox>
        </div>
      </div>

      <div *ngSwitchCase="'airportStops'" class="refundable filter">
        <span class="title" translate>
          {{ "SEARCH.RESULT.AIR.FILTERS." + filterDefinition.type | uppercase }}
        </span>
        <div class="input-container" *ngFor="let list of filterDefinition.data">
          <p-checkbox
            [(ngModel)]="currentFilter.airportStops"
            (onChange)="triggerFilterChange()"
            [label]="flightService.airports[list.label]?.name || list.label"
            [value]="list.key"
          >
          </p-checkbox>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
