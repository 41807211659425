import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sptIndexOf",
})
export class IndexOfPipe implements PipeTransform {
  transform(items: any[], value: string, lastUpdate: Date): any {
    if (Array.isArray(items)) {
      return items.indexOf(value) !== -1;
    }
    return false;
  }
}
