import { ActivatedRoute, Params, Router } from "@angular/router";
import { filter, first, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { Basket } from "../@shared/@types/basket";
import { HotelTypes } from "../@shared/@types/hotel";
import { BasketService } from "../@shared/services/basket.service";
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Label } from "../@shared/@types/label";
import { CommonService } from "../@shared/services/common.service";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { MessageService } from "primeng/api";
import { LabelService } from "../@shared/services/label.service";
import { SearchEngineComponent } from "src/app/search/search-engine/search-engine.component";
import { SearchService } from "src/app/@shared/services/search.service";
import moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { User } from "../@shared/@types/user";
import { MemberSociety, Society } from "../@shared/@types/society";
import { ModalService } from "../@shared/services/modal.service";
import { iif, Observable, of, Subject } from "rxjs";
import { UtilsTypes } from "../@shared/@types/utils";
import { TrainTypes } from "./train/train";

@Component({
  selector: "spt-travel",
  styleUrls: ["./travel.component.scss"],
  templateUrl: "./travel.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class TravelComponent implements OnInit, OnDestroy {
  @ViewChild("engineBlock", { static: false, read: ElementRef })
  engine: ElementRef;
  @ViewChild("stickyHeader", { static: false, read: ElementRef })
  header: ElementRef;
  private ngUnsubscribe: Subject<void> = new Subject();

  public hasOutOfPolicy: boolean;
  public hasBook: boolean;
  public seeOutOfPolicy: boolean;
  public hasSelectEntity: boolean;
  public modifyWithoutValidation: boolean = true;
  public headerDisplayed: boolean = true;

  public selectionSidebarOpen: boolean = false;
  public confirmationSideBarOpen: boolean = false;
  public searchId: string;
  public search: any;
  public itemId: string;
  public relativeBasketId?: string;
  public relativeFolderId?: string;

  public data: any;
  public choosenObject: any;
  public allMandatorySelected: boolean = false;
  // If equals true, hide the loading screen
  public Math: Math = Math;
  public loading: boolean = false;
  public averageTiming: number = null;
  public currentTime: number = 0;
  public currentTimeInterval: any;

  public bookingInProgress: boolean = false;
  public afterBook: boolean = false;

  public labels: Array<Label> = [];
  public locale: string;
  public users: Array<User>;
  public fees: UtilsTypes.Price = {
    amount: 0,
    currency: "EUR",
  };
  public termsAgreementRequired: boolean = undefined;
  termsOfUseCheckboxes: TrainTypes.TermsOfUse[] = [];
  public deliveryOptionRequired: boolean = false;
  public informationRequired: boolean = false;
  public estimationLoading: boolean = false;

  public removeLast: Subject<any> = new Subject();

  public criticalAppDeadEnd: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService,
    public commonService: CommonService,
    private router: Router,
    private basketService: BasketService,
    private labelService: LabelService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.locale = this.translateService.currentLang;
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params: Params): void => {
      this.initExtraSettings();
      this.search = undefined;
      this.searchId = params["search-id"];

      this.searchService.get(this.searchId).subscribe({
        next: (data: { search: object; averageTiming: number; users: Array<User>; society: Society }): void => {
          this.search = data.search;
          this.averageTiming = data.averageTiming;
          this.users = data.users;

          if (
            this.route.snapshot.data.society.settings &&
            this.route.snapshot.data.society.settings.config &&
            this.route.snapshot.data.society.settings.config.modifyWithoutValidation !== undefined
          ) {
            this.modifyWithoutValidation =
              this.route.snapshot.data.society.settings.config.modifyWithoutValidation && !!this.search.oldItemId;
          } else {
            this.modifyWithoutValidation = !!this.search.oldItemId;
          }
        },
        error: (): void => {
          this.criticalAppDeadEnd = true;
        },
      });

      this.labelService.getLabels().subscribe((res: Array<Label>): void => {
        this.labels = res;
      });
    });
    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params: Params): void => {
      this.itemId = params["itemId"];
      this.relativeBasketId = params["relativeBasketId"];
      this.relativeFolderId = params["relativeFolderId"];
    });
    this.loading = true;
  }

  ngOnDestroy(): void {
    clearInterval(this.currentTimeInterval);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  initExtraSettings(): void {
    const extraSettings: any = this.route.snapshot.data.extraSettings;
    if (extraSettings) {
      this.hasOutOfPolicy = extraSettings.hasOutOfPolicy;
      this.hasBook = extraSettings.hasBook;
      this.seeOutOfPolicy = extraSettings.seeOutOfPolicy;
      this.hasSelectEntity = extraSettings.hasSelectEntity;
    }
  }

  modify(): void {
    const config: DynamicDialogConfig = new DynamicDialogConfig();

    if (this.commonService.isTablet) {
      setTimeout((): void => {
        config.height = `100vh`;
      }, 0);
    } else {
      config.height = "auto";
    }
    config.width = this.commonService.isTablet ? "100vw" : "1100px";
    config.showHeader = false;
    config.dismissableMask = true;
    config.styleClass = "search-form-dialog";
    config.data = this.search;
    this.modalService.openModal(SearchEngineComponent, config);
  }

  dataLoading(value: boolean): void {
    this.loading = value;
  }

  // Function to handle when an item has been choosen (car, hotel, flight, train...)
  openConfirmationPanel(data: any): void {
    this.choosenObject = data;
    this.commonService.statusBarTextBlack();

    if (data && data.autoPrebook) {
      this.prebook();
    }
    if (data && data.fees) {
      this.fees = data.fees;
    }
  }

  prebook(): void {
    if (
      this.bookingInProgress ||
      this.termsAgreementRequired ||
      this.deliveryOptionRequired ||
      this.informationRequired ||
      this.estimationLoading
    ) {
      return;
    }
    this.loading = true;
    this.bookingInProgress = true;
    const userIds: Array<string> = this.search.data.userIds;

    let price: UtilsTypes.Price;
    let formData: any = {
      searchId: this.search.id,
    };

    switch (this.search.type) {
      case "car":
        price = this.choosenObject.offer.totalPrice;
        formData = {
          ...formData,
          search: { ...this.search.data },
          ...this.choosenObject,
        };
        break;
      case "hotel":
        price = {
          currency: this.choosenObject.fare.currency,
          amount: Number(this.choosenObject.fare.price),
        };
        const hotelCountry = this.choosenObject.hotel.metadata.parentPlaces.find(
          (place) => place.type === "country",
        )?.name;
        formData = {
          ...formData,
          ...this.choosenObject.fare,
          board: this.search.data.rooms.map(
            (room: HotelTypes.Rooms): Array<string> =>
              room.members.map((member: MemberSociety): string => member.user._id),
          ),
          hotelTitle: this.choosenObject.hotel.description.title,
          hotelChain: this.choosenObject.hotel.description.chain,
          hotelAddress: this.choosenObject.hotel.description.address,
          hotelPhone: this.choosenObject.hotel.description.phone,
          hotelCity: this.choosenObject.hotel.description.city,
          hotelCountry,
          hotelRating: this.choosenObject.hotel.description.rank,
          coordinates: this.choosenObject.hotel.coordinates,
        };
        break;
      case "flight":
        formData.itineraries = this.choosenObject.itineraries;
        formData.settings = this.choosenObject.settings;
        formData.users = this.choosenObject.users;
        formData.superflex = this.choosenObject.superflex;
        formData.limitDate = moment().add(1, "days").toISOString();
        price = this.choosenObject.price;
        break;
      case "train":
        price = this.choosenObject.price;
        formData = {
          ...formData,
          ...this.choosenObject,
          friendlyId: this.search.data.carrierReference,
        };
        formData.limitDate = moment().add(1, "days").toISOString();
        break;
      case "transfer":
        price = this.choosenObject.price;
        formData = {
          ...formData,
          ...this.choosenObject,
        };
        formData.limitDate = moment().add(1, "days").toISOString();
        break;
    }

    let basketId: string;
    this.basketService
      .createBasket(this.modifyWithoutValidation)
      .pipe(
        tap((basket: Basket): void => {
          basketId = basket.id;
          console.log({ basketId });
        }),
        switchMap((): Observable<any> => {
          return this.basketService.addItemToBasket(
            userIds,
            price,
            this.search.type,
            formData,
            this.translateService.currentLang,
            this.search.oldItemId,
            this.relativeBasketId,
            this.relativeFolderId,
          );
        }),
        switchMap((basket: Basket): Observable<any> => {
          return iif(
            (): boolean => this.modifyWithoutValidation,
            this.basketService.getBaskets().pipe(
              switchMap((): Observable<any> => {
                const foundBasket: any = this.basketService.baskets$.value.find((_basket: any): boolean => {
                  return _basket.id === basketId;
                });
                if (foundBasket.status !== "booking") {
                  return this.basketService
                    .validate(foundBasket.id, {
                      comment: undefined,
                      labels: [],
                      billingId: null,
                      modifyWithoutValidation: this.modifyWithoutValidation,
                      oldItemId: this.search.oldItemId === null ? undefined : this.search.oldItemId,
                    })
                    .pipe(
                      switchMap(() =>
                        foundBasket.folderIds.map((folderId) =>
                          this.basketService.book(foundBasket.id, folderId, this.translateService.currentLang),
                        ),
                      ),
                      switchMap((bufferItem: Observable<any>): Observable<any> => {
                        return bufferItem;
                      }),
                    );
                }
                return undefined;
              }),
            ),
            of(undefined),
          );
        }),
        switchMap((): Observable<any> => {
          return this.basketService.getBaskets();
        }),
      )
      .subscribe({
        next: (): void => {
          this.afterBook = true;
          this.closeConfirmationPopup();
          this.bookingInProgress = false;

          if (this.modifyWithoutValidation) {
            this.messageService.add({
              severity: "success",
              summary: this.translateService.instant("BASKET.MODIFIED"),
            });
            this.router.navigate(["/"]);
          } else {
            this.router.navigate(["/", "baskets", basketId]);
          }
        },
        error: (err): void => {
          console.error("Error occurred during booking:", err);
          this.loading = false;
          this.bookingInProgress = false;
        },
      });
  }

  closeConfirmationPopup(): void {
    if (this.choosenObject.provider === "trainline") {
      this.searchService
        .get(this.searchId)
        .subscribe((data: { search: object; averageTiming: number; users: Array<User>; society: Society }): void => {
          this.search = data.search;
          this.averageTiming = data.averageTiming;
          this.users = data.users;
        });
    } else if (this.choosenObject.hotel) {
      this.commonService.statusBarTextBlack();
    } else {
      this.commonService.statusBarTextWhite();
    }
    this.choosenObject = undefined;

    this.confirmationSideBarOpen = false;

    this.removeLast.next(true);
  }

  toggleHeader(): void {
    this.headerDisplayed = !this.headerDisplayed;
  }

  magicFormula(): void {
    this.router.navigate(["/"]);
  }

  toggleSelectionSidebar($event): void {
    this.selectionSidebarOpen = $event;
  }

  termsOfUse(termsOfUseOptions: TrainTypes.TermsOfUse[]) {
    this.termsOfUseCheckboxes = termsOfUseOptions;
    this.toggleTerms(this.termsOfUseCheckboxes);
  }

  toggleTerms(terms: Array<{ label: string; value: boolean }>): void {
    // Si au moins un des termes n'est pas coché: this.termsAgreementRequired = true
    this.termsAgreementRequired = terms.some((term) => !term.value);
  }
}
