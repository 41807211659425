import { DialogService, DynamicDialogConfig } from "primeng/dynamicdialog";
import { StepsModule } from "primeng/steps";
import { VirtualScrollerModule } from "primeng/virtualscroller";
import { TravelCarbonOffsetComponent } from "../travel/carbon-offset/carbon-offset.component";
import { TravelCarbonOffsetInfoComponent } from "../travel/carbon-offset/info/info.component";
import { TravelTrainSeatReservationsComponent } from "../travel/train/seat-reservations/seat-reservations.component";
import { TravelTrainSegmentsComponent } from "../travel/train/segments/segments.component";

import { AddressPipe } from "./pipes/address.pipe";
import { AlphabeticOrderPipe } from "./pipes/alphabeticOrder.pipe";
import { AutoCompleteModule } from "primeng/autocomplete";
import { AvatarComponent } from "../dashboard/@dashboard-shared/components/avatar/avatar.component";
import { CalendarModule } from "primeng/calendar";
import { CheckboxModule } from "primeng/checkbox";
import { CloudBackgroundComponent } from "./components/cloud-background/cloud-background.component";
import { ConfirmationSidebarCarComponent } from "./components/travel/confirmation/car/confirmation-sidebar-car.component";
import { ConfirmationSidebarHotelComponent } from "./components/travel/confirmation/hotel/confirmation-sidebar-hotel.component";
import { DateAdjustmentPipe } from "./pipes/date-adjustment.pipe";
import { DateParserPipe } from "./pipes/dateParser.pipe";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CardModule } from "primeng/card";
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { ContextMenuModule } from "primeng/contextmenu";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { FilterPipe } from "./pipes/filter.pipe";
import {
  GoogleAutocompleteDirective,
  GooglePlacesDirective,
  ScrollAnchorDirective,
  ScrollManagerDirective,
  ScrollSectionDirective,
} from "./directives";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { IsFuturePipe } from "./pipes/is-future.pipe";
import { KiloFormatterPipe } from "./pipes/kilo-formatter.pipe";
import { LabelSideComponent } from "./components/label-side/label-side.component";
import { LocalizedDatePipe } from "./pipes/localized-date.pipe";
import { MenuComponent } from "./components/menu/menu.component";
import { MenubarModule } from "primeng/menubar";
import { MultiSelectModule } from "primeng/multiselect";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipModule } from "primeng/tooltip";
import { SearchPipe } from "./pipes/search.pipe";
import { FusePipe } from "./pipes/fuse.pipe";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PlaceFinderComponent } from "./place-finder/place-finder.component";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { SidebarComponent } from "./components/travel/sidebar.component";
import { SidebarModule } from "primeng/sidebar";
import { StarsPipeModule } from "../dashboard/@dashboard-shared/pipes/stars/stars.module";
import { TimePipe } from "./pipes/time.pipe";
import { ToggleButtonModule } from "primeng/togglebutton";
import { MessagesModule } from "primeng/messages";
import { ConfirmationSidebarTrainComponent } from "./components/travel/confirmation/train/confirmation-sidebar-train.component";
import { ConfirmationSidebarFlightComponent } from "./components/travel/confirmation/flight/confirmation-sidebar-flight.component";
import { TravelFlightItineraryLegComponent } from "../travel/flight/itinerary/leg/travel-flight-itinerary-leg.component";
import { TravelFlightItineraryLegPointComponent } from "../travel/flight/itinerary/leg/point/travel-flight-itinerary-leg-point.component";
import { RadioButtonModule } from "primeng/radiobutton";
import { RefundTextPipe } from "./pipes/refund-text.pipe";
import { IndexOfPipe } from "./pipes/index-of.pipe";
import { FromNowPipe } from "./pipes/date-from.pipe";
import { TagsComponent } from "./components/tags/tags.component";
import { StickyHeaderComponent } from "./components/sticky-header/sticky-header.component";
import { FlightTimeToDurationPipe } from "../travel/flight/flight-time-to-duration.pipe";
import { ModalService } from "./services/modal.service";
import { LoadingV2Component } from "./components/loading-v2/loading.component";
import { SumPipe } from "./pipes/sum.pipe";
import { DetailInformationComponent } from "../travel/flight/detail/information/information.component";
import { ConfirmationSidebarTransferComponent } from "./components/travel/confirmation/transfer/confirmation-sidebar-transfer.component";
import { DurationPipe } from "./pipes/duration.pipe";
import { PasswordStrengthBarComponent } from "./components/password-strength-bar/password-strength-bar.component";
import { ScrollerModule } from "primeng/scroller";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ConditionsTrainComponent } from "./components/travel/conditions/train/conditions-train.component";
import { TooltipComponent } from "../dashboard/@dashboard-shared/components/tooltip/tooltip.component";
import { SPTPricePipe } from "./pipes/currency.pipe";
import { SpecificRateComponent } from "./components/specific-rate/specific-rate.component";
import { TravelSeatmapCompartmentComponent } from "./components/travel/seatmap/compartment/compartment.component";
import { InputSearchPolyfillDirective } from "./directives/input-search-polyfill.directive";
import { DescriptionCarComponent } from "./components/travel/car/description/car-description.component";
import { HeaderMobileComponent } from "./components/header-mobile/header-mobile.component";
import { InputSwitchModule } from "primeng/inputswitch";
import { PaginatorModule } from "primeng/paginator";
import { NormalizeFilterPipe } from "./pipes/normalize-filter.pipe";
import { OutOfPolicyComponent } from "./components/out-of-policy/out-of-policy.component";

const SHARED_MODULES: Array<any> = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MenubarModule,
  ButtonModule,
  ProgressSpinnerModule,
  CardModule,
  DialogModule,
  DynamicDialogModule,
  InputTextModule,
  InputTextareaModule,
  CalendarModule,
  CheckboxModule,
  AutoCompleteModule,
  TranslateModule,
  MultiSelectModule,
  ToggleButtonModule,
  TooltipModule,
  SidebarModule,
  DropdownModule,
  ContextMenuModule,
  OverlayPanelModule,
  StarsPipeModule,
  MessagesModule,
  RadioButtonModule,
  VirtualScrollerModule,
  ScrollerModule,
  InputTextareaModule,
  StepsModule,
  ScrollingModule,
  InputSwitchModule,
  PaginatorModule,
];

const SHARED_PIPES: any[] = [
  FilterPipe,
  SearchPipe,
  DateAdjustmentPipe,
  FusePipe,
  IndexOfPipe,
  FromNowPipe,
  DateParserPipe,
  TimePipe,
  IsFuturePipe,
  LocalizedDatePipe,
  AddressPipe,
  AlphabeticOrderPipe,
  KiloFormatterPipe,
  RefundTextPipe,
  FlightTimeToDurationPipe,
  SumPipe,
  DurationPipe,
  SPTPricePipe,
  NormalizeFilterPipe,
];

const SHARED_COMPONENTS: Array<any> = [
  LoadingV2Component,
  MenuComponent,
  PlaceFinderComponent,
  LabelSideComponent,
  AvatarComponent,
  CloudBackgroundComponent,
  ConfirmationSidebarCarComponent,
  ConfirmationSidebarHotelComponent,
  ConfirmationSidebarTrainComponent,
  ConfirmationSidebarFlightComponent,
  ConfirmationSidebarTransferComponent,
  DetailInformationComponent,
  TravelFlightItineraryLegComponent,
  TravelFlightItineraryLegPointComponent,
  TravelTrainSegmentsComponent,
  TravelTrainSeatReservationsComponent,
  TravelCarbonOffsetComponent,
  TravelCarbonOffsetInfoComponent,
  TravelSeatmapCompartmentComponent,
  SidebarComponent,
  TagsComponent,
  StickyHeaderComponent,
  PasswordStrengthBarComponent,
  ConditionsTrainComponent,
  TooltipComponent,
  SpecificRateComponent,
  DescriptionCarComponent,
  HeaderMobileComponent,
  OutOfPolicyComponent,
];

const SHARED_DIRECTIVES: any[] = [
  GooglePlacesDirective,
  GoogleAutocompleteDirective,
  ScrollManagerDirective,
  ScrollAnchorDirective,
  ScrollSectionDirective,
];

@NgModule({
  declarations: [
    ...SHARED_DIRECTIVES,
    ...SHARED_COMPONENTS,
    ...SHARED_PIPES,
    PasswordStrengthBarComponent,
    InputSearchPolyfillDirective,
  ],
  imports: [
    // BillingsModule,
    ...SHARED_MODULES,
  ],
  exports: [...SHARED_MODULES, ...SHARED_DIRECTIVES, ...SHARED_COMPONENTS, ...SHARED_PIPES],
  providers: [DialogService, DynamicDialogConfig, ...SHARED_PIPES, ...SHARED_DIRECTIVES, CurrencyPipe, ModalService],
})
export class SharedModule {}
