import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CommonService } from "./common.service";
import moment from "moment";
import { map } from "rxjs/operators";
import { UserLabel } from "../@types/label";

export interface OptsBill {
  billingIds?: string[];
  from: string;
  to: string;
  userIds?: string[];
  types?: string[];
  labels?: any[];
  query?: string;
  billingType?: string;
  itemTypes?: string[];
}

@Injectable({
  providedIn: "root",
})
export class BillService {
  constructor(
    private commonService: CommonService,
    private httpClient: HttpClient,
  ) {}

  getBills(optsBill: OptsBill): any {
    const params: any = {
      from: optsBill.from,
      to: optsBill.to,
      ...(optsBill.billingIds &&
        optsBill.billingIds.length > 0 && {
          billingIds: optsBill.billingIds.join(","),
        }),
      ...(optsBill.userIds && optsBill.userIds.length > 0 && { userIds: optsBill.userIds.join(",") }),
      ...(optsBill.types && optsBill.types.length > 0 && { types: optsBill.types.join(",") }),
      ...(optsBill.labels &&
        optsBill.labels.length > 0 && {
          labels: optsBill.labels
            .filter((label: UserLabel) => !!label.label)
            .map((label: UserLabel) => `${label.label};;${label.value}`)
            .join(","),
        }),
      billingType: optsBill.billingType,
      ...(optsBill.itemTypes &&
        optsBill.itemTypes.length > 0 && {
          itemTypes: optsBill.itemTypes.join(","),
        }),
    };

    if (optsBill.query && optsBill.query !== "") {
      params.query = optsBill.query;
    }

    return this.httpClient
      .get(`${environment.api}/bills2`, {
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(
        map((bills: any) => {
          return bills.map((data: any) => {
            if (data.type === "unitary") {
              data.isUnitary = true;
            } else {
              data.isUnitary = false;
            }
            if (data.type === "credit") {
              data.isCreditNote = true;
            } else {
              data.isCreditNote = false;
            }
            return data;
          });
        }),
      );
  }

  getBillByBooking(booking: string, type: string): Observable<boolean> {
    const params: HttpParams = new HttpParams().set("type", type);
    return this.httpClient
      .get(`${environment.api}/api/bills/booking/${booking}`, { params })
      .pipe(map((result: any) => result.data));
  }

  getBillByInput(inputValue: any) {
    const params: any = {
      query: inputValue.query,
    };

    return this.httpClient.get(`${environment.api}/bills2/getByInput`, {
      params: new HttpParams({ fromObject: params }),
    });
  }

  createCustomCsv(
    begin: moment.Moment,
    end: moment.Moment,
    billingIds?: Array<string>,
  ): Observable<Array<{ data: string; type: string }>> {
    const endPoint: string = `${environment.api}/bills2/csv`;

    let params: HttpParams = new HttpParams({
      fromObject: {
        fromdate: begin.format("YYYY-MM-DD"),
        todate: end.format("YYYY-MM-DD"),
      },
    });
    if (billingIds) {
      params = params.set("billingIds", billingIds.toString());
    }

    return this.httpClient.get(endPoint, { headers: { ignoreErrorMessage: "true" }, params }).pipe(
      map((res: any) => {
        if (res && res.data) {
          return res.data;
        } else {
          return [];
        }
      }),
    );
  }

  private downloadZip(urls: any, buckets: Array<string>, fileType: any, name?: any, names?: any): any {
    return this.httpClient.post(
      `${environment.api}/api/zip`,
      {
        urls,
        buckets,
        fileType,
        name,
        names,
      },
      { responseType: "blob" },
    );
  }

  getDocument(billId: string, type: "csv" | "pdf") {
    return this.httpClient.get(`${environment.api}/bills2/${billId}/${type}`);
  }

  getBillInfo(itemId: string) {
    return this.httpClient.get(`${environment.api}/bills2/${itemId}/bills`);
  }

  download(urls: Array<string>, buckets: Array<any>, archiveName: string, fileType: any, name?: any, names?: any): any {
    const files: Array<any> = [];
    urls.forEach((url: string) => {
      files.push({ url });
    });
    buckets.forEach((file: any) => {
      files.push({ bucket: file.bucket, key: file.key });
    });
    this.commonService.downloadZip(files, fileType, name, names).subscribe((data: any) => {
      saveAs(data, archiveName);
    });
  }
}
