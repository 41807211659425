import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { SelectItem } from "primeng/api";
import { CommonService } from "../../../../../@shared/services/common.service";
import { TrainTypes } from "../../../../../travel/train/train";
import { Reason, TrainService } from "../../../../../travel/train/train.service";
import { LoadingService } from "../../../../../@shared/services/loading.service";
import { forkJoin, mergeMap, Observable, of, Subscription } from "rxjs";
import { UtilsTypes } from "../../../../../@shared/@types/utils";
import { SearchService } from "src/app/@shared/services/search.service";
import { Router } from "@angular/router";
import { FlightService } from "src/app/travel/flight/flight.service";
import { FlightService as SharedFlightService } from "../../../../../@shared/services/flight.service";
@Component({
  selector: "spt-reservations-modify-flight",
  templateUrl: "./flight.component.html",
  styleUrls: ["./flight.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservationsModifyFlightComponent implements OnInit, OnDestroy {
  @Input() itemId: string;
  @Input() item: any;
  @Input() orderId: string;
  @Input() orderItems: Array<TrainTypes.OrderItem>;
  @Input() relatedOrderItems: Array<TrainTypes.RelatedOrderItem>;
  @Input() isSmallDevice: boolean;
  @Input() locale: string;
  @Input() travelers: Array<any>;
  @Input() provider: TrainTypes.Provider;
  @Input() hasTickets: boolean;

  protected airports: any;
  protected retrieveOrderData: any;
  private exchangeOptions: TrainTypes.Option[] = [];
  protected journeys: any[] = [];
  protected noChangeableOptions: boolean = false;
  protected yearRange: string = "1930:" + new Date().getFullYear();
  protected hourWindowTypes: Array<SelectItem> = [
    {
      label: this.translateService.instant("SEARCH.DEPARTURE_AFTER"),
      value: "DepartsAfter",
    },
    {
      label: this.translateService.instant("SEARCH.ARRIVAL_BEFORE"),
      value: "ArrivesBefore",
    },
  ];
  protected minDate: Date;
  protected times: Array<any> = [
    { label: "--:--", value: "00:00" },
    { label: "06:00", value: "06:00" },
    { label: "08:00", value: "08:00" },
    { label: "10:00", value: "10:00" },
    { label: "12:00", value: "12:00" },
    { label: "14:00", value: "14:00" },
    { label: "16:00", value: "16:00" },
    { label: "18:00", value: "18:00" },
    { label: "20:00", value: "20:00" },
    { label: "22:00", value: "22:00" },
  ];

  private subscriptions: Subscription[] = [];
  protected modifyForm: FormGroup<ExchangeForm>;

  protected COJFee: UtilsTypes.Price;

  constructor(
    private trainService: TrainService,
    private translateService: TranslateService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private loadingService: LoadingService,
    private searchService: SearchService,
    private flightService: FlightService,
    private sharedFlightService: SharedFlightService,
    private router: Router,
  ) {}

  private buildForm(): FormGroup<ExchangeForm> {
    const form = this.fb.group<ExchangeForm>({
      journeyIndex: this.fb.control(undefined),
      date: this.fb.control(undefined, Validators.required),
      hourWindowTime: this.fb.control("00:00", Validators.required),
      hourWindowType: this.fb.control("DepartsAfter", Validators.required),
    });
    this.subscriptions.push(form.controls.journeyIndex.valueChanges.subscribe((index) => this.onSelectJourney(index)));
    return form;
  }

  private static filterActiveTrips(flights: any) {
    const currentDate = new Date();

    const filteredFlights = flights.filter((flight) => {
      const scheduledDateTime = new Date(flight.Arrival.AircraftScheduledDateTime);
      return scheduledDateTime > currentDate;
    });
    return filteredFlights;
  }

  private onSelectJourney(index?: number): void {
    this.modifyForm.controls.date.setValue(DateTime.fromISO(this.journeys[index].Dep.utc).toJSDate());
  }

  private createExchangeBody() {
    const formValue: ExchangeFormValue = this.modifyForm.value as ExchangeFormValue;
    const userIds: Array<string> = [];
    const trips = this.buildSearchJourney(formValue)
    const cabinClassPreferred = trips[0].cabinClassPreferred
    this.item.travelers.forEach((traveler) => {
      userIds.push(traveler.userId);
    });
    const itemSegments = this.retrieveOrderData.data.retrieveOrder?.dataList.PaxSegmentList.PaxSegment
    const findedItemSegment = itemSegments?.length >=2 ? itemSegments.filter(segment => {
      const departureMatch = segment.Dep.IATALocationCode !== trips[0].departureAirport.iata;
      const arrivalMatch = segment.Arrival.IATALocationCode !== trips[0].arrivalAirport.iata;

      return departureMatch && arrivalMatch;
  }) : null;
    const findItemSegment = findedItemSegment?.length ? findedItemSegment[0] : null;
    const segmentId = findItemSegment?.PaxSegmentID

    const result =  {
      trips,
      userIds,
      type: "simple",
      baggages: null,
      cabinClassPreferred,
      isRebookingAFKL: true,
      segmentId,
    };
    
    return result;
  }

  private buildSearchJourney(formValue: ExchangeFormValue): any {
    const selectedJourney = this.journeys[formValue.journeyIndex];
    const departureDate = new Date(formValue.date)
    const departureYear = departureDate.getFullYear();
    const departureMonth = (departureDate.getMonth() + 1).toString().padStart(2, '0'); 
    const departureDay = departureDate.getDate().toString().padStart(2, '0');
    const localDate = `${departureYear}-${departureMonth}-${departureDay}`;
    const trip = {
      departureDate: localDate,
      departureAirport: {
        name: this.airports[selectedJourney.Dep.IATALocationCode].name,
        iata: this.airports[selectedJourney.Dep.IATALocationCode].iata,
        country: this.airports[selectedJourney.Dep.IATALocationCode].country,
      },
      departureHourWindow: {
        begin: "00:00",
        end: "23:59",
        value: 0
      },
      arrivalHourWindow: {
        begin: "00:00",
        end: "23:59",
      },
      arrivalAirport: {
        name: this.airports[selectedJourney.Arrival.IATALocationCode].name,
        iata: this.airports[selectedJourney.Arrival.IATALocationCode].iata,
        country: this.airports[selectedJourney.Arrival.IATALocationCode].country,
      },
      baggages: null,
      cabinClassPreferred: this.getClassCode(this.item.detail.trips[0].CabinType.CabinTypeName)
    }
    return [trip]
  }

  private getClassCode(className: string) {
    const classMap = {
      ECONOMY: "Y",
      PREMIUM: "J",
      BUSINESS: "C",
      FIRST: "F",
    };

    return classMap[className];
  }

  private mapChangeables(exchangeOptions: any): void {
    const now = DateTime.now().toMillis();
    this.retrieveOrderData = exchangeOptions;

    this.journeys = ReservationsModifyFlightComponent.filterActiveTrips(this.item.detail.trips);
    if (this.journeys.length > 0) {
      this.modifyForm = this.buildForm();
    } else {
      this.noChangeableOptions = true;
      this.modifyForm = undefined;
    }
    this.loadingService.remove();
    this.changeDetector.markForCheck();
  }

  ngOnInit(): void {
    const searchAirports = [];
    this.item.detail.trips.forEach((trip: any) => {
      searchAirports.push(trip.Dep.IATALocationCode);
      searchAirports.push(trip.Arrival.IATALocationCode);
    });
    this.sharedFlightService.listAirports(searchAirports).subscribe((data) => {
      this.airports = data;
    });
    this.loadingService.add();
    this.flightService.getFlightOrder(this.item).subscribe({
      next: (_refundResponse: any) => {
        this.mapChangeables(_refundResponse);
      },
      error: (): void => this.loadingService.remove(),
    });
  }

  calendarShown(calendar?: any): void {
    this.commonService.setBackFunction((): void => {
      calendar.toggle();
    }, this);
  }

  calendarClosed(): void {
    this.commonService.unsetBackFunction();
  }

  ngOnDestroy(): void {
    this.subscriptions.filter((s) => !s.closed).forEach((s) => s.unsubscribe());
  }

  searchExchange(): void {
    const exchangeBody = this.createExchangeBody()
    
    this.searchService.create("flight", this.createExchangeBody(), this.locale, this.itemId)
      .subscribe(
      (search: { id: string }): Promise<boolean> =>
        this.router.navigate(["travel", search.id], {
          queryParams: {
            itemId: this.itemId,
          },
        })
    );
  }

  findSegment(segments, searchObject) {

    const data = segments.find(segment => {
        const departureMatch = segment.Dep.IATALocationCode === searchObject.departureAirport.iata;
        const arrivalMatch = segment.Arrival.IATALocationCode === searchObject.arrivalAirport.iata;
        const cabinClassMatch = segment.CabinType.CabinTypeCode === searchObject.cabinClassPreferred;
        
        const departureDateMatch = segment.Dep.AircraftScheduledDateTime.startsWith(searchObject.departureDate);

        // Comparer l'heure de départ
        const departureTime = segment.Dep.AircraftScheduledDateTime.split('T')[1];
        const departureTimeMatch = departureTime >= searchObject.departureHourWindow.begin && departureTime <= searchObject.departureHourWindow.end;

        // Comparer l'heure d'arrivée
        const arrivalTime = segment.Arrival.AircraftScheduledDateTime.split('T')[1];
        const arrivalTimeMatch = arrivalTime >= searchObject.arrivalHourWindow.begin && arrivalTime <= searchObject.arrivalHourWindow.end;

        return departureMatch && arrivalMatch && cabinClassMatch && departureDateMatch && departureTimeMatch && arrivalTimeMatch;
    });

    return data;
}

}
function getTime(date: any): number {
  if (!date) {
    return NaN;
  }
  if (typeof date.getTime === "function") {
    return date.getTime();
  }
  return Date.parse(date.toString());
}
export interface ExchangeFormValue {
  journeyIndex: number;
  date: Date;
  hourWindowTime: string;
  hourWindowType: TrainTypes.JourneySearchType;
}
export interface ExchangeForm {
  journeyIndex: FormControl<number>;
  date: FormControl<Date>;
  hourWindowTime: FormControl<string>;
  hourWindowType: FormControl<TrainTypes.JourneySearchType>;
}
