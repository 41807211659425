import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { RequestTrain, ItemTrain } from "../../../../../../@shared/@types/models";
import { CommonService } from "../../../../../../@shared/services/common.service";

@Component({
  selector: "spt-request-train",
  templateUrl: "./train.component.html",
  styleUrls: ["../request.component.scss", "./train.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RequestTrainComponent implements OnInit {
  @Input() request: RequestTrain;
  type: string;
  legsLength: number;
  item: ItemTrain;
  constructor(public commonService: CommonService) {}

  ngOnInit(): void {
    this.item = this.request.item as ItemTrain;
    if (this.item.metadata.infosTrips.length === 1) {
      this.type = "simple";
    } else if (this.item.metadata.infosTrips.length === 2) {
      this.type = "round";
    } else {
      this.type = "multi";
    }

    this.legsLength = this.item.metadata.infosTrips.length;
  }
}
