import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { CommonService } from "src/app/@shared/services/common.service";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { DEFAULT_DATE_FORMAT, UserService } from "src/app/@shared/services/user.service";
@Component({
  selector: "spt-flight-detail-information",
  styleUrls: ["./information.component.scss"],
  templateUrl: "./information.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class DetailInformationComponent implements OnInit {
  @Input() dateEnd: any;
  @Input() pricingInformation: any;
  @Input() selectedItinerary?: any;
  @Input() user: any;
  @Input() index: number;
  @Input() provider?: any;
  @Input() isValidateOptionInformation: boolean = false;
  passengerInformationPrice: any;

  dateFormat: string = DEFAULT_DATE_FORMAT;
  private observablesToClearOnDestroy: Subscription[] = [];

  public refundable: boolean;
  public exchangeable: boolean;
  public carryBagageDesc: string;

  constructor(
    public commonService: CommonService,
    private translateService: TranslateService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.observablesToClearOnDestroy.push(
      this.userService.user.subscribe((user) => {
        this.dateFormat = user?.settings?.nativeDateFormat || DEFAULT_DATE_FORMAT;
      }),
    );
    

    if (this.pricingInformation.fare.passengerInfos[this.index]) {
      if (this.selectedItinerary !== undefined && !this.isValidateOptionInformation) {
        this.passengerInformationPrice = parseFloat(
          this.pricingInformation.fare.passengerInfos[this.index]?.passengerTotalFare.totalFare +
            this.pricingInformation.fare.passengerInfos[this.index]?.obFees?.reduce((obFee, f) => obFee + f.amount, 0) +
            (this.selectedItinerary?.pricingInformations[0]?.fare.passengerInfos[this.index]?.passengerTotalFare
              .totalFare +
              this.selectedItinerary?.pricingInformations[0]?.fare.passengerInfos[this.index]?.obFees?.reduce(
                (obFee, f) => obFee + f.amount,
                0,
              )),
        );
      } else {
        this.passengerInformationPrice = this.pricingInformation.fare.passengerInfos[this.index]?.obFees
          ? this.pricingInformation.fare.passengerInfos[this.index]?.passengerTotalFare.totalFare +
            this.pricingInformation.fare.passengerInfos[this.index]?.obFees?.reduce((obFee, f) => obFee + f.amount, 0)
          : this.pricingInformation.fare.passengerInfos[this.index]?.passengerTotalFare.totalFare;
      }
    } else {
      if (this.selectedItinerary !== undefined && !this.isValidateOptionInformation) {
        this.passengerInformationPrice = parseFloat(
          this.pricingInformation.fare.passengerInfos[0]?.passengerTotalFare.totalFare +
            this.pricingInformation.fare.passengerInfos[0]?.obFees?.reduce((obFee, f) => obFee + f.amount, 0) +
            (this.selectedItinerary?.pricingInformations[0]?.fare.passengerInfos[0]?.passengerTotalFare
              .totalFare +
              this.selectedItinerary?.pricingInformations[0]?.fare.passengerInfos[0]?.obFees?.reduce(
                (obFee, f) => obFee + f.amount,
                0,
              )),
        );
      } else {
        this.passengerInformationPrice = this.pricingInformation.fare.passengerInfos[0]?.obFees
          ? this.pricingInformation.fare.passengerInfos[0]?.passengerTotalFare.totalFare +
            this.pricingInformation.fare.passengerInfos[0]?.obFees?.reduce((obFee, f) => obFee + f.amount, 0)
          : this.pricingInformation.fare.passengerInfos[0]?.passengerTotalFare.totalFare;
      }
    }
    if (!this.provider) {
      this.refundable = this.pricingInformation.fare.passengerInfos[this.index]?.penaltiesInfo?.penalties?.some(
        (penalty) => penalty.applicability === "Before" && penalty.type === "Refund" && penalty.amount > -1,
      );
      this.exchangeable = this.pricingInformation.fare.passengerInfos[this.index]?.penaltiesInfo?.penalties?.some(
        (penalty) => penalty.applicability === "Before" && penalty.type === "Exchange" && penalty.amount > -1,
      );
    } else {
      this.refundable = this.pricingInformation.refundable ? this.pricingInformation.refundablePenalities : undefined;
      this.exchangeable = this.pricingInformation.changeable ? this.pricingInformation.changeablePenalities : undefined;
    }
    this.getLuggageDesc();
  }

  getLuggageDesc() {
    let { governingCarriers } = this.pricingInformation.fare;
    let { cabinCodes } = this.pricingInformation;
    if (governingCarriers) {
      let carryDescription = "GLOBAL.FLIGHT_BAGAGES.INFORMATION.CARRY." + governingCarriers;
      if (["AF", "TU"].includes(governingCarriers)) {
        carryDescription += `.${cabinCodes?.[0]}`;
      }
      let trad = this.translateService.instant(carryDescription);
      if (trad !== carryDescription) {
        this.carryBagageDesc = trad;
        return;
      }
    }
    this.carryBagageDesc = this.translateService.instant("GLOBAL.FLIGHT_BAGAGES.INFORMATION.CARRY_NO_INFO");
  }
}
