import { Injectable } from "@angular/core";
import { NgForage } from "ngforage";
import { forkJoin, from, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  constructor(public readonly storage: NgForage) {}

  public setItem(key: string, data: any): Observable<any> {
    return from(this.storage.setItem(key, data));
  }

  public getItem(key: string): Observable<any> {
    return from(this.storage.getItem(key));
  }

  public removeItem(key: string): Observable<any> {
    return from(this.storage.removeItem(key));
  }

  public clear(): Observable<any> {
    return forkJoin([
      from(this.storage.removeItem("session")),
      from(this.storage.removeItem("blackListAir")),
      from(this.storage.removeItem("blackListHotel")),
    ]);
  }
}
