import { Component, Input, OnInit } from "@angular/core";
import { FlightService } from "src/app/travel/flight/flight.service";
import _companies from "src/app/@shared/companies";

@Component({
  selector: "spt-itinerary-flight",
  templateUrl: "./flight.component.html",
  styleUrls: ["./flight.component.scss"],
})
export class ItineraryFlightComponent implements OnInit {
  @Input() item: any;
  public itineraries: any[];
  public viewOnly = true;
  public companies: any = _companies;

  constructor(private flightService: FlightService) {}

  ngOnInit(): void {
    this.itineraries = this.item.detail.trips;
    this.flightService.airports = this.item.detail.airports;
  }
}
