import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { filter, first } from "rxjs/operators";
import { Roles } from "../@types/society";
import { RoleService } from "../services/roles.service";

@Injectable({
  providedIn: "root",
})
export class RoleResolver {
  constructor(private roleService: RoleService) {}

  resolve(): Observable<Roles> {
    return this.roleService.roles.pipe(
      filter((role: Roles) => !!role),
      first(),
    );
  }
}
