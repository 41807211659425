import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { CurrencyPipe } from "@angular/common";

@Pipe({
  name: "refundText",
})
export class RefundTextPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private currencyPipe: CurrencyPipe,
  ) {}

  transform(data: any, _datein: any, price: number): string {
    const [datein] = _datein.toString().split("+");
    if (!data) {
      return;
    }
    if (data.from.ref && (data.to.day === "999" || data.to.day === null || data.to.day === undefined)) {
      return this.translateService.instant("SEARCH.RESULT.HOTEL.REFUNDS.NO_REFUND");
    } else {
      if (data.from.ref === "after_booking") {
        return this.translateService.instant("SEARCH.RESULT.HOTEL.REFUNDS.NO_REFUND");
      } else {
        const dateBegin: moment.Moment = moment(datein).subtract(data.to.day, "days").hours(data.to.time);
        const dateEnd: moment.Moment = moment(datein)
          .subtract(data.from.day, "days")
          .hours(data.from.time)
          .subtract(1, "second");
        if ((moment().isBetween(dateBegin, dateEnd) || moment().isAfter(dateEnd)) && Number(data.amount) >= price) {
          return this.translateService.instant("SEARCH.RESULT.HOTEL.REFUNDS.NO_REFUND");
        } else {
          if (moment().isSame(dateBegin, "day") && moment().isBefore(dateBegin, "hours")) {
            if (Number(data.amount) >= price) {
              return this.translateService.instant("SEARCH.RESULT.HOTEL.REFUNDS.FREE_REFUND_POLICY_LATER_TODAY", {
                timeRefund: dateBegin.format("HH:mm"),
              });
            }
            return this.translateService.instant("SEARCH.RESULT.HOTEL.REFUNDS.REFUND_POLICY_LATER_TODAY", {
              price: this.currencyPipe.transform(data.amount, data.currency, "symbol", "1.2-2"),
              timeRefund: dateBegin.format("HH:mm"),
            });
          }
          if (moment().isSameOrAfter(dateBegin, "day")) {
            return this.translateService.instant("SEARCH.RESULT.HOTEL.REFUNDS.REFUND_POLICY_TODAY", {
              price: this.currencyPipe.transform(data.amount, data.currency, "symbol", "1.2-2"),
              dateRefund: dateBegin.format("DD/MM/YYYY"),
              timeRefund: dateBegin.format("HH:mm"),
            });
          } else {
            return this.translateService.instant("SEARCH.RESULT.HOTEL.REFUNDS.REFUND_POLICY", {
              price: this.currencyPipe.transform(data.amount, data.currency, "symbol", "1.2-2"),
              dateRefund: dateBegin.format("DD/MM/YYYY"),
              timeRefund: dateBegin.format("HH:mm"),
            });
          }
        }
      }
    }
  }
}
