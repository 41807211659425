import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../@shared/shared.module";
import { CarBlockComponent } from "./car-block/car-block.component";
import { DateComponent } from "./date/date.component";
import { FeeBlockComponent } from "./fee/fee-block.component";
import { FlightBlockComponent } from "./flight-block/flight-block.component";
import { HotelBlockComponent } from "./hotel-block/hotel-block.component";
import { OtherBlockComponent } from "./other/other-block.component";
import { TrainBlockComponent } from "./train-block/train-block.component";
import { TransferBlockComponent } from "./transfer-block/transfer-block.component";

const BLOCK_COMPONENTS: Array<any> = [
  CarBlockComponent,
  DateComponent,
  FlightBlockComponent,
  HotelBlockComponent,
  TrainBlockComponent,
  TransferBlockComponent,
  FeeBlockComponent,
  OtherBlockComponent,
];

@NgModule({
  imports: [SharedModule],
  declarations: [...BLOCK_COMPONENTS],
  exports: [...BLOCK_COMPONENTS],
})
export class BlockModule {}
