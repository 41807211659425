<form
  (submit)="search()"
  [style.grid-template-areas]="gridTemplateAreas"
  [style.grid-template-rows]="gridTemplateRows"
  class="form"
  [ngClass]="'suggest-' + suggestPanelService.active.name"
  [class.suggestOpened]="suggestOpened"
>
  <div class="title">
    <div class="flex justify-content-content items-center">
      <img class="icon-travel" src="./assets/svg/{{ searchEngineType }}.svg" />
      <h1 class="label" [innerHTML]="'SEARCH.HOTEL.TITLE' | translate"></h1>
    </div>
  </div>
  <spt-travel-search-autocomplete
    [label]="{ destination: 'GLOBAL.LOCALISATION' | translate }"
    [placeholder]="{
      destination: 'SEARCH.HOTEL.PLACEHOLDER_FINDER' | translate
    }"
    [searchService]="searchService"
    [travel]="searchCriteria.mainTravel"
    (onCloseSuggest)="resetSuggestPanel()"
    (onTravelChange)="checkValidity()"
    [nextFocus]="searchDatetimeComponent"
  ></spt-travel-search-autocomplete>

  <spt-search-datetime
    #searchDatetimeComponent
    [i18n]="{
      outwardLabelDate: 'SEARCH.ARRIVAL_DATE_LABEL' | translate,
      outwardDatePlaceholder: 'SEARCH.CHECKIN' | translate,
      inwardLabelDate: 'SEARCH.DEPARTURE' | translate,
      inwardDatePlaceholder: 'SEARCH.CHECKOUT' | translate,
      oneWaySuggestPanelTitle: 'SEARCH.HOTEL.DATETIME_TITLE' | translate
    }"
    calendarSelectionMode="range"
    [yearRange]="yearRange"
    [time]="false"
    [sourceWhenCriteria]="searchCriteria.mainTravel.when"
    (onActivateSuggest)="activateSuggest($event)"
    (onCloseSuggest)="resetSuggestPanel()"
    (onDatetimeChange)="checkValidity()"
    [searchEngineType]="searchEngineType"
  ></spt-search-datetime>
  <ng-container *ngFor="let travel of searchCriteria.travels; let idx = index">
    <p class="room-title title" [style.grid-area]="'room-title-' + idx">
      <span> {{ "SEARCH.HOTEL.ROOM" | translate }} {{ idx + 1 }} </span>
      <span *ngIf="idx > 0" class="remove" [style.grid-area]="'remove-' + idx"
        ><a (click)="removeRoom(idx)">{{ "GLOBAL.DELETE" | translate }}</a></span
      >
    </p>
    <spt-search-people
      #searchPeople
      [people]="travel.people"
      [gridArea]="'people-' + idx"
      [suggestPanelName]="'people-' + idx"
      (onActivateSuggest)="activateSuggest($event)"
      (onPeopleListChange)="checkValidity()"
      (onCloseSuggest)="resetSuggestPanel()"
      [searchEngineType]="searchEngineType"
    ></spt-search-people>
  </ng-container>
  <div class="options">
    <p *ngIf="searchCriteria.travels.length === 8" class="nbmax" translate>SEARCH.HOTEL.MAXPASSENGERS</p>
    <div>
      <div class="stars">
        <div class="title room-title" translate>SEARCH.RESULT.HOTEL.STARS</div>
        <div class="checkboxs">
          <p-checkbox
            *ngFor="let starOption of searchCriteria.options.stars | keyvalue"
            [name]="'stars-' + idx"
            [value]="starOption.key"
            [label]="starOption.key"
            [(ngModel)]="stars"
            (onChange)="changeStars()"
          ></p-checkbox>
        </div>
      </div>
      <div class="add-room" (click)="addRoom()" *ngIf="searchCriteria.travels.length < 8">
        <p-button
          class="add-room-button"
          styleClass="p-button-quaternary medium"
          type="button"
          [label]="'SEARCH.HOTEL.ADD_ROOM' | translate"
        ></p-button>
      </div>
    </div>
    <div class="others">
      <div class="title" translate>SEARCH.RESULT.HOTEL.CONDITIONS</div>
      <div class="cancellable">
        <p-checkbox
          [binary]="true"
          label="{{ 'SEARCH.HOTEL.CANCELABLE' | translate }}"
          name="cancellable"
          [(ngModel)]="searchCriteria.options.cancellable"
        ></p-checkbox>
      </div>
      <div class="breakfast-included">
        <p-checkbox
          [binary]="true"
          label="{{ 'SEARCH.HOTEL.BREAKFAST' | translate }}"
          name="breakfast"
          [(ngModel)]="searchCriteria.options.breakfast"
        ></p-checkbox>
      </div>
    </div>
  </div>
  <div class="triggers submit">
    <p-button type="submit" [disabled]="!isValid" label="{{ 'SEARCH.CTA' | translate }}"></p-button>
  </div>
</form>
