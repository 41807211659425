import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";

import { Injectable } from "@angular/core";
import { SearchService } from "src/app/@shared/services/search.service";
import { FlightService as SharedFlightService } from "src/app/@shared/services/flight.service";
import { environment } from "src/environments/environment";
import moment from "moment";
import { cloneDeep } from "lodash-es";
import { TrainTypes } from "../train/train";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";

@Injectable()
export class FlightService {
  public dataForFilter: Subject<any> = new Subject<any>();
  public offerPriced: Subject<any> = new Subject<any>();
  public otherOffers: Subject<any> = new Subject<any>();
  public filterForCurrentStep: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public closeExtendedPanel: Subject<any> = new Subject<any>();

  public returnTripsItineraries: any[] = [];
  public airports: any;
  public alternativeTrain: any = {};

  constructor(
    private httpClient: HttpClient,
    private searchService: SearchService,
    private sharedFlightService: SharedFlightService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {}

  public getSearch(searchId: string): Observable<any> {
    return this.searchService.get(searchId).pipe(
      map((result: any) => {
        return result.data;
      }),
    );
  }

  getFilterForCurrentStepData() {
    return this.filterForCurrentStep.asObservable();
  }

  filterClass(itinerary: any, pricedOffer: any[]) {
    let pricedOffers = [];
    let classArray = [];
    switch (itinerary.cabinCodes) {
      case "Y":
        switch (itinerary.className) {
          case "Light":
            classArray = ["Light", "Standard", "Flex"];
            pricedOffers.push(
              ...pricedOffer.filter((offer: any) => classArray.includes(offer.className) && offer.cabinCodes === "Y"),
            );
            pricedOffers.push(...pricedOffer.filter((offer: any) => offer.cabinCodes !== "Y"));
            pricedOffers = [...this.filterAndSortPricedOffers(pricedOffers)];
            return pricedOffers;
          case "Standard":
            classArray = ["Standard", "Flex"];
            pricedOffers.push(
              ...pricedOffer.filter((offer: any) => classArray.includes(offer.className) && offer.cabinCodes === "Y"),
            );
            pricedOffers.push(...pricedOffer.filter((offer: any) => offer.cabinCodes !== "Y"));
            pricedOffers = [...this.filterAndSortPricedOffers(pricedOffers)];
            return pricedOffers;
          case "Flex":
            classArray = ["Flex"];
            pricedOffers.push(
              ...pricedOffer.filter((offer: any) => classArray.includes(offer.className) && offer.cabinCodes === "Y"),
            );
            pricedOffers.push(...pricedOffer.filter((offer: any) => offer.cabinCodes !== "Y"));
            pricedOffers = [...this.filterAndSortPricedOffers(pricedOffers)];
            return pricedOffers;
        }
      case "C":
        switch (itinerary.className) {
          case "Light":
            classArray = ["Light", "Standard", "Flex"];
            pricedOffers.push(
              ...pricedOffer.filter((offer: any) => classArray.includes(offer.className) && offer.cabinCodes === "C"),
            );
            pricedOffers.push(
              ...pricedOffer.filter(
                (offer: any) => offer.cabinCodes !== "Y" && offer.cabinCodes !== "C" && offer.cabinCodes !== "J",
              ),
            );
            pricedOffers = [...this.filterAndSortPricedOffers(pricedOffers)];
            return pricedOffers;
          case "Standard":
            classArray = ["Standard", "Flex"];
            pricedOffers.push(
              ...pricedOffer.filter((offer: any) => classArray.includes(offer.className) && offer.cabinCodes === "C"),
            );
            pricedOffers.push(
              ...pricedOffer.filter(
                (offer: any) => offer.cabinCodes !== "Y" && offer.cabinCodes !== "C" && offer.cabinCodes !== "J",
              ),
            );
            pricedOffers = [...this.filterAndSortPricedOffers(pricedOffers)];
            return pricedOffers;
          case "Flex":
            classArray = ["Flex"];
            pricedOffers.push(
              ...pricedOffer.filter((offer: any) => classArray.includes(offer.className) && offer.cabinCodes === "C"),
            );
            pricedOffers.push(
              ...pricedOffer.filter(
                (offer: any) => offer.cabinCodes !== "Y" && offer.cabinCodes !== "C" && offer.cabinCodes !== "J",
              ),
            );
            pricedOffers = [...this.filterAndSortPricedOffers(pricedOffers)];
            return pricedOffers;
        }
      case "F":
        switch (itinerary.className) {
          default:
            classArray = ["La Premiere"];
            pricedOffers.push(
              ...pricedOffer.filter((offer: any) => classArray.includes(offer.className) && offer.cabinCodes === "F"),
            );
            pricedOffers.push(
              ...pricedOffer.filter(
                (offer: any) =>
                  offer.cabinCodes !== "Y" &&
                  offer.cabinCodes !== "C" &&
                  offer.cabinCodes !== "J" &&
                  offer.cabinCodes !== "F",
              ),
            );
            pricedOffers = [...this.filterAndSortPricedOffers(pricedOffers)];
            return pricedOffers;
        }
      default:
        switch (itinerary.className) {
          case "Premium Light":
            classArray = ["Light", "Standard", "Flex"];
            pricedOffers.push(
              ...pricedOffer.filter((offer: any) => classArray.includes(offer.className) && offer.cabinCodes === "J"),
            );
            pricedOffers.push(
              ...pricedOffer.filter((offer: any) => offer.cabinCodes !== "Y" && offer.cabinCodes !== "J"),
            );
            pricedOffers = [...this.filterAndSortPricedOffers(pricedOffers)];
            return pricedOffers;
          case "Premium Standard":
            classArray = ["Standard", "Flex"];
            pricedOffers.push(
              ...pricedOffer.filter((offer: any) => classArray.includes(offer.className) && offer.cabinCodes === "J"),
            );
            pricedOffers.push(
              ...pricedOffer.filter((offer: any) => offer.cabinCodes !== "Y" && offer.cabinCodes !== "J"),
            );
            pricedOffers = [...this.filterAndSortPricedOffers(pricedOffers)];
            return pricedOffers;
          case "Premium Flex":
            classArray = ["Flex"];
            pricedOffers.push(
              ...pricedOffer.filter((offer: any) => classArray.includes(offer.className) && offer.cabinCodes === "J"),
            );
            pricedOffers.push(
              ...pricedOffer.filter((offer: any) => offer.cabinCodes !== "Y" && offer.cabinCodes !== "J"),
            );
            pricedOffers = [...this.filterAndSortPricedOffers(pricedOffers)];
            return pricedOffers;
        }
    }
  }

  filterSabreClass(itinerary: any, pricedOffer: any[]) {
    let pricedOffers = [];
    let classArray = [];
    switch (itinerary.cabinCodes[0]) {
      case "Y":
        pricedOffers.push(...pricedOffer);
        return pricedOffers;
      case "C":
        pricedOffers.push(...pricedOffer.filter((offer: any) => offer.cabinCodes !== "Y" && offer.cabinCodes !== "J"));
        return pricedOffers;
      case "F":
        pricedOffers.push(
          ...pricedOffer.filter(
            (offer: any) => offer.cabinCodes !== "Y" && offer.cabinCodes !== "C" && offer.cabinCodes !== "J",
          ),
        );
        return pricedOffers;
      default:
        pricedOffers.push(...pricedOffer.filter((offer: any) => offer.cabinCodes !== "Y"));
        return pricedOffers;
    }
  }

  filterAndSortPricedOffers(pricedOffers: any[]): any[] {
    const result = pricedOffers
      .sort((a, b) => {
        if (!a.cabinCodes) return 1; // Met les éléments avec cabinCodes undefined à la fin
        if (!b.cabinCodes) return -1; // Met les éléments avec cabinCodes undefined à la fin
        return a.cabinCodes.localeCompare(b.cabinCodes); // Trie le tableau en fonction de la propriété cabinCodes
      })
      .reduce((acc, obj) => {
        const key = obj.cabinCodes || "undefined";
        const index = acc.findIndex((item) => item[0] && item[0].cabinCodes === key);
        if (index === -1) {
          acc.push([obj]);
        } else {
          acc[index].push(obj);
        }
        return acc;
      }, []);
    return result.reduce((acc, val) => acc.concat(val), []).sort((a, b) => (a.totalPrice > b.totalPrice ? 1 : -1));
  }

  getOfferPrice(searchId: string, offerId: string, offerItemId: string, packagedOfferPrice?: boolean, itinerary?: any) {
    const headers: HttpHeaders = new HttpHeaders({
      ignoreLoadingBar: "false",
    });
    const body = {
      searchId,
      offerItemRefId: offerItemId,
    };
    let iataCodes: Set<string> = new Set();
    return this.httpClient.post(`${environment.api}/flight/${offerId}/offer-price`, body, { headers }).pipe(
      map((data: any) => {
        const { pricedOffer, otherOffers, paymentInfo } = data.data;
        let result: any = {};
        result.paymentInfo = paymentInfo;
        result.pricedOffer = this.mapItinerary(pricedOffer, iataCodes, searchId);
        const newPricedOffer = { ...result.pricedOffer };
        if (packagedOfferPrice) {
          result.pricedOffer.type = "packaged";
        }
        result.otherOffers = otherOffers.map((offer) => {
          return this.mapItinerary(offer, iataCodes, searchId);
        });
        if (itinerary && result.otherOffers.length) {
          result.otherOffers = this.filterClass(itinerary, result.otherOffers);
        }
        result.otherOffers.unshift(newPricedOffer);

        result.pricedOffer.otherOffers = [...result.otherOffers].map((result: any) => {
          if (packagedOfferPrice) {
            result.type = "packaged";
          }
          return result;
        });

        return result;
      }),
    );
  }

  public getSeatMap(searchId: string, type: "offer" | "order", id) {
    return this.httpClient.post(`${environment.api}/flight/${id}/seat-map`, { type, searchId });
  }

  public getServiceList(searchId: string, type: "offer" | "order", id) {
    return this.httpClient.post(`${environment.api}/flight/${id}/service-list`, { type, searchId });
  }

  public setAncillaries(orderId: string, searchId: string, selectedOfferItems: any[], type: string) {
    return this.httpClient.post(`${environment.api}/flight/${orderId}/offer-price/ancillaries/${type}`, {
      searchId,
      selectedOfferItems,
    });
  }

  public fetchResults(searchId: string, seeOutOfPolicy: boolean, searchType: string) {
    const headers: HttpHeaders = new HttpHeaders({
      ignoreLoadingBar: "true",
    });
    let itineraries, dataForFilters, isMulti, settings, users;
    let iataCodes: Set<string> = new Set();
    let alternativeTrain: any = {};
    return this.httpClient.get(`${environment.api}/search/${searchId}/results`, { headers }).pipe(
      map((data: any) => {
        if (data?.result?.isSptError) {
          this.messageService.add({
            severity: "error",
            summary: this.translateService.instant(`NOTIFICATIONS.${data.result.code}`),
            life: 9000,
          });
        }
        let mappedResults = [],
          allOffPolicy;
        if (data?.alternativeSearchId) {
          alternativeTrain.alternativeSearchId = data.alternativeSearchId;
          alternativeTrain.minimumRailDuration = data.minimumRailDuration;
          alternativeTrain.hasRsePolicy = data.hasRsePolicy;
          alternativeTrain.minimumCarbonOffset = data.minimumCarbonOffset;
        }
        const afklResult = [];
        let sabreResult = [];
        if (data?.result?.response) {
          if (data?.result?.response?.afkl && data.result?.response?.afkl.length > 0) {
            data.result?.response?.afkl.forEach((r) => {
              if (r.itineraryGroups.length > 0) {
                const _data = this.mapAfklResults(r, seeOutOfPolicy, searchId);
                iataCodes = new Set([...Array.from(iataCodes), ...Array.from(_data.iataCodes)]);
                afklResult.push(_data.mappedResults);
              }
            });
          } else if (data.result?.response?.afkl && data.result?.response?.afkl.length) {
            const _data = this.mapAfklResults(data.result.response.afkl, seeOutOfPolicy, searchId);

            iataCodes = new Set([...Array.from(iataCodes), ...Array.from(_data.iataCodes)]);
            afklResult.push(_data.mappedResults);
          }

          if (data?.result?.response?.sabre && data.result?.response?.sabre.length > 0) {
            data.result?.response?.sabre.forEach((r) => {
              if (r.itineraryGroups.length > 0) {
                const _data = this.mapSabreResults(r, seeOutOfPolicy);
                iataCodes = new Set([...Array.from(iataCodes), ...Array.from(_data.iataCodes)]);
                sabreResult.push(_data.mappedResults);
              }
            });
          } else if (data.result?.response?.sabre && data.result?.response?.sabre.length) {
            const _data = this.mapSabreResults(data.result.response.afkl, seeOutOfPolicy);

            iataCodes = new Set([...Array.from(iataCodes), ...Array.from(_data.iataCodes)]);
            sabreResult.push(_data.mappedResults);
          }
          if (sabreResult.length && afklResult.length && afklResult[0].length) {
            sabreResult[0] = [...this.filtrerSabreResults(sabreResult[0], afklResult[0])];
            if (afklResult.length > 1 && sabreResult.length > 1) {
              sabreResult[1] = [...this.filtrerSabreResults(sabreResult[1], afklResult[1])];
            }
          }
          if (searchType === "multi") {
            sabreResult.forEach((result, index) => {
              if (afklResult[index]) {
                mappedResults.push([...result, ...afklResult[index]]);
              } else {
                mappedResults.push([...result]);
              }
            });
          } else if (sabreResult.length >= 1 && afklResult.length >= 1) {
            mappedResults.push([...sabreResult[0], ...afklResult[0]]);
            if (sabreResult.length === 2 && afklResult.length === 2) {
              mappedResults.push([...sabreResult[1], ...afklResult[1]]);
            }
          } else if (sabreResult.length === 1 && afklResult.length === 1) {
            mappedResults.push([...sabreResult[0], ...afklResult[0]]);
          } else if (!sabreResult.length && afklResult.length) {
            mappedResults.push([...afklResult[0]]);
            if (afklResult.length === 2) {
              mappedResults.push([...afklResult[1]]);
            }
          } else if (sabreResult.length && !afklResult.length) {
            mappedResults.push([...sabreResult[0]]);
            if (sabreResult.length === 2) {
              mappedResults.push([...sabreResult[1]]);
            }
          }
        }

        if (allOffPolicy) {
          throw new Error("All off policy");
        }
        if (!mappedResults) {
          throw new Error("no result");
        } else {
          isMulti = data.result.response.length > 0;
          itineraries = [...mappedResults];
          settings = data.result.settings;
          users = data.result.users;
          if (data.result?.response?.sabre?.length > 1 || data.result?.response?.afkl?.length > 1) {
            const dataResponse = [...itineraries];
            dataForFilters = dataResponse.map((mdr, index) => {
              return this.createFilters(dataResponse[index]);
            });
          } else {
            dataForFilters = this.createFilters(itineraries[0]);
          }
        }
        return iataCodes;
      }),
      concatMap((iataCodes) => {
        return this.sharedFlightService.listAirports(Array.from(iataCodes));
      }),
      map((airports) => {
        this.airports = airports;
        if (itineraries.length > 1) {
          this.returnTripsItineraries = itineraries[1];
        }
        return {
          itineraries,
          isMulti,
          settings,
          users,
          dataForFilters,
          iataCodes,
          alternativeTrain,
        };
      }),

      catchError((err: any) => {
        console.log(err);
        if (err.error && err.error.code === "UNKNOWN_ERROR") {
          return;
        }
        return throwError(err);
      }),
    );
  }

  filtrerSabreResults(sabreResult, afklResult) {
    // Filtrer le tableau principal en retirant les éléments correspondants
    const tableauFiltreResultat = sabreResult.filter((elementPrincipal) => {
      // Vérifier si l'élément principal correspond à un élément du tableau filtre
      const correspondance = afklResult.some((elementFiltre) => {
        const marketingPrincipal = elementPrincipal.legs[0].schedules[0].carrier.marketing;
        const marketingFlightNumberPrincipal = elementPrincipal.legs[0].schedules[0].carrier.marketingFlightNumber;

        const marketingFiltre = elementFiltre.legs[0].schedules[0].carrier.marketing;
        const marketingFlightNumberFiltre = elementFiltre.legs[0].schedules[0].carrier.marketingFlightNumber;

        return marketingPrincipal === marketingFiltre && marketingFlightNumberPrincipal === marketingFlightNumberFiltre;
      });

      // Retourner true si aucune correspondance n'a été trouvée
      return !correspondance;
    });

    return tableauFiltreResultat;
  }

  public fetchFlightResults(searchId: string, seeOutOfPolicy: boolean, itinerary: any) {
    const headers: HttpHeaders = new HttpHeaders({
      ignoreLoadingBar: "true",
    });
    let itineraries, dataForFilters, isMulti, settings, users;
    let iataCodes: Set<string> = new Set();
    return this.httpClient
      .post(`${environment.api}/search/${searchId}/flight-results`, { outboundTrip: itinerary }, { headers })
      .pipe(
        map((data: any) => {
          const result = data.result;
          let mappedResults = [],
            allOffPolicy;
          if (result && result.response && result.response.length > 0) {
            result.response.forEach((r) => {
              if (r.itineraryGroups.length > 0) {
                const mappingFunction = itinerary.provider ? this.mapAfklResults : this.mapSabreResults;
                const _data = itinerary.provider ? mappingFunction.call(this, r, seeOutOfPolicy) : mappingFunction.call(this, r, seeOutOfPolicy, true);

                iataCodes = new Set([...iataCodes, ..._data.iataCodes]);
                mappedResults.push(_data.mappedResults);
              }
            });
            mappedResults.map((result) => {
              result.type = "packaged";
              return result;
            });
          } else if (result && result.response) {
            const mappingFunction = itinerary.provider ? this.mapAfklResults : this.mapSabreResults;
            const _data = mappingFunction.call(this, result.response, seeOutOfPolicy);

            iataCodes = new Set([...iataCodes, ..._data.iataCodes]);
            mappedResults.push(..._data.mappedResults);
            mappedResults.map((result) => {
              result.type = "packaged";
              return result;
            });
          }
          if (allOffPolicy) {
            throw new Error("All off policy");
          }
          if (!mappedResults) {
            throw new Error("no result");
          } else {
            isMulti = result.response.length > 0;
            if (itinerary.provider) {
               itineraries = [];

              if (
                this.returnTripsItineraries.length &&
                mappedResults.length &&
                Array.isArray(mappedResults[0])
              ) {
                itineraries = [...mappedResults[0], ...this.returnTripsItineraries.filter((returntrip: any) => !returntrip.provider)];
              } else if (
                this.returnTripsItineraries &&
                mappedResults.length
              ) {
                itineraries = [...mappedResults, ...this.returnTripsItineraries.filter((returntrip: any) => !returntrip.provider)];
              } else if (
                mappedResults.length === 0 &&
                this.returnTripsItineraries
              ) {
                itineraries = [...this.returnTripsItineraries.filter((returntrip: any) => !returntrip.provider)];
              } else if (
                mappedResults[0].length
              ) {
                itineraries = [mappedResults[0]];
              } else if (
                mappedResults.length
              ) {
                itineraries = [mappedResults];
              } else {
                itineraries = [];
              }

            } else {
              itineraries =
                mappedResults.length && Array.isArray(mappedResults[0]) ? [...mappedResults[0]] : mappedResults.length ? [...mappedResults] : [...this.returnTripsItineraries];
            }
            
            settings = result.settings;
            users = result.users;
            dataForFilters = itineraries.length ? this.createFilters(itineraries) : [];
          }
          return iataCodes;
        }),
        concatMap((iataCodes) => {
          return this.sharedFlightService.listAirports(Array.from(iataCodes));
        }),
        map((airports) => {
          if (iataCodes.size !== 0) {
            this.airports = airports;
          }
          return {
            itineraries,
            isMulti,
            settings,
            users,
            dataForFilters,
            iataCodes,
          };
        }),
        catchError((err: any) => {
          console.log(err);
          if (err.error && err.error.code === "UNKNOWN_ERROR") {
            return;
          }
          return throwError(err);
        }),
      );
  }

  private mapAfklResults(
    result: any,
    seeOutOfPolicy: boolean,
    searchId?: string,
  ): { mappedResults: any; iataCodes: Set<string>; allOffPolicy: boolean } {
    let nbItineraries: number = 0;
    const iataCodes: Set<string> = new Set();
    if (result && result.itineraryGroups) {
      const data = [];
      nbItineraries = result.itineraryGroups.length;

      result.itineraryGroups.forEach((itinerary: any) => {
        data.push(this.mapItinerary(itinerary, iataCodes, searchId));
      });
      return { mappedResults: data, iataCodes, allOffPolicy: false };
    } else {
      return { mappedResults: [], iataCodes, allOffPolicy: false };
    }
  }

  private mapItinerary(itinerary: any, iataCodes?: any, searchId?: string) {
    let legs: any = itinerary.legs;
    legs.forEach((leg: any) => {
      const airportStops: string[] = [];
      const companies: string[] = [];
      leg.schedules.forEach((schedule: any, idx: number, schedules: any) => {
        if (iataCodes) {
          if (schedules.length > 1) {
            if (idx > 0 && idx !== schedules.length) {

              airportStops.push(schedule.arrival.airport);
            } else if (idx === 0) {
              airportStops.push(schedule.arrival.airport);
            } 
          }
          companies.push(schedule.carrier.marketing);
          iataCodes.add(schedule.departure.airport);
          iataCodes.add(schedule.arrival.airport);
        }

        if (idx === 0) {
          leg.departureDatetime = moment(`${schedule.departure.date}T${schedule.departure.time}`);
          leg.departureDate = schedule.departure.date;
          leg.departureTime = schedule.departure.time;
          leg.departure = schedule.departure;
        }
        if (idx === schedules.length - 1) {
          leg.arrivalDatetime = moment(`${schedule.arrival.date}T${schedule.arrival.time}`);
          leg.arrivalDate = schedule.arrival.date;
          leg.arrivalTime = schedule.arrival.time;
          leg.arrival = schedule.arrival;
        }
        schedule.duration = moment(`${schedule.arrival.date}T${schedule.arrival.time}`).diff(
          `${schedule.departure.date}T${schedule.departure.time}`,
          "minutes",
        );
      });
      const legDuration: number = leg.arrivalDatetime.diff(leg.departureDatetime, "minutes");

      leg.airportStops = airportStops;
      leg.companies = companies;
      leg.duration = legDuration;
    });
    let result;
    const keys: string[] = Object.keys(itinerary.pricingInformations);
    let companies: Array<string>;
    keys.forEach((key: string, indexKey: number) => {
      legs = cloneDeep(itinerary.legs);
      const pricingInformations: any[] = itinerary.pricingInformations[key];
      const pricingInformationsPopulated = [];
      let baggageIncluded: number = Infinity;
      let carryOnIncluded: number = Infinity;
      let refundFees = new Set();
      let exchangeFees = new Set();

      let subscriber: boolean = false;
      let freeRefund: boolean = false;
      let freeExchange: boolean = false;
      let cabinCodes = new Set();
      let detailsCarryOn = [];
      // const penalities = itinerary.offerItem.FareDetail.FareComponent.FareRule.Penalty;
      // if (penalities) {
      //   penalities.forEach((penality) => {
      //     Object.keys(penality).forEach((key) => {
      //       if (key.startsWith("ChangeFeeInd") && penality[key] === true) {
      //         freeExchange = penality[key];
      //         exchangeFees.add(penality.PenaltyAmount["$t"]);
      //       }
      //       if (key.startsWith("CancelFeeInd") && penality[key] === true) {
      //         freeRefund = !penality[key];
      //         if (penality.PenaltyAmount) {
      //           refundFees.add(penality.PenaltyAmount["$t"]);
      //         }
      //       }
      //     });
      //   });
      // }

      pricingInformations.forEach((pricingInformation, indexPricingInformation) => {
        legs.forEach((leg: any) => {
          leg.governingCarrier = leg.companies;
        });
        if (Object.keys(pricingInformation.fare).length !== 0) {
          pricingInformation.fare.passengerInfos.forEach((passengerInfo: any) => {
            let i = 0;
            if (!!passengerInfo.passengerType.match(/(CDT)|(CFM)|(CCH)/)) {
              subscriber = true;
            }

            let penaltyRefund;
            if (Array.isArray(passengerInfo.penaltiesInfo)) {
              penaltyRefund = passengerInfo.penaltiesInfo.find((_penalty) => _penalty.TypeCode === "Cancellation");
            } else {
              Object.keys(passengerInfo?.penaltiesInfo || {}).forEach((key) => {
                if (key.startsWith("TypeCode") && passengerInfo.penaltiesInfo[key] === "Cancellation") {
                  penaltyRefund = passengerInfo.penaltiesInfo;
                }
              });
            }

            let penaltyExchage;
            if (Array.isArray(passengerInfo.penaltiesInfo)) {
              penaltyExchage = passengerInfo.penaltiesInfo.find((_penalty) => _penalty.TypeCode === "Change");
            } else {
              Object.keys(passengerInfo?.penaltiesInfo || {}).forEach((key) => {
                if (key.startsWith("TypeCode") && passengerInfo.penaltiesInfo[key] === "Change") {
                  penaltyExchage = passengerInfo.penaltiesInfo;
                }
              });
            }

            if (penaltyRefund !== undefined) {
              if (!Array.isArray(penaltyRefund)) {
                freeRefund = !penaltyRefund.PenaltyAmount;

                if (penaltyRefund.PenaltyAmount) {
                  if (!pricingInformation.exchangeFees) {
                    pricingInformation.exchangeFees = new Set();
                  }
                  pricingInformation.exchangeFees?.add(penaltyRefund.PenaltyAmount.$t);
                  exchangeFees.add(penaltyRefund.PenaltyAmount.$t);
                }
              } else {
                penaltyRefund.forEach((refund: any) => {
                  freeRefund = !refund.PenaltyAmount;
                  if (refund.PenaltyAmount) {
                    if (!pricingInformation.exchangeFees) {
                      pricingInformation.exchangeFees = new Set();
                    }
                    pricingInformation.exchangeFees?.add(refund.PenaltyAmount.$t);
                    exchangeFees.add(refund.PenaltyAmount.$t);
                  }
                });
              }
            }

            if (penaltyExchage !== undefined) {
              if (!Array.isArray(penaltyExchage)) {
                freeExchange = !penaltyExchage.PenaltyAmount;

                if (penaltyExchage.PenaltyAmount) {
                  if (!pricingInformation.exchangeFees) {
                    pricingInformation.exchangeFees = new Set();
                  }
                  if (pricingInformation.exchangeFees != undefined) {
                    exchangeFees?.add(penaltyExchage.PenaltyAmount.$t);
                    pricingInformation.exchangeFees = exchangeFees;
                  }
                }
              } else {
                penaltyExchage.forEach((exhage: any) => {
                  freeExchange = !exhage.PenaltyAmount;
                  if (exhage.PenaltyAmount) {
                    if (!pricingInformation.exchangeFees) {
                      pricingInformation.exchangeFees = new Set();
                    }
                    if (pricingInformation.exchangeFees != undefined) {
                    }
                  }

                  // freeRefnd = true;
                });
              }
            }

            let passengerBaggageIncluded: number = Infinity;
            let passengerCarryOnIncluded: number = Infinity;

            if (passengerInfo.baggageInformation) {
              passengerInfo.baggageInformation.forEach((baggageInformation: any) => {
                baggageInformation.sizeInfo = {};
                const baggageInfoQty = parseInt(baggageInformation.PieceAllowance.TotalQty, 10);
                if (baggageInformation.TypeCode === "CarryOn") {
                  detailsCarryOn.push(baggageInformation);
                }
                if (baggageInformation.TypeCode === "Checked" && baggageIncluded > baggageInfoQty) {
                  baggageIncluded = baggageInfoQty;
                }
                if (baggageInformation.TypeCode === "Checked" && passengerBaggageIncluded > baggageInfoQty) {
                  passengerBaggageIncluded = baggageInfoQty;
                }

                if (baggageInformation.TypeCode === "CarryOn" && carryOnIncluded > baggageInfoQty) {
                  carryOnIncluded = baggageInfoQty;
                }
                if (baggageInformation.TypeCode === "CarryOn" && passengerCarryOnIncluded > baggageInfoQty) {
                  passengerCarryOnIncluded = baggageInfoQty;
                }
              });
            } else {
              baggageIncluded = 0;
              carryOnIncluded = 0;
            }
            passengerInfo.baggageIncluded = passengerBaggageIncluded === Infinity ? 0 : passengerBaggageIncluded;
            passengerInfo.carryOnIncluded = passengerCarryOnIncluded === Infinity ? 0 : passengerCarryOnIncluded;
          });
          pricingInformation.fare.passengerInfos.forEach((passengerInfo: any) => {
            if (pricingInformation.refundFees) {
              pricingInformation.refundFees = Array.from(pricingInformation.refundFees);
            }
            if (pricingInformation.exchangeFees) {
              pricingInformation.exchangeFees = Array.from(pricingInformation.exchangeFees);
            }
          });
        }

        if (baggageIncluded === Infinity) {
          baggageIncluded = 0;
        }
        if (carryOnIncluded === Infinity) {
          carryOnIncluded = 0;
        }

        // if (seeOutOfPolicy || !pricingInformation.isOutOfPolicy) {
        pricingInformationsPopulated.push(pricingInformation);
        // }
      });
      // pricingInformationsPopulated.forEach()
      const totalPrice = pricingInformations.reduce((acc, curr) => {
        return acc + Number(curr.price.total);
      }, 0);

      let carbonOffset;
      if (!itinerary.carbonOffset) {
        carbonOffset = pricingInformations.reduce(
          (acc, cur) => {
            return {
              amount: acc.amount + Number(cur.carbonOffset.amount),
              price: acc.price + Number(cur.carbonOffset.price),
            };
          },
          {
            amount: 0,
            price: 0,
          },
        );
        carbonOffset.paid = false;
      } else {
        carbonOffset = itinerary.carbonOffset;
      }

      // TODO to delete when Easyjet with baggage work
      // if (baggageIncluded > 0 && companies.indexOf("U2") > -1) {
      //   return;
      // }

      let passengers = [];
      if (typeof itinerary.offerItem[0].FareDetail.PaxRefID === "object") {
        passengers = Array.from(itinerary.offerItem[0].FareDetail.PaxRefID);
      } else {
        passengers.push["PAX1"];
      }

      legs.map(async (leg) => {
        const arrivalAirport = leg.arrival;
        const departureAirport = leg.departure;
        let airports = {};
        await this.sharedFlightService
          .listAirports([arrivalAirport.airport, departureAirport.airport])
          .subscribe((data) => {
            airports = data;
            arrivalAirport.country = data[arrivalAirport.airport].country;
            departureAirport.country = data[departureAirport.airport].country;
            const offset = moment().format("Z");
            leg.arrival.country = arrivalAirport.country;
            leg.arrival.time = moment(leg.arrival.time, "HH:mm:ss").format("HH:mm:ss") + offset;
            leg.departure.country = departureAirport.country;
            leg.departure.time = moment(leg.departure.time, "HH:mm:ss").format("HH:mm:ss") + offset;
            const legSchedules = leg.schedules;
            legSchedules.forEach((schedule) => {
              let scheduleArrivalAirport = schedule.arrival;
              let scheduleDepartureAirport = schedule.departure;
              this.sharedFlightService
                .listAirports([scheduleDepartureAirport.airport, scheduleArrivalAirport.airport])
                .subscribe((airpots: any) => {
                  scheduleArrivalAirport = airpots[scheduleArrivalAirport.airport];
                  scheduleDepartureAirport = airpots[scheduleDepartureAirport.airport];
                  schedule.arrival.country = scheduleArrivalAirport.country;
                  schedule.arrival.time = moment(schedule.arrival.time, "HH:mm:ss").format("HH:mm:ss") + offset;
                  schedule.departure.country = scheduleDepartureAirport.country;
                  schedule.departure.time = moment(schedule.departure.time, "HH:mm:ss").format("HH:mm:ss") + offset;
                });
            });
          });

        return leg;
      });

      result = {
        offerId: itinerary.id,
        offerItem: itinerary.offerItem,
        searchId,
        legs,
        pricingInformations: pricingInformationsPopulated,
        otherOffers: [],
        totalPrice,
        carbonOffset,
        currency: pricingInformations[0].price.currency,
        passengersCount: passengers.length,
        refundable: pricingInformations[0].refundable,
        refundablePenalities: pricingInformations[0].refundablePenalities,
        changeable: pricingInformations[0].changeable,
        changeablePenalities: pricingInformations[0].changeablePenalities,
        // partial: pricingInformations[0].partial,
        flexibleFare: pricingInformations[0].flexibleFare,
        baggageIncluded: pricingInformations[0].fare.passengerInfos[0]?.baggageIncluded,
        detailsCarryOn,
        carryOnIncluded: pricingInformations[0].fare.passengerInfos[0]?.carryOnIncluded,
        haveNegotiatedRate: pricingInformations[0].haveNegotiatedRate,
        isOutOfPolicy: pricingInformations[0].isOutOfPolicy,
        flightNumbersByLeg: pricingInformations[0].flightNumbersByLeg,
        oneWayCombined: pricingInformations.length !== 1,
        subscriber,
        freeRefund: freeRefund && Array.from(refundFees).length === 0 && pricingInformations[0].refundable,
        freeExchange: freeExchange && Array.from(exchangeFees).length === 0 && pricingInformations[0].changeable,
        cabinCodes: itinerary.priceClass,
        className: itinerary.className,
        exchangeFees: Array.from(exchangeFees),
        refundFees: Array.from(refundFees),
        provider: !itinerary.provider ? "sabre" : itinerary.provider,
      };
    });
    return result;
  }
  getFlightOrder(item: any) {
    const body = {
      itemId: item.id,
      searchId: item.formData.searchId,
    };

    return this.httpClient.post(`${environment.api}/flight/order-retrieve`, body);
  }

  private mapSabreResults(
    result: any,
    seeOutOfPolicy: boolean,
    inbound?: boolean
  ): { mappedResults: any; iataCodes: Set<string>; allOffPolicy: boolean } {
    let nbItineraries: number = 0;
    const iataCodes: Set<string> = new Set();
    if (result && result.itineraryGroups) {
      const data = [];
      nbItineraries = result.itineraryGroups.length;

      result.itineraryGroups.forEach((group: any) => {
        group.itineraries.forEach((itinerary: any) => {
          let legs: any = itinerary.legs;
          legs.forEach((leg: any) => {
            const airportStops: string[] = [];
            const companies: string[] = [];
            leg.schedules.forEach((schedule: any, idx: number, schedules: any) => {
              if (schedules.length > 1) {
                if (idx > 0 && idx !== schedules.length) {
                  airportStops.push(schedule.departure.airport);
                  airportStops.push(schedule.arrival.airport);
                } else if (idx === 0) {
                  airportStops.push(schedule.arrival.airport);
                } else if (idx === schedules.length) {
                  airportStops.push(schedule.departure.airport);
                }
              }
              companies.push(schedule.carrier.marketing);
              iataCodes.add(schedule.departure.airport);
              iataCodes.add(schedule.arrival.airport);
              if (idx === 0) {
                leg.departureDatetime = moment(`${schedule.departure.date}T${schedule.departure.time}`);
                leg.departureDate = schedule.departure.date;
                leg.departureTime = schedule.departure.time;
                leg.departure = schedule.departure;
              }
              if (idx === schedules.length - 1) {
                leg.arrivalDatetime = moment(`${schedule.arrival.date}T${schedule.arrival.time}`);
                leg.arrivalDate = schedule.arrival.date;
                leg.arrivalTime = schedule.arrival.time;
                leg.arrival = schedule.arrival;
              }
              schedule.duration = moment(`${schedule.arrival.date}T${schedule.arrival.time}`).diff(
                `${schedule.departure.date}T${schedule.departure.time}`,
                "minutes",
              );
            });
            const legDuration: number = leg.arrivalDatetime.diff(leg.departureDatetime, "minutes");

            leg.airportStops = airportStops;
            leg.companies = companies;
            leg.duration = legDuration;
          });
          const keys: string[] = Object.keys(itinerary.pricingInformations);
          let itineraryOffers = [];
          let companies: Array<string>;
          keys.forEach((key: string, indexKey: number) => {
            legs = cloneDeep(itinerary.legs);

            const pricingInformations: any[] = [itinerary.pricingInformations[key]];
            const pricingInformationsPopulated = [];
            let baggageIncluded: number = Infinity;
            let carryOnIncluded: number = Infinity;
            let refundFees = new Set();
            let exchangeFees = new Set();

            let subscriber: boolean = false;
            let freeRefund: boolean = false;
            let freeExchange: boolean = false;
            let cabinCodes = new Set();
            let detailsCarryOn = [];
            pricingInformations.forEach((pricingInformation, indexPricingInformation) => {
              if (!pricingInformation.fare.governingCarriers) {
                companies = pricingInformation.fare.validatingCarrierCode.split(" ");
              } else {
                companies = pricingInformation.fare.governingCarriers.split(" ");
              }
              legs.forEach((leg: any, index: number) => {
                leg.governingCarrier = companies[index] ? companies[index] : companies[0];
              });

              pricingInformation.fare.passengerInfos.forEach((passengerInfo: any) => {
                let i = 0;
                if (!!passengerInfo.passengerType.match(/(CDT)|(CFM)|(CCH)/)) {
                  subscriber = true;
                }
                const data = passengerInfo.fareComponents.flatMap((far) => {
                  return far.segments.flatMap((seg) => seg.cabinCode);
                });
                let indexes = {
                  indexLeg: 0,
                  indexSch: 0,
                };
                // let indexSch = 0;
                for (let index = 0; index < data.length; index++) {
                  const cabinCode = data[index];

                  if (!legs[indexes.indexLeg].schedules[indexes.indexSch]) {
                    indexes.indexLeg++;
                    indexes.indexSch = 0;
                  }
                  if (!legs[indexes.indexLeg].schedules[indexes.indexSch].cabinCodes) {
                    legs[indexes.indexLeg].schedules[indexes.indexSch].cabinCodes = new Set();
                  }
                  legs[indexes.indexLeg].schedules[indexes.indexSch].cabinCodes.add(cabinCode);
                  cabinCodes.add(cabinCode);
                  indexes.indexSch++;
                }

                const penaltyRefund =
                  passengerInfo.penaltiesInfo && passengerInfo.penaltiesInfo.penalties
                    ? passengerInfo.penaltiesInfo.penalties.find(
                        (_penalty) =>
                          _penalty.type === "Refund" &&
                          _penalty.applicability === "Before" &&
                          _penalty.refundable === true,
                        // _penalty.amount === 0
                      )
                    : undefined;

                const penaltyExchage =
                  passengerInfo.penaltiesInfo && passengerInfo.penaltiesInfo.penalties
                    ? passengerInfo.penaltiesInfo.penalties.find(
                        (_penalty) =>
                          _penalty.type === "Exchange" &&
                          _penalty.applicability === "Before" &&
                          _penalty.changeable === true,
                        // _penalty.amount === 0
                      )
                    : undefined;

                if (penaltyRefund) {
                  if (penaltyRefund.amount === 0) {
                    freeRefund = true;
                  }
                  if (!pricingInformation.refundFees) {
                    pricingInformation.refundFees = new Set();
                  }
                  pricingInformation.refundFees.add(penaltyRefund.amount);
                  // freeRefnd = true;
                  refundFees.add(penaltyRefund.amount);
                }

                if (penaltyExchage) {
                  if (penaltyExchage.amount === 0) {
                    freeExchange = true;
                  }

                  if (!pricingInformation.exchangeFees) {
                    pricingInformation.exchangeFees = new Set();
                  }
                  pricingInformation.exchangeFees.add(penaltyExchage.amount);
                  // freeRefnd = true;
                  exchangeFees.add(penaltyExchage.amount);
                }

                let passengerBaggageIncluded: number = Infinity;
                let passengerCarryOnIncluded: number = Infinity;

                if (passengerInfo.baggageInformation) {
                  passengerInfo.baggageInformation.forEach((baggageInformation: any) => {
                    baggageInformation.sizeInfo = this.extractSizeBaggage(
                      baggageInformation.allowance ? baggageInformation.allowance : baggageInformation.charge,
                    );

                    if (baggageInformation.provisionType === "B") {
                      detailsCarryOn.push(baggageInformation);
                    }
                    if (
                      baggageInformation.provisionType === "A" &&
                      baggageIncluded > baggageInformation.allowance.pieceCount
                    ) {
                      baggageIncluded = baggageInformation.allowance.pieceCount;
                    }
                    if (
                      baggageInformation.provisionType === "A" &&
                      passengerBaggageIncluded > baggageInformation.allowance.pieceCount
                    ) {
                      passengerBaggageIncluded = baggageInformation.allowance.pieceCount;
                    }

                    if (
                      baggageInformation.provisionType === "B" &&
                      carryOnIncluded > baggageInformation.allowance.pieceCount
                    ) {
                      carryOnIncluded = baggageInformation.allowance.pieceCount;
                    }
                    if (
                      baggageInformation.provisionType === "B" &&
                      passengerCarryOnIncluded > baggageInformation.allowance.pieceCount
                    ) {
                      passengerCarryOnIncluded = baggageInformation.allowance.pieceCount;
                    }
                  });
                } else {
                  baggageIncluded = 0;
                  carryOnIncluded = 0;
                }
                passengerInfo.baggageIncluded = passengerBaggageIncluded === Infinity ? 0 : passengerBaggageIncluded;
                passengerInfo.carryOnIncluded = passengerCarryOnIncluded === Infinity ? 0 : passengerCarryOnIncluded;
              });
              pricingInformation.fare.passengerInfos.forEach((passengerInfo: any) => {
                if (pricingInformation.refundFees) {
                  pricingInformation.refundFees = Array.from(pricingInformation.refundFees);
                }
                if (pricingInformation.exchangeFees) {
                  pricingInformation.exchangeFees = Array.from(pricingInformation.exchangeFees);
                }
              });
              if (baggageIncluded === Infinity) {
                baggageIncluded = 0;
              }
              if (carryOnIncluded === Infinity) {
                carryOnIncluded = 0;
              }

              // if (seeOutOfPolicy || !pricingInformation.isOutOfPolicy) {
              pricingInformationsPopulated.push(pricingInformation);
              // }
            });
            // pricingInformationsPopulated.forEach()
            const totalPrice = pricingInformations.reduce((acc, curr) => {
              return acc + curr.price.total;
            }, 0);

            let carbonOffset;
            if (!itinerary.carbonOffset) {
              carbonOffset = pricingInformations.reduce(
                (acc, cur) => {
                  return {
                    amount: acc.amount + Number(cur.carbonOffset.amount),
                    price: acc.price + Number(cur.carbonOffset.price),
                  };
                },
                {
                  amount: 0,
                  price: 0,
                },
              );
              carbonOffset.paid = false;
            } else {
              carbonOffset = itinerary.carbonOffset;
            }

            // TODO to delete when Easyjet with baggage work
            if (baggageIncluded > 0 && companies.indexOf("U2") > -1) {
              return;
            }

            legs.forEach((leg) => {
              leg.cabinCodes = new Set();
              leg.schedules.forEach((schedule) => {
                schedule.cabinCodes = Array.from(schedule.cabinCodes);
                // debugger
                leg.cabinCodes.add(...schedule.cabinCodes);
              });
              leg.cabinCodes = Array.from(leg.cabinCodes);
            });
            const fareComponentsLength = pricingInformations[0].fare.passengerInfos[0].fareComponents.length;
            const className = inbound ? pricingInformations[0].fare.passengerInfos[0].fareComponents[fareComponentsLength - 1].brand?.brandName :  pricingInformations[0].fare.passengerInfos[0].fareComponents[0].brand?.brandName;
            itineraryOffers.push({
              className,
              descriptions: group.groupDescription.legDescriptions,
              legs,
              pricingInformations: pricingInformationsPopulated,
              totalPrice,
              carbonOffset,
              currency: pricingInformations[0].price.currency,
              passengersCount: pricingInformations[0].fare.passengerInfos.length,
              refundable: pricingInformations[0].refundable,
              changeable: pricingInformations[0].changeable,
              // partial: pricingInformations[0].partial,
              flexibleFare: pricingInformations[0].flexibleFare,
              baggageIncluded,
              detailsCarryOn,
              carryOnIncluded,
              haveNegotiatedRate: pricingInformations[0].haveNegotiatedRate,
              isOutOfPolicy: pricingInformations[0].isOutOfPolicy,
              flightNumbersByLeg: pricingInformations[0].flightNumbersByLeg,
              oneWayCombined: pricingInformations.length !== 1,
              subscriber,
              freeRefund,
              freeExchange,
              cardPrice: totalPrice,
              cabinCodes: Array.from(cabinCodes),
              exchangeFees: Array.from(exchangeFees),
              refundFees: Array.from(refundFees),
              type: legs.length > 1 ? "packaged" : null,
            });
          });
          itineraryOffers = itineraryOffers.sort((a, b) => (a.totalPrice > b.totalPrice ? 1 : -1));
          const bestItinerarypricing = { ...itineraryOffers[0] };
          bestItinerarypricing.otherOffers = this.removeDuplicateWithMaxFlexibleFare(itineraryOffers);
          data.push(bestItinerarypricing);
        });
      });
      return { mappedResults: data, iataCodes, allOffPolicy: false };
    } else {
      return { mappedResults: [], iataCodes, allOffPolicy: false };
    }
  }

  removeDuplicateWithMaxFlexibleFare(arr: any[]): any[] {
    const uniqueMap = new Map<string, any>();

    arr.forEach((item) => {
      const key = `${item.totalPrice}_${this.getFareBasisCode(item.pricingInformations[0])}`;
      const existingItem = uniqueMap.get(key);

      if (!existingItem) {
        uniqueMap.set(key, item);
      }
    });

    return Array.from(uniqueMap.values());
  }

  getFareBasisCode(item: any): string {
    const passengerInfo = item.fare.passengerInfos[0];
    return passengerInfo && passengerInfo.fareComponents.length > 0
      ? passengerInfo.fareComponents[0].fareBasisCode
      : "";
  }

  extractSizeBaggage(baggage) {
    if (!baggage) {
      return {};
    }
    let infoKg;
    let infoPound;
    let infoCm;
    let infoInch;
    // let data = {};
    // const regexKg = new RegExp(/(?<infoKg>\d{1,}) KILOGRAM/, 'igm')
    // const regexPounds = new RegExp(/(?<infoPound>\d{1,}) POUND/, 'igm')
    // const regexCm = new RegExp(/(?<infoCm>\d{1,}) LINEAR CENTI/, 'igm')
    // const regexInch = new RegExp(/(?<infoInch>\d{1,}) LINEAR INCHES/, 'igm')

    const regexTotal = new RegExp(
      /:?((?<infoCm>\d{1,}) LINEAR CENTIMETERS)|((?<infoInch>\d{1,}) LINEAR INCHES)|((?<infoPound>\d{1,}) POUND)|((?<infoKg>\d{1,}) KILOGRAM)/gim,
    );
    Object.values(baggage).forEach((allowance: any) => {
      let results;
      if (allowance.MaximumWeightMeasure) {
        results = allowance.MaximumWeightMeasure.matchAll(regexTotal);

        for (const result of results) {
          if (result.groups) {
            infoKg = result.groups.infoKg || infoKg;
            infoPound = result.groups.infoPound || infoPound;
            infoCm = result.groups.infoCm || infoCm;
            infoInch = result.groups.infoInch || infoInch;
          }
        }
      }
    });
    return {
      infoKg,
      infoPound,
      infoCm,
      infoInch,
    };
  }
  public checkBestPackage(searchId, data) {
    const body: any = {
      searchId,
      data,
    };
    return this.httpClient.post(`${environment.api}/flight/check-package`, body, {}).pipe(
      map((res: any) => {
        if (res.data && res.data.itineraries) {
          return res.data.itineraries.map((it) => {
            if (it.itineraryGroups) {
              return this.mapAfklResults(it, true);
            }
            return it;
          });
        }
        return res.data;
      }),
    );
  }

  public checkFlightPackages(searchId, data) {
    const body: any = {
      searchId,
      data,
    };
    return this.httpClient.post(`${environment.api}/flight/check-flight-packages`, body, {}).pipe(
      map((res: any) => {
        if (res.data && res.data.itineraries) {
          return res.data.itineraries.map((it) => {
            if (it.itineraryGroups) {
              return this.mapAfklResults(it, true);
            }
            return it;
          });
        }
        return res.data;
      }),
    );
  }

  private createFilters(itineraries) {
    const filters = {
      price: {
        max: -1,
        min: Infinity,
      },
      duration: {
        max: -1,
        min: Infinity,
      },
      departure: {
        max: moment(itineraries[0].legs[0].departureDatetime),
        min: moment(itineraries[0].legs[0].departureDatetime),
      },
      arrival: {
        max: moment(itineraries[0].legs[itineraries[0].legs.length - 1].arrivalDatetime),
        min: moment(itineraries[0].legs[itineraries[0].legs.length - 1].arrivalDatetime),
      },
      nbStops: new Set(),
      airportStops: new Set(),
      departureAirports: new Set(),
      arrivalAirports: new Set(),
      cabinCodes: new Set(),
      refundableOptions: new Set(),
      changeableOptions: new Set(),
      baggageIncluded: new Set(),
      companies: new Set(),
      subscriberOptions: new Set(),
      haveNegotiatedRateOptions: new Set(),
      freeRefundOptions: new Set(),
      freeExchangeOptions: new Set(),
    };

    itineraries.forEach((itinerary) => {
      itinerary.legs.forEach((leg, index) => {
        const departure = moment(leg.departureDatetime);
        const arrival = moment(index === itinerary.legs.length - 1 ? leg.arrivalDatetime : itinerary.legs[index + 1].arrivalDatetime);
        const duration = arrival.diff(departure, "minute");

        if (filters.duration.max < duration) {
          filters.duration.max = duration;
        }
        if (filters.duration.min > duration) {
          filters.duration.min = duration;
        }

        if (filters.departure.max.isBefore(departure, "minute")) {
          filters.departure.max = departure.clone();
        }
        if (filters.departure.min.isAfter(departure, "minute")) {
          filters.departure.min = departure.clone();
        }

        if (filters.arrival.max.isBefore(arrival, "minute")) {
          filters.arrival.max = arrival.clone();
        }
        if (filters.arrival.min.isAfter(arrival, "minute")) {
          filters.arrival.min = arrival.clone();
        }

        if (filters.price.max < itinerary.totalPrice) {
          filters.price.max = itinerary.totalPrice;
        }
        if (filters.price.min > itinerary.totalPrice) {
          filters.price.min = itinerary.totalPrice;
        }

        filters.nbStops.add(leg.schedules.length - 1);

        leg.schedules.forEach((schedule, idx) => {
          if (idx === 0) {
            filters.departureAirports.add(schedule.departure.airport);
          }
          
          filters.companies.add(schedule.carrier?.operating);
          schedule.cabinCodes.forEach((cabinCode) => {
            filters.cabinCodes.add(cabinCode);
          });
          if (schedule.departure.airport !== leg.departure.airport && schedule.departure.airport !== leg.arrival.airport) {
            filters.airportStops.add(schedule.departure.airport);
          }
        });
        filters.arrivalAirports.add(leg.schedules[leg.schedules.length - 1].arrival.airport);
        
      });
      

      filters.refundableOptions.add(itinerary.refundable);
      filters.changeableOptions.add(itinerary.changeable);
      filters.baggageIncluded.add(itinerary.baggageIncluded);
      filters.subscriberOptions.add(itinerary.subscriber);
      filters.haveNegotiatedRateOptions.add(itinerary.haveNegotiatedRate);
      filters.freeRefundOptions.add(itinerary.freeRefund);
      filters.freeExchangeOptions.add(itinerary.freeExchange);

      itinerary.otherOffers.forEach((offer) => {
        filters.refundableOptions.add(offer.refundable);
        filters.changeableOptions.add(offer.changeable);
        filters.freeExchangeOptions.add(offer.freeExchange);
        filters.freeRefundOptions.add(offer.freeRefund);
        filters.baggageIncluded.add(offer.baggageIncluded);
        offer.legs.forEach((offerLeg) => {
          filters.companies.add(offerLeg.carrier?.operating);
          offerLeg.schedules.forEach((schedule) => {
            schedule.cabinCodes.forEach((cabinCode) => {
              filters.cabinCodes.add(cabinCode);
            })
            if (schedule.departure.airport !== offerLeg.departure.airport && schedule.departure.airport !== offerLeg.arrival.airport) {
              filters.airportStops.add(schedule.departure.airport);
            }
          });
        });
      });
    });

    const dataFilter = [];
    if (filters.price.max !== filters.price.min) {
      dataFilter.push({
        ...filters.price,
        type: "price",
      });
    }
    if (filters.duration.max !== filters.duration.min) {
      dataFilter.push({
        ...filters.duration,
        type: "duration",
      });
    }
    if (filters.subscriberOptions.size > 1) {
      dataFilter.push({
        data: {
          value: "SEARCH.RESULT.AIR.FILTERS.SUBSCRIBER_RATE",
          key: false,
        },
        type: "subscriber",
      });
    }
    if (filters.haveNegotiatedRateOptions.size > 1) {
      dataFilter.push({
        data: {
          value: "SEARCH.RESULT.AIR.FILTERS.NEGOCIATED_RATE",
          key: false,
        },
        type: "haveNegotiatedRate",
      });
    }
    if (filters.changeableOptions.size > 1) {
      dataFilter.push({
        data: {
          value: "SEARCH.RESULT.AIR.FILTERS.CHANGEABLE",
          key: false,
        },
        type: "changeable",
      });
    }
    if (filters.refundableOptions.size > 1) {
      dataFilter.push({
        data: {
          value: "SEARCH.RESULT.AIR.FILTERS.CANCELLABLE",
          key: false,
        },
        type: "refundable",
      });
    }
    if (filters.freeRefundOptions.size > 1) {
      dataFilter.push({
        data: {
          value: "SEARCH.RESULT.AIR.FILTERS.FREE_CANCELLATION",
          key: false,
        },
        type: "freeRefund",
      });
    }
    if (filters.freeExchangeOptions.size > 1) {
      dataFilter.push({
        data: {
          value: "SEARCH.RESULT.AIR.OFFER.EXCHANGEABLE",
          key: false,
        },
        type: "freeExchange",
      });
    }
    if (filters.cabinCodes.size > 1) {
      const cabinData = [];
      filters.cabinCodes.forEach((cabinCode) => {
        cabinData.push({
          label: cabinCode,
          key: cabinCode,
        });
      });
      dataFilter.push({
        data: cabinData,
        type: "cabinCodes",
      });
    }
    if (filters.nbStops.size > 1) {
      const nbStopsData = [];
      filters.nbStops.forEach((nbStop) => {
        nbStopsData.push({
          label: String(nbStop),
          key: nbStop,
        });
      });
      nbStopsData.sort((a, b) => a.key - b.key);
      dataFilter.push({
        data: nbStopsData,
        type: "nbStops",
      });
    }
    if (filters.baggageIncluded.size > 0) {
      const baggageData = [];
      filters.baggageIncluded.forEach((baggage) => {
        baggageData.push({
          label: `${baggage}`,
          key: baggage,
        });
      });
      baggageData.sort((a, b) => a.key - b.key);
      dataFilter.push({
        data: baggageData,
        type: "baggages",
      });
    }
    if (filters.companies.size > 1) {
      const companiesData = [];
      filters.companies.forEach((company) => {
        if (company !== undefined) {
          companiesData.push({
            label: company,
            key: company,
          });
        }
      });
      dataFilter.push({
        data: companiesData,
        type: "companies",
      });
    }
    if (filters.departureAirports.size > 1) {
      const departureAirportsData = [];
      filters.departureAirports.forEach((airport) => {
        departureAirportsData.push({
          label: airport,
          key: airport,
        });
      });
      dataFilter.push({
        data: departureAirportsData,
        type: "departureAirports",
      });
    }
    if (filters.airportStops.size > 1) {
      const airportStopsData = [];
      filters.airportStops.forEach((airport) => {
        airportStopsData.push({
          label: airport,
          key: airport,
        });
      });
      dataFilter.push({
        data: airportStopsData,
        type: "airportStops",
      });
    }
    if (filters.arrivalAirports.size > 1) {
      const arrivalAirportsData = [];
      filters.arrivalAirports.forEach((airport) => {
        arrivalAirportsData.push({
          label: airport,
          key: airport,
        });
      });
      dataFilter.push({
        data: arrivalAirportsData,
        type: "arrivalAirports",
      });
    }
    return dataFilter;
  }

  public filterItineraryOtherOffers(itinerary: any, filterForCurrentStep: any): boolean {
    if (filterForCurrentStep) {
      if (filterForCurrentStep.refundable && !itinerary.refundable) {
        return false;
      }
      if (filterForCurrentStep.changeable && !itinerary.changeable) {
        return false;
      }

      if (filterForCurrentStep.freeRefund && !itinerary.freeRefund) {
        return false;
      }
      if (filterForCurrentStep.freeExchange && !itinerary.freeExchange) {
        return false;
      }

      if (
        filterForCurrentStep.baggageIncluded === true && itinerary.provider &&
        itinerary.pricingInformations[0].fare.passengerInfos[0].baggageIncluded < filterForCurrentStep.baggageIncludedMinimumNumber
      ) {
        return false;
      }
      if (
        filterForCurrentStep.baggageIncluded === true && !itinerary.provider &&
        itinerary.baggageIncluded < filterForCurrentStep.baggageIncludedMinimumNumber
      ) {
        return false;
      }
      if (filterForCurrentStep.carryOnIncluded === true && !itinerary.provider && itinerary.carryOnIncluded <= 0) {
        return false;
      }

      if (filterForCurrentStep.carryOnIncluded === true && itinerary.provider && itinerary.pricingInformations[0].fare.passengerInfos[0].carryOnIncluded <= 0) {
        return false;
      }
    }

    return true;
  }

  public orderResults(order: "price" | "departureTime" | "duration" | "longDepartureTime", itineraries: any[]): any {
    if (!itineraries) {
      return [];
    }
    const result = [...itineraries];
    switch (order) {
      case "price":
        result.sort((a: any, b: any) => {
          if (a.filteredOffers?.length) {
            if (a.filteredOffers[0]?.totalPrice < b.filteredOffers[0]?.totalPrice) {
              return -1;
            } else if (a.filteredOffers[0]?.totalPrice > b.filteredOffers[0]?.totalPrice) {
              return 1;
            } else {
              return 0;
            }
          } else if (a.totalPrice < b.totalPrice) {
            return -1;
          } else if (a.totalPrice > b.totalPrice) {
            return 1;
          } else {
            return 0;
          }
        });
        break;
      case "duration":
        result.sort((a: any, b: any) => {
          const aDuration = a.legs.reduce((acc: number, leg: any) => acc + leg.duration, 0);
          const bDuration = b.legs.reduce((acc: number, leg: any) => acc + leg.duration, 0);
          if (aDuration < bDuration) {
            return -1;
          } else if (aDuration > bDuration) {
            return 1;
          } else {
            return 0;
          }
        });
        break;
      case "departureTime":
        result.sort((a: any, b: any) => {
          const departureLegA: any = a.legs.length === 1 ? a.legs[0] : a.legs[1];
          const departureLegB: any = b.legs.length === 1 ? b.legs[0] : b.legs[1];
          if (departureLegA.departureDatetime.isBefore(departureLegB.departureDatetime)) {
            return -1;
          } else if (departureLegA.departureDatetime.isAfter(departureLegB.departureDatetime)) {
            return 1;
          } else {
            return 0;
          }
        });
        break;
      case "longDepartureTime":
        result.sort((a: any, b: any) => {
          const departureLegA: any = a.legs[0];
          const departureLegB: any = b.legs[0];
          if (departureLegA.departureDatetime.isBefore(departureLegB.departureDatetime)) {
            return 1;
          } else if (departureLegA.departureDatetime.isAfter(departureLegB.departureDatetime)) {
            return -1;
          } else {
            return 0;
          }
        });
        break;
    }

    return result;
  }
}
