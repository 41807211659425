import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MessageService } from "primeng/api";
import { BehaviorSubject } from "rxjs";
import { Booking } from "src/app/@shared/@types/booking";
import { Tag } from "src/app/@shared/@types/tag";
import { CommonService } from "src/app/@shared/services/common.service";
import { TagService } from "src/app/@shared/services/tag.service";
import { EticketsModalComponent } from "../../@dashboard-shared/modals/etickets-modal/etickets-modal.component";
import { ModalService } from "../../../@shared/services/modal.service";

@Component({
  selector: "spt-reservation-block-footer",
  templateUrl: "./block-footer.component.html",
  styleUrls: ["./block-footer.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ReservationsBlockFooterComponent implements OnInit, AfterViewInit {
  private _item: any;
  @Input() locale: string;
  @Input() set item(value: any) {
    value.confirmationDate = value.statusHistory.find((status) => status.to === "confirmed")?.date;
    this._item = value;
    this.getItemTags();
  }
  get item() {
    return this._item;
  }

  @ViewChild("tagsElement", { static: false }) tagsElement: ElementRef;
  @ViewChild("tagsSocietyBtn", { static: false }) tagsSocietyBtn: ElementRef;
  @ViewChild("tagsSocietyDrpdwn", { static: false })
  tagsSocietyDrpdwn: ElementRef;
  @ViewChild("tagsTicketBtn", { static: false }) tagsTicketBtn: ElementRef;
  @ViewChild("tagsTicketDrpdwn", { static: false })
  tagsTicketDrpdwn: ElementRef;

  public tags: any[];
  public societyTags: BehaviorSubject<Tag[]>;

  public tagUpdateMessage: string;
  public tagInput: any;
  public filteredTag: any[];
  public isTagsHeightMoreThan51: boolean;
  public hideSocietyTags: boolean = true;
  public hideTicketTags: boolean = true;
  private tagsHeight: number;

  constructor(
    public commonService: CommonService,
    private tagService: TagService,
    private modalService: ModalService,
  ) {
    this.societyTags = this.tagService.societyTags;
  }

  ngOnInit(): void {
    this.commonService.documentClickedTarget.subscribe((target: any) => this.documentClickListener(target));
  }

  getItemTags() {
    if (this.item.tagIds && this.item.tagIds.length > 0) {
      this.tags = this.item.tagIds
        .map((tagId) => {
          const tag = this.societyTags.value.find((societyTag) => societyTag.id === tagId);
          return tag;
        })
        .filter((tag) => !!tag);
    } else {
      this.tags = [];
    }
  }

  ngAfterViewInit(): void {
    if (this.tagsElement) {
      setTimeout(() => {
        this.isTagsHeightMoreThan51 = this.tagsElement.nativeElement.offsetHeight <= 51;
      }, 100);
    }
  }

  documentClickListener(target: any): void {
    if (
      this.tagsSocietyBtn &&
      !this.tagsSocietyBtn.nativeElement.contains(target) &&
      this.tagsSocietyDrpdwn &&
      !this.tagsSocietyDrpdwn.nativeElement.contains(target)
    ) {
      this.hideSocietyTags = true;
    }

    if (
      this.tagsSocietyBtn &&
      !this.tagsTicketBtn.nativeElement.contains(target) &&
      this.tagsSocietyDrpdwn &&
      !this.tagsTicketDrpdwn.nativeElement.contains(target)
    ) {
      this.hideTicketTags = true;
    }
  }

  updateTags(tagIds) {
    this.tagService.set(tagIds, this.item.id).subscribe();
  }

  toggleTicketTags(): void {
    this.hideTicketTags = !this.hideTicketTags;
    this.closeSocietyTags();
  }

  closeTicketTags(): void {
    this.hideTicketTags = true;
  }

  toggleSocietyTags(): void {
    this.hideSocietyTags = !this.hideSocietyTags;
    this.closeTicketTags();
  }

  closeSocietyTags(): void {
    this.hideSocietyTags = true;
  }

  openEtickets(item: any): void {
    if (item.documentIds.length > 1) {
      let reference: string;
      switch (item.type) {
        case "train":
          reference = this.item.detail?.orderItems.map((o) => o.carrierReference).join(",");
          break;
        case "hotel":
          reference = item.detail.bookingref;
      }
      this.modalService.openModal(EticketsModalComponent, {
        data: {
          etickets: item.documentIds,
          type: item.type,
          reference,
          travelers: item.travelers,
        },
        dismissableMask: true,
        showHeader: false,
      });
    } else {
      if (item.documentIds[0].data) {
        window.open(item.documentIds[0].data, "_blank");
      } else {
        this.commonService.openLink(item.documentIds[0]);
      }
    }
  }
}
