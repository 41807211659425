import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "normalizeFilter",
})
export class NormalizeFilterPipe implements PipeTransform {
  transform(items: any[], searchInProperties: string[], searchTerm: string): unknown {
    const normalizeSearchTerm = normalizeStringForSearch(searchTerm);
    if (!normalizeSearchTerm) {
      return items;
    }
    return items.filter((item) =>
      searchInProperties.some((prop) => normalizeStringForSearch(item[prop]).indexOf(normalizeSearchTerm) > -1),
    );
  }
}

const REMOVE_MAP = {
  ss: /ß/g,
  SS: /ẞ/g,
  IJ: /Ĳ/g,
  ij: /ĳ/g,
  A: /[AⒶＡÀÁÂẦẤẪẨÃĀĂẰẮẴẲȦǠÄǞẢÅǺǍȀȂẠẬẶḀĄ]/g,
  AA: /[Ꜳ]/g,
  AE: /[ÆǼǢ]/g,
  AO: /[Ꜵ]/g,
  AU: /[Ꜷ]/g,
  AV: /[ꜸꜺ]/g,
  AY: /[Ꜽ]/g,
  B: /[BⒷＢḂḄḆɃƂƁ]/g,
  C: /[CⒸＣĆĈĊČÇḈƇȻꜾ]/g,
  D: /[DⒹＤḊĎḌḐḒḎĐƋƊƉꝹ]/g,
  DZ: /[ǱǄ]/g,
  Dz: /[ǲǅ]/g,
  E: /[EⒺＥÈÉÊỀẾỄỂẼĒḔḖĔĖËẺĚȄȆẸỆȨḜĘḘḚƐƎ]/g,
  F: /[FⒻＦḞƑꝻ]/g,
  G: /[GⒼＧǴĜḠĞĠǦĢǤƓꞠꝽꝾ]/g,
  H: /[HⒽＨĤḢḦȞḤḨḪĦⱧⱵꞍ]/g,
  I: /[IⒾＩÌÍÎĨĪĬİÏḮỈǏȈȊỊĮḬƗ]/g,
  J: /[JⒿＪĴɈ]/g,
  K: /[KⓀＫḰǨḲĶḴƘⱩꝀꝂꝄꞢ]/g,
  L: /[LⓁＬĿĹĽḶḸĻḼḺŁȽⱢⱠꝈꝆꞀ]/g,
  LJ: /[Ǉ]/g,
  Lj: /[ǈ]/g,
  M: /[MⓂＭḾṀṂⱮƜ]/g,
  N: /[NⓃＮǸŃÑṄŇṆŅṊṈȠƝꞐꞤ]/g,
  NJ: /[Ǌ]/g,
  Nj: /[ǋ]/g,
  O: /[OⓄＯÒÓÔỒỐỖỔÕṌȬṎŌṐṒŎȮȰÖȪỎŐǑȌȎƠỜỚỠỞỢỌỘǪǬØǾƆƟꝊꝌ]/g,
  OE: /Œ/g,
  OI: /[Ƣ]/g,
  OO: /[Ꝏ]/g,
  OU: /[Ȣ]/g,
  P: /[PⓅＰṔṖƤⱣꝐꝒꝔ]/g,
  Q: /[QⓆＱꝖꝘɊ]/g,
  R: /[RⓇＲŔṘŘȐȒṚṜŖṞɌⱤꝚꞦꞂ]/g,
  S: /[SⓈＳŚṤŜṠŠṦṢṨȘŞⱾꞨꞄ]/g,
  T: /[TⓉＴṪŤṬȚŢṰṮŦƬƮȾꞆ]/g,
  TZ: /[Ꜩ]/g,
  U: /[UⓊＵÙÚÛŨṸŪṺŬÜǛǗǕǙỦŮŰǓȔȖƯỪỨỮỬỰỤṲŲṶṴɄ]/g,
  V: /[VⓋＶṼṾƲꝞɅ]/g,
  VY: /[Ꝡ]/g,
  W: /[WⓌＷẀẂŴẆẄẈⱲ]/g,
  X: /[XⓍＸẊẌ]/g,
  Y: /[YⓎＹỲÝŶỸȲẎŸỶỴƳɎỾ]/g,
  Z: /[ZⓏＺŹẐŻŽẒẔƵȤⱿⱫꝢ]/g,
  a: /[aⓐａẚàáâầấẫẩãāăằắẵẳȧǡäǟảåǻǎȁȃạậặḁąⱥɐ]/g,
  aa: /[ꜳ]/g,
  ae: /[æǽǣ]/g,
  ao: /[ꜵ]/g,
  au: /[ꜷ]/g,
  av: /[ꜹꜻ]/g,
  ay: /[ꜽ]/g,
  b: /[bⓑｂḃḅḇƀƃɓ]/g,
  c: /[cⓒｃćĉċčçḉƈȼꜿↄ]/g,
  d: /[dⓓｄḋďḍḑḓḏđƌɖɗꝺ]/g,
  dz: /[ǳǆ]/g,
  e: /[eⓔｅèéêềếễểẽēḕḗĕėëẻěȅȇẹệȩḝęḙḛɇɛǝ]/g,
  f: /[fⓕｆḟƒꝼ]/g,
  g: /[gⓖｇǵĝḡğġǧģǥɠꞡᵹꝿ]/g,
  h: /[hⓗｈĥḣḧȟḥḩḫẖħⱨⱶɥ]/g,
  hv: /[ƕ]/g,
  i: /[iⓘｉìíîĩīĭïḯỉǐȉȋịįḭɨı]/g,
  j: /[jⓙｊĵǰɉ]/g,
  k: /[kⓚｋḱǩḳķḵƙⱪꝁꝃꝅꞣ]/g,
  l: /[lⓛｌŀĺľḷḹļḽḻſłƚɫⱡꝉꞁꝇ]/g,
  lj: /[ǉ]/g,
  m: /[mⓜｍḿṁṃɱɯ]/g,
  n: /[nⓝｎǹńñṅňṇņṋṉƞɲŉꞑꞥ]/g,
  nj: /[ǌ]/g,
  o: /[oⓞｏòóôồốỗổõṍȭṏōṑṓŏȯȱöȫỏőǒȍȏơờớỡởợọộǫǭøǿɔꝋꝍɵ]/g,
  oe: /œ/g,
  oi: /[ƣ]/g,
  ou: /[ȣ]/g,
  oo: /[ꝏ]/g,
  p: /[pⓟｐṕṗƥᵽꝑꝓꝕ]/g,
  q: /[qⓠｑɋꝗꝙ]/g,
  r: /[rⓡｒŕṙřȑȓṛṝŗṟɍɽꝛꞧꞃ]/g,
  s: /[sⓢｓśṥŝṡšṧṣṩșşȿꞩꞅẛ]/g,
  t: /[tⓣｔṫẗťṭțţṱṯŧƭʈⱦꞇ]/g,
  tz: /[ꜩ]/g,
  u: /[uⓤｕùúûũṹūṻŭüǜǘǖǚủůűǔȕȗưừứữửựụṳųṷṵʉ]/g,
  v: /[vⓥｖṽṿʋꝟʌ]/g,
  vy: /[ꝡ]/g,
  w: /[wⓦｗẁẃŵẇẅẘẉⱳ]/g,
  x: /[xⓧｘẋẍ]/g,
  y: /[yⓨｙỳýŷỹȳẏÿỷẙỵƴɏỿ]/g,
  z: /[zⓩｚźẑżžẓẕƶȥɀⱬꝣ]/g,
};

export function normalizeStringForSearch(str: string): string {
  if (!str) {
    return "";
  }
  return removeDiacriticsAndExpandLigature(str.trim().toLowerCase())
    .replace(/[^a-z0-9\/]+/, " ")
    .trim();
}

/**
 * Voir: https://stackoverflow.com/questions/286921/efficiently-replace-all-accented-characters-in-a-string
 * @param str
 * @returns
 */
function removeDiacriticsAndExpandLigature(str: string) {
  for (var latin in REMOVE_MAP) {
    var nonLatin = REMOVE_MAP[latin];
    str = str.replace(nonLatin, latin);
  }

  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
