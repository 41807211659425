<span class="title">{{ item.detail.title }}</span>
<i
  class="fas fa-info-circle m-l-5"
  *ngIf="item.offline || item.provider === 'offline'"
  (mouseover)="commonService.isTablet ? undefined : serviceFeePanel.show($event)"
  (mouseleave)="commonService.isTablet ? undefined : serviceFeePanel.hide()"
  (mousedown)="commonService.isTablet ? serviceFeePanel.toggle($event) : undefined"
></i>

<p-overlayPanel #serviceFeePanel styleClass="fee-overlay" appendTo="body">
  <div class="p-20">
    <p class="fee-overlay-description">
      {{ "ITEM.FEE.SERVICE" | translate }}
    </p>
  </div>
</p-overlayPanel>
