import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private _pendingRequests: number = 0;

  constructor() {}

  add(): void {
    this._pendingRequests++;

    if (1 === this._pendingRequests) {
      this.loading.next(true);
    } else {
      this._pendingRequests = 1;
      this.loading.next(true);
    }
  }

  remove(): void {
    this._pendingRequests--;
    if (0 === this._pendingRequests) {
      this.loading.next(false);
    } else {
      this._pendingRequests = 0;
      this.loading.next(false);
    }
  }

  init(): void {
    this._pendingRequests = 0;
    this.loading.next(null);
  }
}
