import { Component, ViewEncapsulation, OnInit, Input } from "@angular/core";
import moment from "moment";
import { FlightService } from "src/app/travel/flight/flight.service";
import _companies from "../../../../companies";
import { CarbonOffsetTypes } from "../../../../@types/carbon-offset";

@Component({
  selector: "confirmation-sidebar-flight",
  styleUrls: ["./confirmation-sidebar-flight.component.scss"],
  templateUrl: "./confirmation-sidebar-flight.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationSidebarFlightComponent implements OnInit {
  public companies: any = _companies;
  @Input() itineraries: Array<any>;
  @Input() data: any;
  @Input() viewOnly: boolean;
  @Input() selectedItinerary?: any;
  @Input() itineraryWay?: string;
  public dateEnd: Date;
  public superflexMandatory: boolean = false;

  public tagIds: any;
  public billing: any;
  public tags: Array<any>;
  public showLabelSide: boolean = false;

  public commentPolicy: string;

  public userLabel: any;
  public comment: any;
  // carbonOffset: any;

  private bookings: any = [];
  public superflex: boolean;
  protected readonly superflexRate: number = 0.3;
  public superflexAmount: number;
  public total: number;
  public airportsDifferents: boolean;

  public carbonOffset: CarbonOffsetTypes.CarbonOffsetEstimation = {
    amount: 0,
    price: 0,
  };
  public isOutOfPolicy: boolean = false;
  public users: any[];

  constructor(public flightService: FlightService) {}

  ngOnInit(): void {
    this.dateEnd = moment(`${this.itineraries[0].legs[0].departureDate}T${this.itineraries[0].legs[0].departureTime}`)
      .subtract(1, "day")
      .toDate();
    if (this.viewOnly === true) {
      this.flightService.airports = this.data.detail.airports;
      this.total = this.data.price.amount;
      this.users = this.data.detail.users;
      this.superflex = this.data.detail.superflex;
    } else {
      this.total = 0;
      this.itineraries.forEach((itinerary) => {
        this.total += itinerary.price ? itinerary.price : itinerary.totalPrice;
        if (itinerary.isOutPolicy) {
          this.isOutOfPolicy = true;
        }
      });
      this.users = this.data.users;

      this.data.price = {
        currency: "EUR",
        amount: this.total,
      };
      this.superflexAmount = this.total * this.superflexRate;
      this.superflex = false;
      this.data.superflex = this.superflex;
      this.airportsDifferents = false;
    }
  }

  activeSuperflex(active: boolean = false): void {
    this.data.superflex = active;

    this.superflex = active;
    let price = 0;
    this.itineraries.forEach((itinerary) => {
      price += itinerary.price ? itinerary.price : itinerary.totalPrice;
    });
    this.total = this.superflex === true ? Number(price) + Number(price) * this.superflexRate : Number(price);
  }
}
