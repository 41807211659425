<div
  class="carbon-offset-container"
  *ngIf="carbonOffset && isDetailedCarbonLabel !== false; else searchResultCarbonLabel"
>
  <p class="carbon-offset-paragraph" *ngIf="carbonOffset.amount > 0; else unavailable">
    <img src="./assets/svg/leaf-green.svg" alt="" />
    <span>
      {{ "CARBON_OFFSET.SENTENCE" | translate }}
      <strong>
        {{ carbonOffset.amount | kiloFormatter }} <span [innerHTML]="'CARBON_OFFSET.UNIT' | translate"></span>
      </strong>
    </span>
  </p>
  <ng-template #unavailable>
    <p class="carbon-offset-paragraph" translate>CARBON_OFFSET.UNAVAILABLE</p>
  </ng-template>
</div>
<ng-template #searchResultCarbonLabel>
  <span class="search-result-label">
    <img src="./assets/svg/leaf-green.svg" alt="" /> {{ carbonOffset?.amount | kiloFormatter }}
    <span [innerHTML]="'CARBON_OFFSET.UNIT' | translate"></span>
  </span>
</ng-template>
