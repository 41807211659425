import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { SocietyService } from "src/app/@shared/services/society.service";
import { MemberSociety } from "src/app/@shared/@types/society";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { SuggestPanelService } from "../suggest-panel.service";
import { CommonService } from "src/app/@shared/services/common.service";
import { Subscription } from "rxjs";

@Component({
  selector: "spt-search-people",
  templateUrl: "./search-people.component.html",
  styleUrls: ["./search-people.component.scss"],
})
export class SearchPeopleComponent implements OnInit {
  @ViewChild("template", { static: true }) template!: TemplateRef<any>;
  @Input() pickableMembers: MemberSociety[];
  @Input() gridArea: string = "people";
  @Input() suggestPanelName: string = "people";
  @Input() people!: MemberSociety[];
  @Output() onCloseSuggest: EventEmitter<void> = new EventEmitter<void>();
  @Output() onActivateSuggest: EventEmitter<string> = new EventEmitter();
  @Output() onPeopleListChange: EventEmitter<MemberSociety[]> = new EventEmitter<MemberSociety[]>();
  @Input() searchEngineType: string;

  public allMembers: MemberSociety[] = [];
  form: UntypedFormGroup;

  @Input()
  public membersMax: number = 4;

  private isActive: boolean = false;
  private observablesToClearOnDestroy: Subscription[] = [];

  public isSearch: boolean;
  public title: string;
  public dialogRef: DynamicDialogRef;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private fb: UntypedFormBuilder,
    private societyService: SocietyService,
    protected suggestPanelService: SuggestPanelService,
    // Utilisé dans le template
    protected commonService: CommonService,
  ) {}

  ngOnInit(): void {
    if (!this.people) {
      throw new Error("People attribute must be defined");
    }
    this.observablesToClearOnDestroy.push(
      this.suggestPanelService.onChange.subscribe((panelName) => (this.isActive = this.suggestPanelName === panelName)),
    );
    // Permet de ne pas avoir le tag <stp-locality-autocomplete/> présent dans le DOM
    // (cf.https://stackoverflow.com/questions/38716105/angular2-render-a-component-without-its-wrapping-tag#answer-56887630)
    // Indispensable pour pouvoir utiliser les 'grid-template-areas'
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
    const form: UntypedFormGroup = this.fb.group({
      type: this.searchEngineType,
    });
    this.form = form;
    this.allMembers = this.societyService.society.value.members;

    if (this.allMembers.length === 1) {
      this.onChangeSelectedMembers(this.allMembers);
    }
  }

  onChangeSelectedMembers(selectedMembers: MemberSociety[]) {
    this.people.length = 0;
    this.people.push(...selectedMembers);
    this.onPeopleListChange.emit(this.people);
  }

  activateSuggest(): void {
    if (this.allMembers.length === 1) {
      return;
    }
    this.onActivateSuggest.emit(this.suggestPanelName);
  }

  hideSuggest(): void {
    this.onCloseSuggest.emit();
  }
}
