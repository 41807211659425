import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { TransferTypes } from "../../../../../travel/transfer/transfer";
import { User } from "../../../../@types/user";
import { Society } from "../../../../@types/society";
import moment, { Moment } from "moment";
import vehicleTypes from "src/assets/vehicles";
import { UtilsTypes } from "../../../../@types/utils";
import { CarbonOffsetTypes } from "../../../../@types/carbon-offset";

const TRANSPORTATION_TYPES: Array<string> = ["AIR", "IATA", "STN"];
const ACCOMMODATION_TYPES: Array<string> = ["RST", "GEO", "HTL", "GIATA", "TTI", "ATLAS"];

@Component({
  selector: "spt-travel-confirmation-transfer",
  templateUrl: "./confirmation-sidebar-transfer.component.html",
  styleUrls: ["./confirmation-sidebar-transfer.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationSidebarTransferComponent implements OnInit {
  @Input() data: any;
  @Input() travelers: Array<User>;
  @Input() locale: string;
  @Input() isSmallDevice: boolean;
  @Input() search: {
    id: string;
    data: TransferTypes.SearchBody;
    society: Society;
  };
  @Input() price: UtilsTypes.Price;
  @Output() informationSet: EventEmitter<boolean> = new EventEmitter();
  product: {
    travelling: TransferTypes.ProductBlock;
    returning?: TransferTypes.ProductBlock;
  };
  pickupSearchType: string;
  dropoffSearchType: string;
  searchIcons: any = {
    travelling: {
      pickup: "fas fa-transfer fa-2x",
      dropoff: "fas fa-transfer fa-2x",
    },
    returning: {
      pickup: "fas fa-transfer fa-2x",
      dropoff: "fas fa-transfer fa-2x",
    },
  };
  outboundPlaceholder: any = {
    AIR: "SEARCH.TRANSFER.FLIGHT_DEPARTURE",
    IATA: "SEARCH.TRANSFER.FLIGHT_DEPARTURE",
    GEO: "SEARCH.DEPARTURE_START",
    RST: "SEARCH.DEPARTURE_START",
    STN: "SEARCH.TRANSFER.TRAIN_DEPARTURE",
  };
  inboundPlaceholder: any = {
    AIR: "SEARCH.TRANSFER.FLIGHT_ARRIVAL",
    IATA: "SEARCH.TRANSFER.FLIGHT_ARRIVAL",
    GEO: "SEARCH.ARRIVAL",
    RST: "SEARCH.ARRIVAL",
    STN: "SEARCH.TRANSFER.TRAIN_ARRIVAL",
  };
  complementaryPlaceholders: { number: string; name: string } = {
    number: "",
    name: "",
  };
  isTransportation: { pickup: boolean; dropoff: boolean };
  isAccommodation: { pickup: boolean; dropoff: boolean };
  travellingNumber: string;
  travellingTerminal: string;
  returningNumber: string;
  returningTerminal: string;
  wheelchairNumber: number;
  vehicles: number = 1;
  returningVehicles: number = 0;
  vehicleTypes: any = vehicleTypes;
  productType: string = "travelling";
  estimatedPickupDatetime: Moment;
  estimatedReturningPickupDatetime: Moment;
  estimatedDropoffDatetime: Moment;
  estimatedReturningDropoffDatetime: Moment;
  carbonOffset: CarbonOffsetTypes.CarbonOffsetEstimation = {
    amount: 0,
    price: 0,
  };

  ngOnInit(): void {
    if (!this.price) {
      this.price = this.data.price;
    }

    if (this.search && this.search.data.returning) {
      this.returningVehicles = 1;
    }

    this.product = this.data.product;
    this.pickupSearchType = this.data.pickupSearchType ? this.data.pickupSearchType : this.data.travelling.pickup.type;
    this.dropoffSearchType = this.data.dropoffSearchType
      ? this.data.dropoffSearchType
      : this.data.travelling.dropoff.type;
    this.carbonOffset.amount += this.product.travelling.general.carbonOffset.amount;
    this.carbonOffset.price += this.product.travelling.general.carbonOffset.price;

    switch (this.pickupSearchType) {
      case "AIR":
      case "IATA":
        this.searchIcons.travelling.pickup = "p-col-2 fas fa-2x fa-plane-arrival";
        this.searchIcons.returning.dropoff = "p-col-2 fas fa-2x fa-plane-departure";
        this.complementaryPlaceholders.number = "SEARCH.TRANSFER.FLIGHT_NUMBER";
        break;
      case "STN":
        this.searchIcons.travelling.pickup = "p-col-2 fas fa-2x fa-train";
        this.searchIcons.returning.dropoff = "p-col-2 fas fa-2x fa-train";
        this.complementaryPlaceholders.number = "SEARCH.TRANSFER.TRAIN_NUMBER";
        break;
      case "RST":
        this.searchIcons.travelling.pickup = "p-col-2 fas fa-2x fa-hotel";
        this.searchIcons.returning.dropoff = "p-col-2 fas fa-2x fa-hotel";
        this.complementaryPlaceholders.name = "SEARCH.TRANSFER.HOTEL_NAME";
        break;
      default:
        this.complementaryPlaceholders.name = "SEARCH.TRANSFER.ADDRESS_NAME";
        this.searchIcons.travelling.pickup = "p-col-2 fas fa-2x fa-map-marker-alt";
        this.searchIcons.returning.dropoff = "p-col-2 fas fa-2x fa-map-marker-alt";
        break;
    }

    switch (this.dropoffSearchType) {
      case "AIR":
      case "IATA":
        this.searchIcons.travelling.dropoff = "p-col-2 fas fa-2x fa-plane-departure";
        this.searchIcons.returning.pickup = "p-col-2 fas fa-2x fa-plane-arrival";
        this.complementaryPlaceholders.number = "SEARCH.TRANSFER.FLIGHT_NUMBER";
        break;
      case "STN":
        this.searchIcons.travelling.dropoff = "p-col-2 fas fa-2x fa-train";
        this.searchIcons.returning.pickup = "p-col-2 fas fa-2x fa-train";
        this.complementaryPlaceholders.number = "SEARCH.TRANSFER.TRAIN_NUMBER";
        break;
      case "RST":
        this.searchIcons.travelling.dropoff = "p-col-2 fas fa-2x fa-hotel";
        this.searchIcons.returning.pickup = "p-col-2 fas fa-2x fa-hotel";
        this.complementaryPlaceholders.name = "SEARCH.TRANSFER.HOTEL_NAME";
        break;
      default:
        this.searchIcons.travelling.dropoff = "p-col-2 fas fa-2x fa-map-marker-alt";
        this.searchIcons.returning.pickup = "p-col-2 fas fa-2x fa-map-marker-alt";
        this.complementaryPlaceholders.name = "SEARCH.TRANSFER.ADDRESS_NAME";
        break;
    }

    this.isTransportation = {
      pickup: TRANSPORTATION_TYPES.includes(this.pickupSearchType),
      dropoff: TRANSPORTATION_TYPES.includes(this.dropoffSearchType),
    };
    this.isAccommodation = {
      pickup: ACCOMMODATION_TYPES.includes(this.pickupSearchType),
      dropoff: ACCOMMODATION_TYPES.includes(this.dropoffSearchType),
    };

    if (TRANSPORTATION_TYPES.includes(this.pickupSearchType)) {
      if (!this.data.pickupNumber) {
        this.informationSet.emit(false);
      }
    }

    if (TRANSPORTATION_TYPES.includes(this.dropoffSearchType)) {
      if (!this.data.dropoffNumber) {
        this.informationSet.emit(false);
      }
    }

    if (this.search) {
      if (TRANSPORTATION_TYPES.includes(this.dropoffSearchType)) {
        this.estimatedPickupDatetime = moment(this.search.data.travelling).subtract(
          this.product.travelling.general.transfertime + 150,
          "m",
        );
        this.data.travelling.pickup.date = this.estimatedPickupDatetime.format("YYYY-MM-DDTHH:mm:ss");
        this.data.travelling.dropoff.date = this.estimatedPickupDatetime
          .add(this.product.travelling.general.transfertime, "m")
          .format("YYYY-MM-DDTHH:mm:ss");

        if (this.search.data.returning && this.product.returning) {
          this.estimatedReturningDropoffDatetime = moment(this.search.data.returning).add(
            this.product.returning.general.transfertime,
            "m",
          );
          this.data.returning.dropoff.date = this.estimatedReturningDropoffDatetime.format("YYYY-MM-DDTHH:mm:ss");
        }
      } else {
        this.estimatedDropoffDatetime = moment(this.search.data.travelling).add(
          this.product.travelling.general.transfertime,
          "m",
        );
        this.data.travelling.dropoff.date = this.estimatedDropoffDatetime.format("YYYY-MM-DDTHH:mm:ss");

        if (this.search.data.returning && this.product.returning) {
          this.estimatedReturningPickupDatetime = moment(this.search.data.returning).subtract(
            this.product.returning.general.transfertime + 150,
            "m",
          );
          this.data.returning.pickup.date = this.estimatedReturningPickupDatetime.format("YYYY-MM-DDTHH:mm:ss");
          this.data.returning.dropoff.date = this.search.data.returning;
        }
      }
    }

    if (this.data.booking) {
      if (this.data.booking.transfers[0].fromdetails.flight || this.data.booking.transfers[0].todetails.flight) {
        this.travellingNumber = (
          this.data.booking.transfers[0].fromdetails.flight || this.data.booking.transfers[0].todetails.flight
        ).flightnumber;
        this.travellingTerminal = (
          this.data.booking.transfers[0].fromdetails.flight || this.data.booking.transfers[0].todetails.flight
        ).terminal;
      } else if (this.data.booking.transfers[0].fromdetails.train || this.data.booking.transfers[0].todetails.train) {
        this.travellingNumber = (
          this.data.booking.transfers[0].fromdetails.train || this.data.booking.transfers[0].todetails.train
        ).trainnumber;
      }

      if (this.data.booking.transfers[1]) {
        if (this.data.booking.transfers[1].fromdetails.flight || this.data.booking.transfers[1].todetails.flight) {
          this.returningNumber = (
            this.data.booking.transfers[1].fromdetails.flight || this.data.booking.transfers[1].todetails.flight
          ).flightnumber;
          this.returningTerminal = (
            this.data.booking.transfers[1].fromdetails.flight || this.data.booking.transfers[1].todetails.flight
          ).terminal;
        } else if (this.data.booking.transfers[1].fromdetails.train || this.data.booking.transfers[1].todetails.train) {
          this.returningNumber = (
            this.data.booking.transfers[1].fromdetails.train || this.data.booking.transfers[1].todetails.train
          ).trainnumber;
        }
      }
    }
  }

  vehiclesChange(): void {
    this.price.amount = this.vehicles * this.product.travelling.pricing.price;

    if (this.returningVehicles) {
      this.price.amount += this.returningVehicles * this.product.returning.pricing.price;
    }
  }

  numberSet(number: string, direction: "travelling" | "returning"): void {
    if (number) {
      if (
        (["AIR", "IATA", "STN", "BUS"].includes(this.pickupSearchType) && direction === "travelling") ||
        (["AIR", "IATA", "STN", "BUS"].includes(this.dropoffSearchType) && direction === "returning")
      ) {
        this.data[direction].pickup.number = number;
      } else if (
        (["AIR", "IATA", "STN", "BUS"].includes(this.dropoffSearchType) && direction === "travelling") ||
        (["AIR", "IATA", "STN", "BUS"].includes(this.pickupSearchType) && direction === "returning")
      ) {
        this.data[direction].dropoff.number = number;
      }

      if (
        ((this.search && this.search.data.returning) ||
          (this.data.booking && this.data.booking.transfers && this.data.booking.transfers[1])) &&
        ((!!this.data.travelling.pickup.number && !!this.data.returning.dropoff.number) ||
          (!!this.data.travelling.dropoff.number && !!this.data.returning.pickup.number))
      ) {
        this.informationSet.emit(true);
      } else if (
        ((this.search && !this.search.data.returning) ||
          (this.data.booking && this.data.booking.transfers && !this.data.booking.transfers[1])) &&
        (!!this.data[direction].pickup.number || !!this.data[direction].dropoff.number)
      ) {
        this.informationSet.emit(true);
      }
    }
  }

  terminalSet(terminal: string, type: "travelling" | "returning"): void {
    if (["AIR", "IATA", "PRT"].includes(this.pickupSearchType)) {
      this.data[type].pickup.terminal = terminal;
    } else if (["AIR", "IATA", "PRT"].includes(this.dropoffSearchType)) {
      this.data[type].dropoff.terminal = terminal;
    }
  }
}
