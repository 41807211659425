<p-sidebar
  #labelSidebar
  [dismissible]="false"
  [showCloseIcon]="false"
  [(visible)]="this.visible"
  (onHide)="changeDisplayPanel(false)"
  position="right"
  styleClass="p-sidebar-md overflow-auto p-10"
  appendTo="body"
>
  <div>
    <p class="txt-23 m-b-10">
      {{ "BOOKING.POLICY_COMMENT" | translate }}<b>{{ this.canValidateBasket?.commentMandatory ? "*" : "" }}</b>
    </p>

    <textarea
      pInputTextarea
      [placeholder]="'BOOKING.FILL_COMMENT' | translate"
      maxlength="150"
      [autoResize]="true"
      [rows]="2"
      [(ngModel)]="comment"
      [required]="this.canValidateBasket?.commentMandatory"
    ></textarea>
  </div>

  <div *ngIf="userLabelsForm && labels?.length">
    <p class="txt-23 m-b-10">{{ "LABELS.FILL" | translate }}</p>

    <form novalidate [formGroup]="userLabelsForm">
      <ng-container formArrayName="labels">
        <div *ngFor="let label of labelsArr.controls; let i = index">
          <label class="txt-20 m-b-5" #opTarget>
            {{ this.labels[i].label }}<b>{{ this.labels[i].required ? "*" : "" }}</b> &nbsp;<i
              *ngIf="this.labels[i].description"
              class="fa fa-info-circle pointer"
              (click)="op.toggle($event, opTarget)"
              (mouseover)="op.show($event, opTarget)"
              (mouseout)="op.hide()"
            ></i>
            <p-overlayPanel
              styleClass="p-10 label-description"
              [dismissable]="true"
              [showCloseIcon]="false"
              [appendTo]="'body'"
              #op
            >
              {{ this.labels[i].description }}
            </p-overlayPanel>

            <ng-container *ngIf="label.label === this.societyService.society.value.settings?.config?.systemRequest">
              {{ lastLabelSystemRequestDescription }}
            </ng-container>
          </label>

          <div class="grid" [ngSwitch]="this.labels[i].type">
            <div class="label-container" *ngSwitchCase="'simple'">
              <p-dropdown
                styleClass="w-full"
                [options]="this.labels[i].defaults"
                [optionLabel]="'label'"
                [required]="this.labels[i].required"
                [placeholder]="'LABELS.CHOOSE' | translate"
                [filter]="this.labels[i].defaults?.length > 4"
                [filterFields]="['label']"
                panelStyleClass="fullscreen lg"
                [formControlName]="i"
                [showClear]="!this.labels[i].required"
                [virtualScroll]="true"
                [virtualScrollItemSize]="30"
                appendTo="body"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <span>{{ item?.label | translate }}</span>
                </ng-template>
                <ng-template let-opt pTemplate="item">
                  <div>{{ opt.label | translate }}</div>
                </ng-template>
              </p-dropdown>
            </div>

            <div class="label-container" *ngSwitchCase="'text'">
              <input pInputText [formControlName]="i" [required]="this.labels[i].required" />
            </div>
          </div>
        </div>
      </ng-container>
    </form>

    <p class="txt-19">* {{ "LABELS.REQUIRED" | translate }}</p>
  </div>
  <ng-container>
    <div class="grid justify-content-end">
      <p [ngClass]="(commonService.isTabletObservable | async) === true ? 'col-6' : 'col-4'">
        <p-button styleClass="p-button-secondary medium" [label]="'GLOBAL.CANCEL' | translate" (click)="cancelLabel()">
        </p-button>
      </p>
      <p [ngClass]="(commonService.isTabletObservable | async) === true ? 'col-6' : 'col-4'">
        <p-button
          [label]="'LABELS.VALIDATE' | translate"
          (onClick)="validate()"
          styleClass="medium"
          [disabled]="
            (this.userLabelsForm && !this.userLabelsForm?.valid) ||
            (!this.canValidateBasket?.isAuthorized &&
              this.canValidateBasket?.commentMandatory &&
              !this.comment &&
              this.comment?.length < 1) ||
            loading
          "
        >
        </p-button>
      </p>
    </div>
  </ng-container>
</p-sidebar>
