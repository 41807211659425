import { Injectable } from "@angular/core";
import { map, Observable, of } from "rxjs";
import {
  DateTimeSearch,
  Locality,
  SearchCriteria,
  TimeRange,
  SearchEngineService,
  Travel,
  WhenSearch,
  SearchResult,
  SearchOptions,
  LastSearchItem,
  lastSearches,
  Search,
} from "../search-engine-service";
import { FlightService } from "src/app/@shared/services/flight.service";
import { UserService } from "src/app/@shared/services/user.service";
import { User } from "src/app/@shared/@types/user";
import { SearchService } from "src/app/@shared/services/search.service";
import { FlightTypes } from "src/app/@shared/@types/flight";
import { TranslateService } from "@ngx-translate/core";
import { SocietyService } from "src/app/@shared/services/society.service";
import { MemberSociety, Society } from "src/app/@shared/@types/society";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class FlightSearchService implements SearchEngineService<FlightLocality, FlightSearchOptions> {
  private user: User;
  private society: Society;

  constructor(
    private httpClient: HttpClient,
    private flightService: FlightService,
    private searchService: SearchService,
    private translateService: TranslateService,
    societyService: SocietyService,
    userService: UserService,
  ) {
    this.user = userService.user.getValue();
    this.society = societyService.society.value;
  }

  getType(): "flight" {
    return "flight";
  }

  getMaxPassengers(searchCriteria: SearchCriteria<FlightLocality, FlightSearchOptions>): number {
    return 9;
  }

  getMinimumNumberOfCharactersForLocalitySearch(): number {
    return 1;
  }

  localitySearch(searchString: string): Observable<FlightLocality[]> {
    if (!searchString || searchString.trim().length < this.getMinimumNumberOfCharactersForLocalitySearch()) {
      throw new Error(
        "Please provide at least " +
          this.getMinimumNumberOfCharactersForLocalitySearch() +
          " characters to launch search.",
      );
    }
    return this.flightService
      .locate(searchString, this.user.settings.language)
      .pipe(map((arrayOfJson) => arrayOfJson.map((json) => this.createLocalityFromJson(json))));
  }

  fetchDetailsForLocality(locality: FlightLocality): Observable<FlightLocality> {
    // Pas besoin de détails supplémentaires pour le moment.
    // La localité "Vol" a déjà tout ce qu'il faut dès la récupération des résultats de recherche
    return of(locality);
  }

  launchSearch(
    searchCriteria: SearchCriteria<FlightLocality, FlightSearchOptions>,
    oldItemId?: string,
  ): Observable<SearchResult> {
    const trips: FlightTypes.Trip[] = searchCriteria.travels
      .flatMap((travel) => {
        if (travel.when.inward) {
          // Convertion du retour en un nouveau trajet
          return [
            new Travel<FlightLocality>(
              travel.people,
              new WhenSearch(travel.when.outward),
              travel.destination,
              travel.origin,
            ),
            new Travel<FlightLocality>(
              travel.people,
              new WhenSearch(travel.when.inward),
              travel.origin,
              travel.destination,
            ),
          ];
        }
        return [travel];
      })
      .map((travel) => this.travelToTrip(travel, searchCriteria.options));

    const data: FlightTypes.Search = {
      trips: trips,
      societyId: this.society._id,
      userIds: searchCriteria.mainTravel.people.map((p) => p.user._id),
      type: this.travelType(searchCriteria),
      baggages: searchCriteria.options.luggages === -1 ? null : searchCriteria.options.luggages,
      cabinClassPreferred: searchCriteria.options.cabinClass,
    };
    return this.searchService.create(this.getType(), data, this.translateService.currentLang, oldItemId);
  }

  private travelType(searchCriteria: SearchCriteria<FlightLocality, FlightSearchOptions>): FlightTypes.TravelType {
    return searchCriteria.travels.length > 1 ? "multi" : searchCriteria.mainTravel.when.inward ? "round" : "simple";
  }

  private travelToTrip(travel: Travel<FlightLocality>, options: FlightSearchOptions): FlightTypes.Trip {
    const result = {
      departureDate: travel.when.outward.date,
      departureAirport: travel.origin?.toAirport(),
      departureHourWindow: { begin: "", end: "" },
      arrivalAirport: travel.destination.toAirport(),
      arrivalHourWindow: { begin: "", end: "" },
      baggages: options.luggages === -1 ? null : options.luggages,
      cabinClassPreferred: options.cabinClass,
    };
    /*
      Règles observées en PROD (sur l'ancien moteur Vol) :

      Si "Départ à"      : sélection de l'option: {"label":"Journée","value":{"begin":"00:00","end":"23:59"           }}  à envoyer => departureHourWindow:{"begin":"00:00","end":"23:59","value":0 },arrivalHourWindow:{"begin":"00:00","end":"23:59"}
      Si "Départ à"      : sélection de l'option: {"label":"0h00"   ,"value":{"begin":"00:00","end":"01:00","value":0 }}  à envoyer => departureHourWindow:{"begin":"00:00","end":"23:59","value":0 },arrivalHourWindow:{"begin":"00:00","end":"23:59"}
      Si "Départ à"      : sélection de l'option: {"label":"1h00"   ,"value":{"begin":"00:00","end":"02:00","value":1 }}  à envoyer => departureHourWindow:{"begin":"00:00","end":"23:59","value":1 },arrivalHourWindow:{"begin":"00:00","end":"23:59"}
      Si "Départ à"      : sélection de l'option: {"label":"2h00"   ,"value":{"begin":"01:00","end":"03:00","value":2 }}  à envoyer => departureHourWindow:{"begin":"01:00","end":"23:59","value":2 },arrivalHourWindow:{"begin":"00:00","end":"23:59"}
      Si "Départ à"      : sélection de l'option: {"label":"12h00"  ,"value":{"begin":"11:00","end":"13:00","value":12}}  à envoyer => departureHourWindow:{"begin":"11:00","end":"23:59","value":12},arrivalHourWindow:{"begin":"00:00","end":"23:59"}
      Si "Départ à"      : sélection de l'option: {"label":"22h00"  ,"value":{"begin":"21:00","end":"23:00","value":22}}  à envoyer => departureHourWindow:{"begin":"21:00","end":"23:59","value":22},arrivalHourWindow:{"begin":"00:00","end":"23:59"}
      Si "Départ à"      : sélection de l'option: {"label":"23h00"  ,"value":{"begin":"22:00","end":"23:59","value":23}}  à envoyer => departureHourWindow:{"begin":"22:00","end":"23:59","value":23},arrivalHourWindow:{"begin":"00:00","end":"23:59"}
      Si "Départ à"      : sélection de l'option: {"label":"23h59"  ,"value":{"begin":"23:00","end":"23:59","value":24}}  à envoyer => departureHourWindow:{"begin":"23:00","end":"23:59","value":24},arrivalHourWindow:{"begin":"00:00","end":"23:59"}

      Si "Arrivée avant" : sélection de l'option: {"label":"Journée","value":{"begin":"00:00","end":"23:59"           }}  à envoyer => departureHourWindow:{"begin":"00:00","end":"23:59"},arrivalHourWindow:{"begin":"00:00","end":"23:59"           }
      Si "Arrivée avant" : sélection de l'option: {"label":"0h00"   ,"value":{"begin":"00:00","end":"01:00","value":0 }}  à envoyer => departureHourWindow:{"begin":"00:00","end":"23:59"},arrivalHourWindow:{"begin":"00:00","end":"01:00","value":0 }
      Si "Arrivée avant" : sélection de l'option: {"label":"1h00"   ,"value":{"begin":"00:00","end":"02:00","value":1 }}  à envoyer => departureHourWindow:{"begin":"00:00","end":"23:59"},arrivalHourWindow:{"begin":"00:00","end":"02:00","value":1 }
      Si "Arrivée avant" : sélection de l'option: {"label":"2h00"   ,"value":{"begin":"01:00","end":"03:00","value":2 }}  à envoyer => departureHourWindow:{"begin":"00:00","end":"23:59"},arrivalHourWindow:{"begin":"00:00","end":"03:00","value":2 }
      Si "Arrivée avant" : sélection de l'option: {"label":"12h00"  ,"value":{"begin":"11:00","end":"13:00","value":12}}  à envoyer => departureHourWindow:{"begin":"00:00","end":"23:59"},arrivalHourWindow:{"begin":"00:00","end":"13:00","value":12}
      Si "Arrivée avant" : sélection de l'option: {"label":"22h00"  ,"value":{"begin":"21:00","end":"23:00","value":22}}  à envoyer => departureHourWindow:{"begin":"00:00","end":"23:59"},arrivalHourWindow:{"begin":"00:00","end":"23:00","value":22}
      Si "Arrivée avant" : sélection de l'option: {"label":"23h00"  ,"value":{"begin":"22:00","end":"23:59","value":23}}  à envoyer => departureHourWindow:{"begin":"00:00","end":"23:59"},arrivalHourWindow:{"begin":"00:00","end":"23:59","value":23}
      Si "Arrivée avant" : sélection de l'option: {"label":"23h59"  ,"value":{"begin":"23:00","end":"23:59","value":24}}  à envoyer => departureHourWindow:{"begin":"00:00","end":"23:59"},arrivalHourWindow:{"begin":"00:00","end":"23:59","value":24}
    */
    const time = travel.when.outward.time;
    if (travel.when.outward.type === "DepartsAfter") {
      if (time.begin === "00:00" && time.end === "23:59" && time.value === undefined) {
        // Sélection de "Journée"
        result.departureHourWindow = Object.assign({}, time, { value: 0 });
      } else {
        result.departureHourWindow = time;
      }
      result.arrivalHourWindow = { begin: "00:00", end: "23:59" };
    } else {
      result.departureHourWindow = { begin: "00:00", end: "23:59" };
      result.arrivalHourWindow = time;
    }
    return result;
  }

  private createMemberSocietyArrayFromIDs(userIDs: string[]): MemberSociety[] {
    return userIDs
      .map((userId) => this.society.members.find((member) => member.user._id === userId))
      .filter((item) => !!item);
  }

  private createDateTimeSearchFromPreviousSearch(trip?: FlightTypes.Trip): DateTimeSearch {
    if (!trip) {
      return undefined;
    }
    const type =
      trip.departureHourWindow.value !== undefined && trip.arrivalHourWindow.value === undefined
        ? "DepartsAfter"
        : "ArrivalBefore";
    let hourWindow;
    if (type === "DepartsAfter") {
      hourWindow = Object.assign({}, trip.departureHourWindow);
      if (hourWindow.value === 0) {
        // On ne peut pas distinguer la sélection de "Journée" à "0h00". Dans ce cas, on choisi "Journée"
        delete hourWindow.value;
      }
    } else {
      hourWindow = Object.assign({}, trip.arrivalHourWindow);
    }
    return Object.assign(new DateTimeSearch(), {
      date: trip.departureDate,
      type: type,
      time: Object.assign(new TimeRange(), hourWindow),
    });
  }

  private createTravelFromTrip(
    people: MemberSociety[],
    outwardTrip: FlightTypes.Trip,
    inwardTrip?: FlightTypes.Trip,
  ): Travel<FlightLocality> {
    return new Travel<FlightLocality>(
      people,
      new WhenSearch(
        this.createDateTimeSearchFromPreviousSearch(outwardTrip),
        this.createDateTimeSearchFromPreviousSearch(inwardTrip),
      ),
      this.createFlightLocalityFromAirport(outwardTrip.arrivalAirport),
      this.createFlightLocalityFromAirport(outwardTrip.departureAirport),
    );
  }

  private createFlightLocalityFromAirport(airport: any): FlightLocality {
    return this.createLocalityFromJson({
      label: airport.name,
      countryISO: airport.country.length === 2 ? airport.country : undefined,
      countryName: airport.country.length !== 2 ? airport.country : undefined,
      iata: airport.iata,
      airportIATA: airport.iata,
      cityIATA: airport.iata,
      travelType: "FLIGHT",
    });
  }

  createCriteriaFromPreviousSearch(previousSearch?: any): SearchCriteria<FlightLocality, FlightSearchOptions> {
    if (!previousSearch) {
      return undefined;
    }
    const people = this.createMemberSocietyArrayFromIDs(previousSearch.userIds);
    let travels: Travel<FlightLocality>[];
    if (previousSearch.type === "multi") {
      travels = previousSearch.trips
        .map((trip) => this.createTravelFromTrip(people, trip))
        .map((travel) => {
          travel.when.inward = undefined;
          return travel;
        });
    } else if (previousSearch.type === "round") {
      travels = [this.createTravelFromTrip(people, previousSearch.trips[0], previousSearch.trips[1])];
    } else if (previousSearch.type === "simple") {
      travels = [this.createTravelFromTrip(people, previousSearch.trips[0])];
    }

    return new SearchCriteria<FlightLocality, FlightSearchOptions>(
      Object.assign(new FlightSearchOptions(), {
        luggages: previousSearch.baggages === null ? -1 : previousSearch.baggages,
        cabinClass: previousSearch.cabinClassPreferred,
      }),
      ...travels,
    );
  }

  createBlankCriteria(): SearchCriteria<FlightLocality, FlightSearchOptions> {
    return new SearchCriteria<FlightLocality, FlightSearchOptions>(
      new FlightSearchOptions(),
      new Travel<FlightLocality>(
        [],
        new WhenSearch(new DateTimeSearch(), new DateTimeSearch()),
        this.createLocalityFromJson({}),
        this.createLocalityFromJson({}),
      ),
    );
  }

  searchCriteriaIsValid(searchCriteria: SearchCriteria<FlightLocality, FlightSearchOptions>, dateMandatory?: any): boolean {
    let result =
      searchCriteria.isValid() &&
      searchCriteria.checkOriginIsDefined() &&
      searchCriteria.checkPeopleAreSameOnAllTravels();
    if (searchCriteria.travels.length > 1) {
      // Pour le cas du "multi-destinations",
      // chaque travel doit être de type "one-way" (pas de retour)
      // il ne faudrait pas qu'un travel selectionné ai une date antérieure au travel précédent
      let condition1 = !searchCriteria.travels.some((s) => !!s.when.inward);
      let condition2 = !Object.values(dateMandatory || {}).includes(true);

      result = dateMandatory && Object.keys(dateMandatory).length !== 0
        ? result && condition1 && condition2
        : result && condition1;

   
    }
    return result;
  }

  checkIfTravelsDatesAreInGoodOrder(dateTimeSearch?: DateTimeSearch[]) {
    const result = {};

    for (let i = 0; i < dateTimeSearch.length; i++) {
      const currentDate = new Date(dateTimeSearch[i].date);

      for (let j = 0; j < i; j++) {
        const previousDate = new Date(dateTimeSearch[j].date);

        if (currentDate < previousDate) {
          result[dateTimeSearch[i].date] = true;
          break;
        }
      }
    }

    return result;
  }

  createDummyLocalityFromName(name: string): FlightLocality {
    return this.createLocalityFromJson({ name: name });
  }

  createLocalityFromJson(json: any): FlightLocality {
    return Object.assign(new FlightLocality(), json);
  }

  lastSearches(limit: number): Observable<LastSearchItem<FlightLocality>[]> {
    return lastSearches(this.httpClient, this.getType(), limit).pipe(
      map((array) =>
        array.map((search) => {
          return new LastSearchItem(computeLastSearchLabel(search), computeTravelFromSearch(search));
        }),
      ),
    );
  }

  createCriteriaFromBasketItem(
    lastFolderItemsInBasket: any[],
    members: MemberSociety[],
  ): SearchCriteria<FlightLocality, FlightSearchOptions> {
    const outwardDate = lastFolderItemsInBasket?.[0].detail.trips[0].legs[0].departureDate;
    const inwardDate =
      lastFolderItemsInBasket?.[0].detail.trips[0]?.legs[1]?.departureDate ||
      lastFolderItemsInBasket?.[0].detail.trips[1]?.legs[0]?.departureDate ||
      lastFolderItemsInBasket?.[1]?.detail.trips[0]?.legs[0]?.departureDate;

    if (!outwardDate) {
      return undefined;
    }

    return new SearchCriteria<FlightLocality, FlightSearchOptions>(
      new FlightSearchOptions(),
      new Travel<FlightLocality>(
        lastFolderItemsInBasket?.[0]?.travelers
          .map((traveler) => members.find((member) => member.user?._id === traveler.userId))
          .filter((member) => !!member),
        new WhenSearch(
          !outwardDate ? undefined : new DateTimeSearch(outwardDate),
          !inwardDate ? undefined : new DateTimeSearch(inwardDate),
        ),
        this.createLocalityFromJson({}),
        this.createLocalityFromJson({}),
      ),
    );
  }
}

export class FlightLocality implements Locality {
  label: string;
  countryISO?: string;
  countryName?: string;
  country?: string;
  iata?: string;
  airportIATA?: string;
  cityIATA?: string;

  get name(): string {
    return this.label;
  }
  set name(value: string) {
    this.label = value;
  }

  isValid(): boolean {
    return (
      this.label?.length > 0 &&
      this.iata?.length > 0 &&
      (this.countryISO?.length > 0 || this.countryName?.length > 0 || this.country?.length > 0)
    );
  }

  toAirport(): FlightTypes.Airport {
    return {
      name: this.label,
      country: this.country || this.countryISO || this.countryName,
      iata: this.iata || this.airportIATA || this.cityIATA,
    };
  }
}

export class FlightSearchOptions implements SearchOptions {
  luggages: number = -1; // -1 : pas de préférence
  cabinClass: FlightTypes.CabinClass = "Y"; // Y = Economy class cabin.
  isValid(): boolean {
    return true;
  }
}

function computeLastSearchLabel(search: Search) {
  const trip = search.data.trips[0];
  return trip?.departureAirport.name + " - " + trip?.arrivalAirport.name;
}

function computeTravelFromSearch(search: Search): Travel<FlightLocality> {
  const trip = search.data.trips[0];
  const origin = toAirport(trip?.departureAirport);
  const destination = toAirport(trip?.arrivalAirport);
  return new Travel([], new WhenSearch(new DateTimeSearch(), new DateTimeSearch()), destination, origin);
}

function toAirport(data: any): FlightLocality {
  if (!data) {
    return undefined;
  }
  data.travelType = "FLIGHT";
  return Object.assign(new FlightLocality(), data);
}
