import { BehaviorSubject, Subscription } from "rxjs";
import { CommonService } from "src/app/@shared/services/common.service";
import { Component, ViewEncapsulation, OnInit, Input, EventEmitter, Output, ViewChild } from "@angular/core";
import { Tag } from "src/app/@shared/@types/tag";
import { TagService } from "../../services/tag.service";
import { OverlayPanel } from "primeng/overlaypanel";
import { MenuItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "spt-tags-manage",
  templateUrl: "./tags.component.html",
  styleUrls: ["./tags.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TagsComponent implements OnInit {
  @Input() set tagIds(value: Array<string>) {
    if (Array.isArray(value)) {
      const societyTags: Array<Tag> = this.societyTags.value;
      this.tags = value
        .map((tagId: string) => societyTags.find((societyTag: Tag) => societyTag.id === tagId))
        .filter((tag: Tag) => !!tag);
      this.emitUpdatedTags();
    }
  }
  public tags: Tag[] = [];
  public tagFilterFields: Array<string> = ["label"];
  public societyTags: BehaviorSubject<Tag[]>;
  public tagInput: string;
  public displaySocietyTags: boolean = false;
  @Input() nbMax?: number;
  public tagsAdditionnalList: MenuItem[] = [];
  private isTabletSubscription: Subscription;
  moreTagTriggerEvent: string = "click";

  @Output() updatedTags: EventEmitter<string[]> = new EventEmitter();
  @ViewChild("tagOverlay", { static: false }) overlay: OverlayPanel;

  constructor(
    private tagService: TagService,
    public commonService: CommonService,
    private translateService: TranslateService,
  ) {
    this.societyTags = this.tagService.societyTags;
  }

  ngOnInit(): void {
    if (!this.commonService.isTablet) {
      if (!this.nbMax) {
        this.nbMax = 3;
      }
    } else {
      this.nbMax = 1;
    }
    this.isTabletSubscription = this.commonService.isTabletObservable.subscribe((isTablet) => {
      if (isTablet) {
        this.moreTagTriggerEvent = "click";
      } else {
        this.moreTagTriggerEvent = "mouseover";
      }
    });
  }

  ngOnDestroy(): void {
    this.isTabletSubscription.unsubscribe();
  }
  toggleSocietyTags(): void {
    this.displaySocietyTags = !this.displaySocietyTags;
  }

  selectTag(tag: Tag): void {
    if (this.tags.find((_tag: Tag) => _tag.id === tag.id)) {
      return;
    }
    this.tags.push(tag);
    this.emitUpdatedTags();
    this.tagInput = "";
  }

  addTag(): void {
    const societyTag: Tag = this.societyTags.value.find((_societyTag: Tag) => _societyTag.label === this.tagInput);
    if (societyTag) {
      this.selectTag(societyTag);
    } else if (this.tagInput && this.tagInput.trim() !== "") {
      this.tagService.add(this.tagInput).subscribe((tag: Tag) => {
        this.selectTag(tag);
      });
    } else {
      this.overlay.hide();
    }
  }

  deleteTag(tag: Tag): void {
    const index: number = this.tags.findIndex((_tag: Tag) => _tag.id === tag.id);
    this.tags.splice(index, 1);
    this.emitUpdatedTags();
  }

  private emitUpdatedTags(): void {
    this.updateAdditionnalTagsList();
    const tagIds: Array<string> = this.tags.map((_tag: Tag) => _tag.id);
    this.updatedTags.emit(tagIds);
  }

  private updateAdditionnalTagsList() {
    if (this.commonService.isTablet) {
      this.tagsAdditionnalList = [
        {
          label: " ",
          icon: "pi pi-times",
          styleClass: "close-context-menu",
        },
      ];

      this.tagsAdditionnalList.push(
        ...this.tags.map((tag) => ({
          id: tag._id,
          label: "# " + tag.label,
          icon: "./assets/svg/cross.svg",
          title: this.translateService.instant("MEMBERS.DELETE"),
          command: () => {
            this.deleteTag(tag);
          },
        })),
      );
    } else {
      this.tagsAdditionnalList = this.tags
        .filter((_, idx) => idx >= this.nbMax)
        .map((tag) => ({
          id: tag._id,
          label: "# " + tag.label,
          icon: "./assets/svg/cross.svg",
          command: () => {
            this.deleteTag(tag);
          },
        }));
    }
  }
}
