<div class="description">
  <div class="title-model">
    <div class="title">
      {{ carName }}
    </div>
    <span class="model">{{ carModel }}</span>
  </div>
  <div *ngIf="showLeaflet">
    <div class="leaflet-content">
      <span class="leaflet-title" translate>SEARCH.RESULT.CAR.WITHDRAWAL</span>

      <div class="leaflet-map" #leafletMap></div>
      <a [href]="pickUpCoordinates" target="_system">
        <i>{{
          (commonService.isTabletObservable | async) === true
            ? ("RESERVATIONS.HOTEL.OPEN_MAPS_APP" | translate)
            : ("RESERVATIONS.HOTEL.OPEN_MAPS_DESKTOP" | translate)
        }}</i>
      </a>
    </div>
  </div>
  <div class="title">
    <div class="icon">
      <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7 0C3.1 0 0 3.1 0 7C0 12.2 7 20 7 20C7 20 14 12.2 14 7C14 3.1 10.9 0 7 0ZM7 9.5C5.6 9.5 4.5 8.4 4.5 7C4.5 5.6 5.6 4.5 7 4.5C8.4 4.5 9.5 5.6 9.5 7C9.5 8.4 8.4 9.5 7 9.5Z"
          fill="#312F65"
        />
      </svg>
    </div>
    <div>
      <span *ngIf="isSameReturnAgency" class="underline location-title" translate>SEARCH.CHECKIN</span>
      <p class="address">
        {{ pickupAddressLine1 | titlecase }}
      </p>
    </div>
  </div>
  <div *ngIf="pickupAgencyRef !== returnAgencyRef && showLeaflet">
    <div class="separator"></div>
    <div class="leaflet-content">
      <span class="leaflet-title" translate>SEARCH.RETURN</span>
      <div class="leaflet-map" #leafletMapReturn></div>
      <a [href]="dropOffCoordinates" target="_system">
        <i>{{
          (commonService.isTabletObservable | async) === true
            ? ("RESERVATIONS.HOTEL.OPEN_MAPS_APP" | translate)
            : ("RESERVATIONS.HOTEL.OPEN_MAPS_DESKTOP" | translate)
        }}</i>
      </a>
    </div>
  </div>
  <div class="title title-return" *ngIf="pickupAgencyRef !== returnAgencyRef">
    <div class="icon">
      <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7 0C3.1 0 0 3.1 0 7C0 12.2 7 20 7 20C7 20 14 12.2 14 7C14 3.1 10.9 0 7 0ZM7 9.5C5.6 9.5 4.5 8.4 4.5 7C4.5 5.6 5.6 4.5 7 4.5C8.4 4.5 9.5 5.6 9.5 7C9.5 8.4 8.4 9.5 7 9.5Z"
          fill="#312F65"
        />
      </svg>
    </div>
    <div>
      <span class="underline location-title" translate>SEARCH.CHECKOUT</span>
      <p class="address">
        {{ returnAddressLine1 | titlecase }}{{ returnAddressLine2 | lowercase }},
        <span class="city">
          {{ returnCityName }}
        </span>
      </p>
    </div>
  </div>

  <div class="dates">
    <div class="pickup">
      <div class="title"><span translate>SEARCH.CAR.SIDEBAR.TAKEN_LABEL</span></div>
      <div class="date">{{ pickupDate | date: "d MMM" }}</div>
      <div class="hour"><span translate>GLOBAL.AT</span> {{ pickupTime }}:00</div>
    </div>

    <div class="return">
      <div class="title"><span translate>SEARCH.CAR.SIDEBAR.RETURN_LABEL</span></div>
      <div class="date">{{ returnDate | date: "d MMM" }}</div>
      <div class="hour"><span translate>GLOBAL.AT</span> {{ returnTime }}:00</div>
    </div>
  </div>

  <div class="details" *ngIf="item">
    <div class="_item">
      <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.2727 13.6285V4.37154C16.1625 4.06273 16.8 3.22988 16.8 2.25C16.8 1.00705 15.7746 0 14.5091 0C13.2435 0 12.2182 1.00705 12.2182 2.25C12.2182 3.22988 12.8556 4.06273 13.7455 4.37154V8.25001H9.16364V4.37154C10.0534 4.06273 10.6909 3.22988 10.6909 2.25C10.6909 1.00705 9.66553 0 8.39998 0C7.13443 0 6.10907 1.00705 6.10907 2.25C6.10907 3.22988 6.74652 4.06273 7.63636 4.37154V8.25001H3.05454V4.37154C3.94434 4.06273 4.58182 3.22988 4.58182 2.25C4.58182 1.00705 3.55646 0 2.29091 0C1.02536 0 0 1.00705 0 2.25C0 3.22988 0.637446 4.06273 1.52729 4.37154V9C1.52729 9.41421 1.86917 9.74999 2.29091 9.74999H7.63636V13.6285C6.74652 13.9373 6.10907 14.7701 6.10907 15.75C6.10907 16.9929 7.13443 18 8.39998 18C9.66553 18 10.6909 16.9929 10.6909 15.75C10.6909 14.7701 10.0534 13.9373 9.16361 13.6285V9.74999H13.7454V13.6285C12.8556 13.9373 12.2181 14.7701 12.2181 15.75C12.2181 16.9929 13.2435 18 14.5091 18C15.7746 18 16.8 16.9929 16.8 15.75C16.8 14.7701 16.1625 13.9373 15.2727 13.6285ZM14.5091 1.50001C14.9312 1.50001 15.2727 1.83547 15.2727 2.25C15.2727 2.66453 14.9312 2.99999 14.5091 2.99999C14.087 2.99999 13.7454 2.66453 13.7454 2.25C13.7454 1.83547 14.087 1.50001 14.5091 1.50001ZM8.39998 1.50001C8.82205 1.50001 9.16361 1.83547 9.16361 2.25C9.16361 2.66453 8.82205 2.99999 8.39998 2.99999C7.97792 2.99999 7.63636 2.66453 7.63636 2.25C7.63636 1.83547 7.97792 1.50001 8.39998 1.50001ZM2.29087 1.50001C2.71294 1.50001 3.0545 1.83547 3.0545 2.25C3.0545 2.66453 2.71294 2.99999 2.29087 2.99999C1.86881 2.99999 1.52725 2.66453 1.52725 2.25C1.52725 1.83547 1.86881 1.50001 2.29087 1.50001ZM8.39998 16.5C7.97792 16.5 7.63636 16.1645 7.63636 15.75C7.63636 15.3355 7.97792 15 8.39998 15C8.82205 15 9.16361 15.3355 9.16361 15.75C9.16361 16.1645 8.82205 16.5 8.39998 16.5ZM14.5091 16.5C14.087 16.5 13.7455 16.1645 13.7455 15.75C13.7455 15.3355 14.087 15 14.5091 15C14.9312 15 15.2727 15.3355 15.2727 15.75C15.2727 16.1645 14.9312 16.5 14.5091 16.5Z"
          fill="#312F65"
        />
      </svg>

      <p>
        {{ gearboxType }}
      </p>
    </div>

    <div class="_item">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.1429 0H6.00006C5.79954 0 5.60539 0.0671165 5.45133 0.189644L0.308495 4.28055C0.113085 4.436 8.3705e-05 4.66627 8.3705e-05 4.90909V8.99836L0 9V17.1818C0 17.6337 0.383746 18 0.857139 18H17.1428C17.6162 18 17.9999 17.6337 17.9999 17.1818V9.00164L18 9V0.818182C18 0.366304 17.6163 0 17.1429 0ZM16.2857 8.18182H5.14292V6.92606L7.65177 4.90909H16.2857V8.18182ZM1.71436 5.29229L6.3104 1.63636H16.2857V3.27273H7.33892C7.13686 3.27273 6.94132 3.34088 6.78676 3.46509L3.73362 5.91963C3.54026 6.07508 3.42864 6.30412 3.42864 6.54541V8.18178H1.71436V5.29229ZM16.2856 16.3636C7.46413 16.3636 10.9289 16.3636 1.71428 16.3636V9.81818H16.2856V16.3636Z"
          fill="#312F65"
        />
      </svg>

      <p>{{ doorCount }} {{ "SEARCH.CAR.DOORS" | translate }}</p>
    </div>

    <div class="_item">
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.54662 10.2667L1.46667 9.03239L2.57096 8.06631L4.49605 10.2667H8.17082C8.24006 10.0004 8.3456 9.75069 8.48225 9.51928L5.88188 6.91904L2.96484 7.11347L2.86745 5.65038L4.50398 5.54122L2.70059 3.73792L3.73756 2.70094L5.54118 4.50446L5.65039 2.86744L7.11419 2.96484L6.91918 5.88237L9.51923 8.48229C9.75063 8.34564 10.0003 8.24001 10.2667 8.17077V4.49525L8.06667 2.57025L9.03203 1.46667L10.2667 2.54675V0H11.7333V2.54675L12.968 1.46667L13.9333 2.57025L11.7333 4.49525V8.17077C11.9997 8.24001 12.2494 8.34564 12.4808 8.48229L15.0808 5.88237L14.8858 2.96485L16.3496 2.86745L16.4588 4.50447L18.2624 2.70095L19.2994 3.73792L17.496 5.54123L19.1325 5.65039L19.0352 7.11348L16.1181 6.91904L13.5177 9.51928C13.6544 9.75069 13.7599 10.0004 13.8292 10.2667H17.5039L19.429 8.06631L20.5333 9.03239L19.4534 10.2667H22V11.7333H19.4534L20.5333 12.9676L19.429 13.9337L17.5039 11.7333H13.8292C13.7599 11.9996 13.6544 12.2493 13.5177 12.4807L16.1181 15.081L19.0352 14.8865L19.1325 16.3496L17.496 16.4588L19.2994 18.2621L18.2624 19.2991L16.4588 17.4955L16.3496 19.1326L14.8858 19.0352L15.0808 16.1176L12.4808 13.5177C12.2494 13.6544 11.9997 13.76 11.7333 13.8292V17.5047L13.9333 19.4297L12.968 20.5333L11.7333 19.4533V22H10.2667V19.4533L9.03203 20.5333L8.06667 19.4298L10.2667 17.5048V13.8292C10.0003 13.76 9.75064 13.6544 9.51923 13.5177L6.91918 16.1176L7.11419 19.0352L5.65039 19.1326L5.54118 17.4955L3.73756 19.2991L2.70059 18.2621L4.50398 16.4588L2.86745 16.3496L2.96484 14.8865L5.88188 15.081L8.48225 12.4807C8.3456 12.2493 8.24006 11.9996 8.17082 11.7333H4.49605L2.57096 13.9337L1.46667 12.9676L2.54662 11.7333H0V10.2667H2.54662ZM11 12.4667C11.8085 12.4667 12.4667 11.8089 12.4667 11C12.4667 10.1911 11.8085 9.53333 11 9.53333C10.1915 9.53333 9.53333 10.1911 9.53333 11C9.53333 11.8089 10.1915 12.4667 11 12.4667Z"
          fill="#312F65"
        />
      </svg>

      <p translate>SEARCH.HOTEL.FACILITIES.AIR_CONDITIONER</p>
    </div>

    <div class="_item">
      <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.04 6.07153C6.60309 5.75411 8.18867 6.51376 8.81245 7.87885L10.1432 10.792L14.3593 10.1324C14.7455 10.072 15.123 10.2765 15.2556 10.6179L16.8332 14.6784C17.3139 15.872 16.7237 17.2167 15.4715 17.764C14.1207 18.3536 12.5111 17.7994 11.9041 16.5356L11.312 15.3029L5.79741 16.3139C5.40622 16.3856 5.01697 16.1827 4.88007 15.8357L2.63872 10.1545C1.93052 8.40538 3.06372 6.47407 5.04 6.07153ZM4.15685 9.63358L6.16276 14.7179L11.6318 13.7152C12.0026 13.6472 12.3747 13.8261 12.5284 14.146L13.3832 15.9258C13.6211 16.4211 14.2519 16.6383 14.7812 16.4072C15.2717 16.1928 15.503 15.6658 15.3132 15.1946L13.9616 11.7159L9.78185 12.3698C9.41206 12.4276 9.04785 12.2425 8.90165 11.9225L7.32161 8.4636C7.00175 7.76361 6.18834 7.37391 5.38702 7.53665C4.37373 7.74305 3.79248 8.73367 4.15685 9.63358Z"
          fill="#312F65"
        />
        <path
          d="M3.23776 0C5.02604 0 6.47552 1.34302 6.47552 2.99994C6.47552 4.65687 5.02604 5.99989 3.23776 5.99989C1.44949 5.99989 0 4.65687 0 2.99994C0 1.34302 1.44945 0 3.23776 0ZM3.23776 4.49993C4.13196 4.49993 4.85666 3.82849 4.85666 2.99994C4.85666 2.17139 4.13199 1.49995 3.23776 1.49995C2.34353 1.49995 1.61886 2.17139 1.61886 2.99994C1.61886 3.82849 2.34353 4.49993 3.23776 4.49993Z"
          fill="#312F65"
        />
      </svg>

      <p>
        {{ passengerPlaceCount }}
      </p>
    </div>
  </div>
</div>
