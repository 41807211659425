<!-- TODO recuperer le type de trajet pour l'attribut return du breadcrumb-->
<spt-breadcrumb active="validation" [return]="search.data.type !== 'simple'"></spt-breadcrumb>

<div class="header-validate">
  <img class="close" src="./assets/svg/return-arrow.svg" alt="close" (click)="closeValidation()" />
  <h1 translate>SEARCH.AIR.VALIDATE.TITLE</h1>

  <div class="search-resume" *ngIf="this.itineraries?.length < 3">
    <img src="./assets/svg/flight.svg" alt="" />
    <div class="places">
      <ng-container *ngFor="let place of searchResumePlaces; let isLast = last">
        <span class="place">{{ place }}</span>
        <img src="./assets/svg/details/large-arrow.svg" *ngIf="isLast === false" />
      </ng-container>
    </div>
    <div class="dates">
      {{ searchDates }}
    </div>
  </div>
  <div class="proposal" *ngIf="this.newOffers?.length > 0">
    <span translate>SEARCH.AIR.VALIDATE.ALTERNATIVE</span>
    <p-button
      type="submit"
      styleClass="p-button-third"
      [label]="'SEARCH.AIR.VALIDATE.DISCOVER' | translate"
      (mousedown)="displayOption()"
    ></p-button>
  </div>
</div>

<div class="itineraries primary">
  <p-tabView
    (onChange)="handleChange($event, undefined)"
    [activeIndex]="activeTab"
    [styleClass]="activeTab === 0 ? 'first' : null"
  >
    <p-tabPanel
      [header]="'SEARCH.AIR.VALIDATE.OFFER' | translate: { number: i + 1 }"
      *ngFor="let itinerary of itineraries; let i = index"
    >
      <div class="itinerary">
        <spt-detail-r [users]="users" [itinerary]="itinerary" [isValidateOptionInformation]="true"> </spt-detail-r>

        <div class="warning" *ngIf="airportsDifferent" translate>SEARCH.AIR.AIRPORTS_WARNING</div>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>

<div class="select">
  <div class="price">
    <span class="total">{{ "SEARCH.RESULT.TOTAL" | translate }}: </span>
    <span class="amount">{{
      {
        amount: this.price,
        currency: "EUR"
      }
        | SPTPrice
        | async
    }}</span>
    <div class="out-policy" translate *ngIf="isOutOfPolicy">GLOBAL.OUT_OF_POLICY</div>
  </div>
  <div class="conditions" *ngIf="!viewOnly">
    <div class="title" translate>SEARCH.RESULT.CONDITIONS.BOOKING</div>
    <p translate>SEARCH.RESULT.CONDITIONS.VERIFICATION</p>
    <p [innerHTML]="'SEARCH.RESULT.CONDITIONS.CARBON_OFFSET' | translate"></p>
  </div>
  <span class="warning" translate>SEARCH.AIR.VALIDATE.CHECK_REMINDER</span>
  <p-button
    (click)="this.chooseOffer(this.itineraries)"
    [label]="'SEARCH.AIR.VALIDATE.SELECT_TRAVEL' | translate"
  ></p-button>
</div>

<div *ngIf="this.newOffers?.length > 0" class="option" #option>
  <div class="resume">
    <h2 translate>SEARCH.AIR.VALIDATE.ALTERNATIVE_LESS_EXPENSIVE</h2>
    <div class="price">
      {{ { amount: this.newPrice, currency: "EUR" } | SPTPrice | async }}
    </div>
  </div>
  <div class="itineraries alternative">
    <p-tabView
      (onChange)="handleChange($event, 'new')"
      [activeIndex]="activeTabNew"
      [styleClass]="activeTabNew === 0 ? 'first' : null"
    >
      <p-tabPanel
        [header]="'SEARCH.AIR.VALIDATE.OFFER' | translate: { number: i + 1 }"
        *ngFor="let offer of this.newOffers; let i = index"
      >
        <div class="itinerary">
          <spt-detail-r [users]="users" [itinerary]="offer"> </spt-detail-r>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>

  <div class="conditions">
    <div class="title" translate>SEARCH.RESULT.CONDITIONS.BOOKING</div>
    <p translate>SEARCH.RESULT.CONDITIONS.VERIFICATION</p>
    <p [innerHTML]="'SEARCH.RESULT.CONDITIONS.CARBON_OFFSET' | translate"></p>
  </div>

  <p-button
    (click)="this.chooseOffer(this.newOffers)"
    [label]="'SEARCH.AIR.VALIDATE.SELECT_OPTION' | translate"
  ></p-button>
</div>
