import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, of, tap } from "rxjs";
import { environment } from "src/environments/environment";
import moment from "moment";

@Injectable({
  providedIn: "root",
})
export class ExchangeRateService {
  constructor(private httpClient: HttpClient) {}

  rates: BehaviorSubject<any> = new BehaviorSubject({});

  get(currency: string, date: string | Date) {
    if (typeof date !== "string") {
      date = moment(date).format("YYYY-MM-DD");
    }
    if (this.rates.value?.[currency]?.[date]) {
      return of(this.rates.value[currency][date]);
    } else {
      const params: HttpParams = new HttpParams({
        fromObject: {
          currency,
        },
      });
      return this.httpClient
        .get(`${environment.api}/exchange-rates/${date}`, {
          params,
        })
        .pipe(
          tap((exchangeRate: any) => {
            const rates = this.rates.value;
            if (!rates[exchangeRate.base]) {
              rates[exchangeRate.base] = {};
            }
            if (!rates[exchangeRate.base][exchangeRate.date]) {
              rates[exchangeRate.base][exchangeRate.date] = exchangeRate;
            }
            this.rates.next(rates);
          }),
        );
    }
  }
}
