import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  AfterViewInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import {
  Locality,
  SearchFavorite,
  SearchEngineService,
  Travel,
  SearchEngineType,
  LastSearchItem,
  checkLocalityIsValid,
} from "../../search-engine-service";
import { ModalService } from "../../../../@shared/services/modal.service";
import { CommonService } from "../../../../@shared/services/common.service";
import {
  LocalityAutocompleteComponent,
  LocalityChangeEvent,
  LocalityRestrictions,
} from "./locality-autocomplete/locality-autocomplete.component";
import { Address } from "../../../../@shared/@types/models";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { TransferTypes } from "../../../../travel/transfer/transfer";
import { SuggestPanelService } from "../suggest-panel.service";
import { Subscription } from "rxjs";

@Component({
  selector: "spt-travel-search-autocomplete",
  templateUrl: "./travel-search-autocomplete.component.html",
  styleUrls: ["./travel-search-autocomplete.component.scss"],
})
export class TravelSearchAutocompleteComponent<LOCALITY_TYPE extends Locality | Address | TransferTypes.Place>
  implements OnInit, AfterViewInit, OnDestroy
{
  // Nécessaire pour pouvoir définir un 'name' aux éléments de formulaire unique
  id = "TravelSearchAutocompleteComponent-" + Math.random().toString(36).substring(2);

  @ViewChild("template", { static: true }) template!: TemplateRef<any>;
  @ViewChild("localityAutoCompleteDestination", { static: false })
  localityAutoCompleteDestination: LocalityAutocompleteComponent<LOCALITY_TYPE>;

  @Input() restrictions?: Config<LocalityRestrictions>;

  @Input() icons: Config<string> = {};
  @Input() suggestions: Config<any[]> = {};
  @Input() from: Config<string> = {};
  @Input() radius: Config<number> = {};
  @Input() address: Config<Address> = {};

  @Input() suggestPanelTitle?: string;
  @Input() label?: Config<string>;
  @Input() placeholder?: Config<string>;
  @Input() optionDestinationSameAsOriginLabel?: string;
  @Input() destinationSameAsOrigin?: boolean;
  @Input() favoritesSuggestPanelName: string = "favorites";
  @Input() originGridArea: string = "origin";
  @Input() originSuggestPanelName: string = "origin";
  @Input() destinationGridArea: string = "destination";
  @Input() destinationSuggestPanelName: string = "destination";
  @Input() travel!: Travel<LOCALITY_TYPE>;
  @Input() searchService!: SearchEngineService<LOCALITY_TYPE, any>;
  @Input() className: string = "";
  @Input() showSuggestInPanel: boolean = true;
  @Input() disableFavorites: boolean = false;
  @Input() reverse: boolean = false;
  displayFavorites!: boolean;

  @Input() openInModal?: boolean;
  @Input() nextFocus?: any;

  openedInModal = false;
  private observablesToClearOnDestroy: Subscription[] = [];

  /**
   * Permet de n'afficher que l'origine, ou la destination.
   * Cas d'usage pour l'ouverture du composant dans une modale (sur mobile)
   */
  displayOnly?: "origin" | "destination";

  searchEngineType!: SearchEngineType;

  @Output() onCloseSuggest: EventEmitter<void> = new EventEmitter<void>();
  @Output() onTravelChange: EventEmitter<TravelChangeEvent<LOCALITY_TYPE>> = new EventEmitter<
    TravelChangeEvent<LOCALITY_TYPE>
  >();
  @Output() onDestinationSameAsOriginChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private isActive: boolean = false;
  private isTabletSubscription: Subscription;

  constructor(
    private viewContainerRef: ViewContainerRef,
    public commonService: CommonService,
    private modalService: ModalService,
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    protected suggestPanelService: SuggestPanelService,
  ) {}

  ngAfterViewInit(): void {
    if (!this.localityAutoCompleteDestination && this.config?.data.localityAutoCompleteDestination) {
      this.localityAutoCompleteDestination = this.config.data.localityAutoCompleteDestination;
    }
  }

  ngOnInit(): void {
    if (!this.travel && this.config?.data.displayOnly) {
      // Ouverture du composant dans une modal dédiée à ce composant (déclenchée par "lui-même", cf. openSearchMobileDialog())
      PROPERTIES_TO_COPY_FOR_MODAL.forEach((p) => {
        this[p] = this.config?.data[p];
      });
      this.openedInModal = true;
    } else {
      if (this.openInModal === undefined) {
        // openInModal non définit/forcé -> détermination automatique
        this.isTabletSubscription = this.commonService.isTabletObservable.subscribe((isTablet) => {
          this.openInModal = isTablet;
        });
      }
      this.changeDestinationSameAsOrigin();
    }
    this.observablesToClearOnDestroy.push(
      this.suggestPanelService.onChange.subscribe(
        (panelName) =>
          (this.isActive = (this.originSuggestPanelName || this.destinationSuggestPanelName) === panelName),
      ),
    );

    this.displayFavorites = !this.disableFavorites;
    this.searchEngineType = this.searchService.getType();

    if (!this.openedInModal) {
      // Permet de ne pas avoir le tag <spt-travel-search-autocomplete/> présent dans le DOM
      // (cf.https://stackoverflow.com/questions/38716105/angular2-render-a-component-without-its-wrapping-tag#answer-56887630)
      // Indispensable pour pouvoir utiliser les 'grid-template-areas'
      this.viewContainerRef.createEmbeddedView(this.template);
    }

    this.showSuggestInPanel &&
      this.suggestPanelService.onChange.subscribe((panelName) => {
        if (this.openedInModal) {
          // Si ouvert dans une modale (sur mobile), les favoris doivent rester affichés
          this.displayFavorites = !this.disableFavorites;
        } else {
          this.displayFavorites = panelName.startsWith("favorites");
        }
      });
  }

  ngOnDestroy(): void {
    this.observablesToClearOnDestroy.forEach((o) => o.unsubscribe());
    this.observablesToClearOnDestroy.length = 0;
    this.isTabletSubscription?.unsubscribe();
  }

  onFocusLocality(kind: "origin" | "destination") {
    if (this.openInModal) {
      this.openSearchMobileDialog(kind);
    } else {
      this.showSuggestInPanel && this.suggestPanelService.activate(kind);
      return;
    }
  }

  openSearchMobileDialog(kind: "origin" | "destination"): void {
    const data = {};
    PROPERTIES_TO_COPY_FOR_MODAL.forEach((p) => {
      data[p] = this[p];
    });
    data["displayOnly"] = kind;
    this.dialogRef = this.modalService.openModal(TravelSearchAutocompleteComponent, {
      data: data,
      height: this.commonService.isCordova ? `100vh` : "auto",
      style: {
        "z-index": 100000,
        "background-color": "#F3F4FE",
      },
      styleClass: "dialog-suggest",
      width: this.commonService.isTablet ? "100vw" : "730px",
      showHeader: false,
      dismissableMask: true,
    });
  }

  private createLocalityFromOad(oad: any): LOCALITY_TYPE {
    return oad ? this.searchService.createLocalityFromJson(oad) : undefined;
  }

  applyFavorite(favorite: SearchFavorite<LOCALITY_TYPE>): void {
    if (favorite["oad"]) {
      const destination = this.createLocalityFromOad(favorite["oad"]?.arrival);
      this.travel.origin = this.createLocalityFromOad(favorite["oad"]?.departure);
      if (destination && !this.isLocalityEquals(destination, this.travel.origin)) {
        this.travel.destination = destination;
        this.destinationSameAsOrigin = false;
      } else {
        this.travel.destination = this.travel.origin;
        this.destinationSameAsOrigin = true;
      }
    } else {
      // Pour l'hotel, il n'y a pas forcément d'oad de définit
      this.travel.destination = this.searchService.createLocalityFromJson(favorite);
    }
    this.onTravelChange.emit({ travel: this.travel });
    this.closeModal();
    if (this.openedInModal) {
      this.focusOnNextElement();
    }
  }

  applyLastSearch(lastSearch: LastSearchItem<LOCALITY_TYPE>): void {
    this.travel.destination = lastSearch.travel.destination as LOCALITY_TYPE;
    this.travel.origin = lastSearch.travel.origin as LOCALITY_TYPE;
    this.closeModal();
    if (this.openedInModal) {
      this.focusOnNextElement();
    }
  }

  setOrigin(event: LocalityChangeEvent<LOCALITY_TYPE>): void {
    this.travel.origin = event.locality;
    if (this.destinationSameAsOrigin === true) {
      this.travel.destination = this.travel.origin;
    }
    this.onTravelChange.emit({ travel: this.travel, from: event, fromKind: "origin" });
    if (event.from !== "ngOnChanges" && event.from !== "ngOnInit" && checkLocalityIsValid(event.locality)) {
      this.closeModal();

      if (this.openedInModal) {
        this.focusOnDestination();
      }
    }
  }

  setDestination(event: LocalityChangeEvent<LOCALITY_TYPE>): void {
    this.travel.destination = event.locality;
    this.onTravelChange.emit({ travel: this.travel, from: event, fromKind: "destination" });
    if (event.from !== "ngOnChanges" && event.from !== "ngOnInit" && checkLocalityIsValid(event.locality)) {
      this.closeModal();
      if (this.openedInModal) {
        this.focusOnNextElement();
      }
    }
  }

  focusOnDestination() {
    if (this.localityAutoCompleteDestination && !this.localityAutoCompleteDestination.disabled) {
      this.localityAutoCompleteDestination.focusOnInput();
    } else {
      this.focusOnNextElement();
    }
  }

  focusOnNextElement() {
    if (typeof this.nextFocus?.focus === "function") {
      this.nextFocus.focus();
    }
  }

  closeSuggestPanel(): void {
    this.onCloseSuggest.emit();
    this.displayFavorites = !this.disableFavorites;
  }

  activateSuggest(propertyName: string) {
    this.showSuggestInPanel && this.suggestPanelService.activate(propertyName);
  }

  reverseData() {
    let destination = this.travel.destination;
    let origin = this.travel.origin;

    this.travel.origin = destination;
    this.travel.destination = origin;
  }

  changeDestinationSameAsOrigin(): void {
    if (this.travel.origin === undefined) {
      // Nous ici dans le cas où l'origin n'est pas nécessaire, et donc pas proposée à saisir (cas de l'hotel)
      return;
    }
    if (this.destinationSameAsOrigin === true) {
      this.travel.destination = this.travel.origin;
    } else if (!this.travel.destination || this.isLocalityEquals(this.travel.destination, this.travel.origin)) {
      this.travel.destination = this.searchService.createDummyLocalityFromName("");
    }
    this.onDestinationSameAsOriginChange.emit(!!this.destinationSameAsOrigin);
    this.onTravelChange.emit({ travel: this.travel, fromKind: "changeDestinationSameAsOrigin" });
  }

  closeModal(): void {
    if (this.displayOnly) {
      this.dialogRef.close();
    }
  }

  isLocalityEquals(l1: LOCALITY_TYPE, l2: LOCALITY_TYPE): boolean {
    if ((l1 && !l2) || (!l1 && l2)) {
      return false;
    }
    if (l1["place_id"] && l1["place_id"] === l2["place_id"]) {
      return true;
    }
    return l1 === l2;
  }
}

export interface Config<T> {
  origin?: T;
  destination?: T;
}

export type Kind = "origin" | "destination" | "changeDestinationSameAsOrigin";

export function other(kind: Kind): Kind {
  return kind === "origin" ? "destination" : "origin";
}

export function switchValue(obj: any): void {
  const tmp = obj["origin"];
  obj["origin"] = obj["destination"];
  obj["destination"] = tmp;
}

export interface TravelChangeEvent<LOCALITY_TYPE extends Locality | Address | TransferTypes.Place> {
  travel: Travel<LOCALITY_TYPE>;
  from?: LocalityChangeEvent<LOCALITY_TYPE>;
  fromKind?: Kind;
}

const PROPERTIES_TO_COPY_FOR_MODAL = [
  "restrictions",
  "icons",
  "suggestions",
  "from",
  "radius",
  "address",
  "suggestPanelTitle",
  "label",
  "placeholder",
  "optionDestinationSameAsOriginLabel",
  "destinationSameAsOrigin",
  "favoritesSuggestPanelName",
  "originGridArea",
  "originSuggestPanelName",
  "destinationGridArea",
  "destinationSuggestPanelName",
  "travel",
  "disableFavorites",
  "displayFavorites",
  "displayOnly",
  "searchService",
  "className",
  "nextFocus",
  "showSuggestInPanel",
  "localityAutoCompleteDestination",
];
