import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { filter, first, map, mergeMap } from "rxjs/operators";
import { SocietyService } from "../services/society.service";
import { Society } from "../@types/society";
import { Billing } from "../@types/models";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BillingsResolver implements Resolve<any> {
  constructor(private societyService: SocietyService) {}

  resolve(): Observable<Array<Billing>> {
    return this.societyService.getBillings().pipe(
      filter((billings) => !!billings),
      first(),
      map((billings) => billings),
    );
  }
}
