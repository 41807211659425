<spt-loading-v2 *ngIf="loading"></spt-loading-v2>
<div class="main">
  <div
    class="col-12 grid justify-content-between align-items-center dashboard-title-container-mobile"
    *ngIf="(commonService.isTabletObservable | async) === true; else desktopTitle"
  >
    <h2 class="dashboard-tab-title active f-sb" (click)="changeTab(0)" translate #bookTab>DASHBOARD.BOOK</h2>

    <h2 class="dashboard-tab-title f-sb" (click)="changeTab(1)" translate #bookingsTab>DASHBOARD.BOOKINGS</h2>
  </div>

  <ng-template #desktopTitle>
    <div class="col-12 grid">
      <span class="dashboard-tab-title txt-36 f-sb active" translate>DASHBOARD.BOOK</span>
    </div>
  </ng-template>

  <div
    class="menu-items grid justify-content-center"
    *ngIf="(commonService.isTabletObservable | async) === false || activeTab === 0"
  >
    <div
      class="card-hoverable"
      *ngFor="let menu of menuIcons"
      [ngClass]="(commonService.isTabletObservable | async) === true ? 'col-12' : 'col-4'"
    >
      <p-card (click)="openModalService(menu.type)" class="menu-item">
        <div class="col-3">
          <img [alt]="menu.iconName" [src]="'./assets/svg/' + menu.iconName + '.svg'" class="icon-travel" />
        </div>
        <div class="grid col-nogutter justify-content-between align-items-baseline m-0 col-9">
          <h2 class="m-0 card-title" translate>{{ menu.name }}</h2>
          <i class="fas fa-chevron-right"></i>
        </div>
      </p-card>
    </div>
  </div>
  <div class="resume grid" *ngIf="(commonService.isTabletObservable | async) === false || activeTab === 1">
    <h2
      class="dashboard-tab-title f-sb active txt-32"
      [innerHTML]="'DASHBOARD.NEXT_BOOKINGS' | translate"
      *ngIf="(commonService.isTabletObservable | async) === false"
    ></h2>
    <div class="booking-list-container col-12">
      <spt-booking-list
        [items]="filteredItems"
        [society]="society"
        [user]="user"
        (loading)="loading = $event"
      ></spt-booking-list>
    </div>
  </div>
</div>
