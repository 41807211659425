<div class="legs">
  <div *ngFor="let leg of itinerary.legs; let tripIndex = index" class="leg">
    <spt-travel-flight-itinerary-leg
      [departureDate]="leg.departureDate"
      [baggageIncluded]="itinerary.baggageIncluded"
      [carryOnIncluded]="itinerary.carryOnIncluded"
      [carbonOffset]="itinerary?.carbonOffset"
      [leg]="leg"
    ></spt-travel-flight-itinerary-leg>
  </div>
</div>

<div class="travelers">
  <div class="title"><span translate>SEARCH.RESULT.AIR.FILTERS.CONDITIONS</span></div>
  <ng-container *ngFor="let user of this.users; let i = index">
    <ng-container *ngFor="let pricingInformation of itinerary.pricingInformations">
      <spt-flight-detail-information
        [pricingInformation]="pricingInformation"
        [isValidateOptionInformation]="isValidateOptionInformation"
        [dateEnd]="dateEnd"
        [index]="i"
        [user]="user"
        [provider]="itinerary.provider"
      ></spt-flight-detail-information>
    </ng-container>
  </ng-container>
</div>
