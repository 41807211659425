<div class="date">
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.4 12.6H12.6V4.2H1.4V12.6ZM12.6 1.4V0H11.2V1.4H7.7V0H6.3V1.4H2.8V0H1.4V1.4H0V14H14V1.4H12.6Z"
      fill="#604FD7"
    />
  </svg>
  {{ date | localizedDate: "dd MMM" }}
</div>
<div class="time" *ngIf="time">
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 0C3.15 0 0 3.15 0 7C0 10.85 3.15 14 7 14C10.85 14 14 10.85 14 7C14 3.15 10.85 0 7 0ZM7 12.6C3.92 12.6 1.4 10.08 1.4 7C1.4 3.92 3.92 1.4 7 1.4C10.08 1.4 12.6 3.92 12.6 7C12.6 10.08 10.08 12.6 7 12.6Z"
      fill="#604FD7"
    />
    <path d="M7.35 3.5H6.3V7.7L9.94 9.94L10.5 9.03L7.35 7.14V3.5Z" fill="#604FD7" />
  </svg>
  {{ time }}
</div>
