import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { Subscription } from "rxjs";
import { MenuService } from "src/app/@shared/components/menu/menu.service";
import { BasketService } from "src/app/@shared/services/basket.service";
import { CommonService } from "src/app/@shared/services/common.service";

@Component({
  selector: "spt-menu-mobile",
  templateUrl: "./header-mobile.component.html",
  styleUrls: ["./header-mobile.component.scss"],
})
export class HeaderMobileComponent {
  @Input() basket?: number;
  @Input() background?: boolean;
  public basketCount: number = 0;
  private subscriptions: Subscription[] = [];

  constructor(
    public commonService: CommonService,
    private menuService: MenuService,
    private cd: ChangeDetectorRef,
    public basketService: BasketService,
  ) {}

  ngOnInit() {
    if (this.basket) {
      this.basketCount = this.basket;
    } else {
      this.subscriptions.push(
        this.basketService.counting$.subscribe((counting) => {
          this.basketCount = counting.validating + counting.inProgress
        }),
      );
      /*this.subscriptions.push(
        this.basketService.counting$.subscribe(
          (counting) =>
            (this.basketCount = counting.inProgress + counting.pending + counting.toValidate + counting.validating),
        ),
      );*/
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.filter((s) => !s.closed).forEach((s) => s.unsubscribe());
  }
  displayMenu(): void {
    this.menuService.display();
  }

  removeMenu(): void {
    this.menuService.remove();
  }
}
