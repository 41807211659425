import { UtilsTypes } from "../@types/utils";
import { CurrencyPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { UserService } from "../services/user.service";
import { SocietyService } from "../services/society.service";
import { ExchangeRateService } from "../services/exchange-rate.service";
import moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { map, Observable, of } from "rxjs";

@Pipe({
  name: "SPTPrice",
})
export class SPTPricePipe implements PipeTransform {
  constructor(
    private userService: UserService,
    private societyService: SocietyService,
    private exchangeRateService: ExchangeRateService,
    private translateService: TranslateService,
    private currencyPipe: CurrencyPipe,
  ) {}

  transform(price: UtilsTypes.Price, date?: string | Date): Observable<string> {
    const userCurrency = this.userService.user.value.settings?.currency ?? this.societyService.billingCurrency ?? "EUR";
    const stringDate = price.exchange?.date || typeof date !== "string" ? moment(date).format("YYYY-MM-DD") : date;

    if (price.currency !== userCurrency) {
      return this.exchangeRateService.get(price.currency, stringDate).pipe(
        map((exchangeRate: any) => {
          return this.currencyPipe.transform(
            price.amount * 1.01 * exchangeRate.rates[userCurrency],
            userCurrency,
            "symbol",
            "1.2",
            this.translateService.currentLang,
          );
        }),
      );
    } else {
      return of(
        this.currencyPipe.transform(price.amount, userCurrency, "symbol", "1.2", this.translateService.currentLang),
      );
    }
  }
}
