<ng-container *ngIf="searchResult | async as result">
  <section class="main">
    <spt-travel-train-selection
      *ngIf="selectedOutward && (commonService.isTabletObservable | async) === true"
      [selectedOutward]="selectedOutward"
      [locale]="locale"
      [isSmallDevice]="commonService.isTablet"
      (search)="getSearchResults(true)"
    ></spt-travel-train-selection>
    <spt-travel-train-resume
      *ngIf="(commonService.isTabletObservable | async) === false"
      [origin]="origin"
      [destination]="destination"
      [searchJourneyDirection]="searchJourneyDirection"
      [type]="searchType"
      [departureDate]="searchDate"
      [returnDate]="returnDate"
    ></spt-travel-train-resume>
    <div class="offers" *ngIf="result.journeys && result.journeys.length > 0; else noTrains">
      <div class="offers-header" [class.packages-checkbox]="origin?.country === 'GB' && destination?.country === 'GB'">
        <div class="train-resume">
          <spt-travel-train-resume
            *ngIf="(commonService.isTabletObservable | async) === true"
            [origin]="origin"
            [destination]="destination"
            [searchJourneyDirection]="searchJourneyDirection"
            [type]="searchType"
            [departureDate]="searchDate"
            [returnDate]="returnDate"
          ></spt-travel-train-resume>
        </div>
        <p-tabView
          class="types"
          *ngIf="commonService.isTabletObservable | async; else desktopHeader"
          (activeIndexChange)="activeTabIndex = $event"
          [activeIndex]="activeTabIndex"
        >
          <p-tabPanel
            *ngFor="let flexibility of result.flexibilities"
            [header]="
              ('SEARCH.RESULT.RAIL.FLEXIBILITY.' + flexibility | uppercase | translate) +
              ' (' +
              result.itemsCount[flexibility] +
              ')'
            "
            headerStyleClass="type"
            [class.return-selected]="!!selectedOutward"
          >
            <ng-container [ngTemplateOutlet]="mobileContent"></ng-container>
          </p-tabPanel>
        </p-tabView>
        <ng-template #desktopHeader>
          <div class="types" *ngIf="result.journeys?.length; else noTrains">
            <span class="type text-center" *ngFor="let flexibility of result.flexibilities">
              {{ "SEARCH.RESULT.RAIL.FLEXIBILITY." + flexibility | uppercase | translate }}
            </span>
          </div>
        </ng-template>
      </div>
      <ng-container *ngIf="!(commonService.isTabletObservable | async)">
        <ng-container [ngTemplateOutlet]="offersContainer"></ng-container>
        <div class="actions" *ngIf="result.journeys">
          <ng-container [ngTemplateOutlet]="previous"></ng-container>
          <ng-container [ngTemplateOutlet]="next"></ng-container>
        </div>
      </ng-container>
    </div>
    <ng-template #noTrains>
      <div class="error-container">
        <p class="text-center">
          <img class="error-img" src="./assets/svg/no-results.svg" alt="No results" />
        </p>
        <p class="error-message" translate>SEARCH.RESULT.NO_TRAINS</p>
        <p class="error-message" *ngIf="noETicket" translate>SEARCH.RESULT.RAIL.NO_ELECTRONIC_TICKET</p>
        <p-button
          class="error-button"
          [label]="'SEARCH.RESULT.AIR.UPDATE_SEARCH' | translate"
          styleClass="p-button-secondary"
          (onClick)="changeSearch()"
        ></p-button>
      </div>
    </ng-template>
  </section>

  <ng-template #offersContainer>
    <spt-travel-train-journeys
      [journeys]="result.journeys"
      [origin]="origin"
      [destination]="destination"
      [activeTabIndex]="activeTabIndex"
      [isSmallDevice]="commonService.isTablet"
      [toggleOfferPackages]="toggleOfferPackages"
      [flexibilities]="result.flexibilities"
      [searchJourneyDirection]="searchJourneyDirection"
      [seeOutOfPolicy]="seeOutOfPolicy"
      [allowedAlternativeIds]="allowedAlternativeIds"
      [selectedReturnFare]="selectedReturnFare"
      (selectOffers)="onOffersSelected($event)"
    >
    </spt-travel-train-journeys>
  </ng-template>

  <ng-template #previous>
    <p
      class="previous"
      (mousedown)="noMorePreviousResult ? undefined : loadMore('Previous')"
      [class.disabled]="noMorePreviousResult"
    >
      <span class="label" translate>SEARCH.RESULT.RAIL.PREVIOUS</span>
      <i class="icon spt-icon-chevron-up"></i>
    </p>
  </ng-template>

  <ng-template #next>
    <p class="next" (mousedown)="noMoreNextResult ? undefined : loadMore('Next')" [class.disabled]="noMoreNextResult">
      <span class="label" translate>SEARCH.RESULT.RAIL.NEXT</span>
      <i class="icon spt-icon-chevron-down"></i>
    </p>
  </ng-template>

  <ng-template #mobileContent>
    <ng-container [ngTemplateOutlet]="previous"></ng-container>
    <ng-container [ngTemplateOutlet]="offersContainer"></ng-container>
    <ng-container [ngTemplateOutlet]="next"></ng-container>
  </ng-template>

  <spt-sidebar [opened]="showSidebar" [showCloseIcon]="!commonService.isTablet" (close)="closeDetailPanel()">
    <spt-travel-train-details
      *ngIf="selectedOffers"
      [offersSelection]="selectedOffers"
      [price]="selectedOffers.price"
      [fullPrice]="selectedOffers.fullPrice"
      [flexibility]="selectedOffers.flexibility"
      [travelClass]="selectedOffers.travelClass"
      [carbonOffset]="selectedOffers.journey.carbonOffset"
      [direction]="searchJourneyDirection"
      [origin]="origin.name"
      [destination]="destination.name"
      [searchType]="searchType"
      [passengers]="passengers"
      [isSmallDevice]="commonService.isTablet"
      [provider]="provider"
      (closePanel)="closeDetailPanel()"
      (validated)="onSelectTrip($event)"
    ></spt-travel-train-details>
  </spt-sidebar>

  <spt-travel-train-selection
    *ngIf="selectedOutward && (commonService.isTabletObservable | async) === false"
    [selectedOutward]="selectedOutward"
    [locale]="locale"
    [isSmallDevice]="commonService.isTablet"
    (search)="getSearchResults(true)"
  ></spt-travel-train-selection>
</ng-container>

<div class="error-container" *ngIf="errorOccurred">
  <p class="text-center">
    <img class="error-img" src="./assets/svg/no-results.svg" alt="No results" />
  </p>
  <p class="error-message">
    {{ "NOTIFICATIONS." + errorOccurred | translate }}
  </p>
  <p class="error-message" *ngIf="noETicket" translate>SEARCH.RESULT.RAIL.NO_ELECTRONIC_TICKET</p>
  <p-button
    class="error-button"
    [label]="(searchType === 'Exchange' ? 'SEARCH.ERROR.BTN' : 'SEARCH.RESULT.AIR.UPDATE_SEARCH') | translate"
    styleClass="p-button-secondary"
    (onClick)="searchType === 'Exchange' ? redirect() : changeSearch()"
  ></p-button>
</div>
