import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { TrainTypes } from "../../../../travel/train/train";
import moment from "moment";
import { UtilsTypes } from "../../../../@shared/@types/utils";
import { TrainService } from "src/app/travel/train/train.service";

@Component({
  selector: "spt-reservations-detail-train",
  templateUrl: "./train.component.html",
  styleUrls: ["./train.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservationsDetailTrainComponent implements OnInit {
  @Input() item: any;
  @Input() isSmallDevice: boolean;
  @Input() locale: string;
  @Output() openTicket: EventEmitter<{
    documents: Array<any>;
    type: "train";
    reference: string;
  }> = new EventEmitter();
  travelClasses: UtilsTypes.ObjectKey<TrainTypes.TravelClass> = {};
  fareTypes: UtilsTypes.ObjectKey<Array<TrainTypes.Fare>> = {};
  spaceAllocations: UtilsTypes.ObjectKey<Array<Array<TrainTypes.SpaceAllocation>>> = {};
  selectedFare: TrainTypes.Fare;
  hasDifferentJourneys: boolean = false;
  mappedJourneys: Array<TrainTypes.Journey> = [];
  orderItems: any;
  constructor(private trainService: TrainService) {}

  travelDuration(departureTime: Date, arrivalTime: Date): string {
    if (!departureTime || !arrivalTime) {
      return null;
    }
    const departureT: moment.Moment = moment(departureTime);
    const arrivalT: moment.Moment = moment(arrivalTime);
    const difference: moment.Duration = moment.duration(arrivalT.diff(departureT));
    const hours: number = difference.get("hours");
    const minutes: number = difference.get("minutes");
    return `PT${hours}H${minutes}M`;
  }

  ngOnInit(): void {
    this.mapJourneys();
    const journeysToCheck: Array<TrainTypes.Journey> = [];
    this.item.confirmationDate = this.item.statusHistory.find(
      (status: any): boolean => status.to === "confirmed",
    )?.date;
    this.item.detail.journeys.forEach((_journey: TrainTypes.Journey): void => {
      if (journeysToCheck.length === 0) {
        journeysToCheck.push(_journey);
      } else {
        const sameJourney: TrainTypes.Journey = journeysToCheck.find((_journeyToCheck: TrainTypes.Journey): boolean => {
          return (
            _journeyToCheck.departure.locationId === _journey.departure.locationId &&
            _journeyToCheck.arrival.locationId === _journey.arrival.locationId &&
            _journeyToCheck.departure.date.utc === _journey.arrival.date.utc &&
            _journeyToCheck.arrival.date.utc === _journey.arrival.date.utc &&
            _journeyToCheck.departure.locationId !== _journey.arrival.locationId &&
            _journeyToCheck.arrival.locationId !== _journey.departure.locationId
          );
        });

        this.hasDifferentJourneys = this.hasDifferentJourneys && !sameJourney;
      }
    });
    if (!this.item.offline) {
      this.item.detail.orderItems = this.item.detail.orderItems.sort(
        (_orderItemA: TrainTypes.OrderItem, _orderItemB: TrainTypes.OrderItem): number => {
          if (_orderItemA.item.journeys[0].departure.date.utc < _orderItemB.item.journeys[0].departure.date.utc) {
            return -1;
          } else {
            return 1;
          }
        },
      );
    }
    this.mapFares();
  }

  toggleFare(fare: TrainTypes.Fare): void {
    this.selectedFare = fare;
  }

  private mapJourneys(): void {
    const { mappedJourneys } = this.trainService.mapJourneys(this.item.detail.journeys);
    this.mappedJourneys = mappedJourneys;
  }

  private mapFares(): void {
    this.orderItems = this.item.detail.orderItems || this.item.itineraries;
    this.orderItems.forEach((_orderItem: TrainTypes.OrderItem): void => {
      _orderItem.item.journeys.forEach((_journey: TrainTypes.Journey, _journeyIndex: number): void => {
        _orderItem.item.sections.forEach((_section: TrainTypes.ItinerarySection): void => {
          _section.fares.forEach((_fare: TrainTypes.Fare): void => {
            _fare.segmentIds.forEach((_segmentId: string): void => {
              this.travelClasses[_segmentId] = _fare.fareSegments[0].travelClass;
            });
            const segmentId: number = _journey.segments.findIndex((_segment: TrainTypes.Segment): boolean => {
              return _fare.segmentIds.includes(_segment.id);
            });
            if (segmentId !== -1 && !this.fareTypes[_journey.direction]) {
              this.fareTypes[_journey.direction] = [_fare];
            } else if (segmentId !== -1 && this.fareTypes[_journey.direction]) {
              const foundFare: TrainTypes.Fare = this.fareTypes[_journey.direction].find(
                (_fareType: TrainTypes.Fare): boolean => {
                  return _fareType.fareName === _fare.fareName;
                },
              );
              if (
                foundFare &&
                !foundFare.passengers.some((passenger: string) => _fare.passengers.includes(passenger))
              ) {
                foundFare.passengers.push(..._fare.passengers);
                foundFare.price.amount += _fare.price.amount * _fare.passengers.length;
              } else if (!foundFare) {
                this.fareTypes[_journey.direction].push({
                  ..._fare,
                  price: {
                    amount: _fare.price.amount,
                    currency: _fare.price.currency,
                  },
                });
              }
            }
          });
        });

        _journey.segments.forEach((_segment: TrainTypes.Segment) => {
          if (_orderItem.item.spaceAllocations[_journeyIndex]) {
            if (!this.spaceAllocations[_journey.direction]) {
              this.spaceAllocations[_journey.direction] = [];
            }

            this.spaceAllocations[_journey.direction][_segment.id] = _orderItem.item.spaceAllocations[
              _journeyIndex
            ].filter(
              (spaceAllocation: TrainTypes.SpaceAllocation): boolean => spaceAllocation.fareSegmentId === _segment.id,
            );
          }
        });
      });
    });
  }
}
