<div class="travel-time">
  {{ travelDuration(segments[0].departure.date.utc, segments[segments.length - 1].arrival.date.utc) }}
</div>
<ng-container *ngFor="let segment of segments; let isFirst = first; let isLast = last; let i = index">
  <ng-container *ngIf="expanded; else hidden">
    <div class="origin" *ngIf="isFirst">
      <span class="vertical-separator">
        <span class="round"></span>
        <span class="line" [class.has-stop]="segments.length > 1 && !expanded"></span>
        <span class="round"></span>
      </span>
      <span class="departure-time times">
        {{ segment.departure.date.time }}
      </span>
      <span class="departure-station stations">
        {{ segment.departure.name | titlecase }}
      </span>
      <span class="information" [class.has-services]="segments.length === 1 && segment.transport.services?.length > 0">
        <span class="duration" *ngIf="segment.duration">{{ segment.duration | duration }}</span>
        <span class="offset m-r-5">
          <i class="spt-icon-carbon-offset"></i>
          {{
            segment.carbonOffset
              ? (segment.carbonOffset.amount | kiloFormatter)
              : ("CARBON_OFFSET.UNAVAILABLE" | translate)
          }}
        </span>
        <span class="carrier">
          <span
            class="transport m-r-5"
            *ngIf="segment.transport.carrier && segment.transport.carrier !== 'Unknown Operator'"
          >
            <img
              [alt]="segment.transport.carrier"
              [src]="'./assets/img/train-carriers/' + segment.transport.carrier + '.webp'"
            />
          </span>
          <span class="m-r-5">{{ segment.transport.transportMode }}</span>
          <span class="m-r-5" *ngIf="segment.transport.trainCode">
            {{ segment.transport.trainCode }}
          </span>
          <span>{{ segment.transport.trainNumber }}</span>
        </span>
        <span class="services" *ngIf="segments.length === 1 && segment.transport.services?.length > 0">
          <ng-container *ngFor="let service of segment.transport.services">
            <span class="service"><i [class]="'fas fa-' + services[service.code]" [pTooltip]="service.label"></i></span>
          </ng-container>
        </span>
      </span>
      <span class="arrival-time times">
        {{ segment.arrival.date.time }}
      </span>
      <span class="arrival-station stations">
        {{ segment.arrival.name | titlecase }}
      </span>
      <div class="between-segments" *ngIf="segments.length > 0 && isFirst">
        <div class="switching-time">
          <span>{{ travelDuration(segment.arrival.date.utc, segments[i + 1].departure.date.utc) }}</span>
          <span> {{ "BOOKING.TRAIN.CHANGING_TIME" | translate }}</span>
        </div>
      </div>
    </div>
    <div class="segments-detailed" *ngIf="!isFirst && !isLast">
      <div class="between-segments" *ngIf="segments.length > 0">
        <div class="switching-time">
          <span>{{ travelDuration(segment.arrival.date.utc, segments[i + 1].departure.date.utc) }}</span>
          <span> {{ "BOOKING.TRAIN.CHANGING_TIME" | translate }}</span>
        </div>
      </div>
      <span class="vertical-separator">
        <span class="round"></span>
        <span class="line"></span>
        <span class="round"></span>
      </span>
      <span class="departure-time times">
        {{ segment.departure.date.time }}
      </span>
      <span class="departure-station stations">
        {{ segment.departure.name | titlecase }}
      </span>
      <span class="information" [class.has-services]="segments.length === 1 && segment.transport.services?.length > 0">
        <span class="duration" *ngIf="segment.duration">{{ segment.duration | duration }}</span>
        <span class="offset">
          <i class="spt-icon-carbon-offset"></i>
          {{
            segment.carbonOffset
              ? (segment.carbonOffset.amount | kiloFormatter)
              : ("CARBON_OFFSET.UNAVAILABLE" | translate)
          }}
        </span>
        <span class="carrier">
          <span class="transport m-r-5">
            <img
              [alt]="segment.transport.carrier"
              [src]="'./assets/img/train-carriers/' + segment.transport.carrier + '.webp'"
              *ngIf="segment.transport.carrier !== 'Unknown Operator'"
            />
          </span>
          <span class="m-r-5">{{ segment.transport.transportMode }}</span>
          <span class="m-r-5" *ngIf="segment.transport.trainCode">
            {{ segment.transport.trainCode }}
          </span>
          <span>{{ segment.transport.trainNumber }}</span>
        </span>
      </span>
      <span class="arrival-time times">
        {{ segment.arrival.date.time }}
      </span>
      <span class="arrival-station stations">
        {{ segment.arrival.name | titlecase }}
      </span>
    </div>
    <div class="destination" *ngIf="isLast && !isFirst">
      <span class="vertical-separator">
        <span class="round"></span>
        <span class="line"></span>
        <span class="round"></span>
        <span class="expand" (mousedown)="expand()">
          <i class="spt-icon-chevron-up txt-12"></i>
        </span>
      </span>
      <span class="departure-time times">
        {{ segment.departure.date.time }}
      </span>
      <span class="departure-station stations">
        {{ segment.departure.name | titlecase }}
      </span>
      <span
        class="information"
        [class.has-services]="segments.length === 1 && segment.transport.services?.length > 0 && !expanded"
      >
        <span class="duration" *ngIf="segment.duration">{{ segment.duration | duration }}</span>
        <span class="offset">
          <i class="spt-icon-carbon-offset"></i>
          {{
            segment.carbonOffset
              ? (segment.carbonOffset.amount | kiloFormatter)
              : ("CARBON_OFFSET.UNAVAILABLE" | translate)
          }}
        </span>
        <span class="carrier">
          <span
            class="transport m-r-5"
            *ngIf="segment.transport.carrier && segment.transport.carrier !== 'Unknown Operator'"
          >
            <img
              [alt]="segment.transport.carrier"
              [src]="'./assets/img/train-carriers/' + segment.transport.carrier + '.webp'"
            />
          </span>
          <span class="m-r-5">{{ segment.transport.transportMode }}</span>
          <span class="m-r-5" *ngIf="segment.transport.trainCode">
            {{ segment.transport.trainCode }}
          </span>
          <span>{{ segment.transport.trainNumber }}</span>
        </span>
        <span class="services" *ngIf="segments.length === 1 && segment.transport.services?.length > 0">
          <ng-container *ngFor="let service of segment.transport.services">
            <span class="service"><i [class]="'fas fa-' + services[service.code]" [pTooltip]="service.label"></i></span>
          </ng-container>
        </span>
      </span>
      <span class="arrival-time times">
        {{ segment.arrival.date.time }}
      </span>
      <span class="arrival-station stations">
        {{ segment.arrival.name | titlecase }}
      </span>
    </div>
  </ng-container>
  <ng-template #hidden>
    <div class="origin" *ngIf="isFirst">
      <span class="vertical-separator">
        <span class="round"></span>
        <span class="line" [class.has-stop]="segments.length > 1 && !expanded"></span>
        <span class="round"></span>
      </span>
      <span class="departure-time times">
        {{ segment.departure.date.time }}
      </span>
      <span class="departure-station stations">
        {{ segment.departure.name | titlecase }}
      </span>
      <span class="information" [class.has-services]="segments.length === 1 && segment.transport.services?.length > 0">
        <span
          class="stops"
          [class.has-stop]="segments.length - 1 > 0"
          [class.detailed]="detailed"
          (mousedown)="expand()"
        >
          <ng-container *ngIf="detailed; else noDetailed">
            <span>{{
              segments.length > 1
                ? ("SEARCH.RESULT.RAIL.STOPS" | translate: { stop: segments.length - 1 })
                : ("SEARCH.RESULT.RAIL.NO_STOP" | translate)
            }}</span>
            <i class="spt-icon-chevron-down" *ngIf="segments.length > 1"></i>
          </ng-container>
          <ng-template #noDetailed>
            <span>{{
              segments.length > 1
                ? ("SEARCH.RESULT.RAIL.STOPS" | translate: { stop: segments.length - 1 })
                : ("SEARCH.RESULT.RAIL.NO_STOP" | translate)
            }}</span>
          </ng-template>
        </span>
        <span class="offset">
          <i class="spt-icon-carbon-offset m-r-5"></i>
          <b class="m-l-5 carbon-offset-amount" *ngIf="offerCarbonOffset; else estimateCarbonOffset">
            {{ offerCarbonOffset.amount | kiloFormatter }}
          </b>
          <ng-template #estimateCarbonOffset>
            <b class="m-l-5 carbon-offset-amount" translate> CARBON_OFFSET.ESTIMATION </b>
          </ng-template>
        </span>
        <span class="carrier">
          <span
            class="transport m-r-5"
            *ngIf="segment.transport.carrier && segment.transport.carrier !== 'Unknown Operator'"
          >
            <img
              [alt]="segment.transport.carrier"
              [src]="'./assets/img/train-carriers/' + segment.transport.carrier + '.webp'"
            />
          </span>
          <ng-container *ngIf="detailed && segments.length === 1">
            <span class="m-r-5">{{ segment.transport.transportMode }}</span>
            <span class="m-r-5" *ngIf="segment.transport.trainCode">
              {{ segment.transport.trainCode }}
            </span>
            <span>{{ segment.transport.trainNumber }}</span>
          </ng-container>
        </span>
        <span class="services" *ngIf="segments.length === 1 && segment.transport.services?.length > 0">
          <ng-container *ngFor="let service of segment.transport.services">
            <span class="service"><i [class]="'fas fa-' + services[service.code]" [pTooltip]="service.label"></i></span>
          </ng-container>
        </span>
      </span>
    </div>
    <div class="destination" *ngIf="isLast">
      <span class="arrival-time times">
        {{ segment.arrival.date.time }}
      </span>
      <span class="arrival-station stations">
        {{ segment.arrival.name | titlecase }}
      </span>
    </div>
  </ng-template>
</ng-container>
