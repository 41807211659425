import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "src/app/auth/auth.service";
import { CommonService } from "./common.service";
import { SessionService } from "./session.service";
import { UserService } from "./user.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  ERROR_CODE_TO_IGNORE: Array<string> = ["UNKNOWN_ERROR", "BASKET_PENDING"];
  constructor(
    private messageService: MessageService,
    private translateService: TranslateService,
    private sessionService: SessionService<any>,
    private authService: AuthService,
    private commonService: CommonService,
    private router: Router,
    private userService: UserService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | any> {
    return next.handle(request).pipe(
      catchError((res: any) => {
        let alreadyShown: boolean = false;
        if (res?.error?.code === "OSPITA_SESSION_EXPIRED") {
          this.displayError(res.error);
          const contextUrl = this.router.url ?? "/";
          this.router.navigateByUrl("/", { skipLocationChange: false }).then(() => this.router.navigate([contextUrl]));
        } else if ([401, 419].includes(res.status)) {
          const userProfile: any = this.authService.getUserProfile();
          if (
            this.userService.user.value?.settings.auth &&
            this.userService.user.value?.settings.auth.sso &&
            userProfile
          ) {
            this.authService
              .logout(userProfile, this.commonService.isCordova())
              .pipe(
                map((data: { logoutUri: string }) => {
                  const browser: Window = window.open(data.logoutUri, "_self", "location=no");
                  browser.onunload = (): void => {
                    browser.close();
                  };
                  this.authService.clearUserProfile();
                  return this.sessionService.close();
                }),
              )
              .subscribe();
          } else {
            this.sessionService.close().subscribe();
          }
        } else {
          if (request.headers.has("ignoreErrorMessage")) {
            request.headers.delete("ignoreErrorMessage");
            return next.handle(request);
          }
          alreadyShown = this.displayError(res.error);
        }
        return throwError({ ...res, alreadyShown });
      }),
    );
  }

  // tslint:disable:max-line-length
  displayError(error: any): boolean {
    let sticky: boolean = false;
    let detail: string = this.translateService.instant("NOTIFICATIONS.AN_ERROR_OCCURED");

    try {
      if (
        !error ||
        (error.code && typeof error.code === "string" && this.ERROR_CODE_TO_IGNORE.find((code) => error.code === code))
      ) {
        return;
      }
      if (error.code && typeof error.code === "string") {
        detail = this.translateService.instant("NOTIFICATIONS." + error.code);
        // if (detail === error.code) {
        //   detail = this.translateService.instant("UNKNOWN");

        // }
      } else if (
        (error.message &&
          typeof error.message === "string" &&
          (error.message.indexOf("email_1 dup key") > -1 || error.message.indexOf("Email déjà utilisé") > -1)) ||
        (error.data &&
          error.data.err &&
          error.data.err.message &&
          typeof error.data.err.message === "string" &&
          (error.data.err.message.indexOf("email_1 dup key") > -1 ||
            error.data.err.message.indexOf("Email déjà utilisé") > -1))
      ) {
        detail = this.translateService.instant("NOTIFICATIONS.EMAIL_ALREADY_USED");
      } else if (
        error.err &&
        error.err.length &&
        error.err.indexOf("The booking status doesn't allow this action.") > -1
      ) {
        detail = error.err;
      } else if (
        error.data &&
        typeof error.data.err === "string" &&
        error.data.err.indexOf('"paymentMethod" is required.') > -1
      ) {
        detail = `Aucun moyen de paiement n'a été ajouté sur votre compte`;
        sticky = true;
      } else if (error.data && error.data.err && error.data.err.type === "StripeCardError") {
        detail = `Erreur avec votre moyen de payment`;
        sticky = true;
      } else if (error && error.length && error.indexOf("NO CAR LOCATIONS LISTED") > -1) {
        detail = `Aucune agence n'est disponible pour cette recherche, vous pouvez essayer avec d'autres horaires ou contacter le service client.`;
        sticky = true;
      } else if (error && error.length && error.indexOf("NO RATES QUALIFY") > -1) {
        detail = `Il semblerait qu'aucune agence ne corresponde à vos dates / horaires de recherche.`;
      } else if (
        error &&
        error.err &&
        error.err.message.indexOf(`An error occured, you aren't be charged. RC-1`) > -1
      ) {
        // tslint:disable-next-line:max-line-length
        detail = `Votre réservation n'a pas fonctionné merci de réessayer avec une autre offre. Puis de contacter le service client si cela se reproduit`;
      } else if (
        error &&
        error.err &&
        error.err.message === "Authentication failed. User not found or wrong password"
      ) {
        detail = this.translateService.instant("NOTIFICATIONS.WRONG_ACCOUNT");
      } else if (error && error.err && error.err.message === "No rights") {
        detail = this.translateService.instant(`Vous n'avez pas les droits pour effectuer cette action`);
      } else if (error && error.err && error.err.message === `Cannot read property 'rs:Offers' of undefined`) {
        detail = this.translateService.instant("Aucun résultat pour cette recherche");
      } else if (error && error.err && error.err.message === "Cannot charge a customer that has no active card") {
        detail = this.translateService.instant(`Merci d'ajouter une carte avant d'effectuer une réservation`);
      } else if (
        error.data &&
        error.data.err &&
        error.data.err.message &&
        (error.data.err.name === "RailManageBookingError" || error.data.err.name === "RailShopError")
      ) {
        let errors: any = error.data.err.message;
        detail = "";
        if (errors && errors["rmb:Errors"] && errors["rmb:Errors"]["rcmn:Error"]) {
          errors = errors["rmb:Errors"]["rcmn:Error"];
        }
        errors.forEach((err: any) => {
          if (err.name === "BT-PNR") {
            // tslint:disable-next-line:max-line-length
            detail += this.translateService.instant(
              "Une erreur avec le fournisseur est survenue, n'hésitez pas à contacter notre service client. ",
            );
          } else if (err.name === "BT-CARDNUMBER") {
            detail += this.translateService.instant(
              "Il semblerait que votre numéro de carte voyageur ou d'abonnement soit incorrect.",
            );
          } else if (err.name === "INSUFFICIENT_SEATS") {
            const regs: any = {
              origin: /Origin \| (\w*);/g,
              destination: /Destination \| (\w*);/g,
              train: /Train \| (\w*)\)/g,
            };
            const data: any = {};
            for (const k in regs) {
              if (regs[k]) {
                const a: Array<any> = regs[k].exec(err.message);
                if (a && a[1]) {
                  data[k] = a[1];
                }
              }
            }
            // tslint:disable-next-line:max-line-length
            detail += this.translateService.instant(
              `Il ne reste plus assez de place pour tous les passagers sur le train ${data.train}. `,
            );
          } else if (err.name === "INCOMPATIBLE_FARE") {
            // tslint:disable-next-line:max-line-length
            detail += this.translateService.instant(
              "La combinaison de tarifs choisie est invalide. Vérifiez que vous n'avez pas sélectionné un tarif qui nécessite un aller-retour avec le même tarif.",
            );
          } else if (err.name === "ROUNDTRIP_MANDATORY") {
            detail += this.translateService.instant("Un des tarifs sélectionné nécessite un aller-retour obligatoire.");
          } else if (err.name === "NO_SOLUTION_FOUND") {
            detail += this.translateService.instant(
              "Aucun train n'est disponible, essayez une autre recherche ou contactez le service client.",
            );
          } else if (
            err.value &&
            err.$value.indexOf("Missing information to proceed to fare application control") > -1
          ) {
            detail += "Merci d'ajouter les numéros des cartes d'abonnements aux voyageurs sélectionnés.";
          } else if (err.name) {
            // not listed error
            detail += err.name;
          }
        });
      } else if (error.data && error.data.err && error.data.err.message === "Token invalid due to time") {
        detail = this.translateService.instant("NOTIFICATIONS.INVITATION_EXPIRED");
      } else if (error && error.err && error.err.message.indexOf("book request already handleled") !== -1) {
        detail = "Cette demande a déjà été traitée";
      } else if (error && error.err && error.err.message.indexOf("duplicate-booking") !== -1) {
        detail = "Une reservation a déjà été faite pour cette recherche";
      } else if (
        (error && error.err && error.err.message.indexOf("No right to access this ressource") !== 1) ||
        (error.error && typeof error.error === "string" && error.error.indexOf("Error when create session") > -1)
      ) {
        detail = undefined;
      } else if (error && error.data && error.code === 404 && (error.data.travelling || error.data.returning)) {
        detail = this.translateService.instant("NOTIFICATIONS.NO_ITINERARY");
      } else if (error.data && error.data.err && error.data.err.message) {
        detail = error.data.err.message;
      } else if (error.data && error.data.err && error.data.err.code) {
        detail = this.translateService.instant(error.data.err.code);
      } else if (error.data && error.data.err && error.data.err.description && error.data.err.description.length > 0) {
        detail = error.data.err.description[0].shortMessage;
      } else if (error.data && error.data.err) {
        detail = error.data.err;
      } else if (error.data && error.data.error && error.data.error.data && error.data.error.data.errors) {
        detail = "";
        error.data.error.data.errors.forEach((err) => {
          if (
            !err ||
            !err.result ||
            !err.result.CreatePassengerNameRecordRS ||
            !err.result.CreatePassengerNameRecordRS.ApplicationResults
          ) {
            return;
          }
          err.result.CreatePassengerNameRecordRS.ApplicationResults.Warning.forEach((warn) => {
            warn.SystemSpecificResults.forEach((sys) => {
              sys.Message.forEach((mess) => {
                if (mess && mess.content) {
                  if (mess.content.match(/FREQUENT TRAVELER NUMBER CONTAINS INVALID CHARACTERS/i)) {
                    detail += `${this.translateService.instant("NOTIFICATIONS.INVALID_CHAR_CARD")}\n`;
                  } else if (mess.content.match(/NAME DOES NOT MATCH FREQUENT TRAVELER NUMBER OWNER/i)) {
                    detail += `${this.translateService.instant("NOTIFICATIONS.INFO_NOT_MATCH_CARD")}\n`;
                  }
                }
              });
            });
          });
        });
      } else if (error.errors) {
        detail = "";
        error.errors.forEach((err) => {
          if (
            !err ||
            !err.error ||
            !err.error.error ||
            !err.error.error.error ||
            !err.error.error.error.ApplicationResults
          ) {
            return;
          }
          err.error.error.error.ApplicationResults.Warning.forEach((warn) => {
            warn.SystemSpecificResults.forEach((sys) => {
              sys.Message.forEach((mess) => {
                if (mess && mess._t) {
                  if (mess._t.match(/1POSSIBLE DUPLICATE BOOKING/i)) {
                    detail += `${this.translateService.instant("NOTIFICATIONS.DUPLICATE_BOOKING")}\n`;
                  }
                }
              });
            });
          });
        });
      }
    } catch (err) {
      console.error(err);
    }
    if (detail === "") {
      detail = this.translateService.instant("NOTIFICATIONS.AN_ERROR_OCCURED");
    }
    if (detail !== undefined) {
      this.messageService.add({
        severity: "error",
        // summary: 'Modification impossible',
        // detail: `Error ${JSON.stringify(error)}`,
        detail,
        life: 9000,
        sticky,
      });
      return true;
    }
    return false;
  }

  // tslint:enable:max-line-length
}
