import { Injectable } from "@angular/core";

import { map, tap, concatMap } from "rxjs/operators";

import { StorageService } from "./storage.service";
import { of, BehaviorSubject, from, Observable, Subject } from "rxjs";
import { SocietyService } from "./society.service";

export declare interface SessionType {
  token: string;
  userId: string;
  roles: Array<string>;
  societyId?: string;
  refreshToken?: string;
  defaultLanguage?: string;
  dateFormat?: string;
  timeFormat?: string;
}

@Injectable({
  providedIn: "root",
})
export class SessionService<T extends SessionType> {
  private session: T;
  private loggedIn: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private loggedOut: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private $session: BehaviorSubject<T> = new BehaviorSubject<T>(null);

  constructor(
    public readonly storage: StorageService,
    public readonly societyService: SocietyService,
  ) {}

  public load(options?: any): Observable<void> {
    return from(this.storage.getItem("session")).pipe(
      map((session: T) => {
        this.session = session;
        this.$session.next(session);
        this.loggedIn.next({ options: options || {}, session });
      }),
    );
  }

  public open(session: T, options?: any): Observable<void> {
    return this.set(session, options).pipe(
      tap(() => {
        this.$session.next(session);
        this.loggedIn.next({ options: options || {}, session });
      }),
    );
  }

  public set(session: T, options?: any): Observable<any> {
    this.session = session;
    this.$session.next(session);
    return this.storage.removeItem("session").pipe(
      concatMap((val) => {
        if (options && options.rememberMe === true) {
          return this.storage.setItem("session", session);
        } else {
          return of(null);
        }
      }),
    );
    // return this.storage.removeItem('session').pipe(
    //   concatMap((): any => {
    //     return iif(
    //       () => options && (options.rememberMe === true),
    //       this.storage.setItem('session', session),
    //       of('')
    //     );
    //   })
    // );
  }

  public get(): T {
    if (!this.session) {
      return this.session;
    } else {
      return JSON.parse(JSON.stringify(this.session));
    }
  }

  public close(): Observable<any> {
    this.session = null;
    this.$session.next(null);

    return this.storage.removeItem("session").pipe(tap(() => this.loggedOut.next(true)));
  }

  public getSession(): Observable<T> {
    return this.$session.asObservable();
  }
  public getLoggedIn(): Observable<any> {
    return this.loggedIn.asObservable();
  }

  public getLoggedOut(): Observable<any> {
    return this.loggedOut.asObservable();
  }
}
