<ng-template #template>
  <div
    [className]="className"
    [ngClass]="{
      disabled: disabled,
      valid: valid
    }"
    class="p-inputgroup-container"
    [style.grid-area]="gridArea"
    (click)="focusOnInput()"
  >
    <span class="reverse" (click)="reverseData()" *ngIf="reverse && className.includes('origin')">
      <img src="./assets/svg/switch.svg" />
    </span>
    <p
      class="p-inputgroup"
      [ngClass]="{
        disabled: disabled,
        valid: valid
      }"
    >
      <img class="p-inputgroup-addon disabled" src="./assets/svg/markup-violet.svg" />
      <span class="label">{{ label }}</span>
      <!--
      Le blur "différé" est nécessaire pour que la sléection d'un des items de la liste puisse se faire.
      Sans ça, la liste se masque juste après le blur, et avant le click sur l'item des résultats
    -->
      <input
        #input
        [id]="id"
        [placeholder]="placeholder"
        type="search"
        [disabled]="disabled"
        [(ngModel)]="searchString"
        (input)="onTypingInSearchInput($event)"
        (blur)="hideSuggest()"
        (focus)="onFocus.emit()"
        class="p-inputtext"
        spellcheck="false"
      />
    </p>
  </div>
  <div *ngIf="!showSuggestInPanel || suggestPanelService.active.name === suggestPanelName" class="suggest-panel">
    <ng-container *ngIf="searchResults !== null">
      <ng-container *ngIf="searchResults.length === 0; else results">
        <div class="suggest-list no-result">Aucun résultat trouvé</div>
      </ng-container>
      <ng-template #results>
        <ul class="suggest-list">
          <li class="suggest-item" *ngFor="let suggest of searchResults" (mousedown)="selectLocalityProposal(suggest)">
            <!-- ci-dessus : le 'mousedown' est nécessaire (au lieu du click) pour que "selectPrediction" puisse se déclencher avant le masquage des résultats effectué lors du blur du champ input -->
            <span>
              {{ suggest.label }}
            </span>
            <span *ngIf="suggest.localizedName && suggest.localizedName !== suggest.label">
              ({{ suggest.localizedName }})
            </span>
          </li>
          <li class="hasMoreResults" *ngIf="hasMoreResults">⋮</li>
        </ul>
      </ng-template>
    </ng-container>
  </div>
</ng-template>
<ng-template #placeFinderTemplate>
  <!-- (openEmitter) : hideSuggest(200) -> On met une tempo pour la fermeture pour que le click sur l'élément de la liste proposé puisse de faire si l'utilisateur sélectionne une des suggestions -->
  <spt-place-finder
    #placeFinderComponent
    [className]="className"
    [icon]="icon"
    [valid]="valid"
    (formatted)="formatted($event)"
    (selectTransferType)="selectTransferType($event)"
    [radius]="radius"
    [from]="from"
    [searchType]="searchEngineType"
    [placeholder]="placeholder"
    [gridArea]="{ input: gridArea, results: 'suggest' }"
    [label]="label || 'GLOBAL.LOCALISATION' | translate"
    [disabled]="disabled"
    (onSearch)="hideSuggest()"
    (openEmitter)="$event ? showSuggest() : hideSuggest()"
    (focusEmitter)="onFocus.emit()"
    (inputEmitter)="onPlaceFinderInput($event)"
    [restrictions]="restrictions"
    [suggestions]="suggestions"
    [address]="address"
    [reverse]="reverse"
    (onReverse)="reverseData()"
  ></spt-place-finder>
</ng-template>
