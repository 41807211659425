import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { TrainTypes } from "../../../../../travel/train/train";
import { CommonService } from "../../../../services/common.service";

@Component({
  selector: "spt-travel-seatmap-compartment",
  templateUrl: "./compartment.component.html",
  styleUrls: ["./compartment.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelSeatmapCompartmentComponent implements OnInit {
  elementIcons: Record<string, string> = {
    luggage_rack: "luggage",
    luggage_room: "luggage",
    stairs: "stairs",
    wc: "toilet",
    tech_room: "technique",
  };
  selectedSeats: Record<string, boolean> = {};
  selectedSeatNumber: number = 0;
  isTablet: boolean;
  isFirstCoach = true;
  @Input() passengerNumber: number;
  @Input() seatMapIndex: number;
  @Input() deck: TrainTypes.Deck;
  @Output() maxSeatsReached: EventEmitter<boolean> = new EventEmitter();
  @Output() seatSelected: EventEmitter<Array<string>> = new EventEmitter();
  @HostBinding("style.grid-template-columns") columns: string;
  @HostBinding("style.grid-template-rows") rows: string;
  @HostBinding("class.seat-selected") isSelected: boolean;
  @HostBinding("style.gap") gap: string;
  @HostBinding("style.padding-left") decalPx: string;
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
  }
  scrWidth: number;

  constructor(
    private commonService: CommonService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.isTablet = this.commonService.isTablet;
    if (this.deck.deckGrid.rowSize === 40 && this.deck.deckGrid.columnSize === 8) {
      this.deck.deckGrid.columnSize = 1;
      this.deck.deckGrid.rowSize = 10;
    }
    if (this.commonService.isTablet) {
      let coachWidth = (this.scrWidth / 100) * 65 - 40; // 60% largeur écran -40px padding left+right
      let stretchGap = (coachWidth - this.deck.deckGrid.rowSize * 40) / (this.deck.deckGrid.rowSize - 1); // 10 colonnes = 9 column gap
      this.gap = `15px ${stretchGap}px`;
      this.rows = `repeat(${this.deck.deckGrid.rowSize}, 58px)`;
      this.columns = "0fr 1fr";
    } else {
      if (this.deck.deckGrid.rowSize != 10) {
        let coachWidth = (this.scrWidth / 100) * 65 - 40; // 60% largeur écran -40px padding left+right
        let stretchGap = (coachWidth - this.deck.deckGrid.rowSize * 40) / (this.deck.deckGrid.rowSize - 1); // 10 colonnes = 9 column gap
        this.gap = `15px ${stretchGap}px`;
      }
      this.columns = `repeat(${this.deck.deckGrid.rowSize}, 40px)`;
      if (this.deck.deckGrid.columnSize === 4) {
        this.isFirstCoach = false;
        this.rows = "0fr 1fr 1fr 1fr";
      } else if (this.deck.deckGrid.columnSize === 20) {
        this.rows = "0fr 1fr 1fr 0fr 1fr 1fr";
      } else {
        this.rows = "0fr 1fr 1fr 1fr 1fr 0fr 0fr 1fr 1fr";
      }
    }
  }

  selectSeat(seatNumber: string): void {
    if (this.passengerNumber > 1) {
      if (!this.selectedSeats[seatNumber] && this.selectedSeatNumber === this.passengerNumber) {
        this.maxSeatsReached.emit(true);
      } else {
        this.maxSeatsReached.emit(false);
        this.selectedSeats[seatNumber] = !this.selectedSeats[seatNumber];
        this.selectedSeatNumber = this.selectedSeats[seatNumber]
          ? this.selectedSeatNumber + 1
          : this.selectedSeatNumber - 1;
      }
    } else {
      if (this.selectedSeats[seatNumber]) {
        this.selectedSeats[seatNumber] = !this.selectedSeats[seatNumber];
      } else {
        this.selectedSeats = {};
        this.selectedSeats[seatNumber] = !this.selectedSeats[seatNumber];
      }
    }

    const selectedSeatNumbersValue: Array<string> = Object.entries(this.selectedSeats)
      .filter(([_, value]: [string, boolean]): boolean => value)
      .map(([key]: [string, boolean]): string => key);
    if (selectedSeatNumbersValue.length === this.passengerNumber) {
      this.seatSelected.emit(selectedSeatNumbersValue);
      this.isSelected = true;
    } else {
      this.seatSelected.emit([]);
      this.isSelected = false;
    }
    this.changeDetector.markForCheck();
  }

  lastColonne(): number {
    this.isTablet = this.commonService.isTablet;
    let colonnes = 0;
    for (let deckcell of this.deck.deckGrid.deckCells) {
      if (deckcell.columnRank === 0) {
        if (this.commonService.isTablet) {
          colonnes = colonnes + deckcell.columnStretch;
        }
        colonnes = colonnes + deckcell.rowStretch;
      }
    }
    return colonnes;
  }
}
