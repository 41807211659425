import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { Subscription, concatMap, map } from "rxjs";
import { SettingsUserNotification } from "src/app/@shared/@types/models";
import { MemberSociety, Society } from "src/app/@shared/@types/society";
import { User } from "src/app/@shared/@types/user";
import { AccountService } from "src/app/@shared/services/account.service";
import { CommonService } from "src/app/@shared/services/common.service";
import { RoleService } from "src/app/@shared/services/roles.service";
import { SocietyService } from "src/app/@shared/services/society.service";
import { UserService } from "src/app/@shared/services/user.service";
declare const window: any;

@Component({
  selector: "spt-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  notificationForm: FormGroup;
  private subscriptions: Subscription[] = [];

  user: User;
  public member: MemberSociety;
  role?: string;
  private activatedrouteParamSubscription?: Subscription;
  private society: Society;
  public notifyAllWarning: boolean;
  public bookRequestWarning: boolean;

  public constructor(
    public commonService: CommonService,
    public roleService: RoleService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private messageService: MessageService,
    private userService: UserService,
    private societyService: SocietyService,
    private activatedroute: ActivatedRoute,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit() {
    const societyAndMembers = this.activatedroute.snapshot.data["society_and_members"];
    this.society = societyAndMembers?.society || this.societyService.society.value;

    this.subscriptions.push(
      (this.activatedrouteParamSubscription = this.activatedroute.paramMap.subscribe((params) => {
        const memberId = params.get("memberId");
        let targetUser: User;
        if (memberId) {
          const member: MemberSociety = this.activatedroute.snapshot.data["member"];
          this.member = member;
          targetUser = member?.user;
          this.role = member?.roles[0];
        } else {
          // Si aucun memberId n'est passé en paramètre, on prend l'utilisateur connecté
          targetUser = this.userService.user.value;
          this.role = this.roleService.roles.value;
        }
        if (targetUser) {
          this.initForUser(targetUser);
        } else {
          throw "Impossible de trouver le membre: " + memberId;
        }
      })),
    );
  }

  private initForUser(user: User) {
    this.user = user;
    if (!this.user.settings) {
      this.user.settings = {};
    }
    if (!this.user.settings.mails) {
      this.user.settings.mails = {};
    }

    this.notificationForm = this.formBuilder.group({
      bookrequest: !!this.user.settings.mails.bookrequest || false,
      bookconfirm: !!this.user.settings.mails.bookconfirm || false,
      receipt: !!this.user.settings.mails.receipt || false,
      monthly: !!this.user.settings.mails.monthly || false,
      all: !!this.user.settings.mails.all || false,
    });

    this.notifyAllWarning = !this.user.settings.mails.all;
    this.bookRequestWarning = !this.user.settings.mails.bookrequest;

    this.notificationForm.controls.all.valueChanges.subscribe((value) => {
      this.notifyAllWarning = !value;
    });
    this.notificationForm.controls.bookrequest.valueChanges.subscribe((value) => {
      this.bookRequestWarning = !value;
    });
  }

  public updateNotification(): void {
    // window.OneSignalDeferred = window.OneSignalDeferred || [];
    // window.OneSignalDeferred.push(function (OneSignal) {
    //   OneSignal.User.addTag("validate_b", "1");
    // });
    // window.OneSignalDeferred.push(function (OneSignal) {
    //   OneSignal.User.addTag("b_refused", "0");
    // });
    const notificationOptions: SettingsUserNotification = this.notificationForm.value;

    this.user.settings.mails = notificationOptions;
    this.userService.putUser(this.user._id, this.society._id, this.user).subscribe({
      next: (): void => {
        this.subscriptions.push(
          this.societyService
            .getSociety(this.society._id, { popMember: true }, true)
            // L'appel à .subscribe() est obligatoire pour lancer la requête
            .subscribe(),
        );
        this.messageService.add({
          severity: "success",
          summary: this.translateService.instant("NOTIFICATIONS.ACCOUNT_UPDATED_TITLE"),
          detail: this.translateService.instant("NOTIFICATIONS.ACCOUNT_UPDATED_MESSAGE"),
          life: 30000,
        });
      },
      error: (): void =>
        this.messageService.add({
          severity: "error",
          summary: this.translateService.instant("NOTIFICATIONS.CANT_UPDATE"),
          detail: this.translateService.instant("NOTIFICATIONS.CANT_UPDATE_INFOS"),
        }),
    });
  }
}
