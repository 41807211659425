<div class="block-container">
  <div class="reservations-container" [class.return]="hasDifferentAddress">
    <div class="pickup info">
      <div class="place">
        <div class="type" *ngIf="hasDifferentAddress">Check in</div>

        <div class="address">
          <span class="round"></span> {{ pickupInfo.place | titlecase }} {{ pickupInfo.city | titlecase }}
        </div>
      </div>
      <div class="datetime">
        <spt-basket-date [date]="pickupInfo.date" [time]="pickupInfo.time"> </spt-basket-date>
        <ng-container *ngIf="!hasDifferentAddress">
          <i class="spt-icon-arrow-circle-right arrow-right"></i>
          <spt-basket-date [date]="returnInfo.date" [time]="returnInfo.time"> </spt-basket-date>
        </ng-container>
      </div>
    </div>
    <div class="return info" *ngIf="hasDifferentAddress">
      <div class="place">
        <div class="type">Check out</div>

        <div class="address">
          <span class="round"></span> {{ returnInfo.place | titlecase }} {{ returnInfo.city | titlecase }}
        </div>
      </div>
      <div class="datetime">
        <spt-basket-date [date]="returnInfo.date" [time]="returnInfo.time"> </spt-basket-date>
      </div>
    </div>
  </div>
</div>
