<section class="itinerary-header">
  <div class="icon">
    <i class="spt-icon-train"></i>
  </div>
  <div class="journey" *ngIf="search.data.type === 'Exchange'; else searchData">
    <ng-container *ngFor="let criteria of search.data.exchangeCriteria">
      <div class="origin">
        <div class="name">
          {{ (criteria.outwardJourney || criteria.inwardJourney).departureStation.name }}
        </div>
      </div>
      <i class="arrow spt-icon-arrow-circle-right"></i>
      <div class="destination">
        <div class="name">
          {{ (criteria.outwardJourney || criteria.inwardJourney).arrivalStation.name }}
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template #searchData>
    <div class="journey">
      <div class="origin">
        <div class="name">
          {{ search.data.outwardJourney.departureStation.name }}
        </div>
      </div>
      <i class="arrow spt-icon-arrow-circle-right"></i>
      <div class="destination">
        <div class="name">
          {{ search.data.outwardJourney.arrivalStation.name }}
        </div>
      </div>
    </div>
  </ng-template>
  <div class="step">
    <span class="way" translate>
      {{ isSmallDevice ? "SEARCH.SHORT." : "SEARCH.TRAIN." }}{{ search.data.type | uppercase }}
    </span>
    <span class="confirmation" translate>GLOBAL.CONFIRMATION</span>
  </div>
  <div class="travel-class">
    <i class="spt-icon-tag m-r-5"></i>
    {{ "SEARCH.RESULT.RAIL.FLEXIBILITY." + flexibility | uppercase | translate }}
  </div>
</section>
<section class="journeys-container">
  <div
    class="journeys"
    *ngFor="let journey of mappedJourneys; let i = index; let isFirst = first; let isLast = last"
    [class.m-t-15]="isFirst"
    [ngClass]="journey.direction"
  >
    <div class="direction">
      {{ "GLOBAL." + journey.direction | uppercase | translate }}
    </div>
    <section *ngIf="journey.segments.length > 0; else openReturn" class="journey">
      <div class="date">
        <span class="day">{{ journey.departure.date.utc | date: "dd" }}</span>
        <span class="month">{{ journey.departure.date.utc | localizedDate: "MMM" }}</span>
      </div>
      <div class="legs">
        <spt-travel-train-segments
          class="detailed"
          [segments]="journey.segments"
          [detailed]="true"
          [isSmallDevice]="isSmallDevice"
          [offerCarbonOffset]="journey.carbonOffset"
        ></spt-travel-train-segments>
      </div>
      <div class="fares" *ngIf="fareTypes[journey.direction]; else noFareType">
        <div class="fare-title" *ngFor="let fareType of fareTypes[journey.direction]">
          <p class="type">{{ fareType.fareName }} x {{ fareType.passengers.length }}</p>
          <ng-container *ngIf="fareType.price">
            <p
              class="price"
              *ngIf="
                !fareType.fareName.endsWith('Return') ||
                  (fareType.fareName.endsWith('Return') && journey.direction === 'outward');
                else returnFare
              "
            >
              {{
                {
                  amount: fareType.price.amount,
                  currency: fareType.price.currency
                }
                  | SPTPrice
                  | async
              }}
            </p>
            <ng-template #returnFare>
              <p class="price">
                {{
                  {
                    amount: 0,
                    currency: fareType.price.currency
                  }
                    | SPTPrice
                    | async
                }}
              </p>
            </ng-template>
            <div class="see-conditions" (mousedown)="toggleFare(fareType)">
              {{ "SEARCH.RESULT.RAIL.SEE_CONDITIONS" | translate }}
            </div>
          </ng-container>
        </div>
      </div>
      <ng-template #noFareType>
        <div class="fares">
          <div class="fare-title">
            <p class="type">
              {{ "SEARCH.RESULT.RAIL.SEE_PREVIOUS_CONDITIONS" | translate }}
            </p>
          </div>
        </div>
      </ng-template>
    </section>
    <ng-template #openReturn>
      <section class="open-return-journey">
        {{ "GLOBAL.CLASS.RETURN" | translate }}
        {{ "SEARCH.RESULT.RAIL.OPEN_RETURN" | translate }}
        {{ fareTypes[journey.id][fareTypes[journey.id].length - 1].validUntil.inward | localizedDate: "short" }}
      </section>
    </ng-template>
  </div>
</section>
<!--<section class="travel-cards" *ngIf="showTravelCard">-->
<!--    <p class="title" translate>SEARCH.TRAIN.TRAVEL_CARDS</p>-->
<!--    <div *ngIf="buyTravelCard; else travelCardButton">-->
<!--        <spt-travel-train-travel-cards [itineraryId]="itinerary.id"-->
<!--            (addTravelCard)="addTravelCards($event.searchId, $event.alternatives)"></spt-travel-train-travel-cards>-->
<!--    </div>-->
<!--    <ng-template #travelCardButton>-->
<!--        <div class="ancillaries" *ngFor="let ancillary of ancillaries">-->
<!--            <span>{{ ancillary.type }}</span>-->
<!--            <span>-->
<!--                <b>-->
<!--                    {{ ancillary.price.amount | currency-->
<!--                        : ancillary.price.currencyCode-->
<!--                        : 'symbol'-->
<!--                        : '1.2-2'-->
<!--                        : locale }}-->
<!--                </b>-->
<!--            </span>-->
<!--        </div>-->
<!--        <ng-container *ngIf="ancillariesDeliveryOptions">-->
<!--          <div *ngFor="let section of itinerary.sections">-->
<!--            <p-dropdown [options]="ancillariesDeliveryOptions[section.id]" appendTo="body"-->
<!--                        [placeholder]="'LABELS.CHOOSE' | translate"-->
<!--                        (onChange)="setDeliveryOption(section.id, $event.value, 'ancillary')"-->
<!--                        [disabled]="validatingDeliveryOption"-->
<!--                        [(ngModel)]="selectedAncillaryDeliveryOption"-->
<!--                        *ngIf="ancillariesDeliveryOptions[section.id]"></p-dropdown>-->
<!--          </div>-->
<!--        </ng-container>-->
<!--        <div class="text-center">-->
<!--            <p-button (onClick)="buyTravelCard = true" [label]="'SEARCH.RESULT.RAIL.BUY_TRAVEL_CARDS' | translate"-->
<!--                styleClass="p-button-secondary medium"></p-button>-->
<!--        </div>-->
<!--    </ng-template>-->
<!--    <p *ngIf="validatingTravelCards" translate>-->
<!--        GLOBAL.VALIDATION-->
<!--    </p>-->
<!--</section>-->

<section class="passengers-price">
  <div *ngIf="users.length > 0" class="passengers">
    <p class="travelers" translate>GLOBAL.TRAVELERS</p>
    <ng-container *ngFor="let user of users">
      <spt-avatar
        [user]="user"
        class="avatar"
        [displayTravelerType]="true"
        title="{{ user.firstname }} {{ user.lastname }}"
      ></spt-avatar>
      <!--      <p class="cards" *ngIf="user.appliedDiscounts?.length">-->
      <!--        <span translate>SEARCH.DISCOUNT</span>-->
      <!--        <span *ngFor="let discountCard of user.appliedDiscounts">-->
      <!--              {{ discountCard.discountCode }}-->
      <!--          </span>-->
      <!--      </p>-->
    </ng-container>
  </div>

  <div class="price">
    <p class="total" translate>SEARCH.RESULT.TOTAL</p>
    <p class="amount">
      {{ price | SPTPrice | async }}
    </p>
  </div>
</section>

<div class="separator"></div>

<section class="carbon-offset">
  <spt-travel-carbon-offset
    [carbonOffset]="itineraries[0].carbonOffset"
    [currency]="price.currency"
    [locale]="locale"
  ></spt-travel-carbon-offset>
</section>

<spt-conditions-train-dialog
  *ngIf="selectedFare"
  [visible]="!!selectedFare"
  [conditions]="selectedFare.conditions"
  [conditionsSummary]="selectedFare.conditionsSummary"
  [routeRestriction]="selectedFare.routeRestriction"
  [name]="selectedFare.fareName"
  (closeConditions)="selectedFare = undefined"
></spt-conditions-train-dialog>
