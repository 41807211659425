<div class="hotel-detail">
  <div class="hotel-dates-infos">
    <div class="hotel-dates">
      <div class="datein-wrapper">
        <p class="day-number">{{ datein | localizedDate: "dd" }}</p>
        <p class="month">{{ datein | localizedDate: "MMM" }}</p>
      </div>
      <div class="dot-line">
        <div class="dot-empty"></div>
        <div class="line"></div>
        <div class="dot-empty"></div>
      </div>
      <div class="dateout-wrapper">
        <p class="day-number">{{ dateout | localizedDate: "dd" }}</p>
        <p class="month">{{ dateout | localizedDate: "MMM" }}</p>
      </div>
    </div>
    <div class="hotel-infos">
      <p class="hotel-name">{{ item.detail.hotelTitle }}</p>
      <div class="hotel-adress">
        <img src="./assets/svg/map-purple-icon.svg" class="map-purple-icon" />
        <p class="adress">{{ item.detail.hotelAddress }}</p>
      </div>
      <ng-container *ngIf="hotelRank">
        <p class="hotel-stars" [innerHtml]="hotelRank | stars"></p>
      </ng-container>
      <div class="night-customer-container">
        <p class="night-customer-title">{{ "GLOBAL.NIGHTS" | translate }}</p>
        <p class="night-customer-title">{{ "GLOBAL.TRAVELERS" | translate }}</p>
        <div class="night-customer-img-number">
          <i class="spt-icon-moon"></i>
          <p class="night-customer-number">{{ nightNumber }}</p>
        </div>
        <div class="night-customer-img-number">
          <img src="./assets/svg/profile.svg" class="transport-icon" />
          <p class="night-customer-number">{{ item.travelers.length }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="room-type-container" *ngFor="let room of item.formData.rooms; let idx = index">
    <p class="room-suite-title">{{ room.roomcount }} {{ room.RoomDescription }}</p>
    <ng-container *ngIf="dataHotel">
      <p *ngIf="dataHotel?.cancellationPolicy[0]">
        {{ dataHotel.cancellationPolicy[0].cfps[0] | refundText: datein : amount }}
      </p>
      <p *ngIf="!dataHotel?.cancellationPolicy[0]">
        {{ "SEARCH.RESULT.HOTEL.REFUNDS.NO_REFUND" | translate }}
      </p>
    </ng-container>
    <div class="info-room-container">
      <div class="info-room">
        {{ "SEARCH.RESULT.HOTEL.TYPE_BREAKFAST." + (boardType || "RO") | translate }}
      </div>
      <ng-container *ngIf="dataHotel">
        <div class="info-room">{{ "SEARCH.RESULT.HOTEL.FILTERS.CANCELLABLE" | translate }}</div>
        <ng-container *ngIf="dataHotel?.cancellationPolicy[0]?.cfps[0]">
          <div class="info-room">{{ "GLOBAL.CATEGORY.REFUNDABLE" | translate }}</div>
        </ng-container>
        <ng-container *ngIf="!dataHotel?.cancellationPolicy[0]?.cfps[0]">
          <div class="info-room">{{ "SEARCH.RESULT.HOTEL.REFUNDS.NO_REFUND" | translate }}</div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="leaflet-map" #leafletMap></div>
  <a [href]="coordinates" target="_system" class="map-link">
    <i>{{
      (commonService.isTabletObservable | async) === true
        ? ("RESERVATIONS.HOTEL.OPEN_MAPS_APP" | translate)
        : ("RESERVATIONS.HOTEL.OPEN_MAPS_DESKTOP" | translate)
    }}</i>
  </a>
</div>
