import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { map, tap } from "rxjs/operators";

declare const cordova: any;
declare const device: any;
interface File {
  url: string;
  name?: string;
}
@Injectable({
  providedIn: "root",
})
export class DocumentService {
  constructor(private httpClient: HttpClient) {}

  get(documentId: string, type: "cordova" | "mobile" | "web", download: boolean, revokeUrl: boolean): Observable<File> {
    const endPoint: string = `${environment.api}/documents/${documentId}`;
    const options: any = { headers: { ignoreErrorMessage: "true" } };
    return this.httpClient.get(endPoint, options).pipe(
      map((res: any) => {
        const arraybuffer: ArrayBuffer = Uint8Array.from(res.buffer.data).buffer;
        const blob: Blob = new Blob([arraybuffer], { type: res.contentType });
        const url: string = window.URL.createObjectURL(blob);
        if (type === "cordova") {
          return {
            blob,
            filename: res.contentDisposition.replace(/filename=/, "").replace(/"/g, ""),
            contentType: res.contentType,
          };
        }
        return {
          url,
          name: res.contentDisposition.replace(/filename=/, "").replace(/"/g, ""),
        };
      }),
      tap((data: any) => {
        if (download) {
          if (type === "cordova") {
            this.openFileCordova(data);
          } else if (type === "mobile") {
            const url: string = data.url.replace(/&download=\d/, "");
            window.open(url, "_system");
          } else {
            const link: HTMLAnchorElement = document.createElement("a");
            link.href = data.url;
            link.download = data.name;
            link.click();
            link.remove();
          }
          if (revokeUrl) {
            setTimeout(() => {
              URL.revokeObjectURL(data.url);
            }, 1000);
          }
        }
      }),
    );
  }

  openFileCordova(data: any): void {
    (window as any).requestFileSystem(0, 0, (fs: any) => {
      fs.root.getFile(data.filename, { create: true, exclusive: false }, (fileEntry: any) => {
        fileEntry.createWriter((fileWriter: any) => {
          fileWriter.onwriteend = (): void => {
            let fileUrl: string;
            if (device.platform === "Android") {
              fileUrl = cordova.file.externalCacheDirectory + data.filename;
            } else {
              fileUrl = fileEntry.nativeURL;
            }
            cordova.plugins.fileOpener2.open(fileUrl, data.contentType);
          };
          fileWriter.write(data.blob);
        });
      });
    });
  }
}
