<div class="m-1" *ngIf="car">
  <div class="title-model">
    <svg class="icon" width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.9667 1.375C22.6777 0.55 21.8111 0 20.9444 0H5.05556C4.04444 0 3.32222 0.55 3.03333 1.375L0 9.625V20.625C0 21.45 0.577778 22 1.44444 22H2.88889C3.75556 22 4.33333 21.45 4.33333 20.625V19.25H21.6667V20.625C21.6667 21.45 22.2444 22 23.1111 22H24.5556C25.4222 22 26 21.45 26 20.625V9.625L22.9667 1.375ZM5.05556 15.125C3.9 15.125 2.88889 14.1625 2.88889 13.0625C2.88889 11.9625 3.9 11 5.05556 11C6.21111 11 7.22222 11.9625 7.22222 13.0625C7.22222 14.1625 6.21111 15.125 5.05556 15.125ZM20.9444 15.125C19.7889 15.125 18.7778 14.1625 18.7778 13.0625C18.7778 11.9625 19.7889 11 20.9444 11C22.1 11 23.1111 11.9625 23.1111 13.0625C23.1111 14.1625 22.1 15.125 20.9444 15.125ZM2.88889 8.25L5.05556 2.0625H20.9444L23.1111 8.25H2.88889Z"
        fill="#604FD7"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="-1.10976"
          y1="0.42817"
          x2="45.0149"
          y2="8.93019"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5B86F3" />
          <stop offset="1" stop-color="#FC80E1" />
        </linearGradient>
      </defs>
    </svg>
    <div class="title">Ma location</div>
  </div>
  <spt-car-description [item]="car" [search]="search"></spt-car-description>

  <div class="conditions">
    <p class="section-title">Voyageurs</p>

    <!-- Travelers button -->
    <div class="travelers-button">
      <ng-container *ngIf="travelers.length > 0">
        <ng-container *ngIf="travelers.length < 4; else largeListMembers">
          <spt-avatar
            *ngFor="let member of travelers"
            [user]="member"
            [displayUsername]="travelers.length === 1"
            [ngClass]="{ 'simple-avatar': travelers.length > 1 }"
          ></spt-avatar>
        </ng-container>

        <ng-template #largeListMembers>
          <spt-avatar
            class="simple-avatar"
            *ngFor="let member of travelers.slice(0, 3)"
            [user]="member.user"
            [displayUsername]="false"
          ></spt-avatar>
          <div class="count f-sb">+{{ travelers.length - 3 }}</div>
        </ng-template>
      </ng-container>
    </div>
  </div>

  <div>
    <p class="section-title" translate>CAR.LOCAT_CONDITIONS_LABEL</p>

    <ul class="advantages" *ngIf="car">
      <ng-container *ngFor="let advantage of car.offer.advantages">
        <li class="advantage" *ngIf="advantage.value">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.74759 17.4736C3.888 16.6434 3.20236 15.6503 2.73068 14.5522C2.259 13.4542 2.01072 12.2732 2.00034 11.0782C1.98996 9.88319 2.21767 8.69807 2.6702 7.59201C3.12273 6.48594 3.791 5.48107 4.63604 4.63604C5.48107 3.791 6.48594 3.12273 7.59201 2.6702C8.69807 2.21767 9.88319 1.98996 11.0782 2.00034C12.2732 2.01072 13.4542 2.259 14.5522 2.73068C15.6503 3.20236 16.6434 3.888 17.4736 4.74759C19.113 6.44501 20.0202 8.71843 19.9997 11.0782C19.9792 13.438 19.0326 15.6953 17.364 17.364C15.6953 19.0326 13.438 19.9792 11.0782 19.9997C8.71843 20.0202 6.44501 19.113 4.74759 17.4736ZM3.35817 18.9122C2.30756 17.8975 1.46955 16.6837 0.893055 15.3416C0.316556 13.9996 0.0131073 12.5562 0.000415293 11.0956C-0.0122767 9.63501 0.266042 8.18654 0.819132 6.83468C1.37222 5.48282 2.18901 4.25465 3.22183 3.22183C4.25465 2.18901 5.48282 1.37222 6.83468 0.819132C8.18654 0.266042 9.63501 -0.0122765 11.0956 0.000415515C12.5562 0.0131075 13.9996 0.316556 15.3416 0.893055C16.6837 1.46955 17.8975 2.30756 18.9122 3.35817C20.9159 5.43279 22.0246 8.21142 21.9996 11.0956C21.9745 13.9797 20.8177 16.7387 18.7782 18.7782C16.7387 20.8177 13.9797 21.9745 11.0956 21.9996C8.21142 22.0246 5.43279 20.9159 3.35817 18.9122ZM9.82979 11.8715L14.8617 6.875L16.5 8.525L9.82979 15.125L5.5 10.8257L7.1383 9.1757L9.82979 11.8715Z"
              fill="#604FD7"
            />
          </svg>

          <p translate>SEARCH.CAR.ADVANTAGES.LABELS.{{ advantage.key }}</p>
        </li>
      </ng-container>
    </ul>
    <p class="conditions-hours" translate>CAR.CONDITIONS_HOURS</p>
  </div>

  <div class="conditions">
    <p class="section-title" translate>CAR.CONDITIONS_LABEL</p>

    <p translate>CAR.CONDITIONS_PARAGRAPH</p>
  </div>

  <div class="price">
    <div class="amount">
      {{ car.offer.totalPrice | SPTPrice | async }}
    </div>

    <div class="duration">
      <span translate>GLOBAL.FOR</span> {{ locationDuration }} <span translate>GLOBAL.DAY</span
      >{{ locationDuration > 1 ? "s" : "" }}
    </div>

    <div class="out-of-policy-container">
      <spt-out-of-policy
        [isOutOfPolicy]="car.offer.inPolicy === false"
        [authorizationsByPolicies]="car.offer.authorizationsByPolicies"
        type="car"
      ></spt-out-of-policy>
    </div>
  </div>

  <div class="separator"></div>

  <section class="carbon-offset">
    <spt-travel-carbon-offset [carbonOffset]="car.offer.carbonOffset" [locale]="locale"></spt-travel-carbon-offset>
  </section>
</div>
