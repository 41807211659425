<p-dialog
  #dialog
  appendTo="body"
  [(visible)]="visible"
  [dismissableMask]="true"
  [modal]="true"
  [responsive]="true"
  [draggable]="false"
  [header]="name"
  (onShow)="commonService.isTablet ? dialog.maximize() : undefined"
  (onHide)="onHide()"
>
  <div class="description">
    <ng-container *ngIf="conditions && conditions.length > 0; else summary">
      <ng-container *ngFor="let condition of conditions">
        <p class="condition">
          <b>{{ condition.name }}</b>
        </p>
        <p class="condition">
          {{ condition.description }}
        </p>
      </ng-container>
    </ng-container>
    <ng-template #summary>
      <p class="condition" *ngIf="conditionsSummary">
        {{ conditionsSummary }}
      </p>
    </ng-template>
  </div>
</p-dialog>
