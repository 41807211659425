import { Injectable } from "@angular/core";
import { BehaviorSubject, map, Observable, of } from "rxjs";
import {
  DateTimeSearch,
  Locality,
  SearchCriteria,
  SearchEngineService,
  Travel,
  WhenSearch,
  SearchResult,
  SearchOptions,
  LastSearchItem,
  lastSearches,
  Search,
  TimeRange,
  DateTimeAdjustment,
} from "../search-engine-service";
import { TrainService } from "src/app/travel/train/train.service";
import { SearchService } from "src/app/@shared/services/search.service";
import { TrainTypes } from "src/app/travel/train/train";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { MemberSociety } from "src/app/@shared/@types/society";
import { SocietyService } from "src/app/@shared/services/society.service";

@Injectable({
  providedIn: "root",
})
export class TrainSearchService implements SearchEngineService<TrainLocality, TrainSearchOptions> {
  private warnSearch: BehaviorSubject<boolean> = new BehaviorSubject(false);
  $warnSearch: Observable<boolean> = this.warnSearch.asObservable();

  constructor(
    private httpClient: HttpClient,
    private trainService: TrainService,
    private searchService: SearchService,
    private translateService: TranslateService,
    private societyService: SocietyService,
  ) {}

  getType(): "train" {
    return "train";
  }

  lastSearches(limit: number): Observable<LastSearchItem<TrainLocality>[]> {
    return lastSearches(this.httpClient, this.getType(), limit).pipe(
      map((array) =>
        array.map((search) => {
          return new LastSearchItem(computeLastSearchLabel(search), computeTravelFromSearch(search));
        }),
      ),
    );
  }

  getMaxPassengers(searchCriteria: SearchCriteria<TrainLocality, TrainSearchOptions>): number {
    return Math.min(
      ...searchCriteria.travels.map((travel) =>
        Math.min(this.getMaxPassengersForLocality(travel.origin), this.getMaxPassengersForLocality(travel.destination)),
      ),
    );
  }

  private getMaxPassengersForLocality(locality: TrainLocality): number {
    switch (locality?.country) {
      case "FR":
        return 6;
      case "DE":
        return 5;
    }
    return 9;
  }

  getMinimumNumberOfCharactersForLocalitySearch(): number {
    return 3;
  }

  localitySearch(searchString: string): Observable<TrainLocality[]> {
    if (!searchString || searchString.trim().length < this.getMinimumNumberOfCharactersForLocalitySearch()) {
      throw new Error(
        "Please provide at least " +
          this.getMinimumNumberOfCharactersForLocalitySearch() +
          " characters to launch search.",
      );
    }
    return this.trainService
      .searchStations(searchString)
      .pipe(map((arrayOfJson) => arrayOfJson.map((json) => this.createLocalityFromJson(json))));
  }

  fetchDetailsForLocality(locality: TrainLocality): Observable<TrainLocality> {
    // Pas besoin de détails supplémentaires pour le moment.
    // La localité "Train" a déjà tout ce qu'il faut dès la récupération des résultats de recherche
    return of(locality);
  }

  private getJourneySearchTypeFromDateTimeSearchType(
    type: "DepartsAfter" | "ArrivalBefore",
  ): TrainTypes.JourneySearchType {
    return type === "ArrivalBefore" ? "ArrivesBefore" : type;
  }

  private static DEFAULT_HOUR_WINDOW_TYPE = {
    begin: "06:00",
    end: "16:00",
  };

  private static DATETIME_ADJUSTMENTS: DateTimeAdjustment[] = [
    // Adjust to 06:00-23:59 if no time
    {
      condition: (dateTimeSearch) => dateTimeSearch.time === DateTimeSearch.NO_TIME,
      apply: (dateTimeSearch) => (dateTimeSearch.time = TrainSearchService.DEFAULT_HOUR_WINDOW_TYPE),
    },
    // Si la date de début est antérieur à maintenant, ajoute 15 minutes à l'heure de maintenant
    // Si la fin de la période devient antérieur, un réajustement de la fin est opéré pour être identique au début
    {
      condition: (dateTimeSearch) => dateTimeSearch.getBeginDate().getTime() < Date.now(),
      apply: (dateTimeSearch) => {
        const now = new Date();
        now.setMinutes(now.getMinutes() + 15);
        dateTimeSearch.setDateOnly(now);
        dateTimeSearch.time = TimeRange.hourFromDate(now, dateTimeSearch.getEndDate());
      },
    },
  ];

  private AUTHORIZED_GB_STATION_CODES: string[] = [
    "urn:trainline:public:nloc:at001681",
    "urn:trainline:public:nloc:at001688",
    "urn:trainline:public:nloc:at000932",
    "urn:trainline:public:nloc:at000113",
  ];

  launchSearch(
    searchCriteria: SearchCriteria<TrainLocality, TrainSearchOptions>,
    oldItemId?: string,
  ): Observable<SearchResult> {
    const travel = searchCriteria.mainTravel;

    const origin = travel.origin;
    const destination = travel.destination;

    const inward: DateTimeSearch | undefined = travel.when.inward;

    const outwardDatetime = travel.when.outward.adjust(...TrainSearchService.DATETIME_ADJUSTMENTS);
    const data: TrainTypes.SearchBody = {
      outwardJourney: {
        departureStation: origin.toStation(),
        arrivalStation: destination.toStation(),
        date: outwardDatetime.date,
        hourWindowType: this.getJourneySearchTypeFromDateTimeSearchType(outwardDatetime.type),
        hourWindowTime: outwardDatetime.time.begin,
      },
      type: inward === undefined ? "Single" : "Return",
      userIds: travel.people.map((p) => p.user._id),
    };
    if (inward) {
      const inwardDatetime = inward.adjust(...TrainSearchService.DATETIME_ADJUSTMENTS);
      data.inwardJourney = {
        departureStation: destination.toStation(),
        arrivalStation: origin.toStation(),
        date: inward.date,
        hourWindowType: this.getJourneySearchTypeFromDateTimeSearchType(inward.type),
        hourWindowTime:
          inwardDatetime.date === outwardDatetime.date ? inwardDatetime.time.end : inwardDatetime.time.begin,
      };
    }
    return this.searchService.create(this.getType(), data, this.translateService.currentLang, oldItemId);
  }

  createBlankCriteria(): SearchCriteria<TrainLocality, TrainSearchOptions> {
    return new SearchCriteria<TrainLocality, TrainSearchOptions>(
      new TrainSearchOptions(),
      new Travel<TrainLocality>(
        [],
        new WhenSearch(new DateTimeSearch(), new DateTimeSearch()),
        this.createLocalityFromJson({}),
        this.createLocalityFromJson({}),
      ),
    );
  }

  private createDateTimeSearchFromPreviousSearch(journey: any): DateTimeSearch {
    if (!journey) {
      return undefined;
    }
    const begin = journey.hourWindowTime;
    let end = "23:59";
    if (begin !== "00:00") {
      end = (parseInt(begin.split(":")[0], 10) + 1).toString().padStart(2, "0") + ":59";
    }
    return Object.assign(new DateTimeSearch(), {
      date: journey.date,
      type: journey.hourWindowType === "ArrivesBefore" ? "ArrivalBefore" : journey.hourWindowType,
      time: Object.assign(new TimeRange(), {
        begin: begin,
        end: end,
      }),
    });
  }

  private createMemberSocietyArrayFromIDs(userIDs: string[]): MemberSociety[] {
    return userIDs
      .map((userId) => this.societyService.society.value.members.find((member) => member.user._id === userId))
      .filter((item) => !!item);
  }

  createCriteriaFromPreviousSearch(previousSearch?: any): SearchCriteria<TrainLocality, TrainSearchOptions> {
    if (!previousSearch) {
      return undefined;
    }
    const outwardDate = this.createDateTimeSearchFromPreviousSearch(previousSearch.outwardJourney);
    const inwardDate = this.createDateTimeSearchFromPreviousSearch(previousSearch.inwardJourney);

    // Dans le cas où une recherche a été effectuée à la date du jour, l'heure de début est à heure courante + 15min
    // Quand on souhaite modifier la recherche avec cette heure de début qui n'est pas "ronde", on reprend pour la recherche
    // une heure indéterminée
    if (outwardDate) {
      if (outwardDate.getBeginTimeAsArray()[1] !== 0) {
        outwardDate.time = DateTimeSearch.NO_TIME;
      }
    }
    if (inwardDate) {
      if (inwardDate.getBeginTimeAsArray()[1] !== 0) {
        inwardDate.time = DateTimeSearch.NO_TIME;
      }
    }

    return new SearchCriteria<TrainLocality, TrainSearchOptions>(
      new TrainSearchOptions(),
      new Travel<TrainLocality>(
        this.createMemberSocietyArrayFromIDs(previousSearch.userIds),
        new WhenSearch(outwardDate, inwardDate),
        this.createLocalityFromJson(previousSearch.outwardJourney.arrivalStation),
        this.createLocalityFromJson(previousSearch.outwardJourney.departureStation),
      ),
    );
  }

  searchCriteriaIsValid(searchCriteria: SearchCriteria<TrainLocality, TrainSearchOptions>): boolean {
    if (
      (searchCriteria.travels[0].origin.provider === "Trainline" &&
        searchCriteria.travels[0].origin.country === "GB" &&
        searchCriteria.travels[0].destination.country !== "GB" &&
        !this.AUTHORIZED_GB_STATION_CODES.includes(searchCriteria.travels[0].origin.code)) ||
      (searchCriteria.travels[0].origin.provider === "Trainline" &&
        searchCriteria.travels[0].origin.country !== "GB" &&
        searchCriteria.travels[0].destination.country === "GB" &&
        !this.AUTHORIZED_GB_STATION_CODES.includes(searchCriteria.travels[0].destination.code))
    ) {
      this.warnSearch.next(true);
      return;
    }
    this.warnSearch.next(false);
    return (
      searchCriteria.isValid() &&
      // Le multi-destination n'est pas autorisé sur le train
      searchCriteria.travels.length === 1 &&
      searchCriteria.checkOriginIsDefined() &&
      searchCriteria.checkPeopleAreSameOnAllTravels()
    );
  }

  createDummyLocalityFromName(name: string): TrainLocality {
    return this.createLocalityFromJson({ name: name });
  }

  createLocalityFromJson(json: any): TrainLocality {
    if (!json) {
      return undefined;
    }
    return Object.assign(new TrainLocality(), json);
  }

  createCriteriaFromBasketItem(
    lastFolderItemsInBasket: any[],
    members: MemberSociety[],
  ): SearchCriteria<TrainLocality, TrainSearchOptions> {
    const basketItem = lastFolderItemsInBasket?.[0];
    if (!basketItem) {
      return undefined;
    }

    const outward: TrainTypes.PlaceInTime = basketItem.detail.journeys.find(
      (journey) => journey.direction === "outward",
    )?.departure;
    const inward: TrainTypes.PlaceInTime = basketItem.detail.journeys.find(
      (journey) => journey.direction === "inward",
    )?.departure;

    return new SearchCriteria<TrainLocality, TrainSearchOptions>(
      new TrainSearchOptions(),
      new Travel<TrainLocality>(
        basketItem?.travelers
          .map((traveler) => members.find((member) => member.user?._id === traveler.userId))
          .filter((member) => !!member),
        new WhenSearch(
          !outward
            ? undefined
            : new DateTimeSearch(outward.date.date, TimeRange.oneHour(parseInt(outward.date.time.split(":")[0], 10))),
          !inward
            ? undefined
            : new DateTimeSearch(inward.date.date, TimeRange.oneHour(parseInt(inward.date.time.split(":")[0], 10))),
        ),
        this.createLocalityFromJson(outward),
        this.createLocalityFromJson(inward),
      ),
    );
  }
}

export class TrainLocality implements Locality {
  locationId: string;
  label: string;
  localizedName: string;
  provider: "Trainline" | "Sabre";
  country: string;
  code: string;
  city: string;
  locationType: string;
  latitude: number;
  longitude: number;

  get name(): string {
    return this.label;
  }

  set name(value: string) {
    this.label = value;
  }

  isValid(): boolean {
    return (
      this.label?.length > 0 &&
      this.locationId?.length > 0 &&
      this.localizedName?.length > 0 &&
      !isNaN(this.latitude) &&
      !isNaN(this.longitude) &&
      this.country?.length > 0
    );
  }

  toStation(): TrainTypes.Station {
    return {
      name: this.name,
      code: this.code,
      locationType: this.locationType,
      city: this.city,
      locationId: this.locationId,
      latitude: this.latitude,
      longitude: this.longitude,
      country: this.country,
      localizedName: this.localizedName,
    };
  }
}

export class TrainSearchOptions implements SearchOptions {
  isValid(): boolean {
    return true;
  }
}

function computeLastSearchLabel(search: Search) {
  const journey = search.data.outwardJourney;
  return journey?.departureStation?.name + " - " + journey?.arrivalStation?.name;
}

function computeTravelFromSearch(search: Search): Travel<TrainLocality> {
  return new Travel(
    [],
    new WhenSearch(new DateTimeSearch(), new DateTimeSearch()),
    toTrainStation(search.data.outwardJourney?.arrivalStation),
    toTrainStation(search.data.outwardJourney?.departureStation),
  );
}

function toTrainStation(data: any) {
  if (!data) {
    return undefined;
  }
  return Object.assign(new TrainLocality(), data);
}
