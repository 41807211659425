import { Component, OnDestroy, OnInit, ViewEncapsulation, Output, EventEmitter, Input } from "@angular/core";
import { TagService } from "src/app/@shared/services/tag.service";
import { SessionService } from "src/app/@shared/services/session.service";
import { CommonService } from "src/app/@shared/services/common.service";

@Component({
  selector: "spt-tags",
  styleUrls: ["./tags.component.scss"],
  templateUrl: "./tags.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class TagsComponent implements OnInit, OnDestroy {
  @Input() bookingData: any;
  @Output() tagsAdded: EventEmitter<Array<string>> = new EventEmitter();

  public suggestions: Array<any>;
  public texts: Array<any>;
  public query: string;
  private tags: Array<any>;
  private typedTags: string;

  constructor(
    private tagService: TagService,
    private sessionService: SessionService<any>,
    public commonService: CommonService,
  ) {}

  ngOnInit(): void {
    this.typedTags = "";
    this.texts = [];
    let societyId: string;
    if (this.bookingData) {
      this.texts = this.bookingData.tagIds;
    }
    societyId = this.sessionService.get().societyId;
    this.tagService.getTags().subscribe((tags: Object) => (this.tags = Object.values(tags)));
    this.tagsAdded.emit(this.texts);
  }

  public addTag(event: KeyboardEvent): void {
    if (!this.texts) {
      this.texts = [];
    }
    const tokenInput: any = event.srcElement as any;
    if (tokenInput.value) {
      this.texts.push({ label: tokenInput.value });
      tokenInput.value = "";
    }
    this.tagsAdded.emit(this.texts);
  }

  public emitTag(): void {
    this.tagsAdded.emit(this.texts);
  }

  search(event: any): void {
    this.query = event.query;
    if (this.tags) {
      this.suggestions = Object.values(this.tags).filter((data: any) =>
        data && data.label ? data.label.includes(event.query) : false,
      );
    } else {
      this.suggestions = [];
    }
  }

  sendEmited(): void {
    if (this.typedTags.length > 0) {
      if (!this.texts) {
        this.texts = [];
      }
      this.texts.push({ label: this.typedTags });
    }
    this.tagsAdded.emit(this.texts);
  }

  ngOnDestroy(): void {
    this.sendEmited();
  }
}
