import { Pipe, PipeTransform } from "@angular/core";
import { get } from "lodash-es";

@Pipe({
  name: "sptFilter",
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], value: string | Array<string>, keys: string[] | string): any {
    if (!items || !value || !keys) {
      return items;
    }
    return items.filter((item: any) => {
      let _search: string = "";
      if (typeof keys === "string") {
        let _value: any = get(item, keys);
        if (typeof _value === "boolean") {
          _value = _value.toString();
        }
        if (_value && typeof _value === "string") {
          _search += _value;
        }
      } else {
        for (const key of keys) {
          let _value: any = get(item, key);
          if (typeof _value === "boolean") {
            _value = _value.toString();
          }
          if (_value && typeof _value === "string") {
            _search += _value;
          }
        }
      }
      if (typeof value === "string") {
        return _search.search(new RegExp(value, "i")) !== -1;
      } else if (typeof keys === "string" && typeof item[keys] === "string") {
        return value.includes(item[keys]);
      } else {
        return false;
      }
    });
  }
}
