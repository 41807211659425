import { HotelService } from "src/app/@shared/services/hotel.service";
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { UtilsTypes } from "../../../../../@shared/@types/utils";
import { CommonService } from "src/app/@shared/services/common.service";

@Component({
  selector: "spt-travel-hotel-page-availabilities-availability",
  styleUrls: ["./availability.component.scss"],
  templateUrl: "./availability.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class AvailabilityComponent implements OnInit {
  @Output() isSelected = new EventEmitter();
  @Output() outdatedInformations = new EventEmitter();
  @Input() availability;
  @Input() seeOutOfPolicy: boolean;

  public cancellationPolicy: any;
  public contractRemarks: string[];
  public carbonOffset: { amount: number; price: number };
  public inPolicy: boolean;

  constructor(
    private hotelService: HotelService,
    public commonService: CommonService,
  ) {}

  ngOnInit() {
    this.hotelService.getRatesDetails(this.availability.accomtoken, this.availability.sessionId).subscribe(
      (data: {
        cancellationPolicy: any[];
        contractRemarks: string[];
        carbonOffset: { amount: number; price: number };
        price: UtilsTypes.Price;
        inPolicy: boolean;
        accomtoken: string;
      }) => {
        this.cancellationPolicy = data.cancellationPolicy ? data.cancellationPolicy[0] : null;
        this.contractRemarks = data.contractRemarks;
        this.inPolicy = data.inPolicy;
        this.carbonOffset = data.carbonOffset;
        this.availability = {
          ...this.availability,
          price: data.price,
          accomtoken: data.accomtoken,
        };
      },
      (error) => {
        this.cancellationPolicy = null;
        if (error.error && error.error.code === "The session has expired") {
          this.outdatedInformations.emit();
        }
      },
    );
    this.changeImageSrc();
  }

  changeImageSrc() {
    const images = document.querySelectorAll("spt-travel-carbon-offset-info img");
    if (images) {
      images.forEach((image) => {
        image["src"] = "./assets/svg/leaf-green.svg";
      });
    }
  }
  public selectedOffer() {
    this.isSelected.emit(this.availability);
  }
}
