import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { LatLng } from "leaflet";
import * as L from "leaflet";
import "leaflet-routing-machine";
import "lrm-google";
import moment, { Moment } from "moment";
import { CommonService } from "src/app/@shared/services/common.service";
import { TransferService } from "./transfer.service";
import { TransferTypes } from "./transfer";
import { environment } from "../../../environments/environment";
import vehicleTypes from "src/assets/vehicles";
import { UtilsTypes } from "../../@shared/@types/utils";

@Component({
  selector: "spt-travel-transfer",
  templateUrl: "./transfer.component.html",
  styleUrls: ["../travel.component.scss", "./transfer.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelTransferComponent implements OnInit, AfterViewInit {
  @Input() search: {
    id: string;
    data: TransferTypes.SearchBody;
  };
  @Input() itemId: string;
  @Input() seeOutOfPolicy: boolean;
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Output() selectProduct: EventEmitter<{
    product: {
      travelling: TransferTypes.ProductBlock;
      returning?: TransferTypes.ProductBlock;
    };
    provider: string;
    price: UtilsTypes.Price;
    origin: string;
    destination: string;
    type: "outbound" | "inbound";
    pickupSearchType: string;
    dropoffSearchType: string;
    travelling: {
      pickup: TransferTypes.PlaceInTime;
      dropoff: TransferTypes.PlaceInTime;
      vehicles: number;
    };
    returning?: {
      pickup: TransferTypes.PlaceInTime;
      dropoff: TransferTypes.PlaceInTime;
      vehicles: number;
    };
  }> = new EventEmitter();
  @Output() modifySearch: EventEmitter<void> = new EventEmitter();
  @ViewChild("map") mapElement: ElementRef;
  public pickupSearchType: string = "GEO";
  public dropoffSearchType: string = "GEO";
  public display: boolean;
  public vehicleTypes: any = vehicleTypes;
  public searchTransfer: TransferTypes.SearchResult;
  public productsCopy: TransferTypes.SearchResult;
  public selectedProduct: {
    travelling: TransferTypes.ProductBlock;
    returning?: TransferTypes.ProductBlock;
  };
  public productType: string = "travelling";
  public estimatedDropoffDatetime: Moment;
  public isOutbound: boolean;
  private map: L.Map;

  constructor(
    public commonService: CommonService,
    private transferService: TransferService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngAfterViewInit(): void {
    const layer: L.TileLayer = L.tileLayer("http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
      maxZoom: 18,
      attribution: "...",
      detectRetina: true,
      subdomains: ["mt0", "mt1", "mt2", "mt3"],
    });

    // Calculate center of the pickup and drop-off coordinates
    const latPickupRadians: number = (Math.PI * this.search.data.pickup.coordinates[0]) / 180;
    const latDropoffRadians: number = (Math.PI * this.search.data.dropoff.coordinates[0]) / 180;
    const latXTotal: number = Math.cos(latPickupRadians) + Math.cos(latDropoffRadians);
    const latYTotal: number = Math.sin(latPickupRadians) + Math.sin(latDropoffRadians);
    const lngTotal: number = this.search.data.pickup.coordinates[1] + this.search.data.dropoff.coordinates[1];
    const centerLatRadians: number = Math.atan2(latYTotal, latXTotal);
    const centerLatDegrees: number = (centerLatRadians * 180) / Math.PI;
    const centerLngDegrees: number = lngTotal / 2;
    const center: LatLng = new LatLng(centerLatDegrees, centerLngDegrees);

    this.map = L.map(this.mapElement.nativeElement, {
      center,
      zoom: 11,
      layers: [layer],
      zoomControl: true,
      trackResize: true,
    });
    this.map.whenReady(() => {
      if (environment.production && this.search.data.pickup.coordinates && this.search.data.dropoff.coordinates) {
        const routing: any = L.routing.control({
          pointMarkerStyle: {
            color: "#604FD7",
          },
          waypoints: [
            L.latLng(this.search.data.pickup.coordinates[0], this.search.data.pickup.coordinates[1]),
            L.latLng(this.search.data.dropoff.coordinates[0], this.search.data.dropoff.coordinates[1]),
          ],
          lineOptions: {
            styles: [
              { color: "black", opacity: 0.3, weight: 11 },
              { color: "white", opacity: 0.9, weight: 9 },
              { color: "#604FD7", opacity: 1, weight: 3 },
            ],
            extendToWaypoints: true,
            missingRouteTolerance: 1,
          },
          router: new (L.Routing as any).Google(),
          showAlternatives: false,
        });

        this.map.fitBounds(
          [
            [this.search.data.pickup.coordinates[0], this.search.data.pickup.coordinates[1]],
            [this.search.data.dropoff.coordinates[0], this.search.data.dropoff.coordinates[1]],
          ],
          {
            animate: true,
          },
        );

        routing.addTo(this.map);
      } else {
        let pickupMarker: L.Marker;
        let dropoffMarker: L.Marker;

        if (this.search.data.pickup.coordinates) {
          pickupMarker = L.marker(
            {
              lat: this.search.data.pickup.coordinates[0],
              lng: this.search.data.pickup.coordinates[1],
            },
            {
              icon: L.icon({
                iconUrl: "./assets/marker-icon.png",
                iconSize: [50, 50],
                iconAnchor: [25, 50],
              }),
            },
          );
          pickupMarker.addTo(this.map);
        }

        if (this.search.data.dropoff.coordinates) {
          dropoffMarker = L.marker(
            {
              lat: this.search.data.dropoff.coordinates[0],
              lng: this.search.data.dropoff.coordinates[1],
            },
            {
              icon: L.icon({
                iconUrl: "./assets/marker-icon.png",
                iconSize: [50, 50],
                iconAnchor: [25, 50],
              }),
            },
          );
          dropoffMarker.addTo(this.map);
        }
      }

      setTimeout(() => {
        this.map.invalidateSize();
      }, 0);
    });
  }

  select(product: TransferTypes.ProductBlock): void {
    if (!!this.search.data.returning && this.selectedProduct) {
      this.selectedProduct.returning = product;
      this.selectProduct.emit({
        product: this.selectedProduct,
        origin: this.search.data.pickup.name,
        destination: this.search.data.dropoff.name,
        price: {
          amount:
            Number(this.selectedProduct.travelling.pricing.price) +
            Number(this.selectedProduct.returning.pricing.price),
          currency: this.selectedProduct.travelling.pricing.currency as UtilsTypes.Currency,
        },
        type: this.search.data.type,
        provider: "holidaytaxis",
        pickupSearchType: this.pickupSearchType,
        dropoffSearchType: this.dropoffSearchType,
        travelling: {
          pickup: {
            ...this.search.data.pickup,
            date: moment(this.search.data.travelling).format("YYYY-MM-DDTHH:mm:ss"),
          },
          dropoff: {
            ...this.search.data.dropoff,
            date: null,
          },
          vehicles: 1,
        },
        returning: {
          pickup: {
            ...this.search.data.dropoff,
            date: moment(this.search.data.returning).format("YYYY-MM-DDTHH:mm:ss"),
          },
          dropoff: {
            ...this.search.data.pickup,
            date: null,
          },
          vehicles: 1,
        },
      });
    } else {
      this.selectedProduct = {
        travelling: product,
      };

      if (!this.search.data.returning) {
        this.selectProduct.emit({
          product: this.selectedProduct,
          origin: this.search.data.pickup.name,
          destination: this.search.data.dropoff.name,
          price: {
            amount: Number(this.selectedProduct.travelling.pricing.price),
            currency: this.selectedProduct.travelling.pricing.currency as UtilsTypes.Currency,
          },
          type: this.search.data.type,
          provider: "holidaytaxis",
          pickupSearchType: this.pickupSearchType,
          dropoffSearchType: this.dropoffSearchType,
          travelling: {
            pickup: {
              ...this.search.data.pickup,
              date: moment(this.search.data.travelling).format("YYYY-MM-DDTHH:mm:ss"),
            },
            dropoff: {
              ...this.search.data.dropoff,
              date: moment(this.estimatedDropoffDatetime).format("YYYY-MM-DDTHH:mm:ss"),
            },
            vehicles: 1,
          },
        });
      }
    }
  }

  private init(): void {
    this.isOutbound = this.search.data.type === "outbound";
    this.dropoffSearchType = this.search.data.dropoff.type;
    this.pickupSearchType = this.search.data.pickup.type;
    this.getResults();
  }

  private getResults(loadMore?: boolean): void {
    this.loading.emit(true);
    this.transferService.getSearchResults(this.search.id).subscribe({
      next: (res: TransferTypes.SearchResult) => {
        if (res && res.travelling) {
          if (loadMore) {
            this.productsCopy.travelling.products.push(
              ...res.travelling.products.filter((_product: TransferTypes.ProductBlock) => {
                return (
                  _product.general.minpax <= this.search.data.userIds.length &&
                  _product.general.maxpax >= this.search.data.userIds.length
                );
              }),
            );

            if (res.returning) {
              this.productsCopy.returning.products.push(
                ...res.returning.products.filter((_product: TransferTypes.ProductBlock) => {
                  return (
                    _product.general.minpax <= this.search.data.userIds.length &&
                    _product.general.maxpax >= this.search.data.userIds.length
                  );
                }),
              );
            }
          } else {
            this.productsCopy = {
              travelling: {
                products: res.travelling.products.filter((_product: TransferTypes.ProductBlock) => {
                  return (
                    _product.general.minpax <= this.search.data.userIds.length &&
                    _product.general.maxpax >= this.search.data.userIds.length
                  );
                }),
              },
              returning: {
                products: res.returning
                  ? res.returning.products.filter((_product: TransferTypes.ProductBlock) => {
                      return (
                        _product.general.minpax <= this.search.data.userIds.length &&
                        _product.general.maxpax >= this.search.data.userIds.length
                      );
                    })
                  : undefined,
              },
            };
          }

          this.searchTransfer = {
            travelling: this.productsCopy.travelling,
            returning: this.productsCopy.returning,
          };
        }
      },
      error: () => {
        this.router.navigate(["/"]);
      },
      complete: () => this.loading.emit(false),
    });
  }

  changeSearch(): void {
    this.modifySearch.emit();
  }
}
