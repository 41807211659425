<p class="direction" *ngIf="!isSmallDevice" translate>GLOBAL.DEPARTURE</p>
<div class="oad" *ngIf="(commonService.isTabletObservable | async) === true; else desktopOAD">
  <div class="origin">
    <p class="time">
      {{ selectedOutward.journey.departure.date.time }}
    </p>
    <p class="station">
      {{ selectedOutward.journey.departure.name | titlecase }}
    </p>
  </div>
  <i class="arrow spt-icon-arrow-circle-right txt-12"></i>
  <div class="destination">
    <p class="time">
      {{ selectedOutward.journey.arrival.date.time }}
    </p>
    <p class="station">
      {{ selectedOutward.journey.arrival.name | titlecase }}
    </p>
  </div>
</div>
<ng-template #desktopOAD>
  <div class="origin">
    <p class="time">
      {{ selectedOutward.journey.departure.date.time }}
    </p>
    <p class="station" title="{{ selectedOutward.journey.departure.name | titlecase }}">
      {{ selectedOutward.journey.departure.name | titlecase }}
    </p>
  </div>
  <i class="arrow spt-icon-arrow-circle-right txt-12"></i>
  <div class="destination">
    <p class="time">
      {{ selectedOutward.journey.arrival.date.time }}
    </p>
    <p class="station" title="{{ selectedOutward.journey.arrival.name | titlecase }}">
      {{ selectedOutward.journey.arrival.name | titlecase }}
    </p>
  </div>
</ng-template>
<div class="options" *ngIf="(commonService.isTabletObservable | async) === true; else desktopOptions">
  <div class="type">
    <span class="underline-dashed">
      {{ "SEARCH.RESULT.RAIL.FLEXIBILITY." + selectedOutward.offers[0].flexibility | uppercase | translate }}
    </span>
  </div>
  <div class="class">
    <span class="underline-dashed">
      {{ "SEARCH.TRAIN." + selectedOutward.offers[0].travelClass | uppercase | translate }}
    </span>
  </div>
  <span class="price" *ngIf="!isSmallDevice">
    {{ selectedOutward.offers[0].price | SPTPrice | async }}
  </span>
  <p class="update">
    <p-button
      [label]="'SEARCH.TRAIN.CHANGE_DEPARTURE' | translate"
      *ngIf="isSmallDevice; else modifyButton"
      class="update-btn"
      (onClick)="onSearch()"
    ></p-button>
    <ng-template #modifyButton>
      <p-button
        [label]="'GLOBAL.MODIFY' | translate"
        styleClass="p-button-secondary medium"
        (onClick)="onSearch()"
      ></p-button>
    </ng-template>
  </p>
</div>
<ng-template #desktopOptions>
  <div class="type">
    <span class="underline-dashed">
      {{ "SEARCH.RESULT.RAIL.FLEXIBILITY." + selectedOutward.offers[0].flexibility | uppercase | translate }}
    </span>
  </div>
  <div class="class">
    <span class="underline-dashed">
      {{ "SEARCH.TRAIN." + selectedOutward.offers[0].travelClass | uppercase | translate }}
    </span>
  </div>
  <span class="price" *ngIf="!isSmallDevice">
    {{ selectedOutward.offers[0].price | SPTPrice | async }}
  </span>
  <p class="update">
    <span *ngIf="isSmallDevice; else modifyButton" class="update-btn" (click)="onSearch()">{{
      "SEARCH.TRAIN.CHANGE_DEPARTURE" | translate
    }}</span>
    <ng-template #modifyButton>
      <p-button
        [label]="'GLOBAL.MODIFY' | translate"
        styleClass="p-button-secondary medium"
        (onClick)="onSearch()"
      ></p-button>
    </ng-template>
  </p>
</ng-template>
