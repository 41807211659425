<ng-container *ngFor="let itinerary of this.itemFlight.metadata.itineraries">
  <div>
    <div class="header" *ngFor="let leg of itinerary.legs; let isLast = last">
      <div class="details">
        <p class="address">
          <span>
            <img class="transport-icon" src="./assets/svg/flight-full.svg" />
          </span>
          <span class="title f-sb col">
            <span class="departure">
              {{ leg.departure.airportName ? leg.departure.airportName : leg.departure.airport }}
            </span>
          </span>
          <span class="title f-sb"> => </span>
          <span class="title f-sb col">
            <span class="departure">
              {{ leg.arrival.airportName ? leg.arrival.airportName : leg.arrival.airport }}
            </span>
          </span>
        </p>
      </div>
      <div class="datetimes">
        <p class="dates">
          <ng-container>
            <span class="departure">
              {{ leg.departureDate | localizedDate: "d" }}<br />
              <small class="month">
                {{ (leg.departureDate | localizedDate: "MMM").replace(".", "") }}
              </small>
            </span>
            <!-- <span class="separator"></span> -->
            <!-- <span class="return">
                            {{ leg.arrivalDatetime | localizedDate:'d' }}<br>
                            <small class="month">
                                {{ (leg.arrivalDatetime | localizedDate:'MMM').replace(".", "") }}
                            </small>
                        </span> -->
          </ng-container>
        </p>
        <p class="times">
          <ng-container>
            <span class="schedule departure">
              {{ leg.departureTime.replace(this.reg, "") }}
            </span>
          </ng-container>
        </p>
      </div>
      <span class="separator"></span>
      <div class="datetimes">
        <p class="dates">
          <ng-container>
            <span class="return">
              {{ leg.arrivalDate | localizedDate: "d" }}<br />
              <small class="month">
                {{ (leg.arrivalDate | localizedDate: "MMM").replace(".", "") }}
              </small>
            </span>
          </ng-container>
        </p>
        <p class="times">
          <ng-container>
            <span class="schedule return">
              {{ leg.arrivalTime.replace(this.reg, "") }}
            </span>
          </ng-container>
        </p>
      </div>
    </div>
  </div>
</ng-container>
<p class="travelers" translate>GLOBAL.TRAVELERS</p>
<div class="infos">
  <p class="user">
    <spt-avatar [user]="request.board[0][0]"></spt-avatar>
    <sup class="board" *ngIf="request.board[0].length - 1 > 1"> +{{ request.board[0].length - 1 }} </sup>
  </p>
  <span class="out-politic" *ngIf="this.request?.isOutOfPolicy" translate>GLOBAL.OUT_OF_POLICY</span>
  <p class="price f-sb">
    {{ request.price | currency: "EUR" : "symbol" : "1.2-2" }}
  </p>
</div>
<!--
    <div class="details">
        <p class="address">
            <span>
                <img class="transport-icon" src="./assets/svg/flight-full.svg">
            </span>
            <span class="title f-sb">
                <ng-container *ngFor="let trip of this.itemFlight.metadata.itineraries[0].legs; let i = index">
                    <span [class.departure]="i === 0" [class.return]="i === legsLength - 1 && legsLength > 1"
                          [class.step]="i > 0 && i < legsLength - 1">
                        {{ trip.departure.airport }}
                    </span>
                </ng-container>
            </span>
        </p>
    </div>
    <div class="datetimes">
        <p class="dates">
            <ng-container >
                <span class="departure">
                  {{ leg.departureDatetime | localizedDate:'d' }}<br>
                  <small class="month">
                      {{ (leg.departureDatetime | localizedDate:'MMM').replace(".", "") }}
                  </small>
                </span>
                <span class="separator"></span>
                <span class="return">
                    {{ leg.arrivalDatetime | localizedDate:'d' }}<br>
                    <small class="month">
                        {{ (leg.arrivalDatetime | localizedDate:'MMM').replace(".", "") }}
                    </small>
                </span>
            </ng-container>
        </p>
        <p class="times">
            <ng-container>
                <span [class.departure]="i === 0 && legsLength > 1" [class.return]="i === legsLength - 1 && legsLength > 1"
                    [class.simple]="i === 0 && legsLength === 1" [class.step]="i > 0 && i < legsLength - 1"
                    class="schedule">
                    {{ leg.departureDatetime | localizedDate:(this.sessionService.getSession() | async)?.timeFormat === 12 ? 'h:mm a' : 'H:mm' }}
                    <sup class="adjustment">
                        {{ leg.departureDatetime| dateAdjustement: leg.departureDatetime }}
                    </sup>
                    <br>
                    {{ leg.arrivalDatetime | localizedDate:(this.sessionService.getSession() | async)?.timeFormat === 12 ? 'h:mm a' : 'H:mm' }}
                    <sup class="adjustment">
                        {{ leg.arrivalDatetime | dateAdjustement: leg.departureDatetime }}
                    </sup>
                </span>
            </ng-container>
        </p>
    </div>
</div>
<p class="travelers" translate>GLOBAL.TRAVELERS</p>
<div class="infos">
    <p class="user">
        <spt-avatar [user]="request.board[0][0]"></spt-avatar>
        <sup class="board" *ngIf="request.board[0].length - 1 > 1">
            +{{ request.board[0].length - 1 }}
        </sup>
    </p>
    <p class="price f-sb">
        {{request.price | currency: 'EUR':'symbol':'1.2-2'}}
    </p>
</div> -->

<!--
<ng-container *ngFor="let leg of itineray.legs">
    <div class="details">
        <p class="address">
            <span>
                <img class="transport-icon" src="./assets/svg/flight-full.svg">
            </span>
            <span class="title f-sb">
                <ng-container *ngFor="let trip of this.itemFlight.form.trips; let i = index">
                    <span [class.departure]="i === 0" [class.return]="i === legsLength - 1 && legsLength > 1"
                          [class.step]="i > 0 && i < legsLength - 1">
                        {{ leg.departure.airportName }}
                    </span>
                </ng-container>

            </span>
        </p>

    </div>
</ng-container> -->
