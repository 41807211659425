import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { FlightService } from "../flight.service";
import { AFKLBagagesComponent } from "../afkl-bagages/afkl-bagages.component";
import { ModalService } from "src/app/@shared/services/modal.service";
import { AFKLSeatmapComponent } from "../afkl-seatmap/afkl-seatmap.component";
import { ItemService } from "src/app/@shared/services/item.service";
import { mergeMap } from "rxjs";

@Component({
  selector: "spt-afkl-ancillaries",
  styleUrls: ["./afkl-ancillaries.component.scss"],
  templateUrl: "./afkl-ancillaries.component.html",
  encapsulation: ViewEncapsulation.None
})
export class AFKLAncillariesComponent implements OnInit {
  ancillariesData: any
  protected seatMapUsers: any;
  protected bagUsers: any;
  protected seatSelected: boolean = false;
  protected bagSelected: boolean = false;

  constructor(
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private flightService: FlightService,
    private itemService: ItemService,
    private modalService: ModalService,
  ) {
    this.ancillariesData = this.config.data;
  }

  ngOnInit(): void {
    const { item } = this.ancillariesData;
    if (item?.formData.ancillaries?.length) {
      const bagUsers = item.formData.orderCreateRS?.DataLists.PaxList.Pax[0];
      const seatAncillaries = item.formData.ancillaries.find((ancillary: any) => ancillary.Service.ServiceAssociations.SeatAssignment !== undefined);
      const bagAncillaries = item.formData.ancillaries.find((ancillary: any) => ancillary.Service.ServiceAssociations.ServiceDefinitionRef !== null);
      if (bagAncillaries) {
        const bagServiceDefinition = item.formData.orderCreateRS?.DataLists.ServiceDefinitionList.ServiceDefinition.find((service: any) => {
          return service.ServiceDefinitionID === bagAncillaries.Service.ServiceAssociations.ServiceDefinitionRef.ServiceDefinitionRefID[0];
        })
        this.bagSelected = true;
        bagUsers.bag = {
          label: bagServiceDefinition !== undefined ? bagServiceDefinition[0]?.Name : null,
          price: {
            amount: bagAncillaries.Price.TotalAmount["$t"],
            currency: bagAncillaries.Price.TotalAmount["CurCode"]
          }
        }
      }

      if (seatAncillaries) {
        const seatServiceDefinition = item.formData.orderCreateRS?.DataLists.ServiceDefinitionList.ServiceDefinition.find((service: any) => {
          return service.Name === 'SEAT';
        })
        this.seatSelected = true;
        bagUsers.seat = {
          rowNumber: seatAncillaries.Service.ServiceAssociations.SeatAssignment.Seat.RowNumber,
          ColumnID: seatAncillaries.Service.ServiceAssociations.SeatAssignment.Seat.ColumnID,
          label: seatServiceDefinition !== undefined ? seatServiceDefinition?.Desc?.DescText : null,
          price: {
            amount: seatAncillaries.Price.TotalAmount["$t"],
            currency: seatAncillaries.Price.TotalAmount["CurCode"]
          }
        }
      }
      this.bagUsers = [bagUsers];
      this.seatMapUsers = [bagUsers];
    }
  }

  close() {
    this.dialogRef.close();
  }

  validateAncillaries() {
    this.dialogRef.close(true);
  }

  addLuggage(item: any): void {
    this.flightService
      .getServiceList(item.formData.searchId, "order", item.formData.orderID)
      .subscribe((data2: any) => {
        this.modalService
          .openModal(AFKLBagagesComponent, {
            data: {
              serviceList: data2.data,
              item: item,
            },
            dismissableMask: true,
            showHeader: false,
          })
          .onClose.subscribe((data) => {
            const offerPriceResponse = data;
            if (offerPriceResponse) {
              const offerId = offerPriceResponse?.PricedOffer.OfferID;
              const ancillariesItemsIds = offerPriceResponse?.PricedOffer.OfferItem;
              const bagUsers = item.formData.orderCreateRS?.DataLists.PaxList.Pax;
              const bagItems = data.PricedOffer.OfferItem
              this.bagUsers = bagUsers.map((user: any, index: number) => {
                const serviceDefinition = item.formData.orderCreateRS?.DataLists.ServiceDefinitionList.ServiceDefinition.find((service: any) => {
                  return service.ServiceDefinitionID === bagItems[index].Service.ServiceAssociations.ServiceDefinitionRef.ServiceDefinitionRefID[0];
                })
                this.bagSelected = true;
                user.bag = {
                  label: serviceDefinition !== undefined ? serviceDefinition[0]?.Name : null,
                  price: {
                    amount: data.PricedOffer.OfferItem[index].Price.TotalAmount["$t"],
                    currency: data.PricedOffer.OfferItem[index].Price.TotalAmount["CurCode"]
                  }
                }
                return user;
              })

              this.itemService
                .setItemAncillaries(item.id, offerId, ancillariesItemsIds)
                .subscribe();
            }
          });
      });
  }

  selectSeat(item: any): void {
    this.flightService.getSeatMap(item.formData.searchId, "order", item.formData.orderID).subscribe((response: any) => {
      this.modalService
        .openModal(AFKLSeatmapComponent, {
          data: {
            data: response.data,
            cabinClass: item.detail.trips[0]?.CabinType.CabinTypeCode,
            flightNumber: item.detail.trips[0]?.MarketingCarrierInfo.MarketingCarrierFlightNumberText,
            item: item,
          },
          dismissableMask: true,
          showHeader: false,
        })
        .onClose.subscribe((data) => {
          const offerPriceResponse = data;
          if (offerPriceResponse) {
            const offerId = offerPriceResponse?.PricedOffer.OfferID;
            const ancillariesItemsIds = offerPriceResponse?.PricedOffer.OfferItem;
            const seatMapUsers = item.formData.orderCreateRS?.DataLists.PaxList.Pax;
            const seatItems = data.PricedOffer.OfferItem
            seatMapUsers.map((user: any, index: number) => {
              const serviceDefinition = item.formData.orderCreateRS?.DataLists.ServiceDefinitionList.ServiceDefinition.find((service: any) => {
                return service.ServiceDefinitionID === seatItems[index].Service.ServiceAssociations.SeatAssignment.ServiceDefinitionRefID[0];
              })
              this.seatSelected = true;
              user.seat = {
                rowNumber: seatItems[index].Service.ServiceAssociations.SeatAssignment.Seat.RowNumber,
                ColumnID: seatItems[index].Service.ServiceAssociations.SeatAssignment.Seat.ColumnID,
                label: serviceDefinition !== undefined ? serviceDefinition[0]?.Desc.DescText : null,
                price: {
                  amount: data.PricedOffer.OfferItem[index].Price.TotalAmount["$t"],
                  currency: data.PricedOffer.OfferItem[index].Price.TotalAmount["CurCode"]
                }
              }
            })
            this.itemService
              .setItemAncillaries(item.id, offerId, ancillariesItemsIds)
              .pipe(mergeMap(() => this.seatMapUsers = seatMapUsers))
              .subscribe();
          }
        });
    });
  }
}