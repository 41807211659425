/* tslint:disable:max-line-length */
export default {
  1: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.jpg",
    name: "Taxi standard privé",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. \nVous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.\nLorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. Les prix indiqués sont par véhicule, et le type de véhicule peut varier en fonction de la disponibilité du fournisseur.",
  },
  2: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minibus-minified.jpg",
    name: "Minibus",
    description:
      "Votre transfert professionnel de porte à porte en minibus est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  3: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Navette",
    description:
      "Les navettes sont un moyen de transport rapide, efficace et peu coûteux qui vous permet d'atteindre et de quitter votre logement dans une ville ou dans un pays inconnu(e). Lorsque vous utilisez un service de navette réservée à l'avance, vous êtes immédiatement assuré de parvenir directement à votre destination choisie.  N'oubliez pas qu'il est possible que vous deviez attendre votre navette à l'aéroport car celles-ci fonctionnent selon des horaires. Temps d'attente maximal 1 heure. Les temps de transferts ne sont que des estimations et dépendent de la circulation et du nombre des arrêts effectués.",
  },
  4: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Bateau-taxi",
    description:
      "Le taxi de l'eau est utilisée dans tout Venise pour vous emmener de place en place, c'est une aventure en soi et vous permettra de prendre dans la vie qui vous entoure les voies navigables alors détendez-vous jusqu'à  vous atteindre votre destination. .",
  },
  5: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/limo.jpg",
    name: "Limousine",
    description:
      "La limousine est un service de luxe, qui comprend l'accueil, vous voyagerez dans une limousine haut de gamme. Dans la plupart des aéroports, votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  6: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/helicopter.jpg",
    name: "Hélicoptère",
    description:
      "Voyager en hélicoptère est une expérience de transport grisante qui vous permet de rejoindre votre hôtel en quelques minutes, vous faisant ainsi gagner du temps pour bien profiter de vos vacances. Réserver un hélicoptère est une façon idéale d’éviter les trajets longs et vous permet de rejoindre votre logement en grande pompe.",
  },
  7: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-car.png",
    name: "Superior Taxi",
    description:
      "Si vous souhaitez voyager dans un véhicule plus luxueux sans trop de frais supplémentaires, réservez celui-ci sans tarder. Une Mercedes classe E/D avec la climatisation vous amènera jusqu'à votre logement et vous permettra de démarrer vos vacances avec élégance.",
  },
  8: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-car.png",
    name: "Taxi de luxe",
    description:
      "Cette voiture est idéale pour les hommes d’affaires ou ceux qui souhaitent plus qu’un taxi standard; la Mercedes SEL est une voiture très luxueuse qui vous amènera à votre destination avec la plus grande élégance. Votre chauffeur vous accompagnera jusqu’à votre voiture et vous amènera à votre hébergement pour commencer votre voyage d’un bon pied.",
  },
  9: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxuryminivan.png",
    name: "Superior Minibus",
    description:
      "Pour ceux qui voyagent avec une famille nombreuse ou pour un groupe de plusieurs personnes qui voyagent ensemble, la Chevrolet Starcraft est un choix facile qui ne vous causera aucun problème. Plutôt que d’attendre l’arrivée de plusieurs véhicules à l’aéroport pour atteindre votre destination, la Chevrolet Starcraft offre une option plus confortable et moins stressante pour votre transfert.",
  },
  10: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Cadillac à carrosserie allongée",
    description:
      "Il s'agit d'une voiture à carrosserie allongée, ressemblant à une limousine pour un groupe plus grand qui souhaite voyager en grande pompe. La Cadillac à carrosserie allongée est un autre choix idéal qui ne coûte pas beaucoup plus cher qu'un minibus. Votre chauffeur personnel vous attendra dès votre arrivée et vous escortera jusqu'à votre véhicule pour vous acheminer rapidement et efficacement.",
  },
  11: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/coach.png",
    name: "Autocar",
    description:
      "Votre transfert professionnel de porte à porte en autocar est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  12: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Mercedes blanche",
    description:
      "Pour les occasions spéciales comme les anniversaires ou les mariages, la Mercedes blanche est un véhicule impressionnant qui vous amènera jusqu’à votre destination avec la plus grande élégance.",
  },
  13: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/wheelchair-taxi.jpg",
    name: "Véhicule adapté aux fauteuils roulants",
    description:
      "Ce véhicule est adapté avec un hayon arrière ou une rampe pour fournir à ceux qui utilisent un fauteuil roulant avec un transfert sans tracas. Il n'est pas nécessaire de sortir de votre fauteuil roulant et votre chauffeur vous aidera dans et hors du véhicule si vous en avez besoin. Pour un transfert en toute sécurité et vous sécuriser le mieux possible, il y a aussi des sangles dans le véhicule. (S'il vous plaît noter: ce véhicule ne peut accueillir qu'un seul fauteuil roulant).\nDans la plupart des aéroports, ce service comprend «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  14: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/coach.png",
    name: "Autocar de luxe ",
    description:
      "Le transfert porte-à-porte de Luxe est fourni dans un autocar de haute qualité. Parfait pour un grand groupe de personnes. Dans la plupart des aéroports, ce service comprend l' accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  15: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private-sedan.png",
    name: "Sedan",
    description:
      "Votre transfert professionnel de porte à porte privé est fourni dans une voiture berline confortable et spacieuse. Dans la plupart des aéroports, ce service comprend l’accueil  et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  16: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/mercedes-e-class.png",
    name: "Mercedes",
    description:
      "Cette voiture est idéale pour les voyageurs d’affaires ou pour tous ceux qui désirent plus qu’un taxi standard; La Mercedes est une voiture de luxe confortable qui vous amènera jusqu'à votre destination en grande pompe. Votre transport jusqu’à votre logement sera les prémices d’un excellent voyage.",
  },
  17: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Taxi long",
    description: "6 sièges",
  },
  18: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-car.png",
    name: "Voiture de luxe",
    description:
      "Votre transfert professionnel de porte à porte de luxe et fourni avec une Mercedes Class E/S ou équivalent. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  19: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/helicopter.jpg",
    name: "Navette hélicoptère",
    description: "Après un vol en hélicoptère de 7 minutes, une navette gratuite vous amènera à votre hôtel.",
  },
  24: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/mercedes-e-class.png",
    name: "Mercedes classe E",
    description:
      "Votre transfert professionnel de porte à porte de luxe et fourni avec une Mercedes Class E. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  25: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/mercedes-s-class.png",
    name: "Mercedes classe S Executive sedan",
    description:
      "Cette voiture est idéale pour les voyageurs d’affaires ou pour tous ceux qui désirent plus qu’un taxi standard; La Mercedes classe S executive est une voiture de luxe confortable qui vous amènera jusqu'à votre destination en grande pompe. Vous chauffeur vous escortera à votre voiture et vous amènera jusqu'à votre logement en vous offrant les prémices d’un excellent voyage.",
  },
  26: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Cadillac CTS",
    description:
      "Cette voiture est idéale pour les voyageurs d’affaires ou pour tous ceux qui désirent profiter d'un transfert en première classe jusqu'à leur destination ; la Cadillac CTS est une voiture de luxe qui vous amènera jusqu'à votre destination en grande pompe. Elle contient également un poste de télévision pour vous distraire pendant votre transfert. Vous chauffeur vous escortera à votre voiture et vous amènera jusqu'à votre logement en vous offrant les prémices d’un excellent voyage.",
  },
  33: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Navette Airlink",
    description:
      "Les navettes sont un moyen de transport bon marché, rapide et efficace entre l’aéroport et votre hébergement dans une ville ou un pays que vous ne connaissez pas. En utilisant un service de navette réservé à l’avance, vous avez tout de suite la sécurité de savoir que vous arriverez à votre destination choisie. Il se peut qu’il faille attendre un peu votre navette à l’aéroport puisqu’ils circulent à des heures précises (temps d’attente maximum 1 heure). Les durées de transfert sont estimées et dépendent de la circulation et du nombre d’arrêts en cours de route.",
  },
  34: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/coach.png",
    name: "Grand autocar",
    description:
      "Votre transfert professionnel de porte à porte en Grand Autocar est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  39: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle1min3.jpg",
    name: "Transfert en navette",
    description:
      "Il s'agit d'un service partagé rentable avec d'autres passagers. Les navettes circulent entre l'aéroport et l'hébergement à l'exclusion d' une adresse privée. Vous serez déposé / pris à votre hôtel ou le plus proche point d'accès. Votre temps de transfert est estimé mais peut varier selon le trafic et le nombre d'arrêts effectués. Il peut y avoir une attente à l'aéroport ou un point de ramassage car les bus fonctionnent selon des horaires définis. Pour plus d'informations sur la localisation de votre transfert et pour connaître votre heure de prise en charge, consultez votre bon de réservation.",
  },
  40: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "L'Autriche navette",
    description:
      "Les navettes sont un moyen de transport bon marché, rapide et efficace entre l’aéroport et votre hébergement dans une ville ou un pays que vous ne connaissez pas. En utilisant un service de navette réservé à l’avance, vous avez tout de suite la sécurité de savoir que vous arriverez à votre destination choisie. Il se peut qu’il faille attendre un peu votre navette à l’aéroport puisqu’ils circulent à des heures précises (temps d’attente maximum 1 heure). Les durées de transfert sont estimées et dépendent de la circulation et du nombre d’arrêts en cours de route.",
  },
  41: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Transfert vers station de ski partagé depuis l'aéroport de Genève (départ non programmé)",
    description:
      "Il s'agit d'un service partagé rentable avec d'autres passagers. Les navettes circulent entre l'aéroport et l'hébergement à l'exclusion d' une adresse privée. Vous serez déposé / pris à votre hôtel ou le plus proche point d'accès. Votre temps de transfert est estimé mais peut varier selon le trafic et le nombre d'arrêts effectués. Pour plus d'informations sur la localisation de votre transfert et pour connaître votre heure de prise en charge, consultez votre bon de réservation. Il peut y avoir une attente à l'aéroport ou un point de ramassage car les bus fonctionnent selon des horaires définis. (temps d'attente maximal de 1,5 heure)",
  },
  42: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Italie navette",
    description:
      "Les navettes sont un moyen de transport bon marché, rapide et efficace entre l’aéroport et votre hébergement dans une ville ou un pays que vous ne connaissez pas. En utilisant un service de navette réservé à l’avance, vous avez tout de suite la sécurité de savoir que vous arriverez à votre destination choisie. Il se peut qu’il faille attendre un peu votre navette à l’aéroport puisqu’ils circulent à des heures précises (temps d’attente maximum 1 heure). Les durées de transfert sont estimées et dépendent de la circulation et du nombre d’arrêts en cours de route.",
  },
  43: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.jpg",
    name: "Transfert privé",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. \nVous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.\nLorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  45: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Minibus",
    description:
      "Votre transfert professionnel de porte à porte pour un large groupe de passagers est parfait pour ceux qui souhaitent voyager ensemble. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.\n",
  },
  46: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Taxi pour stations de ski",
    description:
      "Votre transfert professionnel de porte à porte vers votre station de Ski en taxi. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  47: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Transfert pour joueurs de golf",
    description:
      "Idéal pour le transfert des passagers qui voyagent avec leurs clubs de golf. Réservez à l'avance l'un de nos véhicules plutôt que d'attendre plusieurs taxis. Une manière idéale de parvenir facilement et avec élégance à votre logement. Votre véhicule vous attendra à l’arrivée et vous amènera à votre logement en facilitant la poursuite de votre voyage. Nous pouvons prendre en charge des groupes de toutes tailles.",
  },
  48: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/suv.jpg",
    name: "Véhicule utilitaire sport",
    description:
      "Pour les familles ou les groupes plus larges comptant jusqu'à six personnes qui souhaitent voyager ensemble. Réservez à l'avance l'un de nos véhicules plutôt que d'attendre plusieurs taxis. Une manière idéale de parvenir facilement et avec élégance à votre logement. Votre véhicule vous attendra à l’arrivée et vous amènera à votre logement en facilitant la poursuite de votre voyage. Nous pouvons prendre en charge des groupes de toutes tailles.",
  },
  57: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/water-taxi.jpg",
    name: "Taxi standard/Bateau-taxi",
    description:
      "Ce transfert professionnel privé de service taxi-bateau est unique aux canaux de Venise, vous pourrez jouir du cadre qui vous entoure et vous décontracter pendant votre transfert. Vous serez pris en charge á l'aéroport par une voiture privée et emmené à Piazzale Roma où votre bateau-taxi privé vous attendra. Vous serez déposé / récupéré au point d'accès le plus proche de votre logement. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. Dans la plupart des aéroports, ce service comprend l’accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Pour plus d'informations sur la localisation de votre transfert, consultez votre bon de réservation.",
  },
  72: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/limo.jpg",
    name: "New York limousine longue",
    description:
      "Quoi de plus élégant que d'arriver à votre lieu de d'hébergement en limousine; que ce soit pour un voyage d'affaires, pour se rendre à un événement ou un anniversaire ou encore pour un week-end shopping entre filles. Pour un service soigné, réservez un transfert par limousine. Un chauffeur vous attendra à votre arrivée et vous conduira rapidement à destination. L'espace réservé aux bagages étant limité, un véhicule supplémentaire vous accompagnera. (Ceci est inclus dans le prix du transfert).",
  },
  76: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Silver Spur Bentley",
    description:
      "Cette Bentley Silver Spur est un véhicule de luxe.  Plus spacieuse qu'un taxi standard, elle offre un confort absolu pour tous vos transferts.  Votre chauffeur personnel vous attendra dès votre arrivée et vous escortera jusqu'à votre véhicule pour un transfert rapide et efficace.",
  },
  77: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/_minivan.png",
    name: "Minibus",
    description:
      "Votre transfert de porte-à-porte professionnel en Mini-van est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. ",
  },
  78: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxuryminivan.png",
    name: "Transfert de luxe",
    description:
      "Le transfert de porte-à-porte de Luxe est fourni dans un Mini-Van de haute qualité. Parfait pour un grand groupe de personnes.Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  80: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Grande A / C voiture",
    description:
      "Une grande voiture avec air conditionné pour un trajet confortable et sans stress jusqu’à votre destination",
  },
  81: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-private.png",
    name: "Luxe A / C voiture",
    description:
      "Pour un véhicule qui offre plus d'avantages qu'un taxi standard, la voiture de luxe est plus spacieuse et plus confortable pour votre transfert. Votre chauffeur personnel vous attendra dès votre arrivée et vous escortera jusqu'à votre véhicule pour vous acheminer rapidement et efficacement.",
  },
  82: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/stretch-limo.jpg",
    name: "Longue limousine",
    description:
      "La limousine est un service de luxe, qui comprend l'accueil, vous voyagerez dans une limousine haut de gamme. Dans la plupart des aéroports, votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  102: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minibus-wheelchair.jpg",
    name: "Minibus avec assistance",
    description:
      "Pour une grande famille ou un groupe de cinq personnes ou plus qui souhaitent voyager ensemble. Réservez à l’avance un grand véhicule pour votre groupe plutôt que d’attendre plusieurs taxis. C’est une façon idéale de vous rendre à votre hébergement d’une manière aisée et agréable. Votre véhicule vous attendra à l’arrivée et vous amènera à votre hébergement. La poursuite de votre voyage se fera ainsi sans problème. Des groupes de toute taille peuvent être pris en charge.",
  },
  105: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Grande voiture",
    description:
      "Il s’agit d’une grande voiture, ressemblant à une limousine, pour un groupe important qui souhaite un transfert stylé. Cette grande voiture est une solution idéale qui ne coûte pas beaucoup plus qu’un minibus. Vous serez accueilli par votre chauffeur personnel à l’arrivée et accompagné jusqu’au véhicule, qui vous attend pour un transfert rapide et efficace.",
  },
  106: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/premier.jpg",
    name: "Premier Service",
    description:
      "Votre transfert professionnel de porte à porte Premier est fourni avec une Mercedes ou équivalent. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  107: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-private.png",
    name: "Service Diamond",
    description:
      "Ce service offre un luxe ultime et un début parfait pour des vacances glamour. En plus de cela, vous recevrez une bouteille de champagne français fraîche pour siroter en route pendant que vous vous relaxez dans vos vacances. Ce service comprend «Rencontre et Salutations», votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom.Pour plus d'informations sur la localisation de votre transfert consultez votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  108: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-private.png",
    name: "Grand Prix véhicule de Nice",
    description:
      "Pendant la période extravagante du Grand Prix de Monaco, il devient difficile de se déplacer dans les environs. Votre objectif est d’arriver à votre hébergement aussi rapidement et sereinement que possible. En réservant l’un de nos transferts, vous évitez la longue attente dans les files de taxi. En raison de restrictions routières, vous serez déposé et cherché sur le très central Boulevard de la Princesse Charlotte (au-dessus des jardins du Casino de Monte Carlo). Vous aurez ainsi l’assurance de partir de l’aéroport et d’y arriver avec style",
  },
  109: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxuryminivan.png",
    name: "Transfert privé en Minivan",
    description:
      "Votre transfert de porte-à-porte professionnel en Mini-van est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  123: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Rolls Royce",
    description: "Rolls Royce",
  },
  125: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minibus.png",
    name: "Minibus Affaires",
    description:
      "Votre transfert professionnel de porte à porte Exclusif est fourni dans un Mini-bus de qualité, parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée ",
  },
  143: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-private.png",
    name: "Premier Service (inclut eau)",
    description:
      "Votre transfert professionnel de porte à porte service premier est fourni dans une Mercedes Class S ou équivalent  et comprend une bouteille d'eau à l'arrivée pour vous rafraîchir après votre voyage. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  147: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/suv-jeep.jpg",
    name: "Jeep",
    description:
      "Votre transfert professionnel de porte à porte est fourni dans une Jeep. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  193: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-private.png",
    name: "Diamond Service Paris",
    description:
      "Ce service offre un luxe ultime et un début parfait pour des vacances glamour. En plus de cela, vous recevrez une bouteille de champagne français fraîche pour siroter en route pendant que vous vous relaxez dans vos vacances.  \n Vous voyagerez dans une Audi A8 de luxe, confortable et climatisée, dotée de fenêtres teintées et de garniture cuir. Vous recevrez également une bouteille de champagne pour votre retour à l'aéroport, soit pour vous déguster ensuite, soit, si vous le souhaitez, pour emporter chez vous. ",
  },
  202: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Jaguar XJS",
    description:
      "Ce service offre un luxe ultime et le début parfait pour des vacances glamour. Vous voyagerez dans une luxueuse Jaguar XJS avec de beaux sièges en cuir crème offrant un confort absolu pour votre voyage. Votre chauffeur vous escortera jusqu'à votre voiture et vous emmènera à votre hébergement pour une arrivée sûre de bien démarrer votre voyage.",
  },
  204: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Transfert ski partagé (planifié)",
    description:
      "Il s'agit d'un service partagé rentable avec d'autres passagers. Les navettes circulent entre l'aéroport et l'hébergement à l'exclusion d' une adresse privée. Vous serez déposé / pris à votre hôtel ou le plus proche point d'accès.Votre temps de transfert est estimé mais peut varier selon le trafic et le nombre d'arrêts effectués.Il peut y avoir une attente à l'aéroport ou un point de ramassage car les bus fonctionnent selon des horaires définis. Pour plus d'informations sur la localisation de votre transfert et pour connaître votre heure de prise en charge, consultez votre bon de réservation. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Les horaires énumérés ci-dessous sont approximatifs. Nous vous fournirons les coordonnées de notre bureau à l'étranger pour confirmer les heures de départ précises pour votre retour. Notre navette régulière part de l'aéroport et de la station entre 9h00 et 21h00.",
  },
  226: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/mercedes-s-class.png",
    name: "Mercedes S Class 350",
    description:
      "Votre transfert professionnel de porte à porte de Luxe et fourni avec une Mercedes Class S. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  227: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/mercedes-s-class.png",
    name: "Mercedes S Class 500L",
    description:
      "Cette voiture est idéale pour les hommes d’affaires ou ceux qui souhaitent plus qu’un taxi standard; la Mercedes S classe affaires est une voiture très luxueuse qui vous amènera à votre destination avec la plus grande élégance. Votre chauffeur vous accompagnera jusqu’à votre voiture et vous amènera à votre hébergement pour commencer votre voyage d’un bon pied.",
  },
  228: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/stretch-limo.jpg",
    name: "Limousine Mercedes Stretch",
    description:
      "Cette voiture est idéale pour ceux qui souhaitent plus qu’un taxi standard; la Limousine Mercedes Stretch est une voiture très luxueuse qui vous amènera à votre destination avec la plus grande élégance. Votre chauffeur vous accompagnera jusqu’à votre voiture et vous amènera à votre hébergement pour commencer votre voyage d’un bon pied.",
  },
  232: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Lexus LS 600h hybride",
    description:
      "Si vous souhaitez voyager plus propre et réduire les émissions de carbone, réservez le service Lexus LS600. Utiliser la Lexus vous garantit un transport luxueux et confortable mais ce n'est pas tout. Combinant intelligemment carburant et énergie électrique, la Lexus offre des performances étonnantes tout en réduisant les émissions de carbone et la consommation (émissions de CO2 inférieures à 219g/km).",
  },
  237: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Hummer",
    description:
      "Arrivée à Vegas avec élégance. La Hummer possède un intérieur en cuir et garantit sécurité et confort. Commencez vos vacances par une promenade luxueuse et délicieuse jusqu’à votre hébergement, avec chauffeur.",
  },
  238: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/suv.jpg",
    name: "SUV",
    description:
      "Votre transfert professionnel de porte à porte avec un véhicule utilitaire de sport SUV. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  239: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Minibus (avec transport séparé des bagages)",
    description:
      "Pour une grande famille ou un groupe de cinq personnes ou plus qui souhaitent voyager ensemble. Réservez à l’avance un grand véhicule pour votre groupe plutôt que d’attendre plusieurs taxis. C’est une façon idéale de vous rendre à votre hébergement d’une manière aisée et agréable. Votre véhicule vous attendra à l’arrivée et vous amènera à votre hébergement. La poursuite de votre voyage se fera ainsi sans problème. (Veuillez noter qu’un véhicule distinct pour le transport de vos bagages est inclus dans ce prix.)",
  },
  305: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Transfert en navette à horaires fixes",
    description:
      "Il s'agit d'un service partagé rentable avec d'autres passagers. Les temps de transfert sont approximatifs et dépendent de la circulation et du nombre d'arrêts effectués. Si votre vol atterrit dans les 1 à 1,5 heures de la dernière navette, nous ne pouvons être tenus responsables si vous ne vous connectez pas à la navette à temps. Pour plus d'informations sur la localisation de votre transfert et pour connaître votre heure de prise en charge, consultez votre bon de réservation.",
  },
  449: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Marhaba",
    description:
      "Avec notre service Affaires, vous serez accueilli à la passerelle télescopique, assisté pour passer la douane, et accompagné jusqu’à la zone de livraison des bagages, puis à l’extérieur dans la zone d’arrivée, où vous serez accueilli par notre représentant pour le transfert jusqu’à votre hôtel.",
  },
  450: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-private.png",
    name: "Transfert VIP",
    description:
      "Avec notre service VIP, vous serez accueillis au pont aérien, assistés en douceur par les procédures douanières et accompagnés jusqu'à la récupération des bagages; puis à l'extérieur dans la zone des arrivées où vous serez accueilli par notre représentant pour transfert à votre hôtel. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  451: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Transfert privé avec une visite de la ville",
    description:
      "Au lieu de vous rendre directement à votre hôtel, pourquoi ne pas faire un rapide tour de la ville de Vegas pour vous familiariser avec les endroits les plus branchés, les meilleurs quartiers pour le shopping et les meilleurs restaurants? En réservant ce service, votre chauffeur vous fera faire un tour, ce qui vous permettra de savourer l’atmosphère trépidante, de voir les magnifiques hôtels avant de vous rendre à votre destination. Vue fabuleuse sur MGM Grand, New York New York, Paris, Bellagio, Monte Carlo, Venetian, Wynn et beaucoup d’autres hôtels extraordinaires de Vegas - quelle meilleure façon de commencer vos vacances?!",
  },
  452: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Transfert privé ne comprenant pas le coût du ferry",
    description:
      "A l’arrivée dans votre aéroport, votre but est d’arriver à votre hébergement le plus rapidement possible, avec le moins de problèmes. Pourquoi ne pas arriver avec style en réservant l’un de nos taxis standard. Pas d’attente dans de longues files d’attente aux bornes de taxis ou de transfert fatiguant en autocar, passez la foule et profitez de votre premier verre avant que les autres aient quitté l’aéroport. Veuillez noter que ce transfert ne comprend pas le coût des tickets de ferry.",
  },
  453: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Transfert avec mini-tour de Vegas",
    description:
      "Au lieu de vous rendre directement à votre hôtel, pourquoi ne pas faire un rapide tour de la ville de Vegas pour vous familiariser avec les endroits les plus branchés, les meilleurs quartiers pour le shopping et les meilleurs restaurants? En réservant ce service, votre chauffeur vous fera faire un tour, ce qui vous permettra de savourer l’atmosphère trépidante, de voir les magnifiques hôtels avant de vous rendre à votre destination. Vue fabuleuse sur MGM Grand, New York New York, Paris, Bellagio, Monte Carlo, Venetian, Wynn et beaucoup d’autres hôtels extraordinaires de Vegas - quelle meilleure façon de commencer vos vacances?! Veuillez noter : - Tour de la ville à l’arrivée seulement; le retour à l’aéroport est direct. - Les quartiers visités dépendent de votre lieu d’hébergement et de la circulation. - Véhicule disponible pendant 2 heures max (temps de transfert effectif compris).",
  },
  454: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Londres - Transfert en navette",
    description:
      "Notre service de navettes est pratique en le sens qu’il fonctionne 24h/24, 7j/7, de porte-à-porte, et dessert Central London (le centre de Londres) et les Docklands. Dès que vous êtes enregistré(e), vous partez 15 minutes plus tard. Nous desservons n’importe quel immeuble de bureaux, n’importe quelle adresse résidentielle ainsi que plus de 600 hôtels. Nous desservons véritablement tous les hôtels de notre zone d’activité ; que celui-ci possède 5 chambres ou 500, nous vous amènerons à la porte d’entrée. Notre service s’étend de Hammersmith à l’ouest à Tower Bridge à l’est, de Waterloo et la Tate Modern sur la rive sud à Kings Cross, Paddindton et St Pancras. Il est conçu dans le confort avec de larges sièges en cuir, un espace généreux pour les jambes et, bien sûr, des emplacements prévus pour le stockage de vos bagages. Si vous avez besoin de travailler, aucune inquiétude : nous disposons de l’espace suffisant et des technologies nécessaires lors de vos déplacements. Nous comprenons que la ponctualité est un élément crucial. Ainsi pour vous assurer un voyage rapide et en toute sécurité, nous utilisons les lignes de bus et évitons ainsi les ralentissements de la circulation. Les durées de transfert sont des estimations. Dans la mesure où nous disposons à bord des tout derniers systèmes de navigation, nous ferons de notre mieux pour trouver un nouvel itinéraire afin de contourner les embouteillages éventuels",
  },
  455: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Deltanet minivan",
    description:
      "Réservez à l’avance un transfert pour votre groupe au lieu de faire la queue et d’attendre plusieurs taxis. Profitez de vos vacances dès le début en réservant un transfert privé. Votre transport vous attendra à votre arrive et vous amènera à votre hébergement afin que votre arrivée se fasse sans difficulté. Des groupes de toute taille peuvent être pris en charge. VEUILLEZ NOTER: on vous fournira peut-être 2 voitures au lieu d’un véhicule pour votre transfert, en raison d’une pénurie de minibus sur l’île de Crète/Rhodes.",
  },
  456: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Transfert privé  avec une visite de la ville de Paris",
    description:
      "Une façon parfaite de commencer vos vacances: au lieu de vous diriger directement à votre hôtel, pourquoi ne pas découvrir Paris dans votre propre Citroën 2CV? Votre chauffeur vous attendra à la sortie du train, et vous fera faire un tour de Paris pendant 1 heure et demie, et ce dans une voiture française légendaire. Attendez-vous à de magnifiques vues panoramiques sur la ville grâce au toit ouvrant. Expérience unique garantie! Votre chauffeur vous montrera les plus beaux monuments et quartiers de Paris, vous fera découvrir l'histoire de la ville et de nombreuses petites anecdotes parisiennes au cours du trajet. A la fin du circuit, vous serez amené à votre hôtel. Et vous aurez déjà découvert le meilleur de Paris! La Citroën 2CV peut contenir 2 passagers (avec bagage). Si vous êtes plus de 2, veuillez réserver plusieurs véhicules. Vous pouvez quand même voyager tous ensemble et vous arrêter pour prendre des photos. Si vous avez réservé un transfert pour le retour, celui-ci se fera dans une voiture standard (moderne), directement de votre hébergement jusqu'à la gare.",
  },
  457: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "navette d'hiver",
    description:
      "Il s'agit d'un service partagé rentable avec d'autres passagers. Les navettes circulent entre l'aéroport et l'hébergement à l'exclusion d' une adresse privée. Vous serez déposé / pris à votre hôtel ou le plus proche point d'accès. Votre temps de transfert est estimé mais peut varier selon le trafic et le nombre d'arrêts effectués. Il peut y avoir une attente à l'aéroport ou un point de ramassage car les bus fonctionnent selon des horaires définis. Pour plus d'informations sur la localisation de votre transfert et pour connaître votre heure de prise en charge, consultez votre bon de réservation.",
  },
  458: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Service privé",
    description:
      "Votre  transfert porte-à-porte professionnel privé. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  459: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/wheelchair-taxi.jpg",
    name: "Transfert prive (avec une chaise roulante pliante)",
    description:
      "Voyager avec un fauteuil roulant n'a pas besoin d'être difficile et problématique. Notre voiture vous attendra à l'aéroport et sera suffisamment grande pour contenir une valise par personne ainsi qu'un fauteuil roulant pliable. Veuillez noter que les passagers en fauteuil roulant devront s'asseoir dans le taxi et remonter sur leur fauteuil roulant par leurs propres moyens. A l'arrivée, rendez-vous directement à votre hôtel sans difficultés, sans attente dans de longues files de taxi ou des transferts en car fatigants: partez directement et savourez vos vacances.",
  },
  460: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-private.png",
    name: "Premier Service (avec champagne)",
    description:
      "Pour une durée limitée seulement, ce service comprend en plus du champagne (bouteille de 37,5 cl), alors pourquoi ne pas tirer le maximum de notre offre spéciale et vous faire plaisir? (**Offre non valable au réveillon du Nouvel An et/ou entre le 20 déc. 09 et le 02 janv. 10). Vous bénéficierez ainsi d'un service amélioré, avec un véhicule plus spacieux et donc un confort absolu pendant votre transfert. Vous serez accueilli par votre chauffeur personnel à l'arrivée et accompagné jusqu'au véhicule, qui vous attend pour un transfert rapide et efficace.",
  },
  461: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "ITT conférence transfert privé",
    description: "Transfert privé spécial pour la conférence de l'ITT",
  },
  462: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/water-taxi.jpg",
    name: "Superior Service Taxi d'eau",
    description:
      "Ce transfert en taxi-bateau est un transport de haute qualité et, est aussi unique aux canaux de Venise. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Les bateaux-taxis circulent entre l'aéroport et le point d'embarquement le plus proche de votre hébergement. Vous serez déposé / ramassé à partir du point d’accès le plus proche. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  463: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/water-taxi.jpg",
    name: "Taxi Bateau - transfert privé",
    description:
      "Ce transfert professionnel privé de service taxi-bateau est unique aux canaux de Venise. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Les bateaux-taxis circulent entre l'aéroport et le point d'embarquement le plus proche de votre hébergement. Vous serez déposé / ramassé à partir du point d’accès le plus proche. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  464: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/water-taxi.jpg",
    name: "Service Deluxe bateau-taxi",
    description:
      "Ce transfert professionnel de Luxe de service taxi-bateau est un transport de haute qualité et, est aussi unique aux canaux de Venise. Vous serez accueilli par votre propre chauffeur anglophone à votre arrivée et escorté jusqu'à votre véhicule, où un porteur vous assistera avec vos bagages pour un transfert rapide et efficace. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Les bateaux-taxis circulent entre l'aéroport et le point d'embarquement le plus proche de votre hébergement. Vous serez déposé / ramassé à partir du point d’accès le plus proche. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  465: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Navette prévue (Tremblant / Giobar)",
    description:
      "Les navettes régulières sont un service partagé permettent un transport bon marché, fiable et efficace entre l'aéroport et votre hébergement. Sachez que la navette circule selon un horaire strict et que vous pourrez avoir à attendre votre navette à l’aéroport. Les horaires exacts des navettes seront indiqués sur votre coupon de transfert que vous recevrez une fois que vous avez fait votre réservation. Les durées de transfert sont estimées et dépendent de la circulation et du nombre d’arrêts en cours de route. Si votre vol arrive 1 à 1,5 heure avant la dernière navette, nous déclinons toute responsabilité si vous ne rejoignez pas la navette à temps. Nous pouvons vous contacter afin de changer votre réservation si c’est lundi - dimanche jusqu’au 5 avril 2010::: Départ de YUL: 12h30, 15h30, 18h30, 20h30/ (21h00**) **Du 28 mars au 5 avril, la navette quitte YUL à 21h00 Départ de Mt Tremblant: 09h00, 13h00, 15h00, 18h00 Lundi - Dimanche (6-15 avril 2010):::: Départ de YUL: 14h00, 20h00 Départ de Mt Tremblant 09h00, 16h00 *Horaire susceptible de changer",
  },
  466: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Transfert privé (avec place pour sacs de golf)",
    description:
      "Démarrez vos vacances tranquillement en réservant l’un de nos véhicules qui vous transportera jusqu’à votre hébergement, vous et vos clubs, pour un départ stylé. Avec plein d’espace pour vos clubs, 1 valise et 1 bagage à main par personne. A l'arrivée, rendez-vous directement à votre hôtel sans difficultés, sans attente dans de longues files de taxi ou des transferts en car fatigants: partez directement et savourez le trajet.",
  },
  467: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Transfert en navette à horaires fixes",
    description:
      "Il s'agit d'un service partagé rentable avec d'autres passagers. \nAvis important: La navette descend dans tous les grands hôtels, la liste est longue et vous pouvez vérifier auprès de nous avant de réserver si votre hôtel est celui auquel nous déposons directement. Si vous avez besoin d'un dépôt dans un hôtel où la navette ne va pas directement, vous serez déposé au point le plus proche. Sinon, vous pouvez nous contacter pour organiser un service de transfert privé qui vous emmènera directement à votre hôtel. La navette circule à un horaire régulier et en tant que tel, il peut y avoir une attente pour votre navette à l'aéroport. Les temps de transfert sont approximatifs et dépendent de la circulation et du nombre d'arrêts effectués. Si votre arrivée ou départ de vol est tel qu'il ne se connecte pas à une navette, nous nous réservons le droit de vous contacter pour prendre d'autres arrangements de transfert. Les navettes partagées sont un moyen bon marché, fiable et efficace de se rendre à votre hébergement. Les navettes sont partagées avec les autres passagers.",
  },
  468: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Toyota Prius",
    description:
      "Si vous souhaitez voyager plus propre et réduire les émissions de carbone, réservez le service Toyota Prius. Non seulement la Toyota garantit un voyage confortable et luxueux, mais elle génère aussi moins d’émissions et économise le carburant.",
  },
  469: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Service privé (dans plusieurs mini-fourgonnettes)",
    description:
      "Un moyen rentable et confortable de voyager si vous êtes en groupe. Réservez à l’avance au lieu d’attendre plusieurs taxis à l’aéroport afin d’être certain que vous arriviez à votre hébergement en même temps, avec en plus beaucoup plus de confort!",
  },
  470: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "service partagé",
    description:
      "Un moyen rentable et confortable de voyager jusqu’à votre hébergement. Vous partagerez le véhicule avec d’autres passagers qui se rendent à la même villégiature que vous. Veuillez noter qu’il peut y avoir un délai d’attente à l’aéroport en attendant l’arrivée des autres passagers avec lesquels vous partagez éventuellement la navette (durée d’attente maximale 1 heure, ou 90 minutes pour les transferts au ski). Il est important que nous ayons le numéro de mobile d’un contact pour que nous puissions vous contacter si nécessaire. Veuillez l’indiquer lors de la procédure de réservation. Les durées de transfert sont estimées et peuvent varier selon la circulation et les conditions météo.",
  },
  471: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Transfert privé Paris Banlieue",
    description:
      "Evitez les queues et les transferts en car fatigants, profitez au maximum de vos vacances en réservant un transfert à l’avance. Vous serez accueilli à l’aéroport et emmené directement à votre hébergement sans stress. Sachez que si vous avez réservé un hôtel dans la banlieue de Paris, les zones incluses sont énumérées ci-dessous. Pour toute autre zone, contactez-nous pour un devis. Nous ne pouvons pas fournir des services vers d’autres zones au prix indiqué. Aubervilliers, Bagnolet, Boulogne, Billancourt,Gentilly, Charenton le Pont, Clichy, Courbevoie et Bobigny, Issy les Moulineaux, Ivry Sur Seine, La Défense, Le Kremlin Bicêtre, Les Lilas, Le Pré Saint Gervais, Levallois Perret, Malakoff, Montreuil sous Bois, Montrouge, Nanterre, Neuilly sur Seine, Pantin, Saint Denis, Saint Mandé, Saint Ouen, Sèvres, Vanves Vincennes",
  },
  472: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-private.png",
    name: "Diamond Service Paris (Jumbo)",
    description:
      "\nCe service offre un luxe ultime et le début parfait pour des vacances glamour. Vous voyagerez dans le confort climatisé dans une Citroën C6 noire de luxe avec sellerie en cuir. Vous recevrez également une demi-bouteille de champagne français fraîche servis dans des flûtes à champagne pour siroter en route pendant que vous vous relaxez dans vos vacances. Vous recevrez également une seconde moitié de bouteille de champagne pour votre retour à l'aéroport, soit pour vous savourer ensuite, soit, si vous le souhaitez, pour emporter chez vous.",
  },
  473: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-private.png",
    name: "Transfert Executive",
    description:
      "Le transfert porte-à-porte exclusif est un service supérieur qui est fourni dans une Mercedes Class S ou équivalent . Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  474: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-private.png",
    name: "Transfert V.I.P.",
    description:
      "Voyagez avec style pour arriver à votre hébergement aussi rapidement et aussi sereinement que possible dans un de nos véhicules V.I.P. Pas de longues files d'attente aux bornes de taxis ni de transferts fatigants en autocar, évitez les foules et profitez de votre premier verre avant que les autres aient quitté l'aéroport.",
  },
  475: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/eco.jpg",
    name: "Transfert écologique",
    description:
      "Votre transfert porte-à-porte éco-responsable est un véhicule zéro émission qui est parfait pour un transport confortable et plus vert. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  476: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Transfert privé (Voiture Break)",
    description:
      "Votre transfert professionnel de porte à porte privé en Voiture Break. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.. \n\n",
  },
  477: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxuryminivan.png",
    name: "Monospace privé",
    description:
      "Pour votre transfert porte-à-porte professionnel en véhicule Multi-usages, idéal pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  478: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minibus.png",
    name: "Transfert privé en minibus",
    description:
      "Votre transfert professionnel de porte à porte privé en minibus est idéal pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  479: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minibus.png",
    name: "Golfers Minibus",
    description:
      "Idéal pour le transfert des passagers qui voyagent avec leurs clubs de golf. Réservez à l'avance l'un de nos véhicules plutôt que d'attendre plusieurs taxis. Une manière idéale de parvenir facilement et avec élégance à votre logement. Votre véhicule vous attendra à l’arrivée et vous amènera à votre logement en facilitant la poursuite de votre voyage. Nous pouvons prendre en charge des groupes de toutes tailles.",
  },
  480: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Moutiers navette",
    description:
      "Veuillez noter: vous serez déposé à la station de Moutiers d'où vous pourrez prendre un bus (prix non inclus) pour les stations suivantes St Martin de Belleville, Val Thorens et les Menuires. Les navettes régulières sont un moyen de transport bon marché, rapide et efficace pour vous rendre à votre hébergement. Les navettes sont partagées avec d’autres passagers. Sachez que la navette circule selon un horaire strict et que vous pourrez avoir à attendre votre navette à l’aéroport. Les horaires exacts des navettes seront indiqués sur votre bon de transfert que vous recevrez une fois que vous avez effectué votre réservation. Les durées de transfert sont estimées et dépendent de la circulation et du nombre d’arrêts en cours de route. Si votre vol arrive 1 à 1,5 heure avant la dernière navette, nous déclinons toute responsabilité si vous ne rejoignez pas la navette à temps.",
  },
  481: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Aime navette prévue",
    description:
      "Veuillez noter: vous serez déposé à la station d'Aime d'où vous pourrez prendre un bus (prix non inclus) jusqu'à la station de La Plagne. Les navettes régulières sont un moyen de transport bon marché, rapide et efficace pour vous rendre à votre hébergement. Les navettes sont partagées avec d’autres passagers. Sachez que la navette circule selon un horaire strict et que vous pourrez avoir à attendre votre navette à l’aéroport. Les horaires exacts des navettes seront indiqués sur votre bon de transfert que vous recevrez une fois que vous avez effectué votre réservation. Les durées de transfert sont estimées et dépendent de la circulation et du nombre d’arrêts en cours de route. Si votre vol arrive 1 à 1,5 heure avant la dernière navette, nous déclinons toute responsabilité si vous ne rejoignez pas la navette à temps.",
  },
  482: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Bourg St Maurice Navette prévue",
    description:
      "Veuillez noter: vous serez déposé à la station de Bourg St Maurice d'où vous pourrez prendre un bus (prix non inclus) jusqu'à la station des Arcs. Les navettes régulières sont un moyen de transport bon marché, rapide et efficace pour vous rendre à votre hébergement. Les navettes sont partagées avec d’autres passagers. Sachez que la navette circule selon un horaire strict et que vous pourrez avoir à attendre votre navette à l’aéroport. Les horaires exacts des navettes seront indiqués sur votre bon de transfert que vous recevrez une fois que vous avez effectué votre réservation. Les durées de transfert sont estimées et dépendent de la circulation et du nombre d’arrêts en cours de route. Si votre vol arrive 1 à 1,5 heure avant la dernière navette, nous déclinons toute responsabilité si vous ne rejoignez pas la navette à temps.",
  },
  483: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Navette prévue (Reykjavik / Grayline))",
    description:
      "Les navettes régulières sont un service partagé permettent un transport bon marché, fiable et efficace entre l'aéroport et votre hébergement. Sachez que la navette circule selon un horaire strict et que vous pourrez avoir à attendre votre navette à l’aéroport. Les horaires exacts des navettes seront indiqués sur votre bon de transfert que vous recevrez une fois que vous avez effectué votre réservation. Les durées de transfert sont estimées et dépendent de la circulation et du nombre d’arrêts en cours de route. Si votre vol arrive 1 à 1,5 heure avant la dernière navette, nous déclinons toute responsabilité si vous ne rejoignez pas la navette à temps. Il est possible que nous vous contactions pour modifier votre réservation si tel est le cas. Les navettes partent de l'aéroport de Keflavik (KEF) toutes les 30-40 minutes après chaque arrivée de vol. Les ramassages à l'hôtel se font du lundi au dimanche à 04h35, 05h00, 06h00 (sauf samedi et dimanche), 10h00 (sauf mardi, jeudi, samedi, dimanche), 11h00, 12h30, 13h30. *Les horaires peuvent varier",
  },
  484: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Transfert privé, prix du bateau inclus ",
    description:
      "Votre transfert professionnel de porte à porte privé comprend le billet de ferry pour le passage de / vers votre destination est aussi idéal pour un grand groupe. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. S'il vous plaît noter que ce transfert comprend le coût de votre traversée en ferry, mais vous êtes responsable de vérifier votre horaire de ferry par rapport à votre vol et les horaires de ramassage car ils courent à un horaire restreint.",
  },
  485: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Transfert en navette, prix du bateau inclus",
    description:
      "Il s'agit d'un service partagé rentable avec d'autres passagers.Les navettes circulent entre l'aéroport et l'hébergement à l'exclusion d'une adresse privée. Vous serez déposé / pris à votre hôtel ou le plus proche point d'accès. Votre temps de transfert est estimé mais peut varier selon le trafic et le nombre d'arrêts effectués. Il peut y avoir une attente à l'aéroport ou un point de ramassage car les bus fonctionnent selon un horaire prédéfini. S'il vous plaît noter que ce transfert comprend le coût de votre traversée en ferry, mais vous êtes responsable de vérifier votre horaire de ferry par rapport à votre vol et les horaires de ramassage car ils courent à un horaire restreint. Pour plus d'informations sur la localisation de votre transfert et pour connaître votre heure de prise en charge, consultez votre bon de réservation.",
  },
  486: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Transfert privé (y compris convoi spécial)",
    description:
      "A l'arrivée dans votre aéroport, votre but est d'arriver à votre hébergement le plus rapidement possible, avec le moins de problèmes. Pourquoi ne pas arriver avec style en réservant l'un de nos transferts privés (taxi/minibus). Pas de longues files d'attente aux bornes de taxis ni de transferts fatigants en autocar, évitez les foules et profitez de votre premier verre avant que les autres aient quitté l'aéroport. Veuillez noter que si vous arrivez après 16h00, il est nécessaire de réserver un convoi spécial pour les hôtels d'Hurghada.",
  },
  487: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Transfert  privé (incl. Remorque spéciale pour les vélos)",
    description:
      "Voyager avec un vélo n'a pas besoin d'être difficile et problématique. Notre voiture vous attendra à l'aéroport et dispose d'espace suffisant pour transporter une valise et un vélo. A l'arrivée, rendez-vous directement à votre hôtel sans difficultés, sans attente dans de longues files de taxi ou des transferts en car fatigants: partez directement et savourez vos vacances.",
  },
  488: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Transfert privé (y compris les  vélos dans le porte-bagages)",
    description:
      "Voyager avec un vélo n'a pas besoin d'être difficile et problématique. Notre voiture vous attendra à l'aéroport et dispose d'espace suffisant pour transporter une valise et un vélo. A l'arrivée, rendez-vous directement à votre hôtel sans difficultés, sans attente dans de longues files de taxi ou des transferts en car fatigants: partez directement et savourez vos vacances.",
  },
  489: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Navette prévue (Iceland Excursions)",
    description:
      "Il s'agit d'un service partagé rentable avec d'autres passagers. S'il vous plaît soyez conscient que cette navette fonctionne à un horaire régulier et en tant que tel, il peut y avoir une attente pour votre navette à l'aéroport. Les temps de transfert sont approximatifs et dépendent de la circulation et du nombre d'arrêts effectués. Si votre vol atterrit dans un rayon de 1 à 1,5 heure de la dernière navette, nous ne pouvons être tenus responsables si vous ne vous connectez pas à la navette à temps. Nous pouvons vous contacter afin de modifier votre réservation si c'est le cas. Les navettes partent de l'aéroport KEF de 45 à 60 minutes après l'arrivée de chaque vol. Vous trouverez de plus amples informations sur la localisation de votre transfert et sur l'heure de votre prise en charge sur votre bon de réservation.",
  },
  490: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Navette de famille 3",
    description:
      "Les navettes sont un moyen de transfert partagé et permettent un transport bon marché, rapide et efficace entre l'aéroport et votre hébergement dans un lieu que vous ne connaissez pas. Une navette familiale est une solution rentable pour 2adultes et 1enfant (jusqu’à11 ans). Veuillez noter que vous partagerez votre navette avec d’autres clients. Il est important que nous ayons le numéro de mobile d’un contact pour que nous puissions vous contacter si nécessaire. Veuillez l’indiquer lors de la procédure de réservation. Il se peut qu’il faille attendre un peu votre navette à l’aéroport car elles circulent à des heures précises. Temps d’attente maximum: 1 heure, ou 90 minutes pour les transferts pour séjours de ski. Les durées de transfert sont estimées et dépendent de la circulation et du nombre d’arrêts à effectuer.",
  },
  491: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Navette de famille 4",
    description:
      "Les navettes sont un moyen de transfert partagé et permettent un transport bon marché, rapide et efficace entre l'aéroport et votre hébergement dans un lieu que vous ne connaissez pas. Une navette familiale4 est une solution rentable pour 2adultes et 2enfants (jusqu’à11 ans). Veuillez noter que vous partagerez votre navette avec d’autres clients. Il est important que nous ayons le numéro de mobile d’un contact pour que nous puissions vous contacter si nécessaire. Veuillez l’indiquer lors de la procédure de réservation. Il se peut qu’il faille attendre un peu votre navette à l’aéroport car elles circulent à des heures précises. Temps d’attente maximum: 1 heure, ou 90 minutes pour les transferts pour séjours de ski. Les durées de transfert sont estimées et dépendent de la circulation et du nombre d’arrêts à effectuer.",
  },
  492: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Navette de famille 5",
    description:
      "Les navettes sont un moyen de transfert partagé et permettent un transport bon marché, rapide et efficace entre l'aéroport et votre hébergement dans un lieu que vous ne connaissez pas. Une navette familiale5 est une solution rentable pour 2adultes et 3enfants (jusqu’à11 ans) ou 3adultes et 2enfants (jusqu’à11 ans). Veuillez noter que vous partagerez votre navette avec d’autres clients. Il est important que nous ayons le numéro de mobile d’un contact pour que nous puissions vous contacter si nécessaire. Veuillez l’indiquer lors de la procédure de réservation. Il se peut qu’il faille attendre un peu votre navette à l’aéroport car elles circulent à des heures précises. Temps d’attente maximum: 1 heure, ou 90 minutes pour les transferts pour séjours de ski. Les durées de transfert sont estimées et dépendent de la circulation et du nombre d’arrêts à effectuer.",
  },
  493: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Navette de famille 6",
    description:
      "Les navettes sont un moyen de transfert partagé et permettent un transport bon marché, rapide et efficace entre l'aéroport et votre hébergement dans un lieu que vous ne connaissez pas. Une navette familiale6 est une solution rentable pour 2adultes et 4enfants (jusqu’à11 ans) ou 3adultes et 3enfants (jusqu’à11 ans) ou 4adultes et 2enfants (jusqu’à11 ans). Veuillez noter que vous partagerez votre navette avec d’autres clients. Il est important que nous ayons le numéro de mobile d’un contact pour que nous puissions vous contacter si nécessaire. Veuillez l’indiquer lors de la procédure de réservation. Il se peut qu’il faille attendre un peu votre navette à l’aéroport car elles circulent à des heures précises. Temps d’attente maximum: 1 heure, ou 90 minutes pour les transferts pour séjours de ski. Les durées de transfert sont estimées et dépendent de la circulation et du nombre d’arrêts à effectuer.",
  },
  494: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Samos Service partagé",
    description:
      "Un moyen rentable et confortable de voyager jusqu’à votre hébergement. Vous partagerez le véhicule avec d’autres passagers qui se rendent à la même villégiature que vous. Veuillez noter qu’un délai d’attente d’une heure maximum peut se produire, le temps que les autres passagers, avec lesquels vous partagerez la navette, arrivent. Il nous est donc indispensable d’avoir un numéro de téléphone mobile afin de pouvoir vous contacter le cas échéant. Veuillez l’indiquer lors de la procédure de réservation. Les durées de transfert sont estimées et peuvent varier selon la circulation et les conditions météo.",
  },
  495: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/mercedes-e-class.png",
    name: "Mercedes (Y compris l'eau)",
    description:
      "Votre transfert professionnel de porte à porte de Luxe est fourni dans une Mercedes et comprend une bouteille d'eau à l'arrivée pour vous rafraîchir après votre voyage. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  496: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/water-taxi.jpg",
    name: "Airport Express Central Water Taxi service partagé",
    description:
      "Il s'agit d'un service partagé rentable et rapide qui est unique aux canaux de Venise ; vous voyagerez avec d'autres passagers. Vous trouverez plus d'informations sur la localisation de votre transfert ainsi que l'heure de récupération sur votre bon de réservation. IMPORTANT : Les bateaux s'arrêtent à la Place Saint-Marc et dans le quartier du Rialto, d'où vous pourrez rejoindre votre hôtel/hébergement à pied. Sachez qu'il peut y avoir un délai d'attente à l'aéroport puisque les bateaux partent toutes les 1h30. Il est important que nous ayons un numéro de téléphone portable où vous joindre en cas de nécessité. Veuillez l'inscrire au cours de la procédure de réservation.",
  },
  497: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Airport Express Venice Lido   navette maritime",
    description:
      "Un moyen économique et efficace de vous rendre au Lido de Venise. Vous partagerez un bateau avec d'autres passagers.  IMPORTANT&nbsp;: Les bateaux s'arrêtent au débarcadère du Lido de Venise. Sachez qu'il peut y avoir un délai d'attente à l'aéroport puisque les bateaux circulent à horaires fixes.  Il est important que nous ayons un numéro de téléphone portable où vous joindre en cas de nécessité. Veuillez l'inscrire au cours de la procédure de réservation.",
  },
  498: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Aéroport-Venice Lido standard Taxi / navette maritime",
    description:
      "'Un moyen économique et efficace de vous rendre au Lido de Venise. Un véhicule en provenance de l'aéroport de Trévise pour conduira à l'aéroport Marco Polo de Venise, d'où un bateau partagé vous amènera jusqu'au débarcadère du Lido de Venise.  IMPORTANT&nbsp;: Sachez qu'il peut y avoir un délai d'attente à VCE puisque les bateaux circulent à horaires fixes.  Il est important que nous ayons un numéro de téléphone portable où vous joindre en cas de nécessité. Veuillez l'inscrire au cours de la procédure de réservation.",
  },
  499: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-car.png",
    name: "Taxi Supérieure - Thaïlande (avec un guide)",
    description:
      "Votre transfert professionnel de porte à porte de Luxe et fourni avec une Mercedes Class S ou équivalent. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. Ce service comprend un guide.",
  },
  500: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxuryminivan.png",
    name: "Minivan Supérieure - Thaïlande (avec un guide)",
    description:
      "Votre transfert professionnel de porte à porte Supérieur est fourni avec un Mini-van de grande qualité, idéal pour un large groupe de passagers.Dans la plupart des aéroports, ce service comprend l'accueil  et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.Ce service comprend un guide ",
  },
  501: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "PROMO Transfert en navette",
    description:
      "Les navettes sont un moyen de transport rapide, efficace et peu coûteux qui vous permet d'atteindre et de quitter votre logement dans une ville ou dans un pays inconnu(e). Lorsque vous utilisez un service de navette réservée à l'avance, vous êtes immédiatement assuré de parvenir directement à votre logement.  N'oubliez pas qu'il est possible que vous deviez attendre votre navette à l'aéroport car celles-ci fonctionnent selon des horaires. Temps d'attente maximal 1 heure. Les temps de transferts ne sont que des estimations et dépendent de la circulation et du nombre des arrêts effectués.",
  },
  502: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Skiathos Taxi",
    description:
      "Votre transfert de porte-à-porte en taxi sur l'île de Skiathos. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. S'il vous plaît noter qu'en raison d'une pénurie de taxis sur l'île de Skiathos et / ou Skopelos, vous pouvez partager votre taxi / Mini-bus avec d'autres clients.",
  },
  503: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxuryminivan.png",
    name: "Minibus Premier",
    description:
      "Votre transfert de porte-à-porte de Luxe Premier est fourni avec un véhicule de style mini-van haute qualité, idéal pour un large groupe de passagers.Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  504: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Taxi privé ( incl. La remorque)",
    description:
      "'Voyager avec des bagages supplémentaires ne doit pas être difficile ou problématique. Vous retrouverez à l'aéroport votre voiture équipée d'une remorque pour vos bagages. A votre arrivée, rejoignez directement votre hôtel en toute sérénité, sans passer par les longues files d'attente aux stations de taxis, ni subir des transferts en bus fatigants&nbsp;: allez-y tout de suite et profitez de vos vacances.",
  },
  505: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/eco.jpg",
    name: "Voiture éco",
    description:
      "Votre transfert porte-à-porte éco-responsable est un véhicule zéro émission qui est parfait pour un transport confortable et plus vert. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  506: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Transfert privé à la Piazzale Roma ",
    description:
      "'Dès votre arrivée dans l'aéroport de votre choix, votre but est de rejoindre votre lieu d'hébergement aussi vite que possible et en toute sérénité. Pourquoi ne pas arriver avec style en réservant l'un de nos transferts privés (taxi/minibus). Plus de longues files d'attente aux stations de taxis, ni de transferts en bus fatigants&nbsp;: ayez une longueur d'avance sur les hordes de touristes et profitez de votre premier verre avant que la foule ait quitté l'aéroport.  A noter&nbsp;: Ce transfert ne comprend pas l'utilisation de bateaux taxis. En raison des canaux, si votre hébergement se trouve dans le centre de Venise, le transfert ne vous déposera/prendra pas directement à votre hôtel. En revanche il vous amènera/prendra à la Piazzale Roma.",
  },
  507: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minibus.png",
    name: "Minibus à la Piazzale Roma",
    description:
      "Pour les familles nombreuses ou les groupes de cinq personnes ou plus qui veulent voyager ensemble. Préréservez l'un de nos grands véhicules pour vos groupes au lieu d'attendre plusieurs taxis. C'est un moyen idéal de vous rendre sur votre lieu d'hébergement facilement et avec style. Votre véhicule vous attendra à votre arrivée et vous pourrez rejoindre rapidement votre lieu d'hébergement en toute sérénité. Nous répondons aux besoins des groupes de toutes tailles.   A noter&nbsp;: Ce transfert ne comprend pas l'utilisation de bateaux taxis. En raison des canaux, si votre hébergement se trouve dans le centre de Venise, le transfert ne vous déposera/prendra pas directement à votre hôtel. En revanche il vous amènera/prendra à la Piazzale Roma.",
  },
  508: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/wheelchair-taxi.jpg",
    name: "Minibus adapté aux fauteuils roulants",
    description:
      "Ce véhicule est adapté avec un hayon arrière ou une rampe pour fournir à ceux qui utilisent un fauteuil roulant avec un transfert sans tracas. Il n'est pas nécessaire de sortir de votre fauteuil roulant et votre chauffeur vous aidera dans et hors du véhicule si vous en avez besoin. Pour un transfert en toute sécurité et vous sécuriser le mieux possible, il y a aussi des sangles dans le véhicule. (S'il vous plaît noter: ce véhicule ne peut accueillir qu'un seul fauteuil roulant).\nDans la plupart des aéroports, ce service comprend «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  509: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Navette Alcudia",
    description:
      "'Les navettes offrent un service de transfert partagé et sont un moyen bon marché, rapide et efficace de voyager vers et depuis votre lieu d'hébergement dans un lieu que vous ne connaissez peut-être pas.  Il est important que nous ayons un numéro de téléphone portable où vous joindre en cas de nécessité. Veuillez l'inscrire au cours de la procédure de réservation.  Sachez qu'il peut y avoir un délai d'attente à l'aéroport puisque les navettes circulent à horaires fixes. Délai d'attente maximum d'1 heure ou 90 minutes pour les transferts au ski. Les horaires de transfert sont purement indicatifs et peuvent varier en fonction du trafic et/ou du nombre d'arrêts en cours de route.  A noter&nbsp;: Il se peut que certains hôtels, situés dans la vieille ville d'Alcudia, ne soient pas accessibles en voiture. Dans ce cas, notre prestataire vous déposera le plus près possible de votre destination, mais vous aurez une courte marche à faire.",
  },
  510: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Service de navettes",
    description:
      "Il s'agit d'un service partagé rentable avec d'autres passagers. Les navettes circulent entre l'aéroport et l'hébergement à l'exclusion d' une adresse privée. Vous serez déposé / pris à votre hôtel ou le plus proche point d'accès. Votre temps de transfert est estimé mais peut varier selon le trafic et le nombre d'arrêts effectués. Il peut y avoir une attente à l'aéroport ou un point de ramassage car les bus fonctionnent selon des horaires définis. Pour plus d'informations sur la localisation de votre transfert et pour connaître votre heure de prise en charge, consultez votre bon de réservation.",
  },
  511: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Break",
    description:
      "Notre chauffeur vous retrouvera à l'aéroport à bord d'une voiture ayant suffisamment d'espace pour tous vos bagages supplémentaires. A votre arrivée, rejoignez directement votre hôtel en toute sérénité, sans passer par les longues files d'attente aux stations de taxis,",
  },
  512: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "PROMO Taxi",
    description:
      "A l'arrivée dans votre aéroport, votre but est d'arriver à votre hébergement le plus rapidement possible, avec le moins de problèmes. Pourquoi ne pas arriver avec style en réservant l'un de nos transferts privés (taxi/minibus). Pas de longues files d'attente aux bornes de taxis ni de transferts fatigants en autocar, évitez les foules et profitez de votre premier verre avant que les autres aient quitté l'aéroport.",
  },
  513: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/speedyshuttle.jpg",
    name: "Navette Express",
    description:
      "Il s'agit d'un service partagé rentable avec d'autres passagers, fait jusqu’à 4 arrêts. Vous serez déposé / pris à votre hôtel ou le plus proche point d'accès. Votre temps de transfert est estimé mais peut varier selon le trafic et le nombre d'arrêts effectués. Il peut y avoir une attente à l'aéroport ou un point de ramassage car les bus fonctionnent selon des horaires définis. Pour plus d'informations sur la localisation de votre transfert et pour connaître votre heure de prise en charge, consultez votre bon de réservation.",
  },
  514: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Navette rapide",
    description:
      "La navette rapide est une option de transfert partagé qui assure pas plus de 4 arrêts en route vers et à partir de votre hébergement.Il est important que nous ayons votre numéro de téléphone portable afin de vous contacter en cas de besoin. Veuillez l'inscrire au cours de la procédure de réservation.Veuillez noter qu'il peut y avoir un délai d'attente à l'aéroport puisque les navettes circulent à horaires fixes. Temps d'attente maximum 1 heure. Les horaires de transfert sont purement indicatifs et peuvent varier en fonction du trafic et/ou du nombre d'arrêts en cours de route.",
  },
  515: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Croisière Transfert Privé",
    description:
      "Le transfert privé de croisière vous permet un voyage sans encombre à votre destination et vous garantit un espace de bagages pour un maximum de 2 bagages de soute et bagages à main par personne.",
  },
  516: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "PROMO Navette",
    description:
      "Les navettes sont un moyen de transport rapide, efficace et peu coûteux qui vous permet d'atteindre et de quitter votre logement dans une ville ou dans un pays inconnu(e). Lorsque vous utilisez un service de navette réservée à l'avance, vous êtes immédiatement assuré de parvenir directement à votre logement.  N'oubliez pas qu'il est possible que vous deviez attendre votre navette à l'aéroport car celles-ci fonctionnent selon des horaires. Temps d'attente maximal 1 heure. Les temps de transferts ne sont que des estimations et dépendent de la circulation et du nombre des arrêts effectués.",
  },
  517: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Transfert - navette",
    description:
      "Les navettes sont un moyen de transport rapide, efficace et peu coûteux qui vous permet d'atteindre et de quitter votre logement dans une ville ou dans un pays inconnu(e). Lorsque vous utilisez un service de navette réservée à l'avance, vous êtes immédiatement assuré de parvenir directement à votre logement.  N'oubliez pas qu'il est possible que vous deviez attendre votre navette à l'aéroport car celles-ci fonctionnent selon des horaires. Temps d'attente maximal 1 heure. Les temps de transferts ne sont que des estimations et dépendent de la circulation et du nombre des arrêts effectués.",
  },
  518: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Dubrovnik City Shuttle",
    description:
      "A City Shuttle is a cost effective and efficient way of getting to the centre of Dubrovnik. This shared shuttle option is a cheap, fast and cost effective way of travelling to and from the city centre drop off point.     Please note that this is a scheduled bus service picking up and dropping off at specific times and points in the city.  There may be a wait at the airport or pick up point as buses run to a schedule. City Shuttle service runs every 30 minutes after flight arrivals and every 120 minutes before international flight departure times.  Dubrovnik: Bus stops at Pile Gate bus station next to the Old City and Main Bus Station in Gruz. Bus picks up clients going to the airport from: Main Bus Station in Gruz, Bus stop near the Grawe building and Bus stop near the Dubrovnik Cable Car at Ploce next to the Old City.",
  },
  519: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/speedyshuttle.jpg",
    name: "Navette rapide",
    description:
      "Il s'agit d'un service partagé rentable avec d'autres passagers, fait jusqu’à 4 arrêts. Vous serez déposé / pris à votre hôtel ou le plus proche point d'accès. Votre temps de transfert est estimé mais peut varier selon le trafic et le nombre d'arrêts effectués. Il peut y avoir une attente à l'aéroport ou un point de ramassage car les bus fonctionnent selon des horaires définis. Pour plus d'informations sur la localisation de votre transfert et pour connaître votre heure de prise en charge, consultez votre bon de réservation.",
  },
  520: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/mercedes-e-class.png",
    name: "Mercedes de Classe E (ou comparable)",
    description:
      "Votre transfert professionnel de porte à porte de luxe est fourni dans une Mercedes Classe E ou équivalent. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  521: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/mercedes-s-class.png",
    name: "Mercedes de Classe S (ou comparable)",
    description:
      "Votre transfert professionnel de porte à porte de Luxe et fourni avec une Mercedes Class S ou équivalent. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  522: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Monospace de classe Affaires",
    description:
      "Votre transfert de porte-à-porte Exclusif en mini-van est fourni avec un véhicule de haute qualité, idéal pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  523: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "London Taxi",
    description: "Taxi de style londonien",
  },
  524: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Marrakech Taxi",
    description:
      "Dès votre arrivée dans l'aéroport de votre choix, votre but est de rejoindre votre lieu d'hébergement aussi vite que possible et en toute sérénité. Pourquoi ne pas arriver avec style en réservant l'un de nos transferts privés (taxi/minibus). Vous serez accueilli à l'aéroport de Marrakech par notre représentant qui vous guidera vers votre véhicule.  (Veuillez noter qu'un court délai d'attente pour votre transfert est possible )",
  },
  525: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-car.png",
    name: "Voiture de luxe (avec un guide)",
    description:
      "Pour un véhicule qui offre plus d'avantages qu'un taxi standard, la voiture de luxe est plus spacieuse et plus confortable pour votre transfert. Votre chauffeur personnel vous attendra dès votre arrivée et vous escortera jusqu'à votre véhicule pour vous acheminer rapidement et efficacement.",
  },
  526: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Voiture de ville",
    description:
      "Upon arrival into your chosen airport, your goal is to reach your accommodation as quickly and hassle free as possible. Why not arrive in style by booking one of our Town Cars (max 1 piece of main and hand luggage per person) No waiting in public airport taxi queues or tiresome bus transfers, get ahead of the hordes and enjoy your first drink before the masses have left the airport.",
  },
  527: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Ferien navette",
    description:
      "Les navettes sont un moyen de transport rapide, efficace et peu coûteux qui vous permet d'atteindre et de quitter votre logement dans une ville ou dans un pays inconnu(e). Lorsque vous utilisez un service de navette réservée à l'avance, vous êtes immédiatement assuré de parvenir directement à votre logement.  N'oubliez pas qu'il est possible que vous deviez attendre votre navette à l'aéroport car celles-ci fonctionnent selon des horaires. Temps d'attente maximal 1 heure. Les temps de transferts ne sont que des estimations et dépendent de la circulation et du nombre des arrêts effectués.",
  },
  528: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Navette privée",
    description:
      "Upon arrival into Madrid airport, your goal is to reach your accommodation as hassle free as possible. Why not arrive in style by booking one of our private transfers (taxi/minibus).   No negotiating rates with drivers or tiresome bus transfers. Please note that there may be a short wait of up to 30 minutes at Madrid airport.",
  },
  529: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Private Transfer (non english speaking driver)",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.\nCe service est fourni par un chauffeur parlant la langue native.",
  },
  530: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minibus.png",
    name: "Minibus (non english speaking driver)",
    description:
      "Votre transfert professionnel de porte à porte en minibus est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. Ce service est fourni par un chauffeur originaire du pays.",
  },
  531: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Coach (non english speaking driver)",
    description:
      "Votre transfert professionnel de porte à porte en autocar est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l’accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. Ce service est fourni avec un chauffeur qui parle la langue locale ",
  },
  532: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private-wifi-HTX-new.jpg",
    name: "Transfert privé avec Wi-FI",
    description:
      "Votre transfert professionnel de porte à porte privée comprend également une connexion Wi-Fi à bord. Dans la plupart des aéroports, ce service comprend l' accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  533: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minibus-wifi.jpg",
    name: "Minibus avec Wi-Fi",
    description:
      "Votre transfert professionnel de porte à porte en minibus est idéal pour un large groupe de passagers et comprend également une connexion Wi-Fi à bord. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  534: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Grand Prix Shared Vehicle",
    description:
      "Pendant la période extravagante du Grand Prix de Monaco, il devient difficile de se déplacer dans les environs. Votre objectif est d’arriver à votre hébergement aussi rapidement et sereinement que possible. En réservant l’un de nos transferts, vous évitez la longue attente dans les files de taxi. En raison de restrictions routières, vous serez déposé et cherché sur le très central Boulevard de la Princesse Charlotte (au-dessus des jardins du Casino de Monte Carlo). Vous aurez ainsi l’assurance de partir de l’aéroport et d’y arriver avec style",
  },
  535: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private-sedan.png",
    name: "Sedan – English speaking driver GUARANTEED",
    description:
      "Pour un transport facile et sans heurts de l’aéroport jusqu’à votre destination, la Sedan représente une voiture urbaine confortable et spacieuse, parfaite pour cette tâche. Votre chauffeur personnel vous attendra dès votre arrivée et vous escortera jusqu'à votre véhicule pour vous acheminer rapidement et efficacement.",
  },
  536: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle (1)-min-min (3).jpg",
    name: "Express City Shuttle Bus",
    description:
      "A City Shuttle is a cost effective and efficient way of getting to the city centre. This shared shuttle option is a cheap, fast and cost effective way of travelling to and from the city centre drop off point.\r\nPlease note that this is a scheduled bus service picking up and dropping off at specific times and points in the city. There may be a wait at the airport or pick up point as buses run to a schedule.\r\nPlease Note: Pick up and Drop off locations will be displayed on your voucher.",
  },
  537: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Van",
    description:
      "Votre transfert professionnel de porte à porte en van est idéal pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  538: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Micro",
    description:
      "Réservez à l'avance l'un de nos véhicules plutôt que d'attendre plusieurs taxis. Une manière idéale de parvenir facilement et avec élégance à votre logement. Votre véhicule vous attendra à l’arrivée et vous amènera à votre logement en facilitant la poursuite de votre voyage. Nous pouvons prendre en charge des groupes de toutes tailles.",
  },
  539: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/coach.png",
    name: "Bus",
    description:
      "Réservez à l'avance l'un de nos véhicules plutôt que d'attendre plusieurs taxis. Une manière idéale de parvenir facilement et avec élégance à votre logement. Votre véhicule vous attendra à l’arrivée et vous amènera à votre logement en facilitant la poursuite de votre voyage. Nous pouvons prendre en charge des groupes de toutes tailles.",
  },
  540: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle-wifi.jpg",
    name: "Transfert en navette avec Wi-Fi",
    description:
      "Il s'agit d'un service partagé rentable et rapide avec d'autres passagers.ce transfert comprend une connexion Wi-Fi. Les navettes circulent entre l'aéroport et l'hébergement à l'exclusion d'une adresse privée.Vous serez déposé / pris à votre hôtel ou le plus proche point d'accès.Votre temps de transfert est estimé mais peut varier selon le trafic et le nombre d'arrêts effectués.Il peut y avoir une attente à l'aéroport ou un point de ramassage car les bus fonctionnent selon des horaires définis. Pour plus d'informations sur la localisation de votre transfert et pour connaître votre heure de prise en charge, consultez votre bon de réservation.",
  },
  541: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Private Meet and Greet Service",
    description:
      "Upon arrival into your chosen airport, your goal is to reach your accommodation as quickly and hassle free as possible. Why not arrive in style by booking one of our  private meet & greet service transfers (taxi/minibus) where your driver will meet you at the arrivals hall with a Travel Counsellors branded sign board and escort you to your waiting vehicle. No waiting in long public airport taxi queues or tiresome bus transfers, get ahead of the hordes and enjoy your first drink before the masses have left the airport.",
  },
  542: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Private Transfer (English speaking assistant)",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.Ce service est fourni avec un assistant anglophone.",
  },
  543: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minibus.png",
    name: "Minivan (English speaking assistant)",
    description:
      "Votre transfert de porte-à-porte professionnel en Mini-van est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. \nCe service est fourni avec un assistant anglophone.",
  },
  544: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/coach.png",
    name: "Coach (English speaking assistant)",
    description:
      "Votre transfert professionnel de porte à porte en autocar est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l’accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. Ce service est fourni avec un assistant anglophone.\n",
  },
  545: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-private.png",
    name: "Green Class Luxury Car",
    description:
      "Your Luxury Eco-Friendly door to door transfer is a zero-emission vehicle. Perfect for the environmentally conscious traveller wanting to travel in style. In most airports, this service includes ‘Meet & Greet’ and your driver will be waiting for you in the arrival hall holding a sign with your name on it. Further information on how to locate your transfer can be found on your booking voucher. When returning to the airport your driver will be waiting for you at your pick-up location. Your pick-up time is calculated automatically based on your flight details. ",
  },
  546: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-car.png",
    name: "Luxury Private Meet and Greet",
    description:
      "Upon arrival into your chosen airport, your goal is to reach your accommodation as quickly and hassle free as possible. Why not arrive in style by booking one of our  private meet & greet service transfers where your driver will meet you at the arrivals hall with a Travel Counsellors branded sign board and escort you to your waiting vehicle. No waiting in long public airport taxi queues or tiresome bus transfers, get ahead of the hordes and enjoy your first drink before the masses have left the airport.",
  },
  547: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/limo.jpg",
    name: "Limousine Private Meet and Greet",
    description:
      "Upon arrival into your chosen airport, your goal is to reach your accommodation as quickly and hassle free as possible. Why not arrive in style by booking one of our  private meet & greet service transfers where your driver will meet you at the arrivals hall with a Travel Counsellors branded sign board and escort you to your waiting vehicle. No waiting in long public airport taxi queues or tiresome bus transfers, get ahead of the hordes and enjoy your first drink before the masses have left the airport.",
  },
  548: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/water-taxi.jpg",
    name: "Water Taxi - Deluxe (excluding porterage)",
    description:
      "Ce transfert professionnel de Luxe de service taxi-bateau est un transport de haute qualité et, est aussi unique aux canaux de Venise. Vous serez accueilli par votre propre chauffeur anglophone à votre arrivée et escorté jusqu'à votre véhicule. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Les bateaux-taxis circulent entre l'aéroport et le point d'embarquement le plus proche de votre hébergement. Vous serez déposé / ramassé à partir du point d’accès le plus proche. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. Le portage est exclu.",
  },
  549: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/london_black_cab.jpg",
    name: "taxi Typique londonien ",
    description:
      "Votre transfert professionnel de porte à porte dans un taxi 'Typique londonien' Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  550: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Private Meet and Greet (Go Global)",
    description:
      "Upon arrival into your chosen airport, your goal is to reach your accommodation as quickly and hassle free as possible. Why not arrive in style by booking one of our  private meet & greet service transfers (taxi/minibus) where your driver will meet you at the arrivals hall and escort you to your waiting vehicle. No waiting in long public airport taxi queues or tiresome bus transfers, get ahead of the hordes and enjoy your first drink before the masses have left the airport.",
  },
  551: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Ski Scheduled Shuttle (important:read more info)",
    description:
      "Scheduled services are a cheap, reliable and effective way of travelling to and from your resort. The bus will take you to a central point within your resort, from where you can easily make your own way directly to your hotel. Shuttles are shared with other passengers. Please be aware that this service runs only on Saturday and Sunday and to a strict scheduled timetable and as such there may be a wait for your shuttle at the airport. Exact timings will be detailed on your voucher but the maximum wait will be no longer than 180 min. Transfer times are approximate and dependent upon traffic and number of stops made. If your flight lands within 1 – 1.5 hours of the last shuttle, we cannot be responsible if you do not connect with the shuttle in time. ",
  },
  552: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/coach-wifi-new.jpg",
    name: "Coach with Wi-Fi",
    description:
      "Votre transfert professionnel de porte à porte en autocar est idéal pour un large groupe de passagers et comprend également une connexion Wi-Fi à bord. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  553: {
    imageurl:
      "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/speedy-shuttle-wifi-HTX-new.jpg",
    name: "Speedy Shuttle (max. 4 stops) with Wi-Fi",
    description:
      "Il s'agit d'un service partagé rentable et rapide avec d'autres passagers, fait jusqu’à 4 arrêts. Ce service comprend une connexion Wi-Fi á bord. Vous serez déposé / pris à votre hôtel ou le plus proche point d'accès. Votre temps de transfert est estimé mais peut varier selon le trafic et le nombre d'arrêts effectués. Il peut y avoir une attente à l'aéroport ou un point de ramassage car les bus fonctionnent selon des horaires définis. Pour plus d'informations sur la localisation de votre transfert et pour connaître votre heure de prise en charge, consultez votre bon de réservation.",
  },
  554: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Private Transfer Surfer&Surf Board",
    description:
      "Vos transferts privés de porte à porte. Notre voiture vous attendra à l'aéroport et a assez d'espace pour 1 valise par personne ainsi qu'une planche de surf. Ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  555: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Private Transfer - Special Offer This Week Only",
    description:
      "Special Offer - Upon arrival into your chosen airport, your goal is to reach your accommodation as quickly and hassle free as possible. Why not arrive in style by booking one of our private transfers (taxi/minibus). No waiting in public airport taxi queues or tiresome bus transfers, get ahead of the hordes and enjoy your first drink before the masses have left the airport.",
  },
  556: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Shuttle Transfer - Special Offer This Week Only",
    description:
      "Special Offer - this week only! Shuttles are a shared transfer option and are a cheap, fast and effective way of travelling to and from your accommodation in a place you may be unfamiliar with.    It is important that we have a contact mobile number for you so that we can contact you should it be required. Please enter this during the booking process.    Please be aware that there may be a wait for your shuttle at the airport since they run to a schedule. Maximum wait time 1 hour, or 90 minutes for ski transfers. Transfer times are estimated and can vary dependent upon traffic and/or the number of stops made.",
  },
  557: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Private Transfer (with guide)",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.Ce service est fourni avec un guide.",
  },
  558: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Van (with guide)",
    description:
      "Pour une grande famille ou un groupe de cinq personnes ou plus qui souhaitent voyager ensemble. Réservez à l’avance un grand véhicule pour votre groupe plutôt que d’attendre plusieurs taxis. C’est une façon idéale de vous rendre à votre hébergement d’une manière aisée et agréable. Votre véhicule vous attendra à l’arrivée et vous amènera à votre hébergement. La poursuite de votre voyage se fera ainsi sans problème. Des groupes de toute taille peuvent être pris en charge.",
  },
  559: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/premier-luxury.jpg",
    name: "Superior Private Meet and Greet Service",
    description:
      "Upon arrival into your chosen airport, your goal is to reach your accommodation as quickly and hassle free as possible. Why not arrive in style by booking one of our  private meet & greet service transfers (taxi/minibus) where your driver will meet you at the arrivals hall with a Travel Counsellors branded sign board and escort you to your waiting vehicle. No waiting in long public airport taxi queues or tiresome bus transfers, get ahead of the hordes and enjoy your first drink before the masses have left the airport.",
  },
  560: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Private Meet and Greet Service by desk",
    description:
      "Upon arrival into your chosen airport, your goal is to reach your accommodation as quickly and hassle free as possible. Why not arrive in style by booking one of our private meet & greet service transfers (taxi/minibus) where your driver will meet you next to the supplier’s office desk with a Travel Counsellors branded sign board and escort you to your waiting vehicle. No waiting in long public airport taxi queues or tiresome bus transfers, get ahead of the hordes and enjoy your first drink before the masses have left the airport.",
  },
  561: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Shared Water Shuttle Service",
    description:
      "Il s'agit d'un service partagé rentable et rapide qui est unique aux canaux de Venise; vous voyagerez avec d'autres passagers.\nLes navettes circulent entre l'aéroport et le point d'embarquement le plus proche de votre hébergement. Vous serez déposé / ramassé à partir du point accessible le plus proche de votre hébergement. Votre temps de transfert est estimé mais peut varier en fonction du nombre d'arrêts effectués. Vous trouverez plus d'informations sur la localisation de votre transfert ainsi que l'heure de récupération sur votre bon de réservation. IMPORTANT : Les bateaux s'arrêtent à la Place Saint-Marc et dans le quartier du Rialto, d'où vous pourrez rejoindre votre hôtel/hébergement à pied. Sachez qu'il peut y avoir un délai d'attente à l'aéroport puisque les bateaux partent toutes les 1h30 de 6h á 19h . Il est important que nous ayons un numéro de téléphone portable où vous joindre en cas de nécessité. Veuillez l'inscrire au cours de la procédure de réservation.",
  },
  562: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Teletext Private Meet and Greet",
    description:
      "Upon arrival into your chosen airport, your goal is to reach your accommodation as quickly and hassle free as possible. Why not arrive in style by booking one of our  private meet & greet service transfers (taxi/minibus) where your driver will meet you at the arrivals hall with a Teletext branded sign board and escort you to your waiting vehicle. No waiting in long public airport taxi queues or tiresome bus transfers, get ahead of the hordes and enjoy your first drink before the masses have left the airport.",
  },
  563: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Teletext Private Meet and Greet by Desk",
    description:
      "Upon arrival into your chosen airport, your goal is to reach your accommodation as quickly and hassle free as possible. Why not arrive in style by booking one of our private meet & greet service transfers (taxi/minibus) where your driver will meet you next to the supplier’s office desk with a Teletext branded sign board and escort you to your waiting vehicle. No waiting in long public airport taxi queues or tiresome bus transfers, get ahead of the hordes and enjoy your first drink before the masses have left the airport.",
  },
  564: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-car.png",
    name: "Luxury Car (By Blacklane)",
    description:
      "For a vehicle that is more than a standard taxi, the luxury car is more spacious and provides absolute comfort for your transfer. You will be met be your own personal driver on arrival and escorted to your waiting vehicle for a fast and efficient transfer.  Blacklane offers a variety of car classes so you can choose the car which best fits your needs and budget in any part of the world. No matter which vehicle class you choose, you can look forward to a variety of high-quality cars with professional, experienced drivers in each city.",
  },
  565: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxuryminivan.png",
    name: "Luxury Minivan (By Blacklane)",
    description:
      "Pre-Book one of larger vehicles for your party rather than waiting for several taxis. This is an ideal way of getting to your accommodation with ease and style. Your vehicle will be waiting for you upon arrival and whisk you off to your accommodation making your onward journey hassle free. Groups of any size can be catered for.  Blacklane offers a variety of car classes so you can choose the car which best fits your needs and budget in any part of the world. No matter which vehicle class you choose, you can look forward to a variety of high-quality cars with professional, experienced drivers in each city.",
  },
  566: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-car.png",
    name: "Premier Service (By Blacklane)",
    description:
      "Want more than a standard vehicle? Why not upgrade   by booking our 'Premier Service' for relatively little extra cost.    Through upgrading you'll benefit from a more spacious vehicle to provide absolute comfort for your transfer. You will be met by your own personal driver on arrival and escorted to your waiting vehicle for a fast and efficient transfer.  Blacklane offers a variety of car classes so you can choose the car which best fits your needs and budget in any part of the world. No matter which vehicle class you choose, you can look forward to a variety of high-quality cars with professional, experienced drivers in each city.",
  },
  567: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Private Transfer ( By Blacklane )",
    description:
      "Upon arrival into your chosen airport, your goal is to reach your accommodation as quickly and hassle free as possible. Why not arrive in style by booking one of our private transfers (taxi/minibus).No waiting in public airport taxi queues or tiresome bus transfers, get ahead of the hordes and enjoy your first drink before the masses have left the airport.  Blacklane offers a variety of car classes so you can choose the car which best fits your needs and budget in any part of the world. No matter which vehicle class you choose, you can look forward to a variety of high-quality cars with professional, experienced drivers in each city.",
  },
  568: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-car.png",
    name: "Luxury Salon",
    description:
      "This car is ideal for business people or those who want more than a standard transfer. The transfer is provided in a Lexus LS460 or BMW 7 Series ,it is a highly luxurious car and will certainly get you to your destination in style. Your driver will escort you to your car and take you to your accommodation for an arrival sure to start your trip off on a good footing.",
  },
  569: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Lexus ES 350",
    description:
      "Pour un véhicule qui offre plus d'avantages qu'un taxi standard, la voiture de luxe est plus spacieuse et plus confortable pour votre transfert. Votre chauffeur personnel vous attendra dès votre arrivée et vous escortera jusqu'à votre véhicule pour vous acheminer rapidement et efficacement.",
  },
  570: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Shuttle Transfer not including ferry cost",
    description:
      "Shuttles are a shared transfer option and are a cheap, fast and effective way of travelling to and from your accommodation in a place you may be unfamiliar with. They're cheaper as they run to a schedule, and won't go to private addresses/villas. Shuttles will either deliver to the door of the hotel or where this isn’t possible the nearest appropriate stopping place for your property. Transfer times are estimated and can vary dependent upon traffic and/or the number of stops made. These are available in many locations worldwide, and may range in size from a minibus to a large coach.\r\nPlease note that this transfer does not include the cost of ferry tickets.",
  },
  571: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-private.png",
    name: "BMW 5 Series",
    description:
      "Votre transfert professionnel de porte à porte de Luxe est fourni dans une BMW Series 5. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom.Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  572: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/eco.jpg",
    name: "Eco Car (Nissan Leaf)",
    description:
      "'Ceux qui veulent voyager de manière plus écologique et réduire les émissions de carbone pourront réserver la voiture écologique électrique Nissan Leaf, idéale pour eux. Avec zéro émission de carbone, ce véhicule recyclable à 95&nbsp;% vous offre une conduite confortable et plus écologique.",
  },
  573: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/Tesla_model_X.jpg",
    name: "Luxury Eco Car (Tesla Model X)",
    description:
      "Your Luxury Eco-Friendly door to door transfer is provided in a zero-emission Tesla Model X vehicle. Perfect for the environmentally conscious traveller wanting to travel in style. In most airports, this service includes ‘Meet & Greet’ and your driver will be waiting for you in the arrival hall holding a sign with your name on it. Further information on how to locate your transfer can be found on your booking voucher. When returning to the airport your driver will be waiting for you at your pick-up location. Your pick-up time is calculated automatically based on your flight details.",
  },
  574: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-minivan.jpg",
    name: "Mercedes Vito",
    description:
      "Votre transfert professionnel de porte à porte en mini-van est fourni dans un Mercedes Vito, idéal pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  575: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/mercedes-s-class.png",
    name: "Mercedes S Class (up to 2 adults and 1 child)",
    description:
      "Votre transfert professionnel de porte à porte de Luxe est fourni dans une Mercedes Class S Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. S'IL VOUS PLAÎT NOTER: SEULEMENT 1 SEUL BAGAGE EST PERMIS PAR ADULTE.",
  },
  576: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/mercedes-s-class.png",
    name: "Mercedes S Class Meet and Greet",
    description:
      "Votre transfert professionnel de porte à porte de Luxe et fourni avec une Mercedes Class S. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  577: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Minivan Meet and Greet",
    description:
      "Votre transfert de porte-à-porte professionnel en Mini-van est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. ",
  },
  578: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/premier.jpg",
    name: "Premium Transfer (Diplomatic Service)",
    description:
      "(Only offered to diplomats, celebrities & Artists)  Get escorted from Immigration by your personal driver who will be waiting for you to take you to your luxury and comfortable town car. Includes a bottle of water on arrival to refresh you after your journey.",
  },
  579: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/limo.jpg",
    name: "Premium Limousine (Diplomatic Service)",
    description:
      "(Only offered to diplomats, celebrities & Artists)  Get escorted from Immigration by your personal driver who will be waiting for you to take you to your luxury and comfortable limousine. Includes a bottle of water on arrival to refresh you after your journey.",
  },
  580: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Private Transfer (Spanish speaking assistant)",
    description:
      "À votre arrivée à l'aéroport de votre choix, votre objectif est de rejoindre votre logement aussi rapidement et facilement que possible. Pourquoi ne pas arriver en grande pompe en réservant l'un de nos transferts privés (taxi/minibus). Ne perdez pas de temps dans les files d'attente à des arrêts de taxi ou de bus, passez devant les foules et savourez votre première boisson avant même que la plupart des voyageurs n’aient quitté l’aéroport.",
  },
  581: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Private Transfer (English speaking driver)",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.Ce service est fourni avec un chauffeur anglophone.",
  },
  582: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Private Transfer (Spanish speaking driver)",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.Ce service est fourni avec un chauffeur hispanophone.",
  },
  583: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minibus.png",
    name: "Minivan (Spanish speaking assistant)",
    description:
      "Pour une grande famille ou un groupe qui souhaite voyager ensemble. Réservez à l’avance un grand véhicule pour votre groupe plutôt que d’attendre plusieurs taxis. C’est une façon idéale de vous rendre à votre hébergement d’une manière aisée et agréable. Votre véhicule vous attendra à l’arrivée et vous amènera à votre hébergement. La poursuite de votre voyage se fera ainsi sans problème.",
  },
  584: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Transfert Privé (chauffeur parlant le portugais)",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.Ce service est fourni avec un chauffeur parlant le portugais.",
  },
  585: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-private.png",
    name: "BMW X5 / X6 Series",
    description:
      "For a vehicle that is more than a standard taxi, the BMW X5/X6 Series is more spacious and provides absolute comfort for your transfer. You will be met by your own personal driver on arrival and escorted to your waiting vehicle for a fast and efficient transfer.",
  },
  586: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Shared 1 (temporary)",
    description:
      "A cost effective and efficient way of getting to the centre of Venice. You will share a boat with other passengers.    IMPORTANT: Boats stop at St. Mark’s square and Rialto area from where you need to walk to your hotel/accomodation. Please be aware that there may be a wait at the airport as boats leave every 1h 30 minutes from 6am until 7pm.",
  },
  587: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Shared 2 (temporary)",
    description:
      "A cost effective and efficient way of getting to the centre of Venice. You will share a boat with other passengers.    IMPORTANT: Boats stop at St. Mark’s square and Rialto area from where you need to walk to your hotel/accomodation. Please be aware that there may be a wait at the airport as boats leave every 1h 30 minutes from 6am until 7pm.",
  },
  588: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Private Transfer (Chinese speaking driver)",
    description:
      "À votre arrivée à l'aéroport de votre choix, votre objectif est de rejoindre votre logement aussi rapidement et facilement que possible. Pourquoi ne pas arriver en grande pompe en réservant l'un de nos transferts privés (taxi/minibus). Ne perdez pas de temps dans les files d'attente à des arrêts de taxi ou de bus, passez devant les foules et savourez votre première boisson avant même que la plupart des voyageurs n’aient quitté l’aéroport.",
  },
  589: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Minivan (Chinese speaking driver)",
    description:
      "Pour une grande famille ou un groupe qui souhaite voyager ensemble. Réservez à l’avance un grand véhicule pour votre groupe plutôt que d’attendre plusieurs taxis. C’est une façon idéale de vous rendre à votre hébergement d’une manière aisée et agréable. Votre véhicule vous attendra à l’arrivée et vous amènera à votre hébergement. La poursuite de votre voyage se fera ainsi sans problème.",
  },
  590: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minibus.png",
    name: "Minivan (Portuguese speaking assistant)",
    description:
      "Pour une grande famille ou un groupe qui souhaite voyager ensemble. Réservez à l’avance un grand véhicule pour votre groupe plutôt que d’attendre plusieurs taxis. C’est une façon idéale de vous rendre à votre hébergement d’une manière aisée et agréable. Votre véhicule vous attendra à l’arrivée et vous amènera à votre hébergement. La poursuite de votre voyage se fera ainsi sans problème.",
  },
  591: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/economic-private.jpg",
    name: "Transfert privé Économie",
    description:
      "Ce transfert professionnel de porte à porte privé et économique  est votre option privée qui vous permet de bénéficier d'un budget réduit\nD'autres instructions et l'emplacement de votre chauffeur sont clairement décrits sur votre bon de réservation et votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.  S'il vous plaît, lisez vos instructions de réservation pour plus d'informations.",
  },
  592: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Economy Minivan",
    description:
      "Votre transfert professionnel de porte à porte en Mini-van économique est votre option privée qui vous permet de bénéficier d'un budget réduit. Parfait pour un plus grand groupe de personnes. Votre temps de prise en charge est calculé automatiquement en fonction des détails de votre vol. S'il vous plaît, lisez vos instructions de réservation pour plus d'informations.",
  },
  593: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-suv.jpg",
    name: "SUV Limousine",
    description:
      "La Limousine SUV est une offre de luxe qui comprend le service l'accueil et vous serez amené vers une limousine de haute gamme. Dans la plupart des aéroports, votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  594: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/suv.jpg",
    name: "Economy SUV",
    description:
      "Ce transfert professionnel de porte-à-porte en SUV est votre option privée économique. D'autres instructions et l'emplacement de votre chauffeur sont clairement décrits sur votre bon de réservation et votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. S'il vous plaît, lisez vos instructions de réservation pour plus d'informations.",
  },
  595: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Private Transfer Meet and Greet",
    description:
      "A l'arrivée dans votre aéroport, votre but est d'arriver à votre hébergement le plus rapidement possible, avec le moins de problèmes. Pourquoi ne pas arriver avec style en réservant l'un de nos transferts privés (taxi/minibus). Pas de longues files d'attente aux bornes de taxis ni de transferts fatigants en autocar, évitez les foules et profitez de votre premier verre avant que les autres aient quitté l'aéroport.",
  },
  596: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/coach.png",
    name: "Coach Meet and Greet",
    description:
      "Votre transfert professionnel de porte à porte en autocar est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l’accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. ",
  },
  597: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "VIP Shared Transfer",
    description:
      "Il s'agit d'un service partagé rentable et rapide avec d'autres passagers. Le service partagé VIP comprend l'accueil dans la plupart des aéroports. Vous serez transporté dans un utilitaire de taille moyenne pour votre confort et vous êtes assuré de ne pas partager avec plus de 6 passagers au total. Les navettes circulent entre l'aéroport et l'hébergement à l'exclusion d'une adresse privée. Vous serez déposé / ramassé à partir du point d’accès le plus proche. Votre temps de transfert est estimé mais peut varier selon le trafic et le nombre d'arrêts effectués. Il peut y avoir une attente à l'aéroport ou un point de ramassage car les bus fonctionnent selon un horaire prédéfini. Pour plus d'informations sur la localisation de votre transfert et pour connaître votre heure de prise en charge, consultez votre bon de réservation.",
  },
  598: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Private Transfer (Mercedes E Class guaranteed – VP only)",
    description:
      "Votre transfert professionnel de porte à porte de luxe et fourni avec une Mercedes Class E. Ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  599: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-car.png",
    name: "Luxury Car Meet and Greet",
    description:
      "Pour un véhicule qui offre plus d'avantages qu'un taxi standard, la voiture de luxe est plus spacieuse et plus confortable pour votre transfert. Votre chauffeur personnel vous attendra dès votre arrivée et vous escortera jusqu'à votre véhicule pour vous acheminer rapidement et efficacement.",
  },
  600: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-minivan.jpg",
    name: "Mercedes Viano",
    description:
      "Votre transfert de porte à porte de Luxe en Mini-van est fourni dans une Mercedes Viano, idéal pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l’accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  601: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/suv.jpg",
    name: "Luxury SUV",
    description:
      "Votre transfert professionnel de porte à porte de Luxe en SUV. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  602: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/nationalexpress.jpg",
    name: "National Express",
    description:
      "National Express Coaches are a shared transfer option and are a cheap, safe and effective way of travelling from your chosen London airport to all of the major London terminals.\r\nNational Express coaches offer a guaranteed seat and comfort, with leather seats and air conditioning on the majority of coaches. Transfer times are estimated and can vary dependent upon traffic and/or the number of stops made.\r\nPlease note: You will need to exchange your receipt for a ticket to travel only from your chosen airport. \r\nPlease ensure your booking starts at an airport. Failure to do so will result in your booking being cancelled. \r\n\r\nOperators terms and conditions to travel apply and this product is exempt from standard HolidayTaxis Group terms.",
  },
  603: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/terravision.jpg",
    name: "Terravision",
    description:
      "Please note: This booking is NON REFUNDABLE\r\n\r\nTerravision Coaches are a shared transfer option and offer a service that is comfortable, cheap and direct from Milan Malpensa and Florence Pisa Airports, to a city centre drop off point.\r\nTransfer times are estimated and can vary dependent upon traffic and/or the number of stops made.\r\n\r\nOperators terms and conditions to travel apply and this product is exempt from standard HolidayTaxis Group terms\r\n",
  },
  604: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/heathrowexpress.jpg",
    name: "Heathrow Express",
    description:
      "Heathrow Express trains are a cheap, safe and effective way of travelling to and from all five Heathrow terminals. Running every 30 minutes, around the clock, Heathrow Express offers a clean and efficient service that will fit your timetable. Booking can be made up to 24 hours in advance of travel from London Heathrow to London Paddington and vice versa.\r\nTransfer Times are estimated and can vary, dependent on conditions.\r\nOperators terms and conditions to travel apply and this product is exempt from standard HolidayTaxis Group terms\r\n",
  },
  605: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/heathrowexpress.jpg",
    name: "Heathrow Express Business Class",
    description:
      "Heathrow Express trains are a cheap, safe and effective way of travelling to and from all five Heathrow terminals. Running every 30 minutes, around the clock, Heathrow Express offers a clean and efficient service that will fit your timetable. Booking can be made up to 24 hours in advance of travel from London Heathrow to London Paddington and vice versa.\r\nTransfer Times are estimated and can vary, dependent on conditions.\r\nOperators terms and conditions to travel apply and this product is exempt from standard HolidayTaxis Group terms\r\n",
  },
  606: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Dedicated Cruise Private Transfer",
    description:
      "À votre arrivée à l'aéroport de votre choix, votre objectif est de rejoindre votre logement aussi rapidement et facilement que possible. Pourquoi ne pas arriver en grande pompe en réservant l'un de nos transferts privés (taxi/minibus). Ne perdez pas de temps dans les files d'attente à des arrêts de taxi ou de bus, passez devant les foules et savourez votre première boisson avant même que la plupart des voyageurs n’aient quitté l’aéroport.",
  },
  607: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Dedicated Cruise Minivan",
    description:
      "Réservez à l'avance l'un de nos véhicules plutôt que d'attendre plusieurs taxis. Une manière idéale de parvenir facilement et avec élégance à votre logement. Votre véhicule vous attendra à l’arrivée et vous amènera à votre logement en facilitant la poursuite de votre voyage. Nous pouvons prendre en charge des groupes de toutes tailles.",
  },
  608: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-private.png",
    name: "Exclusive Transfer",
    description:
      "À votre arrivée à l'aéroport de votre choix, votre objectif est de rejoindre votre logement aussi rapidement et facilement que possible. Pourquoi ne pas arriver en grande pompe en réservant l'un de nos transferts privés (taxi/minibus). Ne perdez pas de temps dans les files d'attente à des arrêts de taxi ou de bus, passez devant les foules et savourez votre première boisson avant même que la plupart des voyageurs n’aient quitté l’aéroport.",
  },
  609: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/economic-private.jpg",
    name: "Economy Private Transfer (Chinese speaking driver)",
    description:
      "Just as quick, and just as private, but fewer features than we offer on our standard and premium transfers that you might not need. Whichever private transfer you choose, there will be no waiting in public airport taxi queues or tiresome bus transfers.",
  },
  610: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-car.png",
    name: "Luxury Car (Chinese speaking driver)",
    description:
      "Pour un véhicule qui offre plus d'avantages qu'un taxi standard, la voiture de luxe est plus spacieuse et plus confortable pour votre transfert. Votre chauffeur personnel vous attendra dès votre arrivée et vous escortera jusqu'à votre véhicule pour vous acheminer rapidement et efficacement.",
  },
  611: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/premier.jpg",
    name: "Premier Service (Chinese speaking driver)",
    description:
      "Vous souhaitez plus qu’un véhicule standard? Pourquoi ne pas vous offrir un petit plus en réservant notre ‘Service Premier’, moyennant un supplément assez modeste. Vous bénéficierez alors d’un véhicule plus spacieux et donc d’un confort absolu pendant votre transfert. Vous serez accueilli par votre chauffeur personnel à l’arrivée et accompagné jusqu’au véhicule, qui vous attend pour un transfert rapide et efficace.",
  },
  612: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxuryminivan.png",
    name: "Luxury Minivan (Chinese speaking driver)",
    description:
      "This is an ideal way of getting to your accommodation with ease and style. Your vehicle will be waiting for you upon arrival and whisk you off to your accommodation making your onward journey hassle free.",
  },
  613: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/water-taxi.jpg",
    name: "Water Taxi - Private Transfer Meet and Greet Service",
    description:
      "Le bateau-taxi est utilisé dans tout Venise pour aller d'un endroit à un autre - c'est une aventure en soi qui vous permet de savourer la vie au bord de l'eau tout en vous reposant jusqu'à votre destination. Un service d'assistance et de portage n'est pas inclus dans ce transfert. Selon la durée du trajet, des suppléments de nuit peuvent être applicables.",
  },
  614: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/motorbike.png",
    name: "Moto Taxi",
    description:
      "La moto est le moyen le plus rapide pour se rendre au centre-ville. Ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.Pour plus d'informations, consultez votre bon de réservation.",
  },
  615: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Taxi Private Transfer",
    description:
      "Upon arrival into your chosen airport, your goal is to reach your accommodation as quickly and hassle free as possible. Why not arrive in style by booking one of our private transfers (taxi/minibus).No waiting in public airport taxi queues or tiresome bus transfers, get ahead of the hordes and enjoy your first drink before the masses have left the airport. Please be aware that there is a small possibility for your vehicle to be a branded local taxi.",
  },
  616: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Qunar Private Transfer",
    description:
      "À votre arrivée à l'aéroport de votre choix, votre objectif est de rejoindre votre logement aussi rapidement et facilement que possible. Pourquoi ne pas arriver en grande pompe en réservant l'un de nos transferts privés (taxi/minibus). Ne perdez pas de temps dans les files d'attente à des arrêts de taxi ou de bus, passez devant les foules et savourez votre première boisson avant même que la plupart des voyageurs n’aient quitté l’aéroport.",
  },
  617: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Qunar Minivan",
    description:
      "Réservez à l'avance l'un de nos véhicules plutôt que d'attendre plusieurs taxis. Une manière idéale de parvenir facilement et avec élégance à votre logement. Votre véhicule vous attendra à l’arrivée et vous amènera à votre logement en facilitant la poursuite de votre voyage. Nous pouvons prendre en charge des groupes de toutes tailles.",
  },
  618: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/economic-private.jpg",
    name: "Economy Private Transfer- Japanese speaking driver",
    description:
      "Ce transfert professionnel de porte-à-porte privé est votre option privée économique. D'autres instructions et l'emplacement de votre chauffeur sont clairement décrits sur votre bon de réservation et votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. S'il vous plaît, lisez vos instructions de réservation pour plus d'informations. Ce service est fourni par un chauffeur parlant japonais.",
  },
  619: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Economy Minivan- Japanese speaking driver",
    description:
      "Votre transfert professionnel de porte-à-porte en Mini-van est une option économique. Parfait pour un large groupe de voyageur D'autres instructions et l'emplacement de votre chauffeur sont clairement décrits sur votre bon de réservation et votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. S'il vous plaît, lisez vos instructions de réservation pour plus d'informations. Ce service est fourni par un chauffeur parlant japonais.",
  },
  620: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Minivan (Conducteur parlant le portugais)",
    description:
      "Votre transfert de porte-à-porte professionnel en Mini-van est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.\nCe service est fourni par un chauffeur qui parle le portugais.",
  },
  621: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Minivan (Spanish speaking driver)",
    description:
      "Votre transfert professionnel de porte-à-porte en Mini van est idéal pour un large groupe de voyageur Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. Ce service est fourni par un chauffeur hispanophone.",
  },
  622: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Minivan (English speaking driver)",
    description:
      "Votre transfert professionnel de porte-à-porte en Mini van est idéal pour un large groupe de voyageur Dans la plupart des aéroports, ce service comprend l’accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. Ce service est fourni par un chauffeur anglophone.",
  },
  623: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Dedicated Cruise Coach",
    description:
      "Pour ceux qui souhaitent voyager au sein de très grands groupes et éviter les tracas et le stress d'attendre plusieurs taxis à l’aéroport.  En réservant un transfert par bus, vous atteindrez votre destination en même temps que les membres de votre groupe et dans le confort afin que vous puissiez démarrer vos vacances ensemble et de bonne humeur.",
  },
  624: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/water-taxi.jpg",
    name: "Dedicated Cruise Private Transfer - Water Taxi",
    description:
      "Le taxi de l'eau est utilisée dans tout Venise pour vous emmener de place en place, c'est une aventure en soi et vous permettra de prendre dans la vie qui vous entoure les voies navigables alors détendez-vous jusqu'à  vous atteindre votre destination. .",
  },
  625: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Dedicated Cruise Private Transfer - Road Taxi",
    description:
      "À votre arrivée à l'aéroport de votre choix, votre objectif est de rejoindre votre logement aussi rapidement et facilement que possible. Pourquoi ne pas arriver en grande pompe en réservant l'un de nos transferts privés (taxi/minibus). Ne perdez pas de temps dans les files d'attente à des arrêts de taxi ou de bus, passez devant les foules et savourez votre première boisson avant même que la plupart des voyageurs n’aient quitté l’aéroport.",
  },
  626: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Private Transfer (Italian speaking driver)",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.Ce service est fourni avec un chauffeur parlant l'italien.",
  },
  627: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Private Transfer (Arabic speaking driver)",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. Ce service est fourni avec un chauffeur parlant l'arabe.",
  },
  628: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/limo.jpg",
    name: "Economy Limousine",
    description:
      "Quoi de plus élégant que d'arriver à votre destination dans une limousine; qu'il s'agisse d'un voyage d'affaires, d'un anniversaire ou d'un anniversaire de mariage, ou même d'un week-end de shopping entre filles. Réserver à l’avance un transfert en limousine signifie que votre propre chauffeur vous attendra à l’arrivée pour vous amener en grande pompe à votre destination.",
  },
  629: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/economic-private.jpg",
    name: "China Private Transfer (comfort)",
    description:
      "À votre arrivée à l'aéroport de votre choix, votre objectif est de rejoindre votre logement aussi rapidement et facilement que possible. Pourquoi ne pas arriver en grande pompe en réservant l'un de nos transferts privés (taxi/minibus). Ne perdez pas de temps dans les files d'attente à des arrêts de taxi ou de bus, passez devant les foules et savourez votre première boisson avant même que la plupart des voyageurs n’aient quitté l’aéroport.",
  },
  630: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-car.png",
    name: "China Private Transfer (luxury)",
    description:
      "À votre arrivée à l'aéroport de votre choix, votre objectif est de rejoindre votre logement aussi rapidement et facilement que possible. Pourquoi ne pas arriver en grande pompe en réservant l'un de nos transferts privés (taxi/minibus). Ne perdez pas de temps dans les files d'attente à des arrêts de taxi ou de bus, passez devant les foules et savourez votre première boisson avant même que la plupart des voyageurs n’aient quitté l’aéroport.",
  },
  631: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-suv.jpg",
    name: "Dedicated Cruise SUV",
    description:
      "Arrivée avec élégance. La SUV permet un trajet agréable et garantit à la fois confort et sécurité. Commencez vos vacances par une touche spéciale: un transfert luxueux avec chauffeur jusqu’à votre hébergement.",
  },
  632: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/economic-private.jpg",
    name: "China Private Transfer (economy)",
    description:
      "Just as quick, and just as private, but fewer features than we offer on our standard and premium transfers that you might not need. Whichever private transfer you choose, there will be no waiting in public airport taxi queues or tiresome bus transfers.",
  },
  633: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Meet and Greet - Shuttle Transfer",
    description:
      "Les navettes sont un moyen de transport rapide, efficace et peu coûteux qui vous permet d'atteindre et de quitter votre logement dans une ville ou dans un pays inconnu(e). Lorsque vous utilisez un service de navette réservée à l'avance, vous êtes immédiatement assuré de parvenir directement à votre logement.  N'oubliez pas qu'il est possible que vous deviez attendre votre navette à l'aéroport car celles-ci fonctionnent selon des horaires. Temps d'attente maximal 1 heure. Les temps de transferts ne sont que des estimations et dépendent de la circulation et du nombre des arrêts effectués.",
  },
  634: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.jpg",
    name: "Meet and Greet - Private Transfer",
    description:
      "À votre arrivée à l'aéroport de votre choix, votre objectif est de rejoindre votre logement aussi rapidement et facilement que possible. Pourquoi ne pas arriver en grande pompe en réservant l'un de nos transferts privés (taxi/minibus). Ne perdez pas de temps dans les files d'attente à des arrêts de taxi ou de bus, passez devant les foules et savourez votre première boisson avant même que la plupart des voyageurs n’aient quitté l’aéroport.",
  },
  635: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "CTRIP - Private Transfer",
    description:
      "À votre arrivée à l'aéroport de votre choix, votre objectif est de rejoindre votre logement aussi rapidement et facilement que possible. Pourquoi ne pas arriver en grande pompe en réservant l'un de nos transferts privés (taxi/minibus). Ne perdez pas de temps dans les files d'attente à des arrêts de taxi ou de bus, passez devant les foules et savourez votre première boisson avant même que la plupart des voyageurs n’aient quitté l’aéroport.",
  },
  636: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "CTRIP - Minivan",
    description:
      "Réservez à l'avance l'un de nos véhicules plutôt que d'attendre plusieurs taxis. Une manière idéale de parvenir facilement et avec élégance à votre logement. Votre véhicule vous attendra à l’arrivée et vous amènera à votre logement en facilitant la poursuite de votre voyage. Nous pouvons prendre en charge des groupes de toutes tailles.",
  },
  637: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "CTRIP - Private Transfer (comfort)",
    description:
      "À votre arrivée à l'aéroport de votre choix, votre objectif est de rejoindre votre logement aussi rapidement et facilement que possible. Pourquoi ne pas arriver en grande pompe en réservant l'un de nos transferts privés (taxi/minibus). Ne perdez pas de temps dans les files d'attente à des arrêts de taxi ou de bus, passez devant les foules et savourez votre première boisson avant même que la plupart des voyageurs n’aient quitté l’aéroport.",
  },
  638: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/economic-private.jpg",
    name: "CTRIP - Private Transfer (economy)",
    description:
      "Just as quick, and just as private, but fewer features than we offer on our standard and premium transfers that you might not need. Whichever private transfer you choose, there will be no waiting in public airport taxi queues or tiresome bus transfers.",
  },
  639: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/coach.png",
    name: "CTRIP - Coach",
    description:
      "Pour ceux qui souhaitent voyager au sein de très grands groupes et éviter les tracas et le stress d'attendre plusieurs taxis à l’aéroport.  En réservant un transfert par bus, vous atteindrez votre destination en même temps que les membres de votre groupe et dans le confort afin que vous puissiez démarrer vos vacances ensemble et de bonne humeur.",
  },
  640: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "CTRIP - Private Transfer (luxury)",
    description:
      "Pour un véhicule qui offre plus d'avantages qu'un taxi standard, la voiture de luxe est plus spacieuse et plus confortable pour votre transfert. Votre chauffeur personnel vous attendra dès votre arrivée et vous escortera jusqu'à votre véhicule pour vous acheminer rapidement et efficacement.",
  },
  641: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-minivan.jpg",
    name: "CTRIP - Luxury Minivan",
    description:
      "Réservez à l'avance l'un de nos véhicules plutôt que d'attendre plusieurs taxis. Une manière idéale de parvenir facilement et avec élégance à votre logement. Votre véhicule vous attendra à l’arrivée et vous amènera à votre logement en facilitant la poursuite de votre voyage. Nous pouvons prendre en charge des groupes de toutes tailles.",
  },
  642: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "CTRIP - Minivan (Chinese speaking driver)",
    description:
      "Pour une grande famille ou un groupe qui souhaite voyager ensemble. Réservez à l’avance un grand véhicule pour votre groupe plutôt que d’attendre plusieurs taxis. C’est une façon idéale de vous rendre à votre hébergement d’une manière aisée et agréable. Votre véhicule vous attendra à l’arrivée et vous amènera à votre hébergement. La poursuite de votre voyage se fera ainsi sans problème.",
  },
  643: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "CTRIP - Private Transfer (Chinese speaking driver)",
    description:
      "À votre arrivée à l'aéroport de votre choix, votre objectif est de rejoindre votre logement aussi rapidement et facilement que possible. Pourquoi ne pas arriver en grande pompe en réservant l'un de nos transferts privés (taxi/minibus). Ne perdez pas de temps dans les files d'attente à des arrêts de taxi ou de bus, passez devant les foules et savourez votre première boisson avant même que la plupart des voyageurs n’aient quitté l’aéroport.",
  },
  644: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/premier.jpg",
    name: "CTRIP - Premier Service",
    description:
      "Vous souhaitez plus qu’un véhicule standard? Pourquoi ne pas vous offrir un petit plus en réservant notre ‘Service Premier’, moyennant un supplément assez modeste. Vous bénéficierez alors d’un véhicule plus spacieux et donc d’un confort absolu pendant votre transfert. Vous serez accueilli par votre chauffeur personnel à l’arrivée et accompagné jusqu’au véhicule, qui vous attend pour un transfert rapide et efficace.",
  },
  645: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/mercedes-s-class.png",
    name: "CTRIP - Mercedes E-Class (or similar)",
    description:
      "Cette voiture est idéale pour les hommes d'affaires ou les personnes désirant mieux qu'un taxi standard; la Mercedes (ou comparable) est une voiture luxueuse et confortable qui vous mènera à destination avec style. Vous serez conduit à votre hôtel dès votre arrivée afin que vous puissiez démarrer votre séjour sur un bon pied dès votre arrivée.",
  },
  646: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/mercedes-s-class.png",
    name: "CTRIP - Mercedes S-Class (or similar)",
    description:
      "Cette voiture est idéale pour les hommes d'affaires ou les personnes désirant mieux qu'un taxi standard; la Mercedes S de classe affaires (ou comparable) est une voiture grand luxe qui vous mènera sûrement à destination avec style. Votre chauffeur vous conduira à votre voiture et vous accompagnera à votre hôtel afin que vous puissiez démarrer votre séjour sur un bon pied dès votre arrivée.",
  },
  647: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/mercedes-s-class.png",
    name: "CTRIP - Mercedes S Class",
    description:
      "Cette voiture est idéale pour les voyageurs d’affaires ou pour tous ceux qui désirent plus qu’un taxi standard; La Mercedes classe S executive est une voiture de luxe confortable qui vous amènera jusqu'à votre destination en grande pompe. Vous chauffeur vous escortera à votre voiture et vous amènera jusqu'à votre logement en vous offrant les prémices d’un excellent voyage.",
  },
  648: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/suv.jpg",
    name: "CTRIP - SUV",
    description:
      "Arrivée avec élégance. La SUV permet un trajet agréable et garantit à la fois confort et sécurité. Commencez vos vacances par une touche spéciale: un transfert luxueux avec chauffeur jusqu’à votre hébergement.",
  },
  649: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "CTRIP - Luxury Minivan (with guide)",
    description:
      "'Dès votre arrivée dans l'aéroport de votre choix, votre but est de rejoindre votre lieu d'hébergement aussi vite que possible et en toute sérénité. Pourquoi ne pas arriver avec style en réservant l'un de nos services de transfert Superior (taxi/minibus) qui comprend un assistant linguistique qui vous accompagnera pendant votre transfert. Pas plus de longues files d'attente aux stations de taxis, ni de transferts en bus fatigants&nbsp;: ayez une longueur d'avance sur les hordes de touristes et installez-vous dans votre hôtel avant que la foule ait quitté l'aéroport.",
  },
  650: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/eco.jpg",
    name: "CTRIP - Eco Car",
    description:
      "'Ceux qui veulent voyager de manière plus écologique et réduire les émissions de carbone pourront réserver la voiture écologique électrique Nissan Leaf, idéale pour eux. Avec zéro émission de carbone, ce véhicule recyclable à 95&nbsp;% vous offre une conduite confortable et plus écologique.",
  },
  651: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "CTRIP - Coach Meet and Greet",
    description:
      "For those wishing to travel in very large groups and want to avoid the hassle and stress of waiting for several taxis at the airport. By booking a coach transfer you and your party will reach your destination at the same time and in comfort so you can all start your holiday together and in high spirits.",
  },
  652: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/mercedes-s-class.png",
    name: "CTRIP - Mer S Class (max 2 adults and 1 child)",
    description:
      "PLEASE NOTE: ONLY 1 PIECE OF LUGGAGE PERMITTED PER ADULT.  This car is ideal for business people or those who want more than a standard taxi; the Mercedes S class executive is a highly luxurious car and will certainly get you to your destination in style. Your driver will escort you to your car and take you to your accommodation for an arrival sure to start your trip off on a good footing.",
  },
  653: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "CTRIP - Minivan Meet and Greet",
    description:
      "Réservez à l'avance l'un de nos véhicules plutôt que d'attendre plusieurs taxis. Une manière idéale de parvenir facilement et avec élégance à votre logement. Votre véhicule vous attendra à l’arrivée et vous amènera à votre logement en facilitant la poursuite de votre voyage. Nous pouvons prendre en charge des groupes de toutes tailles.",
  },
  654: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "CTRIP - Private Transfer Meet and Greet",
    description:
      "A l'arrivée dans votre aéroport, votre but est d'arriver à votre hébergement le plus rapidement possible, avec le moins de problèmes. Pourquoi ne pas arriver avec style en réservant l'un de nos transferts privés (taxi/minibus). Pas de longues files d'attente aux bornes de taxis ni de transferts fatigants en autocar, évitez les foules et profitez de votre premier verre avant que les autres aient quitté l'aéroport.",
  },
  655: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "CTRIP - Private Transfer (with guide)",
    description:
      "À votre arrivée à l'aéroport de votre choix, votre objectif est de rejoindre votre logement aussi rapidement et facilement que possible. Pourquoi ne pas arriver en grande pompe en réservant l'un de nos transferts privés (taxi/minibus). Ne perdez pas de temps dans les files d'attente à des arrêts de taxi ou de bus, passez devant les foules et savourez votre première boisson avant même que la plupart des voyageurs n’aient quitté l’aéroport.",
  },
  656: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-car.png",
    name: "CTRIP - Luxury Car Meet and Greet",
    description:
      "Pour un véhicule qui offre plus d'avantages qu'un taxi standard, la voiture de luxe est plus spacieuse et plus confortable pour votre transfert. Votre chauffeur personnel vous attendra dès votre arrivée et vous escortera jusqu'à votre véhicule pour vous acheminer rapidement et efficacement.",
  },
  657: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/cuban_classic_car.jpg",
    name: "Cuban Classic Car",
    description:
      "Pourquoi ne pas voyager dans le style cubain dans l'un de nos ancêtres cubains classiques, datant du début de l'embargo américain dans les années 1950? Ce service comprend l’accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation .Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  658: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/speedyshuttle.jpg",
    name: "Transferts en Navette Rapide ",
    description:
      "Il s'agit d'un service partagé rentable et rapide avec d'autres passagers, vous aurez moins d'arrêts qu'une navette normal.Les navettes rapides circulent entre l'aéroport et l'hébergement, à l'exclusion d'une adresse privée Vous serez déposé / pris à votre hôtel ou le plus proche point d'accès.Votre temps de transfert est estimé mais peut varier selon le trafic et le nombre d'arrêts effectués.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.Pour plus d'informations sur la localisation de votre transfert et pour connaître votre heure de prise en charge, consultez votre bon de réservation. ",
  },
  659: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Shuttle Transfer and 6 Day Lift Pass Package",
    description:
      "Shuttles are shared with other passengers. Please be aware that the shuttle runs to a strict scheduled timetable and as such there may be a wait for your shuttle at the airport. Transfer times are approximate and dependent upon traffic and number of stops made. If your flight lands within 1-1.5 hours of the last shuttle, we cannot be responsible if you do not connect with the shuttle in time.\r\nDELIVERY OF PASS: Your 6 Day Grandvalira Lift Pass will be delivered to your accommodation on your arrival day. No requirement to queue or collect, your pass will be ready for you to use from the first morning of your ski holiday. Contact details will be same as current shuttle info",
  },
  660: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Road Taxi - Private Transfer",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  661: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Road Taxi - Minivan",
    description:
      "Votre transfert de porte-à-porte professionnel en Mini-van est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  662: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Road Taxi / Water Taxi - Private Transfer",
    description:
      "Ce transfert professionnel privé de service taxi-bateau est unique aux canaux de Venise, vous pourrez jouir du cadre qui vous entoure et vous décontracter pendant votre transfert. Vous serez pris en charge á l'aéroport par une voiture privée et emmené à Piazzale Roma où votre bateau-taxi privé vous attendra. Vous serez déposé / récupéré au point d'accès le plus proche de votre logement. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. Dans la plupart des aéroports, ce service comprend l’accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Pour plus d'informations sur la localisation de votre transfert, consultez votre bon de réservation.",
  },
  663: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/coach.png",
    name: "Economy Coach",
    description:
      "Votre transfert professionnel de porte-à-porte en Autocar est une option plus économique. Parfait pour un large groupe de voyageur D'autres instructions et l'emplacement de votre chauffeur sont clairement décrits sur votre bon de réservation et votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. S'il vous plaît, lisez vos instructions de réservation pour plus d'informations.",
  },
  664: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/serenella.jpg",
    name: "Water Limousine - Deluxe Service",
    description:
      "Notre service transfert de luxe vous permet de profiter de Serenella, un bateau de luxe haut de gamme avec des finitions en bois de haute qualité et des intérieurs élégants en cuir. Votre transfert comprend un service de portage complet et un guide de l'aéroport jusqu'à la porte de votre hôtel.Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation . Les bateaux-taxis circulent entre l'aéroport et le point d'embarquement le plus proche de votre hébergement. Vous serez déposé / ramassé à partir du point d’accès le plus proche.\nVotre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  665: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Minivan including ferry ticket",
    description:
      "Votre transfert professionnel de porte à porte en Mini-van, comprend le billet de ferry pour la traversée de / vers votre destination, aussi idéal pour un grand groupe de voyageurs. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.S'il vous plaît noter que ce transfert comprend le coût de votre traversée en ferry, mais vous êtes responsable de vérifier votre horaire de ferry par rapport à votre vol et les horaires de ramassage car ils courent à un horaire restreint.",
  },
  666: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/autostradale-logo.jpg",
    name: "Autostradale",
    description:
      "Ce service peu coûteux et non remboursable relie l'aéroport à un point de chute du centre-ville. Si vous souhaitez être déposé à votre hôtel, veuillez opter pour un produit Shuttle ou Private Transfer. Les conditions générales de voyage applicables aux opérateurs s'appliquent et ce produit est exempté des conditions générales du groupe HolidayTaxis.",
  },
  667: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/aerobus-logo.jpg",
    name: "Aerobus",
    description:
      "Ce service peu coûteux et non remboursable relie l'aéroport à un point de chute du centre-ville. Si vous souhaitez être déposé à votre hôtel, veuillez opter pour un produit Shuttle ou Private Transfer. Les conditions générales de voyage applicables aux opérateurs s'appliquent et ce produit est exempté des conditions générales du groupe HolidayTaxis.",
  },
  668: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/prague-airport-transfers.jpg",
    name: "Prague Transport Aéroport",
    description:
      " This low cost, non refundable bus service will take you from the airport to a city centre drop off point. If you want to be dropped off at your hotel, please change to a Shuttle or Private Transfer Product. Operators' terms and conditions to travel apply and this product is exempt from standard HolidayTaxis Group terms. Your ticket to travel will be delivered directly to your personal email address.",
  },
  669: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/magicalshuttle-logo.jpg",
    name: "Magical Shuttle",
    description:
      "Ce service peu coûteux et non remboursable relie l'aéroport à un point de chute du centre-ville. Si vous souhaitez être déposé à votre hôtel, veuillez opter pour un produit Shuttle ou Private Transfer. Les conditions générales de voyage applicables aux opérateurs s'appliquent et ce produit est exempté des conditions générales du groupe HolidayTaxis.",
  },
  670: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Alilaguna",
    description:
      "L'Alilaguna est un transport public effectué en taxi- bateau. \nLes termes et conditions de voyage des opérateurs s'appliquent et ce produit est exempté des conditions standard du groupe HolidayTaxis. Des bateaux directs opèrent de l'aéroport Marco Polo à la place San Marco. Les itinéraires interconnectés vous permettent de rejoindre n'importe quel endroit de la ville à quelques minutes des différents arrêts, tous équipés de salles d'attente confortables. Veuillez noter que ce service n'inclut pas de dépôt directement à votre hôtel. Tous les détails seront imprimés sur votre coupon.",
  },
  671: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Road Taxi - Private Transfer Meet and Greet",
    description:
      "Upon arrival into your chosen airport, your goal is to reach your accommodation as quickly and hassle free as possible. Why not arrive in style by booking one of our  private meet & greet service transfers (taxi/minibus) where your driver will meet you at the arrivals hall with a Travel Counsellors branded sign board and escort you to your waiting vehicle. No waiting in long public airport taxi queues or tiresome bus transfers, get ahead of the hordes and enjoy your first drink before the masses have left the airport.",
  },
  672: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Road Taxi - Shared Transfer",
    description:
      "Il s'agit d'un service partagé rentable avec d'autres passagers. Les navettes circulent entre l'aéroport et l'hébergement à l'exclusion d' une adresse privée. Vous serez déposé / pris à votre hôtel ou le plus proche point d'accès. Votre temps de transfert est estimé mais peut varier selon le trafic et le nombre d'arrêts effectués. Il peut y avoir une attente à l'aéroport ou un point de ramassage car les bus fonctionnent selon des horaires définis. Pour plus d'informations sur la localisation de votre transfert et pour connaître votre heure de prise en charge, consultez votre bon de réservation.",
  },
  673: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Road Taxi - Wheelchair Adapted Vehicle",
    description:
      "Ce véhicule est adapté avec un hayon arrière ou une rampe pour fournir à ceux qui utilisent un fauteuil roulant avec un transfert sans tracas. Il n'est pas nécessaire de sortir de votre fauteuil roulant et votre chauffeur vous aidera dans et hors du véhicule si vous en avez besoin. Pour un transfert en toute sécurité et vous sécuriser le mieux possible, il y a aussi des sangles dans le véhicule. (S'il vous plaît noter: ce véhicule ne peut accueillir qu'un seul fauteuil roulant).\nDans la plupart des aéroports, ce service comprend «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  674: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Executive Sedan",
    description:
      "Le transfert porte-à-porte exclusif est un service supérieur qui est fourni dans une Mercedes Class S ou équivalent . Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  675: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-minivan.jpg",
    name: "Business Minivan",
    description:
      "Votre transfert d'affaire de porte à porte est fourni dans un Mini-van de grande qualité, idéal pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  676: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Etihad - Private Transfer",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  677: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/mercedes-e-class.png",
    name: "Etihad - Executive Sedan",
    description:
      "Votre transfert professionnel de porte à porte de luxe et fourni avec une Mercedes Class E/S ou équivalent. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  678: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-car.png",
    name: "Etihad - Luxury Sedan",
    description:
      "Votre transfert professionnel de porte à porte de luxe et fourni avec une Mercedes Class E/S ou équivalent. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  679: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxuryminivan.png",
    name: "Etihad - Business Van",
    description:
      "Le transfert de porte-à-porte de Luxe est fourni dans un Mini-Van de haute qualité. Parfait pour un grand groupe de personnes.Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  680: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Etihad - Minivan",
    description:
      "Votre transfert de porte-à-porte professionnel en Mini-van est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  681: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minibus.png",
    name: "Etihad - Minibus",
    description:
      "Votre transfert professionnel de porte à porte en minibus est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  682: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/suv.jpg",
    name: "Etihad - SUV",
    description:
      "Votre transfert professionnel de porte à porte avec un véhicule utilitaire de sport SUV. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  683: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle.jpg",
    name: "Etihad - Shuttle Transfer",
    description:
      "Il s'agit d'un service partagé rentable avec d'autres passagers. Les navettes circulent entre l'aéroport et l'hébergement à l'exclusion d' une adresse privée. Vous serez déposé / pris à votre hôtel ou le plus proche point d'accès. Votre temps de transfert est estimé mais peut varier selon le trafic et le nombre d'arrêts effectués. Il peut y avoir une attente à l'aéroport ou un point de ramassage car les bus fonctionnent selon des horaires définis. Pour plus d'informations sur la localisation de votre transfert et pour connaître votre heure de prise en charge, consultez votre bon de réservation.",
  },
  684: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/speedyshuttle.jpg",
    name: "Etihad - Speedy Shuttle (max. 4 stops)",
    description:
      "Il s'agit d'un service partagé rentable avec d'autres passagers, fait jusqu’à 4 arrêts. Vous serez déposé / pris à votre hôtel ou le plus proche point d'accès. Votre temps de transfert est estimé mais peut varier selon le trafic et le nombre d'arrêts effectués. Il peut y avoir une attente à l'aéroport ou un point de ramassage car les bus fonctionnent selon des horaires définis. Pour plus d'informations sur la localisation de votre transfert et pour connaître votre heure de prise en charge, consultez votre bon de réservation.",
  },
  685: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/coach.png",
    name: "Etihad - Coach",
    description:
      "Votre transfert professionnel de porte à porte en autocar est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l’accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  686: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Etihad - Private M.P.V. Transfer",
    description:
      "Pour votre transfert porte-à-porte professionnel en véhicule Multi-usages, idéal pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  687: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/economic-private.jpg",
    name: "Etihad - Economy Private Transfer",
    description:
      "Ce transfert professionnel de porte à porte privé et économique  est votre option privée qui vous permet de bénéficier d'un budget réduit\nD'autres instructions et l'emplacement de votre chauffeur sont clairement décrits sur votre bon de réservation et votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.  S'il vous plaît, lisez vos instructions de réservation pour plus d'informations.",
  },
  688: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Etihad - Economy Minivan",
    description:
      "Votre transfert professionnel de porte à porte en Mini-van économique est votre option privée qui vous permet de bénéficier d'un budget réduit. Parfait pour un plus grand groupe de personnes. Votre temps de prise en charge est calculé automatiquement en fonction des détails de votre vol. S'il vous plaît, lisez vos instructions de réservation pour plus d'informations.",
  },
  689: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/coach.png",
    name: "Etihad - Economy Coach",
    description:
      "Votre transfert professionnel de porte-à-porte en Autocar est une option plus économique. Parfait pour un large groupe de voyageur D'autres instructions et l'emplacement de votre chauffeur sont clairement décrits sur votre bon de réservation et votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. S'il vous plaît, lisez vos instructions de réservation pour plus d'informations.",
  },
  690: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/water-taxi.jpg",
    name: "Etihad - Water Taxi",
    description:
      "Ce transfert professionnel privé de service taxi-bateau est unique aux canaux de Venise. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Les bateaux-taxis circulent entre l'aéroport et le point d'embarquement le plus proche de votre hébergement. Vous serez déposé / ramassé à partir du point d’accès le plus proche. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  691: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Etihad - Road Taxi",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  692: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/limo.jpg",
    name: "Etihad - Limousine",
    description:
      "La limousine est un service de luxe, qui comprend l'accueil, vous voyagerez dans une limousine haut de gamme. Dans la plupart des aéroports, votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  693: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/wheelchair-taxi.jpg",
    name: "Etihad - Wheelchair Adapted Vehicle",
    description:
      "Ce véhicule est adapté avec un hayon arrière ou une rampe pour fournir à ceux qui utilisent un fauteuil roulant avec un transfert sans tracas. Il n'est pas nécessaire de sortir de votre fauteuil roulant et votre chauffeur vous aidera dans et hors du véhicule si vous en avez besoin. Pour un transfert en toute sécurité et vous sécuriser le mieux possible, il y a aussi des sangles dans le véhicule. (S'il vous plaît noter: ce véhicule ne peut accueillir qu'un seul fauteuil roulant).\nDans la plupart des aéroports, ce service comprend «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  694: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Etihad - Road Minivan",
    description:
      "Votre transfert de porte-à-porte professionnel en Mini-van est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  695: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/water-taxi.jpg",
    name: "Etihad - Shared Water Taxi",
    description:
      "Il s'agit d'un service partagé rentable et rapide qui est unique aux canaux de Venise; vous voyagerez avec d'autres passagers. Votre temps de transfert est estimé mais peut varier en fonction du nombre d'arrêts effectués. Vous trouverez plus d'informations sur la localisation de votre transfert ainsi que l'heure de récupération sur votre bon de réservation. IMPORTANT : Les bateaux s'arrêtent à la Place Saint-Marc et dans le quartier du Rialto, d'où vous pourrez rejoindre votre hôtel/hébergement à pied. Sachez qu'il peut y avoir un délai d'attente à l'aéroport puisque les bateaux partent toutes les 1h30 de 6h á 19h . Il est important que nous ayons un numéro de téléphone portable où vous joindre en cas de nécessité. Veuillez l'inscrire au cours de la procédure de réservation.",
  },
  696: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/suv.jpg",
    name: "Etihad - Luxury SUV",
    description:
      "Votre transfert professionnel de porte à porte de Luxe en SUV. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  697: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-private.png",
    name: "Etihad - Luxury Eco Car",
    description:
      "Your Luxury Eco-Friendly door to door transfer is a zero-emission vehicle. Perfect for the environmentally conscious traveller wanting to travel in style. In most airports, this service includes ‘Meet & Greet’ and your driver will be waiting for you in the arrival hall holding a sign with your name on it. Further information on how to locate your transfer can be found on your booking voucher. When returning to the airport your driver will be waiting for you at your pick-up location. Your pick-up time is calculated automatically based on your flight details. ",
  },
  698: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/eco.jpg",
    name: "Etihad - Eco Car",
    description:
      "Votre transfert porte-à-porte éco-responsable est un véhicule zéro émission qui est parfait pour un transport confortable et plus vert. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  699: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/suv.jpg",
    name: "Etihad - Economy SUV",
    description:
      "Ce transfert professionnel de porte-à-porte en SUV est votre option privée économique. D'autres instructions et l'emplacement de votre chauffeur sont clairement décrits sur votre bon de réservation et votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. S'il vous plaît, lisez vos instructions de réservation pour plus d'informations.",
  },
  700: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/coach.png",
    name: "Etihad - Coach (English speaking assistant)",
    description:
      "Votre transfert professionnel de porte à porte en autocar est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l’accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. Ce service est fourni avec un assistant anglophone.",
  },
  701: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minibus.png",
    name: "Etihad - Minivan (English speaking assistant)",
    description:
      "Votre transfert de porte-à-porte professionnel en Mini-van est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. Ce service est fourni avec un assistant anglophone.",
  },
  702: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Etihad - Private Transfer (English speaking Asst.)",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. Ce service est fourni avec un assistant anglophone.",
  },
  703: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/water-taxi.jpg",
    name: "Etihad - Water Taxi Deluxe",
    description:
      "Ce transfert professionnel de Luxe de service taxi-bateau est un transport de haute qualité et, est aussi unique aux canaux de Venise. Vous serez accueilli par votre propre chauffeur anglophone à votre arrivée et escorté jusqu'à votre véhicule, où un porteur vous assistera avec vos bagages pour un transfert rapide et efficace. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Les bateaux-taxis circulent entre l'aéroport et le point d'embarquement le plus proche de votre hébergement. Vous serez déposé / ramassé à partir du point d’accès le plus proche. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  704: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/water-taxi.jpg",
    name: "Etihad - Water Taxi Superior",
    description:
      "Ce transfert en taxi-bateau est un transport de haute qualité unique aux canaux de Venise. Vous serez accueilli par votre propre chauffeur anglophone à votre arrivée et escorté jusqu'à votre hôtel. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Les bateaux-taxis circulent entre l'aéroport et le point d'embarquement le plus proche de votre hébergement. Vous serez déposé / ramassé à partir du point accessible le plus proche. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  705: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Etihad - Private Transfer (English speak. driver)",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. Ce service est fourni avec un chauffeur anglophone.",
  },
  706: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Etihad - Minivan (English speaking driver)",
    description:
      "Votre transfert de porte-à-porte professionnel en Mini-van est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. Ce service est fourni avec un chauffeur anglophone.",
  },
  707: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Stansted Citylink",
    description:
      "Stansted City Link is a public transport bus which runs between London Stansted and Kings Cross St Pancras only. From here you can make your way to hotel accommodation easily by other public transport means.\r\nThis product is non refundable.\r\n\r\nOperators terms and conditions to travel apply and this product is exempt from standard HolidayTaxis Group terms",
  },
  708: {
    imageurl:
      "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/bmw 7 Series WHITE RESIZED.jpg",
    name: "Priority Limousine - BMW 7 Series",
    description:
      "The Bangkok Priority Limousine is an exclusive service provided by the official Airport of Thailand Authority.\r\nYou will receive a priority service which assures you a quicker departure from the airport than that possible through our standard transfers.",
  },
  709: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/toyota camry_LICENSED.jpg",
    name: "Priority Limousine - Toyota Camry",
    description:
      "The Bangkok Priority Limousine is an exclusive service provided by the official Airport of Thailand Authority.\r\nYou will receive a priority service which assures you a quicker departure from the airport than that possible through our standard transfers.",
  },
  710: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/toyota commuter.jpg",
    name: "Priority Limousine - Toyota Commuter",
    description:
      "The Bangkok Priority Limousine is an exclusive service provided by the official Airport of Thailand Authority.\r\nYou will receive a priority service which assures you a quicker departure from the airport than that possible through our standard transfers.\r\n",
  },
  711: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/Isuzu MuX.png",
    name: "Priority Limousine -  Isuzu MuX",
    description:
      "The Bangkok Priority Limousine is an exclusive service provided by the official Airport of Thailand Authority.\r\nYou will receive a priority service which assures you a quicker departure from the airport than that possible through our standard transfers.",
  },
  712: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/Torres Bus.png",
    name: "Torres Bus",
    description:
      "Ce service peu coûteux et non remboursable relie l'aéroport à un point de chute du centre-ville. Si vous souhaitez être déposé à votre hôtel, veuillez opter pour un produit Shuttle ou Private Transfer. Les conditions générales de voyage applicables aux opérateurs s'appliquent et ce produit est exempté des conditions générales du groupe HolidayTaxis.",
  },
  713: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/Mercedes-C-Class.jpg",
    name: "Mercedes C- Class",
    description:
      "Your professional door to door Luxury transfer is provided in a Mercedes C Class. In most airports, this service includes ‘Meet & Greet’ and your driver will be waiting for you in the arrival hall holding a sign with your name on it. Further information on how to locate your transfer can be found on your booking voucher. When returning to the airport your driver will be waiting for you at your pick-up location. Your pick-up time is calculated automatically based on your flight details.",
  },
  714: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Mercedes Transfer BA",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  715: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Minivan not including ferry ticket",
    description:
      "Your professional door to door Minivan transfer is perfect for a larger group. In most airports, this service includes ‘Meet & Greet’ and your driver will be waiting for you in the arrival hall holding a sign with your name on it. Further information on how to locate your transfer can be found on your booking voucher. When returning to the airport your driver will be waiting for you at your pick-up location. Your pick-up time is calculated automatically based on your flight details.\r\nPlease note that this transfer does not include the cost of ferry tickets.",
  },
  716: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Private Transfer VIP Meet & Greet with Fast Track",
    description:
      "You will be met at the arrivals gate by our representative who will escort you through fast track immigration and from there provide luggage assistance to the airport representative in the terminal who will then take you on to your vehicle\r\n\r\nFast track immigration usually takes around 15 minutes but can take longer depending on customers nationality, passport, type of visa etc. Service is not available with Visa on Arrival. Service not available for customer travelling on Pakistani, Turkish or Bangladeshi passports\r\n\r\nWhen returning to the airport you will be met in the lobby of your hotel by our representative who will then take you to the airport and provide assistance at the check in counter and through departure immigration and security",
  },
  717: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Minivan VIP Meet & Greet with Fast Track",
    description:
      "You will be met at the arrivals gate by our representative who will escort you through fast track immigration and from there provide luggage assistance to the airport representative in the terminal who will then take you on to your vehicle\r\n\r\nFast track immigration usually takes around 15 minutes but can take longer depending on customers nationality, passport, type of visa etc. Service is not available with Visa on Arrival. Service not available for customer travelling on Pakistani, Turkish or Bangladeshi passports\r\n\r\nWhen returning to the airport you will be met in the lobby of your hotel by our representative who will then take you to the airport and provide assistance at the check in counter and through departure immigration and security",
  },
  718: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Private Transfer VIP all-inclusive (Fast Track)",
    description:
      "You will be met at the arrivals gate by our representative who will escort you through fast track immigration and from there provide luggage assistance to the airport representative in the terminal who will then take you on to your vehicle\r\n\r\nFast track immigration usually takes around 15 minutes but can take longer depending on customers nationality, passport, type of visa etc. Service is not available with Visa on Arrival. Service not available for customer travelling on Pakistani, Turkish or Bangladeshi passports\r\n\r\nWhen returning to the airport you will be met in the lobby of your hotel by our representative who will then take you to the airport and provide assistance at the check in counter and through departure immigration and security\r\n\r\nThis service is provided with an English speaking assistant\r\n\r\nPlease note that this transfer includes the cost of your ferry crossing, but you are responsible for checking your ferry timetable in relation to your flight and pick up times as they run to a restricted schedule",
  },
  719: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Minivan VIP all-inclusive (Fast Track)",
    description:
      "You will be met at the arrivals gate by our representative who will escort you through fast track immigration and from there provide luggage assistance to the airport representative in the terminal who will then take you on to your vehicle\r\n\r\nFast track immigration usually takes around 15 minutes but can take longer depending on customers nationality, passport, type of visa etc. Service is not available with Visa on Arrival. Service not available for customer travelling on Pakistani, Turkish or Bangladeshi passports\r\n\r\nWhen returning to the airport you will be met in the lobby of your hotel by our representative who will then take you to the airport and provide assistance at the check in counter and through departure immigration and security\r\n\r\nThis service is provided with an English speaking assistant\r\n\r\nPlease note that this transfer includes the cost of your ferry crossing, but you are responsible for checking your ferry timetable in relation to your flight and pick up times as they run to a restricted schedule",
  },
  720: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Private Transfer with Fast Track Service",
    description:
      "You will be met at the arrivals hall by our representative who will escort you to your vehicle\r\n\r\nFast track immigration usually takes around 15 minutes but can take longer depending on customers nationality, passport, type of visa etc. Service is not available with Visa on Arrival. Service not available for customer travelling on Pakistani, Turkish or Bangladeshi passports\r\n\r\nWhen returning to the airport you will be met in the lobby of your hotel by our driver who will then take you to the airport. After checking in, a representative will escort you to the VIP lounge and onward through fast track immigration",
  },
  721: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Minivan with Fast Track Service",
    description:
      "You will be met at the arrivals hall by our representative who will escort you to your vehicle\r\n\r\nFast track immigration usually takes around 15 minutes but can take longer depending on customers nationality, passport, type of visa etc. Service is not available with Visa on Arrival. Service not available for customer travelling on Pakistani, Turkish or Bangladeshi passports\r\n\r\nWhen returning to the airport you will be met in the lobby of your hotel by our driver who will then take you to the airport. After checking in, a representative will escort you to the VIP lounge and onward through fast track immigration",
  },
  722: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/Le Bus Direct-min.png",
    name: "Le Bus Direct",
    description:
      "Ce service peu coûteux et non remboursable relie l'aéroport à un point de chute du centre-ville. Si vous souhaitez être déposé à votre hôtel, veuillez opter pour un produit Shuttle ou Private Transfer. Les conditions générales de voyage applicables aux opérateurs s'appliquent et ce produit est exempté des conditions générales du groupe HolidayTaxis.",
  },
  723: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Mercedes Minivan BA",
    description:
      "Your professional door to door Mercedes Minivan transfer is perfect for a larger group. This service includes ‘Meet & Greet’ and your driver will be waiting for you in the arrival hall holding a sign with your name on it. Further information on how to locate your transfer can be found on your booking voucher. When returning to the airport your driver will be waiting for you at your pick-up location. Your pick-up time is calculated automatically based on your flight details",
  },
  724: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Helicopter Saint Lucia",
    description:
      "This helicopter transfer service is available from Hewanorra International Airport to the following hotels or vice versa; The BodyHoliday, Calabash Cove, Cap Maison, Rendezvous, The Landings, Sandals Grande St Lucian Spa Resort, Sandals Halcyon Beach, Sandals La Toc Golf Resort and Spa, Eastwinds.\r\n\r\nHelicopters depart from the main airport at Hewanorra and land at the helipad in the north of the island. Each helicopter can seat up to 6 people, if you're booking for less than this the helicopter may be shared with others. Price includes a private car transfer from the helipad to or from your hotel.\r\n\r\nPlease note your luggage will be transported separately by car and will arrive some while later at your hotel. Please note that on departure the luggage is collected earlier in the day.\r\n\r\nPassengers are allowed one carry-on bag and one personal item (hand bag or equivalent). Dimensions for carry-on length 24 inches, Width 16 inches, height 10 inches. Maximum weight for carry-on bag + personal item is 20 kg",
  },
  725: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Minivan (Eurowings)",
    description:
      "Votre transfert de porte-à-porte professionnel en Mini-van est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  726: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/Private Transfer (4x4).jpg",
    name: "Private Transfer (4x4)",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  727: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/skybus (1).jpg",
    name: "SkyBus",
    description:
      "This low cost, non refundable bus service will take you from the airport to a city centre drop off point. No hotel drop off is included.\r\nOperators terms and conditions to travel apply and this product is exempt from standard HolidayTaxis Group terms.\r\nYour ticket to travel will be delivered directly to your personal email address.",
  },
  728: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/botg_private.jpg",
    name: "Transfert privé",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  729: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/Con-X-ion (1).jpg",
    name: "ConXion",
    description:
      "This low cost, non refundable bus service will take you from the airport to a city centre drop off point. No hotel drop off is included.\r\nOperators terms and conditions to travel apply and this product is exempt from standard HolidayTaxis Group terms.\r\nYour ticket to travel will be delivered directly to your personal email address.",
  },
  730: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Private Transfer including Water Taxi (BA)",
    description:
      "Your professional door to door private transfer, includes a private Water Taxi to cross to/from your destination. Further information on transfer can be found on your booking voucher.\r\nYour pick-up time is calculated automatically based on your flight details.",
  },
  731: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-car.png",
    name: "GoGlobal - Private transfer",
    description:
      "Votre transfert professionnel de porte à porte de luxe et fourni avec une Mercedes Class E/S ou équivalent. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  732: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/economic-private.jpg",
    name: "Transfert privé Économie",
    description:
      "Ce transfert professionnel de porte à porte privé et économique  est votre option privée qui vous permet de bénéficier d'un budget réduit\nD'autres instructions et l'emplacement de votre chauffeur sont clairement décrits sur votre bon de réservation et votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.  S'il vous plaît, lisez vos instructions de réservation pour plus d'informations.",
  },
  733: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Transfert privé",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  734: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-car.png",
    name: "Voiture de luxe",
    description:
      "Votre transfert professionnel de porte à porte de luxe et fourni avec une Mercedes Class E/S ou équivalent. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  735: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Economy Minivan - Taxicode",
    description:
      "Votre transfert professionnel de porte à porte en Mini-van économique est votre option privée qui vous permet de bénéficier d'un budget réduit. Parfait pour un plus grand groupe de personnes. Votre temps de prise en charge est calculé automatiquement en fonction des détails de votre vol. S'il vous plaît, lisez vos instructions de réservation pour plus d'informations.",
  },
  736: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Minibus",
    description:
      "Votre transfert de porte-à-porte professionnel en Mini-van est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  737: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxuryminivan.png",
    name: "Transfert de luxe",
    description:
      "Le transfert de porte-à-porte de Luxe est fourni dans un Mini-Van de haute qualité. Parfait pour un grand groupe de personnes.Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  738: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/coach.png",
    name: "Economy Coach - Taxicode",
    description:
      "Votre transfert professionnel de porte-à-porte en Autocar est une option plus économique. Parfait pour un large groupe de voyageur D'autres instructions et l'emplacement de votre chauffeur sont clairement décrits sur votre bon de réservation et votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol. S'il vous plaît, lisez vos instructions de réservation pour plus d'informations.",
  },
  739: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/coach.png",
    name: "Transferts en Autocar",
    description:
      "Votre transfert professionnel de porte à porte en autocar est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage.Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  740: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/coach.png",
    name: "Autocar de luxe ",
    description:
      "Le transfert porte-à-porte de Luxe est fourni dans un autocar de haute qualité. Parfait pour un grand groupe de personnes. Dans la plupart des aéroports, ce service comprend l' accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation.Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  741: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "Private Transfer including Speedboat",
    description:
      "Your professional door to door private transfer, includes a speedboat to cross to/from your destination. Further information on transfer can be found on your booking voucher.\r\nYour pick-up time is calculated automatically based on your flight details.",
  },
  742: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/speedyshuttle.jpg",
    name: "Winter Speedy Shuttle (max. 4 stops)",
    description:
      "Il s'agit d'un service partagé rentable avec d'autres passagers, fait jusqu’à 4 arrêts. Vous serez déposé / pris à votre hôtel ou le plus proche point d'accès. Votre temps de transfert est estimé mais peut varier selon le trafic et le nombre d'arrêts effectués. Il peut y avoir une attente à l'aéroport ou un point de ramassage car les bus fonctionnent selon des horaires définis. Pour plus d'informations sur la localisation de votre transfert et pour connaître votre heure de prise en charge, consultez votre bon de réservation.",
  },
  743: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.jpg",
    name: "Private Transfer (1 hour extra waiting time)",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  744: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Minibus (1 hour extra waiting time)",
    description:
      "Votre transfert professionnel de porte à porte en minibus est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  745: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/flyg public.jpg",
    name: "Flygbussarna",
    description:
      "Ce service peu coûteux et non remboursable relie l'aéroport à un point de chute du centre-ville. Si vous souhaitez être déposé à votre hôtel, veuillez opter pour un produit Shuttle ou Private Transfer. Les conditions générales de voyage applicables aux opérateurs s'appliquent et ce produit est exempté des conditions générales du groupe HolidayTaxis.",
  },
  746: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Eilat Shuttle",
    description:
      "Ce service peu coûteux et non remboursable relie l'aéroport à un point de chute du centre-ville. Si vous souhaitez être déposé à votre hôtel, veuillez opter pour un produit Shuttle ou Private Transfer. Les conditions générales de voyage applicables aux opérateurs s'appliquent et ce produit est exempté des conditions générales du groupe HolidayTaxis.",
  },
  747: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Dublin Bus ",
    description:
      "Ce service peu coûteux et non remboursable relie l'aéroport à un point de chute du centre-ville. Si vous souhaitez être déposé à votre hôtel, veuillez opter pour un produit Shuttle ou Private Transfer. Les conditions générales de voyage applicables aux opérateurs s'appliquent et ce produit est exempté des conditions générales du groupe HolidayTaxis.",
  },
  748: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/premier-luxury.jpg",
    name: "Gold Class Private Transfer",
    description:
      "Your professional door to door Gold Class Private Transfer is provided in a premium Mercedes E or S class or equivalent. This service includes ‘Meet & Greet’ and your smartly-dressed driver will be waiting for you with a branded iPad with your name on it, and a welcome letter with local info and contact details. Your driver will speak English as well as the local language, and will help you with your baggage and provide a bottle of water. For the return journey, the driver will call you to confirm pickup details.\r\n \r\n",
  },
  749: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxuryminivan.png",
    name: "Gold Class Minivan",
    description:
      "Your professional door to door Gold Class Minivan is provided in a premium Mercedes van or equivalent. This service includes ‘Meet & Greet’ and your smartly-dressed driver will be waiting for you with a branded iPad with your name on it, and a welcome letter with local info and contact details. Your driver will speak English as well as the local language, and will help you with your baggage and provide a bottle of water. For the return journey, the driver will call you to confirm pickup details.",
  },
  750: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "BA - Road Taxi / Private Speedboat (Lafodia hotel)",
    description:
      "Your professional door to door private transfer, includes a speedboat to cross to/from your destination. Further information on transfer can be found on your booking voucher.\r\nYour pick-up time is calculated automatically based on your flight details.",
  },
  751: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxuryminivan.png",
    name: "Dedicated Cruise Luxury Minivan",
    description:
      "Le transfert de porte-à-porte de Luxe est fourni dans un Mini-Van de haute qualité. Parfait pour un grand groupe de personnes.Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  752: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/luxury-car.png",
    name: "Dedicated Cruise Luxury Car",
    description:
      "Votre transfert professionnel de porte à porte de luxe et fourni avec une Mercedes Class E/S ou équivalent. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  753: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.jpg",
    name: "Private Transfer (incl valet parking)",
    description:
      "Your professional door to door private transfers. This service includes ‘Meet & Greet’ upon arrival. Your driver will be waiting for you in the arrival hall holding a sign with your name on it, your driver will be parked in the valet parking bay, right next to the airport terminal, ensuring you have the swiftest departure from the airport possible. Further information on how to locate your transfer can be found on your booking voucher. When returning to the airport your driver will be waiting for you at your pick-up location. Your pick-up time is calculated automatically based on your flight details. This service is provided with a guide.",
  },
  754: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/Kliaekspres (002).png",
    name: "Klia Ekspres ",
    description:
      "Ce service peu coûteux et non remboursable relie l'aéroport à un point de chute du centre-ville. Si vous souhaitez être déposé à votre hôtel, veuillez opter pour un produit Shuttle ou Private Transfer. Les conditions générales de voyage applicables aux opérateurs s'appliquent et ce produit est exempté des conditions générales du groupe HolidayTaxis. Veuillez noter que des frais de réservation s’appliquent pour ce Service. Let op: er worden reserveringskosten in rekening gebracht voor dit product",
  },
  755: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.png",
    name: "BA - Road Taxi / Private Speedboat (Bozica hotel)",
    description:
      "Your professional door to door private transfer, includes a speedboat to cross to/from your destination. Further information on transfer can be found on your booking voucher.\r\nYour pick-up time is calculated automatically based on your flight details.",
  },
  756: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Minivan (Lufthansa)",
    description:
      "Votre transfert de porte-à-porte professionnel en Mini-van est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  757: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/_minivan.png",
    name: "Minivan (Loveholidays)",
    description:
      "Votre transfert de porte-à-porte professionnel en Mini-van est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  758: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Minivan (extra luggage)",
    description:
      "Votre transfert de porte-à-porte professionnel en Mini-van est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  759: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Economy Minivan (extra luggage)",
    description:
      "Votre transfert professionnel de porte à porte en Mini-van économique est votre option privée qui vous permet de bénéficier d'un budget réduit. Parfait pour un plus grand groupe de personnes. Votre temps de prise en charge est calculé automatiquement en fonction des détails de votre vol. S'il vous plaît, lisez vos instructions de réservation pour plus d'informations.",
  },
  760: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.jpg",
    name: "Worldtravellers - Private transfer Meet and Greet",
    description:
      "À votre arrivée à l'aéroport de votre choix, votre objectif est de rejoindre votre logement aussi rapidement et facilement que possible. Pourquoi ne pas arriver en grande pompe en réservant l'un de nos transferts privés (taxi/minibus). Ne perdez pas de temps dans les files d'attente à des arrêts de taxi ou de bus, passez devant les foules et savourez votre première boisson avant même que la plupart des voyageurs n’aient quitté l’aéroport.",
  },
  761: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "Worldtravellers - Minivan Meet and Greet",
    description:
      "À votre arrivée à l'aéroport de votre choix, votre objectif est de rejoindre votre logement aussi rapidement et facilement que possible. Pourquoi ne pas arriver en grande pompe en réservant l'un de nos transferts privés (taxi/minibus). Ne perdez pas de temps dans les files d'attente à des arrêts de taxi ou de bus, passez devant les foules et savourez votre première boisson avant même que la plupart des voyageurs n’aient quitté l’aéroport.",
  },
  762: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/skybus (1).jpg",
    name: "SkyBus Family 1",
    description:
      "This low cost, non-refundable bus service will take you from the airport to a city centre drop off point. No hotel drop off is included. If there are additional passengers required outside of the allocated number of 1 adult and up to 4 children for this ticket please purchase these separately.\r\nOperators terms and conditions to travel apply and this product is exempt from standard HolidayTaxis Group terms.\r\nYour ticket to travel will be delivered directly to your personal email address.",
  },
  763: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/skybus (1).jpg",
    name: "SkyBus Family 2",
    description:
      "This low cost, non-refundable bus service will take you from the airport to a city centre drop off point. No hotel drop off is included. If there are additional passengers required outside of the allocated number of 2 adults and up to 4 children for this ticket please purchase these separately. \r\nOperators terms and conditions to travel apply and this product is exempt from standard HolidayTaxis Group terms.\r\nYour ticket to travel will be delivered directly to your personal email address.",
  },
  764: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/botg_private.jpg",
    name: "BOTG Road Taxi - Private Transfer",
    description:
      "Your professional door to door private transfers – now bookable within 2 hours of arrival! In most airports, this service includes ‘Meet & Greet’ where your driver will be your name on iwaiting for you in the arrivals hall holding a sign with t. Further information on how to locate your transfer can be found on your booking voucher. When returning to the airport your driver will be waiting for you at your pick-up location. Your pick-up time is calculated automatically based on your flight details. Because of the last minute nature of this booking, details cannot be changed or cancelled once booked.",
  },
  765: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/Jag I-Pace (003).jpg",
    name: "Jaguar I-Pace",
    description:
      "Your Luxury Eco-Friendly door to door transfer is a zero-emission vehicle. Perfect for the environmentally conscious traveller wanting to travel in style. For this product you will have to go to the 'WeKnow' desk which are located in each Terminal. Further information on how to locate your transfer can be found on your booking voucher. When returning to the airport your driver will be waiting for you at your pick-up location. Your pick-up time is calculated automatically based on your flight details. ",
  },
  766: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/minivan.png",
    name: "BOTG Minivan",
    description:
      "Your professional door to door Minivan transfers – now bookable within 2 hours of arrival! In most airports, this service includes ‘Meet & Greet’ where your driver will be waiting for you in the arrivals hall holding a sign with your name on it. Further information on how to locate your transfer can be found on your booking voucher. When returning to the airport your driver will be waiting for you at your pick-up location. Your pick-up time is calculated automatically based on your flight details. Because of the last minute nature of this booking, details cannot be changed or cancelled once booked.",
  },
  767: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "BOTG Minibus",
    description:
      "Your professional door to door Minibus transfers – now bookable within 2 hours of arrival! In most airports, this service includes ‘Meet & Greet’ where your driver will be waiting for you in the arrivals hall holding a sign with your name on it. Further information on how to locate your transfer can be found on your booking voucher. When returning to the airport your driver will be waiting for you at your pick-up location. Your pick-up time is calculated automatically based on your flight details. Because of the last minute nature of this booking, details cannot be changed or cancelled once booked.",
  },
  768: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle1min3.jpg",
    name: "Shuttle Transfer with Greeting",
    description:
      "Il s'agit d'un service partagé rentable avec d'autres passagers. Les navettes circulent entre l'aéroport et l'hébergement à l'exclusion d' une adresse privée. Vous serez déposé / pris à votre hôtel ou le plus proche point d'accès. Votre temps de transfert est estimé mais peut varier selon le trafic et le nombre d'arrêts effectués. Il peut y avoir une attente à l'aéroport ou un point de ramassage car les bus fonctionnent selon des horaires définis. Pour plus d'informations sur la localisation de votre transfert et pour connaître votre heure de prise en charge, consultez votre bon de réservation.",
  },
  769: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/private.jpg",
    name: "EJH Private Transfer",
    description:
      "Votre transfert professionnel de porte à porte privé.\nDans la plupart des aéroports, ce service comprend le service «Meet & Greet» et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  770: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/_minivan.png",
    name: "EJH Minivan",
    description:
      "Votre transfert de porte-à-porte professionnel en Mini-van est parfait pour un large groupe de passagers. Dans la plupart des aéroports, ce service comprend l'accueil et votre chauffeur vous attendra dans le hall d'arrivée avec une pancarte portant votre nom. Vous trouverez plus d'informations sur la localisation de votre transfert sur votre bon de réservation. Lorsque vous retournez à l'aéroport, votre chauffeur vous attendra à votre lieu de ramassage. Votre heure de prise en charge est calculée automatiquement en fonction des détails de votre vol.",
  },
  771: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/imgs/default/vehicle_set/shuttle1min3.jpg",
    name: "EJH Shuttle Transfer",
    description:
      "Il s'agit d'un service partagé rentable avec d'autres passagers. Les navettes circulent entre l'aéroport et l'hébergement à l'exclusion d' une adresse privée. Vous serez déposé / pris à votre hôtel ou le plus proche point d'accès. Votre temps de transfert est estimé mais peut varier selon le trafic et le nombre d'arrêts effectués. Il peut y avoir une attente à l'aéroport ou un point de ramassage car les bus fonctionnent selon des horaires définis. Pour plus d'informations sur la localisation de votre transfert et pour connaître votre heure de prise en charge, consultez votre bon de réservation.",
  },
  772: {
    imageurl: "https://s3-eu-west-1.amazonaws.com/htx-public-dev/",
    name: "Arlanda Express",
    description:
      "This low cost, non-refundable service runs between the airport and a city centre drop off point. If you want to be dropped off at your hotel, please change to a Shuttle or Private Transfer Product. Operators' terms and conditions to travel apply and this product is exempt from standard HolidayTaxis Group terms. ",
  },
};
