<ng-container *ngIf="noChangeableOptions === false; else noOptions">
  <div class="select-travel-container travel-interaction">
    <form *ngIf="modifyForm" [formGroup]="modifyForm" (ngSubmit)="searchExchange()">
      <div class="travel-info-container" *ngFor="let journey of journeys; let journeyIndex = index">
        <div>
          <input
            type="radio"
            [id]="'journey-' + journeyIndex"
            formControlName="journeyIndex"
            class="checkbox"
            [value]="journeyIndex"
          />
          <div class="travel-destination">
            <label class="travel-from-to" [for]="'journey-' + journeyIndex">
              <span class="travel-text">{{ journey.departure.name }}</span> <i class="spt-icon-arrow-circle-right"></i>
              <span class="travel-text">{{ journey.arrival.name }}</span>
            </label>
          </div>
        </div>
        <div class="travel-modify-form-container" *ngIf="modifyForm?.controls.journeyIndex.value === journeyIndex">
          <div class="date p-inputgroup p-l-10">
            <i class="spt-icon-date d-flex align-items-center justify-content-center txt-20"></i>
            <p-calendar
              #date
              (onClose)="calendarClosed()"
              [defaultDate]="defaultDate"
              [monthNavigator]="true"
              [showTime]="false"
              [minDate]="minDate"
              [placeholder]="'GLOBAL.DATE' | translate"
              [readonlyInput]="isSmallDevice"
              [selectOtherMonths]="true"
              [touchUI]="isSmallDevice"
              [yearRange]="yearRange"
              formControlName="date"
              hideTransitionOptions="0ms"
              showTransitionOptions="0ms"
              required
            >
              <p-header>
                <p class="">
                  {{ journeyIndex === 0 ? "SEARCH.DEPARTURE" : ("SEARCH.RETURN" | translate) }}
                </p>
              </p-header>
              <p-footer *ngIf="isSmallDevice">
                <div class="">
                  <button (mousedown)="date.toggle()" [label]="'GLOBAL.CLOSE' | translate" pButton></button>
                </div>
              </p-footer>
            </p-calendar>
          </div>

          <div class="hourWindow" style="display: flex">
            <div class="hourWindowType">
              <p-dropdown formControlName="hourWindowType" [options]="hourWindowTypes"></p-dropdown>
            </div>
            <div class="hour">
              <p-dropdown formControlName="hourWindowTime" [options]="times">
                <ng-template let-option pTemplate="selectedItem">
                  <div class="d-flex align-items-center hour-item">
                    <i class="spt-icon-clock d-flex align-items-center justify-content-center txt-20"></i>
                    <span class="m-l-10">{{ option?.label }}</span>
                  </div>
                </ng-template>
                <ng-template let-option pTemplate="item">
                  <div class="d-flex align-items-center">
                    <i class="spt-icon-clock d-flex align-items-center justify-content-center txt-20"></i>
                    <span class="m-l-10">{{ option.label }}</span>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
        </div>

        <small
          class="block text-center m-t-5 validity"
          *ngIf="exchangeOptions[journeyIndex]?.validFrom && exchangeOptions[journeyIndex]?.validUntil"
        >
          {{ "EXCHANGE.VALID_FROM" | translate }}
          {{
            exchangeOptions[journeyIndex].validFrom["outward"] || exchangeOptions[journeyIndex].validFrom["inward"]
              | localizedDate: "short"
          }}
          {{ "GLOBAL.UNTIL" | translate }}
          {{
            exchangeOptions[journeyIndex].validUntil["outward"] || exchangeOptions[journeyIndex].validUntil["inward"]
              | localizedDate: "short"
          }}
        </small>
      </div>
      <div *ngIf="modifyForm?.controls.journeyIndex.value === undefined" class="select-one">
        <p translate>RESERVATIONS.TRAIN.MODIFY.SELECT_ONE_TRAVELER_AND_ROUTE</p>
      </div>
      <div class="m-t-30 text-center">
        <p-button
          type="submit"
          class="w-full"
          styleClass="change-button"
          [disabled]="modifyForm.invalid"
          [label]="'BOOKING.MODIFICATION' | translate"
        >
        </p-button>
      </div>
    </form>
  </div>
</ng-container>

<p class="coj-messages-container text-center m-t-15" *ngIf="COJFee">
  <p-message
    *ngIf="hasTickets; else refundRebook"
    severity="warn"
    [text]="'RESERVATIONS.TRAIN.MODIFY.PAY_DIFFERENCE' | translate"
  ></p-message>
  <ng-template #refundRebook>
    <p-message severity="warn" [text]="'RESERVATIONS.TRAIN.MODIFY.REBOOK_REFUND' | translate"></p-message>
  </ng-template>
  <p-message
    severity="warn"
    [text]="'RESERVATIONS.TRAIN.MODIFY.COJ_FEE' | translate: { price: (COJFee | SPTPrice | async) }"
  ></p-message>
</p>

<ng-template #noOptions>
  <p class="text-center">
    <p-message severity="warn" [text]="'RESERVATIONS.TRAIN.NO_CHANGEABLES' | translate"></p-message>
  </p>
</ng-template>
