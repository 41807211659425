import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Message, SelectItem } from "primeng/api";
import { TrainTypes } from "../../../../../travel/train/train";
import { TrainService } from "../../../../../travel/train/train.service";
import { LoadingService } from "../../../../../@shared/services/loading.service";
import { CommonService } from "../../../../../@shared/services/common.service";
import { UtilsTypes } from "src/app/@shared/@types/utils";
import { forkJoin, mergeMap, Observable, of } from "rxjs";
import { FlightService } from "../../../../../travel/flight/flight.service";
import { FlightService as SharedFlightService } from "../../../../../@shared/services/flight.service";

@Component({
  selector: "spt-reservations-cancel-flight",
  templateUrl: "./flight.component.html",
  styleUrls: ["./flight.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservationsCancelFlightComponent implements OnInit, OnChanges {
  @Input() orderId: string;
  @Input() item: any;
  @Input() orderItems: Array<TrainTypes.OrderItem>;
  @Input() relatedOrderItems: Array<TrainTypes.RelatedOrderItem>;
  @Input() travelers: any[];
  @Input() provider: TrainTypes.Provider;
  @Input() cancellation: any;
  @Output() selectCancel: EventEmitter<{ orderId: string; options: Array<string>; reason: string }> =
    new EventEmitter();
  airports: any;

  userItems: SelectItem[] = [];
  refundOptions: any;
  journeys: any[] = [];
  selectedTravelers: TrainTypes.Passenger[] = [];
  selectedJourneys: TrainTypes.RefundJourney[] = [];
  selectedRefundOptions: TrainTypes.Option[] = [];
  selectedTravelerRefundOptions: TrainTypes.Option[] = [];
  selectedJourneyRefundOptions: TrainTypes.Option[] = [];
  activeJourney: boolean[] = [];
  noRefundOptions: boolean = false;
  messages: Message[];
  partial: boolean = true;
  displayedReservationFees: UtilsTypes.Price = {
    amount: 0,
    currency: "EUR",
  };
  voucherRefund: boolean = false;
  private reservationFees: UtilsTypes.Price = {
    amount: 0,
    currency: "EUR",
  };

  constructor(
    private trainService: TrainService,
    private flightService: FlightService,
    private changeDetector: ChangeDetectorRef,
    private loadingService: LoadingService,
    public commonService: CommonService,
    private sharedFlightService: SharedFlightService,
  ) {}

  private checkVoucherRefund(): void {
    this.voucherRefund = this.orderItems.some((_orderItem: TrainTypes.OrderItem) => {
      return (
        _orderItem.carrier === "DB" &&
        _orderItem.item.sections.some((_section: TrainTypes.ItinerarySection) =>
          _section.fares.some((_fare: TrainTypes.Fare) => _fare.flexibility === "semiflexi"),
        )
      );
    });
  }

  private mapUserItems(): void {
    this.userItems = this.item.travelers.map((traveler) => {
      return {
        label: `${traveler.firstname} ${traveler.lastname}`,
        value: {
          id: traveler.id,
          email: traveler.email,
          username: `${traveler.firstname} ${traveler.lastname}`,
          firstname: traveler.firstname,
          lastname: traveler.lastname,
          option: traveler,
        },
      };
    });

    this.selectedTravelers = [this.userItems[0].value];
    const searchAirports = [];
    this.item.detail.trips.forEach((trip: any) => {
      searchAirports.push(trip.Dep.IATALocationCode);
      searchAirports.push(trip.Arrival.IATALocationCode);
    });
    this.sharedFlightService.listAirports(searchAirports).subscribe((data) => {
      this.airports = data;
    });
    this.journeys = this.item.detail.trips;
    this.selectedJourneys = [this.journeys[0]];
    this.onSelectTravelers();
  }

  private getReservationFees(retrieveOrderData: any): void {
    if (retrieveOrderData) {
      this.reservationFees = {
        amount: retrieveOrderData.OrderItem.Price.TaxSummary.TotalTaxAmount["$t"],
        currency: retrieveOrderData.OrderItem.Price.TaxSummary.TotalTaxAmount["CurCode"] as UtilsTypes.Currency,
      };
    }

    if (this.reservationFees.amount > 0) {
      this.displayedReservationFees = {
        amount: (this.reservationFees.amount / this.refundOptions[0].length) * this.selectedRefundOptions.length,
        currency: this.reservationFees.currency,
      };
    }
  }

  private mapRefundables(refundOptions: Array<Array<TrainTypes.Option>>): void {
    this.refundOptions = refundOptions;

    if (refundOptions) {
      this.mapUserItems();
      this.getReservationFees(this.refundOptions.data.retrieveOrderData);
    }
    this.loadingService.remove();
    this.changeDetector.markForCheck();
  }

  ngOnInit(): void {
    this.loadingService.add();
    if (this.item.provider === "afkl") {
      this.flightService.getFlightOrder(this.item).subscribe({
        next: (_refundResponse: any) => this.mapRefundables(_refundResponse),
        error: (): void => this.loadingService.remove(),
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cancellation && !changes.cancellation.firstChange && changes.cancellation.currentValue === undefined) {
      this.trainService.synchronizePNR(this.orderId).subscribe();
    }
  }

  onSelectTravelers(): void {
    this.selectedTravelerRefundOptions = [];
    this.selectedRefundOptions = [];
    this.selectedJourneys = [this.journeys[0]];
    // this.onSelectJourney();
    // if (this.selectedTravelers.length > 0) {
    //   if (this.provider === "sabre") {
    //     this.selectedTravelerRefundOptions =
    //       this.refundOptions[0].length !== this.selectedTravelers.length
    //         ? this.refundOptions[0].filter((option: TrainTypes.Option): boolean => {
    //           return (
    //             option.isValid &&
    //             option.partial &&
    //             option.passengers.length > 0 &&
    //             this.hasPassenger(option) &&
    //             (!this.selectedJourneyRefundOptions.length || this.hasJourney(option))
    //           );
    //         })
    //         : this.refundOptions[0];
    //   } else {
    //     this.refundOptions.forEach((compatibleOptions: Array<TrainTypes.Option>) => {
    //       const options = compatibleOptions.filter(
    //         (option: TrainTypes.Option) =>
    //           option.isValid &&
    //           option.partial &&
    //           option.passengers.length > 0 &&
    //           this.hasPassenger(option) &&
    //           (!this.selectedJourneyRefundOptions.length || this.hasJourney(option))
    //       );
    //
    //       if (options.length) {
    //         this.selectedTravelerRefundOptions = options;
    //       }
    //     });
    //   }
    //
    //   this.selectedRefundOptions = this.selectedTravelerRefundOptions;
    //   this.selectedJourneys = [];
    //   this.selectedJourneyRefundOptions = [];
    //   this.updateDisplayedReservationFeesIfNeeded();
    //   this.emitEventForSelectedRefundOptions();
    // } else if (this.selectedJourneyRefundOptions.length) {
    //   this.selectedTravelerRefundOptions = [];
    //   this.selectedRefundOptions = [];
    //   this.onSelectJourney();
    // }
  }

  onSelectJourney(index?: number): void {
    if (this.journeys.length === 1) {
      // cas d'un aller simple (dans ce cas, il n'y a pas de case pour sélectionner un trajet)
      this.selectedJourneys = [this.journeys[0]];
      this.activeJourney = [true];
    } else if (index !== undefined) {
      this.activeJourney[index] = !this.activeJourney[index];
    }

    // if (this.selectedJourneys.length > 0) {
    //   let compatibleIndex: number = 0;
    //
    //   if (this.provider === "sabre") {
    //     this.selectedJourneyRefundOptions =
    //       this.refundOptions[1].length !== this.selectedJourneys.length
    //         ? this.refundOptions[1].filter((option: TrainTypes.Option): boolean => {
    //           return (
    //             option.isValid &&
    //             option.partial &&
    //             option.journeys.length > 0 &&
    //             this.hasJourney(option) &&
    //             (!this.selectedTravelerRefundOptions.length ||
    //               this.selectedTravelerRefundOptions.some(
    //                 (travelerOption: TrainTypes.Option) => travelerOption.orderItemIds[0] === option.orderItemIds[0]
    //               ))
    //           );
    //         })
    //         : this.refundOptions[1];
    //   } else {
    //     this.refundOptions.forEach((_compatibleOptions: TrainTypes.Option[], refundOptionIndex: number): void => {
    //       if (refundOptionIndex !== compatibleIndex) {
    //         compatibleIndex++;
    //       }
    //
    //       _compatibleOptions
    //         .filter(
    //           (option: TrainTypes.Option): boolean =>
    //             option.isValid && option.journeys.length > 0 && this.hasJourney(option)
    //         )
    //         .forEach((_compatibleOption: TrainTypes.Option): void => {
    //           if (
    //             !this.hasSelectedRefundOption(_compatibleOption) ||
    //             (compatibleIndex === refundOptionIndex && !_compatibleOption.partial)
    //           ) {
    //             this.selectedJourneyRefundOptions.push(_compatibleOption);
    //           }
    //         });
    //     });
    //   }
    //   this.selectedRefundOptions = this.selectedJourneyRefundOptions;
    //   this.selectedTravelers = [];
    //   this.selectedTravelerRefundOptions = [];
    //   this.updateDisplayedReservationFeesIfNeeded();
    //   this.emitEventForSelectedRefundOptions();
    // } else if (this.selectedTravelerRefundOptions.length) {
    //   this.selectedJourneyRefundOptions = [];
    //   this.selectedRefundOptions = [];
    //   this.onSelectTravelers();
    // }
  }

  /**
   *
   * @param option Vérifie si l'option indiquée en paramètre fait partie des options de remboursement sélectionnée.
   * @returns
   */
  private hasSelectedRefundOption(option: TrainTypes.Option) {
    return this.selectedRefundOptions.some((_selectedOption: TrainTypes.Option): boolean => {
      return _selectedOption.id === option.id;
    });
  }

  private updateDisplayedReservationFeesIfNeeded() {
    if (this.reservationFees.amount === 0) {
      return;
    }
    if (this.selectedRefundOptions.length === 0) {
      this.displayedReservationFees.amount = this.reservationFees.amount;
    } else {
      this.displayedReservationFees.amount =
        (this.reservationFees.amount / this.refundOptions[0].length) * this.selectedRefundOptions.length;
    }
    this.changeDetector.markForCheck();
  }

  private hasJourney(_compatibleOption: TrainTypes.Option): boolean {
    return _compatibleOption.journeys.some((_journey: TrainTypes.Journey): boolean =>
      this.selectedJourneys.some((_selectedJourneyItem: TrainTypes.Journey) => {
        return (
          _journey.id === _selectedJourneyItem.id ||
          (_journey.departure.locationId === _selectedJourneyItem?.departure.locationId &&
            _journey.arrival.locationId === _selectedJourneyItem?.arrival.locationId)
        );
      }),
    );
  }

  private hasPassenger(_compatibleOption: TrainTypes.Option): boolean {
    return _compatibleOption.passengers.some((_passenger: TrainTypes.Passenger): boolean =>
      this.selectedTravelers.some((_selectedTraveler: TrainTypes.Passenger) => {
        return _passenger.id === _selectedTraveler.id;
      }),
    );
  }

  private emitEventForSelectedRefundOptions() {
    if (this.selectedRefundOptions.length) {
      this.selectCancel.emit({
        orderId: this.orderId,
        options: this.selectedRefundOptions.map((_option: TrainTypes.Option) => _option.id),
        reason: "CUSTOMER_REQUESTED",
      });
    } else {
      this.selectCancel.emit(undefined);
    }
  }
}
