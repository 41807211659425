<section class="alternatives">
  <ng-container *ngFor="let flexibility of flexibilities">
    <ng-container *ngIf="(isSmallDevice && flexibility === selectedFlexibility) || !isSmallDevice">
      <ng-container *ngFor="let travelClass of travelClasses">
        <ng-container
          *ngIf="
            filteredOffers[flexibility + '-' + travelClass] &&
              filteredOffers[flexibility + '-' + travelClass].offers?.length;
            else noPrice
          "
        >
          <div
            class="alternative"
            [class.no-price]="!filteredOffers[flexibility + '-' + travelClass].price"
            *ngIf="filteredOffers[flexibility + '-' + travelClass].price; else noPrice"
            (mousedown)="onSelectOffer(filteredOffers[flexibility + '-' + travelClass])"
          >
            <span class="class text-center">
              <spt-specific-rate
                *ngIf="filteredOffers[flexibility + '-' + travelClass].subscriber"
                type="train"
                [subscriber]="filteredOffers[flexibility + '-' + travelClass].subscriber"
              ></spt-specific-rate>
              <spt-specific-rate
                *ngIf="filteredOffers[flexibility + '-' + travelClass].hasNegotiatedRate"
                type="train"
                [negociated]="filteredOffers[flexibility + '-' + travelClass].hasNegotiatedRate"
              ></spt-specific-rate>

              {{ "SEARCH.TRAIN." + travelClass | translate }}
            </span>
            <span class="price text-center">
              <span
                [class.best]="bestPriceOffer === filteredOffers[flexibility + '-' + travelClass]"
                [pTooltip]="
                  bestPriceOffer === filteredOffers[flexibility + '-' + travelClass]
                    ? ('SEARCH.RESULT.RAIL.BEST_PRICE' | translate)
                    : ''
                "
                tooltipStyleClass="tooltip"
                tooltipPosition="top"
              >
                {{ totalSections[flexibility + "-" + travelClass] | SPTPrice | async }}
              </span>
              <small
                class="availability text-center"
                *ngIf="
                  filteredOffers[flexibility + '-' + travelClass].offers[0]?.availabilities[0]?.status === 'Limited' &&
                  !filteredOffers[flexibility + '-' + travelClass].offers[0]?.availabilities[0]?.remaining
                "
              >
                {{
                  "SEARCH.RESULT.RAIL.AVAILABILITY." +
                    filteredOffers[flexibility + "-" + travelClass].offers[0]?.fares[0]?.availability.status
                    | uppercase
                    | translate
                }}
              </small>
              <small
                class="availability remaining text-center"
                *ngIf="filteredOffers[flexibility + '-' + travelClass].offers[0]?.availabilities[0]?.remaining"
              >
                {{
                  "SEARCH.RESULT.RAIL.AVAILABILITY.REMAINING"
                    | translate
                      : {
                          number: filteredOffers[flexibility + "-" + travelClass].offers[0]?.availabilities[0].remaining
                        }
                }}
              </small>
            </span>

            <spt-out-of-policy
              [isOutOfPolicy]="filteredOffers[flexibility + '-' + travelClass].offers[0].isOutOfPolicy"
              [authorizationsByPolicies]="
                filteredOffers[flexibility + '-' + travelClass].offers[0].authorizationsByPolicies
              "
              type="train"
              [displayInfo]="this.commonService.isTablet ? false : true"
            ></spt-out-of-policy>
          </div>
        </ng-container>
        <ng-template #noPrice>
          <div class="alternative no-price">
            <p class="class text-center">
              {{ "SEARCH.TRAIN." + travelClass | translate }}
            </p>
            <p class="price text-center">-</p>
            <p class="policy text-center"></p>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
</section>
