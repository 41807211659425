import { Component, Input } from "@angular/core";
import moment from "moment";

@Component({
  selector: "spt-conditions-car",
  templateUrl: "./car.component.html",
  styleUrls: ["./car.component.scss"],
})
export class ConditionsCarComponent {
  @Input() item: any;
  @Input() search: any;
  public car: any;

  ngOnInit() {
    this.car = this.item.formData;
  }
  get locationDuration(): number {
    const diff: number = moment(this.search.data?.returnDate ?? this.item.detail.returnDate.date).diff(
      moment(this.search.data?.pickupDate ?? this.item.detail.pickupDate.date),
      "days",
    );

    return diff + 1;
  }
}
