import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/@shared/shared.module";
import { SidebarModule } from "primeng/sidebar";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { BookingListComponent } from "./booking-list.component";
import { BlockModule } from "../block/block.module";
import { HotelComponent } from "./hotel/hotel.component";
import { TrainComponent } from "./train/train.component";
import { FlightComponent } from "./flight/flight.component";
import { RentalComponent } from "./rental/rental.component";
import { TransferComponent } from "./transfer/transfer.component";
import { ReservationsModule } from "../../../reservations/reservations.module";
import { OtherComponent } from "./other/other.component";

const MANAGEMENT_BOOKINGS_MODULES: Array<any> = [
  SharedModule,
  BlockModule,
  SidebarModule,
  LeafletModule,
  ReservationsModule,
];

const MANAGEMENT_BOOKINGS_COMPONENTS: Array<any> = [
  BookingListComponent,
  HotelComponent,
  TrainComponent,
  FlightComponent,
  RentalComponent,
  TransferComponent,
  OtherComponent,
];

@NgModule({
  imports: [...MANAGEMENT_BOOKINGS_MODULES],
  declarations: [...MANAGEMENT_BOOKINGS_COMPONENTS],
  exports: [...MANAGEMENT_BOOKINGS_COMPONENTS],
})
export class BookingListModule {}
