import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/@shared/shared.module";
import { DropdownModule } from "primeng/dropdown";
import { TranslateModule } from "@ngx-translate/core";
import { TimeSelectorComponent } from "./time-selector.component";

const TIME_SELECTOR_COMPONENTS: Array<any> = [TimeSelectorComponent];

const TIME_SELECTOR_MODULES: Array<any> = [SharedModule, DropdownModule, TranslateModule.forChild()];

@NgModule({
  declarations: [...TIME_SELECTOR_COMPONENTS],
  imports: [...TIME_SELECTOR_MODULES],
  exports: [...TIME_SELECTOR_COMPONENTS],
})
export class TimeSelectorModule {}
