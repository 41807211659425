<div class="spt-markup" [class.selected]="isSelected">
  <div class="price">
    {{
      {
        amount: availabilities.bestPrice,
        currency: availabilities.bestPriceCurrency
      }
        | SPTPrice
        | async
    }}
  </div>
  <div class="tip-container">
    <div class="tip"></div>
  </div>

  <div class="miniature-container">
    <p-galleria
      [value]="hotel.description?.images"
      [showThumbnails]="false"
      [showItemNavigators]="true"
      [showIndicatorsOnItem]="true"
      [circular]="true"
      *ngIf="hotel.description?.images.length > 0"
    >
      <ng-template pTemplate="item" let-item>
        <img [src]="item.url" [alt]="item.alt" class="picture" (click)="select(hotel)" (error)="onImgError($event)" />
      </ng-template>
    </p-galleria>
    <ng-container *ngIf="hotel.description?.images.length <= 0">
      <img src="./assets/svg/default-hotel.svg" class="default-image" />
    </ng-container>
    <div class="details" (click)="select(hotel)">
      <div class="title">{{ hotel.description?.title }}</div>
      <div class="star-rating">
        <div class="stars" [innerHtml]="hotel.description?.rank | stars"></div>
        <div class="rating">
          <span class="hotel-rating">
            {{ hotel.description?.rating | number: "1.0-1" }}
          </span>
          <span>/ 10</span>
        </div>
      </div>
      <div class="price">
        <div class="label" translate>GLOBAL.FROM</div>
        <span class="amount">{{
          {
            amount: availabilities?.bestPrice,
            currency: availabilities?.bestPriceCurrency
          }
            | SPTPrice
            | async
        }}</span>
      </div>
    </div>
  </div>
</div>
