import { Component, ViewEncapsulation } from "@angular/core";
import { CommonService } from "../@shared/services/common.service";
@Component({
  selector: "spt-auth",
  styleUrls: ["auth.component.scss"],
  template: `
    <div class="main grid grid-nogutter m-0">
      <div class="col-5 left-auth">
        <img class="logo" src="./assets/svg/supertripper-white.svg" />
        <img class="logo-letter" src="./assets/svg/supertripper-letter-logo.svg" />
        <img class="picture-header" src="./assets/svg/login-hero.svg" />
        <div class="bg-header"></div>
      </div>
      <div class="col-12 main-panel" [class.md:col-7]="commonService.isTablet === false">
        <router-outlet></router-outlet>
      </div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class AuthComponent {
  public constructor(public commonService: CommonService) {}
  ngOnInit() {
    this.commonService.statusBarTextBlack();
  }
}
