import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { element } from "protractor";

@Component({
  selector: "spt-travel-hotel-page-detail",
  styleUrls: ["./detail.component.scss"],
  templateUrl: "./detail.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class DetailComponent implements OnInit {
  @Input() hotel;
  public facilitiesByCategory: { [key: string]: any[] } = {};
  ngOnInit() {
    if (
      this.hotel.description?.facilities?.filter((facility) => facility.type === "OTHER") &&
      !this.hotel.description?.shortFacilities.find((element) => element === "OTHER")
    ) {
      this.hotel.description?.shortFacilities.push("OTHER");
    }
    this.hotel?.description?.shortFacilities?.forEach((short) => {
      this.facilitiesByCategory[short] = this.getFacilitiesByCategory(short);
    });
  }
  getFacilitiesByCategory(category: string) {
    return this.hotel?.description?.facilities?.filter((facility) => facility.code === category) || [];
  }
}
