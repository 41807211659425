import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { User } from "../../../../../../@shared/@types/user";

@Component({
  selector: "spt-request-managers",
  templateUrl: "./managers.component.html",
  styleUrls: ["./managers.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ManagersComponent implements OnInit {
  @Input() managers: Array<User>;
  managersUsernames: string[];
  localManagers: Array<User>;

  ngOnInit(): void {
    this.localManagers = this.managers.filter(({ username }) => !username.toLowerCase().includes("supertripper"));
    this.managersUsernames = this.localManagers
      .map((m) => ` ${m.username}`)
      .filter((name) => !name.toLowerCase().includes("supertripper")); // Remove Supertripper account manager from list of managers
  }
}
