import { ApplicationRef, Injectable, NgZone, Type } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";

import { DocumentService } from "./document.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { saveAs } from "file-saver";
import { tap } from "rxjs/operators";

declare const navigator: any;
declare const document: any;
declare const StatusBar: any;
declare const device: any;

@Injectable()
export class CommonService {
  public isTabletObservable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public isMobileObservable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public isTablet: boolean = true;
  public isMobile: boolean = true;
  public browserSupported: boolean = false;
  public appReady: BehaviorSubject<boolean> = new BehaviorSubject(null);

  public notificationPanelDisplayed: boolean = false;

  private overlay: number = 0;
  private nbIntercom: number = 0;

  public documentClickedTarget: Subject<HTMLElement> = new Subject<HTMLElement>();

  private functions: Array<any> = [];
  private deviceReady: boolean = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private httpClient: HttpClient,
    private applicationRef: ApplicationRef,
    private documentService: DocumentService,
  ) {
    this.testBrowser();

    this.breakpointObserver.observe(["(max-width: 768px)"]).subscribe((state: BreakpointState) => {
      this.isMobile = state.matches;
      this.isMobileObservable.next(this.isTablet);
      if (window.location.origin !== "http://devfront.supertripper.com:4200") {
        (window as any).Intercom("update", {
          hide_default_launcher: state.matches,
        });
      }
    });

    this.breakpointObserver.observe(["(max-width: 1024px)"]).subscribe((state: BreakpointState) => {
      this.isTablet = state.matches;
      console.log("isTablet", this.isTablet);
      this.isTabletObservable.next(this.isTablet);
    });
  }

  public isCordova(): boolean {
    if (this.deviceReady) {
      console.log({ device });
    }
    return this.deviceReady && (device.platform.toLowerCase() === "android" || device.platform.toLowerCase() === "ios");
  }

  public statusBarTextWhite(): void {
    if (this.isCordova()) {
      StatusBar.styleLightContent();
    }
  }

  public statusBarTextBlack(): void {
    if (this.isCordova()) {
      StatusBar.styleDefault();
    }
  }

  public testBrowser(): void {
    const isIE: boolean = /*@cc_on!@*/ false || !!document.documentMode;
    const isEdge: boolean = !isIE && !!window["StyleMedia"];
    this.browserSupported = !isIE && !isEdge;
  }

  public disableAppScroll(): void {
    if (this.isTablet) {
      this.overlay++;
      document.querySelector("app-root").style.overflow = `hidden`;
      document.querySelector("app-root").style["-webkit-overflow-scrolling"] = `unset`;
    }
  }

  public enableAppScroll(): void {
    if (this.isTablet) {
      this.overlay--;
      if (this.overlay < 1) {
        this.overlay = 0;
        document.querySelector("app-root").style.overflow = `auto`;
        document.querySelector("app-root").style["-webkit-overflow-scrolling"] = `touch`;
      }
    }
  }

  public setBackFunction(func: () => any, context: any, otto?: any): void {
    if (this.isCordova()) {
      if (this.functions.length > 0) {
        document.removeEventListener("backbutton", this.functions[this.functions.length - 1], true);
      }
      this.functions.push(($event: Event) => {
        func.call(context);
        this.applicationRef.tick();
      });
      document.addEventListener("backbutton", this.functions[this.functions.length - 1], true);
    }
  }

  public unsetBackFunction(all?: boolean): void {
    if (this.isCordova()) {
      if ((this.functions.length > 0 && all === true) || this.functions.length > 1) {
        document.removeEventListener("backbutton", this.functions[this.functions.length - 1], true);
        this.functions.pop();
      }

      if (this.functions.length > 0) {
        document.addEventListener("backbutton", this.functions[this.functions.length - 1], true);
      }
    }
  }

  public clearBackFunction(): void {
    this.functions.forEach((func: () => {}) => {
      this.unsetBackFunction(true);
    });
  }

  public disableIntercom(): void {
    if (this.isTablet) {
      this.nbIntercom--;
      const intercom: any = document.getElementsByName("intercom-launcher-frame");
      if (intercom && intercom[0]) {
        intercom[0].style.display = "none";
      }
      const intercomNotif: any = document.getElementsByName("intercom-launcher-badge-frame");
      if (intercomNotif && intercomNotif[0]) {
        intercomNotif[0].style.display = "none";
      }
    }
  }

  public enableIntercom(): void {
    if (this.isTablet) {
      this.nbIntercom++;
      if (this.nbIntercom > -1) {
        this.nbIntercom = 0;
        const intercom: any = document.getElementsByName("intercom-launcher-frame");
        if (intercom && intercom[0]) {
          intercom[0].style.display = "";
        }
        const intercomNotif: any = document.getElementsByName("intercom-launcher-badge-frame");
        if (intercomNotif && intercomNotif[0]) {
          intercomNotif[0].style.display = "";
        }
      }
    }
  }

  downloadZip(files: any[], fileType: string, name?: string, names?: string[]): Observable<any> {
    const endPoint: string = `${environment.api}/api/zip`;

    return this.httpClient
      .post(
        endPoint,
        {
          files,
          fileType,
          name,
          names,
        },
        {
          responseType: "blob",
        },
      )
      .pipe(
        tap((data: any) => {
          saveAs(data, name || "etickets");
        }),
      );
  }

  openLink(documentId: string): void {
    let type: "cordova" | "mobile" | "web";
    if (this.isCordova()) {
      type = "cordova";
    } else if (this.isTablet) {
      type = "mobile";
    } else {
      type = "web";
    }
    console.log({ type });
    this.documentService.get(documentId, type, true, false).subscribe();
  }

  encode(stringToEncode: string): string {
    const aUTF16CodeUnits: any = new Uint16Array(stringToEncode.length);
    Array.prototype.forEach.call(aUTF16CodeUnits, (el: Iterable<number>, idx: number, arr: Uint8Array) => {
      arr[idx] = stringToEncode.charCodeAt(idx);
    });
    return this.base64EncArr(new Uint8Array(aUTF16CodeUnits.buffer));
  }

  decode(stringToDecode: string): string {
    return String.fromCharCode.apply(null, new Uint16Array(this.base64DecToArr(stringToDecode, 2).buffer));
  }

  /*\
  |*|
  |*|  Base64 / binary data / UTF-8 strings utilities (#1)
  |*|
  |*|  https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
  |*|
  |*|  Author: madmurphy
  |*|
  \*/

  /* Array of bytes to base64 string decoding */

  b64ToUint6(nChr: number): number {
    return nChr > 64 && nChr < 91
      ? nChr - 65
      : nChr > 96 && nChr < 123
        ? nChr - 71
        : nChr > 47 && nChr < 58
          ? nChr + 4
          : nChr === 43
            ? 62
            : nChr === 47
              ? 63
              : 0;
  }

  base64DecToArr(sBase64: string, nBlockSize: number): Uint8Array {
    const sB64Enc: string = sBase64.replace(/[^A-Za-z0-9\+\/]/g, "");
    const nInLen: number = sB64Enc.length;
    const nOutLen: number = nBlockSize
      ? // tslint:disable-next-line:no-bitwise
        Math.ceil(((nInLen * 3 + 1) >>> 2) / nBlockSize) * nBlockSize
      : (nInLen * 3 + 1) >>> 2;
    const aBytes: Uint8Array = new Uint8Array(nOutLen);

    for (
      let nMod3: number, nMod4: number, nUint24: number = 0, nOutIdx: number = 0, nInIdx: number = 0;
      nInIdx < nInLen;
      nInIdx++
    ) {
      // tslint:disable-next-line:no-bitwise
      nMod4 = nInIdx & 3;
      // tslint:disable-next-line:no-bitwise
      nUint24 |= this.b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << (18 - 6 * nMod4);
      if (nMod4 === 3 || nInLen - nInIdx === 1) {
        for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
          // tslint:disable-next-line:no-bitwise
          aBytes[nOutIdx] = (nUint24 >>> ((16 >>> nMod3) & 24)) & 255;
        }
        nUint24 = 0;
      }
    }

    return aBytes;
  }

  /* Base64 string to array encoding */

  uint6ToB64(nUint6: number): number {
    return nUint6 < 26
      ? nUint6 + 65
      : nUint6 < 52
        ? nUint6 + 71
        : nUint6 < 62
          ? nUint6 - 4
          : nUint6 === 62
            ? 43
            : nUint6 === 63
              ? 47
              : 65;
  }

  base64EncArr(aBytes: Uint8Array): string {
    const eqLen: number = (3 - (aBytes.length % 3)) % 3;
    let sB64Enc: string = "";

    for (let nMod3: number, nLen: number = aBytes.length, nUint24: number = 0, nIdx: number = 0; nIdx < nLen; nIdx++) {
      nMod3 = nIdx % 3;
      /* Uncomment the following line in order to split the output in lines 76-character long: */
      /*
      if (nIdx > 0 && (nIdx * 4 / 3) % 76 === 0) { sB64Enc += "\r\n"; }
      */
      // tslint:disable-next-line:no-bitwise
      nUint24 |= aBytes[nIdx] << ((16 >>> nMod3) & 24);
      if (nMod3 === 2 || aBytes.length - nIdx === 1) {
        sB64Enc += String.fromCharCode(
          // tslint:disable-next-line:no-bitwise
          this.uint6ToB64((nUint24 >>> 18) & 63),
          // tslint:disable-next-line:no-bitwise
          this.uint6ToB64((nUint24 >>> 12) & 63),
          // tslint:disable-next-line:no-bitwise
          this.uint6ToB64((nUint24 >>> 6) & 63),
          // tslint:disable-next-line:no-bitwise
          this.uint6ToB64(nUint24 & 63),
        );
        nUint24 = 0;
      }
    }

    return eqLen === 0 ? sB64Enc : sB64Enc.substring(0, sB64Enc.length - eqLen) + (eqLen === 1 ? "=" : "==");
  }
}
