import { MenuItem } from "primeng/api";
import { Hotel, SearchBody } from "../hotel";
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { CommonService } from "src/app/@shared/services/common.service";
import { HotelService } from "src/app/@shared/services/hotel.service";
import { DateTime } from "luxon";
import { SessionService, SessionType } from "src/app/@shared/services/session.service";

@Component({
  selector: "spt-travel-hotel-page",
  styleUrls: ["./hotel-page.component.scss"],
  templateUrl: "./hotel-page.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class HotelPageComponent implements OnInit {
  @Input() search: {
    data: SearchBody;
  };
  private hasBeenUpdated: boolean = false;
  private _hotel: Hotel;
  protected checktimes: {
    checkInTime: CheckTime;
    checkOutTime: CheckTime;
  } = { checkInTime: {}, checkOutTime: {} };
  protected timeFormat: TimeFormat = "HH'h'mm";
  @Input() set hotel(value: Hotel) {
    this._hotel = value;
    this.checktimes = {
      checkInTime: {
        from: parseTime(value?.description?.checktimes?.CheckInTime?.from),
        to: parseTime(value?.description?.checktimes?.CheckInTime?.to),
      },
      checkOutTime: {
        from: parseTime(value?.description?.checktimes?.CheckOutTime?.from),
        to: parseTime(value?.description?.checktimes?.CheckOutTime?.to),
      },
    };
  }
  get hotel(): Hotel {
    return this._hotel;
  }
  private _availabilities: any;
  @Input() set availabilities(value: any) {
    this._availabilities = value;
    if (this._availabilities.supplier === "SAB" && this.hasBeenUpdated === false) {
      this.availabilitiesUpdateRequired.emit({
        accomcode: this._availabilities.accomcode,
        supplier: `${this._availabilities.supplier}:${this._availabilities[0]?.rateAccessCode}}`,
      });
      this.hasBeenUpdated = true;
    }
  }
  get availabilities(): any {
    return this._availabilities;
  }

  @Input() seeOutOfPolicy: boolean;
  @Output() selectFare: EventEmitter<any> = new EventEmitter();
  @Output() unselectHotel: EventEmitter<any> = new EventEmitter();
  @Output() outdatedInformations: EventEmitter<any> = new EventEmitter();
  @Output() availabilitiesUpdateRequired: EventEmitter<any> = new EventEmitter();
  @Output() updateHotel: EventEmitter<any> = new EventEmitter();

  public menu: MenuItem[] = [
    {
      label: "SEARCH.RESULT.HOTEL.FARES",
      command: (event: any): void => (this.activeMenu = event.item),
    },
    {
      label: "SEARCH.RESULT.HOTEL.DETAILS",
      command: (event: any): void => (this.activeMenu = event.item),
    },
    {
      label: "SEARCH.RESULT.HOTEL.PICTURES",
      command: (event: any): void => (this.activeMenu = event.item),
    },
    {
      label: "SEARCH.RESULT.HOTEL.AVIS",
      command: (event: any): void => (this.activeMenu = event.item),
    },
  ];

  public activeMenu: any = this.menu[0];

  constructor(
    public commonService: CommonService,
    public sessionService: SessionService<SessionType>,
    private hotelService: HotelService,
  ) {}

  ngOnInit(): void {
    const session: any = this.sessionService.get();
    if (session) {
      this.timeFormat =
        session.timeFormat === "12" ? "hh:mm a" : session.defaultLanguage === "fr" ? "HH'h'mm" : "HH:mm";
    }
  }

  back(): void {
    this.selectFare.emit(undefined);
    this.commonService.statusBarTextWhite();
    this.unselectHotel.emit(null);
  }

  selectOffer(fare: any): void {
    this.commonService.statusBarTextBlack();
    this.selectFare.emit({
      fare,
      hotel: this.hotel,
      price: {
        amount: fare.price,
        currency: fare.currency,
      },
    });
  }

  onImgError($event): void {
    $event.target.src = "./assets/svg/default-hotel.svg";
  }

  refresh() {
    this.hotelService.getDescription(this.hotel.accomcode, this.hotel.suppliers?.[0], true, true).subscribe((hotel) => {
      this.hotel = {
        ...this.hotel,
        ...hotel,
      };
      this.updateHotel.emit(this.hotel);
    });
  }
}

type TimeFormat = "hh:mm a" | "HH:mm" | "HH'h'mm";

function parseTime(str: string) {
  if (str?.match(/^\d+.*$/)) {
    const result = DateTime.fromISO(str).toJSDate();
    return isNaN(result.getTime()) ? undefined : result;
  }
  return undefined;
}
interface CheckTime {
  from?: Date;
  to?: Date;
}
