<div class="block-container">
  <div class="dot"></div>
  <div class="hotel" *ngIf="hotel | async; let _hotel; else: rawData">
    <div class="hotel-container">
      <div class="title">
        {{ _hotel.description?.title | titlecase }}
      </div>
      <div class="address">
        <img src="./assets/svg/markup-violet.svg" alt="" />
        {{ _hotel.description?.address }}
      </div>
    </div>
  </div>
  <ng-template #rawData>
    <div class="hotel">
      <div class="hotel-container">
        <div class="title">
          {{ hotelTitle }}
        </div>
        <div class="address">
          <img src="./assets/svg/markup-violet.svg" alt="" />
          {{ hotelAddress }}
        </div>
      </div>
    </div>
  </ng-template>
  <div class="dates">
    <div class="datein">
      <spt-basket-date [date]="datein.toDateString()"></spt-basket-date>
    </div>
    <i class="spt-icon-arrow-circle-right arrow-right"></i>
    <div class="dateout">
      <spt-basket-date [date]="dateout.toDateString()"></spt-basket-date>
    </div>
  </div>
</div>
