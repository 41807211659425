import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { FlightTypes } from "../@types/flight";
import { TrainTypes } from "../../travel/train/train";
import { HotelTypes } from "../@types/hotel";
import { CarTypes } from "src/app/travel/car/car";
import { map } from "rxjs/operators";
import { User } from "../@types/user";
import { Society } from "../@types/society";

@Injectable({
  providedIn: "root",
})
export class SearchService {
  endpoint: string = `${environment.api}/search`;

  constructor(private httpClient: HttpClient) {}

  create(
    type: "flight" | "hotel" | "train" | "car",
    body: SearchBody,
    lang?: string,
    oldItemId?: string,
  ): Observable<{ id: string }> {
    return this.httpClient
      .post(`${this.endpoint}/${type}`, body, {
        params: {
          oldItemId,
        },
        headers: {
          "Accept-Language": lang || "en",
        },
      })
      .pipe(
        map((res: any) => {
          return res.data;
        }),
      );
  }

  get(id: string): Observable<SearchServiceResult> {
    return this.httpClient.get<SearchServiceResult>(`${this.endpoint}/${id}`, {
      headers: { ignoreLoadingBar: "true" },
    });
  }
}

export interface SearchServiceResult {
  search: Search;
  averageTiming: number;
  users: Array<User>;
  society: Society;
}
export interface Search {
  id: string;
  userId: string;
  societyId: string;
  data: SearchBody;
  type: SearchType;
  oldItemId: string;
}
export type SearchType = "hotel" | "car" | "train" | "flight" | "transfer" | "seminar";
export type SearchBody =
  | FlightTypes.Search
  | HotelTypes.Search
  | TrainTypes.SearchBody
  | TrainTypes.ExchangeBody
  | CarTypes.SearchBody;
