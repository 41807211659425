import { Component, OnChanges, Input, ViewEncapsulation, OnInit } from "@angular/core";
import { User } from "src/app/@shared/@types/user";

import ColorHash from "color-hash";
import { TrainTypes } from "../../../../travel/train/train";
import { DateTime } from "luxon";
import { environment } from "src/environments/environment";
import { SocietyService } from "src/app/@shared/services/society.service";
import { CommonService } from "src/app/@shared/services/common.service";
@Component({
  selector: "spt-avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AvatarComponent {
  private _user: User;
  @Input() set user(value: User) {
    if (value) {
      this._user = value;
      this.updateUser();
    }
  }
  get user() {
    return this._user;
  }

  @Input() displayUsername: boolean = true;
  @Input() extraInfo: string = "";
  @Input() validationCircuitAuth: any;
  @Input() validationCircuitDelegation: {
    from: string;
    till: string;
    userId: any;
    delegatorId: any;
  } = { from: "", till: "", userId: "", delegatorId: "" };
  @Input() displayTooltip: boolean = true;
  @Input() displayPicture: boolean = true;
  @Input() displayTravelerType: boolean = false;

  public tagName: string;
  public colorHash: ColorHash;
  public color: string;
  public avatarPath: string;
  passengerType: TrainTypes.PassengerType;

  constructor(
    private societyService: SocietyService,
    protected commonService: CommonService,
  ) {}

  updateUser(): void {
    let _user: User;

    if (
      (!this.user.s3AvatarPath && this.user._id) ||
      (this.user.userId && !this.user.s3AvatarPath) ||
      (this.user.email && !this.user.s3AvatarPath)
    ) {
      if (!this.user._id && this.user.userId) {
        this.user._id = this.user.userId;
      }
      if (this.user._id) {
        _user = this.societyService.society.value.members.find((member) => {
          return member.user._id.toString() === this.user._id.toString();
        })?.user;
      } else if (this.user.email) {
        _user = this.societyService.society.value.members.find((member) => {
          return member.user.email === this.user.email;
        })?.user;
      }
    } else {
      _user = this.user;
    }

    if (!_user && this.user) {
      _user = this.user;
    }

    if (_user && _user.s3AvatarPath) {
      this.avatarPath = `${environment.bucketAvatarsEndpoint + _user.s3AvatarPath}?timestamp=${Date.now()}`;
    } else {
      this.avatarPath = null;
      this.colorHash = new ColorHash();
      this.generateDefaultAvatar(_user);
    }
    let yearsDiff: number;
    if (_user && _user.birthdate) {
      yearsDiff = DateTime.now()
        .diff(DateTime.fromISO(_user.birthdate as string), "years")
        .as("years");
    }
    if (yearsDiff >= 60) {
      this.passengerType = "SENIOR";
    } else if (yearsDiff < 60 && yearsDiff >= 28) {
      this.passengerType = "ADULT";
    } else if (yearsDiff < 28 && yearsDiff >= 12) {
      this.passengerType = "YOUTH";
    } else if (yearsDiff < 12 && yearsDiff >= 4) {
      this.passengerType = "CHILD";
    } else {
      this.passengerType = "INFANT";
    }
  }

  generateDefaultAvatar(_user): void {
    if (_user && !_user.s3AvatarPath) {
      this.colorHash = new ColorHash();
      let username: string;
      if (_user.firstname && _user.lastname) {
        this.tagName = `${_user.firstname.charAt(0)}${_user.lastname.charAt(0)}`;
        username = `${_user.firstname} ${_user.lastname}`;
      } else {
        const res: Array<any> = _user.username.split(" ");
        this.tagName = `${res[0].charAt(0)}${res[1].charAt(0)}`;
        username = _user.username;
      }
      this.color = this.colorHash.hex(username);
    }
  }
}
