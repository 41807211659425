import { Component, Input, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { CommonService } from "src/app/@shared/services/common.service";
import { DEFAULT_DATE_FORMAT, UserService } from "src/app/@shared/services/user.service";

@Component({
  selector: "spt-conditions-flight-information",
  templateUrl: "./information.component.html",
  styleUrls: ["./information.component.scss"],
})
export class ConditionsFlightInformationComponent implements OnDestroy {
  @Input() dateEnd: any;
  @Input() pricingInformation?: any;
  @Input() afklTrip?: any;
  @Input() user: any;
  @Input() index: number;

  public refundable: boolean;
  public exchangeable: boolean;
  public carryBagageDesc: string;
  public date: string;

  dateFormat: string = DEFAULT_DATE_FORMAT;
  private observablesToClearOnDestroy: Subscription[] = [];

  constructor(
    public commonService: CommonService,
    private userService: UserService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.observablesToClearOnDestroy.push(
      this.userService.user.subscribe((user) => {
        this.dateFormat = user?.settings?.nativeDateFormat || DEFAULT_DATE_FORMAT;
      }),
    );
    if (this.pricingInformation) {
      this.refundable = this.pricingInformation.fare.passengerInfos[this.index].penaltiesInfo?.penalties.some(
        (penalty) => penalty.applicability === "Before" && penalty.type === "Refund" && penalty.amount > -1,
      );
      this.exchangeable = this.pricingInformation.fare.passengerInfos[this.index].penaltiesInfo?.penalties.some(
        (penalty) => penalty.applicability === "Before" && penalty.type === "Exchange" && penalty.amount > -1,
      );
      this.getLuggageDesc();
    } else if (this.afklTrip) {
      const orderItem = this.afklTrip.formData.orderCreateRS.Order.OrderItem;
      let fareDetail;
      if (Object.keys(orderItem.FareDetail).includes("0")) {
        fareDetail = Object.values(orderItem.FareDetail);
      } else {
        fareDetail = orderItem.FareDetail;
      }
      if (Object.keys(fareDetail.FareComponent).includes("0")) {
        fareDetail.FareComponent = Object.values(fareDetail.FareComponent);
      }
      if (Object.keys(fareDetail.FareComponent[1].FareRule.Penalty).includes("0")) {
        fareDetail.FareComponent[1].FareRule.Penalty = Object.values(fareDetail.FareComponent[1].FareRule.Penalty);
      }
      this.refundable = !fareDetail.FareComponent[1].FareRule.Penalty.some(
        (penalty) =>
          penalty.TypeCode === "Cancellation" &&
          penalty.CancelFeeInd === true &&
          parseInt(penalty.PenaltyAmount["$t"]) > -1,
      );
      this.exchangeable = fareDetail.FareComponent[1].FareRule.Penalty.some(
        (penalty) =>
          penalty.TypeCode === "Change" && penalty.ChangeFeeInd === true && parseInt(penalty.PenaltyAmount["$t"]) > -1,
      );
      // this.getLuggageDesc();
    }
  }

  ngOnDestroy(): void {
    this.observablesToClearOnDestroy.forEach((o) => o.unsubscribe());
    this.observablesToClearOnDestroy.length = 0;
  }

  getLuggageDesc() {
    let { governingCarriers } = this.pricingInformation.fare;
    let { cabinCodes } = this.pricingInformation;
    if (governingCarriers) {
      let carryDescription = "GLOBAL.FLIGHT_BAGAGES.INFORMATION.CARRY." + governingCarriers;
      if (["AF", "TU"].includes(governingCarriers)) {
        carryDescription += `.${cabinCodes[0]}`;
      }
      let trad = this.translateService.instant(carryDescription);
      if (trad !== carryDescription) {
        this.carryBagageDesc = trad;
        return;
      }
    }
    this.carryBagageDesc = this.translateService.instant("GLOBAL.FLIGHT_BAGAGES.INFORMATION.CARRY_NO_INFO");
  }

  parseInt(value) {
    return parseInt(value);
  }
}
