import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "spt-basket-date",
  templateUrl: "./date.component.html",
  styleUrls: ["./date.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DateComponent {
  @Input() date: string;
  @Input() time: string;
}
