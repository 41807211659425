<div class="hotel-thumbnail-container">
  <div class="promote-head" *ngIf="promoteCarbonOffset">
    <span translate>SEARCH.HOTEL.PROMOTE_CARBON_OFFSET</span>
  </div>
  <div class="picture">
    <p-galleria
      [value]="hotel.description?.images"
      [showThumbnails]="false"
      [showItemNavigators]="true"
      [showIndicatorsOnItem]="true"
      [circular]="true"
    >
      <ng-template pTemplate="item" let-item>
        <img [src]="item.url" (error)="onImgError($event)" class="imageGallerie" />
      </ng-template>
    </p-galleria>
    <img
      src="./assets/svg/hearth{{ hotel.favorite ? '-red' : '' }}.svg"
      [alt]="hotel.favorite"
      class="favorite"
      (mousedown)="switchFavorite($event)"
    />
    <div class="distance" *ngIf="(commonService.isTabletObservable | async) === true">
      {{ hotel.distance | distance }}
    </div>
  </div>
  <div class="detail" (click)="select.emit()">
    <div class="vendor" [class.negociated]="availabilities?.supplier === 'SAB'">
      <span *ngIf="availabilities?.supplier !== 'SAB'">{{
        "SEARCH.PROVIDERS." + availabilities?.supplier | translate
      }}</span>
      <spt-specific-rate
        *ngIf="availabilities?.supplier === 'SAB'"
        [negociated]="true"
        type="hotel"
        [position]="commonService.isTablet ? 'right' : 'bottom'"
      ></spt-specific-rate>
    </div>
    <div class="title">{{ hotel.description?.title }}</div>
    <div class="stars-avantages">
      <div class="stars" [innerHtml]="hotel.description?.rank | stars"></div>
      <div class="avantages">
        <ng-container *ngFor="let facility of facilities">
          <img
            *ngIf="facility === 'gift'"
            src="./assets/svg/facilities/{{ facility }}.svg"
            (mouseover)="promotionsOp.show($event)"
            (touchstart)="promotionsOp.show($event)"
          />
          <img
            *ngIf="facility !== 'gift'"
            src="./assets/svg/facilities/{{ facility }}.svg"
            title="{{ facility | lowercase }}"
          />
        </ng-container>
        <span class="additional-facilities"
          ><span
            *ngIf="additionalFacilities.length > 0"
            (mouseover)="additionalFacilitiesOverlayPanel.show($event)"
            (touchstart)="additionalFacilitiesOverlayPanel.show($event)"
          >
            + {{ additionalFacilities.length }}
          </span></span
        >
      </div>
    </div>
    <div class="price">
      <div class="label" translate>GLOBAL.FROM</div>
      <span class="amount">{{
        {
          amount: availabilities?.bestPrice,
          currency: availabilities?.bestPriceCurrency
        }
          | SPTPrice
          | async
      }}</span>
    </div>
    <spt-out-of-policy
      [isOutOfPolicy]="inPolicy === false"
      [authorizationsByPolicies]="availabilities.authorizationsByPolicies"
      type="hotel"
      [displayInfo]="commonService.isTablet ? false : true"
      *ngIf="!inPolicy"
    ></spt-out-of-policy>
    <div class="out-policy conforme" *ngIf="inPolicy" translate>GLOBAL.IN_POLICY</div>
    <div class="rating">
      <span class="hotel-rating">
        {{ hotel.description?.rating | number: "1.0-1" }}
      </span>
      <span>/ 10</span>
    </div>
  </div>
</div>
<p-overlayPanel
  [showCloseIcon]="commonService.isTablet"
  styleClass="promotions-overlay"
  [appendTo]="'body'"
  #promotionsOp
>
  <ul class="promotions-list" (mouseleave)="promotionsOp.hide()">
    <li class="promotions-item" *ngFor="let promotion of promotions">
      {{ promotion }}
    </li>
  </ul>
</p-overlayPanel>
<p-overlayPanel
  [showCloseIcon]="commonService.isTablet"
  styleClass="facilities-overlay"
  [appendTo]="'body'"
  #additionalFacilitiesOverlayPanel
>
  <ul
    class="facilities-list"
    (mouseleave)="additionalFacilitiesOverlayPanel.hide()"
    *ngIf="hotel.description?.facilities"
  >
    <li class="facility-item" *ngFor="let additionalFacility of additionalFacilities">
      <span><img src="./assets/svg/facilities/{{ additionalFacility }}.svg" /></span
      ><span>{{ additionalFacility | lowercase }}</span>
    </li>
  </ul>
</p-overlayPanel>
