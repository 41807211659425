import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { CommonService } from "../../../../@shared/services/common.service";

@Component({
  selector: "spt-bills-modal",
  templateUrl: "./bills-modal.component.html",
  styleUrls: ["./bills-modal.component.scss"],
})
export class BillsModalComponent implements OnInit {
  bills: Array<any>;
  constructor(
    private dynamicDialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    public commonService: CommonService,
  ) {}

  ngOnInit(): void {
    const bills = this.dynamicDialogConfig.data.bills.map((bill) => {
      if (bill.type === "resume") {
        if (bill.metadata.unitaryType === "receipt") {
          bill.displayedType = "BILL";
        } else {
          bill.displayedType = "REPORT";
        }
      } else if (bill.type === "credit") {
        if (bill.metadata.unitaryType === "receipt") {
          bill.displayedType = "CREDIT";
        } else {
          bill.displayedType = "CREDITNOTE";
        }
      } else if (bill.type === "receipt") {
        bill.displayedType = "RECEIPT";
      } else if (bill.type === "unitary") {
        bill.displayedType = "BILL";
      }
      return bill;
    });
    this.bills = bills;
  }

  openBill(index: number): void {
    if (this.bills[index]) {
      this.commonService.openLink(this.bills[index].documentIds[0]);
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
