import { Component, ViewEncapsulation, OnDestroy } from "@angular/core";
import { Router, ActivationEnd, NavigationStart, Event, NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

@Component({
  selector: "spt-dashboard",
  styleUrls: ["./dashboard.component.scss"],
  template: `
    <spt-header></spt-header>
    <div class="layout" [ngStyle]="{ width: widthContent }">
      <router-outlet></router-outlet>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnDestroy {
  public widthContent: string = "100%";
  private subscription: Subscription;
  public currentRoute: string;

  public constructor(private router: Router) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });

    this.subscription = this.router.events
      .pipe(
        filter(
          (event: Event) => event instanceof ActivationEnd && event.snapshot && event.snapshot.children.length === 0,
        ),
      )
      .subscribe((event: NavigationStart) => {
        if (event["snapshot"].data.widthContent !== undefined) {
          this.widthContent = event["snapshot"].data.widthContent;
        } else {
          this.widthContent = "100%";
        }
      });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
