import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewEncapsulation } from "@angular/core";
import moment from "moment";
import { CarbonOffsetTypes } from "../../../@shared/@types/carbon-offset";
import { TrainTypes } from "../train";

@Component({
  selector: "spt-travel-train-segments",
  templateUrl: "./segments.component.html",
  styleUrls: ["./segments.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelTrainSegmentsComponent implements OnInit {
  @Input() segments: Array<TrainTypes.Segment>;
  @Input() hasSections: boolean;
  @Input() isSmallDevice: boolean;
  @Input() offerCarbonOffset: CarbonOffsetTypes.CarbonOffsetEstimation;
  @Input() detailed: boolean;
  @HostBinding("class.expanded") expanded: boolean = false;
  services = {
    ON_BOARD_ACCESSIBILITY: "wheelchair",
    ON_BOARD_BAR: "wine-glass",
    ON_BOARD_PAID_BIKE: "bicycle",
    ON_BOARD_WIFI: "wifi",
  };

  constructor() {}

  ngOnInit(): void {}

  travelDuration(departureTime: Date, arrivalTime: Date): string {
    const departureT: moment.Moment = moment(departureTime);
    const arrivalT: moment.Moment = moment(arrivalTime);
    const difference: moment.Duration = moment.duration(arrivalT.diff(departureT));
    const hours: number = difference.get("hours");
    const minutes: number = difference.get("minutes");
    return `${hours ? hours : ""}${hours > 0 ? "h" : ""}${minutes < 10 ? "0" : ""}${minutes}m`;
  }

  expand(): void {
    if (this.detailed && this.segments.length > 1) {
      this.expanded = !this.expanded;
    }
  }
}
