<div class="background" [ngClass]="{ visible: opened }" (mousedown)="closeSidebar()"></div>

<div class="confirmation-sidebar {{ styleClass }}" [ngClass]="{ visible: opened }">
  <div class="confirmation-content">
    <div *ngIf="styleClass === 'hotel'" class="sidebar-hotel">
      <ul class="navigation">
        <li class="search">
          <div class="border">
            <i class="spt-icon-search"></i>
          </div>
          <span>{{ "NOTIFICATIONS.SEARCH.TITLE" | translate }} - - - -</span>
        </li>
        <li class="hotel">
          <div class="border">
            <i class="spt-icon-hotel"></i>
          </div>
          <span>{{ "GLOBAL.HOTEL" | translate }} - - - -</span>
        </li>
        <li class="validate">
          <div class="border">
            <i class="spt-icon-check"></i>
          </div>
          <span>{{ "SEARCH.FLIGHT.BREADCRUMB.VALIDATION" | translate }}</span>
        </li>
      </ul>
      <img class="icon-arrow-left" src="./assets/svg/arrow-left.svg" alt="" (mousedown)="closeSidebar()" />
    </div>
    <div class="close-container" *ngIf="showCloseIcon && styleClass !== 'hotel'">
      <img
        src="./assets/svg/close{{ (commonService.isTabletObservable | async) === true ? '' : '-rounded' }}.svg"
        alt=""
        (mousedown)="closeSidebar()"
        class="close"
      />
    </div>
    <ng-content></ng-content>
  </div>
</div>
