import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import L from "leaflet";
import moment from "moment";
import { CommonService } from "src/app/@shared/services/common.service";
import { HotelService } from "src/app/@shared/services/hotel.service";
import { ItemService } from "src/app/@shared/services/item.service";
import { DataHotel } from "../../sidebar-travel.component";
import { of } from "rxjs";

@Component({
  selector: "spt-itinerary-hotel",
  templateUrl: "./hotel.component.html",
  styleUrls: ["./hotel.component.scss"],
})
export class ItineraryHotelComponent implements OnInit, AfterViewInit {
  @Input() item: any;
  @Input() status: string;
  @Input() itemId: string;
  @Input() referenced?: boolean;

  @Input() set formData(value: any) {
    this._formData = value;
    const { rooms, datein, dateout } = value;
    if (datein) {
      this.datein = datein;
    }
    if (dateout) {
      this.dateout = dateout;
    }
    if (rooms) {
      this.rooms = rooms;
    }
    this.getRatesDetailsByBooking();
  }

  @ViewChild("leafletMap", { static: false }) mapElement: ElementRef;

  private map: L.Map = null;
  public mapOtions: L.MapOptions;

  public datein: any;
  public dateout: any;
  public nightNumber: number;
  public hotelRank: number;
  public boardType: string;
  public coordinates: any;
  public errors: any;
  dataHotel: DataHotel;
  public cancellationPolicy: any;
  @Output() onDataHotelUpdated: EventEmitter<DataHotel> = new EventEmitter<DataHotel>();

  public rooms: Array<{ places: number; title: string }> = [];

  constructor(
    private hotelService: HotelService,
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    public commonService: CommonService,
    private itemService: ItemService,
    private elementRef: ElementRef,
  ) {}
  private _formData: any;

  get formData(): any {
    return this._formData;
  }

  ngOnInit() {
    this.rooms = this.item.detail.rooms;
    if (this.item.offline === true || this.item.provider === "offline") {
      this.boardType = this.item.formData.boardType;
    } else {
      this.boardType =
        this.item.detail.boardType || this.item.detail.rooms?.[0]?.type || this.item.formData.rooms?.[0]?.boardType;
    }
    this.nightsNumber();
  }

  ngAfterViewInit() {
    if (this.item.detail.accomcode) {
      this.hotelService
        .getDescription(this.item.detail.accomcode, this.item.formData.suppliercode)
        .subscribe((hotel) => {
          this.hotelRank = hotel.description.rank;
          const [longitude, latitude] = hotel.coordinates;
          this.setMapMarker([latitude, longitude]);
          this.cd.detectChanges();
        });
    } else {
      this.hotelRank = this.item.detail.hotelRating;
      const [longitude, latitude] = this.item.detail.coordinates;
      this.setMapMarker([latitude, longitude]);
    }
  }

  nightsNumber() {
    this.datein = moment(this.item.detail.datein.date);
    this.dateout = moment(this.item.detail.dateout.date);
    this.nightNumber = this.dateout.diff(this.datein, "days");
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  setExternalLink(coordinates: L.LatLngExpression) {
    const userAgent: string = navigator?.userAgent;

    if (this.commonService.isCordova() === true) {
      if (userAgent && /ipad|iphone|ipod/i.test(userAgent)) {
        this.coordinates = "maps://maps.apple.com/?q=";
      } else {
        this.coordinates = "geo:";
      }
    } else {
      this.coordinates = "https://maps.google.com/?q=";
    }
    if (this.coordinates) {
      // sanitaze for safe url
      this.coordinates += coordinates.toString();
      this.coordinates = this.sanitize(this.coordinates);
    }
  }

  setMapMarker(coordinates: L.LatLngExpression) {
    this.mapOtions = {
      center: L.latLng(coordinates),
      layers: [
        L.tileLayer("http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
          maxZoom: 18,
          attribution: "...",
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }),
      ],
      zoom: 15,
    };

    this.map = L.map(this.mapElement.nativeElement, this.mapOtions);
    const marker: L.Icon = L.icon({
      iconUrl: "./assets/svg/map-purple-icon.svg",
      iconSize: [48, 48],
    });
    L.marker(coordinates, { icon: marker }).addTo(this.map);
    this.setExternalLink(coordinates);
  }

  getRatesDetailsByBooking() {
    let getRatesDetailsByBooking: any;
    if (this.status === "confirmed") {
      getRatesDetailsByBooking = this.itemService.getCancellation(this.itemId);
    } else if (this.formData) {
      if (this.item.offline === true) {
        getRatesDetailsByBooking = of({});
      } else if (this.item.detail.bookingref) {
        getRatesDetailsByBooking = this.hotelService.getRatesDetailsByBookingRef(this.item.detail.bookingref);
      } else {
        getRatesDetailsByBooking = this.hotelService.getRatesDetails(this.formData.accomtoken, this.formData.sessionId);
      }
    }
    if (getRatesDetailsByBooking) {
      getRatesDetailsByBooking.subscribe(
        (cancellation: any) => {
          if (cancellation.data) {
            cancellation = cancellation.data;
          }
          this.dataHotel = {
            cancellationPolicy: cancellation.cancellationPolicy || [],
            contractRemarks: cancellation.contractRemarks,
            carbonOffset: cancellation.carbonOffset,
            datein: this.datein,
          };
          this.onDataHotelUpdated.emit(this.dataHotel);
          this.elementRef.nativeElement.scrollIntoView({ behavior: "smooth" });
        },
        (error) => {
          this.dataHotel = {
            cancellationPolicy: [],
            contractRemarks: [],
            carbonOffset: {},
            datein: this.datein,
          };
          if (error.error) {
            this.onDataHotelUpdated.emit(this.dataHotel);
          }
        },
      );
    }
  }
}
