import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { SessionType, SessionService } from "../@shared/services/session.service";
import { Address, Billing, TitleEnum } from "../@shared/@types/models";
import { SocietyService } from "../@shared/services/society.service";
import { BehaviorSubject, Observable } from "rxjs";
import { concatMap } from "rxjs/operators";
import { ResetService } from "../service_initializer/reset.service";

interface LoginCredentials {
  user: string;
  password: string;
}

interface BodyAuthenticate extends LoginCredentials {
  application: string;
}

export interface UserSignUp {
  email: string;
  password: string;

  title: TitleEnum;
  firstname: string;
  lastname: string;
  phone: string;
}

interface BodyRegister extends UserSignUp {
  application: string;
}
@Injectable()
export class AuthService {
  _userProfile: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(
    private httpClient: HttpClient,
    private sessionService: SessionService<SessionType>,
    private societyService: SocietyService,
    private resetService: ResetService,
  ) {}

  login(loginCredentials: LoginCredentials): Observable<any> {
    const user: string = loginCredentials.user.replace(new RegExp(/\s/, "gm"), "");
    const body: BodyAuthenticate = {
      user,
      password: loginCredentials.password,
      application: environment.application,
    };

    const endPoint: string = `${environment.api}/api/authenticate`;

    return this.httpClient.post(endPoint, body, {
      headers: { ignoreErrorMessage: "true" },
    });
  }

  setUserProfile(profile: any): void {
    localStorage.setItem("userProfile", JSON.stringify(profile));
  }

  getUserProfile(): any {
    const userProfile: any = localStorage.getItem("userProfile");
    return userProfile ? JSON.parse(userProfile) : null;
  }

  clearUserProfile(): void {
    localStorage.removeItem("userProfile");
  }

  signUp(user: UserSignUp, societyName: string): Observable<any> {
    const endPoint: string = `${environment.api}/api/register`;

    const body: BodyRegister = {
      application: environment.application,
      ...user,
    };

    return this.httpClient.post(endPoint, body).pipe(
      concatMap((resSignUp: any) => {
        const session: SessionType = {
          token: resSignUp.data.token.replace(/JWT /, ""),
          userId: resSignUp.data.user._id,
          roles: resSignUp.data.user.roles,
        };
        return this.sessionService.set(session);
      }),
      concatMap(() => this.societyService.setSociety(societyName, false)),
    );
  }

  checkSSO(email: string): Observable<any> {
    const endPoint: string = `${environment.api}/oauth2/check`;

    return this.httpClient.post(endPoint, { email }, { headers: { ignoreErrorMessage: "true" } });
  }

  logout(userProfile: any, isCordova: boolean): Observable<any> {
    const endPoint: string = `${environment.api}/oauth2?isCordova=${isCordova}`;

    return this.httpClient.delete(endPoint, {
      headers: new HttpHeaders({ profile: btoa(JSON.stringify(userProfile)) }),
    });
  }

  getSocietyConfig(societyId: string): Observable<any> {
    return this.httpClient.get(`${environment.api}/oauth2/config`, {
      params: {
        societyId,
      },
    });
  }
}
