import { Injectable } from "@angular/core";

import { SocietyResolver } from "./society.resolver";
import { MembersResolver } from "./members.resolver";
import { Observable } from "rxjs";
import { concatMap, first, map } from "rxjs/operators";
import { cloneDeep } from "lodash-es";

@Injectable({
  providedIn: "root",
})
export class SocietyAndMembersResolver {
  constructor(
    protected societyResolver: SocietyResolver,
    protected membersResolver: MembersResolver,
  ) {}

  resolve(): Observable<any> {
    let society: any;
    return this.societyResolver.resolve().pipe(
      first(),
      concatMap((_society: any) => {
        society = _society;
        return this.membersResolver.resolve().pipe(first());
      }),
      map((members: any) =>
        cloneDeep({
          society,
          members,
        }),
      ),
    );
  }
}
