import { Injectable } from "@angular/core";

import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

import {
  CsrPolicy,
  ECategoryPolicyRental,
  EClassPolicyFlight,
  EClassPolicyTrain,
  HotelPolicy,
  PolicyBlacklist,
  PolicyException,
  PolicyFront,
  ValueLabel,
} from "../@types/policies.d";

import { SocietyService } from "./society.service";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, combineLatest, concat, forkJoin, from, of, throwError } from "rxjs";
import { map, tap, pairwise, filter, flatMap, mergeMap, toArray, shareReplay } from "rxjs/operators";
import { SessionService, SessionType } from "./session.service";
import { MemberSociety } from "../@types/society";
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { DateTime } from "luxon";
import { UserService } from "./user.service";
import { FlightService } from "./flight.service";

import FLIGHT_COMPAGNIES from "src/app/@shared/companies";
import RENTAL_CAR_PROVIDERS from "../rentalCarProviders";
import COUNTRIES_FR from "src/assets/countries-fr";
import COUNTRIES_EN from "src/assets/countries-en";
import COUNTRIES_ES from "src/assets/countries-es";
import { GoogleMapsService } from "./google-maps.service";

const countriesByLanguage: { [key: string]: ValueLabel<string>[] } = {
  fr: COUNTRIES_FR,
  en: COUNTRIES_EN,
  es: COUNTRIES_ES,
};

@Injectable({
  providedIn: "root",
})
export class PoliciesService {
  private _policies: BehaviorSubject<PolicyFront[]> = undefined;
  private currentSocietyId: string;
  private language: string;

  private converterToFormValue: ConverterToFormValue;
  private converterToValue: ConverterToValue;
  public formBuilder: PolicyFormBuilder;
  private canNavigateOnDirtyFormObservable: Observable<boolean> = null;

  constructor(
    private httpClient: HttpClient,
    private societyService: SocietyService,
    private translateService: TranslateService,
    private sessionService: SessionService<SessionType>,
    private flightService: FlightService,
    private googleMapsService: GoogleMapsService,
    fb: FormBuilder,
    userService: UserService,
  ) {
    this.converterToFormValue = new ConverterToFormValue(translateService, userService);
    this.converterToValue = new ConverterToValue(() => this.language);
    this.formBuilder = new PolicyFormBuilder(fb);
    userService.user.subscribe((user) => {
      if (user?.settings?.language && user.settings.language != this.language) {
        this.language = user.settings.language;
      }
    });
  }

  convertToValue(policyFormGroup: FormGroup<PolicyFormGroup>) {
    const result = this.converterToValue.policy(policyFormGroup.value as any as PolicyFormValue);
    return result;
  }

  getPoliciesSubject() {
    if (!this._policies) {
      this._policies = new BehaviorSubject<PolicyFront[]>([]);
      this.getPolicies().subscribe();
    }
    return this._policies;
  }

  registerCanNavigateOnDirtyForm(canNavigateOnDirtyFormObservable: Observable<boolean>) {
    this.canNavigateOnDirtyFormObservable = canNavigateOnDirtyFormObservable;
  }

  /**
   * Permet de savoir si la navigation vers une autre URL est possible.
   * Dans le cas où le formulaire aurait été modifié, une popup de confirmation est affiché à l'utilisateur
   */
  canNavigate(edition: FormPolicyEdition): Observable<boolean> {
    edition.form.valueChanges
      .pipe(
        pairwise(),
        map(([oldState, newState]) => {
          let changes = {};
          for (const key in newState) {
            if (oldState[key] !== newState[key] && oldState[key] !== undefined) {
              changes[key] = newState[key];
            }
          }
          return changes;
        }),
        filter((changes) => Object.keys(changes).length !== 0 && !edition.form.invalid),
      )
      .subscribe((value) => {});
    if (!edition.form.dirty) {
      return of(true);
    }
    if (this.canNavigateOnDirtyFormObservable) {
      return this.canNavigateOnDirtyFormObservable;
    }
    return of(confirm("Etes-vous sûr de vouloir quitter cette page et perdre vos modifications ?"));
  }

  getCarProviders(): ValueLabel<string>[] {
    return Object.keys(RENTAL_CAR_PROVIDERS).map((value) => ({
      value,
      label: this.translateService.instant("SEARCH.PROVIDERS." + value),
    }));
  }

  private updateCacheIfNeeded(policies: PolicyFront[]): PolicyFront[] {
    // On trie les politiques pour diminuer au maximum les différences
    const sortedPolicies = policies.sort((a, b) => (a._id || "").localeCompare(b._id || ""));
    if (JSON.stringify(this.getPoliciesSubject().value) !== JSON.stringify(sortedPolicies)) {
      this.getPoliciesSubject().next(sortedPolicies);
    }
    return sortedPolicies;
  }

  private updateItemInCache(policy: PolicyFront): PolicyFront {
    if (policy) {
      const newPolicies = this.getPoliciesSubject().value.filter((p) => p._id !== policy._id);
      newPolicies.push(policy);
      this.updateCacheIfNeeded(newPolicies);
    }
    return policy;
  }

  private buildFormPolicyEditionCache: { [placeid: string]: Observable<FormPolicyEdition> } = {};

  private _buildFormPolicyEdition(policyId: string): Observable<FormPolicyEdition> {
    let result = this.buildFormPolicyEditionCache[policyId];
    if (!result) {
      this.buildFormPolicyEditionCache[policyId] = result = this.getPolicy(policyId).pipe(
        map((policy) => {
          const formValue = this.converterToFormValue.policy(policy);
          const form = this.formBuilder.buildEditionForm(formValue);
          let edition: FormPolicyEdition = {
            _originalPolicy: policy,
            form,
          };
          this.setupAirportNameByIATACode(edition.form);
          return edition;
        }),
        // Pour la mise en cache
        shareReplay(1),
      );
    }
    return result;
  }

  cleanFormPolicyEditionFromCache(policyId: string) {
    delete this.buildFormPolicyEditionCache[policyId || "create"];
  }

  private ref: Ref = {};
  getFormPolicyEdition(policyId: string): Observable<FormPolicyEdition> {
    if (this.ref.edition?._originalPolicy?._id === policyId) {
      return of(this.ref.edition);
    }
    return this._buildFormPolicyEdition(policyId).pipe(
      tap((formPolicyEdition) => {
        if (this.ref.edition?._originalPolicy?._id) {
          console.warn("Change policy edition:", this.ref.edition, "->", formPolicyEdition);
        }
        this.ref.edition = formPolicyEdition;
      }),
    );
  }

  /**
   * Permet de construire le formulaire de confirmation de suppression d'une policy
   */
  buildFormPolicyDeletion(policyId: string, listAssignedMembers: MemberSociety[]): FormPolicyDeletion {
    // Si cette politique n'est assignée à aucun membre pas besoin de récupérer la liste
    // des autres politiques vers laquelle transférer l'assignation
    const transferAssignationsToPolicyIdList: ValueLabel<string>[] =
      listAssignedMembers.length === 0
        ? []
        : this.getPoliciesSubject()
            .value.filter((p) => p._id !== policyId)
            .map((p) => ({ label: p.label, value: p._id }));

    return {
      transferAssignationsToPolicyIdList,
      form: this.formBuilder.buildDeletionForm(transferAssignationsToPolicyIdList),
    };
  }

  private setupAirportNameByIATACode(policyFormGroup: FormGroup<PolicyFormGroup>) {
    // Récupération des codes IATA dont il faut "résoudre" les libellés
    const iataList = Array.from(
      new Set(
        (policyFormGroup.value?.rse?.flight?.exceptedPairs || []).flatMap((p) => [p.origin.value, p.target.value]),
      ),
    );
    if (iataList.length > 0) {
      this.flightService
        .listAirports(iataList)
        .pipe(
          map((result) =>
            Object.fromEntries(Object.values(result).map((iataResult) => [iataResult.iata, iataResult.name])),
          ),
        )
        .subscribe((iataMap) => {
          const rsePolicy = policyFormGroup.controls.rse.value as CsrPolicyFormValue;
          rsePolicy.flight.exceptedPairs
            .flatMap((pair) => [pair.origin, pair.target])
            .forEach((c) => (c.label = iataMap[c.value] || c.label));
          policyFormGroup.controls.rse.setValue(rsePolicy);
        });
    }
  }

  getPolicy(policyId: string): Observable<PolicyFront> {
    return this.sessionService.getSession().pipe(
      mergeMap((session) => {
        this.currentSocietyId = session?.societyId;
        if (policyId === "create") {
          return of(
            CreateValue.policy(this.currentSocietyId, this.translateService.instant("POLICY.NAME_PLACEHOLDER")),
          );
        }

        const endPoint: string = `${environment.api}/api/policies/${policyId}`;
        const params: HttpParams = new HttpParams({
          fromObject: {
            societyId: this.currentSocietyId,
            language: this.translateService.currentLang,
          },
        });

        return this.httpClient.get(endPoint, { params }).pipe(
          map((res: any) => res.data),
          mergeMap((policy: PolicyFront) => this.fixDataIssue([policy])),
          map((policies) => policies[0]),
          tap((policy) => this.updateItemInCache(policy)),
        );
      }),
    );
  }

  getPolicies(): Observable<PolicyFront[]> {
    return this.sessionService.getSession().pipe(
      mergeMap((session) => {
        this.currentSocietyId = session?.societyId;
        this.language = session?.defaultLanguage;
        const endPoint: string = `${environment.api}/api/policies`;
        const params: HttpParams = new HttpParams({
          fromObject: {
            societyId: this.currentSocietyId,
            language: this.translateService.currentLang,
          },
        });

        return this.httpClient.get(endPoint, { params }).pipe(
          map((res: any) => res.data),
          mergeMap((policies: PolicyFront[]) => this.fixDataIssue(policies)),
          tap((result) => this.updateCacheIfNeeded(result)),
        );
      }),
    );
  }

  buildTimeOptions(): TimeOptions {
    return {
      hours: arrayRange(0, 24).map((i) => ({ label: i.toString(), value: i })),
      minutes: arrayRange(0, 59, 15).map((i) => ({ label: i.toString(), value: i })),
    };
  }

  private fixDataIssueForPolicy(policy: PolicyFront): PolicyFront {
    // Corrige le libellé de la politique
    if (policy.label === "GENERAL.DEFAULT") {
      policy.label = this.translateService.instant("GENERAL.DEFAULT");
    }

    // Attribution de valeur par défaut aux politiques manquantes
    const defaultValue = CreateValue.policy(null, null);
    if (!policy.flight) {
      policy.flight = defaultValue.flight;
    }
    if (!policy.train) {
      policy.train = defaultValue.train;
    }
    if (!policy.train.conditions) {
      policy.train.conditions = [];
    }
    if (!policy.hotel) {
      policy.hotel = defaultValue.hotel;
    }
    if (!policy.rental) {
      policy.rental = defaultValue.rental;
    }
    if (!policy.transfer) {
      policy.transfer = defaultValue.transfer;
    }
    if (!policy.rse) {
      policy.rse = defaultValue.rse;
    }

    // Corrige le format de données des prix
    if (policy.flight?.price && typeof policy.flight.price !== "number") {
      policy.flight.price = (policy as any).flight.price.max;
    }
    if (policy.train?.price && typeof policy.train.price !== "number") {
      policy.train.price = (policy as any).train.price.max;
    }

    // Corrige les libellé des références des blacklists
    policy.blacklist = (policy.blacklist || []).map((b) => this.fixPolicyBlacklist(b));
    return policy;
  }
  /**
   * Si `true` : force la résolution des libellé d'un placeId, même si un label existait déjà pour la langue utilisateur.
   */
  private forceResolvePlaceId: false;

  private fixDataIssue(policies: PolicyFront[]): Observable<PolicyFront[]> {
    policies = policies.map((policy) => this.fixDataIssueForPolicy(policy));

    // Récupération de tous les pays correctement déclarés
    const countryPlaceId = Object.fromEntries(
      policies.flatMap((policy) =>
        policy.hotel
          .filter((hotel) => hotel.type === "country" && hotel.placeId)
          .map((hotel) => [hotel.countryCode, hotel.placeId]),
      ),
    );

    // Récupération des codes pays dont le placeId est manquant
    const unresolvedCountryPlaceIdByPlaceIdOfACity: { [countryCode: string]: string } = Object.fromEntries(
      policies.flatMap((policy) => {
        return policy.hotel
          .filter((hotel) => !!hotel.countryCode && !countryPlaceId[hotel.countryCode])
          .map((hotel) => [hotel.countryCode, hotel.placeId]);
      }),
    );
    const unresolvedLabelPlaceIdList: Set<string> = new Set(
      policies.flatMap((policy) =>
        policy.hotel
          .filter(
            (hotel) =>
              hotel.type !== "global" &&
              hotel.type !== "country" &&
              hotel.placeId &&
              (!hotel.labels?.[this.language] || this.forceResolvePlaceId),
          )
          .map((hotel) => hotel.placeId),
      ),
    );
    return forkJoin([
      this.googleMapsService.resolveNameOfAllPlaceIds([...unresolvedLabelPlaceIdList]),
      this.googleMapsService.resolvedPlaceIdForAllCountryByPlaceIdOfACity(unresolvedCountryPlaceIdByPlaceIdOfACity),
    ]).pipe(
      map(([resolvedLabelsByPlaceId, resolvedPlaceIdByCountry]) => {
        policies.forEach((policy) => {
          policy.hotel
            .filter(
              (hotel) =>
                hotel.type !== "global" &&
                hotel.type !== "country" &&
                hotel.placeId &&
                (!hotel.labels?.[this.language] || this.forceResolvePlaceId),
            )
            .forEach((hotel) => {
              if (!hotel.labels) {
                hotel.labels = {};
              }
              hotel.labels[this.language] =
                resolvedLabelsByPlaceId[hotel.placeId] || this.converterToFormValue.hotelPolicyLabelResolver(hotel);
            });

          // Récupération des hotel policy de type country
          const existingCountryPolicyByCountryCode = Object.fromEntries(
            policy.hotel.filter((h) => h.type === "country").map((h) => [h.countryCode, h]),
          );

          // Pour chacune des villes ou régions, on doit s'assurer que le country policy correspondant existe bien
          new Set(
            policy.hotel
              .filter(
                (hotelPolicy) =>
                  hotelPolicy.type !== "global" &&
                  hotelPolicy.type !== "country" &&
                  !existingCountryPolicyByCountryCode[hotelPolicy.countryCode],
              )
              .map((hotelPolicy) => hotelPolicy.countryCode),
          ).forEach((countryCode) => {
            // Création du policyCountry manquant
            const newCountry: HotelPolicy = {
              countryCode,
              placeId: resolvedPlaceIdByCountry[countryCode],
              labels: {},
              type: "country",
              price: {
                max: undefined,
              },
            };

            newCountry.labels[this.language] = this.converterToFormValue.hotelPolicyLabelResolver(newCountry);
            policy.hotel.push(newCountry);
          });
        });
        return policies;
      }),
    );
  }

  private fixPolicyBlacklist(policyBlacklist: PolicyBlacklist): PolicyBlacklist {
    if (policyBlacklist.type === "flight") {
      return {
        type: policyBlacklist.type,
        // Correction des noms des compagnies
        references: policyBlacklist.references.map((reference) => ({
          value: reference.value,
          label: FLIGHT_COMPAGNIES[reference.value]?.name || reference.label,
        })),
      };
    }
    return policyBlacklist;
  }

  transfertPolicyToMembers(policyId: string, members: MemberSociety[]): Observable<void> {
    return this.societyService.assignPolicyToMembers(this.currentSocietyId, members, policyId);
  }

  assignPolicyToMembersFromFormPolicyEdition(
    listAssignedMembers: MemberSociety[],
    membersSelected: MemberSociety[],
    formPolicyEdition: FormPolicyEdition,
  ): Observable<PolicyFront> {
    return (
      this
        // Comme il doit y avoir une mise à jour de la policy pour chaque membre concerné,
        // il est nécessaire de sauvegarder au préalable la policy pour rester cohérant
        .savePolicy(formPolicyEdition)
        .pipe(mergeMap((policy) => this.assignPolicyToMembers(listAssignedMembers, membersSelected, policy)))
    );
  }

  assignPolicyToMembers(
    listAssignedMembers: MemberSociety[],
    membersSelected: MemberSociety[],
    policy: PolicyFront,
  ): Observable<PolicyFront> {
    const added: MemberSociety[] = [];
    const removed: MemberSociety[] = [];
    listAssignedMembers.forEach((oldMember: MemberSociety) => {
      const index: number = membersSelected.findIndex((newMember: MemberSociety) => newMember._id === oldMember._id);
      if (index === -1) {
        oldMember.policy = undefined;
        removed.push(oldMember);
      }
    });
    membersSelected.forEach((newMember: MemberSociety) => {
      const index: number = listAssignedMembers.findIndex(
        (oldMember: MemberSociety) => newMember._id === oldMember._id,
      );
      if (index === -1) {
        newMember.policy = policy;
        added.push(newMember);
      }
    });

    if (!this.currentSocietyId) {
      return throwError(() => new Error("Unable to retrieve current societyId"));
    }
    let observable: Observable<void>;

    if (added.length > 0 && removed.length > 0) {
      observable = concat(
        this.societyService.assignPolicyToMembers(this.currentSocietyId, added, policy._id),
        this.societyService.assignPolicyToMembers(this.currentSocietyId, removed, null),
      ).pipe(map(() => {}));
    } else if (added.length > 0) {
      observable = this.societyService.assignPolicyToMembers(this.currentSocietyId, added, policy._id);
    } else {
      observable = this.societyService.assignPolicyToMembers(this.currentSocietyId, removed, null);
    }
    return observable.pipe(map(() => policy));
  }

  savePolicy(formPolicyEdition: FormPolicyEdition): Observable<PolicyFront> {
    const policyTosave = this.convertToValue(formPolicyEdition.form);
    const apiCall = policyTosave._id
      ? this.httpClient.put(`${environment.api}/api/policies/${policyTosave._id}`, policyTosave)
      : this.httpClient.post(`${environment.api}/api/policies`, policyTosave);
    this.cleanFormPolicyEditionFromCache(policyTosave._id || "create");
    return apiCall.pipe(
      map((res: any) => res.data),
      tap((updatedPolicy: PolicyFront) => {
        formPolicyEdition.form.markAsPristine();
        formPolicyEdition.form.markAsUntouched();

        this.updateItemInCache(updatedPolicy);
        delete this.buildFormPolicyEditionCache[updatedPolicy._id];
      }),
    );
  }

  deletePolicy(policyToDeleteId: string): Observable<PolicyFront> {
    return this.httpClient
      .delete(`${environment.api}/api/policies/${policyToDeleteId}`) //
      .pipe(
        map((res: any) => {
          console.log("DELETED", res);
          return res.data;
        }),
        tap(() => {
          const policiesSubject = this.getPoliciesSubject();
          policiesSubject.next(policiesSubject.value.filter((p) => p._id !== policyToDeleteId));
          this.cleanFormPolicyEditionFromCache(policyToDeleteId);
          delete this.buildFormPolicyEditionCache[policyToDeleteId];
        }),
      );
  }
}
export interface Ref {
  edition?: FormPolicyEdition;
}

class PolicyFormBuilder {
  constructor(private formBuilder: FormBuilder) {}

  buildEditionForm(value: PolicyFormValue): FormGroup<PolicyFormGroup> {
    return this.formBuilder.group<PolicyFormGroup>({
      _id: this.formBuilder.control(value._id),
      guest: this.formBuilder.control(value.guest),
      societyId: this.formBuilder.control(value.societyId),
      label: this.formBuilder.control(value.label),
      hotel: this.formBuilder.group<HotelFormGroup>({
        global: this.hotelItem(value.hotel.global),
        countryPolicies: this.formBuilder.array<FormGroup<HotelCountryFormGroup>>(
          value.hotel.countryPolicies.map((p) => this.hotelCountry(p)),
        ),
        blacklist: this.hotelBlacklist(value.hotel.blacklist || { type: "hotel", references: [] }),
      }),
      flight: this.formBuilder.group({
        price: this.formBuilder.control(value.flight?.price),
        class: this.formBuilder.control(value.flight?.class),
        superflexible: this.formBuilder.control(value.flight?.superflexible),
        exceptionsDuration: this.formBuilder.array<FormGroup<ExceptionFormGroup>>(
          value.flight?.exceptionsDuration.map((e) => this.exception(e)) || [],
        ),
        exceptionsSeason: this.formBuilder.array<FormGroup<ExceptionFormGroup>>(
          value.flight?.exceptionsSeason.map((e) => this.exception(e)) || [],
        ),
        conditions: this.formBuilder.control(value.flight?.conditions || []),
        blacklist: this.blacklist(value.flight?.blacklist || { type: "flight", references: [] }),
      }),
      train: new FormGroup({
        price: this.formBuilder.control(value.train?.price),
        class: this.formBuilder.control(value.train?.class),
        exceptionsDuration: this.formBuilder.array<FormGroup<ExceptionFormGroup>>(
          value.train?.exceptionsDuration.map((e) => this.exception(e)) || [],
        ),
        exceptionsSeason: this.formBuilder.array<FormGroup<ExceptionFormGroup>>(
          value.train?.exceptionsSeason.map((e) => this.exception(e)) || [],
        ),
        conditions: this.formBuilder.control(value.train?.conditions || []),
      }),
      rental: new FormGroup({
        price: this.formBuilder.control(value.rental?.price),
        insurranceIsMandatory: this.formBuilder.control(value.rental?.insurranceIsMandatory),
        categories: this.formBuilder.control(value.rental?.categories),
        exceptionsSeason: this.formBuilder.array<FormGroup<ExceptionFormGroup>>(
          value.rental?.exceptionsSeason.map((e) => this.exception(e)) || [],
        ),
        blacklist: this.blacklist(value.rental?.blacklist || { type: "rental", references: [] }),
      }),
      transfer: new FormGroup({
        price: this.formBuilder.control(value.transfer?.price),
      }),
      rse: new FormGroup<CsrFormGroup>({
        flight: new FormGroup<FlightCsrFormGroup>({
          railOnlyUnder: this.time(value.rse?.flight?.railOnlyUnder),
          exceptedPairs: this.formBuilder.array<FormGroup<CsrWayFormGroup>>(
            (value.rse?.flight.exceptedPairs || []).map((p) => this.rseExceptedPair(p)),
          ),
        }),
        rental: new FormGroup({
          promoteElectric: this.formBuilder.control(value.rse?.rental.promoteElectric),
        }),
        hotel: new FormGroup({
          promoteLowImpact: this.formBuilder.control(value.rse?.hotel.promoteLowImpact),
        }),
      }),
      commentMandatory: this.formBuilder.control(value.commentMandatory || "optional"),
    });
  }

  buildDeletionForm(otherPolicies?: ValueLabel<string>[]): FormGroup<DeletePolicyFormGroup> {
    return this.formBuilder.group<DeletePolicyFormGroup>({
      transferAssignationsToOtherPolicyId:
        otherPolicies?.length > 0 ? this.formBuilder.control("", [Validators.required]) : undefined,
    });
  }

  rseExceptedPair(csrWayFormValue?: CsrWayFormValue): FormGroup<CsrWayFormGroup> {
    return new FormGroup<CsrWayFormGroup>({
      origin: this.formBuilder.control(csrWayFormValue?.origin, [this.exceptedPairValidator()]),
      target: this.formBuilder.control(csrWayFormValue?.target, [this.exceptedPairValidator()]),
    });
  }

  /**
   * Permet de garantir que le code IATA pour un aéroport (ou l'accomcode pour un hotel) a bien été saisi
   */
  private exceptedPairValidator(): ValidatorFn {
    return (currentControl: FormControl<ValueLabel<string>>): any =>
      (currentControl.value?.label || "").trim().length > 0 && (currentControl.value?.value || "").trim().length > 0
        ? null
        : { badIata: true };
  }
  /**
   * Permet de garantir qu'une plage de date comporte bien 2 dates (début et fin).
   */
  private dateRangeValidator(): ValidatorFn {
    return (currentControl: FormControl<Date[]>): any =>
      currentControl.value?.length === 2 && !currentControl.value?.some((date) => isNaN(date?.getTime()))
        ? null
        : { badRangeDate: true };
  }

  hotelCountry(hotelCountryPolicy: HotelCountryPolicyFormValue): FormGroup<HotelCountryFormGroup> {
    return this.formBuilder.group<HotelCountryFormGroup>({
      policyForCountry: this.hotelItem(hotelCountryPolicy.policyForCountry),
      cityOrRegionPolicies: this.formBuilder.array<FormGroup<HotelItemFormGroup>>(
        hotelCountryPolicy.cityOrRegionPolicies.map((c) => this.hotelItem(c)),
      ),
      active: this.formBuilder.control(hotelCountryPolicy.active),
      hidden: this.formBuilder.control(hotelCountryPolicy.hidden),
    });
  }

  hotelItem(hotel: HotelItemPolicyFormValue): FormGroup<HotelItemFormGroup> {
    const hotelFormGroup = this.formBuilder.group<HotelItemFormGroup>({
      countryCode: this.formBuilder.control(hotel.countryCode),
      placeId: this.formBuilder.control(hotel.placeId),
      price: this.formBuilder.control(hotel.price),
      rating: this.formBuilder.group({
        min: this.formBuilder.control(hotel.rating.min),
        max: this.formBuilder.control(hotel.rating.max),
      }),
      score: this.formBuilder.control(hotel.score),
      type: this.formBuilder.control(hotel.type),
      label: this.formBuilder.control(hotel.label),
      exceptionsSeason: this.formBuilder.array<FormGroup<ExceptionFormGroup>>(
        hotel.exceptionsSeason.map((e) => this.exception(e)),
      ),
      conditions: this.formBuilder.control(hotel.conditions),

      active: this.formBuilder.control(hotel.active),
    });
    return hotelFormGroup;
  }

  private blacklist(blacklist: PolicyBlacklist): FormGroup<PolicyBlacklistFormGroup> {
    return this.formBuilder.group<PolicyBlacklistFormGroup>({
      type: this.formBuilder.control(blacklist.type),
      references: this.formBuilder.control(blacklist.references),
    });
  }

  private hotelBlacklist(blacklist: PolicyBlacklist): FormGroup<PolicyBlacklistHotelFormGroup> {
    return this.formBuilder.group<PolicyBlacklistHotelFormGroup>({
      type: this.formBuilder.control(blacklist.type),
      references: this.formBuilder.array(blacklist.references.map((reference) => this.blacklistReference(reference))),
    });
  }

  blacklistReference(reference: ValueLabel<string>): FormControl<ValueLabel<string>> {
    return this.formBuilder.control(reference, [this.exceptedPairValidator()]);
  }

  blankException(type: "duration" | "season", classValue?: string): FormGroup<ExceptionFormGroup> {
    return this.exception({
      type: type,
      class: classValue,
      marge: { type: "percent", value: undefined },
    });
  }

  private exception(exception: PolicyExceptionFormValue): FormGroup<ExceptionFormGroup> {
    const formGroup = new FormGroup<ExceptionFormGroup>({
      type: this.formBuilder.control(exception.type),
      class: this.formBuilder.control({ value: exception.class, disabled: exception.type !== "duration" }),
      conditions: this.formBuilder.control({ value: exception.conditions, disabled: exception.type !== "duration" }),
      marge: new FormGroup<MargeFormGroup>({
        type: this.formBuilder.control(exception.marge.type),
        value: this.formBuilder.control(exception.marge.value),
      }),
      from_to: this.formBuilder.control({ value: exception.from_to, disabled: exception.type !== "season" }, [
        Validators.required,
        this.dateRangeValidator(),
      ]),
      recurrent: this.formBuilder.control({ value: exception.recurrent, disabled: exception.type !== "season" }),
      time: this.time(exception.time, exception.type !== "duration"),
    });
    formGroup.controls.from_to.valueChanges.subscribe((dates) => {
      formGroup.controls.from_to.setValue(
        [startOfDay(dates[0]), endOfDay(dates[1])].filter((d) => !!d),
        {
          onlySelf: true,
          emitEvent: false,
          emitModelToViewChange: false,
          emitViewToModelChange: false,
        },
      );
    });
    return formGroup;
  }

  private time(time: TimeFormValue, disabled: boolean = false): FormGroup<TimeFormGroup> {
    return new FormGroup<TimeFormGroup>({
      hours: this.formBuilder.control({ value: time?.hours, disabled }, [Validators.required]),
      minutes: this.formBuilder.control({ value: time?.minutes, disabled }, [Validators.required]),
    });
  }
}

/**
 * Permet de générer un tableau d'entier
 *
 * @param start Premier nombre
 * @param stop Dernier nombre
 * @param step Écart entre les entiers
 * @returns
 */
export const arrayRange = (start: number, stop: number, step: number = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);

/**
 * Permet de créer de nouvelles valeurs
 */
const CreateValue = {
  policy: (societyId: string, label: string) =>
    ({
      guest: true,
      societyId: societyId,
      label: label,
      hotel: [
        {
          price: {
            max: undefined,
          },
          rating: {
            min: undefined,
            max: undefined,
          },
          score: {
            min: 0,
          },
          type: "global",
          exceptions: [],
        },
      ],
      flight: {
        price: undefined,
        class: EClassPolicyFlight.economic,
        superflexible: false,
        exceptions: [],
        conditions: [],
      },
      rental: {
        price: {
          max: undefined,
        },
        insurranceIsMandatory: false,
        categories: [],
        exceptions: [],
      },
      train: {
        price: undefined,
        class: EClassPolicyTrain.class_b,
        exceptions: [],
        conditions: [],
      },
      transfer: {
        price: {
          max: undefined,
        },
      },
      rse: CreateValue.rse(),
      blacklist: [],
    }) as PolicyFront,
  rse: () =>
    ({
      flight: {
        railOnlyUnder: undefined,
        exceptedPairs: [],
      },
      rental: {
        promoteElectric: false,
      },
      hotel: {
        promoteLowImpact: true,
      },
    }) as CsrPolicy,
  hotel: (type: "country" | "global" | "city" | "region", countryCode?: string) =>
    ({
      type,
      countryCode,
      price: {
        max: undefined,
      },
      rating: {
        min: undefined,
        max: undefined,
      },
      score: {
        min: undefined,
      },
      labels: {},
      exceptions: [],
      conditions: [],
    }) as HotelPolicy,
};

/**
 * Permet de convertir une donnée du Backend vers une valeur utilisée pour initialiser le formulaire
 */
class ConverterToFormValue {
  private countryLabels: { [countryCode: string]: string } = {};
  private language: string;

  constructor(
    private translateService: TranslateService,
    userService: UserService,
  ) {
    userService.user.subscribe((user) => {
      if (user?.settings?.language && user.settings.language != this.language) {
        this.language = user.settings.language;
        this.countryLabels = Object.fromEntries(
          (countriesByLanguage[this.language] || []).map((entry) => [entry.value, entry.label]),
        );
      }
    });
  }
  policy(policy: PolicyFront): PolicyFormValue {
    const hotel: HotelPolicyFormValue = {
      global: this.hotelItem(
        policy.hotel.find((hotelPolicy) => hotelPolicy.type === "global") || CreateValue.hotel("global"),
      ),
      countryPolicies: policy.hotel
        .filter((hotelPolicy) => hotelPolicy.type === "country")
        .map((hotelPolicy) => ({
          countryCode: hotelPolicy.countryCode,
          policyForCountry: this.hotelItem(hotelPolicy),
          cityOrRegionPolicies: [],
        })),
      blacklist: (policy.blacklist || [])
        .filter((b) => b.type === "hotel")
        .map((b) => ({
          type: b.type,
          references: b.references.map((r) => ({
            label: r.label,
            value: r.value,
          })),
        }))?.[0],
    };
    const countryPoliciesByCountryCode: { [countryCode: string]: HotelCountryPolicyFormValue } = Object.fromEntries(
      hotel.countryPolicies.map((cp) => [cp.policyForCountry.countryCode, cp]),
    );
    policy.hotel
      .filter((e) => e.type === "city" || e.type === "region")
      .forEach((cityPolicy) => {
        let countryPolicyForCountryCode: HotelCountryPolicyFormValue =
          countryPoliciesByCountryCode[cityPolicy.countryCode];
        if (!countryPolicyForCountryCode) {
          throw "non existent country policy for: " + cityPolicy.countryCode;
        }
        countryPolicyForCountryCode.cityOrRegionPolicies.push(this.hotelItem(cityPolicy));
      });

    return {
      _id: policy._id,
      guest: true,
      societyId: policy.societyId,
      label: policy.label,
      flight: {
        price: policy.flight.price,
        class: policy.flight.class,

        superflexible: policy.flight.superflexible || false,
        exceptionsDuration: this.exceptions(policy.flight.exceptions, "duration"),
        exceptionsSeason: this.exceptions(policy.flight.exceptions, "season"),
        conditions: [...policy.flight.conditions],
        blacklist: (policy.blacklist || [])
          .filter((b) => b.type === "flight")
          .map((b) => ({
            type: b.type,
            references: b.references.map((r) => ({
              label: r.label,
              value: r.value,
            })),
          }))?.[0],
      },
      train: {
        price: policy.train.price,
        class: policy.train.class,
        exceptionsDuration: this.exceptions(policy.train.exceptions, "duration"),
        exceptionsSeason: this.exceptions(policy.train.exceptions, "season"),
        conditions: [...policy.train.conditions],
      },
      hotel,
      rental: {
        price: policy.rental.price?.max,
        insurranceIsMandatory: policy.rental.insurranceIsMandatory,
        categories: [...(policy.rental.categories || [])],
        exceptionsSeason: this.exceptions(policy.rental.exceptions, "season"),
        blacklist: (policy.blacklist || [])
          .filter((b) => b.type === "rental")
          .map((b) => ({
            type: b.type,
            references: b.references.map((r) => ({
              label: r.label,
              value: r.value,
            })),
          }))?.[0],
      },
      transfer: {
        price: policy.transfer?.price?.max,
      },
      rse: {
        flight: {
          railOnlyUnder: this.time(policy.rse?.flight?.railOnlyUnder),
          exceptedPairs: policy.rse?.flight?.exceptedPairs.map((p) => {
            return {
              origin: this.airport(p[0]),
              target: this.airport(p[1]),
            };
          }),
        },
        rental: {
          promoteElectric: policy.rse?.rental.promoteElectric,
        },
        hotel: {
          promoteLowImpact: policy.rse?.hotel.promoteLowImpact,
        },
      },
      commentMandatory: policy.commentMandatory,
    };
  }
  airport(iata: string): ValueLabel<string> {
    return {
      value: iata,
      label: FLIGHT_COMPAGNIES[iata]?.name || iata,
    };
  }

  hotelItem(hotelPolicy: HotelPolicy): HotelItemPolicyFormValue {
    return {
      countryCode: hotelPolicy.countryCode,
      placeId: hotelPolicy.placeId,
      price: hotelPolicy.price?.max,
      rating: {
        min: hotelPolicy.rating?.min,
        max: hotelPolicy.rating?.max,
      },
      score: hotelPolicy.score?.min,
      type: hotelPolicy.type,
      label: this.hotelPolicyLabelResolver(hotelPolicy),
      exceptionsSeason: this.exceptions(hotelPolicy.exceptions, "season"),
      conditions: hotelPolicy.conditions || [],
      active: undefined,
    };
  }

  exceptions(exceptions: PolicyException[], type: "season" | "duration"): PolicyExceptionFormValue[] {
    return (exceptions || [])
      .filter((e) => e.type === type)
      .map(
        (e) =>
          ({
            type: e.type,
            time: this.time(e.time),
            class: e.class === undefined ? null : e.class,
            marge: {
              type: e.marge.type || "percent",
              value: e.marge.value,
            },
            from_to: [parseFromToDate(e.from, false), parseFromToDate(e.to, true)].filter(
              (d) => !!d && !isNaN(d.getTime()),
            ),

            // Si undefined, prendre false
            recurrent: !!e.recurrent,
            conditions: [...(e.conditions || [])],
          }) as PolicyExceptionFormValue,
      );
  }
  time(totalMinutes?: number): TimeFormValue {
    if (typeof totalMinutes !== "number" || isNaN(totalMinutes)) {
      return { hours: 0, minutes: 0 };
    }
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes - hours * 60;
    return { hours, minutes };
  }

  hotelPolicyLabelResolver(policy?: HotelPolicy): string {
    const label = policy?.labels?.[this.language];
    if (label) {
      return label;
    }
    if (policy?.type === "country") {
      return (
        this.countryLabels[policy.countryCode] ||
        this.translateService.instant("POLICY.COUNTRY") + ` (${policy.countryCode})`
      );
    }
    if (policy?.type === "city") {
      return this.translateService.instant("POLICY.CITY");
    }
    if (policy?.type === "region") {
      return this.translateService.instant("POLICY.REGION");
    }
    return "";
  }
}

/**
 * Permet de convertir une valeur du formulaire en donnée à envoyer au Backend
 */
class ConverterToValue {
  constructor(private languageSupplier: () => string) {}
  policy(formValue: PolicyFormValue): PolicyFront {
    return {
      _id: formValue._id,
      guest: true,
      societyId: formValue.societyId,
      label: formValue.label,
      blacklist: [formValue.hotel?.blacklist, formValue.flight?.blacklist, formValue.rental?.blacklist]
        .filter((b) => !!b)
        .map((b) => ({
          type: b.type,
          references: b.references
            .filter((r) => !!r)
            .map((r) => ({
              label: r.label,
              value: r.value,
            })),
        })),
      flight: {
        price: formValue.flight.price,
        class: formValue.flight.class,
        superflexible: formValue.flight.superflexible,
        exceptions: this.policyExceptions([
          ...(formValue.flight.exceptionsDuration || []),
          ...(formValue.flight.exceptionsSeason || []),
        ]),
        conditions: [...formValue.flight.conditions],
      },
      train: {
        price: formValue.train.price,
        class: formValue.train.class,
        exceptions: this.policyExceptions([
          ...(formValue.train.exceptionsDuration || []),
          ...(formValue.train.exceptionsSeason || []),
        ]),
        conditions: [...formValue.train.conditions],
      },
      hotel: [
        this.hotel(formValue.hotel.global),
        ...formValue.hotel.countryPolicies.flatMap((countryPolicy) => [
          this.hotel(countryPolicy.policyForCountry),
          ...countryPolicy.cityOrRegionPolicies.map((cityPolicy) => this.hotel(cityPolicy)),
        ]),
      ].filter((hotelPolicy) => hotelPolicy?.type === "global" || !!hotelPolicy?.placeId),
      transfer: {
        price: {
          max: formValue.transfer.price,
        },
      },
      rental: {
        price: {
          max: formValue.rental.price,
        },
        insurranceIsMandatory: formValue.rental.insurranceIsMandatory,
        categories: [...formValue.rental.categories],
        exceptions: this.policyExceptions([...(formValue.rental.exceptionsSeason || [])]),
      },
      rse: {
        flight: {
          railOnlyUnder: this.time(formValue.rse?.flight?.railOnlyUnder),
          exceptedPairs: formValue.rse?.flight?.exceptedPairs
            .map((p) => [p.origin?.value, p.target?.value].filter((a) => !!a))
            .filter((p) => p.length === 2),
        },
        rental: {
          promoteElectric: formValue.rse?.rental?.promoteElectric,
        },
        hotel: {
          promoteLowImpact: formValue.rse?.hotel?.promoteLowImpact,
        },
      },
      commentMandatory: formValue.commentMandatory,
    };
  }
  hotel(hotelFormValue: HotelItemPolicyFormValue): HotelPolicy {
    const labels = {};
    labels[this.languageSupplier()] = hotelFormValue.label;
    return {
      countryCode: hotelFormValue.countryCode,
      placeId: hotelFormValue.placeId,
      price: {
        max: hotelFormValue.price,
      },
      rating: hotelFormValue.rating,
      score: {
        min: hotelFormValue.score,
      },
      type: hotelFormValue.type,
      labels,
      exceptions: this.policyExceptions(hotelFormValue.exceptionsSeason),
      conditions: hotelFormValue.conditions,
    };
  }

  policyExceptions(exceptions: PolicyExceptionFormValue[]): PolicyException[] {
    return exceptions
      .filter((i) => !!i)
      .map((e) => {
        const result = {
          type: e.type,
          time: this.time(e.time),
          class: e.class,
          marge: {
            type: e.marge.type,
            value: e.marge.value,
          },
          from: formatFromToDate(e.from_to?.[0]),
          to: formatFromToDate(e.from_to?.[1]),
          recurrent: e.recurrent,
        } as PolicyException;
        if (!result.from) {
          delete result.from;
        }
        if (!result.to) {
          delete result.to;
        }
        if (result.marge.value === null) {
          result.marge.value = undefined;
        }
        if (e.conditions) {
          result.conditions = [...e.conditions];
        }
        return result;
      });
  }
  time(timeFormValue: TimeFormValue): number {
    return timeFormValue ? timeFormValue.hours * 60 + timeFormValue.minutes : undefined;
  }
}

export function parseFromToDate(value: string, endOfDay: boolean): Date {
  let date = DateTime.fromISO(value);
  if (endOfDay) {
    date = date.endOf("day");
  } else {
    date = date.startOf("day");
  }
  return date.toJSDate();
}

function startOfDay(date: Date) {
  return date === undefined ? undefined : DateTime.fromJSDate(date).startOf("day").toJSDate();
}

function endOfDay(date: Date) {
  return date === undefined ? undefined : DateTime.fromJSDate(date).endOf("day").toJSDate();
}

export function formatFromToDate(value: Date): string {
  return value ? DateTime.fromJSDate(value).toUTC().toISO() : undefined;
}
export interface AddedAndRemoved<T> {
  added: T[];
  removed: T[];
}

export interface FormPolicyEdition {
  _originalPolicy: PolicyFront;
  form: FormGroup<PolicyFormGroup>;
}
export interface FormPolicyDeletion {
  transferAssignationsToPolicyIdList: ValueLabel<string>[];
  form: FormGroup<DeletePolicyFormGroup>;
}
export interface TimeOptions {
  hours: ValueLabel<number>[];
  minutes: ValueLabel<number>[];
}

/* ***** Typage des formulaires ********************************************************************** */

export interface PolicyFormGroup {
  _id: FormControl<string>;
  guest: FormControl<boolean>;
  societyId: FormControl<string>;
  label: FormControl<string>;
  hotel: FormGroup<HotelFormGroup>;
  flight: FormGroup<FlightFormGroup>;
  rental: FormGroup<RentalFormGroup>;
  train: FormGroup<TrainFormGroup>;
  transfer: FormGroup<TransferFormGroup>;
  rse: FormGroup<CsrFormGroup>;
  commentMandatory: FormControl<string>;
}
export interface DeletePolicyFormGroup {
  transferAssignationsToOtherPolicyId?: FormControl<string>;
}
export interface FlightFormGroup {
  price: FormControl<number>;
  class: FormControl<EClassPolicyFlight>;
  superflexible: FormControl<boolean>;
  exceptionsDuration: FormArray<FormGroup<ExceptionFormGroup>>;
  exceptionsSeason: FormArray<FormGroup<ExceptionFormGroup>>;
  conditions: FormControl<string[]>;
  blacklist: FormGroup<PolicyBlacklistFormGroup>;
}
export interface TrainFormGroup {
  price: FormControl<number>;
  class: FormControl<EClassPolicyTrain>;
  exceptionsDuration: FormArray<FormGroup<ExceptionFormGroup>>;
  exceptionsSeason: FormArray<FormGroup<ExceptionFormGroup>>;
  conditions: FormControl<string[]>;
}
export interface HotelFormGroup {
  global: FormGroup<HotelItemFormGroup>;
  countryPolicies: FormArray<FormGroup<HotelCountryFormGroup>>;
  blacklist: FormGroup<PolicyBlacklistHotelFormGroup>;
}
export interface HotelCountryFormGroup {
  policyForCountry: FormGroup<HotelItemFormGroup>;
  cityOrRegionPolicies: FormArray<FormGroup<HotelItemFormGroup>>;
  active: FormControl<number>;
  hidden: FormControl<boolean>;
}
export interface HotelItemFormGroup {
  countryCode: FormControl<string>;
  placeId: FormControl<string>;
  price: FormControl<number>;
  rating: FormGroup<MinMaxFormGroup>;
  score: FormControl<number>;
  type: FormControl<"global" | "country" | "city" | "region">;
  label: FormControl<string>;
  exceptionsSeason: FormArray<FormGroup<ExceptionFormGroup>>;
  conditions: FormControl<string[]>;

  active: FormControl<number>;
}
export interface MinMaxFormGroup {
  min: FormControl<number>;
  max: FormControl<number>;
}
export interface RentalFormGroup {
  price: FormControl<number>;
  insurranceIsMandatory: FormControl<boolean>;
  categories: FormControl<ECategoryPolicyRental[]>;
  exceptionsSeason: FormArray<FormGroup<ExceptionFormGroup>>;
  blacklist: FormGroup<PolicyBlacklistFormGroup>;
}
export interface TransferFormGroup {
  price: FormControl<number>;
}
export interface CsrFormGroup {
  flight: FormGroup<FlightCsrFormGroup>;
  rental: FormGroup<RentalCsrFormGroup>;
  hotel: FormGroup<HotelCsrFormGroup>;
}
export interface FlightCsrFormGroup {
  railOnlyUnder: FormGroup<TimeFormGroup>;
  exceptedPairs: FormArray<FormGroup<CsrWayFormGroup>>;
}
export interface RentalCsrFormGroup {
  promoteElectric: FormControl<boolean>;
}
export interface HotelCsrFormGroup {
  promoteLowImpact: FormControl<boolean>;
}
export interface PolicyBlacklistFormGroup {
  type: FormControl<string>;
  references: FormControl<ValueLabel<string>[]>;
}
export interface PolicyBlacklistHotelFormGroup {
  type: FormControl<string>;
  references: FormArray<FormControl<ValueLabel<string>>>;
}
export interface ExceptionFormGroup {
  type: FormControl<"duration" | "season">;
  time: FormGroup<TimeFormGroup>;
  class: FormControl<string>;
  marge: FormGroup<MargeFormGroup>;
  from_to: FormControl<Date[]>;
  recurrent: FormControl<boolean>;
  conditions: FormControl<string[]>;
}
export interface TimeFormGroup {
  hours: FormControl<number>;
  minutes: FormControl<number>;
}
export interface MargeFormGroup {
  type: FormControl<"flat" | "percent">;
  value: FormControl<number>;
}
export interface CsrWayFormGroup {
  origin: FormControl<ValueLabel<string>>;
  target: FormControl<ValueLabel<string>>;
}

/* ***** Type des FormValue (valeur pour les formulaires) ********************************************************************** */

export declare interface PolicyFormValue {
  _id?: string;
  guest: boolean;
  societyId?: string;
  label: string;
  hotel: HotelPolicyFormValue;
  flight: FlightPolicyFormValue;
  rental: RentalPolicyFormValue;
  train: TrainPolicyFormValue;
  transfer: TransferPolicyFormValue;
  rse: CsrPolicyFormValue;
  commentMandatory?: string;
}
export declare interface FlightPolicyFormValue {
  price: number;
  class: EClassPolicyFlight;
  superflexible: boolean;
  blacklist?: PolicyBlacklist;
  exceptionsDuration: PolicyExceptionFormValue[];
  exceptionsSeason: PolicyExceptionFormValue[];
  conditions: string[];
}
export declare interface TrainPolicyFormValue {
  price: number;
  class: EClassPolicyTrain;
  exceptionsDuration: PolicyExceptionFormValue[];
  exceptionsSeason: PolicyExceptionFormValue[];
  conditions: string[];
}
export declare interface HotelPolicyFormValue {
  global: HotelItemPolicyFormValue;
  countryPolicies: HotelCountryPolicyFormValue[];
  blacklist?: PolicyBlacklist;
}
export declare interface HotelCountryPolicyFormValue {
  policyForCountry: HotelItemPolicyFormValue;
  cityOrRegionPolicies: HotelItemPolicyFormValue[];
  // Pour contrôler l'ouverture de l'accordion
  active?: number;
  hidden?: boolean;
}
export declare interface HotelItemPolicyFormValue {
  countryCode?: string;
  placeId?: string;
  price: number;
  rating: MinMaxFormValue;
  score: number;
  type: "global" | "country" | "city" | "region";
  label: string;
  exceptionsSeason: PolicyExceptionFormValue[];
  conditions: string[];

  // Pour contrôler l'ouverture de l'accordion
  active?: number;
}
export declare interface RentalPolicyFormValue {
  price: number;
  insurranceIsMandatory: boolean;
  categories: ECategoryPolicyRental[];
  exceptionsSeason: PolicyExceptionFormValue[];
  blacklist: PolicyBlacklist;
}
export declare interface TransferPolicyFormValue {
  price: number;
}
export declare interface PolicyExceptionFormValue {
  type: "duration" | "season";
  time?: TimeFormValue;
  class: string;
  marge: {
    type: "flat" | "percent";
    value: number;
  };
  from_to?: Date[];
  recurrent?: boolean;
  conditions?: string[];
}
export declare interface MinMaxFormValue {
  min: number;
  max: number;
}
export declare interface TimeFormValue {
  hours: number;
  minutes: number;
}
export declare interface CsrPolicyFormValue {
  flight: {
    railOnlyUnder: TimeFormValue;
    exceptedPairs: CsrWayFormValue[];
  };
  rental: {
    promoteElectric: boolean;
  };
  hotel: {
    promoteLowImpact: boolean;
  };
}
export declare interface CsrWayFormValue {
  origin: ValueLabel<string>;
  target: ValueLabel<string>;
}
