import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { SearchBody } from "../../hotel";
import { DateTime } from "luxon";

@Component({
  selector: "spt-travel-hotel-page-availabilities",
  styleUrls: ["./availabilities.component.scss"],
  templateUrl: "./availabilities.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class AvailabilitiesComponent implements OnInit {
  protected _availabilities: any;
  @Input() search: {
    data: SearchBody;
  };
  public nbNights: number;
  public nbRooms: number;
  public nbGuests: number;
  public roomNames = {};
  protected bestPriceByRoom = {};
  public clickedRooms: { [key: string]: boolean } = {};
  @Input() seeOutOfPolicy: boolean;
  @Input() set availabilities(value: any) {
    this._availabilities = value;
    this.groupedPossibilities = this._availabilities.possibilities.reduce((possibilities, possibility) => {
      const roomNames = possibility.rooms.map((room) => room.RoomDescription);
      this.roomNames[roomNames] = [...roomNames];

      if (this.seeOutOfPolicy || (!this.seeOutOfPolicy && possibility.inPolicy)) {
        if (!possibilities[roomNames]) {
          possibilities[roomNames] = {};
        }
        if (!possibilities[roomNames][possibility.type]) {
          possibilities[roomNames][possibility.type] = [];
        }
        possibilities[roomNames][possibility.type].push(possibility);
      }
      return possibilities;
    }, {});
    this.groupedPossibilitiesKeys = Object.keys(this.groupedPossibilities);
    if (this.groupedPossibilitiesKeys.length > 0) {
      this.clickedRooms = {};
      this.toggleRoom(this.groupedPossibilitiesKeys[0]);
    }
    this.bestPriceByRoom = Object.fromEntries(
      Object.entries(this.groupedPossibilities).map(([roomName, room]) => [roomName, this.bestPriceForRoom(room)]),
    );
  }
  get availabilities(): any {
    return this._availabilities;
  }
  public groupedPossibilities: any;
  public groupedPossibilitiesKeys: string[];
  public selectedAvailability: any;

  @Output() selectFare: EventEmitter<any> = new EventEmitter();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    const dateIn = DateTime.fromISO(this.search.data.datein);
    const dateOut = DateTime.fromISO(this.search.data.dateout);
    this.nbNights = dateOut.diff(dateIn, "days").days;
    this.nbRooms = this.search.data.rooms.length;
    this.nbGuests = this.search.data.userIds.length;
  }
  bestPriceForRoom(room) {
    if (room) {
      const prices = Object.keys(room).map((key) => room[key][0].price);
      const formattedMinPrice = parseFloat(Math.min(...prices).toFixed(2));
      return formattedMinPrice;
    }
    return undefined;
  }
  bestPrice(roomName: string) {
    return this.bestPriceForRoom(this.groupedPossibilities?.[roomName]);
  }

  select(fare) {
    this.selectedAvailability = fare;
    this.selectFare.emit(this.selectedAvailability);
  }

  toggleRoom(room: string): void {
    this.clickedRooms[room] = !this.clickedRooms[room];
    this.cdr.detectChanges();
  }
}
