import { NgModule } from "@angular/core";

import { SharedModule } from "src/app/@shared/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { FlightValidateOptionComponent } from "./flight-validate-option.component";
import { TabViewModule } from "primeng/tabview";
import { DetailComponent } from "../detail/detail.component";
import { DetailInformationComponent } from "../detail/information/information.component";
import { BreadcrumbComponent } from "../breadcrumb/breadcrumb.component";

const SELECT_MEMBERS_MODULES: Array<any> = [SharedModule, TabViewModule, TranslateModule.forChild()];

@NgModule({
  declarations: [FlightValidateOptionComponent, DetailComponent, BreadcrumbComponent],
  imports: [...SELECT_MEMBERS_MODULES],
  exports: [FlightValidateOptionComponent, BreadcrumbComponent],
})
export class EndModule {}
