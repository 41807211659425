import { Component, EventEmitter, OnInit, OnDestroy, Output, Input } from "@angular/core";
import { SearchCriteria, SearchResult } from "../search-engine-service";
import { SeminarSearchOptions, SeminarSearchService } from "./seminar-search.service";
import { SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { Address } from "src/app/@shared/@types/models";
import { SocietyService } from "src/app/@shared/services/society.service";
import { CommonService } from "src/app/@shared/services/common.service";
import { SuggestPanel, SuggestPanelService } from "../common/suggest-panel.service";
import { Subscription } from "rxjs";

@Component({
  selector: "spt-search-seminar",
  templateUrl: "./search-seminar.component.html",
  styleUrls: ["./search-seminar.component.scss"],
})
export class SearchSeminarComponent implements OnInit, OnDestroy {
  searchEngineType: string;
  seminarKindOptions: Array<SelectItem> = [];
  byTypes: Array<SelectItem> = [];

  @Input() searchCriteria: SearchCriteria<Address, SeminarSearchOptions>;
  isValid: boolean = false;
  now: Date = moment().startOf("day").toDate();
  yearRange: string = `${this.now.getFullYear()}:${this.now.getFullYear() + 10}`;

  private observablesToClearOnDestroy: Subscription[] = [];
  suggestOpened: boolean = false;

  constructor(
    protected searchService: SeminarSearchService,
    private translateService: TranslateService,
    private societyService: SocietyService,
    protected suggestPanelService: SuggestPanelService,
    // utilisé dans le template
    protected commonService: CommonService,
  ) {
    this.searchEngineType = searchService.getType();
  }

  ngOnInit(): void {
    if (!this.searchCriteria) {
      this.searchCriteria = this.searchService.createBlankCriteria();
    }
    // On indique un membre, juste pour rendre valide le SearchCriteria.
    // Les passagers ne sont pas utilisés dans SeminarSearchService#launchSearch
    this.searchCriteria.mainTravel.people.push(this.societyService.society.value.members[0]);

    // Pas de favoris sur "séminaire", mais un "écran d'accueil"
    this.suggestPanelService.init("seminar-welcome");
    this.observablesToClearOnDestroy.push(this.suggestPanelService.onChange.subscribe((panel) => {}));
    this.seminarKindOptions.push(
      {
        label: this.translateService.instant("SEARCH.SEMINAR.TYPE.SEMINAR"),
        value: "seminar",
      },
      {
        label: this.translateService.instant("SEARCH.SEMINAR.TYPE.CONFERENCE"),
        value: "conference",
      },
      {
        label: this.translateService.instant("SEARCH.SEMINAR.TYPE.CONVENTION"),
        value: "convention",
      },
      {
        label: this.translateService.instant("SEARCH.SEMINAR.TYPE.MEETING"),
        value: "meeting",
      },
    );
    this.byTypes = [
      {
        label: this.translateService.instant("SEARCH.SEMINAR.BY.PERSON"),
        value: "person",
      },
      {
        label: this.translateService.instant("SEARCH.RESULT.TOTAL"),
        value: "group",
      },
    ];
  }

  ngOnDestroy(): void {
    this.observablesToClearOnDestroy.forEach((o) => o.unsubscribe());
    this.observablesToClearOnDestroy.length = 0;
  }

  activateSuggest(suggestToActivate: string) {
    this.suggestPanelService.activate(suggestToActivate);
    this.suggestOpened = true;
  }

  @Output()
  onSearchResult: EventEmitter<SearchResult> = new EventEmitter<SearchResult>();

  search(): void {
    this.searchService
      .launchSearch(this.searchCriteria)
      .subscribe((searchResult: SearchResult) => this.onSearchResult.emit(searchResult));
  }

  resetSuggestPanel(): void {
    this.suggestPanelService.reset();
    this.suggestOpened = false;
  }

  checkValidity() {
    this.isValid = this.searchService.searchCriteriaIsValid(this.searchCriteria);
  }
}
