<div class="cancel-section">
  <i *ngIf="isSmallDevice" class="mobile-close-modify spt-icon-back-arrow" (click)="returnToDetails.emit()"></i>
  <div *ngIf="!isSmallDevice" class="close-modify-container" (click)="returnToDetails.emit()">
    <i class="close-modify spt-icon-back-arrow"></i>
    <span class="return">{{ "GLOBAL.RETURN" | translate }}</span>
  </div>

  <h2 class="modify-title">{{ "BOOKING.MODIFICATION" | translate }}</h2>

  <p-messages [(value)]="warningMessages" *ngIf="warningMessages.length > 0" [closable]="false"></p-messages>

  <div class="cancellation-content" [ngSwitch]="item.type">
    <ng-container *ngSwitchCase="'train'">
      <spt-reservations-modify-train
        [itemId]="item.id"
        [orderId]="item.detail.id"
        [orderItems]="item.detail.orderItems"
        [relatedOrderItems]="item.detail.relatedOrderItems"
        [travelers]="item.travelers"
        [provider]="item.provider"
        [hasTickets]="!!item.documentIds.length"
        (selectExchange)="onSelected($event.exchangeBody)"
      ></spt-reservations-modify-train>
    </ng-container>
    <ng-container *ngSwitchCase="'flight'">
      <spt-reservations-modify-flight
        [itemId]="item.id"
        [item]="item"
        [orderId]="item.detail.id"
        [orderItems]="item.detail.orderItems"
        [relatedOrderItems]="item.detail.relatedOrderItems"
        [travelers]="item.travelers"
        [provider]="item.provider"
        [hasTickets]="!!item.documentIds.length"
        (selectExchange)="onSelected($event.exchangeBody)"
      ></spt-reservations-modify-flight>
    </ng-container>
  </div>
</div>
