import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable, Predicate } from "@angular/core";
import { environment } from "../../../environments/environment";
import { catchError, filter, map, mergeMap, tap, toArray } from "rxjs/operators";
import { Observable, BehaviorSubject, of, zip, from, EMPTY, forkJoin } from "rxjs";
import {
  AdditionalData,
  Basket,
  BasketInfo,
  BasketItem,
  BasketResult,
  BasketStatusEnum,
  Folder,
  FolderResult,
  FoldersResult,
} from "../@types/basket";
import { UserService } from "./user.service";
import { User } from "../@types/user";
import { UtilsTypes } from "../@types/utils";
import { CanValidateBasketResult } from "./authorization.service";
import { normalizeStringForSearch } from "../pipes/normalize-filter.pipe";
import { MemberSociety } from "../@types/society";
import { SessionService, SessionType } from "./session.service";
import { NGX_LOADING_BAR_IGNORED } from "@ngx-loading-bar/http-client";
import { DateTime } from "luxon";
import { merge } from "lodash";

const DEFAULT_BASKET_INFO = {
  criteria: undefined,
  result: [],
};
const DEFAULT_COUNTING: Counting = {};

// On nettoie le cache toute les 120 secondes afin d'avoir d'éventuelles données plus fraîches.
const CACHE_CLEAR_INTERVAL = /* <TO DELETE> */ 500 * /* </TO DELETE> */ 120 * 1000;

const VALIDATE_BASKET_NEEDED_FOR_STATUS = ["validation"];
const CANVALIDATEBASKETRESULT_IGNORE_RESULT: CanValidateBasketResult = {
  isAuthorized: undefined,
  commentMandatory: undefined,
  errors: [],
  resultPoliciesByItem: {},
};
const VALIDATORUSERNAMES_IGNORE_RESULT = [];
const NEEDED_STATUS_FOR_COUNTING: BasketStatusEnum[] = ["opened", "validation"];
@Injectable({
  providedIn: "root",
})
export class BasketService {
  private httpCacheBasketInfo: { [basketId: string]: Observable<BasketInfo> } = {};
  private httpAverageBookTime: { [url: string]: Observable<any> } = {};
  private httpCacheFolderById: { [url: string]: Observable<any> } = {};
  private httpCacheValidatorUsernames: { [url: string]: Observable<string[]> } = {};
  private httpCacheBasket: { [url: string]: Observable<BasketResult> } = {};
  private httpCacheFolders: { [url: string]: Observable<FoldersResult> } = {};
  private httpCacheBaskets: { [url: string]: Observable<Basket[]> } = {};
  private httpCacheBasketsWithAllDataResult: { [url: string]: Observable<BasketsWithAllDataResult> } = {};
  private httpCacheCanValidateBasket: { [basketId: string]: Observable<CanValidateBasketResult> } = {};

  public readonly actionOnBasket$: BehaviorSubject<BasketAction> = new BehaviorSubject<BasketAction>({});
  public readonly baskets$: BehaviorSubject<Basket[]> = new BehaviorSubject<Basket[]>([]);
  // TODO: à revoir pour avoir une résultat plus prédictible. Sa valeur ne doit pas dépendre de critère de filtre sur getBasketInfos
  private readonly basketInfos$: BehaviorSubject<BasketInfoUpdate> = new BehaviorSubject<BasketInfoUpdate>(
    DEFAULT_BASKET_INFO,
  );
  public readonly counting$: BehaviorSubject<Counting> = new BehaviorSubject(DEFAULT_COUNTING);

  public readonly openedbasketInfo$: BehaviorSubject<BasketInfo | undefined> = new BehaviorSubject(undefined);

  private activeBasket: string;

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService<SessionType>,
  ) {
    setInterval(() => this.clearCache(), CACHE_CLEAR_INTERVAL);
    this.sessionService
      .getLoggedIn()
      .pipe(
        filter((o) => !!o.session),
        mergeMap(() => this.forceUpdateCounting(true)),
      )
      .subscribe();
    this.basketInfos$.subscribe((basketInfoUpdate) => this.updateCounting(basketInfoUpdate));
    this.sessionService.getLoggedOut().subscribe(() => {
      this.clearCache();
      this.openedbasketInfo$.next(undefined);
      this.baskets$.next([]);
      this.basketInfos$.next(DEFAULT_BASKET_INFO);
      this.counting$.next(DEFAULT_COUNTING);
    });
  }

  private clearCache() {
    this.httpCacheFolders = {};
    this.httpCacheBaskets = {};
    this.httpCacheBasketInfo = {};
    this.httpAverageBookTime = {};
    this.httpCacheFolderById = {};
    this.httpCacheValidatorUsernames = {};
    this.httpCacheBasket = {};
    this.httpCacheFolders = {};
    this.httpCacheCanValidateBasket = {};
  }

  private searchCriteriaToQueryParams(searchCriteria?: SearchCriteria) {
    const params = [];

    if (searchCriteria?.skip > 0) {
      params.push("skip=" + searchCriteria.skip);
    }

    if (searchCriteria?.limit > 0) {
      params.push("limit=" + searchCriteria?.limit);
    } else {
      // On définit une limit par défaut, celui ouvert + 10 autres en validation
      params.push("limit=10");
    }
    if (searchCriteria?.authorizationType) {
      params.push("authorizationType=validate-basket");
    }
    if (searchCriteria?.status?.length > 0) {
      params.push("status=" + searchCriteria.status.sort().join(","));
    }

    if (searchCriteria?.userId?.length > 0) {
      params.push("userId=" + searchCriteria.userId);
    }

    const dates = searchCriteria?.dates?.filter((d) => !!d).sort((a, b) => a.getTime() - b.getTime()) || [];

    if (dates.length === 2) {
      params.push("from=" + DateTime.fromJSDate(dates[0]).startOf("day").toISODate());
      params.push("to=" + DateTime.fromJSDate(dates[1]).endOf("day").toISODate());
    }
    if (searchCriteria?.filterText?.length > 0) {
      params.push("filterText=" + searchCriteria.filterText);
    }

    if (params.length === 0) {
      return "";
    }
    return "?" + params.join("&");
  }

  private getBasketsWithAllData(
    withoutCache?: boolean,
    searchCriteria?: SearchCriteria,
    showLoader: boolean = true,
  ): Observable<BasketsWithAllDataResult> {
    let endpoint: string;
    if (!searchCriteria.validating) {
      endpoint = `${environment.api}/baskets/v2/all${this.searchCriteriaToQueryParams(searchCriteria)}`;
    } else {
      endpoint = `${environment.api}/baskets/v2/validation-pending${this.searchCriteriaToQueryParams(searchCriteria)}`;
    }

    const cacheValue = this.httpCacheBasketsWithAllDataResult[endpoint];
    if (cacheValue && !withoutCache) {
      console.log("get data from cache for:", endpoint);
      return cacheValue;
    }
    return (this.httpCacheBasketsWithAllDataResult[endpoint] = this.httpClient
      .get<{
        baskets: Basket[];
        folders: Folder[];
        items: BasketItem[];
      }>(endpoint, {
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, !showLoader),
        headers: {
          ignoreErrorMessage: "true",
        },
      })
      .pipe(
        map((result) => {
          // FIX temporaire en attendant une correction du back
          if ((result as any).basket && !result.baskets) {
            result.baskets = (result as any).basket;
          }
          return result;
        }),
        tap((result) => {
          result.baskets.forEach((basket) => fixBasketDateProperties(basket));
          this.baskets$.next(result.baskets);
        }),
        map((result) => ({
          baskets: Object.fromEntries(result.baskets?.map((b) => [b.id, b]) || []),
          folders: Object.fromEntries(result.folders?.map((f) => [f.id, f]) || []),
          items: Object.fromEntries(result.items?.map((i) => [i.id, i]) || []),
        })),
      ));
  }

  updateUserOpenedBasket(id): string {
    this.activeBasket = id;
    return this.activeBasket;
  }

  getUserOpenedBasket(): string {
    return this.activeBasket;
  }

  getBaskets(
    withoutCache?: boolean,
    searchCriteria: SearchCriteria = { limit: 2 },
    showLoader: boolean = true,
  ): Observable<Basket[]> {
    let endpoint: string = `${environment.api}/baskets${this.searchCriteriaToQueryParams(searchCriteria)}`;
    const cacheValue = this.httpCacheBaskets[endpoint];
    if (cacheValue && !withoutCache) {
      return cacheValue;
    }
    return (this.httpCacheBaskets[endpoint] = this.httpClient
      .get<Basket[]>(endpoint, {
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, !showLoader),
        headers: {
          ignoreErrorMessage: "true",
        },
      })
      .pipe(
        map((baskets: Basket[]) => baskets.map((basket) => fixBasketDateProperties(basket))),
        tap((baskets: Basket[]) => {
          this.baskets$.next(baskets);
        }),
      ));
  }

  getTravelers(basketId: string): Observable<User[]> {
    return this.getFolders(basketId).pipe(
      map((data: { items: any; folders: any[] }) =>
        Object.values(data.items)
          .flatMap((items) => items)
          .flatMap((item: any) => item.travelers as User[]),
      ),
    );
  }

  get(basketId: string, showLoader: boolean = true): Observable<BasketResult> {
    const endpoint: string = `${environment.api}/baskets/v2/all/${basketId}?authorizationType=validate-basket`;
    const cacheValue: Observable<BasketResult> = this.httpCacheBasket[endpoint];
    if (cacheValue) {
      return cacheValue;
    }
    return (this.httpCacheBasket[endpoint] = this.httpClient
      .get<BasketResult>(endpoint, {
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, !showLoader),
      })
      .pipe(
        map((basketResult) => {
          let basket = basketResult.basket;
          if (!basket && (basketResult as any).baskets && !Array.isArray((basketResult as any).baskets)) {
            // Correction d'un problème de modélisation de la donnée côté back
            basket = (basketResult as any).baskets;
          }
          return Object.assign(basketResult, { basket: fixBasketDateProperties(basket) });
        }),
      ));
  }

  getFolders(id: string, withoutCache?: boolean, showLoader: boolean = true): Observable<FoldersResult> {
    const endpoint: string = `${environment.api}/baskets/${id}/folders`;
    const cacheValue: Observable<FoldersResult> = this.httpCacheFolders[endpoint];
    if (cacheValue && !withoutCache) {
      return cacheValue;
    }
    return (this.httpCacheFolders[endpoint] = this.httpClient.get(endpoint, {
      context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, !showLoader),
    }) as Observable<FoldersResult>);
  }

  close(id: string, showLoader: boolean = true): Observable<any> {
    const endpoint: string = `${environment.api}/baskets/${id}`;
    return this.httpClient
      .delete(endpoint, {
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, !showLoader),
      })
      .pipe(
        mergeMap((res) => this.forceUpdateCounting(false, res)),
        tap(() => {
          const baskets: Array<Basket> = this.baskets$.value.filter((basket: Basket) => basket.id !== id);
          this.baskets$.next(baskets);
          this.actionOnBasket$.next({ action: "close", basketId: id });
        }),
      );
  }

  createBasket(modifyWithoutValidation: boolean): Observable<any> {
    const endpoint: string = `${environment.api}/baskets`;

    const current: any =
      this.baskets$.value &&
      this.baskets$.value.length > 0 &&
      this.baskets$.value.find(
        (c: { status: string; userId: string }) =>
          c.status === "opened" && c.userId === this.userService.user.value._id.toString(),
      );
    if (current) {
      return of(current);
    }

    return this.httpClient
      .post(
        endpoint,
        {
          modifyWithoutValidation,
        },
        {
          headers: { ignoreErrorMessage: "true" },
        },
      )
      .pipe(
        map((basket: Basket) => {
          this.baskets$.next([...this.baskets$.value, basket]);
          this.actionOnBasket$.next({ action: "create", basketId: basket.id });
          return basket;
        }),
        tap(() => this.clearCache()),
      );
  }

  addItemToBasket(
    userIds: string[],
    price: { amount: number; currency: string },
    type: string,
    formData: any,
    language: string,
    oldItemId?: string,
    relativeBasketId?: string,
    relativeFolderId?: string,
  ): Observable<any> {
    oldItemId = oldItemId || "";
    const current: any =
      this.baskets$.value &&
      this.baskets$.value.length > 0 &&
      this.baskets$.value.find(
        (c: { status: string; userId: string }) =>
          c.status === "opened" && c.userId === this.userService.user.value._id.toString(),
      );
    if (!current) {
      return of(null);
    }
    const endpoint: string = `${environment.api}/baskets/${current.id}/folders`;

    const data: object = {
      userIds,
      price,
      type,
      formData,
      oldItemId: !!oldItemId ? oldItemId : undefined,
      relativeBasketId,
      relativeFolderId,
    };

    return this.httpClient
      .post(
        endpoint,
        { ...data },
        {
          headers: {
            // ignoreErrorMessage: "true",
            "Accept-Language": language,
          },
        },
      )
      .pipe(
        mergeMap((returnValue) => this.forceUpdateCounting(false, returnValue)),
        tap((basket) => {
          this.actionOnBasket$.next({ action: "addItemToBasket", basketId: basket.id });
        }),
      );
  }

  private updateCounting(basketInfoUpdate: BasketInfoUpdate) {
    const counting: Counting = {};
    if (!basketInfoUpdate.criterias?.status || basketInfoUpdate.criterias?.status?.includes("pending")) {
      const pendingBaskets = basketInfoUpdate.result.filter(FILTER_PENDING);
      const pending = pendingBaskets.length;
      counting.pending = pending;
    }
    if (!basketInfoUpdate.criterias?.status || basketInfoUpdate.criterias?.status?.includes("validation")) {
      const validatingBaskets = basketInfoUpdate.result.filter(FILTER_VALIDATING);
      const toValidateBaskets = basketInfoUpdate.result.filter(FILTER_TOVALIDATE);
      const validating = validatingBaskets.length;
      const toValidate = toValidateBaskets.length;

      counting.toValidate = toValidate;
      counting.validating = validating;
    }
    if (!basketInfoUpdate.criterias?.status || basketInfoUpdate.criterias?.status?.includes("opened")) {
      counting.inProgress = basketInfoUpdate.result.filter(FILTER_OPEN).length;
    }

    this.counting$.next(Object.assign(this.counting$.value, counting));
  }

  private forceUpdateCounting<T>(intialLoading = false, returnValue?: T): Observable<T> {
    this.clearCache();
    return this.getBasketInfos(
      true,
      {
        status: NEEDED_STATUS_FOR_COUNTING,
        userId: intialLoading ? this.userService.user.value._id : undefined,
        dates: [],
      },
      false,
    ).pipe(map(() => returnValue));
  }

  validate(basketId: string, additionalData: AdditionalData, showLoader: boolean = true): Observable<Basket> {
    const endpoint: string = `${environment.api}/baskets/${basketId}`;
    const { labels, comment, billingId, modifyWithoutValidation, oldItemId } = additionalData;
    return this.httpClient.patch<Basket>(
      endpoint,
      {
        labels,
        comment,
        billingId,
        modifyWithoutValidation,
        oldItemId,
      },
      {
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, !showLoader),
      },
    );
  }

  toPending(basketId: string, showLoader: boolean = true): Observable<any> {
    const endpoint: string = `${environment.api}/baskets/${basketId}/pending`;
    return this.httpClient
      .patch(endpoint, {
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, !showLoader),
      })
      .pipe(
        mergeMap((returnValue) => this.forceUpdateCounting(false, returnValue)),
        tap(() => this.actionOnBasket$.next({ action: "toPending", basketId })),
      );
  }

  validateFolders(basketId: string, folderIds: string[], lang: string, showLoader: boolean = true) {
    return forkJoin(folderIds.map((folderId) => this.book(basketId, folderId, lang))).pipe(
      mergeMap((returnValue) => this.forceUpdateCounting(false, returnValue)),
      tap(() => this.actionOnBasket$.next({ action: "validateFolders", basketId })),
    );
  }

  /**
   * @param folderId The uuid of the folder to book
   */
  book(basketId: string, folderId: string, lang: string): Observable<FolderResult> {
    const endpoint: string = `${environment.api}/baskets/${basketId}/folders/${folderId}`;

    /* FIXME: ATTENTION: Erreur de conception backend : le booking se fait sur un GET ! */
    return this.httpClient
      .get<FolderResult>(endpoint, {
        headers: { ignoreErrorMessage: "true", "Accept-Language": lang },
      })
      .pipe(
        catchError((error: any) => {
          return of(error);
        }),
        tap(() => this.clearCache()),
      );
  }

  removeFolder(basketId: string, folderId: string): Observable<any> {
    const endpoint: string = `${environment.api}/baskets/${basketId}/folders/${folderId}`;
    return this.httpClient.delete(endpoint).pipe(
      mergeMap((res) => this.forceUpdateCounting(false, res)),
      tap(() => this.actionOnBasket$.next({ action: "removeFolder", basketId })),
    );
  }

  deleteItemFromFolder(folderId: string, itemId: string): Observable<any> {
    const endpoint: string = `${environment.api}/folders/${folderId}/${itemId}`;
    return this.httpClient
      .delete(endpoint, {
        headers: { ignoreErrorMessage: "true" },
      })
      .pipe(
        tap(() => this.clearCache()),
        tap(() => this.actionOnBasket$.next({ action: "deleteItemFromFolder", folderId, itemId })),
      );
  }

  deleteFolder(basketId: string, folderId: string): Observable<any> {
    const endpoint: string = `${environment.api}/baskets/${basketId}/folders/${folderId}`;

    return this.httpClient
      .delete(endpoint, {
        headers: { ignoreErrorMessage: "true" },
      })
      .pipe(tap(() => this.clearCache()));
  }

  getAverageBookTime(type?: "hotel" | "train" | "car" | "flight"): Observable<any> {
    let endpoint: string = `${environment.api}/baskets/book-average-time`;
    if (type) {
      endpoint += `/${type}`;
    }

    const cacheValue: Observable<any> = this.httpAverageBookTime[endpoint];
    if (cacheValue) {
      return cacheValue;
    }

    return (this.httpAverageBookTime[endpoint] = this.httpClient.get(endpoint, {
      headers: { ignoreErrorMessage: "true" },
    }));
  }

  editTitle(basketId: string, title: string): Observable<any> {
    const endpoint: string = `${environment.api}/baskets/${basketId}/title`;
    return this.httpClient.patch(endpoint, { title }).pipe(tap(() => this.clearCache()));
  }

  activate(basketId: string, showLoader: boolean = true) {
    const endpoint: string = `${environment.api}/baskets/${basketId}/activate`;
    return this.httpClient
      .get<Basket>(endpoint, {
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, !showLoader),
      })
      .pipe(
        map((basket: Basket) => {
          this.baskets$.next([...this.baskets$.value, basket]);
          return basket;
        }),
        mergeMap((returnValue) => this.forceUpdateCounting(false, returnValue)),
        tap(() => this.actionOnBasket$.next({ action: "activate", basketId })),
      );
  }

  public getBasketInfoByBasketId(
    basketId: string,
    showLoader: boolean = true,
    clearCache: boolean = false,
  ): Observable<BasketInfo> {
    if (!basketId) {
      return EMPTY;
    }
    if (clearCache) {
      this.clearCache();
    }
    const cacheValue: Observable<BasketInfo> = this.httpCacheBasketInfo[basketId];
    if (cacheValue) {
      return cacheValue;
    }
    const result = this.get(basketId, showLoader).pipe(
      map((basketResult) => {
        return this.buildBasketInfo_old_toRemove(
          basketResult.basket,
          basketResult.folders,
          basketResult.items,
          basketResult.basket.authorizationsStory?.validators?.map((v) => v.username) || [],
          basketResult.basket.authorizationsStory?.can,
        );
      }),
    );
    return (this.httpCacheBasketInfo[basketId] = result);
  }

  /**
   * @deprecated Use `buildBasketInfo` instead.
   */
  private buildBasketInfo_old_toRemove(
    basket: Basket,
    folders: Folder[],
    itemsByFolderId: { [folderId: string]: BasketItem[] },
    validatorUsernames: string[],
    canValidateBasketResult: CanValidateBasketResult,
  ): BasketInfo {
    const items = Object.values(itemsByFolderId)
      .flat()
      .filter((item) => !!item);
    const totalPrice = calculatePrice(items);
    const hasItemWithError = items.some((item) => item.status === "error");
    const hasItemWithErrorDisponibility = folders
      .flatMap((folder) => folder.statusHistory)
      .some((h) => h.reason === "Automatically closed for unavailability");
    const expired =
      basket.statusHistory.find((h) => h.reason === "Automatically closed by reaching limit date") && !hasItemWithError;
    const validatedBy = basket.statusHistory.find((h) => h.to === "closed");

    // Pour le Basket, détermination des noms de voyageurs normalisés pour la recherche
    const travelersNormalizedName = items
      .flatMap((item) => (item as any).travelers)
      .flatMap((traveler) => [
        normalizeStringForSearch(traveler.firstname),
        normalizeStringForSearch(traveler.lastname),
      ])
      .filter((s) => s.length > 0);
    const titleNormalizedText = normalizeStringForSearch(basket.title);
    const displayedStatus = getBasketDisplayedStatus(
      basket,
      folders,
      hasItemWithError,
      hasItemWithErrorDisponibility,
      expired,
      canValidateBasketResult,
    );
    const travelTypeByFolderId = Object.fromEntries(
      folders.map((folder) => [folder.id, this.getTravelTypeForFolder(itemsByFolderId[folder.id])]),
    );
    const validatedByUserId = validatedBy?.userId;

    let menu = getMenu(basket, displayedStatus, totalPrice, expired);
    return {
      basket,
      folders,
      itemsByFolderId,
      travelTypeByFolderId,
      expired,
      displayedStatus,
      hasItemWithError,
      hasItemWithErrorDisponibility,
      totalPrice,
      menu,
      canValidateBasketResult,
      titleNormalizedText,
      travelersNormalizedName,
      validatorUsernames,
      validatedByUsername: validatedBy?.username,
      validatedByUserId,
    };
  }

  private buildBasketInfo(
    basket: Basket,
    sourceData: BasketsWithAllDataResult,
    validatorUsernames: string[],
    canValidateBasketResult: CanValidateBasketResult,
  ): BasketInfo {
    const folders = basket.folderIds.map((folderId) => sourceData.folders[folderId]);
    const itemsByFolderId = Object.fromEntries(
      folders.map((f) => [f.id, f.itemIds.map((itemId) => sourceData.items[itemId])]),
    );
    const items = Object.values(itemsByFolderId)
      .flat()
      .filter((item) => !!item);
    const totalPrice = calculatePrice(items);
    const hasItemWithError = items.some((item) => item.status === "error");
    const hasItemWithErrorDisponibility = folders
      .flatMap((folder) => folder.statusHistory)
      .some((h) => h.reason === "Automatically closed for unavailability");
    const expired =
      basket.statusHistory.find((h) => h.reason === "Automatically closed by reaching limit date") && !hasItemWithError;
    const validatedBy = basket.statusHistory.find((h) => h.to === "closed");

    // Pour le Basket, détermination des noms de voyageurs normalisés pour la recherche
    const travelersNormalizedName = items
      .flatMap((item) => (item as any).travelers)
      .flatMap((traveler) => [
        normalizeStringForSearch(traveler.firstname),
        normalizeStringForSearch(traveler.lastname),
      ])
      .filter((s) => s.length > 0);
    const titleNormalizedText = normalizeStringForSearch(basket.title);
    const displayedStatus = getBasketDisplayedStatus(
      basket,
      folders,
      hasItemWithError,
      hasItemWithErrorDisponibility,
      expired,
      canValidateBasketResult,
    );
    const travelTypeByFolderId = Object.fromEntries(
      folders.map((folder) => [folder.id, this.getTravelTypeForFolder(itemsByFolderId[folder.id])]),
    );
    const validatedByUserId = validatedBy?.userId;
    let menu = getMenu(basket, displayedStatus, totalPrice, expired);
    return {
      basket,
      folders,
      itemsByFolderId,
      travelTypeByFolderId,
      expired,
      displayedStatus,
      hasItemWithError,
      hasItemWithErrorDisponibility,
      totalPrice,
      menu,
      canValidateBasketResult,
      titleNormalizedText,
      travelersNormalizedName,
      validatorUsernames,
      validatedByUsername: validatedBy?.username,
      validatedByUserId,
    };
  }

  public getBasketInfos(
    withoutCache?: boolean,
    searchCriteria?: SearchCriteria,
    showLoader: boolean = true,
  ): Observable<BasketInfo[]> {
    return this.getBasketsWithAllData(withoutCache, searchCriteria, showLoader).pipe(
      mergeMap((sourceData) => from(Object.values(sourceData.baskets)).pipe(map((basket) => ({ sourceData, basket })))),
      mergeMap((item) => {
        return zip(
          of(item.sourceData),
          of(item.basket),
          VALIDATE_BASKET_NEEDED_FOR_STATUS.includes(item.basket.status) &&
            item.basket.authorizationsStory?.validators?.length > 0
            ? of(item.basket.authorizationsStory?.validators.map((v) => { 
              if(item.basket.authorizationsStory.outOfPolicy && v?.bookOutOfPolicy) {
                return v.username
              } else if(!item.basket.authorizationsStory.outOfPolicy){
                return v.username
              }
              return null;
              }).filter((v:string) => !!v))
            : of(VALIDATORUSERNAMES_IGNORE_RESULT),
          VALIDATE_BASKET_NEEDED_FOR_STATUS.includes(item.basket.status) && item.basket.authorizationsStory?.can
            ? of(item.basket.authorizationsStory?.can as CanValidateBasketResult)
            : of(CANVALIDATEBASKETRESULT_IGNORE_RESULT),
        );
      }),
      map(([sourceData, basket, validatorUsernames, canValidateBasketResult]) =>
        this.buildBasketInfo(basket, sourceData, validatorUsernames, canValidateBasketResult),
      ),
      toArray(),
      tap((basketInfos) => {
        if (NEEDED_STATUS_FOR_COUNTING.every((neededStatus) => searchCriteria.status.includes(neededStatus))) {
          // Mise à jour des compteurs
          this.basketInfos$.next({ criterias: searchCriteria, result: basketInfos });
        }
        const targetStatusFilter = searchCriteria?.status || [];
        if (targetStatusFilter.length === 0 || targetStatusFilter.includes("opened")) {
          this.openedbasketInfo$.next(basketInfos.find((i) => i.basket.status === "opened" && !i.expired));
        }
      }),
    );
  }

  private getTravelTypeForFolder(itemsOfFolder: BasketItem[]): string | undefined {
    if (!itemsOfFolder || itemsOfFolder.length === 0) {
      return;
    }
    if (itemsOfFolder.length > 1) {
      return "multiseg";
    }
    let detail;
    if (itemsOfFolder[0].provider === "afkl") {
      detail = itemsOfFolder[0].detail;
      if (!detail.trips || detail.trips.length === 0) {
        return;
      }
      if (detail.trips.length > 1 && detail.trips.length <= 2) {
        return "return";
      }
      if (detail.trips.length > 2) {
        return "multidesti";
      }
    } else {
      detail = itemsOfFolder[0].detail;
      if (!detail?.trips || detail.trips.length === 0) {
        return;
      }
      if (detail.trips[0].legs.length > 1 && detail.trips[0].legs.length <= 2) {
        return "return";
      }
      if (detail.trips[0].legs.length > 2) {
        return "multidesti";
      }
    }
    return "single";
  }
}

export interface Counting {
  inProgress?: number;
  toValidate?: number;
  pending?: number;
  validating?: number;
}

/**
 * Convertit les propriétés de type "date" en "vrai date"
 */
function fixBasketDateProperties(basket: Basket): Basket {
  // Correction de la propriété createdAt si nécessaire
  if (typeof basket.createdAt.getTime !== "function") {
    basket.createdAt = new Date(Date.parse(basket.createdAt.toString()));
  }
  if (typeof basket.limitDate.getTime !== "function") {
    basket.limitDate = new Date(Date.parse(basket.limitDate.toString()));
  }
  return basket;
}

export const FILTER_OPEN: Predicate<BasketInfo> = (basketInfo) => basketInfo.menu === "opened";
export const FILTER_TOVALIDATE: Predicate<BasketInfo> = (basketInfo) => basketInfo.menu === "toValidate";
export const FILTER_PENDING: Predicate<BasketInfo> = (basketInfo) => basketInfo.menu === "pending";
export const FILTER_VALIDATING: Predicate<BasketInfo> = (basketInfo) => basketInfo.menu === "validation";
export const FILTER_HISTORY: Predicate<BasketInfo> = (basketInfo) => basketInfo.menu === "history";

function isExpired(basket: Basket): boolean {
  return basket.limitDate.getTime() < Date.now();
}

/**
 * Permet de déterminer dans quel menu doit se trouver le Basket
 */
function getMenu(
  basket: Basket,
  displayedStatus: string,
  totalPrice: UtilsTypes.Price,
  expired: boolean,
): "opened" | "validation" | "pending" | "history" | "toValidate" {
  if (displayedStatus === "deleted") {
    return "history";
  }
  if (totalPrice.amount === 0) {
    return "history";
  }
  let menu: "opened" | "validation" | "pending" | "history" | "toValidate" = "history";
  switch (basket.status) {
    case "opened":
      menu = expired ? "history" : "opened";
      break;
    case "pending":
      if (!expired) {
        menu = "pending";
      } else {
        menu = "history";
      }
      break;
    case "validation":
      if (displayedStatus === "toValidate") {
        menu = "toValidate";
      } else if (displayedStatus === "validation") {
        menu = "validation";
      } else {
        menu = "validation";
      }
      break;
    default:
      menu = "history";
      break;
  }
  return menu;
}

/**
 * Détermination du status affichable du Basket.
 * Celui-ci doit prendre en compte son état éventuellement expiré, et les erreurs de ses items
 */
function getBasketDisplayedStatus(
  basket: Basket,
  folders: Folder[],
  hasError: boolean,
  hasErrorDisponibility: boolean,
  expired: boolean,
  canValidateBasketResult: CanValidateBasketResult,
): string {
  if (expired) {
    return "expired";
  }
  if (hasErrorDisponibility) {
    return "errorDisponibility";
  }
  if (hasError) {
    return "error";
  }

  if (basket.status === "validation") {
    if (canValidateBasketResult.isAuthorized === true) {
      return "toValidate";
    } else {
      return "validation";
    }
  }

  /*
    Algorithme :
    =============================
    if(basket.status === closed){
      if(pas de `basket.statusHistory.to` à 'validation' && pas de `folder.statusHistory.to` à 'booking') {
        if(il existe un `basket.statusHistory` (`to` === `closed`) n'est pas en reason "Automatically closed by reaching limit date") {
          return « deleted »
        }
      } else if(Folder cancelled) {
        var userId = userId du statusHistory.to closed
        if(userId) {
          if(userId !== basket.userId) {
            return « refused »
          }
        }
      }
    }
  */

  if (basket.status === "closed") {
    if (
      basket.statusHistory.every((statusHistory) => statusHistory.to !== "validation") &&
      folders.every((folder) =>
        folder.statusHistory.every((statusHistoryFolder) => statusHistoryFolder.to !== "booking"),
      )
    ) {
      if (
        basket.statusHistory
          .filter((s) => s.to === "closed")
          .every((s) => s.reason !== "Automatically closed by reaching limit date")
      ) {
        return "deleted";
      }
    } else if (folders.some((folder) => folder.status === "cancelled")) {
      var userId = basket.statusHistory.find((statusHistory) => statusHistory.to === "closed")?.userId;
      if (userId) {
        if (userId !== basket.userId) {
          return "refused";
        }
      }
    }
  }
  return basket.status;
}

function calculatePrice(_items: BasketItem[]): UtilsTypes.Price {
  return {
    amount: _items.reduce((acc: number, item: BasketItem) => {
      if (["error", "cancelled"].includes(item.status) === false) {
        return acc + item.price.amount;
      } else {
        return acc;
      }
    }, 0) as number,
    currency: "EUR", // TODO improve
  };
}

interface ValidatorServiceResult {
  basket: Basket;
  managers: MemberSociety[];
}
interface BasketsWithAllDataResult {
  baskets: { [basketId: string]: Basket };
  folders: { [folderId: string]: Folder };
  items: { [itemId: string]: BasketItem };
}
export interface SearchCriteria {
  status?: BasketStatusEnum[];
  limit?: number;
  skip?: number;
  authorizationType?: boolean;
  userId?: string;
  dates?: Date[];
  filterText?: string;
  validating?: boolean;
}
interface BasketInfoUpdate {
  criterias?: SearchCriteria;
  result: BasketInfo[];
}
interface BasketAction {
  action?: string;
  basketId?: string;
  folderId?: string;
  itemId?: string;
}
