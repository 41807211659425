<div class="main grid grid-nogutter" [class.p-dir-col-rev]="(commonService.isTabletObservable | async) === true">
  <div class="col products">
    <p class="p-15 txt-32 f-sb m-b-25">
      {{ "SEARCH.RESULT.TRANSFER." + productType | uppercase | translate }}
    </p>
    <ng-container *ngIf="searchTransfer && searchTransfer[productType].products.length; else noTransfer">
      <div *ngFor="let product of searchTransfer[productType].products">
        <div
          class="col grid justify-content-between grid-nogutter align-items-center product pointer"
          *ngIf="seeOutOfPolicy || (!seeOutOfPolicy && !product.general.isOutOfPolicy)"
          (mousedown)="select(product)"
        >
          <div class="logo col-4 m-10">
            <img
              [alt]="product.general.producttype"
              class="logo-img"
              [src]="vehicleTypes[product.general.producttypeid].imageurl"
            />
          </div>
          <p class="f-sb col grid align-items-center justify-content-between m-b-10 m-t-10">
            <span class="product-type col-8">
              <span class="txt-22 f-sb">{{ product.general.producttype }}</span
              ><br />
              <span class="product-pax txt-14">
                <i class="fas fa-users"></i>
                {{
                  product.general.minpax
                    ? product.general.minpax + "-" + product.general.maxpax
                    : search.data.userIds.length
                }}
              </span>
              <span class="product-luggage txt-14 m-l-10">
                <i class="fas fa-luggage-cart"></i>
                {{ product.general.luggage ? product.general.luggage : search.data.userIds.length }}
              </span>
              <span class="product-transfertime txt-14 m-l-10">
                <i class="fas fa-clock"></i>
                ~{{ product.general.transfertime }}min
              </span>
            </span>
            <span class="carbon-offset-container col-4 justify-content-end">
              <i class="spt-icon-carbon-offset"></i>
              <b class="m-l-5 carbon-offset-amount" *ngIf="product.general.carbonOffset; else estimateCarbonOffset">
                {{ product.general.carbonOffset.amount | kiloFormatter }}
              </b>
              <ng-template #estimateCarbonOffset>
                <b class="m-l-5 carbon-offset-amount" translate> CARBON_OFFSET.ESTIMATION </b>
              </ng-template>
            </span>
            <span class="product-price-container">
              <span class="product-price col-6 txt-36 f-sb">
                {{ product.pricing | SPTPrice | async }}
              </span>
              <span class="out-politic col justify-content-end" *ngIf="product.general.isOutOfPolicy" translate>
                GLOBAL.OUT_OF_POLICY
              </span>
            </span>
          </p>
        </div>
      </div>
    </ng-container>
    <ng-template #noTransfer>
      <div class="error-container">
        <p class="text-center">
          <img class="error-img" src="./assets/svg/no-results.svg" alt="No results" />
        </p>
        <p class="error-message" translate>SEARCH.RESULT.NO_TRANSFERS</p>
        <p-button
          class="error-button"
          [label]="'SEARCH.RESULT.AIR.UPDATE_SEARCH' | translate"
          styleClass="p-button-secondary"
          (onClick)="changeSearch()"
        ></p-button>
      </div>
    </ng-template>
  </div>

  <div class="map-container col">
    <div #map class="map"></div>
  </div>
</div>
