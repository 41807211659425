<form
  (submit)="search()"
  [style.grid-template-areas]="gridTemplateAreas"
  [style.grid-template-rows]="gridTemplateRows"
  class="form"
  [ngClass]="'suggest-' + suggestPanelService.active.name"
  [class.suggestOpened]="suggestOpened"
>
  <div class="title">
    <div class="flex justify-content-content items-center">
      <img class="icon-travel" src="./assets/svg/{{ searchEngineType }}.svg" />
      <h1 class="label" ngClass="transfer" [innerHTML]="'DASHBOARD.TRANSFER.TITLE' | translate"></h1>
    </div>
  </div>
  <ng-container *ngFor="let travel of searchCriteria.travels; let idx = index">
    <div class="trip" [style.grid-area]="'trip-' + idx">
      <div class="trip-title" [innerHTML]="'SEARCH.TRANSFER.NUMBER_TRIP' | translate: { number: idx + 1 }"></div>
      <div class="remove" [style.grid-area]="'remove-' + idx" *ngIf="idx > 0">
        <a (click)="removeTrip(idx)" translate>SEARCH.TRANSFER.DELETE_TRIP</a>
      </div>
    </div>
    <spt-travel-search-autocomplete
      [placeholder]="{
        origin: 'SEARCH.TRANSFER.ADDRESS.PICKUP' | translate,
        destination: 'SEARCH.TRANSFER.ADDRESS.DROPOFF' | translate
      }"
      [reverse]="true"
      [searchService]="searchService"
      [travel]="travel"
      (onCloseSuggest)="resetSuggestPanel()"
      (onTravelChange)="checkValidity()"
      [disableFavorites]="true"
      [originGridArea]="'origin-' + idx"
      [originSuggestPanelName]="'origin-' + idx"
      [destinationGridArea]="'destination-' + idx"
      [destinationSuggestPanelName]="'destination-' + idx"
      [nextFocus]="searchDatetimeComponent"
    ></spt-travel-search-autocomplete>
    <div
      class="suggest-panel transfer-default"
      *ngIf="suggestPanelService.active.name === 'transfer-welcome' && !commonService.isTablet"
    >
      <div>
        <div class="title">
          {{ "SEARCH.TRANSFER.DEFAULT_TEXT_TITLE" | translate }}
        </div>
        <p class="subtitle">{{ "SEARCH.TRANSFER.DATETIME_SUBTITLE" | translate }}</p>

        <div class="content" [innerHTML]="'SEARCH.TRANSFER.DEFAULT_TEXT' | translate"></div>
        <div class="bg"></div>
      </div>
    </div>
    <spt-search-datetime
      #searchDatetimeComponent
      [i18n]="{
        outwardLabelDate: 'SEARCH.TRANSFER.LABEL_OUTWARD_DATE' | translate,
        outwardDatePlaceholder: 'GLOBAL.DATE_MANDATORY' | translate,
        inwardLabelDate: 'SEARCH.TRANSFER.LABEL_INWARD_DATE' | translate,
        inwardDatePlaceholder: 'GLOBAL.DATE_MANDATORY' | translate,
        outwardLabelTime: 'SEARCH.TRANSFER.HOUR_MANDATORY' | translate,
        oneWaySuggestPanelTitle: 'SEARCH.TRANSFER.DATETIME_TITLE' | translate,
        oneWaySuggestPanelSubTitle: 'SEARCH.TRANSFER.DATETIME_SUBTITLE' | translate
      }"
      [maxDateCount]="1"
      [yearRange]="yearRange"
      [time]="true"
      [hourType]="false"
      calendarSelectionMode="single"
      [sourceWhenCriteria]="travel.when"
      (onActivateSuggest)="activateSuggest($event)"
      (onCloseSuggest)="resetSuggestPanel()"
      (onDatetimeChange)="checkValidity()"
      [gridArea]="'datetime-' + idx"
      [suggestPanelName]="'datetime-' + idx"
      [searchEngineType]="searchEngineType"
    ></spt-search-datetime>
  </ng-container>
  <div class="addTrip">
    <p-button
      (click)="addTravel()"
      [label]="'SEARCH.TRANSFER.ADD_TRIP' | translate"
      type="button"
      styleClass="p-button-quaternary medium"
    ></p-button>
  </div>
  <div class="options">
    <div class="search-form-item details">
      <div class="p-inputgroup number">
        <img src="./assets/svg/add-person.svg" class="p-inputgroup-addon" />
        <input
          name="transfer-personsNumber"
          [(ngModel)]="searchCriteria.options.personsNumber"
          (ngModelChange)="checkValidity()"
          pInputText
          type="number"
          min="1"
          [placeholder]="'SEARCH.TRANSFER.PERSON' | translate"
        />
      </div>
      <div class="p-inputgroup reference">
        <input
          name="transfer-reference"
          [(ngModel)]="searchCriteria.options.reference"
          (ngModelChange)="checkValidity()"
          pInputText
          type="text"
          [placeholder]="'SEARCH.TRANSFER.REFERENCE' | translate"
          min="0"
        />
      </div>
    </div>
    <div class="search-form-item contact">
      <div class="name">
        <!--input
          pInputText
          formControlName="name"
          [placeholder]="'ACCOUNT.PASSPORT.LASTNAME' | translate"
        /-->
        <input
          name="transfer-name"
          [(ngModel)]="searchCriteria.options.name"
          class="p-inputgroup"
          pInputText
          [placeholder]="'SEARCH.TRANSFER.NAME' | translate"
        />
      </div>
      <div class="p-inputgroup phone">
        <img src="./assets/svg/phone.svg" class="p-inputgroup-addon" />
        <input
          name="transfer-phone"
          [(ngModel)]="searchCriteria.options.phone"
          (ngModelChange)="checkValidity()"
          pInputText
          type="tel"
          [placeholder]="'SEARCH.TRANSFER.PHONE' | translate"
        />
      </div>
    </div>
    <div class="type">
      <p-dropdown
        name="transfer-kind"
        [(ngModel)]="searchCriteria.options.kind"
        (onChange)="checkValidity()"
        class="search-form-item"
        styleClass="search-dropdown p-inputgroup"
        [placeholder]="'SEARCH.TRANSFER.TYPE.LABEL' | translate"
        [options]="transferKindOptions"
      ></p-dropdown>
    </div>
    <div class="search-form-item">
      <!--textarea
        pInputTextarea
        formControlName="comment"
        [placeholder]="'SEARCH.SEMINAR.COMMENT' | translate"
      ></textarea-->
      <textarea
        name="transfer-comment"
        [(ngModel)]="searchCriteria.options.comment"
        (ngModelChange)="checkValidity()"
        pInputTextarea
        [placeholder]="'SEARCH.TRANSFER.COMMENT' | translate"
      ></textarea>
    </div>
  </div>
  <div class="triggers submit">
    <p-button type="submit" [disabled]="!isValid" label="{{ 'SEARCH.TRANSFER.CTA' | translate }}"></p-button>
  </div>
</form>
