export default {
  U2: {
    iata: "U2",
    name: "easyjet",
  },
  "9B": {
    iata: "9B",
    name: "Accesrail",
  },
  "9F": {
    iata: "9F",
    name: "Han Air",
  },
  "1T": {
    iata: "1T",
    name: "Bulgarian Air Charter",
  },
  "8R": {
    iata: "8R",
    name: "Amelia",
  },
  Q5: {
    iata: "Q5",
    name: "40-Mile Air",
  },
  "4O": {
    iata: "4O",
    name: "Interjet",
  },
  "7A": {
    iata: "7A",
    name: "Express Air Cargo",
  },
  JY: {
    iata: "JY",
    name: "Air Turks and Caicos",
  },
  JU: {
    iata: "JU",
    name: "Air Serbia",
  },
  QH: {
    iata: "QH",
    name: "Kyrgyzstan",
  },
  A8: {
    iata: "A8",
    name: "Benin Golf Air",
  },
  RV: {
    iata: "RV",
    name: "Caspian Airlines",
  },
  "1B": {
    iata: "1B",
    name: "Abacus International",
  },
  W9: {
    iata: "W9",
    name: "Eastwind Airlines",
  },
  "6U": {
    iata: "6U",
    name: "Air Ukraine",
  },
  E4: {
    iata: "E4",
    name: "Aero Asia International",
  },
  ZI: {
    iata: "ZI",
    name: "Aigle Azur",
  },
  AE: {
    iata: "AE",
    name: "Mandarin Airlines",
  },
  OZ: {
    iata: "OZ",
    name: "Asiana Airlines",
  },
  "8U": {
    iata: "8U",
    name: "Afriqiyah Airways",
  },
  Q9: {
    iata: "Q9",
    name: "Afrinat International Airlines",
  },
  KI: {
    iata: "KI",
    name: "Adam Air",
  },
  QB: {
    iata: "QB",
    name: "Georgian National Airlines",
  },
  LD: {
    iata: "LD",
    name: "Air Hong Kong",
  },
  UX: {
    iata: "UX",
    name: "Air Europa",
  },
  NX: {
    iata: "NX",
    name: "Air Macau",
  },
  ZV: {
    iata: "ZV",
    name: "Air Midwest",
  },
  HM: {
    iata: "HM",
    name: "Air Seychelles",
  },
  AF: {
    iata: "AF",
    name: "Air France",
  },
  SB: {
    iata: "SB",
    name: "Air Caledonie International",
  },
  EH: {
    iata: "EH",
    name: "SAETA",
  },
  ZW: {
    iata: "ZW",
    name: "Air Wisconsin",
  },
  GN: {
    iata: "GN",
    name: "Air Gabon",
  },
  NQ: {
    iata: "NQ",
    name: "Air Japan",
  },
  VD: {
    iata: "VD",
    name: "SwedJet Airways",
  },
  TT: {
    iata: "TT",
    name: "Tigerair Australia",
  },
  "4N": {
    iata: "4N",
    name: "Air North Charter - Canada",
  },
  NZ: {
    iata: "NZ",
    name: "Air New Zealand",
  },
  QM: {
    iata: "QM",
    name: "Air Malawi",
  },
  ML: {
    iata: "ML",
    name: "Midway Airlines (1976–1991)",
  },
  P8: {
    iata: "P8",
    name: "Pantanal Linhas Aéreas",
  },
  BM: {
    iata: "BM",
    name: "BMI Regional",
  },
  ZX: {
    iata: "ZX",
    name: "Air Georgian",
  },
  G8: {
    iata: "G8",
    name: "Gujarat Airways",
  },
  "7T": {
    iata: "7T",
    name: "Tobruk Air",
  },
  "6V": {
    iata: "6V",
    name: "Mars RK",
  },
  NH: {
    iata: "NH",
    name: "All Nippon Airways",
  },
  TZ: {
    iata: "TZ",
    name: "ATA Airlines",
  },
  "2Q": {
    iata: "2Q",
    name: "Air Cargo Carriers",
  },
  V7: {
    iata: "V7",
    name: "Volotea",
  },
  AB: {
    iata: "AB",
    name: "Air Berlin",
  },
  "4D": {
    iata: "4D",
    name: "Air Sinai",
  },
  QN: {
    iata: "QN",
    name: "Air Armenia",
  },
  AI: {
    iata: "AI",
    name: "Air India Limited",
  },
  PJ: {
    iata: "PJ",
    name: "Air Saint Pierre",
  },
  SZ: {
    iata: "SZ",
    name: "Air Southwest",
  },
  "8C": {
    iata: "8C",
    name: "Shanxi Airlines",
  },
  NF: {
    iata: "NF",
    name: "Air Vanuatu",
  },
  ZB: {
    iata: "ZB",
    name: "Monarch Airlines",
  },
  CC: {
    iata: "CC",
    name: "Macair Airlines",
  },
  RB: {
    iata: "RB",
    name: "Syrian Arab Airlines",
  },
  TN: {
    iata: "TN",
    name: "Air Tahiti Nui",
  },
  SW: {
    iata: "SW",
    name: "Air Namibia",
  },
  AW: {
    iata: "AW",
    name: "Dirgantara Air Service",
  },
  PE: {
    iata: "PE",
    name: "People's Viennaline",
  },
  JM: {
    iata: "JM",
    name: "Jetstar Hong Kong Airways",
  },
  "6G": {
    iata: "6G",
    name: "Air Wales",
  },
  TX: {
    iata: "TX",
    name: "Air Caraibes",
  },
  IX: {
    iata: "IX",
    name: "Air India Express",
  },
  BT: {
    iata: "BT",
    name: "Air Baltic",
  },
  EL: {
    iata: "EL",
    name: "Ellinair",
  },
  YW: {
    iata: "YW",
    name: "Air Nostrum",
  },
  PX: {
    iata: "PX",
    name: "Air Niugini",
  },
  G9: {
    iata: "G9",
    name: "Air Arabia",
  },
  AC: {
    iata: "AC",
    name: "Air Canada",
  },
  AP: {
    iata: "AP",
    name: "Air One",
  },
  XT: {
    iata: "XT",
    name: "SkyStar Airways",
  },
  UM: {
    iata: "UM",
    name: "Air Zimbabwe",
  },
  S2: {
    iata: "S2",
    name: "Air Sahara",
  },
  TC: {
    iata: "TC",
    name: "Air Tanzania",
  },
  "2J": {
    iata: "2J",
    name: "Air Burkina",
  },
  KM: {
    iata: "KM",
    name: "Air Malta",
  },
  YT: {
    iata: "YT",
    name: "Air Togo",
  },
  G4: {
    iata: "G4",
    name: "Allegiant Air",
  },
  M3: {
    iata: "M3",
    name: "North Flying",
  },
  O4: {
    iata: "O4",
    name: "Antrak Air",
  },
  GB: {
    iata: "GB",
    name: "ABX Air",
  },
  "8V": {
    iata: "8V",
    name: "Wright Air Service",
  },
  "8T": {
    iata: "8T",
    name: "Air Tindi",
  },
  JP: {
    iata: "JP",
    name: "Adria Airways",
  },
  A3: {
    iata: "A3",
    name: "Aegean Airlines",
  },
  "2K": {
    iata: "2K",
    name: "Aerogal",
  },
  KD: {
    iata: "KD",
    name: "KD Avia",
  },
  KO: {
    iata: "KO",
    name: "Alaska Central Express",
  },
  VX: {
    iata: "VX",
    name: "Virgin America",
  },
  KH: {
    iata: "KH",
    name: "Aloha Air Cargo",
  },
  AA: {
    iata: "AA",
    name: "American Airlines",
  },
  AX: {
    iata: "AX",
    name: "Trans States Airlines",
  },
  AN: {
    iata: "AN",
    name: "Ansett Australia",
  },
  "5W": {
    iata: "5W",
    name: "Astraeus",
  },
  VV: {
    iata: "VV",
    name: "Aerosvit Airlines",
  },
  WK: {
    iata: "WK",
    name: "Edelweiss Air",
  },
  QQ: {
    iata: "QQ",
    name: "Reno Air",
  },
  FG: {
    iata: "FG",
    name: "Ariana Afghan Airlines",
  },
  Y2: {
    iata: "Y2",
    name: "Flyglobespan",
  },
  SU: {
    iata: "SU",
    name: "Aeroflot Russian Airlines",
  },
  "5Z": {
    iata: "5Z",
    name: "VivaColombia",
  },
  "5D": {
    iata: "5D",
    name: "DonbassAero",
  },
  "1A": {
    iata: "1A",
    name: "Amadeus IT Group",
  },
  JJ: {
    iata: "JJ",
    name: "LATAM Brasil",
  },
  PL: {
    iata: "PL",
    name: "Airstars",
  },
  "8A": {
    iata: "8A",
    name: "Atlas Blue",
  },
  GD: {
    iata: "GD",
    name: "Air Alpha Greenland",
  },
  HT: {
    iata: "HT",
    name: "Aeromist-Kharkiv",
  },
  J2: {
    iata: "J2",
    name: "Azerbaijan Airlines",
  },
  U3: {
    iata: "U3",
    name: "Avies",
  },
  "4Y": {
    iata: "4Y",
    name: "Yute Air Alaska",
  },
  "5A": {
    iata: "5A",
    name: "Alpine Air Express",
  },
  W4: {
    iata: "W4",
    name: "Aero Services Executive",
  },
  IZ: {
    iata: "IZ",
    name: "Arkia Israel Airlines",
  },
  M6: {
    iata: "M6",
    name: "Amerijet International",
  },
  "4A": {
    iata: "4A",
    name: "Air Kiribati",
  },
  EV: {
    iata: "EV",
    name: "ExpressJet",
  },
  HP: {
    iata: "HP",
    name: "Phoenix Airways",
  },
  VH: {
    iata: "VH",
    name: "Aeropostal Alas de Venezuela",
  },
  AM: {
    iata: "AM",
    name: "Aeroméxico",
  },
  TL: {
    iata: "TL",
    name: "Trans Mediterranean Airlines",
  },
  OY: {
    iata: "OY",
    name: "Omni Air International",
  },
  IW: {
    iata: "IW",
    name: "Wings Air",
  },
  J6: {
    iata: "J6",
    name: "AVCOM",
  },
  "2D": {
    iata: "2D",
    name: "Aero VIP",
  },
  VB: {
    iata: "VB",
    name: "VIVA Aerobus",
  },
  OE: {
    iata: "OE",
    name: "Asia Overnight Express",
  },
  GV: {
    iata: "GV",
    name: "Aero Flight",
  },
  JW: {
    iata: "JW",
    name: "Vanilla Air",
  },
  "2B": {
    iata: "2B",
    name: "Aerocondor",
  },
  "4C": {
    iata: "4C",
    name: "Aires, Aerovías de Integración Regional, S.A.",
  },
  AR: {
    iata: "AR",
    name: "Aerolíneas Argentinas",
  },
  AS: {
    iata: "AS",
    name: "Alaska Airlines, Inc.",
  },
  OB: {
    iata: "OB",
    name: "Oasis International Airlines",
  },
  HC: {
    iata: "HC",
    name: "Air Sénégal",
  },
  FO: {
    iata: "FO",
    name: "Airlines of Tasmania",
  },
  OS: {
    iata: "OS",
    name: "Austrian Airlines",
  },
  IQ: {
    iata: "IQ",
    name: "Augsburg Airways",
  },
  RU: {
    iata: "RU",
    name: "SkyKing Turks and Caicos Airways",
  },
  MO: {
    iata: "MO",
    name: "Calm Air",
  },
  GR: {
    iata: "GR",
    name: "Gemini Air Cargo",
  },
  NO: {
    iata: "NO",
    name: "Neos",
  },
  AU: {
    iata: "AU",
    name: "Austral Líneas Aéreas",
  },
  AO: {
    iata: "AO",
    name: "Australian Airlines",
  },
  AV: {
    iata: "AV",
    name: "Avianca - Aerovías del Continente Americano S.A.",
  },
  A0: {
    iata: "A0",
    name: "Avianca Argentina",
  },
  O6: {
    iata: "O6",
    name: "Avianca Brazil",
  },
  K8: {
    iata: "K8",
    name: "Airlink Zambia",
  },
  B9: {
    iata: "B9",
    name: "Air Bangladesh",
  },
  HJ: {
    iata: "HJ",
    name: "Hellas Jet",
  },
  AK: {
    iata: "AK",
    name: "Air Bridge Carriers",
  },
  D7: {
    iata: "D7",
    name: "FlyAsianXpress",
  },
  DJ: {
    iata: "DJ",
    name: "Polynesian Blue",
  },
  I5: {
    iata: "I5",
    name: "AirAsia India",
  },
  EX: {
    iata: "EX",
    name: "Air Santo Domingo",
  },
  "3G": {
    iata: "3G",
    name: "Atlant-Soyuz Airlines",
  },
  AZ: {
    iata: "AZ",
    name: "Ita Airways",
  },
  ZE: {
    iata: "ZE",
    name: "Líneas Aéreas Azteca",
  },
  A2: {
    iata: "A2",
    name: "Cielos Airlines",
  },
  R7: {
    iata: "R7",
    name: "Aserca Airlines",
  },
  RX: {
    iata: "RX",
    name: "Aviaexpress",
  },
  MQ: {
    iata: "MQ",
    name: "American Eagle Airlines",
  },
  ZS: {
    iata: "ZS",
    name: "Sama Airlines",
  },
  FF: {
    iata: "FF",
    name: "Airshop",
  },
  VU: {
    iata: "VU",
    name: "Air Ivoire",
  },
  BP: {
    iata: "BP",
    name: "Air Botswana",
  },
  GS: {
    iata: "GS",
    name: "Tianjin Airlines",
  },
  VT: {
    iata: "VT",
    name: "Air Tahiti",
  },
  "3N": {
    iata: "3N",
    name: "Air Urga",
  },
  VL: {
    iata: "VL",
    name: "Air VIA",
  },
  FK: {
    iata: "FK",
    name: "Keewatin Air",
  },
  G2: {
    iata: "G2",
    name: "Avirex",
  },
  V8: {
    iata: "V8",
    name: "Iliamna Air Taxi",
  },
  K6: {
    iata: "K6",
    name: "Cambodia Angkor Air",
  },
  VE: {
    iata: "VE",
    name: "C.A.I. Second",
  },
  V5: {
    iata: "V5",
    name: "Royal Aruban Airlines",
  },
  CA: {
    iata: "CA",
    name: "Air China",
  },
  Q6: {
    iata: "Q6",
    name: "Aero Condor Peru",
  },
  "5F": {
    iata: "5F",
    name: "Fly One",
  },
  QC: {
    iata: "QC",
    name: "Air Corridor",
  },
  NV: {
    iata: "NV",
    name: "Air Central",
  },
  CV: {
    iata: "CV",
    name: "Cargolux",
  },
  CW: {
    iata: "CW",
    name: "Air Marshall Islands",
  },
  ZA: {
    iata: "ZA",
    name: "Interavia Airlines",
  },
  AH: {
    iata: "AH",
    name: "Air Algérie",
  },
  ER: {
    iata: "ER",
    name: "Astar Air Cargo",
  },
  HO: {
    iata: "HO",
    name: "Juneyao Airlines",
  },
  EN: {
    iata: "EN",
    name: "Air Dolomiti",
  },
  NM: {
    iata: "NM",
    name: "Mount Cook Airlines",
  },
  EE: {
    iata: "EE",
    name: "Aero Airlines",
  },
  "4F": {
    iata: "4F",
    name: "Air City",
  },
  EI: {
    iata: "EI",
    name: "Aer Lingus",
  },
  E8: {
    iata: "E8",
    name: "Alpi Eagles",
  },
  KY: {
    iata: "KY",
    name: "Air São Tomé and Príncipe",
  },
  PC: {
    iata: "PC",
    name: "Pegasus Airlines",
  },
  OF: {
    iata: "OF",
    name: "Transports et Travaux Aériens de Madagascar",
  },
  FJ: {
    iata: "FJ",
    name: "Fiji Airways",
  },
  RC: {
    iata: "RC",
    name: "Atlantic Airways",
  },
  NY: {
    iata: "NY",
    name: "Air Iceland",
  },
  "2P": {
    iata: "2P",
    name: "Air Philippines",
  },
  "2U": {
    iata: "2U",
    name: "Air Guinee Express",
  },
  "0A": {
    iata: "0A",
    name: "Amber Air",
  },
  DA: {
    iata: "DA",
    name: "Air Georgia",
  },
  GL: {
    iata: "GL",
    name: "Miami Air International",
  },
  LL: {
    iata: "LL",
    name: "Allegro",
  },
  "5Y": {
    iata: "5Y",
    name: "Atlas Air",
  },
  GG: {
    iata: "GG",
    name: "Cargo 360",
  },
  H9: {
    iata: "H9",
    name: "Izair",
  },
  HD: {
    iata: "HD",
    name: "AIRDO",
  },
  IP: {
    iata: "IP",
    name: "Atyrau Air Ways",
  },
  QK: {
    iata: "QK",
    name: "Air Canada Jazz",
  },
  KK: {
    iata: "KK",
    name: "Atlasjet",
  },
  JS: {
    iata: "JS",
    name: "Air Koryo",
  },
  KC: {
    iata: "KC",
    name: "Air Astana",
  },
  LV: {
    iata: "LV",
    name: "Albanian Airlines",
  },
  "3S": {
    iata: "3S",
    name: "Air Guyane Express",
  },
  D4: {
    iata: "D4",
    name: "Alidaunia",
  },
  "9I": {
    iata: "9I",
    name: "Thai Sky Airlines",
  },
  XL: {
    iata: "XL",
    name: "Aerolane",
  },
  A6: {
    iata: "A6",
    name: "Hongtu Airlines",
  },
  TD: {
    iata: "TD",
    name: "Tulip Air",
  },
  L8: {
    iata: "L8",
    name: "Air Luxor GB",
  },
  LK: {
    iata: "LK",
    name: "Air Luxor",
  },
  MK: {
    iata: "MK",
    name: "Air Mauritius",
  },
  MD: {
    iata: "MD",
    name: "Air Madagascar",
  },
  "9U": {
    iata: "9U",
    name: "Air Moldova",
  },
  L9: {
    iata: "L9",
    name: "Teamline Air",
  },
  A7: {
    iata: "A7",
    name: "Air Plus Comet",
  },
  QO: {
    iata: "QO",
    name: "Origin Pacific Airways",
  },
  MR: {
    iata: "MR",
    name: "Air Mauritanie",
  },
  F4: {
    iata: "F4",
    name: "Albarka Air",
  },
  AJ: {
    iata: "AJ",
    name: "Aero Contractors",
  },
  "8Y": {
    iata: "8Y",
    name: "China Postal Airlines",
  },
  OT: {
    iata: "OT",
    name: "Aeropelican Air Services",
  },
  AD: {
    iata: "AD",
    name: "Azul Linhas Aéreas Brasileiras",
  },
  QD: {
    iata: "QD",
    name: "Air Class Líneas Aéreas",
  },
  QS: {
    iata: "QS",
    name: "Travel Service",
  },
  AG: {
    iata: "AG",
    name: "Aruba Airlines",
  },
  MC: {
    iata: "MC",
    name: "Air Mobility Command",
  },
  RE: {
    iata: "RE",
    name: "Stobart Air",
  },
  UU: {
    iata: "UU",
    name: "Air Austral",
  },
  ZP: {
    iata: "ZP",
    name: "Silk Way Airlines",
  },
  "6K": {
    iata: "6K",
    name: "Asian Spirit",
  },
  A5: {
    iata: "A5",
    name: "Hop!",
  },
  QL: {
    iata: "QL",
    name: "Línea Aérea de Servicio Ejecutivo Regional",
  },
  R3: {
    iata: "R3",
    name: "Yakutia Airlines",
  },
  MV: {
    iata: "MV",
    name: "Armenian International Airways",
  },
  "2O": {
    iata: "2O",
    name: "Air Salone",
  },
  U8: {
    iata: "U8",
    name: "Armavia",
  },
  BQ: {
    iata: "BQ",
    name: "Baltia Air Lines",
  },
  P5: {
    iata: "P5",
    name: "AeroRepública",
  },
  BF: {
    iata: "BF",
    name: "French Bee",
  },
  "5L": {
    iata: "5L",
    name: "AeroSur",
  },
  JR: {
    iata: "JR",
    name: "Aero California",
  },
  Z3: {
    iata: "Z3",
    name: "Avient Aviation",
  },
  GM: {
    iata: "GM",
    name: "Air Slovakia",
  },
  VW: {
    iata: "VW",
    name: "Aeromar",
  },
  OR: {
    iata: "OR",
    name: "TUI Airlines Netherlands",
  },
  CG: {
    iata: "CG",
    name: "Airlines PNG",
  },
  TY: {
    iata: "TY",
    name: "Iberworld",
  },
  FL: {
    iata: "FL",
    name: "AirTran Airways",
  },
  TS: {
    iata: "TS",
    name: "Air Transat",
  },
  EC: {
    iata: "EC",
    name: "Avialeasing Aviation Company",
  },
  DW: {
    iata: "DW",
    name: "Aero-Charter Ukraine",
  },
  U7: {
    iata: "U7",
    name: "USA Jet Airlines",
  },
  "6R": {
    iata: "6R",
    name: "Alrosa Air Company",
  },
  "6A": {
    iata: "6A",
    name: "Aviacsa",
  },
  JZ: {
    iata: "JZ",
    name: "Skyways Express",
  },
  AQ: {
    iata: "AQ",
    name: "MAP-Management and Planung",
  },
  "3J": {
    iata: "3J",
    name: "Zip",
  },
  SM: {
    iata: "SM",
    name: "Swedline Express",
  },
  KJ: {
    iata: "KJ",
    name: "British Mediterranean Airways",
  },
  BX: {
    iata: "BX",
    name: "Coast Air",
  },
  YE: {
    iata: "YE",
    name: "Aryan Cargo Express",
  },
  VJ: {
    iata: "VJ",
    name: "Vietjet Air",
  },
  "3O": {
    iata: "3O",
    name: "Air Arabia Maroc",
  },
  X9: {
    iata: "X9",
    name: "Avion Express",
  },
  JD: {
    iata: "JD",
    name: "Beijing Capital Airlines",
  },
  ID: {
    iata: "ID",
    name: "Interlink Airlines",
  },
  BA: {
    iata: "BA",
    name: "British Airways",
  },
  BG: {
    iata: "BG",
    name: "Biman Bangladesh Airlines",
  },
  B4: {
    iata: "B4",
    name: "Bankair",
  },
  WX: {
    iata: "WX",
    name: "CityJet",
  },
  BZ: {
    iata: "BZ",
    name: "Keystone Air Service",
  },
  JA: {
    iata: "JA",
    name: "JetSMART",
  },
  J4: {
    iata: "J4",
    name: "Jordan International Air Cargo",
  },
  "8H": {
    iata: "8H",
    name: "BH Air",
  },
  "4T": {
    iata: "4T",
    name: "Belair Airlines",
  },
  UP: {
    iata: "UP",
    name: "Bahamasair",
  },
  E6: {
    iata: "E6",
    name: "Bringer Air Cargo Taxi Aéreo",
  },
  LZ: {
    iata: "LZ",
    name: "Balkan Bulgarian Airlines",
  },
  TH: {
    iata: "TH",
    name: "Transmile Air Services",
  },
  BS: {
    iata: "BS",
    name: "British International Helicopters",
  },
  PG: {
    iata: "PG",
    name: "Bangkok Airways",
  },
  KF: {
    iata: "KF",
    name: "Blue1",
  },
  JV: {
    iata: "JV",
    name: "Bearskin Lake Air Service",
  },
  B3: {
    iata: "B3",
    name: "Bellview Airlines",
  },
  BD: {
    iata: "BD",
    name: "BMI",
  },
  WW: {
    iata: "WW",
    name: "WOW air",
  },
  CH: {
    iata: "CH",
    name: "Bemidji Airlines",
  },
  BO: {
    iata: "BO",
    name: "Bouraq Indonesia Airlines",
  },
  BV: {
    iata: "BV",
    name: "Blue Panorama Airlines",
  },
  "7R": {
    iata: "7R",
    name: "BRA-Transportes Aéreos",
  },
  "8E": {
    iata: "8E",
    name: "Bering Air",
  },
  B2: {
    iata: "B2",
    name: "Belavia Belarusian Airlines",
  },
  BN: {
    iata: "BN",
    name: "Horizon Airlines",
  },
  GQ: {
    iata: "GQ",
    name: "Sky Express",
  },
  V9: {
    iata: "V9",
    name: "Star1 Airlines",
  },
  Y6: {
    iata: "Y6",
    name: "Batavia Air",
  },
  BU: {
    iata: "BU",
    name: "Buryat Airlines Aircompany",
  },
  J8: {
    iata: "J8",
    name: "Berjaya Air",
  },
  QW: {
    iata: "QW",
    name: "Blue Wings",
  },
  SN: {
    iata: "SN",
    name: "Brussels Airlines",
  },
  DB: {
    iata: "DB",
    name: "Brit Air",
  },
  E9: {
    iata: "E9",
    name: "Boston-Maine Airways",
  },
  NT: {
    iata: "NT",
    name: "Binter Canarias",
  },
  "0B": {
    iata: "0B",
    name: "Blue Air",
  },
  FB: {
    iata: "FB",
    name: "Bulgaria Air",
  },
  "8N": {
    iata: "8N",
    name: "Barents AirLink",
  },
  CJ: {
    iata: "CJ",
    name: "CityFlyer Express",
  },
  YB: {
    iata: "YB",
    name: "Borajet",
  },
  BW: {
    iata: "BW",
    name: "Caribbean Airlines",
  },
  "7N": {
    iata: "7N",
    name: "PAWA Dominicana",
  },
  "5C": {
    iata: "5C",
    name: "CAL Cargo Air Lines",
  },
  "3C": {
    iata: "3C",
    name: "RegionsAir",
  },
  R9: {
    iata: "R9",
    name: "Camai Air",
  },
  UY: {
    iata: "UY",
    name: "Cameroon Airlines",
  },
  C6: {
    iata: "C6",
    name: "CanJet",
  },
  CP: {
    iata: "CP",
    name: "Compass Airlines",
  },
  "5T": {
    iata: "5T",
    name: "Canadian North",
  },
  W2: {
    iata: "W2",
    name: "Canadian Western Airlines",
  },
  "9K": {
    iata: "9K",
    name: "Cape Air",
  },
  PT: {
    iata: "PT",
    name: "West Air Sweden",
  },
  "2G": {
    iata: "2G",
    name: "Cargoitalia",
  },
  W8: {
    iata: "W8",
    name: "Cargojet Airways",
  },
  C8: {
    iata: "C8",
    name: "Chicago Express Airlines",
  },
  "8B": {
    iata: "8B",
    name: "Caribbean Star Airlines",
  },
  V3: {
    iata: "V3",
    name: "Carpatair",
  },
  CX: {
    iata: "CX",
    name: "Cathay Pacific",
  },
  KX: {
    iata: "KX",
    name: "Cayman Airways",
  },
  "5J": {
    iata: "5J",
    name: "Cebu Pacific",
  },
  "3B": {
    iata: "3B",
    name: "Central Connect Airlines",
  },
  "9M": {
    iata: "9M",
    name: "Central Mountain Air",
  },
  J7: {
    iata: "J7",
    name: "ValuJet Airlines",
  },
  WE: {
    iata: "WE",
    name: "Thai Smile Airways",
  },
  OP: {
    iata: "OP",
    name: "Chalk's International Airlines",
  },
  MG: {
    iata: "MG",
    name: "Champion Air",
  },
  "2Z": {
    iata: "2Z",
    name: "Chang An Airlines",
  },
  S8: {
    iata: "S8",
    name: "Skywise Airline",
  },
  CI: {
    iata: "CI",
    name: "China Airlines",
  },
  CK: {
    iata: "CK",
    name: "China Cargo Airlines",
  },
  MU: {
    iata: "MU",
    name: "China Eastern Airlines",
  },
  G5: {
    iata: "G5",
    name: "China Express Airlines",
  },
  WH: {
    iata: "WH",
    name: "WebJet Linhas Aéreas",
  },
  CZ: {
    iata: "CZ",
    name: "China Southern Airlines",
  },
  KN: {
    iata: "KN",
    name: "China United Airlines",
  },
  XO: {
    iata: "XO",
    name: "LTE International Airways",
  },
  "3Q": {
    iata: "3Q",
    name: "China Yunnan Airlines",
  },
  X7: {
    iata: "X7",
    name: "Chitaavia",
  },
  QI: {
    iata: "QI",
    name: "Cimber Sterling",
  },
  C7: {
    iata: "C7",
    name: "Rico Linhas Aéreas",
  },
  C9: {
    iata: "C9",
    name: "Cirrus Airlines",
  },
  CF: {
    iata: "CF",
    name: "City Airline",
  },
  G3: {
    iata: "G3",
    name: "Gol Transportes Aéreos",
  },
  CT: {
    iata: "CT",
    name: "Civil Air Transport",
  },
  "6P": {
    iata: "6P",
    name: "Club Air",
  },
  DQ: {
    iata: "DQ",
    name: "Coastal Air",
  },
  "9L": {
    iata: "9L",
    name: "Colgan Air",
  },
  YD: {
    iata: "YD",
    name: "Cologne Air Transport GmbH",
  },
  OH: {
    iata: "OH",
    name: "PSA Airlines",
  },
  MN: {
    iata: "MN",
    name: "Comair",
  },
  C5: {
    iata: "C5",
    name: "CommutAir",
  },
  KR: {
    iata: "KR",
    name: "Comores Airlines",
  },
  GJ: {
    iata: "GJ",
    name: "Eurofly",
  },
  DE: {
    iata: "DE",
    name: "Condor Flugdienst",
  },
  CO: {
    iata: "CO",
    name: "Continental Express",
  },
  CS: {
    iata: "CS",
    name: "Continental Micronesia",
  },
  V0: {
    iata: "V0",
    name: "Conviasa",
  },
  CM: {
    iata: "CM",
    name: "Copa Airlines",
  },
  CQ: {
    iata: "CQ",
    name: "Sunshine Express Airlines",
  },
  XC: {
    iata: "XC",
    name: "Corendon Airlines",
  },
  CD: {
    iata: "CD",
    name: "Corendon Dutch Airlines",
  },
  SS: {
    iata: "SS",
    name: "Corsairfly",
  },
  XK: {
    iata: "XK",
    name: "Corse Méditerranée",
  },
  F5: {
    iata: "F5",
    name: "Cosmic Air",
  },
  OU: {
    iata: "OU",
    name: "Croatia Airlines",
  },
  "7C": {
    iata: "7C",
    name: "Jeju Air",
  },
  QE: {
    iata: "QE",
    name: "Crossair Europe",
  },
  CU: {
    iata: "CU",
    name: "Cubana de Aviación",
  },
  CY: {
    iata: "CY",
    name: "Cyprus Airways",
  },
  YK: {
    iata: "YK",
    name: "Cyprus Turkish Airlines",
  },
  OK: {
    iata: "OK",
    name: "Czech Airlines",
  },
  "8L": {
    iata: "8L",
    name: "Redhill Aviation",
  },
  XG: {
    iata: "XG",
    name: "Clickair",
  },
  WD: {
    iata: "WD",
    name: "WDL Aviation",
  },
  DX: {
    iata: "DX",
    name: "DAT Danish Air Transport",
  },
  ES: {
    iata: "ES",
    name: "DHL International",
  },
  L3: {
    iata: "L3",
    name: "LTU Austria",
  },
  D3: {
    iata: "D3",
    name: "Daallo Airlines",
  },
  N2: {
    iata: "N2",
    name: "Kabo Air",
  },
  H8: {
    iata: "H8",
    name: "Dalavia",
  },
  "0D": {
    iata: "0D",
    name: "Darwin Airline",
  },
  D5: {
    iata: "D5",
    name: "Dauair",
  },
  DL: {
    iata: "DL",
    name: "Delta Air Lines",
  },
  "2A": {
    iata: "2A",
    name: "Deutsche Bahn",
  },
  "1I": {
    iata: "1I",
    name: "Sky Trek International Airlines",
  },
  DH: {
    iata: "DH",
    name: "Independence Air",
  },
  Z6: {
    iata: "Z6",
    name: "Dniproavia",
  },
  YU: {
    iata: "YU",
    name: "Dominair",
  },
  DO: {
    iata: "DO",
    name: "Dominicana de Aviación",
  },
  E3: {
    iata: "E3",
    name: "Domodedovo Airlines",
  },
  D9: {
    iata: "D9",
    name: "Donavia",
  },
  KA: {
    iata: "KA",
    name: "Dragonair, Hong Kong Dragon Airlines",
  },
  KB: {
    iata: "KB",
    name: "Druk Air",
  },
  DI: {
    iata: "DI",
    name: "Dba",
  },
  "9A": {
    iata: "9A",
    name: "Eagle Express Air Charter",
  },
  E1: {
    iata: "E1",
    name: "Everbread",
  },
  "1C": {
    iata: "1C",
    name: "Electronic Data Systems",
  },
  "1Y": {
    iata: "1Y",
    name: "Electronic Data Systems",
  },
  BR: {
    iata: "BR",
    name: "EVA Air",
  },
  EY: {
    iata: "EY",
    name: "Etihad Airways",
  },
  H7: {
    iata: "H7",
    name: "Eagle Air",
  },
  QU: {
    iata: "QU",
    name: "Uganda Airlines",
  },
  S9: {
    iata: "S9",
    name: "East African Safari Air",
  },
  EA: {
    iata: "EA",
    name: "European Air Express",
  },
  T3: {
    iata: "T3",
    name: "Eastern Airways",
  },
  QF: {
    iata: "QF",
    name: "Qantas Airways",
  },
  DK: {
    iata: "DK",
    name: "Eastland Air",
  },
  MS: {
    iata: "MS",
    name: "Egyptair",
  },
  LY: {
    iata: "LY",
    name: "El Al Israel Airlines",
  },
  UZ: {
    iata: "UZ",
    name: "El-Buraq Air Transport",
  },
  EK: {
    iata: "EK",
    name: "Emirates Airlines",
  },
  EM: {
    iata: "EM",
    name: "Empire Airlines",
  },
  EU: {
    iata: "EU",
    name: "Empresa Ecuatoriana De Aviación",
  },
  E0: {
    iata: "E0",
    name: "Eos Airlines",
  },
  B8: {
    iata: "B8",
    name: "Eritrean Airlines",
  },
  E7: {
    iata: "E7",
    name: "European Aviation Air Charter",
  },
  OV: {
    iata: "OV",
    name: "SalamAir",
  },
  ET: {
    iata: "ET",
    name: "Ethiopian Airlines",
  },
  RZ: {
    iata: "RZ",
    name: "Euro Exec Express",
  },
  MM: {
    iata: "MM",
    name: "SAM Colombia",
  },
  UI: {
    iata: "UI",
    name: "Eurocypria Airlines",
  },
  K2: {
    iata: "K2",
    name: "Eurolot",
  },
  "3W": {
    iata: "3W",
    name: "Euromanx Airways",
  },
  "5O": {
    iata: "5O",
    name: "Europe Airpost",
  },
  QY: {
    iata: "QY",
    name: "European Air Transport",
  },
  EW: {
    iata: "EW",
    name: "Eurowings",
  },
  EZ: {
    iata: "EZ",
    name: "Sun Air of Scandinavia",
  },
  JN: {
    iata: "JN",
    name: "Excel Airways",
  },
  MB: {
    iata: "MB",
    name: "MNG Airlines",
  },
  OW: {
    iata: "OW",
    name: "Executive Airlines",
  },
  EO: {
    iata: "EO",
    name: "Hewa Bora Airways",
  },
  DS: {
    iata: "DS",
    name: "easyJet Switzerland",
  },
  IH: {
    iata: "IH",
    name: "Irtysh Air",
  },
  EF: {
    iata: "EF",
    name: "Far Eastern Air Transport",
  },
  FD: {
    iata: "FD",
    name: "Thai AirAsia",
  },
  F6: {
    iata: "F6",
    name: "FaroeJet",
  },
  F3: {
    iata: "F3",
    name: "Faso Airways",
  },
  FX: {
    iata: "FX",
    name: "Federal Express",
  },
  N8: {
    iata: "N8",
    name: "National Air Cargo dba National Airlines",
  },
  "4S": {
    iata: "4S",
    name: "Finalair Congo",
  },
  AY: {
    iata: "AY",
    name: "Finnair",
  },
  FC: {
    iata: "FC",
    name: "Finncomm Airlines",
  },
  FY: {
    iata: "FY",
    name: "Northwest Regional Airlines",
  },
  "7F": {
    iata: "7F",
    name: "First Air",
  },
  DP: {
    iata: "DP",
    name: "First Choice Airways",
  },
  "8F": {
    iata: "8F",
    name: "Fischer Air",
  },
  "8D": {
    iata: "8D",
    name: "Servant Air",
  },
  B5: {
    iata: "B5",
    name: "Flightline",
  },
  PA: {
    iata: "PA",
    name: "Pan American World Airways",
  },
  RF: {
    iata: "RF",
    name: "Florida West International Airways",
  },
  F2: {
    iata: "F2",
    name: "Fly Air",
  },
  OJ: {
    iata: "OJ",
    name: "Overland Airways",
  },
  SH: {
    iata: "SH",
    name: "Fly Me Sweden",
  },
  TE: {
    iata: "TE",
    name: "Skytaxi",
  },
  LF: {
    iata: "LF",
    name: "FlyNordic",
  },
  F7: {
    iata: "F7",
    name: "Flybaboo",
  },
  BE: {
    iata: "BE",
    name: "Flybe",
  },
  W3: {
    iata: "W3",
    name: "Switfair Cargo",
  },
  VY: {
    iata: "VY",
    name: "Vueling Airlines",
  },
  HK: {
    iata: "HK",
    name: "Four Star Aviation / Four Star Cargo",
  },
  FH: {
    iata: "FH",
    name: "Futura International Airways",
  },
  SJ: {
    iata: "SJ",
    name: "Freedom Air",
  },
  FP: {
    iata: "FP",
    name: "Servicios Aéreos de los Andes",
  },
  F9: {
    iata: "F9",
    name: "Frontier Airlines",
  },
  "2F": {
    iata: "2F",
    name: "Frontier Flying Service",
  },
  FZ: {
    iata: "FZ",
    name: "Flydubai",
  },
  "9Y": {
    iata: "9Y",
    name: "Fly Georgia",
  },
  VK: {
    iata: "VK",
    name: "Virgin Nigeria Airways",
  },
  GX: {
    iata: "GX",
    name: "Pacificair",
  },
  Y5: {
    iata: "Y5",
    name: "Pace Airlines",
  },
  GT: {
    iata: "GT",
    name: "GB Airways",
  },
  Z5: {
    iata: "Z5",
    name: "GMG Airlines",
  },
  "7O": {
    iata: "7O",
    name: "Galaxy Air",
  },
  "1G": {
    iata: "1G",
    name: "Galileo International",
  },
  GC: {
    iata: "GC",
    name: "Gambia International Airlines",
  },
  G7: {
    iata: "G7",
    name: "GoJet Airlines",
  },
  GA: {
    iata: "GA",
    name: "Garuda Indonesia",
  },
  "4G": {
    iata: "4G",
    name: "Gazpromavia",
  },
  A9: {
    iata: "A9",
    name: "Georgian Airways",
  },
  ST: {
    iata: "ST",
    name: "Germania",
  },
  "4U": {
    iata: "4U",
    name: "Germanwings",
  },
  GP: {
    iata: "GP",
    name: "Palau Trans Pacific Airlines",
  },
  GH: {
    iata: "GH",
    name: "Ghana Airways",
  },
  G0: {
    iata: "G0",
    name: "Ghana International Airlines",
  },
  GK: {
    iata: "GK",
    name: "JetStar Japan",
  },
  DC: {
    iata: "DC",
    name: "Golden Air",
  },
  G1: {
    iata: "G1",
    name: "Gorkha Airlines",
  },
  ZK: {
    iata: "ZK",
    name: "Great Lakes Airlines",
  },
  IJ: {
    iata: "IJ",
    name: "Spring Airlines Japan",
  },
  G6: {
    iata: "G6",
    name: "Air Volga",
  },
  J9: {
    iata: "J9",
    name: "Jazeera Airways",
  },
  GF: {
    iata: "GF",
    name: "Gulf Air Bahrain",
  },
  GY: {
    iata: "GY",
    name: "Tri-MG Intra Asia Airlines",
  },
  H6: {
    iata: "H6",
    name: "Hageland Aviation Services",
  },
  HR: {
    iata: "HR",
    name: "Hahn Air",
  },
  HU: {
    iata: "HU",
    name: "Hainan Airlines",
  },
  "1R": {
    iata: "1R",
    name: "Hainan Phoenix Information Systems",
  },
  "2T": {
    iata: "2T",
    name: "Haiti Ambassador Airlines",
  },
  "4R": {
    iata: "4R",
    name: "Hamburg International",
  },
  X3: {
    iata: "X3",
    name: "Hapag-Lloyd Express (TUIfly)",
  },
  HF: {
    iata: "HF",
    name: "Hapagfly",
  },
  HB: {
    iata: "HB",
    name: "Harbor Airlines",
  },
  HQ: {
    iata: "HQ",
    name: "Thomas Cook Airlines",
  },
  HA: {
    iata: "HA",
    name: "Hawaiian Airlines",
  },
  BH: {
    iata: "BH",
    name: "Hawkair",
  },
  HN: {
    iata: "HN",
    name: "Heavylift Cargo Airlines",
  },
  JB: {
    iata: "JB",
    name: "Helijet",
  },
  ZU: {
    iata: "ZU",
    name: "Helios Airways",
  },
  HW: {
    iata: "HW",
    name: "North-Wright Airways",
  },
  "2L": {
    iata: "2L",
    name: "Helvetic Airways",
  },
  UD: {
    iata: "UD",
    name: "Hex'Air",
  },
  "5K": {
    iata: "5K",
    name: "Hi Fly",
  },
  H5: {
    iata: "H5",
    name: "Mavial Magadan Airlines",
  },
  HX: {
    iata: "HX",
    name: "Hong Kong Airlines",
  },
  RH: {
    iata: "RH",
    name: "Republic Express Airlines",
  },
  UO: {
    iata: "UO",
    name: "Hong Kong Express Airways",
  },
  HH: {
    iata: "HH",
    name: "Hope Air",
  },
  QX: {
    iata: "QX",
    name: "Horizon Air",
  },
  H4: {
    iata: "H4",
    name: "Inter Island Airways",
  },
  IK: {
    iata: "IK",
    name: "Lankair",
  },
  II: {
    iata: "II",
    name: "IBC Airways",
  },
  "0C": {
    iata: "0C",
    name: "IBL Aviation",
  },
  C3: {
    iata: "C3",
    name: "Independent Carrier (ICAR)",
  },
  "1F": {
    iata: "1F",
    name: "INFINI Travel Information",
  },
  "1U": {
    iata: "1U",
    name: "Polyot Sirena",
  },
  IB: {
    iata: "IB",
    name: "Iberia Airlines",
  },
  I2: {
    iata: "I2",
    name: "Iberia Express",
  },
  FW: {
    iata: "FW",
    name: "Ibex Airlines",
  },
  FI: {
    iata: "FI",
    name: "Icelandair",
  },
  "6E": {
    iata: "6E",
    name: "IndiGo Airlines",
  },
  IC: {
    iata: "IC",
    name: "Indian Airlines",
  },
  I9: {
    iata: "I9",
    name: "Indigo Airlines",
  },
  QZ: {
    iata: "QZ",
    name: "Indonesia AirAsia",
  },
  IO: {
    iata: "IO",
    name: "Indonesian Airlines",
  },
  D6: {
    iata: "D6",
    name: "Interair South Africa",
  },
  RS: {
    iata: "RS",
    name: "Sky Regional Airlines",
  },
  "6I": {
    iata: "6I",
    name: "International Business Air",
  },
  "3L": {
    iata: "3L",
    name: "Intersky",
  },
  I4: {
    iata: "I4",
    name: "Interstate Airlines",
  },
  IR: {
    iata: "IR",
    name: "Iran Air",
  },
  EP: {
    iata: "EP",
    name: "Iran Aseman Airlines",
  },
  IA: {
    iata: "IA",
    name: "Iraqi Airways",
  },
  "2S": {
    iata: "2S",
    name: "Satgur Air Transport",
  },
  CN: {
    iata: "CN",
    name: "Westward Airways",
  },
  IF: {
    iata: "IF",
    name: "Islas Airways",
  },
  WC: {
    iata: "WC",
    name: "Islena De Inversiones",
  },
  "6H": {
    iata: "6H",
    name: "Israir",
  },
  FS: {
    iata: "FS",
    name: "Servicios de Transportes Aéreos Fueguinos",
  },
  GI: {
    iata: "GI",
    name: "Itek Air",
  },
  XM: {
    iata: "XM",
    name: "J-Air",
  },
  JC: {
    iata: "JC",
    name: "JAL Express",
  },
  JO: {
    iata: "JO",
    name: "Jettime",
  },
  MT: {
    iata: "MT",
    name: "Thomas Cook Airlines",
  },
  "1M": {
    iata: "1M",
    name: "JSC Transport Automated Information Systems",
  },
  JI: {
    iata: "JI",
    name: "Midway Airlines (1993–2003)",
  },
  "3X": {
    iata: "3X",
    name: "Japan Air Commuter",
  },
  JL: {
    iata: "JL",
    name: "Japan Airlines Domestic",
  },
  EG: {
    iata: "EG",
    name: "Japan Asia Airways",
  },
  NU: {
    iata: "NU",
    name: "Japan Transocean Air",
  },
  O2: {
    iata: "O2",
    name: "Linear Air",
  },
  "9W": {
    iata: "9W",
    name: "Jet Airways",
  },
  QJ: {
    iata: "QJ",
    name: "Jet Airways",
  },
  PP: {
    iata: "PP",
    name: "Jet Aviation",
  },
  "3K": {
    iata: "3K",
    name: "Jetstar Asia Airways",
  },
  LS: {
    iata: "LS",
    name: "Jet2.com",
  },
  B6: {
    iata: "B6",
    name: "JetBlue Airways",
  },
  JF: {
    iata: "JF",
    name: "L.A.B. Flying Service",
  },
  "0J": {
    iata: "0J",
    name: "Jetclub",
  },
  SG: {
    iata: "SG",
    name: "Spicejet",
  },
  JQ: {
    iata: "JQ",
    name: "Jetstar Airways",
  },
  JX: {
    iata: "JX",
    name: "Jett8 Airlines Cargo",
  },
  R5: {
    iata: "R5",
    name: "Malta Air Charter",
  },
  "6J": {
    iata: "6J",
    name: "Skynet Asia Airways",
  },
  KW: {
    iata: "KW",
    name: "Wataniya Airways",
  },
  WA: {
    iata: "WA",
    name: "Western Airlines",
  },
  KL: {
    iata: "KL",
    name: "KLM",
  },
  K4: {
    iata: "K4",
    name: "Kalitta Air",
  },
  K9: {
    iata: "K9",
    name: "Krylo Airlines",
  },
  RQ: {
    iata: "RQ",
    name: "Kam Air",
  },
  E2: {
    iata: "E2",
    name: "Rio Grande Air",
  },
  V2: {
    iata: "V2",
    name: "Vision Airlines",
  },
  KV: {
    iata: "KV",
    name: "Kavminvodyavia",
  },
  M5: {
    iata: "M5",
    name: "Kenmore Air",
  },
  KQ: {
    iata: "KQ",
    name: "Kenya Airways",
  },
  IT: {
    iata: "IT",
    name: "Tigerair Taiwan",
  },
  Y9: {
    iata: "Y9",
    name: "Kish Air",
  },
  KP: {
    iata: "KP",
    name: "Kiwi International Air Lines",
  },
  "7K": {
    iata: "7K",
    name: "Kogalymavia Air Company",
  },
  "8J": {
    iata: "8J",
    name: "Komiinteravia",
  },
  KE: {
    iata: "KE",
    name: "Korean Air",
  },
  "7B": {
    iata: "7B",
    name: "Krasnojarsky Airlines",
  },
  GW: {
    iata: "GW",
    name: "SkyGreece Airlines",
  },
  KU: {
    iata: "KU",
    name: "Kuwait Airways",
  },
  GO: {
    iata: "GO",
    name: "Kuzu Airlines Cargo",
  },
  N5: {
    iata: "N5",
    name: "Skagway Air Service",
  },
  R8: {
    iata: "R8",
    name: "Kyrgyzstan Airlines",
  },
  YQ: {
    iata: "YQ",
    name: "TAR Aerolineas",
  },
  LR: {
    iata: "LR",
    name: "LACSA",
  },
  KG: {
    iata: "KG",
    name: "LAI - Línea Aérea IAACA",
  },
  LA: {
    iata: "LA",
    name: "LATAM Chile",
  },
  "4M": {
    iata: "4M",
    name: "LATAM Argentina",
  },
  PZ: {
    iata: "PZ",
    name: "TAM Mercosur",
  },
  LU: {
    iata: "LU",
    name: "LATAM Express",
  },
  LP: {
    iata: "LP",
    name: "LATAM Peru",
  },
  NI: {
    iata: "NI",
    name: "Portugalia",
  },
  L5: {
    iata: "L5",
    name: "Lufttransport",
  },
  LO: {
    iata: "LO",
    name: "LOT Polish Airlines",
  },
  LT: {
    iata: "LT",
    name: "LongJiang Airlines",
  },
  N6: {
    iata: "N6",
    name: "Nuevo Continente",
  },
  QV: {
    iata: "QV",
    name: "Lao Airlines",
  },
  L7: {
    iata: "L7",
    name: "Línea Aérea SAPSA",
  },
  NG: {
    iata: "NG",
    name: "Lauda Air",
  },
  LQ: {
    iata: "LQ",
    name: "Lebanese Air Transport",
  },
  LI: {
    iata: "LI",
    name: "Leeward Islands Air Transport",
  },
  LN: {
    iata: "LN",
    name: "Libyan Arab Airlines",
  },
  TM: {
    iata: "TM",
    name: "Linhas Aéreas de Moçambique",
  },
  JT: {
    iata: "JT",
    name: "Lion Mentari Airlines",
  },
  LM: {
    iata: "LM",
    name: "Livingston",
  },
  LB: {
    iata: "LB",
    name: "Lloyd Aéreo Boliviano",
  },
  LC: {
    iata: "LC",
    name: "Varig Logística",
  },
  HE: {
    iata: "HE",
    name: "Luftfahrtgesellschaft Walter",
  },
  LH: {
    iata: "LH",
    name: "Lufthansa (Miles & More)",
  },
  CL: {
    iata: "CL",
    name: "Lufthansa CityLine",
  },
  DV: {
    iata: "DV",
    name: "Nantucket Airlines",
  },
  LG: {
    iata: "LG",
    name: "Luxair",
  },
  "5V": {
    iata: "5V",
    name: "Lviv Airlines",
  },
  L2: {
    iata: "L2",
    name: "Lynden Air Cargo",
  },
  L4: {
    iata: "L4",
    name: "Lynx Aviation",
  },
  Z8: {
    iata: "Z8",
    name: "Línea Aérea Amaszonas",
  },
  MJ: {
    iata: "MJ",
    name: "Mihin Lanka",
  },
  Q2: {
    iata: "Q2",
    name: "Maldivian (airline)",
  },
  OD: {
    iata: "OD",
    name: "Malindo Airways",
  },
  M7: {
    iata: "M7",
    name: "Tropical Airways",
  },
  MH: {
    iata: "MH",
    name: "Malaysia Airlines",
  },
  IN: {
    iata: "IN",
    name: "NAM Air",
  },
  OM: {
    iata: "OM",
    name: "MIAT Mongolian Airlines",
  },
  DM: {
    iata: "DM",
    name: "Maersk",
  },
  W5: {
    iata: "W5",
    name: "Mahan Air",
  },
  M2: {
    iata: "M2",
    name: "MHS Aviation GmbH",
  },
  TF: {
    iata: "TF",
    name: "Malmö Aviation",
  },
  MA: {
    iata: "MA",
    name: "Malév Hungarian Airlines",
  },
  RI: {
    iata: "RI",
    name: "Mandala Airlines",
  },
  JE: {
    iata: "JE",
    name: "Mango",
  },
  MP: {
    iata: "MP",
    name: "Martinair",
  },
  Q4: {
    iata: "Q4",
    name: "Starlink Aviation",
  },
  "8M": {
    iata: "8M",
    name: "Myanmar Airways International",
  },
  MY: {
    iata: "MY",
    name: "Midwest Airlines (Egypt)",
  },
  MW: {
    iata: "MW",
    name: "Mokulele Airlines",
  },
  "7M": {
    iata: "7M",
    name: "Mayair",
  },
  M8: {
    iata: "M8",
    name: "Mekong Airlines",
  },
  IM: {
    iata: "IM",
    name: "Menajet",
  },
  IG: {
    iata: "IG",
    name: "Meridiana",
  },
  MZ: {
    iata: "MZ",
    name: "Merpati Nusantara Airlines",
  },
  YV: {
    iata: "YV",
    name: "Mesa Airlines",
  },
  XJ: {
    iata: "XJ",
    name: "Thai AirAsia X",
  },
  MX: {
    iata: "MX",
    name: "Mexicana de Aviación",
  },
  ME: {
    iata: "ME",
    name: "Middle East Airlines",
  },
  YX: {
    iata: "YX",
    name: "Midwest Airlines",
  },
  M4: {
    iata: "M4",
    name: "Mistral Air",
  },
  "2M": {
    iata: "2M",
    name: "Moldavian Airlines",
  },
  "8I": {
    iata: "8I",
    name: "Myway Airlines",
  },
  YM: {
    iata: "YM",
    name: "Montenegro Airlines",
  },
  "5M": {
    iata: "5M",
    name: "Sibaviatrans",
  },
  "3R": {
    iata: "3R",
    name: "Moskovia Airlines",
  },
  M9: {
    iata: "M9",
    name: "Motor Sich",
  },
  N4: {
    iata: "N4",
    name: "Nordwind Airlines",
  },
  VZ: {
    iata: "VZ",
    name: "Thai Vietjet Air",
  },
  UB: {
    iata: "UB",
    name: "Myanma Airways",
  },
  "6N": {
    iata: "6N",
    name: "Nordic Regional",
  },
  P9: {
    iata: "P9",
    name: "Peruvian Airlines",
  },
  UE: {
    iata: "UE",
    name: "Transeuropean Airlines",
  },
  N7: {
    iata: "N7",
    name: "National Airlines",
  },
  NA: {
    iata: "NA",
    name: "North American Airlines",
  },
  "9O": {
    iata: "9O",
    name: "National Airways Cameroon",
  },
  NC: {
    iata: "NC",
    name: "Northern Air Cargo",
  },
  CE: {
    iata: "CE",
    name: "Chalair",
  },
  "1N": {
    iata: "1N",
    name: "Navitaire",
  },
  RA: {
    iata: "RA",
    name: "Nepal Airlines",
  },
  EJ: {
    iata: "EJ",
    name: "New England Airlines",
  },
  "2N": {
    iata: "2N",
    name: "Yuzhmashavia",
  },
  HG: {
    iata: "HG",
    name: "Niki",
  },
  KZ: {
    iata: "KZ",
    name: "Nippon Cargo Airlines",
  },
  DD: {
    iata: "DD",
    name: "Nok Air",
  },
  "5N": {
    iata: "5N",
    name: "Nordavia",
  },
  JH: {
    iata: "JH",
    name: "Nordeste Linhas Aéreas Regionais",
  },
  N9: {
    iata: "N9",
    name: "North Coast Aviation",
  },
  NW: {
    iata: "NW",
    name: "Northwest Airlines",
  },
  J3: {
    iata: "J3",
    name: "Northwestern Air",
  },
  DY: {
    iata: "DY",
    name: "Norwegian Air Shuttle",
  },
  D8: {
    iata: "D8",
    name: "Norwegian Air International",
  },
  DU: {
    iata: "DU",
    name: "Norwegian Long Haul",
  },
  BJ: {
    iata: "BJ",
    name: "Nouvel Air Tunisie",
  },
  O9: {
    iata: "O9",
    name: "Nova Airline",
  },
  VQ: {
    iata: "VQ",
    name: "Novo Air",
  },
  NK: {
    iata: "NK",
    name: "Spirit Airlines",
  },
  UQ: {
    iata: "UQ",
    name: "O'Connor Airlines",
  },
  O8: {
    iata: "O8",
    name: "Oasis Hong Kong Airlines",
  },
  VC: {
    iata: "VC",
    name: "Voyageur Airways",
  },
  OA: {
    iata: "OA",
    name: "Olympic Airlines",
  },
  WY: {
    iata: "WY",
    name: "Oman Air",
  },
  "8Q": {
    iata: "8Q",
    name: "Princess Air",
  },
  R2: {
    iata: "R2",
    name: "Orenburg Airlines",
  },
  OX: {
    iata: "OX",
    name: "Orient Thai Airlines",
  },
  OL: {
    iata: "OL",
    name: "OLT Express Germany",
  },
  ON: {
    iata: "ON",
    name: "Our Airline",
  },
  O7: {
    iata: "O7",
    name: "Ozjet Airlines",
  },
  PV: {
    iata: "PV",
    name: "St Barth Commuter",
  },
  "9Q": {
    iata: "9Q",
    name: "PB Air",
  },
  PU: {
    iata: "PU",
    name: "PLUNA",
  },
  U4: {
    iata: "U4",
    name: "PMTair",
  },
  BL: {
    iata: "BL",
    name: "Pacific Airlines",
  },
  "8P": {
    iata: "8P",
    name: "Pacific Coastal Airlines",
  },
  Q8: {
    iata: "Q8",
    name: "Pacific East Asia Cargo Airlines",
  },
  LW: {
    iata: "LW",
    name: "Sun Air International",
  },
  PK: {
    iata: "PK",
    name: "Pakistan International Airlines",
  },
  PF: {
    iata: "PF",
    name: "Palestinian Airlines",
  },
  NR: {
    iata: "NR",
    name: "Pamir Airways",
  },
  PN: {
    iata: "PN",
    name: "Pan American Airways",
  },
  I7: {
    iata: "I7",
    name: "Paramount Airways",
  },
  P3: {
    iata: "P3",
    name: "Passaredo Transportes Aéreos",
  },
  KS: {
    iata: "KS",
    name: "Peninsula Airways",
  },
  Z2: {
    iata: "Z2",
    name: "EZD",
  },
  PR: {
    iata: "PR",
    name: "Philippine Airlines",
  },
  "9R": {
    iata: "9R",
    name: "Phuket Air",
  },
  PI: {
    iata: "PI",
    name: "Sun Air (Fiji)",
  },
  "9E": {
    iata: "9E",
    name: "Pinnacle Airlines",
  },
  PO: {
    iata: "PO",
    name: "Polar Air Cargo",
  },
  PH: {
    iata: "PH",
    name: "Polynesian Airlines",
  },
  PD: {
    iata: "PD",
    name: "Porter Airlines",
  },
  BK: {
    iata: "BK",
    name: "Potomac Air",
  },
  PW: {
    iata: "PW",
    name: "Precision Air",
  },
  TO: {
    iata: "TO",
    name: "Transavia France",
  },
  FE: {
    iata: "FE",
    name: "Primaris Airlines",
  },
  "8W": {
    iata: "8W",
    name: "Private Wings Flugcharter",
  },
  P6: {
    iata: "P6",
    name: "Privilege Style Líneas Aéreas",
  },
  P0: {
    iata: "P0",
    name: "Proflight Zambia",
  },
  FV: {
    iata: "FV",
    name: "Rossiya",
  },
  QR: {
    iata: "QR",
    name: "Qatar Airways",
  },
  R6: {
    iata: "R6",
    name: "DAT LT",
  },
  "1D": {
    iata: "1D",
    name: "Radixx Solutions International",
  },
  V4: {
    iata: "V4",
    name: "Vieques Air Link",
  },
  FN: {
    iata: "FN",
    name: "Regional Air Lines",
  },
  ZL: {
    iata: "ZL",
    name: "Regional Express",
  },
  P7: {
    iata: "P7",
    name: "Russian Sky Airlines",
  },
  RW: {
    iata: "RW",
    name: "Republic Airlines",
  },
  RL: {
    iata: "RL",
    name: "Royal Phnom Penh Airways",
  },
  SL: {
    iata: "SL",
    name: "Thai Lion Mentari",
  },
  GZ: {
    iata: "GZ",
    name: "Air Rarotonga",
  },
  RR: {
    iata: "RR",
    name: "Buzz",
  },
  AT: {
    iata: "AT",
    name: "Royal Air Maroc",
  },
  R0: {
    iata: "R0",
    name: "Royal Airlines",
  },
  BI: {
    iata: "BI",
    name: "Royal Brunei Airlines",
  },
  RJ: {
    iata: "RJ",
    name: "Royal Jordanian",
  },
  RK: {
    iata: "RK",
    name: "Royal Khmer Airlines",
  },
  WR: {
    iata: "WR",
    name: "WestJet Encore",
  },
  WB: {
    iata: "WB",
    name: "Rwandair Express",
  },
  "7S": {
    iata: "7S",
    name: "Ryan Air Service",
  },
  RD: {
    iata: "RD",
    name: "Ryan International Airlines",
  },
  FR: {
    iata: "FR",
    name: "Ryanair",
  },
  YS: {
    iata: "YS",
    name: "Régional Compagnie Aérienne Européenne",
  },
  TR: {
    iata: "TR",
    name: "Tigerair Singapore",
  },
  "6Y": {
    iata: "6Y",
    name: "Smartlynx Airlines",
  },
  "7E": {
    iata: "7E",
    name: "Sylt Air GmbH",
  },
  S4: {
    iata: "S4",
    name: "SATA International",
  },
  SA: {
    iata: "SA",
    name: "South African Airways",
  },
  W7: {
    iata: "W7",
    name: "Western Pacific Airlines",
  },
  NL: {
    iata: "NL",
    name: "Shaheen Air International",
  },
  SK: {
    iata: "SK",
    name: "SAS Braathens",
  },
  UG: {
    iata: "UG",
    name: "Tuninter",
  },
  S7: {
    iata: "S7",
    name: "S7 Airlines",
  },
  BB: {
    iata: "BB",
    name: "Seaborne Airlines",
  },
  K5: {
    iata: "K5",
    name: "SeaPort Airlines",
  },
  UL: {
    iata: "UL",
    name: "SriLankan Airlines",
  },
  SY: {
    iata: "SY",
    name: "Sun Country Airlines",
  },
  I6: {
    iata: "I6",
    name: "Sky Eyes",
  },
  "7G": {
    iata: "7G",
    name: "Star Flyer",
  },
  FA: {
    iata: "FA",
    name: "Safair",
  },
  HZ: {
    iata: "HZ",
    name: "Sat Airlines",
  },
  SP: {
    iata: "SP",
    name: "SATA Air Acores",
  },
  "8S": {
    iata: "8S",
    name: "Scorpio Aviation",
  },
  ZY: {
    iata: "ZY",
    name: "Sky Airlines",
  },
  SQ: {
    iata: "SQ",
    name: "Singapore Airlines Cargo",
  },
  SI: {
    iata: "SI",
    name: "Skynet Airlines",
  },
  XS: {
    iata: "XS",
    name: "SITA",
  },
  FT: {
    iata: "FT",
    name: "Siem Reap Airways",
  },
  SX: {
    iata: "SX",
    name: "Skybus Airlines",
  },
  S6: {
    iata: "S6",
    name: "Star Air",
  },
  D2: {
    iata: "D2",
    name: "Severstal Air Company",
  },
  "5G": {
    iata: "5G",
    name: "Skyservice Airlines",
  },
  SD: {
    iata: "SD",
    name: "Sudan Airways",
  },
  SV: {
    iata: "SV",
    name: "Saudia",
  },
  WN: {
    iata: "WN",
    name: "Southwest Airlines",
  },
  A4: {
    iata: "A4",
    name: "Southern Winds Airlines",
  },
  WG: {
    iata: "WG",
    name: "Sunwing Airlines",
  },
  LX: {
    iata: "LX",
    name: "Swiss International Air Lines",
  },
  SR: {
    iata: "SR",
    name: "Swissair",
  },
  WV: {
    iata: "WV",
    name: "Swe Fly",
  },
  XQ: {
    iata: "XQ",
    name: "SunExpress",
  },
  AL: {
    iata: "AL",
    name: "TransAVIAexport Airlines",
  },
  E5: {
    iata: "E5",
    name: "Samara Airlines",
  },
  SC: {
    iata: "SC",
    name: "Shandong Airlines",
  },
  "9C": {
    iata: "9C",
    name: "Spring Airlines",
  },
  "3U": {
    iata: "3U",
    name: "Sichuan Airlines",
  },
  FM: {
    iata: "FM",
    name: "Shanghai Airlines",
  },
  ZH: {
    iata: "ZH",
    name: "Shenzhen Airlines",
  },
  "7L": {
    iata: "7L",
    name: "Sun D'Or",
  },
  NE: {
    iata: "NE",
    name: "SkyEurope",
  },
  SO: {
    iata: "SO",
    name: "Sunshine Airlines",
  },
  JK: {
    iata: "JK",
    name: "Spanair",
  },
  "1Z": {
    iata: "1Z",
    name: "Sabre Pacific",
  },
  "1S": {
    iata: "1S",
    name: "Sabre",
  },
  "1H": {
    iata: "1H",
    name: "Siren-Travel",
  },
  "1Q": {
    iata: "1Q",
    name: "Sirena",
  },
  "1K": {
    iata: "1K",
    name: "Sutra",
  },
  "2C": {
    iata: "2C",
    name: "SNCF",
  },
  S0: {
    iata: "S0",
    name: "Slok Air Gambia",
  },
  S3: {
    iata: "S3",
    name: "Santa Barbara Airlines",
  },
  H2: {
    iata: "H2",
    name: "Sky Airline",
  },
  OO: {
    iata: "OO",
    name: "SkyWest Airlines",
  },
  BC: {
    iata: "BC",
    name: "Skymark Airlines",
  },
  LJ: {
    iata: "LJ",
    name: "Sierra National Airlines",
  },
  MI: {
    iata: "MI",
    name: "SilkAir",
  },
  "6Q": {
    iata: "6Q",
    name: "Slovak Airlines",
  },
  PY: {
    iata: "PY",
    name: "Surinam Airways",
  },
  NB: {
    iata: "NB",
    name: "Sterling Airlines",
  },
  IE: {
    iata: "IE",
    name: "Solomon Airlines",
  },
  "6W": {
    iata: "6W",
    name: "Saratov Airlines Joint Stock Company",
  },
  S5: {
    iata: "S5",
    name: "Trast Aero",
  },
  R1: {
    iata: "R1",
    name: "Sirin",
  },
  O3: {
    iata: "O3",
    name: "SF Airlines",
  },
  EQ: {
    iata: "EQ",
    name: "TAME",
  },
  TP: {
    iata: "TP",
    name: "TAP Portugal",
  },
  TU: {
    iata: "TU",
    name: "Tunisair",
  },
  "3V": {
    iata: "3V",
    name: "TNT Airways",
  },
  T2: {
    iata: "T2",
    name: "Thai Air Cargo",
  },
  TQ: {
    iata: "TQ",
    name: "Tandem Aero",
  },
  ZT: {
    iata: "ZT",
    name: "Titan Airways",
  },
  DG: {
    iata: "DG",
    name: "Tigerair Philippines",
  },
  TG: {
    iata: "TG",
    name: "Thai Airways International",
  },
  TK: {
    iata: "TK",
    name: "Turkish Airlines",
  },
  T7: {
    iata: "T7",
    name: "Twin Jet",
  },
  "3P": {
    iata: "3P",
    name: "Tiara Air",
  },
  TI: {
    iata: "TI",
    name: "Tol-Air Services",
  },
  BY: {
    iata: "BY",
    name: "Thomson Airways",
  },
  PM: {
    iata: "PM",
    name: "Tropic Air",
  },
  QT: {
    iata: "QT",
    name: "TAMPA",
  },
  K3: {
    iata: "K3",
    name: "Taquan Air Services",
  },
  GE: {
    iata: "GE",
    name: "TransAsia Airways",
  },
  HV: {
    iata: "HV",
    name: "Transavia Holland",
  },
  VR: {
    iata: "VR",
    name: "TACV",
  },
  T9: {
    iata: "T9",
    name: "Thai Star Airlines",
  },
  "9T": {
    iata: "9T",
    name: "Transwest Air",
  },
  UN: {
    iata: "UN",
    name: "Transaero Airlines",
  },
  T5: {
    iata: "T5",
    name: "Turkmenhovayollary",
  },
  T6: {
    iata: "T6",
    name: "Tavrey Airlines",
  },
  TW: {
    iata: "TW",
    name: "T'way Air",
  },
  "6B": {
    iata: "6B",
    name: "TUIfly Nordic",
  },
  DT: {
    iata: "DT",
    name: "TAAG Angola Airlines",
  },
  SF: {
    iata: "SF",
    name: "Tassili Airlines",
  },
  TJ: {
    iata: "TJ",
    name: "Tradewind Aviation",
  },
  "1E": {
    iata: "1E",
    name: "Travelsky Technology",
  },
  "2H": {
    iata: "2H",
    name: "Thalys",
  },
  "1L": {
    iata: "1L",
    name: "Open Skies Consultative Commission",
  },
  RO: {
    iata: "RO",
    name: "Tarom",
  },
  "3T": {
    iata: "3T",
    name: "Turan Air",
  },
  T4: {
    iata: "T4",
    name: "TRIP Linhas Aéreas",
  },
  L6: {
    iata: "L6",
    name: "Tbilaviamsheni",
  },
  XN: {
    iata: "XN",
    name: "XpressAir",
  },
  VO: {
    iata: "VO",
    name: "Tyrolean Airways",
  },
  U5: {
    iata: "U5",
    name: "USA3000 Airlines",
  },
  UA: {
    iata: "UA",
    name: "United Airlines",
  },
  UK: {
    iata: "UK",
    name: "United Kingdom",
  },
  "4H": {
    iata: "4H",
    name: "United Airways",
  },
  U6: {
    iata: "U6",
    name: "Ural Airlines",
  },
  UF: {
    iata: "UF",
    name: "UM Airlines",
  },
  "6Z": {
    iata: "6Z",
    name: "Ukrainian Cargo Airways",
  },
  "5X": {
    iata: "5X",
    name: "United Parcel Service",
  },
  US: {
    iata: "US",
    name: "US Airways",
  },
  UH: {
    iata: "UH",
    name: "US Helicopter",
  },
  UT: {
    iata: "UT",
    name: "UTair Aviation",
  },
  HY: {
    iata: "HY",
    name: "Uzbekistan Airways",
  },
  PS: {
    iata: "PS",
    name: "Ukraine International Airlines",
  },
  VA: {
    iata: "VA",
    name: "Viasa",
  },
  VF: {
    iata: "VF",
    name: "Valuair",
  },
  "0V": {
    iata: "0V",
    name: "Vietnam Air Services Company (VASCO)",
  },
  VN: {
    iata: "VN",
    name: "Vietnam Airlines",
  },
  NN: {
    iata: "NN",
    name: "VIM Airlines",
  },
  "2R": {
    iata: "2R",
    name: "Via Rail Canada",
  },
  Y4: {
    iata: "Y4",
    name: "Volaris",
  },
  VI: {
    iata: "VI",
    name: "Volga-Dnepr Airlines",
  },
  TV: {
    iata: "TV",
    name: "Virgin Express",
  },
  VS: {
    iata: "VS",
    name: "Virgin Atlantic Airways",
  },
  ZG: {
    iata: "ZG",
    name: "Viva Macau",
  },
  XF: {
    iata: "XF",
    name: "Vladivostok Air",
  },
  VM: {
    iata: "VM",
    name: "Viaggio Air",
  },
  "9V": {
    iata: "9V",
    name: "Vipair Airlines",
  },
  RG: {
    iata: "RG",
    name: "VRG Linhas Aéreas",
  },
  VP: {
    iata: "VP",
    name: "VASP",
  },
  VG: {
    iata: "VG",
    name: "VLM Airlines",
  },
  WT: {
    iata: "WT",
    name: "Wasaya Airways",
  },
  "2W": {
    iata: "2W",
    name: "Welcome Air",
  },
  WZ: {
    iata: "WZ",
    name: "West African Airlines",
  },
  YH: {
    iata: "YH",
    name: "West Caribbean Airways",
  },
  "8O": {
    iata: "8O",
    name: "West Coast Air",
  },
  WS: {
    iata: "WS",
    name: "WestJet",
  },
  XP: {
    iata: "XP",
    name: "Xtra Airways",
  },
  WF: {
    iata: "WF",
    name: "Widerøe",
  },
  IV: {
    iata: "IV",
    name: "Wind Jet",
  },
  "7W": {
    iata: "7W",
    name: "Windrose Air",
  },
  "8Z": {
    iata: "8Z",
    name: "Wizz Air Bulgaria",
  },
  W6: {
    iata: "W6",
    name: "Wizz Air",
  },
  WO: {
    iata: "WO",
    name: "World Airways",
  },
  "1P": {
    iata: "1P",
    name: "Worldspan",
  },
  MF: {
    iata: "MF",
    name: "Xiamen Airlines",
  },
  SE: {
    iata: "SE",
    name: "XL Airways France",
  },
  YL: {
    iata: "YL",
    name: "Yamal Airlines",
  },
  Y8: {
    iata: "Y8",
    name: "Yangtze River Express",
  },
  Y0: {
    iata: "Y0",
    name: "Yellow Air Taxi/Friendship Airways",
  },
  IY: {
    iata: "IY",
    name: "Yemenia",
  },
  C4: {
    iata: "C4",
    name: "Zimex Aviation",
  },
  Q3: {
    iata: "Q3",
    name: "Zambian Airways",
  },
  Z4: {
    iata: "Z4",
    name: "Zoom Airlines",
  },
  B0: {
    iata: "B0",
    name: "La Compagnie",
  },
};
