import { Injectable } from "@angular/core";
import { AbstractPlaceSearchService } from "../abstract-place-search.service";
import {
  DateTimeSearch,
  SearchCriteria,
  SearchOptions,
  SearchResult,
  TimeRange,
  Travel,
  WhenSearch,
} from "../search-engine-service";
import { Address } from "src/app/@shared/@types/models";
import { Observable } from "rxjs";
import { SearchService } from "src/app/@shared/services/search.service";
import { TranslateService } from "@ngx-translate/core";
import { CarTypes } from "src/app/travel/car/car";
import { HttpClient } from "@angular/common/http";
import { MemberSociety } from "src/app/@shared/@types/society";
import { SocietyService } from "src/app/@shared/services/society.service";

@Injectable({
  providedIn: "root",
})
export class CarSearchService extends AbstractPlaceSearchService<CarSearchOptions> {
  constructor(
    private searchService: SearchService,
    private translateService: TranslateService,
    httpClient: HttpClient,
    private societyService: SocietyService,
  ) {
    super(httpClient);
  }

  createSearchOptionsFromJson(json: {}): CarSearchOptions {
    return Object.assign(new CarSearchOptions(), json);
  }

  getType(): "car" {
    return "car";
  }

  getMaxPassengers(searchCriteria: SearchCriteria<Address, CarSearchOptions>): number {
    return 1;
  }

  launchSearch(searchCriteria: SearchCriteria<Address, CarSearchOptions>): Observable<SearchResult> {
    let data: CarTypes.SearchBody = {
      userIds: searchCriteria.travels.flatMap((searchCriteria) => searchCriteria.people).map((p) => p.user._id),

      pickupAddress: searchCriteria.mainTravel.origin,
      pickupDate: searchCriteria.mainTravel.when.outward.date,
      pickupTime: searchCriteria.mainTravel.when.outward.getBeginTimeAsArray()[0],

      returnAddressSameAsPickupAddress: searchCriteria.options.destinationSameAsOrigin,

      returnAddress:
        searchCriteria.options.destinationSameAsOrigin === true ? null : searchCriteria.mainTravel.destination,
      returnDate: searchCriteria.mainTravel.when.inward?.date,
      returnTime: searchCriteria.mainTravel.when.inward?.getBeginTimeAsArray()[0],
    };
    return this.searchService.create(this.getType(), data, this.translateService.currentLang);
  }

  createBlankCriteria(): SearchCriteria<Address, CarSearchOptions> {
    return new SearchCriteria<Address, CarSearchOptions>(
      Object.assign(new CarSearchOptions(), { destinationSameAsOrigin: true }),
      new Travel<Address>(
        [],
        new WhenSearch(
          Object.assign(new DateTimeSearch(), { time: TimeRange.oneHour(10) }),
          Object.assign(new DateTimeSearch(), { time: TimeRange.oneHour(18) }),
        ),
        this.createLocalityFromJson({}),
        this.createLocalityFromJson({}),
      ),
    );
  }

  private createDateTimeSearchFromPreviousSearch(date: string, time: number): DateTimeSearch {
    if (!date) {
      return undefined;
    }
    return Object.assign(new DateTimeSearch(), { date: date, time: TimeRange.oneHour(time) });
  }

  private createMemberSocietyArrayFromIDs(userIDs: string[]): MemberSociety[] {
    return userIDs
      .map((userId) => this.societyService.society.value.members.find((member) => member.user._id === userId))
      .filter((item) => !!item);
  }

  createCriteriaFromPreviousSearch(previousSearch?: any): SearchCriteria<Address, CarSearchOptions> {
    if (!previousSearch) {
      return undefined;
    }
    return new SearchCriteria<Address, CarSearchOptions>(
      Object.assign(new CarSearchOptions(), {
        destinationSameAsOrigin: previousSearch.returnAddressSameAsPickupAddress,
      }),
      new Travel<Address>(
        this.createMemberSocietyArrayFromIDs(previousSearch.userIds),
        new WhenSearch(
          this.createDateTimeSearchFromPreviousSearch(previousSearch.pickupDate, previousSearch.pickupTime),
          this.createDateTimeSearchFromPreviousSearch(previousSearch.returnDate, previousSearch.returnTime),
        ),
        this.createLocalityFromJson(previousSearch.returnAddress),
        this.createLocalityFromJson(previousSearch.pickupAddress),
      ),
    );
  }

  createCriteriaFromBasketItem(
    lastFolderItemsInBasket: any[],
    members: MemberSociety[],
  ): SearchCriteria<Address, CarSearchOptions> {
    const basketItem = lastFolderItemsInBasket?.[0];
    if (!basketItem) {
      return undefined;
    }
    return new SearchCriteria<Address, CarSearchOptions>(
      new CarSearchOptions(),
      new Travel<Address>(
        basketItem?.travelers
          .map((traveler) => members.find((member) => member.user?._id === traveler.userId))
          .filter((member) => !!member),
        new WhenSearch(
          new DateTimeSearch(basketItem.detail.pickupDate.date),
          new DateTimeSearch(basketItem.detail.returnDate.date),
        ),
        this.createLocalityFromJson(basketItem.detail.pickupAddress),
        this.createLocalityFromJson(basketItem.detail.returnAddress),
      ),
    );
  }
}

export class CarSearchOptions implements SearchOptions {
  destinationSameAsOrigin: boolean;

  isValid(): boolean {
    return true;
  }
}
