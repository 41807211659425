import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { CommonService } from "src/app/@shared/services/common.service";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";

@Component({
  selector: "spt-basket-transfer-block",
  templateUrl: "./transfer-block.component.html",
  styleUrls: ["./transfer-block.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TransferBlockComponent {
  private _item: any;
  @Input() set item(value: any) {
    this._item = value;
    this.itemUpdated();
  }
  get item() {
    return this._item;
  }
  public locale: string;
  public hasDifferentAddress: boolean;

  constructor(
    public commonService: CommonService,
    private translateService: TranslateService,
  ) {}

  itemUpdated(): void {
    this.locale = this.translateService.currentLang;
  }
}
