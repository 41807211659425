<section class="details-header">
  <img class="icon" src="./assets/svg/train-full-colored.svg" alt="" />
  <div class="journey">
    <div class="origin">
      <div class="name">
        {{ direction === "Outward" || searchType === "Exchange" ? origin : destination }}
      </div>
    </div>
    <i class="arrow spt-icon-arrow-circle-right"></i>
    <div class="destination">
      <div class="name">
        {{ direction === "Outward" || searchType === "Exchange" ? destination : origin }}
      </div>
    </div>
  </div>
  <div class="way">
    {{ "GLOBAL." + direction | uppercase | translate }}
  </div>
  <div class="travel-class">
    <i class="spt-icon-tag m-r-5"></i>
    {{ "SEARCH.RESULT.RAIL.FLEXIBILITY." + flexibility | uppercase | translate }}
  </div>
  <div class="arrow-back" (mousedown)="close()" *ngIf="isSmallDevice">
    <span class="spt-icon-back-arrow close"></span>
  </div>
</section>

<section class="journey">
  <div class="date">
    <span class="day">{{ dateTime | date: "dd" }}</span>
    <span class="month">{{ dateTime | localizedDate: "MMM" }}</span>
  </div>
  <div class="segments">
    <spt-travel-train-segments
      class="detailed"
      [detailed]="true"
      [segments]="offersSelection.journey.segments"
      [isSmallDevice]="isSmallDevice"
      [offerCarbonOffset]="carbonOffset"
    ></spt-travel-train-segments>
  </div>

  <div class="operators" *ngIf="operators.length">
    <p class="operated-by" translate>SEARCH.RESULT.RAIL.OPERATED_BY</p>
    <div>
      <span *ngFor="let operator of operators; let isLast = last">
        {{ operator }}<span *ngIf="offersSelection.journey.segments.length > 1 && !isLast"> - </span>
      </span>
    </div>
  </div>

  <div class="passengers" *ngIf="passengers.length > 0">
    <p class="travelers" translate>GLOBAL.TRAVELERS</p>
    <ng-container *ngFor="let passenger of passengers">
      <spt-avatar [user]="passenger" [displayTravelerType]="true" title="{{ passenger.email }}"></spt-avatar>
      <div
        class="discounts"
        *ngIf="passenger.discounts?.length && offersSelection.offers[selectedOfferIndex].discounts?.length"
      >
        <span class="discount" *ngFor="let appliedDiscount of passenger.discounts">
          <ng-container *ngIf="appliedDiscount.name && appliedDiscount.type === 'RAIL_CARD'">
            {{ appliedDiscount.name }}
            {{ "SEARCH.RESULT.RAIL.DISCOUNT.APPLIED" | translate }}
          </ng-container>
          <ng-container *ngIf="appliedDiscount.type === 'CORPORATE_DISCOUNT' && hasCorporateDiscount">
            {{ "SEARCH.RESULT.RAIL.DISCOUNT.PROGRAM.CORPORATE_DISCOUNT" | translate }}
            {{ "SEARCH.RESULT.RAIL.DISCOUNT.APPLIED" | translate }}
          </ng-container>
        </span>
      </div>
    </ng-container>
  </div>

  <div class="price" *ngIf="selectedPrice">
    <p class="total" translate>SEARCH.RESULT.TOTAL</p>
    <p class="amount">
      {{ selectedPrice | SPTPrice | async }}
    </p>
    <p
      class="full-amount"
      *ngIf="selectedFullPrice && selectedFullPrice.amount > 0 && selectedFullPrice.amount !== selectedPrice.amount"
    >
      {{ selectedFullPrice | SPTPrice | async }}
    </p>
    <p class="groupsave-discount" *ngIf="hasGroupSave">
      {{ "SEARCH.RESULT.RAIL.DISCOUNT.GROUP_SAVE" | translate }}
      {{ "SEARCH.RESULT.RAIL.DISCOUNT.APPLIED" | translate }}<br />
      <span [innerHTML]="'SEARCH.RESULT.RAIL.TRAVEL_GROUP' | translate"></span>
    </p>
  </div>
</section>

<section class="warning-container" *ngIf="childrenPassengers?.length && user">
  <p class="children-warning">
    {{ "SEARCH.RESULT.RAIL.PASSENGERS.WARNING" | translate }}
  </p>
  <ul>
    <li *ngFor="let childPassenger of childrenPassengers">
      <span class="passenger">{{ childPassenger.firstname }} {{ childPassenger.lastname }}</span>
      <a
        [routerLink]="user.email === childPassenger.email ? ['/account'] : ['/management', 'members']"
        [queryParams]="user.email === childPassenger.email ? undefined : { email: childPassenger.email }"
      >
        {{ "GLOBAL.MODIFY" | translate }}
      </a>
    </li>
  </ul>
</section>

<ng-container *ngFor="let offer of offersSelection.offers; let offerIndex = index">
  <section
    class="fares"
    *ngIf="
      (offerIndex === selectedOfferIndex &&
        offersSelection.offers[selectedOfferIndex].fares[selectedFareIndex].fareSegments[0].comfortClass) ||
      !offersSelection.offers[selectedOfferIndex].fares[selectedFareIndex].fareSegments[0].comfortClass
    "
  >
    <div
      class="fare"
      *ngFor="let fare of offer.fares; let fareIndex = index; trackBy: trackByName"
      [class.return-fare]="!fare.fareName"
      [class.has-choice]="
        offersSelection.offers.length > 1 &&
        !offersSelection.offers[selectedOfferIndex].fares[selectedFareIndex].fareSegments[0].comfortClass
      "
      [class.selected]="selectedFareIndex === fareIndex"
    >
      <div
        class="choice"
        *ngIf="
          offersSelection.offers?.length > 1 &&
          fareIndex === 0 &&
          !offersSelection.offers[selectedOfferIndex].fares[selectedFareIndex].fareSegments[0].comfortClass
        "
      >
        <p-radioButton
          [(ngModel)]="selectedOfferIndex"
          (ngModelChange)="selectPrices($event)"
          [value]="offerIndex"
        ></p-radioButton>
      </div>
      <p class="title" *ngIf="fare.fareName; else noFareType">{{ fare.fareName }} x {{ fare.passengers.length }}</p>
      <div class="price" *ngIf="fare.price">
        {{ { amount: fare.price.amount * fare.passengers.length, currency: fare.price.currency } | SPTPrice | async }}
      </div>
      <div class="see-conditions" (mousedown)="selectFare(fare)">
        {{ "SEARCH.RESULT.RAIL.SEE_CONDITIONS" | translate }}
      </div>
      <ng-template #noFareType>
        <p class="condition">
          {{ "SEARCH.RESULT.RAIL.SEE_PREVIOUS_CONDITIONS" | translate }}
        </p>
      </ng-template>
      <ng-container *ngIf="selectedOfferIndex === offerIndex">
        <ul
          class="condition-summary-container"
          *ngIf="
            (fare.conditionsSummary && fare.conditionsSummary !== fare.conditions[0].description) ||
            fare.routeRestriction ||
            fare.permittedOrigins?.length ||
            fare.permittedDestinations?.length
          "
        >
          <li
            class="condition-summary"
            *ngIf="fare.conditionsSummary && fare.conditionsSummary !== fare.conditions[0].description"
          >
            {{ fare.conditionsSummary }}
          </li>
          <li class="condition-summary" *ngIf="fare.routeRestriction">
            {{ fare.routeRestriction }}
          </li>
          <li class="condition" *ngIf="fare.permittedOrigins?.length > 1 || fare.permittedDestinations?.length > 1">
            <span
              *ngIf="
                fare.permittedOrigins?.length > 1 &&
                (!fare.permittedDestinations || fare.permittedDestinations.length === 1)
              "
            >
              {{ "SEARCH.RESULT.RAIL.PERMITTED_ORIGINS" | translate: { origin } }}.
            </span>
            <span
              *ngIf="
                fare.permittedDestinations?.length > 1 && (!fare.permittedOrigins || fare.permittedOrigins.length === 1)
              "
            >
              {{ "SEARCH.RESULT.RAIL.PERMITTED_DESTINATIONS" | translate: { destination } }}.
            </span>
            <span *ngIf="fare.permittedOrigins?.length > 1 && fare.permittedDestinations?.length > 1">
              {{ "SEARCH.RESULT.RAIL.PERMITTED_STATIONS" | translate: { origin, destination } }}.
            </span>
            <br />
            <span
              *ngIf="fare.permittedOrigins?.length > 1 || fare.permittedDestinations?.length > 1"
              class="see-stations"
              translate
              (mouseover)="stationOp.show($event)"
              (mouseleave)="stationOp.hide()"
            >
              SEARCH.RESULT.RAIL.SEE_PERMITTED_STATIONS
            </span>
            <p-overlayPanel #stationOp [appendTo]="'body'" styleClass="p-10">
              <b translate>SEARCH.RESULT.RAIL.TRAVEL_FROM</b>
              <p *ngFor="let permittedOrigin of fare.permittedOrigins">
                {{ permittedOrigin }}
              </p>
              <b translate>SEARCH.RESULT.RAIL.TRAVEL_TO</b>
              <p *ngFor="let permittedDestination of fare.permittedDestinations">
                {{ permittedDestination }}
              </p>
            </p-overlayPanel>
          </li>
        </ul>
      </ng-container>
    </div>
  </section>
</ng-container>

<ng-container>
  <section class="offer" *ngFor="let offer of offersSelection.offers; let offerIndex = index">
    <section class="comfort-options" *ngIf="offer.fares[selectedFareIndex].fareSegments[0].comfortClass">
      <p class="title" *ngIf="offerIndex === 0">
        {{ "SEARCH.RESULT.RAIL.COMFORT_OPTIONS" | translate }}
      </p>
      <div
        class="comfort"
        [class.has-choice]="offersSelection.offers.length > 1"
        [class.has-price]="offerIndex > 0"
        *ngFor="let fare of offer.fares; let i = index"
      >
        <div class="choice" *ngIf="i === 0 && offersSelection.offers.length > 1">
          <p-radioButton
            [(ngModel)]="selectedOfferIndex"
            (ngModelChange)="getPrices()"
            [value]="offerIndex"
          ></p-radioButton>
        </div>
        <div class="fare-segments">
          <div class="fare-segment" *ngFor="let fareSegment of fare.fareSegments; let segmentIndex = index">
            <div class="segments">
              <span>{{ offersSelection.journey.segments[segmentIndex].departure.name }}</span>
              <i class="arrow spt-icon-arrow-circle-right"></i>
              <span>{{ offersSelection.journey.segments[segmentIndex].arrival.name }}</span>
            </div>
            <div class="options">
              <div class="class">
                <ng-container *ngIf="fareSegment.comfortClass">
                  <p class="name">
                    {{ fareSegment.comfortClass.class.name }}
                  </p>
                  <p class="description">
                    {{ "SEARCH.RESULT.RAIL.AVAILABLE_ON" | translate }}
                    {{ fareSegment.comfortClass.trains }}.
                    {{ fareSegment.comfortClass.class.description }}
                  </p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="price" *ngIf="offerIndex > 0">
          +
          {{
            {
              amount: offer.price.amount - offersSelection.offers[0].price.amount,
              currency: offer.price.currency
            }
              | SPTPrice
              | async
          }}
        </div>
      </div>
    </section>
  </section>
</ng-container>

<section class="details-actions">
  <p-button
    icon="spt-icon-valid-booking"
    class="select"
    type="button"
    (click)="selectOffer()"
    [label]="
      (direction === 'Outward' ? 'SEARCH.RESULT.RAIL.SELECTION.DEPARTURE' : 'SEARCH.RESULT.RAIL.SELECTION.RETURN')
        | translate
    "
    styleClass="w-full h-full action-button"
  ></p-button>
</section>

<spt-conditions-train-dialog
  *ngIf="selectedFare"
  [visible]="!!selectedFare"
  [conditions]="selectedFare.conditions"
  [conditionsSummary]="selectedFare.conditionsSummary"
  [routeRestriction]="selectedFare.routeRestriction"
  [name]="selectedFare.fareName"
  (closeConditions)="selectedFare = undefined"
></spt-conditions-train-dialog>
