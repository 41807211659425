import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class AuthorizationService {
  private baseUrl: string = `${environment.api}/authorizations`;

  constructor(private httpClient: HttpClient) {}

  public getUserManages(userId: string) {
    const endpoint: string = `${this.baseUrl}/manages?user=${userId}`;
    return this.httpClient.get(endpoint, { headers: { ignoreErrorMessage: "true" } }) as Observable<any[]>;
  }

  public getUserManagesMembers(userId: string) {
    const endpoint: string = `${this.baseUrl}/validation-manages?user=${userId}`;
    return this.httpClient.get(endpoint, { headers: { ignoreErrorMessage: "false" } }) as Observable<any[]>;
  }

  getPolicyHotel(accomcode: string, userIds: any[]) {
    const headers: HttpHeaders = new HttpHeaders({
      ignoreLoading: "true",
    });
    return this.httpClient.post(
      `${this.baseUrl}/policy-hotel/`,
      {
        accomcode,
        userIds,
      },
      { headers },
    ) as Observable<{
      isAuthorized: boolean;
      commentMandatory: boolean;
      errors: string[];
    }>;
  }
}

export interface CanValidateBasketResult {
  isAuthorized: boolean;
  commentMandatory: boolean;
  errors: string[];
  resultPoliciesByItem?: { [itemId: string]: ItemResultPolicies };
}
export interface ItemResultPolicies {
  inPolicy: true;
  authorizationsByPolicies: AuthorizationsByPolicy[];
}
export interface AuthorizationsByPolicy {
  userIds: string[];
  appliedPolicy: {
    priceMax: number;
    refundable?: boolean;
    changeable?: boolean;
    classMax?: string;
    ratingMin?: number;
    ratingMax?: number;
    categories?: string[];
    insurranceIsMandatory?: boolean;
    scoreMin?: number;
  };
  isClassOutOfPolicy: boolean;
  isPriceOutOfPolicy: boolean;
  isScoreOutOfPolicy?: boolean;
  isConditionsOutOfPolicy?: boolean;
  isDurationOutOfPolicy?: boolean;
  mandatoryInsurranceOutOfPolicy?: boolean;
}
