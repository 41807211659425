import { NgModule } from "@angular/core";
import { MessageModule } from "primeng/message";
import { SharedModule } from "../../@shared/shared.module";
import { ReservationsComponent } from "./reservations.component";
import { ReservationsBlockFooterComponent } from "./block-footer/block-footer.component";
import { ReservationsDetailComponent } from "./detail/detail.component";
import { ReservationsDetailTrainComponent } from "./detail/train/train.component";
import { ReservationsCancelComponent } from "./detail/cancel/cancel.component";
import { ReservationsCancelTrainComponent } from "./detail/cancel/train/train.component";
import { ReservationsModifyComponent } from "./detail/modify/modify.component";
import { ReservationsModifyTrainComponent } from "./detail/modify/train/train.component";
import { BlockModule } from "../@dashboard-shared/components/block/block.module";
import { HotelDetailComponent } from "./ticket-detail/hotel-detail/hotel-detail.component";
import { RentalDetailComponent } from "./ticket-detail/car-detail/car-detail.component";
import { FlightDetailComponent } from "./ticket-detail/flight-detail/flight-detail.component";
import { ReservationsDetailTransferComponent } from "./detail/transfer/transfer.component";
import { ReservationsCancelTransferComponent } from "./detail/cancel/transfer/transfer.component";
import { SidebarTravelModule } from "../@dashboard-shared/components/sidebar-travel/sidebar-travel.module";
import { ReservationSidebarFooterComponent } from "./sidebar-footer/sidebar-footer.component";
import { ReservationsCancelFlightComponent } from "./detail/cancel/flight/flight.component";
import { ReservationsModifyFlightComponent } from "./detail/modify/flight/flight.component";

@NgModule({
  imports: [SharedModule, BlockModule, MessageModule, SidebarTravelModule],
  declarations: [
    ReservationsComponent,
    ReservationsBlockFooterComponent,
    ReservationsDetailComponent,
    ReservationsDetailTrainComponent,
    ReservationsDetailTransferComponent,
    HotelDetailComponent,
    RentalDetailComponent,
    FlightDetailComponent,
    ReservationsCancelComponent,
    ReservationsCancelTrainComponent,
    ReservationsCancelFlightComponent,
    ReservationsCancelTransferComponent,
    ReservationsModifyComponent,
    ReservationsModifyTrainComponent,
    ReservationsModifyFlightComponent,
    ReservationSidebarFooterComponent,
  ],
  exports: [
    ReservationsDetailComponent,
    ReservationsDetailTrainComponent,
    HotelDetailComponent,
    RentalDetailComponent,
    FlightDetailComponent,
    ReservationsCancelComponent,
    ReservationsCancelTrainComponent,
    ReservationsModifyComponent,
    ReservationsModifyTrainComponent,
    ReservationsModifyFlightComponent,
  ],
})
export class ReservationsModule {}
