import { Injectable, NgZone, Type } from "@angular/core";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { first } from "rxjs/operators";
import { CommonService } from "./common.service";

@Injectable()
export class ModalService {
  private dialogBaseConfig: DynamicDialogConfig = new DynamicDialogConfig();
  constructor(
    private dialogService: DialogService,
    private zone: NgZone,
    private commonService: CommonService,
  ) {}

  openModal(componentType: Type<any>, config?: DynamicDialogConfig): DynamicDialogRef {
    return this.zone.run(() => {
      this.commonService.disableAppScroll();
      this.commonService.disableIntercom();

      if (!config) {
        config = this.dialogBaseConfig;
      }
      // FIXME: Propriété issue de PrimeNG 8.1.5, à migrer vers la version actuelle de PrimeNG
      //config.disabledAnimation = this.commonService.isTablet;

      const ref: any = this.dialogService.open(componentType, config);

      const echapListener: (event: KeyboardEvent) => void = (event: KeyboardEvent): void => {
        if (event.code === "27") {
          this.commonService.enableAppScroll();
          ref.close();
        }
      };

      document.addEventListener("keydown", echapListener);

      this.commonService.setBackFunction(
        () => {
          ref.close();
        },
        this,
        componentType,
      );

      ref.onClose.pipe(first()).subscribe(() => {
        this.commonService.unsetBackFunction();
        this.commonService.enableAppScroll();
        this.commonService.enableIntercom();
        document.removeEventListener("keydown", echapListener);
      });

      return ref;
    });
  }
}
