import { Injectable } from "@angular/core";

import { filter, first } from "rxjs/operators";
import { SocietyService } from "../services/society.service";
import { Society } from "../@types/society";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SocietyResolver {
  constructor(private societyService: SocietyService) {}

  resolve(): Observable<Society> {
    return this.societyService.society.pipe(
      filter((society: Society) => !!society),
      first(),
    );
  }
}
