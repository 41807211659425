import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MemberSociety } from "src/app/@shared/@types/society";
import { User } from "src/app/@shared/@types/user";
import { CommonService } from "src/app/@shared/services/common.service";
import { RoleService } from "src/app/@shared/services/roles.service";
import { SocietyService } from "src/app/@shared/services/society.service";
import { UserService } from "src/app/@shared/services/user.service";
@Component({
  selector: "spt-sidebar-travel",
  templateUrl: "./sidebar-travel.component.html",
  styleUrls: ["./sidebar-travel.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarTravelComponent implements OnInit {
  @Input() item: any;
  @Input() isSmallDevice: boolean;
  @Output() toggleSidebarEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Input() opened: boolean = true;
  @Input() styleClass: string;
  @Input() showCloseIcon = true;

  @Output() onDataHotelUpdated: EventEmitter<DataHotel> = new EventEmitter<DataHotel>();
  dataHotel: DataHotel;
  public users: User[] = [];
  public customerService: string;
  public booker: { user: User; date: string };
  public validator: { user: User | { firstname: string; lastname: string }; date: string };
  public locale: string;
  public reference: string;
  public showAllTravelers: boolean = false;
  public isUKTravel: boolean = false;
  public showPriceBreakdown: boolean = true;
  protected pnr: string;
  protected user: User;

  constructor(
    public commonService: CommonService,
    private societyService: SocietyService,
    private translateService: TranslateService,
    private userService: UserService,
  ) {}
  ngOnInit(): void {
    this.user = this.userService.user.value;
    this.commonService.statusBarTextBlack();
    this.customerService = this.translateService.instant("GLOBAL.CUSTOMER.SERVICE");
    const statusHistory: any = this.item.statusHistory?.find((status: any): boolean => status.to === "confirmed");
    this.locale = this.translateService.currentLang;
    this.users = this.item.travelers || this.item.users;

    if (!this.item.travelers) {
      this.item.travelers = this.item.users;
    }

    const booker: MemberSociety = this.societyService.society.value.members.find(
      (_member: MemberSociety): boolean => _member.user._id === this.item.userId,
    );
    if (booker) {
      this.booker = { user: booker.user, date: this.item.createdAt };
    }
    if (statusHistory) {
      let memberValidator: MemberSociety = this.societyService.society.value.members.find(
        (_member: MemberSociety): boolean => {
          return _member.user._id === statusHistory.userId;
        },
      );
      if (!memberValidator) {
        this.validator = {
          user: { firstname: statusHistory.firstname, lastname: statusHistory.lastname },
          date: statusHistory.date,
        };
      } else if (memberValidator) {
        this.validator = {
          user: memberValidator.user,
          date: statusHistory.date,
        };
      }
    }

    if (this.item.type === "flight") {
      this.reference = this.item.detail.supplierRef;
      this.pnr = this.item.detail.pnrId;
    } else if (this.item.type === "car") {
      this.reference = this.item.detail.confirmationNumber;
      this.pnr = this.item.detail.pnrId;
    } else if (this.item.type === "hotel") {
      this.reference = this.item.detail.supplierbookingref;
      this.pnr = this.item.detail.bookingref;
    } else if (this.item.type === "train") {
      this.reference = this.item.detail?.orderItems.map((o) => o.carrierReference).join(",");
      this.pnr = this.item.detail?.orderReference;
    } else if (this.item.type === "transfer") {
      this.reference = this.item.detail.booking.general.bookingreference;
    }
  }

  closeSidebar() {
    this.opened = false;
    this.toggleSidebarEmitter.emit(this.opened);
  }
  toggleAllTravelers(): void {
    this.showAllTravelers = !this.showAllTravelers;
  }

  updateDataHotel(dataHotel: DataHotel): void {
    this.dataHotel = dataHotel;
    this.onDataHotelUpdated.emit(dataHotel);
  }
}

export interface DataHotel {
  contractRemarks: string[];
  carbonOffset: any;
  cancellationPolicy: any[];
  datein: any;
}
