<div class="out-policy" *ngIf="isOutOfPolicy">
  <span translate>GLOBAL.OUT_OF_POLICY</span>
  <i class="fas fa-info-circle m-l-5" *ngIf="displayInfo"></i>
</div>
<div class="authorizations" [class.visible]="isOutOfPolicy && displayInfo" [class.top]="position === 'top'">
  <div class="authorization" *ngIf="groupedAuthorizationsByPolicy?.length > 0">
    <ng-container *ngFor="let authorization of groupedAuthorizationsByPolicy; let authorizationIdx = index">
      <div
        class="authorization-content"
        [class.multi]="authorization.userIds.length > 1"
        *ngIf="authorization.hasInfo || isPriceOutOfPolicy"
      >
        <ng-container *ngIf="authorization.userIds.length > 0">
          <strong>
            <ng-container *ngFor="let user of authorization.resolvedUsers; let userIndex = index">
              {{ user?.lastname }} {{ user?.firstname || ("GLOBAL.PASSENGER" | translate) + (authorizationIdx + 1)
              }}<ng-container *ngIf="userIndex < authorization.resolvedUsers.length - 1">,</ng-container>
            </ng-container>
          </strong>
        </ng-container>
        <div
          *ngIf="authorization.appliedPolicy.priceMax"
          class="priceMax"
          [class.invalid]="authorization.isPriceOutOfPolicy || isPriceOutOfPolicy"
        >
          <span class="label" translate>POLICY.TOOLTIP.PRICE</span>
          <span class="value">{{ authorization.appliedPolicy.priceMax }}</span>
        </div>
        <div
          *ngIf="authorization.appliedPolicy.refundable || authorization.appliedPolicy.changeable"
          class="conditions"
          [class.invalid]="authorization.isConditionsOutOfPolicy"
        >
          <span class="label" translate>POLICY.TOOLTIP.CONDITIONS</span>
          <span class="value">
            <ng-container *ngIf="authorization.appliedPolicy.refundable && !authorization.appliedPolicy.changeable">
              <span translate> POLICY.TOOLTIP.AT_LEAST_REFUNDABLE </span>
            </ng-container>
            <ng-container *ngIf="authorization.appliedPolicy.refundable && authorization.appliedPolicy.changeable">
              <span translate> POLICY.TOOLTIP.AT_LEAST_REFUNDABLE_AND_CHANGEABLE </span>
            </ng-container>
            <ng-container *ngIf="!authorization.appliedPolicy.refundable && authorization.appliedPolicy.changeable">
              <span translate> POLICY.TOOLTIP.AT_LEAST_CHANGEABLE </span>
            </ng-container>
          </span>
        </div>
        <div
          *ngIf="
            authorization.appliedPolicy.categories ||
            authorization.appliedPolicy.classMax ||
            (authorization.appliedPolicy.ratingMin && authorization.appliedPolicy.ratingMin > 0) ||
            (authorization.appliedPolicy.ratingMax && authorization.appliedPolicy.ratingMax > 0)
          "
          class="travel-class"
          [class.invalid]="authorization.isClassOutOfPolicy"
        >
          <span class="label" *ngIf="authorization.appliedPolicy.classMax">{{
            "POLICY.TOOLTIP.TRAVEL_CLASS.TRAIN.CLASS_MAX" | translate
          }}</span>
          <span class="label" *ngIf="this.type === 'hotel'">{{
            "POLICY.TOOLTIP.TRAVEL_CLASS.HOTEL.CLASS_MAX" | translate
          }}</span>
          <span class="value" [innerHTML]="isClassOutOfPolicyTexts[authorizationIdx]"></span>
        </div>

        <div
          *ngIf="authorization.appliedPolicy.scoreMin"
          class="score"
          [class.invalid]="authorization.isScoreOutOfPolicy"
        >
          <span class="label" translate>POLICY.TOOLTIP.SCORE_MIN</span>
          <span class="value">{{ authorization.appliedPolicy.scoreMin }}</span>
        </div>

        <div
          *ngIf="authorization.appliedPolicy.insurranceIsMandatory"
          class="insurranceIsMandatory"
          [class.invalid]="authorization.mandatoryInsurranceOutOfPolicy"
        >
          <span class="value" translate>POLICY.TOOLTIP.INSURRANCE_MANDATORY</span>
        </div>

        <div
          *ngIf="authorization.isDurationOutOfPolicy"
          class="duration"
          [class.invalid]="authorization.isDurationOutOfPolicy"
        >
          <span class="value" translate>POLICY.TOOLTIP.RSE_LIMITATION</span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
