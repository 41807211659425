<div class="dot"></div>
<div class="hotel-info">
  <div class="hotel-title f-sb">{{ item.detail.hotelTitle }}</div>
  <div class="hotel-address">{{ item.detail.hotelAddress }}</div>
</div>
<div class="date">
  <div class="from">
    <i class="spt-icon-date calendar-icon m-r-5"></i>
    <span>{{ item.detail.datein.date | localizedDate: "d MMM" | titlecase }}</span>
  </div>
  <i class="spt-icon-arrow-right m-l-10 m-r-10 separator"></i>
  <div class="until">
    <i class="spt-icon-date calendar-icon m-r-5"></i>
    <span>{{ item.detail.dateout.date | localizedDate: "d MMM" | titlecase }}</span>
  </div>
</div>
