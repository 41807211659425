<ng-container *ngIf="noRefundOptions === false; else noOptions">
  <form (ngSubmit)="validateCancel()" [formGroup]="cancelJourneyForm" *ngIf="cancelJourneyForm">
    <div class="select-passengers-container" *ngIf="userItems.length">
      <p-multiSelect
        [options]="userItems"
        [defaultLabel]="'GLOBAL.TRAVELERS' | translate"
        [formControl]="cancelJourneyForm.controls.passengersToRemove"
        [maxSelectedLabels]="null"
      >
        <ng-template let-items pTemplate="selectedItems">
          <p *ngIf="cancelJourneyForm.value.passengersToRemove.length === 0">{{ "GLOBAL.TRAVELERS" | translate }}</p>
          <spt-avatar
            *ngIf="cancelJourneyForm.value.passengersToRemove.length === 1"
            [user]="{
              username:
                cancelJourneyForm.value.passengersToRemove[0].firstname +
                ' ' +
                cancelJourneyForm.value.passengersToRemove[0].lastname
            }"
            [displayTooltip]="true"
          ></spt-avatar>
          <p *ngIf="items?.length > 1">{{ items.length }} {{ "BOOKING.SELECTED_TRAVELERS" | translate }}</p>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div class="traveler-container">
            <spt-avatar [user]="item.value"></spt-avatar>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>

    <div class="select-travel-container">
      <ng-container *ngIf="cancelJourneyForm.controls.journeysToRemove.enabled">
        <div class="travel-info-container" *ngFor="let journey of journeys; let journeyIndex = index">
          <span class="checkbox-button">
            <p-checkbox
              [inputId]="'journey-' + journeyIndex"
              class="checkbox"
              [formControl]="cancelJourneyForm.controls.journeysToRemove"
              [value]="journey"
            ></p-checkbox>
          </span>
          <div class="travel-destination">
            <label [for]="'journey-' + journeyIndex" class="travel-from-to">
              <span class="travel-text">
                {{ journey.departure.name }}
              </span>
              <i class="spt-icon-arrow-circle-right"></i>
              <span class="travel-text">
                {{ journey.arrival.name }}
              </span>
            </label>
          </div>
        </div>
      </ng-container>
    </div>
  </form>

  <div *ngIf="selectedRefundOptions.length === 0" class="select-one">
    <p translate>RESERVATIONS.TRAIN.CANCEL.SELECT_ONE_TRAVELER_OR_ROUTE</p>
  </div>
  <div *ngIf="displayedReservationFees.amount > 0">
    <p
      class="reservation-fees"
      translate
      [translateParams]="{
        fees: (displayedReservationFees | SPTPrice | async)
      }"
    >
      RESERVATIONS.TRAIN.CANCEL.RESERVATION_FEE
    </p>
  </div>
  <div *ngIf="voucherRefund">
    <p class="voucher-refund" translate>RESERVATIONS.TRAIN.CANCEL.VOUCHER_REFUND</p>
  </div>
  <div class="my-5" *ngIf="partial === false">
    {{ "RESERVATIONS.TRAIN.CANCEL.NO_PARTIAL" | translate }}
  </div>
  <div
    class="my-5"
    *ngIf="cancelJourneyForm.value.passengersToRemove.length > 0 && cancelJourneyForm.value.journeysToRemove.length > 0"
  >
    <p-message severity="warn" [text]="'RESERVATIONS.TRAIN.CANCEL.UNAVAILABLE_COMBINATION' | translate"></p-message>
  </div>

  <p-message *ngFor="let message of messages" [severity]="message.severity" [text]="message.detail"></p-message>
</ng-container>

<ng-template #noOptions>
  <p class="text-center">
    <p-message severity="warn" [text]="'RESERVATIONS.TRAIN.NO_REFUNDABLES' | translate"></p-message>
  </p>
</ng-template>
