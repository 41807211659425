import ColorHash from "color-hash";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { FlightService } from "../flight.service";

@Component({
  selector: "spt-afkl-bagages",
  styleUrls: ["./afkl-bagages.component.scss"],
  templateUrl: "./afkl-bagages.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class AFKLBagagesComponent implements OnInit {
  journey: string = "Paris - Londres";
  flightNumber: string = "1234";
  cabinClass: string = "Economy";
  airports: any[];
  offers: any[] = [];
  pricedOffer: any;
  item: any;
  public selectedOffer: any;

  constructor(
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private flightService: FlightService,
  ) {
    const bagTExt = "BAG";
    const regex = new RegExp("\\b" + bagTExt + "\\b", "i");
    const data: any = this.config.data.serviceList;
    this.item = this.config.data.item;
    this.airports = Object.values(this.item.detail.airports);

    data["ALaCarteOffer"].ALaCarteOfferItem.filter((item: any) =>
      item.Service.ServiceDefinitionRefID.Name.match(regex),
    ).forEach((offerItem) => this.offers.push(offerItem));
  }

  selectLuggage(event: any) {
    const offer: any = event.value;

    const data = {
      serviceId: offer.Service.ServiceDefinitionRefID.ServiceDefinitionID,
      OfferItemRefID: offer.OfferItemID,
      price: offer.UnitPrice.TotalAmount["$t"],
      service: offer.Service.ServiceDefinitionRefID,
      PaxRefID: offer.Eligibility?.PaxRefID,
      quantity: 1,
    };
    this.selectedOffer = data;
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }

  selectService(): void {
    this.flightService
      .setAncillaries(this.item.formData.orderID, this.item.formData.searchId, [this.selectedOffer], "luggage")
      .subscribe((response: any) => {
        this.dialogRef.close(response.data);
      });
  }
}
