import { BehaviorSubject, Observable } from "rxjs";
import { filter, map, tap } from "rxjs/operators";

import { HttpClient, HttpContext, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";
import { Label, UserLabel } from "../@types/label";
import { NGX_LOADING_BAR_IGNORED } from "@ngx-loading-bar/http-client";

@Injectable({
  providedIn: "root",
})
export class LabelService {
  private endpoint: string = `${environment.api}/api/label`;
  public labels: BehaviorSubject<Array<Label>> = new BehaviorSubject([]);
  public userLabels: BehaviorSubject<UserLabel> = new BehaviorSubject(null);
  private _labels: Array<Label> = [];

  constructor(private httpClient: HttpClient) {}

  public getLabels(): Observable<Array<Label>> {
    return this.labels.asObservable();
  }

  public getUserLabels(type: string): Observable<UserLabel> {
    return this.userLabels.pipe(filter((userLabel: UserLabel) => (userLabel ? userLabel.type === type : false)));
  }

  public getLabelsBySocietyId(societyId: string): Observable<Array<Label>> {
    return this.httpClient
      .get(`${this.endpoint}/${societyId}`, {
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
        headers: {
          ignoreLoading: "true",
          ignoreErrorMessage: "true",
        },
      })
      .pipe(
        tap((result: any) => {
          if (result.data) {
            result.data.forEach((d) => {
              if (d.defaults) {
                d.defaults = d.defaults.sort((a, b) => a.label.localeCompare(b.label));
              }
            });
          }
          this._labels = result.data;
          this.labels.next(this._labels);
        }),
        map((result: any) => result.data),
      );
  }

  public saveLabel(label: Label): Observable<any> {
    return this.httpClient.post(this.endpoint, label).pipe(
      tap((result: any) => {
        this._labels.push(result.data);
        this.labels.next(this._labels);
      }),
      map((result: any) => result.data),
    );
  }

  public saveUserLabels(label: UserLabel): void {
    this.userLabels.next(label);
  }

  public updateLabel(labelId: string, newLabelValue: Label): Observable<any> {
    return this.httpClient.put(`${this.endpoint}/${labelId}`, newLabelValue).pipe(map((result: any) => result.data));
  }

  public updateUserLabel(label: UserLabel): void {
    this.userLabels.next(label);
  }

  public update(item: any, labels: any[]): Observable<any> {
    const endpoint: string = `${environment.api}/items/${item.id}/labels`;
    return this.httpClient.patch(endpoint, { labels });
  }

  public updateComment(item: any, comment: string): Observable<any> {
    const endpoint: string = `${environment.api}/items/${item.id}/comment`;
    return this.httpClient.patch(endpoint, { comment });
  }

  public resetUserLabel(): void {
    this.userLabels.next(null);
  }

  public deactivateLabel(labelId: string): Observable<any> {
    return this.httpClient.put(`${this.endpoint}/${labelId}`, {
      isActive: false,
    });
  }

  public activateLabel(labelId: string): Observable<any> {
    return this.httpClient.put(`${this.endpoint}/${labelId}`, {
      isActive: true,
    });
  }

  public getProjectManager(labelText: string) {
    return this.httpClient.get(`${environment.api}/erp/${labelText}/manager`, {
      headers: { ignoreErrorMessage: "true" },
    });
  }
}
