<div class="conditions-container" *ngIf="passengerConditionArray.length > 0">
  <p class="title">{{ "GLOBAL.CONDITIONS" | translate }}</p>
  <ng-container *ngFor="let passengerCondition of passengerConditionArray; let idx = index">
    <p class="traveler">
      {{ passengerCondition.passenger.firstname | titlecase }}
      {{ passengerCondition.passenger.lastname | titlecase }}
    </p>
    <div class="conditions-content">
      <ul>
        <li>
          {{ passengerCondition.conditions[0].refundable | translate }}
        </li>
        <li>
          {{ passengerCondition.conditions[0].changeable | translate }}
        </li>
      </ul>
      <div class="conditions-content-detail">
        <div *ngFor="let condition of passengerCondition.conditions">
          <p class="fareDetail">
            <strong class="fareName">{{ condition.fare.fareName }}</strong
            ><span class="farePrice">{{ condition.fare.price | SPTPrice | async }}</span>
          </p>
          <p class="see-conditions" (mousedown)="toggleFare(condition.fare)">
            {{ "SEARCH.RESULT.RAIL.SEE_CONDITIONS" | translate }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<spt-conditions-train-dialog
  *ngIf="selectedFare"
  [visible]="!!selectedFare"
  [conditions]="selectedFare.conditions"
  [conditionsSummary]="selectedFare.conditionsSummary"
  [routeRestriction]="selectedFare.routeRestriction"
  [name]="selectedFare.fareName"
  (closeConditions)="selectedFare = undefined"
></spt-conditions-train-dialog>
