import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { CommonService } from "../../../../@shared/services/common.service";

@Component({
  selector: "spt-etickets-modal",
  templateUrl: "./etickets-modal.component.html",
  styleUrls: ["./etickets-modal.component.scss"],
})
export class EticketsModalComponent implements OnInit {
  etickets: Array<any>;
  type: string;
  reference: string;
  travelers: Array<any>;

  constructor(
    private dynamicDialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    public commonService: CommonService,
  ) {}

  ngOnInit(): void {
    this.etickets = this.dynamicDialogConfig.data.etickets;
    this.type = this.dynamicDialogConfig.data.type;
    this.reference = this.dynamicDialogConfig.data.reference;
  }

  openEticket(index: number): void {
    if (this.etickets[index].data) {
      window.open(this.etickets[index].data, "_blank");
    } else {
      this.commonService.openLink(this.etickets[index]);
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
