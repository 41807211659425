<div class="position" *ngIf="!isTablet" [style.grid-column]="1 + '/ span 2'" [style.grid-row]="1 + '/ span 1 '">
  {{ "SEARCH.TRAIN.PLACING.AVANT" | translate }}
</div>
<div
  class="position"
  *ngIf="!isTablet"
  [style.grid-column]="lastColonne() - 1 + '/ span 2'"
  [style.grid-row]="1 + '/ span 1 '"
  [style.margin-right]="isFirstCoach ? '20px' : ''"
  [style.margin-left]="isFirstCoach ? '5px' : ''"
>
  {{ "SEARCH.TRAIN.PLACING.ARRIERE" | translate }}
</div>

<div
  class="element"
  *ngFor="let deckCell of deck.deckGrid.deckCells"
  [class]="deckCell.type | lowercase"
  [style.grid-column]="
    isTablet
      ? deckCell.columnRank + 1 + '/ span ' + deckCell.columnStretch
      : deckCell.rowRank + 1 + '/ span ' + deckCell.rowStretch
  "
  [style.grid-row]="
    isTablet
      ? deckCell.rowRank + 1 + '/ span ' + deckCell.rowStretch
      : deckCell.columnRank + 2 + '/ span ' + deckCell.columnStretch
  "
  (mousedown)="deckCell.seat?.available ? selectSeat(deckCell.seat.number) : undefined"
>
  <div
    class="seat"
    [class]="deckCell.seat.type + ' ' + deckCell.seat.type + ' ' + deckCell.orientationAngle | lowercase"
    [class.available]="deckCell.seat.available"
    [class.selected]="selectedSeats[deckCell.seat.number]"
    *ngIf="deckCell.seat"
  >
    <img
      class="icon"
      *ngIf="!deckCell.seat.available; else seatNumber"
      alt="Unavailable"
      src="../../../../../../assets/svg/seatmap/unavailable.svg"
    />
    <ng-template #seatNumber>
      {{ deckCell.seat.number }}
    </ng-template>
  </div>
  <img
    *ngIf="elementIcons[deckCell.type | lowercase]"
    [alt]="elementIcons[deckCell.type | lowercase]"
    class="icon"
    [src]="'../../../../../../assets/svg/seatmap/' + elementIcons[deckCell.type | lowercase] + '.svg'"
  />
</div>
