import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sum",
})
export class SumPipe implements PipeTransform {
  transform(arr: Array<any>, byAttr?: string): any {
    return arr.reduce((a: number, b: any) => {
      if (b.price && byAttr) {
        return a + b.price.amount * b[byAttr].length;
      } else if (b.price) {
        return a + b.price.amount;
      } else {
        return a;
      }
    }, 0);
  }
}
