import { Predicate } from "@angular/core";

export const groupBy: (array: Array<any>, key: string) => Array<Array<any>> = (
  array: Array<any>,
  key: string,
): Array<Array<any>> => {
  return array.reduce((prev: any, current: any) => {
    (prev[current[key]] = prev[current[key]] || []).push(current);
    return prev;
  }, {});
};

export function groupByMap<T>(array: T[], key: (T) => string): { [key: string]: T[] } {
  return array.reduce((prev: any, current: any) => {
    (prev[key(current)] = prev[key(current)] || []).push(current);
    return prev;
  }, {});
}

export function removeDuplicates<A>(items: A[], isEquals: (item1: A, item2: A) => boolean): A[] {
  return items.reduce((previousValue, currentValue) => {
    if (!previousValue.find((value) => isEquals(currentValue, value))) {
      previousValue.push(currentValue);
    }
    return previousValue;
  }, []);
}

export const enumToObject: (val: any) => any = (_enum: any): any => {
  const object: any = {};
  const keys: Array<string> = Object.keys(_enum);
  const values: Array<any> = Object.values(_enum);
  keys.forEach((key: string, i: number) => {
    object[key] = i;
  });
  return object;
};

export function not<T>(filter: Predicate<T>): Predicate<T> {
  return (element) => !filter(element);
}
