import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { UtilsTypes } from "../../@shared/@types/utils";
import { CommonService } from "../../@shared/services/common.service";

@Component({
  selector: "spt-travel-carbon-offset",
  templateUrl: "./carbon-offset.component.html",
  styleUrls: ["./carbon-offset.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TravelCarbonOffsetComponent {
  @Input() carbonOffset: { price: number | UtilsTypes.Price; amount: number };
  @Input() rseStatus: "optional" | "mandatory";

  @Input() isDetailedCarbonLabel: boolean = true;
  @Input() locale: string = "fr";
  @Input() currency: UtilsTypes.Currency = "EUR";

  constructor(public commonService: CommonService) {}
}
