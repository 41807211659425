<div *ngIf="user" class="user align-items-center grid-nogutter">
  <ng-container>
    <div class="user-picture" [pTooltip]="displayTooltip ? user.email : ''" [ngStyle]="{ 'background-color': color }">
      <img *ngIf="avatarPath; else tagname" [src]="avatarPath" [alt]="user.username" />
      <ng-template #tagname>
        <span>{{ tagName }}</span>
      </ng-template>
    </div>
  </ng-container>
  <div
    class="additionalData"
    *ngIf="
      displayUsername ||
      extraInfo?.length ||
      displayTravelerType ||
      (validationCircuitDelegation?.from && validationCircuitDelegation?.till) ||
      (validationCircuitAuth !== false && validationCircuitAuth?.length)
    "
  >
    <span
      *ngIf="displayUsername"
      [pTooltip]="user.firstname + ' ' + user.lastname"
      [tooltipDisabled]="(user.firstname + ' ' + user.lastname).length < 25"
      class="username"
      tabindex="0"
      [tooltipEvent]="(commonService.isTabletObservable | async) === true ? 'focus' : 'hover'"
      [tooltipPosition]="(commonService.isTabletObservable | async) === true ? 'top' : 'right'"
      style="outline: 0"
      >{{ user.firstname && user.lastname ? user.firstname + " " + user.lastname : user.username }}</span
    >
    <span *ngIf="extraInfo?.length" class="extraInfo">{{
      "BOOKING.VALIDATION.VALIDATED_BY"
        | translate
          : {
              date: extraInfo | date: "dd/MM/yyyy",
              time: extraInfo | date: "HH'h'mm"
            }
    }}</span>
    <span *ngIf="displayTravelerType" class="traveler-type"
      >({{ "PASSENGERS.TYPE." + passengerType | translate }})</span
    >

    <!-- Circuit de validation -->

    <!-- Authorization -->
    <span
      *ngIf="validationCircuitAuth !== false && validationCircuitAuth?.length"
      class="validationCircuitAuthContainer"
    >
      <div *ngFor="let auth of validationCircuitAuth" class="validationCircuitAuthInfo">
        <ng-container *ngIf="auth">
          <div
            class="authColor"
            [class.VinPolicy]="auth === 'VALIDATE_IN_POLICY'"
            [class.VoutPolicy]="auth === 'VALIDATE_OUT_POLICY'"
            [class.BinPolicy]="auth === 'BOOK'"
            [class.BoutPolicy]="auth === 'BOOK_OUT_OF_POLICY'"
          ></div>
          <p>{{ "AVATAR.DISPLAY.VALIDATION_CIRCUIT." + auth | translate }}</p>
        </ng-container>
      </div>
    </span>

    <!-- Delegated user -->
    <span
      *ngIf="validationCircuitDelegation?.from && validationCircuitDelegation?.till"
      class="validationCircuitDelegation"
      >{{
        "AVATAR.DISPLAY.VALIDATION_CIRCUIT.DELEGATION"
          | translate
            : {
                from: validationCircuitDelegation.from | date: "dd/MM/yyyy",
                till: validationCircuitDelegation.till | date: "dd/MM/yyyy"
              }
      }}</span
    >
  </div>
</div>
