import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({
  name: "time",
})
export class TimePipe implements PipeTransform {
  constructor() {}

  transform(
    time: number,
    unit: "milliseconds" | "seconds" | "minutes" | "hours" | "days" = "minutes",
    format: "legacy" | "auto" = "legacy",
  ): string {
    const duration: moment.Duration = moment.duration(time, unit);
    if (format === "legacy") {
      return `${duration.hours() + duration.days() * 24}h${duration.minutes().toString().padStart(2, "0")}`;
    }
    let str = [];
    if (duration.days() > 0) {
      str.push(`${duration.days()}j`);
    }
    if (duration.hours() > 0) {
      str.push(`${duration.hours()}h`);
    }
    if (duration.minutes() > 0 && duration.days() < 1) {
      str.push(`${duration.minutes()}min`);
    }
    if (duration.seconds() > 0 && duration.days() < 1 && duration.hours() < 1) {
      str.push(`${duration.seconds()}s`);
    }
    return str.join(" ");
  }
}
