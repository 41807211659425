<div class="select-travel-container">
  <div class="travel-info-container" *ngFor="let transfer of booking.transfers; let index = index">
    <div class="checkbox-button" *ngIf="booking.transfers.length > 1">
      <p-checkbox
        [id]="'checkbox-' + index"
        name="checkbox-{{ index }}"
        class="checkbox"
        [value]="transfer.transferid"
        [ngModel]="selectedTransfers"
        (ngModelChange)="onSelectTransfers(index)"
      ></p-checkbox>
    </div>
    <div class="travel-destination" [ngClass]="activeTransfers[index] ? 'travel-interaction' : 'travel-no-interaction'">
      <div class="travel-from-to">
        <p class="travel-text">
          {{ transfer.fromdetails.name }}
        </p>
        <i class="spt-icon-arrow-circle-right"></i>
        <p class="travel-text">
          {{ transfer.todetails.name }}
        </p>
      </div>
    </div>
  </div>
</div>
