import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { ActivatedRoute, Data } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject, takeUntil } from "rxjs";
import { Booking } from "src/app/@shared/@types/booking";
import { Label } from "src/app/@shared/@types/label";
import { MemberSociety } from "src/app/@shared/@types/society";
import { User } from "src/app/@shared/@types/user";
import { BillService } from "src/app/@shared/services/bill.service";
import { CommonService } from "src/app/@shared/services/common.service";
import { LabelService } from "src/app/@shared/services/label.service";
import { ModalService } from "src/app/@shared/services/modal.service";
import { SocietyService } from "src/app/@shared/services/society.service";
import { TrainTypes } from "src/app/travel/train/train";
import { BillsModalComponent } from "../../@dashboard-shared/modals/bills-modal/bills-modal.component";
import { EticketsModalComponent } from "../../@dashboard-shared/modals/etickets-modal/etickets-modal.component";
import { normalizeStringForSearch } from "src/app/@shared/pipes/normalize-filter.pipe";
import { DateTime } from "luxon";

@Component({
  selector: "spt-reservation-sidebar-footer",
  templateUrl: "./sidebar-footer.component.html",
  styleUrls: ["./sidebar-footer.component.scss"],
})
export class ReservationSidebarFooterComponent {
  @Input() item: any;
  @Input() isSmallDevice: boolean;
  @Output() toggleSidebarEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Output() updateReservations: EventEmitter<Booking> = new EventEmitter();

  @Output() onModify: EventEmitter<any> = new EventEmitter();
  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onShowLabelSide: EventEmitter<any> = new EventEmitter();

  public customerService: string;
  public users: User[] = [];
  public booker: any;
  public societyLabels: Array<Label> = [];
  public locale: string;
  public bills: Array<any> = [];
  public showModifyButton: boolean = true;
  public showCancelButton: boolean = true;
  private ngUnsubscribe: Subject<void> = new Subject();

  constructor(
    private commonService: CommonService,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private modalService: ModalService,
    private societyService: SocietyService,
    private billService: BillService,
  ) {}

  ngOnInit(): void {
    this.commonService.statusBarTextBlack();
    this.customerService = this.translateService.instant("GLOBAL.CUSTOMER.SERVICE");
    const statusHistory: any = this.item.statusHistory?.find((status: any): boolean => status.to === "confirmed");
    this.item.confirmationDate = statusHistory?.date;
    this.item.cancellationDate = this.item.statusHistory?.find(
      (status: any): boolean => status.to === "cancelled",
    )?.date;
    if (statusHistory) {
      const member: MemberSociety = this.societyService.society.value.members.find(
        (_member: MemberSociety): boolean => _member.user._id === statusHistory.userId,
      );
      if (member) {
        this.booker = member.user;
      }
    }
    this.locale = this.translateService.currentLang;
    this.users = this.item.travelers;

    // Bill modal stuff
    this.billService.getBillInfo(this.item.id).subscribe((bills: any): void => {
      for (const bill of bills) {
        bill.travelers = [];
        const travelerIds = Array.from(new Set(bill.lines.map((line: any): string[] => line.userIds).flat()));
        for (const userId of travelerIds) {
          bill.travelers.push(
            this.societyService.society.value.members.find(
              (member: MemberSociety): boolean => member.user._id.toString() === userId,
            ).user,
          );
        }
        this.bills.push(bill);
      }
      this.cd.detectChanges();
    });

    const now = DateTime.now().toMillis();

    if (this.item.type === "train") {
      let isNotPastTravel: boolean = false;

      isNotPastTravel = this.item.detail.journeys.some(
        (_journey: TrainTypes.Journey) =>
          DateTime.fromMillis(getTime(_journey.departure.date.utc)).plus({ minutes: 20 }).toMillis() > now,
      );

      if (this.item.detail.afterSales) {
        this.showCancelButton = this.item.detail.afterSales.refundable && isNotPastTravel;
        this.showModifyButton = this.item.detail.afterSales.changeable && isNotPastTravel;
      } else {
        this.showCancelButton = isNotPastTravel;
        this.showModifyButton = isNotPastTravel;
      }
    }
  }

  cancelReservation(): void {
    this.onCancel.emit(this.item);
  }

  modifyReservation(): void {
    this.onModify.emit(this.item);
  }

  openEtickets(event: any): void {
    let reference: string;
    switch (event.type) {
      case "train":
        reference = event.detail.orderReference;
        break;
    }
    if (event.documentIds.length > 1) {
      this.modalService.openModal(EticketsModalComponent, {
        data: {
          etickets: event.documentIds,
          type: event.type,
          reference,
          travelers: this.users,
        },
        dismissableMask: true,
        showHeader: false,
      });
    } else {
      this.commonService.openLink(event.documentIds[0]);
    }
  }

  openBills(bills: any): void {
    if (bills.length > 1) {
      this.modalService.openModal(BillsModalComponent, {
        data: {
          bills: this.bills,
        },
        dismissableMask: true,
        showHeader: false,
      });
    } else {
      this.commonService.openLink(bills[0].documentIds[0]);
    }
  }

  closeSidebarEmitter(event: boolean): void {
    this.commonService.statusBarTextWhite();
    this.toggleSidebarEmitter.emit(event);
  }

  editLabels(): void {
    this.onShowLabelSide.emit(this.item);
  }
}

function getTime(date: any): number {
  if (!date) {
    return NaN;
  }
  if (typeof date.getTime === "function") {
    return date.getTime();
  }
  return Date.parse(date.toString());
}
