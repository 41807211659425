<div class="legs" (mousedown)="extendItinerary(itinerary)">
  <ng-container *ngIf="this.tripType === 'simple'; else displayMulti">
    <div class="leg" *ngIf="itinerary.legs[step]; let leg">
      <spt-travel-flight-itinerary-leg
        [passengersCount]="itinerary.passengersCount"
        [leg]="leg"
        [carbonOffset]="itinerary?.carbonOffset"
        [itinerary]="itinerary"
        [baggageIncluded]="itinerary.baggageIncluded"
        [carryOnIncluded]="itinerary.carryOnIncluded"
        [departureDate]="this.departureDates[step]"
      ></spt-travel-flight-itinerary-leg>
    </div>
  </ng-container>

  <ng-template #displayMulti>
    <ng-container *ngIf="itinerary.type === 'packaged'; else displayIndividualLegs">
      <div class="leg">
        <spt-travel-flight-itinerary-leg
          [passengersCount]="itinerary.passengersCount"
          [leg]="itinerary.legs[1]"
          [itinerary]="itinerary"
          [baggageIncluded]="itinerary.baggageIncluded"
          [carryOnIncluded]="itinerary.carryOnIncluded"
          [departureDate]="this.departureDates[step]"
          [carbonOffset]="itinerary?.carbonOffset"
          [haveNegotiatedRate]="itinerary.haveNegotiatedRate"
        ></spt-travel-flight-itinerary-leg>
      </div>
    </ng-container>
    <ng-template #displayIndividualLegs>
      <div class="leg" *ngFor="let leg of itinerary.legs; let legIndex = index">
        <spt-travel-flight-itinerary-leg
          [passengersCount]="itinerary.passengersCount"
          [leg]="leg"
          [itinerary]="itinerary"
          [baggageIncluded]="itinerary.baggageIncluded"
          [carryOnIncluded]="itinerary.carryOnIncluded"
          [departureDate]="this.departureDates[step]"
          [carbonOffset]="itinerary?.carbonOffset"
          [haveNegotiatedRate]="itinerary.haveNegotiatedRate"
        ></spt-travel-flight-itinerary-leg>
      </div>
    </ng-template>
  </ng-template>
</div>

<div class="informations" (mousedown)="extendItinerary(itinerary)">
  <div class="price-information">
    <spt-specific-rate
      *ngIf="itinerary.haveNegotiatedRate || itinerary.subscriber"
      [subscriber]="itinerary.subscriber"
      [negociated]="itinerary.haveNegotiatedRate"
      type="flight"
    ></spt-specific-rate>
    <div class="type">
      <ng-container *ngIf="this.tripType === 'multi'; else round">
        <strong translate>GLOBAL.FLIGHT</strong> {{ "SEARCH.FLIGHT.PRICE_FROM" | translate }}
      </ng-container>
      <ng-template #round>
        <ng-container *ngIf="this.tripType !== 'simple'; else single">
          <ng-container *ngIf="selectedItinerary !== undefined; else outbound">
            <strong translate>SEARCH.TYPES.RETURN</strong> {{ "SEARCH.FLIGHT.PRICE_FROM" | translate }}
          </ng-container>
          <ng-template #outbound>
            <strong translate>SEARCH.TYPES.FLIGHT.OUTBOUND</strong> {{ "SEARCH.FLIGHT.PRICE_FROM" | translate }}
          </ng-template>
        </ng-container>
        <ng-template #single>
          <strong translate>SEARCH.TYPES.SINGLE</strong> {{ "SEARCH.FLIGHT.PRICE_FROM" | translate }}
        </ng-template>
      </ng-template>
    </div>
    <ng-container *ngIf="selectedItinerary !== undefined && itinerary.type !== 'packaged'; else simplePrice">
      <div class="price" *ngIf="itinerary.totalPrice">
        {{
          {
            amount: itinerary.filteredOffers
              ? itinerary.filteredOffers[0]?.totalPrice + selectedItinerary?.totalPrice
              : itinerary.totalPrice + selectedItinerary?.totalPrice,
            currency: itinerary.currency
          }
            | SPTPrice
            | async
        }}
      </div>
    </ng-container>
    <ng-template #simplePrice>
      <ng-container *ngIf="selectedItinerary !== undefined && itinerary.type == 'packaged'; else outboundPrice">
        <div class="price" *ngIf="itinerary.totalPrice && itinerary.provider !== 'afkl'; else afklPackage">
          <ng-container *ngIf="itinerary?.type === 'packaged'; else singlePrice">
            {{
              {
                amount: itinerary.filteredOffers ? itinerary.filteredOffers[0]?.totalPrice : itinerary?.totalPrice,
                currency: itinerary.currency
              }
                | SPTPrice
                | async
            }}
          </ng-container>
          <ng-template #singlePrice>
            {{
              {
                amount: itinerary.filteredOffers
                  ? itinerary.filteredOffers[0]?.totalPrice + selectedItinerary?.totalPrice
                  : itinerary.totalPrice + selectedItinerary?.totalPrice,
                currency: itinerary.currency
              }
                | SPTPrice
                | async
            }}
          </ng-template>
        </div>
        <ng-template #afklPackage>
          <div class="price" *ngIf="itinerary.totalPrice">
            {{
              {
                amount: itinerary.filteredOffers ? itinerary.filteredOffers[0]?.totalPrice : itinerary?.totalPrice,
                currency: itinerary.currency
              }
                | SPTPrice
                | async
            }}
          </div>
        </ng-template>
      </ng-container>
      <ng-template #outboundPrice>
        <div class="price" *ngIf="itinerary.totalPrice">
          {{
            {
              amount: itinerary.filteredOffers ? itinerary.filteredOffers[0]?.totalPrice : itinerary?.totalPrice,
              currency: itinerary.currency
            }
              | SPTPrice
              | async
          }}
        </div>
      </ng-template>
    </ng-template>

    <spt-out-of-policy
      [isOutOfPolicy]="itinerary.isOutOfPolicy"
      [authorizationsByPolicies]="itinerary.pricingInformations?.[0]?.authorizationsByPolicies"
      [isPriceOutOfPolicy]="itinerary.pricingInformations?.[0]?.isPriceOutOfPolicy"
      type="flight"
      [displayInfo]="this.commonService.isTablet ? false : true"
    ></spt-out-of-policy>
    <ng-container *ngIf="!extended; else noExtended">
      <p-button
        styleClass="p-button-primary medium"
        (click)="extendItinerary(itinerary)"
        [label]="'GLOBAL.SEE_OFFERS' | translate"
      >
      </p-button>
    </ng-container>
    <ng-template #noExtended>
      <p-button
        styleClass="disabled medium"
        (click)="extendItinerary(itinerary)"
        [label]="'GLOBAL.HIDE_OFFERS' | translate"
      >
      </p-button>
    </ng-template>
  </div>
</div>
<div class="extended-block flex w-full" *ngIf="extended">
  <div
    class="titles flex-1"
    *ngIf="itinerary.filteredOffers?.length && (commonService.isTabletObservable | async) === false"
  >
    <ul class="advantages">
      <li *ngFor="let advantage of advantages; let i = index" [ngClass]="(i + 1) % 2 === 0 ? 'even' : ''">
        <span class="advantage-value">
          <span class="advantage-label">
            {{ advantage.label | translate }}
          </span>
        </span>
      </li>
    </ul>
  </div>
  <div class="offers">
    <ng-container *ngIf="itinerary.filteredOffers?.length && hideAdvantages === false; else noFlights">
      <div
        *ngFor="let offer of itinerary.filteredOffers; let offerIndex = index"
        class="offer"
        [ngClass]="'offer-1' + itinerary.filteredOffers?.length - 1"
      >
        <div class="price">
          <p class="offer-name">
            <ng-container *ngIf="offer.className && offer.cabinCodes && offer.provider"
              >{{
                offer.cabinCodes[0] !== "C" && offer.cabinCodes[0] !== "F" && offer.cabinCodes[0] !== "S"
                  ? ("GLOBAL.CATEGORY." + offer.cabinCodes[0] | translate)
                  : ""
              }}
              {{ offer.className | lowercase }}</ng-container
            >
            <ng-container *ngIf="offer.className && offer.cabinCodes && !offer.provider">
              {{ offer.className | lowercase }}</ng-container
            >
            <ng-container *ngIf="offer.className && !offer.cabinCodes">{{ offer.className | lowercase }}</ng-container>
            <ng-container *ngIf="!offer.className">{{
              offer.cabinCodes?.length >= 1
                ? ("GLOBAL.CATEGORY." + offer.cabinCodes[0] | translate)
                : ("MULTI" | lowercase)
            }}</ng-container>
          </p>
          <div class="type">
            <ng-container *ngIf="this.tripType === 'multi'; else round">
              <strong translate>GLOBAL.FLIGHT</strong> {{ "SEARCH.FLIGHT.PRICE_FROM" | translate }}
            </ng-container>
            <ng-template #round>
              <ng-container *ngIf="this.tripType !== 'simple'; else single">
                <ng-container *ngIf="selectedItinerary !== undefined; else outbound">
                  <strong translate>SEARCH.TYPES.RETURN</strong> {{ "SEARCH.FLIGHT.PRICE_FROM" | translate }}
                </ng-container>
                <ng-template #outbound>
                  <strong translate>SEARCH.TYPES.FLIGHT.OUTBOUND</strong> {{ "SEARCH.FLIGHT.PRICE_FROM" | translate }}
                </ng-template>
              </ng-container>
              <ng-template #single>
                <strong translate>SEARCH.TYPES.SINGLE</strong> {{ "SEARCH.FLIGHT.PRICE_FROM" | translate }}
              </ng-template>
            </ng-template>
          </div>
          <div class="amount flex justify-center items-center">
            <ng-container *ngIf="selectedItinerary !== undefined && itinerary.type !== 'packaged'; else simplePrice">
              <div class="price">
                {{
                  {
                    amount: offer?.totalPrice + selectedItinerary?.totalPrice,
                    currency: offer.currency
                  }
                    | SPTPrice
                    | async
                }}
              </div>
            </ng-container>
            <ng-template #simplePrice>
              <ng-container *ngIf="selectedItinerary !== undefined && itinerary.type == 'packaged'; else outboundPrice">
                <div class="price" *ngIf="offer.totalPrice && offer.provider !== 'afkl'; else afklPackage">
                  {{
                    {
                      amount: offer?.totalPrice,
                      currency: offer.currency
                    }
                      | SPTPrice
                      | async
                  }}
                </div>
                <ng-template #afklPackage>
                  <div class="price" *ngIf="itinerary.totalPrice">
                    {{
                      {
                        amount: offer?.totalPrice,
                        currency: offer.currency
                      }
                        | SPTPrice
                        | async
                    }}
                  </div>
                </ng-template>
              </ng-container>
              <ng-template #outboundPrice>
                <div class="price" *ngIf="offer?.totalPrice">
                  {{
                    {
                      amount: offer?.totalPrice,
                      currency: offer.currency
                    }
                      | SPTPrice
                      | async
                  }}
                </div>
              </ng-template>
            </ng-template>
          </div>
          <button class="h-16" translate (click)="previewItinerary(offer)">CAR.CHOOSE_CTA</button>
        </div>
        <div class="advantages">
          <ng-container>
            <div class="advantage">
              <svg
                *ngIf="offer.carryOnIncluded === 0; else okIcon"
                width="22"
                height="22"
                viewBox="0 0 23 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.32443 14.5522C3.79611 15.6503 4.48175 16.6434 5.34134 17.4736C7.03876 19.113 9.31218 20.0202 11.672 19.9997C14.0317 19.9792 16.289 19.0326 17.9577 17.364C19.6264 15.6953 20.5729 13.438 20.5934 11.0782C20.6139 8.71843 19.7068 6.44501 18.0673 4.74759C17.2371 3.888 16.244 3.20236 15.146 2.73068C14.0479 2.259 12.867 2.01072 11.672 2.00034C10.4769 1.98996 9.29182 2.21767 8.18576 2.6702C7.07969 3.12273 6.07482 3.791 5.22979 4.63604C4.38475 5.48107 3.71648 6.48594 3.26395 7.59201C2.81142 8.69807 2.58371 9.88319 2.59409 11.0782C2.60447 12.2732 2.85275 13.4542 3.32443 14.5522ZM1.4868 15.3416C2.0633 16.6837 2.90131 17.8975 3.95192 18.9122C6.02654 20.9159 8.80517 22.0246 11.6893 21.9996C14.5735 21.9745 17.3324 20.8177 19.3719 18.7782C21.4114 16.7387 22.5683 13.9797 22.5933 11.0956C22.6184 8.21142 21.5097 5.43279 19.5059 3.35817C18.4912 2.30756 17.2774 1.46955 15.9354 0.893055C14.5933 0.316556 13.1499 0.0131073 11.6893 0.000415324C10.2288 -0.0122767 8.78029 0.266042 7.42843 0.819132C6.07657 1.37222 4.8484 2.18901 3.81558 3.22183C2.78276 4.25465 1.96597 5.48282 1.41288 6.83468C0.859792 8.18654 0.581473 9.63501 0.594165 11.0956C0.606857 12.5562 0.910306 13.9996 1.4868 15.3416Z"
                  fill="#F77951"
                />
                <path
                  d="M14.047 14.9093L11.3605 12.2228L8.67677 14.9066L7.26722 13.497L9.95098 10.8132L7.25051 8.11277L8.66558 6.6977L11.3661 9.39817L14.0638 6.70047L15.4733 8.11002L12.7756 10.8077L15.4621 13.4942L14.047 14.9093Z"
                  fill="#F77951"
                />
              </svg>
              <span *ngIf="(commonService.isTabletObservable | async) === true" translate>
                SEARCH.RESULT.AIR.FILTERS.BAGGAGES_CARRY
              </span>
            </div>
          </ng-container>
          <ng-container>
            <div class="advantage even">
              <svg
                *ngIf="offer.baggageIncluded === 0; else okIcon"
                width="22"
                height="22"
                viewBox="0 0 23 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.32443 14.5522C3.79611 15.6503 4.48175 16.6434 5.34134 17.4736C7.03876 19.113 9.31218 20.0202 11.672 19.9997C14.0317 19.9792 16.289 19.0326 17.9577 17.364C19.6264 15.6953 20.5729 13.438 20.5934 11.0782C20.6139 8.71843 19.7068 6.44501 18.0673 4.74759C17.2371 3.888 16.244 3.20236 15.146 2.73068C14.0479 2.259 12.867 2.01072 11.672 2.00034C10.4769 1.98996 9.29182 2.21767 8.18576 2.6702C7.07969 3.12273 6.07482 3.791 5.22979 4.63604C4.38475 5.48107 3.71648 6.48594 3.26395 7.59201C2.81142 8.69807 2.58371 9.88319 2.59409 11.0782C2.60447 12.2732 2.85275 13.4542 3.32443 14.5522ZM1.4868 15.3416C2.0633 16.6837 2.90131 17.8975 3.95192 18.9122C6.02654 20.9159 8.80517 22.0246 11.6893 21.9996C14.5735 21.9745 17.3324 20.8177 19.3719 18.7782C21.4114 16.7387 22.5683 13.9797 22.5933 11.0956C22.6184 8.21142 21.5097 5.43279 19.5059 3.35817C18.4912 2.30756 17.2774 1.46955 15.9354 0.893055C14.5933 0.316556 13.1499 0.0131073 11.6893 0.000415324C10.2288 -0.0122767 8.78029 0.266042 7.42843 0.819132C6.07657 1.37222 4.8484 2.18901 3.81558 3.22183C2.78276 4.25465 1.96597 5.48282 1.41288 6.83468C0.859792 8.18654 0.581473 9.63501 0.594165 11.0956C0.606857 12.5562 0.910306 13.9996 1.4868 15.3416Z"
                  fill="#F77951"
                />
                <path
                  d="M14.047 14.9093L11.3605 12.2228L8.67677 14.9066L7.26722 13.497L9.95098 10.8132L7.25051 8.11277L8.66558 6.6977L11.3661 9.39817L14.0638 6.70047L15.4733 8.11002L12.7756 10.8077L15.4621 13.4942L14.047 14.9093Z"
                  fill="#F77951"
                />
              </svg>

              <span *ngIf="(commonService.isTabletObservable | async) === true" translate>
                SEARCH.RESULT.AIR.FILTERS.BAGGAGES_BAGGAGES
              </span>
            </div>
          </ng-container>
          <ng-container>
            <div class="advantage">
              <!-- {{ offer.refundablePenalities }} -->
              <svg
                *ngIf="!offer.refundable; else okIcon"
                width="22"
                height="22"
                viewBox="0 0 23 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.32443 14.5522C3.79611 15.6503 4.48175 16.6434 5.34134 17.4736C7.03876 19.113 9.31218 20.0202 11.672 19.9997C14.0317 19.9792 16.289 19.0326 17.9577 17.364C19.6264 15.6953 20.5729 13.438 20.5934 11.0782C20.6139 8.71843 19.7068 6.44501 18.0673 4.74759C17.2371 3.888 16.244 3.20236 15.146 2.73068C14.0479 2.259 12.867 2.01072 11.672 2.00034C10.4769 1.98996 9.29182 2.21767 8.18576 2.6702C7.07969 3.12273 6.07482 3.791 5.22979 4.63604C4.38475 5.48107 3.71648 6.48594 3.26395 7.59201C2.81142 8.69807 2.58371 9.88319 2.59409 11.0782C2.60447 12.2732 2.85275 13.4542 3.32443 14.5522ZM1.4868 15.3416C2.0633 16.6837 2.90131 17.8975 3.95192 18.9122C6.02654 20.9159 8.80517 22.0246 11.6893 21.9996C14.5735 21.9745 17.3324 20.8177 19.3719 18.7782C21.4114 16.7387 22.5683 13.9797 22.5933 11.0956C22.6184 8.21142 21.5097 5.43279 19.5059 3.35817C18.4912 2.30756 17.2774 1.46955 15.9354 0.893055C14.5933 0.316556 13.1499 0.0131073 11.6893 0.000415324C10.2288 -0.0122767 8.78029 0.266042 7.42843 0.819132C6.07657 1.37222 4.8484 2.18901 3.81558 3.22183C2.78276 4.25465 1.96597 5.48282 1.41288 6.83468C0.859792 8.18654 0.581473 9.63501 0.594165 11.0956C0.606857 12.5562 0.910306 13.9996 1.4868 15.3416Z"
                  fill="#F77951"
                />
                <path
                  d="M14.047 14.9093L11.3605 12.2228L8.67677 14.9066L7.26722 13.497L9.95098 10.8132L7.25051 8.11277L8.66558 6.6977L11.3661 9.39817L14.0638 6.70047L15.4733 8.11002L12.7756 10.8077L15.4621 13.4942L14.047 14.9093Z"
                  fill="#F77951"
                />
              </svg>
              <span *ngIf="(commonService.isTabletObservable | async) === true" translate>
                SEARCH.RESULT.AIR.FILTERS.CANCELLABLE
              </span>
            </div>
          </ng-container>
          <ng-container>
            <div class="even advantage">
              <svg
                *ngIf="!offer.changeable; else okIcon"
                width="22"
                height="22"
                viewBox="0 0 23 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.32443 14.5522C3.79611 15.6503 4.48175 16.6434 5.34134 17.4736C7.03876 19.113 9.31218 20.0202 11.672 19.9997C14.0317 19.9792 16.289 19.0326 17.9577 17.364C19.6264 15.6953 20.5729 13.438 20.5934 11.0782C20.6139 8.71843 19.7068 6.44501 18.0673 4.74759C17.2371 3.888 16.244 3.20236 15.146 2.73068C14.0479 2.259 12.867 2.01072 11.672 2.00034C10.4769 1.98996 9.29182 2.21767 8.18576 2.6702C7.07969 3.12273 6.07482 3.791 5.22979 4.63604C4.38475 5.48107 3.71648 6.48594 3.26395 7.59201C2.81142 8.69807 2.58371 9.88319 2.59409 11.0782C2.60447 12.2732 2.85275 13.4542 3.32443 14.5522ZM1.4868 15.3416C2.0633 16.6837 2.90131 17.8975 3.95192 18.9122C6.02654 20.9159 8.80517 22.0246 11.6893 21.9996C14.5735 21.9745 17.3324 20.8177 19.3719 18.7782C21.4114 16.7387 22.5683 13.9797 22.5933 11.0956C22.6184 8.21142 21.5097 5.43279 19.5059 3.35817C18.4912 2.30756 17.2774 1.46955 15.9354 0.893055C14.5933 0.316556 13.1499 0.0131073 11.6893 0.000415324C10.2288 -0.0122767 8.78029 0.266042 7.42843 0.819132C6.07657 1.37222 4.8484 2.18901 3.81558 3.22183C2.78276 4.25465 1.96597 5.48282 1.41288 6.83468C0.859792 8.18654 0.581473 9.63501 0.594165 11.0956C0.606857 12.5562 0.910306 13.9996 1.4868 15.3416Z"
                  fill="#F77951"
                />
                <path
                  d="M14.047 14.9093L11.3605 12.2228L8.67677 14.9066L7.26722 13.497L9.95098 10.8132L7.25051 8.11277L8.66558 6.6977L11.3661 9.39817L14.0638 6.70047L15.4733 8.11002L12.7756 10.8077L15.4621 13.4942L14.047 14.9093Z"
                  fill="#F77951"
                />
              </svg>
              <span *ngIf="(commonService.isTabletObservable | async) === true" translate>
                SEARCH.RESULT.AIR.FILTERS.CHANGEABLE
              </span>
            </div>
          </ng-container>
        </div>
        <ng-template #okIcon>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.74759 17.4736C3.888 16.6434 3.20236 15.6503 2.73068 14.5522C2.259 13.4542 2.01072 12.2732 2.00034 11.0782C1.98996 9.88319 2.21767 8.69807 2.6702 7.59201C3.12273 6.48594 3.791 5.48107 4.63604 4.63604C5.48107 3.791 6.48594 3.12273 7.59201 2.6702C8.69807 2.21767 9.88319 1.98996 11.0782 2.00034C12.2732 2.01072 13.4542 2.259 14.5522 2.73068C15.6503 3.20236 16.6434 3.888 17.4736 4.74759C19.113 6.44501 20.0202 8.71843 19.9997 11.0782C19.9792 13.438 19.0326 15.6953 17.364 17.364C15.6953 19.0326 13.438 19.9792 11.0782 19.9997C8.71843 20.0202 6.44501 19.113 4.74759 17.4736ZM3.35817 18.9122C2.30756 17.8975 1.46955 16.6837 0.893055 15.3416C0.316556 13.9996 0.0131073 12.5562 0.000415324 11.0956C-0.0122767 9.63501 0.266042 8.18654 0.819132 6.83468C1.37222 5.48282 2.18901 4.25465 3.22183 3.22183C4.25465 2.18901 5.48282 1.37222 6.83468 0.819132C8.18654 0.266042 9.63501 -0.0122767 11.0956 0.000415324C12.5562 0.0131073 13.9996 0.316556 15.3416 0.893055C16.6837 1.46955 17.8975 2.30756 18.9122 3.35817C20.9159 5.43279 22.0246 8.21142 21.9996 11.0956C21.9745 13.9797 20.8177 16.7387 18.7782 18.7782C16.7387 20.8177 13.9797 21.9745 11.0956 21.9996C8.21142 22.0246 5.43279 20.9159 3.35817 18.9122ZM9.82979 11.8715L14.8617 6.875L16.5 8.525L9.82979 15.125L5.5 10.8257L7.1383 9.1757L9.82979 11.8715Z"
              fill="#604FD7"
            />
          </svg>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #noFlights>
      <div class="error-container" *ngIf="hideAdvantages === true">
        <p class="text-center">
          <img class="error-img" src="./assets/svg/no-results.svg" alt="No results" />
        </p>
        <p class="error-message" translate>SEARCH.RESULT.NO_FLIGHTS_FILTERED</p>
      </div>
    </ng-template>
  </div>
</div>
