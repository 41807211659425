<div class="item-actions">
  <div class="actions-tags">
    <spt-tags-manage [tagIds]="item.tagIds" (updatedTags)="updateTags($event)"></spt-tags-manage>
    <div class="separator" *ngIf="(commonService.isTabletObservable | async) === true"></div>
  </div>
  <div>
    <div class="reference" *ngIf="item.reference">{{ "GLOBAL.BOOKING_NUMBER" | translate }} {{ item.reference }}</div>
    <div
      *ngIf="item?.documentIds?.length > 0 && item?.status !== 'cancelled' && item?.status !== 'error'"
      [ngClass]="
        item?.documentIds?.length > 0 && item?.status !== 'cancelled' && item?.status !== 'error' ? 'eticket' : ''
      "
    >
      <p-button
        [label]="item.type === 'train' ? ('BOOKING.ETICKETS' | translate) : ('GLOBAL.VOUCHER' | translate)"
        (click)="openEtickets(item)"
        [styleClass]="
          (commonService.isTabletObservable | async) === true
            ? 'p-button-secondary large-icon'
            : 'p-button-secondary small'
        "
        ><img src="./assets/svg/download-violet.svg" />
      </p-button>
    </div>
  </div>
</div>
