import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { RequestHotel, ItemHotel } from "../../../../../../@shared/@types/models";

@Component({
  selector: "spt-request-hotel",
  templateUrl: "./hotel.component.html",
  styleUrls: ["../request.component.scss", "./hotel.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RequestHotelComponent implements OnInit {
  @Input() request: RequestHotel;
  item: ItemHotel;
  constructor() {}

  ngOnInit(): void {
    this.item = this.request.item as ItemHotel;
  }
}
