import { Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { TrainService } from "../train/train.service";
import { ActivatedRoute } from "@angular/router";
import { TrainTypes } from "../train/train";
import { Observable } from "rxjs";

@Component({
  selector: "spt-travel-conditions",
  templateUrl: "./conditions.component.html",
  styleUrls: ["./conditions.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TravelConditionsComponent implements OnInit {
  $fareType: Observable<TrainTypes.Fare>;
  @ViewChild("container") containerRef: ElementRef;

  constructor(
    private trainService: TrainService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    switch (this.route.snapshot.params.type) {
      case "train":
        this.$fareType = this.trainService.getFareType(this.route.snapshot.params.conditionId);
        break;
      default:
        break;
    }
  }
}
