import { Observable, map } from "rxjs";
import { Address } from "src/app/@shared/@types/models";
import { MemberSociety } from "src/app/@shared/@types/society";
import { environment } from "src/environments/environment";
import { FavoriteData } from "./common/favorites/travelFavorite";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TransferTypes } from "src/app/travel/transfer/transfer";

export interface SearchEngineService<
  LOCALITY_TYPE extends Locality | Address | TransferTypes.Place,
  OPTIONS_TYPE extends SearchOptions,
> {
  getType(): SearchEngineType;

  getMaxPassengers(searchCriteria: SearchCriteria<LOCALITY_TYPE, OPTIONS_TYPE>): number;

  getMinimumNumberOfCharactersForLocalitySearch(): number;

  localitySearch(searchString: string): Observable<LOCALITY_TYPE[]>;

  /**
   * Pour certain type de localité (notamment les adresses),
   * il y a besoin de faire une seconde requête afin d'avoir des détails
   * (ex : longitude, latitude)
   *
   * @param locality
   */
  fetchDetailsForLocality(locality: LOCALITY_TYPE): Observable<LOCALITY_TYPE>;

  lastSearches(limit?: number): Observable<LastSearchItem<LOCALITY_TYPE>[]>;

  launchSearch(
    searchCriteria: SearchCriteria<LOCALITY_TYPE, OPTIONS_TYPE>,
    oldItemId?: string,
  ): Observable<SearchResult>;

  createBlankCriteria(): SearchCriteria<LOCALITY_TYPE, OPTIONS_TYPE>;

  searchCriteriaIsValid(searchCriteria: SearchCriteria<LOCALITY_TYPE, OPTIONS_TYPE>): boolean;

  /**
   * Création d'une localité factice à partir d'un nom.
   */
  createDummyLocalityFromName(name: string): LOCALITY_TYPE;

  createLocalityFromJson(json: any): LOCALITY_TYPE;

  createCriteriaFromBasketItem(
    lastFolderItemsInBasket: any[],
    members: MemberSociety[],
  ): SearchCriteria<LOCALITY_TYPE, OPTIONS_TYPE>;
}

export class SearchCriteria<
  LOCALITY_TYPE extends Locality | Address | TransferTypes.Place,
  OPTIONS_TYPE extends SearchOptions,
> {
  mainTravel: Travel<LOCALITY_TYPE>;
  travels: Travel<LOCALITY_TYPE>[];

  constructor(
    public options: OPTIONS_TYPE,
    ...allTravels: Travel<LOCALITY_TYPE>[]
  ) {
    if (allTravels.length === 0) {
      throw new Error("Please provide at least one travel");
    }
    this.mainTravel = allTravels[0];
    this.travels = allTravels;
  }

  removeTravel(idx: number) {
    if (!this.travels[idx]) {
      throw new Error("Travel index not found: " + idx);
    }
    if (this.travels.length === 1 && idx === 0) {
      throw new Error("mainTravel can not be removed");
    }
    this.travels.splice(idx, 1);
    if (idx === 0) {
      this.mainTravel = this.travels[0];
    }
  }

  isValid(): boolean {
    return this.travels.length > 0 && !this.travels.some((travel) => !travel.isValid()) && this.options.isValid();
  }

  checkOriginIsDefined(): boolean {
    return !this.travels.some((travel) => !travel.origin);
  }

  checkPeopleAreSameOnAllTravels(): boolean {
    if (this.travels.length < 2) {
      return true;
    }
    let passengers = this.travels[0].people
      .map((p) => p.user._id)
      .sort()
      .join(",");
    return !this.travels.some(
      (travel) =>
        passengers !==
        travel.people
          .map((p) => p.user._id)
          .sort()
          .join(","),
    );
  }
}

export class WhenSearch {
  constructor(
    public outward: DateTimeSearch,
    public inward?: DateTimeSearch,
  ) {}

  isValid(): boolean {
    return this.outward.isValid() && (!this.inward || this.inward.isValid());
  }
}

function pad(num: number) {
  return String(num).padStart(2, "0");
}

export class TimeRange {
  private static TIME_PATTERN = /^(?<hours>\d{2}):(?<minutes>\d{2})$/;

  begin: string;
  end: string;
  value?: number;

  /**
   * Récupère les heures à partir d'une date.
   * Dans le cas où `end` est antérieur à `begin`, on met end identique à begin.
   */
  static hourFromDate(begin: Date, end: Date) {
    if (begin.getTime() > end.getTime()) {
      end = new Date(begin.getTime());
    }
    return {
      begin: pad(begin.getHours()) + ":" + pad(begin.getMinutes()),
      end: pad(end.getHours()) + ":" + pad(end.getMinutes()),
    };
  }

  static oneHour(hours: number): TimeRange {
    const h = pad(hours);
    return {
      begin: h + ":00",
      end: h + ":59",
    };
  }

  static getTimeAsArray(time: string) {
    const timeRegexpResult = TimeRange.TIME_PATTERN.exec(time);
    if (!timeRegexpResult) {
      return [];
    }
    return [parseInt(timeRegexpResult.groups.hours, 10), parseInt(timeRegexpResult.groups.minutes, 10)];
  }
}

export class DateTimeAdjustment {
  condition: (dateTimeSearch: DateTimeSearch) => boolean;
  apply: (dateTimeSearch: DateTimeSearch) => void;
}
export class DateTimeSearch {
  static DATE_PATTERN = /^(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})$/;

  static invalidDate(): Date {
    return new Date(NaN);
  }

  static NO_TIME: TimeRange = { begin: "00:00", end: "23:59" };

  date: string = "";
  type: "DepartsAfter" | "ArrivalBefore" = "DepartsAfter";
  time: TimeRange = DateTimeSearch.NO_TIME;
  constructor(initialDate?: string, initialTime?: TimeRange) {
    if (initialDate) {
      this.date = initialDate;
    }
    if (initialTime) {
      this.time = initialTime;
    }
  }
  adjust(...adjustements: DateTimeAdjustment[]) {
    let result: DateTimeSearch = Object.assign(new DateTimeSearch(), this);
    adjustements.forEach((adjust) => {
      if (adjust.condition(result)) {
        adjust.apply(result);
      }
    });
    return result;
  }

  isValid(): boolean {
    return !isNaN(this.getBeginDate().getTime()) && this.type?.length > 0;
  }

  setDateOnly(date: Date): void {
    this.date = !date
      ? undefined
      : [date.getFullYear().toString(), pad(date.getMonth() + 1), pad(date.getDate())].join("-");
  }

  private getDate(time: string): Date {
    const [hours, minutes] = TimeRange.getTimeAsArray(time);
    const dateRegexpResult = DateTimeSearch.DATE_PATTERN.exec(this.date);
    if (!dateRegexpResult || hours === undefined || minutes === undefined) {
      return DateTimeSearch.invalidDate();
    }

    const y = parseInt(dateRegexpResult.groups.year, 10);
    const m = parseInt(dateRegexpResult.groups.month, 10);
    const d = parseInt(dateRegexpResult.groups.day, 10);

    const date = new Date(y, m - 1, d, hours, minutes, 0, 0);
    if (
      date.getFullYear() !== y ||
      date.getMonth() !== m - 1 ||
      date.getDate() !== d ||
      date.getHours() !== hours ||
      date.getMinutes() !== minutes
    ) {
      return DateTimeSearch.invalidDate();
    }
    return date;
  }

  getBeginDate(): Date {
    return this.getDate(this.time.begin);
  }

  getEndDate(): Date {
    return this.getDate(this.time.end);
  }

  getBeginTimeAsArray(): number[] {
    return TimeRange.getTimeAsArray(this.time.begin);
  }
  setBeginTime(hours: number, minutes: number) {
    this.time.begin = pad(hours) + ":" + pad(minutes);
  }
}

export class Travel<LOCALITY_TYPE extends Locality | Address | TransferTypes.Place> {
  constructor(
    public people: MemberSociety[],
    public when: WhenSearch,
    public destination: LOCALITY_TYPE,
    public origin?: LOCALITY_TYPE,
  ) {}

  isValid(): boolean {
    return (
      this.people.length > 0 &&
      this.when.isValid() &&
      checkLocalityIsValid(this.destination) &&
      (!this.origin || checkLocalityIsValid(this.origin))
    );
  }
}

export function checkLocalityIsValid(locality: any): boolean {
  if (locality.travelType === "FLIGHT") {
    return (
      (locality.countryISO?.trim().length > 0 || locality.country?.trim().length > 0) &&
      (locality.iata?.trim().length > 0 ||
        locality.cityIATA?.trim().length > 0 ||
        locality.airportIATA?.trim().length > 0)
    );
  }
  if (locality.code?.trim().length > 0 && locality.description?.trim().length > 0 && locality.type?.trim().length > 0) {
    // cas pour un place-finder pour 'transfer
    return true;
  }
  if (locality.locationType === "StationGroup") {
    return true;
  }
  const longitude = locality.coordinates ? locality.coordinates[0] : locality.longitude;
  const latitude = locality.coordinates ? locality.coordinates[1] : locality.latitude;
  return !isNaN(longitude) && !isNaN(latitude);
}

export interface SearchFavorite<LOCALITY_TYPE extends Locality | Address | TransferTypes.Place> {
  label: string;
  destination: LOCALITY_TYPE;
  origin?: LOCALITY_TYPE;
}

export interface Locality {
  label: string;
}

/**
 * from: https://stackoverflow.com/questions/39513815/is-there-a-way-to-create-an-object-using-variables-and-ignore-undefined-variable/75304450#answer-75304450
 * @param props
 * @param defaultValues
 */
export function propsWithDefault<T>(props: T, defaultValues: any): T {
  return Object.assign(
    {},
    defaultValues,
    Object.fromEntries(Object.entries(props || {}).filter(([key, value]) => value !== undefined)),
  );
}

@Injectable({
  providedIn: "root",
})
export class TravelService {
  constructor(private httpClient: HttpClient) {}

  addFavorite(type: "user" | "company", label: string, engine: SearchEngineType, data: FavoriteData) {
    const endpoint = `${environment.api}/favorites`;
    return this.httpClient.post(endpoint, {
      type,
      label,
      engine,
      ...data,
    });
  }

  removeFavorite(id: string) {
    const endpoint = `${environment.api}/favorites/${id}`;
    return this.httpClient.delete(endpoint);
  }
}

export interface SearchResult {
  id: string;
}

export interface SearchOptions {
  isValid(): boolean;
}

export type SearchEngineType = "hotel" | "car" | "train" | "flight" | "seminar" | "transfer";

export interface Search {
  _id: string;
  id: string;
  createdAt: Date;
  oldItemId?: string;
  societyId: string;
  type: "flight" | "car" | "train" | "hotel";
  data: any;
}

export class LastSearchItem<LOCALITY_TYPE extends Locality | Address | TransferTypes.Place> {
  constructor(
    public label: string,
    public travel: Travel<LOCALITY_TYPE>,
  ) {}
}

export function lastSearches(
  httpClient: HttpClient,
  type: SearchEngineType,
  limitParam?: number,
): Observable<Search[]> {
  const limit: number = limitParam || 10;

  const headers: HttpHeaders = new HttpHeaders({
    ignoreLoading: "true",
    ignoreLoadingBar: "true",
    ignoreErrorMessage: "true",
  });

  const params: HttpParams = new HttpParams({
    fromObject: {
      type,
      limit,
    },
  });
  return httpClient.get(`${environment.api}/search/`, { headers, params }) as Observable<Search[]>;
}
