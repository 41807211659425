<!-- <ng-progress></ng-progress> -->
<ngx-loading-bar height="5px" [includeSpinner]="false"></ngx-loading-bar>

<p-toast [baseZIndex]="999999"></p-toast>
<!-- <p-toast key="reloadSeach" [baseZIndex]="999999"></p-toast> -->

<spt-loading-v2 *ngIf="loading === true" message="{{ 'LOADING.SOCIETY' | translate }}"></spt-loading-v2>

<spt-menu></spt-menu>
<router-outlet></router-outlet>
<!-- <p-button type="button" label="NTM" (click)="emitBackButton()" class="totoETET"></p-button>-->
<!-- <p-button type="button" label="hide" (click)="hideIntercom()" class="totoETET"></p-button>
<p-button type="button" label="show" (click)="showIntercom()" class="totoETET2"></p-button> -->
