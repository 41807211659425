import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "localizedDate",
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, pattern: string = "mediumDate"): any {
    const datePipe: DatePipe = new DatePipe(this.translateService.currentLang || this.translateService.defaultLang);
    const _date = new Date(value);
    if (_date instanceof Date && isFinite(_date as any)) {
      return datePipe.transform(value, pattern);
    } else {
      return value;
    }
  }
}
