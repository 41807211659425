import { Injectable } from "@angular/core";
import { SocietyService } from "./society.service";
import { Society, MemberSociety, Roles } from "../@types/society";
import { SessionService, SessionType } from "./session.service";
import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class RoleService {
  private member: MemberSociety;
  private society: Society;
  private session: SessionType;
  public roles: BehaviorSubject<Roles> = new BehaviorSubject<Roles>(null);

  constructor(
    private societyService: SocietyService,
    private sessionService: SessionService<SessionType>,
  ) {
    this.societyService.society.pipe(filter((society: Society) => !!society)).subscribe((society: Society) => {
      this.session = this.sessionService.get();
      this.society = society;
      this.checkRoles();
    });
  }

  is(role: Roles): boolean {
    if (this.member) {
      return this.member.roles.indexOf(role) > -1;
    } else {
      return false;
    }
  }

  hasBookOutOfPolicy(): boolean {
    return this.member.bookOutOfPolicy;
  }

  hasBook(): boolean {
    return this.member.book !== undefined && this.member.book !== null ? this.member.book : true;
  }

  hasBookToHim(): boolean {
    return this.member.bookToHim !== undefined && this.member.bookToHim !== null ? this.member.bookToHim : true;
  }

  hasValidateBookingRequest(): boolean {
    // tslint:disable-next-line:max-line-length
    return this.member.validateBookingRequest !== undefined && this.member.validateBookingRequest !== null
      ? this.member.validateBookingRequest
      : true;
  }

  seeOutOfPolicy(): boolean {
    return this.member.seeOutOfPolicy !== undefined && this.member.seeOutOfPolicy !== null
      ? this.member.seeOutOfPolicy
      : true;
  }

  hasValidateOutOfPolicy(): boolean {
    return this.member.validateOutOfPolicy;
  }

  canCreateUser(): boolean {
    return this.member.canCreateUser;
  }

  hasSelectEntity(): boolean {
    return this.member.selectEntity ?? false;
  }

  mustSelectAService(): boolean {
    return this.member.mustSelectAService ?? false;
  }

  checkRoles(): void {
    if (!this.session.userId) {
    } else if (!this.society && this.session.societyId) {
      this.societyService.getSociety(this.session.societyId, {}, true).subscribe();
      return;
    }
    const index: number = this.society.members.findIndex((member: MemberSociety) => {
      if (typeof member.user === "string") {
        return member.user === this.session.userId;
      } else {
        return member.user._id === this.session.userId;
      }
    });

    if (index >= 0) {
      this.societyService.getMember(this.society.members[index]._id).subscribe((member: MemberSociety) => {
        this.member = member;
        if (this.is("executive")) {
          this.roles.next("executive");
        } else if (this.is("booker")) {
          this.roles.next("booker");
        } else if (this.is("accountant")) {
          this.roles.next("accountant");
        } else if (this.is("traveler")) {
          this.roles.next("traveler");
        }
      });
    }
  }
}
