// From: https://fr.wikipedia.org/wiki/ISO_3166-1 and https://www.iso.org/obp/ui/fr/#search
export default [
  { value: "AC", label: "Île de l'Ascension" },
  { value: "AD", label: "Andorre" },
  { value: "AE", label: "Émirats Arabes Unis" },
  { value: "AF", label: "Afghanistan" },
  { value: "AG", label: "Antigua-et-Barbuda" },
  { value: "AI", label: "Anguilla" },
  { value: "AL", label: "Albanie" },
  { value: "AM", label: "Arménie" },
  { value: "AO", label: "Angola" },
  { value: "AQ", label: "Antarctique" },
  { value: "AR", label: "Argentine" },
  { value: "AS", label: "Samoa Américaines" },
  { value: "AT", label: "Autriche" },
  { value: "AU", label: "Australie" },
  { value: "AW", label: "Aruba" },
  { value: "AX", label: "Îles Åland" },
  { value: "AZ", label: "Azerbaïdjan" },
  { value: "BA", label: "Bosnie-Herzégovine" },
  { value: "BB", label: "Barbade" },
  { value: "BD", label: "Bangladesh" },
  { value: "BE", label: "Belgique" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BG", label: "Bulgarie" },
  { value: "BH", label: "Bahreïn" },
  { value: "BI", label: "Burundi" },
  { value: "BJ", label: "Bénin" },
  { value: "BL", label: "Saint-Barthélemy" },
  { value: "BM", label: "Bermudes" },
  { value: "BN", label: "Brunéi Darussalam" },
  { value: "BO", label: "Bolivie" },
  { value: "BQ", label: "Bonaire, Saint-Eustache et Saba" },
  { value: "BR", label: "Brésil" },
  { value: "BS", label: "Bahamas" },
  { value: "BT", label: "Bhoutan" },
  { value: "BV", label: "Île Bouvet" },
  { value: "BW", label: "Botswana" },
  { value: "BY", label: "Bélarus" },
  { value: "BZ", label: "Belize" },
  { value: "CA", label: "Canada" },
  { value: "CC", label: "Îles Cocos (Keeling)" },
  { value: "CD", label: "République Démocratique du Congo" },
  { value: "CF", label: "République centrafricaine" },
  { value: "CG", label: "Congo" },
  { value: "CH", label: "Suisse" },
  { value: "CI", label: "Côte d'Ivoire" },
  { value: "CK", label: "Îles Cook" },
  { value: "CL", label: "Chili" },
  { value: "CM", label: "Cameroun" },
  { value: "CN", label: "Chine" },
  { value: "CO", label: "Colombie" },
  { value: "CP", label: "Île Clipperton" },
  { value: "CQ", label: "Sercq" },
  { value: "CR", label: "Costa Rica" },
  { value: "CU", label: "Cuba" },
  { value: "CV", label: "Cap-Vert" },
  { value: "CW", label: "Curaçao" },
  { value: "CX", label: "Île Christmas" },
  { value: "CY", label: "Chypre" },
  { value: "CZ", label: "République Tchèque" },
  { value: "DE", label: "Allemagne" },
  { value: "DG", label: "Diego Garcia" },
  { value: "DJ", label: "Djibouti" },
  { value: "DK", label: "Danemark" },
  { value: "DM", label: "Dominique" },
  { value: "DO", label: "République dominicaine" },
  { value: "DZ", label: "Algérie" },
  { value: "EA", label: "Ceuta et Melilla" },
  { value: "EC", label: "Équateur" },
  { value: "EE", label: "Estonie" },
  { value: "EG", label: "Égypte" },
  { value: "EH", label: "Sahara Occidental" },
  { value: "ER", label: "Érythrée" },
  { value: "ES", label: "Espagne" },
  { value: "ET", label: "Éthiopie" },
  { value: "EU", label: "Union Européenne" },
  { value: "EZ", label: "Zone euro" },
  { value: "FI", label: "Finlande" },
  { value: "FJ", label: "Fidji" },
  { value: "FK", label: "Falkland, Îles Malouines" },
  { value: "FM", label: "États Fédérés de Micronésie" },
  { value: "FO", label: "Îles Féroé" },
  { value: "FR", label: "France" },
  { value: "GA", label: "Gabon" },
  { value: "GB", label: "Royaume-Uni" },
  { value: "GD", label: "Grenade" },
  { value: "GE", label: "Géorgie" },
  { value: "GF", label: "Guyane Française" },
  { value: "GG", label: "Guernesey" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GL", label: "Groenland" },
  { value: "GM", label: "Gambie" },
  { value: "GN", label: "Guinée" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GQ", label: "Guinée Équatoriale" },
  { value: "GR", label: "Grèce" },
  { value: "GS", label: "Géorgie du Sud-et-les Îles Sandwich du Sud" },
  { value: "GT", label: "Guatemala" },
  { value: "GU", label: "Guam" },
  { value: "GW", label: "Guinée-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HK", label: "Hong Kong" },
  { value: "HM", label: "Île Heard et Îles MacDonald" },
  { value: "HN", label: "Honduras" },
  { value: "HR", label: "Croatie" },
  { value: "HT", label: "Haïti" },
  { value: "HU", label: "Hongrie" },
  { value: "IC", label: "Îles Canaries" },
  { value: "ID", label: "Indonésie" },
  { value: "IE", label: "Irlande" },
  { value: "IL", label: "Israël" },
  { value: "IM", label: "Île de Man" },
  { value: "IN", label: "Inde" },
  { value: "IO", label: "Territoire Britannique de l'Océan Indien" },
  { value: "IQ", label: "Iraq" },
  { value: "IR", label: "Iran" },
  { value: "IS", label: "Islande" },
  { value: "IT", label: "Italie" },
  { value: "JE", label: "Jersey" },
  { value: "JM", label: "Jamaïque" },
  { value: "JO", label: "Jordanie" },
  { value: "JP", label: "Japon" },
  { value: "KE", label: "Kenya" },
  { value: "KG", label: "Kirghizistan" },
  { value: "KH", label: "Cambodge" },
  { value: "KI", label: "Kiribati" },
  { value: "KM", label: "Comores" },
  { value: "KN", label: "Saint-Kitts-et-Nevis" },
  { value: "KP", label: "République Populaire Démocratique de Corée" },
  { value: "KR", label: "République de Corée" },
  { value: "KW", label: "Koweït" },
  { value: "KY", label: "Îles Caïmans" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "LA", label: "République Démocratique Populaire Lao" },
  { value: "LB", label: "Liban" },
  { value: "LC", label: "Sainte-Lucie" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LK", label: "Sri Lanka" },
  { value: "LR", label: "Libéria" },
  { value: "LS", label: "Lesotho" },
  { value: "LT", label: "Lituanie" },
  { value: "LU", label: "Luxembourg" },
  { value: "LV", label: "Lettonie" },
  { value: "LY", label: "Libye" },
  { value: "MA", label: "Maroc" },
  { value: "MC", label: "Monaco" },
  { value: "MD", label: "République de Moldova" },
  { value: "ME", label: "Monténégro" },
  { value: "MF", label: "Saint-Martin (Partie Française)" },
  { value: "MG", label: "Madagascar" },
  { value: "MH", label: "Îles Marshall" },
  { value: "MK", label: "Macédoine du Nord" },
  { value: "ML", label: "Mali" },
  { value: "MM", label: "Myanmar" },
  { value: "MN", label: "Mongolie" },
  { value: "MO", label: "Macao" },
  { value: "MP", label: "Îles Mariannes du Nord" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauritanie" },
  { value: "MS", label: "Montserrat" },
  { value: "MT", label: "Malte" },
  { value: "MU", label: "Maurice" },
  { value: "MV", label: "Maldives" },
  { value: "MW", label: "Malawi" },
  { value: "MX", label: "Mexique" },
  { value: "MY", label: "Malaisie" },
  { value: "MZ", label: "Mozambique" },
  { value: "NA", label: "Namibie" },
  { value: "NC", label: "Nouvelle-Calédonie" },
  { value: "NE", label: "Niger" },
  { value: "NF", label: "Île Norfolk" },
  { value: "NG", label: "Nigéria" },
  { value: "NI", label: "Nicaragua" },
  { value: "NL", label: "Pays-Bas" },
  { value: "NO", label: "Norvège" },
  { value: "NP", label: "Népal" },
  { value: "NR", label: "Nauru" },
  { value: "NU", label: "Niue" },
  { value: "NZ", label: "Nouvelle-Zélande" },
  { value: "OM", label: "Oman" },
  { value: "PA", label: "Panama" },
  { value: "PE", label: "Pérou" },
  { value: "PF", label: "Polynésie Française" },
  { value: "PG", label: "Papouasie-Nouvelle-Guinée" },
  { value: "PH", label: "Philippines" },
  { value: "PK", label: "Pakistan" },
  { value: "PL", label: "Pologne" },
  { value: "PM", label: "Saint-Pierre-et-Miquelon" },
  { value: "PN", label: "Pitcairn" },
  { value: "PR", label: "Porto Rico" },
  { value: "PS", label: "Palestine, État de" },
  { value: "PT", label: "Portugal" },
  { value: "PW", label: "Palaos" },
  { value: "PY", label: "Paraguay" },
  { value: "QA", label: "Qatar" },
  { value: "RE", label: "La Réunion" },
  { value: "RO", label: "Roumanie" },
  { value: "RS", label: "Serbie" },
  { value: "RU", label: "Fédération de Russie" },
  { value: "RW", label: "Rwanda" },
  { value: "SA", label: "Arabie Saoudite" },
  { value: "SB", label: "Îles Salomon" },
  { value: "SC", label: "Seychelles" },
  { value: "SD", label: "Soudan" },
  { value: "SE", label: "Suède" },
  { value: "SG", label: "Singapour" },
  { value: "SH", label: "Sainte-Hélène, Ascension et Tristan da Cunha" },
  { value: "SI", label: "Slovénie" },
  { value: "SJ", label: "Svalbard et Île Jan Mayen" },
  { value: "SK", label: "Slovaquie" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SM", label: "Saint-Marin" },
  { value: "SN", label: "Sénégal" },
  { value: "SO", label: "Somalie" },
  { value: "SR", label: "Suriname" },
  { value: "SS", label: "Soudan du Sud" },
  { value: "ST", label: "Sao Tomé-et-Principe" },
  { value: "SU", label: "Union Soviétique (URSS)" },
  { value: "SV", label: "El Salvador" },
  { value: "SX", label: "Saint-Martin (Partie Néerlandaise)" },
  { value: "SY", label: "République Arabe Syrienne" },
  { value: "SZ", label: "Eswatini" },
  { value: "TA", label: "Tristan da Cunha" },
  { value: "TC", label: "Îles Turks-et-Caïcos" },
  { value: "TD", label: "Tchad" },
  { value: "TF", label: "Terres Australes Françaises" },
  { value: "TG", label: "Togo" },
  { value: "TH", label: "Thaïlande" },
  { value: "TJ", label: "Tadjikistan" },
  { value: "TK", label: "Tokelau" },
  { value: "TL", label: "Timor-Leste" },
  { value: "TM", label: "Turkménistan" },
  { value: "TN", label: "Tunisie" },
  { value: "TO", label: "Tonga" },
  { value: "TR", label: "Turquie" },
  { value: "TT", label: "Trinité-et-Tobago" },
  { value: "TV", label: "Tuvalu" },
  { value: "TW", label: "Taïwan" },
  { value: "TZ", label: "République-Unie de Tanzanie" },
  { value: "UA", label: "Ukraine" },
  { value: "UG", label: "Ouganda" },
  { value: "UK", label: "Royaume-Uni" },
  { value: "UM", label: "Îles Mineures éloignées des États-Unis" },
  { value: "UN", label: "Organisation des Nations unies (ONU)" },
  { value: "US", label: "États-Unis d'Amérique" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Ouzbékistan" },
  { value: "VA", label: "Saint-Siège (État De La Cité Du Vatican)" },
  { value: "VC", label: "Saint-Vincent-et-Les Grenadines" },
  { value: "VE", label: "République Bolivarienne du Venezuela" },
  { value: "VG", label: "Îles Vierges Britanniques" },
  { value: "VI", label: "Îles Vierges des États-Unis" },
  { value: "VN", label: "Viet Nam" },
  { value: "VU", label: "Vanuatu" },
  { value: "WF", label: "Wallis-et-Futuna" },
  { value: "WS", label: "Samoa" },
  { value: "YE", label: "Yémen" },
  { value: "YT", label: "Mayotte" },
  { value: "ZA", label: "Afrique du Sud" },
  { value: "ZM", label: "Zambie" },
  { value: "ZW", label: "Zimbabwe" },
];
