import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { BehaviorSubject } from "rxjs";
import { SearchEngineType } from "../../search/search-engine/search-engine-service";
import { NGX_LOADING_BAR_IGNORED } from "@ngx-loading-bar/http-client";

@Injectable({
  providedIn: "root",
})
export class FavoriteService {
  constructor(private httpClient: HttpClient) {}

  favorites: BehaviorSubject<any[]> = new BehaviorSubject<any>(null);

  get(type: SearchEngineType = null) {
    const params: HttpParams = new HttpParams({ fromObject: { type } });
    return this.httpClient
      .get(`${environment.api}/favorites`, {
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
        headers: {
          ignoreLoading: "true",
          ignoreErrorMessage: "true",
        },
        params,
      })
      .pipe(
        map((data: any) => {
          const result = data.map((favorite) => {
            favorite.isFavorite = true;
            return favorite;
          });
          this.favorites.next(result);
          return result;
        }),
      );
  }
}

export interface Favorite {
  id: string;
  type: "user" | "company";
  ref: string;
  label: string;
  engine: SearchEngineType;
  metadata: any;
  coordinates?: number[]; // longitude, latitude
  oad?: {
    departure: string;
    arrival: string;
  };
}
