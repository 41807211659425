import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { TrainService } from "src/app/travel/train/train.service";
import { UtilsTypes } from "../../../../../@shared/@types/utils";
import { CommonService } from "../../../../../@shared/services/common.service";
import { TrainTypes } from "../../../../../travel/train/train";

@Component({
  selector: "spt-basket-train-block",
  templateUrl: "./train-block.component.html",
  styleUrls: ["./train-block.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainBlockComponent {
  private _item: any;
  @Input() set item(value: any) {
    this._item = value;
    this.itemUpdated();
  }
  get item(): any {
    return this._item;
  }
  exchangeType: TrainTypes.SearchType;
  exchangeDirection: TrainTypes.OfferDirection;
  schedules: UtilsTypes.ObjectKey<Array<{ departure: UtilsTypes.TravelDate; arrival: UtilsTypes.TravelDate }>> = {
    outward: [],
    inward: [],
  };
  hasReturn: boolean;
  mappedJourneys: Array<TrainTypes.Journey>;
  journeys: TrainTypes.Journey[];

  constructor(
    public commonService: CommonService,
    private trainService: TrainService,
  ) {}

  getSchedules(): void {
    if (this.item.detail?.journeys.length > 0) {
      this.journeys = this.item.detail.journeys;
    } else {
      this.journeys = this.item.formData.itinerary.journeys;
    }
    const { mappedJourneys, schedules } = this.trainService.mapJourneys(this.journeys);
    this.mappedJourneys = mappedJourneys;
    this.schedules = schedules;
  }

  itemUpdated(): void {
    this.getSchedules();
    this.hasReturn =
      this.journeys.some((_journey: TrainTypes.Journey) => _journey.direction === "inward") && this.journeys.length > 1;

    const orderItem: TrainTypes.OrderItem = this.item.detail.orderItems.find(
      (_item: TrainTypes.OrderItem) => _item.type === "itinerarychange",
    );

    if (this.item.detail.orderItems.length > 2 && orderItem && orderItem.item) {
      if (this.hasReturn) {
        this.exchangeType = "Return";
      } else {
        this.exchangeType = "Single";
        this.exchangeDirection = orderItem.item.journeys[0].direction;
      }
    }
  }
}
