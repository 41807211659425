import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { CommonService } from "../../../../@shared/services/common.service";
import { TranslateService } from "@ngx-translate/core";
import { Favorite, FavoriteService } from "../../../../@shared/services/favorite.service";
import { UntypedFormGroup } from "@angular/forms";
import { FavoriteData } from "./travelFavorite";
import { AutoComplete } from "primeng/autocomplete";
import {
  LastSearchItem,
  Locality,
  SearchEngineService,
  Travel,
  TravelService,
  checkLocalityIsValid,
  SearchEngineType,
} from "src/app/search/search-engine/search-engine-service";
import { Config } from "../travel-search-autocomplete/travel-search-autocomplete.component";

@Component({
  selector: "spt-favorites",
  templateUrl: "./favorites.component.html",
  styleUrls: ["./favorites.component.scss"],
})
export class FavoritesComponent<LOCALITY_TYPE extends Locality> implements OnInit {
  @Input() i18n: any = {};

  @Input() searchService!: SearchEngineService<LOCALITY_TYPE, any>;
  @Input() label?: Config<string>;
  @Input() placeholder?: Config<string>;
  @Input() optionDestinationSameAsOriginLabel?: string;
  @Input() destinationSameAsOrigin: boolean = false;

  searchEngineType!: SearchEngineType;

  @Output() onSelectFavorite: EventEmitter<any> = new EventEmitter();
  @Output() onSelectLastSearch: EventEmitter<LastSearchItem<LOCALITY_TYPE>> = new EventEmitter();

  @Output() onChange: EventEmitter<LOCALITY_TYPE> = new EventEmitter<LOCALITY_TYPE>();

  @ViewChild("autoTripArrival") autoTripArrival: AutoComplete;
  @ViewChild("autoTripDeparture") autoTripDeparture: AutoComplete;
  searchString!: string;
  searchResults: LOCALITY_TYPE[] | null = null;

  public addFavorites: boolean = false;
  public predictions: Array<google.maps.places.AutocompletePrediction & { value: any }>;
  public favorites: any[] = [];
  public lastSearchs: LastSearchItem<LOCALITY_TYPE>[] = [];
  public title: string = "";
  form: UntypedFormGroup;

  newFavorite!: Travel<LOCALITY_TYPE>;
  newFavoriteLabel: string = "";
  newFavoriteValid: boolean = false;

  constructor(
    public commonService: CommonService,
    private favoriteService: FavoriteService,
    private translateService: TranslateService,
    private travelService: TravelService,
  ) {}

  ngOnInit(): void {
    if (this.searchService.getType() === "seminar" || this.searchService.getType() === "transfer") {
      console.info("Favorites component not aplicable to 'seminar' and 'transfer'");
      return;
    }
    this.searchEngineType = this.searchService.getType() as SearchEngineType;
    this.title = this.translateService.instant("PLACEFINDER.FAVORITES.DEFAULT");
    if (this.searchService.getType() === "hotel") {
      this.title = this.translateService.instant("PLACEFINDER.FAVORITES.HOTEL");
    } else if (this.searchService.getType() === "car") {
      this.title = this.translateService.instant("PLACEFINDER.FAVORITES.CAR");
    }
    this.newFavorite = this.searchService.createBlankCriteria().mainTravel;
    this.refreshFavoriteList();
    this.refreshLastSearch();
  }

  private refreshFavoriteList(): void {
    this.favoriteService.get(this.searchEngineType).subscribe((data: any[]) => {
      this.favorites = data.filter((f) => f.engine === this.searchEngineType);
    });
  }

  private refreshLastSearch(): void {
    this.searchService.lastSearches(3).subscribe((data: any) => {
      this.lastSearchs = data;
    });
  }

  checkNewFavoriteValidity(): void {
    this.newFavoriteValid =
      (!this.newFavorite.origin || checkLocalityIsValid(this.newFavorite.origin)) &&
      (!this.newFavorite.destination || checkLocalityIsValid(this.newFavorite.destination));
  }

  selectFavorite(favorite: any): void {
    this.searchString = favorite.label;
    this.newFavoriteValid = checkLocalityIsValid(favorite);
    this.onSelectFavorite.emit(favorite);
  }

  selectLastSearch(lastSearchItem: LastSearchItem<LOCALITY_TYPE>): void {
    this.onSelectLastSearch.emit(lastSearchItem);
  }

  private saveFavorite(favorite: FavoriteData) {
    if (favorite.oad) {
      favorite.oad = {
        departure: this.toOaD(favorite.oad.departure),
        arrival: this.toOaD(favorite.oad.arrival),
      };
    }
    this.travelService
      .addFavorite(favorite.type, favorite.label, favorite.engine, favorite)
      .subscribe((favorite: Favorite) => {
        this.refreshFavoriteList();
        this.addFavorites = false;
        this.newFavorite = this.searchService.createBlankCriteria().mainTravel;
      });
  }

  addFavorite() {
    const data: FavoriteData = {
      type: "user",
      engine: this.searchEngineType,
      label: this.getNewFavoriteLabel(),
      coordinates: this.newFavorite.destination["coordinates"],
      oad: {
        departure: this.newFavorite.origin,
        arrival: this.newFavorite.destination,
      },
    };
    if (data.engine === "hotel") {
      delete data.oad;
    }
    this.saveFavorite(data);
  }

  /**
   * Mise en conformité des données à sauvegarder.
   * Cf. spt-api-management:src/ms/favorite/favorite.validator.ts
   *
   * @param locality
   * @returns
   */
  private toOaD(locality: LOCALITY_TYPE) {
    if (!locality) {
      return undefined;
    }
    if (this.searchEngineType === "train") {
      return removeEmptyOrUndefinedProperties({
        name: locality["label"] || locality["name"],
        locationId: locality["locationId"],
        latitude: locality["latitude"],
        longitude: locality["longitude"],
        country: locality["country"],
        locationType: locality["locationType"],
        code: locality["code"],
        provider: locality["provider"],
        localizedName: locality["localizedName"],
        city: locality["city"],
      });
    }
    if (this.searchEngineType === "car") {
      return removeEmptyOrUndefinedProperties({
        place_id: locality["place_id"],
        coordinates: locality["coordinates"],
        label: locality["label"] || locality["name"],
        types: locality["types"],
        street: locality["street"],
        city: locality["city"],
        area: locality["area"],
        country: locality["country"],
        countryCode: locality["countryCode"],
      });
    }
    if (this.searchEngineType === "flight") {
      return removeEmptyOrUndefinedProperties({
        name: locality["label"] || locality["name"],
        country: locality["countryISO"] || locality["countryName"] || locality["country"],
        travelType: locality["travelType"],
        cityIATA: locality["cityIATA"],
        airportIATA: locality["airportIATA"] || locality["iata"],
      });
    }
    return undefined;
  }

  addLastSearchToFavorite(lastSearch: LastSearchItem<LOCALITY_TYPE>) {
    const favorite: FavoriteData = {
      type: "user",
      engine: this.searchEngineType,
      label: lastSearch.label,
      coordinates:
        (lastSearch.travel.destination && lastSearch.travel.destination["coordinates"]) ||
        (lastSearch.travel.origin && lastSearch.travel.origin["coordinates"]) ||
        undefined,
    };
    if (this.searchEngineType !== "hotel") {
      favorite.oad = {
        departure: lastSearch.travel.origin,
        arrival: lastSearch.travel.destination,
      };
    }
    this.saveFavorite(favorite);
  }

  /**
   * Permet de récupérer le libellé d'un favoris.
   * Si le favoris n'est pas de type Hotel, le libellé doit être construit comme suit :
   * ${origin.label} - ${destination.label}
   */
  private getNewFavoriteLabel(): string {
    if (this.searchEngineType === "hotel") {
      return this.newFavoriteLabel || this.newFavorite.destination?.label;
    }
    return `${this.newFavorite.origin?.label} - ${this.newFavorite.destination?.label}`;
  }

  removeFavorite(favorite: any) {
    this.travelService.removeFavorite(favorite.id).subscribe(() => this.refreshFavoriteList());
  }
}

function removeEmptyOrUndefinedProperties(data) {
  return Object.fromEntries(
    Object.entries(data).filter(
      ([property, value]) =>
        value != null &&
        value != undefined &&
        // Dans le cas d'une chaine de caractères, si la chaîne est vide, on doit l'ignorer
        (typeof value !== "string" || value.length > 0),
    ),
  );
}
