import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "kiloFormatter",
})
export class KiloFormatterPipe implements PipeTransform {
  transform(value: number, lang?: string): string {
    if (value > 1000) {
      return (value / 1000).toFixed(2) + "T";
    } else if (value !== 0 && value < 1) {
      return (value * 1000).toFixed(2) + "g";
    } else if (value !== 0) {
      return value.toFixed(2) + "kg";
    } else {
      return "0";
    }
  }
}
