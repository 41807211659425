import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { CommonService } from "../../../../../@shared/services/common.service";

@Component({
  selector: "spt-basket-fee-block",
  templateUrl: "./fee-block.component.html",
  styleUrls: ["./fee-block.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FeeBlockComponent implements OnInit {
  @Input() item: any;

  constructor(public commonService: CommonService) {}

  ngOnInit(): void {}
}
