<div class="conditions-container">
  <div class="title" translate>
    CAR.LOCAT_CONDITIONS_LABEL
    <span class="farePrice">{{ car.offer?.totalPrice ?? item.price | SPTPrice | async }}</span>
  </div>
  <div class="conditions-content">
    <ul *ngIf="car">
      <ng-container *ngFor="let advantage of car.offer?.advantages">
        <li *ngIf="advantage.value" translate>SEARCH.CAR.ADVANTAGES.LABELS.{{ advantage.key }}</li>
      </ng-container>
    </ul>

    <div class="conditions-content-detail">
      <div class="duration">
        {{ "CAR.LOCATION_DURATION" | translate }} :&nbsp; {{ locationDuration }} <span translate>GLOBAL.DAY</span
        >{{ locationDuration > 1 ? "s" : "" }}
      </div>

      <div class="out-of-policy-container">
        <span class="out-of-policy" translate *ngIf="car.offer?.inPolicy === false">GLOBAL.OUT_OF_POLICY</span>
      </div>
    </div>
  </div>
</div>
