import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { concatMap, map } from "rxjs/operators";
import { from, Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CarService {
  constructor(private httpClient: HttpClient) {}

  search(
    searchId: string,
    _pickup: any,
    _return: any,
    pickupDate: string,
    pickupTime: string,
    returnDate: string,
    returnTime: string,
    radius: number = 5,
    agencySearch?: any,
  ) {
    const endpoint: string = `${environment.api}/cars/search/${searchId}`;

    const params: any = {
      pdt: `${pickupDate}T${pickupTime}`,
      rdt: `${returnDate}T${returnTime}`,
      r: radius,
    };

    if (agencySearch) {
      if (agencySearch.length > 1) {
        let Pickup = { ...agencySearch.filter((search) => search.isPickUp)[0] };
        let DropOff = { ...agencySearch.filter((search) => search.isDropOff)[0] };

        if (Pickup) {
          params.pickUpExtendedLocationCode = Pickup?.extendedLocationCode;
          params.pickUpLocationCode = Pickup?.locationCode;
          params.vendor = Pickup?.vendor;
        }
        if (DropOff) {
          params.dropOffExtendedLocationCode = DropOff?.extendedLocationCode;
          params.dropOffLocationCode = DropOff?.locationCode;
        }
      } else {
        params.extendedLocationCode = agencySearch.extendedLocationCode;
        params.locationCode = agencySearch.locationCode;
        params.vendor = agencySearch.vendor;
      }
    }

    if (typeof _pickup === "string") {
      params.pairport = _pickup;
    } else {
      params.plat = _pickup[0];
      params.plong = _pickup[1];
    }

    if (typeof _return === "string") {
      params.rairport = _return;
    } else {
      params.rlat = _return[0];
      params.rlong = _return[1];
    }

    return this.httpClient
      .get(endpoint, {
        params,
        headers: { ignoreErrorMessage: "true" },
      })
      .pipe(
        map((res: any) => {
          const data = {
            ...res,
            // Fill car with data agency
            cars: res.cars.map((car) => {
              car.pickup.agency = res.agencies[car.agencyName][car.pickup.agencyRef];
              car.return.agency = res.agencies[car.agencyName][car.return.agencyRef];

              car.startPrice = {
                amount: Math.floor(Math.min(...car.offers.map((c) => c.totalPrice.amount))) + 1,
                currency: car.offers[0].totalPrice.currency,
              };

              car.offers.map((offer) => {
                offer.name = res.agenciesPackagesAdvantages[car.agencyName][offer.packageCategory].name;
                offer.advantages = res.agenciesPackagesAdvantages[car.agencyName][offer.packageCategory].benefits;

                return offer;
              });

              return car;
            }),
          };

          return data;
        }),
      );
  }

  // ####################################
  // LEGACY
  // ####################################

  getSearchResults(id: string): Observable<any> {
    return this.httpClient.get(`${environment.api}/search/${id}/results`);
  }

  private carEndpoint: string = "car-rent";

  public searchLocation(
    localisationOptions: any,
    pickupDate: any,
    returnDate: any,
    dropOffAddress?: any,
  ): Observable<any> {
    const endPoint: string = `${environment.api}/api/${this.carEndpoint}/search`;
    return this.httpClient
      .post(endPoint, {
        address: localisationOptions,
        pickupDate,
        returnDate,
        dropOffAddress,
      })
      .pipe(
        map((res: any) => {
          if (res && res.data) {
            res.data.data = res.data.data.map((agency: any) => {
              agency.LocationDetails.newDistance =
                Math.round(
                  this.distance(
                    localisationOptions.lat,
                    localisationOptions.long,
                    agency.LocationDetails.Address.Latitude,
                    agency.LocationDetails.Address.Longitude,
                  ) / 100,
                ) / 10;
              agency.LocationDetails.newUnitOfMeasure = "KM";
              if (agency.DropOffLocationDetails) {
                agency.DropOffLocationDetails.newDistance =
                  Math.round(
                    this.distance(
                      localisationOptions.lat,
                      localisationOptions.long,
                      agency.DropOffLocationDetails.Address.Latitude,
                      agency.DropOffLocationDetails.Address.Longitude,
                    ) / 100,
                  ) / 10;
                agency.DropOffLocationDetails.newUnitOfMeasure = "KM";
              }
              return agency;
            });
            return res.data;
          }
          return res;
        }),
      );
  }

  searchAvailabilities(objectOptions: Array<any>): Observable<any> {
    const endPoint: string = `${environment.api}/api/${this.carEndpoint}/availabilities`;
    const headers: HttpHeaders = new HttpHeaders({
      ignoreLoadingBar: "true",
      ignoreErrorMessage: "true",
    });

    return from(objectOptions).pipe(
      concatMap((agency: any): any => {
        return this.httpClient.post(endPoint, agency, { headers }).pipe(
          catchError((error: any) => {
            // throw error === end so use of and manage error in data
            return of(error);
          }),
        );
      }),
    );
  }

  distance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const p: number = 0.017453292519943295; // Math.PI / 180
    const c: (x: number) => number = Math.cos;
    const a: number = 0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

    return 12742000 * Math.asin(Math.sqrt(a)); // 2 * R * 1000; R = 6371 km
  }

  public bookRentalCar(rentalCarOptions: any): Observable<any> {
    const endpoint: string = `${environment.api}/api/booking`;
    return this.httpClient.post(endpoint, rentalCarOptions);
  }
}
