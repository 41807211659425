import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "src/app/@shared/services/common.service";

// Les clés de traduction ne sont pas consistantes/cohérantes pour le type 'flight' (déclaré sous la clé 'air')
const I18N_KEY = {
  flight: {
    subscriber: {
      name: "SEARCH.RESULT.AIR.FILTERS.SUBSCRIBER_RATE",
      description: "GLOBAL.FLIGHT_TARIFS.INFORMATION.SUBSCRIBER_RATE",
    },
    negociated: {
      name: "SEARCH.RESULT.AIR.FILTERS.NEGOCIATED_RATE",
      description: "GLOBAL.FLIGHT_TARIFS.INFORMATION.NEGOCIATED_RATE",
    },
  },
  hotel: {
    negociated: {
      name: "SEARCH.PROVIDERS.SAB",
      description: "GLOBAL.HOTEL_TARIFS",
    },
  },
  train: {
    subscriber: {
      name: "SEARCH.RESULT.RAIL.TARIF.SUBSCRIBER_RATE",
      description: "GLOBAL.RAIL_TARIFS.INFORMATION.SUBSCRIBER_RATE",
    },
    negociated: {
      name: "SEARCH.RESULT.RAIL.TARIF.NEGOCIATED_RATE",
      description: "GLOBAL.RAIL_TARIFS.INFORMATION.NEGOCIATED_RATE",
    },
  },
};

@Component({
  selector: "spt-specific-rate",
  templateUrl: "./specific-rate.component.html",
  styleUrls: ["./specific-rate.component.scss"],
})
export class SpecificRateComponent implements OnInit {
  @Input() subscriber: boolean;
  @Input() negociated: boolean;
  @Input() type: string = "";
  @Input() position?: string;
  name: string;
  description: string;

  constructor(
    public commonService: CommonService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    if (!this.position) {
      this.position = this.commonService.isTablet ? "top" : "left";
    }
    const i18n = I18N_KEY[this.type]?.[this.subscriber ? "subscriber" : this.negociated ? "negociated" : ""];
    if (i18n) {
      this.name = this.translateService.instant(i18n.name);
      this.description = this.translateService.instant(i18n.description);
    }
  }
}
