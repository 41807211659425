<div class="car" [ngClass]="{ expanded: expanded, promote: promote }">
  <div class="promote-head" *ngIf="promote">
    <span translate>SEARCH.CAR.PROMOTE_CARBON_OFFSET</span>
  </div>
  <div
    class="flex justify-content-center items-center distance"
    *ngIf="isANumber(car.pickup.agencyDistance) && (commonService.isTabletObservable | async) !== true"
  >
    <div class="flex justify-content-center items-center">
      <svg width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.17391 0C1.84845 0 0 1.86 0 4.2C0 7.32 4.17391 12 4.17391 12C4.17391 12 8.34783 7.32 8.34783 4.2C8.34783 1.86 6.49938 0 4.17391 0ZM4.17391 5.7C3.33913 5.7 2.68323 5.04 2.68323 4.2C2.68323 3.36 3.33913 2.7 4.17391 2.7C5.0087 2.7 5.6646 3.36 5.6646 4.2C5.6646 5.04 5.0087 5.7 4.17391 5.7Z"
          fill="#604FD7"
        />
      </svg>
    </div>

    <p>{{ Math.round(car.pickup.agencyDistance) }} km</p>
  </div>

  <div class="triangle"></div>
  <div (click)="expanded = !expanded" class="content">
    <div class="images">
      <img
        class="car-picture"
        [src]="car.carImage"
        (error)="car.carImage = car.sabreCarImage ?? './assets/svg/default-car.svg'"
        loading="lazy"
        [alt]="car.acrissCode"
      />

      <spt-car-provider-image [providerName]="car.agencyName"></spt-car-provider-image>
    </div>

    <div>
      <div>
        <div class="car-model">{{ car.carModel }}</div>
        <div class="car-name">{{ car.carName }}</div>
        <div class="location">
          <div class="address">
            {{ displayingAddress }}
            <div class="city">
              <div class="ml-1">
                {{ car.pickup.agency.PickUp.location.Address.CityName.value | titlecase }}
              </div>

              <div class="ml-1" *ngIf="car.pickup.agency.PickUp.location.Address.PostalCode">
                ({{ car.pickup.agency.PickUp.location.Address.PostalCode }})
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="features">
        <div class="item">
          <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.2727 13.6285V4.37154C16.1625 4.06273 16.8 3.22988 16.8 2.25C16.8 1.00705 15.7746 0 14.5091 0C13.2435 0 12.2182 1.00705 12.2182 2.25C12.2182 3.22988 12.8556 4.06273 13.7455 4.37154V8.25001H9.16364V4.37154C10.0534 4.06273 10.6909 3.22988 10.6909 2.25C10.6909 1.00705 9.66553 0 8.39998 0C7.13443 0 6.10907 1.00705 6.10907 2.25C6.10907 3.22988 6.74652 4.06273 7.63636 4.37154V8.25001H3.05454V4.37154C3.94434 4.06273 4.58182 3.22988 4.58182 2.25C4.58182 1.00705 3.55646 0 2.29091 0C1.02536 0 0 1.00705 0 2.25C0 3.22988 0.637446 4.06273 1.52729 4.37154V9C1.52729 9.41421 1.86917 9.74999 2.29091 9.74999H7.63636V13.6285C6.74652 13.9373 6.10907 14.7701 6.10907 15.75C6.10907 16.9929 7.13443 18 8.39998 18C9.66553 18 10.6909 16.9929 10.6909 15.75C10.6909 14.7701 10.0534 13.9373 9.16361 13.6285V9.74999H13.7454V13.6285C12.8556 13.9373 12.2181 14.7701 12.2181 15.75C12.2181 16.9929 13.2435 18 14.5091 18C15.7746 18 16.8 16.9929 16.8 15.75C16.8 14.7701 16.1625 13.9373 15.2727 13.6285ZM14.5091 1.50001C14.9312 1.50001 15.2727 1.83547 15.2727 2.25C15.2727 2.66453 14.9312 2.99999 14.5091 2.99999C14.087 2.99999 13.7454 2.66453 13.7454 2.25C13.7454 1.83547 14.087 1.50001 14.5091 1.50001ZM8.39998 1.50001C8.82205 1.50001 9.16361 1.83547 9.16361 2.25C9.16361 2.66453 8.82205 2.99999 8.39998 2.99999C7.97792 2.99999 7.63636 2.66453 7.63636 2.25C7.63636 1.83547 7.97792 1.50001 8.39998 1.50001ZM2.29087 1.50001C2.71294 1.50001 3.0545 1.83547 3.0545 2.25C3.0545 2.66453 2.71294 2.99999 2.29087 2.99999C1.86881 2.99999 1.52725 2.66453 1.52725 2.25C1.52725 1.83547 1.86881 1.50001 2.29087 1.50001ZM8.39998 16.5C7.97792 16.5 7.63636 16.1645 7.63636 15.75C7.63636 15.3355 7.97792 15 8.39998 15C8.82205 15 9.16361 15.3355 9.16361 15.75C9.16361 16.1645 8.82205 16.5 8.39998 16.5ZM14.5091 16.5C14.087 16.5 13.7455 16.1645 13.7455 15.75C13.7455 15.3355 14.087 15 14.5091 15C14.9312 15 15.2727 15.3355 15.2727 15.75C15.2727 16.1645 14.9312 16.5 14.5091 16.5Z"
              fill="#312F65"
            />
          </svg>

          <p *ngIf="car.gearboxType === 'Manual'" translate>SEARCH.RESULT.CAR.MANUAL_GEARBOX</p>
          <p *ngIf="car.gearboxType === 'Automatic'" translate>SEARCH.RESULT.CAR.AUTOMATIC_GEARBOX</p>
        </div>

        <div class="item electric" *ngIf="car.isElectric">
          <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.0816 13.8566H21.2957C21.8874 13.8566 22.3671 13.3769 22.3671 12.7852V9.17583C22.3671 8.7341 22.096 8.33769 21.6845 8.17741L17.0102 6.35691L13.796 1H5.22498L3.08222 6.35691H2.01084C1.41913 6.35691 0.939453 6.83657 0.939453 7.4283V12.7852C0.939453 13.3769 1.41913 13.8566 2.01084 13.8566H5.22498M18.0816 13.8566C18.0816 15.04 17.1223 15.9994 15.9388 15.9994C14.7554 15.9994 13.796 15.04 13.796 13.8566M18.0816 13.8566C18.0816 12.6731 17.1223 11.7138 15.9388 11.7138C14.7554 11.7138 13.796 12.6731 13.796 13.8566M5.22498 13.8566C5.22498 15.04 6.18433 15.9994 7.36775 15.9994C8.55117 15.9994 9.51051 15.04 9.51051 13.8566M5.22498 13.8566C5.22498 12.6731 6.18433 11.7138 7.36775 11.7138C8.55117 11.7138 9.51051 12.6731 9.51051 13.8566M13.796 13.8566H9.51051"
              stroke="#312F65"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <p>Electrique</p>
        </div>

        <div class="item" *ngIf="car.hasAirConditioning">
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.95859 8.9L2.075 7.89013L2.97851 7.09971L4.55359 8.9H7.56022C7.61687 8.68214 7.70322 8.47783 7.81502 8.2885L5.68745 6.16103L3.30078 6.32011L3.2211 5.12304L4.56007 5.03373L3.08457 3.5583L3.93301 2.70986L5.40869 4.18547L5.49805 2.84609L6.6957 2.92578L6.53614 5.31285L8.66346 7.44006C8.85279 7.32825 9.0571 7.24183 9.275 7.18518V4.17793L7.475 2.60293L8.26485 1.7L9.275 2.5837V0.5H10.475V2.5837L11.4852 1.7L12.275 2.60293L10.475 4.17793V7.18518C10.6929 7.24183 10.8972 7.32825 11.0865 7.44006L13.2139 5.31285L13.0543 2.92578L14.252 2.8461L14.3413 4.18548L15.817 2.70987L16.6654 3.5583L15.1899 5.03373L16.5289 5.12305L16.4492 6.32012L14.0625 6.16104L11.935 8.2885C12.0468 8.47783 12.1331 8.68214 12.1898 8.9H15.1964L16.7715 7.09971L17.675 7.89013L16.7914 8.9H18.875V10.1H16.7914L17.675 11.1099L16.7715 11.9003L15.1964 10.1H12.1898C12.1331 10.3179 12.0468 10.5222 11.935 10.7115L14.0625 12.839L16.4492 12.6799L16.5289 13.877L15.1899 13.9663L16.6654 15.4417L15.817 16.2901L14.3413 14.8145L14.252 16.1539L13.0543 16.0742L13.2139 13.6871L11.0865 11.5599C10.8972 11.6717 10.6929 11.7582 10.475 11.8148V14.8221L12.275 16.3971L11.4852 17.3L10.475 16.4163V18.5H9.275V16.4163L8.26485 17.3L7.475 16.3971L9.275 14.8221V11.8148C9.0571 11.7582 8.8528 11.6717 8.66346 11.5599L6.53614 13.6872L6.6957 16.0742L5.49805 16.1539L5.40869 14.8145L3.93301 16.2901L3.08457 15.4417L4.56007 13.9663L3.2211 13.877L3.30078 12.6799L5.68745 12.839L7.81502 10.7115C7.70322 10.5222 7.61687 10.3179 7.56022 10.1H4.55359L2.97851 11.9003L2.075 11.1099L2.95859 10.1H0.875V8.9H2.95859ZM9.875 10.7C10.5365 10.7 11.075 10.1618 11.075 9.5C11.075 8.83818 10.5365 8.3 9.875 8.3C9.21348 8.3 8.675 8.83818 8.675 9.5C8.675 10.1618 9.21348 10.7 9.875 10.7Z"
              fill="#312F65"
            />
          </svg>

          <p>Climatiseur</p>
        </div>

        <div class="item">
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.875 0.625H6.12505C5.9496 0.625 5.77972 0.683727 5.64491 0.790938L1.14493 4.37048C0.97395 4.5065 0.875073 4.70799 0.875073 4.92045V8.49857L0.875 8.5V15.6591C0.875 16.0545 1.21078 16.375 1.625 16.375H15.8749C16.2891 16.375 16.6249 16.0545 16.6249 15.6591V8.50143L16.625 8.5V1.34091C16.625 0.945516 16.2892 0.625 15.875 0.625ZM15.125 7.78409H5.37505V6.6853L7.5703 4.92045H15.125V7.78409ZM2.37507 5.25576L6.3966 2.05682H15.125V3.48864H7.29656C7.11975 3.48864 6.94866 3.54827 6.81342 3.65695L4.14192 5.80468C3.97273 5.94069 3.87506 6.1411 3.87506 6.35224V7.78406H2.37507V5.25576ZM15.1249 14.9432C7.40611 14.9432 10.4378 14.9432 2.37499 14.9432V9.21591H15.1249V14.9432Z"
              fill="#312F65"
            />
          </svg>

          <p>{{ car.doorCount }}</p>
        </div>

        <div class="item">
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.36853 5.73423C6.83967 5.43444 8.33198 6.15188 8.91907 7.44114L10.1715 10.1924L14.1396 9.56953C14.5031 9.51245 14.8584 9.70556 14.9833 10.0281L16.468 13.8629C16.9205 14.9902 16.365 16.2602 15.1864 16.7771C13.9151 17.334 12.4002 16.8106 11.8289 15.617L11.2716 14.4527L6.08139 15.4076C5.71321 15.4753 5.34685 15.2837 5.21801 14.9559L3.1085 9.59038C2.44196 7.93842 3.5085 6.1144 5.36853 5.73423ZM4.53733 9.09838L6.42525 13.9002L11.5726 12.9533C11.9215 12.8891 12.2718 13.058 12.4164 13.3601L13.2209 15.041C13.4448 15.5088 14.0385 15.7139 14.5367 15.4957C14.9984 15.2932 15.2161 14.7955 15.0375 14.3505L13.7653 11.065L9.83144 11.6825C9.48341 11.7372 9.14062 11.5624 9.00303 11.2602L7.51592 7.9934C7.21488 7.3323 6.44932 6.96425 5.69514 7.11795C4.74145 7.31288 4.1944 8.24846 4.53733 9.09838Z"
              fill="#312F65"
            />
            <path
              d="M3.6723 0C5.35539 0 6.71961 1.26841 6.71961 2.83328C6.71961 4.39815 5.35539 5.66656 3.6723 5.66656C1.98922 5.66656 0.625 4.39815 0.625 2.83328C0.625 1.26841 1.98919 0 3.6723 0ZM3.6723 4.24994C4.5139 4.24994 5.19597 3.6158 5.19597 2.83328C5.19597 2.05076 4.51394 1.41662 3.6723 1.41662C2.83067 1.41662 2.14863 2.05076 2.14863 2.83328C2.14863 3.6158 2.83067 4.24994 3.6723 4.24994Z"
              fill="#312F65"
            />
          </svg>

          <p>
            {{ car.passengerPlaceCountSabre ? car.passengerPlaceCountSabre : car.passengerPlaceCount }}
          </p>
        </div>
      </div>
    </div>

    <div class="price-box">
      <p translate>CAR.PRICE_FROM</p>

      <p class="amount">
        {{ car.startPrice | SPTPrice | async }}
      </p>
    </div>
  </div>

  <div (click)="expanded = !expanded" class="content-mobile flex-col">
    <!-- First line -->
    <div class="flex justify-content-between items-center">
      <div class="flex">
        <p class="car-agency">{{ car.agencyName }}</p>
        <p class="car-model">{{ car.carModel }}</p>
      </div>
      <div class="flex justify-content-end" *ngIf="isANumber(car.pickup.agencyDistance)">
        <p>{{ Math.round(car.pickup.agencyDistance) }} km</p>
      </div>
    </div>

    <!-- Seconde line -->
    <div class="flex justify-content-between items-center second">
      <div class="flex">
        <img [src]="car.carImage" loading="lazy" class="mr-2" />

        <div class="flex flex-col">
          <p class="name">{{ car.carName }}</p>
          <div class="flex justify-content-center items-center">
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 5L1.19983 0L0 1.24978L3.59949 5L0 8.75022L1.19983 10L6 5Z" fill="#312F65" />
            </svg>

            <div class="address ml-1">
              <p>
                {{ displayingAddress }}
              </p>
              <p>
                {{ car.pickup.agency.PickUp.location.Address.CityName.value | titlecase }}
                ({{ car.pickup.agency.PickUp.location.Address.PostalCode }})
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="price">
        <p translate>CAR.PRICE_FROM</p>
        {{ car.startPrice | SPTPrice | async }}
      </div>
    </div>

    <!-- Details -->
    <div class="details flex items-center third">
      <div class="item">
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.2727 13.6285V4.37154C16.1625 4.06273 16.8 3.22988 16.8 2.25C16.8 1.00705 15.7746 0 14.5091 0C13.2435 0 12.2182 1.00705 12.2182 2.25C12.2182 3.22988 12.8556 4.06273 13.7455 4.37154V8.25001H9.16364V4.37154C10.0534 4.06273 10.6909 3.22988 10.6909 2.25C10.6909 1.00705 9.66553 0 8.39998 0C7.13443 0 6.10907 1.00705 6.10907 2.25C6.10907 3.22988 6.74652 4.06273 7.63636 4.37154V8.25001H3.05454V4.37154C3.94434 4.06273 4.58182 3.22988 4.58182 2.25C4.58182 1.00705 3.55646 0 2.29091 0C1.02536 0 0 1.00705 0 2.25C0 3.22988 0.637446 4.06273 1.52729 4.37154V9C1.52729 9.41421 1.86917 9.74999 2.29091 9.74999H7.63636V13.6285C6.74652 13.9373 6.10907 14.7701 6.10907 15.75C6.10907 16.9929 7.13443 18 8.39998 18C9.66553 18 10.6909 16.9929 10.6909 15.75C10.6909 14.7701 10.0534 13.9373 9.16361 13.6285V9.74999H13.7454V13.6285C12.8556 13.9373 12.2181 14.7701 12.2181 15.75C12.2181 16.9929 13.2435 18 14.5091 18C15.7746 18 16.8 16.9929 16.8 15.75C16.8 14.7701 16.1625 13.9373 15.2727 13.6285ZM14.5091 1.50001C14.9312 1.50001 15.2727 1.83547 15.2727 2.25C15.2727 2.66453 14.9312 2.99999 14.5091 2.99999C14.087 2.99999 13.7454 2.66453 13.7454 2.25C13.7454 1.83547 14.087 1.50001 14.5091 1.50001ZM8.39998 1.50001C8.82205 1.50001 9.16361 1.83547 9.16361 2.25C9.16361 2.66453 8.82205 2.99999 8.39998 2.99999C7.97792 2.99999 7.63636 2.66453 7.63636 2.25C7.63636 1.83547 7.97792 1.50001 8.39998 1.50001ZM2.29087 1.50001C2.71294 1.50001 3.0545 1.83547 3.0545 2.25C3.0545 2.66453 2.71294 2.99999 2.29087 2.99999C1.86881 2.99999 1.52725 2.66453 1.52725 2.25C1.52725 1.83547 1.86881 1.50001 2.29087 1.50001ZM8.39998 16.5C7.97792 16.5 7.63636 16.1645 7.63636 15.75C7.63636 15.3355 7.97792 15 8.39998 15C8.82205 15 9.16361 15.3355 9.16361 15.75C9.16361 16.1645 8.82205 16.5 8.39998 16.5ZM14.5091 16.5C14.087 16.5 13.7455 16.1645 13.7455 15.75C13.7455 15.3355 14.087 15 14.5091 15C14.9312 15 15.2727 15.3355 15.2727 15.75C15.2727 16.1645 14.9312 16.5 14.5091 16.5Z"
            fill="#312F65"
          />
        </svg>

        <p>{{ car.gearboxType }}</p>
      </div>
      <div class="item electric" *ngIf="car.isElectric">
        <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.0816 13.8566H21.2957C21.8874 13.8566 22.3671 13.3769 22.3671 12.7852V9.17583C22.3671 8.7341 22.096 8.33769 21.6845 8.17741L17.0102 6.35691L13.796 1H5.22498L3.08222 6.35691H2.01084C1.41913 6.35691 0.939453 6.83657 0.939453 7.4283V12.7852C0.939453 13.3769 1.41913 13.8566 2.01084 13.8566H5.22498M18.0816 13.8566C18.0816 15.04 17.1223 15.9994 15.9388 15.9994C14.7554 15.9994 13.796 15.04 13.796 13.8566M18.0816 13.8566C18.0816 12.6731 17.1223 11.7138 15.9388 11.7138C14.7554 11.7138 13.796 12.6731 13.796 13.8566M5.22498 13.8566C5.22498 15.04 6.18433 15.9994 7.36775 15.9994C8.55117 15.9994 9.51051 15.04 9.51051 13.8566M5.22498 13.8566C5.22498 12.6731 6.18433 11.7138 7.36775 11.7138C8.55117 11.7138 9.51051 12.6731 9.51051 13.8566M13.796 13.8566H9.51051"
            stroke="#312F65"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <p>Electrique</p>
      </div>

      <div class="item" *ngIf="car.hasAirConditioning">
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.95859 8.9L2.075 7.89013L2.97851 7.09971L4.55359 8.9H7.56022C7.61687 8.68214 7.70322 8.47783 7.81502 8.2885L5.68745 6.16103L3.30078 6.32011L3.2211 5.12304L4.56007 5.03373L3.08457 3.5583L3.93301 2.70986L5.40869 4.18547L5.49805 2.84609L6.6957 2.92578L6.53614 5.31285L8.66346 7.44006C8.85279 7.32825 9.0571 7.24183 9.275 7.18518V4.17793L7.475 2.60293L8.26485 1.7L9.275 2.5837V0.5H10.475V2.5837L11.4852 1.7L12.275 2.60293L10.475 4.17793V7.18518C10.6929 7.24183 10.8972 7.32825 11.0865 7.44006L13.2139 5.31285L13.0543 2.92578L14.252 2.8461L14.3413 4.18548L15.817 2.70987L16.6654 3.5583L15.1899 5.03373L16.5289 5.12305L16.4492 6.32012L14.0625 6.16104L11.935 8.2885C12.0468 8.47783 12.1331 8.68214 12.1898 8.9H15.1964L16.7715 7.09971L17.675 7.89013L16.7914 8.9H18.875V10.1H16.7914L17.675 11.1099L16.7715 11.9003L15.1964 10.1H12.1898C12.1331 10.3179 12.0468 10.5222 11.935 10.7115L14.0625 12.839L16.4492 12.6799L16.5289 13.877L15.1899 13.9663L16.6654 15.4417L15.817 16.2901L14.3413 14.8145L14.252 16.1539L13.0543 16.0742L13.2139 13.6871L11.0865 11.5599C10.8972 11.6717 10.6929 11.7582 10.475 11.8148V14.8221L12.275 16.3971L11.4852 17.3L10.475 16.4163V18.5H9.275V16.4163L8.26485 17.3L7.475 16.3971L9.275 14.8221V11.8148C9.0571 11.7582 8.8528 11.6717 8.66346 11.5599L6.53614 13.6872L6.6957 16.0742L5.49805 16.1539L5.40869 14.8145L3.93301 16.2901L3.08457 15.4417L4.56007 13.9663L3.2211 13.877L3.30078 12.6799L5.68745 12.839L7.81502 10.7115C7.70322 10.5222 7.61687 10.3179 7.56022 10.1H4.55359L2.97851 11.9003L2.075 11.1099L2.95859 10.1H0.875V8.9H2.95859ZM9.875 10.7C10.5365 10.7 11.075 10.1618 11.075 9.5C11.075 8.83818 10.5365 8.3 9.875 8.3C9.21348 8.3 8.675 8.83818 8.675 9.5C8.675 10.1618 9.21348 10.7 9.875 10.7Z"
            fill="#312F65"
          />
        </svg>

        <p>Climatiseur</p>
      </div>

      <div class="item">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.36853 5.73423C6.83967 5.43444 8.33198 6.15188 8.91907 7.44114L10.1715 10.1924L14.1396 9.56953C14.5031 9.51245 14.8584 9.70556 14.9833 10.0281L16.468 13.8629C16.9205 14.9902 16.365 16.2602 15.1864 16.7771C13.9151 17.334 12.4002 16.8106 11.8289 15.617L11.2716 14.4527L6.08139 15.4076C5.71321 15.4753 5.34685 15.2837 5.21801 14.9559L3.1085 9.59038C2.44196 7.93842 3.5085 6.1144 5.36853 5.73423ZM4.53733 9.09838L6.42525 13.9002L11.5726 12.9533C11.9215 12.8891 12.2718 13.058 12.4164 13.3601L13.2209 15.041C13.4448 15.5088 14.0385 15.7139 14.5367 15.4957C14.9984 15.2932 15.2161 14.7955 15.0375 14.3505L13.7653 11.065L9.83144 11.6825C9.48341 11.7372 9.14062 11.5624 9.00303 11.2602L7.51592 7.9934C7.21488 7.3323 6.44932 6.96425 5.69514 7.11795C4.74145 7.31288 4.1944 8.24846 4.53733 9.09838Z"
            fill="#312F65"
          />
          <path
            d="M3.6723 0C5.35539 0 6.71961 1.26841 6.71961 2.83328C6.71961 4.39815 5.35539 5.66656 3.6723 5.66656C1.98922 5.66656 0.625 4.39815 0.625 2.83328C0.625 1.26841 1.98919 0 3.6723 0ZM3.6723 4.24994C4.5139 4.24994 5.19597 3.6158 5.19597 2.83328C5.19597 2.05076 4.51394 1.41662 3.6723 1.41662C2.83067 1.41662 2.14863 2.05076 2.14863 2.83328C2.14863 3.6158 2.83067 4.24994 3.6723 4.24994Z"
            fill="#312F65"
          />
        </svg>

        <p>
          {{ car.passengerPlaceCountSabre ? car.passengerPlaceCountSabre : car.passengerPlaceCount }}
        </p>
      </div>

      <div class="item">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.875 0.625H6.12505C5.9496 0.625 5.77972 0.683727 5.64491 0.790938L1.14493 4.37048C0.97395 4.5065 0.875073 4.70799 0.875073 4.92045V8.49857L0.875 8.5V15.6591C0.875 16.0545 1.21078 16.375 1.625 16.375H15.8749C16.2891 16.375 16.6249 16.0545 16.6249 15.6591V8.50143L16.625 8.5V1.34091C16.625 0.945516 16.2892 0.625 15.875 0.625ZM15.125 7.78409H5.37505V6.6853L7.5703 4.92045H15.125V7.78409ZM2.37507 5.25576L6.3966 2.05682H15.125V3.48864H7.29656C7.11975 3.48864 6.94866 3.54827 6.81342 3.65695L4.14192 5.80468C3.97273 5.94069 3.87506 6.1411 3.87506 6.35224V7.78406H2.37507V5.25576ZM15.1249 14.9432C7.40611 14.9432 10.4378 14.9432 2.37499 14.9432V9.21591H15.1249V14.9432Z"
            fill="#312F65"
          />
        </svg>

        <p>{{ car.doorCount }}</p>
      </div>
    </div>
  </div>
  <div *ngIf="expanded && (commonService.isTabletObservable | async) === false" class="extended-block flex w-full">
    <div class="titles flex-1">
      <div class="space"></div>
      <div class="avantage libelle"></div>
      <div
        [ngClass]="{ even: isEven }"
        class="avantage libelle"
        *ngFor="let advantageKey of advantageKeyList; even as isEven"
        translate
      >
        SEARCH.CAR.ADVANTAGES.LABELS.{{ advantageKey }}
      </div>
    </div>
    <div
      *ngFor="let offer of computedOffers; let offerIndex = index"
      class="offer flex flex-col"
      [ngClass]="'offer-' + computedOffers.length"
    >
      <div class="price py-2 flex flex-column justify-content-between items-center">
        <div class="out-of-policy-container">
          <spt-out-of-policy
            [isOutOfPolicy]="offer.inPolicy === false"
            [authorizationsByPolicies]="offer.authorizationsByPolicies"
            type="car"
          ></spt-out-of-policy>
        </div>

        <p class="offer-name">{{ offer.name }}</p>

        <div class="amount flex justify-center items-center">
          <p>
            {{ offer.totalPrice | SPTPrice | async }}
          </p>

          <button clas="h-16" (mousedown)="selectOffer(car, offer)" translate>CAR.CHOOSE_CTA</button>
        </div>
      </div>
      <div *ngIf="hasCorporateCode === true && offerIndex === 0">
        <div class="avantage">
          <div class="distance-allowance" *ngIf="offer.distance">
            &#60; {{ offer.distance }}km
            <span *ngIf="offer.distance !== offer.distanceByDay"> ({{ offer.distanceByDay }}km/j) </span>
          </div>

          <span class="distance-allowance" *ngIf="!offer.distance">{{ "SEARCH.CAR.NO_INFORMATION" | translate }}</span>
        </div>

        <div class="flex justify-content-center items-center enterprise-text">
          {{ "SEARCH.CAR.SOCIETY_CONDITIONS" | translate }}
        </div>
      </div>
      <ng-container *ngIf="(hasCorporateCode === true && offerIndex > 0) || hasCorporateCode === false">
        <div class="avantage">
          <div class="distance-allowance" *ngIf="offer.distance">
            &#60; {{ offer.distance }}km
            <span *ngIf="car.offers[offerIndex].distance !== offer.distanceByDay">
              ({{ offer.distanceByDay }}km/j)
            </span>
          </div>
          <ng-container *ngFor="let advantage of offer.advantages">
            <span
              *ngIf="advantage.key === 'UNLIMITED_MILEAGE' && advantage.value && !offer.distance"
              class="distance-allowance"
            >
              {{ "SEARCH.CAR.UNLIMITED" | translate }}
            </span>
          </ng-container>
        </div>
        <ng-container *ngFor="let advantageKey of advantageKeyList; even as isEven">
          <ng-container *ngFor="let offerAdvantage of offer.advantages">
            <div *ngIf="advantageKey === offerAdvantage.key" [ngClass]="{ even: isEven }" class="avantage">
              <svg
                *ngIf="offerAdvantage.value"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.748 17.474A9 9 0 1 1 17.25 4.527 9 9 0 0 1 4.748 17.474Zm-1.39 1.438A11 11 0 1 1 18.641 3.09 11 11 0 0 1 3.358 18.912Zm6.472-7.04 5.032-4.997 1.638 1.65-6.67 6.6-4.33-4.3 1.638-1.65 2.692 2.696Z"
                  fill="#604FD7"
                />
              </svg>
              <spt-tooltip
                *ngIf="offerAdvantage.value && offerAdvantage.description"
                [description]="offerAdvantage.description"
                [triggerImg]="'info-fonce.svg'"
                [position]="'top'"
                [isSmallDevice]="commonService.isTablet"
              ></spt-tooltip>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div
    *ngIf="expanded && (commonService.isTabletObservable | async) === true"
    class="extended-block-mobile flex w-full flex-col"
  >
    <div *ngFor="let offer of car.offers; let offerIndex = index" class="offer flex">
      <div class="left flex-1">
        <ul>
          <li *ngIf="offer.distance" class="advantage">
            <div>
              &#60; {{ offer.distance }}km
              <span *ngIf="offer.distance !== offer.distanceByDay"> ({{ offer.distanceByDay }}km/j) </span>
            </div>
          </li>
          <ng-container *ngFor="let advantageKey of advantageKeyList">
            <ng-container *ngFor="let offerAdvantage of offer.advantages">
              <li *ngIf="advantageKey === offerAdvantage.key" class="advantage">
                <ng-container [ngSwitch]="offerAdvantage.key">
                  <div *ngSwitchCase="'UNLIMITED_MILEAGE'">
                    <svg
                      *ngIf="offerAdvantage.value"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.568308 9.76285C0.935171 10.6169 1.46845 11.3893 2.13702 12.035C3.45723 13.3101 5.22545 14.0157 7.06083 13.9997C8.8962 13.9838 10.6519 13.2476 11.9497 11.9497C13.2476 10.6519 13.9838 8.8962 13.9997 7.06083C14.0157 5.22545 13.3101 3.45723 12.035 2.13702C11.3893 1.46845 10.6169 0.935171 9.76285 0.568308C8.90882 0.201445 7.99028 0.00834101 7.06083 0.000264297C6.13137 -0.00781242 5.20961 0.169299 4.34934 0.521266C3.48906 0.873232 2.7075 1.393 2.05025 2.05025C1.393 2.7075 0.873232 3.48906 0.521266 4.34934C0.169299 5.20961 -0.00781242 6.13137 0.000264297 7.06083C0.00834101 7.99028 0.201445 8.90882 0.568308 9.76285ZM6.25532 7.55458L9.45745 4.375L10.5 5.425L6.25532 9.625L3.5 6.88908L4.54255 5.83908L6.25532 7.55458Z"
                        fill="#604FD7"
                      />
                    </svg>
                  </div>
                  <div *ngSwitchDefault>
                    <svg
                      *ngIf="offerAdvantage.value"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.568308 9.76285C0.935171 10.6169 1.46845 11.3893 2.13702 12.035C3.45723 13.3101 5.22545 14.0157 7.06083 13.9997C8.8962 13.9838 10.6519 13.2476 11.9497 11.9497C13.2476 10.6519 13.9838 8.8962 13.9997 7.06083C14.0157 5.22545 13.3101 3.45723 12.035 2.13702C11.3893 1.46845 10.6169 0.935171 9.76285 0.568308C8.90882 0.201445 7.99028 0.00834101 7.06083 0.000264297C6.13137 -0.00781242 5.20961 0.169299 4.34934 0.521266C3.48906 0.873232 2.7075 1.393 2.05025 2.05025C1.393 2.7075 0.873232 3.48906 0.521266 4.34934C0.169299 5.20961 -0.00781242 6.13137 0.000264297 7.06083C0.00834101 7.99028 0.201445 8.90882 0.568308 9.76285ZM6.25532 7.55458L9.45745 4.375L10.5 5.425L6.25532 9.625L3.5 6.88908L4.54255 5.83908L6.25532 7.55458Z"
                        fill="#604FD7"
                      />
                    </svg>
                  </div>
                </ng-container>
                <p *ngIf="offerAdvantage.value" translate>SEARCH.CAR.ADVANTAGES.LABELS.{{ advantageKey }}</p>
                <spt-tooltip
                  *ngIf="offerAdvantage.value && offerAdvantage.description"
                  [description]="offerAdvantage.description"
                  [triggerImg]="'info-fonce.svg'"
                  [position]="'top'"
                  [isSmallDevice]="commonService.isTablet"
                ></spt-tooltip>
              </li>
            </ng-container>
          </ng-container>
        </ul>
      </div>

      <div class="right">
        <p class="offer-name">
          {{ offer.name }}
        </p>

        <p class="price">
          {{ offer.totalPrice | SPTPrice | async }}
        </p>

        <button (mousedown)="selectOffer(car, offer)">Choisir</button>
      </div>
    </div>
  </div>
</div>
