<div class="header">
  <span translate>LOGIN.SUBSCRIPTION.ALREADY_HAVE_AN_ACCOUNT</span>
  <a (click)="loginPage()" translate>LOGIN.SUBSCRIPTION.SIGNIN</a>
</div>

<div class="container">
  <div class="m-20">
    <div>
      <h1 translate>LOGIN.SUBSCRIPTION.CREATE_ACCOUNT</h1>
      <!-- <h4 translate>LOGIN.SUBSCRIPTION.LIMITED_FREE_USE</h4> -->
    </div>

    <form [formGroup]="signUpForm" (ngSubmit)="register()" class="form-group">
      <div class="section">
        <h3 translate>LOGIN.SUBSCRIPTION.DETAILS_LOGIN</h3>

        <label translate>ACCOUNT.GENERAL.EMAIL</label>
        <input pInputText required formControlName="email" type="email" />
        <p translate>ACCOUNT.PASSWORD.RULES</p>
        <label translate>ACCOUNT.GENERAL.PASSWORD</label>

        <div class="p-inputgroup align-items-center">
          <input
            required
            formControlName="password"
            autocomplete="off"
            class="input-shadow"
            pInputText
            [type]="this.displayPassword['password'] ? 'text' : 'password'"
          />
          <span class="p-inputgroup-addon" (click)="changeStatutPassword('password')"><i class="fas fa-eye"></i></span>
        </div>
        <app-password-strength-bar [passwordToCheck]="signUpForm.value.password"></app-password-strength-bar>

        <label translate>LOGIN.SUBSCRIPTION.REPEAT_PASSWORD</label>
        <div class="p-inputgroup align-items-center">
          <input
            required
            formControlName="controlPassword"
            autocomplete="off"
            class="input-shadow"
            pInputText
            [type]="this.displayPassword['controlPassword'] ? 'text' : 'password'"
          />
          <span class="p-inputgroup-addon" (click)="changeStatutPassword('controlPassword')"
            ><i class="fas fa-eye"></i
          ></span>
        </div>
      </div>
      <div class="section">
        <h3 translate>LOGIN.SUBSCRIPTION.PERSONAL_INFO</h3>

        <label translate>LOGIN.SUBSCRIPTION.GENDER.GENDER</label>
        <div class="grid">
          <div class="col-6">
            <p-radioButton
              name="gender"
              formControlName="title"
              value="Mr"
              label="{{ 'LOGIN.SUBSCRIPTION.GENDER.MR' | translate }}"
            ></p-radioButton>
          </div>
          <div class="col-6">
            <p-radioButton
              name="gender"
              formControlName="title"
              value="Mrs"
              label="{{ 'LOGIN.SUBSCRIPTION.GENDER.MRS' | translate }}"
            ></p-radioButton>
          </div>
        </div>

        <div class="grid">
          <div class="col-6">
            <label translate>ACCOUNT.PASSPORT.LASTNAME</label>
            <input pInputText required formControlName="lastname" type="text" />
          </div>
          <div class="col-6">
            <label translate>ACCOUNT.PASSPORT.FIRSTNAME</label>
            <input pInputText required formControlName="firstname" type="text" />
          </div>
          <div class="col-12">
            <label translate>ACCOUNT.GENERAL.PHONE</label>
            <input pInputText formControlName="phone" type="text" />
          </div>
        </div>
      </div>

      <div class="section">
        <h3 translate>LOGIN.SUBSCRIPTION.COMPANY_INFO</h3>
        <div>
          <label translate>LOGIN.SUBSCRIPTION.COMPANY_NAME</label>
          <input pInputText required formControlName="companyName" type="text" />
          <!-- <spt-place-finder
            [types]="types"
            [placeholder]="'LOGIN.SUBSCRIPTION.COMPANY_ADDRESS' | translate"
            (formatted)="setAddress($event)"
            [required]="true"
          ></spt-place-finder> -->
        </div>
      </div>
      <p-button label="Submit" class="m-t-20" [disabled]="!signUpForm.valid" type="submit"></p-button>
    </form>
  </div>
</div>
