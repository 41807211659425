import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { SessionService } from "src/app/@shared/services/session.service";
import { RequestTransfer, ItemTransfer } from "../../../../../../@shared/@types/models";

@Component({
  selector: "spt-request-transfer",
  templateUrl: "./transfer.component.html",
  styleUrls: ["../request.component.scss", "./transfer.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RequestTransferComponent implements OnInit {
  @Input() request: RequestTransfer;
  item: ItemTransfer;
  constructor(public sessionService: SessionService<any>) {}

  ngOnInit(): void {
    this.item = this.request.item as ItemTransfer;
  }
}
