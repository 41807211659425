import { Observable } from "rxjs";
import { filter, first, mergeMap } from "rxjs/operators";
import { Society } from "src/app/@shared/@types/society";

import { Injectable } from "@angular/core";

import { LabelService } from "../services/label.service";
import { SocietyService } from "../services/society.service";
import { Label } from "../@types/label";

@Injectable({
  providedIn: "root",
})
export class LabelResolver {
  constructor(
    private labelService: LabelService,
    private societyService: SocietyService,
  ) {}

  resolve(): Observable<Array<Label>> {
    return this.societyService.society.pipe(
      filter((society: Society) => !!society),
      mergeMap((society: Society) => this.labelService.getLabelsBySocietyId(society._id)),
      first(),
    );
  }
}
