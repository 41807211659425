import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/@shared/shared.module";
import { CarComponent } from "./car.component";
import { CarBlockComponent } from "./car-block/car-block.component";
import { CarProviderImageComponent } from "./car-block/car-provider-image/car-provider-image.component";
import { CheckboxModule } from "primeng/checkbox";
import { SliderModule } from "primeng/slider";
import { TranslateModule } from "@ngx-translate/core";
import { CurrencySymbolPipe } from "src/app/@shared/pipes/currency-symbol.pipe";
@NgModule({
  declarations: [CarComponent, CarBlockComponent, CarProviderImageComponent, CurrencySymbolPipe],
  imports: [TranslateModule.forChild(), FormsModule, SharedModule, FormsModule, CheckboxModule, SliderModule],
  exports: [CarComponent],
})
export class TravelCarModule {}
