import { CommentariesComponent } from "./hotel-page/commentaries/commentaries.component";
import { DetailComponent } from "./hotel-page/detail/detail.component";
import { PicturesComponent } from "./hotel-page/pictures/pictures.component";
import { CommonModule, CurrencyPipe, DecimalPipe } from "@angular/common";
import { AvailabilitiesComponent } from "./hotel-page/availabilities/availabilities.component";
import { TabMenuModule } from "primeng/tabmenu";
import { HotelPageComponent } from "./hotel-page/hotel-page.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { StarsPipeModule } from "./../../dashboard/@dashboard-shared/pipes/stars/stars.module";
import { DistancePipeModule } from "./../../dashboard/@dashboard-shared/pipes/distance/distance.module";
import { HotelThumbnailComponent } from "./hotel-thumbnail/hotel-thumbnail.component";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "src/app/@shared/shared.module";
import { HotelComponent } from "./hotel.component";
import { MarkupHotelComponent } from "./markup-hotel/markup-hotel.component";
import { SliderModule } from "primeng/slider";
import { AvailabilityComponent } from "./hotel-page/availabilities/availability/availability.component";
import { ImageModule } from "primeng/image";
import { GalleriaModule } from "primeng/galleria";

const HOTEL_COMPONENTS: Array<any> = [
  HotelComponent,
  HotelThumbnailComponent,
  HotelPageComponent,
  AvailabilitiesComponent,
  AvailabilityComponent,
  MarkupHotelComponent,
  PicturesComponent,
  DetailComponent,
  CommentariesComponent,
];

const HOTEL_PIPES: Array<any> = [];

const HOTEL_MODULES: Array<any> = [
  CommonModule,
  SharedModule,
  TranslateModule.forChild(),
  InfiniteScrollModule,
  DistancePipeModule,
  StarsPipeModule,
  TabMenuModule,
  SliderModule,
  ImageModule,
  GalleriaModule,
];

const HOTEL_PROVIDERS: Array<any> = [CurrencyPipe, DecimalPipe];

@NgModule({
  imports: [...HOTEL_MODULES],
  declarations: [...HOTEL_COMPONENTS, ...HOTEL_PIPES],
  providers: [...HOTEL_PROVIDERS],
  exports: [HotelComponent],
})
export class TravelHotelModule {}
