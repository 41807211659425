import { Pipe, PipeTransform } from "@angular/core";
import _companies from "../../@shared/companies";

@Pipe({
  name: "alphabeticOrder",
})
export class AlphabeticOrderPipe implements PipeTransform {
  constructor() {}

  private compareCompagnies(label1: string, label2: string) {
    return this.getCompagnyName(label1).localeCompare(this.getCompagnyName(label2));
  }
  private getCompagnyName(label: string): string {
    return _companies[label]?.name || _companies[label]?.iata || label;
  }

  transform(data: Array<any>, sortBy: string, context: string): any {
    switch (context) {
      case "companies":
        // ascend -> A-Z
        // descend -> Z-A
        if (sortBy === "ascend") {
          data = data.sort((a, b) => this.compareCompagnies(a.label, b.label));
        } else if (sortBy === "descend") {
          data = data.sort((a, b) => this.compareCompagnies(b.label, a.label));
        }
        break;
      default:
        break;
    }

    return data;
  }
}
