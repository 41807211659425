<p class="traveler">
  {{ user.firstname | titlecase }}
  {{ user.lastname | titlecase }}
  <ng-container *ngIf="pricingInformation; else afklPrice">
    <span class="price">
      {{
        {
          amount: pricingInformation.fare.passengerInfos[index].passengerTotalFare.totalFare,
          currency: pricingInformation.fare.passengerInfos[index].passengerTotalFare.currency
        }
          | SPTPrice
          | async
      }}
    </span>
  </ng-container>
  <ng-template #afklPrice>
    <span class="price">
      {{
        {
          amount: afklTrip.formData.orderCreateRS.Order.TotalPrice.TotalAmount["$t"],
          currency: afklTrip.formData.orderCreateRS.Order.TotalPrice.TotalAmount["CurCode"]
        }
          | SPTPrice
          | async
      }}
    </span>
  </ng-template>
</p>
<ng-container *ngIf="pricingInformation; else afklData">
  <div class="conditions-content">
    <ul>
      <li class="refundable" translate>GLOBAL.CATEGORY.{{ refundable ? "REFUNDABLE" : "NON_REFUNDABLE" }}</li>
      <li class="exchangeable" translate>GLOBAL.CATEGORY.{{ exchangeable ? "EXCHANGEABLE" : "NON_EXCHANGEABLE" }}</li>
    </ul>
    <div class="conditions-content-detail">
      <ng-container *ngIf="pricingInformation.fare.passengerInfos[index]?.penaltiesInfo?.penalties?.length > 0">
        <p
          *ngFor="
            let penalty of pricingInformation.fare.passengerInfos[index]?.penaltiesInfo?.penalties;
            let isLast = last
          "
        >
          <ng-container *ngIf="penalty.type === 'Exchange' && penalty.applicability === 'Before'">
            <span
              *ngIf="penalty.amount > -1"
              [innerHTML]="
                'GLOBAL.CATEGORY.MODIFICATION_BEFORE_UNTIL'
                  | translate
                    : {
                        date: dateEnd | date: dateFormat,
                        price:
                          {
                            amount: penalty.amount,
                            currency: pricingInformation.price.currency
                          }
                          | SPTPrice
                          | async
                      }
              "
            >
            </span>
          </ng-container>
          <ng-container *ngIf="penalty.type === 'Refund' && penalty.applicability === 'Before'">
            <span
              *ngIf="penalty.amount > -1"
              [innerHTML]="
                'GLOBAL.FEES.CANCELLATION.BEFORE_UNTIL'
                  | translate
                    : {
                        date: dateEnd | date: dateFormat,
                        price:
                          {
                            amount: penalty.amount,
                            currency: pricingInformation.price.currency
                          }
                          | SPTPrice
                          | async
                      }
              "
            >
            </span>
          </ng-container>
        </p>
      </ng-container>
    </div>
    <div class="luggages">
      <span translate>GLOBAL.FLIGHT_BAGAGES.INCLUDED_LUGGAGE</span>
      <ul class="luggages-type">
        <li>
          <span class="m-r-10"><img src="./assets/svg/bagage-soute-white.svg" alt="" /></span>
          <span
            class="luggage-name"
            translate
            [translateParams]="{
              number: pricingInformation.fare.passengerInfos[index].baggageIncluded
            }"
            >GLOBAL.FLIGHT_BAGAGES.BAGAGE
          </span>
          <spt-tooltip
            [description]="'GLOBAL.FLIGHT_BAGAGES.INFORMATION.BAGAGE' | translate"
            [isSmallDevice]="commonService.isTablet"
            [triggerImg]="'info-fonce.svg'"
          ></spt-tooltip>
        </li>
        <li>
          <i class="spt-icon-baggage m-r-10"></i>
          <span
            class="luggage-name"
            translate
            [translateParams]="{
              number: pricingInformation.fare.passengerInfos[index].carryOnIncluded || 0
            }"
            >GLOBAL.FLIGHT_BAGAGES.CARRY</span
          >
          <spt-tooltip
            [description]="carryBagageDesc"
            [isSmallDevice]="commonService.isTablet"
            [triggerImg]="'info-fonce.svg'"
          ></spt-tooltip>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
<ng-template #afklData>
  <div class="conditions-content">
    <ul>
      <li class="refundable" translate>GLOBAL.CATEGORY.{{ refundable ? "REFUNDABLE" : "NON_REFUNDABLE" }}</li>
      <li class="exchangeable" translate>GLOBAL.CATEGORY.{{ exchangeable ? "EXCHANGEABLE" : "NON_EXCHANGEABLE" }}</li>
    </ul>
    <div class="conditions-content-detail">
      <ng-container
        *ngIf="
          afklTrip.formData.orderCreateRS.Order.OrderItem.fareDetail?.FareComponent[1]?.FareRule?.Penalty?.length > 0
        "
      >
        <p
          *ngFor="
            let penalty of afklTrip.formData.orderCreateRS.Order.OrderItem.fareDetail?.FareComponent[1]?.FareRule
              ?.Penalty;
            let isLast = last
          "
        >
          <ng-container *ngIf="penalty.TypeCode === 'Change' && penalty.ChangeFeeInd === true">
            <span
              *ngIf="penalty.PenaltyAmount['$t'] > -1"
              [innerHTML]="
                'GLOBAL.CATEGORY.MODIFICATION_BEFORE_UNTIL'
                  | translate
                    : {
                        price:
                          {
                            amount: penalty.PenaltyAmount['$t'],
                            currency: penalty.PenaltyAmount['CurCode']
                          }
                          | SPTPrice
                          | async
                      }
              "
            >
            </span>
          </ng-container>
          <ng-container *ngIf="penalty.TypeCode === 'Cancellation' && penalty.CancelFeeInd === true">
            <span
              *ngIf="penalty.PenaltyAmount['$t'] > -1"
              [innerHTML]="
                'GLOBAL.FEES.CANCELLATION.BEFORE_UNTIL'
                  | translate
                    : {
                        price:
                          {
                            amount: penalty.PenaltyAmount['$t'],
                            currency: penalty.PenaltyAmount['CurCode']
                          }
                          | SPTPrice
                          | async
                      }
              "
            >
            </span>
          </ng-container>
        </p>
      </ng-container>
    </div>
    <div class="luggages">
      <span translate>GLOBAL.FLIGHT_BAGAGES.INCLUDED_LUGGAGE</span>
      <ul class="luggages-type">
        <li>
          <span class="m-r-10"><img src="./assets/svg/bagage-soute-white.svg" alt="" /></span>
          <span
            class="luggage-name"
            translate
            [translateParams]="{
              number:
                afklTrip.formData.orderCreateRS.DataLists.BaggageAllowanceList?.BaggageAllowance[0]?.TypeCode ===
                'Checked'
                  ? parseInt(
                      afklTrip.formData.orderCreateRS.DataLists.BaggageAllowanceList?.BaggageAllowance[0]
                        ?.PieceAllowance.TotalQty
                    )
                  : 0
            }"
            >GLOBAL.FLIGHT_BAGAGES.BAGAGE
          </span>
          <spt-tooltip
            [description]="'GLOBAL.FLIGHT_BAGAGES.INFORMATION.BAGAGE' | translate"
            [isSmallDevice]="commonService.isTablet"
            [triggerImg]="'info-fonce.svg'"
          ></spt-tooltip>
        </li>
        <li>
          <i class="spt-icon-baggage m-r-10"></i>
          <span
            class="luggage-name"
            translate
            [translateParams]="{
              number:
                afklTrip.formData.orderCreateRS.DataLists.BaggageAllowanceList?.BaggageAllowance[1]?.TypeCode ===
                'CarryOn'
                  ? parseInt(
                      afklTrip.formData.orderCreateRS.DataLists.BaggageAllowanceList?.BaggageAllowance[1]
                        ?.PieceAllowance.TotalQty
                    )
                  : 0 || 0
            }"
            >GLOBAL.FLIGHT_BAGAGES.CARRY</span
          >
          <spt-tooltip
            [description]="carryBagageDesc"
            [isSmallDevice]="commonService.isTablet"
            [triggerImg]="'info-fonce.svg'"
          ></spt-tooltip>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
