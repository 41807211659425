import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { isEmpty } from "lodash-es";
import { filter, takeUntil } from "rxjs/operators";
import { TrainTypes } from "../train";
import { BehaviorSubject, Subject } from "rxjs";

@Component({
  selector: "spt-travel-train-journeys",
  templateUrl: "./journeys.component.html",
  styleUrls: ["./journeys.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelTrainJourneysComponent implements OnInit, OnChanges, OnDestroy {
  @Input() journeys: Array<TrainTypes.JourneyWithOffers>;
  @Input() searchJourneyDirection: TrainTypes.SearchJourneyDirection;
  @Input() origin: TrainTypes.Station;
  @Input() destination: TrainTypes.Station;
  @Input() activeTabIndex: number;
  @Input() toggleOfferPackages: boolean;
  @Input() isSmallDevice: boolean;
  @Input() flexibilities: Array<TrainTypes.Flexibility | TrainTypes.ATOCFlexibility>;
  @Input() seeOutOfPolicy: boolean;
  @Input() selectedReturnFare: boolean;
  @Input() allowedAlternativeIds: Array<string>;
  @Output() selectOffers: EventEmitter<TrainTypes.OffersSelection> = new EventEmitter();
  selectedFlexibility: TrainTypes.Flexibility | TrainTypes.ATOCFlexibility;
  readonly displayedOffers: BehaviorSubject<Array<TrainTypes.JourneyWithOffers>> = new BehaviorSubject(null);
  locale: string;
  isEmpty: (obj: unknown) => boolean = isEmpty;
  private ngUnsubscribe: Subject<void> = new Subject();

  static compareJourneys(a: TrainTypes.JourneyWithOffers, b: TrainTypes.JourneyWithOffers): number {
    if (a.departure.date.utc < b.departure.date.utc) {
      return -1;
    } else if (a.departure.date.utc > b.departure.date.utc) {
      return 1;
    }

    if (a.arrival.date.utc < b.arrival.date.utc) {
      return -1;
    } else if (a.arrival.date.utc > b.arrival.date.utc) {
      return 1;
    }
  }

  constructor(private translateService: TranslateService) {}

  private filterJourneys(tabIndex?: number): void {
    if (tabIndex !== undefined) {
      this.activeTabIndex = tabIndex;
      this.selectedFlexibility = this.flexibilities[tabIndex];
    } else {
      this.selectedFlexibility = this.flexibilities[0];
    }

    this.journeys = this.journeys.sort(TravelTrainJourneysComponent.compareJourneys);
    let offersToDisplay: Array<TrainTypes.JourneyWithOffers>;
    if (this.searchJourneyDirection === "Outward") {
      offersToDisplay = this.journeys.filter((offer: TrainTypes.JourneyWithOffers) => {
        return offer.direction === "outward";
      });
    } else {
      offersToDisplay = this.journeys.filter((offer: TrainTypes.JourneyWithOffers) => {
        return offer.direction === "inward";
      });
    }
    this.displayedOffers.next(offersToDisplay);
  }

  ngOnInit(): void {
    this.locale = this.translateService.currentLang;
    this.displayedOffers.pipe(
      filter((data: any) => {
        return !!data;
      }),
      takeUntil(this.ngUnsubscribe),
    );
    if (this.isSmallDevice) {
      this.filterJourneys(this.activeTabIndex);
    } else {
      this.filterJourneys();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.journeys && !changes.journeys.firstChange && changes.journeys.currentValue !== undefined) {
      if (this.isSmallDevice) {
        this.filterJourneys(this.activeTabIndex);
      } else {
        this.filterJourneys();
      }
    }

    if (
      changes.activeTabIndex &&
      !changes.activeTabIndex.firstChange &&
      changes.activeTabIndex.currentValue !== undefined
    ) {
      this.filterJourneys(changes.activeTabIndex.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  trackById(index: number, item: TrainTypes.JourneyWithOffers): string {
    return item.id;
  }

  onSelectOffers(selection: TrainTypes.OffersSelection): void {
    this.selectOffers.emit(selection);
  }
}
