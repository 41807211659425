import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import {
  ItemFlight,
  ItemHotel,
  ItemTrain,
  ItemTransfer,
  RequestFlight,
  RequestHotel,
  RequestTrain,
  RequestTransfer,
  Requests,
} from "../../../../../../@shared/@types/models";

import { CommonService } from "../../../../../../@shared/services/common.service";
import { HotelTypes } from "../../../../../../@shared/@types/hotel";
import { MemberSociety } from "../../../../../../@shared/@types/society";
import { MessageService } from "primeng/api";
import { Router } from "@angular/router";
import { SearchService } from "../../../../../../@shared/services/search.service";
import { SocietyService } from "../../../../../../@shared/services/society.service";
import { TrainTypes } from "../../../../../../travel/train/train";
import { TranslateService } from "@ngx-translate/core";
import { User } from "src/app/@shared/@types/user";
import { UserLabel } from "../../../../../../@shared/@types/label";
import moment from "moment";

@Component({
  selector: "spt-request-actions",
  templateUrl: "./actions.component.html",
  styleUrls: ["./actions.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RequestActionsComponent implements OnInit {
  @Input() request: Requests;
  @Input() userId: string;
  @Output() refuseRequest: EventEmitter<void> = new EventEmitter();
  private members: Array<MemberSociety>;

  constructor(
    private messageService: MessageService,
    private translateService: TranslateService,
    private commonService: CommonService,
    private searchService: SearchService,
    private societyService: SocietyService,
    private router: Router,
  ) {}

  ngOnInit(): void {}

  refuse(): void {
    this.refuseRequest.emit();
  }

  goToBookRequest(): void {
    switch (this.request.type) {
      case "Train":
        this.goToTrain(this.request);
        break;
      case "Hotel":
        this.goToHotel(this.request);
        break;
      case "RentalCar":
        this.goToRentalCar(this.request);
        break;
      case "Flight":
        this.goToFlight(this.request);
        break;
      case "Transfer":
        this.goToTransfer(this.request);
        break;
    }
  }

  goToFlight(bookrequest: RequestFlight): void {
    this.router.navigate(["travel", "flight", (bookrequest.item as ItemFlight).metadata.searchId], {
      queryParams: {
        bookrequest: bookrequest._id,
        userLabel: bookrequest.userLabel
          ? this.commonService.encode(JSON.stringify(bookrequest.userLabel as UserLabel))
          : "",
      },
    });
  }

  goToRentalCar(bookrequest: any): void {
    this.router.navigate(["travel", "car", bookrequest.item.searchId], {
      queryParams: {
        bookrequest: bookrequest._id,
      },
    });
  }

  goToHotel(bookrequest: RequestHotel): void {
    const board = (bookrequest.board as User[][]).map((room) => room.map((u) => (u._id ? u._id : u)));
    const form: HotelTypes.ParamsOptions = {
      breakfast: (bookrequest.item as ItemHotel).breakfast,
      cancellable: (bookrequest.item as ItemHotel).cancellable,
      completeAddress: (bookrequest.item as ItemHotel).completeAddress,
      dates: [
        this.customMoment(bookrequest.datein).format("YYYY-MM-DD"),
        this.customMoment(bookrequest.dateout).format("YYYY-MM-DD"),
      ],
      board: JSON.stringify(board),
    };
    const hotel: any = {
      id: (bookrequest.item as ItemHotel).id,

      accom: (bookrequest.item as ItemHotel).accom,
      rooms: (bookrequest.item as ItemHotel).rooms,
    };

    this.router.navigate(["traveling", "hotel"], {
      queryParams: {
        form: this.commonService.encode(JSON.stringify(form)),
        hotel: this.commonService.encode(JSON.stringify(hotel)),
        bookrequest: bookrequest._id,
        userLabel: bookrequest.userLabel
          ? this.commonService.encode(JSON.stringify(bookrequest.userLabel as UserLabel))
          : "",
      },
    });
  }

  customMoment(date?: string): moment.Moment {
    if (date) {
      return moment(date.replace(/Z/, ""));
    }
    return moment();
  }

  goToTrain(bookrequest: RequestTrain): void {
    const itemTrain: ItemTrain = bookrequest.item as ItemTrain;
    if (!itemTrain.metadata.searchId) {
      const form: any = JSON.parse(itemTrain.form);
      const passengers: Array<string> = [];
      this.members.forEach((_member: MemberSociety) => {
        if (form.passengersIds.some((_passengerId: string) => _member.user._id === _passengerId)) {
          passengers.push(_member.user._id);
        }
      });
      const type: TrainTypes.SearchType = form.dateTimeFrom && form.dateTimeTo ? "Return" : "Single";
      const searchBody: TrainTypes.SearchBody = {
        type,
        outwardJourney: {
          departureStation: {
            name: form.departure.$value,
            locationId: form.departure.LocationCode,
          },
          arrivalStation: {
            name: form.arrival.$value,
            locationId: form.arrival.LocationCode,
          },
          date: moment(form.dateTimeFrom).format("YYYY-DD-MM"),
          hourWindowType: "DepartsAfter",
          hourWindowTime: moment(form.dateTimeFrom).format("HH:mm"),
        },
        userIds: passengers,
      };

      if (type === "Return") {
        searchBody.inwardJourney = {
          departureStation: {
            name: form.arrival.$value,
            locationId: form.arrival.LocationCode,
          },
          arrivalStation: {
            name: form.departure.$value,
            locationId: form.departure.LocationCode,
          },
          date: moment(form.dateTimeTo).format("YYYY-MM-DD"),
          hourWindowType: "DepartsAfter",
          hourWindowTime: moment(form.dateTimeTo).format("HH:mm"),
        };
      }

      this.searchService.create("train", searchBody).subscribe((data: { id: string }) => {
        this.router.navigate(["travel", "train", data.id], {
          queryParams: {
            bookrequest: bookrequest._id,
            userLabel: bookrequest.userLabel
              ? this.commonService.encode(JSON.stringify(bookrequest.userLabel as UserLabel))
              : "",
          },
        });
      });
    } else {
      this.router.navigate(["travel", "train", itemTrain.metadata.searchId], {
        queryParams: {
          bookrequest: bookrequest._id,
          userLabel: bookrequest.userLabel
            ? this.commonService.encode(JSON.stringify(bookrequest.userLabel as UserLabel))
            : "",
        },
      });
    }
  }

  goToTransfer(bookrequest: RequestTransfer): void {
    this.router.navigate(["traveling", "transfer"], {
      queryParams: {
        bookRequestId: bookrequest._id,
        form: this.commonService.encode(JSON.stringify((bookrequest.item as ItemTransfer).metadata.form)),
        userLabel: bookrequest.userLabel
          ? this.commonService.encode(JSON.stringify(bookrequest.userLabel as UserLabel))
          : "",
      },
    });
  }
}
