import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import moment from "moment";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { MemberSociety, Society } from "src/app/@shared/@types/society";
import { CommonService } from "src/app/@shared/services/common.service";
import { RoleService } from "src/app/@shared/services/roles.service";
import { SocietyService } from "src/app/@shared/services/society.service";
import { UserService } from "src/app/@shared/services/user.service";
import { DateTimeSearch, SearchCriteria, SearchResult, TimeRange } from "./search-engine-service";
import { CarSearchService } from "./search-car/car-search.service";
import { TrainSearchService } from "./search-train/train-search.service";
import { FlightSearchService } from "./search-flight/flight-search.service";
import { HotelSearchService } from "./search-hotel/hotel-search.service";
import { SearchTransferService } from "./search-transfer/search-transfer.service";
import { SeminarSearchService } from "./search-seminar/seminar-search.service";

@Component({
  selector: "spt-search-engine",
  templateUrl: "./search-engine.component.html",
  styleUrls: ["./search-engine.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SearchEngineComponent implements OnInit {
  @Input()
  type: string;

  calendarDates: Date[] = [];
  calendarSelectionMode: "multiple" | "range" = "range";
  public minStartDate: Date = moment().startOf("day").toDate();

  public members: MemberSociety[] = [];
  public society: Society;
  form: UntypedFormGroup;
  private isAccountant: boolean;
  membersMax: number = 4;
  searchCriteria?: SearchCriteria<any, any>;

  constructor(
    private trainSearchService: TrainSearchService,
    private flightSearchService: FlightSearchService,
    private hotelSearchService: HotelSearchService,
    private carSearchService: CarSearchService,
    private transferSearchService: SearchTransferService,
    private seminarSearchService: SeminarSearchService,

    private router: Router,
    public config: DynamicDialogConfig,
    private fb: UntypedFormBuilder,
    private roleService: RoleService,
    private userService: UserService,
    private societyService: SocietyService,
    private dynamicDialogRef: DynamicDialogRef,
    private commonService: CommonService,
  ) {}

  private getSearchServiceFromType(type: string) {
    switch (type) {
      case "train": {
        return this.trainSearchService;
      }
      case "flight": {
        return this.flightSearchService;
      }
      case "car": {
        return this.carSearchService;
      }
      case "hotel": {
        return this.hotelSearchService;
      }
      case "transfer": {
        return this.transferSearchService;
      }
      case "seminar": {
        return this.seminarSearchService;
      }
    }
    throw new Error("SearchEngine type not known:" + this.type);
  }

  ngOnInit(): void {
    if (!this.type && this.config?.data?.type) {
      this.type = this.config.data.type;
    }

    const searchService = this.getSearchServiceFromType(this.type);
    if (this.config?.data?.id) {
      const previousSearch = this.config?.data?.data;
      this.searchCriteria = searchService.createCriteriaFromPreviousSearch?.(previousSearch);
    } else {
      this.searchCriteria = searchService.createBlankCriteria();
    }
    const lastFolderItemsInBasket = this.config?.data?.lastFolderItemsInBasket;
    if (lastFolderItemsInBasket) {
      const searchCriteriaFromLastItemInBasket = this.getSearchServiceFromType(
        lastFolderItemsInBasket[0]?.type,
      ).createCriteriaFromBasketItem?.(lastFolderItemsInBasket, this.societyService.society.value.members);

      this.searchCriteria.mainTravel.people.length = 0;
      this.searchCriteria.mainTravel.people.push(...searchCriteriaFromLastItemInBasket.mainTravel.people);

      this.searchCriteria.mainTravel.when.outward.date =
        searchCriteriaFromLastItemInBasket.mainTravel.when.outward.date;

      if (!this.isMultiDestiFlight(lastFolderItemsInBasket)) {
        if (!this.searchCriteria.mainTravel.when.inward) {
          this.searchCriteria.mainTravel.when.inward = new DateTimeSearch("", new TimeRange());
        }
        if (searchCriteriaFromLastItemInBasket.mainTravel.when.inward?.date) {
          this.searchCriteria.mainTravel.when.inward.date =
            searchCriteriaFromLastItemInBasket.mainTravel.when.inward.date;
        }
      }
    }

    this.society = this.societyService.society.value;

    this.isAccountant = this.roleService.is("accountant");
    if (this.isAccountant) {
      this.members = this.societyService.society.value.members.filter(
        (member: MemberSociety) => member.user._id === this.userService.user.value._id,
      );
    } else {
      this.members = this.societyService.society.value.members.filter((m: MemberSociety) => m.user.status > -1);
    }

    const form: UntypedFormGroup = this.fb.group({
      type: this.type,
    });
    this.form = form;
  }
  isMultiDestiFlight(lastFolderItemsInBasket: any[]): boolean {
    if (lastFolderItemsInBasket[0].type !== "flight") {
      return false;
    }
    return lastFolderItemsInBasket.length > 1;
  }

  showSearchResult(searchResult: SearchResult) {
    this.router.navigate(["travel", searchResult.id]).then((x) => {
      this.closeModal();
    });
  }

  closeModal(): void {
    this.commonService.statusBarTextWhite();
    this.dynamicDialogRef.close();
  }
}
