<ng-container [ngSwitch]="request.type">
  <spt-request-flight *ngSwitchCase="'Flight'" [request]="request" class="request"></spt-request-flight>
  <spt-request-hotel *ngSwitchCase="'Hotel'" [request]="request" class="request"></spt-request-hotel>
  <spt-request-rental-car *ngSwitchCase="'RentalCar'" [request]="request" class="request"></spt-request-rental-car>
  <spt-request-train *ngSwitchCase="'Train'" [request]="request" class="request"></spt-request-train>
  <spt-request-transfer *ngSwitchCase="'Transfer'" [request]="request" class="request"></spt-request-transfer>
  <spt-request-actions (refuseRequest)="refuse()" [request]="request" [userId]="user._id"></spt-request-actions>
  <spt-request-managers
    [managers]="userManagers"
    *ngIf="userManagers && userManagers.length > 0"
  ></spt-request-managers>
</ng-container>
