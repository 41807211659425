import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DataHotel } from "../sidebar-travel.component";

@Component({
  selector: "spt-itinerary",
  templateUrl: "./itinerary.component.html",
  styleUrls: ["./itinerary.component.scss"],
})
export class ItineraryComponent {
  @Input() item: any;
  @Input() referenced?: boolean;
  @Input() isSmallDevice: boolean;
  @Input() locale: string;
  @Output() onDataHotelUpdated: EventEmitter<DataHotel> = new EventEmitter<DataHotel>();
}
