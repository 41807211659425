import { getCurrencySymbol } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "currencySymbol",
})
export class CurrencySymbolPipe implements PipeTransform {
  transform(code: string, locale: string = "fr", format: "wide" | "narrow" = "narrow"): unknown {
    return getCurrencySymbol(code, format, locale);
  }
}
