import { Injectable } from "@angular/core";
import { AbstractPlaceSearchService } from "../abstract-place-search.service";
import {
  DateTimeSearch,
  SearchCriteria,
  SearchOptions,
  SearchResult,
  Travel,
  WhenSearch,
} from "../search-engine-service";
import { Address } from "src/app/@shared/@types/models";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { UntypedFormGroup } from "@angular/forms";
import { RequestService } from "src/app/@shared/services/request.service";
import { MessageService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "src/app/@shared/services/common.service";
import { HttpClient } from "@angular/common/http";
import { MemberSociety } from "src/app/@shared/@types/society";
import { DateTime } from "luxon";

@Injectable({
  providedIn: "root",
})
export class SeminarSearchService extends AbstractPlaceSearchService<SeminarSearchOptions> {
  seminarForm: UntypedFormGroup;
  constructor(
    private requestService: RequestService,
    private messageService: MessageService,
    private translateService: TranslateService,
    public commonService: CommonService,
    httpClient: HttpClient,
  ) {
    super(httpClient);
  }

  getType(): "seminar" {
    return "seminar";
  }

  getMaxPassengers(searchCriteria: SearchCriteria<Address, SeminarSearchOptions>): number {
    return -1;
  }

  /**
   * Permet de formater une date en ISO-8601 (en gardant la timezone local de l'utilisateur)
   */
  private toLocalIsoDate(date: Date): string {
    return DateTime.fromJSDate(date).toISO();
  }

  launchSearch(searchCriteria: SearchCriteria<Address, SeminarSearchOptions>): Observable<SearchResult> {
    // Les passagers indiqués dans SearchCriteria#mainTravel.people ne sont mis qu'à titre arbitraire pour valider le SearchCriteria
    // cf. SearchSeminarComponent#constructor

    let data = {
      type: searchCriteria.options.kind,
      address: searchCriteria.mainTravel.destination,
      startDate: this.toLocalIsoDate(searchCriteria.mainTravel.when.outward.getEndDate()),
      endDate: this.toLocalIsoDate(searchCriteria.mainTravel.when.inward.getEndDate()),
      person: searchCriteria.options.personsNumber,
      budget: searchCriteria.options.budget,
      by: searchCriteria.options.budgetAllocation,
      name: searchCriteria.options.name,
      phone: searchCriteria.options.phone,
      comment: searchCriteria.options.comment,
    };
    return this.requestService.create(data, "seminar").pipe(
      tap((result) => {
        if (result) {
          this.messageService.add({
            severity: "success",
            summary: this.translateService.instant("SEARCH.SEMINAR.SENT"),
          });
        }
      }),
    );
  }

  createBlankCriteria(): SearchCriteria<Address, SeminarSearchOptions> {
    return new SearchCriteria<Address, SeminarSearchOptions>(
      new SeminarSearchOptions(),
      new Travel<Address>(
        [],
        new WhenSearch(new DateTimeSearch(), new DateTimeSearch()),
        this.createLocalityFromJson({}),
      ),
    );
  }

  createBlankOptions(): SeminarSearchOptions {
    throw new SeminarSearchOptions();
  }

  createCriteriaFromPreviousSearch(previousSearch?: any): SearchCriteria<Address, SeminarSearchOptions> {
    if (!previousSearch) {
      return undefined;
    }
    throw new Error("Method not implemented.");
  }

  createCriteriaFromBasketItem(
    lastFolderItemsInBasket: any,
    members: MemberSociety[],
  ): SearchCriteria<Address, SeminarSearchOptions> {
    const basketItem = lastFolderItemsInBasket?.[0];
    if (!basketItem) {
      return undefined;
    }
    throw new Error("Method not implemented.");
  }
}

export class SeminarSearchOptions implements SearchOptions {
  kind: string = "";
  budget: number;
  personsNumber: number;
  budgetAllocation: string;
  name: string;
  phone: string;
  comment: string;

  isValid(): boolean {
    return (
      this.kind?.trim().length > 0 &&
      this.budget > 0 &&
      this.personsNumber > 0 &&
      this.budgetAllocation?.trim().length > 0 &&
      this.name?.trim().length > 0 &&
      this.phone?.trim().length > 0
    );
  }
}
