<div class="header">
  <div
    class="tab"
    *ngFor="let s of seatmaps; let i = index"
    [class.active]="i === displayedSeatmapIndex"
    (mousedown)="display(i)"
  >
    Vol n°{{ i + 1 }}
  </div>
</div>

<div class="main">
  <div class="description">
    <div class="flight">Vol n°{{ flightNumber }}</div>
    <div class="cabin-class">{{ cabinClass }}</div>
  </div>
  <div class="cabinFares">
    <div class="offer" *ngFor="let offer of seatmaps[displayedSeatmapIndex].offers" (mousedown)="highlight(offer.id)">
      <div class="square" [ngStyle]="{ 'background-color': offer.color }"></div>
      <div class="name">{{ offer.description }}</div>
      <div class="price">{{ offer.price | SPTPrice | async }}</div>
    </div>
  </div>
  <div class="cabin">
    <div class="position" *ngIf="!isTablet">
      <span class="avant">
        {{ "SEARCH.TRAIN.PLACING.ARRIERE" | translate }}
      </span>
      <span class="arriere">
        {{ "SEARCH.TRAIN.PLACING.AVANT" | translate }}
      </span>
    </div>
    <ng-container *ngIf="seatmaps && seatmaps.length > 0">
      <div class="seatmap-container">
        <div class="seatmap">
          <div class="toilet-column">
            <div class="toilet">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2895_88026)">
                  <path d="M40 0H0V40H40V0Z" fill="white" fill-opacity="0.01" />
                  <path
                    d="M12.5003 11.6667C14.3413 11.6667 15.8337 10.1743 15.8337 8.33333C15.8337 6.49238 14.3413 5 12.5003 5C10.6594 5 9.16699 6.49238 9.16699 8.33333C9.16699 10.1743 10.6594 11.6667 12.5003 11.6667Z"
                    fill="#A2A2C8"
                  />
                  <path
                    d="M27.5003 11.6667C29.3413 11.6667 30.8337 10.1743 30.8337 8.33333C30.8337 6.49238 29.3413 5 27.5003 5C25.6594 5 24.167 6.49238 24.167 8.33333C24.167 10.1743 25.6594 11.6667 27.5003 11.6667Z"
                    fill="#A2A2C8"
                  />
                  <path d="M8.33301 16.666H16.6663L14.9997 34.9993H9.99967L8.33301 16.666Z" fill="#A2A2C8" />
                  <path
                    d="M23.3337 16.666H31.667L33.3337 25.8327H30.8337L30.0003 34.9993H25.0003L24.167 25.8327H21.667L23.3337 16.666Z"
                    fill="#A2A2C8"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2895_88026">
                    <rect width="40" height="40" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="toilet">
              <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.5 26H6.73215C4.94668 26 3.23433 25.3626 1.9718 24.2281C0.709279 23.0935 0 21.5548 0 19.9503V11.317C0 10.6297 0.303841 9.97051 0.844681 9.4845C1.38552 8.99848 2.11906 8.72544 2.88392 8.72544H16.3392C17.1041 8.72544 17.8376 8.99848 18.3785 9.4845C18.9193 9.97051 19.2231 10.6297 19.2231 11.317V19.9503C19.2231 21.5534 18.5151 23.0909 17.2545 24.2252C15.9939 25.3595 14.2839 25.9979 12.5 26Z"
                  fill="#A2A2C8"
                />
                <path
                  d="M21.1518 20.7034C20.921 20.6979 20.7002 20.6182 20.5293 20.4788C20.3584 20.3394 20.2488 20.1495 20.2204 19.9437C20.1921 19.7378 20.2468 19.5297 20.3747 19.357C20.5026 19.1843 20.6952 19.0586 20.9174 19.0027C21.536 18.8606 22.0843 18.5375 22.476 18.0844C22.8676 17.6313 23.0803 17.0739 23.0804 16.5002V13.9086C23.0804 13.6788 22.9788 13.4584 22.7979 13.2959C22.6171 13.1334 22.3718 13.0421 22.1161 13.0421H21.1518C20.9133 13.0213 20.6919 12.9214 20.5308 12.762C20.3698 12.6025 20.2806 12.3949 20.2806 12.1795C20.2806 11.9642 20.3698 11.7566 20.5308 11.5971C20.6919 11.4377 20.9133 11.3378 21.1518 11.317H22.1161C22.8809 11.317 23.6145 11.5901 24.1553 12.0761C24.6962 12.5621 25 13.2213 25 13.9086V16.5002C25.0013 17.4577 24.6476 18.3883 23.9948 19.145C23.342 19.9018 22.4273 20.4416 21.3951 20.6791C21.3156 20.6972 21.2337 20.7053 21.1518 20.7034Z"
                  fill="#A2A2C8"
                />
                <path
                  d="M9.61608 7.85887C9.36032 7.85887 9.11505 7.76758 8.93421 7.60506C8.75336 7.44255 8.65176 7.22214 8.65176 6.99231C8.65176 6.76248 8.55017 6.54207 8.36932 6.37956C8.18848 6.21705 7.9432 6.12575 7.68745 6.12575H4.81254C4.24211 6.12647 3.68425 5.97516 3.20952 5.69095C2.73478 5.40674 2.36451 5.00239 2.14552 4.52906C1.92653 4.05572 1.86867 3.53466 1.97925 3.03177C2.08983 2.52888 2.36389 2.06676 2.76676 1.70385C3.0333 1.45989 3.35185 1.26674 3.70336 1.13594C4.05486 1.00513 4.43211 0.939367 4.81254 0.94257H12.5631C12.7188 0.940316 12.8713 0.901828 13.0055 0.830859C13.1398 0.75989 13.2513 0.658864 13.3291 0.537634C13.3803 0.425635 13.4569 0.324507 13.5542 0.240767C13.6515 0.157027 13.7672 0.092534 13.894 0.051444C14.0207 0.010354 14.1556 -0.00642093 14.2899 0.0022003C14.4243 0.0108215 14.5552 0.0446476 14.6741 0.101498C14.7931 0.158349 14.8974 0.236963 14.9805 0.332274C15.0635 0.427585 15.1235 0.537477 15.1564 0.654868C15.1893 0.772259 15.1944 0.894544 15.1715 1.01384C15.1487 1.13314 15.0982 1.2468 15.0234 1.34751C14.7854 1.74998 14.4297 2.0864 13.9945 2.32047C13.5593 2.55454 13.0612 2.67737 12.5541 2.6757H4.81254C4.68661 2.67277 4.56139 2.6936 4.44497 2.73684C4.32855 2.78008 4.22351 2.84478 4.13662 2.92676C4.00107 3.04748 3.90842 3.20163 3.87042 3.3697C3.83241 3.53777 3.85076 3.71219 3.92313 3.87088C3.99551 4.02957 4.11866 4.1654 4.27698 4.26116C4.43531 4.35692 4.6217 4.40831 4.81254 4.40882H7.69647C8.07519 4.40882 8.4502 4.47585 8.80009 4.60609C9.14999 4.73633 9.46791 4.92723 9.73571 5.16788C10.0035 5.40853 10.2159 5.69423 10.3609 6.00865C10.5058 6.32308 10.5804 6.66008 10.5804 7.00041C10.578 7.22883 10.4754 7.44717 10.2948 7.60793C10.1142 7.7687 9.87027 7.85888 9.61608 7.85887Z"
                  fill="#A2A2C8"
                />
              </svg>
            </div>
            <div class="toilet">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2895_88026)">
                  <path d="M40 0H0V40H40V0Z" fill="white" fill-opacity="0.01" />
                  <path
                    d="M12.5003 11.6667C14.3413 11.6667 15.8337 10.1743 15.8337 8.33333C15.8337 6.49238 14.3413 5 12.5003 5C10.6594 5 9.16699 6.49238 9.16699 8.33333C9.16699 10.1743 10.6594 11.6667 12.5003 11.6667Z"
                    fill="#A2A2C8"
                  />
                  <path
                    d="M27.5003 11.6667C29.3413 11.6667 30.8337 10.1743 30.8337 8.33333C30.8337 6.49238 29.3413 5 27.5003 5C25.6594 5 24.167 6.49238 24.167 8.33333C24.167 10.1743 25.6594 11.6667 27.5003 11.6667Z"
                    fill="#A2A2C8"
                  />
                  <path d="M8.33301 16.666H16.6663L14.9997 34.9993H9.99967L8.33301 16.666Z" fill="#A2A2C8" />
                  <path
                    d="M23.3337 16.666H31.667L33.3337 25.8327H30.8337L30.0003 34.9993H25.0003L24.167 25.8327H21.667L23.3337 16.666Z"
                    fill="#A2A2C8"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2895_88026">
                    <rect width="40" height="40" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div class="line-container">
            <div class="line" *ngFor="let columnId of seatmaps[displayedSeatmapIndex].columnID">
              <div class="seat-row" *ngFor="let seatRow of seatmaps[displayedSeatmapIndex].seatRows">
                <ng-container *ngFor="let seat of seatRow.Seat">
                  <ng-container *ngIf="seat.ColumnID === columnId">
                    <div
                      class="seat"
                      (click)="seat?.price ? op.toggle($event) : ''"
                      [class.occupied]="seat.OccupationStatusCode !== 'VACANT'"
                      [class.free]="seat.OccupationStatusCode === 'VACANT'"
                      [ngStyle]="{
                        'background-color': seat.OccupationStatusCode === 'VACANT' ? seat?.offerItem.color : 'EAEAF5'
                      }"
                      [class.highlight]="seat.OfferItemRefID === highlightedOffer"
                    >
                      <ng-container *ngIf="seat?.price; else occuped">
                        {{ seat?.price | SPTPrice | async }}
                      </ng-container>
                      <ng-template #occuped>
                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.65096 6.5004L12.1367 10.846L10.5535 12.3797L6.06775 8.03414L1.582 12.3797L-0.00121257 10.846L4.48454 6.5004L-0.00121219 2.15483L1.582 0.621093L6.06775 4.96667L10.5535 0.621094L12.1367 2.15483L7.65096 6.5004Z"
                            fill="#A2A2C8"
                          />
                        </svg>
                      </ng-template>
                    </div>
                    <p-overlayPanel #op [style]="{ width: '268' }" [showCloseIcon]="true">
                      <ng-template pTemplate="content">
                        <div style="width: 100%; height: 100%; position: relative">
                          <div
                            style="
                              width: 268px;
                              height: 369px;
                              padding-top: 30px;
                              padding-bottom: 30px;
                              padding-left: 30px;
                              padding-right: 25px;
                              left: 20px;
                              top: 0px;
                              position: absolute;
                              background: #fcfcfc;
                              box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
                              border-radius: 30px;
                              overflow: hidden;
                              flex-direction: column;
                              justify-content: flex-start;
                              align-items: flex-start;
                              gap: 20px;
                              display: inline-flex;
                            "
                          >
                            <img style="width: 214px; height: 100px; border-radius: 11px" [src]="seat?.cover" />
                            <div
                              style="
                                color: #312f65;
                                font-size: 24px;
                                font-family: Segoe UI;
                                font-weight: 700;
                                line-height: 31.2px;
                                letter-spacing: 0.24px;
                                word-wrap: break-word;
                              "
                            >
                              Choix du siège
                            </div>
                            <div
                              style="
                                width: 319px;
                                color: #312f65;
                                font-size: 16px;
                                font-family: Segoe UI;
                                font-weight: 400;
                                line-height: 18px;
                                word-wrap: break-word;
                              "
                            >
                              {{ seat?.offerItem?.description }}
                            </div>
                            <div
                              style="
                                align-self: stretch;
                                height: 65px;
                                padding-top: 20px;
                                padding-bottom: 20px;
                                padding-left: 20px;
                                padding-right: 12px;
                                background: #604fd7;
                                border-radius: 18.2px;
                                overflow: hidden;
                                justify-content: flex-end;
                                align-items: center;
                                gap: 14px;
                                display: inline-flex;
                              "
                            >
                              <div
                                style="
                                  width: 43px;
                                  height: 25px;
                                  padding-right: 0.54px;
                                  justify-content: center;
                                  align-items: center;
                                  display: inline-flex;
                                "
                              >
                                <div
                                  style="
                                    flex: 1 1 0;
                                    align-self: stretch;
                                    padding-bottom: 0.02px;
                                    padding-right: 0.46px;
                                    background: #fcfcfc;
                                    border-radius: 8.34px;
                                    justify-content: center;
                                    align-items: center;
                                    display: inline-flex;
                                  "
                                >
                                  <div
                                    style="
                                      width: 42px;
                                      height: 25px;
                                      text-align: center;
                                      color: #604fd7;
                                      font-size: 15px;
                                      font-family: Segoe UI;
                                      font-weight: 700;
                                      text-transform: capitalize;
                                      word-wrap: break-word;
                                    "
                                  >
                                    {{ seat?.rowNumber }} {{ seat?.ColumnID }}
                                  </div>
                                </div>
                              </div>
                              <div style="width: 225px">
                                <span
                                  *ngIf="seat?.OfferItem?.Service?.Desc?.DescText"
                                  style="
                                    color: #fcfcfc;
                                    font-size: 15px;
                                    font-family: Segoe UI;
                                    font-weight: 700;
                                    line-height: 19.5px;
                                    letter-spacing: 0.15px;
                                    word-wrap: break-word;
                                  "
                                >
                                  {{ seat?.OfferItem?.Service?.Desc?.DescText }},</span
                                ><span
                                  style="
                                    color: #fcfcfc;
                                    font-size: 15px;
                                    font-family: Segoe UI;
                                    font-weight: 400;
                                    line-height: 19.5px;
                                    letter-spacing: 0.15px;
                                    word-wrap: break-word;
                                  "
                                >
                                  {{ seat?.price | SPTPrice | async }}</span
                                >
                              </div>
                            </div>
                          </div>
                          <div
                            (click)="seat?.price ? selectSeat(seat) : ''; op.hide()"
                            style="
                              cursor: pointer;
                              width: 105px;
                              height: 44px;
                              padding-left: 40px;
                              padding-right: 40px;
                              padding-top: 10px;
                              padding-bottom: 10px;
                              left: 159px;
                              top: 313px;
                              position: absolute;
                              background: #7de1c2;
                              box-shadow: 0px 1px 20px rgba(49, 47, 101, 0.15);
                              border-radius: 50px;
                              flex-direction: column;
                              justify-content: center;
                              align-items: center;
                              gap: 10px;
                              display: inline-flex;
                            "
                          >
                            <div
                              style="
                                text-align: center;
                                color: #fcfcfc;
                                font-size: 13px;
                                font-family: Segoe UI;
                                font-weight: 600;
                                line-height: 24px;
                              "
                            >
                              Choisir
                            </div>
                          </div>
                          <div
                            (click)="op.hide()"
                            style="
                              cursor: pointer;
                              width: 99px;
                              height: 44px;
                              padding-left: 40px;
                              padding-right: 40px;
                              padding-top: 10px;
                              padding-bottom: 10px;
                              left: 50px;
                              top: 313px;
                              position: absolute;
                              background: #fcfcfc;
                              box-shadow: 0px 1px 20px rgba(49, 47, 101, 0.15);
                              border-radius: 50px;
                              border: 2px #f67951 solid;
                              flex-direction: column;
                              justify-content: center;
                              align-items: center;
                              gap: 10px;
                              display: inline-flex;
                            "
                          >
                            <div
                              style="
                                text-align: center;
                                color: #f67951;
                                font-size: 13px;
                                font-family: Segoe UI;
                                font-weight: 600;
                                line-height: 24px;
                                word-wrap: break-word;
                              "
                            >
                              Annuler
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </p-overlayPanel>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="toilet-column">
            <div class="toilet">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2895_88026)">
                  <path d="M40 0H0V40H40V0Z" fill="white" fill-opacity="0.01" />
                  <path
                    d="M12.5003 11.6667C14.3413 11.6667 15.8337 10.1743 15.8337 8.33333C15.8337 6.49238 14.3413 5 12.5003 5C10.6594 5 9.16699 6.49238 9.16699 8.33333C9.16699 10.1743 10.6594 11.6667 12.5003 11.6667Z"
                    fill="#A2A2C8"
                  />
                  <path
                    d="M27.5003 11.6667C29.3413 11.6667 30.8337 10.1743 30.8337 8.33333C30.8337 6.49238 29.3413 5 27.5003 5C25.6594 5 24.167 6.49238 24.167 8.33333C24.167 10.1743 25.6594 11.6667 27.5003 11.6667Z"
                    fill="#A2A2C8"
                  />
                  <path d="M8.33301 16.666H16.6663L14.9997 34.9993H9.99967L8.33301 16.666Z" fill="#A2A2C8" />
                  <path
                    d="M23.3337 16.666H31.667L33.3337 25.8327H30.8337L30.0003 34.9993H25.0003L24.167 25.8327H21.667L23.3337 16.666Z"
                    fill="#A2A2C8"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2895_88026">
                    <rect width="40" height="40" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="toilet">
              <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.5 26H6.73215C4.94668 26 3.23433 25.3626 1.9718 24.2281C0.709279 23.0935 0 21.5548 0 19.9503V11.317C0 10.6297 0.303841 9.97051 0.844681 9.4845C1.38552 8.99848 2.11906 8.72544 2.88392 8.72544H16.3392C17.1041 8.72544 17.8376 8.99848 18.3785 9.4845C18.9193 9.97051 19.2231 10.6297 19.2231 11.317V19.9503C19.2231 21.5534 18.5151 23.0909 17.2545 24.2252C15.9939 25.3595 14.2839 25.9979 12.5 26Z"
                  fill="#A2A2C8"
                />
                <path
                  d="M21.1518 20.7034C20.921 20.6979 20.7002 20.6182 20.5293 20.4788C20.3584 20.3394 20.2488 20.1495 20.2204 19.9437C20.1921 19.7378 20.2468 19.5297 20.3747 19.357C20.5026 19.1843 20.6952 19.0586 20.9174 19.0027C21.536 18.8606 22.0843 18.5375 22.476 18.0844C22.8676 17.6313 23.0803 17.0739 23.0804 16.5002V13.9086C23.0804 13.6788 22.9788 13.4584 22.7979 13.2959C22.6171 13.1334 22.3718 13.0421 22.1161 13.0421H21.1518C20.9133 13.0213 20.6919 12.9214 20.5308 12.762C20.3698 12.6025 20.2806 12.3949 20.2806 12.1795C20.2806 11.9642 20.3698 11.7566 20.5308 11.5971C20.6919 11.4377 20.9133 11.3378 21.1518 11.317H22.1161C22.8809 11.317 23.6145 11.5901 24.1553 12.0761C24.6962 12.5621 25 13.2213 25 13.9086V16.5002C25.0013 17.4577 24.6476 18.3883 23.9948 19.145C23.342 19.9018 22.4273 20.4416 21.3951 20.6791C21.3156 20.6972 21.2337 20.7053 21.1518 20.7034Z"
                  fill="#A2A2C8"
                />
                <path
                  d="M9.61608 7.85887C9.36032 7.85887 9.11505 7.76758 8.93421 7.60506C8.75336 7.44255 8.65176 7.22214 8.65176 6.99231C8.65176 6.76248 8.55017 6.54207 8.36932 6.37956C8.18848 6.21705 7.9432 6.12575 7.68745 6.12575H4.81254C4.24211 6.12647 3.68425 5.97516 3.20952 5.69095C2.73478 5.40674 2.36451 5.00239 2.14552 4.52906C1.92653 4.05572 1.86867 3.53466 1.97925 3.03177C2.08983 2.52888 2.36389 2.06676 2.76676 1.70385C3.0333 1.45989 3.35185 1.26674 3.70336 1.13594C4.05486 1.00513 4.43211 0.939367 4.81254 0.94257H12.5631C12.7188 0.940316 12.8713 0.901828 13.0055 0.830859C13.1398 0.75989 13.2513 0.658864 13.3291 0.537634C13.3803 0.425635 13.4569 0.324507 13.5542 0.240767C13.6515 0.157027 13.7672 0.092534 13.894 0.051444C14.0207 0.010354 14.1556 -0.00642093 14.2899 0.0022003C14.4243 0.0108215 14.5552 0.0446476 14.6741 0.101498C14.7931 0.158349 14.8974 0.236963 14.9805 0.332274C15.0635 0.427585 15.1235 0.537477 15.1564 0.654868C15.1893 0.772259 15.1944 0.894544 15.1715 1.01384C15.1487 1.13314 15.0982 1.2468 15.0234 1.34751C14.7854 1.74998 14.4297 2.0864 13.9945 2.32047C13.5593 2.55454 13.0612 2.67737 12.5541 2.6757H4.81254C4.68661 2.67277 4.56139 2.6936 4.44497 2.73684C4.32855 2.78008 4.22351 2.84478 4.13662 2.92676C4.00107 3.04748 3.90842 3.20163 3.87042 3.3697C3.83241 3.53777 3.85076 3.71219 3.92313 3.87088C3.99551 4.02957 4.11866 4.1654 4.27698 4.26116C4.43531 4.35692 4.6217 4.40831 4.81254 4.40882H7.69647C8.07519 4.40882 8.4502 4.47585 8.80009 4.60609C9.14999 4.73633 9.46791 4.92723 9.73571 5.16788C10.0035 5.40853 10.2159 5.69423 10.3609 6.00865C10.5058 6.32308 10.5804 6.66008 10.5804 7.00041C10.578 7.22883 10.4754 7.44717 10.2948 7.60793C10.1142 7.7687 9.87027 7.85888 9.61608 7.85887Z"
                  fill="#A2A2C8"
                />
              </svg>
            </div>
            <div class="toilet">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2895_88026)">
                  <path d="M40 0H0V40H40V0Z" fill="white" fill-opacity="0.01" />
                  <path
                    d="M12.5003 11.6667C14.3413 11.6667 15.8337 10.1743 15.8337 8.33333C15.8337 6.49238 14.3413 5 12.5003 5C10.6594 5 9.16699 6.49238 9.16699 8.33333C9.16699 10.1743 10.6594 11.6667 12.5003 11.6667Z"
                    fill="#A2A2C8"
                  />
                  <path
                    d="M27.5003 11.6667C29.3413 11.6667 30.8337 10.1743 30.8337 8.33333C30.8337 6.49238 29.3413 5 27.5003 5C25.6594 5 24.167 6.49238 24.167 8.33333C24.167 10.1743 25.6594 11.6667 27.5003 11.6667Z"
                    fill="#A2A2C8"
                  />
                  <path d="M8.33301 16.666H16.6663L14.9997 34.9993H9.99967L8.33301 16.666Z" fill="#A2A2C8" />
                  <path
                    d="M23.3337 16.666H31.667L33.3337 25.8327H30.8337L30.0003 34.9993H25.0003L24.167 25.8327H21.667L23.3337 16.666Z"
                    fill="#A2A2C8"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2895_88026">
                    <rect width="40" height="40" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div class="board-cabin"></div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="selectedSeat" *ngIf="seatMapUsers?.length > 0">
    <div
      class="user"
      *ngFor="let seatMapdUser of seatMapUsers"
      [class.selected]="selectedUser?.PaxID === seatMapdUser.PaxID"
      (click)="selectUser(seatMapdUser)"
    >
      <div class="number">
        <ng-container *ngIf="seatMapdUser.seat; else noSeatSelected">
          {{ seatMapdUser.seat?.rowNumber }} {{ seatMapdUser.seat?.ColumnID }}
        </ng-container>
        <ng-template #noSeatSelected>--</ng-template>
      </div>
      {{ seatMapdUser?.Individual?.Surname }} {{ seatMapdUser?.Individual?.GivenName }}:
      {{ seatMapdUser.seat?.price !== null ? seatMapdUser.seat?.price : {amount: "0.00", currency: 'EUR'} | SPTPrice | async }}
    </div>
  </div>
</div>

<div class="action">
  <p-button
    iconPos="left"
    styleClass="p-button-tertiary"
    label="{{ 'GLOBAL.CANCEL' | translate }}"
    type="button"
    (mousedown)="cancel()"
  ></p-button>

  <p-button
    iconPos="left"
    styleClass="p-button-primary"
    label="{{ 'GLOBAL.VALIDATE' | translate }}"
    type="button"
    (mousedown)="validate()"
  ></p-button>
</div>
