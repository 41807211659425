<div class="info-price">
  <div class="price-carbon">
    <div class="price">
      {{
        {
          amount: availability.price,
          currency: availability.currency
        }
          | SPTPrice
          | async
      }}
    </div>
    <div class="carbon-offset">
      <spt-travel-carbon-offset-info [carbonOffset]="carbonOffset"></spt-travel-carbon-offset-info>
    </div>
  </div>
  <div class="cancellation" [class.bold]="cancellationPolicy">
    <ng-container *ngIf="cancellationPolicy">
      <img src="./assets/svg/checked-violet-rounded.svg" alt="" />
      {{ cancellationPolicy?.cfps[0] | refundText: availability.datein : availability.price }}
    </ng-container>
    <ng-container *ngIf="cancellationPolicy === null">
      <img src="./assets/svg/cross-rounded.svg" alt="" />
      {{ "SEARCH.RESULT.HOTEL.REFUNDS.NO_REFUND" | translate }}
    </ng-container>
  </div>
</div>
<div>
  <p-button (click)="selectedOffer()">{{ "primeng.choose" | translate }}</p-button>
  <spt-out-of-policy
    [isOutOfPolicy]="inPolicy === false"
    [authorizationsByPolicies]="availability.authorizationsByPolicies"
    type="hotel"
    *ngIf="!(commonService.isTabletObservable | async)"
  ></spt-out-of-policy>
</div>

<spt-out-of-policy
  [isOutOfPolicy]="inPolicy === false"
  [authorizationsByPolicies]="availability.authorizationsByPolicies"
  type="hotel"
  [displayInfo]="(commonService.isTabletObservable | async) ? false : true"
  *ngIf="commonService.isTabletObservable | async"
></spt-out-of-policy>
