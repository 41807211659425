import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { map, tap } from "rxjs/operators";
import { BehaviorSubject, forkJoin, Observable, of } from "rxjs";
import { TitleEnum } from "src/app/@shared/@types/models";
import { User } from "../@types/user";
import { SocietyService } from "./society.service";

/**
 * Mapping entre le format de date PrimeNG (https://primeng.org/calendar#format)
 * et le format "standard" Angular (https://angular.io/api/common/DatePipe#custom-format-options)
 */
const DATE_FORMAT_MAPPING = {
  // DD.MM.YYYY (26.08.2022)
  "dd.mm.yy": "dd.MM.yyyy",
  // DD-MM-YY (26-08-22)
  "dd-mm-y": "dd-MM-yy",
  // YYYY-MM-DD (2022-26-08)
  "yy-mm-dd": "yyyy-MM-dd",
  // MM-DD-YY (08-26-22)
  "mm-dd-y": "MM-dd-yy",
  // DD/MM/YY (26/08/22)
  "dd/mm/y": "dd/MM/yy",
  // DD/MM/YYYY (26/08/2022)
  "dd/mm/yy": "dd/MM/yyyy",
};
export const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public user: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor(
    private httpClient: HttpClient,
    private societyService: SocietyService,
  ) {}

  public reset(): void {
    this.user.next(null);
  }

  public getUser(userId: string, saveCurrent: boolean): Observable<User> {
    const endPoint: string = `${environment.api}/api/users/${userId}`;

    return this.httpClient.get(endPoint).pipe(
      map((res: any): User => this.mapUserFront(res.data)),
      tap((user: User) => {
        user.settings.nativeDateFormat = DATE_FORMAT_MAPPING[user?.settings?.dateFormat] || DEFAULT_DATE_FORMAT;
        if (!user.title) {
          user.title = TitleEnum.mr;
        }
        if (saveCurrent) {
          const emailRegex = /^executive\+.+@supertripper\.com$/;
          user.isAdmin = emailRegex.test(user.email);
          // if email match executive+something@supertripper.com
          this.user.next(user);
        }
        return user;
      }),
    );
  }

  // TODO: faire le set avec les bon parametes et la bonne logique
  public setUser(user: any): Observable<User | any> {
    const endPoint: string = `${environment.api}/api/users`;

    const body: any = {
      billing: user.billing,
      birthdate: user.birthdate,
      email: user.email,
      fidelities: user.fidelities,
      firstname: user.firstname,
      lastname: user.lastname,
      memberRoles: user.memberRoles,
      passport: user.passport,
      phone: user.phone,
      policy: user.policy,
      settings: user.settings,
      title: user.title,
      unaccessible: user.unaccessible,
      customFields: user.customFields,
      bookOutOfPolicy: user.bookOutOfPolicy,
      seeOutOfPolicy: user.seeOutOfPolicy,
      book: user.book,
      bookToHim: user.bookToHim,
      managedBillings: user.managedBillings,
      validateOutOfPolicy: user.validateOutOfPolicy,
      validateBookingRequest: user.validateBookingRequest,
      selectEntity: user.selectEntity,
      mustSelectAService: user.mustSelectAService,
      validateOffset: user.validateOffset,
      invited: user.invited,
      expirationDate: user.expirationDate,
      activationDate: user.activationDate,
      canCreateUser: user.canCreateUser,
      manage: user.manage,
      manageCustom: user.manageCustom,
      delegations: user.delegations,
    };
    return this.httpClient.post(endPoint, body).pipe(map((res: any): User => this.mapUserFront(res.data)));
  }

  public putUser(userId: string, societyId: string, data: any): Observable<User | any> {
    const endPointSociety: string = `${environment.api}/api/society/${societyId}`;
    const endPointUser: string = `${environment.api}/api/users/${userId}`;

    const bodyUser: any = {
      birthdate: data.birthdate,
      title: data.title,
      changeStatus: data.status,
      setFidelities: data.fidelities,
      travelSubscriptions: data.subscriptions,
      setPassport: data.passport,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      customFields: data.customFields,
      phone: data.phone,
      settings: data.settings,
      unaccessible: data.unaccessible,
    };
    const bodySociety: any = {
      billing: data.billing,
      idsMember: data.idsMember,
      manage: data.manage,
      manageCustom: data.manageCustom,
      managedBillings: data.managedBillings,
      policy: data.policy === "" ? null : data.policy,
      updateRoles: data.roles,
      bookOutOfPolicy: data.bookOutOfPolicy,
      seeOutOfPolicy: data.seeOutOfPolicy,
      book: data.book,
      bookToHim: data.bookToHim,
      validateOutOfPolicy: data.validateOutOfPolicy,
      selectEntity: data.selectEntity,
      mustSelectAService: data.mustSelectAService,
      validateOffset: data.validateOffset,
      invited: data.invited,
      expirationDate: data.expirationDate,
      activationDate: data.activationDate,
      validateBookingRequest: data.validateBookingRequest,
      canCreateUser: data.canCreateUser,
      delegations: data.delegations,
    };

    let observable: Observable<any>;
    if (societyId && userId && Object.keys(bodySociety).length > 1 && Object.keys(bodyUser).length > 1) {
      observable = forkJoin([
        this.httpClient.put(endPointSociety, bodySociety),
        this.httpClient.put(endPointUser, bodyUser),
      ]).pipe(map((res: Array<any>) => this.mapForkJoin(res)));
    } else if (userId && Object.keys(bodyUser).length > 1) {
      observable = this.httpClient.put(endPointUser, bodyUser).pipe(
        tap((res: any) => {
          this.societyService.updateUser(res.data);
          if (this.user.value._id === res.data._id) {
            this.user.next({
              ...this.user.value,
              ...res.data,
            });
          }
        }),
      );
    } else if (societyId && Object.keys(bodySociety).length > 1) {
      observable = this.httpClient.put(endPointSociety, bodySociety);
    } else {
      observable = of({ status: 400, message: "body is empty" });
    }

    return observable;
  }

  sendInvitMail(credentials: { user: string }): Observable<any> {
    const endPoint: string = `${environment.api}/api/utils/invitation`;

    return this.httpClient.post(endPoint, credentials);
  }

  mapForkJoin(data: Array<any>): User {
    return data[1] as User;
  }
  mapUserFront(data: any): User {
    return data as User;
  }

  mapUserBack(user: User): any {
    return user;
  }
}
