import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, forkJoin, from, of } from "rxjs";
import { map, mergeAll, mergeMap, shareReplay, tap } from "rxjs/operators";
import { Address } from "src/app/@shared/@types/models";

declare const google: any;

@Injectable({
  providedIn: "root",
})
export class GoogleMapsService {
  constructor(private translateService: TranslateService) {}

  getPlace(val: string): Observable<google.maps.places.QueryAutocompletePrediction> {
    const promiseGetPredictions: Promise<Array<google.maps.places.AutocompletePrediction>> = new Promise(
      (resolve: any, reject: any): void => {
        const autocomplete: google.maps.places.AutocompleteService = new google.maps.places.AutocompleteService();
        autocomplete.getQueryPredictions(
          {
            input: val,
          },
          (
            res: Array<google.maps.places.QueryAutocompletePrediction>,
            status: google.maps.places.PlacesServiceStatus,
          ) => {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
              reject(status);
            } else {
              resolve(res);
            }
          },
        );
      },
    );

    return from(promiseGetPredictions).pipe(
      map((results: Array<google.maps.places.QueryAutocompletePrediction>) => {
        return results[0];
      }),
    );
  }

  getPlaceId(val: string): Observable<google.maps.places.AutocompletePrediction> {
    const promiseGetPredictions: Promise<Array<google.maps.places.AutocompletePrediction>> = new Promise(
      (resolve: any, reject: any): void => {
        const autocomplete: google.maps.places.AutocompleteService = new google.maps.places.AutocompleteService();
        autocomplete.getPlacePredictions(
          {
            input: val,
            types: ["geocode"],
          },
          (res: Array<google.maps.places.AutocompletePrediction>, status: google.maps.places.PlacesServiceStatus) => {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
              reject(status);
            } else {
              resolve(res);
            }
          },
        );
      },
    );

    return from(promiseGetPredictions).pipe(
      map((results: Array<google.maps.places.AutocompletePrediction>) => results[0]),
    );
  }

  addressFromPlaceId(placeId: string): Observable<Address> {
    return this.geocodePlaceId(placeId).pipe(map((result) => this.formatAddress(result)));
  }

  nameOfPlaceId(placeId: string): Observable<string> {
    return this.geocodePlaceId(placeId).pipe(map((result) => result.address_components[0].long_name));
  }

  resolveNameOfAllPlaceIds(unresolvedLabelPlaceIdList: string[]): Observable<{ [placeId: string]: string }> {
    if (!unresolvedLabelPlaceIdList || unresolvedLabelPlaceIdList.length === 0) {
      return of({});
    }
    return forkJoin(
      unresolvedLabelPlaceIdList.map((placeId) => this.nameOfPlaceId(placeId).pipe(map((label) => [placeId, label]))),
    ).pipe(map((entries) => Object.fromEntries(entries) as { [placeId: string]: string }));
  }

  resolvedPlaceIdForAllCountryByPlaceIdOfACity(unresolvedCountryPlaceIdByPlaceIdOfACity: {
    [countryCode: string]: string;
  }): Observable<{ [countryCode: string]: string }> {
    const observableList = Object.entries(unresolvedCountryPlaceIdByPlaceIdOfACity).map(
      ([countryCode, placeIdOfACity]) =>
        this.addressFromPlaceId(placeIdOfACity).pipe(
          mergeMap((address: any) =>
            this.getCountryPlaceId({
              lat:
                typeof address.coordinates?.[0] === "number"
                  ? (address.coordinates?.[0] as number)
                  : parseFloat(address.coordinates?.[0]),
              lng:
                typeof address.coordinates?.[1] === "number"
                  ? (address.coordinates?.[1] as number)
                  : parseFloat(address.coordinates?.[1]),
            } as google.maps.LatLngLiteral).pipe(map((result) => [countryCode, result.place_id])),
          ),
        ),
    );
    if (observableList.length === 0) {
      return of({});
    }
    return forkJoin(observableList).pipe(
      map((entries) => Object.fromEntries(entries) as { [countryCode: string]: string }),
    );
  }

  private geocodePlaceIdCache: { [lang: string]: { [placeid: string]: Observable<google.maps.GeocoderResult> } } = {};
  private geocodePlaceId(placeId: string): Observable<google.maps.GeocoderResult> {
    let resultFromCache = this.geocodePlaceIdCache[this.translateService.currentLang]?.[placeId];
    if (!resultFromCache) {
      if (!this.geocodePlaceIdCache[this.translateService.currentLang]) {
        this.geocodePlaceIdCache[this.translateService.currentLang] = {};
      }
      const promiseGeocoder: any = new Promise((resolve: any, reject: any): void => {
        const geocoder: google.maps.Geocoder = new google.maps.Geocoder();

        geocoder.geocode(
          {
            placeId: placeId,
          },
          (res: Array<google.maps.GeocoderResult>, status: google.maps.GeocoderStatus) => {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
              reject(status);
            } else {
              resolve(res);
            }
          },
        );
      });
      resultFromCache = from(promiseGeocoder).pipe(
        map((results: Array<google.maps.GeocoderResult>) => results[0]),
        // Pour la mise en cache
        shareReplay(1),
      );

      this.geocodePlaceIdCache[this.translateService.currentLang][placeId] = resultFromCache;
    }
    return resultFromCache;
  }

  formatAddress(addrObj: google.maps.places.PlaceResult | google.maps.GeocoderResult): Address {
    const add: Address | any = {
      place_id: addrObj.place_id,
      street: (<google.maps.places.PlaceResult>addrObj).name,
      city: (<google.maps.places.PlaceResult>addrObj).vicinity,
      coordinates: [addrObj.geometry.location.lat(), addrObj.geometry.location.lng()],
    };
    addrObj.address_components.forEach((item: google.maps.GeocoderAddressComponent) => {
      if (item.types.indexOf("administrative_area_level_1") > -1) {
        add.area = item.short_name;
      }
      if (item.types.indexOf("country") > -1) {
        add.country = item.long_name;
        add.countryCode = item.short_name;
      }
      if (item.types.indexOf("postal_code") > -1) {
        add.postal_code = item.short_name;
      }
    });

    return add;
  }

  getDirections(directionsRequest: google.maps.DirectionsRequest): Observable<google.maps.DirectionsResult> {
    const directionService: google.maps.DirectionsService = new google.maps.DirectionsService();
    const promiseDirections: Promise<google.maps.DirectionsResult> = new Promise((resolve: any, reject: any): any => {
      directionService.route(
        directionsRequest,
        (result: google.maps.DirectionsResult, status: google.maps.DirectionsStatus) => {
          if (status === google.maps.DirectionStatus.OK) {
            resolve(result);
          } else {
            reject(status);
          }
        },
      );
    });

    return from(promiseDirections);
  }

  private getCountryPlaceIdCache: {
    [lang: string]: { [latlng: string]: Observable<google.maps.GeocoderResult> };
  } = {};
  /**
   * Permet de récupérer les informations d'un pays à partir de coordonnées GPS.
   * Pas de solution simple pour obtenir le place_id d'un pays à partir d'un code pays.
   * Pour le moment, on ne peux le faire qu'à partir des coordonnées GPS
   * Cf. https://stackoverflow.com/questions/50527643/retrieving-different-levels-of-placeids#answer-50553833
   *
   * @param countryCode
   * @returns
   */
  getCountryPlaceId(latlng: google.maps.LatLngLiteral): Observable<google.maps.GeocoderResult> {
    const cacheKey = latlng.lat + "\n" + latlng.lng;
    let resultFromCache = this.getCountryPlaceIdCache[this.translateService.currentLang]?.[cacheKey];
    if (!resultFromCache) {
      if (!this.getCountryPlaceIdCache[this.translateService.currentLang]) {
        this.getCountryPlaceIdCache[this.translateService.currentLang] = {};
      }
      resultFromCache = from(
        new google.maps.Geocoder()
          .geocode({
            location: latlng,
            language: this.translateService.currentLang,
          })
          .then(
            (response) => response.results.filter((r) => r.types.includes("country"))?.[0],
          ) as Promise<google.maps.GeocoderResult>,
      ).pipe(
        // Pour la mise en cache
        shareReplay(1),
      );
      this.geocodePlaceIdCache[this.translateService.currentLang][cacheKey] = resultFromCache;
    }

    return resultFromCache;
  }
}
