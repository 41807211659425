<div class="user-resume">
  <span>
    <div class="dot"></div>
    <spt-avatar [user]="user" [displayPicture]="false" title="{{ user.firstname }} {{ user.lastname }}"></spt-avatar>
  </span>
  <div class="traveler-type">
    <div class="price" *ngIf="provider !== 'afkl'; else afklPrice">
      {{
        {
          amount: passengerInformationPrice,
          currency: pricingInformation.fare.passengerInfos[index]
            ? pricingInformation.fare.passengerInfos[index]?.passengerTotalFare.currency
            : pricingInformation.fare.passengerInfos[0]?.passengerTotalFare.currency
        }
          | SPTPrice
          | async
      }}
    </div>
    <ng-template #afklPrice>
      <div class="price">
        {{
          {
            amount: passengerInformationPrice,
            currency: pricingInformation.price.currency
          }
            | SPTPrice
            | async
        }}
      </div>
    </ng-template>
  </div>
</div>
<div class="conditions-content">
  <ul>
    <li translate class="refundable">GLOBAL.CATEGORY.{{ refundable ? "REFUNDABLE" : "NON_REFUNDABLE" }}</li>
    <li translate class="exchangeable">GLOBAL.CATEGORY.{{ exchangeable ? "EXCHANGEABLE" : "NON_EXCHANGEABLE" }}</li>
  </ul>

  <div class="conditions-content-detail">
    <ng-container *ngIf="provider === 'afkl'; else sabrePenalities">
      <ng-container *ngIf="pricingInformation.changeablePenalities?.length > 0">
        <p *ngFor="let penalty of pricingInformation.changeablePenalities; let isLast = last">
          <ng-container *ngIf="penalty.TypeCode === 'Change' && penalty.AppCode === 'PDE'">
            <span
              *ngIf="penalty.PenaltyAmount?.$t"
              [innerHtml]="
                'GLOBAL.CATEGORY.MODIFICATION_BEFORE_FLIGHT'
                  | translate
                    : {
                        date: dateEnd | date: dateFormat,
                        price:
                          {
                            amount: penalty.PenaltyAmount.$t,
                            currency: pricingInformation.price?.currency
                          }
                          | SPTPrice
                          | async
                      }
              "
            >
            </span>
            <span *ngIf="!penalty.PenaltyAmount" translate>GLOBAL.CATEGORY.MODIFICATION_FREE_PDE</span>
          </ng-container>
        </p>
      </ng-container>

      <ng-container *ngIf="pricingInformation.refundablePenalities?.length > 0">
        <p *ngFor="let penalty of pricingInformation.refundablePenalities; let isLast = last">
          <ng-container *ngIf="penalty.TypeCode === 'Cancellation' && penalty.AppCode === 'PDE'">
            <span
              *ngIf="penalty.PenaltyAmount?.$t"
              [innerHtml]="
                'GLOBAL.FEES.CANCELLATION.BEFORE_UNTIL'
                  | translate
                    : {
                        date: dateEnd | date: dateFormat,
                        price:
                          {
                            amount: penalty.PenaltyAmount.$t,
                            currency: pricingInformation.price?.currency
                          }
                          | SPTPrice
                          | async
                      }
              "
            >
            </span>
            <span *ngIf="!penalty.PenaltyAmount" translate>GLOBAL.CATEGORY.CANCELLATION_FREE_PDE</span>
          </ng-container>
        </p>
      </ng-container>
    </ng-container>
  </div>
  <div class="luggages">
    <span translate>GLOBAL.FLIGHT_BAGAGES.INCLUDED_LUGGAGE</span>
    <ul class="luggages-type">
      <ng-container *ngIf="provider === 'afkl'; else sabreLuggage">
        <li>
          <span class="m-r-10">
            <img src="./assets/svg/bagage-soute-white.svg" alt="" />
          </span>
          <span
            class="luggage-name"
            translate
            [translateParams]="{
              number: pricingInformation.fare.passengerInfos[index]
                ? pricingInformation.fare.passengerInfos[index]?.baggageIncluded
                : pricingInformation.fare.passengerInfos[0]?.baggageIncluded
            }"
            >GLOBAL.FLIGHT_BAGAGES.BAGAGE
          </span>
          <spt-tooltip
            [triggerImg]="'info-fonce.svg'"
            [description]="'GLOBAL.FLIGHT_BAGAGES.INFORMATION.BAGAGE' | translate"
            [isSmallDevice]="commonService.isTablet"
          ></spt-tooltip>
        </li>
        <li>
          <i class="spt-icon-baggage m-r-10"></i>
          <span
            class="luggage-name"
            translate
            [translateParams]="{
              number: pricingInformation.fare.passengerInfos[index]
                ? pricingInformation.fare.passengerInfos[index]?.carryOnIncluded
                : pricingInformation.fare.passengerInfos[0]?.carryOnIncluded
            }"
            >GLOBAL.FLIGHT_BAGAGES.CARRY</span
          >
          <spt-tooltip
            [triggerImg]="'info-fonce.svg'"
            [title]="'TOOLTIP.INFORMATION' | translate"
            [description]="carryBagageDesc"
            [isSmallDevice]="commonService.isTablet"
          ></spt-tooltip>
        </li>
      </ng-container>
      <ng-template #sabreLuggage>
        <li>
          <span class="m-r-10">
            <img src="./assets/svg/bagage-soute-white.svg" alt="" />
          </span>
          <span
            class="luggage-name"
            translate
            [translateParams]="{
              number: pricingInformation.fare.passengerInfos[index]
                ? pricingInformation.fare.passengerInfos[index]?.baggageIncluded
                : pricingInformation.fare.passengerInfos[0]?.baggageIncluded
            }"
            >GLOBAL.FLIGHT_BAGAGES.BAGAGE
          </span>
          <spt-tooltip
            [triggerImg]="'info-fonce.svg'"
            [description]="'GLOBAL.FLIGHT_BAGAGES.INFORMATION.BAGAGE' | translate"
            [isSmallDevice]="commonService.isTablet"
          ></spt-tooltip>
        </li>
        <li>
          <i class="spt-icon-baggage m-r-10"></i>
          <span
            class="luggage-name"
            translate
            [translateParams]="{
              number: pricingInformation.fare.passengerInfos[index]
                ? pricingInformation.fare.passengerInfos[index]?.carryOnIncluded
                : pricingInformation.fare.passengerInfos[0]?.carryOnIncluded
            }"
            >GLOBAL.FLIGHT_BAGAGES.CARRY</span
          >
          <spt-tooltip
            [triggerImg]="'info-fonce.svg'"
            [title]="'TOOLTIP.INFORMATION' | translate"
            [description]="carryBagageDesc"
            [isSmallDevice]="commonService.isTablet"
          ></spt-tooltip>
        </li>
      </ng-template>
    </ul>
  </div>
</div>

<ng-template #sabrePenalities>
  <ng-container *ngIf="pricingInformation.fare.passengerInfos[index]?.penaltiesInfo?.penalties?.length > 0">
    <p
      *ngFor="let penalty of pricingInformation.fare.passengerInfos[index]?.penaltiesInfo?.penalties; let isLast = last"
    >
      <ng-container *ngIf="penalty.type === 'Exchange' && penalty.applicability === 'Before'">
        <span
          *ngIf="penalty.amount > -1"
          [innerHtml]="
            'GLOBAL.CATEGORY.MODIFICATION_BEFORE_UNTIL'
              | translate
                : {
                    date: dateEnd | date: dateFormat,
                    price:
                      {
                        amount: penalty.amount,
                        currency: pricingInformation.price.currency
                      }
                      | SPTPrice
                      | async
                  }
          "
        >
        </span>
      </ng-container>
      <ng-container *ngIf="penalty.type === 'Refund' && penalty.applicability === 'Before'">
        <span
          *ngIf="penalty.amount > -1"
          [innerHtml]="
            'GLOBAL.FEES.CANCELLATION.BEFORE_UNTIL'
              | translate
                : {
                    date: dateEnd | date: dateFormat,
                    price:
                      {
                        amount: penalty.amount,
                        currency: pricingInformation.price.currency
                      }
                      | SPTPrice
                      | async
                  }
          "
        >
        </span>
      </ng-container>
    </p>
  </ng-container>
</ng-template>
