import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
  ViewEncapsulation,
} from "@angular/core";
import { SearchCriteria, SearchResult } from "../search-engine-service";
import { HotelSearchOptions, HotelSearchService } from "./hotel-search.service";
import { MemberSociety } from "src/app/@shared/@types/society";
import { SessionService } from "src/app/@shared/services/session.service";
import { CommonService } from "src/app/@shared/services/common.service";
import moment from "moment";
import { Address } from "src/app/@shared/@types/models";
import { SuggestPanelService } from "../common/suggest-panel.service";
import { SearchPeopleComponent } from "../common/search-people/search-people.component";
import { MessageService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "spt-search-hotel",
  templateUrl: "./search-hotel.component.html",
  styleUrls: ["./search-hotel.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SearchHotelComponent implements OnInit, AfterViewInit {
  searchEngineType: string;
  @Input() pickableMembers: MemberSociety[];
  @ViewChildren("searchPeople") searchPeopleButtons: QueryList<SearchPeopleComponent>;

  @Output()
  onSearchResult: EventEmitter<SearchResult> = new EventEmitter<SearchResult>();
  suggestOpened: boolean = false;

  selectEmitter($event: any) {
    throw new Error("Method not implemented.");
  }
  selectedCity($event: any) {
    throw new Error("Method not implemented.");
  }

  membersList: MemberSociety[] = [];
  stars: string[] = [];
  @Input() searchCriteria!: SearchCriteria<Address, HotelSearchOptions>;
  isValid: boolean = false;
  gridTemplateAreas!: string;
  gridTemplateRows!: string;

  public yearRange: string = `${moment().format("YYYY")}:${moment().add(1, "year").format("YYYY")}`;

  changeStars() {
    Object.keys(this.searchCriteria.options.stars).forEach((key) => {
      this.searchCriteria.options.stars[key] = this.stars.includes(key);
    });
  }

  constructor(
    protected searchService: HotelSearchService,
    public commonService: CommonService,
    public sessionService: SessionService<any>,
    protected suggestPanelService: SuggestPanelService,
    private messageService: MessageService,
    private translateService: TranslateService

  ) {
    this.searchEngineType = searchService.getType();
  }

  private refreshGridTemplateAreas(): void {
    const gridTemplateAreasResult: string[] = ["title        suggest", "destination  suggest", "datetime     suggest"];
    const gridTemplateAreasRows: string[] = ["auto", "auto", "auto"];
    this.searchCriteria.travels.forEach((item, index) => {
      gridTemplateAreasResult.push(
        "room-title-" + index + "  suggest",
        "people-" + index + "  suggest",
        "remove-" + index + "  suggest",
      );
      gridTemplateAreasRows.push("auto", "auto", "auto");
    });
    gridTemplateAreasResult.push("addRoom  suggest");
    gridTemplateAreasRows.push("auto");
    gridTemplateAreasResult.push("options   suggest", "triggers  suggest");
    gridTemplateAreasRows.push("auto", "1fr");
    this.gridTemplateAreas = gridTemplateAreasResult.map((l) => "'" + l + "'").join("\n");
    this.gridTemplateRows = gridTemplateAreasRows.join(" ");
  }

  ngOnInit(): void {
    if (!this.searchCriteria) {
      this.searchCriteria = this.searchService.createBlankCriteria();
    }
    this.stars = Object.entries(this.searchCriteria.options.stars)
      .filter(([key, val]) => val)
      .map(([key, val]) => key);

    this.suggestPanelService.init(DEFAULT_SUGGEST_PANEL);
    this.refreshGridTemplateAreas();
  }

  ngAfterViewInit(): void {
    this.searchPeopleButtons.changes.subscribe((queryList: QueryList<SearchPeopleComponent>) =>
      queryList.last.activateSuggest(),
    );
  }

  activateSuggest(suggestToActivate: string) {
    this.suggestPanelService.activate(suggestToActivate);
    this.suggestOpened = true;
  }

  hasDuplicates(array) {
    const ids = new Set(array.map(user => user._id));
    return ids.size !== array.length;
  }

  search(): void {
    if(this.searchCriteria?.travels?.length > 1) {
      let users = this.searchCriteria.travels.map((travel) => travel.people).flat(1)
      if(this.hasDuplicates(users)) {
        this.messageService.add({
          severity: "error",
          detail: this.translateService.instant("NOTIFICATIONS.HOTEL_DUPLICATE_ROOM_ENTRY"),
          sticky: true
        });
        return
      }
    }
    this.searchService
      .launchSearch(this.searchCriteria)
      .subscribe((searchResult: SearchResult) => this.onSearchResult.emit(searchResult));
  }

  resetSuggestPanel(): void {
    this.suggestPanelService.reset();
    this.suggestOpened = false;
  }

  checkValidity() {
    this.isValid = this.searchService.searchCriteriaIsValid(this.searchCriteria);
  }

  removeRoom(idx): void {
    this.searchCriteria.removeTravel(idx);
    this.checkValidity();
    this.refreshGridTemplateAreas();
  }

  addRoom(): void {
    this.searchService.addRoomTo(this.searchCriteria);
    this.checkValidity();
    this.refreshGridTemplateAreas();
  }
}
const DEFAULT_SUGGEST_PANEL = "favorites";
