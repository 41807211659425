import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { CommonService } from "src/app/@shared/services/common.service";
import { UserService } from "src/app/@shared/services/user.service";

@Component({
  selector: "spt-conditions-flight",
  templateUrl: "./flight.component.html",
  styleUrls: ["./flight.component.scss"],
})
export class ConditionsFlightComponent {
  @Input() item: any;
  public dateEnd: Date;
  constructor(private userService: UserService) {}
  ngOnInit(): void {
    if (this.item?.detail?.trips[0].legs) {
      this.dateEnd = moment(
        `${this.item?.detail?.trips[0].legs[0].departureDate}T${this.item?.detail?.trips[0].legs[0].departureTime}`,
      )
        .subtract(1, "day")
        .toDate();
    } else {
      this.dateEnd = moment(`${this.item?.detail?.trips[0].Dep.AircraftScheduledDateTime}`).subtract(1, "day").toDate();
    }
  }
}
