import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { CommonService } from "../../services/common.service";

@Component({
  selector: "spt-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent {
  @Input() opened: boolean = true;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Input() styleClass: string;
  @Input() showCloseIcon = true;

  constructor(public commonService: CommonService) {}

  closeSidebar() {
    this.opened = false;
    this.close.emit();
  }
}
