import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "distance",
})
export class DistancePipe implements PipeTransform {
  constructor() {}

  transform(data: any): string {
    const distance = Math.round(data);
    if (distance >= 1000) {
      return `${Math.round(distance / 100) / 10}km`;
    } else {
      return `${distance}m`;
    }
  }
}
