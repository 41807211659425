<div class="search-description">
  <img src="./assets/svg/moon.svg" alt="" />
  <span translate [translateParams]="{ nbNights: nbNights, nbRooms: nbRooms, nbGuests: nbGuests }"
    >SEARCH.RESULT.HOTEL.DESC_TARIF</span
  >
</div>
<div class="room" *ngFor="let room of groupedPossibilitiesKeys">
  <ng-container *ngIf="groupedPossibilities[room]; let possibilities">
    <div class="title" (click)="toggleRoom(room)">
      <img *ngIf="clickedRooms[room]" class="chevronDown" src="./assets/svg/chevron.svg" alt="" />
      <img *ngIf="!clickedRooms[room]" src="./assets/svg/chevron.svg" alt="" />
      <div class="roomNames">
        <div class="name" *ngFor="let name of roomNames[room]">
          {{ name }}
        </div>
      </div>
      <div class="best-price">
        <span>{{ "GLOBAL.FROM" | translate }}</span>
        <div class="price">
          {{
            {
              amount: bestPriceByRoom[room],
              currency: availabilities?.bestPriceCurrency
            }
              | SPTPrice
              | async
          }}
        </div>
      </div>
    </div>
    <div *ngIf="clickedRooms[room]" class="possibilities">
      <div class="possibility" *ngFor="let possibility of possibilities | keyvalue">
        <div class="type" translate>
          <img src="./assets/svg/breakfast.svg" alt="" *ngIf="possibility.key === 'BB'" />
          <span translate>SEARCH.RESULT.HOTEL.TYPE_BREAKFAST.{{ possibility.key }}</span>
        </div>
        <div class="fares">
          <div
            class="fare"
            *ngFor="let availability of possibility.value"
            [class.selected]="selectedAvailability === availability"
          >
            <spt-travel-hotel-page-availabilities-availability
              [availability]="availability"
              (isSelected)="select($event)"
              (outdatedInformations)="outdatedInformations.emit()"
            ></spt-travel-hotel-page-availabilities-availability>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
