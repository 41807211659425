<div class="block-container">
  <div class="vertical-separator" *ngIf="(commonService.isTabletObservable | async) === false">
    <span class="round"></span>
    <span class="line"></span>
    <span class="round"></span>
  </div>
  <div class="reservations-container">
    <div class="reservation" *ngFor="let transfer of item.detail.booking.transfers; let i = index">
      <div class="mini-separator" *ngIf="i === 1 && (commonService.isTabletObservable | async) === true"></div>
      <p class="direction" *ngIf="(commonService.isTabletObservable | async) === true">
        {{ (i === 0 ? "GLOBAL.OUTWARD" : "GLOBAL.INWARD") | uppercase | translate }}
      </p>
      <div class="vertical-separator" *ngIf="(commonService.isTabletObservable | async) === true">
        <span class="round"></span>
        <span class="line"></span>
        <span class="round"></span>
      </div>
      <div class="pickup infos">
        <p class="place">
          {{ item.detail.booking.transfers[0]?.fromdetails.name | titlecase }}
        </p>
        <p class="place" *ngIf="item.detail.booking.transfers.length === 1">
          {{ item.detail.booking.transfers[0]?.todetails.name | titlecase }}
        </p>
      </div>
      <div class="date-hours-container">
        <div class="date-hours">
          <p class="date">
            <img src="./assets/svg/calendar-icon.svg" class="calendar-icon" />
            <ng-container *ngIf="item.detail.booking.transfers[0]?.fromdetails.flight">
              <span class="date-day">{{
                item.detail.booking.transfers[0]?.fromdetails.flight.arrivaldatetime.date | localizedDate: "dd"
              }}</span>
              <span class="date-month">{{
                item.detail.booking.transfers[0]?.fromdetails.flight.arrivaldatetime.date
                  | localizedDate: "MMM"
                  | titlecase
              }}</span>
            </ng-container>
            <ng-container *ngIf="transfer.fromdetails.train">
              <span class="date-day">{{ transfer.fromdetails.train.arrivaldatetime.date | localizedDate: "dd" }}</span>
              <span class="date-month">{{
                item.detail.booking.transfers[0]?.fromdetails.train.arrivaldatetime.date
                  | localizedDate: "MMM"
                  | titlecase
              }}</span>
            </ng-container>
            <ng-container *ngIf="transfer.fromdetails.accommodation">
              <span class="date-day">{{
                transfer.fromdetails.accommodation.pickupdatetime.date | localizedDate: "dd"
              }}</span>
              <span class="date-month">{{
                transfer.fromdetails.accommodation.pickupdatetime.date | localizedDate: "MMM" | titlecase
              }}</span>
            </ng-container>
          </p>
          <div class="chip-timing">
            <img src="./assets/svg/clock2.svg" class="clock-icon" />
            <ng-container *ngIf="transfer.fromdetails.flight">
              <span>{{ transfer.fromdetails.flight.arrivaldatetime.time }}</span>
            </ng-container>
            <ng-container *ngIf="transfer.fromdetails.train">
              <span>{{ transfer.fromdetails.train.arrivaldatetime.time }}</span>
            </ng-container>
            <ng-container *ngIf="transfer.fromdetails.accommodation">
              <span>{{ transfer.fromdetails.accommodation.pickupdatetime.time }}</span>
            </ng-container>
          </div>
        </div>
        <div class="date-hours" *ngIf="item.detail.booking.transfers.length === 1">
          <p class="date">
            <img src="./assets/svg/calendar-icon.svg" class="calendar-icon" />
            <ng-container *ngIf="item.detail.booking.transfers[0]?.todetails.flight">
              <span class="date-day">{{
                item.detail.booking.transfers[0]?.todetails.flight.departuredatetime.date | localizedDate: "dd"
              }}</span>
              <span class="date-month">{{
                item.detail.booking.transfers[0]?.todetails.flight.departuredatetime.date
                  | localizedDate: "MMM"
                  | titlecase
              }}</span>
            </ng-container>
            <ng-container *ngIf="item.detail.booking.transfers[0]?.todetails.train">
              <span class="date-day">{{
                item.detail.booking.transfers[0]?.todetails.train.departuredatetime.date | localizedDate: "dd"
              }}</span>
              <span class="date-month">{{
                item.detail.booking.transfers[0]?.todetails.train.departuredatetime.date
                  | localizedDate: "MMM"
                  | titlecase
              }}</span>
            </ng-container>
            <ng-container *ngIf="item.detail.booking.transfers[0]?.todetails.accommodation">
              <span class="date-day">{{
                item.detail.booking.transfers[0]?.todetails.accommodation.dropoffdatetime.date | localizedDate: "dd"
              }}</span>
              <span class="date-month">{{
                item.detail.booking.transfers[0]?.todetails.accommodation.dropoffdatetime.date
                  | localizedDate: "MMM"
                  | titlecase
              }}</span>
            </ng-container>
          </p>
          <div class="chip-timing">
            <img src="./assets/svg/clock2.svg" class="clock-icon" />
            <ng-container *ngIf="item.detail.booking.transfers[0]?.todetails.flight">
              <span>{{ item.detail.booking.transfers[0]?.todetails.flight.departuredatetime.time }}</span>
            </ng-container>
            <ng-container *ngIf="item.detail.booking.transfers[0]?.todetails.train">
              <span>{{ item.detail.booking.transfers[0]?.todetails.train.departuredatetime.time }}</span>
            </ng-container>
            <ng-container *ngIf="item.detail.booking.transfers[0]?.todetails.accommodation">
              <span>{{ item.detail.booking.transfers[0]?.todetails.accommodation.dropoffdatetime.time }}</span>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="mobile-time" *ngIf="(commonService.isTabletObservable | async) === true">
        <ng-container *ngIf="item.detail.booking.transfers[0]?.fromdetails.flight">
          <span>{{ item.detail.booking.transfers[0]?.fromdetails.flight.arrivaldatetime.time }}</span>
        </ng-container>
        <ng-container *ngIf="item.detail.booking.transfers[0]?.fromdetails.train">
          <span>{{ item.detail.booking.transfers[0]?.fromdetails.train.arrivaldatetime.time }}</span>
        </ng-container>
        <ng-container *ngIf="item.detail.booking.transfers[0]?.fromdetails.accommodation">
          <span>{{ item.detail.booking.transfers[0]?.fromdetails.accommodation.pickupdatetime.time }}</span>
        </ng-container>
        <ng-container *ngIf="item.detail.booking.transfers[0]?.todetails.flight">
          <span>{{ item.detail.booking.transfers[0]?.todetails.flight.departuredatetime.time }}</span>
        </ng-container>
        <ng-container *ngIf="item.detail.booking.transfers[0]?.todetails.train">
          <span>{{ item.detail.booking.transfers[0]?.todetails.train.departuredatetime.time }}</span>
        </ng-container>
        <ng-container *ngIf="item.detail.booking.transfers[0]?.todetails.accommodation">
          <span>{{ item.detail.booking.transfers[0]?.todetails.accommodation.dropoffdatetime.time }}</span>
        </ng-container>
      </div>
    </div>
  </div>
</div>
