<form
  (submit)="search()"
  class="form"
  [ngClass]="'suggest-' + suggestPanelService.active.name"
  [class.suggestOpened]="suggestOpened"
>
  <div class="title">
    <div class="flex justify-content-content items-center">
      <img class="icon-travel" src="./assets/svg/{{ searchEngineType }}.svg" />
      <h1 class="label" ngClass="seminar" [innerHTML]="'GLOBAL.SEMINAR.CUSTOM' | translate"></h1>
    </div>
  </div>
  <div class="type">
    <p-dropdown
      name="seminar-kind"
      [(ngModel)]="searchCriteria.options.kind"
      (onChange)="checkValidity()"
      class="search-form-item"
      styleClass="search-dropdown"
      [placeholder]="'GLOBAL.TYPE' | translate"
      [options]="seminarKindOptions"
    ></p-dropdown>
  </div>
  <spt-travel-search-autocomplete
    [label]="{ destination: 'GLOBAL.ADDRESS' | translate }"
    [placeholder]="{
      destination: 'SEARCH.SEMINAR.PLACEHOLDER_FINDER' | translate
    }"
    [searchService]="searchService"
    [travel]="searchCriteria.mainTravel"
    (onCloseSuggest)="resetSuggestPanel()"
    (onTravelChange)="checkValidity()"
    [disableFavorites]="true"
  ></spt-travel-search-autocomplete>
  <div
    class="suggest-panel seminar-default"
    *ngIf="suggestPanelService.active.name === 'seminar-welcome' && !commonService.isTablet"
  >
    <div class="title">
      {{ "SEARCH.SEMINAR.DEFAULT_TEXT_TITLE" | translate }}
    </div>

    <div class="content" [innerHTML]="'SEARCH.SEMINAR.DEFAULT_TEXT' | translate"></div>
    <div class="bg"></div>
  </div>
  <spt-search-datetime
    [i18n]="{
      outwardLabelDate: 'SEARCH.SEMINAR.LABEL_OUTWARD_DATE' | translate,
      inwardLabelDate: 'SEARCH.SEMINAR.LABEL_INWARD_DATE' | translate,
      outwardDatePlaceholder: 'GLOBAL.DATE' | translate,
      inwardDatePlaceholder: 'GLOBAL.DATE' | translate,
      oneWaySuggestPanelTitle: 'SEARCH.SEMINAR.DATETIME_TITLE' | translate
    }"
    calendarSelectionMode="range"
    [yearRange]="yearRange"
    [time]="false"
    [sourceWhenCriteria]="searchCriteria.mainTravel.when"
    (onActivateSuggest)="activateSuggest($event)"
    (onCloseSuggest)="resetSuggestPanel()"
    (onDatetimeChange)="checkValidity()"
  ></spt-search-datetime>
  <div class="options">
    <div class="search-form-item details">
      <div class="p-inputgroup person-number">
        <img src="./assets/svg/add-person.svg" class="p-inputgroup-addon" />
        <input
          name="seminar-personsNumber"
          [(ngModel)]="searchCriteria.options.personsNumber"
          (ngModelChange)="checkValidity()"
          pInputText
          type="number"
          min="1"
          [placeholder]="'SEARCH.SEMINAR.PERSON' | translate"
        />
      </div>
      <div class="p-inputgroup budget">
        <img src="./assets/svg/euro.svg" class="p-inputgroup-addon pt-1" />
        <!--input
          pInputText
          type="number"
          [placeholder]="'SEARCH.SEMINAR.BUDGET' | translate"
          min="0"
          formControlName="budget"
        /-->
        <input
          name="seminar-budget"
          [(ngModel)]="searchCriteria.options.budget"
          (ngModelChange)="checkValidity()"
          pInputText
          type="number"
          class="p-inputgroup"
          [placeholder]="'SEARCH.SEMINAR.BUDGET' | translate"
          min="0"
        />
        <!--p-dropdown
          formControlName="by"
          [options]="byTypes"
          styleClass="by-dropdown align-items-center"
          class=""
        ></p-dropdown-->
        <p-dropdown
          name="seminar-budgetAllocation"
          [(ngModel)]="searchCriteria.options.budgetAllocation"
          (onChange)="checkValidity()"
          [options]="byTypes"
          styleClass="p-inputgroup by-dropdown align-items-center"
        ></p-dropdown>
      </div>
    </div>
    <div class="search-form-item contact">
      <div class="name">
        <!--input
          pInputText
          formControlName="name"
          [placeholder]="'ACCOUNT.PASSPORT.LASTNAME' | translate"
        /-->
        <input
          name="seminar-name"
          class="p-inputgroup"
          [(ngModel)]="searchCriteria.options.name"
          pInputText
          [placeholder]="'SEARCH.SEMINAR.NAME' | translate"
        />
      </div>
      <div class="p-inputgroup phone">
        <img src="./assets/svg/phone.svg" class="p-inputgroup-addon" />
        <!--input
          pInputText
          type="tel"
          formControlName="phone"
          [placeholder]="'ACCOUNT.GENERAL.PHONE' | translate"
        /-->
        <input
          name="seminar-phone"
          [(ngModel)]="searchCriteria.options.phone"
          (ngModelChange)="checkValidity()"
          pInputText
          class="p-inputgroup"
          type="tel"
          [placeholder]="'SEARCH.SEMINAR.PHONE' | translate"
        />
      </div>
    </div>
    <div class="search-form-item">
      <!--textarea
        pInputTextarea
        formControlName="comment"
        [placeholder]="'SEARCH.SEMINAR.COMMENT' | translate"
      ></textarea-->
      <textarea
        name="seminar-comment"
        [(ngModel)]="searchCriteria.options.comment"
        (ngModelChange)="checkValidity()"
        pInputTextarea
        [placeholder]="'SEARCH.SEMINAR.COMMENT' | translate"
      ></textarea>
    </div>
  </div>
  <div class="triggers submit">
    <p-button type="submit" [disabled]="!isValid" label="{{ 'SEARCH.SEMINAR.CTA' | translate }}"></p-button>
  </div>
</form>
