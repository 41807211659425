<section class="bookings-list">
  <div class="filter">
    <p-dropdown
      [options]="members"
      [filter]="true"
      (onChange)="selectMember($event)"
      [showClear]="true"
      optionLabel="user.username"
      [autoDisplayFirst]="false"
      [placeholder]="'GLOBAL.ALL' | translate"
      [filterFields]="['user.username']"
    ></p-dropdown>
  </div>
  <ng-container *ngIf="displayedItems && displayedItems.length > 0; else empty">
    <div class="item" *ngFor="let item of displayedItems">
      <div class="icon">
        <img *ngIf="item.type !== 'other'" [alt]="item.type" [src]="'./assets/svg/' + item.type + '-full.svg'" />
      </div>
      <p-card class="block" styleClass="padded-container" (mousedown)="choosenObject = item">
        <div class="body" [ngSwitch]="item.type">
          <spt-booking-flight [item]="item" *ngSwitchCase="'flight'"></spt-booking-flight>
          <spt-booking-train [item]="item" *ngSwitchCase="'train'"></spt-booking-train>
          <spt-booking-hotel [item]="item" *ngSwitchCase="'hotel'"></spt-booking-hotel>
          <spt-booking-rental [item]="item" *ngSwitchCase="'car'"></spt-booking-rental>
          <spt-booking-other [item]="item" *ngSwitchCase="'other'"></spt-booking-other>
          <!-- <spt-basket-seminar [item]="item" *ngSwitchCase="'seminar'"></spt-basket-seminar>-->
        </div>
      </p-card>
      <div class="travelers" *ngIf="(commonService.isTabletObservable | async) === false">
        <spt-avatar *ngFor="let traveler of item.travelers" [user]="traveler" [displayUsername]="false"></spt-avatar>
      </div>
      <div
        class="eticket-container"
        (mousedown)="openEticket(item)"
        *ngIf="item.documentIds.length > 0 && item.status === 'confirmed'"
      >
        <div class="eticket">
          <img
            src="./assets/svg/qr-{{ (commonService.isTabletObservable | async) === true ? 'white' : 'green' }}.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #empty>
    <div class="no-booking">
      {{ "BOOKING.EMPTY_MONTH" | translate }}
    </div>
  </ng-template>
  <a class="txt-18" routerLink="/management/bookings">
    {{ "GLOBAL.ALL_BOOKING" | translate }}
  </a>
</section>

<spt-sidebar [opened]="choosenObject !== undefined" (close)="choosenObject = undefined">
  <spt-reservations-detail
    *ngIf="choosenObject"
    [item]="choosenObject"
    [isSmallDevice]="commonService.isTabletObservable | async"
    (toggleSidebarEmitter)="onSidebarClose()"
  ></spt-reservations-detail>
</spt-sidebar>
