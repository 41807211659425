import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TrainLocality, TrainSearchOptions, TrainSearchService } from "./train-search.service";
import { DateTimeSearch, SearchCriteria, SearchResult, Travel, WhenSearch } from "../search-engine-service";
import { UtilsTypes } from "src/app/@shared/@types/utils";
import { TrainTypes } from "src/app/travel/train/train";
import { DateTime } from "luxon";
import { UntypedFormGroup } from "@angular/forms";
import { SessionService } from "src/app/@shared/services/session.service";
import { SelectItem } from "primeng/api";
import { SuggestPanelService } from "../common/suggest-panel.service";

@Component({
  selector: "spt-search-train",
  templateUrl: "./search-train.component.html",
  styleUrls: ["./search-train.component.scss"],
})
export class SearchTrainComponent implements OnInit, OnChanges, OnDestroy {
  searchEngineType: string;

  public form: UntypedFormGroup;

  public minStartDate: Date = DateTime.now().startOf("day").toJSDate();
  public maxDateCount: number = 2;
  public membersMax: number;
  protected hourMandatory: boolean = false;

  yearRange: string = `${this.minStartDate.getFullYear()}:${this.minStartDate.getFullYear() + 10}`;

  @Input() searchCriteria: SearchCriteria<TrainLocality, TrainSearchOptions>;
  isValid: boolean = false;

  travelTrainTypes: UtilsTypes.FixedLengthArray<["Return", "Single"]> = ["Return", "Single"];

  timeOptions: SelectItem[] = [];

  travelTrainType: TrainTypes.SearchType = "Return";
  suggestOpened: boolean = false;
  showSearchWarning: boolean = false;

  ngUnsubscribe: Subject<void> = new Subject();

  constructor(
    protected searchService: TrainSearchService,
    private sessionService: SessionService<any>,
    protected suggestPanelService: SuggestPanelService,
  ) {
    this.searchEngineType = searchService.getType();
  }

  ngOnInit(): void {
    if (!this.searchCriteria) {
      this.searchCriteria = this.searchService.createBlankCriteria();
    }
    this.suggestPanelService.init(DEFAULT_SUGGEST_PANEL);
    this.travelTrainType = !!this.searchCriteria.mainTravel.when.inward ? "Return" : "Single";
    this.membersMax = this.searchService.getMaxPassengers(this.searchCriteria);
    const session = this.sessionService.get();
    if (session.timeFormat === "12") {
      this.timeOptions.push(
        { label: "--:--", value: { begin: "00:00", end: "23:59" } },
        { label: "06:00AM", value: { begin: "06:00", end: "07:59" } },
        { label: "08:00AM", value: { begin: "08:00", end: "09:59" } },
        { label: "10:00AM", value: { begin: "10:00", end: "11:59" } },
        { label: "12:00PM", value: { begin: "12:00", end: "13:59" } },
        { label: "02:00PM", value: { begin: "14:00", end: "15:59" } },
        { label: "04:00PM", value: { begin: "16:00", end: "17:59" } },
        { label: "06:00PM", value: { begin: "18:00", end: "19:59" } },
        { label: "08:00PM", value: { begin: "20:00", end: "21:59" } },
        { label: "10:00PM", value: { begin: "22:00", end: "23:59" } },
      );
    } else {
      this.timeOptions.push(
        { label: "--:--", value: { begin: "00:00", end: "23:59" } },
        { label: "06:00", value: { begin: "06:00", end: "07:59" } },
        { label: "08:00", value: { begin: "08:00", end: "09:59" } },
        { label: "10:00", value: { begin: "10:00", end: "11:59" } },
        { label: "12:00", value: { begin: "12:00", end: "13:59" } },
        { label: "14:00", value: { begin: "14:00", end: "15:59" } },
        { label: "16:00", value: { begin: "16:00", end: "17:59" } },
        { label: "18:00", value: { begin: "18:00", end: "19:59" } },
        { label: "20:00", value: { begin: "20:00", end: "21:59" } },
        { label: "22:00", value: { begin: "22:00", end: "23:59" } },
      );
    }

    this.searchService.$warnSearch.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      this.showSearchWarning = value;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  activateSuggest(suggestToActivate: string) {
    this.suggestPanelService.activate(suggestToActivate);
    this.suggestOpened = true;
  }

  @Output()
  onSearchResult: EventEmitter<SearchResult> = new EventEmitter<SearchResult>();

  search(): void {
    this.searchService
      .launchSearch(this.searchCriteria)
      .subscribe((searchResult: SearchResult) => this.onSearchResult.emit(searchResult));
  }

  resetSuggestPanel(): void {
    this.suggestPanelService.activate(DEFAULT_SUGGEST_PANEL);
    this.suggestOpened = false;
  }

  private hourMandatoryForTravel(when: WhenSearch): boolean {
    return (
      (when.inward.time.begin === "00:00" || when.outward.time.begin === "00:00") &&
      // Et l'heure de l'aller n'est pas définit non plus
      when.inward.date &&
      // Et la date de l'aller et du retour sont identique
      when.outward.date === when.inward?.date
    );
  }

  checkValidity() {
    const oldMembersMax = this.membersMax;
    this.membersMax = this.searchService.getMaxPassengers(this.searchCriteria);
    if (this.travelTrainType === "Return") {
      this.hourMandatory = this.hourMandatoryForTravel(this.searchCriteria.mainTravel.when);
    } else {
      this.hourMandatory = false;
    }
    if (oldMembersMax > this.membersMax) {
      // TODO : afficher un message d'avertissement ?
      console.warn("Change max passengers:", oldMembersMax, "->", this.membersMax, "for:", this.searchCriteria);
      this.searchCriteria.travels.forEach((travel) => {
        if (travel.people.length > this.membersMax) {
          travel.people = [];
        }
      });
    }
    this.isValid = this.searchService.searchCriteriaIsValid(this.searchCriteria) && !this.hourMandatory;
  }

  changeType(type: any) {
    if (type === "Single") {
      this.maxDateCount = 1;
      this.searchCriteria.mainTravel.when.inward = undefined;
    } else {
      this.maxDateCount = 2;
      this.searchCriteria.mainTravel.when.inward = new DateTimeSearch();
    }
    this.checkValidity();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["type"]) {
      this.changeType(changes["type"]);
    }
  }
  get outwardJourney(): UntypedFormGroup {
    return this.form.get("outwardJourney") as UntypedFormGroup;
  }

  get inwardJourney(): UntypedFormGroup {
    return this.form.get("inwardJourney") as UntypedFormGroup;
  }
}

const DEFAULT_SUGGEST_PANEL = "favorites";
