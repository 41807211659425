<div *ngIf="openedInModal" class="inModal">
  <button type="button" class="closeModalButton fas fa-times fa-2x m-l-auto" (mousedown)="closeModal()"></button>
  <ng-container *ngTemplateOutlet="template"></ng-container>
</div>
<ng-template #template>
  <stp-locality-autocomplete
    #localityAutoCompleteOrigin
    *ngIf="travel.origin && (!displayOnly || displayOnly === 'origin')"
    [searchService]="searchService"
    [className]="'origin ' + className + (destinationSameAsOrigin ? ' destinationSameAsOrigin' : '')"
    [suggestPanelName]="originSuggestPanelName"
    [showSuggestInPanel]="showSuggestInPanel"
    [gridArea]="originGridArea"
    [label]="label?.origin || 'SEARCH.DEPARTURE' | translate"
    [placeholder]="placeholder?.origin"
    suggestPanelTitle="Sélectionner un départ :"
    [initialLocality]="travel.origin"
    (closeSuggest)="closeSuggestPanel()"
    (onSearch)="activateSuggest($event)"
    (onChange)="setOrigin($event)"
    [icon]="icons?.origin"
    [restrictions]="restrictions?.origin"
    [suggestions]="suggestions?.origin"
    [from]="from?.origin"
    [radius]="radius?.origin"
    [address]="address?.origin"
    (onFocus)="onFocusLocality('origin')"
    [reverse]="reverse"
    (onReverse)="reverseData()"
  ></stp-locality-autocomplete>
  <stp-locality-autocomplete
    #localityAutoCompleteDestination
    *ngIf="travel.destination && (!displayOnly || displayOnly === 'destination')"
    [searchService]="searchService"
    [className]="'destination ' + className"
    [disabled]="destinationSameAsOrigin"
    [suggestPanelName]="destinationSuggestPanelName"
    [showSuggestInPanel]="showSuggestInPanel"
    [gridArea]="destinationGridArea"
    [label]="label?.destination || 'SEARCH.ARRIVAL_LOCALISATION_LABEL' | translate"
    [placeholder]="placeholder?.destination"
    suggestPanelTitle="Sélectionner une arrivée :"
    [initialLocality]="travel.destination"
    (closeSuggest)="closeSuggestPanel()"
    (onSearch)="activateSuggest($event)"
    (onChange)="setDestination($event)"
    [icon]="icons?.destination"
    [restrictions]="restrictions?.destination"
    [suggestions]="suggestions?.destination"
    [from]="from?.destination"
    [radius]="radius?.destination"
    [address]="address?.destination"
    (onFocus)="onFocusLocality('destination')"
  ></stp-locality-autocomplete>

  <div
    *ngIf="optionDestinationSameAsOriginLabel && !displayOnly"
    class="destinationSameAsOrigin"
    style="grid-area: destinationSameAsOrigin"
  >
    <p-checkbox
      class="checkbox"
      [name]="id + '-destinationSameAsOrigin'"
      [binary]="true"
      [label]="optionDestinationSameAsOriginLabel"
      [(ngModel)]="destinationSameAsOrigin"
      (onChange)="changeDestinationSameAsOrigin()"
    ></p-checkbox>
  </div>
  <div class="suggest-panel favoriteSuggestList" *ngIf="displayFavorites && !disableFavorites">
    <spt-favorites
      [searchService]="searchService"
      [label]="label"
      [placeholder]="placeholder"
      [optionDestinationSameAsOriginLabel]="optionDestinationSameAsOriginLabel"
      (onSelectFavorite)="applyFavorite($event)"
      (onSelectLastSearch)="applyLastSearch($event)"
      [destinationSameAsOrigin]="true"
    ></spt-favorites>
  </div>
</ng-template>
