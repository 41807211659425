import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

export enum ItemStatus {
  CREATED = "created",
  ERROR = "error",
  CONFIRMED = "confirmed",
  CANCELLED = "cancelled",
  MODIFIED = "modified",
}

@Injectable({
  providedIn: "root",
})
export class ItemService {
  constructor(private httpClient: HttpClient) {}

  getCancellation(id: string, formData?: any): Observable<any> {
    return this.httpClient.get(`${environment.api}/items/${id}/cancellation`, {
      params: formData,
    });
  }

  public setItemAncillaries(itemId: string, offerId: string, selectedOfferItems: any[]) {
    return this.httpClient.patch(`${environment.api}/items/${itemId}/ancillaries-afkl`, {
      offerId,
      selectedOfferItems,
    });
  }

  cancel(id: string, formData: { dataId: string; optionId: string }, lang?: string): Observable<any> {
    const params: HttpParams = new HttpParams({
      fromObject: formData,
    });
    return this.httpClient.delete(`${environment.api}/items/${id}`, {
      params,
      headers: { "Accept-Language": lang },
    });
  }
}
