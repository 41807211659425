import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { concatMap, catchError } from "rxjs/operators";
import { AuthService } from "../auth.service";
import { SessionService, SessionType } from "src/app/@shared/services/session.service";
import { MessageService } from "primeng/api";
import { CommonService } from "src/app/@shared/services/common.service";
import { LoadingService } from "src/app/@shared/services/loading.service";
import { throwError, forkJoin, Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { SocietyService } from "src/app/@shared/services/society.service";
import { UserService } from "src/app/@shared/services/user.service";

@Component({
  selector: "spt-active-account",
  styleUrls: ["./active-account.component.scss"],
  templateUrl: "./active-account.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class ActiveAccountComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  private subscription: Subscription;
  private token: string;
  private userId: string;
  public displayPassword: Object = { password: false, passwordControl: false };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private httpClient: HttpClient,
    private authService: AuthService,
    private sessionService: SessionService<SessionType>,
    private messageService: MessageService,
    public commonService: CommonService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private societyService: SocietyService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.route.params.subscribe((params: { token: string; userId: string }) => {
      this.token = params.token;
      this.userId = params["user-id"];

      this.form = this.formBuilder.group(
        {
          password: [
            "",
            [
              Validators.required,
              Validators.pattern(
                /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d!@#\$%\^&\*~`()_\-\+={[}\]|:;"'<,>\.\/?´ç¡¿€]{7,}/,
              ),
            ],
          ],
          controlPassword: [
            "",
            [
              Validators.required,
              Validators.pattern(
                /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d!@#\$%\^&\*~`()_\-\+={[}\]|:;"'<,>\.\/?´ç¡¿€]{7,}/,
              ),
            ],
          ],
        },
        {
          validator: this.duplicatePassword,
        },
      );
    });
  }

  activeAccount(): void {
    const endPoint: string = `${environment.api}/api/users/${this.userId}`;

    const data: any = {
      activate: this.token,
      password: this.form.get("password").value,
    };
    const headers: HttpHeaders = new HttpHeaders({ Authorization: this.token });

    this.httpClient
      .put(endPoint, data, { headers })
      .pipe(
        concatMap((res: any) =>
          this.authService
            .login({
              user: res.data.email,
              password: this.form.get("password").value,
            })
            .pipe(
              catchError((error: any) => {
                this.loadingService.remove();
                if (error.status === 401) {
                  this.messageService.add({
                    severity: "error",
                    detail: this.translateService.instant("NOTIFICATIONS.WRONG_ACCOUNT"),
                  });
                }
                return throwError(error);
              }),
              concatMap((resLogin: any) => {
                const session: SessionType = {
                  token: resLogin.data.replace(/JWT /, ""),
                  userId: resLogin.loggedUser._id,
                  roles: resLogin.loggedUser.roles,
                };
                this.sessionService.set(session, { rememberMe: true });
                return forkJoin({
                  societyId: this.societyService.retrieveSociety(resLogin.loggedUser._id, false, true),
                  user: this.userService.getUser(resLogin.loggedUser._id, true),
                });
              }),
              concatMap((resGetInfo: any) => {
                const session: any = this.sessionService.get();
                session.societyId = resGetInfo.societyId;
                session.defaultLanguage = resGetInfo.user.settings.language || "fr";
                this.translateService.setDefaultLang(session.defaultLanguage);
                this.sessionService.set(session, { rememberMe: true });
                return this.societyService.getSociety(resGetInfo.societyId, { popMember: true }, true);
              }),
              concatMap((society: any) => {
                const session: any = this.sessionService.get();
                return this.sessionService.open(session, { rememberMe: true });
              }),
            ),
        ),
      )
      .subscribe(() => {
        this.messageService.add({
          severity: "success",
          detail: "Compte activé",
        });
      });
  }

  duplicatePassword(formGroup: UntypedFormGroup): any {
    const control: any = formGroup.get("password");
    const matchingControl: any = formGroup.get("controlPassword");

    if (!control.value || !matchingControl.value) {
      return;
    }
    const exactMatch: boolean = control.value === matchingControl.value;

    exactMatch === false ? matchingControl.setErrors({ mismatchedPassword: false }) : matchingControl.setErrors();

    return null;
  }

  changeStatutPassword(type): void {
    this.displayPassword[type] = !this.displayPassword[type];
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
