import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "spt-car-provider-image",
  templateUrl: "./car-provider-image.component.html",
  styleUrls: ["./car-provider-image.component.scss"],
})
export class CarProviderImageComponent {
  @Input() providerName: string;
}
