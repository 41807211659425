<div class="confirmation-header" *ngIf="viewOnly">
  <img class="flight-logo" src="./assets/svg/details/plane.svg" />
  <div class="informations">
    <div>Ma reservation de vol</div>
    <div>N° {{ this.data?.detail.pnrId }}</div>
  </div>
</div>
<ng-container *ngFor="let itinerary of this.itineraries">
  <div class="confirmation-header" *ngIf="!viewOnly">
    <img class="flight-logo" src="./assets/svg/details/plane.svg" />
    <div class="informations">
      <div class="oad">
        <div class="origin">
          {{ flightService.airports[itinerary.legs[0].departure.airport]?.name }}
        </div>
        <i class="spt-icon-arrow-circle-right"></i>
        <div class="destination">
          {{ flightService.airports[itinerary.legs[0].arrival.airport]?.name }}
        </div>
      </div>
      <div class="secondary">
        <div class="type" translate>
          {{
            itinerary.cabinCodes?.length === 1 ? ("GLOBAL.CATEGORY." + itinerary.cabinCodes[0] | uppercase) : "MULTI"
          }}
        </div>
        <div class="flexible">
          <i class="spt-icon-tag txt-14"></i>
          <span class="refundable" translate>
            GLOBAL.CATEGORY.{{ itinerary.refundable ? "REFUNDABLE" : "NON_REFUNDABLE" }}
          </span>
          <span class="exchangeable" translate>
            GLOBAL.CATEGORY.{{ itinerary.changeable ? "EXCHANGEABLE" : "NON_EXCHANGEABLE" }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="legs">
    <div *ngFor="let leg of itinerary.legs; let tripIndex = index" class="leg">
      <spt-travel-flight-itinerary-leg
        [departureDate]="leg.schedules[0].departure.date"
        [baggageIncluded]="itinerary.baggageIncluded"
        [carryOnIncluded]="itinerary.carryOnIncluded"
        [carbonOffset]="itinerary?.carbonOffset"
        [leg]="leg"
        class="hideInformations"
      ></spt-travel-flight-itinerary-leg>
      <div class="company">
        <span>{{ "SEARCH.RESULT.AIR.SOLD_BY" | translate }} {{ companies[leg?.governingCarrier]?.name }} </span>
        <img class="icon" src="https://static.misterfly.com:443/images/airlines/{{ leg.governingCarrier }}.png" />
      </div>
    </div>
  </div>
  <div class="warning" *ngIf="airportsDifferents" translate>SEARCH.AIR.AIRPORTS_WARNING</div>
  <div class="charges" *ngIf="itineraries?.length > 1">
    <div class="title">{{ "SEARCH.RESULT.TOTAL" | translate }}:</div>

    <div class="price">
      {{
        {
          amount: itinerary.price ? itinerary.price : itinerary.totalPrice,
          currency: "EUR"
        }
          | SPTPrice
          | async
      }}
      <span class="out-policy" *ngIf="itinerary.isOutPolicy" translate>GLOBAL.OUT_OF_POLICY</span>
    </div>
  </div>
  <div class="travelers">
    <div class="title" ><span translate>SEARCH.RESULT.AIR.FILTERS.CONDITIONS</span> <span>{{itinerary?.className}}</span></div>
    <ng-container *ngFor="let user of this.users; let i = index">
      <ng-container *ngFor="let pricingInformation of itinerary.pricingInformations">
        <spt-flight-detail-information
          [pricingInformation]="pricingInformation"
          [selectedItinerary]="itinerary.type !== 'packaged' ? selectedItinerary : null"
          [dateEnd]="dateEnd"
          [index]="i"
          [user]="user"
          [provider]="itinerary.provider"
        ></spt-flight-detail-information>
      </ng-container>
    </ng-container>
  </div>

  <div class="emission">
    <spt-travel-carbon-offset [carbonOffset]="itinerary?.carbonOffset"></spt-travel-carbon-offset>
  </div>
</ng-container>
<div class="charges">
  <div class="title">
    {{ "SEARCH.RESULT.TOTAL" | translate }}
    :
  </div>
  <div class="price">
    {{
      {
        amount: itineraries[0]?.type !== "packaged" ? total + selectedItinerary?.totalPrice : total,
        currency: "EUR"
      }
        | SPTPrice
        | async
    }}
    <span class="out-policy" *ngIf="isOutOfPolicy" translate>GLOBAL.OUT_OF_POLICY</span>
  </div>
</div>
