<div class="errors noresult" *ngIf="noResult || reallyNoResult || fatalError; error">
  <div class="no-results">
    <p-card>
      <div class="error-container">
        <img src="./assets/svg/no-results-car.svg" alt="No results" />
        <div class="error-message">
          <ng-container *ngIf="noResult || outOfTime; else noResultTemplate">
            <p
              [innerHTML]="
                outOfTime
                  ? ('SEARCH.RESULT.NO_RESULT_CARS_CRITERIA' | translate)
                  : ('NOTIFICATIONS.CAR_SEARCH_NO_AVAIBILITIES' | translate)
              "
            ></p>
          </ng-container>
          <ng-template #noResultTemplate>
            <p [innerHTML]="error ? (error | translate) : ('SEARCH.RESULT.NO_CARS' | translate)"></p>
          </ng-template>
          <p-button
            class="error-button"
            [label]="'SEARCH.RESULT.AIR.UPDATE_SEARCH' | translate"
            styleClass="p-button-quaternary medium"
            (onClick)="modifySearch.emit()"
          ></p-button>
        </div>
      </div>
    </p-card>
  </div>
</div>

<div *ngIf="!fatalError && !noResult && search && !error && !reallyNoResult" class="container-padding">
  <div class="title-section">
    <div class="title">
      <h1>
        <span translate>CAR.TITLE_LABEL</span><span class="title-city">{{ search.data.pickupAddress.city }}</span>
      </h1>
    </div>

    <div class="title-mobile">
      <div class="dates">
        <div class="from">
          <p class="text-center">
            {{ search?.data.pickupDate | localizedDate: "dd MMM" }}
          </p>
          <div class="chip-timing2">
            <img class="clock-icon ng-star-inserted" src="./assets/svg/clock2.svg" />
            <span>{{ searchTimes.pickup | localizedDate: "shortTime" }}</span>
          </div>
        </div>
        <i class="spt-icon-arrow-circle-right"></i>
        <div class="to">
          <p class="text-center">
            {{ search?.data.returnDate | localizedDate: "dd MMM" }}
          </p>
          <div class="chip-timing2">
            <img class="clock-icon ng-star-inserted" src="./assets/svg/clock2.svg" />
            <span>{{ searchTimes.return | localizedDate: "shortTime" }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="filters-sort">
      <p-dropdown [options]="sortOptions" [(ngModel)]="sortOption" (onChange)="changeSortMethod()"> </p-dropdown>
    </div>
  </div>

  <div class="main" [class.filters-opened]="filterPanelOpened">
    <aside>
      <div class="mobile-filters-header" *ngIf="(commonService.isTabletObservable | async) === true">
        <span class="spt-icon-back-arrow" (mousedown)="switchFilterPanel()"></span>
        <div class="title" translate>SEARCH.RESULT.FILTERS</div>
      </div>

      <div class="agencies">
        <h4 translate>CAR.FILTER_AGENCIES_LABEL</h4>

        <div class="options">
          <div
            class="agency"
            [class.selected]="vendor.isSelected"
            *ngFor="let vendor of vendors | async"
            (click)="selectVendor(vendor.name)"
          >
            {{ vendor.name }}
          </div>
        </div>
      </div>

      <div class="horizontal-separator"></div>

      <div class="price-range">
        <h4 translate>CAR.FILTER_TOTAL_PRICE_LABEL</h4>

        <div class="options">
          <p-slider
            [min]="lowestPrice"
            [max]="highestPrice"
            [step]="10"
            [(ngModel)]="filteringPrice"
            (onSlideEnd)="selectMaxPrice($event)"
          >
          </p-slider>
          <span>{{ lowestPrice }}-{{ filteringPrice }} {{ displayedCurrency }}</span>
        </div>
      </div>

      <div class="horizontal-separator"></div>

      <div class="categories">
        <h4 translate>CAR.FILTER_CATEGORIES_LABEL</h4>

        <ul class="options">
          <li
            class="option"
            [ngClass]="{
              active: selectedCategories && selectedCategories.includes(category.name)
            }"
            *ngFor="let category of allCategories"
          >
            <p-checkbox
              [label]="category.name"
              [value]="category.name"
              [(ngModel)]="selectedCategories"
              (onChange)="selectCategory()"
            ></p-checkbox>
            <div class="p-count">
              {{
                {
                  amount: category.firstPrice + 1,
                  currency: category.currency
                }
                  | SPTPrice
                  | async
              }}
            </div>
          </li>
        </ul>
      </div>

      <div class="horizontal-separator"></div>

      <div class="car-options">
        <h4 translate>CAR.FILTER_OPTIONS_LABEL</h4>

        <ul class="options">
          <ng-container *ngFor="let item of allOptions | keyvalue">
            <li
              class="option"
              [ngClass]="{
                active: selectedOptions && selectedOptions.includes(item.key)
              }"
              *ngIf="item.value > 0"
            >
              <p-checkbox
                [label]="'SEARCH.CAR.ADVANTAGES.LABELS.' + item.key | translate"
                [value]="item.key"
                (onChange)="selectOption()"
                [(ngModel)]="selectedOptions"
              ></p-checkbox>

              <div class="count">
                {{ item.value }}
              </div>
            </li>
          </ng-container>
        </ul>
      </div>

      <div class="horizontal-separator"></div>

      <div class="transmission">
        <h4 translate>CAR.FILTER_GEARBOX_LABEL</h4>

        <ul class="options">
          <li
            class="option"
            [ngClass]="{
              active: selectedGearbox && selectedGearbox.includes(type.name)
            }"
            *ngFor="let type of allGearbox"
          >
            <p-checkbox
              [label]="
                (type.name === 'manual' ? 'SEARCH.RESULT.CAR.MANUAL_GEARBOX' : 'SEARCH.RESULT.CAR.AUTOMATIC_GEARBOX')
                  | translate
              "
              [value]="type.name"
              (onChange)="selectGearboxType()"
              [(ngModel)]="selectedGearbox"
            >
            </p-checkbox>

            <div class="count">
              {{ type.count }}
            </div>
          </li>
        </ul>
      </div>

      <div class="horizontal-separator"></div>

      <div class="engineType">
        <h4 translate>CAR.FILTER_ENGINE_LABEL</h4>

        <ul class="options">
          <li
            class="option"
            [ngClass]="{
              active: selectedEngineTypes && selectedEngineTypes.includes(type.name)
            }"
            *ngFor="let type of allEngineTypes"
          >
            <ng-container *ngIf="type.count > 0">
              <p-checkbox
                [label]="type.name"
                [value]="type.name"
                (onChange)="selectEngineType()"
                [(ngModel)]="selectedEngineTypes"
              >
              </p-checkbox>

              <div class="count">
                {{ type.count }}
              </div>
            </ng-container>
          </li>
        </ul>
      </div>

      <div class="actions">
        <button class="filters-mobile-validate" (mousedown)="switchFilterPanel()" type="button">
          {{ "GLOBAL.VALIDATE" | translate }}
        </button>
      </div>
    </aside>

    <article>
      <div *ngIf="noResult" class="_404">
        <p>
          Nous n'avons pas trouvé de véhicules disponibles concernant votre recherche. Veuillez réessayer à une autre
          date ou une autre localisation.
        </p>

        <button class="editSearch">Je change ma recherche</button>
      </div>

      <div *ngIf="noResultFilter" class="_404">
        <p>Aucun véhicule ne correspond à ces critères. Veuillez modifier ou enlever un critère.</p>
      </div>

      <ng-container>
        <spt-travel-car-block
          *ngFor="let car of filteredCars | async"
          [car]="car"
          (carSelected)="carSelected($event)"
          [locale]="locale"
        >
        </spt-travel-car-block>
      </ng-container>
    </article>
    <button class="filters-mobile" (mousedown)="switchFilterPanel()" type="button">
      <span class="spt-icon-filter"></span>

      {{ "SEARCH.RESULT.FILTERS" | translate }}
    </button>
  </div>
</div>
