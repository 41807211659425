<div class="header">
  <div class="details">
    <p class="address">
      <span>
        <img class="transport-icon" src="./assets/svg/train-full.svg" />
      </span>
      <span class="title f-sb">
        <ng-container *ngFor="let trip of this.item.metadata.infosTrips; let i = index">
          <span
            [class.departure]="i === 0"
            [class.return]="i === legsLength - 1 && legsLength > 1"
            [class.step]="i > 0 && i < legsLength - 1"
          >
            {{ trip.departure }}
          </span>
        </ng-container>
        <sup *ngIf="(commonService.isTabletObservable | async) === true && type === 'multi'">
          {{ +legsLength }}
        </sup>
      </span>
    </p>
    <ng-container [ngSwitch]="type">
      <p class="type" translate>
        <ng-container *ngSwitchCase="'simple'"> SEARCH.SIMPLE </ng-container>
        <ng-container *ngSwitchCase="'round'"> SEARCH.SHORT.ROUND </ng-container>
        <ng-container *ngSwitchCase="'multi'"> SEARCH.SHORT.MULTI </ng-container>
      </p>
    </ng-container>
  </div>
  <div class="datetimes">
    <p class="dates">
      <ng-container *ngFor="let trip of this.item.metadata.infosTrips; let i = index">
        <span
          *ngIf="
            ((i === 1 || (i > 1 && (commonService.isTabletObservable | async) === false)) && type === 'multi') ||
            (i === 1 && type === 'round')
          "
          class="separator"
        ></span>
        <span
          [class.departure]="i === 0 && type !== 'simple'"
          [class.return]="i === 1 && type === 'round'"
          [class.simple]="type === 'simple'"
          [class.step]="i > 0 && i < legsLength - 1 && type === 'multi'"
        >
          {{ trip.departureTime | localizedDate: "d" }}<br />
          <small class="month">
            {{ (trip.departureTime | localizedDate: "MMM").replace(".", "") }}
          </small>
        </span>
      </ng-container>
    </p>
    <p class="times">
      <ng-container *ngFor="let trip of this.item.metadata.infosTrips; let i = index">
        <span
          [class.departure]="i === 0 && type !== 'simple'"
          [class.return]="i === 1 && type === 'round'"
          [class.simple]="type === 'simple'"
          [class.step]="i > 0 && i < legsLength - 1 && type === 'multi'"
          class="schedule"
        >
          {{
            trip.departureTime
              | localizedDate: ((this.sessionService.getSession() | async)?.timeFormat === 12 ? "h:mm a" : "H:mm")
          }}
          <br />
          {{
            trip.arrivalTime
              | localizedDate: ((this.sessionService.getSession() | async)?.timeFormat === 12 ? "h:mm a" : "H:mm")
          }}
        </span>
      </ng-container>
    </p>
  </div>
</div>
<p class="travelers" translate>GLOBAL.TRAVELERS</p>
<div class="infos">
  <p class="user">
    <spt-avatar [user]="request.board[0][0]"></spt-avatar>
    <sup class="board" *ngIf="request.board[0].length - 1 > 1"> +{{ request.board[0].length - 1 }} </sup>
  </p>
  <span class="out-politic" *ngIf="this.request?.isOutOfPolicy" translate>GLOBAL.OUT_OF_POLICY</span>
  <p class="price f-sb">
    {{ request.price | currency: "EUR" : "symbol" : "1.2-2" }}
  </p>
</div>
