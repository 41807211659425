import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import moment from "moment";

@Component({
  selector: "spt-detail-r",
  styleUrls: ["./detail.component.scss"],
  templateUrl: "./detail.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class DetailComponent implements OnInit {
  @Input() itinerary: any;
  public settings: any;
  @Input() users: Array<any>;
  public newOffers: Array<any>;
  @Input() isValidateOptionInformation: boolean = false;

  public activeTab: number = 0;
  public dateEnd: moment.Moment;

  constructor() {}

  ngOnInit(): void {
    this.dateEnd = moment(this.itinerary.legs[0].departureDatetime).subtract(1, "day");
  }

  handleChange($event: any): void {
    this.activeTab = $event.index;
  }
}
