import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { TrainTypes } from "../../../../../travel/train/train";
import { Flexibility, TrainService } from "../../../../../travel/train/train.service";
import { CarbonOffsetTypes } from "../../../../@types/carbon-offset";
import { Society } from "../../../../@types/society";
import { User } from "../../../../@types/user";
import { UtilsTypes } from "../../../../@types/utils";
import { CommonService } from "src/app/@shared/services/common.service";

@Component({
  selector: "spt-travel-confirmation-train",
  templateUrl: "./confirmation-sidebar-train.component.html",
  styleUrls: ["./confirmation-sidebar-train.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationSidebarTrainComponent implements OnInit {
  @Input() journeys: Array<TrainTypes.Journey>;
  @Input() itineraries: Array<TrainTypes.Itinerary | TrainTypes.ItineraryChange>;
  @Input() invoices: Array<TrainTypes.Invoice>;
  @Input() provider: TrainTypes.Provider;
  @Input() users: Array<User>;
  @Input() locale: string;
  @Input() isSmallDevice: boolean;
  @Input() search: {
    id: string;
    data: TrainTypes.SearchBody | TrainTypes.ExchangeBody;
    society: Society;
  };
  @Input() price: UtilsTypes.Price;
  @Output() closePanel: EventEmitter<boolean> = new EventEmitter();

  carbonOffset: CarbonOffsetTypes.CarbonOffsetEstimation = {
    amount: 0,
    price: 0,
  };
  flexibility: TrainTypes.Flexibility | TrainTypes.ATOCFlexibility;
  selectedFare: TrainTypes.Fare;
  mappedJourneys: Array<TrainTypes.Journey> = [];
  fareTypes: UtilsTypes.ObjectKey<Array<TrainTypes.Fare>> = {};
  seatPreferenceGroups: UtilsTypes.ObjectKey<Array<TrainTypes.SeatPreferenceOption>>;

  schedules: UtilsTypes.ObjectKey<Array<{ departure: UtilsTypes.TravelDate; arrival: UtilsTypes.TravelDate }>> = {
    outward: [],
    inward: [],
  };

  constructor(
    private changeDetector: ChangeDetectorRef,
    private commonService: CommonService,
    private trainService: TrainService,
  ) {}

  private mapJourneys(): void {
    const mapping = this.trainService.mapJourneys(this.journeys);
    this.mappedJourneys = mapping.mappedJourneys;
  }

  private mapFares(itinerary: TrainTypes.Itinerary): void {
    itinerary.sections.forEach((_itinerarySection: TrainTypes.ItinerarySection): void => {
      _itinerarySection.fares.forEach((_fare: TrainTypes.Fare): void => {
        if (!this.flexibility || Flexibility[this.flexibility] < Flexibility[_fare.flexibility]) {
          this.flexibility = _fare.flexibility;
          this.changeDetector.markForCheck();
        }

        const journey = itinerary.journeys.find((_journey: TrainTypes.Journey) => {
          return _journey.segments.some((_segment: TrainTypes.Segment) => {
            return _fare.segmentIds.includes(_segment.id);
          });
        });

        if (this.fareTypes[journey.direction]) {
          const foundFare: TrainTypes.Fare = this.fareTypes[journey.direction].find(
            (_fareType: TrainTypes.Fare): boolean => {
              return _fareType.fareName === _fare.fareName;
            },
          );
          if (foundFare && !foundFare.passengers.some((passenger: string) => _fare.passengers.includes(passenger))) {
            foundFare.passengers = Array.from(new Set([...foundFare.passengers, ..._fare.passengers]));
            foundFare.price.amount += _fare.price.amount;
          } else if (!foundFare) {
            this.fareTypes[journey.direction].push(_fare);
          }
        } else {
          this.fareTypes[journey.direction] = [_fare];
        }
      });
    });
  }

  ngOnInit(): void {
    this.commonService.statusBarTextBlack();
    this.mapJourneys();
    this.itineraries.forEach((_itinerary: TrainTypes.Itinerary): void => {
      this.mapFares(_itinerary);
    });
  }

  close(update: boolean = true): void {
    this.commonService.statusBarTextWhite();
    this.closePanel.emit(update);
    this.changeDetector.markForCheck();
  }

  toggleFare(fare: TrainTypes.Fare): void {
    this.selectedFare = fare;
  }
}
